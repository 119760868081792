// @ts-check
/* eslint react/prop-types: 0 */

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, Checkbox, ClickAwayListener, Grow, Paper, Popper } from "@mui/material";
// eslint-disable-next-line no-unused-vars
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export default function FilterColors(props) {
  const { colors, options, setColors } = props;

  const ref = useRef(null);

  const [open, setOpen] = useState(false);

  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  const handleChange = (option) => {
    setColors((colors) => {
      return colors.includes(option.name) ? colors.filter((color) => color !== option.name) : [...colors, option.name];
    });
  };

  return (
    <>
      <Button
        color="secondary"
        disableElevation
        endIcon={<KeyboardArrowDownIcon />}
        onClick={() => {
          setOpen((open) => !open);
        }}
        ref={ref}
        sx={{
          textTransform: "none",
        }}
        variant="text"
      >
        {colors.length === 0 ? tProjectSchedule("All Colors") : `${colors.length} ${tProjectSchedule("Colors")}`}
      </Button>
      <Popper
        anchorEl={ref.current}
        disablePortal
        open={open}
        placement="bottom-start"
        role={undefined}
        sx={{
          margin: "5px 0 0 10px",
          zIndex: 1201,
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "left top",
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={(event) => {
                  // @ts-ignore
                  if (ref.current && ref.current.contains(event.target)) {
                    return;
                  }
                  setOpen(false);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    padding: "20px",
                    width: "250px",
                  }}
                >
                  <strong>
                    <small>{tProjectSchedule("Select Colors")}</small>
                  </strong>
                  {options.map((option) => {
                    return (
                      <Box
                        key={option.name}
                        sx={{
                          alignItems: "center",
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                        }}
                      >
                        <Checkbox
                          checked={colors.includes(option.name)}
                          onClick={() => handleChange(option)}
                          sx={{
                            padding: "0",
                          }}
                          value={option.name}
                        />
                        <Box
                          onClick={() => handleChange(option)}
                          sx={{
                            backgroundColor: option.light,
                            borderColor: option.dark,
                            borderRadius: "5px",
                            borderStyle: "solid",
                            borderWidth: "1px",
                            display: "block",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                        <Box
                          onClick={() => handleChange(option)}
                          sx={{
                            userSelect: "none",
                          }}
                        >
                          <small>{tProjectSchedule(option.name)}</small>
                        </Box>
                      </Box>
                    );
                  })}
                  <Button
                    color="primary"
                    onClick={() => {
                      setOpen(false);
                    }}
                    sx={{
                      textTransform: "none",
                    }}
                    variant="contained"
                  >
                    {tProjectSchedule("Close")}
                  </Button>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
