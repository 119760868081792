const inspectionsTemplates = {
  add: "テンプレートを追加",
  edit: "テンプレートを編集",
  guideMe: "ガイド",
  inspection_template_recycle_bin: "検査 - テンプレート - リサイクルビン",
  list: "検査リスト",
  meeting: "ミーティング",
  no_inspections_templates_text:
    "追加する場合は、検査テンプレートの追加ボタンをクリックしてプロセスを開始してください",
  no_inspections_templates_title: "現在、検査テンプレートは作成されていません",
  title: "検査 - テンプレート",
  workflow: "ワークフロー",
  add_button_tour: "新しい検査テンプレートを作成",
};

export { inspectionsTemplates };
