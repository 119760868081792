import Lottie from "lottie-react";
import { CircleSharp, Lock, LockOpen } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import animationData from "../../../projectDashboard/16627-firework.json";

const useMeetingsSteps = () => {
  const { t: tTour } = useTranslation("tour");
  const { t: translation } = useTranslation("meetings");

  const addSteps = [
    {
      target: "[data-tour='add-meetings-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_addMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_addMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='add-meetings-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_addMeeting_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_addMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-meetings-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_addMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_addMeeting_text")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            <span style={{ fontWeight: "bold" }}>
              <LockOpen sx={{ marginBottom: -0.4, fontSize: 16 }} /> Public
            </span>{" "}
            - {translation("step3_addMeeting_text_2")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            <span style={{ fontWeight: "bold" }}>
              <Lock sx={{ marginBottom: -0.4, fontSize: 16 }} /> Private
            </span>{" "}
            - {translation("step3_addMeeting_text_3")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-meetings-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_addMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_addMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-meetings-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_addMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_addMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-meetings-6']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_addMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_addMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-meetings-7']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_addMeeting_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            {translation("step7_addMeeting_text_1_1")}{" "}
            <CircleSharp
              sx={{ color: "#5ce381", marginBottom: -0.3, fontSize: 14 }}
            />
            {translation("step7_addMeeting_text_1_2")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            {translation("step7_addMeeting_text_2_1")}{" "}
            <CircleSharp
              sx={{ color: "#ffc900", marginBottom: -0.3, fontSize: 14 }}
            />
            {translation("step7_addMeeting_text_2_2")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-meetings-8']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step8_addMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step8_addMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-meetings-9']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step9_addMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step9_addMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-meetings-10']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step10_addMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step10_addMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-meetings-11']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step11_addMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step11_addMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-meetings-12']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step12_addMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step12_addMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "top",
    },
    {
      target: "[data-tour='add-meetings-13']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step13_addMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step11_addMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "right",
    },
    {
      target: "[data-tour='add-meetings-14']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step14_addMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step14_addMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-meetings-15']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step15_addMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step15_addMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-meetings-16']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step16_addMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step16_addMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_addMeeting")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const viewSteps = [
    {
      target: "[data-tour='view-meetings-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_viewMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_viewMeeting_text")}
          </Typography>
        </div>
      ),
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "[data-tour='view-meetings-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_viewMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_viewMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='view-meetings-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_viewMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_viewMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='view-meetings-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_viewMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_viewMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='view-meetings-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_viewMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_viewMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='view-meetings-6']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_viewMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_viewMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='view-meetings-7']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_viewMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_viewMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='view-meetings-8']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step8_viewMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step8_viewMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_viewMeeting")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const editSteps = [
    {
      target: "[data-tour='edit-meetings-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_editMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_editMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "[data-tour='add-meetings-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_editMeeting_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_editMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-meetings-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_editMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_editMeeting_text")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            <span style={{ fontWeight: "bold" }}>
              <LockOpen sx={{ marginBottom: -0.4, fontSize: 16 }} /> Public
            </span>{" "}
            - {translation("step3_editMeeting_text_2")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            <span style={{ fontWeight: "bold" }}>
              <Lock sx={{ marginBottom: -0.4, fontSize: 16 }} /> Private
            </span>{" "}
            - {translation("step3_editMeeting_text_3")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-meetings-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_editMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_editMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-meetings-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_editMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_editMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-meetings-6']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_editMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_editMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-meetings-7']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_editMeeting_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            {translation("step7_editMeeting_text_1_1")}{" "}
            <CircleSharp
              sx={{ color: "#5ce381", marginBottom: -0.3, fontSize: 14 }}
            />
            {translation("step7_editMeeting_text_1_2")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            {translation("step7_editMeeting_text_2_1")}{" "}
            <CircleSharp
              sx={{ color: "#ffc900", marginBottom: -0.3, fontSize: 14 }}
            />
            {translation("step7_editMeeting_text_2_2")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-meetings-8']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step8_editMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step8_editMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-meetings-9']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step9_editMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step9_editMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='edit-meetings-10']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step10_editMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step10_editMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='edit-meetings-11']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step11_editMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step11_editMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='edit-meetings-12']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step12_editMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step12_editMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "top",
    },
    {
      target: "[data-tour='edit-meetings-13']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step13_editMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step11_editMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "right",
    },
    {
      target: "[data-tour='add-meetings-10']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step14_editMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step14_editMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-meetings-16']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step15_editMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step15_editMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_editMeeting")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const recycleSteps = [
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {`1. ${tTour("moreTitle")}`}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {tTour("moreDescription")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='recycle']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_recycleMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_recycleMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_recycleMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_recycleMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='recycle-meetings-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_recycleMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_recycleMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: ".checkbox-recycle",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_recycleMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_recycleMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "right",
    },
    {
      target: "[data-tour='confirm-recycle']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_recycleMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_recycleMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "right",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_recycleMeeting")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const exportSteps = [
    {
      target: "[data-tour='export-meetings-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_exportMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_exportMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_exportMeeting")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const settingsSteps = [
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {`1. ${tTour("moreTitle")}`}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {tTour("moreDescription")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='settings']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_settingsMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_settingsMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='docs-settings-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_settingsMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_settingsMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "right",
    },
    {
      target: "[data-tour='save-settings']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_settingsMeeting_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_settingsMeeting_text")}
          </Typography>
        </div>
      ),
      placement: "right",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_settingsMeeting")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  return [
    {
      addSteps,
      viewSteps,
      editSteps,
      recycleSteps,
      exportSteps,
      settingsSteps,
    },
  ];
};

export { useMeetingsSteps };
