import { tour } from "./tour";

export const contacts = {
  // general tour
  ...tour,
  moduleElement: "Contactos Externos",
  // ADD - guide me steps
  step1_addContact_title: "1. Nuevo contacto",
  step1_addContact_text: "Haz click aquí para crear un nuevo contacto.",
  step2_addContact_title: "2. Nombre",
  step2_addContact_text: "Escríbele el nombre del contacto.",
  step3_addContact_title: "3. Apellido",
  step3_addContact_text: "Escribe el o los apellidos del contacto",
  step4_addContact_title: "4. Correo electrónico",
  step4_addContact_text: "Escribe el correo electrónico del contacto.",
  step5_addContact_title: "5. Número de teléfono",
  step5_addContact_text: "Escribe el numero de teléfono del contacto.",
  step6_addContact_title: "6. Compañia",
  step6_addContact_text:
    "Escribe el nombre de la compañia a la que pertenece el contacto.",
  step7_addContact_title: "7. Añadir",
  step7_addContact_text: "Confirma la información del contacto para agregarlo.",
  step_final_addContact: "Haz aprendido cómo crear un contacto.",
  // EDIT - guide me steps
  step1_editContact_title: "1. Editar contacto",
  step1_editContact_text: "Haz click para editar el contacto.",
  step2_editContact_title: "2. Nombre",
  step2_editContact_text: "Edita el nombre del contacto.",
  step3_editContact_title: "3. Apellidos",
  step3_editContact_text: "Edita los apellidos del contacto.",
  step4_editContact_title: "4. Correo electrónico",
  step4_editContact_text: "Edita el correo electrónico del contacto.",
  step5_editContact_title: "5. Numero de teléfono",
  step5_editContact_text: "Edita el numero de teléfono del contacto.",
  step6_editContact_title: "6. Empresa",
  step6_editContact_text: "Edita la empresa a la que pertenece el contacto.",
  step7_editContact_title: "7. Confirmar",
  step7_editContact_text: "Confirma los cambios realizados al contacto.",
  step_final_editContact: "Haz aprendido cómo editar un contacto.",
  // DELETE - guide me steps
  step1_deleteContact_title: "1. Eliminar contacto",
  step1_deleteContact_text: "Haz click aqui para eliminar un contacto.",
  step2_deleteContact_title: "2. Confirmar eliminación",
  step2_deleteContact_text:
    "Haz click aquí para confirmar la eliminación del contacto.",
  step_final_deleteContact: "Haz aprendido cómo eliminar un contacto.",

  externalContacts: "Contactos Externos",
  addContact: "Añadir Contacto",
  editContact: "Editar Contacto",
  loadingError: "Hubo un problema cargando los contactos externos",
  createContactSuccess: "Contacto creado",
  updateContactSuccess: "Contacto actualizado",
  deleteContactSuccess: "Contacto eliminado",
  deleteContactConfirmation: "Seguro que quieres eliminar este contacto?",
  noContactsCreated: "No hay contactos por el momento",
  noContactsContent:
    "Si desea crear un nuevo contacto, presione el siguiente botón",
};
