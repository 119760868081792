import { ArrowDropDown } from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DisciplineFormTable } from "./DisciplineFormTable";

function DisciplineForm({ discipline, index }) {
  const { t: tGeneral } = useTranslation("general");

  const [isOpen, setIsOpen] = useState(true);

  const { setValue, watch } = useFormContext();

  const fields = watch("disciplines");

  return (
    <Box
      sx={{
        borderLeft: `4px solid ${discipline.color}`,
        borderRadius: "0.5rem 0 0 0.5rem",
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Button
          sx={{
            color: "black",
            textTransform: "none",
            justifyConteny: "center",
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <ArrowDropDown
            sx={{
              rotate: isOpen ? "0deg" : "180deg",
              animation: "ease 1s rotate",
            }}
          />
          <Typography>{discipline.name}</Typography>
        </Button>
        <Box display="flex" alignItems="center">
          <Typography>{tGeneral("value")}</Typography>
          <TextField
            value={fields[index].value}
            onChange={(e) =>
              setValue(`disciplines[${index}].value`, e.target.value)
            }
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
            size="small"
            InputProps={{ endAdornment: <InputAdornment>%</InputAdornment> }}
          />
        </Box>
      </Box>
      <Box height={isOpen ? "auto" : "0px"} overflow="hidden">
        <DisciplineFormTable index={index} />
      </Box>
    </Box>
  );
}

export { DisciplineForm };
