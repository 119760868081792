export const rfis = {
  // guide me buttons
  add_button_tour: "添加",
  view_button_tour: "查看",
  edit_button_tour: "编辑",
  recycle_button_tour: "回收站",
  export_button_tour: "导出",
  settings_button_tour: "设置",
  // ADD - guide me steps
  step1_addRfi_title: "1. 添加RFI",
  step1_addRfi_text: "点击此处添加RFI",
  step2_addRfi_title: "2. 标题",
  step2_addRfi_text: "输入描述RFI的标题",
  step3_addRfi_title: "3. 交付日期",
  step3_addRfi_text: "选择交付日期作为接收答复的截止日期",
  step4_addRfi_title: "4. 状态",
  step4_addRfi_text1_1: "选择状态",
  step4_addRfi_text1_2: "选择“打开”以发布待回复的RFI。",
  step4_addRfi_text2_1: "选择状态",
  step4_addRfi_text2_2: "选择“草稿”以保存未发布的RFI。",
  step5_addRfi_title: "5. 隐私",
  step5_addRfi_text_1: "选择RFI的隐私类型",
  step5_addRfi_text_2: "任何有权限访问模块的人都可以看到你的RFI",
  step5_addRfi_text_3: "只有参与者才能看到此RFI",
  step6_addRfi_title: "6. 被指派人",
  step6_addRfi_text: "选择将回答你的问题的人",
  step7_addRfi_title: "7. 问题",
  step7_addRfi_text: "输入你想要询问的问题或信息",
  step8_addRfi_title: "8. 文件",
  step8_addRfi_text: "上传任何类型的文件（PDF、Word、Excel、JPG等）。",
  step9_addRfi_title: "9. 来自",
  step9_addRfi_text: "请指定你从项目中的哪个人那里收到了信息。",
  step10_addRfi_title: "10. 规格",
  step10_addRfi_text: "选择你想要引用的规格。",
  step11_addRfi_title: "11. 图纸",
  step11_addRfi_text: "选择你想要将此RFI关联到的图纸。",
  step12_addRfi_title: "12. 成本",
  step12_addRfi_text: "如果此RFI对成本有影响，可以添加金额。",
  step13_addRfi_title: "13. 延迟",
  step13_addRfi_text: "如果此RFI对延迟有影响，可以添加天数和小时数。",
  step14_addRfi_title: "14. 分发列表",
  step14_addRfi_text: "选择你想要通知此RFI的人。",
  step15_addRfi_title: "15. 发布",
  step15_addRfi_text: "点击发布以创建RFI。",
  stepFinal_addRfi: "你已经学会了如何创建RFI",
  // VIEW - guide me steps
  step1_viewRfi_title: "1. 查看RFI",
  step1_viewRfi_text: "点击此处查看RFI",
  step2_viewRfi_title: "2. RFI概览",
  step2_viewRfi_text: "显示完整的RFI信息",
  step3_viewRfi_title: "3. 官方问题",
  step3_viewRfi_text: "显示带有附件的官方问题",
  step4_viewRfi_title: "4. 问题翻译",
  step4_viewRfi_text: "问题会自动翻译成所需的语言，以便跨国界进行有效沟通",
  step5_viewRfi_title: "5. 添加回答和评论",
  step5_viewRfi_text: "被指派人可以对官方请求发送回答和评论",
  step6_viewRfi_title: "6. 创建主题",
  step6_viewRfi_text: "分发列表中的用户也可以在主题部分回答和评论",
  step7_viewRfi_title: "7. 最近查看",
  step7_viewRfi_text: "你可以看到最近查看此RFI的用户",
  step8_viewRfi_title: "8. 最近编辑",
  step8_viewRfi_text: "你可以看到最近与此RFI互动的用户",
  step9_viewRfi_title: "9. 关闭",
  step9_viewRfi_text: "如果你是创建者，一旦获得官方答复，你有责任关闭此RFI",
  step10_viewRfi_title: "10. 导出PDF",
  step10_viewRfi_text: "你可以导出包含此RFI信息的PDF",
  stepFinal_viewRfi: "你已经学会了如何查看RFI",
  // EDIT - guide me steps
  step1_editRfi_title: "1. 编辑RFI",
  step1_editRfi_text: "点击此处编辑RFI",
  step2_editRfi_title: "2. 标题",
  step2_editRfi_text: "输入能描述你的RFI的标题",
  step3_editRfi_title: "3. 交付日期",
  step3_editRfi_text: "选择交付日期作为接收答复的截止日期",
  step4_editRfi_title: "4. 状态",
  step4_editRfi_text1_1: "选择状态",
  step4_editRfi_text1_2: "选择“打开”以发布待回复的RFI。",
  step4_editRfi_text2_1: "选择状态",
  step4_editRfi_text2_2: "选择“草稿”以保存未发布的RFI。",
  step5_editRfi_title: "5. 隐私",
  step5_editRfi_text_1: "选择RFI的隐私类型",
  step5_editRfi_text_2: "任何有权限访问模块的人都可以看到你的RFI",
  step5_editRfi_text_3: "只有参与者才能看到此RFI",
  step6_editRfi_title: "6. 被指派人",
  step6_editRfi_text: "占位符",
  step7_editRfi_title: "7. 分发列表",
  step7_editRfi_text: "占位符",
  step8_editRfi_title: "8. 附加信息",
  step8_editRfi_text: "占位符",
  step9_editRfi_title: "9. 发布",
  step9_editRfi_text: "占位符",
  stepFinal_editRfi: "你已经学会了如何编辑RFI",
  // RECYCLE - guide me steps
  step1_recycleRfi_title: "1. RFI回收站",
  step1_recycleRfi_text: "点击此处打开RFI回收站",
  step2_recycleRfi_title: "2. 回收站菜单",
  step2_recycleRfi_text: "这是RFI回收站的菜单",
  step3_recycleRfi_title: "3. 查看RFI",
  step3_recycleRfi_text: "你可以查看完整的RFI",
  step4_recycleRfi_title: "4. 恢复RFI",
  step4_recycleRfi_text: "选择一个或多个RFI进行恢复",
  stepFinal_recycleRfi: "你已经学会了如何与RFI回收站交互",
  // EXPORT - guide me steps
  step1_exportRfi_title: "1. 导出PDF",
  step1_exportRfi_text: "点击此处以PDF格式导出RFI",
  step2_exportRfi_title: "2. 发送电子邮件",
  step2_exportRfi_text: "点击此处通过电子邮件分享此RFI",
  step3_exportRfi_title: "2. 导出CSV",
  step3_exportRfi_text: "选择一些或筛选所有RFI以导出为CSV文件",
  step4_exportRfi_title: "4. 导出PDF",
  step4_exportRfi_text: "选择一些或筛选所有RFI以导出为PDF文件",
  step5_exportRfi_title: "3. RFI详细视图",
  step5_exportRfi_text: "你可以从详细视图中导出PDF",
  stepFinal_exportRfi: "你已经学会了如何导出RFI",
  // SETTINGS - guide me steps
  step1_settingsRfi_title: "1. RFI设置",
  step1_settingsRfi_text: "点击此处修改设置",
  step2_settingsRfi_title: "2. 天数",
  step2_settingsRfi_text: "在此处修改全局回答RFI的天数限制",
  step3_settingsRfi_title: "3. 分发列表",
  step3_settingsRfi_text: "在此处修改全局分发列表的用户",
  step4_settingsRfi_title: "4. 保存设置",
  step4_settingsRfi_text: "点击此处保存设置",
  stepFinal_settingsRfi: "你已经学会了如何与RFI设置交互",
  // other
  obligatory: "必填",
  public: "公开",
  private: "私密",
  congratulations: "恭喜！",
  rfi_title_module: "RFIs",
  no_rfis_title: "目前尚未创建RFIs（疑问）",
  no_rfis_text: "如果你想添加一个，请点击添加按钮开始流程",
  meeting: "支持",
  workflow: "工作流",
  guideMe: "引导",
  support: "支持",
  days: "天",
  rfi_recycle_bin: "RFI回收站",
  addSpec: "添加规格",
  deleteRfi: "删除RFI（s）",
  confirmDelete: "你确定要删除所选的RFI吗？此操作无法撤销。",
};
