export const contacts = {
  externalContacts: "外部の連絡先",
  addContact: "連絡先を追加",
  editContact: "連絡先を編集",
  loadingError: "外部の連絡先の読み込みに問題が発生しました",
  createContactSuccess: "連絡先が作成されました",
  updateContactSuccess: "連絡先が更新されました",
  deleteContactSuccess: "連絡先が削除されました",
  deleteContactConfirmation: "この連絡先を削除してもよろしいですか？",
  noContactsCreated: "現在、連絡先はありません",
  noContactsContent: "作成する場合は、以下のボタンをクリックしてください",
  moduleElement: "外部の連絡先",
  step1_addContact_title: "1. 新しい連絡先",
  step1_addContact_text:
    "新しい連絡先を作成するにはここをクリックしてください。",
  step2_addContact_title: "2. 名前",
  step2_addContact_text: "連絡先の名前を入力してください。",
  step3_addContact_title: "3. 姓",
  step3_addContact_text: "連絡先の姓を入力してください。",
  step4_addContact_title: "4. メール",
  step4_addContact_text: "連絡先のメールアドレスを入力してください。",
  step5_addContact_title: "5. 電話番号",
  step5_addContact_text: "連絡先の電話番号を入力してください。",
  step6_addContact_title: "6. 企業",
  step6_addContact_text: "連絡先が所属する企業を入力してください。",
  step7_addContact_title: "7. 追加",
  step7_addContact_text: "連絡先情報を確認して追加してください。",
  step_final_addContact: "新しい連絡先の追加方法を学びました。",
  step1_editContact_title: "1. 連絡先の編集",
  step1_editContact_text: "連絡先を編集するにはクリックしてください。",
  step2_editContact_title: "2. 名前",
  step2_editContact_text: "連絡先の名前を編集してください。",
  step3_editContact_title: "3. 姓",
  step3_editContact_text: "連絡先の姓を編集してください。",
  step4_editContact_title: "4. メール",
  step4_editContact_text: "連絡先のメールを編集してください。",
  step5_editContact_title: "5. 電話番号",
  step5_editContact_text: "連絡先の電話番号を編集してください。",
  step6_editContact_title: "6. 企業",
  step6_editContact_text: "連絡先が所属する企業を編集してください。",
  step7_editContact_title: "7. 確認",
  step7_editContact_text: "連絡先に加えられた変更を確認してください。",
  step_final_editContact: "連絡先の編集方法を学びました。",
  step1_deleteContact_title: "1. 連絡先の削除",
  step1_deleteContact_text: "連絡先を削除するにはここをクリックしてください。",
  step2_deleteContact_title: "2. 削除の確認",
  step2_deleteContact_text:
    "連絡先の削除を確認するにはここをクリックしてください。",
  step_final_deleteContact: "連絡先の削除方法を学びました。",
};
