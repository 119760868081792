import { Avatar, Box, Tooltip, Typography, styled } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";

function TooltipUsers({ users, icon, children, idsWithIcon = [] }) {
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow followCursor />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 10,
    },
  }));

  return (
    <LightTooltip
      title={
        <Box
          sx={{
            p: "5px",
            display: "flex",
            flexDirection: "column",
            rowGap: "5px",
          }}
        >
          {users.map((user) => (
            <Box display="flex" alignItems="center" columnGap="5px">
              <Avatar
                key={user?.id}
                alt={user?.name}
                src={user?.urlAvatar}
                sx={{
                  bgcolor: "#ffc900",
                  width: 30,
                  height: 30,
                  fontSize: 16,
                  boxShadow: "0 0 4px 0 rgba(200, 200, 200, 1)",
                  opacity: user.bActive ? 1 : 0.5,
                }}
                style={{
                  border: "0.8px solid white",
                }}
              >{`${user?.name?.split(" ")?.[0]?.[0]}${
                user?.name?.split(" ")?.[1]?.[0]
              }`}</Avatar>
              <Typography color={user.bActive ? "initial" : "gray"}>
                {user.name} {!user.bActive && "(disabled)"}
              </Typography>
              {idsWithIcon.indexOf(user.id) > -1 && icon}
            </Box>
          ))}
        </Box>
      }
    >
      {children}
    </LightTooltip>
  );
}

export { TooltipUsers };
