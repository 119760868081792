export const contacts = {
  externalContacts: "Contactos Externos",
  addContact: "Adicionar Contato",
  editContact: "Editar Contato",
  loadingError: "Houve um problema ao carregar os contatos externos",
  createContactSuccess: "Contato criado",
  updateContactSuccess: "Contato atualizado",
  deleteContactSuccess: "Contato excluído",
  deleteContactConfirmation: "Tem certeza de que deseja excluir este contato?",
  noContactsCreated: "Não há contatos no momento",
  noContactsContent:
    "Se deseja criar um novo contato, pressione o botão abaixo",
  moduleElement: "Contactos Externos",
  step1_addContact_title: "1. Novo Contato",
  step1_addContact_text: "Clique aqui para criar um novo contato.",
  step2_addContact_title: "2. Nome",
  step2_addContact_text: "Insira o nome do contato.",
  step3_addContact_title: "3. Sobrenome",
  step3_addContact_text: "Insira o(s) sobrenome(s) do contato.",
  step4_addContact_title: "4. Email",
  step4_addContact_text: "Insira o endereço de e-mail do contato.",
  step5_addContact_title: "5. Número de telefone",
  step5_addContact_text: "Insira o número de telefone do contato.",
  step6_addContact_title: "6. Empresa",
  step6_addContact_text: "Insira a empresa à qual o contato pertence.",
  step7_addContact_title: "7. Adicionar",
  step7_addContact_text: "Confirme as informações do contato para adicioná-lo.",
  step_final_addContact: "Você aprendeu como adicionar um novo contato.",
  step1_editContact_title: "1. Editar Contato",
  step1_editContact_text: "Clique para editar o contato.",
  step2_editContact_title: "2. Nome",
  step2_editContact_text: "Edite o nome do contato.",
  step3_editContact_title: "3. Sobrenome",
  step3_editContact_text: "Edite o(s) sobrenome(s) do contato.",
  step4_editContact_title: "4. Email",
  step4_editContact_text: "Edite o e-mail do contato.",
  step5_editContact_title: "5. Número de telefone",
  step5_editContact_text: "Edite o número de telefone do contato.",
  step6_editContact_title: "6. Empresa",
  step6_editContact_text: "Edite a empresa à qual o contato pertence.",
  step7_editContact_title: "7. Confirmar",
  step7_editContact_text: "Confirme as alterações feitas no contato.",
  step_final_editContact: "Você aprendeu como editar um contato.",
  step1_deleteContact_title: "1. Excluir Contato",
  step1_deleteContact_text: "Clique aqui para excluir um contato.",
  step2_deleteContact_title: "2. Confirmar Exclusão",
  step2_deleteContact_text: "Clique aqui para confirmar a exclusão do contato.",
  step_final_deleteContact: "Você aprendeu como excluir um contato.",
};
