import { apiSlice } from "../../api/apiSlice";

export const geolocationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.query({
      query: () => ({
        url: "/geolocation/countries",
      }),
    }),
    getStates: builder.query({
      query: (country) => ({
        url: `/geolocation/states/${country}`,
      }),
    }),
    getCities: builder.query({
      query: ({ country, state }) => ({
        url: `/geolocation/cities/${country}/${state}`,
      }),
    }),
  }),
});

export const { useGetCountriesQuery, useGetStatesQuery, useGetCitiesQuery } =
  geolocationApiSlice;
