import { apiSlice } from "../../api/apiSlice";

export const specificationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSpecifications: builder.query({
      query: (idProject) =>
        `/project/specification/getSpecifications/${idProject}`,
      keepUnusedDataFor: 60,
      providesTags: ["Specifications"],
    }),
    addSpecification: builder.mutation({
      query: (body) => ({
        url: "/project/specification/addSpecification",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Specifications"],
    }),
    updateSpecification: builder.mutation({
      query: (body) => ({
        url: "/project/specification/updateSpecification",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Specifications"],
    }),
    deleteSpecification: builder.mutation({
      query: (body) => ({
        url: "/project/specification/deleteSpecification",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Specifications"],
    }),
  }),
});

export const {
  useGetSpecificationsQuery,
  useAddSpecificationMutation,
  useUpdateSpecificationMutation,
  useDeleteSpecificationMutation,
} = specificationApiSlice;
