import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import Lottie from "lottie-react";

import animationData from "../../../projectDashboard/16627-firework.json";

const useProjectDirectorySteps = () => {
  const { t: translation } = useTranslation("projectDirectory");

  const addSteps = [
    {
      target: `[data-tour='${"add-user-1"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_addUser_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_addUser_text")}
          </Typography>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: `[data-tour='${"add-user-2"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_addUser_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_addUser_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"add-user-3"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_addUser_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_addUser_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"add-user-4"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_addUser_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_addUser_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"add-user-5"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_addUser_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_addUser_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"add-user-6"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_addUser_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_addUser_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_addUser")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];
  const editSteps = [
    {
      target: `[data-tour='${"edit-user-1"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_editUser_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_editUser_text")}
          </Typography>
        </div>
      ),
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: `[data-tour='${"edit-user-2"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_editUser_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_editUser_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"edit-user-3"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_editUser_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_editUser_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"edit-user-4"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_editUser_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_editUser_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"settings-tasks-4"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_editUser_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_editUser_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_editUser")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];
  const viewSteps = [
    {
      target: `[data-tour='${"view-user-1"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_viewUser_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_viewUser_text")}
          </Typography>
        </div>
      ),
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: `[data-tour='${"view-user-2"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_viewUser_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_viewUser_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"view-user-3"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_viewUser_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_viewUser_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_viewUser")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  return [
    {
      addSteps,
      editSteps,
      viewSteps,
    },
  ];
};

export { useProjectDirectorySteps };
