/* eslint-disable no-nested-ternary */
import { Chat, ErrorOutline, LocationOn, Update } from "@mui/icons-material";
import TranslateIcon from "@mui/icons-material/Translate";
import { Box, Button, Chip, Divider, Tooltip, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import MaterialUIAudioPlayer from "material-ui-audio-player";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";

import moment from "moment";
import { useParams } from "react-router";
import Docx from "../../../../assets/documents/doc.png";
import Dwg from "../../../../assets/documents/dwg.svg";
import Heic from "../../../../assets/documents/heic.png";
import Pdf from "../../../../assets/documents/pdf.svg";
import Ppt from "../../../../assets/documents/ppt.svg";
import Xlsx from "../../../../assets/documents/xls.svg";
import Zip from "../../../../assets/documents/zip.png";
import Video from "../../../../assets/icons/sharedModules/video.png";
import AudioPlayer from "../../../../components/AudioPlayer";
import { ModalCarouselFileViewer } from "../../../../components/shared";
import UserAvatar from "../../../../components/userAvatars/UserAvatar";
import { useCommentObjectMutation } from "../../../../features/message/messageApiSlice";
import { useGetDailyReportChatQuery } from "../../../../features/project/modules/dailyReports/dailyReportsApiSlice";
import { useGetProjectByIdQuery } from "../../../../features/project/projectApiSlice";
import { useGetUserPreferencesQuery } from "../../../../features/userPreferences/userPreferencesApiSlice";
import { CommentReply } from "./CommentReply";

function CommentItem({ comment }) {
  return (
    <Box p={1}>
      <Box display="flex" gap={1} alignItems="center">
        <UserAvatar url={comment.urlAvatar} bActive={comment.bActive} />
        <Typography color="#4B4B4B">{`${comment.firstName} ${comment.lastName}`}</Typography>
      </Box>
      <Typography fontSize="14px" color="#4B4B4B" pt="4px">
        {comment.comment}
      </Typography>
    </Box>
  );
}

function ReportLocationDescription({
  location,
  topic,
  disabled = false,
  category,
  delayDays,
  delayHours,
}) {
  const { idProject } = useParams();
  const { data: currentProject } = useGetProjectByIdQuery(idProject);

  const { t: tDailyReports } = useTranslation("dailyReports");

  const { t: tGeneral } = useTranslation("general");
  const { data: comments } = useGetDailyReportChatQuery(topic.id);
  const { data: userPreferences } = useGetUserPreferencesQuery();
  const [initialIndex, setInitialIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [transcriptLang, setTranscriptLang] = useState(null);
  const [transcriptValue, setTranscriptValue] = useState(null);
  const [voicenoteLang, setVoicenoteLang] = useState(null);
  const [voicenoteUrl, setVoicenoteUrl] = useState(null);

  const [commentObject, { isLoading: isCommentLoading }] =
    useCommentObjectMutation();

  const commentReport = (comment) => {
    commentObject({
      idObject: topic.id,
      comment,
      files: [],
      module: "dailyreports",
      objectName: "topic",
    });
  };

  const hasFiles = topic?.files?.length > 0;

  const renderFiles = (file, index, showPlusCount) => {
    const fileType = file.extension;
    const fileName = file.name;

    const handleClose = () => {
      setOpen(false);
      setSelectedFile(null);
      setTranscriptLang(null);
      setTranscriptValue(null);
      setVoicenoteLang(null);
      setVoicenoteUrl(null);
    };

    const handleOpen = () => {
      setOpen(true);
    };

    if (fileType.match(/png|jpg|jpeg|gif|avif|apng|svg|ico/)) {
      return (
        <LazyLoad key={index}>
          <Box
            component="div"
            sx={{
              cursor: "pointer",
              height: "100%",
              width: "100%",
              position: "relative",
            }}
            onClick={() => setInitialIndex(index)}
          >
            {index === 2 && showPlusCount > 0 && (
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontSize: "34px",
                  fontWeight: "700",
                  backgroundColor: "black",
                  opacity: "0.45",
                  borderRadius: "10px",
                }}
              >
                +{showPlusCount}
              </Box>
            )}
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "10px",
              }}
              src={file.url}
              alt={file.name}
            />
          </Box>
        </LazyLoad>
      );
    }
    let sourceImage = Pdf;

    if (fileType.match("doc")) {
      sourceImage = Docx;
    }

    if (fileType.match(/xls|csv/)) {
      sourceImage = Xlsx;
    }

    if (fileType.match(/dwg|cad/)) {
      sourceImage = Dwg;
    }

    if (fileType.match(/ppt/)) {
      sourceImage = Ppt;
    }

    if (fileType.match(/zip|rar/)) {
      sourceImage = Zip;
    }
    if (fileType.match(/heic/)) {
      sourceImage = Heic;
    }
    if (fileType.toLowerCase().match(/mp4|mov|avi/)) {
      sourceImage = Video;
    }
    if (
      fileName.includes("voicenote") &&
      (fileType === "aac" || fileType === "wav" || fileType === "mp4")
    ) {
      return (
        <Box key={file?.url} sx={{ width: "100%" }}>
          {userPreferences && userPreferences.translationFeature !== "off" ? (
            <TranslateIcon
              onClick={() => {
                handleOpen();
                setSelectedFile(fileName);
              }}
              style={{ fontSize: "18px", cursor: "pointer" }}
            />
          ) : null}
          {fileName === selectedFile ? (
            <Modal
              aria-describedby="modal-description"
              aria-labelledby="modal-title"
              onClose={handleClose}
              open={open}
            >
              <Paper
                style={{
                  backgroundColor: "white",
                  border: "2px solid #000",
                  boxShadow: 24,
                  left: "50%",
                  padding: "16px",
                  position: "absolute",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "max-content",
                }}
              >
                <div>
                  <Box
                    sx={{
                      backgroundColor: "#FFEBBC4D",
                      border: "none",
                      borderRadius: "8px",
                      maxWidth: "max-content",
                      mb: "25px",
                      minWidth: "40%",
                      p: "15px",
                    }}
                  >
                    <Typography
                      color="black"
                      component="div"
                      mb="12px"
                      sx={{
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                      }}
                    >
                      <strong>View transcript in:</strong>

                      {["Es", "Pt", "En", "Zh", "Ja", "Ko"].map((lang) => {
                        const label =
                          lang === "Es"
                            ? "Español (Es)"
                            : lang === "Pt"
                              ? "Português (Pt)"
                              : lang === "En"
                                ? "English (En)"
                                : lang === "Zh"
                                  ? "中文 (Zh)"
                                  : lang === "Ja"
                                    ? "日本語 (Ja)"
                                    : lang === "Ko"
                                      ? "한국어 (Ko)"
                                      : null;
                        return (
                          <Button
                            key={lang}
                            disabled={!file[`transcriptIn${lang}`]}
                            onClick={() => {
                              setTranscriptLang(lang.toLowerCase());
                              setTranscriptValue(file[`transcriptIn${lang}`]);
                            }}
                            sx={{
                              color:
                                transcriptLang === lang.toLowerCase()
                                  ? "black"
                                  : "primary.main",
                            }}
                          >
                            {label}
                          </Button>
                        );
                      })}
                    </Typography>

                    <Typography
                      color="black"
                      component="div"
                      mb="12px"
                      sx={{
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                      }}
                    >
                      {transcriptLang && transcriptValue && (
                        <span style={{ marginLeft: "30px", color: "primary" }}>
                          {transcriptValue}
                        </span>
                      )}
                    </Typography>
                    <Typography
                      color="black"
                      component="div"
                      mb="12px"
                      sx={{
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                      }}
                    >
                      <strong>Listen in:</strong>

                      <Button
                        onClick={() => {
                          setVoicenoteLang("og");
                          setVoicenoteUrl(file.url);
                        }}
                        sx={{
                          color:
                            voicenoteLang === "og" || !voicenoteUrl
                              ? "black"
                              : "primary.main",
                        }}
                      >
                        Original
                      </Button>
                      {["Es", "Pt", "En", "Zh", "Ja", "Ko"].map((lang) => {
                        const label =
                          lang === "Es"
                            ? "Español (Es)"
                            : lang === "Pt"
                              ? "Português (Pt)"
                              : lang === "En"
                                ? "English (En)"
                                : lang === "Zh"
                                  ? "中文 (Zh)"
                                  : lang === "Ja"
                                    ? "日本語 (Ja)"
                                    : lang === "Ko"
                                      ? "한국어 (Ko)"
                                      : null;
                        return (
                          <Button
                            key={lang}
                            disabled={!file[`voiceNoteIn${lang}`]}
                            onClick={() => {
                              setVoicenoteLang(lang.toLowerCase());
                              setVoicenoteUrl(file[`voiceNoteIn${lang}`]);
                            }}
                            sx={{
                              color:
                                voicenoteLang === lang.toLowerCase()
                                  ? "black"
                                  : "primary.main",
                            }}
                          >
                            {label}
                          </Button>
                        );
                      })}
                    </Typography>
                  </Box>
                </div>
                <Box
                  sx={{
                    border: "none",
                    borderRadius: "8px",
                    mb: "25px",
                    p: "15px",
                    width: "fit-content",
                  }}
                >
                  <MaterialUIAudioPlayer src={voicenoteUrl || file.url} />{" "}
                </Box>
                <Button
                  color="primary"
                  onClick={handleClose}
                  variant="contained"
                >
                  Close
                </Button>
              </Paper>
            </Modal>
          ) : null}
          <AudioPlayer url={file.url} />
          <Typography
            fontSize="10px"
            fontWeight="light"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {file.name}
          </Typography>
        </Box>
      );
    }

    return (
      <Box
        component="div"
        onClick={() => setInitialIndex(index)}
        sx={{ maxWidth: "180px", cursor: "pointer" }}
      >
        <img
          src={sourceImage}
          alt={fileName}
          width="100%"
          height="110px"
          style={{ objectFit: "contain" }}
        />
        <Tooltip title={fileName}>
          <Typography
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {fileName}
          </Typography>
        </Tooltip>
      </Box>
    );
  };

  function getRisk(selectedRisk) {
    const risks = [
      {
        label: tDailyReports("highRisk"),
        value: "0",
        color: "#FFC62B",
      },
      {
        label: tDailyReports("mediumRisk"),
        value: "1",
        color: "#FCF18C",
      },
      {
        label: tDailyReports("lowRisk"),
        value: "2",
        color: "#C0E5D1",
      },
    ];
    const risk = risks.find((risk) => risk.value === selectedRisk);

    return (
      <Chip
        label={risk.label}
        sx={[
          {
            fontWeight: "medium",
            fontSize: "1rem",
            minWidth: "92px",
            backgroundColor: risk.color,
          },
        ]}
      />
    );
  }

  return (
    <Box height="100%" p="8px 16px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={1}
      >
        <Typography display="flex" alignItems="center" color="#1F1F1F">
          <LocationOn sx={{ color: "#E6B60C" }} />
          {location.path}
        </Typography>

        <Typography fontSize="12px" fontWeight="400" color="#8E8E8E">
          {moment(topic.createDate)
            .tz(currentProject?.projectTimezone)
            .format("H:mm, DD, MMM, YY")}
        </Typography>
      </Box>

      <Box pb={2}>
        <Typography color="#4B4B4B">{topic.title}</Typography>

        {category === 3 && (
          <Box
            display="flex"
            columnGap={1}
            alignItems="center"
            py={1}
            borderBottom="1px solid black"
          >
            <ErrorOutline sx={{ color: "#FF7300" }} />
            <Typography fontSize="16px" fontWeight="500">{`${tDailyReports(
              "risk",
            )}`}</Typography>
            {location.risk !== null ? getRisk(location.risk) : "N/A"}
          </Box>
        )}

        {category === 4 && (
          <Box
            display="flex"
            columnGap={1}
            alignItems="center"
            py={1}
            borderBottom="1px solid black"
          >
            <Update sx={{ color: "#CB6CBE" }} />
            <Typography fontSize="16px" fontWeight="500">{`${tDailyReports(
              "delay",
            )}`}</Typography>
            <Typography fontSize="14px" fontWeight="medium">
              {(delayDays > 0
                ? `${delayDays} ${
                    delayDays > 1 ? tGeneral("days") : tGeneral("days")
                  }, `
                : "") +
                (delayHours > 0
                  ? `${delayHours} ${
                      delayHours > 1 ? tGeneral("hours") : tGeneral("hour")
                    }.`
                  : "")}
            </Typography>
          </Box>
        )}
      </Box>

      {hasFiles && (
        <Box
          display="flex"
          flexDirection="column"
          maxHeight="40%"
          overflow="hidden"
        >
          <Box
            display="grid"
            sx={{
              gridTemplateColumns: "1fr 1fr 1fr",
              gridTemplateRows: "150px",
              maxHeight: "150px",
              overflowY: "hidden",
            }}
            columnGap="16px"
            rowGap="16px"
          >
            {topic?.files?.map((item, index) =>
              renderFiles(item, index, (topic?.files?.length ?? 0) - 3),
            )}
          </Box>
        </Box>
      )}

      <Typography
        fontSize="12px"
        fontWeight="400"
        color="#8E8E8E"
        p={1}
        sx={{
          textDecoration: "underline",
          display: "flex",
          alignItems: "center",
          gap: "2px",
        }}
      >
        <Chat
          sx={{
            color: "#E6B60C",
            fontSize: "16px",
          }}
        />{" "}
        {comments?.length ?? 0} {tGeneral("comments")}
      </Typography>

      <Divider sx={{ py: "4px" }} />

      {!disabled && (
        <CommentReply
          placeholder={`${tGeneral("newComment")}`}
          reply={commentReport}
          isLoading={isCommentLoading}
          disabled={disabled}
        />
      )}

      <Divider />

      <Box pt={2}>
        {comments?.map((comment) => (
          <CommentItem key={comment.id} comment={comment} />
        ))}
      </Box>

      {initialIndex !== null && (
        <ModalCarouselFileViewer
          isOpen={initialIndex !== null}
          onClose={() => setInitialIndex(null)}
          files={topic?.files}
          initialIndex={initialIndex}
          location={location}
          topic={topic}
        />
      )}
    </Box>
  );
}

export { ReportLocationDescription };
