import { Close, Save, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  useGetProjectConfigurationQuery,
  useGetProjectUsersQuery,
  useUpdateProjectConfigMutation,
} from "../../../features/project/projectApiSlice";
import { PopUpAlert } from "../../PopUpAlert";
import BubbleAvatarDeletable from "../../userAvatars/BubbleAvatarDeletable";
import UserAvatar from "../../userAvatars/UserAvatar";
import { TooltipIconButton } from "../../shared/TooltipIconButton";

function ModalSettingsSubmittals({ isOpen, onClose }) {
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const [distributionList, setDistributionList] = useState([]);
  const [delayDays, setDelayDays] = useState(0);

  const { data: usersData } = useGetProjectUsersQuery(idProject);

  const { data: projectConfig } = useGetProjectConfigurationQuery(idProject);
  const [updateConfig, { isLoading, isSuccess, isError, error, reset }] =
    useUpdateProjectConfigMutation();

  const mapUserItems = (user) => ({
    label: `${user?.firstName} ${user?.lastName}`,
    id: user?.id,
    name: `${user?.firstName} ${user?.lastName}`,
    urlAvatar: user?.urlAvatar,
  });

  const projectUsers = useMemo(() => {
    if (usersData) {
      return usersData.map((user) => mapUserItems(user.user));
    }
    return [];
  }, [usersData]);

  useEffect(() => {
    if (projectConfig && usersData) {
      let selectedUsers = usersData?.filter(
        (user) =>
          projectConfig["submittalsDistributionList"]?.indexOf(user?.user?.id) >
          -1,
      );
      setDistributionList(selectedUsers.map((user) => mapUserItems(user.user)));
      setDelayDays(projectConfig["submittalsDefaultDeliveryDays"] ?? 0);
    }
  }, [projectConfig, usersData]);

  const handleOnDelete = (val) => {
    setDistributionList((prev) => prev.filter((user) => user.id !== val.id));
  };

  const handleOnSave = () => {
    updateConfig({
      idProject,
      submittalsDistributionList: distributionList.map((user) => user.id),
      submittalsDefaultDeliveryDays: delayDays ? delayDays : 0,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert(
        "success",
        tGeneral("success"),
        tGeneral("projectConfigurationUpdateSuccess"),
      );
      reset();
      onClose();
    }
    if (isError) {
      PopUpAlert("error", "Error", error.message);
      reset();
      onClose();
    }
  }, [isSuccess, isError, error, tGeneral, onClose, reset]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={(theme) => ({
          height: "auto",
          borderRadius: "20px",
          p: "25px 40px",
          width: "30%",
          [theme.breakpoints.down("lg")]: {
            width: "50%",
          },
        })}
      >
        <Box display="flex" alignItems="center">
          <Typography sx={{ flex: 1 }} fontSize="1.5625rem" fontWeight="bold">
            {tGeneral("settings")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close fontSize="1.5625rem" />
          </IconButton>
          <TooltipIconButton
            icon={<Save />}
            label={tGeneral("save")}
            onClick={handleOnSave}
            data-tour="settings-submittal-4"
          />
        </Box>
        <Divider sx={{ my: "20px" }} />
        <TextField
          data-tour="settings-submittal-2"
          label={tGeneral("days")}
          value={delayDays}
          placeholder={tGeneral("days")}
          onChange={(e) => setDelayDays(e.target.value)}
          fullWidth
          type="number"
          sx={{ mb: "20px" }}
        />
        <Box data-tour="settings-submittal-3">
          <Autocomplete
            multiple
            options={projectUsers}
            disableCloseOnSelect
            value={distributionList}
            isOptionEqualToValue={(opt, val) => val.id === opt.id}
            onChange={(e, val) => setDistributionList(val)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} />
                <UserAvatar name={option.label} url={option.urlAvatar} />
                <Typography>{option.label}</Typography>
              </li>
            )}
            renderInput={(params) => (
              <Box ref={params.InputProps.ref} data-tour="settings-submittal-2">
                <TextField
                  fullWidth
                  label={tGeneral("distributionList")}
                  sx={{ padding: 0, height: "55px" }}
                  inputProps={{...params.inputProps}}
                  InputProps={{
                    endAdornment: <Search />,
                    sx: { height: "55px", padding: "14px !important" },
                  }}
                />
              </Box>
            )}
          />
          <Box
            sx={{
              maxHeight: "250px",
              height: "250px",
              overflow: "auto",
              mt: "16px",
            }}
          >
            {distributionList.length ? (
              distributionList?.map((user) => (
                <BubbleAvatarDeletable
                  key={user.id}
                  user={user}
                  editable // To show delete button
                  setValues={() => {
                    handleOnDelete(user);
                  }}
                />
              ))
            ) : (
              <Typography
                fontSize="1rem"
                fontWeight="medium"
                textAlign="center"
              >
                {tGeneral("noUsersSelected")}
              </Typography>
            )}
          </Box>
        </Box>
      </Card>
    </Modal>
  );
}

export { ModalSettingsSubmittals };
