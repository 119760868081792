import { Close, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useGetProjectUsersQuery } from "../../../features/project/projectApiSlice";
import { TooltipIconButton } from "../../shared";
import UserAvatar from "../../userAvatars/UserAvatar";
import { verifyEmail } from "../../../utils";
import BubbleAvatarDeletable from "../../userAvatars/BubbleAvatarDeletable";
import { useSendEmailDrawingMutation } from "../../../features/drawings";
import { useGetUserQuery } from "../../../features/enterprise/enterpriseApiSlice";
import { PopUpAlert } from "../../PopUpAlert";

function ModalDrawingEmail({ isOpen, onClose, drawing }) {
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");

  const [cc, setCc] = useState("");
  const [ccError, setCcError] = useState(null);

  const { watch, setValue, getValues, handleSubmit } = useForm({
    defaultValues: {
      distributionList: [],
      cc: [],
      format: "all",
      revision: [],
      message: "",
    },
  });

  const { data: projectUsers } = useGetProjectUsersQuery(idProject);
  const { data: currentUser } = useGetUserQuery();
  const [sendEmail, { isLoading, isSuccess, isError, error }] =
    useSendEmailDrawingMutation();

  const mapUserItems = (user) => ({
    ...user,
    label: `${user.user?.firstName} ${user.user?.lastName}`,
    id: user.user?.id,
    name: `${user.user?.firstName} ${user.user?.lastName}`,
    urlAvatar: user.user?.urlAvatar,
    email: user?.user?.email,
  });

  const optionUsers = useMemo(() => {
    if (projectUsers) {
      return projectUsers.map(mapUserItems);
    }
    return [];
  }, [projectUsers]);

  const handleAddCc = (cc) => {
    const ccMails = getValues("cc");
    if (ccMails.indexOf(cc) > -1) {
      setCc("");
      return;
    }
    if (verifyEmail(cc)) {
      setValue("cc", [...ccMails, cc]);
    } else {
      setCcError(tGeneral("invalidEmail"));
    }
    setCc("");
  };

  const handleSetCc = ({ target: { value } }) => {
    setCcError(null);
    if (!value.match(/;/)) {
      setCc(value);
      return;
    }
    const newCc = value.replace(";", "");
    handleAddCc(newCc);
  };

  const distributionList = watch("distributionList");

  const handleDeleteUser = (user) => {
    const newDistributionList = [...distributionList].filter(
      (selectedUser) => selectedUser.id !== user.id,
    );
    setValue("distributionList", newDistributionList);
  };

  const ccMails = watch("cc");

  const handleDeleteCc = (mail) => {
    const newCcMails = ccMails.filter((m) => m !== mail);
    setValue("cc", newCcMails);
  };

  const handleChangeRevision = (e) => {
    if (e.target.value.includes("all")) {
      setValue(
        "revision",
        drawing.revisions.map((r) => r.id),
      );
    } else {
      setValue("revision", e.target.value);
    }
  };

  const revision = watch("revision");

  const onSubmit = (data) => {
    const revisisionsWithFormat = data.revision.map((r) => ({
      id: r,
      pdf: data.format === "all" || data.format === "pdf",
      dwg: data.format === "all" || data.format === "dwg",
    }));
    sendEmail({
      revisions: revisisionsWithFormat,
      to: [...data.distributionList.map((u) => u.email)],
      cc: data.cc,
      text: data.message,
      subject: drawing.name,
      drawingId: drawing.id,
      from: currentUser.email,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert("success", tGeneral("sent"), tGeneral("sentMessage"));
      onClose();
    }
    if (isError) {
      PopUpAlert("error", tGeneral("error"), error.message);
    }
  }, [isSuccess, isError, error, onClose, tGeneral]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Card
        sx={{
          p: "1.5rem 1rem",
          borderRadius: "20px",
          width: "30%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" alignItems="center">
            <Typography fontSize="1.25rem" fontWeight="500">
              {tGeneral("sendEmail")}
            </Typography>
            <Box flex={1} />
            <TooltipIconButton
              icon={<Close />}
              disabled={isLoading}
              color="#8E8E8E"
              onClick={onClose}
              label={tGeneral("close")}
            />
          </Box>
          <Divider sx={{ my: "1rem" }} />
          <Box display="flex" flexDirection="column" rowGap="1rem">
            <Autocomplete
              size="small"
              multiple
              disabled={isLoading}
              options={optionUsers}
              disableCloseOnSelect
              value={watch("distributionList")}
              isOptionEqualToValue={(opt, val) => val.id === opt.id}
              onChange={(e, val) => setValue("distributionList", val)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  <UserAvatar name={option.label} url={option.urlAvatar} />
                  <Typography>{option.label}</Typography>
                </li>
              )}
              renderInput={(params) => (
                <Box ref={params.InputProps.ref}>
                  <TextField
                    fullWidth
                    label={tGeneral("distributionList")}
                    sx={{ borderRadius: "1rem" }}
                    inputProps={{...params.inputProps}}
                    InputProps={{
                      endAdornment: <Search />,
                      sx: { height: "55px", padding: "14px !important" },
                    }}
                  />
                </Box>
              )}
            />
            <Box
              sx={{
                overflow: "auto",
                my: "8px",
                height: "100px",
                maxHeight: "100px",
              }}
            >
              {distributionList.length ? (
                distributionList?.map((user) => (
                  <BubbleAvatarDeletable
                    key={user.id}
                    user={user}
                    editable // To show delete button
                    setValues={() => {
                      handleDeleteUser(user);
                    }}
                  />
                ))
              ) : (
                <Typography
                  fontSize="1rem"
                  fontWeight="medium"
                  textAlign="center"
                >
                  {tGeneral("noUsersSelected")}
                </Typography>
              )}
            </Box>
            <Box>
              <Typography fontSize="0.75rem" sx={{ color: "rgba(0,0,0,0.6)" }}>
                {tGeneral("ccInstruction")}
              </Typography>
              <TextField
                disabled={isLoading}
                sx={{ mt: "8px" }}
                fullWidth
                placeholder="CC"
                value={cc}
                onChange={handleSetCc}
                error={Boolean(ccError)}
                helperText={ccError || ""}
                onKeyDownCapture={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleAddCc(e.target.value);
                  }
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Box>
            <Box
              height="50px"
              display="flex"
              alignItems="center"
              sx={{ overflowX: "auto" }}
              columnGap="5px"
            >
              {ccMails.map((mail) => (
                <Chip
                  key={mail}
                  label={mail}
                  onDelete={() => handleDeleteCc(mail)}
                />
              ))}
            </Box>
            <Box display="flex" columnGap="1rem">
              <FormControl fullWidth size="small">
                <InputLabel>{tGeneral("format")}</InputLabel>
                <Select
                  disabled={isLoading}
                  fullWidth
                  value={watch("format")}
                  onChange={(e) => setValue("format", e.target.value)}
                  label={tGeneral("format")}
                  size="small"
                  sx={{ borderRadius: "1rem" }}
                >
                  <MenuItem value="all">{tGeneral("all")}</MenuItem>
                  <MenuItem value="pdf">{tGeneral("pdf")}</MenuItem>
                  <MenuItem value="dwg">{tGeneral("dwg")}</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth size="small">
                <InputLabel>{tDrawings("revision")}</InputLabel>
                <Select
                  fullWidth
                  disabled={isLoading}
                  value={revision ?? []}
                  multiple
                  onChange={handleChangeRevision}
                  label={tGeneral("revision")}
                  size="small"
                  sx={{ borderRadius: "1rem" }}
                  renderValue={(selected) =>
                    selected.length > 0
                      ? `${tDrawings("revision")} (${selected.length})`
                      : null
                  }
                >
                  <MenuItem value="all">
                    <Checkbox
                      checked={revision.length === drawing.revisions.length}
                    />
                    <ListItemText>{tGeneral("all")}</ListItemText>
                  </MenuItem>
                  {drawing.revisions.map((r) => (
                    <MenuItem value={r.id} key={r.id}>
                      <Checkbox checked={revision.indexOf(r.id) > -1} />
                      <ListItemText>{r.number}</ListItemText>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <FormControl>
              <InputLabel shrink={Boolean(watch("message"))}>
                {tGeneral("message")}
              </InputLabel>
              <TextField
                multiline
                label={tGeneral("message")}
                value={watch("message")}
                onChange={(e) => setValue("message", e.target.value)}
                rows={4}
                InputProps={{ sx: { borderRadius: "1rem" } }}
                disabled={isLoading}
              />
            </FormControl>
            <Box display="flex" justifyContent="flex-end">
              <Button
                sx={{
                  borderRadius: "1rem",
                  background:
                    "linear-gradient(90deg, #FBCB04 0%, #E5E900 100%)",
                  textTransform: "none",
                }}
                type="submit"
                variant="contained"
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={16} />
                ) : (
                  tGeneral("sendEmail")
                )}
              </Button>
            </Box>
          </Box>
        </form>
      </Card>
    </Modal>
  );
}

export { ModalDrawingEmail };
