import { Clear, Close, Search } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { ConfigProvider, DatePicker } from "antd";
import { useState, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectTheme } from "../../../../features/preferences/preferencesSlice";
import { MenuProps, filterOptions, filterRecentOptions } from "../config";
import { SearchUserFilter } from "../../../../components/shared/SearchUserFilter";

const { RangePicker } = DatePicker;

function DocumentFilters(props) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDocuments } = useTranslation("documents");
  const { paramsDocs, setParamsDocs, userList } = props;
  // Theme / Styling
  const darkMode = useSelector(selectTheme);
  const styles = {
    secondaryFilter: {
      minWidth: "200px",
      verticalAlign: "center",
    },
    inputTitle: {
      backgroundColor: darkMode ? "" : "transparent",
      borderRadius: 2,
    },
    selectInput: {
      backgroundColor: darkMode ? "" : "#FFF5DB",
    },
  };

  const projectUsers = useMemo(() => {
    if (userList) {
      return userList.map((user) => ({
        id: user.idUser,
        name: user.name,
        urlAvatar: user.urlAvatar,
      }));
    }
    return [];
  }, [userList]);

  function adjustTime(utcTime) {
    const tzOffset = utcTime.getTimezoneOffset() * 60000;
    const localTime = new Date(utcTime.getTime() + tzOffset);
    return localTime;
  }

  function startAndEndOfWeek(date) {
    // If no date object supplied, use current date
    // Copy date so don't modify supplied date
    const now = date ? new Date(date) : new Date();
    // set time to some convenient value
    now.setHours(0, 0, 0, 0);
    // Get the previous Monday
    const monday = new Date(now);
    monday.setDate(monday.getDate() - monday.getDay() + 1);
    // Get next Sunday
    const sunday = new Date(now);
    sunday.setDate(sunday.getDate() - sunday.getDay() + 7);
    // Return array of date objects
    return [monday, sunday];
  }

  function startAndEndOfLastWeek(date) {
    // If no date object supplied, use current date
    // Copy date so don't modify supplied date
    const now = date ? new Date(date) : new Date();
    // Get current week
    const currentWeekDates = startAndEndOfWeek(now);
    // Create Dates
    const prevMonday = currentWeekDates[0];
    prevMonday.setDate(prevMonday.getDate() - 7);
    const prevSunday = currentWeekDates[1];
    prevSunday.setDate(prevSunday.getDate() - 7);
    // Return array of date
    return [prevMonday, prevSunday];
  }

  function startAndEndOfMonth(date) {
    // If no date object supplied, use current date
    // Copy date so don't modify supplied date
    const now = date ? new Date(date) : new Date();
    const firstDayMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDayMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    return [firstDayMonth, lastDayMonth];
  }

  const [search, setSearch] = useState("");
  const timerIdRef = useRef(null);

  // Project and user settings
  const [currentFilter, setCurrentFilter] = useState();
  const [createdByList, setCreatedByList] = useState([]);
  const [createdOn, setCreatedOn] = useState(null);
  const [recentList, setRecentList] = useState([]);
  const filterDocuments = (value, filter) => {
    const params = { ...paramsDocs };
    switch (filter) {
      case "createdBy":
        setCreatedByList(value);
        if (value.length > 0) params.createdBy = value;
        else params.createdBy = undefined;

        if (currentFilter === "createdBy") setCurrentFilter();
        break;

      case "createdOn":
        setCreatedOn(value);
        if (value) {
          params.createdOn = [
            adjustTime(new Date(value[0])).toString(),
            adjustTime(new Date(value[1])).toString(),
          ];
        } else {
          params.createdOn = undefined;
        }
        if (currentFilter === "createdOn") setCurrentFilter();
        break;

      case "recent":
        setRecentList(value);
        if (value.length > 0) {
          params.recent = value;
        } else params.recent = undefined;

        if (currentFilter === "recent") setCurrentFilter();
        break;

      case "search":
        params[filter] = value ?? null;
        break;

      default:
        break;
    }
    setParamsDocs(params);
  };

  const handleChangeSearch = (e) => {
    const {
      target: { value },
    } = e;
    setSearch(value);
    clearTimeout(timerIdRef.current);
    timerIdRef.current = setTimeout(() => {
      filterDocuments(e.target.value, "search");
    }, 500);
  };

  return (
    <Box sx={{ flexGrow: 1, p: "10px", display: "flex", columnGap: "10px" }}>
      <Box display="flex" columnGap="10px" flex={1}>
        <FormControl size="small" fullWidth sx={{ maxWidth: "200px" }}>
          <InputLabel id="add-filter-label" shrink={false}>
            {tGeneral("addFilter")}
          </InputLabel>
          <Select
            labelId="add-filter-label"
            id="add-filter-select"
            defaultValue=""
            value={currentFilter === undefined ? "" : currentFilter}
            onChange={(e) => {
              setCurrentFilter(e.target.value);
            }}
            autoWidth
            displayEmpty
            // label="Add filter"
            renderValue={(selected) => null}
            MenuProps={MenuProps}
          >
            {filterOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {tDocuments(option.label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Created by Select */}
        {(currentFilter === "createdBy" || createdByList.length > 0) && (
          <SearchUserFilter
            title={tGeneral("createdBy")}
            userList={projectUsers}
            selectedUsersList={projectUsers.filter(
              (user) =>
                createdByList.findIndex(
                  (creatorUser) => creatorUser === user.id,
                ) > -1,
            )}
            selectedUsersFormatted={createdByList}
            handleUpdateUsersList={(list) => {
              filterDocuments(
                list.length > 0 ? list.map((user) => user.id) : [],
                "createdBy",
              );
            }}
            selectProps={{
              ml: 1,
              width: "200px",
            }}
          />
        )}

        {/* Created on Select */}
        {(currentFilter === "createdOn" || createdOn) && (
          <FormControl size="small" sx={styles.secondaryFilter}>
            <Box
              sx={{
                height: 56,
                borderRadius: 1,
                ml: 1,
                mt: 1,
                backgroundColor: darkMode ? "" : "#FFF5DB",
                border: "solid",
                borderWidth: 1,
                borderColor: "rgba(0, 0, 0, 0.2)",
                maxWidth: 300,
                display: "flex",
              }}
            >
              <IconButton
                onClick={() => filterDocuments(null, "createdOn")}
                sx={{
                  ml: 1,
                  height: 24,
                  width: 24,
                  p: 2,
                  mt: "auto",
                  mb: "auto",
                }}
              >
                <Close />
              </IconButton>
              <ConfigProvider
                theme={{
                  components: {
                    DatePicker: {
                      colorPrimary: "#FBCB04",
                    },
                  },
                }}
              >
                <RangePicker
                  size="large"
                  bordered={false}
                  onChange={(dates, datesStrings) => {
                    filterDocuments(datesStrings, "createdOn");
                  }}
                  placeholder={[tGeneral("startDate"), tGeneral("endDate")]}
                  // value={[dayjs(), dayjs()]}
                />
              </ConfigProvider>
            </Box>
          </FormControl>
        )}

        {/* Recent Select */}
        {(currentFilter === "recent" || recentList.length > 0) && (
          <FormControl size="small" sx={styles.secondaryFilter}>
            <InputLabel id="recentInput" sx={styles.inputTitle}>
              {recentList.length > 0 ? "" : tGeneral("recent")}
            </InputLabel>
            <Select
              labelId="recentInput"
              id="recentInput-select"
              multiple
              onChange={(e) => {
                filterDocuments(e.target.value, "recent");
              }}
              value={recentList}
              startAdornment={
                <IconButton
                  size="small"
                  onClick={(e) => {
                    filterDocuments([], "recent");
                  }}
                >
                  <Clear />
                </IconButton>
              }
              input={
                <OutlinedInput
                  label={recentList.length > 0 ? "" : tGeneral("recent")}
                />
              }
              renderValue={(selected) =>
                recentList.length > 0
                  ? `${tDocuments("recentDocument")} (${recentList.length})`
                  : tGeneral("recentDocument")
              }
              MenuProps={MenuProps}
              sx={styles.selectInput}
            >
              {filterRecentOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox
                    checked={recentList.indexOf(option.value) > -1}
                    sx={{
                      color: "black",
                      ":checked": {
                        color: "#FBCB04",
                      },
                      borderColor:
                        recentList.indexOf(option.value) > -1
                          ? "#f7e4b2"
                          : "black",
                    }}
                  />
                  {tGeneral(option.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      <TextField
        size="small"
        value={search}
        onChange={handleChangeSearch}
        placeholder={tGeneral("search")}
        InputProps={{ startAdornment: <Search /> }}
      />
    </Box>
  );
}

export default DocumentFilters;
