import { Delete, Edit, Lock, LockOpen } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledMaterialReactTable, TooltipIconButton } from "../shared";
import { ModalEditDrawing } from "./ModalEditDrawing";
import { DrawingsReviewFilters } from "./DrawingsReviewFilters";
import Can from "../../utils/can";

function DrawingsToPublish({
  data,
  selected,
  onSelectionChange,
  onDelete,
  isLoading,
  filters,
  setFilters,
}) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");
  const [pageSize, setPageSize] = useState(10);
  const [editingId, setEditingId] = useState(null);
  const columns = [
    {
      header: "Priv.",
      accessorKey: "bPrivate",
      Cell: ({ cell }) => (cell.getValue() ? <Lock /> : <LockOpen />),
      size: 30,
    },
    {
      header: "ID",
      accessorKey: "idAux",
      Cell: ({ cell }) => (
        <Button
          variant="text"
          color="info"
          sx={{ p: 0, display: "flex", justifyContent: "flex-start" }}
        >
          {cell.getValue()}
        </Button>
      ),
      size: 50,
    },
    {
      header: tGeneral("name"),
      accessorKey: "name",
      size: 120,
    },
    {
      header: "#Rev",
      accessorKey: "number",
      size: 65,
    },
    {
      header: `${tGeneral("name")} Set`,
      accessorKey: "setName",
      size: 100,
    },
    {
      header: tDrawings("discipline"),
      accessorKey: "disciplineName",
      size: 100,
    },
  ];
  return (
    <Box>
      <StyledMaterialReactTable
        getRowId={(row) => row.id}
        data={data}
        columns={columns}
        enableRowSelection
        selectedRows={selected}
        setSelectedRows={onSelectionChange}
        isLoading={isLoading}
        filtersComponent={
          <DrawingsReviewFilters
            filters={filters}
            onChange={(val) => setFilters(val)}
          />
        }
        renderRowActions={({ row }) => (
          <Box display="flex">
            <TooltipIconButton
              icon={<Edit />}
              label={tGeneral("edit")}
              onClick={() => setEditingId(row.original?.drawingId)}
              color="black"
            />
            <Can I="delete" a="drawings">
              <TooltipIconButton
                icon={<Delete />}
                label={tGeneral("delete")}
                onClick={() => onDelete(row.id)}
                color="red"
              />
            </Can>
          </Box>
        )}
      />
      {Boolean(editingId) && (
        <ModalEditDrawing
          isOpen={Boolean(editingId)}
          onClose={() => setEditingId(null)}
          idDrawing={editingId}
        />
      )}
    </Box>
  );
}

export { DrawingsToPublish };
