import { Upload } from "@mui/icons-material";
import { Box, Button, IconButton, TextField } from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { FilePreviewHorizontalScroll } from "../../../../../components/shared";
import { ReportTopicItem } from "./ReportTopicItem";
import Voicenote from "../../../../../components/Voicenote";

function ReportTopics({ topics, onEditTopic, onAddTopic, onDeleteTopic, category }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDailyReports } = useTranslation("dailyReports");

  const inputRef = useRef(null);

  const [files, setFiles] = useState([]);
  const [newTopic, setNewTopic] = useState("");

  const handleAddTopic = (e) => {
    e.stopPropagation();
    onAddTopic({
      title: newTopic,
      files,
      createDate: new Date(),
      id: uuidv4(),
      order: topics.length + 1,
    });
    setNewTopic("");
    setFiles([]);
  };

  const handleOpenAddFiles = (e) => {
    e.stopPropagation();
    inputRef.current.click();
  };

  const handleAddFiles = (e) => {
    setFiles([...files, ...e.target.files]);
  };

  const handleOnDeleteFile = (file, i) => {
    const newFiles = [...files];
    newFiles.splice(i, 1);
    setFiles(newFiles);
  };

  const handleSetNewTopic = (e) => {
    const { value } = e.target;
    setNewTopic(value);
  };

  return (
    <Box
      data-tour="edit-dailyReport-11"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {topics.map((topic, index) => (
        <ReportTopicItem
          key={topic.id}
          topic={topic}
          onEditTopic={onEditTopic}
          onDeleteTopic={onDeleteTopic}
        />
      ))}
      {files.length > 0 && (
        <FilePreviewHorizontalScroll
          files={files}
          onAddPhotos={(files) => setFiles(files)}
          onDelete={handleOnDeleteFile}
        />
      )}
      {((category === 3 && topics.length === 0) || category !== 3)&&
        <TextField
          onClick={(e) => e.stopPropagation()}
          value={newTopic}
          onChange={handleSetNewTopic}
          size="small"
          fullWidth
          multiline
          placeholder={tDailyReports("addDescription")}
          data-tour="add-dailyReport-12"
          InputProps={{
            sx: { backgroundColor: "white", flex: 1, minHeight: category === 3 ? "150px" : "auto" },
            endAdornment: (
              <Box display="flex" columnGap="10px">
                <input
                  type="file"
                  multiple
                  ref={inputRef}
                  onChange={handleAddFiles}
                  style={{ display: "none" }}
                />
                <Box data-tour="add-dailyReport-13" display="flex">
                  <Voicenote
                    submitVoicenote={false}
                    handleVoicenote={(file) => setFiles((Files) => [...Files, file])}
                  />
                  <IconButton sx={{ p: 0, m: 0 }} onClick={handleOpenAddFiles}>
                    <Upload />
                  </IconButton>
                </Box>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ textTransform: "none", p: 0, m: 0 }}
                  onClick={handleAddTopic}
                  disabled={newTopic === ""}
                >
                  {tGeneral("add")}
                </Button>
              </Box>
            ),
          }}
        />
      }
    </Box>
  );
}

export { ReportTopics };
