import { CheckCircle } from "@mui/icons-material";
import { Box, Button, Card, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useUpdateEnterpriseMutation } from "../../../features/enterprise/enterpriseApiSlice";
import { Plan } from "../../Onboarding";

function PlanSettings({ enterprise }) {
  const { t: tGeneral } = useTranslation("general");
  const { planType, planConcurrency, activeUsers, activeProjects } = enterprise;
  const currentPlan = {
    price: 27.5,
    activeObjects: 12,
    isUser: true,
    period: "annual",
    features: [
      tGeneral("allTheFeatures"),
      tGeneral("unlimitedProjects"),
      tGeneral("unlimitedStorage"),
      tGeneral("translationSpanglish"),
      tGeneral("companyReports"),
      tGeneral("customizableReports"),
    ],
  };

  const plans = {
    annual: {
      user: {
        price: 27.5,
        isUser: true,
        features: [
          tGeneral("allTheFeatures"),
          tGeneral("unlimitedProjects"),
          tGeneral("unlimitedStorage"),
          tGeneral("translationSpanglish"),
          tGeneral("companyReports"),
          tGeneral("customizableReports"),
        ],
        defaultActive: 1,
        bestValue: false,
      },
      project: {
        price: 475,
        isUser: false,
        features: [
          tGeneral("allTheFeatures"),
          tGeneral("unlimitedUsers"),
          tGeneral("unlimitedStorage"),
          tGeneral("translationSpanglish"),
          tGeneral("companyReports"),
          tGeneral("customizableReports"),
        ],
        defaultActive: 10,
        bestValue: true,
      },
    },
    month: {
      user: {
        price: 30,
        isUser: true,
        features: [
          tGeneral("allTheFeatures"),
          tGeneral("unlimitedProjects"),
          tGeneral("unlimitedStorage"),
          tGeneral("translationSpanglish"),
          tGeneral("companyReports"),
          tGeneral("customizableReports"),
        ],
        defaultActive: 1,
        bestValue: false,
      },
      project: {
        price: 500,
        isUser: false,
        features: [
          tGeneral("allTheFeatures"),
          tGeneral("unlimitedUsers"),
          tGeneral("unlimitedStorage"),
          tGeneral("translationSpanglish"),
          tGeneral("companyReports"),
          tGeneral("customizableReports"),
        ],
        defaultActive: 10,
        bestValue: true,
      },
    },
  };
  const { price, features } = plans[planConcurrency][planType] || {};
  const [isEditing, setIsEditing] = useState(false);
  const [updateEnterprise, { isLoading }] = useUpdateEnterpriseMutation();
  const handleUpdateEnterprise = (data, activeObjects) => {
    const [period, type] = data.id.split("-");
    const body = {
      planType: type,
      planConcurrency: period,
    };
    if (type === "enterprise") {
      body.projectsLimit = activeObjects;
    } else {
      body.usersLimit = activeObjects;
    }
    updateEnterprise(body);
  };

  const isUser = planType === "user";

  return isEditing ? (
    <Plan
      onBackClick={() => setIsEditing(false)}
      onButtonClick={handleUpdateEnterprise}
      isSettings
    />
  ) : (
    <Box>
      <Typography fontSize="1.563rem" fontWeight="bold">
        {tGeneral("currentPlan")}
      </Typography>
      <Box sx={{ backgroundColor: "#FAFAFA", p: "34px 30px" }}>
        <Card sx={{ borderRadius: "10px", p: "20px 28px", height: "430px" }}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography
                fontSize="2.875rem"
                lineHeight="2.875rem"
                fontWeight="bold"
              >
                ${price} USD
              </Typography>
              <Typography
                fontSize="1.125rem"
                lineHeight="1.125rem"
                fontWeight="500"
              >
                {tGeneral("per")}{" "}
                {tGeneral(planType === "project" ? "project" : "user")},{" "}
                {tGeneral("per")}{" "}
                {tGeneral(planConcurrency === "monthly" ? "month" : "year")}
              </Typography>
            </Box>
            <Box display="flex" columnGap="16px" alignItems="center">
              <Box
                display="flex"
                alignItems="center"
                p="6px 26px 5px 16px"
                columnGap="12px"
                width="117px"
                height="42px"
                sx={{ backgroundColor: "#FFEBBC", borderRadius: "21px" }}
              >
                <Typography
                  fontWeight="500"
                  fontSize="0.813rem"
                  lineHeight="0.813rem"
                >
                  {tGeneral(isUser ? "activeUsers" : "activeProjects")}
                </Typography>
                <Typography fontWeight="500" fontSize="1.375rem">
                  {isUser ? activeUsers : activeProjects}
                </Typography>
              </Box>
              <Box
                sx={{
                  border: "1px solid #FFC42D",
                  borderRadius: "8px",
                  p: "13px 35px",
                }}
              >
                <Typography
                  fontSize="1.563rem"
                  fontWeight="500"
                  sx={{ color: "#FFC42D" }}
                >
                  {tGeneral(
                    planConcurrency === "annual" ? "annual" : "monthly",
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Divider sx={{ mt: "20px", mb: "10px" }} />
          <Typography fontWeight="bold" mb="20px">
            {tGeneral("features")}
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            rowGap="30px"
            flexWrap="wrap"
            height="50%"
            sx={{
              "&>*": {
                flex: "1 1 auto",
              },
            }}
          >
            {features?.map((feature) => (
              <Box display="flex" alignItems="center">
                <CheckCircle color="primary" />
                <Typography ml="10px" fontSize="1.313rem">
                  {feature}
                </Typography>
              </Box>
            ))}
          </Box>
        </Card>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt="21px">
        <Button
          fullWidth
          variant="contained"
          sx={{ maxWidth: "270px", py: "16px" }}
          onClick={() => setIsEditing(true)}
        >
          {tGeneral("edit")}
        </Button>
      </Box>
    </Box>
  );
}

export { PlanSettings };
