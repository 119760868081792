import { Search } from "@mui/icons-material";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useGetActiveProjectUsersQuery } from "../../../features/project/projectApiSlice";
import { DateRangeFilter } from "../../shared/DateRangeFilter";
import { SearchUserFilter } from "../../shared/SearchUserFilter";

function MeetingsFilters({ setFilters }) {
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");

  const { data: projectUsersData } = useGetActiveProjectUsersQuery({
    idProject,
    moduleName: "meetings",
  });

  const [params, setParams] = useState({
    search: "",
    attendance: null,
    creator: null,
    createdOn: null,
  });
  const timerIdRef = useRef(null);

  const handleChange = (key, value) => {
    if (key !== "search") setParams((prev) => ({ ...prev, [key]: value }));
    setFilters({ ...params, [key]: value });
  };

  const handleAddFilter = (e) => {
    const {
      target: { value },
    } = e;
    handleChange(value, []);
  };

  const handleChangeSearch = (e) => {
    const {
      target: { value },
    } = e;
    setParams((prev) => ({ ...prev, search: value }));
    clearTimeout(timerIdRef.current);

    timerIdRef.current = setTimeout(() => {
      handleChange("search", value);
    }, 500);
  };

  const projectUsers = useMemo(() => {
    if (projectUsersData) {
      return projectUsersData;
    }
    return [];
  }, [projectUsersData]);

  return (
    <Box display="flex" columnGap="10px" p="10px">
      <Box flex={1} display="flex" columnGap="10px">
        <FormControl size="small" fullWidth sx={{ maxWidth: "250px" }}>
          <InputLabel>{tGeneral("addFilter")}</InputLabel>
          <Select
            label={tGeneral("addFilter")}
            value=""
            onChange={handleAddFilter}
          >
            <MenuItem value="attendance">{tGeneral("attendance")}</MenuItem>
            <MenuItem value="creator">{tGeneral("createdBy")}</MenuItem>
            <MenuItem value="createdOn">{tGeneral("createdOn")}</MenuItem>
          </Select>
        </FormControl>
        {params.attendance && (
          <SearchUserFilter
            title={tGeneral("attendance")}
            userList={projectUsers}
            selectedUsersList={projectUsers.filter(
              (user) =>
                params.attendance.findIndex(
                  (attendanceUser) => attendanceUser === user.id,
                ) > -1,
            )}
            selectedUsersFormatted={params.attendance}
            handleUpdateUsersList={(list) => {
              handleChange(
                "attendance",
                list.length > 0 ? list.map((user) => user.id) : null,
              );
            }}
            selectProps={{
              ml: 1,
              width: "200px",
            }}
          />
        )}
        {params.creator && (
          <SearchUserFilter
            title={tGeneral("createdBy")}
            userList={projectUsers}
            selectedUsersList={projectUsers.filter(
              (user) =>
                params.creator.findIndex(
                  (creatorUser) => creatorUser === user.id,
                ) > -1,
            )}
            selectedUsersFormatted={params.creator}
            handleUpdateUsersList={(list) => {
              handleChange(
                "creator",
                list.length > 0 ? list.map((user) => user.id) : null,
              );
            }}
            selectProps={{
              ml: 1,
              width: "200px",
            }}
          />
        )}
        {params.createdOn && (
          <DateRangeFilter
            onChange={(stringDates) => {
              handleChange("createdOn", stringDates);
            }}
          />
        )}
      </Box>
      <TextField
        size="small"
        value={params.search}
        onChange={handleChangeSearch}
        InputProps={{ startAdornment: <Search /> }}
        placeholder={tGeneral("search")}
      />
    </Box>
  );
}

export { MeetingsFilters };
