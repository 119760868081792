import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  submittals: [],
  current: null,
};

export const submittalSlice = createSlice({
  name: "submittal",
  initialState,
  reducers: {
    setSubmittals: (state, action) => {
      state.submittals = action.payload;
    },
    setCurrentSubmittal: (state, action) => {
      state.current = action.payload;
    },
    resetSubmittals: () => initialState,
  },
});

export const selectAllSubmittals = (state) => state.submittal.submittals;

export const selectCurrentSubmittal = (state) => state.submittal.current;

export const { setSubmittals, setCurrentSubmittal, resetSubmittals } =
  submittalSlice.actions;

export default submittalSlice.reducer;
