import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TodoListIcon from "../../../../assets/icons/modules/to-do-list.png";
import { useAddProjectTodoMutation } from "../../../../features/project/projectApiSlice";

function ModalAddTodoList({ isOpen, onClose, idProject, order }) {
  const { t: tGeneral } = useTranslation("general");
  const [name, setName] = useState("");
  const [limitDate, setLimitDate] = useState(
    moment(new Date()).add(3600000 * 24),
  );
  const [addTodo, { isLoading }] = useAddProjectTodoMutation();

  const handleAdd = async () => {
    await addTodo({ idProject, name, limitDate, order });
    handleClose();
  };

  const handleClose = () => {
    setName("");
    setLimitDate(moment(new Date()).add(3600000 * 24));
    onClose();
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}
    >
      <Card
        sx={{
          width: "25%",
          height: "550px",
          p: "30px 40px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          borderRadius: "20px",
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography fontSize="1.5625rem" fontWeight="bold">
            {tGeneral("addList")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <img
          src={TodoListIcon}
          alt="To do list"
          style={{ maxHeight: "127px", objectFit: "contain", margin: "25px 0" }}
        />
        <TextField
          fullWidth
          multiline
          rows={4}
          label={tGeneral("activityDesc")}
          sx={{ mb: "20px" }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <DatePicker
          label={tGeneral("expirationDate")}
          value={limitDate}
          onChange={(value) => setLimitDate(value)}
          renderInput={(params) => <TextField fullWidth {...params} required />}
        />
        <Box display="flex" columnGap="16px">
          <Button
            sx={{
              height: "55px",
              mt: "25px",
              backgroundColor: "white",
              color: "black",
            }}
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleClose}
            disabled={isLoading}
          >
            {tGeneral("cancel")}
          </Button>
          <Button
            sx={{ height: "55px", mt: "25px" }}
            fullWidth
            variant="contained"
            disabled={isLoading}
            onClick={handleAdd}
          >
            {isLoading ? (
              <CircularProgress color="secondary" />
            ) : (
              tGeneral("add")
            )}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}

export { ModalAddTodoList };
