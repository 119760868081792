import { Add, Close } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useLinkAnnotationMutation } from "../../features/annotations";
import { useGetObjectsByModuleQuery } from "../../features/project/projectApiSlice";
import { PopUpAlert } from "../PopUpAlert";
import TaskForm from "../../views/projectDashboard/Tasks/TaskForm";
import RfiForm from "../../views/projectDashboard/RfiForm";

const filter = createFilterOptions();

function ModalAnnotationLinkModule({ isOpen, onClose, annotation, idDrawing }) {
  const { idProject } = useParams();

  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");

  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedObject, setSelectedObject] = useState(null);
  const [isCreateNewOpen, setIsCreateNewOpen] = useState(false);
  const [createdId, setCreatedId] = useState(null);
  const [objectSearch, setObjectSearch] = useState("");

  const { data: objects } = useGetObjectsByModuleQuery(
    {
      module: selectedModule,
      idProject,
    },
    { skip: !selectedModule },
  );

  const [linkAnnotation, { isSuccess, isError, error, isLoading }] =
    useLinkAnnotationMutation();

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tDrawings("annotationLinkedMessage"),
      );
      onClose();
    }
    if (isError) {
      PopUpAlert("error", "Error", error.message);
      onClose();
    }
  }, [isSuccess, isError, error]);

  const handleLinkAnnotation = () => {
    linkAnnotation({
      idObject: selectedObject.id,
      moduleName: selectedModule,
      idAnnotation: annotation.id,
    });
  };

  useEffect(() => {
    if (annotation && annotation.linkedId) {
      setSelectedModule(annotation.linkedModule);
    }
  }, [annotation]);

  useEffect(() => {
    if (annotation && annotation.linkedId && selectedModule && objects) {
      const currObject = objects.find((obj) => obj.id === annotation.linkedId);
      setSelectedObject(currObject);
    }
  }, [selectedModule, annotation, objects]);

  const renderButtonContent = () => {
    if (isLoading) {
      return <CircularProgress color="primary" />;
    }
    if (annotation?.linkedId) {
      return tGeneral("save");
    }
    return tGeneral("add");
  };

  useEffect(() => {
    if (createdId && objects) {
      const createdObject = objects.find((obj) => obj.id === createdId);
      if (createdObject) {
        setSelectedObject(createdObject);
        setCreatedId(null);
      }
    }
  }, [createdId, objects]);

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Card
          p="32px 40px"
          sx={{
            width: "30%",
            borderRadius: "15px",
            p: "22px 30px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box display="flex">
            <Typography sx={{ flex: 1 }} fontSize="1.563rem" fontWeight="bold">
              {tGeneral("linkTo")}
            </Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
          <Divider sx={{ my: "20px" }} />
          <FormControl fullWidth>
            <InputLabel id="module-select-label">
              {tGeneral("module")}
            </InputLabel>
            <Select
              labelId="module-select-label"
              value={selectedModule}
              onChange={(e) => {
                setSelectedObject(null);
                setObjectSearch("");
                setSelectedModule(e.target.value);
              }}
              label={tGeneral("module")}
            >
              <MenuItem value="rfi">RFI's</MenuItem>
              <MenuItem value="task">{tGeneral("tasks")}</MenuItem>
            </Select>
          </FormControl>
          <Autocomplete
            disabled={!selectedModule}
            freeSolo
            options={objects ?? []}
            sx={{ my: "15px" }}
            onChange={(e, val) => setSelectedObject(val)}
            value={selectedObject}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              return [
                {
                  id: "addNew",
                  number: "0",
                  name: "addNew",
                },
                ...filtered,
              ];
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={tGeneral(selectedModule ?? "module")}
              />
            )}
            inputValue={objectSearch}
            onInputChange={(_, newVal) => {
              setObjectSearch(newVal);
            }}
            renderOption={(props, obj) =>
              obj.id === "addNew" ? (
                <Box
                  display="flex"
                  component="div"
                  onClick={() => setIsCreateNewOpen(true)}
                  sx={{
                    cursor: "pointer",
                    height: "30px",
                    px: "0.5rem",
                    columnGap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <Add />
                  <Typography>{`${tGeneral("add")} ${tGeneral(
                    "new",
                  )} ${tGeneral(selectedModule)}`}</Typography>
                </Box>
              ) : (
                <Typography
                  {...props}
                >{`#${obj.number} - ${obj.name}`}</Typography>
              )
            }
            getOptionLabel={(option) => `#${option.number} - ${option.name}`}
          />
          <Box flex={1} />
          <Box display="flex" columnGap="6px">
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={onClose}
              sx={{ py: "16px" }}
              disabled={isLoading}
            >
              {tGeneral("cancel")}
            </Button>
            <Button
              variant="contained"
              fullWidth
              sx={{ py: "16px" }}
              onClick={handleLinkAnnotation}
              disabled={isLoading}
            >
              {renderButtonContent()}
            </Button>
          </Box>
        </Card>
      </Modal>
      <Modal
        open={isCreateNewOpen}
        onClose={() => setIsCreateNewOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card sx={{ width: "80%" }}>
          {selectedModule && selectedModule === "rfi" ? (
            <RfiForm
              isModal
              onClose={() => setIsCreateNewOpen(false)}
              onCreate={(id) => setCreatedId(id)}
              idDrawing={idDrawing}
            />
          ) : (
            <TaskForm
              isModal
              onClose={() => setIsCreateNewOpen(false)}
              onCreate={(id) => setCreatedId(id)}
            />
          )}
        </Card>
      </Modal>
    </>
  );
}

export { ModalAnnotationLinkModule };
