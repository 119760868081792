import { Add, Delete, Edit, MoreVert } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const filter = createFilterOptions();

function MenuOption({ label, onMenuOpen, onEdit, onDelete, value, ...props }) {
  const { t: tGeneral } = useTranslation("general");
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Tooltip title={label}>
      <Box display="flex" alignItems="center" pl="1rem">
        {!value && <Add color="primary" />}
        <Typography
          flex={1}
          noWrap
          whiteSpace="none"
          textOverflow="ellipsis"
          component="li"
          {...props}
        >
          {label}
        </Typography>
        {(onEdit || onDelete) && value && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onMenuOpen(true);
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreVert />
          </IconButton>
        )}
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
            onMenuOpen(false);
          }}
        >
          <MenuItem onClick={() => onEdit(value, label)}>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText>
              <Typography>{tGeneral("edit")}</Typography>
            </ListItemText>
          </MenuItem>
          {/* <MenuItem onClick={onDelete}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText>
            <Typography>{tGeneral("delete")}</Typography>
          </ListItemText>
        </MenuItem> */}
        </Menu>
      </Box>
    </Tooltip>
  );
}

function AutocompleteCell({
  options,
  value,
  onChange,
  onCreate,
  isLoading,
  onEdit,
  onDelete,
  startAdornment,
  label,
  idTour,
}) {
  const { t: tGeneral } = useTranslation("general");
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOptionsOpen, setIsMenuOptionsOpen] = useState(false);

  const isAutocompleteOpen = isOpen || isMenuOptionsOpen;

  const selectedValue = value
    ? options?.find((option) => option?.value === value)?.label ?? null
    : null;

  if (!options) return null;

  return (
    <Autocomplete
      data-tour={idTour ? idTour : ""}
      value={selectedValue}
      size="small"
      loading={isLoading}
      open={isAutocompleteOpen}
      disableCloseOnSelect
      onFocus={() => setIsOpen(true)}
      onBlur={() => setIsOpen(false)}
      disabled={isLoading}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          onChange(newValue);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          onCreate(newValue.inputValue);
          setIsOpen(false);
        } else {
          onChange(newValue?.value);
          setIsOpen(false);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;

        return [
          {
            inputValue,
            label:
              inputValue.length > 0
                ? `${tGeneral("add")} "${inputValue}"`
                : tGeneral("typeToAdd"),
            disabled: inputValue.length === 0,
          },
          ...filtered,
        ];
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={options}
      getOptionKey={(option) => option.value}
      getOptionDisabled={(option) => option.disabled}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      renderOption={(props, option) => (
        <MenuOption
          label={option.label}
          onMenuOpen={(val) => setIsMenuOptionsOpen(val)}
          onDelete={onDelete}
          onEdit={onEdit}
          value={option.value}
          {...props}
        />
      )}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          onBlur={() => {}}
          sx={{ backgroundColor: "white" }}
          InputProps={{
            ...params.InputProps,
            endAdornment: isLoading ? (
              <CircularProgress size={15} />
            ) : (
              params.InputProps.endAdornment
            ),
            startAdornment: startAdornment ?? params.InputProps.startAdornment,
          }}
        />
      )}
    />
  );
}

export { AutocompleteCell };
