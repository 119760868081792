import Close from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../assets/documents/deleteIcon.png";

function DeleteModal({ isOpen, onClose, handleDelete }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tMeetings } = useTranslation("meetings");

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          width: "30%",
          height: "auto",
          borderRadius: "20px",
          p: "25px 40px",
        }}
      >
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="right"
          mb="15px"
        >
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Box sx={{ mt: "30px", mb: "45px", textAlign: "center" }}>
          <img src={DeleteIcon} alt="delete icon" height="100px" />
          <Typography variant="h6" fontWeight="bold" sx={{ m: 1 }}>
            {`${tMeetings("deleteMeeting")}`}
          </Typography>
          <Typography variant="body1" sx={{ m: 1 }}>
            {tMeetings("messageDelete")}
          </Typography>
        </Box>
        {/* Action Buttons */}
        <Box
          display="flex"
          justifyContent="space-around"
          mb="20px"
          columnGap="1rem"
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            sx={{
              backgroundColor: "white",
              color: "black",
              py: ".8rem",
              border: "1px solid black",
              ":hover": {
                backgroundColor: "lightgrey",
              },
            }}
            fullWidth
          >
            {tGeneral("cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleDelete();
            }}
            fullWidth
            sx={{ py: ".8rem" }}
          >
            {tGeneral("accept")}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}

export default DeleteModal;
