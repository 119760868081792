import { apiSlice } from "../../api/apiSlice";

const drawingsQuery = "project/drawing";

export const revisionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    approveRevision: builder.mutation({
      query: (body) => ({
        url: `${drawingsQuery}/approveRevision`,
        body,
        method: "PUT",
      }),
      invalidatesTags: ["Drawings"],
    }),
    publishRevisions: builder.mutation({
      query: (body) => ({
        url: `${drawingsQuery}/publishRevisions`,
        body,
        method: "PUT",
      }),
      invalidatesTags: ["Drawings"],
    }),
    deleteRevisions: builder.mutation({
      query: (body) => ({
        url: `${drawingsQuery}/deleteRevisions`,
        body,
        method: "DELETE",
      }),
      invalidatesTags: ["Drawings"],
    }),
    deleteLastRevisions: builder.mutation({
      query: (body) => ({
        url: `${drawingsQuery}/lastRevisions`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Drawings"],
    }),
    deleteAllRevisions: builder.mutation({
      query: (body) => ({
        url: `${drawingsQuery}/allRevisions`,
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Drawings"],
    }),
    restoreRevisions: builder.mutation({
      query: (body) => ({
        url: `${drawingsQuery}/restoreDrawing`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Drawings"],
    }),
    compareRevisions: builder.query({
      query: ({ idFirstRev, idSecondRev }) =>
        `${drawingsQuery}/compare/${idFirstRev}/${idSecondRev}`,
    }),
    assignIdAux: builder.mutation({
      query: (body) => ({
        url: `${drawingsQuery}/assignIdAux`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Drawings"],
    }),
    assignDiscipline: builder.mutation({
      query: (body) => ({
        url: `${drawingsQuery}/assignDiscipline`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Drawings", "Disciplines"],
    }),
    assignSet: builder.mutation({
      query: (body) => ({
        url: `${drawingsQuery}/assignSet`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Drawings", "Sets"],
    }),
    getRevision: builder.query({
      query: (id) => `${drawingsQuery}/revision/${id}`,
      providesTags: ["Drawings"],
    }),
  }),
});

export const {
  useApproveRevisionMutation,
  usePublishRevisionsMutation,
  useDeleteRevisionsMutation,
  useDeleteAllRevisionsMutation,
  useDeleteLastRevisionsMutation,
  useRestoreRevisionsMutation,
  useLazyCompareRevisionsQuery,
  useAssignIdAuxMutation,
  useAssignDisciplineMutation,
  useAssignSetMutation,
  useGetRevisionQuery,
} = revisionApiSlice;
