// @ts-check
/* eslint react/prop-types: 0 */

import RefreshIcon from "@mui/icons-material/Refresh";
import { Button } from "@mui/material";
// eslint-disable-next-line no-unused-vars
import React from "react";

export default function Refresh(props) {
  const { handleClick } = props;

  return (
    <Button
      color="secondary"
      onClick={handleClick}
      sx={{
        minWidth: "0",
      }}
      variant="text"
    >
      <RefreshIcon />
    </Button>
  );
}
