import { tour } from "./tour";

export const documents = {
  // general tour
  ...tour,
  moduleElement: "Documentos",
  // ADD FILE - guide me steps
  step1_docsAddFile_title: "1. Adicionar",
  step1_docsAddFile_text: "Clique no ícone +",
  step2_docsAddFile_title: "2. Adicionar arquivos",
  step2_docsAddFile_text: "Selecione a opção para adicionar arquivos.",
  step3_docsAddFile_title: "3. Carregar arquivos",
  step3_docsAddFile_text:
    "Arraste e solte arquivos ou clique no quadrado pontilhado para carregá-los.",
  step4_docsAddFile_title: "4. Privacidade",
  step4_docsAddFile_text:
    "Se ativado, o acesso a determinadas empresas e indivíduos será restrito.",
  step5_docsAddFile_title: "5. Adicionar",
  step5_docsAddFile_text: "Clique aqui para adicionar os arquivos.",
  step_final_docsAddFile: "Você aprendeu como adicionar um arquivo.",
  // ADD FOLDER - guide me steps
  step1_docsAddFolder_title: "1. Adicionar",
  step1_docsAddFolder_text: "Clique no ícone +",
  step2_docsAddFolder_title: "2. Adicionar pasta",
  step2_docsAddFolder_text: "Selecione a opção para criar uma pasta",
  step3_docsAddFolder_title: "3. Nome da pasta",
  step3_docsAddFolder_text: "Digite o nome da pasta",
  step4_docsAddFolder_title: "4. Privacidade",
  step4_docsAddFolder_text:
    "Se ativado, o acesso a determinadas empresas e indivíduos será restrito.",
  step5_docsAddFolder_title: "5. Adicionar",
  step5_docsAddFolder_text: "Clique aqui para adicionar a pasta.",
  step_final_docsAddFolder: "Você aprendeu como adicionar uma pasta.",
  // ACTIONS - guide me steps
  step1_docsActions_title: "1. Ações",
  step1_docsActions_text:
    "Aqui você tem diferentes ações que pode executar em relação a arquivos/pastas.",
  step2_docsActions_title: "2. Selecionar como favorito",
  step2_docsActions_text:
    "Clique aqui para marcar o arquivo como favorito para acesso fácil.",
  step3_docsActions_title: "3. Baixar",
  step3_docsActions_text: "Clique aqui para baixar o arquivo selecionado.",
  step5_docsActions_title: "4. Editar nome",
  step5_docsActions_text: "Clique aqui para editar o nome do arquivo.",
  step6_docsActions_title: "5. Mais ações",
  step6_docsActions_text:
    "Clique nos 3 pontos para abrir um menu com mais ações.",
  step7_docsActions_title: "6. Informações",
  step7_docsActions_text:
    "Aqui você pode ver o tamanho do arquivo ou pasta, data de criação, permissões, histórico de alterações e lista de destinatários de e-mail. (Consulte o guia para esta funcionalidade)",
  step8_docsActions_title: "7. Mover",
  step8_docsActions_text:
    "Clique aqui para abrir o menu para mover arquivos. (Veja o Guia para esta função)",
  step9_docsActions_title: "8. Copiar",
  step9_docsActions_text:
    "Clique aqui para copiar o arquivo para a área de transferência.",
  step10_docsActions_title: "9. Excluir",
  step10_docsActions_text:
    "Clique aqui se você deseja excluir permanentemente o arquivo.",
  step_final_docsActions:
    "Você aprendeu como executar várias ações em arquivos/pastas.",
  // MOVE TO - guide me steps
  step1_docsMoveTo_title: "1. Mais ações",
  step1_docsMoveTo_text:
    "Clique nos 3 pontos e um menu com mais ações aparecerá.",
  step2_docsMoveTo_title: "2. Mover",
  step2_docsMoveTo_text: "Clique aqui para abrir o menu para mover arquivos.",
  step3_docsMoveTo_title: "3. Mover arquivo",
  step3_docsMoveTo_text:
    "Selecione o novo caminho desejado e clique em aceitar para mover o arquivo.",
  step4_docsMoveTo_title: "4. Aceitar",
  step4_docsMoveTo_text:
    "Clique aqui para confirmar a nova localização e mover o arquivo.",
  step_final_docsMoveTo: "Você aprendeu como mover arquivos/pastas.",
  // FILE INFO - guide me steps
  step1_docsFileInfo_title: "1. Mais ações",
  step1_docsFileInfo_text:
    "Clique nos 3 pontos e um menu com mais ações aparecerá.",
  step2_docsFileInfo_title: "2. Informações",
  step2_docsFileInfo_text:
    "Aqui você pode ver o tamanho do arquivo e/ou pasta, a data de criação, as permissões, o histórico de alterações e a lista de e-mails enviados.",
  step3_docsFileInfo_title: "3. Gerenciar permissões",
  step3_docsFileInfo_text:
    "Clique aqui para gerenciar quais empresas e pessoas têm acesso ao documento.",
  step4_docsFileInfo_title: "4. Histórico de alterações",
  step4_docsFileInfo_text:
    "Clique aqui para ver informações sobre as alterações feitas nos documentos e quem fez as alterações.",
  step5_docsFileInfo_title: "5. Histórico de e-mails",
  step5_docsFileInfo_text:
    "Clique aqui para ver o histórico de e-mails relacionados ao documento.",
  step5_1_docsFileInfo_title: "5.1 Baixar",
  step5_1_docsFileInfo_text:
    "Clique aqui para baixar as informações do e-mail.",
  step_final_docsFileInfo:
    "Você aprendeu como ver informações sobre arquivos/pastas.",
  // SETTINGS - guide me steps
  step1_docsSettings_title: "2. Abrir menu de configurações",
  step1_docsSettings_text: "Clique aqui para abrir o menu de configurações.",
  step2_docsSettings_title: "3. Lista de distribuição",
  step2_docsSettings_text:
    "Adicione ou remova pessoas que terão acesso padrão ao módulo de documentos.",
  step3_docsSettings_title: "4. Salvar",
  step3_docsSettings_text: "Clique aqui para salvar as configurações.",
  step_final_docsSettings: "Você aprendeu como ajustar as configurações.",

  lastEdit: "Última Edição",
  uploadedCreatedBy: "Carregado / Criado Por",
  uploadedCreatedOn: "Carregado / Criado Em",
  recentDocuments: "Documentos Recentes",
  folderIsPublic: "Esta pasta é pública para todos em",
  folderIsPrivate: "Esta pasta é privada",
  documentMovedMessage: "Documento movido com sucesso.",
  folderMovedMessage: "Pasta movida com sucesso.",
  recentDocument: "Documento recente",
  documentsRecycleBin: "Lixeira de Documentos",
  noDocumentsCreated: "Nenhum documento criado",
  addDocumentsPrompt:
    "Se você deseja adicionar documentos, clique no botão adicionar",
  fileUpdatedSuccessfully: "Arquivo atualizado com sucesso.",
  folderMarkedSuccessfully: "Pasta marcada com sucesso.",
  fileMarkedSuccessfully: "Arquivo marcado com sucesso.",
  folderDeletedSuccessfully: "Pasta(s) excluída(s) com sucesso",
  fileDeletedSuccessfully: "Arquivo(s) excluído(s) com sucesso",
  storage: "Armazenamento",
  sendToEmail: "Enviar por E-mail",
  sendTo: "Enviar para",
  subject: "Assunto",
  body: "Corpo",
  selectAll: "Selecionar Todos",
  deleteItems: "Excluir Itens",
  deleteItemsQuestion:
    "Tem certeza de que deseja excluir o(s) item(ns) selecionado(s)?",
  deleteItemsWarning: "Esta ação não pode ser desfeita.",
  deleteFolder: "Excluir Pasta",
  deleteFile: "Excluir Arquivo",
  deleteQuestion: "Tem certeza de que deseja excluir este ",
  deleteFolderQuestion:
    "Tem certeza de que deseja excluir a pasta selecionada?",
  deleteFileQuestion:
    "Tem certeza de que deseja excluir o arquivo selecionado?",
  file: "arquivo",
  folder: "pasta",
  renameFolder: "Renomear Pasta",
  renameFile: "Renomear Arquivo",
  folderName: "Nome da Pasta",
  fileName: "Nome do Arquivo",
  folderCreatedSuccessfully: "Pasta criada com sucesso.",
  fileCreatedSuccessfully: "Arquivo criado com sucesso.",
  addFolder: "Adicionar Pasta",
  addFile: "Adicionar Arquivo",
  noFilesOnFavorites: "Nenhum arquivo nos favoritos",
  noFilesOnRecycleBin: "Nenhum arquivo na lixeira",
  fileRestoreSuccessfully: "Arquivo restaurado com sucesso.",
  folderRestoreSuccessfully: "Pasta restaurada com sucesso.",
};
