import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
// MUI
import ChecklistIcon from "@mui/icons-material/Checklist";
import CircleSharp from "@mui/icons-material/CircleSharp";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Lock from "@mui/icons-material/Lock";
import LockOpen from "@mui/icons-material/LockOpen";
import SubjectIcon from "@mui/icons-material/Subject";
import {
  Accordion,
  AccordionSummary,
  Box,
  Card,
  CardActions,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
// Custom imports
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Close } from "@mui/icons-material";
import TaskCategorySelect from "../../../components/projectDashboard/Tasks/TaskCategorySelect";
import { NestedLocationSelect } from "../../../components/shared/NestedLocationSelect";
import BubbleAvatarDeletable from "../../../components/userAvatars/BubbleAvatarDeletable";
import {
  useCreateTaskMutation,
  useEditTaskMutation,
  useGetTaskByIdQuery,
} from "../../../features/project/modules/tasks/tasksApiSlice";
import {
  selectEditingTask,
  setEditingTask,
} from "../../../features/project/modules/tasks/tasksSlice";
import {
  useGetActiveProjectUsersQuery,
  useGetProjectConfigurationQuery,
  useGetProjectUsersQuery,
} from "../../../features/project/projectApiSlice";
import { selectCurrentGuideMe } from "../../../features/project/projectSlice";
import taskUtil from "../../../utils/task";

import SubtaskForm from "../../../components/projectDashboard/Tasks/Subtasks/SubtaskForm";
import {
  FilePreviewHorizontalScroll,
  FilterableUserDropdown,
  FormHeader,
} from "../../../components/shared";
import { useUploadFilesMutation } from "../../../features/files";
import { useAppTourContext } from "../../../components/supportButton/context/context";

function TaskForm({ isModal, onClose, onCreate }) {
  // Translation
  const { t: tGeneral } = useTranslation("general");
  const { t: tTasks } = useTranslation("tasks");

  const priorityOptions = [
    {
      value: "high",
      label: tTasks("high"),
    },
    {
      value: "medium",
      label: tTasks("medium"),
    },
    {
      value: "low",
      label: tTasks("low"),
    },
  ];

  // Hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Params
  const [searchParams] = useSearchParams();
  const idTopic = searchParams.get("topic");

  const { idProject } = useParams();
  // Selectors
  const editingTask = useSelector(selectEditingTask);
  const currentGuideMe = useSelector(selectCurrentGuideMe);
  // Data fetching queries
  const { data: projectConfig } = useGetProjectConfigurationQuery(idProject);
  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();
  const { data: projectUsers } = useGetActiveProjectUsersQuery({
    idProject,
    moduleName: "tasks",
  });
  const { data: currentTask } = useGetTaskByIdQuery(editingTask?.id, {
    skip: !editingTask,
  });
  // Mutations
  const [createTask, { isSuccess, data: createdTask }] =
    useCreateTaskMutation();
  const [editTask, { isSuccess: isEditSuccess }] = useEditTaskMutation();
  // Local state
  const [files, setFiles] = useState([]);
  const [subtasks, setSubtasks] = useState([]);
  const [uploadFiles] = useUploadFilesMutation();

  const [accordionOpen, setAccordionOpen] = useState(true);
  const [isComponentLoading, setIsComponentLoading] = useState(false);

  const statusOptions = useMemo(() => {
    const status = [
      {
        value: "open",
        label: tGeneral("open_action").toUpperCase(),
        color: "#5ce381",
      },
    ];
    if (!currentTask || currentTask.status !== "doing") {
      status.push({
        value: "draft",
        label: tGeneral("draft").toUpperCase(),
        color: "#ffc900",
      });
    }
    return status;
  }, [currentTask, tGeneral]);

  const [userList, setUserList] = useState([]);
  useEffect(() => {
    if (projectUsers) {
      const list = projectUsers
        .filter((obj) => !obj.bExternal)
        .map((projectUser) => {
          return {
            id: projectUser.id,
            firstName: projectUser.firstName,
            lastName: projectUser.lastName,
            urlAvatar: projectUser.urlAvatar,
            name: `${projectUser.firstName} ${projectUser.lastName}`,
          };
        });
      setUserList(list);
    }
  }, [projectUsers]);

  const goBack = useCallback(() => {
    dispatch(setEditingTask(null));
    navigate(-1);
  }, [dispatch, navigate]);

  useEffect(() => {
    if ((isSuccess && createdTask) || isEditSuccess) {
      setIsComponentLoading(false);
      if (isModal && isSuccess) {
        onCreate(createdTask.id);
        onClose();
      } else {
        goBack();
      }
    }
  }, [
    isSuccess,
    isEditSuccess,
    setIsComponentLoading,
    goBack,
    createdTask,
    onCreate,
    onClose,
    isModal,
  ]);

  function formatDate(simpleDate) {
    const dateFormat = new Date(simpleDate);
    let month = dateFormat.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = dateFormat.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${dateFormat.getFullYear()}-${month}-${day}`;
  }

  const handleSubmit = async (values) => {
    setIsComponentLoading(true);
    const [year, month, day] = values?.dueDate?.split("-") ?? [];
    const body = {
      idProject,
      title: values?.title,
      question: values?.question,
      dueDate: `${month}-${day}-${year}`,
      bPrivate: values?.bPrivate,
      bDraft: values?.status === "draft",
      assignedTo: values?.assignedTo,
      distributionList: values?.distributionList,
      ...(values?.priority && {
        priority: values?.priority,
      }),
      ...(values?.location && {
        locationId: values?.location,
      }),
      ...(values?.category && {
        category: values?.category,
      }),
    };

    const uploadFilesPromises = [];
    if (editingTask) {
      // Add taskId to body and perform request
      body.id = editingTask?.id;
      body.idQuestion = currentTask.question.id;
      body.status = values.status;
      const newFiles = files.filter((file) => !file.id);
      if (newFiles.length) {
        const filesFormData = new FormData();
        files.forEach((file) => {
          filesFormData.append("files", file);
        });
        uploadFilesPromises.push(
          uploadFiles({
            idObject: currentTask.question.id,
            moduleName: "tasks",
            body: filesFormData,
          }),
        );
      }

      // Subtasks
      if (subtasks.length > 0) {
        let newOrder = -1;
        const subtasksData = subtasks.map((subtask) => {
          if (subtask.bActive) newOrder = +1;
          return {
            id: subtask.id,
            description: subtask.description,
            bNew: subtask.bNew,
            bChecked: subtask.bChecked,
            bActive: subtask.bActive,
            order: subtask.bActive ? newOrder : -1,
          };
        });
        body.subtasks = subtasksData;
      }

      await Promise.all(uploadFilesPromises);
      editTask(body);
    } else {
      const idQuestion = uuidv4();
      if (files.length > 0) {
        const filesFormData = new FormData();
        files.forEach((file) => {
          filesFormData.append("files", file);
        });
        uploadFilesPromises.push(
          uploadFiles({
            idObject: idQuestion,
            moduleName: "tasks",
            body: filesFormData,
          }),
        );
      }
      body.idQuestion = idQuestion;

      // Meeting relation
      if (idTopic) {
        body.idTopic = idTopic;
      }

      // Subtasks
      if (subtasks.length > 0) {
        const subtasksData = subtasks.map((subtask) => {
          return subtask.description;
        });
        body.subtasks = subtasksData;
      }

      await Promise.all(uploadFilesPromises);
      createTask(body);
    }
  };

  const validationSchema = yup.object({
    title: yup
      .string(tGeneral("enterValidTitle"))
      .required(tGeneral("fieldRequired")),
    question: yup
      .string(tGeneral("enterValidDescription"))
      .required(tGeneral("fieldRequired")),
    dueDate: yup
      .string(tGeneral("enterValidDate"))
      .required(tGeneral("fieldRequired")),
    status: yup
      .string(tGeneral("enterValidStatus"))
      .oneOf(["open", "draft"])
      .required(tGeneral("fieldRequired")),
    bPrivate: yup
      .boolean(tGeneral("enterValidStatus"))
      .required(tGeneral("fieldRequired")),
    assignedTo: yup.array().of(yup.string()).min(1, tGeneral("fieldRequired")),
    distributionList: yup.array().of(yup.string()),
    priority: yup
      .string(tGeneral("enterValidPriority"))
      .oneOf(["high", "medium", "low", ""]),
    location: yup.string(tGeneral("enterValidLocation")),
    category: yup.string(tGeneral("enterValidCategory")),
  });

  const formik = useFormik({
    initialValues: {
      title: editingTask?.title || "",
      question: currentTask?.question.question || "",
      dueDate: editingTask?.dueDate?.split("T")[0] || "",
      status:
        taskUtil.getTaskStatus(editingTask?.status, editingTask?.bDraft)
          ?.value || "open",
      bPrivate: editingTask?.bPrivate || false,
      assignedTo: editingTask?.assignedTo || [],
      distributionList:
        editingTask?.distributionList ||
        projectConfig?.taskDistributionList ||
        [],
      priority: editingTask?.priority || "",
      location: editingTask?.locationId || "",
      category: editingTask?.category || "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => handleSubmit(values),
  });

  const { setFieldValue } = formik;

  useEffect(() => {
    if (!editingTask && projectConfig) {
      // Update days default
      if (projectConfig?.taskDays !== null) {
        const defaultDueDate = new Date();
        defaultDueDate.setDate(
          defaultDueDate.getDate() + projectConfig.taskDays,
        );
        setFieldValue("dueDate", formatDate(defaultDueDate));
      }
    } else if (editingTask && userList) {
      const currentSubtasks = editingTask.subtasks.map((subtask) => {
        return {
          ...subtask,
          bNew: false,
        };
      });
      setSubtasks(currentSubtasks);
    }
  }, [userList, editingTask, projectConfig, setFieldValue]);

  useEffect(() => {
    if (currentTask) {
      setFiles(currentTask.question.files);
    }
  }, [currentTask]);

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 1 &&
      (currentGuideMe === "tasks-add" || currentGuideMe === "tasks-edit")
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe, setState]);

  return (
    <>
      <FormHeader
        isEditing={editingTask}
        onBack={() => {
          if (isModal) {
            onClose();
          } else {
            goBack();
          }
        }}
        title={editingTask ? tTasks("editTask") : tTasks("addTask")}
        onDone={() => formik.submitForm()}
        dataTourNameButton="add-tasks-13"
        backIcon={isModal ? <Close /> : null}
      />
      <form
        onSubmit={formik.handleSubmit}
        style={{ width: "100%", height: "88vh", overflow: "hidden" }}
      >
        <Box display="flex" width={1} height="100%" columnGap="10px" pl="10px">
          {/* Left side of content: Form */}
          <Box flex={2} display="flex" flexDirection="column">
            {/* First 4 inputs */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                columnGap: "10px",
                rowGap: "20px",
                py: "10px",
                borderBottom: "1px solid #E1E1E1",
              }}
              data-tour="edit-tasks-2"
            >
              {/* Title */}
              <TextField
                required
                fullWidth
                name="title"
                label={tGeneral("title")}
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                data-tour="add-tasks-2"
              />
              {/* Due Date */}
              <TextField
                required
                fullWidth
                InputLabelProps={{ shrink: true }}
                name="dueDate"
                label={tTasks("deliveryDate")}
                type="date"
                value={formik.values.dueDate}
                onChange={formik.handleChange}
                error={formik.touched.dueDate && Boolean(formik.errors.dueDate)}
                helperText={formik.touched.dueDate && formik.errors.dueDate}
                data-tour="add-tasks-3"
              />
              {/* Status */}
              <FormControl fullWidth data-tour="add-tasks-4">
                <InputLabel id="status-label">{tGeneral("status")}</InputLabel>
                <Select
                  required
                  labelId="status-label"
                  name="status"
                  variant="outlined"
                  type="text"
                  label="Select status"
                  select="true"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Box display="flex" alignItems="center">
                        <CircleSharp sx={{ color: option.color, mr: 2 }} />
                        {option.label}
                      </Box>
                    </MenuItem>
                  ))}
                  {editingTask?.status === "done" && (
                    <MenuItem value="closed">
                      <Box display="flex" alignItems="center">
                        <CircleSharp sx={{ color: "#B0B7C1", mr: 2 }} />
                        {tGeneral("closed")}
                      </Box>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              {/* Private / Public */}
              <Card
                sx={{ width: "100%", height: "100%" }}
                data-tour="add-tasks-5"
              >
                <CardActions
                  sx={{ justifyContent: "space-between", alignItems: "center" }}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    marginLeft={1}
                  >
                    {formik.values.bPrivate ? <Lock /> : <LockOpen />}
                    <Typography marginLeft={2}>
                      {formik.values.bPrivate
                        ? tGeneral("private")
                        : tGeneral("public")}
                    </Typography>
                  </Box>
                  <Switch
                    name="bPrivate"
                    checked={formik.values.bPrivate}
                    onChange={formik.handleChange}
                    color="primary"
                  />
                </CardActions>
              </Card>
            </Box>
            <Box flex={1} sx={{ overflowY: "auto" }} py="10px">
              {/* Description */}
              <Box data-tour="add-tasks-8">
                <Box display="flex" alignItems="center">
                  <SubjectIcon />
                  <Typography
                    variant="h6"
                    align="left"
                    fontWeight="bold"
                    ml={1}
                  >
                    {tGeneral("description")}
                  </Typography>
                </Box>
                <TextField
                  fullWidth
                  name="question"
                  value={formik.values.question}
                  onChange={formik.handleChange}
                  multiline
                  rows={5}
                />
                {formik.touched.question && (
                  <Typography color="red" textAlign="center">
                    {formik.errors.question}
                  </Typography>
                )}
              </Box>
              {/* File upload */}
              <Box marginY={2} data-tour="add-tasks-9">
                <Box display="flex" alignItems="center">
                  <DescriptionOutlinedIcon />
                  <Typography
                    variant="h6"
                    align="left"
                    fontWeight="bold"
                    ml={1}
                  >
                    {tGeneral("files")}
                  </Typography>
                </Box>
                <FilePreviewHorizontalScroll
                  onAddPhotos={(files) => setFiles(files)}
                  files={files}
                  onDelete={() => {}}
                />
              </Box>
              {/* Subtasks (checklist) */}
              <Box marginY={2}>
                <Box display="flex" alignItems="center">
                  <ChecklistIcon />
                  <Typography
                    variant="h6"
                    align="left"
                    fontWeight="bold"
                    ml={1}
                  >
                    {tTasks("subtasks")}
                  </Typography>
                </Box>
                <SubtaskForm
                  subtasks={subtasks}
                  onAdd={(subtaskDescription) => {
                    const tempSubtasks = [...subtasks];
                    tempSubtasks.push({
                      id: subtasks.length + 1,
                      description: subtaskDescription,
                      bChecked: false,
                      bNew: true, // For edit
                      bActive: true, // For edit (If you delete any existing)
                    });
                    setSubtasks(tempSubtasks);
                  }}
                  onChange={(modifiedSubtasks) => {
                    setSubtasks(modifiedSubtasks);
                  }}
                />
              </Box>
              {/* Additional information */}
              <Accordion
                elevation={0}
                disableGutters
                expanded={accordionOpen}
                onChange={() => setAccordionOpen(!accordionOpen)}
                sx={{
                  "&:before": {
                    display: "none",
                  },
                  marginY: 2,
                  paddingTop: 2,
                  borderTop: "1px solid #d7d7d7",
                }}
                data-tour="edit-tasks-4"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ px: 0 }}
                >
                  <Box display="flex" alignItems="center">
                    <InfoOutlinedIcon />
                    <Typography
                      variant="h6"
                      align="left"
                      fontWeight="bold"
                      ml={1}
                    >
                      {tGeneral("additionalInformation")}
                    </Typography>
                  </Box>
                </AccordionSummary>
                {/* Inputs */}
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: 2,
                    paddingTop: 2,
                    paddingBottom: 2,
                  }}
                >
                  {/* Category */}
                  <Box data-tour="add-tasks-10">
                    <TaskCategorySelect
                      selectedCategory={formik.values.category}
                      onChange={(category) =>
                        formik.setFieldValue("category", category)
                      }
                    />
                  </Box>
                  {/* Priority */}
                  <FormControl fullWidth data-tour="add-tasks-11">
                    <InputLabel id="priority-label">
                      {tTasks("priority")}
                    </InputLabel>
                    <Select
                      labelId="priority-label"
                      name="priority"
                      variant="outlined"
                      type="text"
                      label="Priority"
                      select="true"
                      value={formik.values.priority}
                      onChange={formik.handleChange}
                    >
                      {priorityOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <Box display="flex" alignItems="center">
                            {option.label}
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* Location */}
                  {/* TODO: When editing a task, the location is not appearing */}
                  <Box data-tour="add-tasks-12">
                    <NestedLocationSelect
                      selectedLocation={formik.values.location}
                      onChange={(location) =>
                        formik.setFieldValue("location", location)
                      }
                    />
                  </Box>
                </Box>
              </Accordion>
            </Box>
          </Box>
          {/* Right side of content: Assigned & Dist List */}
          <Box
            flex={1}
            p="10px"
            bgcolor="#f2f2f2"
            display="flex"
            flexDirection="column"
          >
            {/* Assigned to */}
            <FormControl
              sx={{ flex: 1, width: "100%" }}
              data-tour="add-tasks-6"
            >
              <FilterableUserDropdown
                title={tGeneral("assigned")}
                userList={userList}
                selectedUsersList={userList.filter(
                  (user) =>
                    formik.values.assignedTo.findIndex(
                      (creator) => creator === user.id,
                    ) > -1,
                )}
                selectedUsersFormatted={formik.values.assignedTo}
                handleUpdateUsersList={(list) => {
                  formik.setFieldValue(
                    "assignedTo",
                    list.map((user) => user.id),
                  );
                }}
              />

              <Box height={200} sx={{ overflowY: "scroll" }}>
                {userList
                  .filter(
                    (user) =>
                      formik.values.assignedTo.findIndex(
                        (creator) => creator === user.id,
                      ) > -1,
                  )
                  .map((user) => (
                    <BubbleAvatarDeletable
                      key={user.id}
                      user={user}
                      editable // To show delete button
                      byObject
                      setValues={(value) => {
                        const newSelectedArray = [
                          ...formik.values.assignedTo,
                        ].filter((assigned) => assigned !== value);
                        formik.setFieldValue("assignedTo", newSelectedArray);
                      }}
                    />
                  ))}
              </Box>
            </FormControl>
            {/* Distribution List */}
            <FormControl
              sx={{ flex: 1, width: "100%" }}
              data-tour="add-tasks-7"
            >
              <FilterableUserDropdown
                title={tGeneral("distributionList")}
                userList={userList}
                selectedUsersList={userList.filter(
                  (user) =>
                    formik.values.distributionList.findIndex(
                      (creator) => creator === user.id,
                    ) > -1,
                )}
                selectedUsersFormatted={formik.values.distributionList}
                handleUpdateUsersList={(list) => {
                  formik.setFieldValue(
                    "distributionList",
                    list.map((user) => user.id),
                  );
                }}
              />

              <Box height={400} sx={{ overflowY: "scroll" }}>
                {formik.values.distributionList?.length > 0 &&
                  userList
                    .filter(
                      (user) =>
                        formik.values.distributionList.findIndex(
                          (creator) => creator === user.id,
                        ) > -1,
                    )
                    .map((user) => (
                      <BubbleAvatarDeletable
                        key={user.id}
                        user={user}
                        byObject
                        editable // To show delete button
                        setValues={(value) => {
                          const newSelectedArray = [
                            ...formik.values.distributionList,
                          ].filter((assignee) => assignee !== value);
                          formik.setFieldValue(
                            "distributionList",
                            newSelectedArray,
                          );
                        }}
                      />
                    ))}
              </Box>
            </FormControl>
          </Box>
        </Box>
      </form>
      <Modal
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={isComponentLoading}
      >
        <CircularProgress size="100px" color="primary" />
      </Modal>
    </>
  );
}
export default TaskForm;
