import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  order: [],
  visible: [],
};

export const projectSettingsSlice = createSlice({
  name: "projectSettings",
  initialState,
  reducers: {
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setVisibles: (state, action) => {
      state.visible = action.payload;
    },
  },
});

export const selectVisibleConfig = (state) => state.projectSettings.visible;

export const selectOrderConfig = (state) => state.projectSettings.order;

export const { setOrder, setVisibles } = projectSettingsSlice.actions;

export default projectSettingsSlice.reducer;
