import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { forwardRef } from "react";

/**
 * TooltipIconButton component displays an icon button with a tooltip.
 *
 * @param {React.ReactNode} icon - The icon element to display within the button.
 * @param {string} label - The label for the tooltip.
 * @param {function} onClick - The click event handler for the button.
 * @param {string} [color="#FBCB04"] - The color of the icon button.
 * @param {object} buttonProps - Additional props to be spread to the IconButton component.
 * @param {React.Ref} ref - Ref forwarded to the IconButton component.
 * @returns {React.ReactElement} A React component representing the TooltipIconButton.
 */
const TooltipIconButton = forwardRef(
  (
    {
      icon,
      label,
      onClick,
      color = "#FBCB04",
      isLoading,
      sx = {},
      ...buttonProps
    },
    ref,
  ) => {
    return (
      <Tooltip arrow placement="top" title={label}>
        <span>
        <IconButton
          onClick={(e) => onClick?.(e)}
          sx={{
            color,
            "&.Mui-disabled": { color, opacity: 0.5 },
            ...sx,
          }}
          {...buttonProps}
          ref={ref}
        >
          {isLoading ? <CircularProgress size="1.5rem" /> : icon}
        </IconButton>
        </span>
      </Tooltip>
    );
  },
);

export { TooltipIconButton };
