import {
  Check,
  EditNote,
  PictureAsPdf,
  RemoveRedEye,
} from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { datadogRum } from "@datadog/browser-rum";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Find from "../../../assets/rfi/find.svg";
import Cost from "../../../assets/submittals/cost.png";
import Location from "../../../assets/submittals/location.png";
import Type from "../../../assets/submittals/tipo.png";
import BubbleAvatarDeletable from "../../../components/userAvatars/BubbleAvatarDeletable";
import {
  useCloseSubmittalMutation,
  useGetSubmittalByIdQuery,
} from "../../../features/project/modules/submittals/submittalApiSlice";
import { capitalizeFirstLetterOfSentence } from "../../../services/helpers/capitalizeFirstLetterOfSentence";
import { PopUpAlert } from "../../../components/PopUpAlert";
import { Question } from "../../../components/question";
import {
  DetailsHeader,
  ModalLastEdit,
  ModalSeenBy,
} from "../../../components/shared";
import { ModalFileViewer } from "../../../components/shared/ModalFileViewer";
import { PropertyCard } from "../../../components/shared/PropertyCard";
import { Topic } from "../../../components/topic";
import { selectCurrentToken } from "../../../features/auth/authSlice";
import { useAddUserLogMutation } from "../../../features/log/logApiSlice";
import { selectCurrentUser } from "../../../features/userSettings/userSettingsSlice";
import CaslContext from "../../../utils/caslContext";
import SubmittalSectionOneDetail from "./SubmittalSectionOneDetail";
import SkeletonLoading from "../../../components/shared/SkeletonLoading";
import pdf from "../../../utils/pdf";
import { useToast } from "../../../contexts/ToastContext";

// TODO: make style(s) document
const styles = {
  sectionTitle: {
    textAlign: "left",
    fontWeight: "bold",
  },
  sectionText: {
    textAlign: "left",
  },
  box1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: "1",
  },
  // PropertyCard.value: {
  //   flex: "end",
  // }
};

function SubmittalDetails() {
  const { idProject, idSubmittal, idEnterprise } = useParams();

  const { t: tGeneral } = useTranslation("general");
  const { t: tSubmittals } = useTranslation("submittals");

  const navigate = useNavigate();
  const [pdfUrl, setPdfUrl] = React.useState(null);
  const ability = useContext(CaslContext);
  const toast = useToast();

  const { data: currentSubmittal, isLoading } =
    useGetSubmittalByIdQuery(idSubmittal);
  const [closeCurrentSubmittal] = useCloseSubmittalMutation();
  const [addLog] = useAddUserLogMutation();
  const currentUser = useSelector(selectCurrentUser);
  const token = useSelector(selectCurrentToken);

  const isMobile = useMediaQuery("(max-width:820px)");
  const [openLastSeen, setOpenLastSeen] = useState(false);
  const [openLastEdit, setOpenLastEdit] = useState(false);

  const exportPdfDocument = async (currentSubmittal) => {
    toast.showLoading(
      idSubmittal,
      tGeneral("downloading"),
      currentSubmittal.title,
    );
    await pdf({
      data: {},
      token,
      url: `${process.env.REACT_APP_BACKEND_URL}pdf/${idEnterprise}/${idProject}/submittals/${idSubmittal}`,
      download: currentSubmittal.title,
      module: "submittal",
      object: currentSubmittal,
      tGeneral,
    });
    toast.hideLoading(idSubmittal);
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const body = {
      idEnterprise,
      idProject,
      module: "submittal",
      action: "seen",
      idObject: idSubmittal,
      result: "ok",
    };
    addLog(body).unwrap();
  }, [addLog, idEnterprise, idProject, idSubmittal]);

  const showClose = useMemo(() => {
    if (currentSubmittal && ability && currentUser) {
      return (
        currentSubmittal?.status !== "closed" &&
        ((currentSubmittal?.createdBy.id === currentUser.id &&
          ability.can("close", "submittals")) ||
          ability.can("closeAll", "submittals"))
      );
    }
    return false;
  }, [currentSubmittal, ability, currentUser]);

  const canAnswer = useMemo(() => {
    if (currentSubmittal && currentUser) {
      return (
        ability.can("superAdmin", "submittals") ||
        (!ability.can("superAdmin", "submittals") &&
          (currentSubmittal.assignedTo.find(
            (user) => user.id === currentUser.id,
          ) ||
            currentSubmittal.createdBy.id === currentUser.id))
      );
    }
    return false;
  }, [currentSubmittal, currentUser, ability]);

  const canTopic = useMemo(() => {
    if (currentSubmittal && currentUser) {
      return (
        !ability.can("onlyView", "submittals") &&
        (currentSubmittal.assignedTo.find(
          (user) => user.id === currentUser.id,
        ) ||
          currentSubmittal.distributionList.find(
            (user) => user.id === currentUser.id,
          ) ||
          currentSubmittal.createdBy.id === currentUser.id ||
          ability.can("superAdmin", "submittals"))
      );
    }
    return false;
  }, [currentSubmittal, currentUser, ability]);

  const closeSubmittal = async () => {
    const dateNow = new Date();

    const body = {
      idSubmittal: currentSubmittal?.id,
      closeDate: dateNow,
    };

    try {
      const response = await closeCurrentSubmittal(body).unwrap();

      if (response) {
        PopUpAlert(
          "success",
          tGeneral("success"),
          `${tSubmittals("submittal")} ${tGeneral("closedSuccessfully")}`,
        );
        navigate(-1);
      }
    } catch (err) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      datadogRum.addError({
        message: "Submittals - Close - Error",
        stack: err.stack ? err.stack : "invalid stack",
        custom: {
          app: "buildpeer-web",
          enterprise: idEnterprise,
          module: "Submittals",
          project: idProject,
        },
        timestamp: Date.now(),
      });
    }
  };

  if (isLoading || !currentSubmittal) {
    return (
      <Box p="1rem 0.5rem">
        <SkeletonLoading />
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box display="flex" width="100%">
          <DetailsHeader
            bPrivate={currentSubmittal?.bPrivate}
            number={currentSubmittal?.number}
            title={currentSubmittal?.title}
            onBack={goBack}
            showMainAction={showClose}
            mainAction={{
              icon: <Check />,
              label: `${tGeneral("close")} ${tSubmittals("submittal")}`,
              onClick: () => closeSubmittal(),
            }}
            mainActionDataTour="view-submittal-9"
            status={currentSubmittal?.status}
            actions={[
              {
                onClick: () => {
                  exportPdfDocument(currentSubmittal);
                },
                icon: <PictureAsPdf />,
                label: tGeneral("exportPdf"),
                dataTour: "export-pdf",
              },
              {
                onClick: () => setOpenLastEdit(true),
                icon: <EditNote />,
                label: tGeneral("lastEdit"),
                dataTour: "last-edit",
              },
              {
                onClick: () => setOpenLastSeen(true),
                icon: <RemoveRedEye />,
                label: tGeneral("lastSeen"),
                dataTour: "last-seen",
              },
            ]}
          />
          {isMobile ? (
            <Box
              display="flex"
              flex={1}
              sx={{ display: "flex", alignItems: "center", my: "1%" }}
            >
              {/* Title */}
              <Typography
                textAlign="center"
                flex={1}
                fontSize="16px"
                fontWeight="medium"
              >
                {capitalizeFirstLetterOfSentence(currentSubmittal?.title)}
              </Typography>
            </Box>
          ) : null}
        </Box>
        <Divider />
      </Box>
      <Box
        sx={{ width: "100%", maxHeight: "75vh", overflow: "auto" }}
        data-tour="resize-view-submittal-2"
      >
        <Box data-tour="view-submittal-2" p="10px">
          {currentSubmittal && (
            <SubmittalSectionOneDetail currentSubmittal={currentSubmittal} />
          )}
          <Divider />
          {/* SECTION #2 - ADDITIONAL INFORMATION */}
          <Box
            display="flex"
            my="20px"
            justifyContent="space-evenly"
            gap="1%"
            flexWrap="wrap"
          >
            {/* Type */}
            <Box sx={{ flex: 1, maxWidth: isMobile ? "50%" : "auto" }}>
              <PropertyCard
                img={<img src={Type} alt="Type" style={{ height: 40 }} />}
                label={tGeneral("type")}
                value={
                  currentSubmittal?.typeObject
                    ? currentSubmittal?.typeObject.name
                    : "N/A"
                }
              />
            </Box>
            {/* Cost Code */}
            <Box sx={{ flex: 1, maxWidth: isMobile ? "50%" : "auto" }}>
              <PropertyCard
                img={<img src={Cost} style={{ height: 40 }} alt="Cost Code" />}
                label={tSubmittals("costCode")}
                value={
                  currentSubmittal?.costCodeObject
                    ? `${currentSubmittal?.costCodeObject.code} - ${currentSubmittal?.costCodeObject.name}`
                    : "N/A"
                }
              />
            </Box>
            {/* Location */}
            <Box sx={{ flex: 1, maxWidth: isMobile ? "50%" : "auto" }}>
              <PropertyCard
                img={
                  <img src={Location} style={{ height: 40 }} alt="Location" />
                }
                label={tGeneral("location")}
                value={
                  currentSubmittal?.locationObject
                    ? currentSubmittal?.locationObject.locationName
                    : "N/A"
                }
              />
            </Box>
            {/* Specification */}
            <Box sx={{ flex: 1, maxWidth: isMobile ? "50%" : "auto" }}>
              <PropertyCard
                img={
                  <img src={Find} style={{ height: 40 }} alt="Specification" />
                }
                label={tGeneral("specification")}
                value={
                  currentSubmittal?.specificationObject
                    ? `${currentSubmittal?.specificationObject.code} - ${currentSubmittal?.specificationObject.name}`
                    : "N/A"
                }
              />
            </Box>
          </Box>
        </Box>
        <Divider />
        {/* SECTION #4 - CHAT */}
        <Box
          display="flex"
          flexDirection="column"
          mt="35px"
          width="100%"
          data-tour="view-submittal-3"
          p="10px"
        >
          <Question
            object={currentSubmittal}
            moduleName="submittals"
            disabled={currentSubmittal?.status === "closed" || !canAnswer}
            canMakeOfficial={
              (ability.can("superAdmin", "submittals") ||
                currentSubmittal?.createdBy.id === currentUser.id) &&
              !currentSubmittal?.hasOfficialAnswer
            }
          />
        </Box>
        <Divider />
        <Box data-tour="view-submittal-6" mt="35px" p="10px">
          <Topic
            idObject={currentSubmittal?.id}
            topics={currentSubmittal?.topics || []}
            moduleName="submittals"
            disabled={currentSubmittal?.status === "closed" || !canTopic}
          />
        </Box>

        {Boolean(pdfUrl) && (
          <ModalFileViewer
            file={{ url: pdfUrl }}
            isOpen={Boolean(pdfUrl)}
            onClose={() => setPdfUrl(null)}
          />
        )}
      </Box>
      <Divider />
      {/* SECTION #5 - DISTRIBUTION LIST */}
      <Box display="flex" flexDirection="column" px="10px" overflow="hidden">
        <Typography mt="20px" style={styles.sectionTitle}>
          {tGeneral("distributionList")}
        </Typography>

        <Box display="flex" mb="20px" flex={1} sx={{ overflowX: "auto" }}>
          {currentSubmittal?.distributionList?.length ? (
            currentSubmittal?.distributionList.map((member) => (
              <BubbleAvatarDeletable
                key={member.id}
                user={member}
                editable={false} // To hide delete button
                color // To show background color
                isList={false} // To add padding
              />
            ))
          ) : (
            <Typography fontSize="18px" fontWeight="medium">
              N/A
            </Typography>
          )}
        </Box>
      </Box>
      <ModalSeenBy
        isOpen={openLastSeen}
        onClose={() => setOpenLastSeen(false)}
        idObject={idSubmittal}
      />
      <ModalLastEdit
        isOpen={openLastEdit}
        onClose={() => setOpenLastEdit(false)}
        idObject={idSubmittal}
      />
    </>
  );
}

export default SubmittalDetails;
