import { tour } from "./tour";

export const documents = {
  // general tour
  ...tour,
  moduleElement: "文件",
  // ADD FILE - guide me steps
  step1_docsAddFile_title: "1. 添加",
  step1_docsAddFile_text: "点击 + 图标",
  step2_docsAddFile_title: "2. 添加文件",
  step2_docsAddFile_text: "选择添加文件的选项。",
  step3_docsAddFile_title: "3. 上传文件",
  step3_docsAddFile_text: "拖放文件，或点击虚线框上传文件。",
  step4_docsAddFile_title: "4. 隐私",
  step4_docsAddFile_text: "如果激活，将限制对某些公司和个人的访问权限。",
  step5_docsAddFile_title: "5. 添加",
  step5_docsAddFile_text: "点击此处添加文件。",
  step_final_docsAddFile: "您已学会如何添加文件。",
  // ADD FOLDER - guide me steps
  step1_docsAddFolder_title: "1. 添加",
  step1_docsAddFolder_text: "点击 + 图标",
  step2_docsAddFolder_title: "2. 添加文件夹",
  step2_docsAddFolder_text: "选择创建文件夹的选项",
  step3_docsAddFolder_title: "3. 文件夹名称",
  step3_docsAddFolder_text: "输入文件夹的名称",
  step4_docsAddFolder_title: "4. 隐私",
  step4_docsAddFolder_text: "如果激活，将限制对某些公司和个人的访问权限。",
  step5_docsAddFolder_title: "5. 添加",
  step5_docsAddFolder_text: "点击此处添加文件夹。",
  step_final_docsAddFolder: "您已学会如何添加文件夹。",
  // ACTIONS - guide me steps
  step1_docsActions_title: "1. 操作",
  step1_docsActions_text: "这里有不同的操作可执行，涉及文件/文件夹。",
  step2_docsActions_title: "2. 设为收藏",
  step2_docsActions_text: "点击此处将文件标记为收藏，以便轻松访问。",
  step3_docsActions_title: "3. 下载",
  step3_docsActions_text: "点击此处下载所选文件。",
  step5_docsActions_title: "4. 编辑名称",
  step5_docsActions_text: "点击此处编辑文件的名称。",
  step6_docsActions_title: "5. 更多操作",
  step6_docsActions_text: "点击 3 个点以打开包含更多操作的菜单。",
  step7_docsActions_title: "6. 信息",
  step7_docsActions_text:
    "您可以在此处查看文件或文件夹的大小、创建日期、权限、更改历史和电子邮件收件人列表。（有关此功能，请参阅指南）",
  step8_docsActions_title: "7. 移动",
  step8_docsActions_text:
    "点击此处打开移动文件的菜单。（有关此功能，请参阅指南）",
  step9_docsActions_title: "8. 复制",
  step9_docsActions_text: "点击此处将文件复制到剪贴板。",
  step10_docsActions_title: "9. 删除",
  step10_docsActions_text: "如果要永久删除文件，请点击此处。",
  step_final_docsActions: "您已学会如何对文件/文件夹执行各种操作。",
  // MOVE TO - guide me steps
  step1_docsMoveTo_title: "1. 更多操作",
  step1_docsMoveTo_text: "点击 3 个点，将显示包含更多操作的菜单。",
  step2_docsMoveTo_title: "2. 移动",
  step2_docsMoveTo_text: "点击此处打开移动文件的菜单。",
  step3_docsMoveTo_title: "3. 移动文件",
  step3_docsMoveTo_text: "选择新的目标路径，然后点击接受以移动文件。",
  step4_docsMoveTo_title: "4. 接受",
  step4_docsMoveTo_text: "点击此处确认新位置并移动文件。",
  step_final_docsMoveTo: "您已学会如何移动文件/文件夹。",
  // FILE INFO - guide me steps
  step1_docsFileInfo_title: "1. 更多操作",
  step1_docsFileInfo_text: "点击 3 个点，将显示包含更多操作的菜单。",
  step2_docsFileInfo_title: "2. 信息",
  step2_docsFileInfo_text:
    "您可以在此处查看文件和/或文件夹的大小、创建日期、权限、更改历史和发送的电子邮件列表。",
  step3_docsFileInfo_title: "3. 管理权限",
  step3_docsFileInfo_text: "点击此处管理哪些公司和人员可以访问该文档。",
  step4_docsFileInfo_title: "4. 更改历史",
  step4_docsFileInfo_text: "点击此处查看有关文档的更改信息以及进行更改的人员。",
  step5_docsFileInfo_title: "5. 电子邮件历史",
  step5_docsFileInfo_text: "点击此处查看与文档相关的电子邮件历史记录。",
  step5_1_docsFileInfo_title: "5.1 下载",
  step5_1_docsFileInfo_text: "点击此处下载电子邮件中的信息。",
  step_final_docsFileInfo: "您已学会如何查看文件/文件夹的信息。",
  // SETTINGS - guide me steps
  step1_docsSettings_title: "2. 打开设置菜单",
  step1_docsSettings_text: "点击此处打开设置菜单。",
  step2_docsSettings_title: "3. 分发列表",
  step2_docsSettings_text: "添加或删除将默认访问文档模块的人员。",
  step3_docsSettings_title: "4. 保存",
  step3_docsSettings_text: "点击此处保存设置。",
  step_final_docsSettings: "您已学会如何调整设置。",

  lastEdit: "最后编辑",
  uploadedCreatedBy: "上传者 / 创建者",
  uploadedCreatedOn: "上传 / 创建日期",
  recentDocuments: "最近文档",
  folderIsPublic: "此文件夹对所有人公开",
  folderIsPrivate: "此文件夹是私有的",
  documentMovedMessage: "文件移动成功。",
  folderMovedMessage: "文件夹移动成功。",
  recentDocument: "最近文档",
  documentsRecycleBin: "文档回收站",
  noDocumentsCreated: "未创建文档",
  addDocumentsPrompt: "如果要添加文档，请点击添加按钮",
  fileUpdatedSuccessfully: "文件更新成功。",
  folderMarkedSuccessfully: "文件夹标记成功。",
  fileMarkedSuccessfully: "文件标记成功。",
  folderDeletedSuccessfully: "文件夹删除成功",
  fileDeletedSuccessfully: "文件删除成功",
  storage: "存储",
  sendToEmail: "发送到电子邮件",
  sendTo: "发送至",
  subject: "主题",
  body: "正文",
  selectAll: "全选",
  deleteItems: "删除项目",
  deleteItemsQuestion: "您确定要删除所选项目吗？",
  deleteItemsWarning: "此操作无法撤消。",
  deleteFolder: "删除文件夹",
  deleteFile: "删除文件",
  deleteQuestion: "您确定要删除此",
  deleteFolderQuestion: "您确定要删除所选文件夹吗？",
  deleteFileQuestion: "您确定要删除所选文件吗？",
  file: "文件",
  folder: "文件夹",
  renameFolder: "重命名文件夹",
  renameFile: "重命名文件",
  folderName: "文件夹名称",
  fileName: "文件名称",
  folderCreatedSuccessfully: "文件夹创建成功。",
  fileCreatedSuccessfully: "文件创建成功。",
  addFolder: "添加文件夹",
  addFile: "添加文件",
  noFilesOnFavorites: "收藏夹中没有文件",
  noFilesOnRecycleBin: "回收站中没有文件",
  fileRestoreSuccessfully: "文件恢复成功。",
  folderRestoreSuccessfully: "文件夹恢复成功。",
};
