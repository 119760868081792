import { Close, FormatShapes, TextSnippet } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { ReactComponent as ArrowIcon } from "../../assets/drawings/arrow.svg";
import { ReactComponent as EllipseIcon } from "../../assets/drawings/ellipse.svg";
import { ReactComponent as LineIcon } from "../../assets/drawings/line.svg";
import { ReactComponent as PolylineIcon } from "../../assets/drawings/polyline.svg";
import { ReactComponent as RectangleIcon } from "../../assets/drawings/rectangle.svg";
import { useTranslation } from "react-i18next";
import {
  useDeleteAnnotationMutation,
  usePublishAnnotationMutation,
} from "../../features/annotations";
import { PopUpAlert } from "../PopUpAlert";
import { useEffect } from "react";

function ModalDeleteAnnotations({
  isOpen,
  onClose,
  annotations,
  eraseAnnotations,
}) {
  const { t: tDrawings } = useTranslation("drawings");
  const { t: tGeneral } = useTranslation("general");

  const [
    deleteAnnotations,
    {
      isError: isDeleteError,
      isSuccess: isDeleteSuccess,
      error: deleteError,
      isLoading: isDeleteLoading,
    },
  ] = useDeleteAnnotationMutation();

  useEffect(() => {
    if (isDeleteError) {
      PopUpAlert("error", "Error", deleteError.message);
      onClose();
    }
    if (isDeleteSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tDrawings("annotationDeletedMessage"),
      );
      eraseAnnotations();
      onClose();
    }
  }, [isDeleteError, isDeleteSuccess, deleteError, tDrawings, tGeneral]);

  const handleDeleteAnnotations = async () => {
    await deleteAnnotations({ ids: annotations.map((annot) => annot.id) });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          borderRadius: "12px",
          width: "30%",
          maxHeight: "50%",
          p: "32px 40px",
        }}
      >
        <Box display="flex" alignItems="center">
          <Typography sx={{ flex: 1 }} fontWeight="bold" fontSize="1.563rem">
            {tDrawings("deleteAnnotations")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Typography sx={{ my: "16px" }}>
          {tDrawings("deleteAnnotationsContent").replace(
            "${1}",
            annotations.length,
          )}
        </Typography>
        <Box display="flex" columnGap="5px">
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            disabled={isDeleteLoading}
            sx={{ py: "16px" }}
            onClick={onClose}
          >
            {tGeneral("cancel")}
          </Button>
          <Button
            variant="contained"
            fullWidth
            disabled={isDeleteLoading}
            sx={{ py: "16px" }}
            onClick={handleDeleteAnnotations}
          >
            {isDeleteLoading ? (
              <CircularProgress color="primary" />
            ) : (
              tGeneral("delete")
            )}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}

export { ModalDeleteAnnotations };
