import { tour } from "./tour";

const roles = {
  // general tour
  ...tour,
  moduleElement: "Roles",
  // ADD - guide me steps
  step1_addRole_title: "1. Add Role",
  step1_addRole_text: "Click here to add a new role.",
  step2_addRole_title: "2. Role Name",
  step2_addRole_text: "Give a name to the role being created.",
  step3_addRole_title: "3. Permissions",
  step3_addRole_text:
    "Choose the permission type for each module that the role will have.",
  step4_addRole_title: "4. Add",
  step4_addRole_text: "Click here to add the role to the list of roles.",
  step_final_addRole:
    "You have learned how to add a new role to the list of roles.",
  // EDIT - guide me steps
  step1_editRole_title: "1. Edit Role",
  step1_editRole_text: "Click here to edit the role.",
  step2_editRole_title: "2. Role Name",
  step2_editRole_text: "Edit the name of the role.",
  step3_editRole_title: "3. Permissions",
  step3_editRole_text:
    "Edit the permission type for each module that the role has.",
  step4_editRole_title: "4. Save",
  step4_editRole_text: "Click here to save the changes made to the role.",
  step_final_editRole: "You have learned how to update a role.",
  // DELETE - guide me steps
  step1_deleteRole_title: "1. Delete Role",
  step1_deleteRole_text: "Click here to delete the role.",
  step2_deleteRole_title: "2. Accept",
  step2_deleteRole_text: "Click here to confirm the deletion.",
  step_final_deleteRole: "You have learned how to delete a role.",
  // CHANGE ROLE - guide me steps
  step1_changeRole_title: "1. User Role",
  step1_changeRole_text: "Select the user whose role will be changed.",
  step2_changeRole_title: "2. Role Type",
  step2_changeRole_text: "Select the role that the user will have.",
  step3_changeRole_title: "3. Save",
  step3_changeRole_text: "Click here to save the changes.",
  step_final_changeRole: "You have learned how to change the role to a user.",
};

export { roles };
