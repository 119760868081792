import { Search } from "@mui/icons-material";
import { Box, TextField } from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

function ProjectDirectoryFilters({ setFilters }) {
  const { t: tGeneral } = useTranslation("general");

  const [search, setSearch] = useState("");

  const timerIdRef = useRef(null);

  const handleChangeSearch = (e) => {
    const {
      target: { value },
    } = e;
    setSearch(value);
    clearTimeout(timerIdRef.current);
    timerIdRef.current = setTimeout(() => {
      setFilters({ search: value });
    }, 500);
  };
  return (
    <Box display="flex" justifyContent="flex-end" p="10px">
      <TextField
        size="small"
        value={search}
        placeholder={tGeneral("search")}
        InputProps={{ startAdornment: <Search /> }}
        onChange={handleChangeSearch}
      />
    </Box>
  );
}

export { ProjectDirectoryFilters };
