import { useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Third-party library imports
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import moment from "moment";
import "moment/locale/ja";
import "moment/locale/ko";
import "moment/locale/pt";
import "moment/locale/zh-cn";
import { useTranslation } from "react-i18next";
import { MdLocationOn } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

// Project-specific absolute imports
import { CiCalendar } from "react-icons/ci";
import { IoAdd } from "react-icons/io5";
import { TbGridDots } from "react-icons/tb";
import RefreshIcon from "@mui/icons-material/Refresh";
import { selectLocale } from "../features/preferences/preferencesSlice";
import {
  useGetProjectCurrentWeatherQuery,
  useGetProjectConfigurationQuery,
  useUpdateProjectConfigMutation,
} from "../features/project/projectApiSlice";
import { selectCurrentUser } from "../features/userSettings/userSettingsSlice";
import {
  setWidgets,
  selectAllWidgets,
} from "../features/project/analyticsSlice";
import Can from "../utils/can";
// Relative imports
import WidgetContainer from "../components/projectDashboard/Analytics/WidgetContainer";
import { TitleWidgets } from "../components/shared";
import { PopUpAlert } from "../components/PopUpAlert";

function Analytics() {
  const dispatch = useDispatch();

  // Get navigation hooks from react-router-dom
  const navigate = useNavigate();

  // Translation hook for the analytics namespace
  const { t: tAnalytics } = useTranslation("analytics");
  const { t: tGeneral } = useTranslation("general");

  // Fetch user information from the Redux store
  const user = useSelector(selectCurrentUser);
  const currentLocale = useSelector(selectLocale);
  const { idProject } = useParams();

  // Fetch the layout configuration for widgets
  const widgets = useSelector(selectAllWidgets);

  // Declare RTK mutation
  const [updateProjectConfig] = useUpdateProjectConfigMutation();

  // Query to get project configuration
  const {
    data: projectConfig,
    isError,
    isLoading,
    refetch,
  } = useGetProjectConfigurationQuery(idProject);

  // Set the widgets state when project configuration is fetched
  useEffect(() => {
    if (projectConfig?.analyticsLayout) {
      dispatch(setWidgets(projectConfig.analyticsLayout));
    }
  }, [projectConfig, dispatch]);

  // Handle updating project configuration
  const handleUpdateData = async () => {
    if (widgets == null) return;
    try {
      await updateProjectConfig({
        idProject,
        analyticsLayout: widgets,
      }).unwrap();
      PopUpAlert(
        "success",
        tGeneral("success"),
        tGeneral("updatedSuccessfully"),
      );
    } catch (error) {
      PopUpAlert("error", tGeneral("error"));
    }
  };

  // Determine if there are unsaved changes in the widgets layout
  const isSaving = _.isEqual(widgets, projectConfig?.analyticsLayout);

  // Fetch weather data for the current project
  const { data: weatherData } = useGetProjectCurrentWeatherQuery(idProject);

  // Memoize the current weather information
  const currentWeather = useMemo(() => {
    if (weatherData && !weatherData.code) {
      const { current: currentWeather } = weatherData;
      return {
        location: weatherData.location.split(",")[0], // Get the city name
        temperature: Math.round(currentWeather.temp), // Round the temperature
        icon: `https://openweathermap.org/img/wn/${currentWeather.weather[0].icon}@2x.png`, // Get the weather icon URL
      };
    }
    return null;
  }, [weatherData]);

  // Convert locale and format the date (for Zh language specifically)
  const formattedDate = moment()
    .locale(currentLocale === "zh" ? "zh-cn" : currentLocale)
    .format("D MMM YYYY");

  return (
    <Can I="view" a="analytics">
      <Box
        sx={{
          width: "100%",
          height: "100vh", // Full viewport height
          display: "flex",
          flexDirection: "column",
          background: `
            linear-gradient(to top right, #F9F6C8, #FAFAFA 50%, #F9F6C8)
          `,
          backgroundBlendMode: "multiply", // This blends the gradients
        }}
      >
        <Box sx={{ backgroundColor: "white" }}>
          <TitleWidgets
            title={tAnalytics("analytics")}
            showMoreOptions={false}
            enableSupport
            addFunction={() => {}}
            moduleName="analytics"
            showAdd={false}
            showSave={!isSaving}
            onSave={handleUpdateData}
          />
        </Box>
        {/* Main grid */}
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{ width: { sm: "100%", lg: "92.5%" }, mr: "7.5%" }}
        >
          <Grid item sx={{ width: "100%" }}>
            {/* Header */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                padding: "5px",
                width: "100%",
                "@media (min-width: 1090px)": {
                  flexDirection: "row",
                  justifyContent: "space-between",
                },
              }}
            >
              {/* Left part of the header */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "1%",
                }}
              >
                <Typography
                  textAlign="left"
                  fontWeight="700"
                  sx={{
                    fontSize: {
                      xs: "25px",
                      md: "30px",
                      lg: "33px",
                      xl: "35px",
                    },
                    color: "#1F1F1F",
                  }}
                >
                  {tAnalytics("hi")} {` ${user?.firstName} 👋🏻`}
                </Typography>
                <Typography
                  sx={{
                    marginLeft: "10px",
                    fontSize: {
                      xs: "13px",
                      md: "16px",
                      lg: "19px",
                      xl: "21px",
                    },
                    weight: "700",
                    color: "#4B4B4B",
                  }}
                >
                  {tAnalytics("welcomeBack")}
                </Typography>
              </Box>
              {/* Right part of the header */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: {
                    xs: "9px",
                    md: "11px",
                    lg: "14px",
                    xl: "16px",
                  },
                }}
              >
                {/* Location chip */}
                <Box
                  sx={{
                    display: "inline-flex",
                    backgroundColor: "white",
                    borderRadius: "16px",
                    alignItems: "center",
                    padding: "0px 8px;",
                    height: "40px",
                    gap: "6px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <MdLocationOn
                    style={{ color: "#E6B60C", height: "20px", width: "20px" }}
                  />
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "7px",
                        md: "8px",
                        lg: "10px",
                        xl: "11px",
                      },
                      fontWeight: "500",
                    }}
                  >
                    {currentWeather?.location}
                  </Typography>
                  <img
                    src={currentWeather?.icon}
                    alt="weather"
                    height="35px"
                    width="35px"
                  />
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "8px",
                        md: "10px",
                        lg: "11px",
                        xl: "12px",
                      },
                      fontWeight: "500",
                      color: "#4B4B4B",
                    }}
                  >
                    {currentWeather?.temperature}°
                  </Typography>
                </Box>
                {/* Date chip */}
                <Box
                  sx={{
                    display: "flex",
                    backgroundColor: "white",
                    gap: "4px",
                    borderRadius: "16px",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "8px",
                    color: "#1F1F1F",
                    height: "40px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <CiCalendar style={{ height: "20px", width: "20px" }} />
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "7px",
                        md: "8px",
                        lg: "10px",
                        xl: "11px",
                      },
                      fontWeight: "500",
                    }}
                  >
                    {formattedDate}
                  </Typography>
                </Box>
                {/* Dashboard button */}
                <Button
                  sx={{
                    display: "flex",
                    width: {
                      lg: "115px",
                      xl: "125px",
                    },
                    height: "40px",
                    padding: "4px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "16px",
                    background:
                      "linear-gradient(90deg, #FBCB04 0%, #E5E900 100%)",
                    textAlign: "center",
                    color: "var(--Primary-Primary-950, #412107)",
                    textTransform: "none",
                    position: "relative",
                    "&:hover": {
                      background: "#FBCB04",
                    },
                    "&:hover .grid-icon": {
                      opacity: 0,
                    },
                    "&:hover .add-icon": {
                      opacity: 1,
                    },
                    overflow: "hidden",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                  onClick={() => navigate("../dashboard")}
                >
                  {/* When not hovered grid-icon is rendered */}
                  <Box
                    className="grid-icon"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      transition: "opacity 0.3s",
                      opacity: 1,
                      position: "absolute",
                      left: "10px",
                    }}
                  >
                    <TbGridDots
                      style={{
                        height: "20px",
                        width: "20px",
                        color: "#1F1F1F",
                      }}
                    />
                  </Box>
                  {/* when hovered add-icon is rendered */}
                  <Box
                    className="add-icon"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      transition: "opacity 0.3s",
                      opacity: 0,
                      position: "absolute",
                      left: "10px",
                    }}
                  >
                    <IoAdd
                      style={{
                        height: "23px",
                        width: "23px",
                        color: "#1F1F1F",
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "10px",
                        md: "12px",
                        lg: "13px",
                        xl: "14px",
                      },
                      fontWeight: "500",
                      marginLeft: "30px",
                    }}
                  >
                    {tGeneral("dashboard")}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item sx={{ width: "100%" }}>
            {isLoading && !isError && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: "100%",
                  height: "625px",
                  background: "rgba(255, 255, 255, 0.4)",
                  borderRadius: "16px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  margin: "1%",
                }}
              >
                <CircularProgress />
              </Box>
            )}

            {isError && !isLoading && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: "100%",
                  height: "625px",
                  background: "rgba(255, 255, 255, 0.4)",
                  borderRadius: "16px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  margin: "1%",
                }}
              >
                <Typography
                  sx={{
                    color: "#8E8E8E",
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22px",
                  }}
                >
                  {tAnalytics("somethingWentWrong")}
                </Typography>
                <IconButton onClick={refetch}>
                  <RefreshIcon />
                </IconButton>
              </Box>
            )}

            {!isLoading && !isError && <WidgetContainer />}
          </Grid>
        </Grid>
      </Box>
    </Can>
  );
}

export default Analytics;
