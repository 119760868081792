import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { forwardRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IMaskInput, IMask } from "react-imask";

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  const masks = {
    cardNumber: "####-####-####-####",
    expireDate: "MM/AA",
    cvv: "####",
  };
  return (
    <IMaskInput
      {...other}
      mask={masks[props.name]}
      definitions={{
        "#": /[0-9]/,
      }}
      blocks={{
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
        },
        AA: {
          mask: IMask.MaskedRange,
          from: new Date().getFullYear() % 100,
          to: 99,
        },
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
    />
  );
});

function AddCardModal({
  isOpen,
  onClose,
  onAction,
  cardInfo = {
    name: "",
    cardNumber: "",
    expireDate: "",
    cvv: "",
    address: "",
  },
}) {
  const { t: tPayment } = useTranslation("payment");
  const { t: tGeneral } = useTranslation("general");
  const { register, control, handleSubmit } = useForm({
    defaultValues: cardInfo,
  });

  const onSubmit = (data) => {
    onAction(data);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          padding: "30px",
          width: "25%",
          height: "auto",
          borderRadius: "20px",
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography fontSize="1.563rem" fontWeight="bold">
            {tPayment("addCard")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ my: "30px" }} />
        <Box display="flex" flexDirection="column" rowGap="20px">
          <TextField
            fullWidth
            id="defaultNameTextField"
            variant="outlined"
            type="text"
            label={tGeneral("name")}
            required
            {...register("name")}
          />
          <Controller
            control={control}
            name="cardNumber"
            render={({ field }) => (
              <TextField
                fullWidth
                InputProps={{
                  inputComponent: TextMaskCustom,
                }}
                id="defaultNameTextField"
                variant="outlined"
                type="text"
                label={tPayment("cardNumber")}
                required
                {...field}
              />
            )}
          />
          <Controller
            name="expireDate"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                id="defaultNameTextField"
                variant="outlined"
                type="text"
                label="MM/AA"
                required
                InputProps={{
                  inputComponent: TextMaskCustom,
                }}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="cvv"
            render={({ field }) => (
              <TextField
                fullWidth
                id="defaultNameTextField"
                variant="outlined"
                type="text"
                label="CVV"
                required
                InputProps={{
                  inputComponent: TextMaskCustom,
                }}
                {...field}
              />
            )}
          />
          <TextField
            fullWidth
            id="defaultNameTextField"
            variant="outlined"
            type="text"
            label={tGeneral("address")}
            required
            {...register("address")}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          mt="30px"
          columnGap="15px"
        >
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            sx={{ py: "16px" }}
            onClick={onClose}
          >
            {tGeneral("cancel")}
          </Button>
          <Button
            variant="contained"
            fullWidth
            sx={{ py: "16px" }}
            type="submit"
          >
            {tGeneral("save")}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}

export { AddCardModal };
