import Lottie from "lottie-react";
import { CircleSharp, Lock, LockOpen } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import animationData from "../../../projectDashboard/16627-firework.json";

const useRFISteps = () => {
  const { t: tTour } = useTranslation("tour");
  const { t: translation } = useTranslation("rfis");

  const addSteps = [
    {
      target: "[data-tour='add-rfi-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_addRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_addRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='add-rfi-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_addRfi_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_addRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-rfi-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_addRfi_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_addRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-rfi-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_addRfi_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            {translation("step4_addRfi_text1_1")}{" "}
            <CircleSharp
              sx={{ color: "#5ce381", marginBottom: -0.3, fontSize: 14 }}
            />
            {translation("step4_addRfi_text1_2")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            {translation("step4_addRfi_text2_1")}{" "}
            <CircleSharp
              sx={{ color: "#ffc900", marginBottom: -0.3, fontSize: 14 }}
            />
            {translation("step4_addRfi_text2_2")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-rfi-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_addRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_addRfi_text_1")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            <span style={{ fontWeight: "bold" }}>
              <LockOpen sx={{ marginBottom: -0.4, fontSize: 16 }} /> Public
            </span>{" "}
            - {translation("step5_addRfi_text_2")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            <span style={{ fontWeight: "bold" }}>
              <Lock sx={{ marginBottom: -0.4, fontSize: 16 }} /> Private
            </span>{" "}
            - {translation("step5_addRfi_text_3")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-rfi-6']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_addRfi_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_addRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-rfi-7']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_addRfi_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_addRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-rfi-8']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step8_addRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step8_addRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-rfi-9']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step9_addRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step9_addRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-rfi-10']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step10_addRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step10_addRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-rfi-11']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step11_addRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step11_addRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-rfi-12']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step12_addRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step12_addRfi_text")}
          </Typography>
        </div>
      ),
      placement: "top",
    },
    {
      target: "[data-tour='add-rfi-13']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step13_addRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step11_addRfi_text")}
          </Typography>
        </div>
      ),
      placement: "right",
    },
    {
      target: "[data-tour='add-rfi-14']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step14_addRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step14_addRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-rfi-15']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step15_addRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step15_addRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_addRfi")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const viewSteps = [
    {
      target: "[data-tour='view-rfi-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_viewRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_viewRfi_text")}
          </Typography>
        </div>
      ),
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "[data-tour='view-rfi-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_viewRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_viewRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='view-rfi-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_viewRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_viewRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='view-rfi-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_viewRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_viewRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='view-rfi-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_viewRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_viewRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='view-rfi-6']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_viewRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_viewRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {tTour("moreTitle")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {tTour("moreDescription")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='last-seen']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_viewRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_viewRfi_text")}
          </Typography>
        </div>
      ),
      placement: "top",
    },
    {
      target: "[data-tour='last-edit']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step8_viewRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step8_viewRfi_text")}
          </Typography>
        </div>
      ),
      placement: "top",
    },
    {
      target: "[data-tour='export-pdf']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step10_viewRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step10_viewRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='view-rfi-9']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step9_viewRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step9_viewRfi_text")}
          </Typography>
        </div>
      ),
      placement: "top",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_viewRfi")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const editSteps = [
    {
      target: "[data-tour='edit-rfi-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_editRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_editRfi_text")}
          </Typography>
        </div>
      ),
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "[data-tour='add-rfi-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_editRfi_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_editRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-rfi-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_editRfi_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_editRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-rfi-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_editRfi_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            {translation("step4_editRfi_text1_1")}{" "}
            <CircleSharp
              sx={{ color: "#5ce381", marginBottom: -0.3, fontSize: 14 }}
            />
            {translation("step4_editRfi_text1_2")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            {translation("step4_editRfi_text2_1")}{" "}
            <CircleSharp
              sx={{ color: "#ffc900", marginBottom: -0.3, fontSize: 14 }}
            />
            {translation("step4_editRfi_text2_2")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-rfi-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_editRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_editRfi_text_1")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            <span style={{ fontWeight: "bold" }}>
              <LockOpen sx={{ marginBottom: -0.4, fontSize: 16 }} /> Public
            </span>{" "}
            - {translation("step5_editRfi_text_2")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            <span style={{ fontWeight: "bold" }}>
              <Lock sx={{ marginBottom: -0.4, fontSize: 16 }} /> Private
            </span>{" "}
            - {translation("step5_editRfi_text_3")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-rfi-6']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_editRfi_title")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-rfi-14']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_editRfi_title")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='edit-rfi-8']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step8_editRfi_title")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-rfi-15']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step9_editRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step9_editRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_editRfi")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const recycleSteps = [
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {`1. ${tTour("moreTitle")}`}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {tTour("moreDescription")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='recycle']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_recycleRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_recycleRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_recycleRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_recycleRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='recycle-rfi-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_recycleRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_recycleRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableScrolling: true,
    },
    {
      target: ".checkbox-recycle",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_recycleRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_recycleRfi_text")}
          </Typography>
        </div>
      ),
      placement: "right",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_recycleRfi")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const exportSteps = [
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {`1. ${tTour("moreTitle")}`}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {tTour("moreDescription")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='export-pdf']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_exportRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_exportRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='export-csv']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_exportRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_exportRfi_text")}
          </Typography>
        </div>
      ),
      placement: "top",
    },
    {
      target: "[data-tour='view-rfi-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_exportRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_exportRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableScrolling: true,
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_exportRfi")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const settingsSteps = [
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {`1. ${tTour("moreTitle")}`}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {tTour("moreDescription")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='settings']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_settingsRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_settingsRfi_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='settings-rfi-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_settingsRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_settingsRfi_text")}
          </Typography>
        </div>
      ),
      placement: "right",
    },
    {
      target: "[data-tour='settings-rfi-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_settingsRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_settingsRfi_text")}
          </Typography>
        </div>
      ),
      placement: "right",
    },
    {
      target: "[data-tour='settings-rfi-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_settingsRfi_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_settingsRfi_text")}
          </Typography>
        </div>
      ),
      placement: "right",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_settingsRfi")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  return [
    {
      addSteps,
      viewSteps,
      editSteps,
      recycleSteps,
      exportSteps,
      settingsSteps,
    },
  ];
};

export { useRFISteps };
