import { Close } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  IconButton,
} from "@mui/material";
import * as React from "react";
import Dropzone from "react-dropzone";
import FileViewer from "react-file-viewer";
import { useTranslation } from "react-i18next";
import { FilePreviewHorizontalScroll } from "./../components/shared";

// Alert
import Swal from "sweetalert2";

function UploadFiles({
  acceptedTypes,
  multipleFiles,
  setFiles,
  filesParams,
  isEditable,
}) {
  const { t: tGeneral } = useTranslation("general");
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [reloadList, setReloadList] = React.useState(false);
  const [edited, setEdited] = React.useState(false);
  const [fileInfo, setFileInfo] = React.useState();
  React.useEffect(() => {
    if (filesParams && !edited) {
      // to update the element file(s) only the first time the page loads
      setSelectedFiles(filesParams);
    }
    setReloadList(false);
  }, [reloadList, filesParams, setSelectedFiles, edited]);

  function showAlert(message, type) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: type,
      title: message,
    });
  }

  const isViewOpen = Boolean(fileInfo);

  const handleViewFile = (file) => {
    if (!file.src && !file.url) {
      file.src = URL.createObjectURL(file);
    }
    const fileType = file.name.split(".");
    file.fileType = fileType[fileType.length - 1];
    setFileInfo(file);
  };

  const isDoc = fileInfo?.fileType?.match(/docx|docs|doc|xls|xlsx|pdf/);

  const handleDeleteFile = (file) => {
    setEdited(true);
    let files = selectedFiles.filter((f) => f.name !== file.name);
    setSelectedFiles((prev) => prev.filter((f) => f.name !== file.name));
    setFiles(files);
  };

  const handleAddFiles = (files) => {
    setSelectedFiles(files);
    setEdited(true);
    setFiles(files);
  };

  return (
    <Box sx={{ px: "20px", width: "100%" }}>
      {selectedFiles.length > 0 && (
        <h4>{multipleFiles ? tGeneral("files") : tGeneral("file")}</h4>
      )}
      {selectedFiles.length > 0 && (
        <Box mb="36px">
          <FilePreviewHorizontalScroll
            onDelete={handleDeleteFile}
            onAddPhotos={handleAddFiles}
            onView={handleViewFile}
            files={selectedFiles}
          />
        </Box>
      )}
      {isEditable && selectedFiles.length === 0 && (
        <Dropzone
          onDrop={(files) => {
            if (multipleFiles) {
              //for rfi (accept multiple files)
              if (selectedFiles) {
                // add files to current list
                let currentFiles = selectedFiles;
                for (var i = 0; i < files.length; i++) {
                  currentFiles.push(files[i]);
                }
                setSelectedFiles(currentFiles);
              }
              if (filesParams) {
                // only update
                setEdited(true);
              }
              setFiles(selectedFiles);
              setReloadList(true);
            } else {
              // for enterprise settings (only accept one file)
              if (selectedFiles) {
                let currentFiles = selectedFiles;
                if (currentFiles.length === 1) {
                  // if a file already exists in the list
                  showAlert("Delete the current file and try again", "error");
                } else {
                  // add file to current list
                  setSelectedFiles(files);
                  setFiles(files);
                  setReloadList(true);
                  if (filesParams) {
                    // only update
                    setEdited(true);
                  }
                }
              }
            }
          }}
          multiple={multipleFiles ? true : false} // true for rfis and false for enterprise settings
          // check if these are all accepted file types (enterpriseSettings and rfiForm view)
          accept={acceptedTypes}
          // disabled={isCreateLoading || isBucketLoading} //check ¿?
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <Box
                {...getRootProps({ className: "dropzone" })}
                sx={{
                  textAlign: "center",
                  padding: "25px",
                  border: "3px dashed #eeeeee",
                  backgroundColor: "#fafafa",
                  color: "#bdbdbd",
                  cursor: "pointer",
                  mb: "20px",
                  height: "80px",
                  // width: "100%", //600px
                  borderColor: "#FBCB04",
                }}
              >
                <input {...getInputProps()} />
                <div className="selected-file">
                  {tGeneral(multipleFiles ? "dragFiles" : "dragFile")}
                </div>
              </Box>
            </section>
          )}
        </Dropzone>
      )}
      {fileInfo && (
        <Dialog
          open={isViewOpen}
          onClose={(e) => {
            setFileInfo(null);
          }}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          PaperProps={{ sx: { maxWidth: "none", borderRadius: 5 } }}
        >
          <DialogContent dividers sx={{ textAlign: "center", padding: 0 }}>
            <Card>
              <CardContent sx={{ padding: "7%", mt: 1 }}>
                <IconButton
                  onClick={(e) => {
                    setFileInfo(null);
                  }}
                  sx={{ position: "absolute", right: 0, top: 0, mr: 2 }}
                >
                  <Close />
                </IconButton>
                {isDoc ? (
                  <FileViewer
                    fileType={fileInfo?.fileType}
                    filePath={fileInfo?.src ?? fileInfo?.url}
                    onError={(error) => console.error(error)}
                    style={{ minWidth: "100%" }}
                  />
                ) : (
                  <img
                    src={fileInfo?.src ?? fileInfo?.url}
                    alt={fileInfo?.name}
                    style={{ maxHeight: "500px", width: "100%" }}
                  />
                )}
              </CardContent>
            </Card>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}

export default UploadFiles;
