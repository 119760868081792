import { tour } from "./tour";

export const meetings = {
  // general tour
  ...tour,
  moduleElement: "Reuniones",
  // ADD - guide me steps
  step1_addMeeting_title: "1. Crear una nueva reunión.",
  step1_addMeeting_text:
    "Haz click en el icono de + para agregar una nueva reunión.",
  step2_addMeeting_title: "2. Título",
  step2_addMeeting_text: "Escribe un titulo para la minuta.",
  step3_addMeeting_title: "3. Privacidad",
  step3_addMeeting_text:
    "Selecciona si la minuta sera visible para todos o no.",
  step3_addMeeting_text_2:
    "Cualquier persona con acceso al módulo verá tu minuta",
  step3_addMeeting_text_3: "Solo los participantes podrán ver esta minuta",
  step4_addMeeting_title: "4. Fecha de minuta",
  step4_addMeeting_text: "Determina la fecha y hora de la minuta.",
  step5_addMeeting_title: "5. Ubicación",
  step5_addMeeting_text: "Determina cual es la ubicación de la minuta.",
  step6_addMeeting_title: "6. URL de la minuta",
  step6_addMeeting_text: "Coloca aqui el URL de la minuta.",
  step7_addMeeting_title: "7. Estatus",
  step7_addMeeting_text_1_1: "Elige el estatus",
  step7_addMeeting_text_1_2: "ABIERTO para las personas involucrada.",
  step7_addMeeting_text_2_1: "Elige el estatus",
  step7_addMeeting_text_2_2: "BORRADOR para guardar como borrador.",
  step8_addMeeting_title: "8. Asistentes",
  step8_addMeeting_text: "Selecciona las personas requeridas para la junta.",
  step9_addMeeting_title: "9. Lista de distribución",
  step9_addMeeting_text:
    "Selecciona las personas que recibirán notificación de la minuta.",
  step10_addMeeting_title: "10. Nuevo elemento de agenda",
  step10_addMeeting_text: "Agrega un nuevo item a la minuta.",
  step11_addMeeting_title: "11. Nombre del item",
  step11_addMeeting_text: "Escribe el nombre del item.",
  step12_addMeeting_title: "12. Tópicos a discutir",
  step12_addMeeting_text:
    "Escribe los tópicos a discutir en el item de la minuta.",
  step13_addMeeting_title: "13. Tiempo",
  step13_addMeeting_text: "Determina el tiempo estimado para el item.",
  step14_addMeeting_title: "14. Archivos",
  step14_addMeeting_text: "Sube archivos relevantes para la minuta.",
  step15_addMeeting_title: "15. Añadir",
  step15_addMeeting_text: "Haz click aqui para añadir el item.",
  step16_addMeeting_title: "16. Crear",
  step16_addMeeting_text: "Haz click aqui para crear la minuta.",
  step_final_addMeeting: "Haz aprendido cómo crear una reunión.",
  // VIEW - guide me steps
  step1_viewMeeting_title: "1. Abrir minuta.",
  step1_viewMeeting_text: "Haz click aqui para abrir una minuta.",
  step2_viewMeeting_title: "2. Información",
  step2_viewMeeting_text:
    "En esta sección podrás ver la información principal de la minuta.",
  step3_viewMeeting_title: "3. URL",
  step3_viewMeeting_text:
    "Haz click para abrir el url, o en el icono de la derecha para copiarlo al portapapeles.",
  step4_viewMeeting_title: "4. Agenda",
  step4_viewMeeting_text:
    "En esta sección se encuentra la información de la agenda.",
  step5_viewMeeting_title: "5. Item",
  step5_viewMeeting_text:
    "Haz click sobre un item para poder ver su contenido.",
  step6_viewMeeting_title: "6. Tópico",
  step6_viewMeeting_text:
    "Haz click sobre un tópico para poder ver su contenido.",
  step7_viewMeeting_title: "7. Comentarios",
  step7_viewMeeting_text:
    "En esta sección, es posible agregar comentarios sobre los tópicos.",
  step8_viewMeeting_title: "8. Tareas",
  step8_viewMeeting_text:
    "Las tareas que se creen en el tópico se ubicarán aqui.",
  step_final_viewMeeting: "Haz aprendido cómo ver información de una reunión.",
  // EDIT - guide me steps
  step1_editMeeting_title: "1. Editar una reunión.",
  step1_editMeeting_text: "Haz click aqui para poder editar la minuta",
  step2_editMeeting_title: "2. Título",
  step2_editMeeting_text: "Edita el título de la minuta.",
  step3_editMeeting_title: "3. Privacidad",
  step3_editMeeting_text: "Edita la privacidad de la minuta.",
  step3_editMeeting_text_2:
    "Cualquier persona con acceso al módulo verá tu minuta",
  step3_editMeeting_text_3: "Solo los participantes podrán ver esta minuta",
  step4_editMeeting_title: "4. Fecha de minuta",
  step4_editMeeting_text: "Edita la fecha y hora de la minuta.",
  step5_editMeeting_title: "5. Ubicación",
  step5_editMeeting_text: "Edita cuál es la ubicación de la minuta.",
  step6_editMeeting_title: "6. URL de la minuta",
  step6_editMeeting_text: "Edita el URL de la minuta.",
  step7_editMeeting_title: "7. Edita el estatus de la minuta",
  step7_editMeeting_text_1_1: "Elige el estatus",
  step7_editMeeting_text_1_2: "ABIERTO para las personas involucrada.",
  step7_editMeeting_text_2_1: "Elige el estatus",
  step7_editMeeting_text_2_2: "BORRADOR para guardar como borrador.",
  step8_editMeeting_title: "8. Asistentes",
  step8_editMeeting_text:
    "Agrega o elimina a las personas requeridas para la junta.",
  step9_editMeeting_title: "9. Lista de distribución",
  step9_editMeeting_text:
    "Edita a las personas que recibirán notificación de la minuta.",
  step10_editMeeting_title: "10. Nombre del item",
  step10_editMeeting_text: "Edita el nombre del item",
  step11_editMeeting_title: "11. Tópicos a discutir",
  step11_editMeeting_text: "Edita los tópicos del item",
  step12_editMeeting_title: "12. Tiempo",
  step12_editMeeting_text: "Edita el tiempo estimado para el item",
  step13_editMeeting_title: "13. Archivos",
  step13_editMeeting_text:
    "Edita los archivos que se encuentran, o agrega nuevos.",
  step14_editMeeting_title: "14. Nuevo elemento de agenda",
  step14_editMeeting_text: "Agrega un nuevo item a la minuta.",
  step15_editMeeting_title: "15. Guardar",
  step15_editMeeting_text:
    "Haz click aqui para guardar los cambios a la minuta.",
  step_final_editMeeting: "Haz aprendido cómo editar una reunión.",
  // RECYCLE - guide me steps
  step1_recycleMeeting_title: "2. Papelera de minutas",
  step1_recycleMeeting_text: "Haz clic aquí para abrir la papelera de minutas.",
  step2_recycleMeeting_title: "3. Menú de la papelera",
  step2_recycleMeeting_text: "Este es el menú de la papelera de minutas.",
  step3_recycleMeeting_title: "4. Ver un minuta",
  step3_recycleMeeting_text:
    "Haz click aquí para poder ver cual es la información de la minuta que se quiere restaurar",
  step4_recycleMeeting_title: "5. Selecciona minutas",
  step4_recycleMeeting_text: "Selecciona las minutas que se quieren restaurar.",
  step5_recycleMeeting_title: "6. Restaurar minutas seleccionadas",
  step5_recycleMeeting_text:
    "Haz click aquí para restaurar las minutas seleccionadas.",
  step_final_recycleMeeting:
    "Haz aprendido cómo interactuar con la papelera de minutas.",
  // SETTINGS - guide me steps
  step1_settingsMeeting_title: "2. Abrir menu de configuración",
  step1_settingsMeeting_text:
    "Haz click aquí para abrir el menu de configuración.",
  step2_settingsMeeting_title: "3. Lista de distribución",
  step2_settingsMeeting_text:
    "Agrega o elimina las personas que tendrán acceso de manera predeterminada al modulo de minutas.",
  step3_settingsMeeting_title: "4. Guardar",
  step3_settingsMeeting_text: "Haz click aquí para guardar los ajustes.",
  step_final_settingsMeeting: "Haz aprendido cómo ajustar la configuración.",
  // EXPORT - guide me steps
  step1_exportMeeting_title: "1. Exportar minuta",
  step1_exportMeeting_text:
    "Haz click aqui para exportar la información de la minuta por medio de correo electrónico.",
  step_final_exportMeeting: "Haz aprendido cómo exportar una minuta.",

  meetings: "Reuniones",
  addMeeting: "Programar una Reunión",
  deleteMeeting: "Eliminar Reunión",
  messageDelete:
    "¿Estás seguro de querer eliminar las reuniones seleccionadas? Al realizar esta acción, no podrá revertirse.",
  meetingDeleted: "Reunión eliminada exitosamente",
  errorMeetingDeleted: "No se pudo eliminar la reunión",
  meetingRecovered: "Reunión restaurada exitosamente",
  errorMeetingRecovered: "No se puede restaurar la reunión",
  meetingsRecycleBin: "Papelera de Reuniones",
  meetingCreated: "Reunión creada exitosamente",
  errorMeetingCreated: "No se puede crear la reunión",
  meetingUpdated: "Reunión actualizada exitosamente",
  errorMeetingUpdated: "No se puede actualizar la reunión",
  editMeeting: "Editar Reunión",
  urlMeeting: "URL de Reunión",
  agenda: "Agenda",
  meetingStarted: "Reunión iniciada exitosamente",
  errorMeetingStarted: "No se puede iniciar la reunión",
  meetingClosed: "Reunión cerrada exitosamente",
  errorMeetingClosed: "No se puede cerrar la reunión",
  startMeeting: "Iniciar reunión",
  noAgendaItems: "No hay elementos de agenda",
  closeMeeting: "Cerrar reunión",
  noElementsTitle: "¡Hora de empezar!",
  noElementsSubtitle:
    "¿Por qué no creas tu primer meeting para planear tus próximas tareas? Haz clic en el botón de abajo para empezar.",
  newAgendaItem: "Nuevo Elemento de Agenda",
  topicsToDiscuss: "Temas a discutir",
  attachOrDrop: "Adjuntar archivo(s) o arrastra y suelta aquí",
  viewFiles: "Ver archivos",
  noTopics: "No hay temas",
  taskSuccessfullyDeleted: "Tarea eliminada exitosamente",
  taskCantDelete: "No se puede eliminar la tarea",
  taskName: "Nombre de la tarea",
  newTask: "Nueva Tarea",
  deleteTask: "Eliminar Tarea",
  deleteTaskQuestion: "¿Estás seguro de querer eliminar esta tarea?",
  noMeetings: "No hay reuniones en la papelera",
  confirmedAssistance: "Asistencia confirmada",
};
