export const materialTable = { // Zh Hant
  actions: '動作',
  and: '與',
  cancel: '取消',
  changeFilterMode: '更改過濾模式',
  changeSearchMode: '更改搜尋模式',
  clearFilter: '清除過濾',
  clearSearch: '清除搜尋',
  clearSelection: '清除選擇',
  clearSort: '清除排序',
  clickToCopy: '點擊以複製',
  copy: '複製',
  collapse: '折疊',
  collapseAll: '全部折疊',
  columnActions: '欄位動作',
  copiedToClipboard: '已複製至剪貼簿',
  dropToGroupBy: '拖放以按 {column} 分組',
  edit: '編輯',
  expand: '展開',
  expandAll: '全部展開',
  filterArrIncludes: '包括',
  filterArrIncludesAll: '包括所有',
  filterArrIncludesSome: '包括',
  filterBetween: '介於首尾',
  filterBetweenInclusive: '介於首尾之間',
  filterByColumn: '以 {column} 過濾',
  filterContains: '包含',
  filterEmpty: '是空字串',
  filterEndsWith: '結尾為',
  filterEquals: '等於',
  filterEqualsString: '等於',
  filterFuzzy: '模糊搜索',
  filterGreaterThan: '大於',
  filterGreaterThanOrEqualTo: '大於等於',
  filterInNumberRange: '介於',
  filterIncludesString: '包含',
  filterIncludesStringSensitive: '包含',
  filterLessThan: '小於',
  filterLessThanOrEqualTo: '小於等於',
  filterMode: '過濾模式：{filterType}',
  filterNotEmpty: '非空字串',
  filterNotEquals: '不等於',
  filterStartsWith: '開首為',
  filterWeakEquals: '等於',
  filteringByColumn: '正以 {column} 過濾：{filterType} {filterValue}',
  goToFirstPage: '到第一頁',
  goToLastPage: '到最後一頁',
  goToNextPage: '下一頁',
  goToPreviousPage: '上一頁',
  grab: '拖曳',
  groupByColumn: '按 {column} 分組',
  groupedBy: '分組依據 ',
  hideAll: '隱藏全部',
  hideColumn: '隱藏 {column} 欄位',
  max: '最大',
  min: '最小',
  move: '移動',
  noRecordsToDisplay: '無紀錄可顯示',
  noResultsFound: '未找到結果',
  of: '/',
  or: '或',
  pin: '固定',
  pinToLeft: '固定至左邊',
  pinToRight: '固定至右邊',
  resetColumnSize: '重置欄位大小',
  resetOrder: '重置排序',
  rowActions: '橫列動作',
  rowNumber: '#',
  rowNumbers: '橫列編號',
  rowsPerPage: '每頁數量',
  save: '儲存',
  search: '搜尋',
  selectedCountOfRowCountRowsSelected: '已選擇橫列：{selectedCount}/{rowCount}',
  select: '選擇',
  showAll: '顯示全部',
  showAllColumns: '顯示全部欄位',
  showHideColumns: '顯示/隱藏 欄位',
  showHideFilters: '顯示/隱藏 過濾條件',
  showHideSearch: '顯示/隱藏 搜尋條件',
  sortByColumnAsc: '按 {column} 順序排序',
  sortByColumnDesc: '按 {column} 倒序排序',
  sortedByColumnAsc: '已按 {column} 順序排序',
  sortedByColumnDesc: '已按 {column} 倒序排序',
  thenBy: '，然後以',
  toggleDensity: '切換密度',
  toggleFullScreen: '切換全螢幕',
  toggleSelectAll: '切換選擇全部',
  toggleSelectRow: '切換選擇橫列',
  toggleVisibility: '切換可見度',
  ungroupByColumn: '取消按 {column} 分組',
  unpin: '取消固定',
  unpinAll: '全部取消固定',
};
