import {
  Notifications,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Search,
  Tune,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useGetUserProjectsByEnterpriseQuery } from "../../../features/enterprise/enterpriseApiSlice";
import {
  useGetNotificationsByEnterpriseQuery,
  useMarkAllAsReadMutation,
} from "../../../features/notificationCenter/notificationCenterApiSlice";
import { TooltipIconButton } from "../TooltipIconButton";
import { NotificationCard } from "./NotificationCard";
import { usePagination } from "../../../hooks";
import { InfiniteList } from "../../InfiniteList";

const notificationModules = [
  { value: "rfi", label: "rfi" },
  { value: "drawing", label: "drawing" },
  { value: "task", label: "task" },
  { value: "dailyReport", label: "report" },
  { value: "image", label: "image" },
  { value: "document", label: "documents" },
  { value: "inspection_v2", label: "inspection" },
  { value: "meeting", label: "meeting" },
  { value: "submittal_v2", label: "submittals" },
];

function ButtonFilter({ value, onChange }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tNotificationCenter } = useTranslation("notificationCenter");

  const [anchorEl, setAnchorEl] = useState(null);
  const [localValue, setLocalValue] = useState(value);

  return (
    <>
      <TooltipIconButton
        icon={<Tune />}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        label={tGeneral("filters")}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e) => {
          setAnchorEl(null);
          e.stopPropagation();
        }}
      >
        <MenuItem onClick={() => setLocalValue("all")} sx={{ p: 0 }}>
          <Checkbox
            checked={localValue === "all"}
            checkedIcon={<RadioButtonChecked />}
            icon={<RadioButtonUnchecked />}
          />
          <ListItemText primaryTypographyProps={{ fontSize: "0.875rem" }}>
            {tGeneral("all")}
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => setLocalValue("read")} sx={{ p: 0 }}>
          <Checkbox
            checked={localValue === "read"}
            checkedIcon={<RadioButtonChecked />}
            icon={<RadioButtonUnchecked />}
          />
          <ListItemText primaryTypographyProps={{ fontSize: "0.875rem" }}>
            {tNotificationCenter("read")}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setLocalValue("unread")} sx={{ p: 0 }}>
          <Checkbox
            checked={localValue === "unread"}
            checkedIcon={<RadioButtonChecked />}
            icon={<RadioButtonUnchecked />}
          />
          <ListItemText primaryTypographyProps={{ fontSize: "0.875rem" }}>
            {tNotificationCenter("unread")}
          </ListItemText>
        </MenuItem>
        <Divider />
        <ListItemButton>
          <Button
            onClick={() => {
              onChange(localValue);
              setAnchorEl(null);
            }}
            variant="contained"
            sx={{ textTransform: "none" }}
          >
            {tGeneral("showResults")}
          </Button>
        </ListItemButton>
      </Menu>
    </>
  );
}

function FilterSelect({ value, onChange, options, label, allLabel }) {
  const [search, setSearch] = useState("");
  const [localValue, setLocalValue] = useState([]);
  const { t: tGeneral } = useTranslation("general");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (value) {
      setLocalValue(value);
    }
  }, [value]);

  const handleShowResults = () => {
    onChange(localValue);
    setIsOpen(false);
  };

  const handleClose = () => {
    setLocalValue(value);
    setSearch("");
    setIsOpen(false);
  };

  const handleChange = (e, el) => {
    const newVal = [...e.target.value];
    if (el.props.value === "all") {
      setLocalValue(["all"]);
      return;
    }
    if (newVal.length > 1 && newVal.includes("all")) {
      newVal.splice(newVal.indexOf("all"), 1);
    }
    setLocalValue(newVal.filter((v) => v && v.length > 0));
  };

  return (
    <Select
      open={isOpen}
      size="small"
      onOpen={() => setIsOpen(true)}
      value={localValue ?? []}
      multiple
      renderValue={(val) =>
        `${label} (${val.includes("all") ? options.length : val.length})`
      }
      onChange={handleChange}
      onClose={handleClose}
      sx={{ minWidth: "120px" }}
      MenuProps={{
        sx: { maxHeight: "500px", overflowY: "auto", p: "0.5rem" },
      }}
    >
      <TextField
        onClick={(e) => e.stopPropagation()}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        placeholder={tGeneral("search")}
        onKeyDown={(e) => e.stopPropagation()}
        size="small"
        InputProps={{
          startAdornment: <Search />,
          sx: { maxHeight: "26px" },
        }}
        sx={{ mb: "0.5rem" }}
        fullWidth
      />
      <MenuItem value="all" sx={{ padding: 0 }}>
        <Checkbox
          checkedIcon={<RadioButtonChecked />}
          icon={<RadioButtonUnchecked />}
          checked={localValue.includes("all")}
        />
        <ListItemText primaryTypographyProps={{ fontSize: "0.75rem" }}>
          {allLabel} ({options.length})
        </ListItemText>
      </MenuItem>
      <Divider />
      {options
        .filter((o) => o.label.match(new RegExp(search, "g")) || search === "")
        .map((option) => (
          <MenuItem key={option.value} value={option.value} sx={{ padding: 0 }}>
            <Checkbox
              checkedIcon={<RadioButtonChecked />}
              icon={<RadioButtonUnchecked />}
              checked={
                localValue.includes(option.value) || localValue.includes("all")
              }
            />
            <ListItemText primaryTypographyProps={{ fontSize: "0.75rem" }}>
              {option.label}
            </ListItemText>
          </MenuItem>
        ))}
      <Divider />
      <ListItemButton sx={{ position: "sticky", bottom: "0" }}>
        <Button
          onClick={handleShowResults}
          disabled={localValue.length === 0}
          size="small"
          fullWidth
          variant="contained"
          sx={{
            textTransform: "none",
            background: "linear-gradient(90deg, #FBCB04 0%, #E5E900 100%)",
            borderRadius: "0.75rem",
          }}
        >
          {tGeneral("showResults")}
        </Button>
      </ListItemButton>
    </Select>
  );
}

function NotificationsDrawer({ isOpen, onClose }) {
  const { idEnterprise } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { t: tNotificationCenter } = useTranslation("notificationCenter");
  const [notifications, setNotifications] = useState([]);
  const [filters, setFilters] = useState({
    projects: ["all"],
    modules: ["all"],
    status: "all",
  });

  const { currentPage, perPage, handleFirstPage, handleNextPage } =
    usePagination(50);

  const [markAllAsRead, { isLoading: isAllReadLoading }] =
    useMarkAllAsReadMutation();

  const {
    data: notificationsData,
    isLoading,
    isFetching,
    refetch,
  } = useGetNotificationsByEnterpriseQuery(
    { idEnterprise, page: currentPage, pageSize: perPage, ...filters },
    { skip: !idEnterprise || !isOpen, refetchOnMountOrArgChange: true },
  );

  const { data: projects } = useGetUserProjectsByEnterpriseQuery(idEnterprise);
  const projectsOptions = useMemo(
    () =>
      projects?.projects
        ? projects.projects.map((p) => ({ value: p.id, label: p.projectName }))
        : [],
    [projects],
  );

  useEffect(() => {
    if (notificationsData) {
      setNotifications((prev) => [...prev, ...notificationsData.data]);
    }
  }, [notificationsData]);

  const reset = useCallback(
    (handleRefetch = false) => {
      handleFirstPage();
      setNotifications([]);

      if (handleRefetch) {
        setTimeout(() => {
          refetch();
        }, 250);
      }
    },
    [handleFirstPage, refetch],
  );

  useEffect(() => {
    if (isOpen) {
      reset(true);
    }
  }, [isOpen]);

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        onClose();

        setTimeout(() => {
          reset();
        }, 250);
      }}
      anchor="right"
      sx={{
        "& .MuiDrawer-paper": {
          width: "40%",
          maxHeight: "100vh",
          overflow: "hidden",
        },
      }}
    >
      <Box
        display="flex"
        px="1rem"
        columnGap="0.5rem"
        mt="0.5rem"
        alignItems="center"
      >
        <Notifications sx={{ color: "#E6B60C" }} />
        <Typography fontWeight="700">{tGeneral("activity")}</Typography>
        <Box flex={1} />
        <ButtonFilter
          value={filters.status}
          onChange={(newVal) => {
            setFilters((prev) => ({ ...prev, status: newVal }));

            setTimeout(() => {
              reset();
            }, 250);
          }}
        />
      </Box>
      <Box display="flex" columnGap="0.5rem" px="1rem">
        <FilterSelect
          options={projectsOptions}
          allLabel={tGeneral("allProjects")}
          label={tGeneral("projects")}
          value={filters.projects}
          onChange={(newVal) => {
            setFilters((prev) => ({ ...prev, projects: newVal }));

            setTimeout(() => {
              reset();
            }, 250);
          }}
        />
        <FilterSelect
          options={notificationModules.map((m) => ({
            ...m,
            label: tGeneral(m.label),
          }))}
          allLabel={tGeneral("allModules")}
          label={tGeneral("modules")}
          value={filters.modules}
          onChange={(newVal) => {
            setFilters((prev) => ({ ...prev, modules: newVal }));

            setTimeout(() => {
              reset();
            }, 250);
          }}
        />
        <Box flex={1} />
        <Button
          variant="text"
          sx={{ textTransform: "none" }}
          disabled={notifications.every((n) => n.read)}
          onClick={() => {
            markAllAsRead(idEnterprise);

            setTimeout(() => {
              reset();
            }, 250);
          }}
        >
          {isAllReadLoading ? (
            <CircularProgress size="1rem" />
          ) : (
            tNotificationCenter("markAsRead")
          )}
        </Button>
      </Box>

      <Divider sx={{ mt: "0.5rem" }} />
      <Box
        pr="8px"
        pl="16px"
        mt="12px"
        overflow="auto"
        id="notifications-scroll-container"
      >
        <InfiniteList
          gridTemplateColumns="1fr"
          fetchMore={isOpen && !isFetching && handleNextPage}
          hasMore={!!notificationsData?.hasMore}
          isLoading={isLoading}
          rows={notifications}
          renderRow={(notification) => (
            <NotificationCard
              key={notification.id}
              notification={notification}
              closeDrawer={onClose}
              onUpdate={reset}
            />
          )}
          spacing="10px"
          isGrid
        />
      </Box>
    </Drawer>
  );
}

export { NotificationsDrawer };
