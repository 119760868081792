// @ts-check

import { Slide } from "@mui/material";
// eslint-disable-next-line no-unused-vars
import React, { forwardRef } from "react";

const TransitionComponent = forwardRef((props, ref) => {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

export default TransitionComponent;
