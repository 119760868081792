import { ListItemIcon, Typography } from "@mui/material";

const SupportMenuItemHeader = ({ children, title }) => {
  return (
    <>
      <ListItemIcon>{children}</ListItemIcon>
      <Typography textAlign="center">{title}</Typography>
    </>
  );
};

export { SupportMenuItemHeader };
