/* eslint-disable react/prop-types */

import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { TitleWidgets } from "../../components/shared";
import {
  useGetInspectionByIdGraphQuery,
  useGetInspectionByIdQuery,
} from "../../features/project/modules/inspections/apiSlice";
import Can from "../../utils/can";
import { Fragment } from "react";

const statusColor = {
  "N/A": "#999999",
  fail: "red",
  pass: "#45B763",
};

function InspectionsGraph() {
  const { idInspection, idProject } = useParams();

  const { t: tGeneral } = useTranslation("general");
  const { t: tInspections } = useTranslation("inspections");
  const { data: inspection } = useGetInspectionByIdQuery(
    { idInspection, idProject },
    {
      skip: !idInspection || !idProject,
    },
  );
  const { data: graph } = useGetInspectionByIdGraphQuery(
    { idInspection, idProject },
    {
      skip: !idInspection || !idProject,
    },
  );

  if (!graph || !inspection) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <CircularProgress size="4rem" />
      </Box>
    );
  }

  return (
    <Can I="view" a="inspections">
      <Box display="flex" flexDirection="column" gap={2} width={1}>
        {/* Title */}
        <TitleWidgets
          title={` ${inspection.title} #${inspection.number} - ${tGeneral(
            "history",
          )}`}
          backEnabled
        />
        <TableContainer component={Box} maxHeight="80vh" p="1rem">
          <Table
            sx={{
              border: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            {graph.sections.map((section, s, sections) => (
              <Fragment key={`section-fragment-${s}`}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                    >
                      {section[0]}
                    </TableCell>
                    {graph.revisions.map((revision) => (
                      <TableCell
                        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                        key={`cell-revision-${inspection.number}-${revision}`}
                      >
                        {" "}
                        Revision {inspection.number}.{revision}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {section[1].map((item, i) => (
                    <TableRow key={`table-row-${i}`}>
                      <TableCell
                        sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
                      >
                        {item}
                      </TableCell>
                      {graph.revisions.map((revision) => {
                        const status =
                          graph.statuses?.[s + revision * sections.length]?.[
                            i
                          ]?.[0] ?? "N/A";
                        return (
                          <TableCell
                            sx={{
                              borderRight: "1px solid rgba(224, 224, 224, 1)",
                              color: statusColor[status],
                            }}
                            key={`cell-status-${i}`}
                          >
                            {status !== "N/A" ? tInspections(status).toUpperCase() : status}{" "}
                            {status === "pass" &&
                              `(${
                                graph.statuses?.[
                                  s + revision * sections.length
                                ]?.[i]?.[1] ?? ""
                              })`}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Fragment>
            ))}
          </Table>
        </TableContainer>
      </Box>
    </Can>
  );
}

export default InspectionsGraph;
