import { useAbility } from "@casl/react";
import { Edit, Search } from "@mui/icons-material";
import { Box, Switch, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useChangeEnterpriseUserStatusMutation } from "../features/enterprise/enterpriseApiSlice";
import CaslContext from "../utils/caslContext";
import stringUtil from "../utils/string";
import { PopUpAlert } from "./PopUpAlert";
import { ModalWithAction, StyledMaterialReactTable, TooltipIconButton } from "./shared";
import UserAvatar from "./userAvatars/UserAvatar";
import { ModalEditEnterpriseUser } from "../views/enterpriseDashboard/Directory/ModalEditEnterpriseUser";

function DirectoryTable({ data = [], setSearch }) {
  const { idEnterprise } = useParams();
  const ability = useAbility(CaslContext);
  const { t: tGeneral } = useTranslation("general");
  const [debounceSearch, setDebounceSearch] = useState("");
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [changeStatusUserId, setChangeStatusUserId] = useState(null);
  const [
    changeUserStatus,
    { isLoading: isChangeStatusLoading, isSuccess, isError, error },
  ] = useChangeEnterpriseUserStatusMutation();

  const handleUpdateUserStatus = () => {
    changeUserStatus({ idEnterprise, idUser: changeStatusUserId });
  };
  const timerIdRef = useRef(null);

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert("success", tGeneral("Done"), tGeneral("updatedSuccessfully"));
      setChangeStatusUserId(null);
    }

    if (isError) {
      PopUpAlert("error", "Error", error.message);
      setChangeStatusUserId(null);
    }
  }, [isSuccess, isError, error, tGeneral]);
  const columns = useMemo(() => {
    const cols = [
      {
        header: tGeneral("name"),
        accessorKey: "firstName",
        enableGrouping: false,
        Cell: ({ cell, row }) => {
          const user = row.original;
          return (
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              columnGap="10px"
              width="80%"
            >
              <UserAvatar
                key={user?.id}
                name={`${stringUtil.capitalizeFirstLetter(
                  user?.firstName?.trim(),
                )} ${stringUtil.capitalizeFirstLetter(user?.lastName?.trim())}`}
                size="Normal"
                url={user?.urlAvatar}
              />
              <Typography>{`${stringUtil.capitalizeFirstLetter(
                cell.getValue(),
              )}`}</Typography>
            </Box>
          );
        },
        size: 50,
      },
      {
        header: tGeneral("lastName"),
        accessorKey: "lastName",
        enableGrouping: false,
        Cell: ({ cell, row }) => {
          return (
            <Typography>{`${stringUtil.capitalizeFirstLetter(
              cell.getValue(),
            )}`}</Typography>
          );
        },
        size: 50,
      },
      {
        header: tGeneral("email"),
        accessorKey: "email",
        enableGrouping: false,
        size: 100,
        Cell: ({ cell, row }) => {
          return (
            <a href={`mailto:${cell.getValue()}`}>
              <Typography
                sx={{
                  textDecoration: "underline",
                  textTransform: "lowercase",
                  color: "#009DFF",
                }}
              >
                {`${cell.getValue()}`}
              </Typography>
            </a>
          );
        },
      },
      {
        header: tGeneral("phone"),
        accessorKey: "phone",
        enableGrouping: false,
        Cell: ({ cell, row }) => {
          return (
            <a href={`tel:${cell.getValue()}`}>
              <Typography>{`${cell.getValue()}`}</Typography>
            </a>
          );
        },
        size: 80,
      },
      {
        header: tGeneral("company"),
        accessorKey: "companyName",
        Cell: ({ cell, row }) => {
          return (
            <Typography>{`${stringUtil.capitalizeFirstLetter(
              cell.getValue(),
            )}`}</Typography>
          );
        },
        GroupedCell: ({ cell, row }) => (
          <Box>
            <Typography
              color="black"
              fontWeight="bold"
            >{`${stringUtil.capitalizeFirstLetter(cell.getValue())} (${
              row.subRows?.length
            })`}</Typography>
          </Box>
        ),
        size: 50,
      },
    ];

    if (ability.can("edit", "user")) {
      cols.push({
        header: tGeneral("active"),
        accessorKey: "bActive",
        enableGrouping: false,
        Cell: ({ cell, row }) => (
          <Switch
            checked={cell.getValue()}
            onChange={() => setChangeStatusUserId(row.original.id)}
          />
        ),
        size: 50,
      });
    }
    return cols;
  }, [ability.rules, tGeneral]);

  const handleChangeSearch = (e) => {
    const {
      target: { value },
    } = e;
    setDebounceSearch(value);
    clearTimeout(timerIdRef.current);
    timerIdRef.current = setTimeout(() => {
      setSearch(value);
    }, 500);
  };

  return (
    <>
      <StyledMaterialReactTable
        columns={columns}
        enableGrouping
        filtersComponent={
          <Box display="flex" justifyContent="flex-end" p="10px">
            <TextField
              onChange={handleChangeSearch}
              value={debounceSearch}
              size="small"
              placeholder={tGeneral("search")}
              InputProps={{ endAdornment: <Search /> }}
            />
          </Box>
        }
        data={data}
        initialState={{
          density: "comfortable",
          expanded: true, // expand all groups by default
          grouping: ["companyName"], // an array of columns to group by by default (can be multiple)
          sorting: [{ id: "firstName", desc: false }], // sort by firstName by default
        }}
        sizeActions={35}
        enableRowActions={ability.can("edit", "user")}
        renderRowActions={({ row }) => (
          <TooltipIconButton
            icon={<Edit />}
            label={tGeneral("edit")}
            onClick={() => {
              setSelectedUser(row.original)
              setIsEditOpen(true)
            }}
            color="black"
            disabled={!ability.can("edit", "user")}
          />
        )}
      />
      <ModalWithAction
        title={tGeneral("changeStatus")}
        actionLabel={tGeneral("accept")}
        content={tGeneral("changeStatusContent")}
        isOpen={Boolean(changeStatusUserId)}
        onClose={() => setChangeStatusUserId(null)}
        action={handleUpdateUserStatus}
        isLoading={isChangeStatusLoading}
      />
      {selectedUser &&
        <ModalEditEnterpriseUser
          user={selectedUser}
          isOpen={isEditOpen}
          onClose={() => {
            setSelectedUser(null)
            setIsEditOpen(false)
          }}
        />
      }
    </>
  );
}

export default DirectoryTable;
