import { WhatsApp } from "@mui/icons-material";
import { Box, Fab, useMediaQuery } from "@mui/material";
import { Companies } from "./Companies";
import { DocumentSharing } from "./documentSharing";
import { Download } from "./Download";
import { Footer } from "./Footer";
import { Navbar } from "./navbar";
import { SignUpForm } from "./SignUpForm";
import { TopSection } from "./TopSection";
import { VideoSection } from "./VideoSection";
import { WhyBuildpeer } from "./WhyBuildpeer";

function Landing() {
  const isDesktop = useMediaQuery("(min-width: 600px)");
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {isDesktop && (
        <Fab
          color="primary"
          sx={{ position: "fixed", bottom: "16px", right: "16px" }}
        >
          <WhatsApp />
        </Fab>
      )}
      <Navbar />
      <video
        autoPlay
        muted
        loop
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          minWidth: "100%",
          minHeight: "100%",
          backgroundSize: "cover",
          zIndex: -1000,
        }}
      >
        <source src={require("../../assets/video/home.mp4")} type="video/mp4" />
      </video>
      <TopSection />
      <Companies />
      <DocumentSharing />
      <VideoSection />
      <WhyBuildpeer />
      <Download />
      <SignUpForm />
      <Footer />
    </Box>
  );
}

export { Landing };
