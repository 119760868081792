import { Facebook, Instagram } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";

const sections = [
  { label: "Service", url: "" },
  { label: "Pricing", url: "" },
  { label: "Log In", url: "" },
  { label: "Sign Up", url: "" },
];

function Footer() {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Box sx={{ backgroundColor: "#FAFBFB", width: "100%" }} px="10%" pt="80px">
      <Container
        sx={[
          { display: "flex", justifyContent: "space-between" },
          isMobile && { flexDirection: "column" },
        ]}
      >
        <Box
          maxWidth="540px"
          display="flex"
          flexDirection="column"
          mb={isMobile ? "45px" : "0"}
          alignItems="center"
        >
          <img
            src={require("./../../assets/logo.png")}
            alt="Build Peer Logo"
            height={isMobile ? "50px" : "67px"}
            style={{ marginBottom: isMobile ? "30px" : "0" }}
          />
          <Typography
            color="#6E7D94"
            textAlign={isMobile ? "center" : "initial"}
            fontSize={isMobile ? "16px" : "18px"}
          >
            Buildpeer is the platform where the construction team comes together
            to collaborate and share information in real-time.
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignContent={"flex-start"}
          mb={isMobile ? "45px" : "0"}
          alignItems={isMobile && "center"}
        >
          <Typography color="black" fontSize="18px">
            Sections
          </Typography>
          {sections.map((section) => (
            <Button
              key={section.label}
              variant="text"
              sx={{ color: "#6E7D94", justifyContent: "flex-start" }}
            >
              {section.label}
            </Button>
          ))}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignContent="flex-start"
          alignItems={isMobile && "center"}
        >
          <Typography color="black" fontSize="18px">
            Social media
          </Typography>
          <Button
            variant="text"
            sx={{ color: "#6E7D94", justifyContent: "flex-start" }}
            startIcon={<Facebook color="disabled" />}
          >
            Facebook
          </Button>
          <Button
            variant="text"
            sx={{ color: "#6E7D94", justifyContent: "flex-start" }}
            startIcon={<Instagram color="disabled" />}
          >
            Instagram
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            paddingTop: "40px",
          }}
          mb={isMobile ? "30px" : "0"}
        >
          <img
            src={require("./../../assets/download-android.png")}
            alt="Download Google Play"
            style={{ marginRight: "8px" }}
            height="42px"
          />
          <img
            src={require("./../../assets/download-apple.png")}
            alt="Download App Store"
            height="42px"
          />
        </Box>
      </Container>
      <Divider />
      <Box display="flex" justifyContent="space-around">
        <Button variant="text" sx={{ color: "#6E7D94", marginY: "34px" }}>
          Terms & Conditions
        </Button>
        <Button variant="text" sx={{ color: "#6E7D94", marginY: "34px" }}>
          All Rights Reserved Buildpeer
        </Button>
      </Box>
    </Box>
  );
}

export { Footer };
