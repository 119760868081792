export const enterprise = {
  construction: "Construction",
  realState: "Real State",
  architect: "Architecture",
  contractor: "Contractor",
  subcontractor: "Subcontractor",
  maintenance: "Maintenance",
  developer: "Developer",
  generalContractor: "General contractor",
  other: "Other",
};
