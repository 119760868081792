import { apiSlice } from "../../api/apiSlice";
import { objectToQueryString } from "../../utils";

// TODO: Move rfi endpoints to rfiApiSlice
// TODO: Make apiSlice per module

export const projectApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserProjects: builder.query({
      query: () => "/project/get",
      keepUnusedDataFor: 60,
      providesTags: ["Enterprises", "Projects"],
    }),
    createProject: builder.mutation({
      query: (body) => ({
        url: "project/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects", "Enterprises"],
    }),
    // get project types
    getProjectTypes: builder.query({
      query: (idEnterprise) =>
        `/project/projectTypes/getProjectTypes/${idEnterprise}`,
      providesTags: ["Projects"],
    }),

    // create project type
    createProjectTypes: builder.mutation({
      query: (body) => ({
        url: "/project/projectTypes/createProjectTypes",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects"],
    }),

    editProject: builder.mutation({
      query: (body) => ({
        url: "project/edit",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects"],
    }),
    getProjectById: builder.query({
      query: (idProject) => `/project/${idProject}`,
      providesTags: ["Projects"],
    }),
    getProjectEnterprise: builder.query({
      query: (idProject) => `/project/${idProject}/getProjectEnterprise`,
      keepUnusedDataFor: 10,
      providesTags: ["Projects"],
    }),
    // Project directory - get buildpeer users
    getBuildpeerUsers: builder.query({
      query: (idProject) => `/user/${idProject}/bpUsers`,
      keepUnusedDataFor: 10,
      providesTags: ["Projects"],
    }),
    // Project directory - get project users
    getProjectUsers: builder.query({
      query: (idProject) => `/project/${idProject}/getProjectUsers`,
      keepUnusedDataFor: 10,
      providesTags: ["Projects"],
    }),
    // Modules (get all project users)
    getAllProjectUsers: builder.query({
      query: (idProject) => `/project/${idProject}/getAllProjectUsers`,
      keepUnusedDataFor: 10,
      providesTags: ["Projects"],
    }),
    setUserRole: builder.mutation({
      query: (body) => ({
        url: "role/setUserRole",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects", "Enterprises"],
    }),
    getUserRole: builder.query({
      query: ({ idProject, idUser }) =>
        `role/getUserRole/${idProject}/${idUser}`,
      keepUnusedDataFor: 10,
      providesTags: ["Projects"],
    }),
    getProjectConfig: builder.mutation({
      query: (body) => ({
        url: "project/config/get",
        method: "POST",
        body,
      }),
    }),
    getProjectConfiguration: builder.query({
      query: (idProject) => ({
        url: `project/projectConfig/getProjectConfig/${idProject}`,
      }),
      providesTags: ["ProjectConfig"],
    }),
    updateProjectConfig: builder.mutation({
      query: (body) => ({
        url: "project/projectConfig/updateProjectConfig",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProjectConfig"],
    }),
    getProjectAnnouncements: builder.query({
      query: (params) =>
        `/project/${params.idProject}/getProjectAnnouncements?view=${params.view}`,
      keepUnusedDataFor: 10,
      providesTags: ["Projects", "Announcements"],
    }),
    // HOURS
    addEmployee: builder.mutation({
      query: (body) => ({
        url: "project/employee",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects"],
    }),
    addCode: builder.mutation({
      query: (body) => ({
        url: "project/code",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects"],
    }),
    getProjectEmployees: builder.query({
      query: (idProject) => `/project/${idProject}/getProjectEmployees`,
      keepUnusedDataFor: 10,
      providesTags: ["Projects"],
    }),
    getProjectCodes: builder.query({
      query: (idProject) => `/project/${idProject}/getProjectCodes`,
      keepUnusedDataFor: 10,
      providesTags: ["Projects"],
    }),
    addMachine: builder.mutation({
      query: (body) => ({
        url: "project/machine",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects"],
    }),
    addLocation: builder.mutation({
      query: (body) => ({
        url: "project/location",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects"],
    }),
    getProjectMachines: builder.query({
      query: (idProject) => `/project/${idProject}/getProjectMachines`,
      keepUnusedDataFor: 10,
      providesTags: ["Projects"],
    }),
    getProjectLocations: builder.query({
      query: (idProject) => `/project/${idProject}/getProjectLocations`,
      keepUnusedDataFor: 10,
      providesTags: ["Projects"],
    }),
    removeEmployee: builder.mutation({
      query: (body) => ({
        url: "project/removeEmployee",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects"],
    }),
    removeCode: builder.mutation({
      query: (body) => ({
        url: "project/removeCode",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects"],
    }),
    removeMachine: builder.mutation({
      query: (body) => ({
        url: "project/removeMachine",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects"],
    }),
    restoreMachine: builder.mutation({
      query: (body) => ({
        url: "project/restoreMachine",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects"],
    }),
    getProjectMachinesDeleted: builder.query({
      query: (idProject) => `/project/${idProject}/getProjectMachinesDeleted`,
      keepUnusedDataFor: 10,
      providesTags: ["Projects"],
    }),
    getProjectAdminPanelInfo: builder.query({
      query: (idProject) => `/project/${idProject}/getProjectAdminPanelInfo`,
      keepUnusedDataFor: 10,
      providesTags: ["AdminPanel"],
    }),
    updateProject: builder.mutation({
      query: (body) => ({
        url: "project/update",
        method: "POST",
        body,
      }),
      invalidatesTags: ["AdminPanel", "Projects"],
    }),
    getProjectPendings: builder.query({
      query: (idProject) => `/project/${idProject}/getProjectPendings`,
      providesTags: [
        "Dashboard",
        "Meetings",
        "RFIs",
        "Tasks",
        "DailyReports",
        "Submittals",
      ],
    }),
    getUserPendings: builder.query({
      query: (idProject) => `/project/${idProject}/getUserPendings`,
      providesTags: ["Dashboard"],
    }),
    getTodoList: builder.query({
      query: ({ idProject, bActive = true }) =>
        `project/todoList/${idProject}?bActive=${bActive}`,
      providesTags: ["TodoList"],
    }),
    addProjectTodo: builder.mutation({
      query: (body) => ({
        url: "project/todoList",
        method: "POST",
        body,
      }),
      invalidatesTags: ["TodoList"],
    }),
    updateProjectTodo: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `project/todoList/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["TodoList"],
    }),
    restoreProjectTodos: builder.mutation({
      query: (body) => ({
        url: "project/restoreTodoList",
        method: "POST",
        body,
      }),
      invalidatesTags: ["TodoList"],
    }),
    getProjectCurrentWeather: builder.query({
      query: (idProject) => `project/${idProject}/currentWeather`,
      keepUnusedDataFor: 28800,
    }),
    getProjectWeekWeather: builder.query({
      query: (idProject) => `project/${idProject}/weekWeather`,
      keepUnusedDataFor: 28800,
    }),
    getObjectsByModule: builder.query({
      query: ({ idProject, module }) =>
        `project/objectsByModule/${idProject}/${module}`,
      providesTags: ["Tasks", "RFIs"],
    }),
    getDistributionList: builder.query({
      query: ({ idProject, distributionListName }) =>
        `project/distributionList/${idProject}/${distributionListName}`,
      providesTags: ["DistributionList"],
    }),
    updateDistributionList: builder.mutation({
      query: (body) => ({
        url: "project/distributionList",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["DistributionList"],
    }),
    getProjectConfigRfi: builder.query({
      query: ({ idProject, distributionListName, rfiDaysName }) =>
        `project/configRfi/${idProject}/${distributionListName}/${rfiDaysName}`,
      providesTags: ["DistributionList"],
    }),
    updateProjectConfigRfi: builder.mutation({
      query: (body) => ({
        url: "project/configRfi",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["DistributionList"],
    }),
    getModuleSearchByText: builder.mutation({
      query: (body) => ({
        url: "project/moduleSearchByText",
        method: "POST",
        body,
      }),
      invalidatesTags: [],
    }),
    getNoAddedUsers: builder.query({
      query: ({ idEnterprise, idProject }) =>
        `project/noAddedUsers/${idEnterprise}/${idProject}`,
      providesTags: ["ProjectDirectoryUsers"],
    }),
    addUserToProject: builder.mutation({
      query: ({ idProject, ...body }) => ({
        url: `project/addUser/${idProject}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProjectDirectoryUsers"],
    }),
    getCopyInfo: builder.query({
      query: (idProject) => `project/copyInfo/${idProject}`,
    }),
    getActiveProjectUsers: builder.query({
      query: ({ idProject, moduleName }) =>
        `project/activeUsers/${idProject}${objectToQueryString({
          moduleName,
        })}`,
    }),
  }),
});

export const {
  useGetUserProjectsQuery,
  useCreateProjectMutation,
  useGetProjectTypesQuery,
  useCreateProjectTypesMutation,
  useGetProjectUsersQuery,
  useGetAllProjectUsersQuery,
  useSetUserRoleMutation,
  useGetUserRoleQuery,
  useGetProjectConfigMutation,
  useGetProjectConfigurationQuery,
  useUpdateProjectConfigMutation,
  useGetProjectEnterpriseQuery,
  useGetBuildpeerUsersQuery,
  useAddEmployeeMutation,
  useAddCodeMutation,
  useGetProjectEmployeesQuery,
  useGetProjectCodesQuery,
  useAddMachineMutation,
  useAddLocationMutation,
  useGetProjectMachinesQuery,
  useGetProjectLocationsQuery,
  useRemoveEmployeeMutation,
  useRemoveCodeMutation,
  useRemoveMachineMutation,
  useRestoreMachineMutation,
  useGetProjectMachinesDeletedQuery,
  useEditProjectMutation,
  useGetProjectByIdQuery,
  useGetProjectAdminPanelInfoQuery,
  useUpdateProjectMutation,
  useGetProjectPendingsQuery,
  useGetUserPendingsQuery,
  useAddProjectTodoMutation,
  useGetTodoListQuery,
  useUpdateProjectTodoMutation,
  useRestoreProjectTodosMutation,
  useGetProjectAnnouncementsQuery,
  useGetProjectCurrentWeatherQuery,
  useGetProjectWeekWeatherQuery,
  useGetObjectsByModuleQuery,
  useGetDistributionListQuery,
  useUpdateDistributionListMutation,
  useGetModuleSearchByTextMutation,
  useGetProjectConfigRfiQuery,
  useUpdateProjectConfigRfiMutation,
  useGetNoAddedUsersQuery,
  useAddUserToProjectMutation,
  useLazyGetCopyInfoQuery,
  useGetActiveProjectUsersQuery,
} = projectApiSlice;
