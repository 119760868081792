import { tour } from "./tour";

export const dailyReports = {
  ...tour,
  moduleElement: "Reports",
  dailyReports: "Daily Reports",
  reports: "Reports",
  emptyReportsTitle: "No reports created at this moment",
  emptyReportsContent:
    "If you want to add one, we invite you to click the add report button to start the process",
  emptyInactiveReportsTitle:
    "There are no reports in the recycle bin at the moment",
  emptyInactiveReportsContent: "Once you delete a report, it will show up here",
  addReport: "Add Report",
  addDailyReport: "Add Daily Report",
  editReport: "Edit Report",
  dueDate: "Due Date",
  newReport: "New report",
  creators: "Creators",
  assigned: "Assigned",
  distributionList: "Distribution List",
  private: "Private",
  num: "Num",
  title: "Title",
  status: "Status",
  closedDate: "Closed Date",
  actions: "Actions",
  detail: "Detail",
  exportPdf: "Export PDF",
  exportCvs: "Export CVS",
  fetchReportIssue: "There was an issue loading this report",
  retry: "Retry",
  createdDate: "Created Date",
  atHour: "at",
  lastSeen: "Last Seen",
  lastEdit: "Last Edit",
  approveReport: "Approve Report",
  dailyReportsRecycleBin: "Recycle Bin (Reports)",
  confirmDelete:
    "¿Are you sure you want to delete the selected daily report(s)? By performing this action, it cannot be reversed",
  successRecovered: "Daily Report recovered successfully",
  errorRecovered: "Can not restore Daily Report",
  successDeleted: "Daily Report deleted successfully",
  errorDeleted: "Can not delete Daily Report",
  approvedReport: "Approved report successfully",
  errorApprovedReport: "Can not approve the report",
  deleteReport: "Delete Report",
  report: "Report",
  addDescription: "Add a description",
  // other
  obligatory: "Obligatory",
  public: "Public",
  congratulations: "Congratulations!",
  rfi_title_module: "RFIs",
  no_rfis_title: "No RFIs have been created at the moment",
  no_rfis_text:
    "If you want to add one, we invite you to click the add RFI button to start the process",
  meeting: "Meeting",
  workflow: "Workflow",
  guideMe: "Guide me",
  support: "Support",
  days: "Days",
  rfi_recycle_bin: "RFI Recycle Bin",
  // add steps
  step1_addDR_title: "1. Add report",
  step1_addDR_text: "Click here to add new report",
  step2_addDR_title: "2. Title",
  step2_addDR_text: "Write a title that can describe your new report",
  step3_addDR_title: "3. Due Date",
  step3_addDR_text:
    "Choose a due date as deadline to receive approval from the assignee",
  step4_addDR_title: "4. Status",
  step4_addDR_text1_1: "Choose the status",
  step4_addDR_text1_2: "OPEN to Publish the report as active.",
  step4_addDR_text2_1: "Choose the",
  step4_addDR_text2_2:
    "DRAFT status to keep a draft of the report without publishing it.",
  step5_addDR_title: "5. Privacy",
  step5_addDR_text_1: "Choose the type of report privacy",
  step5_addDR_text_2: "Anyone with access to the module will see your report",
  step5_addDR_text_3: "Only participants will be able to see this report",
  step6_addDR_title: "6. Creators",
  step6_addDR_text:
    "Choose the users who will have the same permissions as you",
  step7_addDR_title: "7. Assignees",
  step7_addDR_text: "Select the people who will approve the report.",
  step8_addDR_title: "8. Distribution list",
  step8_addDR_text: "Select the people you want to notify about this report",
  step9_addDR_title: "9. Category",
  step9_addDR_text1_1: "Choose the category",
  step9_addDR_text1_2: "Basic to create multiple reports.",
  step9_addDR_text2:
    "Work Progress category to create reports with progress and cost measures.",
  step9_addDR_text3: "Problem category to create only one report.",
  step9_addDR_text4:
    "Delay category to create a report with days and hours of delay.",
  step10_addDR_title: "10. Add New Report",
  step10_addDR_text: "Click here to start new report",
  step11_addDR_title: "11. Select the location",
  step11_addDR_text:
    "Click here to select or add a location that will be reported on",
  step12_addDR_title: "12. Description",
  step12_addDR_text: "Write the description of the progress of work",
  step13_addDR_title: "13. Attach photos or files",
  step13_addDR_text: "Add photos or files to the description",
  step14_addDR_title: "14. Add",
  step14_addDR_text: "Click here to publish the report",
  stepFinal_addDR: "You have learned how to create a report",
  // VIEW - guide me steps
  step1_viewDR_title: "1. View Report",
  step1_viewDR_text: "Click here to visualize a report",
  step2_viewDR_title: "2. Category",
  step2_viewDR_text: "View the category to which the report belongs",
  step3_viewDR_title: "3. General Information Report",
  step3_viewDR_text: "Shows the complete information of this report",
  step4_viewDR_title: "{{stepNumber}}. Description report by location",
  step4_viewDR_text: "Shows the complete information of this report and photos",
  /* step4_viewDR_title: "4. Drawing",
  step4_viewDR_text: "View the location marked on the drawing", */
  step5_viewDR_title: "{{stepNumber}}. Comments",
  step5_viewDR_text: "You can comment on the reported locations",
  /* step6_viewDR_title: "6. Export PDF",
  step6_viewDR_text: "You can generate a complete progress report in PDF", */
  step7_viewDR_title: "{{stepNumber}}. Approve",
  step7_viewDR_text: "Approve the report",
  stepFinal_viewDR: "You have learned how to view a report",
  step_view_reports_description_title: "{{stepNumber}}. Description",
  step_view_reports_description_text: "View the full description of the report",
  step_view_report_progress_title: "{{stepNumber}}. Delay and Cost",
  step_view_report_progress_text:
    "Observe the progress and cost measures of the report",
  // EDIT - guide me steps
  step1_editDR_title: "1. Edit report",
  step1_editDR_text: "Click here to edit a report",
  step9_editDR_title: "{{stepNumber}}. Add New Report",
  step9_editDR_text: "Click here to start new report",
  step10_editDR_title: "{{stepNumber}}. Edit created reports",
  step10_editDR_text: "You can view and edit the information about the report",
  step11_editDR_title: "{{stepNumber}}. Files and information",
  step11_editDR_text: "Edit or add files to the created reports",
  step13_editDR_title: "{{stepNumber}}. Save",
  step13_editDR_text: "Click here to save the modified report",
  stepFinal_editDR: "You have learned how to edit an report",
  step11_edit_report_progress_title: "{{stepNumber}}. Advance and cost",
  step11_edit_report_progress_text:
    "Edit or add the progress and cost measures of the report",
  step10_edit_report_problem: "Modify the report description and files",
  step9_edit_report_delay_title: "9. Delay",
  step9_edit_report_delay_text:
    "Edit the number of days or hours the report is delayed",
  step10_edit_report_delay: "Modify the report description and files",
  // RECYCLE - guide me steps
  step1_recycleDR_title: "1. Recycle Bin Reports",
  step1_recycleDR_text: "Click here to open the recycle bin",
  step2_recycleDR_title: "2. Recycle Bin Menu",
  step2_recycleDR_text: "This is the menu fot the recycle bin",
  step3_recycleDR_title: "3. View a report",
  step3_recycleDR_text: "You can view the complete report",
  step4_recycleDR_title: "4. Restore report",
  step4_recycleDR_text: "Select one or several reports to restore",
  step5_recycleDR_title: "5. Restore reports selected",
  step5_recycleDR_text: "Click here to restore the reports selected.",
  stepFinal_recycleDR: "You have learned how to interact with the recycle bin",
  // EXPORT - guide me steps
  step1_exportDR_title: "1. Export PDF",
  step1_exportDR_text: "Click here to export the report in PDF format",
  step2_exportDR_title: "2. Send Email",
  step2_exportDR_text: "Click here to share this report by email",
  step3_exportDR_title: "3. Exports CSV",
  step3_exportDR_text:
    "Select some or filter all the reports to export in a CSV file",
  step4_exportDR_title: "4. Exports PDF",
  step4_exportDR_text:
    "Select some or filter all the reports to export in a PDF file",
  step5_exportDR_title: "5. Screen inside a report",
  step5_exportDR_text: "You can export in PDF from the detail view",
  stepFinal_exportDR: "You have learned how to export a report",
  // SETTINGS - guide me steps
  step1_settingsDR_title: "2. Report settings",
  step1_settingsDR_text: "Click here to modify the settings",
  step2_settingsDR_title: "3. Distribution List",
  step2_settingsDR_text:
    "Modify here the users who belong to the distribution list globally",
  step3_settingsDR_title: "4. Save settings",
  step3_settingsDR_text: "Click here to save the settings",
  stepFinal_settingsDR:
    "You have learned how to interact with the report settings",
  due: "Due Date",
  closed: "Closed",
  basic: "Basic",
  workProgress: "Work progress",
  problem: "Problem",
  delay: "Delay",
  advance: "Advance",
  unit: "Unit",
  addUnit: "Add unit",
  unitAdded: "Unit added successfully",
  risk: "Risk",
  highRisk: "High risk",
  mediumRisk: "Medium risk",
  lowRisk: "Low risk",
  workerName: "Worker name",
  jobType: "Job type",
  workedHours: "Worked hours",
  assistants: "Assistants",
  manpower: "Manpower",
  subcontractorMessage:
    "Today {{date}} a total of {{total}} people attended. Please review.",
  job: "Job",
  createReport: "Create Report",
  generalChart: "General Chart",
  approvedCorrectly: "Report approved\nsuccessfully",
  continueNavigate: "You can continue browsing",
  approvedAtAndBy: "Approved on {{date}} by",
};
