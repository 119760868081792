export const notificationActions = {
  CREATE: "Has created {{object}}",
  EDIT: "Has edited {{object}}",
  CLOSE: "Has closed {{object}}",
  RESTORE: "Has restored {{object}}",
  ANSWER: "Has answered a question in {{object}}",
  NEW_TOPIC: "Has created a new topic in {{object}}",
  COMMENT: "Has commented on {{object}}",
  REOPEN: "Has reopened {{object}}",
  OFFICIAL_RESPONSE: "Has marked a response as official in {{object}}",
  DRAFT: "Has created {{object}} as draft",
  APPROVE: "Has approved {{object}}",
  DELETE: "Has deleted {{object}}",
  START: "Has started {{object}}",
  CONFIRM: "Has confirmed attendance to {{object}}",
  CREATE_PHOTO: "Has uploaded a photo",
  CREATE_ALBUM: "Has created an album",
  CREATE_VIDEO: "Has created a video",
  CREATE_FOLDER: "Has created a folder",
  CREATE_FILE: "Has uploaded a file",
  MOVE_FILE: "Has moved a file",
  MOVE_FOLDER: "Has moved a folder",
  CREATE_SCHEDULED: "Has created a scheduled announcement",
  REVISION: "Has created a revision",
  CREATE_ANNOTATION: "Has created an annotation",
  ASSIGN: "Has assigned an annotation",
  LINK: "Has linked an annotation",
  SIGN: "Has signed {{object}}",
  REJECT: "Has rejected {{object}}",
  NEW_REVISION: "Has made a revision of this inspection",
  rfi: "an RFI",
  drawing: "a drawing",
  task: "a task",
  daily_report: "a report",
  dailyReport: "a report",
  image: "an image",
  document: "a document",
  inspection: "an inspection",
  meeting: "a meeting",
};
