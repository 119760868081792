import { tour } from "./tour";

export const documents = {
  // general tour
  ...tour,
  moduleElement: "문서",
  // ADD FILE - guide me steps
  step1_docsAddFile_title: "1. 추가",
  step1_docsAddFile_text: "+ 아이콘을 클릭하세요",
  step2_docsAddFile_title: "2. 파일 추가",
  step2_docsAddFile_text: "파일 추가 옵션을 선택하세요.",
  step3_docsAddFile_title: "3. 파일 업로드",
  step3_docsAddFile_text:
    "파일을 끌어다 놓거나 점선 사각형을 클릭하여 업로드하세요.",
  step4_docsAddFile_title: "4. 개인 정보",
  step4_docsAddFile_text:
    "활성화된 경우, 특정 회사 및 개인의 액세스가 제한됩니다.",
  step5_docsAddFile_title: "5. 추가",
  step5_docsAddFile_text: "파일을 추가하려면 여기를 클릭하세요.",
  step_final_docsAddFile: "파일 추가 방법을 배웠습니다.",
  // ADD FOLDER - guide me steps
  step1_docsAddFolder_title: "1. 추가",
  step1_docsAddFolder_text: "+ 아이콘을 클릭하세요",
  step2_docsAddFolder_title: "2. 폴더 추가",
  step2_docsAddFolder_text: "폴더 생성 옵션을 선택하세요.",
  step3_docsAddFolder_title: "3. 폴더 이름",
  step3_docsAddFolder_text: "폴더의 이름을 입력하세요.",
  step4_docsAddFolder_title: "4. 개인 정보",
  step4_docsAddFolder_text:
    "활성화된 경우, 특정 회사 및 개인의 액세스가 제한됩니다.",
  step5_docsAddFolder_title: "5. 추가",
  step5_docsAddFolder_text: "폴더를 추가하려면 여기를 클릭하세요.",
  step_final_docsAddFolder: "폴더 추가 방법을 배웠습니다.",
  // ACTIONS - guide me steps
  step1_docsActions_title: "1. 작업",
  step1_docsActions_text:
    "여기에서 파일/폴더에 대해 다양한 작업을 실행할 수 있습니다.",
  step2_docsActions_title: "2. 즐겨찾기로 선택",
  step2_docsActions_text: "파일을 즐겨찾기로 표시하려면 여기를 클릭하세요.",
  step3_docsActions_title: "3. 다운로드",
  step3_docsActions_text: "선택한 파일을 다운로드하려면 여기를 클릭하세요.",
  step5_docsActions_title: "4. 이름 편집",
  step5_docsActions_text: "파일 이름을 편집하려면 여기를 클릭하세요.",
  step6_docsActions_title: "5. 추가 작업",
  step6_docsActions_text:
    "더 많은 작업이 있는 메뉴를 열려면 3개의 점을 클릭하세요.",
  step7_docsActions_title: "6. 정보",
  step7_docsActions_text:
    "여기에서 파일 또는 폴더의 크기, 생성 날짜, 권한, 변경 기록 및 이메일 수신자 목록을 볼 수 있습니다. (이 기능에 대한 가이드 참조)",
  step8_docsActions_title: "7. 이동",
  step8_docsActions_text:
    "파일을 이동하기 위한 메뉴를 열려면 여기를 클릭하세요. (이 기능에 대한 가이드 참조)",
  step9_docsActions_title: "8. 복사",
  step9_docsActions_text: "파일을 클립보드에 복사하려면 여기를 클릭하세요.",
  step10_docsActions_title: "9. 삭제",
  step10_docsActions_text: "파일을 영구적으로 삭제하려면 여기를 클릭하세요.",
  step_final_docsActions:
    "파일/폴더에 대한 다양한 작업을 실행하는 방법을 배웠습니다.",
  // MOVE TO - guide me steps
  step1_docsMoveTo_title: "1. 추가 작업",
  step1_docsMoveTo_text:
    "3개의 점을 클릭하면 더 많은 작업이 있는 메뉴가 나타납니다.",
  step2_docsMoveTo_title: "2. 이동",
  step2_docsMoveTo_text:
    "파일을 이동하기 위한 메뉴를 열려면 여기를 클릭하세요.",
  step3_docsMoveTo_title: "3. 파일 이동",
  step3_docsMoveTo_text:
    "새로운 원하는 경로를 선택하고 파일을 이동하려면 확인을 클릭하세요.",
  step4_docsMoveTo_title: "4. 수락",
  step4_docsMoveTo_text:
    "새 위치를 확인하고 파일을 이동하기 위해 여기를 클릭하세요.",
  step_final_docsMoveTo: "파일/폴더를 이동하는 방법을 배웠습니다.",
  // FILE INFO - guide me steps
  step1_docsFileInfo_title: "1. 추가 작업",
  step1_docsFileInfo_text:
    "3개의 점을 클릭하면 더 많은 작업이 있는 메뉴가 나타납니다.",
  step2_docsFileInfo_title: "2. 정보",
  step2_docsFileInfo_text:
    "여기에서 파일 및/또는 폴더의 크기, 생성 날짜, 권한, 변경 기록 및 이메일 목록을 볼 수 있습니다.",
  step3_docsFileInfo_title: "3. 권한 관리",
  step3_docsFileInfo_text:
    "문서에 액세스할 수 있는 회사 및 개인을 관리하려면 여기를 클릭하세요.",
  step4_docsFileInfo_title: "4. 변경 기록",
  step4_docsFileInfo_text:
    "문서에 대한 변경 내용 및 변경한 사람에 대한 정보를 확인하려면 여기를 클릭하세요.",
  step5_docsFileInfo_title: "5. 이메일 기록",
  step5_docsFileInfo_text:
    "문서와 관련된 이메일 기록을 확인하려면 여기를 클릭하세요.",
  step5_1_docsFileInfo_title: "5.1 다운로드",
  step5_1_docsFileInfo_text:
    "이메일에서 정보를 다운로드하려면 여기를 클릭하세요.",
  step_final_docsFileInfo:
    "파일/폴더에 대한 정보를 확인하는 방법을 배웠습니다.",
  // SETTINGS - guide me steps
  step1_docsSettings_title: "2. 설정 메뉴 열기",
  step1_docsSettings_text: "여기를 클릭하여 설정 메뉴를 엽니다.",
  step2_docsSettings_title: "3. 배포 목록",
  step2_docsSettings_text:
    "문서 모듈에 기본 액세스 권한을 갖는 사람을 추가하거나 제거하세요.",
  step3_docsSettings_title: "4. 저장",
  step3_docsSettings_text: "여기를 클릭하여 설정을 저장하세요.",
  step_final_docsSettings: "설정을 조정하는 방법을 배웠습니다.",

  lastEdit: "최종 편집",
  uploadedCreatedBy: "업로드 / 생성자",
  uploadedCreatedOn: "업로드 / 생성 날짜",
  recentDocuments: "최근 문서",
  folderIsPublic: "이 폴더는 모든 사람에게 공개되어 있습니다.",
  folderIsPrivate: "이 폴더는 비공개입니다.",
  documentMovedMessage: "문서가 성공적으로 이동되었습니다.",
  folderMovedMessage: "폴더가 성공적으로 이동되었습니다.",
  recentDocument: "최근 문서",
  documentsRecycleBin: "문서 휴지통",
  noDocumentsCreated: "생성된 문서가 없습니다.",
  addDocumentsPrompt: "문서를 추가하려면 추가 버튼을 클릭하세요.",
  fileUpdatedSuccessfully: "파일이 성공적으로 업데이트되었습니다.",
  folderMarkedSuccessfully: "폴더가 성공적으로 표시되었습니다.",
  fileMarkedSuccessfully: "파일이 성공적으로 표시되었습니다.",
  folderDeletedSuccessfully: "폴더가 성공적으로 삭제되었습니다.",
  fileDeletedSuccessfully: "파일이 성공적으로 삭제되었습니다.",
  storage: "저장소",
  sendToEmail: "이메일로 보내기",
  sendTo: "보내기",
  subject: "제목",
  body: "본문",
  selectAll: "모두 선택",
  deleteItems: "항목 삭제",
  deleteItemsQuestion: "선택한 항목을 삭제하시겠습니까?",
  deleteItemsWarning: "이 작업은 되돌릴 수 없습니다.",
  deleteFolder: "폴더 삭제",
  deleteFile: "파일 삭제",
  deleteQuestion: "이 항목을 삭제하시겠습니까?",
  deleteFolderQuestion: "선택한 폴더를 삭제하시겠습니까?",
  deleteFileQuestion: "선택한 파일을 삭제하시겠습니까?",
  file: "파일",
  folder: "폴더",
  renameFolder: "폴더 이름 변경",
  renameFile: "파일 이름 변경",
  folderName: "폴더 이름",
  fileName: "파일 이름",
  folderCreatedSuccessfully: "폴더가 성공적으로 생성되었습니다.",
  fileCreatedSuccessfully: "파일이 성공적으로 생성되었습니다.",
  addFolder: "폴더 추가",
  addFile: "파일 추가",
  noFilesOnFavorites: "즐겨찾기에 파일이 없습니다.",
  noFilesOnRecycleBin: "휴지통에 파일이 없습니다.",
  fileRestoreSuccessfully: "파일이 성공적으로 복원되었습니다.",
  folderRestoreSuccessfully: "폴더가 성공적으로 복원되었습니다.",
};
