import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { DrawingsAnnotationComments } from "./DrawingsAnnotationComments";
import { DrawingsAnnotationLinkedModule } from "./DrawingsAnnotationLinkedModule";
import { useTranslation } from "react-i18next";

function DrawingsAnnotationDetails({ annotation, onEditUsers, onEditModule }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");

  const [tab, setTab] = useState(
    annotation.assignedTo.length > 0 ? "assigned" : "linked",
  );
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        position: "relative",
        borderLeft: "1px solid black",
        pl: "5px",
        overflowY: "auto",
        height: "80vh",
        maxHeight: "80vh",
      }}
    >
      <Tabs variant="fullWidth" value={tab} onChange={(_e, val) => setTab(val)}>
        {annotation.assignedTo.length > 0 && (
          <Tab label={tGeneral("assigned")} value="assigned" />
        )}
        {annotation.linkedId && <Tab label={tDrawings("links")} value="linked" />}
      </Tabs>
      {tab === "assigned" && (
        <DrawingsAnnotationComments
          annotation={annotation}
          onEdit={onEditUsers}
        />
      )}
      {tab === "linked" && (
        <DrawingsAnnotationLinkedModule
          annotation={annotation}
          onEdit={onEditModule}
        />
      )}
    </Box>
  );
}
export { DrawingsAnnotationDetails };
