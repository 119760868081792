import { Button, Box, Grid, Typography } from "@mui/material";
import Lottie from "lottie-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import logo from "../../../assets/logo.png";
import WelcomeImage from "../../../assets/onboarding/welcome.svg";
import WelcomeAnimation from "../../../assets/animations/onboarding/welcome.json";
import { ModalProjectJoin } from "./ModalProjectJoin";
import { selectCurrentUser } from "../../../features/userSettings/userSettingsSlice";

function Welcome({ onButtonClick }) {
  const [isJoinOpen, setIsJoinOpen] = useState(false);
  const user = useSelector(selectCurrentUser);
  const { t: tOnboarding } = useTranslation("onboarding");
  return (
    <Box display="flex">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        flex={1}
      >
        <img src={logo} alt="Buildpeer" style={{ maxWidth: "390px" }} />
        <Typography fontWeight="600" fontSize="2.25rem" my="25px">
          {tOnboarding("welcomeTitle")} {user?.firstName}
        </Typography>
        <Typography fontSize="1.375rem" mb="50px">
          {tOnboarding("welcomeSubtitle")}
        </Typography>
        <Button
          variant="contained"
          fullWidth
          sx={{ p: "12px 50px", maxWidth: "400px" }}
          onClick={onButtonClick}
        >
          <Typography fontWeight="medium" fontSize="1rem">
            {tOnboarding("createEnterprise")}
          </Typography>
        </Button>
        <Button
          variant="contained"
          fullWidth
          sx={{
            p: "12px 89px",
            mt: "25px",
            maxWidth: "400px",
          }}
          onClick={() => setIsJoinOpen(true)}
        >
          <Typography fontWeight="medium" fontSize="1rem">
            {tOnboarding("joinProject")}
          </Typography>
        </Button>
      </Box>
      <Box
        position="relative"
        flex={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={WelcomeImage}
          alt="Welcome"
          style={{ zIndex: -1, objectFit: "contain" }}
        />
        <Lottie
          animationData={WelcomeAnimation}
          style={{
            height: "600px",
            position: "absolute",
            zIndex: 1000,
            top: "10%",
            left: "30%",
          }}
        />
      </Box>
      <ModalProjectJoin
        isOpen={isJoinOpen}
        onClose={() => setIsJoinOpen(false)}
      />
    </Box>
  );
}

export { Welcome };
