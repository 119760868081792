import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";

function TopSection() {
  const isDesktop = useMediaQuery("(min-width: 600px)");
  return (
    <>
      <Container
        sx={{
          display: "flex",
          paddingTop: "250px",
          paddingBottom: "190px",
        }}
      >
        <Box sx={{ maxWidth: isDesktop ? "780px" : "100%" }}>
          <Typography
            fontSize={isDesktop ? "63px" : "30px"}
            color="#20364A"
            fontWeight="bold"
            textAlign={!isDesktop && "center"}
          >
            Poject Management for construction teams
          </Typography>
          <Typography
            fontSize={isDesktop ? "22px" : "16px"}
            sx={isDesktop ? { maxWidth: "780px" } : { textAlign: "center" }}
            color="#20364A"
            lineHeight="55px"
          >
            Buildpeer is the platform where the construction team comes together
            to collaborate and share information in real-time.
          </Typography>
          <Box
            display="flex"
            flexDirection={isDesktop ? "row" : "column"}
            maxWidth={isDesktop ? "600px" : "100%"}
            alignItems={!isDesktop && "center"}
          >
            <Button
              variant="contained"
              size="large"
              sx={[
                {
                  borderRadius: "20px",
                  minWidth: "200px",
                  flex: 1,
                },
                isDesktop
                  ? {
                      marginRight: "20px",
                    }
                  : { maxWidth: "170px", marginBottom: "20px" },
              ]}
            >
              Pick a plan
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              sx={[
                {
                  borderRadius: "20px",
                  minWidth: "200px",
                  flex: 1,
                },
                !isDesktop && { maxWidth: "170px" },
              ]}
            >
              Request a demo
            </Button>
          </Box>
        </Box>
      </Container>
      {isDesktop && (
        <img
          src={require("./../../assets/top-phone.png")}
          alt="phone app"
          style={{
            position: "absolute",
            right: "0",
            top: "200px",
            overflow: "hidden",
          }}
        />
      )}
    </>
  );
}

export { TopSection };
