export const drawingsChecklist = {
  checkList: "チェックリスト",
  totalCompleted: "合計完了",
  done: "完了",
  revision: "修正",
  pending: "保留中",
  viewDrawings: "図面を表示",
  hideDrawings: "図面を非表示",
  link: "リンク",
  deleteRowTitle: "この行を削除しますか？",
  percentageCompleted: "パーセンテージ完了",
};
