import { Delete, Mic, Pause, PlayArrow, Send } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

function Voicenote({ submitVoicenote, handleVoicenote, resetMessage }) {
  const mediaRecorderRef = useRef(null);

  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    if (isRecording && !isPaused) {
      const timer = setInterval(() => {
        setElapsedTime(Math.floor((new Date() - startTime) / 1000));
      }, 1000);
      return () => clearInterval(timer);
    }
    return () => {};
  }, [isRecording, isPaused, startTime]);

  const handleOnClick = async () => {
    if (submitVoicenote) {
      resetMessage();
    }

    if (!isRecording) {
      setIsRecording(true);
      setStartTime(new Date());

      const parts = [];

      const mediaRecorder = new MediaRecorder(
        await navigator.mediaDevices.getUserMedia({
          audio: true,
        }),
      );

      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          parts.push(e.data);
        }
      };

      mediaRecorder.onstop = () => {
        setIsRecording(false);
        setElapsedTime(0);
        setStartTime(null);

        const file = new File(
          [new Blob(parts, { type: "audio/wav" })],
          `voicenote_${new Date().toISOString()}.wav`,
          { type: "audio/wav" },
        );
        if (submitVoicenote) {
          handleVoicenote("", [file]);
        } else {
          handleVoicenote(file);
        }
      };

      mediaRecorder.start();
      mediaRecorderRef.current = mediaRecorder;
    } else {
      setIsRecording(false);

      if (isPaused) {
        setIsPaused(false);
      }

      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
      }
    }
  };

  const handlePauseResume = () => {
    if (submitVoicenote) {
      resetMessage();
    }

    if (!isPaused) {
      mediaRecorderRef.current.pause();
      setElapsedTime(Math.floor((new Date() - startTime) / 1000));
    } else {
      mediaRecorderRef.current.resume();
      setStartTime(new Date() - elapsedTime * 1000);
    }

    setIsPaused((prev) => !prev);
  };

  const handleDelete = () => {
    if (submitVoicenote) {
      resetMessage();
    }

    setIsRecording(false);
    setIsPaused(false);
    setElapsedTime(0);

    mediaRecorderRef.current = null;
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.stop();
    }
  };

  return (
    <Stack alignItems="center" direction="row" spacing={2}>
      {isRecording ? (
        <>
          <IconButton onClick={handlePauseResume}>
            {isPaused ? <PlayArrow /> : <Pause />}
          </IconButton>
          <Typography>{`${elapsedTime}s`}</Typography>{" "}
          <IconButton onClick={handleDelete}>
            <Delete />
          </IconButton>
          <IconButton onClick={handleOnClick}>
            <Send />
          </IconButton>
        </>
      ) : (
        <IconButton onClick={handleOnClick}>
          <Mic />
        </IconButton>
      )}
    </Stack>
  );
}

Voicenote.propTypes = {
  handleVoicenote: PropTypes.func.isRequired,
  submitVoicenote: PropTypes.bool.isRequired,
};

export default Voicenote;
