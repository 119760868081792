import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import darkTheme from "./assets/theme/darkTheme";
import defaultTheme from "./assets/theme/defaultTheme";
import { selectTheme } from "./features/preferences/preferencesSlice";

import UserSettingsLayout from "./components/userSettings/UserSettingsLayout";
import RequireAuth from "./features/auth/RequireAuth";
import CaslContext from "./utils/caslContext";
import ChangePassword from "./views/userSettings/ChangePassword";
import Preferences from "./views/userSettings/Preferences";
import Profile from "./views/userSettings/Profile";
import ProfileEdit from "./views/userSettings/ProfileEdit";
import Tickets from "./views/userSettings/Tickets";
import UserManual from "./views/userSettings/UserManual";

function UserRoutes() {
  const darkMode = useSelector(selectTheme);
  const theme = darkMode ? darkTheme : defaultTheme;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        {/* CaslContext - for user roles and perimissions */}
        <CaslContext.Provider value="">
          <Routes>
            {/* protected routes */}
            <Route element={<RequireAuth />}>
              <Route path="user" element={<UserSettingsLayout />}>
                <Route path="profile" element={<Profile />} />
                <Route path="profileEdit" element={<ProfileEdit />} />
                <Route path="changePassword" element={<ChangePassword />} />
                <Route path="tickets" element={<Tickets />} />
                <Route path="preferences" element={<Preferences />} />
                <Route path="userManual" element={<UserManual />} />
              </Route>
            </Route>
          </Routes>
        </CaslContext.Provider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default UserRoutes;
