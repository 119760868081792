import { datadogRum } from "@datadog/browser-rum";
import React, { useEffect, useState } from "react";
// Hooks
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// Redux reducers & mutations
import { SupportAgent, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { useLoginMutation } from "../features/auth/authApiSlice";
import {
  selectCurrentToken,
  setAccessToken,
  setEmailToVerify,
} from "../features/auth/authSlice";
import {
  selectTheme,
  setLocale,
} from "../features/preferences/preferencesSlice";
import {
  selectCurrentUser,
  setCurrentUser,
} from "../features/userSettings/userSettingsSlice";
// MUI Components
// Custom components
import LoginVideo from "../assets/loginVideo.mp4";
import LoadingOverlay from "../components/LoadingOverlay";
import { useGetEnterprisesQuery } from "../features/enterprise/enterpriseApiSlice";
import { setCurrentEnterprise } from "../features/enterprise/enterpriseSlice";
import { setCurrentProject } from "../features/project/projectSlice";
import { useGetProjectByIdQuery } from "../features/project/projectApiSlice";
import { TooltipIconButton } from "../components/shared";

function Login() {
  const { t: tLogin } = useTranslation("login");
  const { t: tGeneral } = useTranslation("general");
  // local input state definition
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const accessToken = useSelector(selectCurrentToken);

  const dateNow = new Date();

  // Navigate and location hooks
  const navigate = useNavigate();
  const location = useLocation();
  const from =
    location.state?.from?.pathname ?? "/enterprise/idEnterprise/dashboard";
  // We check if theme == dark to render proper logo dynamically
  const darkMode = useSelector(selectTheme);

  // Only to navigate to specific element
  const [idProjectPath, setIdProjectPath] = useState(null);
  const [path, setPath] = useState(null);

  // Redux dispatch action
  const dispatch = useDispatch();
  const [login, { isLoading }] = useLoginMutation();
  const user = useSelector(selectCurrentUser);
  const { data: enterprises } = useGetEnterprisesQuery(
    {},
    {
      skip: !accessToken,
    },
  );
  const { data: selectedProject } = useGetProjectByIdQuery(idProjectPath, {
    skip: !idProjectPath,
  });

  useEffect(() => {
    if (user && Boolean(accessToken) && Boolean(enterprises)) {
      if (!user?.onboardingDone) {
        navigate("/onboarding", { replace: true });
      }

      // For direct navigations within a module of the project
      if (from.includes("/project/")) {
        // Path structure: enterprise/idEnterprise/project/idProject/moduleName/idObject
        let pathSplit = from.split("/");
        let indexIdEnterprise = pathSplit.indexOf("enterprise") + 1;
        let indexIdProject = pathSplit.indexOf("project") + 1;
        dispatch(
          setCurrentEnterprise(
            enterprises.find(
              (enterprise) => enterprise.id === pathSplit[indexIdEnterprise],
            ),
          ),
        );
        setIdProjectPath(pathSplit[indexIdProject]);
        setPath(from);
      } else {
        // Normal login
        const enterpriseLink = from
          .toString()
          .replace(/idEnterprise|undefined/g, enterprises[0].id);

        navigate(enterpriseLink, {
          replace: true,
        });
      }
    }
  }, [enterprises, from, navigate, accessToken, user]);

  useEffect(() => {
    if (path && selectedProject) {
      dispatch(setCurrentProject(selectedProject));
      navigate(path, {
        replace: true,
      });
    }
  }, [path, selectedProject]);

  // Handle submit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = await login({ email, password }).unwrap();
      dispatch(setAccessToken(userData.accessToken));
      dispatch(setCurrentUser(userData.user));
      dispatch(setLocale(userData.user.translationFeature));
      datadogRum.addAction("login", {
        app: "buildpeer-web",
        email,
        enterprise: userData.idEnterprise,
        status: "success",
      });
    } catch (err) {
      datadogRum.addAction("login", {
        app: "buildpeer-web",
        email,
        enterprise: err.data.idEnterprise ? err.data.idEnterprise : [],
        status: "failure",
      });
      Swal.fire({
        icon: "error",
        title: tLogin("errorLoginTitle"),
        text:
          err?.status === 401 || err?.status === 403
            ? tLogin("errorLoginAuthMessage")
            : tLogin("errorLoginMessage"),
        showConfirmButton: false,
        timer: 2000,
      });
      if (err?.status === 404) {
        dispatch(setEmailToVerify(email));
        navigate("/send-email-verify", { replace: true });
      }
    }
  };

  const handleChat = () => {
    const status = window.HubSpotConversations.widget.status();
    if (status?.loaded) {
      window.HubSpotConversations.widget.remove();
    } else {
      window.HubSpotConversations.widget.load();
    }
  };

  return (
    <Box display="flex" height="100vh">
      <Grid container item xs={12} md={6} justifyContent="center" flex={1}>
        <Grid item xs={8} alignItems="center" justifyContent="center">
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              width: "100%",
              mt: 15,
              mb: 3,
            }}
          >
            <Box
              component="img"
              sx={{
                width: "60%",
                height: "60%",
              }}
              alt="Buildpeer Logo"
              src={
                darkMode
                  ? require("../assets/logo-dark.png")
                  : dateNow?.getMonth() === 11
                    ? require("../assets/logo-christmas.png") // Only for December
                    : require("../assets/logo.png")
              }
            />
          </Box>
          <Typography align="center" fontWeight="bold">
            {tLogin("welcomeBack")}
          </Typography>
          <form>
            {/* Email input */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 1,
                mb: 1,
              }}
            >
              <TextField
                margin="dense"
                variant="outlined"
                required
                label={tGeneral("email")}
                type="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                sx={{ width: "100%" }}
              />
            </Box>

            {/* Password input */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 1,
                mb: 1,
              }}
            >
              <FormControl sx={{ width: "100%" }} variant="outlined" required>
                <InputLabel htmlFor="outlined-adornment-password">
                  {tLogin("password")}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(e) => {
                          setShowPassword(!showPassword);
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  value={password}
                  required
                  label={tLogin("password")}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
            </Box>

            <Button
              type="submit"
              onClick={(e) => {
                handleSubmit(e);
              }}
              variant="contained"
              sx={{ width: "100%", borderRadius: 3, my: 1 }}
            >
              {tLogin("logIn")}
            </Button>
            {/* Text Forgot password */}
            <Typography align="center">
              <Link href="/recover-password" underline="hover">
                {tLogin("forgotPassword")}
              </Link>
            </Typography>
          </form>
        </Grid>

        <Grid item xs={12}>
          <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="center"
            height="100%"
            pb="1rem"
          >
            <Button sx={{ textTransform: "none" }} onClick={handleChat}>
              <Typography color="#8E8E8E" fontWeight="400" fontSize="0.75rem">
                ¿Tienes dudas?
              </Typography>
              <Typography
                color="#4B4B4B"
                fontWeight="500"
                fontSize="0.75rem"
                ml="0.25rem"
                sx={{ textDecoration: "underline" }}
              >
                Contáctanos
              </Typography>
              <SupportAgent sx={{ color: "#8E8E8E", ml: "0.5rem" }} />
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box flex={1} position="relative">
        <video
          autoPlay
          muted
          loop
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            objectFit: "cover",
            width: "100%",
            height: "100vh",
          }}
        >
          <source src={LoginVideo} type="video/mp4" />
        </video>
      </Box>
      <LoadingOverlay open={isLoading} />
    </Box>
  );
}

export default Login;
