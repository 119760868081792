import { useState } from "react";
import { useNavigate } from "react-router";
// Redux reducers & mutations
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  useSendEmailVerifyMutation,
  useSignupMutation,
} from "../features/auth/authApiSlice";
import { setEmailToVerify } from "../features/auth/authSlice";
import { selectTheme } from "../features/preferences/preferencesSlice";
// Validate los input
import darkLogo from "../assets/logo-dark.png";
import logo from "../assets/logo.png";
import { emailValidator } from "../services/validators/emailValidator";
import { nameValidator } from "../services/validators/nameValidator";
import { passwordRepeatValidator } from "../services/validators/passwordRepeatValidator";
import { passwordValidator } from "../services/validators/passwordValidator";
import { phoneValidator } from "../services/validators/phoneValidator";
// MUI Components
// Custom components
import LoadingOverlay from "../components/LoadingOverlay";

function SignUp() {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [nameAlert, setNameAlert] = useState("");
  const [errorName, setErrorName] = useState(false);

  const [lastName, setLastName] = useState("");
  const [lastNameAlert, setLastNameAlert] = useState("");
  const [errorLastName, setErrorLastName] = useState(false);

  const [email, setEmail] = useState("");
  const [emailAlert, setEmailAlert] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordAlert, setPasswordAlert] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);

  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [passwordRepeatAlert, setPasswordRepeatAlert] = useState("");
  const [errorPasswordRepeat, setErrorPasswordRepeat] = useState(false);

  const [phone, setPhone] = useState("");
  const [phoneAlert, setPhoneAlert] = useState("");
  const [errorPhone, setErrorPhone] = useState(false);

  const [dateBirth, setDateBirth] = useState("");
  const [gender, setGender] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  // We check if theme == dark to render proper logo dynamically
  const darkMode = useSelector(selectTheme);
  const dispatch = useDispatch();

  const [signup, { isLoading }] = useSignupMutation();
  const [sendEmailVerify] = useSendEmailVerifyMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!acceptedTerms) {
      return;
    }
    try {
      const signUpSuccess = await signup({
        firstName,
        lastName,
        email,
        password,
        phone,
        dateBirth,
        gender,
      }).unwrap();
      if (signUpSuccess) {
        await sendEmailVerify({ email }).unwrap();
        Swal.fire({
          icon: "success",
          title: "User registered succesfully",
          showConfirmButton: false,
          timer: 2000,
        });
        dispatch(setEmailToVerify(signUpSuccess.email));
        navigate("/send-email-verify", { replace: true });
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Sign up Failed",
        text:
          err?.status === 400 ? err?.data.validation.body.message : err?.data,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <>
      <Grid container item xs={12} md={6} justifyContent="center">
        {/* Buildpeer logo */}
        <Grid item justifyContent="center" alignItems="center" xs={12}>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              width: "100%",
              mt: 3,
              mb: 3,
            }}
          >
            <Box
              component="img"
              sx={{
                width: "40%",
                height: "40%",
              }}
              alt="Buildpeer Logo"
              src={darkMode ? darkLogo : logo}
            />
          </Box>
        </Grid>

        {/* register Text */}
        <Grid item xs={12} justifyContent="center" alignItems="center">
          <Typography variant="h5" align="center">
            Register
          </Typography>
        </Grid>
        {/* name input */}
        <Grid item xs={6} alignItems="center" justifyContent="center">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              m: 1,
            }}
          >
            <TextField
              required
              error={errorName}
              helperText={nameAlert}
              name="first-name"
              value={firstName}
              margin="dense"
              variant="outlined"
              label="Name"
              placeholder="Enter name"
              type="text"
              multiline
              sx={{ width: "100%" }}
              onChange={(e) => {
                setFirstName(e.target.value);
                if (firstName !== "" && nameValidator(firstName) === -1) {
                  setErrorName(true);
                  setNameAlert(
                    "the name must have more than 2 characters, up to 16",
                  );
                } else {
                  setErrorName(false);
                  setNameAlert("");
                }
              }}
            />
          </Box>
        </Grid>
        {/* surname input */}
        <Grid item xs={6} alignItems="center" justifyContent="center">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              m: 1,
            }}
          >
            <TextField
              error={errorLastName}
              helperText={lastNameAlert}
              name="last-name"
              value={lastName}
              margin="dense"
              variant="outlined"
              required
              label="Surname"
              placeholder="Enter Surname"
              type="text"
              onChange={(e) => {
                setLastName(e.target.value);
                if (lastName !== "" && nameValidator(lastName) === -1) {
                  setErrorLastName(true);
                  setLastNameAlert(
                    "the Surname must have more than 2 characters, up to 16",
                  );
                } else {
                  setErrorLastName(false);
                  setLastNameAlert("");
                }
              }}
              sx={{ width: "100%" }}
            />
          </Box>
        </Grid>
        {/* email input */}
        <Grid item xs={6} alignItems="center" justifyContent="center">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              m: 1,
            }}
          >
            <TextField
              error={errorEmail}
              helperText={emailAlert}
              name="email"
              value={email}
              margin="dense"
              variant="outlined"
              required
              label="E-mail"
              placeholder="example@mail.com"
              type="email"
              sx={{ width: "100%" }}
              onChange={(e) => {
                setEmail(e.target.value);
                if (email !== "" && emailValidator(email) === -1) {
                  setErrorEmail(true);
                  setEmailAlert("email invalid");
                } else {
                  setErrorEmail(false);
                  setEmailAlert("");
                }
              }}
            />
          </Box>
        </Grid>
        {/* phone input */}
        <Grid item xs={6} alignItems="center" justifyContent="center">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              m: 1,
            }}
          >
            <TextField
              error={errorPhone}
              helperText={phoneAlert}
              name="phone"
              value={phone}
              margin="dense"
              variant="outlined"
              required
              label="Phone"
              placeholder="555-555-5555"
              type="tel"
              onChange={(e) => {
                setPhone(e.target.value);
                if (phone !== "" && phoneValidator(phone) === -1) {
                  setErrorPhone(true);
                  setPhoneAlert("Please enter a valid phone number.");
                } else {
                  setErrorPhone(false);
                  setPhoneAlert("");
                }
              }}
              sx={{ width: "100%" }}
            />
          </Box>
        </Grid>
        {/* Password input */}
        <Grid item xs={6} alignItems="center" justifyContent="center">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
              mr: 1,
              ml: 1,
            }}
          >
            <TextField
              error={errorPassword}
              helperText={passwordAlert}
              name="password"
              value={password}
              margin="dense"
              variant="outlined"
              required
              label="Password"
              placeholder="Enter password"
              type={showPassword ? "text" : "password"}
              onChange={(e) => {
                setPassword(e.target.value);
                if (
                  password !== "" &&
                  passwordRepeat !== "" &&
                  passwordValidator(password, passwordRepeat) === -1
                ) {
                  setErrorPassword(true);
                  setPasswordAlert("password invalid");
                } else {
                  setErrorPassword(false);
                  setPasswordAlert("");
                }
              }}
              sx={{ width: "100%" }}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
          </Box>
        </Grid>
        {/* Password repeat */}
        <Grid item xs={6} alignItems="center" justifyContent="center">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
              mr: 1,
              ml: 1,
            }}
          >
            <TextField
              error={errorPasswordRepeat}
              helperText={passwordRepeatAlert}
              margin="dense"
              variant="outlined"
              required
              label="Repeat password"
              placeholder="Enter password"
              type={showConfirmPassword ? "text" : "password"}
              name="passwordRepeat"
              value={passwordRepeat}
              onChange={(e) => {
                setPasswordRepeat(e.target.value);
                if (
                  password !== "" &&
                  passwordRepeat !== "" &&
                  passwordRepeatValidator(passwordRepeat, password) === 1
                ) {
                  setErrorPasswordRepeat(true);
                  setPasswordRepeatAlert("Passwords do not match");
                } else {
                  setErrorPasswordRepeat(false);
                  setPasswordRepeatAlert("");
                }
              }}
              sx={{ width: "100%" }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => setShowConfirmPassword((prev) => !prev)}
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
          </Box>
        </Grid>
        {/* text password  */}
        <Grid item xs={12} alignItems="center" justifyContent="center">
          <Typography fontSize={12} sx={{ ml: 1 }}>
            (Digits, lowercase, uppercase and symbols)
          </Typography>
        </Grid>

        {/* date of birth  */}
        <Grid item xs={6} alignItems="center" justifyContent="center">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              m: 1,
            }}
          >
            <TextField
              margin="dense"
              variant="outlined"
              type="date"
              required
              name="dateBirth"
              value={dateBirth}
              onChange={(e) => setDateBirth(e.target.value)}
              sx={{ width: "100%" }}
              helperText="Date of Birth"
            />
          </Box>
        </Grid>
        {/* Gender */}
        <Grid item xs={6} alignItems="center" justifyContent="center">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              m: 1,
            }}
          >
            <TextField
              margin="dense"
              variant="outlined"
              required
              label="Gender"
              name="gender"
              id="gender"
              select
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              sx={{ width: "100%" }}
              helperText="Select Gender"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>
          </Box>
        </Grid>
        {/* Check accept the terms  */}
        <Grid item xs={12} sx={{ m: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography style={{ fontSize: "1rem" }}>
              <Checkbox
                required
                className="checkbox"
                type="checkbox"
                name="Terms"
                value={acceptedTerms}
                onChange={(e) => setAcceptedTerms(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
              />
              {"I accept the "}
              <Link
                href="https://buildpeer.com/terminos/"
                underline="hover"
                target="_blank"
              >
                Terms
              </Link>
            </Typography>
          </Box>
        </Grid>
        {/* Button register */}
        <Grid item xs={12} sx={{ m: 1 }}>
          <Button
            type="submit"
            onClick={(e) => {
              handleSubmit(e);
            }}
            variant="contained"
            sx={{ width: "100%", borderRadius: 3 }}
          >
            Create Account
          </Button>
        </Grid>
        {/* text have an account */}
        <Grid item xs={12} justifyContent="center" alignItems="center">
          <Typography style={{ fontSize: "1rem" }} align="center">
            Do You already have an account?
          </Typography>
        </Grid>
        {/* Button Log in */}
        <Grid item xs={12} sx={{ m: 1 }}>
          <Button
            onClick={() => navigate("/")}
            variant="outlined"
            color="inherit"
            sx={{ width: "100%", borderRadius: 3 }}
          >
            LOG IN
          </Button>
        </Grid>
      </Grid>
      <LoadingOverlay open={isLoading} />
    </>
  );
}

export default SignUp;
