import * as React from "react";
import { DehazeOutlined } from "@mui/icons-material";
import { AppBar, Box, IconButton, Toolbar, useMediaQuery } from "@mui/material";
import { DrawerMenu } from "./DrawerMenu";

function Navbar() {
  const [opened, setOpened] = React.useState(false);
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "transparent",
        paddingX: "10%",
        height: 200,
        justifyContent: "center",
        borderBottom: "none",
      }}
    >
      <Toolbar sx={{ alignSelf: "center" }}>
        <img
          src={require("./../../../assets/logo.png")}
          style={{ width: isMobile ? "50%" : "10%" }}
        />
        <Box sx={{ flexGrow: 1 }}></Box>
        <IconButton onClick={() => setOpened(!opened)}>
          <DehazeOutlined />
        </IconButton>
      </Toolbar>
      <DrawerMenu
        opened={opened}
        onClose={() => {
          setOpened(false);
        }}
      />
    </AppBar>
  );
}

export { Navbar };
