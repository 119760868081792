import { tour } from "./tour";

export const projectDirectory = {
  ...tour,
  moduleElement: "Usuário",
  moduleName: "Diretório",
  // ADD - guide me steps
  step1_addUser_title: "1. Adicionar novo usuário ao projeto",
  step1_addUser_text: "Clique aqui para adicionar um novo usuário ao projeto.",
  step2_addUser_title: "2. Selecionar usuário",
  step2_addUser_text:
    "Digite o nome completo do usuário, em seguida, você precisará escolher um usuário da lista.",
  step3_addUser_title: "3. Posição",
  step3_addUser_text: "Digite a posição que o usuário ocupa no projeto.",
  step4_addUser_title: "4. Função padrão",
  step4_addUser_text:
    "Selecione uma função padrão para o contato, se ele pode apenas visualizar e não editar, ou se ele não terá permissões.",
  step5_addUser_title: "5. Permissões individuais",
  step5_addUser_text:
    "Selecione uma função específica para cada módulo individualmente.",
  step6_addUser_title: "6. Confirmar",
  step6_addUser_text: "Clique aqui para confirmar e adicionar o usuário.",
  step_final_addUser:
    "Você aprendeu como adicionar um novo usuário ao projeto.",
  // VIEW - guide me steps
  step1_viewUser_title: "1. Visualizar informações do usuário",
  step1_viewUser_text: "Clique aqui para visualizar as informações do usuário.",
  step2_viewUser_title: "2. Último login",
  step2_viewUser_text:
    "Aqui, você pode ver informações sobre o último login do usuário.",
  step3_viewUser_title: "3. Ações recentes",
  step3_viewUser_text:
    "Aqui, você pode ver informações sobre as ações recentes realizadas pelo usuário.",
  step_final_viewUser:
    "Você aprendeu como visualizar as informações do usuário do projeto.",
  // EDIT - guide me steps
  step1_editUser_title: "1. Editar usuário",
  step1_editUser_text: "Clique aqui para editar um usuário.",
  step2_editUser_title: "2. Posição",
  step2_editUser_text: "Edite a posição que o contato ocupa no projeto.",
  step3_editUser_title: "3. Função padrão",
  step3_editUser_text:
    "Edite a função padrão para o contato, se ele pode apenas visualizar e não editar, ou se ele não terá permissões.",
  step4_editUser_title: "4. Permissões individuais",
  step4_editUser_text: "Edite as funções para cada módulo individualmente.",
  step5_editUser_title: "5. Salvar",
  step5_editUser_text: "Clique aqui para salvar as alterações.",
  step_final_editUser: "Você aprendeu como editar um usuário.",

  directory: "Diretório",
  addCompany: "ADICIONAR EMPRESA",
  lastLogin: "Último Login",
  infoText: " não se conectou há ",
  days: " dias",
  wasConnectedToday: " se conectou nas últimas 24 horas",
  hasNeverLoggedIn: " nunca fez login ",
  latestActivity: "Última Atividade",
  createRfi: "Criar RFI 24",
  responseSubmittal: "Envio de Resposta",
  createDailyReport: "Criar Relatório Diário",
  createRfi123: "Criar RFI 123",
  uploadPhotos: "Enviar Fotos",
  newContact: "Novo Contato",
  editContact: "Editar Contato",
  generalInformation: "Informações Gerais",
  permissions: "Permissões",
  drawings: "Desenhos",
  punchList: "Lista de Pendências",
  rfis: "RFIs",
  submittals: "Envios",
  deliveries: "Entregas",
  hours: "Horas",
  meetings: "Reuniões",
  photos: "Fotos",
  tasks: "Tarefas",
  inspections: "Inspeções",
  issues: "Problemas",
  dailyReports: "Relatórios",
  documents: "Documentos",
  delays: "Atrasos",
  announcements: "Anúncios",
  dashbooard: "Painel de Controle",
  adminPanel: "Painel de Administração",
  generalDirectory: "Diretório Geral",
  deleteContact: "Excluir Contato",
  confirmDelete:
    "Tem certeza de que deseja excluir este contato? Essa ação não pode ser revertida",
  currentPlan: "Plano Atual",
  activeUsers: " usuários ativos.",
  type: "Tipo",
  user: "Usuário",
  contact: "Contato",
  usersLimit: "Limite de Usuários",
  confirmUsersLimit:
    "Tem certeza de que deseja continuar e adicionar um novo usuário? Essa ação adicionará um usuário extra à sua fatura.",
  deletedRoleMessage:
    "A função atual deste usuário foi excluída, por favor, selecione uma nova",
};
