export const general = {
  addFilter: "Adicionar filtro",
  settings: "Configurações",
  restore: "Restaurar",
  location: "Localização",
  locations: "Localizações",
  uploadedBy: "Enviado por",
  attendance: "Presença",
  assignees: "Responsáveis",
  assigned: "Atribuído",
  category: "Categoria",
  priority: "Prioridade",
  createdBy: "Criado por",
  distributionList: "Lista de distribuição",
  noUsersSelected: "Nenhum usuário selecionado",
  noAttendanceUsers: "Nenhum usuário de presença",
  actions: "Ações",
  day: "Dia",
  month: "Mês",
  year: "Ano",
  week: "Semana",
  rename: "Renomear",
  delete: "Excluir",
  deleteAll: "Excluir tudo",
  cancel: "Cancelar",
  confirm: "Confirmar",
  edit: "Editar",
  information: "Informação",
  comments: "Comentários",
  takenOn: "Registrado em",
  uploadedOn: "Enviado em",
  errorMessage: "Ocorreu um erro, tente novamente mais tarde.",
  errorUploadMessage:
    "Os arquivos não puderam ser carregados. Verifique sua conexão com a internet e tente novamente mais tarde.",
  errorDownloadMessage:
    "Os arquivos não puderam ser baixados. Verifique sua conexão com a internet e tente novamente mais tarde.",
  close: "Fechar",
  save: "Salvar",
  logOut: "Sair",
  view: "Visualizar",
  seeDetails: "Ver detalhes",
  download: "Baixar",
  add: "Adicionar",
  update: "Atualizar",
  accept: "Aceitar",
  loading: "Carregando",
  loadingDots: "Carregando...",
  email: "Email",
  phone: "Telefone",
  name: "Nome",
  lastName: "Sobrenome",
  position: "Cargo",
  enterprise: "Empresa",
  role: "Função",
  module: "Módulo",
  superAdmin: "Super Admin",
  admin: "Admin",
  viewOnly: "Somente visualização",
  none: "Nenhum",
  invalid: "Inválido",
  search: "Buscar",
  projectSearch: "Busca em todo o projeto",
  open: "Abrir",
  open_action: "Abrir",
  still_open: "Ainda aberto",
  public: "Público",
  private: "Privado",
  privacy: "Privacidade",
  date: "Data",
  closedDate: "Data de fechamento",
  status: "Status",
  title: "Título",
  draft: "Rascunho",
  favorite: "Favorito",
  back: "Voltar",
  configUpdated: "Configuração atualizada com sucesso",
  errorConfigUpdated: "Não foi possível atualizar a configuração",
  hour: "Hora",
  selectStatus: "Selecionar status",
  publish: "Publicar",
  attendanceConfirmed: "Presença confirmada com sucesso",
  errorAttendanceConfirmed: "Não foi possível confirmar a presença",
  copy: "Copiar",
  confirmAttendance: "Confirmar presença",
  pdf: "PDF",
  creator: "Criador",
  lastSeen: "Última visualização",
  lastEdit: "Última edição",
  systemLanguage: "Idioma do sistema",
  address: "Endereço",
  street: "Rua",
  number: "Número",
  country: "País",
  state: "Estado",
  city: "Cidade",
  zipCode: "CEP",
  next: "Próximo",
  en: "Inglês",
  es: "Espanhol",
  file: "Arquivo",
  files: "Arquivos",
  dateReceived: "Data de recebimento",
  features: "Recursos",
  drawings: "Desenhos",
  drawing: "Desenho",
  submittals: "Submissões",
  rfis: "RFIs",
  dailyReports: "Relatórios",
  issues: "Problemas",
  photos: "Fotos",
  tasks: "Tarefas",
  inspections: "Inspeções",
  documents: "Documentos",
  meetings: "Reuniões",
  delay: "Atraso",
  directory: "Diretório",
  hours: "Horas",
  deliveries: "Entregas",
  announcements: "Anúncios",
  user: "Usuário",
  project: "Projeto",
  projects: "Projetos",
  subscribe: "Inscrever-se",
  notFound: "Nenhum projeto encontrado",
  noProjects: "Nenhum projeto foi criado no momento",
  createButton:
    "Se você deseja adicionar um, convidamos você a clicar no botão criar projeto para iniciar o processo",
  createProject: "Criar projeto",
  loadingEnterprises: "Carregando empresas...",
  generalDirectory: "Diretório geral",
  superAdmins: "Super Admins",
  roles: "Funções",
  manager: "Gerente",
  constructionDirector: "Diretor de construção",
  architect: "Arquiteto",
  engineer: "Engenheiro",
  active: "Ativo",
  inactive: "Inativo",
  generalInfo: "Informações gerais",
  changePlan: "Alterar plano",
  billing: "Faturamento",
  paymentMethod: "Métodos de pagamento",
  updateProfile: "Atualizar perfil da empresa",
  youSureEnterprise:
    "Você tem certeza de que deseja atualizar o perfil da empresa com esses dados?",
  enterpriseName: "Nome da empresa",
  enterpriseType: "Tipo de empresa",
  enterpriseImage: "Logotipo da empresa",
  success: "Sucesso",
  done: "Concluído!",
  enterpriseUpdated: "Informações da empresa atualizadas com sucesso.",
  error: "Erro",
  errorBig: "Erro!",
  errorOccurred: "Ocorreu um erro, tente novamente mais tarde.",
  logout: "Sair",
  profile: "Perfil",
  currentPlan: "Plano atual",
  per: "Por",
  activeProjects: "Projetos ativos",
  activeUsers: "Usuários ativos",
  annual: "Anual",
  monthly: "Mensal",
  allTheFeatures: "Todos os recursos",
  unlimitedProjects: "Projetos ilimitados",
  unlimitedUsers: "Usuários ilimitados",
  unlimitedStorage: "Armazenamento ilimitado",
  translationSpanglish: "Tradução Espanhol-Inglês",
  companyReports: "Relatórios da empresa",
  customizableReports: "Modelos de relatório personalizáveis",
  home: "Início",
  changePassword: "Alterar senha",
  tickets: "Tickets",
  preferences: "Preferências",
  userManual: "Manual do usuário",
  notifications: "Notificações",
  male: "Masculino",
  female: "Feminino",
  editProfile: "Editar perfil",
  firstName: "Nome",
  birthDate: "Data de nascimento",
  gender: "Gênero",
  inProcess: "Em processo",
  currentPassword: "Senha atual",
  newPassword: "Nova senha",
  repeatPassword: "Repetir senha",
  passwordNotice:
    "A senha deve ter pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número, 1 caractere especial (_, #, ?, !, @, $, %, ^, &, * ou -) e ter 8 caracteres de comprimento.",
  passwordYouSure: "Tem certeza de que deseja alterar a senha?",
  enterPassword: "Digite a senha",
  changedPassword: "Senha alterada com sucesso",
  welcomeTickets:
    "Bem-vindo! Selecione a categoria do incidente encontrado abaixo.",
  mobile: "Celular",
  browser: "Navegador",
  device: "Dispositivo",
  details: "Detalhes",
  send: "Enviar",
  mode: "Modo",
  dark: "Escuro",
  light: "Claro",
  exportCvs: "Exportar CVS",
  exportPdf: "Exportar PDF",
  newSuperAdmin: "Novo super admin",
  dashboard: "Dashboard",
  adminPanel: "Painel de administração",
  visibleModules: "Módulos visíveis",
  orderModules: "Ordem dos módulos",
  projectAdmin: "Administração do projeto",
  specification: "Especificação",
  specifications: "Especificações",
  costCodes: "Códigos de custo",
  connections: "Conexões",
  projectName: "Nome do projeto",
  translation: "Tradução",
  managers: "Gerentes",
  constructionManager: "Gerente de construção",
  projectEngineer: "Engenheiro de projeto",
  editInfo: "Editar informações",
  on: "Ligado",
  off: "Desligado",
  addSuperAdmin: "Adicionar Super Admin",
  deleteSuperAdmin: "Excluir Super Admin",
  areYouAdmin:
    "Tem certeza de que deseja excluir este super admin? Ao realizar esta ação, ela não poderá ser revertida",
  writeDetail: "Descreva em detalhes o bug encontrado.",
  dragFiles:
    "Arraste e solte os arquivos aqui ou clique para selecionar os arquivos",
  dragFile:
    "Arraste e solte o arquivo aqui ou clique para selecionar o arquivo",
  emails: "Emails",
  subject: "Assunto",
  to: "Para",
  createdOn: "Criado em",
  info: "Informação",
  move: "Mover",
  favorites: "Favoritos",
  generalInformation: "Informações Gerais",
  permissions: "Permissões",
  managePermissions: "Gerenciar Permissões",
  changeHistory: "Histórico de Alterações",
  published: "Publicado",
  deleted: "Excluído",
  noPermission: "Você não tem permissão para ver esta página",
  recycleBin: "Lixeira",
  not_defined: "Não definido",
  closed: "Fechado",
  no_assinged_users: "Nenhum usuário atribuído",
  due_date: "Data de vencimento",
  closed_date: "Data de fechamento",
  approve: "Aprovar",
  emptyDataSourceMessage: "Nenhum registro para exibir",
  of: "de",
  deletedPermanently: "Excluído permanentemente",
  deletedSuccessfully: "Excluído com sucesso",
  updatedSuccessfully: "Atualizado com sucesso",
  createdSuccessfully: "Criado com sucesso",
  recoveredSuccessfully: "Recuperado com sucesso",
  closedSuccessfully: "Fechado com sucesso",
  approvedSuccessfully: "Aprovado com sucesso",
  cantRestore: "Erro ao restaurar",
  cantClose: "Erro ao fechar",
  cantCreate: "Erro ao criar",
  cantApprove: "Erro ao aprovar",
  cantDelete: "Erro ao excluir",
  cantEdit: "Erro ao editar",
  deleteRFI: "Tem certeza de que deseja excluir o RFI?",
  question: "Pergunta",
  startWriting: "Comece a escrever...",
  additionalInformation: "Informações adicionais",
  receivedFrom: "Recebido de",
  company: "Empresa",
  cost: "Custo",
  price: "Preço",
  currency: "Moeda",
  mxn: "MXN - Peso mexicano",
  usd: "USD - Dólar americano",
  emptyQuestion: "A pergunta não pode estar vazia",
  createDate: "Data de criação",
  newResponse: "Nova resposta...",
  newComment: "Novo comentário...",
  newTopic: "Novo tópico...",
  topics: "Tópicos",
  showTranslation: "Mostrar tradução",
  closeTranslation: "Fechar tradução",
  viewFiles: "Visualizar arquivos",
  images: "Imagens",
  noImages: "Nenhuma imagem",
  noDocuments: "Nenhum documento",
  attachFiles: "Anexar arquivo(s) ou arraste e solte",
  january: "Janeiro",
  february: "Fevereiro",
  march: "Março",
  april: "Abril",
  may: "Maio",
  june: "Junho",
  july: "Julho",
  august: "Agosto",
  september: "Setembro",
  october: "Outubro",
  november: "Novembro",
  december: "Dezembro",
  noEnterprises: "Parece que você ainda não tem uma empresa!",
  pendingItems: "Itens pendentes",
  weather: "Clima",
  todoList: "Lista de tarefas",
  ensureThat:
    "Certifique-se de que seu projeto tenha um endereço válido para obter o clima ou tente novamente mais tarde.",
  addItemList: "Adicionar item à sua lista de tarefas",
  noAnno: "Não há anúncios para ver",
  daysLeft: "Dias restantes",
  congratulations: "Parabéns",
  noPending: "Você não tem itens pendentes.",
  noFilterableElements:
    "Nenhum elemento encontrado que corresponda aos critérios de pesquisa",
  addList: "Adicionar lista de tarefas",
  activityDesc: "Descrição da atividade",
  expirationDate: "Data de vencimento",
  onTime: "No prazo",
  overdue: "Atrasado",
  areYouTask: "Tem certeza de que deseja marcar esta tarefa como concluída?",
  trashCan: "Lixeira",
  newAnnouncement: "Novo anúncio",
  startDate: "Data de início",
  time: "Hora",
  limitDate: "Data limite",
  cancelled: "Cancelado",
  operations: "Operações foram canceladas.",
  task: "Tarefa",
  dailyReport: "Relatório diário",
  deletedBy: "Excluído por",
  deletedAt: "Excluído em",
  publishedDate: "Data de publicação",
  moved: "Movido",
  selectAll: "Selecionar tudo",
  pleaseMakeSureAllDataIsCorrect:
    "Certifique-se de que todos os dados estejam corretos.",
  addLocation: "Adicionar localização",
  new: "Novo",
  sendSomeoneComment: "Enviar um comentário para @alguém",
  endDate: "Data de término",
  recent: "Recente",
  thisWeek: "Esta semana",
  thisMonth: "Este mês",
  lastWeek: "Semana passada",
  projectConfigurationUpdateSuccess:
    "Configuração do projeto atualizada com sucesso",
  description: "Descrição",
  enterValidTitle: "Insira um título válido",
  enterValidDescription: "Insira uma descrição válida",
  enterValidDate: "Insira uma data válida",
  enterValidStatus: "Insira um status válido",
  enterValidPriority: "Insira uma prioridade válida",
  enterValidLocation: "Insira uma localização válida",
  enterValidCategory: "Insira uma categoria válida",
  fieldRequired: "Este campo é obrigatório",
  cantUndone: "Esta ação não pode ser desfeita. ",
  users: "Usuários",
  minutes: "Minutos",
  noOne: "Ninguém",
  noComments: "Nenhum comentário",
  noTasks: "Nenhuma tarefa",
  updated: "Atualizado",
  restored: "Restaurado",
  created: "Criado",
  successfully: "com sucesso",
  labelRowsPerPage: "Linhas por página:",
  labelRowsSelect: "linhas",
  firstAriaLabel: "Primeira página",
  firstTooltip: "Primeira página",
  previousAriaLabel: "Página anterior",
  previousTooltip: "Página anterior",
  nextAriaLabel: "Próxima página",
  nextTooltip: "Próxima página",
  lastAriaLabel: "Última página",
  lastTooltip: "Última página",
  adminEnabledSuccessfully: "Usuário admin habilitado com sucesso",
  cantEnableAdmin: "Não foi possível habilitar o usuário admin",
  adminDisabledSuccessfully: "Usuário admin desabilitado com sucesso",
  cantDisableAdmin: "Não foi possível desabilitar o usuário admin",
  emptyFields: "Campos vazios",
  cantCreateUser: "Não foi possível criar o usuário",
  userCreatedSuccessfully: "Usuário criado com sucesso",
  userEditedSuccessfully: "Usuário editado com sucesso",
  cantEditUser: "Não foi possível editar o usuário",
  userDeletedSuccessfully: "Usuário excluído com sucesso",
  cantDeleteUser: "Não foi possível excluir o usuário",
  companyCreatedSuccessfully: "Empresa criada com sucesso",
  cantCreateCompany: "Não foi possível criar a empresa",
  totalEnterprises: "Total de empresas",
  totalContacts: "Total de contatos",
  enterpriseRoleUpdated: "Função da empresa atualizada com sucesso",
  cantUpdateEnterpriseRole: "Não foi possível atualizar a função da empresa",
  enterpriseRoleDeleted: "Função empresarial excluída com sucesso",
  cantDeleteEnterpriseRole: "Não é possível excluir a função empresarial",
  newRole: "Nova função",
  cantEditUnclassifiedRole: "Não é possível editar a função não classificada",
  cantDeleteUnclassifiedRole:
    "Não é possível excluir a função não classificada",
  noUsersRole: "Nenhum usuário com essa função",
  deleteRole: "Excluir função",
  deleteRoleMessage:
    "Tem certeza de que deseja excluir esta função? Ao realizar esta ação, ela não poderá ser revertida.",
  editRole: "Editar função",
  roleName: "Nome da função",
  invalidName: "Nome inválido",
  cantCreateRole: "Não é possível criar a função",
  roleCreatedSuccessfully: "Função criada com sucesso",
  roleEditedSuccessfully: "Função editada com sucesso",
  cantEditRole: "Não é possível editar a função",
  addRole: "Adicionar função",
  roleNameEmpty: "O nome da função não pode estar vazio",
  projectCreated: "Projeto criado com sucesso",
  cantCreateProject: "Não é possível criar o projeto",
  userName: "Nome do usuário",
  selectUser: "Selecionar usuário",
  userAlreadyAdded: "Usuário já adicionado",
  newProject: "Novo projeto",
  skip: "Pular",
  fillRequiredFields: "Preencha os campos obrigatórios",
  exportInfo: "Exportar informações",
  deleteImage: "Excluir imagem",
  clickAddProject: "Clique para adicionar um projeto",
  other: "Outro",
  projectTypeCreated: "Tipo de projeto criado com sucesso",
  cantCreateProjectType: "Não é possível criar o tipo de projeto",
  projectType: "Tipo de projeto",
  addProjectType: "Adicionar tipo de projeto",
  cover: "capa",
  logo: "logotipo",
  recommendedSize: "Tamanho recomendado",
  projectManager: "Gerente de projeto",
  mexico: "México",
  unitedStates: "Estados Unidos",
  guatemala: "Guatemala",
  colombia: "Colômbia",
  chile: "Chile",
  argentina: "Argentina",
  projectLimitReached: "Limite de projeto atingido",
  projectsLimitReached: "Limite de projetos atingido",
  projectAddWarning:
    "Tem certeza de que deseja prosseguir com a criação de mais um projeto? Ao realizar esta ação, um projeto extra será cobrado na sua próxima fatura.",
  billingInformation: "Informações de faturamento",
  enterpriseInfoUpdated: "Informações da empresa atualizadas com sucesso",
  cantUpdateEnterpriseInfo:
    "Não é possível atualizar as informações da empresa",
  reference: "Referência",
  amount: "Valor",
  paymentDate: "Data de pagamento",
  emailRecipient: "Destinatário do e-mail",
  cardNumber: "Número do cartão",
  mmaa: "MM/AA",
  addCard: "Adicionar cartão",
  selectAvatar: "Selecionar avatar",
  userUpdate: "Usuário atualizado com sucesso",
  cantUpdateUser: "Não é possível atualizar o usuário",
  avatarUpdateError: "Erro ao atualizar o avatar",
  feelsLike: "Sensação térmica",
  weatherValidation:
    "Verifique se o seu projeto possui um código postal e um país para obter o clima ou tente novamente mais tarde",
  clear: "Limpo",
  cloudy: "Nublado",
  mostlyClear: "Principalmente limpo",
  partlyCloudy: "Parcialmente nublado",
  mostlyCloudy: "Principalmente nublado",
  fog: "Neblina",
  lightFog: "Neblina leve",
  drizzle: "Chuvisco",
  rain: "Chuva",
  lightRain: "Chuva leve",
  heavyRain: "Chuva forte",
  snow: "Neve",
  flurries: "Nevascas",
  lightSnow: "Neve leve",
  heavySnow: "Neve pesada",
  drizzleFreezing: "Chuvisco congelante",
  freezingRain: "Chuva congelante",
  freezingLightRain: "Chuva leve congelante",
  freezingHeavyRain: "Chuva forte congelante",
  icePellets: "Granizo",
  heavyIcePellets: "Granizo pesado",
  lightIcePellets: "Granizo leve",
  thunderstorm: "Tempestade",
  Sun: "Dom",
  Mon: "Seg",
  Tue: "Ter",
  Wed: "Qua",
  Thu: "Qui",
  Fri: "Sex",
  Sat: "Sáb",
  cantUpdateConfig: "Não é possível atualizar a configuração",
  drawingsAdded: "Desenhos adicionados com sucesso",
  modified: "Modificado",
  modification: "Modificação",
  from: "De",
  moveFolderTo: "Mover pasta para",
  moveFileTo: "Mover arquivo para",
  locationCreated: "Local criado com sucesso",
  cantCreateLocation: "Não é possível criar o local",
  locationUpdated: "Local atualizado com sucesso",
  cantUpdateLocation: "Não é possível atualizar o local",
  locationDeleted: "Local excluído com sucesso",
  cantDeleteLocation: "Não é possível excluir o local",
  type: "Tipo",
  addType: "Adicionar tipo",
  typeAdded: "Tipo adicionado com sucesso",
  editType: "Editar tipo",
  typeUpdated: "Tipo atualizado com sucesso",
  deleteType: "Excluir tipo",
  typeDeleted: "Tipo excluído com sucesso",
  superLocation: "Super local",
  sureDelete: "Tem certeza de que deseja excluir",
  specificationAdded: "Especificação adicionada com sucesso",
  costCodeAdded: "Código de custo adicionado com sucesso",
  specificationUpdated: "Especificação atualizada com sucesso",
  costCodeUpdated: "Código de custo atualizado com sucesso",
  specificationDeleted: "Especificação excluída com sucesso",
  costCodeDeleted: "Código de custo excluído com sucesso",
  addSpecification: "Adicionar especificação",
  addCostCode: "Adicionar código de custo",
  editSpecification: "Editar especificação",
  editCostCode: "Editar código de custo",
  code: "Código",
  deleteSpecification: "Excluir especificação",
  deleteCostCode: "Excluir código de custo",
  deleteGeneric:
    "Tem certeza de que deseja excluir este elemento? Ao realizar esta ação, ela não poderá ser revertida",
  seen: "Visto",
  create: "Criar",
  rfi: "RFIs",
  meeting: "Reuniões",
  project_directory: "Diretório do projeto",
  createuser: "Criar usuário",
  deleteAgendaItem: "Excluir item da agenda",
  deleteAgendaItemMessage:
    "Tem certeza de que deseja excluir este item da agenda? Ao realizar esta ação, ela não poderá ser revertida.",
  viewCurrentFiles: "Ver arquivos atuais",
  reportAnIssue: "Reportar um problema",
  message: "Mensagem",
  sendToEmail: "Enviar para o e-mail",
  sent: "Enviado",
  sentMessage: "O e-mail foi enviado com sucesso.",
  timezone: "Fuso horário",
  agenda: "Agenda",
  newPasswordTitle: "Digite sua nova senha",
  assignTo: "Atribuir usuário",
  comment: "Comentário",
  linkTo: "Link para",
  deliveryDate: "Data de entrega",
  responsible: "Responsável",
  invalidEmail: "E-mail inválido",
  publishedBy: "Publicado por",
  privacyOptions: "Opções de privacidade",
  person: "Pessoa",
  companies: "Empresas",
  onlyFavorites: "Apenas favoritos",
  approvedBy: "Aprovado por",
  approvalDate: "Data de aprovação",
  externalContacts: "Contatos externos",
  retry: "Tentar novamente",
  generalErrorMessage: "Algo deu errado. Por favor, tente novamente",
  yesDeleteIt: "Sim, exclua",
  creators: "Criadores",
  deleteInspection: "Tem certeza de que deseja excluir a Inspeção?",
  deleteInspectionTemplate:
    "Tem certeza de que deseja excluir o Modelo de Inspeção?",
  sections: "Seções",
  items: "Itens",
  cancelPrompt: "Tem certeza de que deseja cancelar?",
  graph: "Gráfico",
  quickTemplate: "+ Modelo Rápido",
  saveAsTemplate: "Salvar como Modelo",
  createRevision: "Criar Revisão",
  generate: "Gerar",
  videos: "Vídeos",
  noVideos: "Nenhum vídeo",
  audios: "Áudios",
  noAudios: "Nenhum áudio",
  in_revision: "Em revisão",
  logoutMessage: "Tem certeza de que deseja sair?",
  exportCsv: "Exportar CSV",
  compare: "Comparar",
  approved: "Aprovado",
  comparing: "Comparando",
  more: "Mais",
  noCCSelected: "Nenhum destinatário CC selecionado",
  and: "e",
  removeSuperAdminTitle: "Remover função de super administrador",
  removeSuperAdminDescription:
    "Esta ação remove a função de super administrador do usuário, mas não o exclui.",
  noResults: "Nenhum resultado",
  noResultsMessage: "Tente usar filtros diferentes.",
  assignedProjects: "Projetos atribuídos",
  noAssignedProjects: "Nenhum projeto atribuído",
  userAddedSuccessfully: "Usuário adicionado com sucesso",
  projectSchedule: "Cronograma do projeto",
  noOptions: "Nenhuma opção",
  noData: "Nenhum dado encontrado",
  order: "Ordem",
  changeStatus: "Alterar status",
  changeStatusContent: "Tem certeza de que deseja alterar o status do usuário?",
  select: "Selecionar",
  sign: "Assinar",
  useExisting: "Usar existente",
  reject: "Rejeitar",
  rejectedSuccessfully: "Rejeitado com sucesso",
  reviewedBy: "Revisado por:",
  timeAgo: "{{time}} {{unit}} atrás",
  seconds: "Segundos",
  onlyUnread: "Mostrar apenas não lidos",
  days: "Dias",
  image: "Imagem",
  reviewDate: "Data de revisão",
  publicationDate: "Data de publicação",
  inspection: "Inspeção",
  announcement: "Anúncio",
  updatedProject: "Informações do projeto atualizadas com sucesso.",
  draw: "Desenhar",
  history: "Histórico",
  templates: "Modelos",
  useFileName: "Usar nome do arquivo",
  progress: "Progresso",
  bim: "BIM",
  reports: "Relatórios",
  report: "Relatório",
  constants: "Constantes",
  importInfoMessage:
    "Aqui você pode importar informações de outro projeto para economizar tempo no processo de criação.",
  importInfo: "Importar informações",
  imported: "Importado",
  importedSuccessfully: "Importado com sucesso",
  createdDate: "Data de criação",
  acceptedBy: "Aceito por",
  acceptedDate: "Data de aceitação",
  projectNetwork: "Rede do projeto",
  share: "Compartilhar",
  shared: "Compartilhado",
  sharedSuccessfully: "Compartilhado com sucesso",
  sharedOnly: "Apenas compartilhado",
  altitude: "Altitude",
  fullscreen: "Tela cheia",
  metric: "Métrico",
  imperial: "Imperial",
  meters: "Metros",
  feet: "Pés",
  inches: "Polegadas",
  daily_reports: "Relatórios",
  upload: "Enviar",
  enterprises: "Empresas",
  all: "Todos",
  total: "Total",
  modules: "Módulos",
  filters: "Filtros",
  filterBy: "Filtrar por",
  clearFilter: "Limpar",
  typeToAdd: "Digite para adicionar",
  saveChanges: "Salvar alterações",
  format: "Formato",
  showResults: "Mostrar resultados",
  activity: "Atividade",
  allProjects: "Todos os projetos",
  allModules: "Todos os módulos",
  analytics: "Análises",
  uploadingQtyFiles: "Carregando {{qty}} arquivos",
  technicalIssues: "Estamos atualmente enfrentando problemas técnicos",
  comeBackLater: "Por favor, volte em alguns minutos.",
  noInternetConnection: "Sem conexão com a internet",
  checkConnection: "Por favor, verifique sua conexão ou conecte-se ao Wi-Fi.",
  charactersLimitError: "Limite de caracteres atingido",
  maxCharacters: "Máximo de {{max}} caracteres",
  sharedBy: "Compartilhado por",
  ccInstruction:
    "Pressione Enter ou ';' após um e-mail válido para adicioná-lo",
  exportExcel: "Exportar para Excel",
  excelSuccessfullyDownloaded: "Seu documento Excel foi baixado com sucesso",
  noRfiSelected: "Você não selecionou nenhuma RFI para exportar.",
  selectRfiToContinue: "Selecione uma RFI para continuar.",
  noSubmittalSelected: "Você não selecionou nenhum envio para exportação.",
  selectSubmittalToContinue: "Selecione um envio para continuar.",
  noMoreResults: "Não há mais resultados",
  projectDescription: "Descrição do projeto",
  clickToWrite: "Clique aqui para escrever",
  fileUploadMaxSizeWarning:
    "Limite de tamanho para upload de arquivos é de 5GB",
  errorFileUploadSizeTitle: "Um dos arquivos excede o limite de 5GB",
  errorFileUploadSizeDescription:
    "Por favor, revise seus arquivos e tente novamente com um arquivo menor",
  errorBimUploadLimitTitle: "Limite de upload de BIM do projeto alcançado",
  errorBimUploadLimitDescription:
    "Você atingiu o limite de Arquivos para este projeto, por favor, entre em contato com o suporte se quiser adicionar mais.",
  duration: "Duração",
  startsAt: "Começa em",
  weighting: "Ponderação",
  document: "Documento",
  dependsOn: "Depende de",
  calculate: "Calcular",
  checklist: "Checklist",
  activities: "Atividades",
  weeks: "Semanas",
  disciplines: "Disciplinas",
  required: "Requerido",
  notRequired: "Não requerido",
  duplicate: "Duplicar",
  design: "Design",
  exportPdfLimitWarning:
    " número máximo de fotos por relatório é 50, por favor reduza a seleção e tente novamente.",
  exportPdfVideoWarning:
    "Aviso, arquivos de vídeo não são suportados em exportações de PDF",
  errorExportPDFMessage:
    "Houve um erro ao gerar o PDF de exportação, por favor entre em contato com o suporte",
  downloading: "Baixando",
  chatSupport: "Chat de suporte",
  fromDate: "Desde",
  toDate: "Até",
  descriptions: "Descrições",
  continue: "Continuar",
  unavailableEnterprise: "Empresa desativada",
  contactSalesForPayment:
    "Por favor, entre em contato com <a href='mailto:ventas@buildpeer.com'>ventas@buildpeer.com</a> para realizar seu pagamento.",
  addPeopleToDistributionList: "Adicione pessoas à sua lista de distribuição",
  invalidRoleNameTitle: "Ação inválida",
  addInvalidRoleNameWarning:
    "O nome do papel Super Admin está reservado para o sistema.",
  editInvalidRoleNameWarning: "O papel Super Admin não pode ser atualizado.",
  deleteInvalidRoleNameWarning: "O papel de Super Admin não pode ser excluído.",
  uploads: "Uploads",
  downloads: "Downloads",
  uploaded: "Subido",
  downloaded: "Baixado",
  uploading: "Subindo",
  downloading: "Baixando",
};
