import { Card, CardContent, Typography } from "@mui/material";

function PropertyCard({ value, label, img }) {
  return (
    <Card sx={{ minWidth: "160px", overflowY: "true", my: "1%" }}>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {img}
        <Typography fontSize="14px" style={{ marginTop: 5 }} color="#707070">
          {label}
        </Typography>
        <Typography fontSize="18px" fontWeight="medium">
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
}

export { PropertyCard };
