import { tour } from "./tour";

export const documents = {
  // general tour
  ...tour,
  moduleElement: "Documentos",
  // ADD FILE - guide me steps
  step1_docsAddFile_title: "1. Agregar",
  step1_docsAddFile_text: "Haz click en el icono de +",
  step2_docsAddFile_title: "2. Agregar Archivo",
  step2_docsAddFile_text: "Selecciona la opción de agregar archivo.",
  step3_docsAddFile_title: "3. Subir archivos",
  step3_docsAddFile_text:
    "Arrastra los archivos, o haz click en el cuadrado punteado para subirlos.",
  step4_docsAddFile_title: "4. Privacidad",
  step4_docsAddFile_text:
    "Si se activa, se restringirá el acceso a ciertas empresas y personas.",
  step5_docsAddFile_title: "5. Agregar",
  step5_docsAddFile_text: "Haz click aquí para agregar los archivos.",
  step_final_docsAddFile: "Haz aprendido cómo agregar un archivo.",
  // ADD FOLDER - guide me steps
  step1_docsAddFolder_title: "1. Agregar",
  step1_docsAddFolder_text: "Haz click en el icono de +",
  step2_docsAddFolder_title: "2. Agregar Carpeta",
  step2_docsAddFolder_text: "Selecciona la opción de agregar carpeta.",
  step3_docsAddFolder_title: "3. Nombre de la carpeta",
  step3_docsAddFolder_text: "Escribe el nombre de la carpeta",
  step4_docsAddFolder_title: "4. Privacidad",
  step4_docsAddFolder_text:
    "Si se activa, se restringirá el acceso a ciertas empresas y personas.",
  step5_docsAddFolder_title: "5. Agregar",
  step5_docsAddFolder_text: "Haz click aquí para agregar la carpeta.",
  step_final_docsAddFolder: "Haz aprendido cómo agregar una carpeta.",
  // ACTIONS - guide me steps
  step1_docsActions_title: "1. Acciones",
  step1_docsActions_text:
    "Aquí tienes diferentes acciones que puedes realizar sobre los archivos/carpetas.",
  step2_docsActions_title: "2. Seleccionar como favorito",
  step2_docsActions_text:
    "Haz click aquí para determinar el archivo favorito y acceder fácilmente.",
  step3_docsActions_title: "3. Descargar",
  step3_docsActions_text:
    "Haz click aquí para descargar el archivo seleccionado.",
  /*step4_docsActions_title: "4. Email",
  step4_docsActions_text:
    "Haz click aquí para mandar el archivo seleccionado por correo electrónico.",*/
  step5_docsActions_title: "4. Editar nombre",
  step5_docsActions_text: "Haz click aquí para editar el nombre del archivo.",
  step6_docsActions_title: "5. Más acciones",
  step6_docsActions_text:
    "Haz click en los 3 puntos y se desplegara un menu con mas acciones.",
  step7_docsActions_title: "6. Información",
  step7_docsActions_text:
    "Aqui puedes ver el peso del archivo y/o carpeta, la fecha de creación, los permisos, el historial de cambios y la lista de correos que han enviado. (Ver guide me de esta función)",
  step8_docsActions_title: "7. Mover",
  step8_docsActions_text:
    "Haz click aquí para abrir el menú para abrir el menú para mover archivos (Ver guide me de esta función)",
  step9_docsActions_title: "8. Copiar",
  step9_docsActions_text:
    "Haz click aquí para copiar el archivo en el portapapeles.",
  step10_docsActions_title: "9. Eliminar",
  step10_docsActions_text:
    "Haz click aquí si se quiere eliminar de manera permanente el archivo",
  step_final_docsActions:
    "Haz aprendido cómo ejecutar diferentes acciones que puedes realizar sobre los archivos/carpetas.",
  // MOVE TO - guide me steps
  step1_docsMoveTo_title: "1. Más acciones",
  step1_docsMoveTo_text:
    "Haz click en los 3 puntos y se desplegara un menu con mas acciones.",
  step2_docsMoveTo_title: "2. Mover",
  step2_docsMoveTo_text:
    "Haz click aquí para abrir el menú para abrir el menú para mover archivos.",
  step3_docsMoveTo_title: "3. Mover archivo",
  step3_docsMoveTo_text:
    "Selecciona la nueva ruta deseada y selecciona aceptar para mover el archivo.",
  step4_docsMoveTo_title: "4. Aceptar",
  step4_docsMoveTo_text:
    "Haz click aquí para confirmar la nueva ubicación y mover el archivo.",
  step_final_docsMoveTo: "Haz aprendido cómo mover archivos/carpetas.",
  // FILE INFO - guide me steps
  step1_docsFileInfo_title: "1. Más acciones",
  step1_docsFileInfo_text:
    "Haz click en los 3 puntos y se desplegara un menu con mas acciones.",
  step2_docsFileInfo_title: "2. Información",
  step2_docsFileInfo_text:
    "Aqui puedes ver el peso del archivo y/o carpeta, la fecha de creación, los permisos, el historial de cambios y la lista de correos que han enviado.",
  step3_docsFileInfo_title: "3. Gestionar permisos",
  step3_docsFileInfo_text:
    "Haz click aquí para poder gestionar cuáles son las empresas y las personas que tiene acceso al documento.",
  step4_docsFileInfo_title: "4. Historial de cambio",
  step4_docsFileInfo_text:
    "Haz click aquí para ver la información respecto a los cambios que se han hecho en los documentos, y quienes realizaron los cambios.",
  step5_docsFileInfo_title: "5. Historial de correos",
  step5_docsFileInfo_text:
    "Haz click aquí para ver el historial de los correos que se han realizado respecto al documento.",
  step5_1_docsFileInfo_title: "5.1 Descargar",
  step5_1_docsFileInfo_text:
    "Haz click aquí para descargar la información del correo electrónico",
  step_final_docsFileInfo:
    "Haz aprendido cómo ver la información de archivos/carpetas.",
  // SETTINGS - guide me steps
  step1_docsSettings_title: "2. Abrir menu de configuración",
  step1_docsSettings_text:
    "Haz click aquí para abrir el menu de configuración.",
  step2_docsSettings_title: "3. Lista de distribución",
  step2_docsSettings_text:
    "Agrega o elimina las personas que tendrán acceso de manera predeterminada al modulo de documentos.",
  step3_docsSettings_title: "4. Guardar",
  step3_docsSettings_text: "Haz click aquí para guardar los ajustes.",
  step_final_docsSettings: "Haz aprendido cómo ajustar la configuración.",

  lastEdit: "Última Edición",
  uploadedCreatedBy: "Subido / Creado Por",
  uploadedCreatedOn: "Subido / Creado En",
  recentDocuments: "Documentos Recientes",
  addFolder: "Agregar Carpeta",
  addFile: "Agregar Archivo",
  folderIsPublic: "Esta carpeta es pública para cualquiera en",
  folderIsPrivate: "Esta carpeta es privada",
  documentMovedMessage: "Ubicación de documento cambiada con éxito.",
  folderMovedMessage: "Ubicación de carpeta cambiada con éxito.",
  recentDocument: "Documento reciente",
  documentsRecycleBin: "Basurero de Documentos",
  noDocumentsCreated: "No hay documentos creados",
  addDocumentsPrompt:
    "Si desea agregar documentos, haga clic en el botón de añadir",
  fileUpdatedSuccessfully: "Archivo actualizado con éxito.",
  folderMarkedSuccessfully: "Carpeta marcada con éxito.",
  fileMarkedSuccessfully: "Archivo marcado con éxito.",
  folderDeletedSuccessfully: "Carpeta(s) eliminada(s) con éxito",
  fileDeletedSuccessfully: "Archivo(s) eliminado(s) con éxito",
  storage: "Almacenamiento",
  sendToEmail: "Enviar a Correo Electrónico",
  sendTo: "Enviar a",
  subject: "Asunto",
  body: "Mensaje",
  selectAll: "Seleccionar Todos",
  deleteItems: "Eliminar Archivo(s)",
  deleteQuestion: "¿Está seguro de que desea eliminar este ",
  deleteItemsQuestion:
    "¿Está seguro de que desea eliminar los archivos seleccionados?",
  deleteItemsWarning: "Esta acción no se puede deshacer.",
  file: "archivo",
  folder: "carpeta",
  renameFolder: "Renombrar Carpeta",
  renameFile: "Renombrar Archivo",
  folderName: "Nombre de la Carpeta",
  fileName: "Nombre del Archivo",
  folderCreatedSuccessfully: "Carpeta creada con éxito.",
  fileCreatedSuccessfully: "Archivo creado con éxito.",
  noFilesOnFavorites: "No hay archivos en favoritos",
  noFilesOnRecycleBin: "No hay archivos en el basurero",
  fileRestoreSuccessfully: "Archivo restaurado con éxito.",
  folderRestoreSuccessfully: "Carpeta restaurada con éxito.",
  deleteFolder: "Eliminar Carpeta",
};
