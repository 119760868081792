import { apiSlice } from "../../api/apiSlice";
import { objectToParamString } from "../../utils";

const drawingsQuery = "project/drawing";

export const drawingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDrawings: builder.query({
      query: ({
        idProject,
        setIds,
        disciplineIds,
        search,
        startDate,
        endDate,
      }) =>
        `${drawingsQuery}/${idProject}${objectToParamString({
          setIds,
          disciplineIds,
          search,
          startDate,
          endDate,
        })}`,
      providesTags: ["Drawings"],
    }),
    getDrawingsRecycleBin: builder.query({
      query: (idProject) => `${drawingsQuery}/recycleBin/${idProject}`,
      providesTags: ["Drawings"],
    }),
    getDrawingById: builder.query({
      query: (id) => `${drawingsQuery}/getDrawing/${id}`,
      providesTags: ["Drawings"],
    }),
    getPresignUrls: builder.mutation({
      query: (body) => ({
        url: `${drawingsQuery}/uploadUrls`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Drawings']
    }),
    addDrawingV2: builder.mutation({
      query: (body) => ({
        url: `${drawingsQuery}/addDrawing`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Drawings']
    }),
    addDrawing: builder.mutation({
      query: (body) => ({
        url: `${drawingsQuery}/createDrawing`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Drawings"],
    }),

    getToCheckDrawings: builder.query({
      query: ({ idProject, search }) =>
        `${drawingsQuery}/toCheck/${idProject}${objectToParamString({
          search,
        })}`,
      providesTags: ["Drawings"],
    }),
    getToPublishDrawings: builder.query({
      query: ({ idProject, search }) =>
        `${drawingsQuery}/toPublish/${idProject}${objectToParamString({
          search,
        })}`,
      providesTags: ["Drawings"],
    }),
    getDrawingIds: builder.query({
      query: (idProject) => `${drawingsQuery}/getDrawingIds/${idProject}`,
      providesTags: ["Drawings"],
    }),
    updateDrawing: builder.mutation({
      query: (body) => ({
        url: `${drawingsQuery}/editDrawing`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Drawings"],
    }),
    getDrawingsList: builder.query({
      query: (idProject) => `${drawingsQuery}/drawingsList/${idProject}`,
    }),
    getDrawingsByDiscipline: builder.query({
      query: (idDiscipline) => `${drawingsQuery}/byDiscipline/${idDiscipline}`,
    }),
    commentDrawing: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${drawingsQuery}/comment/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Drawings"],
    }),
    sendEmailDrawing: builder.mutation({
      query: (body) => ({
        url: `${drawingsQuery}/send-email`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Drawings"],
    }),
  }),
});

export const {
  useGetDrawingsQuery,
  useGetPresignUrlsMutation,
  useAddDrawingV2Mutation,
  useAddDrawingMutation,
  useGetToCheckDrawingsQuery,
  useGetToPublishDrawingsQuery,
  useGetDrawingIdsQuery,
  useGetDrawingByIdQuery,
  useUpdateDrawingMutation,
  useGetDrawingsRecycleBinQuery,
  useGetDrawingsListQuery,
  useGetDrawingsByDisciplineQuery,
  useCommentDrawingMutation,
  useSendEmailDrawingMutation,
} = drawingsApiSlice;
