const inspectionsTemplates = {
  add: "템플릿 추가",
  edit: "템플릿 수정",
  guideMe: "가이드",
  inspection_template_recycle_bin: "검사 - 템플릿 - 재활용통",
  list: "검사 목록",
  meeting: "미팅",
  no_inspections_templates_text:
    "하나를 추가하려면 검사 템플릿 추가 버튼을 클릭하여 프로세스를 시작하십시오",
  no_inspections_templates_title: "현재까지 검사 템플릿이 생성되지 않았습니다",
  title: "검사 - 템플릿",
  workflow: "워크플로우",
  add_button_tour: "새로운 검사 템플릿 생성",
};

export { inspectionsTemplates };
