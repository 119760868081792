import React, { useMemo, useState } from "react";

import { ArrowBack, ChevronRight, Clear, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Checkbox,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useTour } from "@reactour/tour";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { selectTheme } from "../../features/preferences/preferencesSlice";
import { useGetLocationsQuery } from "../../features/locations/locationApiSlice";

// NOTES
/*
    STRUCTURE OF LIST ITEMS
    selectedLocations = [{
        id: uuid,
        locationName: string,
        ...location,
    }]
*/

function SearchLocationFilter(props) {
  const {
    title = "",
    onChange,
    selectedLocations, // Object Array
    selectProps = {},
  } = props;

  const { idProject } = useParams();

  const { t: tGeneral } = useTranslation("general");

  const darkMode = useSelector(selectTheme);

  const { isOpen } = useTour();

  const { data: locations } = useGetLocationsQuery(idProject, {
    skip: !idProject,
    refetchOnMountOrArgChange: true,
  });

  const [tempLocations, setTempLocations] = useState(selectedLocations);
  const [currentTier, setCurrentTier] = useState(0);
  const [superLocation, setSuperLocation] = useState([]);
  const [value, setValue] = useState("");

  const tiers = useMemo(() => {
    if (locations) {
      return Object.keys(locations);
    }
    return [];
  }, [locations]);

  const handleOnChange = (location) => {
    const isChecked =
      tempLocations.findIndex(
        (selectedLocation) => selectedLocation.id === location.id,
      ) > -1;

    if (!isChecked) {
      // If the checkbox is checked, add the barrier to the selected list
      setTempLocations([...tempLocations, { ...location }]);
    } else {
      // If the checkbox is unchecked, remove the barrier from the selected list
      setTempLocations(tempLocations.filter((el) => el.id !== location.id));
    }
  };

  const handlePrevTier = () => {
    const lastId = superLocation[currentTier - 1].id;
    setSuperLocation((prev) => prev.filter((loc) => loc.id !== lastId));
    setCurrentTier((prev) => prev - 1);
    setValue("");
  };

  const handleNextTier = (location) => {
    if (currentTier < tiers.length) {
      setSuperLocation((prev) => [...prev, location]);
      setCurrentTier((prev) => prev + 1);
      setValue("");
    }
  };

  const filterBySuperLocation = (location) => {
    if (superLocation.length === 0) {
      return true;
    }
    return superLocation[currentTier - 1].id === location.superLocation;
  };

  return (
    <Box sx={selectProps}>
      <Autocomplete
        multiple
        options={
          tiers.length
            ? locations[tiers[currentTier]].filter(filterBySuperLocation)
            : []
        }
        getOptionLabel={(option) => option.locationName}
        disableCloseOnSelect
        value={tempLocations}
        onClose={() => {
          setSuperLocation([]);
          setCurrentTier(0);
          onChange(tempLocations);
        }}
        sx={{ backgroundColor: darkMode ? "" : "#FFF5DB" }}
        disablePortal={isOpen}
        componentsProps={{
          popper: {
            style: {
              minWidth: selectProps?.width ? selectProps?.width : "auto",
              width: "fit-content",
            },
          },
        }}
        noOptionsText={
          <>
            {currentTier > 0 && (
              <Box display="flex" alignItems="center">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePrevTier();
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <Typography fontWeight="bold">
                  {superLocation[currentTier - 1].locationName}
                </Typography>
              </Box>
            )}
            {tGeneral("noOptions")}
          </>
        }
        renderOption={(props, location, { selected, index }) => (
          <>
            {currentTier > 0 && index === 0 && (
              <Box display="flex" alignItems="center">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handlePrevTier();
                  }}
                >
                  <ArrowBack />
                </IconButton>
                <Typography fontWeight="bold">
                  {superLocation[currentTier - 1].locationName}
                </Typography>
              </Box>
            )}
            <li key={location.id}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor:
                    tempLocations.findIndex(
                      (selectedLocation) => selectedLocation.id === location.id,
                    ) > -1
                      ? "#fbcb0414"
                      : "transparent",
                }}
              >
                <Box
                  width="90%"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  onClick={(e) => {
                    handleOnChange(location);
                  }}
                >
                  <Checkbox
                    checked={
                      tempLocations.findIndex(
                        (selectedLocation) =>
                          selectedLocation.id === location.id,
                      ) > -1
                    }
                  />
                  <Typography>{location.locationName}</Typography>
                </Box>
                {currentTier < tiers.length - 1 && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleNextTier(location);
                    }}
                  >
                    <ChevronRight />
                  </IconButton>
                )}
              </Box>
            </li>
          </>
        )}
        inputValue={value}
        onInputChange={(event, newInputValue) => {
          setValue(newInputValue);
        }}
        renderInput={(params) => (
          <Box ref={params.InputProps.ref}>
            <TextField
              {...params.inputProps}
              fullWidth
              label={`${title} (${tempLocations.length})`}
              sx={{ padding: 0, height: "40px", backgroundColor: "#FFF5DB" }}
              size="small"
              InputProps={{
                endAdornment:
                  selectedLocations.length > 0 ? (
                    <IconButton
                      onClick={(e) => {
                        onChange([]);
                      }}
                    >
                      <Clear />
                    </IconButton>
                  ) : (
                    <Search />
                  ),
                sx: { height: "40px", padding: "14px !important" },
              }}
            />
          </Box>
        )}
      />
    </Box>
  );
}

export { SearchLocationFilter };
