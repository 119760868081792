import { Add, Delete, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StyledMaterialReactTable, TooltipIconButton } from "../../../shared";

function CreateProjectDisciplines() {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");
  const [name, setName] = useState("");

  const { control, watch } = useFormContext();
  const { append, remove } = useFieldArray({ control, name: "disciplines" });

  const handleAdd = () => {
    append(name);
    setName("");
  };

  const disciplines = watch("disciplines");

  const columns = [
    {
      accessorKey: "name",
      header: tGeneral("name"),
      Cell: ({ cell }) => <Typography>{cell.getValue()}</Typography>,
      size: 180,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
  ];

  const handleDelete = (index) => {
    remove(index);
  };

  return (
    <Accordion sx={{ border: "1px solid #bfbfbf" }}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        {tDrawings("disciplines")}
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" mb="0.5rem" columnGap="0.5rem">
          <TextField
            label={tGeneral("name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ width: "50%" }}
            size="small"
          />
          <TooltipIconButton
            icon={<Add />}
            label={tGeneral("add")}
            onClick={handleAdd}
          />
        </Box>
        <StyledMaterialReactTable
          columns={columns}
          data={disciplines.map((discipline, i) => ({
            id: i,
            name: discipline,
          }))}
          renderRowActions={({ row }) => (
            <TooltipIconButton
              icon={<Delete />}
              label={tGeneral("delete")}
              onClick={() => handleDelete(row.original?.id)}
              color="red"
            />
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export { CreateProjectDisciplines };
