import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
// Redux reducers & selectors
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// Material UI Components
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// Material UI Icons
import {
  Logout,
  ManageAccounts,
  MenuBook,
  Notifications,
} from "@mui/icons-material";
import AdminPanelSettings from "@mui/icons-material/AdminPanelSettings";
import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded";
import ContactsIcon from "@mui/icons-material/Contacts";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
// Custom Components
import { Badge, ButtonGroup } from "@mui/material";
import { useLogoutMutation } from "../../features/auth/authApiSlice";
import { setAccessToken } from "../../features/auth/authSlice";
import {
  resetEnterprises,
  selectCurrentEnterprise,
} from "../../features/enterprise/enterpriseSlice";
import { resetPreferences } from "../../features/preferences/preferencesSlice";
import { resetProjects } from "../../features/project/projectSlice";
import { selectCurrentUser } from "../../features/userSettings/userSettingsSlice";
import Can from "../../utils/can";
import {
  ModalWithAction,
  NotificationsDrawer,
  TooltipIconButton,
} from "../shared";
import { EnterpriseSelector } from "../shared/EnterpriseSelector";
import DrawerAvatar from "../userAvatars/DrawerAvatar";
import CaslContext from "../../utils/caslContext";
import { useNotificationsCounterPolling } from "../../utils/useNotificationsInfinitePolling";

function DrawerEnterprise(props) {
  const { idEnterprise } = useParams();

  const currentEnterprise = useSelector(selectCurrentEnterprise);
  const [enterpriseOpen, setEnterpriseOpen] = useState(false);
  const { t: tGeneral } = useTranslation("general");
  const { t: tTemplates } = useTranslation("templates");
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isLogoutOpen, setIsLogoutOpen] = useState(false);
  const dispatch = useDispatch();
  const { count } = useNotificationsCounterPolling();

  const [logout] = useLogoutMutation();

  // Hooks
  const location = useLocation();
  const navigate = useNavigate();
  //  Get current user
  const user = useSelector(selectCurrentUser);

  // Open & close enterprise collapsable item
  const toggleEnterprise = () => {
    setEnterpriseOpen(!enterpriseOpen);
  };

  const drawerContent = React.useMemo(() => {
    const handleLogout = async () => {
      try {
        dispatch(setAccessToken(null));
        dispatch(resetEnterprises());
        dispatch(resetProjects());
        dispatch(resetPreferences());
        await logout().unwrap();
        navigate("/login", { replace: true });
      } catch (err) {
        console.error(err);
      }
    };

    return (
      <Box
        display="flex"
        flexDirection="column"
        height="100vh"
        maxHeight="100vh"
      >
        <EnterpriseSelector />
        <List sx={{ flex: 1, overflowY: "auto" }}>
          {/* Projects */}
          <ListItem disablePadding>
            <ListItemButton
              selected={/^\/enterprise\/.+\/dashboard$/.test(location.pathname)}
              onClick={() => {
                navigate("dashboard");
                props?.toggle();
              }}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "rgba(251, 203, 4, 0.5)",
                },
              }}
            >
              <ListItemIcon>
                <GridViewRoundedIcon />
              </ListItemIcon>
              <ListItemText primary={tGeneral("projects")} />
            </ListItemButton>
          </ListItem>

          {currentEnterprise?.libraries && (
            <ListItem disablePadding>
              <ListItemButton
                selected={/^\/enterprise\/.+\/templates$/.test(
                  location.pathname,
                )}
                onClick={() => {
                  navigate("templates");
                  props?.toggle();
                }}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "rgba(251, 203, 4, 0.5)",
                  },
                }}
              >
                <ListItemIcon>
                  <MenuBook />
                </ListItemIcon>
                <ListItemText primary={tTemplates("libraries")} />
              </ListItemButton>
            </ListItem>
          )}

          {/* Verify is user is Enterprise Admin */}
          <Can I="view" a="enterpriseInfo">
            {/* Enterprise */}
            <ListItem disablePadding>
              <ListItemButton onClick={toggleEnterprise}>
                <ListItemIcon>
                  <ApartmentRoundedIcon />
                </ListItemIcon>
                <ListItemText primary={tGeneral("enterprise")} />
                {enterpriseOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>

            <Collapse in={enterpriseOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* Super Admins */}
                <ListItemButton
                  selected={/^\/enterprise\/.+\/admins$/.test(
                    location.pathname,
                  )}
                  onClick={() => {
                    navigate("admins");
                    props.toggle();
                  }}
                  sx={{
                    pl: 4,
                    "&.Mui-selected": {
                      backgroundColor: "rgba(251, 203, 4, 0.5)",
                    },
                  }}
                >
                  <ListItemIcon>
                    <AdminPanelSettings />
                  </ListItemIcon>
                  <ListItemText primary={tGeneral("superAdmins")} />
                </ListItemButton>

                {/* General Directory */}
                <ListItemButton
                  selected={/^\/enterprise\/.+\/directory$/.test(
                    location.pathname,
                  )}
                  onClick={() => {
                    navigate("directory");
                    props.toggle();
                  }}
                  sx={{
                    pl: 4,
                    "&.Mui-selected": {
                      backgroundColor: "rgba(251, 203, 4, 0.5)",
                    },
                  }}
                >
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary={tGeneral("users")} />
                </ListItemButton>

                {/* External Contacts */}
                <ListItemButton
                  selected={/^\/enterprise\/.+\/contacts$/.test(
                    location.pathname,
                  )}
                  onClick={() => {
                    navigate("contacts");
                    props.toggle();
                  }}
                  sx={{
                    pl: 4,
                    "&.Mui-selected": {
                      backgroundColor: "rgba(251, 203, 4, 0.5)",
                    },
                  }}
                >
                  <ListItemIcon>
                    <ContactsIcon />
                  </ListItemIcon>
                  <ListItemText primary={tGeneral("externalContacts")} />
                </ListItemButton>

                {/* Roles */}
                <ListItemButton
                  selected={/^\/enterprise\/.+\/roles$/.test(location.pathname)}
                  onClick={() => {
                    navigate("roles");
                    props.toggle();
                  }}
                  sx={{
                    pl: 4,
                    "&.Mui-selected": {
                      backgroundColor: "rgba(251, 203, 4, 0.5)",
                    },
                  }}
                >
                  <ListItemIcon>
                    <PersonAddAltRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary={tGeneral("roles")} />
                </ListItemButton>

                {/* Enterprise Settings */}
                <ListItem disablePadding>
                  <ListItemButton
                    selected={/^\/enterprise\/.+\/enterpriseSettings$/.test(
                      location.pathname,
                    )}
                    onClick={() => {
                      navigate("enterpriseSettings");
                      props.toggle();
                    }}
                    sx={{
                      pl: 4,
                      "&.Mui-selected": {
                        backgroundColor: "rgba(251, 203, 4, 0.5)",
                      },
                    }}
                  >
                    <ListItemIcon>
                      <ManageAccounts />
                    </ListItemIcon>
                    <ListItemText primary={tGeneral("settings")} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
          </Can>
        </List>
        <Divider />
        {/* User Avatar */}
        <DrawerAvatar user={user} />
        <ButtonGroup sx={{ justifyContent: "space-around", mb: "10px" }}>
          <TooltipIconButton
            icon={<Logout />}
            label={tGeneral("logout")}
            color="black"
            onClick={() => setIsLogoutOpen(true)}
          />

          <TooltipIconButton
            icon={
              <Badge
                badgeContent={
                  // eslint-disable-next-line no-nested-ternary
                  count?.unreadNotifications
                    ? count?.unreadNotifications > 99
                      ? "99+"
                      : count?.unreadNotifications
                    : 0
                }
                color="primary"
              >
                <Notifications />
              </Badge>
            }
            label={tGeneral("notifications")}
            color="black"
            onClick={() => setIsNotificationsOpen(true)}
          />
        </ButtonGroup>
        <ModalWithAction
          action={handleLogout}
          actionLabel={tGeneral("accept")}
          title={tGeneral("logout")}
          content={tGeneral("logoutMessage")}
          onClose={() => setIsLogoutOpen(false)}
          isOpen={isLogoutOpen}
        />
      </Box>
    );
  }, [
    dispatch,
    enterpriseOpen,
    isLogoutOpen,
    location.pathname,
    logout,
    navigate,
    count?.unreadNotifications,
    props,
    tGeneral,
    toggleEnterprise,
    user,
  ]);

  if (!user) {
    return (
      <Box
        component="nav"
        sx={{
          width: {
            xs: props.drawerWidth,
          },
          flexShrink: { xs: 1 },
        }}
        aria-label="drawer enterprise"
      >
        <NotificationsDrawer
          isOpen={isNotificationsOpen}
          onClose={() => setIsNotificationsOpen(false)}
        />
      </Box>
    );
  }

  return (
    <Box
      component="nav"
      sx={{
        width: {
          xs: props.drawerWidth,
        },
        flexShrink: { xs: 1 },
      }}
      aria-label="drawer enterprise"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        variant="temporary"
        open={props.open}
        onClose={props.toggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: props.drawerWidth || 240,
          },
        }}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: props.drawerWidth || 240,
          },
        }}
        open
      >
        {drawerContent}
      </Drawer>
      <NotificationsDrawer
        isOpen={isNotificationsOpen}
        onClose={() => setIsNotificationsOpen(false)}
      />
    </Box>
  );
}

export default DrawerEnterprise;
