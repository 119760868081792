import {
  Bolt,
  Info,
  InsertDriveFile,
  PlaylistAddCheck,
  Search,
  ShowChart,
  Update,
} from "@mui/icons-material";
import { Box, Button, TextField } from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchLocationFilterV2 } from "../../../../components/shared";
import { FiltersButton } from "./FiltersButton";

function ItemsSection({ setFilter, filters, reports }) {
  const { t: tDailyReports } = useTranslation("dailyReports");
  const { t: tGeneral } = useTranslation("general");

  const timer = useRef(null);
  const [search, setSearch] = useState(filters.search);

  const categoryOptions = [
    {
      label: "all",
      icon: <PlaylistAddCheck />,
      category: null,
    },
    {
      label: "basic",
      icon: <InsertDriveFile />,
      category: 1,
    },
    {
      label: "workProgress",
      icon: <ShowChart />,
      category: 2,
    },
    {
      label: "problem",
      icon: <Info />,
      category: 3,
    },
    {
      label: "delay",
      icon: <Update />,
      category: 4,
    },
    {
      label: "manpower",
      icon: <Bolt />,
      category: 5,
    },
  ];

  const setCategoryFilter = (category) => {
    if (category === null) {
      setFilter({ ...filters, category: [] });
      return;
    }
    const currentCategoryFilter = filters?.category
      ? [...filters.category]
      : [];
    const categoryIndex = currentCategoryFilter.indexOf(category);
    if (categoryIndex > -1) {
      currentCategoryFilter.splice(categoryIndex, 1);
    } else {
      currentCategoryFilter.push(category);
    }
    setFilter({ ...filters, category: currentCategoryFilter });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setFilter({ ...filters, search: e.target.value });
    }, 1500);
  };

  return (
    <Box mb="1rem">
      <Box display="flex" mb="1rem" alignItems="center" columnGap="1rem">
        <TextField
          fullWidth
          size="small"
          placeholder={tGeneral("search")}
          InputProps={{
            startAdornment: <Search sx={{ color: "#E6B60C" }} />,
            sx: { borderRadius: "1rem", backgroundColor: "white" },
          }}
          value={search}
          onChange={handleSearch}
          sx={{ flex: 1 }}
        />
        <Box flex={1}>
          <SearchLocationFilterV2
            selectedLocations={filters.location ?? []}
            onChange={(val) => setFilter({ ...filters, location: val })}
          />
        </Box>
        <FiltersButton
          filters={filters}
          setFilters={setFilter}
          countResults={reports?.length}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-around",
          alignItems: "center",
          columnGap: "1rem",
        }}
      >
        {categoryOptions.map((category) => (
          <Button
            key={category.label}
            onClick={() => setCategoryFilter(category.category)}
            variant="outlined"
            sx={{
              textTransform: "none",
              flex: 1,
              borderRadius: "1rem",
              border: "0.05rem solid #E1E1E1",
              color: "#4B4B4B",
              backgroundColor: "white",
              "&:hover": {
                border: "0.05rem solid #E1E1E1 !important",
                backgroundColor: "white",
                color: "#E6B60C !important",
              },
              "&.active": {
                border: "0.05rem solid #E6B60C",
                color: "#E6B60C",
              },
            }}
            className={
              filters?.category?.indexOf(category.category) > -1 ||
              (!category.category && !filters?.category?.length)
                ? "active"
                : ""
            }
            startIcon={category.icon}
          >
            {category.label === "all"
              ? tGeneral("all")
              : tDailyReports(category.label)}
          </Button>
        ))}
      </Box>
    </Box>
  );
}

export { ItemsSection };
