import { Delete } from "@mui/icons-material";
import { Box, FormControl, IconButton, Typography } from "@mui/material";
import { Avatar } from "antd";
import { useFormContext } from "react-hook-form";
import { FilterableUserDropdown } from "../FilterableUserDropdown";
import { useTranslation } from "react-i18next";

function DeletableUser({ user, onDelete, canRemoveSelf, currentUserId }) {
  return (
    <Box
      display="flex"
      borderRadius="20px"
      alignItems="center"
      padding="0 10px"
      height="40px"
      sx={{ backgroundColor: "#F7E4B2", my: "10px" }}
    >
      <Avatar src={user.urlAvatar} sx={{ width: "30px", height: "30px" }}>
        {user.firstName[0]}
        {user.lastName[0]}
      </Avatar>
      <Box flex={1}>
        <Typography sx={{ pl: "10px" }}>
          {user.firstName} {user.lastName}
        </Typography>
      </Box>
      {(canRemoveSelf || user.id !== currentUserId) && (
        <IconButton onClick={onDelete}>
          <Delete sx={{ color: "red" }} />
        </IconButton>
      )}
    </Box>
  );
}

function CreateFormUsersSelect({
  users,
  label,
  name,
  required,
  canRemoveSelf = true,
  currentUserId = "",
  dataTourName = "",
}) {
  const { t: tGeneral } = useTranslation("general");
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const value = watch(name) ?? [];
  const handleDeleteUser = (id) => {
    setValue(
      name,
      value.filter((val) => val !== id),
    );
  };
  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      overflow="hidden"
      p="5px 10px"
      data-tour={dataTourName}
    >
      <FormControl fullWidth required={required}>
        <FilterableUserDropdown
          title={label}
          userList={users}
          selectedUsersList={users.filter(
            (user) => value.indexOf(user.id) > -1,
          )}
          selectedUsersFormatted={value}
          handleUpdateUsersList={(list) =>
            setValue(
              name,
              list.map((user) => user.id),
            )
          }
        />
      </FormControl>
      {errors?.[name] && (
        <Typography color="red" fontSize="0.7rem">
          {tGeneral("fieldRequired")}*
        </Typography>
      )}
      <Box flex={1} sx={{ overflowY: "auto" }} mt="10px">
        {users
          .filter((user) => value.indexOf(user.id) > -1)
          .map((user) => (
            <DeletableUser
              user={user}
              onDelete={() => handleDeleteUser(user.id)}
              canRemoveSelf={canRemoveSelf}
              currentUserId={currentUserId}
              key={user.id}
            />
          ))}
      </Box>
    </Box>
  );
}

export { CreateFormUsersSelect };
