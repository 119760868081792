export const announcements = {
  published: "已发布",
  scheduled: "已计划",
  newAnnouncement: "新公告",
  announcements: "公告",
  announcement: "公告",
  generals: "常规",
  programmed: "已计划",
  expiration: "到期",
  creator: "创建者",
  repeat: "重复",
  nextPublication: "下一次发布",
  programAnnouncement: "计划公告",
  programAnnouncementNow: "公告将立即发布",
  programAnnouncementLater: "公告将计划发布",
  repeatList: "重复列表",
  description: "描述",
  startDate: "开始日期",
  expirationDate: "到期日期",
  time: "时间",
  distributionList: "分发列表",
  never: "从不",
  everyDay: "每天",
  everyWeek: "每周",
  everyTwoWeeks: "每两周",
  everyMonth: "每月",
  everyYear: "每年",
  noAnnouncements: "目前没有创建任何公告",
  addAnnouncement: "添加公告",
  noAnnouncementsPar: "如果您想添加一个，请点击添加公告按钮开始该过程",
  deleteAnnouncement: "删除公告",
  confirmDelete: "确定要删除该公告吗？此操作无法撤销",
  recycleBin: "回收站",
  editAnnouncement: "编辑公告",
  programForLater: "计划发布",
  addAnnouncementTitle: "目前没有创建任何公告",
  addAnnouncementValidation: "如果您想添加一个，请点击添加按钮开始该过程",
  noAnnouncementsRestore: "没有选定要恢复的公告",
  expire: "到期",
  step1_addAnnouncement_title: "1. 创建新公告",
  step1_addAnnouncement_text: "单击 + 图标添加新公告。",
  step2_addAnnouncement_title: "2. 安排公告",
  step2_addAnnouncement_text:
    "选择公告是立即发布还是稍后安排。如果选择后者，将显示其他选项。",
  step2_1_addAnnouncement_title: "2.1 重复",
  step2_1_addAnnouncement_text: "选择公告重复的频率。",
  step2_2_addAnnouncement_title: "2.2 开始日期",
  step2_2_addAnnouncement_text: "选择公告发布的日期。",
  step2_3_addAnnouncement_title: "2.3 开始时间",
  step2_3_addAnnouncement_text: "选择公告发布的时间。",
  step3_addAnnouncement_title: "3. 描述",
  step3_addAnnouncement_text: "编写公告的主题。",
  step4_addAnnouncement_title: "4. 分发列表",
  step4_addAnnouncement_text: "选择将收到公告通知的人员。",
  step5_addAnnouncement_title: "5. 到期日期",
  step5_addAnnouncement_text: "选择公告将过期/变为不可见的日期。",
  step6_addAnnouncement_title: "6. 到期时间",
  step6_addAnnouncement_text: "选择公告将到期并不再可见的时间。",
  step7_addAnnouncement_title: "7. 添加",
  step7_addAnnouncement_text: "单击此处发布公告。",
  step_final_addAnnouncement: "您已学会如何创建公告",
  step1_viewAnnouncement_title: "1. 查看公告",
  step1_viewAnnouncement_text: "单击此处查看公告信息。",
  step2_viewAnnouncement_title: "2. 查看公告",
  step2_viewAnnouncement_text:
    "在弹出窗口中，您可以查看有关公告的相关信息，例如描述和日期。",
  step_final_viewAnnouncement: "您已学会如何查看公告",
  step1_editAnnouncement_title: "1. 编辑公告",
  step1_editAnnouncement_text: "单击此处编辑公告。",
  step2_editAnnouncement_title: "2. 编辑公告安排",
  step2_editAnnouncement_text:
    "编辑公告是立即发布还是稍后发布；选择后者将显示其他选项。",
  step2_1_editAnnouncement_title: "2.1 编辑重复",
  step2_1_editAnnouncement_text: "编辑公告重复的频率。",
  step2_2_editAnnouncement_title: "2.2 开始日期",
  step2_2_editAnnouncement_text: "编辑公告开始的日期。",
  step2_3_editAnnouncement_title: "2.3 开始时间",
  step2_3_editAnnouncement_text: "编辑公告开始的时间。",
  step3_editAnnouncement_title: "3. 描述",
  step3_editAnnouncement_text: "编辑公告的主题。",
  step4_editAnnouncement_title: "4. 编辑分发列表",
  step4_editAnnouncement_text: "编辑哪些人可以看到公告。",
  step5_editAnnouncement_title: "5. 到期日期",
  step5_editAnnouncement_text: "编辑公告将不再有效/可见的日期。",
  step6_editAnnouncement_title: "6. 到期时间",
  step6_editAnnouncement_text: "编辑公告将到期并不再可见的时间。",
  step7_editAnnouncement_title: "7. 编辑",
  step7_editAnnouncement_text: "单击此处保存更改。",
  step_final_editAnnouncement: "您已学会如何编辑公告",
  step1_recycleAnnouncement_title: "1. 更多选项",
  step1_recycleAnnouncement_text: "单击此处查看更多选项。",
  step2_recycleAnnouncement_title: "2. 公告回收站",
  step2_recycleAnnouncement_text: "单击此处查看公告回收站。",
  step3_recycleAnnouncement_title: "3. 回收站菜单",
  step3_recycleAnnouncement_text: "这是公告回收站菜单。",
  step4_recycleAnnouncement_title: "4. 查看公告",
  step4_recycleAnnouncement_text: "单击此处查看要恢复的公告信息。",
  step5_recycleAnnouncement_title: "5. 选择公告",
  step5_recycleAnnouncement_text: "选择要恢复的一个或多个公告。",
  step6_recycleAnnouncement_title: "6. 恢复所选公告",
  step6_recycleAnnouncement_text: "单击此处恢复所选的公告。",
  step_final_recycleAnnouncement: "您已学会如何与公告回收站交互",
  guideMe: "引导我",
  support: "支持",
};
