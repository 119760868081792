import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import {
  Close,
  Autorenew,
  Add,
  Visibility,
  Edit,
  Restore,
  PictureAsPdf,
  Settings,
  ContactSupport,
} from "@mui/icons-material";
// import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
// Icons
import GroupsIcon from "@mui/icons-material/Groups"; // meeting
import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded"; // workflow
import { ListItemIcon, Card, Divider } from "@mui/material";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
// Calendly
import { InlineWidget } from "react-calendly";
// Youtube player
import YouTube from "react-youtube";

// Tutorial del modulo
import { useTour } from "@reactour/tour";

// React router
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { setCurrentGuideMe } from "../../features/project/projectSlice";
import Can from "../../utils/can";

import { useGetActiveRfisQuery } from "../../features/project/modules/rfiApiSlice";

import DirectorioProyecto from "../../assets/workflow/DirectorioProyecto.png";
import ProjectDirectory from "../../assets/workflow/ProjectDirectory.png";

import { selectLocale } from "../../features/preferences/preferencesSlice";

function SupportButtonProjectDirectory() {
  const dispatch = useDispatch();

  const { t: tRfis } = useTranslation("rfis");

  const { idProject } = useParams();

  const [rfisLength, setRfisLength] = useState(0);

  const locale = useSelector(selectLocale);
  const [imgWorkflow] = useState(
    locale === "en" ? ProjectDirectory : DirectorioProyecto,
  );

  const { data: rfis } = useGetActiveRfisQuery({ idProject });

  const addSteps = [];

  const viewSteps = [];

  const editSteps = [];

  const recycleSteps = [];

  const exportSteps = [];

  const settingsSteps = [];

  // Support button (make component)
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorSupp, setAnchorSupp] = useState(null);
  // For open
  const handleClick = (event) => {
    setAnchorSupp(event.currentTarget);
    setOpenMenu(true);
  };
  // For close
  const handleClose = () => {
    setAnchorSupp(null);
    setOpenMenu(false);
  };
  // End support button

  // Calendly modal
  const [openModal, setOpenModal] = React.useState(false);
  const handleCloseModal = () => setOpenModal(false);

  // Video modal
  const [openTextModal, setOpenTextModal] = React.useState(false);
  const handleOpenTextModal = () => setOpenTextModal(true);
  const handleCloseTextModal = () => setOpenTextModal(false);

  // Workflow modal
  const [openImgModal, setOpenImgModal] = React.useState(false);
  const handleOpenImgModal = () => {
    setOpenImgModal(true);
  };
  const handleCloseImgModal = () => {
    setOpenImgModal(false);
  };

  // guide me modal
  const [openGuideMeModal, setOpenGuideMeModal] = React.useState(false);
  const handleCloseGuideMeModal = () => setOpenGuideMeModal(false);

  // Modal style
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "800px",
    transform: "translate(-50%, -50%)",
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    p: 0,
  };
  // End modal

  // Modal style img
  const modalStyleImage = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "90%",
    transform: "translate(-50%, -50%)",
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    p: 0,
  };
  // End modal

  // Youtube video style
  const YoutubeOpts = {
    width: "100%",
    height: "425",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  // End Youtube video

  // Tutorial Guide Me
  const {
    setIsOpen,
    setCurrentStep,
    // isOpen,
    setSteps,
  } = useTour();

  const handleGuideMe = (option) => {
    handleCloseGuideMeModal();
    setCurrentStep(0);
    if (option === "add") {
      setSteps(addSteps);
    } else if (option === "view") {
      setSteps(viewSteps);
    } else if (option === "edit") {
      setSteps(editSteps);
    } else if (option === "recycle") {
      setSteps(recycleSteps);
    } else if (option === "export") {
      setSteps(exportSteps);
    } else if (option === "settings") {
      setSteps(settingsSteps);
    }
    setIsOpen(true);
  };

  useEffect(() => {
    if (rfis) {
      setRfisLength(rfis.length);
    }
  }, [rfis]);

  return (
    <div>
      {/* Button */}
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={tRfis("support")}
      >
        <IconButton
          id="support-button"
          aria-controls={openMenu ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? "true" : undefined}
          sx={{ color: "#007FFF" }}
          onClick={handleClick}
        >
          <ContactSupport />
        </IconButton>
      </Tooltip>

      <Menu
        id="basic-menu"
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorEl={anchorSupp}
        open={openMenu}
        onClose={handleClose}
        keepMounted
        sx={{ borderRadius: 105 }}
      >
        {/* Meeting */}
        <MenuItem
          onClick={() => {
            setOpenMenu(false);
            handleOpenTextModal();
          }}
        >
          <ListItemIcon>
            <GroupsIcon sx={{ color: "#000000" }} />
          </ListItemIcon>
          <Typography textAlign="center">{tRfis("meeting")}</Typography>
        </MenuItem>
        <Modal open={openTextModal} onClose={handleCloseTextModal}>
          <Box>
            <IconButton
              aria-label="close"
              onClick={handleCloseTextModal}
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
              }}
            >
              <CloseIcon
                style={{
                  backgroundColor: "red",
                  borderRadius: "50%",
                  padding: 1,
                  color: "white",
                }}
              />
            </IconButton>
            <InlineWidget
              url="https://calendly.com/buildpeersupport"
              styles={{
                height: "100vh",
              }}
            />
          </Box>
        </Modal>

        {/* Video */}
        {/* <MenuItem onClick={() => {
                    setOpenMenu(false);
                    handleOpenModal()
                    }}>
                <ListItemIcon>
                    <PlayCircleIcon sx={{ color: "#000000" }} />
                </ListItemIcon>
                <Typography textAlign="center">Video</Typography>
                </MenuItem> */}
        <Modal open={openModal} onClose={handleCloseModal} closeAfterTransition>
          <Box sx={modalStyle}>
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                position: "absolute",
                right: 4,
                top: -4,
                width: 25,
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
            <YouTube videoId="w3_5x-8PVqw" opts={YoutubeOpts} />
          </Box>
        </Modal>

        {/* Workflow */}
        <MenuItem
          onClick={() => {
            setOpenMenu(false);
            handleOpenImgModal();
          }}
        >
          <ListItemIcon>
            <AppRegistrationRoundedIcon sx={{ color: "#000000" }} />
          </ListItemIcon>
          <Typography textAlign="center">{tRfis("workflow")}</Typography>
        </MenuItem>
        <Modal
          open={openImgModal}
          onClose={handleCloseImgModal}
          closeAfterTransition
        >
          <Box sx={modalStyleImage}>
            <IconButton
              aria-label="close"
              onClick={handleCloseImgModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <img
              alt="imgWorkFlow"
              src={imgWorkflow}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </Box>
        </Modal>

        {/* Guide Me */}
        {/* <MenuItem
                    onClick={() => {
                        setOpenMenu(false);
                        handleOpenGuideMeModal();
                    }}
                >
                    <ListItemIcon>
                        <TouchAppRoundedIcon sx={{ color: "#000000" }} />
                    </ListItemIcon>
                    <Typography textAlign="center">
                        {tRfis("guideMe")}
                    </Typography>
                </MenuItem> */}
        <Modal
          open={openGuideMeModal}
          onClose={handleCloseGuideMeModal}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              width: "40%",
              height: "auto",
              borderRadius: "20px",
              p: "25px 40px",
            }}
          >
            {/* Title */}
            <Box
              display="flex"
              flex={1}
              alignItems="center"
              justifyContent="space-between"
              mb="20px"
            >
              <Box display="flex" alignItems="center">
                <Autorenew />
                <Typography
                  sx={{
                    fontSize: "1.5625rem",
                    fontWeight: "bold",
                    marginLeft: 1,
                  }}
                >
                  {tRfis("guideMe")}
                </Typography>
              </Box>
              <IconButton onClick={handleCloseGuideMeModal}>
                <Close />
              </IconButton>
            </Box>
            <Divider />
            <Box mt="35px">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                mb="20px"
                rowGap="1rem"
              >
                {/* ADD */}
                <Can I="add" a="rfis">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      dispatch(setCurrentGuideMe("rfi-add"));
                      handleGuideMe("add");
                    }}
                    fullWidth
                    sx={{ py: "1rem" }}
                  >
                    <Add sx={{ marginRight: 1 }} />
                    {tRfis("add_button_tour")} RFI
                  </Button>
                </Can>

                {/* VIEW */}
                <Can I="view" a="rfis">
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={rfisLength === 0}
                    onClick={() => {
                      dispatch(setCurrentGuideMe("rfi-view"));
                      handleGuideMe("view");
                    }}
                    fullWidth
                    sx={{ py: "1rem" }}
                  >
                    <Visibility sx={{ marginRight: 1 }} />
                    {tRfis("view_button_tour")} RFI
                  </Button>
                </Can>

                {/* EDIT */}
                <Can I="editRfi" a="rfis">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      dispatch(setCurrentGuideMe("rfi-edit"));
                      handleGuideMe("edit");
                    }}
                    disabled={rfisLength === 0}
                    fullWidth
                    sx={{ py: "1rem" }}
                  >
                    <Edit sx={{ marginRight: 1 }} />
                    {tRfis("edit_button_tour")} RFI
                  </Button>
                </Can>

                {/* RECYCLE BIN */}
                <Can I="restore" a="rfis">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      dispatch(setCurrentGuideMe("rfi-recycle"));
                      handleGuideMe("recycle");
                    }}
                    disabled={rfisLength === 0}
                    fullWidth
                    sx={{ py: "1rem" }}
                  >
                    <Restore sx={{ marginRight: 1 }} />
                    {tRfis("recycle_button_tour")} RFI
                  </Button>
                </Can>

                {/* EXPORT */}
                <Can I="view" a="rfis">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      dispatch(setCurrentGuideMe("rfi-export"));
                      handleGuideMe("export");
                    }}
                    disabled={rfisLength === 0}
                    fullWidth
                    sx={{ py: "1rem" }}
                  >
                    <PictureAsPdf sx={{ marginRight: 1 }} />
                    {tRfis("export_button_tour")} RFI
                  </Button>
                </Can>

                {/* SETTINGS */}
                <Can I="settings" a="rfis">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      dispatch(setCurrentGuideMe("rfi-settings"));
                      handleGuideMe("settings");
                    }}
                    disabled={rfisLength === 0}
                    fullWidth
                    sx={{ py: "1rem" }}
                  >
                    <Settings sx={{ marginRight: 1 }} />
                    {tRfis("settings_button_tour")} RFI
                  </Button>
                </Can>
              </Box>
            </Box>
          </Card>
        </Modal>
      </Menu>
    </div>
  );
}

export default SupportButtonProjectDirectory;
