import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useContext, useMemo } from "react";

import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../features/userSettings/userSettingsSlice";
import CaslContext from "../../../../utils/caslContext";
import { ReportLocationDescription } from "./ReportLocationDescription";

function DelayReportDetails({ report }) {
  const topic = useMemo(() => {
    if (report) {
      return report.delayReport.topic;
    }
    return [];
  }, [report]);

  const currentUser = useSelector(selectCurrentUser);
  const ability = useContext(CaslContext);

  const disableChat = useMemo(() => {
    if (report && currentUser) {
      const isDisabled = report.status === "close";
      if (!isDisabled && !ability.can("superAdmin", "dailyReports")) {
        const canComment =
          report.creators.indexOf(currentUser?.id) > -1 ||
          report.assignedTo.indexOf(currentUser?.id) > -1;
        if (canComment) {
          return false;
        }
        return true;
      }
      return isDisabled;
    }
    return false;
  }, [report, currentUser, ability]);

  return (
    <Box>
      <Paper elevation={3} sx={{ display: "flex", height: "100%" }}>
        <Box flex={1} data-tour="view-dailyReport-5" overflow="auto" pb="20px">
          <ReportLocationDescription
            disabled={disableChat}
            location={report.delayReport?.location}
            topic={topic}
            category={report.category}
            delayDays={report.delayReport?.delayDays}
            delayHours={report.delayReport?.delayHours}
          />
        </Box>
      </Paper>
    </Box>
  );
}

export default DelayReportDetails;
