import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import animationData from "../../../projectDashboard/16627-firework.json";

const useAnnouncementSteps = () => {
  const { t: translation } = useTranslation("announcements");

  const addSteps = [
    {
      target: `[data-tour='${"add-announcement-1"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_addAnnouncement_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_addAnnouncement_text")}
          </Typography>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: `[data-tour='${"add-announcement-2"}']`,
      position: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_addAnnouncement_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_addAnnouncement_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"add-announcement-2_1"}']`,
      position: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_1_addAnnouncement_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_1_addAnnouncement_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"add-announcement-2_2"}']`,
      position: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_2_addAnnouncement_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_2_addAnnouncement_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"add-announcement-2_3"}']`,
      position: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_3_addAnnouncement_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_3_addAnnouncement_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"add-announcement-3"}']`,
      position: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_addAnnouncement_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_addAnnouncement_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"add-announcement-4"}']`,
      position: "bottom",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_addAnnouncement_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_addAnnouncement_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"add-announcement-5"}']`,
      position: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_addAnnouncement_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_addAnnouncement_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"add-announcement-6"}']`,
      position: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_addAnnouncement_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_addAnnouncement_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"add-announcement-7"}']`,
      position: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_addAnnouncement_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_addAnnouncement_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_addAnnouncement")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];
  const viewSteps = [
    {
      target: `[data-tour='${"view-announcement-1"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_viewAnnouncement_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_viewAnnouncement_text")}
          </Typography>
        </div>
      ),
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: `[data-tour='${"view-announcement-2"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_viewAnnouncement_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_viewAnnouncement_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_viewAnnouncement")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];
  const editSteps = [
    {
      target: `[data-tour='${"edit-announcement-1"}']`,
      placement: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_editAnnouncement_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_editAnnouncement_text")}
          </Typography>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: `[data-tour='${"edit-announcement-2"}']`,
      placement: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_editAnnouncement_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_editAnnouncement_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"edit-announcement-2_1"}']`,
      placement: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_1_editAnnouncement_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_1_editAnnouncement_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"edit-announcement-2_2"}']`,
      placement: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_2_editAnnouncement_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_2_editAnnouncement_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"edit-announcement-2_3"}']`,
      placement: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_3_editAnnouncement_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_3_editAnnouncement_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"edit-announcement-3"}']`,
      placement: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_editAnnouncement_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_editAnnouncement_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"edit-announcement-4"}']`,
      placement: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_editAnnouncement_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_editAnnouncement_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"edit-announcement-5"}']`,
      placement: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_editAnnouncement_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_editAnnouncement_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"edit-announcement-6"}']`,
      placement: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_editAnnouncement_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_editAnnouncement_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"edit-announcement-7"}']`,
      placement: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_editAnnouncement_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_editAnnouncement_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_editAnnouncement")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];
  const recycleSteps = [
    {
      target: "[data-tour='more']",
      placement: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_recycleAnnouncement_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_recycleAnnouncement_text")}
          </Typography>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: "[data-tour='recycle']",
      placement: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_recycleAnnouncement_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_recycleAnnouncement_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_recycleAnnouncement_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_recycleAnnouncement_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='view-announcement-1'",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_recycleAnnouncement_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_recycleAnnouncement_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableScrolling: true,
    },
    {
      target: ".checkbox-recycle",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_recycleAnnouncement_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_recycleAnnouncement_text")}
          </Typography>
        </div>
      ),
      placement: "right",
      disableScrolling: true,
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_recycleAnnouncement")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];
  const exportSteps = [
    {
      selector: `[data-tour='${"add-announcement-1"}']`,
      position: "left",
      content: () => (
        <div>
          <p>export</p>
        </div>
      ),
    },
  ];
  const settingsSteps = [
    {
      selector: `[data-tour='${"add-announcement-1"}']`,
      position: "left",
      content: () => (
        <div>
          <p>settings</p>
        </div>
      ),
    },
  ];

  return [
    {
      addSteps,
      viewSteps,
      editSteps,
      recycleSteps,
      exportSteps,
      settingsSteps,
    },
  ];
};

export { useAnnouncementSteps };
