import { LocationOn, North, South } from "@mui/icons-material";
import { Box, Card, Skeleton, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useGetProjectCurrentWeatherQuery } from "../../../../features/project/projectApiSlice";
import { ModalWeekWeather } from "./ModalWeekWeather";
import { getIcon } from "./icons";

function Weather() {
  const { t: tGeneral } = useTranslation("general");
  const { t: tWeather } = useTranslation("weather");
  const { idProject } = useParams();
  const { data, isLoading } = useGetProjectCurrentWeatherQuery(idProject);
  const [isWeatherOpen, setIsWeatherOpen] = useState(false);

  const current = useMemo(() => {
    if (data && !data.code) {
      const { current: currentWeather } = data;
      return {
        name: data.location,
        temperature: currentWeather.temp,
        temperatureApparent: currentWeather.feels_like,
        max: currentWeather.temp,
        min: currentWeather.temp,
        code: `${currentWeather.weather[0].main.toLowerCase()}-${
          currentWeather.weather[0].id
        }`,
        icon: `https://openweathermap.org/img/wn/${currentWeather.weather[0].icon}@2x.png`,
      };
    }
    return null;
  }, [data]);

  const handleCloseWeather = () => {
    setIsWeatherOpen(false);
  };

  const handleOpenWeather = () => {
    setIsWeatherOpen(true);
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box width="50%">
          <Skeleton width="100%" />
          <Box
            display="flex"
            height="100px"
            columnGap="20px"
            alignItems="center"
          >
            <Skeleton width="30%" height="100%" />
            <Skeleton width="30%" height="100%" />
            <Box width="30%">
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Box>
          </Box>
        </Box>
      );
    }

    function toCamelCase(str) {
      return str
        .split(" ")
        .map(function (word, index) {
          if (index == 0) {
            return word.toLowerCase();
          }
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join("");
    }

    if (current) {
      const { name, icon, temperature, temperatureApparent, code } = current;

      let label = tWeather(code);

      return (
        <Box
          component="div"
          onClick={handleOpenWeather}
          sx={{ cursor: "pointer" }}
        >
          <Box
            display="flex"
            alignItems="center"
            color="gray"
            justifyContent="center"
            px="10px"
          >
            <LocationOn />
            <Typography textAlign="center">{name}</Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            columnGap="20px"
            justifyContent="center"
          >
            <img
              src={icon}
              alt="weather"
              height="100%"
              width="100%"
              style={{ maxHeight: "100px", maxWidth: "100px" }}
            />
            <Typography fontSize="3.5rem">
              {Math.round(temperature)}°
            </Typography>
            <Box>
              <Typography fontWeight="600" textAlign="justify">
                {label}
              </Typography>
              <Typography textAlign="justify">
                {tGeneral("feelsLike")} {Math.round(temperatureApparent)}°
              </Typography>
              <Box display="flex" justifyContent="space-around">
                <North />{" "}
                <Typography>{Math.round(data?.daily[0].temp.max)}°</Typography>{" "}
                <South />{" "}
                <Typography>{Math.round(data?.daily[0].temp.min)}°</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <Typography textAlign="center" maxWidth="70%">
        {tGeneral("ensureThat")}
      </Typography>
    );
  };

  return (
    <Box display="flex" flexDirection="column" overflow="hidden" flex={1}>
      <Typography fontSize="1.375rem">{tGeneral("weather")}</Typography>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
          justifyContent: "center",
          mx: "5px",
          mb: "5px",
        }}
      >
        {renderContent()}
      </Card>
      {data && (
        <ModalWeekWeather
          isOpen={isWeatherOpen}
          onClose={handleCloseWeather}
          current={current}
          week={data.daily}
          idProject={idProject}
          timezone={data.timezone}
        />
      )}
    </Box>
  );
}

export { Weather };
