import { Close, Lock, LockOpen, Save } from "@mui/icons-material";
import {
  Box,
  Card,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  useGetDisciplinesQuery,
  useGetDrawingByIdQuery,
  useGetSetsQuery,
  useUpdateDrawingMutation,
} from "../../features/drawings";
import { selectCurrentGuideMe } from "../../features/project/projectSlice";
import { PopUpAlert } from "../PopUpAlert";
import { CompanySelector, IOSSwitch, TooltipIconButton } from "../shared";
import { useAppTourContext } from "../supportButton/context/context";

function ModalEditDrawing({ isOpen, onClose, idDrawing }) {
  const { idProject, idEnterprise } = useParams();
  const currentGuideMe = useSelector(selectCurrentGuideMe);
  const { t: tDrawings } = useTranslation("drawings");
  const { t: tGeneral } = useTranslation("general");
  const { data: drawing } = useGetDrawingByIdQuery(idDrawing, {
    skip: !isOpen,
  });
  const { data: disciplines } = useGetDisciplinesQuery(idProject);
  const { data: sets } = useGetSetsQuery(idProject);
  const { control, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      bPrivate: false,
      idSet: "",
      idDiscipline: "",
      allowedCompanies: [],
      allowedUsers: [],
    },
  });
  const [bPublished, setBPublished] = useState(false);
  const bPrivate = watch("bPrivate");
  const allowedCompanies = watch("allowedCompanies");
  const allowedUsers = watch("allowedUsers");
  const [
    updateDrawing,
    { isLoading: isUpdateLoading, isSuccess, isError, error },
  ] = useUpdateDrawingMutation();

  useEffect(() => {
    if (drawing) {
      const {
        idAux,
        name,
        idDiscipline,
        allowedCompanies,
        allowedUsers,
        bPrivate,
        revision: {
          idSet,
          number,
          publishedByName,
          approvedByName,
          publishedDate,
          approvedDate,
          bPublished,
        },
      } = drawing;
      const actionedBy = bPublished ? publishedByName : approvedByName;
      const actionedDate = bPublished ? publishedDate : approvedDate;

      setValue("idAux", idAux);
      setValue("actionedBy", actionedBy);
      setValue("actionedDate", moment(actionedDate).format("DD/MM/YYYY"));
      setValue("name", name);
      setValue("idSet", idSet);
      setValue("idDiscipline", idDiscipline);
      setValue("bPrivate", bPrivate);
      setValue("number", number);
      setValue("allowedCompanies", allowedCompanies);
      setValue("allowedUsers", allowedUsers);
      setBPublished(bPublished);
    }
  }, [drawing, setValue]);

  const handleSave = async (data) => {
    const {
      idSet,
      idDiscipline,
      name,
      bPrivate,
      allowedCompanies,
      allowedUsers,
    } = data;
    const body = {
      idDrawing,
      idRevision: drawing?.revision?.id,
      idSet,
      idDiscipline,
      name,
      bPrivate,
      allowedCompanies: allowedCompanies ?? [],
      allowedUsers: allowedUsers ?? [],
    };
    await updateDrawing(body);
  };

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert(
        "success",
        tGeneral("updated"),
        tDrawings("editedDrawingMessage"),
      );
      onClose();
    }
    if (isError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      onClose();
    }
  }, [isSuccess, isError, onClose, tDrawings, tGeneral]);

  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();
  useEffect(() => {
    if (tourActive && stepIndex === 1 && currentGuideMe === "drawings-edit") {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe, setState]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          width: "60%",
          p: "20px 40px",
          borderRadius: "20px",
        }}
        component="form"
        onSubmit={handleSubmit(handleSave)}
      >
        <Box display="flex" alignItems="center">
          <Typography fontWeight="bold" fontSize="1.563rem" flex={1}>
            {tDrawings("editDrawing")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close sx={{ width: "24px", height: "24px" }} color="secondary" />
          </IconButton>
          <TooltipIconButton
            icon={<Save />}
            label={tGeneral("save")}
            type="submit"
            disabled={isUpdateLoading}
            data-tour="edit-drawings-7"
          />
        </Box>
        <Divider sx={{ my: "17px" }} />
        <Box sx={{ pt: "5px" }}>
          <Box display="flex" columnGap="45px">
            <Box flex="1">
              <img src={null} alt="" />
            </Box>
            <Box flex="1" display="flex" flexDirection="column" rowGap="15px">
              <Box display="flex" columnGap="10px">
                <Controller
                  name="idAux"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={tDrawings("drawingId")}
                      sx={{ flex: 1, backgroundColor: "#F0F2F4" }}
                      InputLabelProps={{
                        shrink: !!field.value,
                      }}
                      {...field}
                      disabled
                    />
                  )}
                />
                <Controller
                  name="number"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled
                      InputLabelProps={{
                        shrink: field.value !== null,
                      }}
                      label="# Rev"
                      sx={{
                        width: "auto",
                        backgroundColor: "#F0F2F4",
                        maxWidth: "min-content",
                        minWidth: "80px",
                      }}
                    />
                  )}
                />
              </Box>
              <Controller
                control={control}
                name="actionedBy"
                render={({ field }) => (
                  <TextField
                    fullWidth
                    InputLabelProps={{
                      shrink: !!field.value,
                    }}
                    {...field}
                    label={
                      bPublished
                        ? tGeneral("publishedBy")
                        : tGeneral("approvedBy")
                    }
                    sx={{ backgroundColor: "#F0F2F4" }}
                    disabled
                  />
                )}
              />
              <Controller
                control={control}
                name="actionedDate"
                render={({ field }) => (
                  <TextField
                    fullWidth
                    InputLabelProps={{
                      shrink: !!field.value,
                    }}
                    {...field}
                    disabled
                    label={
                      bPublished
                        ? tGeneral("publicationDate")
                        : tGeneral("reviewDate")
                    }
                    sx={{ backgroundColor: "#F0F2F4" }}
                  />
                )}
              />
            </Box>
          </Box>
          <Box display="flex" mt="25px" columnGap="45px">
            <Controller
              render={({ field }) => (
                <TextField
                  fullWidth
                  InputLabelProps={{
                    shrink: !!field.value,
                  }}
                  label={tDrawings("drawingName")}
                  {...field}
                  data-tour="edit-drawings-2"
                />
              )}
              control={control}
              name="name"
            />
            <Controller
              control={control}
              name="idSet"
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel shrink={!!field.value}>
                    {tDrawings("setName")}
                  </InputLabel>
                  <Select
                    {...field}
                    label={tDrawings("setName")}
                    MenuProps={{ sx: { maxHeight: "300px" } }}
                    notched={!!field.value}
                    data-tour="edit-drawings-3"
                  >
                    {sets?.map((set) => (
                      <MenuItem key={set.id} value={set.id}>
                        {set.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Box>
          <Box display="flex" mt="15px" columnGap="45px">
            <Controller
              control={control}
              name="idDiscipline"
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel shrink={!!field.value}>
                    {tDrawings("discipline")}
                  </InputLabel>
                  <Select
                    {...field}
                    label={tDrawings("discipline")}
                    notched={!!field.value}
                    data-tour="edit-drawings-4"
                  >
                    {disciplines?.map((discipline) => (
                      <MenuItem key={discipline.id} value={discipline.id}>
                        {discipline.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <Card
              sx={{
                display: "flex",
                height: "56px",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
              data-tour="edit-drawings-5"
            >
              <Box display="flex" ml="20px">
                {bPrivate ? <Lock /> : <LockOpen />}
                {bPrivate ? tGeneral("private") : tGeneral("public")}
              </Box>
              <IOSSwitch
                sx={{ mr: "20px" }}
                onChange={(e) => setValue("bPrivate", e.target.checked)}
                checked={bPrivate}
              />
            </Card>
          </Box>
          {bPrivate && <Divider sx={{ my: "26px" }} />}
          {bPrivate && (
            <span data-tour="edit-drawings-6">
              <Box display="flex">
                <Lock /> <Typography>{tGeneral("privacyOptions")}</Typography>
              </Box>
              <CompanySelector
                idEnterprise={idEnterprise}
                idProject={idProject}
                onChangeCompanies={(ids) => setValue("allowedCompanies", ids)}
                onChangeUsers={(ids) => setValue("allowedUsers", ids)}
                multiple
                selectedCompanies={allowedCompanies}
                selectedUsers={allowedUsers}
                moduleName="drawings"
              />
            </span>
          )}
        </Box>
      </Card>
    </Modal>
  );
}

export { ModalEditDrawing };
