import React from "react";
import { useSelector } from "react-redux";
// Redux: selectors
import { useGetUserQuery } from "../../features/enterprise/enterpriseApiSlice";
import { selectCurrentUser } from "../../features/userSettings/userSettingsSlice";
// MUI Components
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";
//alert
// Custom Components
import CakeIcon from "@mui/icons-material/Cake";
import EmailIcon from "@mui/icons-material/Email";
import FemaleIcon from "@mui/icons-material/Female";
import MaleIcon from "@mui/icons-material/Male";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UserAvatar from "../../components/userAvatars/UserAvatar";
import { selectCurrentEnterprise } from "../../features/enterprise/enterpriseSlice";
import moment from "moment";

function Profile() {
  // hooks
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser);
  const currentEnterprise = useSelector(selectCurrentEnterprise);

  const isMobile = useMediaQuery("(max-width:900px)");
  const { t: tGeneral } = useTranslation("general");

  const { data: enterprisesUser } = useGetUserQuery();
  const { firstName, lastName, urlAvatar, gender, phone, email } = {
    ...enterprisesUser,
  };

  const dateBirthFormat = new Date(enterprisesUser?.dateBirth).toLocaleString(
    currentEnterprise.locale,
    {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
  );

  const fullName = {
    userFullName: user?.firstName + " " + user?.lastName,
  };

  const genderIcon = () => {
    if (gender === "Male") {
      return <MaleIcon />;
    }

    if (gender === "Female") {
      return <FemaleIcon />;
    }
    return <RadioButtonUncheckedIcon />;
  };

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box>
        <Typography fontSize="36px" align="left" fontWeight="bold">
          {tGeneral("profile")}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" marginBottom="10px">
        <Card sx={{ borderRadius: "100%", padding: "5px" }}>
          <UserAvatar
            name={firstName + " " + lastName}
            size={"Big"}
            url={urlAvatar}
          />
        </Card>
      </Box>
      <Typography fontSize="2.25rem" fontWeight="bold" textAlign="center">
        {fullName.userFullName}
      </Typography>
      <Box
        display="flex"
        gap="5%"
        my="1%"
        flexWrap="wrap"
        justifyContent={"center"}
      >
        <Card
          sx={{
            width: isMobile ? "100%" : "45%",
            borderRadius: "20px",
            my: "10px",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
            }}
          >
            <CakeIcon />
            <Typography sx={{ ml: "5px" }} variant="h6">
              {moment.utc(enterprisesUser?.dateBirth).format("DD MMMM YYYY")}
            </Typography>
          </CardContent>
        </Card>
        <Card
          sx={{
            width: isMobile ? "100%" : "45%",
            borderRadius: "20px",
            my: "10px",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
            }}
          >
            {genderIcon()}
            <Typography sx={{ ml: "5px" }} variant="h6">
              {tGeneral(
                gender === "Male" ? tGeneral("male") : tGeneral("female"),
              )}
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Box
        display="flex"
        gap="5%"
        my="1%"
        flexWrap="wrap"
        justifyContent={"center"}
      >
        <Card
          sx={{
            width: isMobile ? "100%" : "45%",
            borderRadius: "20px",
            my: "10px",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
            }}
          >
            <PhoneIphoneIcon />
            <Typography sx={{ ml: "5px" }} variant="h6">
              {phone}
            </Typography>
          </CardContent>
        </Card>
        <Card
          sx={{
            width: isMobile ? "100%" : "45%",
            borderRadius: "20px",
            my: "10px",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
            }}
          >
            <EmailIcon />
            <Typography
              sx={{ ml: "5px", textOverflow: "ellipsis", overflow: "hidden" }}
              variant="h6"
            >
              {email}
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Divider sx={{ mb: "20px" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          my: "1%",
          mx: isMobile ? "0%" : "2.5%",
        }}
      >
        <Button
          type="submit"
          variant="contained"
          onClick={() => {
            navigate("/user/profileEdit");
          }}
          sx={{
            borderRadius: 1,
            px: "5%",
            py: "10px",
            width: isMobile ? "100%" : "25%",
          }}
        >
          {tGeneral("editProfile")}
        </Button>
      </Box>
    </Box>
  );
}

export default Profile;
