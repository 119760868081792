export const weather = {
  "thunderstorm-200": "약한 비를 동반한 천둥",
  "thunderstorm-201": "비를 동반한 천둥",
  "thunderstorm-202": "강한 비를 동반한 천둥",
  "thunderstorm-210": "약한 천둥",
  "thunderstorm-211": "천둥",
  "thunderstorm-212": "강한 천둥",
  "thunderstorm-221": "불규칙한 천둥",
  "thunderstorm-230": "약한 이슬비를 동반한 천둥",
  "thunderstorm-231": "이슬비를 동반한 천둥",
  "thunderstorm-232": "강한 이슬비를 동반한 천둥",
  "drizzle-300": "약한 이슬비",
  "drizzle-301": "이슬비",
  "drizzle-302": "강한 이슬비",
  "drizzle-310": "약한 이슬비 비",
  "drizzle-311": "이슬비 비",
  "drizzle-312": "강한 이슬비 비",
  "drizzle-313": "소나기 비와 이슬비",
  "drizzle-314": "강한 소나기 비와 이슬비",
  "drizzle-321": "소나기 이슬비",
  "rain-500": "약한 비",
  "rain-501": "보통 비",
  "rain-502": "강한 비",
  "rain-503": "매우 강한 비",
  "rain-504": "극심한 비",
  "rain-511": "얼어붙는 비",
  "rain-520": "약한 소나기 비",
  "rain-521": "소나기 비",
  "rain-522": "강한 소나기 비",
  "rain-531": "불규칙한 소나기 비",
  "snow-600": "약한 눈",
  "snow-601": "눈",
  "snow-602": "강한 눈",
  "snow-611": "진눈깨비",
  "snow-612": "약한 소나기 진눈깨비",
  "snow-613": "소나기 진눈깨비",
  "snow-615": "약한 비와 눈",
  "snow-616": "비와 눈",
  "snow-620": "약한 소나기 눈",
  "snow-621": "소나기 눈",
  "snow-622": "강한 소나기 눈",
  "mist-701": "안개",
  "smoke-711": "연기",
  "haze-721": "안개",
  "dust-731": "모래/먼지 회오리",
  "fog-741": "안개",
  "sand-751": "모래",
  "dust-761": "먼지",
  "ash-762": "화산재",
  "squall-771": "돌풍",
  "tornado-781": "토네이도",
  "clear-800": "맑은 하늘",
  "clouds-801": "구름 조금: 11-25%",
  "clouds-802": "산개된 구름: 25-50%",
  "clouds-803": "조각난 구름: 51-84%",
  "clouds-804": "흐린 구름",
  sensation: "체감 온도",
};
