import { Box, Typography, Button } from "@mui/material";
import NoRfis from "../../assets/empty-state.svg";
import noImages from "../../assets/noImages.png";
import noDailyReports from "../../assets/noDailyReports.png";
import noTasks from "../../assets/noTasks.png";
import noDrawings from "../../assets/noDrawings.png";
import noAnnouncements from "../../assets/noAnnouncements.png";
import noDocuments from "../../assets/noDocuments.png";
import noMeetings from "../../assets/meetings/NoMeetings2.png";
import { useSelector } from "react-redux";
import { selectTheme } from "../../features/preferences/preferencesSlice";
import Can from "../../utils/can";
import { Add } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";

function EmptyState({
  title,
  content,
  button,
  module = "rfis",
  disableCasl = false,
}) {
  const isMobile = useMediaQuery("(max-width:820px)");

  let EmptyStateImage = NoRfis;
  if (module === "rfis") {
    EmptyStateImage = NoRfis;
  } else if (module === "photos") {
    EmptyStateImage = noImages;
  } else if (module === "dailyReports") {
    EmptyStateImage = noDailyReports;
  } else if (module === "tasks") {
    EmptyStateImage = noTasks;
  } else if (module === "drawings") {
    EmptyStateImage = noDrawings;
  } else if (module === "announcements") {
    EmptyStateImage = noAnnouncements;
  } else if (module === "documents") {
    EmptyStateImage = noDocuments;
  } else if (module === "meetings") {
    EmptyStateImage = noMeetings;
  }

  const darkMode = useSelector(selectTheme);

  const pageContent = (
    <>
      <Typography
        sx={{
          fontSize: 15,
          color: darkMode ? "white" : "#B0B7C1",
          textAlign: "center",
          mt: 2,
          width: "100%",
        }}
      >
        {content}
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        my="15px"
        width="100%"
      >
        <Box
          sx={{
            color: "black",
            py: "0.5rem",
          }}
        >
          {button}
        </Box>
      </Box>
    </>
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          alignItems: "center",
          justifyContent: "center",
          mt: 10,
        }}
      >
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: "bold",
            color: darkMode,
            textAlign: "center",
            width: "100%",
          }}
        >
          {title}
        </Typography>
        {!disableCasl ? (
          <Can I="add" a={module}>
            {pageContent}
          </Can>
        ) : (
          pageContent
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "right",
            margin: 0,
          }}
        >
          <img
            src={EmptyStateImage}
            alt="emptyImage"
            style={{ width: isMobile ? "90%" : "30%" }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export { EmptyState };
