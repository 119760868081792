export const plan = {
  perUser: "Por usuario",
  perProject: "Por proyecto",
  perMonth: "Por mes",
  perYear: "Por año",
  monthly: "Mensual",
  annual: "Anual",
  bestValue: "Mejor valor",
  youWillPay: "Pagarás",
  activeProjects: "Proyectos Activos",
  activeUsers: "Usuarios Activos",
};
