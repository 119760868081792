// @ts-check
/* eslint react/prop-types: 0 */
/* eslint jsx-a11y/control-has-associated-label: 0 */

import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Box, Button, Dialog, DialogContent, Switch } from "@mui/material";
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import TransitionComponent from "../TransitionComponent";
import pdf from "../../../../utils/pdf";
import { selectCurrentToken } from "../../../../features/auth/authSlice";
import { useToast } from "../../../../contexts/ToastContext";

export default function ExportPDF(props) {
  const { open, setOpen } = props;
  const toast = useToast();

  const { idEnterprise, idProject } = useParams();

  const token = useSelector(selectCurrentToken);

  const [projectCost, setProjectCost] = useState(false);
  const [scheduledTimes, setScheduledTimes] = useState(false);
  const [realTimes, setRealTimes] = useState(false);

  const { t: tProjectSchedule } = useTranslation("projectSchedule");
  const { t: tGeneral } = useTranslation("general");
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={handleClose}
      open={open}
      PaperProps={{
        className: "project-schedule",
        sx: {
          borderRadius: "20px",
        },
      }}
      scroll="body"
      // @ts-ignore
      TransitionComponent={TransitionComponent}
    >
      <DialogContent
        dividers
        sx={{
          padding: "30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  gap: "10px",
                }}
              >
                <PictureAsPdfIcon />
                <strong>{tProjectSchedule("PDF Settings")}</strong>
              </Box>
              <Box
                onClick={handleClose}
                sx={{
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <CloseIcon />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Box>{tProjectSchedule("Additional Columns")}</Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  border: "1px solid #c2c8d1",
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "12px",
                  justifyContent: "space-between",
                  padding: "0 10px",
                }}
              >
                <Box>{tProjectSchedule("Project Cost")}</Box>
                <Switch
                  checked={projectCost}
                  color="primary"
                  name="bPrivate"
                  onChange={(event) => setProjectCost(event.target.checked)}
                />
              </Box>
              <Box
                sx={{
                  alignItems: "center",
                  border: "1px solid #c2c8d1",
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "12px",
                  justifyContent: "space-between",
                  padding: "0 10px",
                }}
              >
                <Box>{tProjectSchedule("Scheduled Times")}</Box>
                <Switch
                  checked={scheduledTimes}
                  color="primary"
                  name="bPrivate"
                  onChange={(event) => setScheduledTimes(event.target.checked)}
                />
              </Box>
              <Box
                sx={{
                  alignItems: "center",
                  border: "1px solid #c2c8d1",
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "12px",
                  justifyContent: "space-between",
                  padding: "0 10px",
                }}
              >
                <Box>{tProjectSchedule("Real Times")}</Box>
                <Switch
                  checked={realTimes}
                  color="primary"
                  name="bPrivate"
                  onChange={(event) => setRealTimes(event.target.checked)}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Button
              color="primary"
              onClick={async () => {
                toast.showLoading(idProject, tGeneral("downloading"), "Project Schedule");
                await pdf({
                  data: {
                    columns: {
                      projectCost,
                      scheduledTimes,
                      realTimes,
                    },
                  },
                  token,
                  url: `${process.env.REACT_APP_BACKEND_URL}pdf/${idEnterprise}/${idProject}/project-schedule`,
                  download: "Project Schedule.pdf",
                });
                toast.hideLoading(idProject);
              }}
              sx={{
                width: "100%",
              }}
              type="button"
              variant="contained"
            >
              {tProjectSchedule("DOWNLOAD PDF")}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
