export const tour = {
  // topbar
  guideMe: "Guie-me",
  support: "Suporte",
  meeting: "Reunião",
  workflow: "Fluxo de trabalho",
  // guide me buttons
  addButtonTour: "Adicionar",
  viewButtonTour: "Visualizar",
  editButtonTour: "Editar",
  recycleButtonTour: "Reciclar",
  exportButtonTour: "Exportar",
  settingsButtonTour: "Configurações",
  deleteButtonTour: "Excluir",
  changeRoleButtonTour: "Alterar Função",
  // docs
  docsAddFileButtonTour: "Adicionar Arquivo",
  docsAddFolderButtonTour: "Adicionar Pasta",
  docsFileInfoButtonTour: "Informações do Arquivo",
  docsMoveToButtonTour: "Mover para",
  docsActionsButtonTour: "Ações",
  // more
  obligatory: "Obrigatório",
  congratulations: "Parabéns!",
  moreTitle: "Mais opções",
  moreDescription: "Aqui você pode encontrar opções adicionais.",
};
