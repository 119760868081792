import {
  Compare,
  Download,
  Edit,
  Lock,
  LockOpen,
  Mail,
  MoreVert,
  RemoveRedEye,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_EN as MRTLocalizationEN } from "material-react-table/locales/en";
import { MRT_Localization_ES as MRTLocalizationES } from "material-react-table/locales/es";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DrawingsFilters, HoverRevisions } from "../../../components/drawings";
import { ModalDownload } from "../../../components/drawings/ModalDownload";
import {
  StyledMaterialReactTable,
  TooltipIconButton,
} from "../../../components/shared";
import SendToEmail from "../../../components/shared/SendToEmail";
import { selectLocale } from "../../../features/preferences/preferencesSlice";
import Can from "../../../utils/can";
import { useExportDrawingsMutation } from "../../../features/exportPdf";
import { useSendToEmailMutation } from "../../../features/ses/sesApiSlice";
import {
  selectCurrentGuideMe,
  selectCurrentProject,
} from "../../../features/project/projectSlice";
import { PopUpAlert } from "../../../components/PopUpAlert";
import { useAbility } from "@casl/react";
import CaslContext from "../../../utils/caslContext";
import { selectCurrentUser } from "../../../features/userSettings/userSettingsSlice";
import { useAppTourContext } from "../../../components/supportButton/context/context";
import { ModalDrawingEmail } from "../../../components/drawings/ModalDrawingEmail";

function DrawingsTable({
  drawings,
  onEdit,
  onSelect,
  onCompare,
  onViewDrawing,
  selectedRows,
  onFiltersChange,
  filters = {},
  isLoading,
  isRecycle = false,
}) {
  const ability = useAbility(CaslContext);
  const currentUser = useSelector(selectCurrentUser);
  const locale = useSelector(selectLocale);

  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");
  const currentGuideMe = useSelector(selectCurrentGuideMe);
  const [downloadingRevisions, setDownloadingRevisions] = useState(null);
  const [sendEmailRevisions, setSendEmailRevisions] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawingMenu, setDrawingMenu] = useState({});
  const menuRef = useRef();

  const currentProject = useSelector(selectCurrentProject);

  const [
    sendMail,
    {
      isLoading: isSendMailLoading,
      isSuccess: isSendEmailSuccess,
      isError: isSendEmailError,
      error: sendEmailError,
    },
  ] = useSendToEmailMutation();
  const [exportDrawings, { isLoadingPDF, data, error }] =
    useExportDrawingsMutation();

  const handleSendEmail = async (
    selectedDrawings,
    message,
    subject,
    recipients,
  ) => {
    const bodyPdf = {
      drawings: selectedDrawings,
      project: currentProject,
    };

    const url = await exportDrawings(bodyPdf);
    const body = {
      message,
      subject,
      pdfLink: url.data,
    };
    if (recipients?.length > 0) {
      body.recipients = recipients;
    }
    sendMail(body);
  };

  useEffect(() => {
    if (isSendEmailSuccess) {
      PopUpAlert("success", tGeneral("sent"), tGeneral("sentMessage"));
      setSendEmailRevisions(null);
    }
    if (isSendEmailError) {
      PopUpAlert("error", tGeneral("error"), sendEmailError.message);
      setSendEmailRevisions(null);
    }
  }, [isSendEmailError, isSendEmailSuccess, sendEmailError, tGeneral]);

  const columnOrder = useMemo(
    () =>
      isRecycle
        ? [
            "mrt-row-select",
            "idAux",
            "number",
            "name",
            "set",
            "discipline",
            "deletedByName",
            "deletedDate",
          ]
        : [
            "mrt-row-expand",
            "mrt-row-select",
            "bPrivate",
            "idAux",
            "rev",
            "name",
            "set",
            "createdDate",
            "mrt-row-actions",
          ],
    [isRecycle],
  );

  const orderDrawings = (drawings) => {
    const dArray = Object.keys(drawings).map((key) => drawings[key]);
    return dArray.sort((a, b) =>
      a.disciplineName?.localeCompare(b.disciplineName, "es"),
    );
  };

  const orderedDrawings = orderDrawings(drawings);

  const columns = useMemo(() => {
    const tableColumns = [];
    if (isRecycle) {
      tableColumns.push(
        {
          header: tDrawings("discipline"),
          accessorKey: "discipline",
          size: 70,
          enableGrouping: false,
          Cell: ({ cell }) => (
            <Tooltip title={cell.getValue()}>
              <Typography textOverflow="ellipsis">{cell.getValue()}</Typography>
            </Tooltip>
          ),
        },
        {
          header: tGeneral("deletedAt"),
          accessorKey: "deletedDate",
          sortable: false,
          Cell: ({ cell }) =>
            moment(new Date(cell.getValue())).format("D MMMM YYYY"),
          enableGrouping: false,
          size: 80,
        },
        {
          header: tGeneral("deletedBy"),
          accessorKey: "deletedByName",
          enableGrouping: false,
          size: 85,
          Cell: ({ cell }) => (
            <Tooltip title={cell.getValue()}>
              <Typography textOverflow="ellipsis">{cell.getValue()}</Typography>
            </Tooltip>
          ),
        },
        {
          header: "#Rev",
          accessorKey: "number",
          sortable: false,
          enableGrouping: false,
          size: 55,
        },
        {
          header: "Set",
          accessorKey: "set",
          sortable: false,
          enableGrouping: false,
          size: 40,
          Cell: ({ cell }) => (
            <Tooltip title={cell.getValue()}>
              <Typography noWrap textOverflow="ellipsis">
                {cell.getValue()}
              </Typography>
            </Tooltip>
          ),
        },
      );
    } else {
      tableColumns.push(
        {
          header: tGeneral("publishedDate"),
          name: "createdDate",
          id: "createdDate",
          accessorFn: (row) => row?.lastRev?.publishedDate,
          sortable: false,
          Cell: ({ cell }) =>
            moment(new Date(cell.getValue())).format("DD/MM/YYYY"),
          enableGrouping: false,
          size: 110,
        },
        {
          header: tDrawings("discipline"),
          accessorKey: "disciplineName",
          id: "disciplineName",
          size: 100,
          GroupedCell: ({ cell, row }) => (
            <Tooltip title={cell.getValue()}>
              <Box display="flex" maxWidth="100%">
                <Typography fontWeight="bold" noWrap textOverflow="ellipsis">
                  {cell.getValue()}
                </Typography>
                <Typography fontWeight="bold">
                  ({row.subRows?.length})
                </Typography>
              </Box>
            </Tooltip>
          ),
          sortingFn: (a, b) => {
            if (
              a.original.disciplineName === "N/A" &&
              b.original.disciplineName === "N/A"
            ) {
              return 0;
            }
            if (a.original.disciplineName === "N/A") {
              return 1;
            }
            if (b.original.disciplineName === "N/A") {
              return -1;
            }
            return a.original.disciplineName?.localeCompare(
              b.original.disciplineName,
            );
          },
        },
        {
          header: "#Rev",
          name: "rev",
          id: "rev",
          Cell: ({ cell, row }) => {
            return (
              <HoverRevisions
                lastRev={row.original?.lastRev}
                revisions={row.original?.revisions}
                isTour={row.original?.id === orderedDrawings[0].id}
              />
            );
          },
          sortable: false,
          enableGrouping: false,
          size: 50,
        },
        {
          header: "Set",
          name: "set",
          id: "set",
          accessorFn: (row) => row?.lastRev?.setName,
          sortable: false,
          enableGrouping: false,
          size: 80,
          Cell: ({ cell }) => (
            <Tooltip title={cell.getValue()}>
              <Typography noWrap textOverflow="ellipsis">
                {cell.getValue()}
              </Typography>
            </Tooltip>
          ),
        },
      );
    }
    tableColumns.push(
      {
        header: "ID",
        accessorKey: "idAux",
        enableGrouping: false,
        size: 70,
        Cell: ({ cell }) => (
          <Tooltip title={cell.getValue()}>
            <Typography noWrap textOverflow="ellipsis">
              {cell.getValue()}
            </Typography>
          </Tooltip>
        ),
      },
      {
        header: tGeneral("drawing"),
        accessorKey: "name",
        muiTableHeadCellProps: {
          align: "left",
        },
        muiTableBodyCellProps: {
          align: "left",
        },
        sortable: false,
        enableGrouping: false,
        size: isRecycle ? 80 : 100,
        Cell: ({ cell, row }) => (
          <Tooltip title={cell.getValue()}>
            <Typography noWrap textOverflow="ellipsis">
              {cell.getValue()}
            </Typography>
          </Tooltip>
        ),
      },
    );
    return tableColumns;
  }, [isRecycle, tDrawings, tGeneral, drawings]);

  const handleOpenMenu = (e, drawing) => {
    setDrawingMenu(drawing);
    setAnchorEl(e.currentTarget);
  };

  const {
    state: { tourActive, stepIndex, steps },
    setState,
  } = useAppTourContext();
  useEffect(() => {
    if (
      tourActive &&
      (stepIndex === 1 || stepIndex === steps.length - 2) &&
      currentGuideMe === "drawings-edit"
    ) {
      if (drawings && drawings.length > 0) {
        onEdit(drawings[0].id);
        setTimeout(() => {
          setState({ run: true });
        }, 500);
      }
    }

    if (
      tourActive &&
      (stepIndex === steps.length - 1 || stepIndex === 0) &&
      currentGuideMe === "drawings-edit"
    ) {
      onEdit(null);
    }
  }, [tourActive, stepIndex, steps, currentGuideMe]);

  useEffect(() => {
    if (tourActive && stepIndex === 1 && currentGuideMe === "drawings-export") {
      if (orderedDrawings && orderedDrawings.length > 0) {
        handleOpenMenu({ currentTarget: menuRef.current }, orderedDrawings[0]);

        setTimeout(() => {
          setState({ run: true });
        }, 500);
      }
    }

    if (tourActive && stepIndex === 2 && currentGuideMe === "drawings-export") {
      setAnchorEl(null);
    }
  }, [tourActive, stepIndex, currentGuideMe]);

  useEffect(() => {
    if (tourActive && currentGuideMe === "drawings-view") {
      if (stepIndex === 0) {
        onViewDrawing(null);
        setTimeout(() => {
          setState({ run: true });
        }, 500);
      }
      if (stepIndex === 1 || stepIndex === steps.length - 2) {
        if (drawings && drawings.length > 0) {
          onViewDrawing(drawings[0]);
        }
      }

      if (stepIndex === steps.length - 1) {
        onViewDrawing(null);
      }
    }
  }, [tourActive, stepIndex, steps, currentGuideMe]);

  return (
    <>
      <StyledMaterialReactTable
        columns={columns}
        data={drawings || []}
        enableGrouping
        enablePagination
        filtersComponent={
          isRecycle ? null : (
            <DrawingsFilters
              filters={filters}
              onChange={(val) => onFiltersChange(val)}
            />
          )
        }
        initialState={{
          grouping: isRecycle ? [] : ["disciplineName"],
          density: "comfortable",
          expanded: true,
          columnOrder,
          sorting: [{ id: "disciplineName", desc: false }],
          pagination: { pageSize: 100 },
        }}
        enableStickyHeader
        enableRowActions={!isRecycle}
        selectedRows={selectedRows}
        setSelectedRows={onSelect}
        state={{
          rowSelection: selectedRows,
          columnOrder,
          isLoading,
        }}
        enableRowSelection
        enableColumnActions={false}
        renderRowActions={({ row }) => (
          <Box display="flex" justifyContent="center">
            {(ability.can("superAdmin", "drawings") ||
              (ability.can("edit", "drawings") &&
                row.original.createdBy === currentUser.id)) && (
              <TooltipIconButton
                icon={<Edit sx={{ color: "black" }} />}
                onClick={() => onEdit(row.original?.id)}
                label={tGeneral("edit")}
                data-tour="edit-drawings-1"
              />
            )}
            <TooltipIconButton
              icon={<RemoveRedEye sx={{ color: "black" }} />}
              onClick={() => onViewDrawing(row.original)}
              label={tGeneral("view")}
              data-tour="view-drawings-1"
            />
            <IconButton
              data-tour={
                row.original?.id === orderedDrawings[0].id
                  ? "export-drawings-1"
                  : ""
              }
              onClick={(e) => handleOpenMenu(e, row.original)}
              ref={row.original?.id === orderedDrawings[0].id ? menuRef : null}
            >
              <MoreVert sx={{ color: "black" }} />
            </IconButton>
          </Box>
        )}
        emptyStateMessage={tGeneral("noElements", {
          moduleName: tDrawings("moduleElement").toLowerCase(),
        })}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => onCompare(drawingMenu)}
          disabled={drawingMenu?.revisions?.length < 2}
        >
          <ListItemIcon>
            <Compare
              sx={{
                color: drawingMenu?.revisions?.length < 2 ? "gray" : "black",
              }}
            />
          </ListItemIcon>
          <ListItemText>{tGeneral("compare")}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => setDownloadingRevisions(drawingMenu)}
          data-tour="export-drawings-2"
        >
          <ListItemIcon>
            <Download sx={{ color: "black" }} />
          </ListItemIcon>
          <ListItemText>{tGeneral("download")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setSendEmailRevisions(drawingMenu)}>
          <ListItemIcon>
            <Mail sx={{ color: "black" }} />
          </ListItemIcon>
          <ListItemText>{tGeneral("email")}</ListItemText>
        </MenuItem>
      </Menu>
      <ModalDownload
        isOpen={Boolean(downloadingRevisions)}
        onClose={() => setDownloadingRevisions(null)}
        revisions={downloadingRevisions?.revisions}
        drawingName={downloadingRevisions?.name}
      />
      {Boolean(sendEmailRevisions) && (
        <ModalDrawingEmail
          onClose={() => setSendEmailRevisions(null)}
          isOpen={Boolean(sendEmailRevisions)}
          title={sendEmailRevisions?.name}
          onSend={(message, subject, recipients, ccMails) => {
            handleSendEmail(sendEmailRevisions, message, subject, [
              ...recipients,
              ...ccMails,
            ]);
          }}
          drawing={sendEmailRevisions}
          isLoading={isSendMailLoading || isLoadingPDF}
        />
      )}
    </>
  );
}

export { DrawingsTable };
