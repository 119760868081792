// @ts-check
/* eslint react/prop-types: 0 */

import { ToggleButton, ToggleButtonGroup } from "@mui/material";
// eslint-disable-next-line no-unused-vars
import React from "react";
import { useTranslation } from "react-i18next";

export default function Columns(props) {
  const { columns, handleChange } = props;

  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  return (
    <ToggleButtonGroup
      color="primary"
      onChange={(_, columns) => {
        handleChange(columns);
      }}
      size="small"
      value={columns}
    >
      <ToggleButton
        sx={{
          borderColor: "rgba(53, 53, 53, 0.5)",
          color: "#000000",
          fontSize: "14px",
          paddingBottom: "5px",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "5px",
          textTransform: "none",
        }}
        value="projectCost"
      >
        {tProjectSchedule("$ Project Cost")}
      </ToggleButton>
      <ToggleButton
        sx={{
          borderColor: "rgba(53, 53, 53, 0.5)",
          color: "#000000",
          fontSize: "14px",
          paddingBottom: "5px",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "5px",
          textTransform: "none",
        }}
        value="scheduledTimes"
      >
        {tProjectSchedule("Scheduled Times")}
      </ToggleButton>
      <ToggleButton
        sx={{
          borderColor: "rgba(53, 53, 53, 0.5)",
          color: "#000000",
          fontSize: "14px",
          paddingBottom: "5px",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "5px",
          textTransform: "none",
        }}
        value="realTimes"
      >
        {tProjectSchedule("Real Times")}
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
