import { apiSlice } from "../../api/apiSlice";

export const filesApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    uploadFiles: builder.mutation({
      query: ({ body, moduleName, idObject }) => ({
        url: `/files/${moduleName}/${idObject}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["ImageChat", "RFIs", "Tasks", "Meetings", "Submittals", "Message"],
    }),
  }),
});

export const { useUploadFilesMutation } = filesApiSlice;
