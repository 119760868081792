export const onboarding = {
  welcome: "Bienvenido",
  enterpriseInfo: "Información de empresa",
  features: "Novedades",
  choosePlan: "Escoger plan",
  payment: "Pago",
  welcomeTitle: "Bienvenido a buildpeer",
  welcomeSubtitle: "Conoce tu proyecto & Disfruta el proceso",
  startCreateEnterprise: "Crea una empresa",
  joinProject: "Entrar a un proyecto",
  projectCode: "Código de proyecto",
  createEnterprise: "Crear Empresa",
  officialName: "Nombre de empresa",
  enterpriseType: "Tipo de empresa",
  welcomeJourney: "Bienvenido al viaje",
  planning: "Planeación",
  processTracking: "Avance y seguimiento",
  documentation: "Documentación",
  resources: "Recursos",
  community: "Comunidad",
  management: "Administración",
  communication: "Comunicación",
};
