export const contacts = {
  externalContacts: "외부 연락처",
  addContact: "연락처 추가",
  editContact: "연락처 수정",
  loadingError: "외부 연락처를 불러오는 중에 문제가 발생했습니다",
  createContactSuccess: "연락처가 생성되었습니다",
  updateContactSuccess: "연락처가 업데이트되었습니다",
  deleteContactSuccess: "연락처가 삭제되었습니다",
  deleteContactConfirmation: "이 연락처를 삭제하시겠습니까?",
  noContactsCreated: "현재 연락처가 없습니다",
  noContactsContent: "생성하려면 아래 버튼을 클릭하세요",
  moduleElement: "외부 연락처",
  step1_addContact_title: "1. 새 연락처",
  step1_addContact_text: "새 연락처를 만들려면 여기를 클릭하세요.",
  step2_addContact_title: "2. 이름",
  step2_addContact_text: "연락처의 이름을 입력하세요.",
  step3_addContact_title: "3. 성",
  step3_addContact_text: "연락처의 성(들)을 입력하세요.",
  step4_addContact_title: "4. 이메일",
  step4_addContact_text: "연락처의 이메일 주소를 입력하세요.",
  step5_addContact_title: "5. 전화번호",
  step5_addContact_text: "연락처의 전화번호를 입력하세요.",
  step6_addContact_title: "6. 회사",
  step6_addContact_text: "연락처가 속한 회사를 입력하세요.",
  step7_addContact_title: "7. 추가",
  step7_addContact_text: "연락처 정보를 확인하여 추가하세요.",
  step_final_addContact: "새 연락처를 추가하는 방법을 배웠습니다.",
  step1_editContact_title: "1. 연락처 수정",
  step1_editContact_text: "연락처를 수정하려면 클릭하세요.",
  step2_editContact_title: "2. 이름",
  step2_editContact_text: "연락처의 이름을 수정하세요.",
  step3_editContact_title: "3. 성",
  step3_editContact_text: "연락처의 성(들)을 수정하세요.",
  step4_editContact_title: "4. 이메일",
  step4_editContact_text: "연락처의 이메일을 수정하세요.",
  step5_editContact_title: "5. 전화번호",
  step5_editContact_text: "연락처의 전화번호를 수정하세요.",
  step6_editContact_title: "6. 회사",
  step6_editContact_text: "연락처가 속한 회사를 수정하세요.",
  step7_editContact_title: "7. 확인",
  step7_editContact_text: "연락처에 대한 변경 사항을 확인하세요.",
  step_final_editContact: "연락처를 수정하는 방법을 배웠습니다.",
  step1_deleteContact_title: "1. 연락처 삭제",
  step1_deleteContact_text: "연락처를 삭제하려면 여기를 클릭하세요.",
  step2_deleteContact_title: "2. 삭제 확인",
  step2_deleteContact_text: "연락처의 삭제를 확인하려면 여기를 클릭하세요.",
  step_final_deleteContact: "연락처를 삭제하는 방법을 배웠습니다.",
};
