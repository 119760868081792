export const materialTable = {
  actions: '동작',
  and: '~',
  cancel: '취소',
  changeFilterMode: '필터 모드 변경',
  changeSearchMode: '검색 모드 변경',
  clearFilter: '필터 초기화',
  clearSearch: '검색 초기화',
  clearSelection: '선택 초기화',
  clearSort: '정렬 초기화',
  clickToCopy: '클릭하여 복사하기',
  copy: '복사',
  collapse: '행 접기',
  collapseAll: '전체 접기',
  columnActions: '열 동작',
  copiedToClipboard: '클립보드에 복사됨',
  dropToGroupBy: '드롭하여 {column} 그룹화',
  edit: '편집',
  expand: '행 확장',
  expandAll: '전체 확장',
  filterArrIncludes: '포함',
  filterArrIncludesAll: '모두 포함',
  filterArrIncludesSome: '포함',
  filterBetween: '사이(필터 값 제외)',
  filterBetweenInclusive: '사이(필터 값 포함)',
  filterByColumn: '{column} 필터링',
  filterContains: '포함',
  filterEmpty: '비어있는 셀',
  filterEndsWith: '끝 단어',
  filterEquals: '같음',
  filterEqualsString: '같음',
  filterFuzzy: '퍼지',
  filterGreaterThan: '보다 큼',
  filterGreaterThanOrEqualTo: '보다 크거나 같음',
  filterInNumberRange: '사이',
  filterIncludesString: '포함',
  filterIncludesStringSensitive: '포함',
  filterLessThan: '보다 작음',
  filterLessThanOrEqualTo: '보다 작거나 같음',
  filterMode: '필터 모드: {filterType}',
  filterNotEmpty: '비어있지 않은 셀',
  filterNotEquals: '같지 않음',
  filterStartsWith: '시작 단어',
  filterWeakEquals: '같음',
  filteringByColumn: '{column} 필터링 - {filterValue} {filterType}',
  goToFirstPage: '첫 페이지',
  goToLastPage: '마지막 페이지',
  goToNextPage: '다음 페이지',
  goToPreviousPage: '이전 페이지',
  grab: '잡기',
  groupByColumn: '{column} 그룹화',
  groupedBy: '다음으로 그룹화 됨 ',
  hideAll: '모두 숨기기',
  hideColumn: '{column} 숨기기',
  max: '최대',
  min: '최소',
  move: '이동',
  noRecordsToDisplay: '표시할 데이터가 없습니다.',
  noResultsFound: '찾은 결과가 없습니다.',
  of: '/',
  or: '또는',
  pin: '고정',
  pinToLeft: '왼쪽에 고정',
  pinToRight: '오른쪽에 고정',
  resetColumnSize: '열 크기 초기화',
  resetOrder: '순서 초기화',
  rowActions: '행 동작',
  rowNumber: '#',
  rowNumbers: '행 번호',
  rowsPerPage: '페이지 당 행 수',
  save: '저장',
  search: '검색',
  selectedCountOfRowCountRowsSelected:
    '{rowCount} 행 중 {selectedCount} 행 선택됨',
  select: '선택',
  showAll: '모두 보이기',
  showAllColumns: '모든 열 보이기',
  showHideColumns: '열 숨기기/보이기',
  showHideFilters: '필터 숨기기/보이기',
  showHideSearch: '검색 숨기기/보이기',
  sortByColumnAsc: '{column} 오름차순 정렬',
  sortByColumnDesc: '{column} 내림차순 정렬',
  sortedByColumnAsc: '{column} 오름차순 정렬됨',
  sortedByColumnDesc: '{column} 내림차순 정렬됨',
  thenBy: ', ',
  toggleDensity: '행 높이 조정',
  toggleFullScreen: '전체 화면 토글',
  toggleSelectAll: '전체 선택',
  toggleSelectRow: '행 선택',
  toggleVisibility: '숨기기/보이기',
  ungroupByColumn: '{column} 그룹 해제',
  unpin: '고정 해제',
  unpinAll: '전체 고정 해제',
};
