function getLocationNameFromId(locations, idLocation) {
  if (!locations || !idLocation) return;
  let foundLocation;
  const tiers = Object.values(locations);
  for (let i = 0; i < tiers?.length; i++) {
    if (foundLocation) break;
    for (let j = 0; j < tiers?.[i]?.length; j++) {
      if (tiers?.[i]?.[j]?.id === idLocation) {
        foundLocation = tiers?.[i]?.[j];
        break;
      }
    }
  }

  return foundLocation?.locationName;
}

export default {
  getLocationNameFromId,
};
