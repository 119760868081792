import Lottie from "lottie-react";
import { CircleSharp, Lock, LockOpen } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import animationData from "../../../projectDashboard/16627-firework.json";

const useInspectionSteps = () => {
  const { t: translation } = useTranslation("inspections");
  const addSteps = [
    {
      target: "[data-tour='add-inspection-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_inspectionsAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_inspectionsAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='add-inspection-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_inspectionsAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_inspectionsAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-inspection-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_inspectionsAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_inspectionsAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-inspection-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_inspectionsAdd_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            {translation("step4_inspectionsAdd_text_1_1")}{" "}
            <CircleSharp
              sx={{ color: "#03cafc", marginBottom: -0.3, fontSize: 14 }}
            />
            {translation("step4_inspectionsAdd_text_1_2")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            {translation("step4_inspectionsAdd_text_2_1")}{" "}
            <CircleSharp
              sx={{ color: "#ffc900", marginBottom: -0.3, fontSize: 14 }}
            />
            {translation("step4_inspectionsAdd_text_2_2")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-inspection-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_inspectionsAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_inspectionsAdd_text")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            <span style={{ fontWeight: "bold" }}>
              <LockOpen sx={{ marginBottom: -0.4, fontSize: 16 }} /> Public
            </span>{" "}
            - {translation("step5_inspectionsAdd_text_1")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            <span style={{ fontWeight: "bold" }}>
              <Lock sx={{ marginBottom: -0.4, fontSize: 16 }} /> Private
            </span>{" "}
            - {translation("step5_inspectionsAdd_text_2")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-inspection-6']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_inspectionsAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_inspectionsAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-inspection-7']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_inspectionsAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_inspectionsAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-dailyReport-6']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step8_inspectionsAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step8_inspectionsAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-dailyReport-7']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step9_inspectionsAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step9_inspectionsAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-inspection-10']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step10_inspectionsAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step10_inspectionsAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-inspection-11']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step11_inspectionsAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step11_inspectionsAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_inspectionsAdd")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];
  const addTemplateSteps = [
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_inspectionTemplatesAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_inspectionTemplatesAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='add-inspection-templates-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_inspectionTemplatesAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_inspectionTemplatesAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-inspection-templates-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_inspectionTemplatesAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_inspectionTemplatesAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-inspection-templates-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_inspectionTemplatesAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_inspectionTemplatesAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-inspection-templates-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_inspectionTemplatesAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_inspectionTemplatesAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-inspection-templates-6']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_inspectionTemplatesAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_inspectionTemplatesAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-inspection-templates-7']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_inspectionTemplatesAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_inspectionTemplatesAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-inspection-templates-8']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step8_inspectionTemplatesAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step8_inspectionTemplatesAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-inspection-templates-8_1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step8_1_inspectionTemplatesAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step8_1_inspectionTemplatesAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-inspection-templates-8_1_1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step8_1_1_inspectionTemplatesAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step8_1_1_inspectionTemplatesAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-inspection-templates-8_1_2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step8_1_2_inspectionTemplatesAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step8_1_2_inspectionTemplatesAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-inspection-templates-8_1_3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step8_1_3_inspectionTemplatesAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step8_1_3_inspectionTemplatesAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-inspection-templates-9']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step9_inspectionTemplatesAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step9_inspectionTemplatesAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-inspection-templates-10']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step10_inspectionTemplatesAdd_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step10_inspectionTemplatesAdd_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_inspectionTemplatesAdd")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  return [
    {
      addSteps,
      addTemplateSteps,
    },
  ];
};

export { useInspectionSteps };
