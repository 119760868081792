import { Close, LocationOn, North, South, Umbrella } from "@mui/icons-material";
import { Box, Card, IconButton, Modal, Typography } from "@mui/material";
import moment from "moment";
import { useGetProjectWeekWeatherQuery } from "../../../../features/project/projectApiSlice";
import { getIcon } from "./icons";
import { useTranslation } from "react-i18next";

function DailyWeatherCard({
  date,
  id,
  main,
  iconCode,
  pop,
  min,
  max,
  isLast,
  timezone,
}) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");

  const formattedDate = moment(date * 1000)
    .tz(timezone)
    .format("ddd D")
    ?.split(" ");
  const icon = `https://openweathermap.org/img/wn/${iconCode}@2x.png`;
  const label = tDrawings(`${main.toLowerCase()}-${id}`);
  const width = `${(max - min) * 20}px`;
  const marginLeft = `${min * 1.2}px`;
  return (
    <Box
      display="flex"
      sx={{ borderBottom: isLast ? "none" : "1px solid lightgray" }}
      alignItems="center"
      height="70px"
    >
      <Box width="20%">
        <Typography textAlign="center">{tGeneral(formattedDate[0])}</Typography>
        <Typography fontWeight="bold" textAlign="center">
          {formattedDate[1]}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" width="20%">
        <img src={icon} alt={label} width="40px" height="40px" />
      </Box>
      <Box display="flex" width="20%" justifyContent="center">
        <Umbrella />
        <Typography>{pop}%</Typography>
      </Box>
      <Box width="40%" display="flex">
        <Typography color="gray" ml={marginLeft}>
          {Math.round(min)}°
        </Typography>
        <Box
          width={width}
          sx={{
            backgroundColor: "rgb(212, 221, 231)",
            borderRadius: "5px",
            mx: "10px",
          }}
        />
        <Typography>{Math.round(max)}°</Typography>
      </Box>
    </Box>
  );
}

function ModalWeekWeather({ isOpen, onClose, current, week, timezone }) {
  const { t: tGeneral } = useTranslation("general");

  function toCamelCase(str) {
    return str
      ?.split(" ")
      .map(function (word, index) {
        if (index == 0) {
          return word.toLowerCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join("");
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          width: "40%",
          height: "70%",
          p: "10px 60px",
          borderRadius: "22px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          position: "relative",
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 0, right: 0, color: "black" }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
        <Box display="flex" justifyContent="center" sx={{ color: "gray" }}>
          <LocationOn /> <Typography>{current?.name}</Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          columnGap="40px"
          justifyContent="center"
        >
          <img
            src={current?.icon}
            alt="weather"
            height="100%"
            width="100%"
            style={{ maxHeight: "100px", maxWidth: "100px" }}
          />
          <Typography fontSize="3.5rem">
            {Math.round(current?.temperature)}°
          </Typography>
          <Box>
            <Typography fontWeight="600" textAlign="justify">
              {tGeneral(toCamelCase(current?.label))}
            </Typography>
            <Typography textAlign="justify">
              {tGeneral("feelsLike")} {Math.round(current?.temperatureApparent)}
              °
            </Typography>
          </Box>
        </Box>
        {week?.map(
          ({ temp: { min, max }, dt, weather, pop }, index) =>
            index > 0 && (
              <DailyWeatherCard
                date={dt}
                pop={Math.round(pop * 100)}
                min={min}
                max={max}
                id={weather[0].id}
                main={weather[0].main}
                iconCode={weather[0].icon}
                isLast={index >= week.length - 1}
                timezone={timezone}
              />
            ),
        )}
      </Card>
    </Modal>
  );
}

export { ModalWeekWeather };
