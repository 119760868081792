import { apiSlice } from "../../../../api/apiSlice";
import { objectToParamString } from "../../../../utils";

export const dailyReportsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDailyReports: builder.query({
      query: ({ idProject, ...params }) =>
        `/project/dailyReports/getDailyReports/${idProject}${objectToParamString(
          params,
        )}`,
      keepUnusedDataFor: 60,
      providesTags: ["DailyReports"],
    }),
    getReportsCategoryCounters: builder.query({
      query: (idProject) =>
        `/project/dailyReports/getReportsCategoryCounters/${idProject}`,
      providesTags: ["DailyReports"],
    }),
    getInactiveDailyReports: builder.query({
      query: (idProject) =>
        `/project/dailyReports/getInactiveDailyReports/${idProject}`,
      keepUnusedDataFor: 60,
      providesTags: ["DailyReports"],
    }),
    getReportById: builder.query({
      query: (idReport) => `/project/dailyReports/getDailyReport/${idReport}`,
      keepUnusedDataFor: 60,
      providesTags: ["DailyReports"],
    }),
    createReport: builder.mutation({
      query: (body) => ({
        url: "/project/dailyReports/createDailyReport",
        method: "POST",
        body,
      }),
      invalidatesTags: ["DailyReports"],
    }),
    editReport: builder.mutation({
      query: (body) => ({
        url: "/project/dailyReports/editDailyReport",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["DailyReports"],
    }),
    deleteReports: builder.mutation({
      query: (body) => ({
        url: "/project/dailyReports/deleteDailyReport",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["DailyReports"],
    }),
    setReportStatus: builder.mutation({
      query: (body) => ({
        url: "/project/dailyReports/setStatusDailyReport",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["DailyReports", "Dashboard"],
    }),
    // Restore report
    restoreDailyReport: builder.mutation({
      query: (body) => ({
        url: "/project/dailyReports/restoreDailyReport",
        method: "PUT",
        body,
        invalidatesTags: ["DailyReports"],
      }),
    }),
    getDailyReportChat: builder.query({
      query: (id) => `/project/dailyReports/chat/${id}`,
      providesTags: ["DailyReportsChat"],
    }),
    sendMail: builder.mutation({
      query: (body) => ({
        url: "/project/dailyReports/sendMail",
        method: "POST",
        body,
      }),
    }),
    shareDailyReport: builder.mutation({
      query: ({ idDailyReport, ...body }) => ({
        url: `/project/dailyReports/share/${idDailyReport}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["DailyReports"],
    }),
    getSharedProjects: builder.query({
      query: (idProject) => `/project/dailyReports/sharedProjects/${idProject}`,
    }),
  }),
});

export const {
  useGetDailyReportsQuery,
  useGetInactiveDailyReportsQuery,
  useGetReportByIdQuery,
  useCreateReportMutation,
  useEditReportMutation,
  useDeleteReportsMutation,
  useSetReportStatusMutation,
  useRestoreDailyReportMutation,
  useGetDailyReportChatQuery,
  useSendMailMutation,
  useShareDailyReportMutation,
  useGetReportsCategoryCountersQuery,
  useGetSharedProjectsQuery,
} = dailyReportsApiSlice;
