import Close from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PopUpAlert } from "../../../../../components/PopUpAlert";
import { useEditDocumentMutation } from "../../../../../features/project/modules/documents/documentsApiSlice";
import { useEditProjectFolderMutation } from "../../../../../features/project/modules/documents/projectFolderApiSlice";
import { setNeedReload } from "../../../../../features/project/modules/documents/projectFolderSlice";
import { useTranslation } from "react-i18next";

function EditModal({ isOpen, onClose, oldData }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDocuments } = useTranslation("documents");

  const dispatch = useDispatch();

  const [
    editDocument,
    {
      isSuccess: isEditDocumentSuccess,
      isError: isEditDocumentError,
      isLoading: isEditDocumentLoading,
    },
  ] = useEditDocumentMutation();
  useEffect(() => {
    if (isEditDocumentSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tDocuments("fileUpdatedSuccessfully"),
      );
    }
  }, [isEditDocumentSuccess]);
  useEffect(() => {
    if (isEditDocumentError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
    }
  }, [isEditDocumentError]);
  const [
    editProjectFolder,
    {
      isSuccess: isEditProjectFolderSuccess,
      isError: isEditProjectFolderError,
      isLoading: isEditProjectFolderLoading,
    },
  ] = useEditProjectFolderMutation();
  useEffect(() => {
    if (isEditProjectFolderSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tDocuments("folderMarkedSuccessfully"),
      );
    }
  }, [isEditProjectFolderSuccess]);
  useEffect(() => {
    if (isEditProjectFolderError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
    }
  }, [isEditProjectFolderError]);

  const [modalState, setModalState] = useState({
    id: "",
    name: "",
    isFolder: null,
  });

  const handleChange = (event, name) => {
    setModalState({
      ...modalState,
      [name]: event.target.value,
    });
  };

  useEffect(() => {
    setModalState({
      ...modalState,
      id: oldData.id,
      isFolder: oldData.isFolder,
      fileType: oldData.name
        ? oldData.name.slice(oldData.name.lastIndexOf("."))
        : "",
      name: oldData.name
        ? oldData.isFolder
          ? oldData.name
          : oldData.name.slice(0, oldData.name.lastIndexOf("."))
        : "",
    });
    return () => {};
  }, [oldData]);

  const handleRename = async () => {
    const body = {
      id: modalState.id,
      name: modalState.name,
    };
    if (modalState.isFolder) {
      await editProjectFolder(body).unwrap();
    } else {
      body.name = modalState.name + modalState.fileType;
      await editDocument(body).unwrap();
    }
    dispatch(setNeedReload(true));
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          width: "30%",
          height: "auto",
          borderRadius: "20px",
          p: "25px 40px",
        }}
      >
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="space-between"
          mb="15px"
        >
          <Typography variant="h6" fontWeight="bold">
            {modalState.isFolder
              ? tDocuments("renameFolder")
              : tDocuments("renameFile")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ mt: 2, mb: 2 }}>
          {/* Name */}
          <FormControl fullWidth>
            <TextField
              id={modalState.isFolder ? "folderName" : "fileName"}
              label={
                modalState.isFolder
                  ? tDocuments("folderName")
                  : tDocuments("fileName")
              }
              variant="outlined"
              required
              type="text"
              value={modalState.name}
              onChange={(e) => handleChange(e, "name")}
              InputProps={{
                endAdornment: modalState.isFolder ? (
                  <div />
                ) : (
                  <InputAdornment position="end">
                    {modalState.fileType}
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Box>
        {/* Action Buttons */}
        <Box
          display="flex"
          justifyContent="space-around"
          mb="20px"
          columnGap="1rem"
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            sx={{
              backgroundColor: "white",
              color: "black",
              py: ".8rem",
              border: "1px solid black",
              ":hover": {
                backgroundColor: "lightgrey",
              },
            }}
            fullWidth
          >
            {tGeneral("cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleRename();
            }}
            fullWidth
            sx={{ py: ".8rem" }}
          >
            {tGeneral("accept")}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}

export default EditModal;
