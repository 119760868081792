import { tour } from "./tour";

export const announcements = {
  // general tour
  ...tour,
  moduleElement: "Announcement",
  // ADD - guide me steps
  step1_addAnnouncement_title: "1. Create a new announcement",
  step1_addAnnouncement_text: "Click on the + icon to add a new announcement.",
  step2_addAnnouncement_title: "2. Schedule announcement",
  step2_addAnnouncement_text:
    "Select whether the announcement will be published immediately or if it will be scheduled for a later time. If you choose the second option, additional options will appear.",
  step2_1_addAnnouncement_title: "2.1 Repeat",
  step2_1_addAnnouncement_text:
    "Select the frequency in which the announcement will repeat.",
  step2_2_addAnnouncement_title: "2.2 Start date",
  step2_2_addAnnouncement_text:
    "Select the date when the announcement will be published.",
  step2_3_addAnnouncement_title: "2.3 Start time",
  step2_3_addAnnouncement_text:
    "Select the time when the announcement will be published.",
  step3_addAnnouncement_title: "3. Description",
  step3_addAnnouncement_text: "Write the subject of the announcement.",
  step4_addAnnouncement_title: "4. Distribution list",
  step4_addAnnouncement_text:
    "Select the people who will receive notification of the announcement.",
  step5_addAnnouncement_title: "5. Expiration date",
  step5_addAnnouncement_text:
    "Select the day when the announcement will expire/become invisible.",
  step6_addAnnouncement_title: "6. Expiration time",
  step6_addAnnouncement_text:
    "Select the time when the announcement will expire and no longer be visible.",
  step7_addAnnouncement_title: "7. Add",
  step7_addAnnouncement_text: "Click here to publish the announcement.",
  step_final_addAnnouncement: "You have learned how to create an announcement",
  // VIEW - guide me steps
  step1_viewAnnouncement_title: "1. View announcement",
  step1_viewAnnouncement_text:
    "Click here to see the information about the announcement.",
  step2_viewAnnouncement_title: "2. View announcement",
  step2_viewAnnouncement_text:
    "In the pop-up window, you can see the relevant information about the announcement, such as the description and the date.",
  step_final_viewAnnouncement: "You have learned how to view an announcement",
  // EDIT - guide me steps
  step1_editAnnouncement_title: "1. Edit announcement",
  step1_editAnnouncement_text: "Click here to edit an announcement.",
  step2_editAnnouncement_title: "2. Edit advertisement schedule",
  step2_editAnnouncement_text:
    "Edit whether the advertisement will be published immediately or if it will be published later; selecting the second option will display additional options.",
  step2_1_editAnnouncement_title: "2.1 Edit repeat",
  step2_1_editAnnouncement_text:
    "Edit the frequency at which the announcement will be repeated.",
  step2_2_editAnnouncement_title: "2.2 Start date",
  step2_2_editAnnouncement_text:
    "Edit the date when the announcement will start.",
  step2_3_editAnnouncement_title: "2.3 Start Time",
  step2_3_editAnnouncement_text:
    "Edit the time at which the announcement will start.",
  step3_editAnnouncement_title: "3. Description",
  step3_editAnnouncement_text: "Edit the subject of the announcement.",
  step4_editAnnouncement_title: "4. Edit distribution list",
  step4_editAnnouncement_text:
    "Edit which people will be able to see the announcement.",
  step5_editAnnouncement_title: "5. Expiration date",
  step5_editAnnouncement_text:
    "Edit the day when the announcement will no longer be valid/visible.",
  step6_editAnnouncement_title: "6. Expiration time",
  step6_editAnnouncement_text:
    "Edit the time when the announcement will expire/be no longer visible.",
  step7_editAnnouncement_title: "7. Edit",
  step7_editAnnouncement_text: "Click here to save the changes.",
  step_final_editAnnouncement: "You have learned how to edit an announcement",
  // RECYCLE - guide me steps
  step1_recycleAnnouncement_title: "1. More options",
  step1_recycleAnnouncement_text: "Click here to see more options.",
  step2_recycleAnnouncement_title: "2. Announcement Recycle Bin",
  step2_recycleAnnouncement_text:
    "Click here to visualize the announcement recycle bin.",
  step3_recycleAnnouncement_title: "3.  Menú de la papelera",
  step3_recycleAnnouncement_text: "Este es el menú de la papelera de Anuncios",
  step4_recycleAnnouncement_title: "4. Ver un anuncio",
  step4_recycleAnnouncement_text:
    "Haz click aquí para poder ver cual es la información del anuncio que se quiere restaurar",
  step5_recycleAnnouncement_title: "5. Selecciona anuncios",
  step5_recycleAnnouncement_text:
    "Selecciona uno o varios anuncios para restaurar",
  step6_recycleAnnouncement_title: "6.- Restaurar anuncios seleccionados",
  step6_recycleAnnouncement_text:
    "Haz click aquí para restaurar los anuncios seleccionados",
  step_final_recycleAnnouncement:
    "Haz aprendido cómo interactuar con la papelera de Anuncios",
  // rest
  published: "Published",
  scheduled: "Scheduled",
  newAnnouncement: "New Announcement",
  announcements: "Announcements",
  announcement: "Announcement",
  generals: "Generals",
  programmed: "Programmed",
  expiration: "Expiration",
  creator: "Creator",
  repeat: "Repeat",
  nextPublication: "Next Publication",
  programAnnouncement: "Program announcement",
  programAnnouncementNow: "The announcement will publish now",
  programAnnouncementLater: "The announcement will program for later",
  repeatList: "Repeat List",
  description: "Description",
  startDate: "Start date",
  expirationDate: "Expiration Date",
  time: "Time",
  distributionList: "Distribution List",
  never: "Never",
  everyDay: "Every Day",
  everyWeek: "Every Week",
  everyTwoWeeks: "Every Two Weeks",
  everyMonth: "Every Month",
  everyYear: "Every Year",
  noAnnouncements: "No announcements have been created at this moment",
  addAnnouncement: "Add Announcement",
  noAnnouncementsPar:
    "If you wish to add one, click on the following button to begin the process",
  deleteAnnouncement: "DELETE ANNOUNCEMENT",
  confirmDelete:
    "Are you sure you wish to delete this announcement? This action cannot be reverted",
  recycleBin: "Announcements Recycle Bin",
  editAnnouncement: "Edit Announcement",
  programForLater: "Program for later",
  addAnnouncementTitle: "No announcements have been created at the moment",
  addAnnouncementValidation:
    "If you want to add one, we invite you to click the add button to start the process",
  noAnnouncementsRestore: "No announcements selected to restore",
  expire: "Expires",

  guideMe: "Guide me",
  support: "Support",
};
