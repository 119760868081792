import { Add, Delete, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { StyledMaterialReactTable, TooltipIconButton } from "../../../shared";

function CreateProjectSpecifications() {
  const { t: tGeneral } = useTranslation("general");
  const [code, setCode] = useState("");
  const [name, setName] = useState("");

  const { control, watch } = useFormContext();
  const { append, remove } = useFieldArray({ control, name: "specifications" });

  const handleAdd = () => {
    append({ code, name });
    setCode("");
    setName("");
  };

  const handleDelete = (index) => {
    remove(index);
  };

  const specifications = watch("specifications");

  const columns = [
    {
      accessorKey: "code",
      header: tGeneral("code"),
      Cell: ({ cell }) => <Typography>{cell.getValue()}</Typography>,
      size: 180,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      accessorKey: "name",
      header: tGeneral("name"),
      Cell: ({ cell }) => <Typography>{cell.getValue()}</Typography>,
      size: 180,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
  ];

  return (
    <Accordion sx={{ border: "1px solid #bfbfbf" }}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        {tGeneral("specifications")}
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" mb="0.5rem" columnGap="0.5rem">
          <TextField
            label={tGeneral("code")}
            value={code}
            onChange={(e) => setCode(e.target.value)}
            fullWidth
            size="small"
          />
          <TextField
            label={tGeneral("name")}
            value={name}
            onChange={(e) => setName(e.target.value)}
            fullWidth
            size="small"
          />
          <TooltipIconButton
            icon={<Add />}
            label={tGeneral("add")}
            onClick={handleAdd}
          />
        </Box>
        <StyledMaterialReactTable
          columns={columns}
          data={specifications.map((costCode, i) => ({ ...costCode, id: i }))}
          renderRowActions={({ row }) => (
            <TooltipIconButton
              icon={<Delete />}
              label={tGeneral("delete")}
              onClick={() => handleDelete(row.original?.id)}
              color="red"
            />
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export { CreateProjectSpecifications };
