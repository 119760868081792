const templates = {
  createTemplates: "Crear plantillas",
  createTemplate: "Crear plantilla",
  templateInstructions: "Completa los siguientes pasos para comenzar",
  templateTitle: "Título de plantilla",
  selectDisciplines: "Agrega disciplinas",
  saveTemplate: "Guardar plantilla",
  weighting: "Ponderación",
  checklist: "Checklist",
  designTemplates: "Plantillas de diseño",
  addSubitem: "Agregar sublistado",
  designDescription:
    "Crea plantillas para llevar un seguimiento del departamento de diseño",
  selectModule: "Selecciona un módulo para crear tu plantilla",
  templates: "Plantillas",
  libraries: "Librerias",
  noTemplates: "Todavía no tienes plantillas creadas",
  createTemplatesDesc: "Haz click en crear plantilla para comenzar",
  importTemplate: "Importar desde Excel",
  createNewTemplate: "Crear plantilla nueva",
  filters: {
    title: "Filtros",
    filterBy: "Filtrar por",
    searchUser: "Buscar usuarios",
    filterByEditedBy: "Editado por",
    filterByDateRange: "Fecha",
    startDate: "Inicio:",
    endDate: "Hasta:",
    setDatePlaceholder: "Establecer fecha",
    showResults: "Mostrar resultados",
    clearFilters: "Limpiar",
  },
  noTemplatesFilters: "Tus filtros no coinciden con ninguna plantilla",
  editTemplatesFiltersDesc: "Ajusta tus filtros para continuar.",
  table: {
    name: "Nombre de plantilla",
    createdDate: "Fecha de creación",
    editedDate: "Ultima edicion",
    disciplines: {
      singular: "Disciplina",
      plural: "Disciplinas",
    },
    date: "Fecha",
    actions: "Acciones",
    editedBy: "Editado por",
    items: {
      singular: "Item",
      plural: "Items",
    },
  },
  actions: {
    lastEdits: "Ultimas ediciones",
    exportTemplate: "Exportar a excel",
    editTemplate: "Editar plantilla",
    deleteTemplate: "Eliminar plantilla",
    duplicateTemplate: "Duplicar plantilla",
  },
  delete: {
    title: "Eliminar plantilla",
    description: "¿Estás seguro que deseas eliminar esta plantilla?",
    confirm: "Eliminar",
    cancel: "Cancelar",
  },
  success: "Éxito",
  error: "Error",
  templateDuplicated: {
    success: "Plantilla duplicada con éxito",
    error: "Error al duplicar la plantilla",
  },
  templateDeleted: {
    success: "Plantilla eliminada con éxito",
    error: "Error al eliminar la plantilla",
  },
};

export { templates };
