import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
};

export const userSettingsSlice = createSlice({
  name: "userSettings",
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setCurrentUser } = userSettingsSlice.actions;

export default userSettingsSlice.reducer;

// Selectors
export const selectCurrentUser = (state) => state.userSettings.user;
