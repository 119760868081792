import { ArrowBack, Close, Compare } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import WebViewer from "@pdftron/webviewer";
import { useLazyCompareRevisionsQuery } from "../../features/drawings/revision";
import { PopUpAlert } from "../PopUpAlert";
import { TooltipIconButton } from "../shared";

function ModalCompareDrawings({ isOpen, onClose, comparingDrawing }) {
  const { t: tDrawings } = useTranslation("drawings");
  const { t: tGeneral } = useTranslation("general");

  const [isDocumentLoading, setIsDocumentLoading] = useState(false);

  const viewerRef = useRef();

  const [firstRev, setFirstRev] = useState(null);
  const [secondRev, setSecondRev] = useState(null);
  const [viewerInstance, setViewerInstance] = useState(null);

  const [trigger, { data, isLoading, isError, error, isSuccess }] =
    useLazyCompareRevisionsQuery();
  useEffect(() => {
    if (viewerRef.current) {
      WebViewer(
        {
          licenseKey: process.env.REACT_APP_APRYSE_LICENSE_KEY,
          path: "/pdftron",
          disabledElements: ["header", "toolsHeader"],
          css: "/pdfTron.css",
        },
        viewerRef.current,
      ).then((instance) => {
        setViewerInstance(instance);
        instance.Core.documentViewer.addEventListener("documentLoaded", () => {
          setIsDocumentLoading(false);
          PopUpAlert(
            "success",
            "Generated",
            "Comparison generated successfully",
          );
        });
      });
    }
  }, [viewerRef.current]);

  useEffect(() => {
    if (data && viewerInstance) {
      const { documentViewer } = viewerInstance.Core;
      documentViewer.loadDocument(data.compareUrl);
    }
  }, [data, viewerInstance]);

  const handleGenerateComparation = () => {
    trigger({ idFirstRev: firstRev, idSecondRev: secondRev });
    setIsDocumentLoading(true);
  };

  useEffect(() => {
    if (isError) {
      PopUpAlert("error", "Error", error.message);
    }
  }, [isError, error]);

  return (
    <Modal open={isOpen} onClose={onClose} sx={{ height: "100%" }}>
      <Card sx={{ width: "100%", height: "100%", p: "1%" }}>
        <Box
          display="flex"
          justifyContent="center"
          mb="10px"
          alignItems="center"
        >
          <Typography variant="h5" flex={1}>
            {tGeneral("comparing")}: {comparingDrawing.name}
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            columnGap="20px"
            alignItems="center"
            flex={1}
          >
            <FormControl sx={{ maxWidth: "300px" }} fullWidth size="small">
              <InputLabel shrink>{tDrawings("revision")}</InputLabel>
              <Select
                value={firstRev}
                onChange={(e) => setFirstRev(e.target.value)}
                label={tDrawings("revision")}
                notched
              >
                {comparingDrawing.revisions.map((rev) => (
                  <MenuItem key={rev.id} value={rev.id}>
                    {tDrawings("revision")} {rev.number}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              sx={{ maxWidth: "300px" }}
              fullWidth
              disabled={!firstRev}
              size="small"
            >
              <InputLabel shrink>{tDrawings("compareWith")}</InputLabel>
              <Select
                value={secondRev}
                onChange={(e) => setSecondRev(e.target.value)}
                label={tDrawings("compareWith")}
                notched
              >
                {comparingDrawing.revisions
                  .filter((rev) => rev.id !== firstRev)
                  .map((rev) => (
                    <MenuItem key={rev.id} value={rev.id}>
                      {tDrawings("revision")} {rev.number}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            flex={1}
          >
            <TooltipIconButton
              icon={<Close />}
              onClick={onClose}
              label={tGeneral("close")}
              color="black"
            />
            <TooltipIconButton
              icon={<Compare />}
              onClick={handleGenerateComparation}
              disabled={
                firstRev === null ||
                secondRev === null ||
                isDocumentLoading ||
                isLoading
              }
              label={tGeneral("compare")}
              isLoading={isDocumentLoading || isLoading}
            />
          </Box>
        </Box>
        {(!firstRev || !secondRev) && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="80%"
          >
            <Typography variant="h4">
              {tDrawings("selectDrawgingsToCompare")}
            </Typography>
          </Box>
        )}
        <div
          style={{
            height: "80vh",
            width: "100%",
            display: data ? "block" : "none",
          }}
          ref={viewerRef}
          className="webviewer"
        />
      </Card>
    </Modal>
  );
}

export { ModalCompareDrawings };
