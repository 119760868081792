import { useTranslation } from "react-i18next";
import { Box, Divider, Icon, Typography } from "@mui/material";
import { TemplateCard } from "../../../components/enterpriseDashboard/Templates";
import Compass from "../../../assets/icons/templates/compass.svg";
import Design from "../../../assets/icons/templates/designTemplates.svg";

function Templates() {
  const { t: tTemplates } = useTranslation("templates");
  const { t: tGeneral } = useTranslation("general");
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh", // Full viewport height
        display: "flex",
        flexDirection: "column",
        background: `
        linear-gradient(to top right, #F9F6C8, #FAFAFA 50%, #F9F6C8)
      `,
        backgroundBlendMode: "multiply", // This blends the gradients
      }}
    >
      <Box p="1rem 2rem">
        <Typography fontSize="2.125rem" color="#4B4B4B" fontWeight="700">
          {tTemplates("templates")}
        </Typography>
      </Box>
      <Divider sx={{ py: "0.5rem" }} />
      <Box p="1rem 2rem">
        <Typography color="#8E8E8E" fontWeight="500" mt="1rem" mb="1.5rem">
          {tTemplates("selectModule")}
        </Typography>
        <Box display="grid" gridTemplateColumns="repeat(3, 1fr)">
          <TemplateCard
            title={tGeneral("design")}
            description={tTemplates("designDescription")}
            icon={<img src={Compass} alt="compass" />}
            image={Design}
          />
        </Box>
      </Box>
    </Box>
  );
}

export { Templates };
