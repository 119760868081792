export const projectNetwork = {
  addProjectNetwork: "Agregar a red de proyectos",
  acceptInvite: "Aceptar invitación",
  yourNewCodeIs: "Tu nuevo código es",
  generateNewCode: "Generar nuevo código",
  deleteNetworkMessage:
    "¿Estás seguro que deseas borrar este proyecto de tu red? Todos los objetos compartidos dejarán de ser visibles.",
  emptyNetworkTitle: "Red vacía",
  emptyNetworkContent: "Aun no has agregado proyectos a tu red.",
  accepted: "Aceptado",
  acceptedSuccessfully: "Invitación aceptada satisfacotriamente",
  codeCopied: "Código copiado",
  availableCodes: "Códigos disponibles",
};
