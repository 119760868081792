export const login = {
  errorLoginTitle: "Inicio de sesión fallido",
  errorLoginAuthMessage: "Correo y/o contraseña no coinciden",
  errorLoginMessage: "Algo salió mal. Intenta de nuevo más tarde.",
  errorNoUser: "El usuario no existe",
  welcomeBack: "¡Bienvenido de nuevo!",
  password: "Contraseña",
  logIn: "Iniciar Sesión",
  forgotPassword: "¿Olvidaste tu contraseña?",
  errorRecoverTitle: "Error al recuperar la contraseña",
  followInstructions: "Por favor siga las instrucciones",
  recoverPasswordTitle: "Ingrese la dirección de correo electrónico de su cuenta Buildpeer para recibir instrucciones y poder restablecer su contraseña",
  exampleEmail: "ejemplo@correo.com",
  recoverPassword: "Recuperar contraseña",
  emailInvalid: "Correo electrónico inválido",
};
