import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { Add, Delete, Edit, MoreVert } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Menu,
} from "@mui/material";
import { UnitModal } from "./UnitModal";
import { useGetUnitsQuery } from "../../../../../features/project/unitApiSlice";

const UnitSelect = ({ selectedUnit, onChange = () => {} }) => {
  const { idProject } = useParams();

  const { t: tGeneral } = useTranslation("general");
  const { t: tDailyReports } = useTranslation("dailyReports");

  const { data: projectUnits } = useGetUnitsQuery(idProject);

  const [openModalUnit, setOpenModalUnit] = useState(false);

  const units = useMemo(() => projectUnits ?? [], [projectUnits]);

  return (
    <>
      <FormControl fullWidth>
        <InputLabel size="small">{tDailyReports("unit")}</InputLabel>
        <Select
          id="unit-select"
          labelId="unit-select-label"
          value={selectedUnit || ""}
          onChange={(event) => {
            event.stopPropagation();
            onChange(event.target.value);
          }}
          onClose={(e) => {
            e.stopPropagation();
          }}
          label={tDailyReports("unit")}
          MenuProps={{ sx: { maxHeight: "300px" } }}
          size="small"
          sx={{
            backgroundColor: "white",
          }}
          renderValue={(value) => (
            <Typography>
              {units.find((unit) => unit.id === value)?.name}
            </Typography>
          )}
        >
          {units.map((unit) => (
            <MenuItem value={unit.id} key={unit.id}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                {unit.name}
              </Box>
            </MenuItem>
          ))}
          <Box display="flex">
            <Button
              variant="text"
              startIcon={<Add />}
              fullWidth
              style={{
                justifyContent: "flex-start",
                paddingLeft: "8%",
                color: "black",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModalUnit(true);
              }}
            >
              {tGeneral("add")}
            </Button>
          </Box>
        </Select>
      </FormControl>
      <UnitModal
        isOpen={openModalUnit}
        onClose={(e) => {
          setOpenModalUnit(false);
        }}
      />
    </>
  );
};

export default UnitSelect;
