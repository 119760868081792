import { ArrowBack, Delete, Task } from "@mui/icons-material";
import { Box, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { PopUpAlert } from "../../../../components/PopUpAlert";
import {
  DrawingsToCheck,
  DrawingsToPublish,
} from "../../../../components/drawings";
import {
  ModalWithAction,
  TooltipIconButton,
} from "../../../../components/shared";
import {
  useDeleteRevisionsMutation,
  useGetToCheckDrawingsQuery,
  useGetToPublishDrawingsQuery,
  usePublishRevisionsMutation,
} from "../../../../features/drawings";
import Can from "../../../../utils/can";
import { useAppTourContext } from "../../../../components/supportButton/context/context";
import { useSelector } from "react-redux";
import { selectCurrentGuideMe } from "../../../../features/project/projectSlice";

function ReviewDrawings() {
  const { idProject } = useParams();
  const { t: tDrawings } = useTranslation("drawings");
  const { t: tGeneral } = useTranslation("general");

  const currentGuideMe = useSelector(selectCurrentGuideMe);
  const navigate = useNavigate();
  const [tab, setTab] = useState("toCheck");
  const [selectedToCheck, setSelectedToCheck] = useState({});
  const [selectedToPublish, setSelectedToPublish] = useState({});
  const [deletingIds, setDeletingIds] = useState(null);
  const [filtersToCheck, setFiltersToCheck] = useState({
    search: "",
  });
  const [filtersToPublish, setFiltersToPublish] = useState({
    search: "",
  });

  const {
    data: toCheckData,
    isLoading: drawingsToCheckLoading,
    isFetching: isDrawingsToCheckFetching,
  } = useGetToCheckDrawingsQuery({ idProject, ...filtersToCheck });
  const {
    data: toPublishData,
    isLoading: drawingsToPublishLoading,
    isFetching: isDrawingsToPublishFetching,
  } = useGetToPublishDrawingsQuery({ idProject, ...filtersToPublish });

  const [
    deleteRevisions,
    {
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      error: deleteError,
    },
  ] = useDeleteRevisionsMutation();
  const [
    publishRevisions,
    {
      isLoading: isPublishLoading,
      isSuccess: isPublishSuccess,
      isError: isPublishError,
      error: publishError,
    },
  ] = usePublishRevisionsMutation();

  useEffect(() => {
    if (isDeleteSuccess) {
      PopUpAlert(
        "success",
        tGeneral("deleted"),
        tDrawings("drawingsDeletedMessage"),
      );
    }
    if (isDeleteError) {
      PopUpAlert("error", "Error", deleteError.message);
    }
  }, [isDeleteSuccess, isDeleteError, deleteError, tDrawings, tGeneral]);

  useEffect(() => {
    if (isPublishSuccess) {
      PopUpAlert(
        "success",
        tGeneral("published"),
        tDrawings("drawingsPublishedMessage"),
      );
    }
    if (isPublishError) {
      PopUpAlert("error", "Error", publishError.message);
    }
  }, [isPublishSuccess, isPublishError, publishError, tDrawings, tGeneral]);

  const handleChangeTab = (_, newValue) => {
    setTab(newValue);
    setSelectedToCheck({});
    setSelectedToPublish({});
  };

  const handleDeleteRevisions = async () => {
    await deleteRevisions({ ids: deletingIds });
    setDeletingIds(null);
  };

  const handlePublish = () => {
    publishRevisions({ ids: Object.keys(selectedToPublish) });
  };

  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 5 &&
      currentGuideMe === "drawings-review-and-publish"
    ) {
      setTab("toCheck");

      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
    if (
      tourActive &&
      stepIndex === 6 &&
      currentGuideMe === "drawings-review-and-publish"
    ) {
      setTab("toPublish");

      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }

    if (
      tourActive &&
      stepIndex === 7 &&
      currentGuideMe === "drawings-review-and-publish"
    ) {
      setTab("toPublish");

      if (toPublishData && toPublishData.length > 0) {
        const key = toPublishData[0].id;
        setSelectedToPublish(JSON.parse(`{"${key}": true }`));
      }

      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe, toPublishData]);

  return (
    <Box flex={1}>
      <Box
        display="flex"
        alignItems="center"
        borderBottom="1px solid #FBCB04"
        p="10px"
      >
        <Box display="flex" alignItems="center" width="100%">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h4">{tDrawings("reviewDrawings")}</Typography>
        </Box>
        <Box
          display="flex"
          columnGap="16px"
          flex={1}
          justifyContent="flex-end"
          alignItems="center"
        >
          {(Object.keys(selectedToPublish).length > 0 ||
            Object.keys(selectedToCheck).length > 0) && (
            <Can I="delete" a="drawings">
              <TooltipIconButton
                icon={<Delete />}
                label={tGeneral("delete")}
                onClick={() =>
                  setDeletingIds([
                    ...Object.keys(selectedToCheck),
                    ...Object.keys(selectedToPublish),
                  ])
                }
              />
            </Can>
          )}
          {Object.keys(selectedToPublish).length > 0 && (
            <TooltipIconButton
              icon={<Task />}
              label={tGeneral("publish")}
              onClick={handlePublish}
              data-tour="review-and-publish-drawings-8"
            />
          )}
        </Box>
      </Box>
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        sx={{
          borderBottom: "1px solid #CBCBCB",
        }}
      >
        <Tab
          sx={{ "&.Mui-selected": { color: "#000", fontWeight: "bold" } }}
          label={`${tDrawings("drawingsToCheck")} (${toCheckData?.length})`}
          value="toCheck"
        />
        <Tab
          sx={{ "&.Mui-selected": { color: "#000", fontWeight: "bold" } }}
          label={`${tDrawings("drawingsToPublish")} (${toPublishData?.length})`}
          value="toPublish"
          data-tour="review-and-publish-drawings-6"
        />
      </Tabs>
      <Box flex={1} height="80vh" mt="15px">
        {tab === "toCheck" && (
          <DrawingsToCheck
            data={toCheckData}
            selected={selectedToCheck}
            onSelectionChange={setSelectedToCheck}
            onDelete={(id) => setDeletingIds([id])}
            isLoading={drawingsToCheckLoading || isDrawingsToCheckFetching}
            filters={filtersToCheck}
            setFilters={(val) => setFiltersToCheck(val)}
          />
        )}
        {tab === "toPublish" && (
          <DrawingsToPublish
            data={toPublishData}
            selected={selectedToPublish}
            onSelectionChange={setSelectedToPublish}
            onDelete={(id) => setDeletingIds([id])}
            isLoading={drawingsToPublishLoading || isDrawingsToPublishFetching}
            filters={filtersToPublish}
            setFilters={(val) => setFiltersToPublish(val)}
          />
        )}
      </Box>
      <ModalWithAction
        title={tDrawings("deleteDrawing").toUpperCase()}
        action={handleDeleteRevisions}
        actionLabel={tGeneral("accept")}
        isOpen={Boolean(deletingIds)}
        onClose={() => setDeletingIds(null)}
        icon="delete"
        isLoading={isDeleteLoading}
        content={tDrawings("deletingDrawingMessage")}
      />
    </Box>
  );
}

export { ReviewDrawings };
