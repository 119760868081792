import {
  ChevronLeft,
  ChevronRight,
  Clear,
  LocationOn,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Checkbox,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useGetLocationsQuery } from "../../features/locations/locationApiSlice";

function SearchLocationFilterV2({
  selectedLocations,
  onChange,
  size = "small",
}) {
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");

  const [tempLocations, setTempLocations] = useState([]);
  const [superLocation, setSuperLocation] = useState([]);

  useEffect(() => {
    setTempLocations(selectedLocations);
  }, [selectedLocations]);

  const { data } = useGetLocationsQuery(idProject, {
    skip: !idProject,
    refetchOnMountOrArgChange: true,
  });
  const options = useMemo(() => {
    if (!data) return [];
    if (superLocation.length === 0) {
      return data.tier1;
    }
    if (superLocation.length === 1) {
      return [
        { id: "back-tier-1", locationName: tGeneral("back") },
        ...data.tier2.filter((loc) => loc.superLocation === superLocation[0]),
      ];
    }
    if (superLocation.length === 2) {
      return data.tier3.filter((loc) => loc.superLocation === superLocation[1]);
    }
    return [];
  }, [data, superLocation, tGeneral]);

  const handleSelectLocation = (add, id) => {
    if (add) {
      setTempLocations([...tempLocations, id]);
    } else {
      setTempLocations((prev) => prev.filter((location) => location !== id));
    }
  };

  const handleSetFilter = () => {
    setSuperLocation([]);
    if (tempLocations.length === 0 && selectedLocations === 0) return;
    onChange(tempLocations);
  };

  const handleOnChange = (location) => {
    if (tempLocations.indexOf(location.id) > -1) {
      setTempLocations((prev) => prev.filter((loc) => loc.id !== location.id));
    } else {
      setTempLocations((prev) => [...prev, location.id]);
    }
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      options={options}
      disableCloseOnSelect
      getOptionLabel={(location) => location?.locationName}
      getOptionKey={(location) => location?.id}
      value={tempLocations}
      onBlur={handleSetFilter}
      renderOption={(props, location, { index }) => (
        <li {...props} key={location.id}>
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            onClick={(e) => {
              e.stopPropagation();
              handleSelectLocation(
                !(tempLocations.indexOf(location.id) > -1),
                location.id,
              );
            }}
          >
            {index === 0 && superLocation.length > 0 ? (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setSuperLocation((prev) =>
                    prev.length === 2 ? [prev[0]] : [],
                  );
                }}
              >
                <ChevronLeft />
              </IconButton>
            ) : (
              <Checkbox checked={tempLocations.indexOf(location.id) > -1} />
            )}
            <Typography flex={1}>{location.locationName}</Typography>
            {superLocation.length < 2 &&
              data?.[`tier${superLocation.length + 2}`]?.some(
                (loc) => loc.superLocation === location.id,
              ) && (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setSuperLocation((prev) => [...prev, location.id]);
                  }}
                >
                  <ChevronRight />
                </IconButton>
              )}
          </Box>
        </li>
      )}
      noOptionsText={tGeneral("noOptions")}
      fullWidth
      size={size}
      disableClearable
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            sx: {
              padding: 0,
              backgroundColor: "white",
              borderRadius: "1rem",
              ...params.InputProps.sx,
            },
            startAdornment: <LocationOn sx={{ color: "#E6B60C" }} />,
            endAdornment:
              selectedLocations.length > 0 ? (
                <IconButton
                  onClick={() => {
                    onChange([]);
                  }}
                  size="small"
                  sx={{ padding: 0 }}
                >
                  <Clear />
                </IconButton>
              ) : null,
          }}
          size={size}
          variant="outlined"
          placeholder={`${tGeneral("location")} (${tempLocations.length})`}
        />
      )}
      onChange={(ev, val) => handleOnChange(val.pop())}
    />
  );
}

export { SearchLocationFilterV2 };
