export const contacts = {
  externalContacts: "外部联系人",
  addContact: "添加联系人",
  editContact: "编辑联系人",
  loadingError: "加载外部联系人时出现问题",
  createContactSuccess: "联系人创建成功",
  updateContactSuccess: "联系人更新成功",
  deleteContactSuccess: "联系人删除成功",
  deleteContactConfirmation: "确定要删除此联系人吗？",
  noContactsCreated: "目前没有联系人",
  noContactsContent: "如果您想创建新的联系人，请点击下面的按钮",
  moduleElement: "外部联系人",
  step1_addContact_title: "1. 新建联系人",
  step1_addContact_text: "点击这里新建一个联系人。",
  step2_addContact_title: "2. 姓名",
  step2_addContact_text: "输入联系人的姓名。",
  step3_addContact_title: "3. 姓氏",
  step3_addContact_text: "输入联系人的姓氏。",
  step4_addContact_title: "4. 电子邮件",
  step4_addContact_text: "输入联系人的电子邮件地址。",
  step5_addContact_title: "5. 电话号码",
  step5_addContact_text: "输入联系人的电话号码。",
  step6_addContact_title: "6. 企业",
  step6_addContact_text: "输入联系人所属的公司。",
  step7_addContact_title: "7. 添加",
  step7_addContact_text: "确认联系人信息以添加。",
  step_final_addContact: "您已学会如何添加新联系人。",
  step1_editContact_title: "1. 编辑联系人",
  step1_editContact_text: "点击编辑联系人。",
  step2_editContact_title: "2. 姓名",
  step2_editContact_text: "编辑联系人的姓名。",
  step3_editContact_title: "3. 姓氏",
  step3_editContact_text: "编辑联系人的姓氏。",
  step4_editContact_title: "4. 电子邮件",
  step4_editContact_text: "编辑联系人的电子邮件。",
  step5_editContact_title: "5. 电话号码",
  step5_editContact_text: "编辑联系人的电话号码。",
  step6_editContact_title: "6. 公司",
  step6_editContact_text: "编辑联系人所属的公司。",
  step7_editContact_title: "7. 确认",
  step7_editContact_text: "确认对联系人的更改。",
  step_final_editContact: "您已学会如何编辑联系人。",
  step1_deleteContact_title: "1. 删除联系人",
  step1_deleteContact_text: "点击这里删除一个联系人。",
  step2_deleteContact_title: "2. 确认删除",
  step2_deleteContact_text: "点击这里确认删除联系人。",
  step_final_deleteContact: "您已学会如何删除联系人。",
};
