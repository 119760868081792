import {
  AssignmentLateRounded,
  AssignmentTurnedInRounded,
  BookRounded,
  CachedRounded,
  CampaignRounded,
  EventNoteRounded,
  FlagRounded,
  FolderRounded,
  GroupsRounded,
  InsertPhotoRounded,
  LibraryAddCheckRounded,
  NoteRounded,
  PollRounded,
  StickyNote2Rounded,
  WarningRounded,
  WatchLaterRounded,
} from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CommunicationImage from "../../../assets/onboarding/features/communication.svg";
import CommunityImage from "../../../assets/onboarding/features/community.svg";
import DocumentationImage from "../../../assets/onboarding/features/documentation.svg";
import FeaturesMainImage from "../../../assets/onboarding/features/main.png";
import OrganizationImage from "../../../assets/onboarding/features/organization.svg";
import PlanImage from "../../../assets/onboarding/features/plan.svg";
import ProcessTrackingImage from "../../../assets/onboarding/features/process-tracking.svg";
import ResourcesImage from "../../../assets/onboarding/features/resources.svg";
import { FeatureCard } from "./FeatureCard";

const features = [
  {
    title: "planning",
    cover: PlanImage,
    features: [
      {
        icon: <NoteRounded />,
        label: "drawings",
      },
      {
        icon: <AssignmentTurnedInRounded />,
        label: "submittals",
      },
      {
        icon: <CachedRounded />,
        label: "rfis",
      },
    ],
  },
  {
    title: "processTracking",
    cover: ProcessTrackingImage,
    features: [
      {
        icon: <FlagRounded />,
        label: "dailyReports",
      },
      {
        icon: <WarningRounded />,
        label: "issues",
      },
      {
        icon: <InsertPhotoRounded />,
        label: "photos",
      },
      {
        icon: <StickyNote2Rounded />,
        label: "tasks",
      },
      {
        icon: <PollRounded />,
        label: "projectSchedule",
      },
      {
        icon: <LibraryAddCheckRounded />,
        label: "inspections",
      },
    ],
  },
  {
    title: "documentation",
    cover: DocumentationImage,
    features: [
      {
        icon: <FolderRounded />,
        label: "documents",
      },
      {
        icon: <GroupsRounded />,
        label: "meetings",
      },
      {
        icon: <AssignmentLateRounded />,
        label: "delay",
      },
    ],
  },
  {
    title: "resources",
    cover: ResourcesImage,
    features: [
      {
        icon: <BookRounded />,
        label: "directory",
      },
      {
        icon: <WatchLaterRounded />,
        label: "hours",
      },
      {
        icon: <EventNoteRounded />,
        label: "deliveries",
      },
      {
        icon: <CampaignRounded />,
        label: "announcements",
      },
    ],
  },
  {
    title: "community",
    cover: CommunityImage,
    features: [],
  },
  {
    title: "management",
    cover: OrganizationImage,
    content:
      "Make it easy to track every aspect of your project, take control and enjoy the process with every stage on time and on budget",
  },
  {
    title: "communication",
    cover: CommunicationImage,
    content:
      "All team members communicated in real time, with updates from each area of the project, without having to wait for a response. It also includes a internal chat so everyone can catch up to any news.",
  },
];

function Features({ onButtonClick, onBackClick }) {
  const { t: tOnboarding } = useTranslation("onboarding");
  const { t: tGeneral } = useTranslation("general");
  const [scrolled, setScrolled] = useState(0);
  const [maxScroll, setMaxScroll] = useState(0);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      setMaxScroll(
        scrollRef.current.scrollWidth - scrollRef.current.clientWidth,
      );
    }
  }, []);

  const handleScroll = (e) => {
    setScrolled(e.target.scrollLeft);
  };

  const handleNextClick = () => {
    if (scrolled >= maxScroll) {
      onButtonClick();
    } else {
      setScrolled(maxScroll);
      scrollRef.current.scrollLeft = maxScroll;
    }
  };
  return (
    <Box>
      <Typography fontWeight="bold" fontSize="2rem" textAlign="center">
        {tOnboarding("welcomeJourney")}
      </Typography>
      <Box
        display="flex"
        sx={{
          backgroundImage: `url(${FeaturesMainImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
        alignItems="center"
      >
        <Box flex={1}>
          <Typography fontWeight="bold" fontSize="2.1875rem">
            Buildpeer
          </Typography>
          <Typography fontSize="3.4375rem" fontWeight="bold">
            {tGeneral("features")}
          </Typography>
        </Box>
        <Box
          component="div"
          className="hide-scroll"
          display="flex"
          columnGap="12px"
          sx={{
            overflow: "auto",
            padding: "10px",
            scrollbarWidth: "none",
          }}
          flex={3}
          onScrollCapture={handleScroll}
          ref={(ref) => {
            scrollRef.current = ref;
          }}
        >
          {features.map(({ title, features, content, cover }) => (
            <FeatureCard
              title={tOnboarding(title)}
              features={features}
              content={content}
              cover={cover}
            />
          ))}
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        columnGap="5px"
        my="36px"
      >
        <Box
          width={`${scrolled}px`}
          height="4px"
          maxWidth={`${maxScroll - 50}px`}
          sx={{ backgroundColor: "#CFCFCF", borderRadius: "2px" }}
        />
        <Box
          width="50px"
          height="4px"
          sx={{ backgroundColor: "#FFC42D", borderRadius: "2px" }}
        />
        <Box
          width={`${maxScroll - 50 - scrolled}px`}
          height="4px"
          sx={{ backgroundColor: "#CFCFCF", borderRadius: "2px" }}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" columnGap="16px">
        {/* cancel button */}
        <Button
          variant="contained"
          sx={{
            p: "16px 100px",
            backgroundColor: "white",
            color: "black",
            border: "1px solid black",
            "&:hover": {
              backgroundColor: "gray",
            },
          }}
          // disabled={isUpdateProjectLoading}
          onClick={onBackClick}
        >
          <Typography fontWeight="medium" fontSize="1rem">
            Back
          </Typography>
        </Button>

        {/* Save Button */}
        <Button
          variant="contained"
          sx={{ p: "16px 100px" }}
          onClick={handleNextClick}
        >
          <Typography fontWeight="medium" fontSize="1rem">
            Next
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}

export { Features };
