export const notificationActions = {
  CREATE: "Ha creado {{object}}",
  EDIT: "Ha editado {{object}}",
  CLOSE: "Ha cerrado {{object}}",
  RESTORE: "Ha restaurado {{object}}",
  ANSWER: "Ha respondido una pregunta en {{object}}",
  NEW_TOPIC: "Ha creado un nuevo tema en {{object}}",
  COMMENT: "Ha comentado {{object}}",
  REOPEN: "Ha reabierto {{object}}",
  OFFICIAL_RESPONSE: "Ha marcado una respuesta como oficial en {{object}}",
  DRAFT: "Ha creado {{object}} como borrador",
  APPROVE: "Ha aprobado {{object}}",
  DELETE: "Ha eliminado {{object}}",
  START: "Ha comenzado {{object}}",
  CONFIRM: "Ha confirmado su asistencia a {{object}}",
  CREATE_PHOTO: "Ha subido una foto",
  CREATE_ALBUM: "Ha creado un album",
  CREATE_VIDEO: "Ha creado un video",
  CREATE_FOLDER: "Ha creado una carpeta",
  CREATE_FILE: "Ha subido un archivo",
  MOVE_FILE: "Ha movido un archivo",
  MOVE_FOLDER: "Ha movido una carpeta",
  CREATE_SCHEDULED: "Ha creado un anuncio programado",
  REVISION: "Ha creado una revisión",
  CREATE_ANNOTATION: "Ha creado una anotación",
  ASSIGN: "Ha asignado una anotación",
  LINK: "Ha enlazado una anotación",
  SIGN: "Ha firmado {{object}}",
  REJECT: "Ha rechazado {{object}}",
  NEW_REVISION: "Ha hecho una revisión de esta inspección",
  rfi: "un rfi",
  drawing: "un plano",
  task: "una tarea",
  daily_report: "un reporte",
  dailyReport: "un reporte",
  image: "una imágen",
  document: "un archivo",
  inspection: "una inspección",
  meeting: "una reunión",
};
