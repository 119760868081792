import { tour } from "./tour";

const roles = {
  // general tour
  ...tour,
  moduleElement: "Funções",
  step1_addRole_title: "1. Adicionar Função",
  step1_addRole_text: "Clique aqui para adicionar uma nova função.",
  step2_addRole_title: "2. Nome da Função",
  step2_addRole_text: "Dê um nome para a função que está sendo criada.",
  step3_addRole_title: "3. Permissões",
  step3_addRole_text:
    "Escolha o tipo de permissão para cada módulo que a função terá.",
  step4_addRole_title: "4. Adicionar",
  step4_addRole_text: "Clique aqui para adicionar a função à lista de funções.",
  step_final_addRole:
    "Você aprendeu como adicionar uma nova função à lista de funções.",
  // EDIT - guide me steps
  step1_editRole_title: "1. Editar Função",
  step1_editRole_text: "Clique aqui para editar a função.",
  step2_editRole_title: "2. Nome da Função",
  step2_editRole_text: "Edite o nome da função.",
  step3_editRole_title: "3. Permissões",
  step3_editRole_text:
    "Edite o tipo de permissão para cada módulo que a função possui.",
  step4_editRole_title: "4. Salvar",
  step4_editRole_text:
    "Clique aqui para salvar as alterações feitas na função.",
  step_final_editRole: "Você aprendeu como atualizar uma função.",
  // DELETE - guide me steps
  step1_deleteRole_title: "1. Excluir Função",
  step1_deleteRole_text: "Clique aqui para excluir a função.",
  step2_deleteRole_title: "2. Confirmar",
  step2_deleteRole_text: "Clique aqui para confirmar a exclusão.",
  step_final_deleteRole: "Você aprendeu como excluir uma função.",
  // CHANGE ROLE - guide me steps
  step1_changeRole_title: "1. Função do Usuário",
  step1_changeRole_text: "Selecione o usuário cuja função será alterada.",
  step2_changeRole_title: "2. Tipo de Função",
  step2_changeRole_text: "Selecione a função que o usuário terá.",
  step3_changeRole_title: "3. Salvar",
  step3_changeRole_text: "Clique aqui para salvar as alterações.",
  step_final_changeRole: "Você aprendeu como alterar a função de um usuário.",
};

export { roles };
