import {
  Add,
  ArrowBack,
  CloudUpload,
  Delete,
  Download,
  Edit,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import JSZip from "jszip";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { PopUpAlert } from "../../../components/PopUpAlert";
import {
  CreateAlbum,
  PhotoGallery,
  UploadPhoto,
} from "../../../components/projectDashboard/Photos";
import { ModalWithAction, TooltipIconButton } from "../../../components/shared";
import { ModalFileViewer } from "../../../components/shared/ModalFileViewer";
import { useGetAlbumQuery } from "../../../features/project/imageApiSlice";
import { useDistributionList, useImageGallery } from "../../../hooks";
import Can from "../../../utils/can";
import { SearchUserFilter } from "../../../components/shared/SearchUserFilter";
import { SearchLocationFilter } from "../../../components/shared/SearchLocationFilter";
import { DateRangeFilter } from "../../../components/shared/DateRangeFilter";
import { selectCurrentToken } from "../../../features/auth/authSlice";
import pdf from "../../../utils/pdf";
import {
  PHOTO_EXPORT_DOWNLOAD_LIMIT,
  VIDEO_EXTENSIONS,
} from "../../../constants";
import { useToast } from "../../../contexts/ToastContext";
import { setDownloadingFiles } from "../../../features/drawings/downloadsSlice";

function AlbumView() {
  const { t: tPhotos } = useTranslation("photos");
  const { t: tGeneral } = useTranslation("general");
  const { idEnterprise, idProject, idAlbum } = useParams();
  const token = useSelector(selectCurrentToken);
  const [isAlbumOpen, setIsAlbumOpen] = React.useState(false);
  const [openAddFilter, setOpenAddFilter] = React.useState(false);
  const [isImageOpen, setIsImageOpen] = React.useState(false);
  const [selectedLocations, setSelectedLocations] = React.useState(null);
  const [selectedUsers, setSelectedUsers] = React.useState(null);
  const [selectedDateRange, setSelectedDateRange] = React.useState(null);
  const [groupBy, setGroupBy] = React.useState("month");
  const toast = useToast();

  const dispatch = useDispatch();

  const [selectedDateRangeCreated, setSelectedDateRangeCreated] =
    React.useState(null);
  const navigate = useNavigate();
  const { projectUsers } = useDistributionList(idProject, "photos");
  const { data: album, isLoading } = useGetAlbumQuery({
    idAlbum,
    idProject,
    uploadedBy:
      selectedUsers !== null
        ? selectedUsers?.map((user) => user.id).join(",")
        : "",
    locations:
      selectedLocations?.map((location) => location.id).join(",") ?? "",
    ...(selectedDateRange !== null &&
      selectedDateRange !== "" && { startDate: selectedDateRange[0] }),
    ...(selectedDateRange !== null &&
      selectedDateRange !== "" && { endDate: selectedDateRange[1] }),
    groupBy,
    ...(selectedDateRangeCreated !== null &&
      selectedDateRangeCreated !== "" && {
        startDateCreated: selectedDateRangeCreated[0],
      }),
    ...(selectedDateRangeCreated !== null &&
      selectedDateRangeCreated !== "" && {
        endDateCreated: selectedDateRangeCreated[1],
      }),
  });
  const {
    selectedDates,
    selectedImages,
    handleSelectImage,
    handleSelectAllImages,
    isDeleteLoading,
    handleDeleteImages,
    isDeleteOpen,
    onOpenDelete,
    onCloseDelete,
    handleClearImageGallery,
  } = useImageGallery();
  const [pdfUrl, setPdfUrl] = React.useState(null);

  // State variable used to track if we are currently downloading the album as a zip file
  const [isLoadingDownloadAlbum, setIsLoadingDownloadAlbum] =
    React.useState(false);
  const [isDownloadBatchFilesError, setIsDownloadBatchFilesError] =
    React.useState(false);

  const selectedImagesFiltered = React.useMemo(() => {
    let filteredImages = [];

    if (album?.imageLists) {
      const allImages = album.imageLists.reduce(
        (prev, curr) => [...prev, ...curr.photos],
        [],
      );

      if (selectedImages.length > 0) {
        filteredImages = [
          ...filteredImages,
          ...allImages.filter((image) => selectedImages.indexOf(image.id) > -1),
        ];
      }
    }

    return filteredImages;
  }, [album, selectedImages]);

  const handleExportImages = async (type) => {
    if (selectedImagesFiltered.length > PHOTO_EXPORT_DOWNLOAD_LIMIT) {
      PopUpAlert(
        "warning",
        tGeneral("exportPdf"),
        tGeneral("exportPdfLimitWarning"),
        4000,
      );
      return;
    }

    const albumImages = album.imageLists.reduce((curr, list) => {
      return [...curr, ...list.photos];
    }, []);

    const downloadImages = albumImages?.filter(
      (imageL) => selectedImages.indexOf(imageL.id) > -1,
    );

    let timeout = 500;
    if (
      downloadImages.some((image) =>
        VIDEO_EXTENSIONS.includes(
          image.name.toLowerCase().substring(image.name.lastIndexOf(".") + 1),
        ),
      )
    ) {
      PopUpAlert(
        "info",
        tGeneral("exportPdf"),
        tGeneral("exportPdfVideoWarning"),
        2000,
        "30rem",
      );

      // Increase timeout to allow user to read the warning message and the loading spinner to show
      timeout = 2250;
    }

    setTimeout(async () => {
      dispatch(
        setDownloadingFiles([
          {
            type: "export",
            module: "photos",
            export: {
              data: {
                album: idAlbum,
                photos: selectedImages,
                type,
              },
              token,
              url: `${process.env.REACT_APP_BACKEND_URL}pdf/${idEnterprise}/${idProject}/photos`,
              download: album.name,
              module: "photos",
              tGeneral,
            },
          },
        ]),
      );
    }, timeout);
  };

  const handleAddFilter = (filter) => {
    if (filter === "location") {
      setSelectedLocations((prev) => (prev === null ? [] : prev));
    } else if (filter === "uploadedOn") {
      setSelectedDateRange((prev) => (prev === null ? "" : prev));
    } else if (filter === "createdOn") {
      setSelectedDateRangeCreated((prev) => (prev === null ? "" : prev));
    } else {
      setSelectedUsers((prev) => (prev === null ? [] : prev));
    }
    setOpenAddFilter(false);
  };

  React.useEffect(() => {
    if (isDownloadBatchFilesError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("generalErrorMessage"));
    }
  }, [isDownloadBatchFilesError, tGeneral]);

  const users = React.useMemo(() => {
    if (projectUsers) {
      return projectUsers.map((user) => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        urlAvatar: user.urlAvatar,
        ...user,
      }));
    }
    return [];
  }, [projectUsers]);

  const handleDownload = async () => {
    if (selectedImages.length === 0) return;
    const albumImages = album.imageLists.reduce((curr, list) => {
      return [...curr, ...list.photos];
    }, []);

    dispatch(
      setDownloadingFiles([
        {
          type: "album",
          module: "photos",
          albumName: album.name,
          files: albumImages?.filter(
            (imageL) => selectedImages.indexOf(imageL.id) > -1,
          ),
        },
      ]),
    );
  };

  const renderAlbum = () => (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid #FBCB04"
        height="70px"
        px="10px"
      >
        <Box display="flex" alignItems="center" columnGap="10px">
          <IconButton
            onClick={() => navigate("./../..", { state: { stateShowTab: 1 } })}
            sx={{ width: "1rem", height: "1rem" }}
          >
            <ArrowBack fontSize="1rem" />
          </IconButton>
          <Typography fontSize="1.5625rem">{album?.name}</Typography>
          <Can I="renameAlbum" a="photos">
            <IconButton onClick={() => setIsAlbumOpen(true)}>
              <Edit />
            </IconButton>
          </Can>
        </Box>
        <Box display="flex" alignItems="center" columnGap="15px">
          {selectedImagesFiltered.length > 0 && (
            <InputLabel id="actions-label">{`${selectedImagesFiltered.length}/${PHOTO_EXPORT_DOWNLOAD_LIMIT}`}</InputLabel>
          )}
          <FormControl
            disabled={isLoading || !selectedImages.length}
            size="small"
            sx={{ width: "200px" }}
          >
            <InputLabel id="actions-label">{tGeneral("exportPdf")}</InputLabel>
            <Select
              labelId="actions-label"
              id="actions-select"
              label={tGeneral("exportPdf")}
              autoWidth
            >
              <ListSubheader>{`PDF - ${tPhotos(
                "selectedPhotos",
              )}`}</ListSubheader>
              <MenuItem onClick={() => handleExportImages("1perPage")}>
                {`1 ${tPhotos("perPage")}`}
              </MenuItem>
              <MenuItem onClick={() => handleExportImages("2perPage")}>
                {`2 ${tPhotos("perPage")}`}
              </MenuItem>
              <MenuItem onClick={() => handleExportImages("4perPage")}>
                {`4 ${tPhotos("perPage")}`}
              </MenuItem>
            </Select>
          </FormControl>
          {selectedImages.length > 0 && (
            <>
              <TooltipIconButton
                icon={
                  isLoadingDownloadAlbum ? (
                    <CircularProgress
                      style={{ width: "24px", height: "24px" }}
                      color="secondary"
                    />
                  ) : (
                    <Download />
                  )
                }
                label={tGeneral("download")}
                onClick={() => handleDownload()}
                color="#FBCB04"
              />
              <Can I="delete" a="photos">
                <TooltipIconButton
                  onClick={onOpenDelete}
                  icon={<Delete />}
                  label={tGeneral("delete")}
                  color="#FBCB04"
                />
              </Can>
            </>
          )}

          {selectedImages.length === 0 && (
            <Can I="add" a="photos">
              <TooltipIconButton
                icon={<Add />}
                onClick={() => setIsImageOpen(true)}
                label={tGeneral("add")}
                color="#FBCB04"
              />
            </Can>
          )}
        </Box>
      </Box>

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" columnGap="10px" p="10px" flex={1}>
          <FormControl sx={{ minWidth: "150px" }} size="small">
            <InputLabel shrink={false}>{tGeneral("addFilter")}</InputLabel>
            <Select
              open={openAddFilter}
              onClose={() => {
                setOpenAddFilter(false);
              }}
              onOpen={() => {
                setOpenAddFilter(true);
              }}
              onChange={() => null}
              value={null}
            >
              <MenuItem
                value="location"
                onClick={() => handleAddFilter("location")}
              >
                {tGeneral("location")}
              </MenuItem>
              <MenuItem
                value="uploadedOn"
                onClick={() => handleAddFilter("uploadedOn")}
              >
                {tPhotos("dateUploaded")}
              </MenuItem>
              <MenuItem
                value="createdOn"
                onClick={() => handleAddFilter("createdOn")}
              >
                {tGeneral("takenOn")}
              </MenuItem>
              <MenuItem
                value="createdBy"
                onClick={() => handleAddFilter("uploadedBy")}
              >
                {tGeneral("uploadedBy")}
              </MenuItem>
            </Select>
          </FormControl>
          {selectedLocations !== null && (
            <SearchLocationFilter
              title={tGeneral("location")}
              selectedLocations={selectedLocations}
              onChange={(list) => {
                setSelectedLocations(list.length > 0 ? list : null);
              }}
              selectProps={{
                ml: 1,
                width: "200px",
              }}
            />
          )}
          {selectedUsers !== null && (
            <SearchUserFilter
              title={tGeneral("uploadedBy")}
              userList={users}
              selectedUsersList={users.filter(
                (user) =>
                  selectedUsers
                    .map((user) => user.id)
                    .findIndex((creatorUser) => creatorUser === user.id) > -1,
              )}
              selectedUsersFormatted={selectedUsers.map((user) => user.id)}
              handleUpdateUsersList={(list) => {
                setSelectedUsers(list.length > 0 ? list : null);
              }}
              selectProps={{
                ml: 1,
                width: "200px",
              }}
            />
          )}
          {/* Uploaded on */}
          {selectedDateRange !== null && (
            <DateRangeFilter
              onChange={(stringDates) => {
                setSelectedDateRange(stringDates);
              }}
              customIcon={<CloudUpload fontSize="small" />}
            />
          )}
          {/* Created on */}
          {selectedDateRangeCreated !== null && (
            <DateRangeFilter
              onChange={(stringDates) => {
                setSelectedDateRangeCreated(stringDates);
              }}
            />
          )}
        </Box>
        <Tabs
          value={groupBy}
          onChange={(_, newValue) => {
            setGroupBy(newValue);
            handleClearImageGallery();
          }}
        >
          <Tab value="day" label={tGeneral("day")} />
          <Tab value="week" label={tGeneral("week")} />
          <Tab value="month" label={tGeneral("month")} />
        </Tabs>
      </Box>
      <Divider />

      <PhotoGallery
        showSelectAll
        images={{ results: album?.imageLists }}
        selectedImages={selectedImages}
        selectedDates={selectedDates}
        setSelectedImages={handleSelectImage}
        setSelectedAllImages={handleSelectAllImages}
        hasFilters={selectedLocations !== null || selectedUsers !== null}
      />
      {isImageOpen && (
        <UploadPhoto
          isOpen={isImageOpen}
          onClose={() => setIsImageOpen(false)}
          album={idAlbum}
        />
      )}

      {isAlbumOpen && (
        <CreateAlbum
          isOpen={isAlbumOpen}
          onClose={() => setIsAlbumOpen(false)}
          currentAlbum={album}
        />
      )}

      <ModalWithAction
        isOpen={isDeleteOpen}
        onClose={onCloseDelete}
        title={tGeneral("delete")}
        content={tPhotos("deleteImagesContent")}
        actionLabel={tGeneral("delete")}
        isLoading={isDeleteLoading}
        action={handleDeleteImages}
      />
    </>
  );
  return (
    <Box width="100%" height="100%">
      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        renderAlbum()
      )}
      {Boolean(pdfUrl) && (
        <ModalFileViewer
          file={{ url: pdfUrl }}
          isOpen={Boolean(pdfUrl)}
          onClose={() => setPdfUrl(null)}
        />
      )}
    </Box>
  );
}

export { AlbumView };
