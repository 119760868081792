import { Apartment, Chat } from "@mui/icons-material";
import { Box, Divider, Typography } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import UserAvatar from "../../../../../components/userAvatars/UserAvatar";
import { useCommentObjectMutation } from "../../../../../features/message/messageApiSlice";
import { CommentReply } from "../CommentReply";

function CommentItem({ comment }) {
  return (
    <Box p={1}>
      <Box display="flex" gap={1} alignItems="center">
        <UserAvatar url={comment.user.urlAvatar} bActive={comment.bActive} />
        <Typography color="#4B4B4B">{`${comment.user.firstName} ${comment.user.lastName}`}</Typography>
      </Box>
      <Typography fontSize="14px" color="#4B4B4B" pt="4px">
        {comment.comment}
      </Typography>
    </Box>
  );
}

function SubcontractorDetailsTable({ detail }) {
  const { t: tDailyReports } = useTranslation("dailyReports");
  const { t: tGeneral } = useTranslation("general");

  return (
    <Box
      border="0.8px solid #E6B60C"
      borderRadius="0.5rem"
      sx={{ backgroundColor: "#FEFDE8" }}
      p="0.5rem 1rem"
      display="flex"
      flexDirection="column"
      rowGap="0.5rem"
    >
      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr">
        <Typography>{tGeneral("name")}</Typography>
        <Typography>{tDailyReports("job")}</Typography>
        <Typography>{tDailyReports("assistants")}</Typography>
        <Typography>{tDailyReports("workedHours")}</Typography>
      </Box>
      <Divider />
      {detail.map((d) => (
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr">
          <Typography>{d.assistants > 0 ? "-" : d.name}</Typography>
          <Typography>{d.jobType.name}</Typography>
          <Typography>{d.assistants > 0 ? d.assistants : "-"}</Typography>
          <Typography>{d.hours}</Typography>
        </Box>
      ))}
      <Divider />
      <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr">
        <Typography>{tGeneral("total")}</Typography>
        <Typography />
        <Typography>
          {detail.reduce((acc, m) => {
            return acc + (m.assistants > 0 ? m.assistants : 1);
          }, 0)}
        </Typography>
        <Typography>
          {detail.reduce((acc, m) => {
            return acc + (m.hours > 0 ? m.hours : 1);
          }, 0)}
        </Typography>
      </Box>
    </Box>
  );
}

function SubcontractorDetails({ manpower, showComments = true }) {
  const { t: tDailyReports } = useTranslation("dailyReports");
  const { t: tGeneral } = useTranslation("general");

  const [commentReport] = useCommentObjectMutation();

  const handleCommentReport = (comment) => {
    commentReport({
      idObject: manpower.id,
      comment,
      files: [],
      module: "dailyReports",
      objectName: "comment",
    });
  };

  return (
    <Box
      flex={3}
      p="0.5rem 1rem"
      display="flex"
      flexDirection="column"
      rowGap="0.5rem"
      overflow="hidden"
      sx={{ maxHeight: "40vh", overflowY: "auto" }}
    >
      <Box display="flex" py="0.5rem">
        <Apartment sx={{ color: "#E6B60C" }} />
        <Typography>{manpower.subcontractor.name}</Typography>
      </Box>
      <Typography>
        {tDailyReports("subcontractorMessage", {
          date: moment(manpower.createdDate).format("dddd D"),
          total: manpower.detail.reduce((acc, m) => {
            return acc + (m.assistants > 0 ? m.assistants : 1);
          }, 0),
        })}
      </Typography>

      <SubcontractorDetailsTable detail={manpower.detail} />

      {showComments && (
        <>
          <Typography
            fontSize="12px"
            fontWeight="400"
            color="#8E8E8E"
            p={1}
            sx={{
              textDecoration: "underline",
              display: "flex",
              alignItems: "center",
              gap: "2px",
            }}
          >
            <Chat
              sx={{
                color: "#E6B60C",
                fontSize: "16px",
              }}
            />{" "}
            {manpower.comments?.length ?? 0} {tGeneral("comments")}
          </Typography>

          <Divider sx={{ py: "4px" }} />

          <CommentReply
            placeholder={`${tGeneral("newComment")}`}
            reply={handleCommentReport}
          />

          <Divider />

          <Box pt={2}>
            {manpower.comments?.map((comment) => (
              <CommentItem key={comment.id} comment={comment.comment} />
            ))}
          </Box>
        </>
      )}
    </Box>
  );
}

export { SubcontractorDetails };
