import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectTheme } from "../../../features/preferences/preferencesSlice";

function TopicForm({ setTopic }) {
  const darkMode = useSelector(selectTheme);
  const [showForm, setShowForm] = useState(false);
  const [topicName, setTopicName] = useState("");
  const { t: tGeneral } = useTranslation("general");

  return (
    <Box>
      {!showForm ? (
        <Button
          startIcon={
            <Add
              fontSize="small"
              style={{ padding: 1, color: darkMode ? "white" : "#383838" }}
            />
          }
          sx={{
            color: darkMode ? "white" : "#383838",
            fontSize: "16px",
            textTransform: "none",
            borderRadius: 5,
          }}
          size="small"
          onClick={() => {
            setShowForm(true);
          }}
        >
          <Typography fontSize="14px">{tGeneral("add")}</Typography>
        </Button>
      ) : (
        <TextField
          fullWidth
          multiline
          autoFocus={showForm}
          value={topicName}
          onChange={(e) => setTopicName(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment sx={{ mb: 1, ml: 1 }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    if (topicName.length > 0) {
                      setShowForm(false);
                      setTopic(topicName);
                      setTopicName("");
                    }
                  }}
                >
                  {tGeneral("add").toUpperCase()}
                </Button>
              </InputAdornment>
            ),
          }}
          sx={{
            backgroundColor: darkMode ? "" : "white",
            borderRadius: 5,
            "& fieldset": { border: "none" },
          }}
        />
      )}
    </Box>
  );
}

export default TopicForm;
