import { Box } from "@mui/material";
import { CreateProjectDisciplines } from "./CreateProjectDisciplines";
import { CreateProjectCostCodes } from "./CreateProjectCostCodes";
import { CreateProjectSpecifications } from "./CreateProjectSpecifications";
import { CreateProjectLocations } from "./CreateProjectLocations";

function CreateProjectConstants() {
  return (
    <Box display="flex" flexDirection="column" rowGap="0.5rem">
      <Box>
        <CreateProjectDisciplines />
      </Box>
      <Box>
        <CreateProjectCostCodes />
      </Box>
      <Box>
        <CreateProjectSpecifications />
      </Box>
      <Box>
        <CreateProjectLocations />
      </Box>
    </Box>
  );
}

export { CreateProjectConstants };
