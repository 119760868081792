import { Business, Close, Email, Phone, Save } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useGetRolesFromEnterprisesQuery } from "../../../features/enterprise/enterpriseApiSlice";
import { selectCurrentEnterprise } from "../../../features/enterprise/enterpriseSlice";
import { useEditProjectDirectoryUserMutation } from "../../../features/project/modules/directory/projectDirectoryApiSlice";
import { useGetUserRoleQuery } from "../../../features/project/projectApiSlice";
import stringUtil from "../../../utils/string";
import { PopUpAlert } from "../../PopUpAlert";
import UserAvatar from "../../userAvatars/UserAvatar";
import DisplayPermissionsGrid from "./DisplayPermissionsGrid";
import { TooltipIconButton } from "./../../shared";

function EditProjectUserModal({ open, setOpen, user }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tProjectDirectory } = useTranslation("projectDirectory");
  const [selectedRole, setSelectedRole] = useState(null);
  const [showDeletedRoleMessage, setShowDeletedRoleMessage] = useState(false);
  const currentEnterprise = useSelector(selectCurrentEnterprise);

  const { data: enterpriseRoles } = useGetRolesFromEnterprisesQuery(
    currentEnterprise?.id,
  );
  const { data: userRole } = useGetUserRoleQuery(
    { idProject: user?.idProject, idUser: user?.id },
    {
      skip: user?.id === null,
    },
  );
  const [
    editUser,
    {
      isLoading: isEditLoading,
      isError: isEditError,
      isSuccess: isEditSuccess,
    },
  ] = useEditProjectDirectoryUserMutation();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      position: user?.position || "",
      role: userRole?.[0]?.role?.id || "",
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data) => {
    await editUser({
      idUser: user?.id,
      idEnterprise: currentEnterprise?.id,
      idProject: user?.idProject,
      role: data?.role,
      position: data?.position,
    });
  };

  useEffect(() => {
    if (userRole?.length > 0) {
      // Only select a default role if it's in the valid list
      const userRoleObject = userRole?.[0]?.role;
      const isUserRoleValid = enterpriseRoles
        ?.filter((role) => role.name?.toLowerCase() !== "unclassified")
        ?.some((role) => role.id === userRoleObject?.id);
      if (!isUserRoleValid) {
        // If the user's current role has been deleted, a message is displayed
        if (userRoleObject?.name?.toLowerCase() === "unclassified") {
          setShowDeletedRoleMessage(true);
        }
        return;
      }
      setValue("role", userRoleObject?.id);
      setSelectedRole(userRoleObject);
    }
  }, [userRole]);

  useEffect(() => {
    if (isEditSuccess) {
      PopUpAlert(
        "success",
        tGeneral("success"),
        tGeneral("userEditedSuccessfully"),
      );
      handleClose();
    } else if (isEditError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("cantEditUser"));
    }
  }, [isEditSuccess, isEditError]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableScrollLock
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "scroll",
      }}
    >
      <Card
        sx={(theme) => ({
          height: "auto",
          borderRadius: "20px",
          p: "25px 40px",
          width: "60%",
          [theme.breakpoints.down("lg")]: {
            width: "70%",
          },
          maxWidth: "1000px",
        })}
      >
        <Box display="flex" alignItems="center">
          <Typography sx={{ flex: 1 }} fontSize="1.5625rem" fontWeight="bold">
            {tProjectDirectory("editContact")}
          </Typography>
          <Box flex={1} />
          <IconButton onClick={handleClose}>
            <Close fontSize="1.5625rem" />
          </IconButton>
          <TooltipIconButton
            icon={<Save />}
            label={tGeneral("save")}
            disabled={isEditLoading || !isDirty}
            isLoading={isEditLoading}
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
          />
        </Box>
        <Divider sx={{ my: "20px" }} />

        {/* General Info */}
        <Box display="flex" alignItems="center" gap={2} flex={1} p={1} mb={2}>
          {/* Profile picture */}
          <UserAvatar
            sx={{ m: 10 }}
            name={`${stringUtil.capitalizeFirstLetter(
              user?.firstName?.trim(),
            )} ${stringUtil.capitalizeFirstLetter(user?.lastName?.trim())}`}
            size="Big"
            url={user?.urlAvatar}
          />
          {/* User Info */}
          <Box>
            {/* Name Header */}
            <Typography fontSize="1.5rem" fontWeight="bold" mb={1}>
              {`${stringUtil.capitalizeFirstLetter(
                user?.firstName,
              )} ${stringUtil.capitalizeFirstLetter(user?.lastName)}`}
            </Typography>
            {/* Info */}
            <Box display="flex" gap={2}>
              {/* Email */}
              <Typography
                color="rgba(32, 33, 36, 0.54)"
                sx={{ textDecoration: "underline" }}
              >
                <Email
                  sx={{
                    fontSize: "inherit",
                    verticalAlign: "middle",
                    marginRight: 1,
                  }}
                />
                {`${user?.email}`}
              </Typography>
              {/* Phone */}
              <Typography color="rgba(32, 33, 36, 0.54)">
                <Phone
                  sx={{
                    fontSize: "inherit",
                    verticalAlign: "middle",
                    marginRight: 1,
                  }}
                />
                {`${user?.phone}`}
              </Typography>
              {/* Company */}
              <Typography color="rgba(32, 33, 36, 0.54)">
                <Business
                  sx={{
                    fontSize: "inherit",
                    verticalAlign: "middle",
                    marginRight: 1,
                  }}
                />
                {`${stringUtil.capitalizeFirstLetter(user?.companyName)}`}
              </Typography>
            </Box>
          </Box>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" justifyContent="center" gap={10}>
            {/* Position */}
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              flex={1}
              data-tour="edit-user-2"
            >
              <Typography
                textAlign="center"
                fontSize="1.5rem"
                fontWeight="bold"
                mb={1}
              >
                {tGeneral("position")}
              </Typography>
              <Controller
                name="position"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      inputProps: { style: { textAlign: "center" } },
                    }}
                    sx={{ margin: "auto" }}
                  />
                )}
              />
            </Box>
            {/* Role */}
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              flex={1}
              data-tour="edit-user-3"
            >
              <Typography
                textAlign="center"
                fontSize="1.5rem"
                fontWeight="bold"
                mb={1}
              >
                {tGeneral("role")}
              </Typography>
              <Controller
                name="role"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    select
                    variant="outlined"
                    SelectProps={{
                      style: { textAlign: "center" },
                    }}
                    sx={{ margin: "auto" }}
                    onChange={(e) => {
                      const newRoleId = e.target.value;
                      const newRoleObject = enterpriseRoles.find(
                        (role) => role.id === newRoleId,
                      );
                      setValue("role", newRoleId, { shouldDirty: true });
                      setSelectedRole(newRoleObject);
                    }}
                  >
                    {enterpriseRoles?.length > 0 &&
                      enterpriseRoles.map((role) => {
                        if (role.name?.toLowerCase() !== "unclassified") {
                          return (
                            <MenuItem key={role.id} value={role.id}>
                              {role.name}
                            </MenuItem>
                          );
                        }
                        return null;
                      })}
                  </TextField>
                )}
              />
            </Box>
          </Box>
          {/* If the user's current role has been deleted, a message is displayed */}
          {showDeletedRoleMessage && (
            <Typography
              fontSize="0.7rem"
              textAlign="right"
              color="red"
              mt={0.5}
            >
              *{tProjectDirectory("deletedRoleMessage")}
            </Typography>
          )}

          <Typography
            textAlign="center"
            fontSize="1.5rem"
            fontWeight="bold"
            mb={1}
            mt={3}
          >
            {tProjectDirectory("permissions")}
          </Typography>

          <Box height="250px" overflow="hidden">
            <DisplayPermissionsGrid role={selectedRole} maxHeight="250px" />
          </Box>
        </form>
      </Card>
    </Modal>
  );
}

export { EditProjectUserModal };
