export function phoneValidator(input) {
  const regPhone = /^\+?1?\s*?\(?\d{3}(?:\)|[-|\s])?\s*?\d{3}[-|\s]?\d{3}$/;
  if (regPhone.test(input)) {
    return 1;
  }
  return -1;
}
/* All these should pass:
    (123) 456 7899
    (123).456.7899
    (123)-456-7899
    123-456-7899
    123 456 7899
    1234567899
  */
