const inspectionsTemplates = {
  add: "添加模板",
  edit: "编辑模板",
  guideMe: "引导我",
  inspection_template_recycle_bin: "回收站（检查模板）",
  list: "检查列表",
  meeting: "会议",
  no_inspections_templates_text:
    "如果您想添加一个，请点击“添加检查模板”按钮开始该过程",
  no_inspections_templates_title: "当前没有创建检查模板",
  title: "检查 - 模板",
  workflow: "工作流程",
  add_button_tour: "创建新的检查模板",
};

export { inspectionsTemplates };
