import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useMemo, useRef, useState } from "react";
import { FilterSelect } from "../shared";
import {
  useGetDisciplinesQuery,
  useGetSetsQuery,
} from "../../features/drawings";
import { Search } from "@mui/icons-material";
import { SearchBasicFilter } from "../shared/SearchBasicFilter";
import { DateRangeFilter } from "../shared/DateRangeFilter";

function DrawingsFilters({ onChange, filters }) {
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");

  const { disciplines, sets, createdOn } = filters;

  const { data: projectSets } = useGetSetsQuery(idProject);
  const { data: projectDisciplines } = useGetDisciplinesQuery(idProject);

  const [search, setSeatch] = useState("");
  const timerIdRef = useRef(null);

  const filterSets = useMemo(
    () =>
      projectSets
        ? projectSets.map((set) => ({ label: set.name, id: set.id }))
        : [],
    [projectSets],
  );
  const filterDisciplines = useMemo(
    () =>
      projectDisciplines
        ? projectDisciplines.map((discipline) => ({
            id: discipline.id,
            label: discipline.name,
          }))
        : [],
    [projectDisciplines],
  );

  const handleChange = (key, value) => {
    onChange({ ...filters, [key]: value });
  };

  const handleFilterChange = (e) => {
    const {
      target: { value },
    } = e;

    if (value === "discipline" && disciplines === null) {
      handleChange("disciplines", []);
    }
    if (value === "set" && sets === null) {
      handleChange("sets", []);
    }
    if (value === "createdOn" && createdOn === null) {
      handleChange("createdOn", "");
    }
  };

  const handleChangeSearch = (e) => {
    const {
      target: { value },
    } = e;
    setSeatch(value);
    clearTimeout(timerIdRef.current);
    timerIdRef.current = setTimeout(() => {
      handleChange("search", value);
    }, 500);
  };

  return (
    <Box display="flex" p="10px" columnGap="10px">
      <Box display="flex" columnGap="10px" flex={1}>
        <FormControl size="small" sx={{ width: "20%" }}>
          <InputLabel id="add-filter-select-label" shrink={false}>
            {tGeneral("addFilter")}
          </InputLabel>
          <Select
            labelId="add-filter-select-label"
            id="add-filter-select"
            value=""
            renderValue={() => null}
            onChange={handleFilterChange}
          >
            <MenuItem key="discpline" value="discipline">
              {tDrawings("discipline")}
            </MenuItem>
            <MenuItem key="set" value="set">
              Set
            </MenuItem>
            <MenuItem key="createdOn" value="createdOn">
              {tGeneral("createdOn")}
            </MenuItem>
          </Select>
        </FormControl>
        {disciplines !== null && (
          <SearchBasicFilter
            title={tDrawings("discipline")}
            itemList={filterDisciplines}
            selectedElementsList={disciplines}
            handleUpdateElementsList={(list) => {
              handleChange("disciplines", list.length > 0 ? list : null);
            }}
            selectProps={{
              ml: 1,
              width: "200px",
            }}
          />
        )}
        {sets !== null && (
          <SearchBasicFilter
            title="Sets"
            itemList={filterSets}
            selectedElementsList={sets}
            handleUpdateElementsList={(list) => {
              handleChange("sets", list.length > 0 ? list : null);
            }}
            selectProps={{
              ml: 1,
              width: "200px",
            }}
          />
        )}
        {createdOn !== null && (
          <DateRangeFilter
            onChange={(stringDates) => {
              handleChange("createdOn", stringDates);
            }}
          />
        )}
      </Box>
      <TextField
        size="small"
        value={search}
        onChange={handleChangeSearch}
        placeholder={tGeneral("search")}
        InputProps={{ startAdornment: <Search /> }}
      />
    </Box>
  );
}

export { DrawingsFilters };
