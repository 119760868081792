import { Add, AttachFile, CheckBox, Remove } from "@mui/icons-material";
import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FileMenu } from "./FileMenu";
import { Checklist } from "./Checklist";
import { v4 } from "uuid";

const GRID_COLUMNS = "1fr 2fr 1fr 1fr 1fr 1fr 1fr";

function DisciplineFormTableElement({ disciplineIndex, index }) {
  const { t: tGeneral } = useTranslation("general");
  const { register, watch, setValue } = useFormContext();

  const [checklistOpen, setChecklistOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const elements = watch(`disciplines[${disciplineIndex}].elements`);

  const files = watch(
    `disciplines[${disciplineIndex}].elements[${index}].files`,
  );
  const checklist = watch(
    `disciplines[${disciplineIndex}].elements[${index}].checklist`,
  );

  return (
    <Box
      display="grid"
      gridTemplateColumns={GRID_COLUMNS}
      columnGap="0.5rem"
      p="0.5rem"
      component="div"
      position="relative"
    >
      <TextField
        {...register(
          `disciplines[${disciplineIndex}].elements[${index}].idAux`,
        )}
        size="small"
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          backgroundColor: "white",
          borderRadius: "0.5rem",
        }}
        placeholder="ID"
      />
      <TextField
        {...register(`disciplines[${disciplineIndex}].elements[${index}].name`)}
        size="small"
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          backgroundColor: "white",
          borderRadius: "0.5rem",
        }}
        placeholder="Ej: Plano estructural"
      />
      <TextField
        {...register(
          `disciplines[${disciplineIndex}].elements[${index}].starts`,
        )}
        size="small"
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          backgroundColor: "white",
          borderRadius: "0.5rem",
        }}
        type="number"
        InputProps={{
          endAdornment: <Typography>{tGeneral("weeks")}</Typography>,
        }}
      />
      <TextField
        {...register(
          `disciplines[${disciplineIndex}].elements[${index}].value`,
        )}
        size="small"
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          backgroundColor: "white",
          borderRadius: "0.5rem",
        }}
        InputProps={{
          endAdornment: <Typography>%</Typography>,
        }}
      />
      <Box display="flex" alignItems="center" sx={{ backgroundColor: "white" }}>
        <Button
          size="small"
          sx={{ textTransform: "none", color: "#8E8E8E" }}
          fullWidth
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <AttachFile />
          {`${files?.length > 0 ? files?.length.toString() : ""} ${tGeneral(
            "file",
          )}`}
        </Button>
        <FileMenu
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          files={files}
          onChange={(newFiles) => {
            setValue(
              `disciplines[${disciplineIndex}].elements[${index}].files`,
              newFiles,
            );
          }}
        />
      </Box>
      <Box display="flex" alignItems="center" sx={{ backgroundColor: "white" }}>
        <Button
          size="small"
          sx={{ textTransform: "none", color: "#8E8E8E" }}
          fullWidth
          onClick={() => setChecklistOpen(true)}
        >
          <CheckBox />
          {`${
            checklist.items?.length > 0
              ? checklist.items?.length.toString()
              : ""
          } ${tGeneral("items")}`}
        </Button>
      </Box>
      <Select
        size="small"
        {...register(
          `disciplines[${disciplineIndex}].elements[${index}].parent`,
        )}
      >
        {elements
          .filter((_, i) => i !== index)
          .map((element) => (
            <MenuItem key={element.id} value={element.id}>
              {element.name}
            </MenuItem>
          ))}
      </Select>
      <Checklist
        disciplineIndex={disciplineIndex}
        elementIndex={index}
        isOpen={checklistOpen}
        onClose={() => setChecklistOpen(false)}
      />
    </Box>
  );
}

function DisciplineFormTableHeader() {
  const { t: tGeneral } = useTranslation("general");
  return (
    <Box
      display="grid"
      gridTemplateColumns={GRID_COLUMNS}
      sx={{ backgroundColor: "#EEE" }}
      columnGap="0.5rem"
      px="0.5rem"
    >
      <Typography>ID</Typography>
      <Typography>{tGeneral("name")}</Typography>
      <Typography>{tGeneral("startsAt")}</Typography>
      <Typography>{tGeneral("weighting")}</Typography>
      <Typography>{tGeneral("document")}</Typography>
      <Typography>{tGeneral("checklist")}</Typography>
      <Typography>{tGeneral("dependsOn")}</Typography>
    </Box>
  );
}

function DisciplineFormTableFooter({ activities, weeks, value, files }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tTemplates } = useTranslation("templates");
  return (
    <Box
      display="grid"
      gridTemplateColumns={GRID_COLUMNS}
      sx={{ backgroundColor: "#EEE" }}
      px="0.5rem"
    >
      <Typography>{tGeneral("calculate")}</Typography>
      <Typography textAlign="center">{`${activities} ${tGeneral(
        "activities",
      )}`}</Typography>
      <Typography textAlign="center">{`${weeks} ${tGeneral(
        "weeks",
      )}`}</Typography>
      <Typography textAlign="center">{`${value}%`}</Typography>
      <Typography textAlign="center">{`${files} ${tGeneral(
        "files",
      )} `}</Typography>
      <Box />
      <Box />
    </Box>
  );
}

function DisciplineFormTable({ index }) {
  const { control } = useFormContext();
  const { fields, append } = useFieldArray({
    control,
    name: `disciplines[${index}].elements`,
  });

  const [showAdd, setShowAdd] = useState(false);

  const { activities, weeks, value, files } = useMemo(() => {
    const activities = fields.length;
    let weeks = 0;
    let value = 0;
    let files = 0;
    fields.forEach((field) => {
      weeks += Number(field.starts);
      value += Number(field.value);
      files += field.files?.length ?? 0;
    });
    return { activities, weeks, value, files };
  }, [fields]);

  const handleAdd = () => {
    append({
      id: "",
      idAux: "",
      name: "",
      startsAt: 0,
      value: 0,
      files: [],
      checklist: {
        id: v4(),
        category: "required",
        createdDate: new Date(),
        items: [],
      },
      parent: null,
    });
  };

  return (
    <Box
      sx={{ backgroundColor: "#F5F5F5" }}
      component="div"
      onMouseEnter={() => setShowAdd(true)}
      onMouseLeave={() => setShowAdd(false)}
    >
      <DisciplineFormTableHeader />
      <Box maxHeight="200px" overflow="auto">
        {fields.map(({ id }, i) => (
          <DisciplineFormTableElement
            disciplineIndex={index}
            index={i}
            key={id}
          />
        ))}
      </Box>
      {showAdd && (
        <Button fullWidth onClick={handleAdd}>
          <Add />
        </Button>
      )}
      <DisciplineFormTableFooter
        activities={activities}
        files={files}
        value={value}
        weeks={weeks}
      />
    </Box>
  );
}

export { DisciplineFormTable };
