import { tour } from "./tour";

export const dailyReports = {
  ...tour,
  moduleElement: "Reports",
  dailyReports: "每日报告",
  reports: "报告",
  emptyReportsTitle: "此刻没有创建报告",
  emptyReportsContent:
    "如果您想添加一个报告，我们邀请您点击添加报告按钮开始该过程",
  emptyInactiveReportsTitle: "回收站中没有报告",
  emptyInactiveReportsContent: "一旦您删除报告，它将显示在这里",
  addReport: "添加报告",
  addDailyReport: "添加每日报告",
  editReport: "编辑报告",
  dueDate: "截止日期",
  newReport: "新报告",
  creators: "创建者",
  assigned: "已分配",
  distributionList: "分发列表",
  private: "私有",
  num: "编号",
  title: "标题",
  status: "状态",
  closedDate: "关闭日期",
  actions: "操作",
  detail: "详情",
  exportPdf: "导出 PDF",
  exportCvs: "导出 CVS",
  fetchReportIssue: "加载报告时出现问题",
  retry: "重试",
  createdDate: "创建日期",
  atHour: "于",
  lastSeen: "上次查看",
  lastEdit: "上次编辑",
  approveReport: "批准报告",
  dailyReportsRecycleBin: "回收站（报告）",
  confirmDelete: "您确定要删除所选的每日报告吗？执行此操作后，无法撤消",
  successRecovered: "成功恢复每日报告",
  errorRecovered: "无法恢复每日报告",
  successDeleted: "成功删除每日报告",
  errorDeleted: "无法删除每日报告",
  approvedReport: "成功批准报告",
  errorApprovedReport: "无法批准报告",
  deleteReport: "删除报告",
  report: "报告",
  addDescription: "添加描述",
  // other
  obligatory: "必填",
  public: "公开",
  congratulations: "恭喜！",
  rfi_title_module: "RFIs",
  no_rfis_title: "此刻没有创建RFI",
  no_rfis_text: "如果您想添加一个RFI，我们邀请您点击添加RFI按钮开始该过程",
  meeting: "会议",
  workflow: "工作流程",
  guideMe: "引导",
  support: "支持",
  days: "天",
  rfi_recycle_bin: "RFI回收站",
  // add steps
  step1_addDR_title: "1. 添加报告",
  step1_addDR_text: "点击此处添加新报告",
  step2_addDR_title: "2. 标题",
  step2_addDR_text: "编写一个能描述您的新报告的标题",
  step3_addDR_title: "3. 截止日期",
  step3_addDR_text: "选择一个截止日期，以便从指定人员那里获得批准",
  step4_addDR_title: "4. 状态",
  step4_addDR_text1_1: "选择状态",
  step4_addDR_text1_2: "选择“开放”以将报告发布为活动状态。",
  step4_addDR_text2_1: "选择",
  step4_addDR_text2_2: "“草稿”状态以保留未发布的报告。",
  step5_addDR_title: "5. 隐私",
  step5_addDR_text_1: "选择报告的隐私类型",
  step5_addDR_text_2: "任何有权访问模块的人都可以看到您的报告",
  step5_addDR_text_3: "只有参与者才能看到此报告",
  step6_addDR_title: "6. 创建者",
  step6_addDR_text: "选择与您具有相同权限的用户",
  step7_addDR_title: "7. 分配人员",
  step7_addDR_text: "选择将批准报告的人员",
  step8_addDR_title: "8. 分发列表",
  step8_addDR_text: "选择要通知此报告的人员",
  step9_addDR_title: "9. 类别",
  step9_addDR_text1_1: "选择类别",
  step9_addDR_text1_2: "基本类别用于创建多个报告。",
  step9_addDR_text2: "工作进展类别用于创建具有进展和成本度量的报告。",
  step9_addDR_text3: "问题类别用于创建一个报告。",
  step9_addDR_text4: "延迟类别用于创建具有延迟天数和小时数的报告。",
  step10_addDR_title: "10. 添加新报告",
  step10_addDR_text: "点击此处开始新报告",
  step11_addDR_title: "11. 选择位置",
  step11_addDR_text: "点击此处选择或添加要报告的位置",
  step12_addDR_title: "12. 描述",
  step12_addDR_text: "编写工作进展的描述",
  step13_addDR_title: "13. 附加照片或文件",
  step13_addDR_text: "向描述中添加照片或文件",
  step14_addDR_title: "14. 添加",
  step14_addDR_text: "点击此处发布报告",
  stepFinal_addDR: "您已学会如何创建报告",
  // VIEW - guide me steps
  step1_viewDR_title: "1. 查看报告",
  step1_viewDR_text: "点击此处查看报告",
  step2_viewDR_title: "2. 类别",
  step2_viewDR_text: "查看报告所属的类别",
  step3_viewDR_title: "3. 报告概要信息",
  step3_viewDR_text: "显示此报告的完整信息",
  step4_viewDR_title: "{{stepNumber}}. 按位置查看报告描述",
  step4_viewDR_text: "显示此报告的完整信息和照片",
  step5_viewDR_title: "{{stepNumber}}. 评论",
  step5_viewDR_text: "您可以对报告的位置进行评论",
  step7_viewDR_title: "{{stepNumber}}. 批准",
  step7_viewDR_text: "批准报告",
  stepFinal_viewDR: "您已学会如何查看报告",
  step_view_reports_description_title: "{{stepNumber}}. 描述",
  step_view_reports_description_text: "查看报告的完整描述",
  step_view_report_progress_title: "{{stepNumber}}. 延迟和成本",
  step_view_report_progress_text: "观察报告的进展和成本度量",
  // EDIT - guide me steps
  step1_editDR_title: "1. 编辑报告",
  step1_editDR_text: "点击此处编辑报告",
  step9_editDR_title: "{{stepNumber}}. 添加新报告",
  step9_editDR_text: "点击此处开始新报告",
  step10_editDR_title: "{{stepNumber}}. 编辑已创建的报告",
  step10_editDR_text: "您可以查看和编辑报告的信息",
  step11_editDR_title: "{{stepNumber}}. 文件和信息",
  step11_editDR_text: "编辑或添加已创建的报告的文件",
  step13_editDR_title: "{{stepNumber}}. 保存",
  step13_editDR_text: "点击此处保存修改后的报告",
  stepFinal_editDR: "您已学会如何编辑报告",
  step11_edit_report_progress_title: "{{stepNumber}}. 进展和成本",
  step11_edit_report_progress_text: "编辑或添加报告的进展和成本度量",
  step10_edit_report_problem: "修改报告描述和文件",
  step9_edit_report_delay_title: "9. 延迟",
  step9_edit_report_delay_text: "编辑报告的延迟天数或小时数",
  step10_edit_report_delay: "修改报告描述和文件",
  // RECYCLE - guide me steps
  step1_recycleDR_title: "1. 报告回收站",
  step1_recycleDR_text: "点击此处打开回收站",
  step2_recycleDR_title: "2. 回收站菜单",
  step2_recycleDR_text: "这是回收站的菜单",
  step3_recycleDR_title: "3. 查看报告",
  step3_recycleDR_text: "您可以查看完整的报告",
  step4_recycleDR_title: "4. 恢复报告",
  step4_recycleDR_text: "选择一个或多个报告进行恢复",
  step5_recycleDR_title: "5. 恢复所选报告",
  step5_recycleDR_text: "点击此处恢复所选报告",
  stepFinal_recycleDR: "您已学会如何与回收站交互",
  // EXPORT - guide me steps
  step1_exportDR_title: "1. 导出 PDF",
  step1_exportDR_text: "点击此处以 PDF 格式导出报告",
  step2_exportDR_title: "2. 发送电子邮件",
  step2_exportDR_text: "点击此处通过电子邮件共享此报告",
  step3_exportDR_title: "3. 导出 CSV",
  step3_exportDR_text: "选择一些或筛选所有报告以导出为 CSV 文件",
  step4_exportDR_title: "4. 导出 PDF",
  step4_exportDR_text: "选择一些或筛选所有报告以导出为 PDF 文件",
  step5_exportDR_title: "5. 报告内部屏幕",
  step5_exportDR_text: "您可以从详细视图中导出为 PDF",
  stepFinal_exportDR: "您已学会如何导出报告",
  // SETTINGS - guide me steps
  step1_settingsDR_title: "2. 报告设置",
  step1_settingsDR_text: "点击此处修改设置",
  step2_settingsDR_title: "3. 分发列表",
  step2_settingsDR_text: "全局修改属于分发列表的用户",
  step3_settingsDR_title: "4. 保存设置",
  step3_settingsDR_text: "点击此处保存设置",
  stepFinal_settingsDR: "您已学会如何与报告设置交互",
  due: "截止日期",
  closed: "已关闭",
  basic: "基本",
  workProgress: "工作进展",
  problem: "问题",
  delay: "延迟",
  advance: "进展",
  unit: "单位",
  addUnit: "添加单位",
  unitAdded: "单位添加成功",
  risk: "风险",
  highRisk: "高风险",
  mediumRisk: "中风险",
  lowRisk: "低风险",
  workerName: "工人姓名",
  jobType: "工作类型",
  workedHours: "工作时间",
  assistants: "助手",
  manpower: "人力",
  subcontractorMessage: "今天{{date}}共有{{total}}人出席。请检查。",
  job: "工作",
  createReport: "创建报告",
  generalChart: "通用图表",
  approvedCorrectly: "报告已成功批准",
  continueNavigate: "您可以继续浏览",
  approvedAtAndBy: "于 {{date}} 批准",
};
