import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
// MUI
import { Box, Typography } from "@mui/material";
// Icons
import {
  Bolt,
  Info,
  InsertDriveFile,
  ShowChart,
  Update,
} from "@mui/icons-material";

// Custom imports
import { PopUpAlert } from "../../../components/PopUpAlert";

import { TitleWidgets } from "../../../components/shared";
import { DISTRIBUTION_LIST_NAMES } from "../../../constants";
import {
  useDeleteReportsMutation,
  useGetDailyReportsQuery,
} from "../../../features/project/modules/dailyReports/dailyReportsApiSlice";
import DeleteModal from "./components/Modals/DeleteModal";

import { ModalFileViewer } from "../../../components/shared/ModalFileViewer";
import { useAppTourContext } from "../../../components/supportButton/context/context";
import { selectCurrentGuideMe } from "../../../features/project/projectSlice";
import CaslContext from "../../../utils/caslContext";
import { CategoryAccordion } from "./components/CategoryAccordion";
import { ItemsSection } from "./components/ItemsSection";
import noDailyReportsCreated from "../../../assets/NoDailyReportsCreated.png";

const categoryOptions = [
  {
    label: "basic",
    icon: <InsertDriveFile sx={{ color: "#E6B60C" }} />,
    category: 1,
    color: "#CACACA",
  },
  {
    label: "workProgress",
    icon: <ShowChart sx={{ color: "#E6B60C" }} />,
    category: 2,
    color: "#5571FF",
  },
  {
    label: "problem",
    icon: <Info sx={{ color: "#E6B60C" }} />,
    category: 3,
    color: "#FF7300",
  },
  {
    label: "delay",
    icon: <Update sx={{ color: "#E6B60C" }} />,
    category: 4,
    color: "#CB6CBE",
  },
  {
    label: "manpower",
    icon: <Bolt sx={{ color: "#E6B60C" }} />,
    category: 5,
    color: "#5271FF",
  },
];

function NoDailyReportsCreated() {
  const { t: tDailyReports } = useTranslation("dailyReports");

  return (
    <Box
      sx={{
        backgroundColor: "#FAFAFA",
        width: "100%",
        height: "90vh",
        borderRadius: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img src={noDailyReportsCreated} alt="noDailyReports" />
      <Typography fontSize="34px" fontWeight="700">
        {tDailyReports("noDailyReportCreated")}
      </Typography>
      <Typography fontSize="16px" fontWeight="400">
        {tDailyReports("clickCreateReportToStart")}
      </Typography>
    </Box>
  );
}

function DailyReports() {
  const storageFilters = localStorage.getItem("reportsFilters");

  // Local state
  const [filters, setFilters] = useState(
    JSON.parse(storageFilters) || { category: [] },
  );
  const [selectedReports, setSelectedReports] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // Selectors
  const { idProject } = useParams();
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const { data: reportsData, isLoading } = useGetDailyReportsQuery(
    {
      ...filters,
      idProject,
    },
    { skip: !idProject, refetchOnMountOrArgChange: true },
  );
  const { t: tGeneral } = useTranslation("general");
  const { t: tDailyReports } = useTranslation("dailyReports");
  const {
    state: { tourActive, stepIndex, run },
    setState,
  } = useAppTourContext();

  const ability = useContext(CaslContext);

  // Handle multiple row delete
  const [
    deleteReports,
    {
      isLoading: isDeleteReportsLoading,
      isSuccess: isDeleteReportsSuccess,
      isError: isDeleteReportsError,
    },
  ] = useDeleteReportsMutation();
  useEffect(() => {
    if (isDeleteReportsSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        `${tDailyReports("reports")}(s) ${tGeneral("deletedSuccessfully")}`,
      );
      setDeleteModalOpen(false);
    } else if (isDeleteReportsError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      setDeleteModalOpen(false);
    }
  }, [isDeleteReportsSuccess, isDeleteReportsError, tDailyReports, tGeneral]);
  const handleCloseDelete = () => {
    setDeleteModalOpen(false);
  };
  const handleMultiDelete = async () => {
    deleteReports({ ids: Object.keys(selectedReports) });
  };

  const [pdfUrl, setPdfUrl] = React.useState(null);

  const handleSetFilters = useCallback(
    (val) => {
      setFilters(val);
      localStorage.setItem("reportsFilters", JSON.stringify(val));
    },
    [setFilters],
  );

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 0 &&
      (currentGuideMe === "daily-reports-add" ||
        currentGuideMe === "daily-reports-view" ||
        currentGuideMe === "daily-reports-edit" ||
        currentGuideMe === "reports-edit-work-progress" ||
        currentGuideMe === "reports-edit-problem" ||
        currentGuideMe === "reports-edit-delay" ||
        currentGuideMe === "reports-view-work-progress" ||
        currentGuideMe === "reports-view-problem" ||
        currentGuideMe === "reports-view-delay") &&
      !run
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, run, currentGuideMe, setState]);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <TitleWidgets
        title={tDailyReports("reports")}
        backEnabled={false}
        moduleName="dailyReports"
        distributionListName={DISTRIBUTION_LIST_NAMES.dailyReports}
        showDelete={
          Object.keys(selectedReports).length > 0 &&
          ability.can("delete", "dailyReports")
        }
        onDelete={() => setDeleteModalOpen(true)}
        enableSupport
        addNameTour="add-dailyReport-1"
        enableShared
        showExports={false}
        showAddButton
        customAddLabel={tDailyReports("createReport")}
        showSettings
        customRestoreLabel={tDailyReports("dailyReportsRecycleBin")}
      />
      <Box
        pt="1rem"
        px="1rem"
        sx={{ backgroundColor: "#EEE", height: "90vh", overflow: "hidden" }}
      >
        <ItemsSection
          filters={filters}
          setFilter={handleSetFilters}
          reports={reportsData}
        />
        <Box
          display="flex"
          flexDirection="column"
          rowGap="1rem"
          sx={{ overflowY: "auto", maxHeight: "80%" }}
        >
          {reportsData && !reportsData.length && <NoDailyReportsCreated />}
          {reportsData &&
            reportsData.length &&
            categoryOptions
              .filter(
                ({ category }) =>
                  filters.category?.indexOf(category) > -1 ||
                  filters.category?.length === 0,
              )
              .map(({ color, icon, label, category }, i) => {
                const currentReports =
                  reportsData?.filter((r) => r.category === category) || [];
                return (
                  <CategoryAccordion
                    key={`report-${category}`}
                    reports={currentReports}
                    dataTour={i === 0 ? "view-dailyReport-0" : ""}
                    color={color}
                    filters={filters}
                    icon={icon}
                    title={
                      label === "all" ? tGeneral("all") : tDailyReports(label)
                    }
                    category={category}
                    isLoading={isLoading}
                    selectedReports={selectedReports}
                    setSelectedReports={setSelectedReports}
                    searchActive={!!filters.search}
                  />
                );
              })}
        </Box>
      </Box>
      <DeleteModal
        isOpen={deleteModalOpen}
        onClose={handleCloseDelete}
        handleDelete={handleMultiDelete}
        action="multi"
        isLoading={isDeleteReportsLoading}
      />
      {Boolean(pdfUrl) && (
        <ModalFileViewer
          file={{ url: pdfUrl }}
          isOpen={Boolean(pdfUrl)}
          onClose={() => setPdfUrl(null)}
        />
      )}
    </Box>
  );
}

export default DailyReports;
