import { Close, Save } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { CompanySelector } from "./CompanySelector";
import { IOSSwitch } from "./IOSSwitch";
import { TooltipIconButton } from "./TooltipIconButton";

function ManagePermissionsModal({
  isOpen,
  isLoading,
  isPrivate,
  currentUsers,
  currentCompanies,
  onClose,
  onSave,
}) {
  const { idProject, idEnterprise } = useParams();

  const { t: tGeneral } = useTranslation("general");

  const [privacy, setPrivacy] = useState(isPrivate);
  const [selectedUsers, setSelectedUsers] = useState(currentUsers);
  const [selectedCompanies, setSelectedCompanies] = useState(currentCompanies);

  const handleClose = () => {
    setSelectedUsers([]);
    setSelectedCompanies([]);
    onClose();
  };

  useEffect(() => {
    setPrivacy(isPrivate);
    setSelectedUsers(currentUsers);
    setSelectedCompanies(currentCompanies);
  }, [isPrivate, currentCompanies, currentUsers]);

  const handleSave = () => {
    onSave({
      bPrivate: privacy,
      allowedCompanies: privacy ? selectedCompanies : [],
      allowedUsers: privacy ? selectedUsers : [],
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          width: "35%",
          height: "auto",
          p: "20px 40px",
          borderRadius: "20px",
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography fontSize="1.563rem" fontWeight="bold">
            {tGeneral("managePermissions")}
          </Typography>
          <Box flex={1} />
          <TooltipIconButton
            label={tGeneral("close")}
            color="gray"
            icon={<Close />}
            onClick={handleClose}
          />
          <TooltipIconButton
            label={tGeneral("save")}
            icon={<Save />}
            onClick={handleSave}
            isLoading={isLoading}
          />
        </Box>
        <Divider sx={{ my: "30px" }} />
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography>{tGeneral("privacy")}</Typography>
          <IOSSwitch
            checked={privacy}
            value={privacy}
            onChange={(e) => setPrivacy(e.target.checked)}
          />
        </Box>
        {privacy && (
          <CompanySelector
            idProject={idProject}
            idEnterprise={idEnterprise}
            multiple
            selectedCompanies={selectedCompanies}
            selectedUsers={selectedUsers}
            onChangeCompanies={(companies) => setSelectedCompanies(companies)}
            onChangeUsers={(users) => setSelectedUsers(users)}
          />
        )}
      </Card>
    </Modal>
  );
}

export { ManagePermissionsModal };
