import { Box, Button, Divider, Radio, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PlanCard } from "./PlanCard";
import { useGetProductsQuery } from "../../../features/stripe";

function Plan({
  onButtonClick,
  onBackClick,
  isSettings = false,
  selected = "",
  objectLimit = null,
  period = "annual",
}) {
  const { t: tPlan } = useTranslation("plan");
  const { t: tGeneral } = useTranslation("general");
  const [selectedPeriod, setSelectedPeriod] = useState(period);
  const [selectedPlan, setSelectedPlan] = useState(selected);
  const [activeObjects, setActiveObjects] = useState();
  const { data: products } = useGetProductsQuery();

  const handleSelect = (id, activeObjects) => {
    if (id !== null) {
      let newId = id;
      if (selectedPlan === id) {
        newId = "";
      }
      setSelectedPlan(newId);
    }
    setActiveObjects(activeObjects);
  };

  const handleSelectPlan = () => {
    const plan = products[selectedPeriod].find((p) => p.id === selectedPlan);
    onButtonClick({ ...plan, activeObjects });
  };

  return (
    <Box>
      <Box display="flex" columnGap="20px" justifyContent="center" mb="38px">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "270px",
            height: "60px",
            borderRadius: "8px",
            border: "1px solid ",
            borderColor: selectedPeriod === "monthly" ? "#FFC42D" : "#CFCFCF",
            cursor: "pointer",
          }}
          component="div"
          onClick={() =>
            setSelectedPeriod((prev) => (prev === "annual" ? "monthly" : prev))
          }
        >
          <Radio checked={selectedPeriod === "monthly"} />
          <Typography
            sx={{ color: selectedPeriod === "monthly" ? "#FFC42D" : "#000" }}
            fontWeight="500"
          >
            {tPlan("monthly")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "270px",
            height: "60px",
            borderRadius: "8px",
            border: "1px solid ",
            borderColor: selectedPeriod === "annual" ? "#FFC42D" : "#CFCFCF",
            cursor: "pointer",
          }}
          component="div"
          onClick={() =>
            setSelectedPeriod((prev) => (prev === "monthly" ? "annual" : prev))
          }
        >
          <Radio
            checked={selectedPeriod === "annual"}
            onChange={(e) =>
              setSelectedPeriod((prev) => (e.target.checked ? "annual" : prev))
            }
          />
          <Typography
            sx={{ color: selectedPeriod === "annual" ? "#FFC42D" : "#000" }}
            fontWeight="500"
          >
            {tPlan("annual")}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" columnGap="20px">
        {products &&
          products[selectedPeriod].map((plan) => (
            <PlanCard
              key={plan.id}
              objectLimit={selected === plan.id ? objectLimit : null}
              plan={plan}
              period={selectedPeriod}
              onSelect={handleSelect}
              isSelected={selectedPlan === plan.id}
            />
          ))}
      </Box>
      <Divider sx={{ my: "32px" }} />
      <Box display="flex" justifyContent="flex-end" columnGap="16px">
        {/* cancel button */}
        <Button
          variant="contained"
          sx={{
            p: "16px 100px",
            backgroundColor: "white",
            color: "black",
            border: "1px solid black",
            "&:hover": {
              backgroundColor: "gray",
            },
          }}
          // disabled={isUpdateProjectLoading}
          onClick={onBackClick}
        >
          <Typography fontWeight="medium" fontSize="1rem">
            {tGeneral(isSettings ? "cancel" : "back")}
          </Typography>
        </Button>

        {/* Save Button */}
        <Button
          variant="contained"
          sx={{ p: "16px 100px" }}
          onClick={handleSelectPlan}
          disabled={selectedPlan === ""}
        >
          <Typography fontWeight="medium" fontSize="1rem">
            {tGeneral(isSettings ? "save" : "next")}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
export { Plan };
