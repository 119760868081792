import { apiSlice } from "../../api/apiSlice";

export const notificationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // get user
    getUser: builder.query({
      query: () => "/user/get",
      keepUnusedDataFor: 60,
      providesTags: ["Projects"],
    }),
    getNotificationValues: builder.query({
      query: (idUser) => `/user/${idUser}/notifications`,
      keepUnusedDataFor: 60,
      providesTags: ["Projects"],
    }),

    // update Enterprise's information
    updateNotificationValues: builder.mutation({
      query: (body) => ({
        url: "/user/updateNotifications",
        method: "PATCH",
        body,
      }),
      providesTags: ["Projects"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetNotificationValuesQuery,
  useUpdateNotificationValuesMutation,
} = notificationsApiSlice;
