import React, { useState, useEffect, forwardRef } from "react";
// React router
import { useParams } from "react-router-dom";

import { useSelector } from "react-redux";

import {
  TextField,
  Button,
  IconButton,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ButtonGroup,
  Typography,
} from "@mui/material";

// Icons
import {
  AddBox,
  Close,
  Clear,
  DeleteOutline,
  ChevronRight,
  Edit,
  FirstPage,
  LastPage,
  ChevronLeft,
  Search,
  Delete,
} from "@mui/icons-material";
import { Add } from "@mui/icons-material";

import MaterialTable from "material-table";

import {
  useGetSpecificationsQuery,
  useAddSpecificationMutation,
  useUpdateSpecificationMutation,
  useDeleteSpecificationMutation,
} from "../../features/project/specificationApiSlice";
import { selectTheme } from "../../features/preferences/preferencesSlice";

import { PopUpAlert } from "../../components/PopUpAlert";
import DeleteImg from "../../assets/icons/delete.png";

import Can from "../../utils/can";
import { selectCurrentRole } from "../../features/project/projectSlice";

import { useTranslation } from "react-i18next";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
};

const SpecificationsPanel = () => {
  const { t: tGeneral } = useTranslation("general");

  const darkMode = useSelector(selectTheme);
  const currentRole = useSelector(selectCurrentRole);
  const { idProject } = useParams();
  const { data: projectSpecifications } = useGetSpecificationsQuery(idProject);
  const [newSpecification] = useAddSpecificationMutation();
  const [updateSpecification] = useUpdateSpecificationMutation();
  const [deleteSpecification] = useDeleteSpecificationMutation();

  const [dataSpecification, setDataSpecification] = useState([]);
  const [number, setNumber] = useState("");
  const [numberError, setNumberError] = useState(false);
  const [numberHelperText, setNumberHelperText] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");
  const [currentIdSpecification, setCurrentIdSpecification] = useState("");
  const [openSpecification, setOpenSpecification] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const styles = {
    columnNumHeader: {
      textAlign: "left",
      width: "20%",
      maxWidth: "20%",
      fontWeight: "bold",
      fontSize: "1rem",
    },
    columnNumCell: {
      fontSize: "1rem ",
      textAlign: "left",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "20%",
      maxWidth: "20%",
    },
    columnNameHeader: {
      textAlign: "left",
      width: "100%",
      maxWidth: "100%",
      fontWeight: "bold",
      fontSize: "1rem",
    },
    columnNameCell: {
      fontSize: "1rem",
      textAlign: "left",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
      maxWidth: "100%",
    },
    columnActionHeader: {
      textAlign: "center",
      width: "10%",
      maxWidth: "10%",
      fontWeight: "bold",
      fontSize: "1rem",
    },
    columnActionCell: {
      fontSize: 12,
      textAlign: "center",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "10%",
      maxWidth: "10%",
    },
    iconButton: {
      color: darkMode ? "white" : "black",
      ":hover": {
        color: "#FBCB04",
      },
      m: [0, 0, 0, 0],
    },
    boxStyle: {
      height: "100%",
      width: "100%",
      maxHeight: "90vh",
    },
    boxButton: {
      backgroundColor: "#ffc900",
      padding: 0.5,
      paddingX: 0.7,
      borderRadius: 1,
    },
    addButton: {
      padding: [0.4, 0, 0.4, 0],
      marginBottom: -0.5,
      color: "black",
    },
    dialogTitle: {
      textAlign: "left",
      height: "60px",
      fontWeight: "bold",
    },
    iconButtonClose: {
      position: "absolute",
      right: 8,
      top: 8,
    },
    textField: {
      mx: "auto",
      width: "100%",
      mb: 1,
    },
    cancelButton: {
      width: "50%",
      color: "black",
      backgroundColor: "white",
      borderColor: "black",
      border: 1,
      ":hover": {
        color: "white",
        backgroundColor: "#b4b4b4",
      },
    },
    saveButton: {
      width: "50%",
      color: "black",
      backgroundColor: "#ffc900",
      ":hover": {
        color: "black",
        backgroundColor: "#d9ab00",
      },
    },
    removeButton: {
      width: "50%",
      color: "black",
      backgroundColor: "#ffc900",
      ":hover": {
        color: "black",
        backgroundColor: "#d9ab00",
      },
    },
  };

  const columns = [
    // Num
    {
      field: "number",
      title: "Num",
      headerStyle: styles.columnNumHeader,
      cellStyle: styles.columnNumCell,
      initialEditValue: false,
    },
    // Name
    {
      field: "name",
      title: tGeneral("name"),
      headerStyle: styles.columnNameHeader,
      cellStyle: styles.columnNameCell,
      initialEditValue: false,
    },
    // Actions
    // {
    //   field: "action",
    //   title: "Actions",
    //   initialEditValue: "",
    //   sorting: false,
    //   headerStyle: styles.columnActionHeader,
    //   cellStyle: styles.columnActionCell,
    //   render: (rowData) => (
    //     <ButtonGroup>
    //       <Can I="edit" a="adminPanel">
    //         <IconButton
    //           sx={styles.iconButton}
    //           onClick={(e) => {
    //             setCurrentIdSpecification(rowData.id);
    //             setNumber(rowData.number);
    //             setName(rowData.name);
    //             setOpenSpecification(true);
    //           }}
    //         >
    //           <Edit />
    //         </IconButton>
    //       </Can>
    //       <Can I="delete" a="adminPanel">
    //         <IconButton
    //           sx={styles.iconButton}
    //           onClick={(e) => {
    //             setCurrentIdSpecification(rowData.id);
    //             setName(rowData.name);
    //             setOpenDelete(true);
    //           }}
    //         >
    //           <Delete />
    //         </IconButton>
    //       </Can>
    //     </ButtonGroup>
    //   ),
    // },
  ];

  if (currentRole?.adminPanel === 2 || currentRole?.adminPanel === 3) {
    columns.push({
      field: "action",
      title: tGeneral("actions"),
      initialEditValue: "",
      sorting: false,
      headerStyle: styles.columnActionHeader,
      cellStyle: styles.columnActionCell,
      render: (rowData) => (
        <ButtonGroup>
          <IconButton
            sx={styles.iconButton}
            onClick={(e) => {
              setCurrentIdSpecification(rowData.id);
              setNumber(rowData.number);
              setName(rowData.name);
              setOpenSpecification(true);
            }}
          >
            <Edit />
          </IconButton>
          <IconButton
            sx={styles.iconButton}
            onClick={(e) => {
              setCurrentIdSpecification(rowData.id);
              setName(rowData.name);
              setOpenDelete(true);
            }}
          >
            <Delete />
          </IconButton>
        </ButtonGroup>
      ),
    });
  }

  useEffect(() => {
    if (projectSpecifications) {
      setDataSpecification(
        projectSpecifications.map((specification) => ({
          id: specification.id,
          number: specification.code,
          name: specification.name,
        })),
      );
    }
  }, [projectSpecifications]);

  const addSpecification = async (e) => {
    let body = {
      idProject: idProject,
      code: number,
      name: name,
    };
    try {
      const response = await newSpecification(body).unwrap();

      if (response) {
        resetVars();
        PopUpAlert(
          "success",
          tGeneral("success"),
          tGeneral("specificationAdded"),
        );
      }
    } catch (err) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      console.error(err);
    }
  };

  const editSpecification = async (e) => {
    let body = {
      idSpecification: currentIdSpecification,
      idProject: idProject,
      code: number,
      name: name,
    };
    try {
      const response = await updateSpecification(body).unwrap();

      if (response) {
        resetVars();
        PopUpAlert(
          "success",
          tGeneral("success"),
          tGeneral("specificationUpdated"),
        );
      }
    } catch (err) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      console.error(err);
    }
  };

  const removeSpecification = async (e) => {
    let body = {
      idSpecification: currentIdSpecification,
      idProject: idProject,
    };
    try {
      const response = await deleteSpecification(body).unwrap();

      if (response) {
        resetVars();
        PopUpAlert(
          "success",
          tGeneral("success"),
          tGeneral("specificationDeleted"),
        );
      }
    } catch (err) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      console.error(err);
    }
  };

  function validateFields(val, field) {
    switch (field) {
      case "number":
        setNumber(val);
        const numberValidation = val.length > 0 ? 1 : -1;
        numberValidation === 1 ? setNumberError(false) : setNumberError(true);
        numberValidation === 1
          ? setNumberHelperText("")
          : setNumberHelperText("Invalid number");
        break;
      case "name":
        setName(val);
        const nameValidation = val.length > 0 ? 1 : -1;
        nameValidation === 1 ? setNameError(false) : setNameError(true);
        nameValidation === 1
          ? setNameHelperText("")
          : setNameHelperText("Invalid name");
        break;
      default:
    }
  }

  function resetVars() {
    setOpenSpecification(false);
    setOpenDelete(false);
    setCurrentIdSpecification("");
    setNumber("");
    setNumberError(false);
    setNumberHelperText("");
    setName("");
    setNameError(false);
    setNameHelperText("");
  }

  const tableLocalization = {
    pagination: {
      labelRowsPerPage: `${tGeneral("labelRowsPerPage")}`,
      labelDisplayedRows: `{from}-{to} ${tGeneral("of")} {count}`,
      labelRowsSelect: `${tGeneral("labelRowsSelect")}`,
      firstAriaLabel: `${tGeneral("firstAriaLabel")}`,
      firstTooltip: `${tGeneral("firstTooltip")}`,
      previousAriaLabel: `${tGeneral("previousAriaLabel")}`,
      previousTooltip: `${tGeneral("previousTooltip")}`,
      nextAriaLabel: `${tGeneral("nextAriaLabel")}`,
      nextTooltip: `${tGeneral("nextTooltip")}`,
      lastAriaLabel: `${tGeneral("lastAriaLabel")}`,
      lastTooltip: `${tGeneral("lastTooltip")}`,
    },
    toolbar: {
      searchPlaceholder: tGeneral("search"),
    },
    body: {
      emptyDataSourceMessage: tGeneral("emptyDataSourceMessage"),
    },
  };

  return (
    <Box sx={styles.boxStyle}>
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        data={dataSpecification}
        localization={tableLocalization}
        actions={
          currentRole?.adminPanel === 3 || currentRole?.adminPanel === 2
            ? [
                {
                  icon: forwardRef((props, ref) => (
                    <Box sx={styles.boxButton}>
                      <Add sx={styles.addButton} ref={ref} />
                    </Box>
                  )),
                  tooltip: "Add Specification",
                  isFreeAction: true,
                  // hidden: userRole.panel === 3 ? false : true, // only for super admins
                  onClick: (event) => {
                    setOpenSpecification(true);
                  },
                },
              ]
            : []
        }
        options={{
          showTitle: false,
          search: true,
          // Search styles
          searchFieldVariant: "outlined",
          searchFieldAlignment: "left",
          searchFieldStyle: { width: "450px", height: "35px" },
          sorting: false,
          maxBodyHeight: "65vh",
          actionsColumnIndex: -1,
          paging: true,
          exportButton: false,
          pageSize: 10,
          pageSizeOptions: [10, 20, 30],
          showFirstLastPageButtons: true,
          // Styles
          headerStyle: {
            textAlign: "center",
          },
          // Styles for rows
          rowStyle: {
            height: "10px",
            overflowWrap: "break-word",
          },
          padding: "dense",
          draggable: false,
        }}
        disableSelectionOnClick
      />
      {/* ADD/EDIT POP UP */}
      <Dialog
        open={openSpecification}
        onClose={(e) => resetVars()}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ mx: "auto" }}
        PaperProps={{ sx: { borderRadius: "10px" } }}
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={[
            styles.dialogTitle,
            { padding: "16px 40px" },
            { marginBottom: "10px" },
          ]}
        >
          {currentIdSpecification !== ""
            ? tGeneral("editSpecification")
            : tGeneral("addSpecification")}
          <IconButton
            aria-label="close"
            onClick={(e) => resetVars()}
            sx={[styles.iconButtonClose, { marginRight: "20px" }]}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={[{ mx: "auto" }, { padding: "0px 40px 10px 40px" }]}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
            sx={{ mx: "auto" }}
          >
            <TextField
              id="defaultSpecNumTextField"
              variant="outlined"
              error={numberError}
              helperText={numberHelperText}
              type="number"
              label="Num"
              required
              value={number}
              onChange={(e) => {
                validateFields(e.target.value, "number");
              }}
              sx={[styles.textField, { marginBottom: "20px" }]}
            />
            <TextField
              id="defaultNameTextField"
              variant="outlined"
              error={nameError}
              helperText={nameHelperText}
              type="text"
              label={tGeneral("name")}
              required
              value={name}
              onChange={(e) => {
                validateFields(e.target.value, "name");
              }}
              sx={styles.textField}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={[
            { marginBottom: "10px" },
            { marginRight: "30px" },
            { marginLeft: "30px" },
          ]}
        >
          <Button
            sx={styles.cancelButton}
            type="submit"
            variant="contained"
            size="large"
            onClick={(e) => resetVars()}
          >
            {tGeneral("cancel")}
          </Button>
          <Button
            sx={styles.saveButton}
            type="submit"
            variant="contained"
            size="large"
            disabled={
              numberError ||
              nameError ||
              number.length === 0 ||
              name.length === 0
                ? true
                : false
            }
            onClick={
              currentIdSpecification !== ""
                ? editSpecification
                : addSpecification
            }
          >
            {currentIdSpecification !== "" ? tGeneral("save") : tGeneral("add")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* DELETE POP UP */}
      <Dialog
        open={openDelete}
        onClose={(e) => resetVars()}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ mx: "auto" }}
      >
        <DialogTitle id="scroll-dialog-title-delete" sx={styles.dialogTitle}>
          <IconButton
            aria-label="close"
            onClick={(e) => resetVars()}
            sx={styles.iconButtonClose}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ mx: "auto" }}>
          <DialogContentText
            id="scroll-dialog-description-delete"
            tabIndex={-1}
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <img src={DeleteImg} alt="Delete" width="70px" />
            <Typography
              sx={{
                fontWeight: "bold",
                color: darkMode ? "white" : "black",
                mt: 1,
              }}
            >
              {tGeneral("deleteSpecification")}
            </Typography>
            <Typography
              sx={{
                color: darkMode ? "white" : "black",
                transform: "none",
                m: 1,
              }}
            >
              {tGeneral("deleteGeneric")}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            sx={styles.cancelButton}
            type="submit"
            variant="contained"
            size="small"
            onClick={(e) => resetVars()}
          >
            {tGeneral("cancel")}
          </Button>
          <Button
            sx={styles.removeButton}
            type="submit"
            variant="contained"
            size="small"
            onClick={removeSpecification}
          >
            {tGeneral("accept")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SpecificationsPanel;
