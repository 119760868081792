import React from "react";
import { Outlet } from "react-router";
import Grid from "@mui/material/Grid";
import Appbar from "../Appbar";
// Redux: hooks
import { useSelector } from "react-redux";
// Redux: selectors
import { selectCurrentProject } from "../../features/project/projectSlice";

const drawerWidthLeft = 240;

const UserSettingsLayout = () => {
  return (
    <>
      <Appbar
        title="Settings"
        type="settings"
        drawerWidthLeft={drawerWidthLeft}
      />
      <Grid
        container
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidthLeft}px)` },
          ml: { sm: `${drawerWidthLeft}px` },
        }}
      >
        <Outlet />
      </Grid>
    </>
  );
};

export default UserSettingsLayout;
