import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// MUI Components
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";

// Icons
import { Add, Search } from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Swal from "sweetalert2";
import { useParams } from "react-router";
import Connections from "./Connections";
import CostCodesPanel from "./CostCodesPanel";
import SpecificationsPanel from "./SpecificationsPanel";

// Alert

import { useGetProjectUsersQuery } from "../../features/project/projectApiSlice";

import { useCreatePresignedUrlMutation } from "../../features/s3/s3ApiSlice";

import { AdminPanelProject } from "../../components/projectDashboard/AdminPanel";
import { selectAdminUsers } from "../../features/enterprise/enterpriseSlice";
import {
  useAddLocationsMutation,
  useAddLocationTier1Mutation,
  useDeleteLocationTierMutation,
  useEditLocationsMutation,
  useEditLocationTier1Mutation,
  useGetLocationsQuery,
} from "../../features/locations/locationApiSlice";
import { selectCurrentUser } from "../../features/userSettings/userSettingsSlice";
import Can from "../../utils/can";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AdminPanel() {
  const { idProject } = useParams();
  const adminUsers = useSelector(selectAdminUsers);
  const currentUser = useSelector(selectCurrentUser);

  const userIsSuperAdmin =
    adminUsers && adminUsers.length > 0
      ? adminUsers.some((user) => user.idUser === currentUser?.id)
      : false;

  const { t: tGeneral } = useTranslation("general");
  // PRESIGNED URL
  const [createPresignedUrl, { isLoading: isUrlLoading }] =
    useCreatePresignedUrlMutation();

  // PROJECT USERS
  const { data: projectUsers, isSuccess } = useGetProjectUsersQuery(idProject);

  const [userList, setUserList] = useState([]);

  // lOCATIONS
  const { data: locationsData, isSuccessLocations } = useGetLocationsQuery(
    idProject,
    {
      skip: !idProject,
      refetchOnMountOrArgChange: true,
    },
  );
  const [dataLocationTier1, setDataLocationTier1] = useState([]);
  const [dataLocationTier2, setDataLocationTier2] = useState([]);
  const [dataLocationTier3, setDataLocationTier3] = useState([]);
  const [addLocation, { isSuccess: isSuccesLocation }] =
    useAddLocationsMutation();
  const [addLocationTier1, { isSuccess: isSuccesTier1 }] =
    useAddLocationTier1Mutation();
  const [removeLocation, { isSuccess: isSuccesDelete }] =
    useDeleteLocationTierMutation();
  const [editLocation, { isSuccess: isSuccesEdit }] =
    useEditLocationsMutation();
  const [editLocationTier1, { isSuccess: isSuccesEditTier1 }] =
    useEditLocationTier1Mutation();

  const [locationName, setLocationName] = useState();
  const [locationNameError, setLocationNameError] = useState(false);
  const [locationNameHelperText, setLocationNameHelperText] = useState("");
  const [locationType, setLocationType] = useState("tier 1");
  const [locationTypeError, setLocationTypeError] = useState(false);
  const [locationTypeHelperText, setLocationTypeHelperText] = useState("");
  const [superLocation, setSuperLocation] = useState();
  const [superLocationError, setSuperLocationError] = useState(false);
  const [superLocationHelperText, setSuperLocationHelperText] = useState("");

  const [filteredLocationTier2, setFilteredLocationTier2] = useState([]);
  const [filteredLocationTier3, setFilteredLocationTier3] = useState([]);

  const [idSelected, setIdSelected] = useState();
  const [openDeleteLocation, setOpenDeleteLocation] = useState(false);
  const [openEditLocation, setOpenEditLocation] = useState(false);

  const tierType = ["tier 1", "tier 2", "tier 3"];

  // TABLIST ONCHANGE
  const [value, setValue] = React.useState("projectAdministration");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // END TABLIST ONCHANGE

  // MENU STUFF
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, loc) => {
    setIdSelected(loc.id);
    setLocationName(loc.locationName);
    setLocationType(loc.locationType);
    setSuperLocation(loc.superLocation);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // END MENU STUFF

  // DRAG AND DROP
  const [cover, setCover] = useState(null);
  const [changedFile, setChangedFile] = useState(false);
  const [openLocation, setOpenLocation] = useState(false);
  const [addTier1, setAddTier1] = useState(false);
  const [addTier2, setAddTier2] = useState(false);
  const [addTier3, setAddTier3] = useState(false);
  const [addTierId, setAddTierId] = useState(false);
  const [addTier2Id, setAddTier2Id] = useState(false);

  // Search section
  const [searchValueTier1, setSearchValueTier1] = useState("");
  const [searchValueTier2, setSearchValueTier2] = useState("");
  const [searchValueTier3, setSearchValueTier3] = useState("");
  // End search section

  const resetVars = () => {
    setOpenLocation(false);
    setSuperLocation();
    setLocationName();
    setLocationType();
    setOpenDeleteLocation(false);
    setOpenEditLocation(false);
    setAddTier1(false);
    setAddTier2(false);
    setAddTier3(false);
  };

  const handleTier2 = (event, tierId) => {
    setFilteredLocationTier3([]);
    setSearchValueTier2("");
    setSearchValueTier3("");
    setAddTierId(tierId);
    setAddTier2Id();
  };

  useEffect(() => {
    if (addTierId) {
      const locationsList = dataLocationTier2.filter(function (obj) {
        return obj.superLocation === addTierId;
      });
      setFilteredLocationTier2(locationsList);
    }
  }, [locationsData, dataLocationTier2, addTierId]);

  const handleTier3 = (event, tierId) => {
    setAddTier2Id(tierId);
    setSearchValueTier3("");
  };

  useEffect(() => {
    if (addTier2Id) {
      const locationsList = dataLocationTier3.filter(function (obj) {
        return obj.superLocation === addTier2Id;
      });
      setFilteredLocationTier3(locationsList);
    }
  }, [addTier2Id, dataLocationTier3]);

  function validateFields(val, field) {
    switch (field) {
      case "locationName":
        setLocationName(val);
        const locationNameValidation = val.length > 0 ? 1 : -1;
        locationNameValidation === 1
          ? setLocationNameError(false)
          : setLocationNameError(true);
        locationNameValidation === 1
          ? setLocationNameHelperText("")
          : setLocationNameHelperText("Invalid name");
        break;
      case "locationType":
        setLocationType(val);
        const locationTypeValidation = val.length > 0 ? 1 : -1;
        locationTypeValidation === 1
          ? setLocationTypeError(false)
          : setLocationTypeError(true);
        locationTypeValidation === 1
          ? setLocationTypeHelperText("")
          : setLocationTypeHelperText("Invalid type");
        break;
      case "superLocation":
        setSuperLocation(val);
        const superLocationValidation = val.length > 0 ? 1 : -1;
        superLocationValidation === 1
          ? setSuperLocationError(false)
          : setSuperLocationError(true);
        superLocationValidation === 1
          ? setSuperLocationHelperText("")
          : setSuperLocationHelperText("Invalid superlocation");
        break;
      default:
        break;
    }
  }

  const createLocationTier1 = async (e) => {
    let mistakes = false;
    validateFields(locationName, "locationName");
    // setLocationType("tier 1");
    if (locationNameError) {
      mistakes = true;
    }
    if (!mistakes) {
      try {
        let response = null;
        let bodyCreate = {};
        bodyCreate = {
          idProject,
          locationName,
          locationType: "tier 1",
        };
        response = await addLocationTier1(bodyCreate).unwrap();

        if (response) {
          resetVars();
          showAlert(tGeneral("locationCreated"), "success");
        }
      } catch (err) {
        showAlert(tGeneral("cantCreateLocation"), "error");
        console.error(err);
      }
    }
  };

  const createLocationTier2 = async (e) => {
    let mistakes = false;
    validateFields(locationName, "locationName");
    // setLocationType("tier 1");
    if (locationNameError) {
      mistakes = true;
    }
    if (!mistakes) {
      try {
        let response = null;
        let bodyCreate = {};
        bodyCreate = {
          idProject,
          locationName,
          locationType: "tier 2",
          superLocationId: addTierId,
        };
        response = await addLocation(bodyCreate).unwrap();

        if (response) {
          resetVars();
          showAlert(tGeneral("locationCreated"), "success");
        }
      } catch (err) {
        showAlert(tGeneral("cantCreateLocation"), "error");
        console.error(err);
      }
    }
  };

  const createLocationTier3 = async (e) => {
    let mistakes = false;
    validateFields(locationName, "locationName");
    // setLocationType("tier 1");
    if (locationNameError) {
      mistakes = true;
    }
    if (!mistakes) {
      try {
        let response = null;
        let bodyCreate = {};
        bodyCreate = {
          idProject,
          locationName,
          locationType: "tier 3",
          superLocationId: addTier2Id,
        };
        response = await addLocation(bodyCreate).unwrap();

        if (response) {
          resetVars();
          showAlert(tGeneral("locationCreated"), "success");
        }
      } catch (err) {
        showAlert(tGeneral("cantCreateLocation"), "error");
        console.error(err);
      }
    }
  };

  const createLocation = async (e) => {
    let mistakes = false;
    validateFields(locationName, "locationName");
    validateFields(locationType, "locationType");
    if (locationNameError || locationTypeError) {
      mistakes = true;
    }
    if (!mistakes) {
      try {
        let response = null;
        let bodyCreate = {};
        if (locationType == "tier 1") {
          bodyCreate = {
            idProject,
            locationName,
            locationType,
          };

          response = await addLocationTier1(bodyCreate).unwrap();
        } else {
          bodyCreate = {
            idProject,
            locationName,
            locationType,
            superLocationId: superLocation,
          };

          response = await addLocation(bodyCreate).unwrap();
        }
        if (response) {
          resetVars();
          showAlert(tGeneral("locationCreated"), "success");
        }
      } catch (err) {
        showAlert(tGeneral("cantCreateLocation"), "error");
        console.error(err);
      }
    }
  };

  const removeLocations = async (e) => {
    try {
      let response = null;
      const body = {
        idProject,
        idLocation: idSelected,
      };
      response = await removeLocation(body).unwrap();

      if (response) {
        setOpenDeleteLocation(false);
        setIdSelected();
        resetVars();
        showAlert(tGeneral("locationDeleted"), "success");
      }
    } catch (err) {
      showAlert(tGeneral("cantDeleteLocation"), "error");
      console.error(err);
    }
  };

  const editLocations = async (e) => {
    try {
      let response = null;
      const body = {
        idProject,
        idLocation: idSelected,
        locationName,
        locationType,
        superLocationId: superLocation,
      };
      response = await editLocation(body).unwrap();

      if (response) {
        setOpenEditLocation(false);
        setIdSelected();
        resetVars();
        showAlert(tGeneral("locationUpdated"), "success");
      }
    } catch (err) {
      showAlert(tGeneral("cantUpdateLocation"), "error");
      console.error(err);
    }
  };

  const editLocationsTier1 = async (e) => {
    try {
      let response = null;
      const body = {
        idProject,
        idLocation: idSelected,
        locationName,
        locationType,
      };
      response = await editLocationTier1(body).unwrap();

      if (response) {
        setOpenEditLocation(false);
        setIdSelected();
        resetVars();
        showAlert("Location edited successfully", "success");
      }
    } catch (err) {
      showAlert("Can't edit location", "error");
      console.error(err);
    }
  };

  function showAlert(message, type) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: type,
      title: message,
    });
  }

  useEffect(() => {
    if (projectUsers) {
      const projectUserList = projectUsers.map(function (projectUser) {
        return {
          id: projectUser.id,
          name: `${projectUser.user.firstName} ${projectUser.user.lastName}`,
        };
      });
      setUserList(projectUserList);
    }
    if (locationsData) {
      const locationsTier1List = locationsData.tier1.map(function (location) {
        return {
          id: location.id,
          locationName: location.locationName,
          locationType: location.locationType,
        };
      });
      setDataLocationTier1(locationsTier1List);
      const locationsTier2List = locationsData.tier2.map(function (location) {
        return {
          id: location.id,
          locationName: location.locationName,
          locationType: location.locationType,
          superLocation: location.superLocation,
        };
      });
      setDataLocationTier2(locationsTier2List);
      const locationsTier3List = locationsData.tier3.map(function (location) {
        return {
          id: location.id,
          locationName: location.locationName,
          locationType: location.locationType,
          superLocation: location.superLocation,
        };
      });
      setDataLocationTier3(locationsTier3List);
    }
  }, [projectUsers, locationsData]);

  // Search for Tier 1
  function customSearchFunctionTier1(searchValue) {
    setSearchValueTier2("");
    setSearchValueTier3("");
    setAddTierId();
    setAddTier2Id();
    setFilteredLocationTier2([]);
    setFilteredLocationTier3([]);
    setSearchValueTier1(searchValue);
  }

  // Search for Tier 2
  function customSearchFunctionTier2(searchValue) {
    setSearchValueTier3("");
    setAddTier2Id();
    setFilteredLocationTier3([]);
    setSearchValueTier2(searchValue);
  }

  // Search for Tier 3
  function customSearchFunctionTier3(searchValue) {
    setSearchValueTier3(searchValue);
  }

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Typography variant="h5" align="left" fontWeight="bold">
        {tGeneral("adminPanel")}
      </Typography>
      <TabContext value={value}>
        {/* TABS */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            <Tab
              label={tGeneral("projectAdmin")}
              value="projectAdministration"
              sx={{
                fontWeight: value === "projectAdministration" ? "bold" : "none",
                textTransform: "none",
                color: "black",
                minWidth: "266px",
                "&.Mui-selected": {
                  color: "black",
                },
              }}
            />
            <Tab
              label={tGeneral("location")}
              value="location"
              sx={{
                fontWeight: value === "location" ? "bold" : "none",
                textTransform: "none",
                color: "black",
                minWidth: "266px",
                "&.Mui-selected": {
                  color: "black",
                },
              }}
            />
            <Tab
              label={tGeneral("specifications")}
              value="specifications"
              sx={{
                fontWeight: value === "specifications" ? "bold" : "none",
                textTransform: "none",
                color: "black",
                minWidth: "266px",
                "&.Mui-selected": {
                  color: "black",
                },
              }}
            />
            <Tab
              label={tGeneral("costCodes")}
              value="costCodes"
              sx={{
                fontWeight: value === "costCodes" ? "bold" : "none",
                textTransform: "none",
                color: "black",
                minWidth: "266px",
                "&.Mui-selected": {
                  color: "black",
                },
              }}
            />
            {userIsSuperAdmin && (
              <Tab
                label={tGeneral("connections")}
                value="connections"
                sx={{
                  fontWeight: value === "connections" ? "bold" : "none",
                  textTransform: "none",
                  color: "black",
                  minWidth: "266px",
                  "&.Mui-selected": {
                    color: "black",
                  },
                }}
              />
            )}
          </TabList>
        </Box>

        {/* tab 1 */}
        <TabPanel value="projectAdministration">
          <AdminPanelProject />
        </TabPanel>

        {/* tab 2  */}
        <TabPanel value="location">
          <Box display="flex">
            <Card
              sx={{
                width: "100%",
                display: "flex",
                backgroundColor: "#FAFAFA",
                height: "80vh",
              }}
            >
              <Box flex="1" p="20px">
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography fontSize="22px" fontWeight="Medium">
                    Tier 1
                  </Typography>
                </Box>
                <TextField
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="search button" edge="end">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "100%",
                    paddingY: 1,
                    display: { xs: "none", md: "inline-flex" },
                  }}
                  value={searchValueTier1}
                  placeholder={tGeneral("search")}
                  onChange={(e) => customSearchFunctionTier1(e.target.value)}
                />
                <List
                  component="nav"
                  aria-label="secondary mailbox folder"
                  sx={{
                    overflow: "scroll",
                    height: "60vh",
                  }}
                >
                  {dataLocationTier1
                    .filter(function (item) {
                      return item.locationName
                        .toLowerCase()
                        .includes(searchValueTier1.toLowerCase());
                    })
                    .map((locations) => (
                      <Box
                        key={locations.id}
                        component="div"
                        mb={2}
                        sx={{
                          width: "auto",
                          height: "auto",
                          backgroundColor:
                            addTierId === locations.id ? "#FFEBBC" : "white",
                          border: "1px solid #B0B7C1",
                          borderRadius: "15px",
                        }}
                      >
                        <ListItemButton
                          selected={addTierId === locations.id}
                          onClick={(event) => handleTier2(event, locations.id)}
                        >
                          <ListItemText primary={locations.locationName} />
                          <Can I="edit" a="adminPanel">
                            <IconButton
                              aria-label="more"
                              id={locations.id}
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(event) => handleClick(event, locations)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </Can>
                          <IconButton aria-label="arrow" id={locations.id}>
                            <ArrowForwardIosIcon />
                          </IconButton>
                        </ListItemButton>
                      </Box>
                    ))}
                  <Can I="add" a="adminPanel">
                    {addTier1 ? (
                      <Grid container item xs={12}>
                        <Grid
                          item
                          xs={10}
                          container
                          spacing={0}
                          sx={{ borderRadius: "2" }}
                        >
                          <TextField
                            id="locationName"
                            margin="dense"
                            variant="outlined"
                            error={!!locationNameError}
                            helperText={locationNameHelperText}
                            type="text"
                            label={tGeneral("name")}
                            value={locationName}
                            onChange={(e) => {
                              validateFields(e.target.value, "locationName");
                            }}
                            sx={{ m: 1, width: "100%" }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          container
                          spacing={0}
                          sx={{ borderRadius: "2" }}
                        >
                          <Button onClick={createLocationTier1}>
                            {tGeneral("add")}
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <IconButton
                        aria-label="more"
                        id="addTier1"
                        aria-controls={open ? "long-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-haspopup="true"
                        onClick={(e) => setAddTier1(true)}
                      >
                        <Add />
                        <Typography variant="h6" align="left">
                          {tGeneral("add")}
                        </Typography>
                      </IconButton>
                    )}
                  </Can>
                </List>
              </Box>
              <Divider orientation="vertical" />
              <Box flex="1" p="20px">
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography fontSize="22px" fontWeight="Medium">
                    Tier 2
                  </Typography>
                </Box>
                <TextField
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="search button" edge="end">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "100%",
                    paddingY: 1,
                    display: { xs: "none", md: "inline-flex" },
                  }}
                  value={searchValueTier2}
                  placeholder={tGeneral("search")}
                  onChange={(e) => customSearchFunctionTier2(e.target.value)}
                />
                <List
                  component="nav"
                  aria-label="secondary mailbox folder"
                  sx={{
                    overflow: "scroll",
                    height: "60vh",
                  }}
                >
                  {filteredLocationTier2
                    .filter(function (item) {
                      return item.locationName
                        .toLowerCase()
                        .includes(searchValueTier2.toLowerCase());
                    })
                    .map((locations) => (
                      <Box
                        key={locations.id}
                        component="div"
                        borderRadius={3}
                        mb={2}
                        border={1}
                        sx={{
                          width: "auto",
                          height: "auto",
                          backgroundColor:
                            addTier2Id === locations.id ? "#FFEBBC" : "white",
                          border: "1px solid #B0B7C1",
                          borderRadius: "15px",
                        }}
                      >
                        <ListItemButton
                          onClick={(event) => handleTier3(event, locations.id)}
                        >
                          <ListItemText primary={locations.locationName} />
                          <Can I="edit" a="adminPanel">
                            <IconButton
                              aria-label="more"
                              id={locations.locationName}
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(event) => handleClick(event, locations)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </Can>
                          <IconButton
                            aria-label="Arrow"
                            id={locations.locationName}
                          >
                            <ArrowForwardIosIcon />
                          </IconButton>
                        </ListItemButton>
                      </Box>
                    ))}
                  <Can I="add" a="adminPanel">
                    {addTier2 ? (
                      <Grid container item xs={12}>
                        <Grid
                          item
                          xs={10}
                          container
                          spacing={0}
                          sx={{ borderRadius: "2" }}
                        >
                          <TextField
                            id="locationName"
                            margin="dense"
                            variant="outlined"
                            error={!!locationNameError}
                            helperText={locationNameHelperText}
                            type="text"
                            label={tGeneral("name")}
                            value={locationName}
                            onChange={(e) => {
                              validateFields(e.target.value, "locationName");
                            }}
                            sx={{ m: 1, width: "100%" }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          container
                          spacing={0}
                          sx={{ borderRadius: "2" }}
                        >
                          <Button onClick={createLocationTier2}>
                            {tGeneral("add")}
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Box>
                        {addTierId ? (
                          <IconButton
                            aria-label="more"
                            id="addTier1"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => setAddTier2(true)}
                          >
                            <Add />
                            <Typography variant="h6" align="left">
                              {tGeneral("add")}
                            </Typography>
                          </IconButton>
                        ) : (
                          <Grid
                            item
                            xs={2}
                            container
                            spacing={0}
                            sx={{ borderRadius: "2" }}
                          />
                        )}
                      </Box>
                    )}
                  </Can>
                </List>
              </Box>
              <Divider orientation="vertical" />
              <Box flex="1" p="20px">
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Typography fontSize="22px" fontWeight="Medium">
                    Tier 3
                  </Typography>
                </Box>
                <TextField
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="search button" edge="end">
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "100%",
                    paddingY: 1,
                    display: { xs: "none", md: "inline-flex" },
                  }}
                  value={searchValueTier3}
                  placeholder={tGeneral("search")}
                  onChange={(e) => customSearchFunctionTier3(e.target.value)}
                />
                <List
                  component="nav"
                  aria-label="secondary mailbox folder"
                  sx={{
                    overflow: "scroll",
                    height: "60vh",
                  }}
                >
                  {filteredLocationTier3
                    .filter(function (item) {
                      return item.locationName
                        .toLowerCase()
                        .includes(searchValueTier3.toLowerCase());
                    })
                    .map((locations) => (
                      <Box
                        key={locations.id}
                        component="div"
                        borderRadius={3}
                        mb={2}
                        border={1}
                        sx={{
                          width: "auto",
                          height: "auto",
                          backgroundColor: "white",
                          border: "1px solid #B0B7C1",
                          borderRadius: "15px",
                        }}
                      >
                        <ListItemButton>
                          <ListItemText primary={locations.locationName} />
                          <Can I="edit" a="adminPanel">
                            <IconButton
                              aria-label="more"
                              id={locations.locationName}
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(event) => handleClick(event, locations)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </Can>
                        </ListItemButton>
                      </Box>
                    ))}
                  <Can I="add" a="adminPanel">
                    {addTier3 ? (
                      <Grid container item xs={12}>
                        <Grid
                          item
                          xs={10}
                          container
                          spacing={0}
                          sx={{ borderRadius: "2" }}
                        >
                          <TextField
                            id="locationName"
                            margin="dense"
                            variant="outlined"
                            error={!!locationNameError}
                            helperText={locationNameHelperText}
                            type="text"
                            label={tGeneral("name")}
                            value={locationName}
                            onChange={(e) => {
                              validateFields(e.target.value, "locationName");
                            }}
                            sx={{ m: 1, width: "100%" }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          container
                          spacing={0}
                          sx={{ borderRadius: "2" }}
                        >
                          <Button onClick={createLocationTier3}>
                            {tGeneral("add")}
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Box>
                        {addTier2Id ? (
                          <IconButton
                            aria-label="more"
                            id="addTier1"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => setAddTier3(true)}
                          >
                            <Add />
                            <Typography variant="h6" align="left">
                              {tGeneral("add")}
                            </Typography>
                          </IconButton>
                        ) : (
                          <Grid
                            item
                            xs={2}
                            container
                            spacing={0}
                            sx={{ borderRadius: "2" }}
                          />
                        )}
                      </Box>
                    )}
                  </Can>
                </List>
              </Box>
            </Card>
          </Box>
        </TabPanel>

        {/* Specifications */}
        <TabPanel value="specifications">
          <SpecificationsPanel />
        </TabPanel>

        {/* Cost Codes */}
        <TabPanel value="costCodes">
          <CostCodesPanel />
        </TabPanel>

        {/* Connections */}
        <TabPanel value="connections">
          <Connections />
        </TabPanel>
      </TabContext>

      <Menu
        id="locationsSettings"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "EditLocationButton",
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setOpenEditLocation(true);
          }}
        >
          <EditIcon sx={{ pr: "5px" }} />
          {tGeneral("edit")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setOpenDeleteLocation(true);
          }}
        >
          <DeleteIcon sx={{ pr: "5px" }} />
          {tGeneral("delete")}
        </MenuItem>
      </Menu>

      <Dialog
        open={openLocation}
        onClose={(e) => resetVars()}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#ffc900",
            color: "white",
          }}
          id="scroll-dialog-title"
        >
          Add location
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <TextField
              id="locationName"
              margin="dense"
              variant="outlined"
              error={!!locationNameError}
              helperText={locationNameHelperText}
              type="text"
              label={tGeneral("name")}
              value={locationName}
              onChange={(e) => {
                validateFields(e.target.value, "locationName");
              }}
              sx={{ m: 1, width: "100%" }}
            />

            <FormControl sx={{ m: 1, width: "100%" }}>
              <InputLabel required id="userTag">
                {tGeneral("type")}
              </InputLabel>
              <Select
                labelId="locationTypeTag"
                id="locationType"
                value={locationType}
                onChange={(e) => validateFields(e.target.value, "locationType")}
                input={<OutlinedInput label={tGeneral("type")} />}
                renderValue={(selected) => selected}
                MenuProps={MenuProps}
                error={!!locationTypeError}
              >
                {tierType.map((type) => (
                  <MenuItem key={type} value={type}>
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {locationType === "tier 2" ? (
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel required id="userTag">
                  {tGeneral("superLocation")}
                </InputLabel>
                <Select
                  labelId="locationTypeTag"
                  id="superLocation"
                  value={superLocation}
                  onChange={(e) =>
                    validateFields(e.target.value, "superLocation")
                  }
                  input={<OutlinedInput label={tGeneral("superLocation")} />}
                  renderValue={(selected) =>
                    dataLocationTier1[
                      dataLocationTier1.findIndex(function (item, j) {
                        return item.id === selected;
                      })
                    ].locationName
                  }
                  MenuProps={MenuProps}
                  error={!!superLocationError}
                >
                  {dataLocationTier1.map((type) => (
                    <MenuItem
                      key={type.id}
                      value={type.id}
                      name={type.locationName}
                    >
                      <ListItemText primary={type.locationName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Box />
            )}
            {locationType === "tier 3" ? (
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel required id="userTag">
                  {tGeneral("superLocation")}
                </InputLabel>
                <Select
                  labelId="locationTypeTag"
                  id="superLocation"
                  value={superLocation}
                  onChange={(e) =>
                    validateFields(e.target.value, "superLocation")
                  }
                  input={<OutlinedInput label={tGeneral("superLocation")} />}
                  renderValue={(selected) =>
                    dataLocationTier2[
                      dataLocationTier2.findIndex(function (item, j) {
                        return item.id === selected;
                      })
                    ].locationName
                  }
                  MenuProps={MenuProps}
                  error={!!superLocationError}
                >
                  {dataLocationTier2.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      <ListItemText primary={type.locationName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Box />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => resetVars()}>Cancel</Button>
          <Button onClick={createLocation}>Create</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteLocation}
        onClose={(e) => resetVars()}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#ffc900",
            color: "white",
          }}
          id="scroll-dialog-title"
        >
          {tGeneral("delete")} {tGeneral("location")}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            {tGeneral("sureDelete")} {locationName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => resetVars()}>{tGeneral("cancel")}</Button>
          <Button onClick={(e) => removeLocations()}>
            {tGeneral("delete")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEditLocation}
        onClose={(e) => resetVars()}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#ffc900",
            color: "white",
          }}
          id="scroll-dialog-title"
        >
          {tGeneral("edit")} {tGeneral("location")}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <TextField
              id="locationName"
              margin="dense"
              variant="outlined"
              error={!!locationNameError}
              helperText={locationNameHelperText}
              type="text"
              label={tGeneral("name")}
              value={locationName}
              onChange={(e) => {
                validateFields(e.target.value, "locationName");
              }}
              sx={{ m: 1, width: "100%" }}
            />

            <FormControl sx={{ m: 1, width: "100%" }}>
              <InputLabel required id="userTag">
                {tGeneral("type")}
              </InputLabel>
              <Select
                labelId="locationTypeTag"
                id="locationType"
                value={locationType}
                onChange={(e) => validateFields(e.target.value, "locationType")}
                input={<OutlinedInput label={tGeneral("type")} />}
                renderValue={(selected) => selected}
                MenuProps={MenuProps}
                error={!!locationTypeError}
              >
                {tierType.map((type) => (
                  <MenuItem key={type} value={type}>
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {locationType === "tier 2" ? (
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel required id="userTag">
                  {tGeneral("superLocation")}
                </InputLabel>
                <Select
                  labelId="locationTypeTag"
                  id="superLocation"
                  value={superLocation}
                  onChange={(e) =>
                    validateFields(e.target.value, "superLocation")
                  }
                  input={<OutlinedInput label={tGeneral("superLocation")} />}
                  renderValue={(selected) =>
                    dataLocationTier1[
                      dataLocationTier1.findIndex(function (item, j) {
                        return item.id === selected;
                      })
                    ].locationName
                  }
                  MenuProps={MenuProps}
                  error={!!superLocationError}
                >
                  {dataLocationTier1.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      <ListItemText primary={type.locationName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Box />
            )}
            {locationType === "tier 3" ? (
              <FormControl sx={{ m: 1, width: "100%" }}>
                <InputLabel required id="userTag">
                  {tGeneral("superLocation")}
                </InputLabel>
                <Select
                  labelId="locationTypeTag"
                  id="superLocation"
                  value={superLocation}
                  onChange={(e) =>
                    validateFields(e.target.value, "superLocation")
                  }
                  input={<OutlinedInput label={tGeneral("superLocation")} />}
                  renderValue={(selected) =>
                    dataLocationTier2[
                      dataLocationTier2.findIndex(function (item, j) {
                        return item.id === selected;
                      })
                    ].locationName
                  }
                  MenuProps={MenuProps}
                  error={!!superLocationError}
                >
                  {dataLocationTier2.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      <ListItemText primary={type.locationName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Box />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => resetVars()}>{tGeneral("cancel")}</Button>
          {locationType !== "tier 1" ? (
            <Button onClick={editLocations}>{tGeneral("edit")}</Button>
          ) : (
            <Button onClick={editLocationsTier1}>{tGeneral("edit")}</Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AdminPanel;
