import React, { useState } from "react";
// Redux reducers & mutations
// Hooks
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// MUI Components
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
// Custom components
import Swal from "sweetalert2";
import { selectCurrentEmail } from "../features/auth/authSlice";
import { selectTheme } from "../features/preferences/preferencesSlice";
import {
  useSendEmailVerifyMutation,
  useVerifyAccountMutation,
} from "../features/auth/authApiSlice";

function SendEmailVerify() {
  const navigate = useNavigate();
  // We check if theme == dark to render proper logo dynamically
  const darkMode = useSelector(selectTheme);

  // Redux dispatch action
  const [sendEmailVerify] = useSendEmailVerifyMutation();
  const email = useSelector(selectCurrentEmail);

  // Handle submit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = await sendEmailVerify({ email }).unwrap();
      Swal.fire({
        icon: "success",
        title: "Email has been sent",
        text: "Please follow instructions",
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Failed to send email",
        text: err?.data,
        showConfirmButton: false,
        timer: 2000,
      });
      console.error(err);
    }
  };

  return (
    <>
      {/* Buildpeer logo */}
      <Grid item justifyContent="center" alignItems="center" xs={12}>
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            mt: 15,
            mb: 10,
          }}
        >
          <Box
            component="img"
            sx={{
              width: "40%",
              height: "40%",
            }}
            alt="Buildpeer Logo"
            src={
              darkMode
                ? require("../assets/logo-dark.png")
                : require("../assets/logo.png")
            }
          />
        </Box>
      </Grid>
      {/* Instructions Text */}
      <Grid item xs={8} justifyContent="center" alignItems="center">
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            mt: 5,
          }}
        >
          <Typography variant="h5" align="center" fontWeight="bold">
            Your account is not verified!
          </Typography>
        </Box>
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            mt: 5,
          }}
        >
          <Typography variant="subtitle 1" align="center">
            Please enter to the email you registered in Buildpeer and verify
            your account.
          </Typography>
        </Box>
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            mt: 5,
          }}
        >
          <Typography variant="subtitle 1" align="center">
            Remember to look in spam
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={4}
        sx={{ mt: 5 }}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            mt: 5,
          }}
        >
          {/* Button resend email */}
          <Button
            onClick={(e) => {
              handleSubmit(e);
            }}
            variant="contained"
            sx={{
              width: "50%",
              borderRadius: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Resend Email
          </Button>
        </Box>

        {/* Button Back */}
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            mt: 5,
          }}
        >
          <Button
            onClick={(e) => {
              navigate("/");
            }}
            variant="outlined"
            color="secondary"
            sx={{
              width: "50%",
              borderRadius: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Back
          </Button>
        </Box>
      </Grid>
    </>
  );
}

export default SendEmailVerify;
