const status = {
  open: "オープン",
  closed: "クローズ",
  close: "クローズ",
  in_revision: "進行中",
  draft: "下書き",
  pending: "保留中",
  doing: "オープン",
  backlog: "オープン",
  rejected: "却下",
  done: "完了",
  inactive: "非アクティブ",
  "in revision": "進行中",
};

export { status };
