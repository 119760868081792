import { useEffect, useState } from "react";

import { useGetUserProjectsByEnterprisePaginatedQuery } from "../features/enterprise/enterpriseApiSlice";

const DEFAULT_PAGINATION_LIMIT = 15;

function uniqueProjects(data) {
  const ids = data.map(({ id }) => id);
  return data.filter(({ id }, index) => !ids.includes(id, index + 1));
}

const usePaginatedProjects = (props) => {
  const { idEnterprise, searchQuery, bActive = true, total = 0 } = props;

  const [paginated, setPaginated] = useState([]);
  const [page, setPage] = useState(1);

  const {
    data,
    isLoading,
    isFetching,
    refetch,
    isUninitialized,
    isSuccess,
    isError,
    error,
  } = useGetUserProjectsByEnterprisePaginatedQuery(
    {
      idEnterprise,
      bActive,
      search: searchQuery ?? null,
      limit: DEFAULT_PAGINATION_LIMIT,
      page,
    },
    {
      skip: !idEnterprise,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    },
  );

  const reset = () => {
    setPaginated([]);
    setPage(1);
    if (!isUninitialized) refetch();
  };

  useEffect(() => {
    reset();
  }, [searchQuery, total]);

  useEffect(() => {
    if (data?.length > 0 && !isFetching) {
      setPaginated((prev) => uniqueProjects([...prev, ...data]));
    }
  }, [data, isFetching]);

  const onEndReach = () => {
    if (isLoading || isFetching) return;
    setPage((prev) => prev + 1);
  };

  return {
    paginated,
    noResults:
      !isLoading && !isFetching && paginated?.length < 1 && data?.length < 1,
    noMoreResults:
      (!isLoading &&
        !isFetching &&
        data?.length === 0 &&
        paginated.length > 0) ||
      total <= DEFAULT_PAGINATION_LIMIT,
    isLoading,
    isFetching,
    isSuccess,
    isUninitialized,
    isError,
    error,
    onEndReach: total < DEFAULT_PAGINATION_LIMIT ? null : onEndReach,
    refetch: reset,
    reset,
  };
};

export { usePaginatedProjects };
