import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function TotalCompleted({ drawings, isTotal = false }) {
  const { t: tDrawingsChecklist } = useTranslation("drawingsChecklist");

  const drawingsCounters = drawings
    ? drawings.reduce(
        (prev, curr) => {
          const newCounters = { ...prev };
          newCounters.total += 1;
          if (curr.status === "done") newCounters.done += 1;
          if (curr.status === "revision") newCounters.revision += 1;
          if (curr.status === "pending") newCounters.pending += 1;
          return newCounters;
        },
        { total: 0, done: 0, revision: 0, pending: 0 },
      )
    : { total: 0, done: 0, revision: 0, pending: 0 };

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor={isTotal ? "#FEFDE8" : "#F5F5F5"}
      mb="0.5rem"
      p="0.875rem 1.4rem"
      rowGap="0.75rem"
    >
      <Box display="flex" alignItems="center" columnGap="1rem">
        <Typography
          fontSize={isTotal ? "1.25rem" : "0.875rem"}
          flex={2}
          fontWeight="medium"
        >
          {tDrawingsChecklist(
            isTotal ? "totalCompleted" : "percentageCompleted",
          )}
        </Typography>
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          sx={{ borderBottom: "1px solid #C0E5D1" }}
        >
          <Typography fontWeight={isTotal ? "bold" : "medium"}>
            {tDrawingsChecklist("done")}
          </Typography>
        </Box>
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          sx={{ borderBottom: "1px solid #F9DF3A" }}
        >
          <Typography fontWeight={isTotal ? "bold" : "medium"}>
            {tDrawingsChecklist("revision")}
          </Typography>
        </Box>
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          sx={{ borderBottom: "1px solid #CACACA" }}
        >
          <Typography fontWeight={isTotal ? "bold" : "medium"}>
            {tDrawingsChecklist("pending")}
          </Typography>
        </Box>
        <Box width="30px" />
      </Box>
      <Box display="flex" alignItems="center" columnGap="1rem">
        <Typography
          fontSize={isTotal ? "1rem" : "0.875rem"}
          color={isTotal ? "#C68D08" : "#E6B60C"}
          flex={2}
          fontWeight="medium"
        >
          {drawingsCounters.total > 0
            ? Math.ceil((drawingsCounters.done * 100) / drawingsCounters.total)
            : "0"}
          %
        </Typography>
        <Typography
          fontWeight={isTotal ? "bold" : "regular"}
          textAlign="center"
          fontSize="0.875rem"
          flex={1}
        >
          {drawingsCounters.done}
        </Typography>
        <Typography
          fontWeight={isTotal ? "bold" : "regular"}
          textAlign="center"
          fontSize="0.875rem"
          flex={1}
        >
          {drawingsCounters.revision}
        </Typography>
        <Typography
          fontWeight={isTotal ? "bold" : "regular"}
          textAlign="center"
          fontSize="0.875rem"
          flex={1}
        >
          {drawingsCounters.pending}
        </Typography>
        <Box width="30px" />
      </Box>
    </Box>
  );
}

export { TotalCompleted };
