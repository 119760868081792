import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useGetEnterpriseProjectsQuery } from "../../../features/enterprise/enterpriseApiSlice";
import { useLazyGetCopyInfoQuery } from "../../../features/project/projectApiSlice";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { PopUpAlert } from "../../PopUpAlert";

function ImportInfo() {
  const { idEnterprise } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { data } = useGetEnterpriseProjectsQuery(idEnterprise);

  const { getValues, reset } = useFormContext();

  const [idProject, setIdProject] = useState(null);

  const [
    getCopyInfo,
    { data: copyInfo, isLoading, isSuccess, isError, error },
  ] = useLazyGetCopyInfoQuery();

  const handleExportInfo = () => {
    getCopyInfo(idProject);
  };

  useEffect(() => {
    if (copyInfo) {
      const defaultValues = getValues();
      reset({ ...defaultValues, ...copyInfo, exportedProject: idProject });
    }
  }, [copyInfo]);

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert(
        "success",
        tGeneral("imported"),
        tGeneral("importedSuccessfully"),
      );
    }
    if (isError) {
      PopUpAlert("error", "Error", error.message);
    }
  }, [isSuccess, isError, error]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      rowGap="0.5rem"
    >
      <Typography textAlign="center">
        {tGeneral("importInfoMessage")}
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
        columnGap="0.5rem"
      >
        <FormControl sx={{ width: "50%" }} size="small">
          <InputLabel>{tGeneral("project")}</InputLabel>
          <Select
            label={tGeneral("project")}
            value={idProject}
            onChange={(e) => setIdProject(e.target.value)}
          >
            {data?.map((project) => (
              <MenuItem value={project.id} key={project.id}>
                {project.projectName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          size="small"
          onClick={handleExportInfo}
          disabled={isLoading}
        >
          {isLoading ? <CircularProgress size="1rem" /> : tGeneral("export")}
        </Button>
      </Box>
    </Box>
  );
}

export { ImportInfo };
