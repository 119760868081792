import { apiSlice } from "../../api/apiSlice";

export const unitApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUnits: builder.query({
      query: (idProject) => `/project/unit/getUnits/${idProject}`,
      keepUnusedDataFor: 60,
      providesTags: ["Units"],
    }),
    addUnit: builder.mutation({
      query: (body) => ({
        url: "/project/unit/addUnit",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Units"],
    }),
    updateUnit: builder.mutation({
      query: (body) => ({
        url: "/project/unit/updateUnit",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Units"],
    }),
    deleteUnit: builder.mutation({
      query: (body) => ({
        url: "/project/unit/deleteUnit",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Units"],
    }),
  }),
});

export const {
  useGetUnitsQuery,
  useAddUnitMutation,
  useUpdateUnitMutation,
  useDeleteUnitMutation,
} = unitApiSlice;
