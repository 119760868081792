import { useCallback, useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";

const checkValue = (value) => {
  return !(value === null || value === undefined);
};

function StyledMaterialTable({
  columns = [],
  data = [],
  onClickRow,
  pageIndex = 0,
  pageSize = 25,
  customHeight = "unset",
  customMaxHeight = "unset",
  onPaginationChange,
  defaultOrderBy = "",
  customTableHeadSx = {
    backgroundColor: "#FAFAFA",
  },
}) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tMaterialTable } = useTranslation("materialTable");
  const id = "material-table";

  const [height, setHeight] = useState(customHeight);
  const [maxHeight, setMaxHeight] = useState(customMaxHeight);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState(defaultOrderBy);

  const updateHeight = useCallback(() => {
    const table = document.getElementById(id);
    if (table) {
      const parent = table.parentNode.parentNode;
      const parentTop = parent.getBoundingClientRect().top;
      const parentBottom = parentTop + parent.offsetHeight;
      if (height === "unset") {
        setHeight(
          `${table.offsetHeight + window.innerHeight - parentBottom}px`,
        );
      }
    }
  }, [height]);

  useEffect(() => {
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [updateHeight]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = data.sort((a, b) => {
    if (orderBy) {
      if (a[orderBy] < b[orderBy]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[orderBy] > b[orderBy]) {
        return order === "asc" ? 1 : -1;
      }
    }
    return 0;
  });

  const handleChangePage = (event, newPage) => {
    onPaginationChange({ pageIndex: newPage, pageSize });
  };

  const handleChangeRowsPerPage = (event) => {
    onPaginationChange({
      pageIndex: 0,
      pageSize: parseInt(event.target.value, 10),
    });
  };

  return (
    <Box>
      <Box style={{ height, maxHeight, overflowX: "hidden" }}>
        <Table
          id={id}
          style={{
            width: "100%",
            border: "5px solid rgb(245, 245, 245)",
            borderRadius: "10px 10px 0 0",
            padding: "0px",
          }}
          stickyHeader
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.field}
                  align="left"
                  style={{
                    ...customTableHeadSx,
                    width: column.size,
                    maxWidth: column.size,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  sortDirection={orderBy === column.field ? order : false}
                >
                  {column.sortable ? (
                    <TableSortLabel
                      active={orderBy === column.field}
                      direction={orderBy === column.field ? order : "asc"}
                      onClick={() => handleSort(column.field)}
                    >
                      {tMaterialTable(column.header)}
                    </TableSortLabel>
                  ) : (
                    tMaterialTable(column.header)
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  {tMaterialTable("noRecordsToDisplay")}
                </TableCell>
              </TableRow>
            )}
            {sortedData.map((row) => (
              <TableRow
                key={row.id}
                hover
                style={{
                  height: "60px",
                  cursor: onClickRow ? "pointer" : "default",
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.field}
                    align="left"
                    style={{
                      width: column.size,
                      maxWidth: column.size,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {column.render
                      ? column.render(row)
                      : `${row[column.field]}`}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box>
        <TablePagination
          style={{
            ...customTableHeadSx,
            width: "100%",
            border: "5px solid rgb(245, 245, 245)",
            borderRadius: "0 0 10px 10px",
            padding: "0px",
          }}
          component="div"
          count={data.length}
          page={pageIndex}
          onPageChange={handleChangePage}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          labelRowsPerPage={tMaterialTable("rowsPerPage")}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${tMaterialTable("of")} ${
              count !== -1 ? count : `more than ${to}`
            }`
          }
        />
      </Box>
    </Box>
  );
}

export { StyledMaterialTable, checkValue };
