export const plan = {
  perUser: "每个用户",
  perProject: "每个项目",
  perMonth: "每月",
  perYear: "每年",
  monthly: "每月",
  annual: "每年",
  bestValue: "最佳价值",
  youWillPay: "您将支付",
  activeProjects: "活跃项目",
  activeUsers: "活跃用户",
};
