import { DoneAll, Translate } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import moment from "moment/moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useSetOfficialResponseMutation } from "../../features/message/messageApiSlice";
import { useGetProjectByIdQuery } from "../../features/project/projectApiSlice";
import { selectCurrentTranslatable } from "../../features/project/projectSlice";
import { useTranslateTextMutation } from "../../features/translation";
import { useGetUserPreferencesQuery } from "../../features/userPreferences/userPreferencesApiSlice";
import { PopUpAlert } from "../PopUpAlert";
import ViewFiles from "../ViewFiles";
import { ExpandedImage, ModalWithAction } from "../shared";

function MessageBox({
  createdBy,
  text,
  files,
  createdDate,
  width,
  isOfficial,
  canMakeOfficial,
  id,
  module,
  question = false,
  isTopic = false,
  bgColor = null,
  bActive = true,
  dateFormat = null,
}) {
  const { idProject } = useParams();
  const [translate, { isLoading }] = useTranslateTextMutation();
  const [translated, setTranslated] = useState(null);
  const [showTranslation, setShowTranslation] = useState(false);
  const [viewFiles, setViewFiles] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const projectTranslatable = useSelector(selectCurrentTranslatable);
  const { data: project } = useGetProjectByIdQuery(idProject);
  const { data: userPreferences } = useGetUserPreferencesQuery();
  const [
    checkAnswer,
    {
      isLoading: isCheckLoading,
      isSuccess: isCheckSuccess,
      isError: isCheckError,
      error: checkError,
    },
  ] = useSetOfficialResponseMutation();

  // Translations
  const { t: tMessage } = useTranslation("message");
  const { t: tGeneral } = useTranslation("general");

  const [openImage, setOpenImage] = useState(false);
  const [expandImage, setExpandImage] = useState(null);
  const [transcriptLang, setTranscriptLang] = useState(null);
  const [transcriptValue, setTranscriptValue] = useState(null);
  const [voicenoteLang, setVoicenoteLang] = useState(null);
  const [voicenoteUrl, setVoicenoteUrl] = useState(null);

  const handleClose = () => {
    setOpenImage(false);
    setExpandImage(null);
  };

  const handleTranslate = async () => {
    if (translated === null) {
      const translatedText = await translate({ text });
      setTranslated(translatedText.data);
    }
    setShowTranslation((prev) => !prev);
  };

  const boxBgColor = useMemo(() => {
    if (bgColor) {
      return bgColor;
    }
    if (isTopic) {
      return "#D3D3D34D";
    }
    if (isOfficial) {
      return "#FFEBBC";
    }
    return "#FFEBBC4D";
  }, [bgColor, isTopic, isOfficial]);

  const handleCheckMessage = () => {
    checkAnswer({ idAnswer: id, module });
  };

  useEffect(() => {
    if (isCheckSuccess) {
      PopUpAlert("success", tGeneral("done"), tMessage("answerCheckedMessage"));
      setIsChecking(false);
    }
    if (isCheckError) {
      PopUpAlert("error", tGeneral("error"), checkError.message);
      setIsChecking(false);
    }
  }, [isCheckSuccess, isCheckError, checkError]);

  const getAudioType = (extension) => {
    switch (extension) {
      case "mp4":
        return "audio/mp4";
      case "wav":
        return "audio/wav";
      case "aac":
        return "audio/aac";
      default:
        return "audio/mpeg";
    }
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: question ? "95%" : "max-content",
          minWidth: width ?? "40%",
          backgroundColor: boxBgColor,
          p: "15px",
          borderRadius: "8px",
          mb: "25px",
          ml: "22px",
          border: isOfficial ? "2px solid #FFC42D" : "none",
        }}
      >
        <Typography
          fontSize="1rem"
          fontWeight="bold"
          color={bActive ? "black" : "gray"}
          mb="12px"
        >
          {createdBy} {!bActive && `(${tGeneral("inactive")})`}
        </Typography>
        <Typography
          mb="12px"
          color="black"
          component="div"
          dangerouslySetInnerHTML={{ __html: text }}
          sx={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
        />
        {files && files.length
          ? files.map((file) => {
              if (
                file.name.includes("voicenote") &&
                (file.extension === "mp4" ||
                  file.extension === "wav" ||
                  file.extension === "aac")
              ) {
                if (
                  userPreferences &&
                  userPreferences.translationFeature !== "off"
                ) {
                  const getTranscript = (lang) => {
                    const transcript =
                      file[`transcriptIn${lang}`] ||
                      file[`transcript_in_${lang.toLowerCase()}`];
                    return (
                      <Button
                        disabled={!transcript}
                        onClick={() => {
                          setTranscriptLang(lang);
                          setTranscriptValue(transcript);
                        }}
                        sx={{
                          color:
                            transcriptLang === lang ? "black" : "primary.main",
                        }}
                      >
                        {lang === "Es"
                          ? "Español (Es)"
                          : lang === "Pt"
                            ? "Português (Pt)"
                            : lang === "En"
                              ? "English (En)"
                              : lang === "Zh"
                                ? "中文 (Zh)"
                                : lang === "Ja"
                                  ? "日本語 (Ja)"
                                  : lang === "Ko"
                                    ? "한국어 (Ko)"
                                    : null}
                      </Button>
                    );
                  };

                  const getVoiceNote = (lang) => {
                    const voiceNote =
                      file[`voiceNoteIn${lang}`] ||
                      file[`voice_note_in_${lang.toLowerCase()}`];
                    return (
                      <Button
                        disabled={!voiceNote}
                        onClick={() => {
                          setVoicenoteLang(lang);
                          setVoicenoteUrl(voiceNote);
                        }}
                        sx={{
                          color:
                            voicenoteLang === lang ? "black" : "primary.main",
                        }}
                      >
                        {lang === "Es"
                          ? "Español (Es)"
                          : lang === "Pt"
                            ? "Português (Pt)"
                            : lang === "En"
                              ? "English (En)"
                              : lang === "Zh"
                                ? "中文 (Zh)"
                                : lang === "Ja"
                                  ? "日本語 (Ja)"
                                  : lang === "Ko"
                                    ? "한국어 (Ko)"
                                    : null}
                      </Button>
                    );
                  };

                  return (
                    <div key={file.id}>
                      <Typography
                        color="black"
                        component="div"
                        mb="12px"
                        sx={{
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                        }}
                      >
                        <strong>View transcript in:</strong>
                        {getTranscript("Es")}
                        {getTranscript("Pt")}
                        {getTranscript("En")}
                        {getTranscript("Zh")}
                        {getTranscript("Ja")}
                        {getTranscript("Ko")}
                      </Typography>

                      <Typography
                        color="black"
                        component="div"
                        mb="12px"
                        sx={{
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                        }}
                      >
                        {transcriptLang && transcriptValue && (
                          <span
                            style={{ marginLeft: "30px", color: "primary" }}
                          >
                            {transcriptValue}
                          </span>
                        )}
                      </Typography>

                      <Typography
                        color="black"
                        component="div"
                        mb="12px"
                        sx={{
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                        }}
                      >
                        <strong>Listen in:</strong>
                        <Button
                          onClick={() => {
                            setVoicenoteLang("og");
                            setVoicenoteUrl(file.url);
                          }}
                          sx={{
                            color:
                              voicenoteLang === "og" || !voicenoteUrl
                                ? "black"
                                : "primary.main",
                          }}
                        >
                          Original
                        </Button>
                        {getVoiceNote("Es")}
                        {getVoiceNote("Pt")}
                        {getVoiceNote("En")}
                        {getVoiceNote("Zh")}
                        {getVoiceNote("Ja")}
                        {getVoiceNote("Ko")}
                      </Typography>
                    </div>
                  );
                }
              }
              return null;
            })
          : null}
        {text && showTranslation && (
          <Box display="flex" mb="12px">
            <Translate sx={{ fontSize: "18px" }} />
            <Box
              sx={{
                border: "1.5px solid #A2A2A2",
                mx: "10px",
                borderRadius: "10px",
              }}
            />
            <Typography
              component="div"
              dangerouslySetInnerHTML={{ __html: translated }}
            />
          </Box>
        )}
        <Box display="flex" mt="18px" alignItems="center" columnGap="8px">
          <Box>
            {files &&
              files.length > 0 &&
              (files.some(
                (file) =>
                  file.name.includes("voicenote") &&
                  (file.extension === "mp4" ||
                    file.extension === "wav" ||
                    file.extension === "aac"),
              ) ? (
                files.map((file) => {
                  if (
                    file.name.includes("voicenote") &&
                    (file.extension === "mp4" ||
                      file.extension === "wav" ||
                      file.extension === "aac")
                  ) {
                    return (
                      <audio controls>
                        <source
                          src={voicenoteUrl || file.url}
                          type={getAudioType(file.extension)}
                        />
                        <track default src="#" kind="captions" />
                      </audio>
                    );
                  }
                  return null;
                })
              ) : (
                <Button
                  sx={{
                    backgroundColor: "white",
                    p: "12px 30px",
                    mt: "20px",
                  }}
                  variant="outlined"
                  onClick={() => setViewFiles(true)}
                >
                  {tGeneral("viewFiles")}
                </Button>
              ))}
          </Box>
          {isOfficial && (
            <Box display="flex" alignItems="center" columnGap="8px">
              <Box
                display="flex"
                sx={{
                  height: "50px",
                  width: "50px",
                  borderRadius: "5px",
                  backgroundColor: "#FFC42D",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <DoneAll sx={{ color: "white" }} />
              </Box>
              <Typography color="primary" fontSize="0.875rem">
                {tMessage("officialAnswer")}
              </Typography>
            </Box>
          )}
          {canMakeOfficial && (
            <Box display="flex">
              <Button
                variant="contained"
                sx={{ backgroundColor: "white", height: "50px", width: "50px" }}
                disabled={isCheckLoading}
                onClick={() => setIsChecking(true)}
              >
                {isCheckLoading ? (
                  <CircularProgress size="small" />
                ) : (
                  <DoneAll color="primary" />
                )}
              </Button>
            </Box>
          )}
          <Box flex={1} />
          <Typography color="#B0B7C1">
            {dateFormat
              ? moment(createdDate)
                  .tz(
                    project?.projectTimezone
                      ? project?.projectTimezone
                      : "America/Monterrey",
                  )
                  .format(`${dateFormat} | h:mm A`)
              : moment(createdDate)
                  .tz(
                    project?.projectTimezone
                      ? project?.projectTimezone
                      : "America/Monterrey",
                  )
                  .format("DD MMMM YYYY  |  h:mm A")}
          </Typography>
        </Box>
        <Modal
          open={viewFiles}
          onClose={() => setViewFiles(false)}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <ViewFiles
              files={files}
              setImageOpen={(value) => {
                setOpenImage(true);
                setExpandImage(value);
              }}
            />
          </Box>
        </Modal>
      </Box>
      {expandImage && (
        <ExpandedImage
          expandImage={expandImage}
          openImage={openImage}
          handleClose={handleClose}
        />
      )}
      {isChecking && (
        <ModalWithAction
          action={handleCheckMessage}
          isLoading={isCheckLoading}
          title={tMessage("officialAnswer")}
          actionLabel={tGeneral("accept")}
          content={tMessage("setOfficialAnswerContent")}
          isOpen={isChecking}
          onClose={() => {
            setIsChecking(false);
          }}
        />
      )}
    </>
  );
}

export { MessageBox };
