import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

//This function turns the user's name into a unique hex color
//No longer in use
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  let formattedName = name.split(" ").filter(el => el !== '')
  return {
    sx: {
      bgcolor: stringToColor(name),
      //color: "white",

      //fontSize: 12,
    },
    children: `${formattedName[0][0]}${formattedName[1][0]}`,
  };
}

function UserAvatar({
  name,
  size,
  url,
  width = null,
  height = null,
  bActive = true,
  ...avatarStyles
}) {
  let sWidth, sHeight;
  if (size === "Big") {
    sWidth = 100;
    sHeight = 100;
  } else if (size === "Medium") {
    sWidth = 55;
    sHeight = 55;
  } else if (size === "Small") {
    sWidth = 30;
    sHeight = 30;
  } else {
    sWidth = 40;
    sHeight = 40;
  }

  let userImage;

  if (url != null) {
    userImage = (
      <Avatar
        alt={name}
        src={url}
        sx={{
          width: width ?? sWidth,
          height: height ?? sHeight,
          border: width ? 8 : 2,
          borderColor: "white",
          opacity: bActive ? 1 : 0.5,
          ...avatarStyles,
        }}
      />
    );
  } else if (name) {
    userImage = (
      <Avatar
        {...stringAvatar(name)}
        sx={{
          width: width ?? sWidth,
          height: height ?? sHeight,
          bgcolor: "#ffc900",
          border: width ? 8 : 2,
          borderColor: "white",
          fontSize: width ? 80 : size === "Big" ? 35 : 12,
          opacity: bActive ? 1 : 0.5,
          ...avatarStyles,
        }}
      />
    );
  }

  return (
    <Stack direction="row" spacing={2}>
      {userImage}
    </Stack>
  );
}

export default UserAvatar;
