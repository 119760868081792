const COLORS = [
  { color: "#8E8E8E", name: "grey" },
  { color: "#CACACA", name: "lightGrey" },
  { color: "#9E640A", name: "brown" },
  { color: "#FF7300", name: "orange" },
  { color: "#FFE009", name: "yellow" },
  { color: "#7ED957", name: "green" },
  { color: "#5571FF", name: "blue" },
  { color: "#CB6CBE", name: "purple" },
  { color: "#5CE1E6", name: "lightBlue" },
  { color: "#E5E900", name: "lightYellow" },
];

export { COLORS };
