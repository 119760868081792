import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedFolderId: "",
  selectedFolder: {},
  needReload: false,
};

export const projectFolderSlice = createSlice({
  name: "projectFolder",
  initialState,
  reducers: {
    setSelectedFolderId: (state, action) => {
      state.selectedFolderId = action.payload;
    },
    setSelectedFolder: (state, action) => {
      state.selectedFolder = action.payload;
    },
    setNeedReload: (state, action) => {
      state.needReload = action.payload;
    },
  },
});

export const selectSelectedFolderId = (state) =>
  state.projectFolder.selectedFolderId;

export const selectSelectedFolder = (state) =>
  state.projectFolder.selectedFolder;

export const selectNeedReload = (state) => state.projectFolder.needReload;

export const { setSelectedFolderId, setSelectedFolder, setNeedReload } =
  projectFolderSlice.actions;

export default projectFolderSlice.reducer;
