export const submittals = {
  submittal: "提交",
  submittalTitleModule: "提交",
  deleteSubmittalsTitle: "删除提交",
  deleteSubmittalsContent: "您确定要删除这些提交吗？",
  addSubmittal: "添加提交",
  noSubmittalsTitle: "目前没有创建任何提交",
  addSubmittalsValidation: "如果您想添加一个，请点击“添加提交”按钮开始该过程",
  submittalRecycleBin: "提交回收站",
  costCode: "成本代码",
};
