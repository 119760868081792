import { Button, Typography } from "@mui/material";
import { nameValidator } from "../services/validators/nameValidator";

const KpiSteps = [
  {
    selector: ".Kpi-table",
    content:
      "Todas las estadisticas de actividad de cada empresa aparecerán aquí, solo podrás ver los datos y descargarlos (no se pueden editar).",
    disableActions: false,
  },
  {
    selector: "button[aria-label=Export]",
    content: "Tambien pudes exportar esta tabla como un archivo csv o pdf",
    disableActions: false,
  },
  {
    selector: "canvas",
    content:
      "Aqui podrás ver la gráfica de inactividad de empresas, donde podras ver el periodo actual, el pasado o incluso compararlos. Da click en los botones 'actual period y last period' ",
    disableActions: false,
  },
];

const KpiColumns = [
  {
    field: "enterpriseName",
    title: "Enterprise",
    width: 120,
    // editable: 'onUpdate'
    initialEditValue: "",
    validate: (rowData) =>
      rowData.enterpriseName === ""
        ? { isValid: false, helperText: "Cannot be empty" }
        : true,
    render: (rowData) => (
      <Typography sx={{ fontWeight: "bold" }}>
        {rowData.enterpriseName}
      </Typography>
    ),
  },
  {
    field: "logo",
    title: "Logo",
    width: 75,
    render: () => (
      <img
        style={{
          width: 40,
          height: 40,
          borderRadius: "50%",
        }}
        src="https://i.natgeofe.com/k/e1b408e3-19b2-4ff1-85bf-3af14958e9ac/t-rex-og-dino-news.jpg?w=795&h=530"
        alt="Avatar"
      />
    ),
    sortable: false,
    filterable: false,
    export: false,
  },
  {
    field: "projectName",
    title: "Project",
    width: 120,
    // editable: 'onUpdate'
    initialEditValue: "",
    render: (rowData) => (
      <Button
        variant="text"
        color="info"
        className={`phone-button-${rowData.tableData.id}`}
        sx={{ textDecoration: "underline" }}
        onClick={() => window.open(`tel:${rowData.phone}`)}
      >
        {rowData.phone}
      </Button>
    ),
    // type: 'numeric', //check if use or not
    // validate: rowData => (rowData.phone !== '' && phoneValidator(rowData.phone) === 1) ? true : { isValid: false, helperText: 'Phone invalid' }, //check phoneValidator
    validate: (rowData) =>
      rowData.phone !== "" && rowData.phone.length === 10
        ? true
        : { isValid: false, helperText: "Phone invalid" },
  },
  {
    field: "objectsCreated",
    title: "Objects created",
    width: 170,
    initialEditValue: "",
    validate: (rowData) =>
      rowData.firstName !== "" && nameValidator(rowData.firstName) === 1
        ? true
        : { isValid: false, helperText: "Max 15 characters" },
    // editable: 'onUpdate',
  },
  {
    field: "moduleName",
    title: "Module name",
    width: 190,
    // editable: 'onUpdate',
    initialEditValue: "",
    validate: (rowData) =>
      rowData.lastName !== "" && nameValidator(rowData.lastName) === 1
        ? true
        : { isValid: false, helperText: "Max 15 characters" },
  },
  {
    field: "lastActivity",
    title: "Last activity",
    width: 190,
    // editable: 'onUpdate',
    initialEditValue: "",
    validate: (rowData) =>
      rowData.lastName !== "" && nameValidator(rowData.lastName) === 1
        ? true
        : { isValid: false, helperText: "Max 15 characters" },
  },
  /*  {
    field: 'actions',
    title: 'Actions',
    type: 'actions',
    renderCell: params => <usersActions {...{ params }} />
  } */
];

export { KpiSteps, KpiColumns };
