export const enterprise = {
  construction: "건설",
  realState: "부동산",
  architect: "건축가",
  contractor: "계약자",
  subcontractor: "하도급업자",
  maintenance: "유지보수",
  developer: "개발자",
  generalContractor: "총괄계약자",
  other: "기타",
};
