import { Box, Typography, useMediaQuery } from "@mui/material";

const companies = [
  { src: require("./../../assets/companies/inverta.png"), alt: "INVERTA" },
  { src: require("./../../assets/companies/romsega.png"), alt: "ROMSEGA" },
  { src: require("./../../assets/companies/orven.png"), alt: "Orven" },
  {
    src: require("./../../assets/companies/tec.png"),
    alt: "Tec Constructora",
  },
  {
    src: require("./../../assets/companies/bsp.png"),
    alt: "Blue Solar Power",
  },
  { src: require("./../../assets/companies/tecosa.png"), alt: "Tecosa" },
  {
    src: require("./../../assets/companies/forte-deck.png"),
    alt: "Forte Deck",
  },
  {
    src: require("./../../assets/companies/galan-galan.png"),
    alt: "Galán Galán",
  },
  { src: require("./../../assets/companies/velosa.png"), alt: "Velosa" },
  {
    src: require("./../../assets/companies/plus-steel.png"),
    alt: "Plus Steel",
  },
];
function Companies() {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Box
      sx={[
        {
          display: "flex",
          justifyContent: "center",
          paddingX: "10%",
          alignItems: "center",
          backgroundColor: "white",
          paddingY: "200px",
        },
        isMobile && { flexDirection: "column" },
      ]}
    >
      <Box sx={{ flex: 2 }}>
        <Typography
          variant={isMobile ? "h4" : "h3"}
          fontWeight="bold"
          textAlign={isMobile && "center"}
        >
          Great companies that trust us
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexFlow: "row wrap",
          flex: 5,
          justifyContent: isMobile && "center",
        }}
      >
        {companies.map(({ src, alt }) => (
          <Box
            key={alt}
            sx={{
              width: isMobile ? "100px" : "200px",
              height: isMobile ? "100px" : "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ":hover": {
                borderRadius: "10px",
                boxShadow: "0px 20px 40px #00000029;",
              },
            }}
          >
            <img
              src={src}
              alt={alt}
              style={{
                maxWidth: isMobile && "80px",
                maxHeight: isMobile && "80px",
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export { Companies };
