import { Box } from "@mui/material";

const SupportContent = ({ children }) => {
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      justifyContent="space-around"
      mb="20px"
      rowGap="1rem"
    >
      {children}
    </Box>
  );
};

export { SupportContent };
