import { apiSlice } from "../../api/apiSlice";

export const logApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // get user
    // getUser: builder.query({
    //   query: () => "/user/get",
    //   keepUnusedDataFor: 60,
    //   providesTags: ["Logs"],
    // }),
    // add user log
    addLog: builder.mutation({
      query: (body) => ({
        url: "log/add",
        method: "POST",
        body,
      }),
      providesTags: ["Logs"],
    }),
    // get user log
    // getLog: builder.query({
    //   query: () => "/log/get",
    //   keepUnusedDataFor: 60,
    //   providesTags: ["Logs"],
    // }),
    getLogsByObjectId: builder.query({
      query: (idObject) => `/log/getUsersLogByIdObject/${idObject}`,
      keepUnusedDataFor: 60,
      providesTags: ["Logs"],
    }),
    getModifiedByLogIdObject: builder.query({
      query: (idObject) => `/log/getModifiedByLogIdObject/${idObject}`,
      keepUnusedDataFor: 60,
      providesTags: ["Logs"],
    }),
    getSeenByLogIdObject: builder.query({
      query: (idObject) => `/log/getSeenByLogIdObject/${idObject}`,
      keepUnusedDataFor: 60,
      providesTags: ["Logs"],
    }),
    addUserLog: builder.mutation({
      query: (body) => ({
        url: `/log/addUserLog`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Logs"],
    }),
  }),
});

export const {
  // useGetUserQuery,
  useAddLogMutation,
  // useGetLogQuery,
  useGetLogsByObjectIdQuery,
  useGetModifiedByLogIdObjectQuery,
  useGetSeenByLogIdObjectQuery,
  useAddUserLogMutation,
} = logApiSlice;
