const TooltipFooter = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
      }}
    >
      {children}
    </div>
  );
};

export { TooltipFooter };
