import { datadogRum } from "@datadog/browser-rum";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import {
  AssignedUsersTooltip,
  StyledMaterialReactTable,
  TitleWidgets,
} from "../../components/shared";
import {
  useGetInactiveInspectionsTemplatesQuery,
  useRecoverInspectionTemplateMutation,
} from "../../features/project/modules/inspectionsTemplates/apiSlice";
import { capitalizeFirstLetterOfSentence } from "../../services/helpers/capitalizeFirstLetterOfSentence";
import { formatDate } from "../../services/helpers/dateFormat";

function InspectionsTemplatesRecycle() {
  const { idProject, idEnterprise } = useParams();
  const { t: tInspections } = useTranslation("inspections");
  const { t: tInspectionsTemplates } = useTranslation("inspectionsTemplates");
  const { t: tGeneral } = useTranslation("general");

  const [restoreIds, setRestoreIds] = useState({});

  const { data: inspectionsTemplates, isSuccess } =
    useGetInactiveInspectionsTemplatesQuery(
      { idProject },
      {
        skip: !idProject,
      },
    );
  const [recoverInspectionsTemplates, { isSuccess: isSuccessRecover }] =
    useRecoverInspectionTemplateMutation();

  function showAlert(message, type) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: type,
      title: message,
    });
  }

  // Restore inspection template
  const restoreInspectionTemplate = async (e) => {
    try {
      const response = await recoverInspectionsTemplates({
        ids: Object.keys(restoreIds),
        idProject,
      }).unwrap();
      if (response) {
        setRestoreIds({});
        showAlert(`Template ${tGeneral("recoveredSuccessfully")}`, "success");
      }
    } catch (err) {
      showAlert(tGeneral("cantRestore"), "error");
      if (datadogRum?.addError) {
        datadogRum.addError({
          message: "Inspections - Template Restore - Error",
          stack: err.stack ? err.stack : "invalid stack",
          custom: {
            app: "buildpeer-web",
            enterprise: idEnterprise,
            module: "Inspections",
            project: idProject,
          },
          timestamp: Date.now(),
        });
      }
    }
  };

  const columns = [
    {
      accessorKey: "number",
      header: "Num",
    },
    {
      accessorKey: "title",
      header: tGeneral("title"),

      Cell: ({ cell }) => (
        <Typography
          fontSize="1rem"
          maxWidth="200px"
          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {capitalizeFirstLetterOfSentence(cell.getValue())}
        </Typography>
      ),
    },
    {
      accessorKey: "code",
      header: tGeneral("code"),
      Cell: ({ cell }) => (
        <Typography
          fontSize="1rem"
          maxWidth="200px"
          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {cell.getValue()}
        </Typography>
      ),
    },
    {
      accessorKey: "category",
      header: tGeneral("category"),

      Cell: ({ cell }) => (
        <Typography
          fontSize="1rem"
          maxWidth="200px"
          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {cell.getValue()}
        </Typography>
      ),
    },
    {
      accessorKey: "updatedDate",
      header: tGeneral("updated"),
      Cell: ({ cell }) => (
        <Typography fontSize="1rem">{formatDate(cell.getValue())}</Typography>
      ),
    },
    {
      header: tInspections("items"),
      Cell: ({ row }) => (
        <Typography
          fontSize="1rem"
          maxWidth="200px"
          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {row?.original?.sections
            ?.map((section) => section.items.length)
            .reduce((a, b) => a + b, 0)}
        </Typography>
      ),
    },
    {
      accessorKey: "createdByUser",
      header: tGeneral("createdBy"),
      Cell: ({ cell }) => <AssignedUsersTooltip users={[cell.getValue()]} />,
    },
  ];
  return (
    <Box>
      <TitleWidgets
        title={`${tGeneral("recycleBin")} (${tInspectionsTemplates("title")})`}
        isRecycle
        backEnabled
        restoreDisabled={!Object.keys(restoreIds).length}
        onRestore={restoreInspectionTemplate}
      />
      <StyledMaterialReactTable
        columns={columns}
        data={inspectionsTemplates ?? []}
        enableRowActions={false}
        enableRowSelection
        selectedRows={restoreIds}
        setSelectedRows={setRestoreIds}
      />
    </Box>
  );
}

export default InspectionsTemplatesRecycle;
