import { tour } from "./tour";

export const meetings = {
  // general tour
  ...tour,
  moduleElement: "Reuniones",
  // ADD - guide me steps
  step1_addMeeting_title: "1. Add meeting.",
  step1_addMeeting_text: "Click on the + icon to add a new meeting.",
  step2_addMeeting_title: "2. Title",
  step2_addMeeting_text: "Write a title for the meetings.",
  step3_addMeeting_title: "3. Privacy",
  step3_addMeeting_text:
    "Select if the meetings will be visible to everyone or not.",
  step3_addMeeting_text_2:
    "Anyone with access to the module will see your meeting.",
  step3_addMeeting_text_3:
    "Only participants will be able to see this meeting.",
  step4_addMeeting_title: "4. Meeting Date",
  step4_addMeeting_text: "Set the date and time of the meeting.",
  step5_addMeeting_title: "5. Location",
  step5_addMeeting_text: "Determine the location of the meetings.",
  step6_addMeeting_title: "6. Meeting URL",
  step6_addMeeting_text: "Enter the URL of the meetings here.",
  step7_addMeeting_title: "7. Status",
  step7_addMeeting_text_1_1: "Choose the status",
  step7_addMeeting_text_1_2: "OPEN public for the people involved.",
  step7_addMeeting_text_2_1: "Choose the status",
  step7_addMeeting_text_2_2: "DRAFT save as a draft.",
  step8_addMeeting_title: "8. Attendees",
  step8_addMeeting_text: "Select the people required for the meeting.",
  step9_addMeeting_title: "9. Distribution List",
  step9_addMeeting_text:
    "Select the people who will receive notifications of the meeting.",
  step10_addMeeting_title: "10. New item",
  step10_addMeeting_text: "Add a new item to the meeting.",
  step11_addMeeting_title: "11. Item Name",
  step11_addMeeting_text: "Write the name of the item.",
  step12_addMeeting_title: "12. Topics to Discuss",
  step12_addMeeting_text:
    "Write the topics to be discussed in the meetings item.",
  step13_addMeeting_title: "13. Time",
  step13_addMeeting_text: "Determine the estimated time for the item.",
  step14_addMeeting_title: "14. Files",
  step14_addMeeting_text: "Upload relevant files for the meetings.",
  step15_addMeeting_title: "15. Add",
  step15_addMeeting_text: "Click to confirm the new item",
  step16_addMeeting_title: "16. Create",
  step16_addMeeting_text: "Click here to create the meeting.",
  step_final_addMeeting: "You have learned how to create a meeting.",
  // VIEW - guide me steps
  step1_viewMeeting_title: "1. View meeting.",
  step1_viewMeeting_text: "Click to open a meeting.",
  step2_viewMeeting_title: "2. Information",
  step2_viewMeeting_text:
    "In this section, you can view the main information of the meeting summary.",
  step3_viewMeeting_title: "3. URL",
  step3_viewMeeting_text:
    "Click here to open the URL, or click on the icon on the right to copy it to the clipboard.",
  step4_viewMeeting_title: "4. Agenda",
  step4_viewMeeting_text:
    "This section contains the information of the agenda.",
  step5_viewMeeting_title: "5. Item",
  step5_viewMeeting_text: "Click on an item to view its content.",
  step6_viewMeeting_title: "6. Topic",
  step6_viewMeeting_text: "Click on a topic to view its content.",
  step7_viewMeeting_title: "7. Comments",
  step7_viewMeeting_text:
    "In this section, you can add comments about the topics.",
  step8_viewMeeting_title: "8. Tasks",
  step8_viewMeeting_text:
    "The tasks created under the topic will be located here.",
  step_final_viewMeeting: "You have learned how to see the info of a meeting.",
  // EDIT - guide me steps
  step1_editMeeting_title: "1. Edit a meeting.",
  step1_editMeeting_text: "Click here to edit the meeting.",
  step2_editMeeting_title: "2. Title",
  step2_editMeeting_text: "Edit the title of the meetings",
  step3_editMeeting_title: "3. Privacy",
  step3_editMeeting_text: "Edit the privacy of the meeting.",
  step3_editMeeting_text_2:
    "Anyone with access to the module will see your meeting.",
  step3_editMeeting_text_3:
    "Only participants will be able to see this meeting.",
  step4_editMeeting_title: "4. Meeting Date",
  step4_editMeeting_text: "Edit the date and time of the meeting.",
  step5_editMeeting_title: "5. Location",
  step5_editMeeting_text: "Edit the location of the meeting.",
  step6_editMeeting_title: "6. Meeting URL",
  step6_editMeeting_text: "Edit the URL of the meeting.",
  step7_editMeeting_title: "7. Meeting Status",
  step7_editMeeting_text_1_1: "Choose the status",
  step7_editMeeting_text_1_2: "OPEN public for the people involved.",
  step7_editMeeting_text_2_1: "Choose the status",
  step7_editMeeting_text_2_2: "DRAFT save as a draft.",
  step8_editMeeting_title: "8. Attendees",
  step8_editMeeting_text: "Add or remove the people required for the meeting.",
  step9_editMeeting_title: "9. Distribution List",
  step9_editMeeting_text:
    "Edit the people who will receive notifications of the meeting.",
  step10_editMeeting_title: "10. Item Name",
  step10_editMeeting_text: "Edit the name of the item.",
  step11_editMeeting_title: "11. Discussion Topics",
  step11_editMeeting_text: "Edit the discussion topics of the item.",
  step12_editMeeting_title: "12. Estimated Time",
  step12_editMeeting_text: "Edit the estimated time for the item.",
  step13_editMeeting_title: "13. Files",
  step13_editMeeting_text: "Edit or add files related to the meeting.",
  step14_editMeeting_title: "14. New item",
  step14_editMeeting_text: "Add a new item to the meeting.",
  step15_editMeeting_title: "15. Save",
  step15_editMeeting_text: "Click here to save the changes for the meeting",
  step_final_editMeeting: "You have learned how to edit a meeting.",
  // RECYCLE - guide me steps
  step1_recycleMeeting_title: "2. Meetings Recycle Bin",
  step1_recycleMeeting_text: "Click here to open the meeting recycle bin.",
  step2_recycleMeeting_title: "3. Recycle Bin Menu",
  step2_recycleMeeting_text: "This is the meeting bin menu.",
  step3_recycleMeeting_title: "4. View a meeting.",
  step3_recycleMeeting_text:
    "Click here to see the information of the meeting that you want to restore.",
  step4_recycleMeeting_title: "5. Select meetings.",
  step4_recycleMeeting_text: "Select the meetings you want to restore.",
  step5_recycleMeeting_title: "6. Restaurar reuniones seleccionadas.",
  step5_recycleMeeting_text: "Click here to restore selected meetings.",
  step_final_recycleMeeting:
    "You've learned how to interact with the meeting recycle bin.",
  // SETTINGS - guide me steps
  step1_settingsMeeting_title: "2. Open settings menu",
  step1_settingsMeeting_text: "Click here to open the settings menu.",
  step2_settingsMeeting_title: "3. Distribution list",
  step2_settingsMeeting_text:
    "Add or remove people who will have default access to the meeting module.",
  step3_settingsMeeting_title: "4. Save",
  step3_settingsMeeting_text: "Click here to save the settings.",
  step_final_settingsMeeting: "You have learned how to ajust the settings.",
  // EXPORT - guide me steps
  step1_exportMeeting_title: "1. Export meeting",
  step1_exportMeeting_text:
    "Click here to export the information of the meetings through email.",
  step_final_exportMeeting: "You have learned how to export a meeting.",

  meetings: "Meetings",
  addMeeting: "Schedule a Meeting",
  deleteMeeting: "Delete Meeting",
  messageDelete:
    "¿Are you sure you want to delete the selected meetings? By performing this action, it cannot be reversed.",
  meetingDeleted: "Meeting deleted successfully",
  errorMeetingDeleted: "Can't delete meeting",
  meetingRecovered: "Meeting recovered successfully",
  errorMeetingRecovered: "Can't restore meeting",
  meetingsRecycleBin: "Meetings Recycle Bin",
  meetingCreated: "Meeting created succesfully",
  errorMeetingCreated: "Can't create meeting",
  meetingUpdated: "Meeting updated succesfully",
  errorMeetingUpdated: "Can't update meeting",
  editMeeting: "Edit Meeting",
  urlMeeting: "URL Meeting",
  agenda: "Agenda",
  meetingStarted: "Meeting started successfully",
  errorMeetingStarted: "Can't start current meeting",
  meetingClosed: "Meeting closed successfully",
  errorMeetingClosed: "Meeting can't be closed",
  startMeeting: "Start meeting",
  noAgendaItems: "No agenda items",
  closeMeeting: "Close meeting",
  noElementsTitle: "¡Time to start!",
  noElementsSubtitle:
    "¿Why don't you create your first meeting to plan your next tasks? Click the button below to begin.",
  newAgendaItem: "New Agenda Item",
  topicsToDiscuss: "Topics to discuss",
  attachOrDrop: "Attach file(s) or drag and drop here",
  viewFiles: "View files",
  noTopics: "No topics",
  taskSuccessfullyDeleted: "Task deleted successfully",
  taskCantDelete: "Task can't be deleted",
  taskName: "Task name",
  newTask: "New Task",
  deleteTask: "Delete Task",
  deleteTaskQuestion: "Are you sure you want to delete this task?",
  noMeetings: "No meetings in the recycle bin",
  confirmedAssistance: "Confirmed attendance",
};
