import { apiSlice } from "../../../api/apiSlice";
import { objectToParamString } from "../../../utils";

export const rfiApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getRfis: builder.query({
      query: ({ idProject, params }) =>
        `/project/rfis/${idProject}/get${objectToParamString(params)}`,
      keepUnusedDataFor: 60,
      providesTags: ["RFIs"],
    }),
    getActiveRfis: builder.query({
      query: (params) =>
        `project/rfis/${params.idProject}/get?bActive=true&assignees=${params.assignees}&creator=${params.creator}&status=${params.status}&privacy=${params.privacy}`,
      keepUnusedDataFor: 10,
      providesTags: ["RFIs"],
    }),
    getInactiveRfis: builder.query({
      query: (params) =>
        `project/rfis/${params.idProject}/get?bActive=false&assignees=${params.assignees}&creator=${params.creator}&status=${params.status}&privacy=${params.privacy}`,
      keepUnusedDataFor: 10,
      providesTags: ["RFIs"],
    }),
    getRfiById: builder.query({
      query: (idRfi) => `project/rfis/get/${idRfi}`,
      providesTags: ["RFIs"],
    }),
    createRfi: builder.mutation({
      query: (body) => ({
        url: "project/rfis/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["RFIs"],
    }),
    deleteRfi: builder.mutation({
      query: (body) => ({
        url: "project/rfis/delete",
        method: "POST",
        body,
      }),
      invalidatesTags: ["RFIs"],
    }),
    recoverRfi: builder.mutation({
      query: (body) => ({
        url: "project/rfis/recover",
        method: "POST",
        body,
      }),
      invalidatesTags: ["RFIs"],
    }),
    setPrivateRfi: builder.mutation({
      query: (body) => ({
        url: "project/rfis/setPrivate",
        method: "POST",
        body,
      }),
      // providesTags: ["Projects"],
    }),
    setPublicRfi: builder.mutation({
      query: (body) => ({
        url: "project/rfis/setPublic",
        method: "POST",
        body,
      }),
      // providesTags: ["Projects"],
    }),
    publishRfi: builder.mutation({
      query: (body) => ({
        url: "project/rfis/publish",
        method: "POST",
        body,
      }),
      // providesTags: ["Projects"],
    }),
    setDraftRfi: builder.mutation({
      query: (body) => ({
        url: "project/rfis/setDraft",
        method: "POST",
        body,
      }),
      // providesTags: ["Projects"],
    }),
    editRfi: builder.mutation({
      query: (body) => ({
        url: "project/rfis/edit",
        method: "POST",
        body,
      }),
      invalidatesTags: ["RFIs", "Message"],
    }),
    closeRfi: builder.mutation({
      query: (body) => ({
        url: "project/rfis/close",
        method: "POST",
        body,
      }),
      invalidatesTags: ["RFIs", "Dashboard"],
    }),
    sendEmailRfi: builder.mutation({
      query: (body) => ({
        url: "project/rfis/sendMailRfis",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects"],
    }),
    getRfisStatus: builder.mutation({
      query: (body) => ({
        url: "project/rfis/getRfis",
        method: "POST",
        body,
      }),
      invalidateTags: ["RFIs"],
    }),
    getLastRfi: builder.query({
      query: (idProject) => `/project/rfis/lastRfi/${idProject}`,
      keepUnusedDataFor: 10,
      providesTags: ["RFIs"],
    }),
  }),
});

export const {
  useGetRfisQuery,
  useGetActiveRfisQuery,
  useGetInactiveRfisQuery,
  useCreateRfiMutation,
  useEditRfiMutation,
  useDeleteRfiMutation,
  useCloseRfiMutation,
  useSendEmailRfiMutation,
  useGetRfisStatusMutation,
  useGetLastRfiQuery,
  useRecoverRfiMutation,
  useGetRfiByIdQuery,
} = rfiApiSlice;
