import MaterialTable from "material-table";
import React, { forwardRef, useState } from "react";
// redux queries
// import { selectCurrentEnterprise } from "../../features/enterprise/enterpriseSlice";
// Redux reducers & mutations
import AddBox from "@mui/icons-material/AddBox";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Check from "@mui/icons-material/Check";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Clear from "@mui/icons-material/Clear";
import Edit from "@mui/icons-material/Edit";
import FilterList from "@mui/icons-material/FilterList";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import Remove from "@mui/icons-material/Remove";
import SaveAlt from "@mui/icons-material/SaveAlt";
import Search from "@mui/icons-material/Search";
import ViewColumn from "@mui/icons-material/ViewColumn";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { selectTheme } from "../../features/preferences/preferencesSlice";
import {
  useGetNotificationValuesQuery,
  useGetUserQuery,
  useUpdateNotificationValuesMutation,
} from "../../features/notifications/notificationsApiSlice";
// Custom Components
import LoadingOverlay from "../../components/LoadingOverlay";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),

  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function Notifications() {
  const darkMode = useSelector(selectTheme);
  const [data, setData] = useState([]);
  const [newValues, setNewValues] = useState([]);
  const { data: user, isSuccess } = useGetUserQuery();
  const {
    data: getNotificationsData,
    isLoading,
    isSuccess: isSuccessNotifications,
  } = useGetNotificationValuesQuery(user?.id);
  const [updateNotificationValues] = useUpdateNotificationValuesMutation();

  const [updateNotification, setUpdateNotification] = useState();

  // Table column configuration
  const [columns, setColumns] = useState([
    {
      title: " ",
      width: 170,
      field: "name",
      editable: "never",
    },
    {
      title: "Email",
      field: "email",
      editComponent: (props) => {
        return (
          <input
            type="checkbox"
            checked={props.value}
            onChange={(e) => props.onChange(e.target.checked)}
          />
        );
      },
      render: (rowdata) => (
        <input type="checkbox" checked={rowdata.email} disabled="disabled" />
      ),
    },
    {
      title: "Phone",
      field: "phone",
      editComponent: (props) => {
        return (
          <input
            type="checkbox"
            checked={props.value}
            onChange={(e) => props.onChange(e.target.checked)}
          />
        );
      },
      render: (rowdata) => (
        <input type="checkbox" checked={rowdata.phone} disabled="disabled" />
      ),
    },
    {
      title: "App",
      field: "app",
      editComponent: (props) => {
        return (
          <input
            type="checkbox"
            checked={props.value}
            onChange={(e) => props.onChange(e.target.checked)}
          />
        );
      },
      render: (rowdata) => (
        <input type="checkbox" checked={rowdata.app} disabled="disabled" />
      ),
    },
  ]);

  const assignData = async () => {
    const arrNames = Object.keys(getNotificationsData[0]);
    for (let i = 2; i < arrNames.length; i++) {
      const columnName = arrNames[i];
      const arrValues = getNotificationsData[0][arrNames[i]];
      let email = false; // if true: +4 else: -4
      let phone = false; // if true: +2 else: -2
      let app = false; // if true: +1 else: -1
      switch (arrValues) {
        case 0: // Binary: 000
          email = false;
          phone = false;
          app = false;
          break;
        case 1: // Binary: 001
          email = false;
          phone = false;
          app = true;
          break;
        case 2: // Binary: 010
          email = false;
          phone = true;
          app = false;
          break;
        case 3: // Binary: 011
          email = false;
          phone = true;
          app = true;
          break;
        case 4: // Binary: 100
          email = true;
          phone = false;
          app = false;
          break;
        case 5: // Binary: 101
          email = true;
          phone = false;
          app = true;
          break;
        case 6: // Binary: 110
          email = true;
          phone = true;
          app = false;
          break;
        case 7: // Binary: 111
          email = true;
          phone = true;
          app = true;
          break;
        default:
          email = false;
          phone = false;
          app = false;
          break;
      }
      data.push({ name: columnName, email, phone, app });
    }
  };

  // Get values query
  const getValues = async () => {
    if (isSuccess) {
      if (isSuccessNotifications) {
        const assignValues = await assignData();
      }
    }
  };

  const handleChange = async (newData) => {
    let newValue = 0;
    if (newData.email == true) {
      newValue += 4;
    }
    if (newData.phone == true) {
      newValue += 2;
    }
    if (newData.app == true) {
      newValue += 1;
    }
    const columnName = newData.name;
    try {
      const response = await updateNotificationValues({
        idUser: user.id,
        columnName,
        newValue,
      }).unwrap();
    } catch (err) {
      console.error(err);
    }
  };

  getValues();

  return (
    <>
      <Box sx={{ height: 400, width: "100%" }}>
        <MaterialTable
          title="Notifications"
          icons={tableIcons}
          columns={columns}
          data={data}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataUpdate = [...data];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  setData([...dataUpdate]);
                  resolve();
                  handleChange(newData);
                }, 1000);
              }),
          }}
          options={{
            actionsColumnIndex: -1,
            rowStyle: (rowData) => ({
              backgroundColor: darkMode,
            }),
          }}
        />
      </Box>
      <LoadingOverlay open={isLoading} />
    </>
  );
}

export default Notifications;
