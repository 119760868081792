import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { PopUpAlert } from "../../../components/PopUpAlert";
import { ModalWithAction, TitleWidgets } from "../../../components/shared";
import { useAppTourContext } from "../../../components/supportButton/context/context";
import {
  useDeleteRevisionsMutation,
  useGetDrawingsRecycleBinQuery,
  useRestoreRevisionsMutation,
} from "../../../features/drawings";
import { selectCurrentGuideMe } from "../../../features/project/projectSlice";
import { DrawingsTable } from "./DrawingsTable";

function DrawingsRecycleBin() {
  const { idProject } = useParams();

  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");
  const currentGuideMe = useSelector(selectCurrentGuideMe);
  const [selectedRevisions, setSelectedRevisions] = useState({});
  const [showDeleting, setShowDeleting] = useState(false);

  const [
    deleteRevisions,
    {
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      error: deleteError,
    },
  ] = useDeleteRevisionsMutation();

  const { data } = useGetDrawingsRecycleBinQuery(idProject);
  const [restoreRevisions, { isLoading, isSuccess, isError, error }] =
    useRestoreRevisionsMutation();

  const revisions = useMemo(() => {
    if (data) {
      return data;
    }
    return [];
  }, [data]);

  const handleRestore = async () => {
    await restoreRevisions({ ids: Object.keys(selectedRevisions) });
  };

  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert(
        "success",
        tGeneral("restored"),
        tDrawings("drawingsRestoredMessage"),
      );
      setSelectedRevisions({});
    }
    if (isError) {
      PopUpAlert("error", "Error", error.message);
      setSelectedRevisions({});
    }

    return () => {};
  }, [isSuccess, isError, error, tDrawings, tGeneral]);

  useEffect(() => {
    if (isDeleteSuccess) {
      PopUpAlert(
        "success",
        tGeneral("deleted"),
        tDrawings("revisionsDeletedSuccessfully"),
      );
      setShowDeleting(false);
      setSelectedRevisions({});
    }
    if (isDeleteError) {
      PopUpAlert("error", "Error", deleteError.message);
      setShowDeleting(false);
      setSelectedRevisions({});
    }
  }, [isDeleteError, isDeleteSuccess, deleteError, tGeneral, tDrawings]);

  const handleDeletePermanent = () => {
    deleteRevisions({ ids: Object.keys(selectedRevisions) });
  };

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 2 &&
      currentGuideMe === "drawings-recycle"
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe]);

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 2 &&
      currentGuideMe === "drawings-recycle"
    ) {
      if (revisions && revisions.length > 0) {
        const key = revisions[0].id;

        setSelectedRevisions(JSON.parse(`{"${key}": true }`));
      }

      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe, revisions]);

  return (
    <Box flex={1}>
      <TitleWidgets
        title={tDrawings("recycleBin")}
        isRecycle
        onRestore={handleRestore}
        backEnabled
        restoreDisabled={!Object.keys(selectedRevisions).length || isLoading}
        showMoreOptions={false}
        showDelete={!!Object.keys(selectedRevisions).length}
        onDelete={() => setShowDeleting(true)}
      />
      <DrawingsTable
        drawings={revisions}
        isRecycle
        selectedRows={selectedRevisions}
        onSelect={setSelectedRevisions}
      />
      <ModalWithAction
        action={handleDeletePermanent}
        actionLabel={tGeneral("delete")}
        content={tDrawings("deletingDrawingMessage")}
        title={tDrawings("deleteDrawing")}
        isLoading={isDeleteLoading}
        isOpen={showDeleting}
        onClose={() => setShowDeleting(false)}
      />
    </Box>
  );
}

export { DrawingsRecycleBin };
