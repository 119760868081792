import React from "react";

import { Search } from "@mui/icons-material";
import { Autocomplete, Box, Chip, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { useTour } from "@reactour/tour";
import { selectTheme } from "../../features/preferences/preferencesSlice";
import BubbleAvatarDeletable from "../userAvatars/BubbleAvatarDeletable";

function FilterableUserDropdown({
  title = "",
  userList = [],
  selectedUsersList = [],
  selectedUsersFormatted = [],
  formError,
  handleUpdateUsersList,
}) {
  const darkMode = useSelector(selectTheme);

  const { isOpen } = useTour();

  const mapItems = (user) => ({
    id: user?.id,
    urlAvatar: user?.urlAvatar,
    label: user.name ?? `${user?.firstName} ${user.lastName}`,
    name: user.name ?? `${user?.firstName} ${user.lastName}`,
  });

  const elementsList = React.useMemo(() => {
    return userList.map(mapItems);
  }, [userList]);

  const selectedElements = React.useMemo(() => {
    return selectedUsersList.map(mapItems);
  }, [selectedUsersList]);

  const handleSelectedElementsChange = (elementValue, elementChecked) => {
    const isChecked = !elementChecked;
    const element = elementsList.find((el) => el.id === elementValue);

    if (typeof element !== "undefined") {
      if (isChecked) {
        // If the checkbox is checked, add the barrier to the selected list
        handleUpdateUsersList([...selectedElements, { ...element }]);
      } else {
        // If the checkbox is unchecked, remove the barrier from the selected list
        handleUpdateUsersList(
          selectedElements.filter((el) => el.id !== elementValue),
        );
      }
    }
  };

  return (
    <Box>
      <Autocomplete
        multiple
        options={elementsList}
        disableCloseOnSelect
        value={selectedElements}
        error={formError}
        onChange={(e, val) => handleUpdateUsersList(val)}
        sx={{ backgroundColor: darkMode ? "" : "#FFF" }}
        disablePortal={isOpen}
        renderOption={(props, user, { selected }) => (
          <li key={user.id}>
            <Chip
              sx={{
                borderRadius: "0px",
                width: "100%",
                height: 45,
                ":hover": {
                  backgroundColor: "#fbcb041f",
                },
                bgcolor:
                  selectedUsersFormatted.indexOf(user.id) > -1
                    ? "#fbcb0414"
                    : "transparent",
                borderColor:
                  selectedUsersFormatted.indexOf(user.id) > -1
                    ? "#fbcb0414"
                    : "transparent",
                justifyContent: "left",
                alignContent: "center",
              }}
              size="medium"
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();

                handleSelectedElementsChange(
                  user.id,
                  selectedUsersFormatted.indexOf(user.id) > -1,
                );
              }}
              label={
                <BubbleAvatarDeletable
                  key={user.id}
                  user={user}
                  editable={false} // To hide delete button
                  color={false} // To hide background color
                  isList // Don´t add padding
                />
              }
            />
          </li>
        )}
        renderInput={(params) => (
          <Box ref={params.InputProps.ref}>
            <TextField
              fullWidth
              label={`${title}(${selectedUsersList.length})`}
              sx={{ padding: 0, height: "55px" }}
              inputProps={{...params.inputProps}}
              InputProps={{
                endAdornment: <Search />,
                sx: { height: "55px", padding: "14px !important" },
              }}
            />
          </Box>
        )}
      />
    </Box>
  );
}

export { FilterableUserDropdown };
