import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import NoInternet from "../../assets/icons/NoInternetConnection.svg";

function NoInternetConnection() {
  const { t: tGeneral } = useTranslation("general");
  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      rowGap="1rem"
      flexDirection="column"
      sx={{
        background:
          "linear-gradient(45deg, rgba(251,203,4,1) 0%, rgba(229,233,0,0.5) 5%, rgba(1,100,139,0) 50%, rgba(229,233,0,0.5) 95%, rgba(251,203,4,1) 100%);",
      }}
    >
      <img src={NoInternet} alt="No Internet" />
      <Box display="flex" rowGap="1.5rem" flexDirection="column">
        <Typography
          textAlign="center"
          fontSize="2.125rem"
          color="#4B4B4B"
          fontWeight="700"
        >
          {tGeneral("noInternetConnection")}
        </Typography>
        <Typography
          textAlign="center"
          fontWeight="500"
          color="#4B4B4B"
          fontSize="1.25rem"
        >
          {tGeneral("checkConnection")}
        </Typography>
      </Box>
    </Box>
  );
}

export { NoInternetConnection };
