import { Close, Save, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { DISTRIBUTION_LIST_NAMES } from "../../../constants";
import {
  useGetActiveProjectUsersQuery,
  useGetProjectConfigRfiQuery,
  useUpdateProjectConfigRfiMutation,
} from "../../../features/project/projectApiSlice";
import { PopUpAlert } from "../../PopUpAlert";
import { TooltipIconButton } from "../../shared/TooltipIconButton";
import BubbleAvatarDeletable from "../../userAvatars/BubbleAvatarDeletable";
import UserAvatar from "../../userAvatars/UserAvatar";

const rfiDaysName = "rfi_delay_days";

function ModalSettingsRfis({ isOpen, onClose }) {
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { t: tRfis } = useTranslation("rfis");
  const [distributionList, setDistributionList] = useState([]);
  const [delayDays, setDelayDays] = useState(0);

  const { data: usersData } = useGetActiveProjectUsersQuery({
    idProject,
    moduleName: "rfis",
  });

  const { data } = useGetProjectConfigRfiQuery({
    idProject,
    distributionListName: DISTRIBUTION_LIST_NAMES.rfis,
    rfiDaysName,
  });

  const [
    updateDistributionList,
    { isLoading, isSuccess, isError, error, reset },
  ] = useUpdateProjectConfigRfiMutation();

  const mapUserItems = (user) => ({
    label: `${user?.firstName} ${user?.lastName}`,
    id: user?.id,
    name: `${user?.firstName} ${user?.lastName}`,
    urlAvatar: user?.urlAvatar,
  });

  useEffect(() => {
    if (data) {
      setDistributionList(data.users.map(mapUserItems));
      setDelayDays(data.delayDays);
    }
    return [];
  }, [data]);

  const handleOnDelete = (val) => {
    setDistributionList((prev) => prev.filter((user) => user.id !== val.id));
  };

  const handleOnSave = () => {
    updateDistributionList({
      idProject,
      distributionListName: DISTRIBUTION_LIST_NAMES.rfis,
      users: distributionList.map((user) => user.id),
      rfiDaysName,
      days: delayDays.length > 0 ? delayDays : 0,
    });
  };

  const projectUsers = useMemo(() => {
    if (usersData) {
      return usersData.map((user) => mapUserItems(user.user));
    }
    return [];
  }, [usersData]);

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert(
        "success",
        tGeneral("success"),
        tGeneral("projectConfigurationUpdateSuccess"),
      );
      reset();
      onClose();
    }
    if (isError) {
      PopUpAlert("error", "Error", error.message);
      reset();
      onClose();
    }
  }, [isSuccess, isError, error, tGeneral, onClose, reset]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={(theme) => ({
          height: "auto",
          borderRadius: "20px",
          p: "25px 40px",
          width: "30%",
          [theme.breakpoints.down("lg")]: {
            width: "50%",
          },
        })}
      >
        <Box display="flex" alignItems="center">
          <Typography sx={{ flex: 1 }} fontSize="1.5625rem" fontWeight="bold">
            {tGeneral("settings")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close fontSize="1.5625rem" />
          </IconButton>
          <TooltipIconButton
            icon={<Save />}
            label={tGeneral("save")}
            onClick={handleOnSave}
            data-tour="settings-rfi-4"
          />
        </Box>
        <Divider sx={{ my: "20px" }} />
        <TextField
          data-tour="settings-rfi-2"
          label={tRfis("days")}
          value={delayDays}
          placeholder={tRfis("days")}
          onChange={(e) => setDelayDays(e.target.value)}
          fullWidth
          type="number"
          sx={{ mb: "20px" }}
        />
        <Box data-tour="settings-rfi-3">
          <Autocomplete
            multiple
            options={projectUsers}
            disableCloseOnSelect
            value={distributionList}
            isOptionEqualToValue={(opt, val) => val.id === opt.id}
            onChange={(e, val) => setDistributionList(val)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} />
                <UserAvatar name={option.label} url={option.urlAvatar} />
                <Typography>{option.label}</Typography>
              </li>
            )}
            renderInput={(params) => (
              <Box ref={params.InputProps.ref} data-tour="settings-photos-2">
                <TextField
                  fullWidth
                  label={tGeneral("distributionList")}
                  sx={{ padding: 0, height: "55px" }}
                  inputProps={{...params.inputProps}}
                  InputProps={{
                    endAdornment: <Search />,
                    sx: { height: "55px", padding: "14px !important" },
                  }}
                />
              </Box>
            )}
          />
          <Box
            sx={{
              maxHeight: "250px",
              height: "250px",
              overflow: "auto",
              mt: "16px",
            }}
          >
            {distributionList.length ? (
              distributionList?.map((user) => (
                <BubbleAvatarDeletable
                  key={user.id}
                  user={user}
                  editable // To show delete button
                  setValues={() => {
                    handleOnDelete(user);
                  }}
                />
              ))
            ) : (
              <Typography
                fontSize="1rem"
                fontWeight="medium"
                textAlign="center"
              >
                {tGeneral("noUsersSelected")}
              </Typography>
            )}
          </Box>
        </Box>
      </Card>
    </Modal>
  );
}

export { ModalSettingsRfis };
