import { Close } from "@mui/icons-material";
import { Box, FormControl, IconButton } from "@mui/material";
import { ConfigProvider, DatePicker } from "antd";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

function DateRangeFilter(props) {
  const {
    onChange,
    filterProps = {
      minWidth: "200px",
      verticalAlign: "center",
    },
    customIcon = null,
  } = props;

  const { t: tGeneral } = useTranslation("general");

  function getDates(value) {
    const endDate = new Date(value[1]);
    endDate.setUTCHours(23, 59, 59, 999);

    const dates = [
      adjustTime(new Date(value[0])).toString(), // startDate
      adjustTime(endDate).toString(), // endDate
    ];
    onChange(dates);
  }

  function adjustTime(utcTime) {
    const tzOffset = utcTime.getTimezoneOffset() * 60000;
    const localTime = new Date(utcTime.getTime() + tzOffset);
    return localTime;
  }

  return (
    <FormControl size="small" sx={filterProps}>
      <Box
        sx={{
          height: "40px",
          borderRadius: 1,
          backgroundColor: "#FFF5DB",
          border: "solid",
          borderWidth: 1,
          borderColor: "rgba(0, 0, 0, 0.2)",
          maxWidth: 310,
          display: "flex",
        }}
      >
        <IconButton
          onClick={() => onChange(null)}
          sx={{
            ml: 1,
            height: 24,
            width: 24,
            p: 2,
            mt: "auto",
            mb: "auto",
          }}
        >
          <Close />
        </IconButton>
        <ConfigProvider
          theme={{
            components: {
              DatePicker: {
                colorPrimary: "#FBCB04",
              },
            },
          }}
        >
          <RangePicker
            size="large"
            bordered={false}
            allowClear={false}
            onChange={(dates, datesStrings) => {
              getDates(datesStrings);
            }}
            placeholder={[tGeneral("startDate"), tGeneral("endDate")]}
            {...customIcon && { suffixIcon: customIcon }}
          />
        </ConfigProvider>
      </Box>
    </FormControl>
  );
}

export { DateRangeFilter };
