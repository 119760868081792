function getTaskStatus(status, isDraft) {
  let label = "OPEN";
  let value = "open";
  let color = "#5de280";
  if (status === "backlog" && isDraft) {
    label = "DRAFT";
    value = "draft";
    color = "#FF891E";
  }
  if (status === "done") {
    label = "CLOSED";
    value = "closed";
    color = "#b0b7c1";
  }

  return { label, value, color };
}

export default {
  getTaskStatus,
};
