import React, { forwardRef, useEffect, useState } from "react";

// React router
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";

// Components
import { AvatarGroup } from "@mui/material";

// MUI Components
import MaterialTable from "material-table";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  MenuItem,
  Grid,
  CardActions,
  Divider,
  Card,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Select, { SelectChangeEvent } from "@mui/material/Select";

// Icons
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Settings,
  Restore,
} from "@mui/icons-material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Alert
import Swal from "sweetalert2";

import {
  useGetProjectUsersQuery,
  useAddEmployeeMutation,
  useAddCodeMutation,
  useGetProjectEmployeesQuery,
  useGetProjectCodesQuery,
  useAddMachineMutation,
  useGetProjectMachinesQuery,
  useRemoveEmployeeMutation,
  useRemoveCodeMutation,
  useRemoveMachineMutation,
  useRestoreMachineMutation,
  useGetProjectMachinesDeletedQuery,
} from "../../features/project/projectApiSlice";

import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const HoursSettings = () => {
  const { t: tGeneral } = useTranslation("general");

  const { idProject } = useParams();
  const navigate = useNavigate();

  // EMPLOYEES
  const [addEmployee, { isSuccess: isSuccesEmployee }] =
    useAddEmployeeMutation();
  const [addCode, { isSuccess: isSuccesCode }] = useAddCodeMutation();
  const { data: projectEmployees, isSuccessEmployees } =
    useGetProjectEmployeesQuery(idProject);
  const { data: projectCodes, isSuccessCodes } =
    useGetProjectCodesQuery(idProject);
  const [dataEmployee, setDataEmployee] = useState([]);
  const [dataCode, setDataCode] = useState([]);
  const [removeEmployee, { isLoading: isRemoveEmployee }] =
    useRemoveEmployeeMutation();
  const [removeCode, { isLoading: isRemoveCode }] = useRemoveCodeMutation();
  const [removeMachine, { isLoading: isRemoveMachine }] =
    useRemoveMachineMutation();
  const [restoreMachine, { isLoading: isRestoreMachine }] =
    useRestoreMachineMutation();

  const [employeeName, setEmployeeName] = useState();
  const [employeeNameError, setEmployeeNameError] = useState(false);
  const [employeeNameHelperText, setEmployeeNameHelperText] = useState("");
  const [employeeCompany, setEmployeeCompany] = useState();
  const [employeeCompanyError, setEmployeeCompanyError] = useState(false);
  const [employeeCompanyHelperText, setEmployeeCompanyHelperText] =
    useState("");
  const [openDeleteEmployee, setOpenDeleteEmployee] = useState(false);

  const [codeNumber, setCodeNumber] = useState();
  const [codeNumberError, setCodeNumberError] = useState(false);
  const [codeNumberHelperText, setCodeNumberHelperText] = useState("");
  const [codeName, setCodeName] = useState();
  const [codeNameError, setCodeNameError] = useState(false);
  const [codeNameHelperText, setCodeNameHelperText] = useState("");
  const [openDeleteCode, setOpenDeleteCode] = useState(false);

  const [distributionListEmployee, setDistributionListEmployee] = useState();
  const [distributionListEmployeeError, setDistributionListEmployeeError] =
    useState(false);
  const [
    distributionListEmployeeHelperText,
    setDistributionListEmployeeHelperText,
  ] = useState("");

  // MACHINES
  const [addMachine, { isSuccess: isSuccesMachine }] = useAddMachineMutation();
  const { data: projectMachines, isSuccessMachines } =
    useGetProjectMachinesQuery(idProject);
  const [dataMachine, setDataMachine] = useState([]);
  const { data: projectMachinesDeleted, isSuccessMachinesDeleted } =
    useGetProjectMachinesDeletedQuery(idProject);
  const [dataMachineDeleted, setDataMachineDeleted] = useState([]);

  const [machineName, setMachineName] = useState();
  const [machineNameError, setMachineNameError] = useState(false);
  const [machineNameHelperText, setMachineNameHelperText] = useState("");
  const [machineCompany, setMachineCompany] = useState();
  const [machineCompanyError, setMachineCompanyError] = useState(false);
  const [machineCompanyHelperText, setMachineCompanyHelperText] = useState("");
  const [openDeleteMachine, setOpenDeleteMachine] = useState(false);
  const [openRestoreMachine, setOpenRestoreMachine] = useState(false);

  const [distributionListMachine, setDistributionListMachine] = useState();
  const [distributionListMachineError, setDistributionListMachineError] =
    useState(false);
  const [
    distributionListMachineHelperText,
    setDistributionListMachineHelperText,
  ] = useState("");

  const [idSelected, setIdSelected] = useState();

  const { data: projectUsers, isSuccessUsers } =
    useGetProjectUsersQuery(idProject);
  const [userList, setUserList] = useState([]);
  const [personName, setPersonName] = useState([]);

  const [type, setType] = React.useState("employees");
  const handleType = (event, newType) => {
    setType(newType);
  };

  const [employeesTabs, setEmployeesTabs] = React.useState("1");
  const handleChangeEmployees = (event, newValue) => {
    setEmployeesTabs(newValue);
  };
  const [machinesTabs, setMachinesTabs] = React.useState("1");
  const [numTab, setNumTab] = useState(false);
  const handleChangeMachines = (event, newValue) => {
    setMachinesTabs(newValue);
    if (newValue == 3) {
      setNumTab(true);
    } else {
      setNumTab(false);
    }
  };

  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    resetVars();
    setChecked(event.target.checked);
  };

  const [pageSize, setPageSize] = useState(5);

  let body = {};

  const columnsEmployees = [
    {
      field: "name",
      title: "Employee",
      width: 50,
    },
    {
      field: "company",
      title: "Company",
      width: 250,
    },
    {
      field: "icon",
      title: "Icon",
      width: 50,
    },
    {
      title: "Actions",
      width: 100,
      sorting: false,
      render: (rowData) => (
        <ButtonGroup>
          <IconButton
            title="Delete"
            onClick={() => {
              body = {
                idProject: idProject,
                id: rowData.id,
              };
              body.id = rowData.id;
              setIdSelected(rowData.id);
              setEmployeeName(rowData.name);
              setOpenDeleteEmployee(true);
            }}
            sx={{
              ":hover": {
                color: "#FBCB04",
              },
            }}
          >
            <DeleteOutline />
          </IconButton>
        </ButtonGroup>
      ),
    },
  ];
  const columnsCodes = [
    {
      field: "number",
      title: "Number",
      width: 50,
    },
    {
      field: "name",
      title: "Name",
      width: 250,
    },
    {
      title: "Actions",
      width: 100,
      sorting: false,
      render: (rowData) => (
        <ButtonGroup>
          <IconButton
            title="Delete"
            onClick={() => {
              body = {
                idProject: idProject,
                id: rowData.id,
              };
              body.id = rowData.id;
              setIdSelected(rowData.id);
              setCodeName(rowData.name);
              setOpenDeleteCode(true);
            }}
            sx={{
              ":hover": {
                color: "#FBCB04",
              },
            }}
          >
            <DeleteOutline />
          </IconButton>
        </ButtonGroup>
      ),
    },
  ];
  const columnsMachines = [
    {
      field: "name",
      title: "Machine",
      width: 50,
    },
    {
      field: "company",
      title: "Company",
      width: 250,
    },
    {
      field: "icon",
      title: "Icon",
      width: 250,
    },
    {
      title: "Actions",
      width: 100,
      sorting: false,
      render: (rowData) => (
        <ButtonGroup>
          {numTab ? (
            <IconButton
              title="Restore"
              onClick={() => {
                body = {
                  idProject: idProject,
                  id: rowData.id,
                };
                body.id = rowData.id;
                setIdSelected(rowData.id);
                setMachineName(rowData.name);
                setOpenRestoreMachine(true);
              }}
              sx={{
                ":hover": {
                  color: "#FBCB04",
                },
              }}
            >
              <Restore />
            </IconButton>
          ) : (
            <IconButton
              title="Delete"
              onClick={() => {
                body = {
                  idProject: idProject,
                  id: rowData.id,
                };
                body.id = rowData.id;
                setIdSelected(rowData.id);
                setMachineName(rowData.name);
                setOpenDeleteMachine(true);
              }}
              sx={{
                ":hover": {
                  color: "#FBCB04",
                },
              }}
            >
              <DeleteOutline />
            </IconButton>
          )}
        </ButtonGroup>
      ),
    },
  ];
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const goBack = () => {
    navigate(-1);
  };

  const changeAssignedUsersEmployee = (e) => {
    validateFields(e.target.value, "distributionListEmployees");
    const value = e.target.value;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const changeAssignedUsersMachine = (e) => {
    validateFields(e.target.value, "distributionListMachines");
    const value = e.target.value;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const resetVars = () => {
    setEmployeeName("");
    setEmployeeNameError(false);
    setEmployeeNameHelperText("");
    setEmployeeCompany("");
    setEmployeeCompanyError(false);
    setEmployeeCompanyHelperText("");
    setCodeNumber("");
    setCodeNumberError(false);
    setCodeNumberHelperText("");
    setCodeName("");
    setCodeNameError(false);
    setCodeNameHelperText("");
    setDistributionListEmployee("");
    setDistributionListEmployeeError(false);
    setDistributionListEmployeeHelperText("");

    setMachineName("");
    setMachineNameError(false);
    setMachineNameHelperText("");
    setMachineCompany("");
    setMachineCompanyError(false);
    setMachineCompanyHelperText("");
    setDistributionListMachine("");
    setDistributionListMachineError(false);
    setDistributionListMachineHelperText("");

    setPersonName([]);

    setOpenDeleteEmployee(false);
    setOpenDeleteCode(false);
    setOpenDeleteMachine(false);
    setOpenRestoreMachine(false);
  };

  function validateFields(val, field) {
    switch (field) {
      // EMPLOYEES
      case "employeeName":
        setEmployeeName(val);
        const employeeNameValidation = val.length > 0 ? 1 : -1;
        employeeNameValidation === 1
          ? setEmployeeNameError(false)
          : setEmployeeNameError(true);
        employeeNameValidation === 1
          ? setEmployeeNameHelperText("")
          : setEmployeeNameHelperText("Invalid name");
        break;
      case "employeeCompany":
        setEmployeeCompany(val);
        const employeeCompanyValidation = val.length > 0 ? 1 : -1;
        employeeCompanyValidation === 1
          ? setEmployeeCompanyError(false)
          : setEmployeeCompanyError(true);
        employeeCompanyValidation === 1
          ? setEmployeeCompanyHelperText("")
          : setEmployeeCompanyHelperText("Invalid company");
        break;
      case "codeNumber":
        setCodeNumber(val);
        const codeNumberValidation = val.length > 0 ? 1 : -1;
        codeNumberValidation === 1
          ? setCodeNumberError(false)
          : setCodeNumberError(true);
        codeNumberValidation === 1
          ? setCodeNumberHelperText("")
          : setCodeNumberHelperText("Invalid number");
        break;
      case "codeName":
        setCodeName(val);
        const codeNameValidation = val.length > 0 ? 1 : -1;
        codeNameValidation === 1
          ? setCodeNameError(false)
          : setCodeNameError(true);
        codeNameValidation === 1
          ? setCodeNameHelperText("")
          : setCodeNameHelperText("Invalid name");
        break;
      case "distributionListEmployees":
        setDistributionListEmployee(val);
        const distributionListEmployeeValidation = val.length > 0 ? 1 : -1;
        distributionListEmployeeValidation === 1
          ? setDistributionListEmployeeError(false)
          : setDistributionListEmployeeError(true);
        distributionListEmployeeValidation === 1
          ? setDistributionListEmployeeHelperText("")
          : setDistributionListEmployeeHelperText("Invalid list");
        break;
      // MACHINES
      case "machineName":
        setMachineName(val);
        const machineNameValidation = val.length > 0 ? 1 : -1;
        machineNameValidation === 1
          ? setMachineNameError(false)
          : setMachineNameError(true);
        machineNameValidation === 1
          ? setMachineNameHelperText("")
          : setMachineNameHelperText("Invalid name");
        break;
      case "machineCompany":
        setMachineCompany(val);
        const machineCompanyValidation = val.length > 0 ? 1 : -1;
        machineCompanyValidation === 1
          ? setMachineCompanyError(false)
          : setMachineCompanyError(true);
        machineCompanyValidation === 1
          ? setMachineCompanyHelperText("")
          : setMachineCompanyHelperText("Invalid company");
        break;
      case "distributionListMachines":
        setDistributionListMachine(val);
        const distributionListMachineValidation = val.length > 0 ? 1 : -1;
        distributionListMachineValidation === 1
          ? setDistributionListMachineError(false)
          : setDistributionListMachineError(true);
        distributionListMachineValidation === 1
          ? setDistributionListMachineHelperText("")
          : setDistributionListMachineHelperText("Invalid list");
        break;

      default:
        break;
    }
  }

  const createEmployee = async (e) => {
    let mistakes = false;

    validateFields(employeeName, "employeeName");
    validateFields(employeeCompany, "employeeCompany");
    if (employeeNameError || employeeCompanyError) {
      mistakes = true;
    }
    if (!mistakes) {
      try {
        let response = null;
        let bodyCreate = {};

        bodyCreate = {
          idProject: idProject,
          name: employeeName,
          company: employeeCompany,
          icon: "employeeIcon",
        };
        response = await addEmployee(bodyCreate).unwrap();
        if (response) {
          resetVars();
          showAlert("Employee created successfully", "success");
        }
      } catch (err) {
        showAlert("Can't create employee", "error");
        console.error(err);
      }
    }
  };

  const createCode = async (e) => {
    let mistakes = false;

    validateFields(codeNumber, "codeNumber");
    validateFields(codeName, "codeName");
    if (codeNameError || codeNumberError) {
      mistakes = true;
    }
    if (!mistakes) {
      try {
        let response = null;
        let bodyCreate = {};

        bodyCreate = {
          idProject: idProject,
          name: codeName,
          number: codeNumber,
        };
        response = await addCode(bodyCreate).unwrap();
        if (response) {
          resetVars();
          showAlert("Code created successfully", "success");
        }
      } catch (err) {
        showAlert("Can't create code", "error");
        console.error(err);
      }
    }
  };

  const createMachine = async (e) => {
    let mistakes = false;

    validateFields(machineName, "machineName");
    validateFields(machineCompany, "machineCompany");
    if (machineCompanyError || machineNameError) {
      mistakes = true;
    }
    if (!mistakes) {
      try {
        let response = null;
        let bodyCreate = {};

        bodyCreate = {
          idProject: idProject,
          name: machineName,
          company: machineCompany,
          icon: "machineIcon",
        };
        response = await addMachine(bodyCreate).unwrap();
        if (response) {
          resetVars();
          showAlert("Machine created successfully", "success");
        }
      } catch (err) {
        showAlert("Can't create machine", "error");
        console.error(err);
      }
    }
  };

  const removeEmployees = async (e) => {
    try {
      let response = null;
      let body = {
        idProject: idProject,
        id: idSelected,
      };
      response = await removeEmployee(body).unwrap();

      if (response) {
        setOpenDeleteEmployee(false);
        setIdSelected();
        resetVars();
        showAlert("Employee deleted successfully", "success");
      }
    } catch (err) {
      showAlert("Can't delete employee", "error");
      console.error(err);
    }
  };

  const removeCodes = async (e) => {
    try {
      let response = null;
      let body = {
        idProject: idProject,
        id: idSelected,
      };
      response = await removeCode(body).unwrap();

      if (response) {
        setOpenDeleteCode(false);
        setIdSelected();
        resetVars();
        showAlert("Code deleted successfully", "success");
      }
    } catch (err) {
      showAlert("Can't delete code", "error");
      console.error(err);
    }
  };

  const removeMachines = async (e) => {
    try {
      let response = null;
      let body = {
        idProject: idProject,
        id: idSelected,
      };
      response = await removeMachine(body).unwrap();

      if (response) {
        setOpenDeleteMachine(false);
        setIdSelected();
        resetVars();
        showAlert("Machine deleted successfully", "success");
      }
    } catch (err) {
      showAlert("Can't delete machine", "error");
      console.error(err);
    }
  };

  const restoreMachines = async (e) => {
    try {
      let response = null;
      let body = {
        idProject: idProject,
        id: idSelected,
      };
      response = await restoreMachine(body).unwrap();

      if (response) {
        setOpenRestoreMachine(false);
        setIdSelected();
        resetVars();
        showAlert("Machine restores successfully", "success");
      }
    } catch (err) {
      showAlert("Can't restore machine", "error");
      console.error(err);
    }
  };

  function showAlert(message, type) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: type,
      title: message,
    });
  }

  useEffect(() => {
    if (projectUsers) {
      let usersList = projectUsers
        .filter(function (obj) {
          return !obj.bExternal; // only intern users
        })
        .map((user) => ({
          id: user.id,
          name: user.user.firstName + " " + user.user.lastName,
        }));
      setUserList(usersList);
    }
    if (projectEmployees) {
      let projectEmployee = projectEmployees.map((employee) => ({
        id: employee.id,
        name: employee.name,
        company: employee.company,
        icon: employee.icon,
      }));

      setDataEmployee(projectEmployee);
    }
    if (projectCodes) {
      let projectCode = projectCodes.map((code) => ({
        id: code.id,
        name: code.name,
        number: code.number,
      }));

      setDataCode(projectCode);
    }
    if (projectMachines) {
      let projectMachine = projectMachines.map((machine) => ({
        id: machine.id,
        name: machine.name,
        company: machine.company,
        icon: machine.icon,
      }));

      setDataMachine(projectMachine);
    }
    if (projectMachinesDeleted) {
      let projectMachineDeleted = projectMachinesDeleted.map((machine) => ({
        id: machine.id,
        name: machine.name,
        company: machine.company,
        icon: machine.icon,
      }));

      setDataMachineDeleted(projectMachineDeleted);
    }
  }, [projectEmployees, projectCodes, projectMachines, projectMachinesDeleted]);

  const tableLocalization = {
    pagination: {
      labelRowsPerPage: `${tGeneral("labelRowsPerPage")}`,
      labelDisplayedRows: `{from}-{to} ${tGeneral("of")} {count}`,
      labelRowsSelect: `${tGeneral("labelRowsSelect")}`,
      firstAriaLabel: `${tGeneral("firstAriaLabel")}`,
      firstTooltip: `${tGeneral("firstTooltip")}`,
      previousAriaLabel: `${tGeneral("previousAriaLabel")}`,
      previousTooltip: `${tGeneral("previousTooltip")}`,
      nextAriaLabel: `${tGeneral("nextAriaLabel")}`,
      nextTooltip: `${tGeneral("nextTooltip")}`,
      lastAriaLabel: `${tGeneral("lastAriaLabel")}`,
      lastTooltip: `${tGeneral("lastTooltip")}`,
    },
    toolbar: {
      searchPlaceholder: tGeneral("search"),
    },
    body: {
      emptyDataSourceMessage: tGeneral("emptyDataSourceMessage"),
    },
  };

  return (
    <Box sx={{ height: 500, width: "100%" }}>
      <Grid container item xs={12} justifyContent="center" alignItems="center">
        <Grid item xs={6} md={9}>
          <CardActions>
            <IconButton title="Back" onClick={goBack}>
              <ArrowBackIcon />
            </IconButton>
            <Typography fontSize="24px">Hours Settings</Typography>
          </CardActions>
        </Grid>

        <Grid item xs={6} md={3}>
          <ToggleButtonGroup
            value={type}
            exclusive
            onChange={handleType}
            aria-label="choose type"
          >
            <ToggleButton value="employees" aria-label="employees">
              EMPLOYEES
            </ToggleButton>
            <ToggleButton value="machines" aria-label="machines">
              MACHINES
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>

      {type == "machines" ? (
        //MACHINES SETTINGS
        <TabContext value={machinesTabs}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChangeMachines}
              aria-label="lab API tabs example"
            >
              <Tab label="Machines" value="1" />
              <Tab label="Recycle bin" value="3" />
              <Tab label="Distribution list" value="4" />
            </TabList>
          </Box>

          {/* MACHINES TAB */}
          <TabPanel value="1">
            <Grid container item xs={12} md={12} justifyContent="center">
              <Grid item xs={6} md={6} marginTop={2}>
                <Box
                  sx={{
                    display: "flex",
                    m: 1,
                  }}
                >
                  <TextField
                    id="machineNameField"
                    required
                    margin="dense"
                    variant="outlined"
                    type="text"
                    label="Name"
                    error={machineNameError ? true : false}
                    helpertext={machineNameHelperText}
                    onChange={(e) => {
                      validateFields(e.target.value, "machineName");
                    }}
                    value={machineName}
                    sx={{ width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} md={6} marginTop={2}>
                <Box
                  sx={{
                    display: "flex",
                    m: 1,
                  }}
                >
                  <TextField
                    id="machineCompanyField"
                    required
                    margin="dense"
                    variant="outlined"
                    type="text"
                    label="Company"
                    error={machineCompanyError ? true : false}
                    helpertext={machineCompanyHelperText}
                    onChange={(e) => {
                      validateFields(e.target.value, "machineCompany");
                    }}
                    value={machineCompany}
                    sx={{ width: "100%" }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ border: 1, color: "#E1E1E1" }} />
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  display: "flex",
                  m: 1,
                  justifyContent: "space-between",
                }}
              >
                <Button
                  sx={{
                    width: "100%",
                    m: 5,
                    color: "white",
                    backgroundColor: "#ffc900",
                    ":hover": {
                      color: "white",
                      backgroundColor: "#d9ab00",
                    },
                  }}
                  type="submit"
                  variant="contained"
                  id="createButton"
                  onClick={createMachine}
                >
                  ADD MACHINE
                </Button>
              </Box>
            </Grid>
            {/* MACHINES TABLE */}
            <MaterialTable
              icons={tableIcons}
              columns={columnsMachines}
              data={dataMachine}
              localization={tableLocalization}
              title=""
              options={{
                actionsColumnIndex: -1,
                exportButton: false,
              }}
              rowsPerPageOptions={[5, 10, 20]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            />
          </TabPanel>

          {/* RECYCLE BIN TAB */}
          <TabPanel value="3">
            {/* ERASED MACHINES TABLE */}
            <MaterialTable
              icons={tableIcons}
              columns={columnsMachines}
              data={dataMachineDeleted}
              localization={tableLocalization}
              title="Erased Machines"
              options={{
                actionsColumnIndex: -1,
                exportButton: false,
              }}
              rowsPerPageOptions={[5, 10, 20]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            />
          </TabPanel>

          {/* DISTRIBUTION LIST TAB */}
          <TabPanel value="4">
            <Grid container item xs={12} md={12} justifyContent="center">
              <Grid item xs={12} md={12} marginTop={2}>
                <Box
                  sx={{
                    display: "flex",
                    m: 1,
                  }}
                >
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel required id="userTag">
                      Distribution List
                    </InputLabel>
                    <Select
                      labelId="userTag"
                      id="enterpriseField"
                      multiple
                      value={personName}
                      onChange={(e) => changeAssignedUsersMachine(e)}
                      input={<OutlinedInput label="Distribution list" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      helperText={distributionListMachineHelperText}
                      error={distributionListMachineError ? true : false}
                      sx={{ m: 1, width: "100%" }}
                    >
                      {userList.map((user) => (
                        <MenuItem key={user.id} value={user.name}>
                          <Checkbox
                            checked={personName.indexOf(user.name) > -1}
                          />
                          <ListItemText primary={user.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ border: 1, color: "#E1E1E1" }} />
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  display: "flex",
                  m: 1,
                  justifyContent: "space-between",
                }}
              >
                <Button
                  sx={{
                    width: "100%",
                    m: 5,
                    color: "white",
                    backgroundColor: "#ffc900",
                    ":hover": {
                      color: "white",
                      backgroundColor: "#d9ab00",
                    },
                  }}
                  type="submit"
                  variant="contained"
                  id="createButton"
                  onClick=""
                >
                  ADD DISTRIBUTION LIST
                </Button>
              </Box>
            </Grid>
            {/* CODE TABLE */}
            <MaterialTable
              icons={tableIcons}
              columns={columnsCodes}
              data={dataCode}
              localization={tableLocalization}
              title=""
              options={{
                actionsColumnIndex: -1,
                exportButton: false,
              }}
              rowsPerPageOptions={[5, 10, 20]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            />
          </TabPanel>
        </TabContext>
      ) : (
        //EMPLOYEES SETTINGS
        <TabContext value={employeesTabs}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChangeEmployees}
              aria-label="lab API tabs example"
            >
              <Tab label="Employees" value="1" />
              <Tab label="Codes" value="2" />
              <Tab label="Distribution List" value="3" />
            </TabList>
          </Box>

          {/* EMPLOYEES TAB */}
          <TabPanel value="1">
            <Grid container item xs={12} md={12} justifyContent="center">
              <Grid item xs={6} md={6} marginTop={2}>
                <Box
                  sx={{
                    display: "flex",
                    m: 1,
                  }}
                >
                  <TextField
                    id="employeeNameField"
                    required
                    margin="dense"
                    variant="outlined"
                    type="text"
                    label="Name"
                    error={employeeNameError ? true : false}
                    helpertext={employeeNameHelperText}
                    onChange={(e) => {
                      validateFields(e.target.value, "employeeName");
                    }}
                    value={employeeName}
                    sx={{ width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} md={6} marginTop={2}>
                <Box
                  sx={{
                    display: "flex",
                    m: 1,
                  }}
                >
                  <TextField
                    id="employeeCompanyField"
                    required
                    margin="dense"
                    variant="outlined"
                    type="text"
                    label="Company"
                    error={employeeCompanyError ? true : false}
                    helpertext={employeeCompanyHelperText}
                    onChange={(e) => {
                      validateFields(e.target.value, "employeeCompany");
                    }}
                    value={employeeCompany}
                    sx={{ width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} md={6} marginTop={2}>
                <Box
                  sx={{
                    display: "flex",
                    m: 1,
                  }}
                >
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ border: 1, color: "#E1E1E1" }} />
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  display: "flex",
                  m: 1,
                  justifyContent: "space-between",
                }}
              >
                <Button
                  sx={{
                    width: "100%",
                    m: 5,
                    color: "white",
                    backgroundColor: "#ffc900",
                    ":hover": {
                      color: "white",
                      backgroundColor: "#d9ab00",
                    },
                  }}
                  type="submit"
                  variant="contained"
                  id="createButton"
                  onClick={createEmployee}
                >
                  ADD EMPLOYEE
                </Button>
              </Box>
            </Grid>
            {/* EMPLOYEES TABLE */}
            <MaterialTable
              icons={tableIcons}
              columns={columnsEmployees}
              data={dataEmployee}
              localization={tableLocalization}
              title=""
              options={{
                actionsColumnIndex: -1,
                exportButton: false,
              }}
              rowsPerPageOptions={[5, 10, 20]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            />
          </TabPanel>

          {/* CODES TAB */}
          <TabPanel value="2">
            <Grid container item xs={12} md={12} justifyContent="center">
              <Grid item xs={6} md={6} marginTop={2}>
                <Box
                  sx={{
                    display: "flex",
                    m: 1,
                  }}
                >
                  <TextField
                    id="codeNumberField"
                    required
                    margin="dense"
                    variant="outlined"
                    type="text"
                    label="Number"
                    error={codeNumberError ? true : false}
                    helpertext={codeNumberHelperText}
                    onChange={(e) => {
                      validateFields(e.target.value, "codeNumber");
                    }}
                    value={codeNumber}
                    sx={{ width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} md={6} marginTop={2}>
                <Box
                  sx={{
                    display: "flex",
                    m: 1,
                  }}
                >
                  <TextField
                    id="codeNameField"
                    required
                    margin="dense"
                    variant="outlined"
                    type="text"
                    label="Name"
                    error={codeNameError ? true : false}
                    helpertext={codeNameHelperText}
                    onChange={(e) => {
                      validateFields(e.target.value, "codeName");
                    }}
                    value={codeName}
                    sx={{ width: "100%" }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ border: 1, color: "#E1E1E1" }} />
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  display: "flex",
                  m: 1,
                  justifyContent: "space-between",
                }}
              >
                <Button
                  sx={{
                    width: "100%",
                    m: 5,
                    color: "white",
                    backgroundColor: "#ffc900",
                    ":hover": {
                      color: "white",
                      backgroundColor: "#d9ab00",
                    },
                  }}
                  type="submit"
                  variant="contained"
                  id="createButton"
                  onClick={createCode}
                >
                  ADD CODE
                </Button>
              </Box>
            </Grid>
            {/* CODE TABLE */}
            <MaterialTable
              icons={tableIcons}
              columns={columnsCodes}
              data={dataCode}
              localization={tableLocalization}
              title=""
              options={{
                actionsColumnIndex: -1,
                exportButton: false,
              }}
              rowsPerPageOptions={[5, 10, 20]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            />
          </TabPanel>

          {/* DISTRIBUTION LIST TAB */}
          <TabPanel value="3">
            <Grid container item xs={12} md={12} justifyContent="center">
              <Grid item xs={12} md={12} marginTop={2}>
                <Box
                  sx={{
                    display: "flex",
                    m: 1,
                  }}
                >
                  <FormControl sx={{ m: 1, width: "100%" }}>
                    <InputLabel required id="userTag">
                      Distribution List
                    </InputLabel>
                    <Select
                      labelId="userTag"
                      id="distributionList"
                      multiple
                      value={personName}
                      onChange={(e) => changeAssignedUsersEmployee(e)}
                      input={<OutlinedInput label="Distribution list" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      helperText={distributionListEmployeeHelperText}
                      error={distributionListEmployeeError ? true : false}
                      sx={{ m: 1, width: "100%" }}
                    >
                      {userList.map((user) => (
                        <MenuItem key={user.id} value={user.name}>
                          <Checkbox
                            checked={personName.indexOf(user.name) > -1}
                          />
                          <ListItemText primary={user.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ border: 1, color: "#E1E1E1" }} />
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  display: "flex",
                  m: 1,
                  justifyContent: "space-between",
                }}
              >
                <Button
                  sx={{
                    width: "100%",
                    m: 5,
                    color: "white",
                    backgroundColor: "#ffc900",
                    ":hover": {
                      color: "white",
                      backgroundColor: "#d9ab00",
                    },
                  }}
                  type="submit"
                  variant="contained"
                  id="createButton"
                  onClick=""
                >
                  ADD DISTRIBUTION LIST
                </Button>
              </Box>
            </Grid>
            {/* CODE TABLE */}
            <MaterialTable
              icons={tableIcons}
              columns={columnsCodes}
              data={dataCode}
              localization={tableLocalization}
              title=""
              options={{
                actionsColumnIndex: -1,
                exportButton: false,
              }}
              rowsPerPageOptions={[5, 10, 20]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            />
          </TabPanel>
        </TabContext>
      )}

      {/* REMOVE EMPLOYEE */}
      <Dialog
        open={openDeleteEmployee}
        onClose={(e) => resetVars()}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#ffc900",
            color: "white",
          }}
          id="scroll-dialog-title"
        >
          Delete Employee
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            You sure you want to delete {employeeName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => resetVars()}>Cancel</Button>
          <Button onClick={(e) => removeEmployees()}>Delete</Button>
        </DialogActions>
      </Dialog>
      {/* REMOVE CODE */}
      <Dialog
        open={openDeleteCode}
        onClose={(e) => resetVars()}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#ffc900",
            color: "white",
          }}
          id="scroll-dialog-title"
        >
          Delete Code
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            You sure you want to delete {codeName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => resetVars()}>Cancel</Button>
          <Button onClick={(e) => removeCodes()}>Delete</Button>
        </DialogActions>
      </Dialog>
      {/* REMOVE MACHINE */}
      <Dialog
        open={openDeleteMachine}
        onClose={(e) => resetVars()}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#ffc900",
            color: "white",
          }}
          id="scroll-dialog-title"
        >
          Delete Machine
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            You sure you want to delete {machineName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => resetVars()}>Cancel</Button>
          <Button onClick={(e) => removeMachines()}>Delete</Button>
        </DialogActions>
      </Dialog>
      {/* RESTORE MACHINE */}
      <Dialog
        open={openRestoreMachine}
        onClose={(e) => resetVars()}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#ffc900",
            color: "white",
          }}
          id="scroll-dialog-title"
        >
          Restore Machine
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            You sure you want to restore {machineName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => resetVars()}>Cancel</Button>
          <Button onClick={(e) => restoreMachines()}>Restore</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default HoursSettings;
