import { tour } from "./tour";

export const announcements = {
  // general tour
  ...tour,
  moduleElement: "Anuncio",
  // ADD - guide me steps
  step1_addAnnouncement_title: "1. Crear un nuevo anuncio",
  step1_addAnnouncement_text:
    "Haz click en el icono de + para agregar un nuevo anuncio",
  step2_addAnnouncement_title: "2. Programar anuncio",
  step2_addAnnouncement_text:
    "Selecciona si el anuncio será publicado en el momento, o si se publicará después; seleccionando la segunda opción se desplegaran nuevas opciones",
  step2_1_addAnnouncement_title: "2.1 Repetir",
  step2_1_addAnnouncement_text:
    "Selecciona la frecuencia en la que se repetirá el anuncio",
  step2_2_addAnnouncement_title: "2.2 Fecha de comienzo",
  step2_2_addAnnouncement_text:
    "Selecciona la fecha en que el anuncio se publicará",
  step2_3_addAnnouncement_title: "2.3 Hora de comienzo",
  step2_3_addAnnouncement_text:
    "Selecciona la hora en que el anuncio se publicará",
  step3_addAnnouncement_title: "3. Descripción",
  step3_addAnnouncement_text: "Escribe el asunto del anuncio",
  step4_addAnnouncement_title: "4. Lista de distribución",
  step4_addAnnouncement_text:
    "Selecciona a que personas les llegara notificación del anuncio",
  step5_addAnnouncement_title: "5. Fecha de vencimiento",
  step5_addAnnouncement_text:
    "Selecciona el día en que el anuncio dejara de ser valido/visible",
  step6_addAnnouncement_title: "6. Hora de vencimiento",
  step6_addAnnouncement_text:
    "Selecciona la hora en que el anuncio dejara de ser valido/visible",
  step7_addAnnouncement_title: "7. Agregar",
  step7_addAnnouncement_text: "Haz click aquí para publicar el anuncio",
  step_final_addAnnouncement: "Haz aprendido cómo crear un anuncio",
  // VIEW - guide me steps
  step1_viewAnnouncement_title: "1. Ver anuncio",
  step1_viewAnnouncement_text:
    "Haz click aquí para ver la información del anuncio",
  step2_viewAnnouncement_title: "2. Visualizar anuncio",
  step2_viewAnnouncement_text:
    "En la ventana emergente podrás ver la información pertinente al anuncio, como la descripción y la fecha",
  step_final_viewAnnouncement: "Haz aprendido cómo visualizar un anuncio",
  // EDIT - guide me steps
  step1_editAnnouncement_title: "1. Editar anuncio",
  step1_editAnnouncement_text: "Haz click aquí para editar un anuncio",
  step2_editAnnouncement_title: "2. Editar programación del anuncio",
  step2_editAnnouncement_text:
    "Edita si el anuncio será publicado en el momento, o si se publicará después; seleccionando la segunda opción se desplegaran nuevas opciones",
  step2_1_editAnnouncement_title: "2.1 Repetir",
  step2_1_editAnnouncement_text:
    "Edita la frecuencia en la que se repetirá el anuncio",
  step2_2_editAnnouncement_title: "2.2 Fecha de comienzo",
  step2_2_editAnnouncement_text: "Editar la fecha en que el anuncio iniciará",
  step2_3_editAnnouncement_title: "2.3 Hora de comienzo",
  step2_3_editAnnouncement_text: "Editar la hora en que el anuncio iniciará",
  step3_editAnnouncement_title: "3. Descripción",
  step3_editAnnouncement_text: "Edita el asunto del anuncio",
  step4_editAnnouncement_title: "4. Lista de distribución",
  step4_editAnnouncement_text: "Edita que personas podrán ver el anuncio",
  step5_editAnnouncement_title: "5. Fecha de vencimiento",
  step5_editAnnouncement_text:
    "Edita el día en que el anuncio dejara de ser valido/visible",
  step6_editAnnouncement_title: "6. Fecha de vencimiento",
  step6_editAnnouncement_text:
    "Edita la hora en que el anuncio dejara de ser valido/visible",
  step7_editAnnouncement_title: "7. Editar",
  step7_editAnnouncement_text: "Haz click aquí para guardar los cambios",
  step_final_editAnnouncement: "Haz aprendido cómo editar un anuncio",
  // RECYCLE - guide me steps
  step1_recycleAnnouncement_title: "1.  Menú del modulo de Anuncios",
  step1_recycleAnnouncement_text: "Haz click aquí para ver las opciones",
  step2_recycleAnnouncement_title: "2. Papelera de Anuncios",
  step2_recycleAnnouncement_text:
    "Haz clic aquí para abrir la papelera de Anuncios",
  step3_recycleAnnouncement_title: "3.  Menú de la papelera",
  step3_recycleAnnouncement_text: "Este es el menú de la papelera de Anuncios",
  step4_recycleAnnouncement_title: "4. Ver un anuncio",
  step4_recycleAnnouncement_text:
    "Haz click aquí para poder ver cual es la información del anuncio que se quiere restaurar",
  step5_recycleAnnouncement_title: "5. Selecciona anuncios",
  step5_recycleAnnouncement_text:
    "Selecciona uno o varios anuncios para restaurar",
  step6_recycleAnnouncement_title: "6.- Restaurar anuncios seleccionados",
  step6_recycleAnnouncement_text:
    "Haz click aquí para restaurar los anuncios seleccionados",
  step_final_recycleAnnouncement:
    "Haz aprendido cómo interactuar con la papelera de Anuncios",
  // rest
  published: "Publicados",
  scheduled: "Programados",
  newAnnouncement: "Anuncio Nuevo",
  announcements: "Anuncios",
  announcement: "Anuncio",
  generals: "Generales",
  programmed: "Programados",
  expiration: "Expiración",
  creator: "Creador",
  repeat: "Repetir",
  nextPublication: "Siguiente Publicación",
  programAnnouncement: "Programar anuncio",
  programAnnouncementNow: "El anuncio se publicará al momento de añadirlo",
  programAnnouncementLater: "El anuncio se programará para después",
  repeatList: "Lista de repetición",
  description: "Descripción",
  startDate: "Fecha de inicio",
  expirationDate: "Fecha de expiración",
  time: "Tiempo",
  distributionList: "Lista de distribución",
  never: "Nunca",
  everyDay: "Cada Día",
  everyWeek: "Cada Semana",
  everyTwoWeeks: "Cada Dos Semanas",
  everyMonth: "Cada Mes",
  everyYear: "Cada Año",
  noAnnouncements: "No se han creado anuncios al momento",
  addAnnouncement: "Añadir Anuncio",
  noAnnouncementsPar:
    "Si deseas agregar uno, te invitamos a dar clic al botón de añadir anuncios para comenzar con el proceso",
  deleteAnnouncement: "ELIMINAR ANUNCIO",
  confirmDelete:
    "¿Estás seguro de eliminar el anuncio? Al realizar esta acción no podrá revertirse",
  recycleBin: "Basurero Anuncios",
  editAnnouncement: "Editar Anuncio",
  programForLater: "Programar para despues",
  addAnnouncementTitle: "No se han creado anuncios al momento",
  addAnnouncementValidation:
    "Si deseas agregar uno, te invitamos a dar clic al botón de añadir para comenzar con el proceso",
  noAnnouncementsRestore: "No hay anuncios seleccionados para restaurar",
  expire: "Expira",
};
