import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectTheme } from "../../../../features/preferences/preferencesSlice";
import { selectCurrentUser } from "../../../../features/userSettings/userSettingsSlice";

function CommentReply({
  reply,
  placeholder,
  isLoading,
  disabled = false,
  requiredText = false,
}) {
  const [message, setMessage] = useState("");
  const user = useSelector(selectCurrentUser);
  const darkMode = useSelector(selectTheme);

  const { t: tDrawings } = useTranslation("drawings");

  const handleOnReply = () => {
    reply(message);
    setMessage("");
  };

  return (
    <Box display="flex" alignItems="start" py={1}>
      <Avatar
        sx={{ backgroundColor: "white" }}
        title={`${user?.firstName} ${user?.lastName}`}
        src={user?.urlAvatar}
      />
      <FormControl
        fullWidth
        sx={{
          ml: "10px",
          minHeight: "55px",
        }}
      >
        <OutlinedInput
          disabled={isLoading || disabled}
          placeholder={placeholder}
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          multiline
          maxRows={5}
          endAdornment={
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
              }}
            >
              {isLoading ? (
                <CircularProgress color="primary" />
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    p: "2px 16px",
                    textTransform: "capitalize",
                    borderRadius: "16px",
                    height: "28px",
                  }}
                  onClick={() => {
                    if (requiredText) {
                      if (message.length > 0) handleOnReply();
                    } else {
                      handleOnReply();
                    }
                  }}
                  disabled={isLoading || disabled}
                >
                  {tDrawings("comment")}
                </Button>
              )}
            </Box>
          }
          sx={{
            paddingY: 1,
            paddingX: 2,
            backgroundColor: darkMode ? "black" : "white",
            "& fieldset": { border: "none" },
          }}
        />
      </FormControl>
    </Box>
  );
}

export { CommentReply };
