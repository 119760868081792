import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rfis: [],
  current: null,
  settingsRfiPopupOpen: false,
  sampleRfi: null,
  sampleDailyReportId: null,
  settingsDailyReportsPopupOpen: false,
  sampleTask: null,
  settingsTasksPopupOpen: false,
  addPhotoModalOpen: false,
  addAlbumModalOpen: false,
  sampleImage: null,
  imageViewerOpen: false,
  settingsPhotosPopupOpen: false,
};

export const rfiSlice = createSlice({
  name: "rfi",
  initialState,
  reducers: {
    setRfis: (state, action) => {
      state.rfis = action.payload;
    },
    setCurrentRfi: (state, action) => {
      state.current = action.payload;
    },
    setSettingsRfiPopupOpen: (state, action) => {
      state.settingsRfiPopupOpen = action.payload;
    },
    setSampleRfi: (state, action) => {
      state.sampleRfi = action.payload;
    },
    setSampleDailyReportId: (state, action) => {
      state.sampleDailyReportId = action.payload;
    },
    setSettingsDailyReportsPopupOpen: (state, action) => {
      state.settingsDailyReportsPopupOpen = action.payload;
    },
    setSampleTask: (state, action) => {
      state.sampleTask = action.payload;
    },
    setSettingsTasksPopupOpen: (state, action) => {
      state.settingsTasksPopupOpen = action.payload;
    },
    setAddPhotoModalOpen: (state, action) => {
      state.addPhotoModalOpen = action.payload;
    },
    setAddAlbumModalOpen: (state, action) => {
      state.addAlbumModalOpen = action.payload;
    },
    setSampleImage: (state, action) => {
      state.sampleImage = action.payload;
    },
    setImageViewerOpen: (state, action) => {
      state.imageViewerOpen = action.payload;
    },
    setSettingsPhotosPopupOpen: (state, action) => {
      state.settingsPhotosPopupOpen = action.payload;
    },
    resetRfis: () => initialState,
  },
});

export const selectAllRfis = (state) => state.rfi.rfis;

export const selectCurrentRfi = (state) => state.rfi.current;

export const selectSettingsRfiPopupOpen = (state) =>
  state.rfi.settingsRfiPopupOpen;

export const selectSettingsDailyReportsPopupOpen = (state) =>
  state.rfi.settingsDailyReportsPopupOpen;

export const selectSampleRfi = (state) => state.rfi.sampleRfi;

export const selectSampleDailyReportId = (state) =>
  state.rfi.sampleDailyReportId;

export const selectSampleTask = (state) => state.rfi.sampleTask;

export const selectSettingsTasksPopupOpen = (state) =>
  state.rfi.settingsTasksPopupOpen;

export const selectAddPhotoModalOpen = (state) => state.rfi.addPhotoModalOpen;

export const selectAddAlbumModalOpen = (state) => state.rfi.addAlbumModalOpen;

export const selectSampleImage = (state) => state.rfi.sampleImage;

export const selectImageViewerOpen = (state) => state.rfi.imageViewerOpen;

export const selectSettingsPhotosPopupOpen = (state) =>
  state.rfi.settingsPhotosPopupOpen;

export const {
  setRfis,
  setCurrentRfi,
  setSettingsRfiPopupOpen,
  setSampleRfi,
  resetRfis,
  setSampleDailyReportId,
  setSettingsDailyReportsPopupOpen,
  setSampleTask,
  setSettingsTasksPopupOpen,
  setAddPhotoModalOpen,
  setAddAlbumModalOpen,
  setSampleImage,
  setImageViewerOpen,
  setSettingsPhotosPopupOpen,
} = rfiSlice.actions;

export default rfiSlice.reducer;
