import { tour } from "./tour";

export const projectDirectory = {
  ...tour,
  moduleElement: "用户",
  moduleName: "目录",
  step1_addUser_title: "1. 将新用户添加到项目",
  step1_addUser_text: "点击此处添加新用户到项目。",
  step2_addUser_title: "2. 选择用户",
  step2_addUser_text: "输入用户的全名，然后从列表中选择一个用户。",
  step3_addUser_title: "3. 职位",
  step3_addUser_text: "输入用户在项目中的职位。",
  step4_addUser_title: "4. 默认角色",
  step4_addUser_text:
    "为联系人选择默认角色，无论他们只能查看而不能编辑，还是完全没有权限。",
  step5_addUser_title: "5. 个别权限",
  step5_addUser_text: "为每个模块选择特定的角色。",
  step6_addUser_title: "6. 确认",
  step6_addUser_text: "点击此处确认并添加用户。",
  step_final_addUser: "您已学会如何向项目添加新用户。",
  // VIEW - guide me steps
  step1_viewUser_title: "1. 查看用户信息",
  step1_viewUser_text: "点击此处查看用户的信息。",
  step2_viewUser_title: "2. 最后登录",
  step2_viewUser_text: "在这里，您可以查看用户的最后登录信息。",
  step3_viewUser_title: "3. 最近操作",
  step3_viewUser_text: "在这里，您可以查看用户的最近操作信息。",
  step_final_viewUser: "您已学会如何查看项目用户的信息。",
  // EDIT - guide me steps
  step1_editUser_title: "1. 编辑用户",
  step1_editUser_text: "点击此处编辑用户。",
  step2_editUser_title: "2. 职位",
  step2_editUser_text: "编辑联系人在项目中的职位。",
  step3_editUser_title: "3. 默认角色",
  step3_editUser_text:
    "编辑联系人的默认角色，无论他们只能查看而不能编辑，还是完全没有权限。",
  step4_editUser_title: "4. 个别权限",
  step4_editUser_text: "编辑每个模块的角色。",
  step5_editUser_title: "5. 保存",
  step5_editUser_text: "点击此处保存更改。",
  step_final_editUser: "您已学会如何编辑用户。",
  directory: "目录",
  addCompany: "添加公司",
  lastLogin: "最后登录",
  infoText: "未连接 ",
  days: " 天",
  wasConnectedToday: " 在过去的24小时内连接",
  hasNeverLoggedIn: " 从未登录",
  latestActivity: "最新活动",
  createRfi: "创建RFI 24",
  responseSubmittal: "响应提交",
  createDailyReport: "创建每日报告",
  createRfi123: "创建RFI 123",
  uploadPhotos: "上传照片",
  newContact: "新联系人",
  editContact: "编辑联系人",
  generalInformation: "常规信息",
  permissions: "权限",
  drawings: "图纸",
  punchList: "问题清单",
  rfis: "RFIs",
  submittals: "提交",
  deliveries: "交付",
  hours: "工时",
  meetings: "会议",
  photos: "照片",
  tasks: "任务",
  inspections: "检查",
  issues: "问题",
  dailyReports: "每日报告",
  documents: "文档",
  delays: "延迟",
  announcements: "公告",
  dashbooard: "仪表盘",
  adminPanel: "管理面板",
  generalDirectory: "常规目录",
  deleteContact: "删除联系人",
  confirmDelete: "您确定要删除此联系人吗？此操作无法撤销",
  currentPlan: "当前计划",
  activeUsers: "活跃用户",
  type: "类型",
  user: "用户",
  contact: "联系人",
  usersLimit: "用户限制",
  confirmUsersLimit: "您确定要继续添加新用户吗？此操作将增加额外的用户支出。",
  deletedRoleMessage: "此用户的当前角色已被删除，请选择新角色",
};
