import * as React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

export function DateFormat({ date, withTime, ...props }) {
  const { t: tGeneral } = useTranslation("general");
  const months = [
    tGeneral("january"),
    tGeneral("february"),
    tGeneral("march"),
    tGeneral("april"),
    tGeneral("may"),
    tGeneral("june"),
    tGeneral("july"),
    tGeneral("august"),
    tGeneral("september"),
    tGeneral("october"),
    tGeneral("november"),
    tGeneral("december"),
  ];

  function getDate(simpleDate) {
    const dateInput = new Date(simpleDate);

    const dateFormat = `${dateInput.getDate()} ${
      months[dateInput.getMonth()]
    } ${dateInput.getFullYear()}`;

    return dateFormat;
  }

  function getDateWithTime(simpleDate) {
    const date = new Date(simpleDate);

    let hrs = date.getHours();
    let ampm = "";
    if (hrs < 11) {
      ampm = "AM";
    } else {
      if (hrs !== 12) {
        hrs -= 12;
      }
      ampm = "PM";
    }

    let mins = date.getMinutes();
    if (mins < 10) mins = `0${mins}`;
    if (hrs < 10) hrs = `0${hrs}`;

    const dateFormat =
      `${date.getDate()} ${
        months[date.getMonth()]
      } ${date.getFullYear()}\xa0\xa0\xa0\xa0\xa0` +
      " " +
      `\xa0\xa0\xa0${hrs}:${mins} ${ampm}`;

    return dateFormat;
  }

  return (
    <>
      <Typography variant="caption" color="secondary" sx={props}>
        {withTime ? getDateWithTime(date) : getDate(date)}
      </Typography>
    </>
  );
}

// export default DateFormat();

export function formatDate(simpleDate) {
  const date = new Date(simpleDate);

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // const months = [
  //   tGeneral("january"),
  //   tGeneral("february"),
  //   tGeneral("march"),
  //   tGeneral("april"),
  //   tGeneral("may"),
  //   tGeneral("june"),
  //   tGeneral("july"),
  //   tGeneral("august"),
  //   tGeneral("september"),
  //   tGeneral("october"),
  //   tGeneral("november"),
  //   tGeneral("december"),
  // ];

  const dateBirthFormat = `${date.getDate()} ${
    month[date.getMonth()]
  } ${date.getFullYear()}`;

  return dateBirthFormat;
}

export function formatDateMsg(simpleDate) {
  const date = new Date(simpleDate);

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let hrs = date.getHours();
  let ampm = "";
  if (hrs < 11) {
    ampm = "AM";
  } else {
    if (hrs !== 12) {
      hrs -= 12;
    }
    ampm = "PM";
  }

  let mins = date.getMinutes();
  if (mins < 10) mins = `0${mins}`;
  if (hrs < 10) hrs = `0${hrs}`;

  const dateBirthFormat =
    `${date.getDate()} ${
      month[date.getMonth()]
    } ${date.getFullYear()}\xa0\xa0\xa0\xa0\xa0` +
    " " +
    `\xa0\xa0\xa0\xa0\xa0${hrs}:${mins} ${ampm}`;

  return dateBirthFormat;
}

// For text field
export function formatDatePicker(simpleDate) {
  const dateFormat = new Date(`${simpleDate.split("T")[0]}T06:00:00.000Z`);
  let month = dateFormat.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  let day = dateFormat.getDate();
  if (day < 10) {
    day = `0${day}`;
  }
  return `${dateFormat.getFullYear()}-${month}-${day}`;
}

export function formatSimpleDate(simpleDate) {
  let dateFormat = new Date(simpleDate);
  let month = dateFormat.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  let day = dateFormat.getDate();
  if (day < 10) {
    day = "0" + day;
  }
  return `${dateFormat.getFullYear()}-${month}-${day}`;
}

function hasTimePast(firstDate, secondDate) {
  if (firstDate.getTime() > secondDate.getTime()) {
    return true;
  } else {
    return false;
  }
}

function hasDayPast(firstDate, secondDate) {
  if (firstDate.getDay() > secondDate.getDate()) {
    return true;
  } else {
    return false;
  }
}

export function getNextDateAnnouncement(inputDate, repetition) {
  const nextDate = new Date(inputDate);
  const today = new Date();
  const date = new Date(inputDate);
  if (repetition === "everyDay") {
    date.setDate(
      hasTimePast(today, nextDate) ? today.getDate() + 1 : today.getDate(),
    );
  } else if (repetition === "everyWeek") {
    date.setDate(
      hasDayPast(today, nextDate)
        ? today.getDate() + ((7 - today.getDay() + nextDate.getDay()) % 7)
        : today.getDate() + ((7 - today.getDay() + nextDate.getDay()) % 7 || 7),
    );
  } else if (repetition === "everyTwoWeeks") {
    // TODO: esta incorrecto porque toma las dos semanas a partir de hoy
    date.setDate(
      hasTimePast(today, nextDate)
        ? today.getDate() + ((nextDate.getDay() + 14 - today.getDay()) % 14)
        : today.getDate() +
            ((nextDate.getDay() + 14 - today.getDay()) % 14 || 14),
    );
  } else if (repetition === "everyMonth") {
    if (today.getDate() > nextDate.getDate()) {
      date.setDate(today.getMonth() + 1); //if date has past
    } else if (today.getDate() < nextDate.getDate()) {
      date.setDate(today.getMonth());
    } else {
      //same day
      date.setDate(
        hasTimePast(today, nextDate) ? today.getMonth() + 1 : today.getMonth(),
      );
    }
  } else if (repetition === "everyYear") {
    if (today.getMonth() > nextDate.getMonth()) {
      date.setDate(today.getYear() + 1); //if month has past
    } else if (today.getMonth() < nextDate.getMonth()) {
      date.setDate(today.getYear());
    } else {
      //same month
      if (today.getDate() > nextDate.getDate()) {
        date.setDate(today.getMonth() + 1); //if day has past
      } else if (today.getDate() < nextDate.getDate()) {
        date.setDate(today.getMonth());
      } else {
        //same day
        date.setDate(
          hasTimePast(today, nextDate)
            ? today.getMonth() + 1
            : today.getMonth(),
        );
      }
    }
  }

  return date;
}

export function getLastPublication(inputDate, repetition) {
  const nextDate = new Date(inputDate);
  const today = new Date();
  const date = new Date(inputDate);
  if (repetition === "everyDay") {
    date.setDate(
      hasTimePast(today, nextDate) ? today.getDate() : today.getDate() - 1,
    );
  } else if (repetition === "everyWeek") {
    date.setDate(
      today.getDay === nextDate.getDay()
        ? hasTimePast(today, nextDate)
          ? today.getDate()
          : today.getDate() - ((nextDate.getDay() + 6) % 7)
        : today.getDate() - ((nextDate.getDay() + 6) % 7),
    );
  } else if (repetition === "everyTwoWeeks") {
    // TODO: esta incorrecto porque toma las dos semanas a partir de hoy
    date.setDate(
      today.getDay === nextDate.getDay()
        ? hasTimePast(today, nextDate)
          ? today.getDate()
          : today.getDate() - ((nextDate.getDay() + 12) % 14)
        : today.getDate() - ((nextDate.getDay() + 12) % 14),
    );
  } else if (repetition === "everyMonth") {
    if (today.getDate() > nextDate.getDate()) {
      date.setDate(today.getMonth()); //if date has past
    } else if (today.getDate() < nextDate.getDate()) {
      date.setDate(today.getMonth() - 1);
    } else {
      //same day
      date.setDate(
        hasTimePast(today, nextDate) ? today.getMonth() : today.getMonth() - 1,
      );
    }
  } else if (repetition === "everyYear") {
    if (today.getMonth() > nextDate.getMonth()) {
      date.setDate(today.getYear()); //if month has past
    } else if (today.getMonth() < nextDate.getMonth()) {
      date.setDate(today.getYear() - 1);
    } else {
      //same month
      if (today.getDate() > nextDate.getDate()) {
        date.setDate(today.getMonth()); //if day has past
      } else if (today.getDate() < nextDate.getDate()) {
        date.setDate(today.getMonth() - 1);
      } else {
        //same day
        date.setDate(
          hasTimePast(today, nextDate)
            ? today.getMonth()
            : today.getMonth() - 1,
        );
      }
    }
  }

  return date;
}
