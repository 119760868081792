export const submittals = {
  submittal: "Envio",
  submittalTitleModule: "Envios",
  deleteSubmittalsTitle: "Excluir envios",
  deleteSubmittalsContent: "Tem certeza de que deseja excluir estes envios?",
  addSubmittal: "Adicionar envio",
  noSubmittalsTitle: "Nenhum envio foi criado no momento",
  addSubmittalsValidation:
    "Se deseja adicionar um, convidamos você a clicar no botão Adicionar Envio para iniciar o processo",
  submittalRecycleBin: "Lixeira de Envios",
  costCode: "Código de custo",
};
