export const analytics = {
  projectProgress: "프로젝트 진행 상황",
  hi: "안녕",
  realProgress: "실제 진행 상황",
  welcomeBack: "다시 환영해요",
  moduleStatistics: "모듈 통계",
  onTime: "정시",
  overdue: "연체",
  constructionPercentage: "건설 비율",
  completed: "완료",
  thisWeek: "이번 주",
  workForce: "노동력",
  hours: "시간",
  completedTasks: "완료된 작업",
  analytics: "분석",
  addWidgets: "표시할 위젯 추가",
  projectStatistics: "프로젝트 통계",
  rfis: "RFIs",
  tasks: "작업",
  submittals: "제출물",
  drawingsProgress: "도면 진행",
  drawingsCount: "도면",
  inspections: "검사",
  inspectionCount: "검사",
  reports: "보고서",
  reportCount: "보고서",
  delayed: "지연",
  closed: "닫힘",
  open: "열림",
  pending: "보류 중",
  inProgress: "진행 중",
  done: "완료",
  revision: "수정",
  total: "총계",
  selectWidget: "선택할 위젯을 선택하십시오",
  widgetDisplayed: "선택한 위젯이 대시보드에 표시됩니다",
  somethingWentWrong: "문제가 발생했습니다",
  basic: "기본",
  manpower: "인력",
  workProgress: "작업 진행",
  problem: "문제",
  delay: "지연",
  categoryReports: "카테고리 보고서",
  projectAdvancement: "프로젝트 진척",
  remainingDays: "프로젝트 완료까지 남은 일수",
  day: "일",
  progress: "진척",
  remaining: "남은",
  error: "오류",
  notEnoughSpace: "이 위젯에 대한 공간이 부족합니다.",
  w: "주",
  progressOverTime: "시간이 지남에 따른 진행",
  week: "주",
  noDataAvailable: "데이터가 없습니다.",
};
