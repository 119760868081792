export const announcements = {
  published: "公開済み",
  scheduled: "予定",
  newAnnouncement: "新しいお知らせ",
  announcements: "お知らせ",
  announcement: "お知らせ",
  generals: "一般",
  programmed: "プログラム化",
  expiration: "有効期限",
  creator: "作成者",
  repeat: "繰り返し",
  nextPublication: "次の公開",
  programAnnouncement: "お知らせをプログラムする",
  programAnnouncementNow: "お知らせは今すぐ公開されます",
  programAnnouncementLater: "お知らせは後でプログラムされます",
  repeatList: "繰り返しリスト",
  description: "説明",
  startDate: "開始日",
  expirationDate: "有効期限日",
  time: "時間",
  distributionList: "配信リスト",
  never: "なし",
  everyDay: "毎日",
  everyWeek: "毎週",
  everyTwoWeeks: "2週間ごと",
  everyMonth: "毎月",
  everyYear: "毎年",
  noAnnouncements: "現在、お知らせはありません",
  addAnnouncement: "お知らせを追加",
  noAnnouncementsPar:
    "追加する場合は、以下のボタンをクリックしてプロセスを開始してください",
  deleteAnnouncement: "お知らせを削除",
  confirmDelete:
    "このお知らせを削除してもよろしいですか？この操作は元に戻せません",
  recycleBin: "お知らせのごみ箱",
  editAnnouncement: "お知らせを編集",
  programForLater: "後でプログラムする",
  addAnnouncementTitle: "現在、お知らせはありません",
  addAnnouncementValidation:
    "追加する場合は、追加ボタンをクリックしてプロセスを開始してください",
  noAnnouncementsRestore: "復元するお知らせが選択されていません",
  expire: "有効期限切れ",
  step1_addAnnouncement_title: "1. 新しいお知らせを作成する",
  step1_addAnnouncement_text:
    "新しいお知らせを追加するには、+ アイコンをクリックしてください。",
  step2_addAnnouncement_title: "2. お知らせのスケジュール",
  step2_addAnnouncement_text:
    "お知らせを即座に公開するか、後日公開するかを選択してください。後者を選択した場合、追加のオプションが表示されます。",
  step2_1_addAnnouncement_title: "2.1 繰り返し",
  step2_1_addAnnouncement_text:
    "お知らせが繰り返される頻度を選択してください。",
  step2_2_addAnnouncement_title: "2.2 開始日",
  step2_2_addAnnouncement_text: "お知らせが公開される日付を選択してください。",
  step2_3_addAnnouncement_title: "2.3 開始時間",
  step2_3_addAnnouncement_text: "お知らせが公開される時間を選択してください。",
  step3_addAnnouncement_title: "3. 説明",
  step3_addAnnouncement_text: "お知らせの件名を記入してください。",
  step4_addAnnouncement_title: "4. 配信リスト",
  step4_addAnnouncement_text: "お知らせの通知を受け取る人を選択してください。",
  step5_addAnnouncement_title: "5. 有効期限",
  step5_addAnnouncement_text: "お知らせが期限切れになる日を選択してください。",
  step6_addAnnouncement_title: "6. 有効期限時間",
  step6_addAnnouncement_text:
    "お知らせが期限切れになり、もはや表示されなくなる時間を選択してください。",
  step7_addAnnouncement_title: "7. 追加",
  step7_addAnnouncement_text:
    "お知らせを公開するにはここをクリックしてください。",
  step_final_addAnnouncement: "お知らせの作成方法を学びました",
  step1_viewAnnouncement_title: "1. お知らせを表示する",
  step1_viewAnnouncement_text:
    "お知らせの情報を表示するにはここをクリックしてください。",
  step2_viewAnnouncement_title: "2. お知らせを表示する",
  step2_viewAnnouncement_text:
    "ポップアップウィンドウで、お知らせの説明や日付などの関連情報を確認できます。",
  step_final_viewAnnouncement: "お知らせの表示方法を学びました",
  step1_editAnnouncement_title: "1. お知らせを編集する",
  step1_editAnnouncement_text:
    "お知らせを編集するにはここをクリックしてください。",
  step2_editAnnouncement_title: "2. お知らせのスケジュールを編集する",
  step2_editAnnouncement_text:
    "お知らせを即座に公開するか、後日公開するかを編集してください。後者を選択した場合、追加のオプションが表示されます。",
  step2_1_editAnnouncement_title: "2.1 繰り返しを編集する",
  step2_1_editAnnouncement_text:
    "お知らせが繰り返される頻度を編集してください。",
  step2_2_editAnnouncement_title: "2.2 開始日を編集する",
  step2_2_editAnnouncement_text: "お知らせが開始される日付を編集してください。",
  step2_3_editAnnouncement_title: "2.3 開始時間を編集する",
  step2_3_editAnnouncement_text: "お知らせが開始される時間を編集してください。",
  step3_editAnnouncement_title: "3. 説明を編集する",
  step3_editAnnouncement_text: "お知らせの件名を編集してください。",
  step4_editAnnouncement_title: "4. 配信リストを編集する",
  step4_editAnnouncement_text:
    "お知らせを見ることができる人を編集してください。",
  step5_editAnnouncement_title: "5. 有効期限を編集する",
  step5_editAnnouncement_text:
    "お知らせがもはや有効でなくなる日を編集してください。",
  step6_editAnnouncement_title: "6. 有効期限時間を編集する",
  step6_editAnnouncement_text:
    "お知らせが期限切れになり、もはや表示されなくなる時間を編集してください。",
  step7_editAnnouncement_title: "7. 編集",
  step7_editAnnouncement_text:
    "変更内容を保存するにはここをクリックしてください。",
  step_final_editAnnouncement: "お知らせの編集方法を学びました",
  step1_recycleAnnouncement_title: "1. その他のオプション",
  step1_recycleAnnouncement_text:
    "詳細オプションを表示するにはここをクリックしてください。",
  step2_recycleAnnouncement_title: "2. お知らせごみ箱",
  step2_recycleAnnouncement_text:
    "お知らせのごみ箱を表示するにはここをクリックしてください。",
  step3_recycleAnnouncement_title: "3. ごみ箱メニュー",
  step3_recycleAnnouncement_text: "これはお知らせのごみ箱メニューです。",
  step4_recycleAnnouncement_title: "4. お知らせの表示",
  step4_recycleAnnouncement_text:
    "復元したいお知らせの情報を表示するにはここをクリックしてください。",
  step5_recycleAnnouncement_title: "5. お知らせを選択する",
  step5_recycleAnnouncement_text:
    "復元するお知らせを1つまたは複数選択してください。",
  step6_recycleAnnouncement_title: "6. 選択したお知らせを復元する",
  step6_recycleAnnouncement_text:
    "選択したお知らせを復元するにはここをクリックしてください。",
  step_final_recycleAnnouncement: "お知らせのごみ箱とのやり取り方を学びました",
  guideMe: "ガイド",
  support: "サポート",
};
