export function getNames(ids, userList) {
  let names = [];
  for (var i = 0; i < ids.length; i++) {
    var index = userList.findIndex(function (item, j) {
      return item.id === ids[i];
    });
    if (index > -1) {
      names.push(userList[index].name);
    }
  }

  return names;
}

export function getIds(names, userList) {
  let ids = [];
  for (var i = 0; i < names.length; i++) {
    var index = userList.findIndex(function (item, j) {
      return item.name === names[i];
    });
    ids.push(userList[index].id);
  }
  return ids;
}

export function getUserInfo(names, userList) {
  let users = [];
  for (var i = 0; i < names.length; i++) {
    var index = userList.findIndex(function (item, j) {
      return item.name === names[i];
    });
    users.push({
      id: userList[index].id,
      name: userList[index].name,
      urlAvatar: userList[index].urlAvatar,
    });
  }
  return users;
}

export function getSelectedUsers(ids, usersList) {
  let group = [];
  if (usersList.length > 0) {
    for (var i = 0; i < ids.length; i++) {
      var index = usersList.findIndex(function (item, j) {
        return item.id === ids[i];
      });
      group.push({
        id: usersList[index]?.id,
        name: usersList[index]?.name,
        urlAvatar: usersList[index]?.urlAvatar,
      });
    }
  }
  return group;
}

export function getUserInfoByIdUser(idsUser, usersList) {
  let users = [];
  if (usersList.length > 0) {
    for (var i = 0; i < idsUser.length; i++) {
      var index = usersList.findIndex(function (item, j) {
        return item.idUser === idsUser[i];
      });
      users.push(usersList[index]);
    }
  }
  return users;
}

export function getCreatorInfo(idCreator, usersList) {
  let user = null;
  if (usersList.length > 0) {
    var index = usersList.findIndex(function (item, j) {
      return item.idUser === idCreator;
    });
    user = usersList[index];
  }
  return user;
}

export function getUsersByUserId(ids, usersList) {
  // only for get idUsers (not idProjectDirectory)
  let users = [];
  if (usersList.length > 0) {
    for (var i = 0; i < ids.length; i++) {
      var index = usersList.findIndex(function (item, j) {
        return item.id === ids[i];
      });
      users.push(usersList[index]?.idUser);
    }
  }
  return users;
}
