export const plan = {
  perUser: "Por usuário",
  perProject: "Por projeto",
  perMonth: "Por mês",
  perYear: "Por ano",
  monthly: "Mensal",
  annual: "Anual",
  bestValue: "Melhor valor",
  youWillPay: "Você pagará",
  activeProjects: "Projetos Ativos",
  activeUsers: "Usuários Ativos",
};
