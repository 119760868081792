const inspectionsTemplates = {
  add: "Add Template",
  edit: "Edit Template",
  guideMe: "Guide me",
  inspection_template_recycle_bin: "Inspections - Templates - Recycle Bin",
  list: "Inspection List",
  meeting: "Meeting",
  no_inspections_templates_text:
    "If you want to add one, we invite you to click the add Inspection Template button to start the process",
  no_inspections_templates_title:
    "No Inspections Templates have been created at the moment",
  title: "Inspections - Templates",
  workflow: "Workflow",
  add_button_tour: "Create a new inspection template",
};

export { inspectionsTemplates };
