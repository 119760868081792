import { tour } from "./tour";

const roles = {
  // general tour
  ...tour,
  moduleElement: "Roles",
  // ADD - guide me steps
  step1_addRole_title: "1. Agregar rol",
  step1_addRole_text: "Haz click aquí para agregar un nuevo rol.",
  step2_addRole_title: "2. Nombre del rol",
  step2_addRole_text: "Dale un nombre al rol que se va a crear.",
  step3_addRole_title: "3. Permisos",
  step3_addRole_text:
    "Selecciona el tipo de permiso para cada modulo que tendrá el rol",
  step4_addRole_title: "4. Guardar",
  step4_addRole_text: "Haz click aqui para agregar el rol a la lista de roles.",
  step_final_addRole: "Haz aprendido cómo agregar un nuevo rol de usuario.",
  // EDIT - guide me steps
  step1_editRole_title: "1. Editar rol",
  step1_editRole_text: "Haz click aqui para editar el rol.",
  step2_editRole_title: "2. Nombre del rol",
  step2_editRole_text: "Edita el nombre del rol.",
  step3_editRole_title: "3. Permisos",
  step3_editRole_text:
    "Edita el tipo de permiso para cada modulo que tiene el rol.",
  step4_editRole_title: "4. Guardar",
  step4_editRole_text: "Haz click aqui para guardar los cambios hechos al rol.",
  step_final_editRole: "Haz aprendido cómo actualizar un rol.",
  // DELETE - guide me steps
  step1_deleteRole_title: "1. Eliminar rol",
  step1_deleteRole_text: "Haz click aqui para eliminar el rol.",
  step2_deleteRole_title: "2. Aceptar",
  step2_deleteRole_text: "Haz click aqui para confirmar la eliminación",
  step_final_deleteRole: "Haz aprendido cómo eliminar un rol.",
  // CHANGE ROLE - guide me steps
  step1_changeRole_title: "1. Rol de usuario",
  step1_changeRole_text: "Selecciona el usuario para actualizar el rol.",
  step2_changeRole_title: "2. Rol",
  step2_changeRole_text: "Selecciona el rol que será asignado.",
  step3_changeRole_title: "3. Guardar",
  step3_changeRole_text: "Click para guardar los cambios.",
  step_final_changeRole: "Haz aprendido cómo actualizar el rol de un usuario.",
};

export { roles };
