import { createSlice } from "@reduxjs/toolkit";

export const DEFAULT_PAGINATION_INDEX = 0;
export const DEFAULT_PAGINATION_LIMIT = 25;

export const CONST_DEFAULT_DESIGN_TEMPLATES_FILTERS = {
  editedBy: [],
  createdAfter: null,
  createdBefore: null,
};

export const CONST_DEFAULT_DESIGN_TEMPLATES_W_PAGINATION = {
  page: DEFAULT_PAGINATION_INDEX,
  limit: DEFAULT_PAGINATION_LIMIT,
};

const initialState = {
  filters: {
    editedBy: [],
    createdAfter: null,
    createdBefore: null,
    page: DEFAULT_PAGINATION_INDEX,
    limit: DEFAULT_PAGINATION_LIMIT,
  },
};

export const designTemplatesSlice = createSlice({
  name: "designTemplates",
  initialState,
  reducers: {
    setCurrentFiltersDesignTemplates: (state, action) => {
      state.filters = action.payload;
    },
    resetDesignTemplates: () => initialState,
  },
});

export const selectCurrentFiltersDesignTemplates = (state) =>
  state.designTemplates.filters;

export const {
  setEditingTask,
  addSelectedTask,
  removeSelectedTask,
  resetSelectedTasks,
  setCurrentFiltersDesignTemplates,
  resetDesignTemplates,
} = designTemplatesSlice.actions;

export default designTemplatesSlice.reducer;
