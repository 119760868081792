import { Check, Close } from "@mui/icons-material";
import {
  Box,
  ButtonGroup,
  Card,
  Checkbox,
  FormControlLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { FilePreviewHorizontalScroll, TooltipIconButton } from "../../shared";
import { useUploadBimMutation } from "../../../features/project/modules/bim";
import { PopUpAlert } from "../../PopUpAlert";

function ModalAddBim({ isOpen, onClose }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tBim } = useTranslation("bim");
  const { idProject } = useParams();
  const [files, setFiles] = useState([]);
  const [name, setName] = useState("");
  const [useFileName, setUseFileName] = useState(false);

  const [uploadBim, { isLoading, isSuccess, isError, error, reset }] =
    useUploadBimMutation();

  const handleDeleteFile = (file, i) => {
    const newFiles = [...files];
    newFiles.splice(i, 1);
    setFiles(newFiles);
  };

  const fileName = useMemo(() => {
    if (files.length > 0) {
      return files[0].name.replace(".rvt", "");
    }
    return "";
  }, [files]);

  const handleUploadBim = () => {
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("name", useFileName ? fileName : name);
    formData.append("idProject", idProject);
    uploadBim(formData);
  };

  const handleClose = useCallback(() => {
    setFiles([]);
    setName("");
    setUseFileName(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert(
        "success",
        tGeneral("success"),
        tGeneral("createdSuccessfully"),
      );
      reset();
      handleClose();
    }
    if (isError) {
      PopUpAlert("error", "Error", error);
      reset();
      handleClose();
    }
  }, [isSuccess, isError, error, tGeneral, reset, handleClose]);

  return (
    <Modal
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      open={isOpen}
      onClose={handleClose}
    >
      <Card sx={{ width: "30%", borderRadius: "1rem", p: "1rem" }}>
        <Box display="flex">
          <Typography fontSize="1.5rem" sx={{ flex: 1 }}>
            {tBim("addBim")}
          </Typography>
          <ButtonGroup>
            <TooltipIconButton
              label={tGeneral("close")}
              icon={<Close />}
              color="black"
              onClick={handleClose}
              disabled={isLoading}
            />
            <TooltipIconButton
              label={tGeneral("upload")}
              icon={<Check />}
              onClick={handleUploadBim}
              disabled={isLoading}
            />
          </ButtonGroup>
        </Box>
        <FilePreviewHorizontalScroll
          files={files}
          onAddPhotos={(newFiles) => setFiles(newFiles)}
          onDelete={handleDeleteFile}
          multiple={false}
        />
        <TextField
          fullWidth
          label={tGeneral("name")}
          value={useFileName ? fileName : name}
          disabled={useFileName}
          onChange={(e) => setName(e.target.value)}
        />
        <Box display="flex" justifyContent="flex-end">
          <FormControlLabel
            control={
              <Checkbox
                checked={useFileName}
                onChange={(e) => setUseFileName(e.target.checked)}
                size="small"
              />
            }
            label={tGeneral("useFileName")}
            sx={{ "& .MuiFormControlLabel-label": { fontSize: "0.8rem" } }}
          />
        </Box>
      </Card>
    </Modal>
  );
}

export { ModalAddBim };
