import { Close, Download } from "@mui/icons-material";
import {
  Box,
  Card,
  Divider,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import UserAvatar from "../../../../../components/userAvatars/UserAvatar";
import { useTranslation } from "react-i18next";

const EMAILS = [
  {
    id: "12313jasd",
    createdBy: "Elsa Quijano",
    createdByAvatar: null,
    subject: "FW: Folder: The City",
    to: "Juan Lopez",
    cc: ["Diego González", "Sonia Rodríguez", "Melissa Tovar"],
    bcc: ["Cristian López"],
    message: "Juan, Please see above wastes set of the city",
  },
];

function EmailsHistoryModal({ isOpen, onClose, file }) {
  const { t: tGeneral } = useTranslation("general");
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{ width: "40%", height: "90%", p: "40px", borderRadius: "20px" }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontSize="1.563rem" fontWeight="bold">
            {tGeneral("emails")}: {file?.name}
          </Typography>
          <IconButton>
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ my: "30px" }} />
        <Box>
          <Card sx={{ borderRadius: "20px", p: "30px" }}>
            {EMAILS.map((email, i) => (
              <Box
                key={email.id}
                sx={{
                  borderBottom:
                    i === EMAILS.length - 1 ? "none" : "1px solid #D7D7D7",
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  columnGap="14px"
                  mb="30px"
                >
                  <UserAvatar
                    name={email.createdBy}
                    url={email.createdByAvatar}
                  />
                  <Typography fontWeight="bold">{email.createdBy}</Typography>
                  <Box flex="1" />
                  <IconButton data-tour="docs-file-info-5_1">
                    <Download />
                  </IconButton>
                </Box>
                <Box display="flex" columnGap="12px">
                  <Box display="flex" flexDirection="column" rowGap="10px">
                    <Typography fontWeight="bold">
                      {tGeneral("subject")}
                    </Typography>
                    <Typography fontWeight="bold">{tGeneral("to")}</Typography>
                    <Typography fontWeight="bold">cc</Typography>
                    <Typography fontWeight="bold">bcc</Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" rowGap="10px">
                    <Typography>{email.subject}</Typography>
                    <Typography>{email.to}</Typography>
                    <Typography>{email.cc}</Typography>
                    <Typography>{email.bcc}</Typography>
                  </Box>
                </Box>
                <Typography fontWeight="bold" mt="10px">
                  {email.message}
                </Typography>
              </Box>
            ))}
          </Card>
        </Box>
      </Card>
    </Modal>
  );
}

export { EmailsHistoryModal };
