const statusOptions = [
  {
    value: "open",
    label: "OPEN",
    color: "#5ce381",
  },
  {
    value: "close",
    label: "CLOSED",
    color: "#707070",
  },
  {
    value: "draft",
    label: "DRAFT",
    color: "#ffc900",
  },
];

export { statusOptions };
