import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editingReport: null,
  selected: [],
  needReload: false,
};

export const dailyReportsSlice = createSlice({
  name: "dailyReports",
  initialState,
  reducers: {
    setEditingReport: (state, action) => {
      state.editingReport = action.payload;
    },
    setSelectedReports: (state, action) => {
      state.selected = action.payload;
    },
    resetSelectedReports: (state) => {
      state.selected = [];
    },
    resetReportsState: () => initialState,
    setNeedReload: (state, action) => {
      state.needReload = action.payload;
    },
  },
});

export const selectEditingReport = (state) => state.dailyReports.editingReport;
export const selectSelectedReports = (state) => state.dailyReports.selected;
export const selectNeedReload = (state) => state.dailyReports.needReload;

export const {
  setEditingReport,
  setSelectedReports,
  resetSelectedReports,
  resetReportsState,
  setNeedReload,
} = dailyReportsSlice.actions;

export default dailyReportsSlice.reducer;
