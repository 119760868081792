import { Avatar, Box, Card, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AssignedUsersTooltip } from "./AssignedUsersTooltip";

function ListDisplay({ users = [], label }) {
  const { t: tGeneral } = useTranslation("general");

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      {users.length > 0 ? <AssignedUsersTooltip users={users} /> : <Avatar />}
      {users.length > 0 ? (
        <Box display="flex" mt="5px">
          <Typography
            fontSize="0.875rem"
            fontWeight="medium"
            textAlign="center"
          >
            {users[0]?.name ?? `${users[0]?.firstName} ${users[0]?.lastName}`}{" "}
            {users[0]?.bActive ? "" : `(${tGeneral("inactive")})`}
          </Typography>
          {users.length > 1 && (
            <Typography
              fontSize="0.875rem"
              fontWeight="medium"
              textAlign="center"
            >
              &nbsp;{tGeneral("and")} +{users.length - 1}
            </Typography>
          )}
        </Box>
      ) : (
        <Typography
          fontSize="0.875rem"
          fontWeight="medium"
          textAlign="center"
          mt="5px"
        >
          {tGeneral("noOne")}
        </Typography>
      )}
      <Typography fontSize="0.75rem" fontw="true" textAlign="center">
        {label}
      </Typography>
    </Box>
  );
}

function RelatedUsersDetailsCard({ lists }) {
  return (
    <Card sx={{ display: "flex", flex: 1 }} data-tour="view-tasks-2">
      {lists.map(({ users, label }) => (
        <ListDisplay users={users} label={label} key={label}/>
      ))}
    </Card>
  );
}

export { RelatedUsersDetailsCard };
