import {
  Avatar,
  AvatarGroup,
  Box,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import { useTranslation } from "react-i18next";

function AssignedUsersTooltip({ users }) {
  const { t: tGeneral } = useTranslation("general");
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
      padding: 10,
      maxHeight: "200px",
      overflow: "auto",
    },
  }));

  if (!users || !users.length) {
    return <Typography>N/A</Typography>;
  }

  const getUserInitials = (user) => {
    if (user?.name) {
      const splittedName = user.name.split(" ");
      return `${splittedName[0][0]}${splittedName[1][0]}`;
    }
    return `${user?.firstName?.[0]}${user?.lastName?.[0]}`;
  };

  return (
    <LightTooltip
      arrow
      title={users.map((user) => (
        <Box
          display="flex"
          key={user?.id}
          justifyContent="center"
          alignItems="center"
          mb="5px"
        >
          <Avatar
            alt={user?.name}
            src={user?.urlAvatar}
            sx={{
              backgroundColor: "#FBCB04",
              opacity: user?.bActive ? 1 : 0.5,
            }}
          >
            {getUserInitials(user)}
          </Avatar>
          <Box display="flex" flex={1} columnGap="0.5rem">
            <Typography color={user?.bActive ? "initial" : "gray"}>
              &nbsp;
              {user?.name ?? `${user?.firstName} ${user?.lastName}`}
            </Typography>{" "}
            {!user?.bActive && (
              <Typography color="gray">({tGeneral("inactive")})</Typography>
            )}
            {user?.icon}
          </Box>
        </Box>
      ))}
    >
      <AvatarGroup max={3} sx={{ justifyContent: "center" }}>
        {users.map((user) => (
          <Avatar
            key={user?.id}
            alt={user?.name}
            src={user?.urlAvatar}
            sx={{
              backgroundColor: "#FBCB04",
              opacity: user?.bActive ? 1 : 0.5,
            }}
          >
            {getUserInitials(user)}
          </Avatar>
        ))}
      </AvatarGroup>
    </LightTooltip>
  );
}

export { AssignedUsersTooltip };
