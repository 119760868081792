export const VIDEO_EXTENSIONS = [
  "mp4",
  "mov",
  "avi",
  "wmv",
  "flv",
  "ogv",
  "webm",
  "mpeg",
  "mpg",
];
