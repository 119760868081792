export const payment = {
  paymentMethod: "결제 방법",
  invoiceDetails: "송장 세부 정보",
  addNewCard: "새로운 카드 추가",
  addData: "정보 추가",
  orderSummary: "주문 요약",
  pricePerProject: "프로젝트 당 가격",
  pricePerUser: "사용자 당 가격",
  projectsNumber: "프로젝트 수",
  usersNumber: "사용자 수",
  totalPerYear: "연간 총액",
  totalPerMonth: "월간 총액",
  agreement:
    "사용자 수에 따라 송장이 작성될 것을 이해합니다. 결제 후 계정이나 프로젝트에 사용자를 초대하여 라이선스를 추가할 수 있습니다. 사용자는 언제든지 제거할 수 있습니다.",
  editCard: "카드 편집",
  emailRecipient: "이메일 수신인",
  companyName: "회사 이름",
  addCard: "카드 추가",
  cardNumber: "카드 번호",
};
