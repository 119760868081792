export const general = {
  addFilter: "필터 추가",
  settings: "설정",
  restore: "복원",
  location: "위치",
  locations: "위치",
  uploadedBy: "업로드한 사용자",
  attendance: "참석",
  assignees: "담당자",
  assigned: "담당됨",
  category: "카테고리",
  priority: "우선순위",
  createdBy: "작성자",
  distributionList: "배포 목록",
  noUsersSelected: "선택된 사용자 없음",
  noAttendanceUsers: "참석 사용자 없음",
  actions: "동작",
  day: "일",
  month: "월",
  year: "년",
  week: "주",
  rename: "이름 변경",
  delete: "삭제",
  deleteAll: "모두 삭제",
  cancel: "취소",
  confirm: "확인",
  edit: "편집",
  information: "정보",
  comments: "댓글",
  takenOn: "촬영일",
  uploadedOn: "업로드일",
  errorMessage: "오류가 발생했습니다. 나중에 다시 시도하세요.",
  errorUploadMessage:
    "파일을 업로드할 수 없습니다. 인터넷 연결을 확인한 후 나중에 다시 시도해 주세요.",
  errorDownloadMessage:
    "파일을 다운로드할 수 없습니다. 인터넷 연결을 확인한 후 나중에 다시 시도해 주세요.",
  close: "닫기",
  save: "저장",
  logOut: "로그아웃",
  view: "보기",
  seeDetails: "자세히 보기",
  download: "다운로드",
  add: "추가",
  update: "업데이트",
  accept: "수락",
  loading: "로딩 중",
  loadingDots: "로딩 중...",
  email: "이메일",
  phone: "전화번호",
  name: "이름",
  lastName: "성",
  position: "직책",
  enterprise: "기업",
  role: "역할",
  module: "모듈",
  superAdmin: "슈퍼 관리자",
  admin: "관리자",
  viewOnly: "읽기 전용",
  none: "없음",
  invalid: "유효하지 않음",
  search: "검색",
  projectSearch: "프로젝트 전체 검색",
  open: "열기",
  open_action: "열기",
  still_open: "아직 열림",
  public: "공개",
  private: "비공개",
  privacy: "개인정보",
  date: "날짜",
  closedDate: "종료일",
  status: "상태",
  title: "제목",
  draft: "임시",
  favorite: "즐겨찾기",
  back: "뒤로",
  configUpdated: "구성이 성공적으로 업데이트되었습니다",
  errorConfigUpdated: "구성을 업데이트할 수 없습니다",
  hour: "시간",
  selectStatus: "상태 선택",
  publish: "게시",
  attendanceConfirmed: "참석이 성공적으로 확인되었습니다",
  errorAttendanceConfirmed: "참석을 확인할 수 없습니다",
  copy: "복사",
  confirmAttendance: "참석 확인",
  pdf: "PDF",
  creator: "생성자",
  lastSeen: "최근 접속",
  lastEdit: "최근 편집",
  systemLanguage: "시스템 언어",
  address: "주소",
  street: "거리",
  number: "번호",
  country: "국가",
  state: "주",
  city: "도시",
  zipCode: "우편번호",
  next: "다음",
  en: "영어",
  es: "스페인어",
  file: "파일",
  files: "파일",
  dateReceived: "수신일",
  features: "기능",
  drawings: "도면",
  drawing: "도면",
  submittals: "서브미션",
  rfis: "RFI",
  dailyReports: "일일 보고서",
  issues: "이슈",
  photos: "사진",
  tasks: "작업",
  inspections: "검사",
  documents: "문서",
  meetings: "회의",
  delay: "지연",
  directory: "디렉토리",
  hours: "시간",
  deliveries: "배송",
  announcements: "공지사항",
  user: "사용자",
  project: "프로젝트",
  projects: "프로젝트",
  subscribe: "구독",
  notFound: "프로젝트를 찾을 수 없음",
  noProjects: "현재 생성된 프로젝트가 없습니다",
  createButton: "프로젝트를 추가하려면 프로젝트 생성 버튼을 클릭하세요",
  createProject: "프로젝트 생성",
  loadingEnterprises: "기업 로딩 중...",
  generalDirectory: "일반 디렉토리",
  superAdmins: "슈퍼 관리자",
  roles: "역할",
  manager: "매니저",
  constructionDirector: "건설 디렉터",
  architect: "건축가",
  engineer: "엔지니어",
  active: "활성화",
  inactive: "비활성화",
  generalInfo: "일반 정보",
  changePlan: "플랜 변경",
  billing: "결제",
  paymentMethod: "결제 수단",
  updateProfile: "기업 프로필 업데이트",
  youSureEnterprise: "이 데이터로 기업 프로필을 업데이트하시겠습니까?",
  enterpriseName: "기업 이름",
  enterpriseType: "기업 유형",
  enterpriseImage: "기업 로고",
  success: "성공",
  done: "완료!",
  enterpriseUpdated: "기업 정보가 성공적으로 업데이트되었습니다.",
  error: "오류",
  errorBig: "오류!",
  errorOccurred: "오류가 발생했습니다. 나중에 다시 시도하세요.",
  logout: "로그아웃",
  profile: "프로필",
  currentPlan: "현재 플랜",
  per: "당",
  activeProjects: "활성화된 프로젝트",
  activeUsers: "활성화된 사용자",
  annual: "연간",
  monthly: "월간",
  allTheFeatures: "모든 기능",
  unlimitedProjects: "무제한 프로젝트",
  unlimitedUsers: "무제한 사용자",
  unlimitedStorage: "무제한 저장소",
  translationSpanglish: "스페인어-영어 번역",
  companyReports: "회사 브랜드 보고서",
  customizableReports: "사용자 정의 보고서 템플릿",
  home: "홈",
  changePassword: "비밀번호 변경",
  tickets: "티켓",
  preferences: "환경 설정",
  userManual: "사용자 매뉴얼",
  notifications: "알림",
  male: "남성",
  female: "여성",
  editProfile: "프로필 편집",
  firstName: "이름",
  birthDate: "생년월일",
  gender: "성별",
  inProcess: "진행 중",
  currentPassword: "현재 비밀번호",
  newPassword: "새 비밀번호",
  repeatPassword: "비밀번호 확인",
  passwordNotice:
    "비밀번호는 대문자 1개, 소문자 1개, 숫자 1개, 특수문자 (_, #, ?, !, @, $, %, ^, &, *, -) 1개를 포함하고 8자 이상이어야 합니다.",
  passwordYouSure: "비밀번호를 변경하시겠습니까?",
  enterPassword: "비밀번호 입력",
  changedPassword: "비밀번호가 성공적으로 변경되었습니다",
  welcomeTickets: "환영합니다! 아래에서 발견된 문제의 카테고리를 선택하세요.",
  mobile: "모바일",
  browser: "브라우저",
  device: "기기",
  details: "세부사항",
  send: "보내기",
  mode: "모드",
  dark: "다크",
  light: "라이트",
  exportCvs: "CSV로 내보내기",
  exportPdf: "PDF로 내보내기",
  newSuperAdmin: "새로운 슈퍼 관리자",
  dashboard: "대시보드",
  adminPanel: "관리자 패널",
  visibleModules: "표시되는 모듈",
  orderModules: "모듈 순서",
  projectAdmin: "프로젝트 관리",
  specification: "사양",
  specifications: "사양",
  costCodes: "비용 코드",
  connections: "연결",
  projectName: "프로젝트 이름",
  translation: "번역",
  managers: "매니저",
  constructionManager: "건설 매니저",
  projectEngineer: "프로젝트 엔지니어",
  editInfo: "정보 편집",
  on: "켜짐",
  off: "꺼짐",
  addSuperAdmin: "슈퍼 관리자 추가",
  deleteSuperAdmin: "슈퍼 관리자 삭제",
  areYouAdmin:
    "이 슈퍼 관리자를 삭제하시겠습니까? 이 작업을 수행하면 되돌릴 수 없습니다",
  writeDetail: "발견된 버그에 대해 자세히 작성하세요.",
  dragFiles: "여기로 파일을 드래그하거나 클릭하여 파일을 선택하세요",
  dragFile: "여기로 파일을 드래그하거나 클릭하여 파일을 선택하세요",
  emails: "이메일",
  subject: "제목",
  to: "받는 사람",
  createdOn: "생성일",
  info: "정보",
  move: "이동",
  favorites: "즐겨찾기",
  generalInformation: "일반 정보",
  permissions: "권한",
  managePermissions: "권한 관리",
  changeHistory: "변경 내역",
  published: "게시됨",
  deleted: "삭제됨",
  noPermission: "이 페이지를 볼 권한이 없습니다",
  recycleBin: "휴지통",
  not_defined: "정의되지 않음",
  closed: "종료됨",
  no_assinged_users: "담당자 없음",
  due_date: "마감일",
  closed_date: "종료일",
  approve: "승인하다",
  emptyDataSourceMessage: "표시할 레코드가 없습니다",
  of: "/",
  deletedPermanently: "영구 삭제됨",
  deletedSuccessfully: "삭제 성공",
  updatedSuccessfully: "업데이트 성공",
  createdSuccessfully: "생성 성공",
  recoveredSuccessfully: "복구 성공",
  closedSuccessfully: "종료 성공",
  approvedSuccessfully: "승인 성공",
  cantRestore: "복원할 수 없음",
  cantClose: "종료할 수 없음",
  cantCreate: "생성할 수 없음",
  cantApprove: "승인할 수 없음",
  cantDelete: "삭제할 수 없음",
  cantEdit: "편집할 수 없음",
  deleteRFI: "RFI를 삭제하시겠습니까?",
  question: "질문",
  startWriting: "작성 시작...",
  additionalInformation: "추가 정보",
  receivedFrom: "발신자",
  company: "회사",
  cost: "비용",
  price: "가격",
  currency: "통화",
  mxn: "MXN - 멕시코 페소",
  usd: "USD - 미국 달러",
  emptyQuestion: "질문을 비워둘 수 없습니다",
  createDate: "생성일",
  newResponse: "새로운 응답...",
  newComment: "새로운 댓글...",
  newTopic: "새로운 주제...",
  topics: "주제",
  showTranslation: "번역 보기",
  closeTranslation: "번역 닫기",
  viewFiles: "파일 보기",
  images: "이미지",
  noImages: "이미지 없음",
  noDocuments: "문서 없음",
  attachFiles: "파일 첨부 또는 드래그하여 선택하세요",
  january: "1월",
  february: "2월",
  march: "3월",
  april: "4월",
  may: "5월",
  june: "6월",
  july: "7월",
  august: "8월",
  september: "9월",
  october: "10월",
  november: "11월",
  december: "12월",
  noEnterprises: "아직 기업이 없는 것 같습니다!",
  pendingItems: "보류 중인 항목",
  weather: "날씨",
  todoList: "할 일 목록",
  ensureThat:
    "프로젝트에 유효한 주소가 있는지 확인하거나 나중에 다시 시도하세요.",
  addItemList: "할 일 목록에 항목 추가",
  noAnno: "볼 공지사항이 없습니다",
  daysLeft: "남은 일수",
  congratulations: "축하합니다",
  noPending: "보류 중인 항목이 없습니다.",
  noFilterableElements: "검색 기준과 일치하는 요소를 찾을 수 없습니다",
  addList: "할 일 목록 추가",
  activityDesc: "활동 설명",
  expirationDate: "만료일",
  onTime: "제 시간에",
  overdue: "기한 초과",
  areYouTask: "이 작업을 완료로 표시하시겠습니까?",
  trashCan: "휴지통",
  newAnnouncement: "새로운 공지사항",
  startDate: "시작일",
  time: "시간",
  limitDate: "기한",
  cancelled: "취소됨",
  operations: "작업이 취소되었습니다.",
  task: "작업",
  dailyReport: "일일 보고서",
  deletedBy: "삭제한 사용자",
  deletedAt: "삭제한 날짜",
  publishedDate: "게시일",
  moved: "이동됨",
  selectAll: "모두 선택",
  pleaseMakeSureAllDataIsCorrect: "모든 데이터가 올바른지 확인하세요.",
  addLocation: "위치 추가",
  new: "새로운",
  sendSomeoneComment: "@누군가에게 댓글 보내기",

  endDate: "종료 날짜",
  recent: "최근",
  thisWeek: "이번 주",
  thisMonth: "이번 달",
  lastWeek: "지난 주",
  projectConfigurationUpdateSuccess:
    "프로젝트 구성이 성공적으로 업데이트되었습니다",
  description: "설명",
  enterValidTitle: "유효한 제목을 입력하세요",
  enterValidDescription: "유효한 설명을 입력하세요",
  enterValidDate: "유효한 날짜를 입력하세요",
  enterValidStatus: "유효한 상태를 입력하세요",
  enterValidPriority: "유효한 우선순위를 입력하세요",
  enterValidLocation: "유효한 위치를 입력하세요",
  enterValidCategory: "유효한 카테고리를 입력하세요",
  fieldRequired: "이 필드는 필수입니다",
  cantUndone: "이 작업은 되돌릴 수 없습니다.",
  users: "사용자",
  minutes: "분",
  noOne: "아무도 없음",
  noComments: "댓글 없음",
  noTasks: "작업 없음",
  updated: "업데이트됨",
  restored: "복원됨",
  created: "생성됨",
  successfully: "성공적으로",
  labelRowsPerPage: "페이지당 행:",
  labelRowsSelect: "행",
  firstAriaLabel: "첫 페이지",
  firstTooltip: "첫 페이지",
  previousAriaLabel: "이전 페이지",
  previousTooltip: "이전 페이지",
  nextAriaLabel: "다음 페이지",
  nextTooltip: "다음 페이지",
  lastAriaLabel: "마지막 페이지",
  lastTooltip: "마지막 페이지",
  adminEnabledSuccessfully: "관리자 사용자가 성공적으로 활성화되었습니다",
  cantEnableAdmin: "관리자 사용자를 활성화할 수 없습니다",
  adminDisabledSuccessfully: "관리자 사용자가 성공적으로 비활성화되었습니다",
  cantDisableAdmin: "관리자 사용자를 비활성화할 수 없습니다",
  emptyFields: "빈 필드",
  cantCreateUser: "사용자를 생성할 수 없습니다",
  userCreatedSuccessfully: "사용자가 성공적으로 생성되었습니다",
  userEditedSuccessfully: "사용자가 성공적으로 편집되었습니다",
  cantEditUser: "사용자를 편집할 수 없습니다",
  userDeletedSuccessfully: "사용자가 성공적으로 삭제되었습니다",
  cantDeleteUser: "사용자를 삭제할 수 없습니다",
  companyCreatedSuccessfully: "회사가 성공적으로 생성되었습니다",
  cantCreateCompany: "회사를 생성할 수 없습니다",
  totalEnterprises: "총 기업",
  totalContacts: "총 연락처",
  enterpriseRoleUpdated: "기업 역할이 성공적으로 업데이트되었습니다",
  cantUpdateEnterpriseRole: "기업 역할을 업데이트할 수 없습니다",
  enterpriseRoleDeleted: "기업 역할이 성공적으로 삭제되었습니다",
  cantDeleteEnterpriseRole: "기업 역할을 삭제할 수 없습니다",
  newRole: "새 역할",
  cantEditUnclassifiedRole: "미분류된 역할을 편집할 수 없습니다",
  cantDeleteUnclassifiedRole: "미분류된 역할을 삭제할 수 없습니다",
  noUsersRole: "이 역할을 가진 사용자 없음",
  deleteRole: "역할 삭제",
  deleteRoleMessage:
    "이 역할을 삭제하시겠습니까? 이 작업을 수행하면 되돌릴 수 없습니다.",
  editRole: "역할 편집",
  roleName: "역할 이름",
  invalidName: "유효하지 않은 이름",
  cantCreateRole: "역할을 생성할 수 없습니다",
  roleCreatedSuccessfully: "역할이 성공적으로 생성되었습니다",
  roleEditedSuccessfully: "역할이 성공적으로 편집되었습니다",
  cantEditRole: "역할을 편집할 수 없습니다",
  addRole: "역할 추가",
  roleNameEmpty: "역할 이름은 비워둘 수 없습니다",
  projectCreated: "프로젝트가 성공적으로 생성되었습니다",
  cantCreateProject: "프로젝트를 생성할 수 없습니다",
  userName: "사용자 이름",
  selectUser: "사용자 선택",
  userAlreadyAdded: "이미 추가된 사용자입니다",
  newProject: "새 프로젝트",
  skip: "건너뛰기",
  fillRequiredFields: "필수 필드 작성",
  exportInfo: "정보 내보내기",
  deleteImage: "이미지 삭제",
  clickAddProject: "프로젝트 추가를 클릭하세요",
  other: "기타",
  projectTypeCreated: "프로젝트 유형이 성공적으로 생성되었습니다",
  cantCreateProjectType: "프로젝트 유형을 생성할 수 없습니다",
  projectType: "프로젝트 유형",
  addProjectType: "프로젝트 유형 추가",
  cover: "커버",
  logo: "로고",
  recommendedSize: "권장 크기",
  projectManager: "프로젝트 매니저",
  mexico: "멕시코",
  unitedStates: "미국",
  guatemala: "과테말라",
  colombia: "콜롬비아",
  chile: "칠레",
  argentina: "아르헨티나",
  projectLimitReached: "프로젝트 한도에 도달했습니다",
  projectsLimitReached: "프로젝트 한도에 도달했습니다",
  projectAddWarning:
    "한 프로젝트를 더 생성하시겠습니까? 이 작업을 수행하면 추가 프로젝트가 다음 청구서에 청구됩니다.",
  billingInformation: "청구 정보",
  enterpriseInfoUpdated: "기업 정보가 성공적으로 업데이트되었습니다",
  cantUpdateEnterpriseInfo: "기업 정보를 업데이트할 수 없습니다",
  reference: "참조",
  amount: "금액",
  paymentDate: "결제 날짜",
  emailRecipient: "이메일 수신자",
  cardNumber: "카드 번호",
  mmaa: "MM/YY",
  addCard: "카드 추가",
  selectAvatar: "아바타 선택",
  userUpdate: "사용자가 성공적으로 업데이트되었습니다",
  cantUpdateUser: "사용자를 업데이트할 수 없습니다",
  avatarUpdateError: "아바타 업데이트 오류",
  feelsLike: "체감 온도",
  weatherValidation:
    "날씨를 가져오려면 프로젝트에 우편 번호와 국가가 있는지 확인하거나 나중에 다시 시도하세요",
  clear: "맑음",
  cloudy: "흐림",
  mostlyClear: "대체로 맑음",
  partlyCloudy: "구름 조금",
  mostlyCloudy: "대체로 흐림",
  fog: "안개",
  lightFog: "약한 안개",
  drizzle: "이슬비",
  rain: "비",
  lightRain: "약한 비",
  heavyRain: "폭우",
  snow: "눈",
  flurries: "눈보라",
  lightSnow: "약한 눈",
  heavySnow: "폭설",
  drizzleFreezing: "이슬비 결빙",
  freezingRain: "얼음비",
  freezingLightRain: "약한 얼음비",
  freezingHeavyRain: "폭우와 얼음비",
  icePellets: "얼음 알갱이",
  heavyIcePellets: "큰 얼음 알갱이",
  lightIcePellets: "작은 얼음 알갱이",
  thunderstorm: "천둥번개",
  Sun: "일",
  Mon: "월",
  Tue: "화",
  Wed: "수",
  Thu: "목",
  Fri: "금",
  Sat: "토",
  cantUpdateConfig: "구성을 업데이트할 수 없습니다",
  drawingsAdded: "도면이 성공적으로 추가되었습니다",
  modified: "수정됨",
  modification: "수정",
  from: "부터",
  moveFolderTo: "폴더 이동",
  moveFileTo: "파일 이동",
  locationCreated: "위치가 성공적으로 생성되었습니다",
  cantCreateLocation: "위치를 생성할 수 없습니다",
  locationUpdated: "위치가 성공적으로 업데이트되었습니다",
  cantUpdateLocation: "위치를 업데이트할 수 없습니다",
  locationDeleted: "위치가 성공적으로 삭제되었습니다",
  cantDeleteLocation: "위치를 삭제할 수 없습니다",
  type: "유형",
  addType: "유형 추가",
  typeAdded: "유형이 성공적으로 추가되었습니다",
  editType: "유형 편집",
  typeUpdated: "유형이 성공적으로 업데이트되었습니다",
  deleteType: "유형 삭제",
  typeDeleted: "유형이 성공적으로 삭제되었습니다",
  superLocation: "상위 위치",
  sureDelete: "삭제하시겠습니까",
  specificationAdded: "사양이 성공적으로 추가되었습니다",
  costCodeAdded: "비용 코드가 성공적으로 추가되었습니다",
  specificationUpdated: "사양이 성공적으로 업데이트되었습니다",
  costCodeUpdated: "비용 코드가 성공적으로 업데이트되었습니다",
  specificationDeleted: "사양이 성공적으로 삭제되었습니다",
  costCodeDeleted: "비용 코드가 성공적으로 삭제되었습니다",
  addSpecification: "사양 추가",
  addCostCode: "비용 코드 추가",
  editSpecification: "사양 편집",
  editCostCode: "비용 코드 편집",
  code: "코드",
  deleteSpecification: "사양 삭제",
  deleteCostCode: "비용 코드 삭제",
  deleteGeneric:
    "이 요소를 삭제하시겠습니까? 이 작업을 수행하면 되돌릴 수 없습니다",
  seen: "읽음",
  create: "생성",
  rfi: "RFI",
  meeting: "회의",
  project_directory: "프로젝트 디렉토리",
  createuser: "사용자 생성",
  deleteAgendaItem: "안건 삭제",
  deleteAgendaItemMessage:
    "이 안건을 삭제하시겠습니까? 이 작업을 수행하면 되돌릴 수 없습니다.",
  viewCurrentFiles: "현재 파일 보기",
  reportAnIssue: "문제 신고",
  message: "메시지",
  sendToEmail: "이메일로 보내기",
  sent: "전송됨",
  sentMessage: "이메일이 성공적으로 전송되었습니다.",
  timezone: "시간대",
  agenda: "안건",
  newPasswordTitle: "새 비밀번호를 입력하세요",
  assignTo: "사용자 할당",
  comment: "댓글",
  linkTo: "링크",
  deliveryDate: "납기일",
  responsible: "담당자",
  invalidEmail: "유효하지 않은 이메일",
  publishedBy: "게시자",
  privacyOptions: "개인 정보 옵션",
  person: "개인",
  companies: "회사",
  onlyFavorites: "즐겨찾기만",
  approvedBy: "승인자",
  approvalDate: "승인 날짜",
  externalContacts: "외부 연락처",
  retry: "재시도",
  generalErrorMessage: "문제가 발생했습니다. 다시 시도하세요",
  yesDeleteIt: "예, 삭제합니다",
  creators: "생성자",
  deleteInspection: "검사를 삭제하시겠습니까?",
  deleteInspectionTemplate: "검사 템플릿을 삭제하시겠습니까?",
  sections: "섹션",
  items: "항목",
  cancelPrompt: "취소하시겠습니까?",
  graph: "그래프",
  quickTemplate: "+ 빠른 템플릿",
  saveAsTemplate: "템플릿으로 저장",
  createRevision: "개정본 생성",
  generate: "생성",
  videos: "비디오",
  noVideos: "비디오 없음",
  audios: "오디오",
  noAudios: "오디오 없음",
  in_revision: "개정 중",
  logoutMessage: "로그아웃하시겠습니까?",
  exportCsv: "CSV 내보내기",
  compare: "비교",
  approved: "승인됨",
  comparing: "비교 중",
  more: "더 보기",
  noCCSelected: "선택된 CC 수신자 없음",
  and: "그리고",
  removeSuperAdminTitle: "슈퍼 관리자 역할 제거",
  removeSuperAdminDescription:
    "이 작업은 사용자에서 슈퍼 관리자 역할을 제거하며 사용자를 삭제하지 않습니다.",
  noResults: "결과 없음",
  noResultsMessage: "다른 필터를 사용해보세요.",
  assignedProjects: "할당된 프로젝트",
  noAssignedProjects: "할당된 프로젝트 없음",
  userAddedSuccessfully: "사용자가 성공적으로 추가되었습니다",
  projectSchedule: "프로젝트 일정",
  noOptions: "옵션 없음",
  noData: "데이터가 없습니다",
  order: "순서",
  changeStatus: "상태 변경",
  changeStatusContent: "사용자 상태를 변경하시겠습니까?",
  select: "선택",
  sign: "서명",
  useExisting: "기존 사용",
  reject: "거부",
  rejectedSuccessfully: "거부되었습니다",
  reviewedBy: "검토자:",
  timeAgo: "{{time}} {{unit}} 전",
  seconds: "초",
  onlyUnread: "읽지 않은 것만 표시",
  days: "일",
  image: "이미지",
  reviewDate: "검토 날짜",
  publicationDate: "게시 날짜",
  inspection: "검사",
  announcement: "공지",
  updatedProject: "프로젝트 정보가 성공적으로 업데이트되었습니다.",
  draw: "그리기",
  history: "기록",
  templates: "템플릿",
  useFileName: "파일 이름 사용",
  progress: "진행",
  bim: "BIM",
  reports: "보고서",
  report: "보고서",
  constants: "상수",
  importInfoMessage:
    "다른 프로젝트에서 정보를 가져와서 생성 프로세스에서 시간을 절약할 수 있습니다.",
  importInfo: "정보 가져오기",
  imported: "가져옴",
  importedSuccessfully: "가져오기 성공",
  createdDate: "생성 날짜",
  acceptedBy: "수락자",
  acceptedDate: "수락 날짜",
  projectNetwork: "프로젝트 네트워크",
  share: "공유",
  shared: "공유됨",
  sharedSuccessfully: "공유 성공",
  sharedOnly: "공유된 항목만",
  altitude: "고도",
  fullscreen: "전체 화면",
  metric: "미터법",
  imperial: "영국식",
  meters: "미터",
  feet: "피트",
  inches: "인치",
  daily_reports: "보고서",
  upload: "업로드",
  enterprises: "기업",
  all: "모두",
  total: "총",
  modules: "모듈",
  filters: "필터",
  filterBy: "필터링",
  clearFilter: "청소하다",
  typeToAdd: "추가할 유형",
  saveChanges: "변경 사항 저장",
  format: "형식",
  showResults: "결과 표시",
  activity: "활동",
  allProjects: "모든 프로젝트",
  allModules: "모든 모듈",
  analytics: "분석",
  uploadingQtyFiles: "{{qty}}개의 파일 업로드 중",
  technicalIssues: "현재 기술적 문제가 발생했습니다",
  comeBackLater: "몇 분 후에 다시 시도해 주세요.",
  noInternetConnection: "인터넷에 연결되지 않음",
  checkConnection: "연결을 다시 확인하거나 Wi-Fi에 연결하세요.",
  charactersLimitError: "문자 수 한도에 도달했습니다",
  maxCharacters: "최대 {{max}} 문자",
  sharedBy: "공유자",
  ccInstruction:
    "유효한 이메일을 입력한 후 Enter 키 또는 ';' 키를 눌러 추가하세요",
  exportExcel: "엑셀로 내보내기",
  excelSuccessfullyDownloaded:
    "귀하의 Excel 문서가 성공적으로 다운로드되었습니다",
  noRfiSelected: "내보낼 RFI를 선택하지 않았습니다.",
  selectRfiToContinue: "계속하려면 RFI를 선택하세요.",
  noSubmittalSelected: "내보낼 제출물을 선택하지 않았습니다.",
  selectSubmittalToContinue: "계속하려면 제출물을 선택하세요.",
  noMoreResults: "더 이상 결과가 없습니다",
  projectDescription: "프로젝트 설명",
  clickToWrite: "글을 쓰려면 여기를 클릭하세요",
  fileUploadMaxSizeWarning: "파일 업로드 크기 제한은 5GB입니다",
  errorFileUploadSizeTitle: "파일 중 하나가 5GB 제한을 초과했습니다",
  errorFileUploadSizeDescription:
    "파일을 확인하고 더 작은 파일로 다시 시도해 주세요",
  errorBimUploadLimitTitle: "프로젝트 BIM 업로드 한도에 도달했습니다",
  errorBimUploadLimitDescription:
    "프로젝트의 아카이브 한도에 도달했습니다. 더 추가하려면 지원팀에 문의하세요.",
  duration: "기간",
  startsAt: "시작 시간",
  weighting: "가중치",
  document: "문서",
  dependsOn: "의존",
  calculate: "계산",
  checklist: "체크리스트",
  activities: "활동",
  weeks: "주",
  disciplines: "분야",
  required: "필수",
  notRequired: "필수 아님",
  duplicate: "복제",
  design: "디자인",
  exportPdfLimitWarning:
    "보고서당 최대 사진 수는 50개입니다. 선택을 줄이고 다시 시도하십시오.",
  exportPdfVideoWarning: "경고, 비디오 파일은 PDF 내보내기를 지원하지 않습니다",
  errorExportPDFMessage:
    "내보내기 PDF를 생성하는 중 오류가 발생했습니다. 지원팀에 문의하십시오",
  downloading: "다운로드 중",
  chatSupport: "지원 채팅",
  fromDate: "이후",
  toDate: "까지",
  descriptions: "설명",
  continue: "계속",
  unavailableEnterprise: "회사 비활성화됨",
  contactSalesForPayment:
    "<a href='mailto:ventas@buildpeer.com'>ventas@buildpeer.com</a>로 연락하여 결제를 진행해 주세요.",
  addPeopleToDistributionList: "배포 목록에 사람 추가",
  invalidRoleNameTitle: "잘못된 동작",
  addInvalidRoleNameWarning:
    "Super Admin 역할 이름은 시스템에서 예약되었습니다.",
  editInvalidRoleNameWarning: "Super Admin 역할은 업데이트할 수 없습니다.",
  deleteInvalidRoleNameWarning: "슈퍼 관리자 역할은 삭제할 수 없습니다.",
  uploads: "업로드",
  downloads: "다운로드",
  uploaded: "업로드됨",
  downloaded: "다운로드됨",
  uploading: "업로드 중",
  downloading: "다운로드 중",
};
