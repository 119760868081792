export const onboarding = {
  welcome: "Bem-vindo",
  enterpriseInfo: "Informações da empresa",
  features: "Recursos",
  choosePlan: "Escolher plano",
  payment: "Pagamento",
  welcomeTitle: "Bem-vindo ao buildpeer",
  welcomeSubtitle: "Conheça o seu projeto e aproveite o processo",
  startCreateEnterprise: "Criar uma empresa",
  joinProject: "Participar de um projeto",
  projectCode: "Código do projeto",
  createEnterprise: "Criar Empresa",
  officialName: "Nome oficial",
  enterpriseType: "Tipo de empresa",
  welcomeJourney: "Bem-vindo à jornada",
  planning: "Planejamento",
  processTracking: "Acompanhamento do processo",
  documentation: "Documentação",
  resources: "Recursos",
  community: "Comunidade",
  management: "Gestão",
  communication: "Comunicação",
};
