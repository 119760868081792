import {
  Box,
  Card,
  CardContent,
  Chip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";

function DateCardDetail({ dateString, label, img, status, type }) {
  const { t: tGeneral } = useTranslation("general");

  const isMobile = useMediaQuery("(max-width:820px)");

  const statusProps = {
    stillOpen: {
      label: tGeneral("still_open").toUpperCase(),
      styles: {
        color: "white",
        fontSize: 10,
        backgroundColor: "#ffc900",
      },
    },
    still_open_past_due: {
      label: tGeneral("still_open").toUpperCase(),
      styles: {
        backgroundColor: "#e15452",
        color: "white",
        fontSize: 10,
        width: 100,
      },
    },
    notDefined: {
      label: tGeneral("not_defined").toUpperCase(),
      styles: {
        color: "white",
        fontSize: 10,
        backgroundColor: "#2196f3",
      },
    },
  };

  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
      }}
    >
      <CardContent
        sx={{
          margin: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          margin={0.5}
          gap="20px"
          flexDirection={isMobile ? "column" : "row"}
        >
          {img}
          <Box margin={0.5}>
            <Typography fontSize="12px" color="GrayText" textAlign="center">
              {label}
            </Typography>
            {type === "delivery" ||
            (type === "closed" && status === "closed") ||
            type === "create" ? (
              <Typography
                fontSize="14px"
                fontWeight="medium"
                textAlign={isMobile ? "center" : "initial"}
              >
                {dateString}
              </Typography>
            ) : new Date(dateString) > new Date() ? (
              <Chip
                sx={statusProps["stillOpen"].styles}
                label={statusProps["stillOpen"].label}
              />
            ) : (
              <Chip
                sx={statusProps["still_open_past_due"].styles}
                label={statusProps["still_open_past_due"].label}
              />
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default DateCardDetail;
