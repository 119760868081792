import { Check } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Calendar from "../../../assets/icons/sharedModules/calendar.png";
import Delivery from "../../../assets/icons/sharedModules/delivery.svg";
import Location from "../../../assets/icons/sharedModules/location.png";
import { selectTheme } from "../../../features/preferences/preferencesSlice";
import { RelatedUsersDetailsCard } from "../../shared";

function GeneralInfo(props) {
  const isMobile = useMediaQuery("(max-width:1100px)");
  const darkMode = useSelector(selectTheme);
  const { t: tGeneral } = useTranslation("general");
  const { currentMeeting } = props;

  function DateCard({ dateString, label, img }) {
    const isMobile = useMediaQuery("(max-width:820px)");

    return (
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <CardContent
          sx={{
            margin: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            margin={0.5}
            gap="20px"
            flexDirection={isMobile ? "column" : "row"}
          >
            {img}
            <Box margin={0.5}>
              <Typography
                fontSize="12px"
                color={darkMode ? "white" : "GrayText"}
                textAlign="center"
              >
                {label}
              </Typography>
              <Typography
                fontSize="14px"
                fontWeight="medium"
                textAlign={isMobile ? "center" : "initial"}
              >
                {dateString}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Box
      display="flex"
      my="20px"
      gap="10px"
      flexWrap="wrap"
      width="100%"
      flexDirection={isMobile ? "column" : "row"}
      data-tour="view-meetings-2"
    >
      <RelatedUsersDetailsCard
        lists={[
          { users: [currentMeeting?.createdBy], label: tGeneral("creator") },
          {
            users: currentMeeting?.attendenceList.map((user) =>
              currentMeeting?.confirmedAttendenceList.findIndex(
                (c) => c.id === user.id,
              ) > -1
                ? { ...user, icon: <Check /> }
                : user,
            ),
            label: tGeneral("attendance"),
          },
          {
            users: currentMeeting?.distributionList,
            label: tGeneral("distributionList"),
          },
        ]}
      />
      <Box
        display="flex"
        flex={1}
        gap="1%"
        maxWidth={isMobile ? "100%" : "60%"}
      >
        {/* Date */}
        <DateCard
          dateString={moment.utc(currentMeeting?.date).format("DD MMMM YYYY")}
          label={tGeneral("date")}
          img={<img src={Calendar} alt="Creado" width="50px" />}
          type="date"
        />

        {/* Hour */}
        <DateCard
          dateString={currentMeeting?.hour}
          label={tGeneral("hour")}
          img={<img src={Delivery} alt="Entrega" width="60px" />}
          type="delivery"
        />

        {/* Location */}
        <DateCard
          dateString={
            currentMeeting?.location ? currentMeeting?.location : "N/A"
          }
          label={tGeneral("location")}
          img={<img src={Location} alt="Location" width="40px" />}
          type="location"
        />
      </Box>
    </Box>
  );
}

export default GeneralInfo;
