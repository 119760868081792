import { Box, Card, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DailyReportIcon from "../../../../assets/icons/modules/dailyReport.png";
import Delays from "../../../../assets/icons/modules/delays.png";
import Deliveries from "../../../../assets/icons/modules/deliveries.png";
import Issues from "../../../../assets/icons/modules/issues.png";
import Meetings from "../../../../assets/icons/modules/minutesNew.png";
import PunchList from "../../../../assets/icons/modules/punchList.png";
import RfiIcon from "../../../../assets/icons/modules/rfis.png";
import SubmittalsIcon from "../../../../assets/icons/modules/submittals.png";
import TasksIcon from "../../../../assets/icons/modules/tasks.png";
import Vobos from "../../../../assets/icons/modules/vobos.png";

const moduleIcons = {
  rfi: RfiIcon,
  submittal: SubmittalsIcon,
  tasks: TasksIcon,
  reports: DailyReportIcon,
  vobos: Vobos,
  delays: Delays,
  punchList: PunchList,
  deliveries: Deliveries,
  issues: Issues,
  meetings: Meetings,
  inspections: Vobos,
};

function ModuleCard({ moduleName, data, openPendings }) {
  const { t: tGeneral } = useTranslation("general");

  const moduleLabels = {
    meetings: tGeneral("meetings"),
    punchList: "Punch List",
    directory: "Directory",
    vobos: "VoBos",
    rfi: "RFIs",
    submittal: tGeneral("submittals"),
    deliveries: "Deliveries",
    hours: "Hours",
    photos: "Photos",
    tasks: tGeneral("tasks"),
    issues: "Issues",
    reports: tGeneral("reports"),
    delays: "Delays",
    inspections: tGeneral("inspections"),
  };

  const onTime = data.filter(
    (object) =>
      moment(object.dueDate).diff(new Date()) > 0 || object.dueDate === null,
  ).length;
  const outdated = data.filter(
    (object) => moment(object.dueDate).diff(new Date()) <= 0,
  ).length;
  const [hoverOnTime, setHoverOnTime] = useState(false);
  const [hoverOutdated, setHoverOutdated] = useState(false);
  return (
    <Card
      onClick={() => openPendings({ moduleName, data })}
      sx={{
        display: "flex",
        height: "84px",
        borderRadius: "22px",
        p: "22px",
        alignItems: "center",
        columnGap: "32px",
        mb: "5px",
        mx: "10px",
        cursor: "pointer",
      }}
    >
      <img
        width="40px"
        height="40px"
        src={moduleIcons[moduleName]}
        alt={moduleName}
      />
      <Typography
        flex={1}
        fontSize="1.375rem"
        color="#6A6A6A"
        fontWeight="medium"
      >
        {moduleLabels[moduleName]}
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        rowGap="10px"
        alignItems="flex-end"
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          component="div"
          onMouseEnter={() => setHoverOnTime(true)}
          onMouseLeave={() => setHoverOnTime(false)}
          sx={{
            backgroundColor: "#FFC42D",
            borderRadius: "20px",
            height: "26px",
            width: "40px",
            justifyContent: "space-around",
            alignItems: "center",
            transition: "all 0.2s ease",
            ":hover": {
              height: "34px",
              width: "140px",
            },
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {hoverOnTime && (
            <Typography color="white">{tGeneral("onTime")}</Typography>
          )}
          <Typography color="white" fontWeight="bold">
            {onTime}
          </Typography>
        </Box>
        <Box
          display="flex"
          sx={{
            backgroundColor: "#E05353",
            borderRadius: "20px",
            height: "26px",
            width: "40px",
            justifyContent: "space-around",
            alignItems: "center",
            transition: "all 0.2s ease",
            ":hover": {
              height: "34px",
              width: "140px",
            },
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
          component="div"
          onMouseEnter={() => setHoverOutdated(true)}
          onMouseLeave={() => setHoverOutdated(false)}
        >
          {hoverOutdated && (
            <Typography color="white">{tGeneral("overdue")}</Typography>
          )}
          <Typography color="white" fontWeight="bold">
            {outdated}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

export { ModuleCard };
