import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: null,
};

export const projectDirectorySlice = createSlice({
  name: "projectDirectory",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    resetDirectoryState: () => initialState,
  },
});

export const selectUserInfo = (state) => state.projectDirectory.userInfo;

export const { setUserInfo, resetDirectoryState } =
  projectDirectorySlice.actions;

export default projectDirectorySlice.reducer;
