import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
// Material UI Components
import MuiAppbar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
// MUI Icons
import MenuIcon from "@mui/icons-material/Menu";
// Redux
import { useSelector } from "react-redux";
// Custom Components
import { Search } from "@mui/icons-material";
import { Button } from "@mui/material";
import DrawerEnterprise from "./enterpriseDashboard/DrawerEnterprise";
import DrawerProject from "./projectDashboard/DrawerProject";
import DrawerSettings from "./userSettings/DrawerSettings";

// Redux: reducers
import { selectCurrentEnterprise } from "../features/enterprise/enterpriseSlice";
// Redux: queries
import { useGetProjectByIdQuery } from "../features/project/projectApiSlice";
import ModalSearchProjectOverview from "./ModalSearchProjectOverview";
import { DaysLeft } from "./appbar/DaysLeft";

const styles = {
  userMenuText: {
    mx: 2,
  },
};

function Appbar(props) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { pathname } = useLocation();
  const isProject = pathname.match(/project/g);
  const isEnterprise = pathname.match(/enterprise/g);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { drawerWidthLeft = 0 } = props;

  const selectDrawer = () => {
    if (isProject) {
      return (
        <DrawerProject
          drawerWidth={drawerWidthLeft}
          open={mobileOpen}
          toggle={handleDrawerToggle}
        />
      );
    }

    if (isEnterprise) {
      return (
        <DrawerEnterprise
          drawerWidth={drawerWidthLeft}
          open={mobileOpen}
          toggle={handleDrawerToggle}
        />
      );
    }

    return (
      <DrawerSettings
        drawerWidth={drawerWidthLeft}
        open={mobileOpen}
        toggle={handleDrawerToggle}
      />
    );
  };

  return (
    <Box sx={{ display: "flex", flexGrow: 1 }}>
      {/* Select drawer based on props */}
      {selectDrawer()}
    </Box>
  );
}

export default Appbar;
