import {
  Add,
  CloseTwoTone,
  InfoOutlined,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetDrawingsByDisciplineQuery } from "../../../features/drawings";
import {
  useCreateChecklistItemMutation,
  useDeleteItemMutation,
  useEditChecklistItemMutation,
  useLinkChecklistItemMutation,
} from "../../../features/project/modules/drawingsChecklist/drawingsChecklistApiSlice";
import { ModalWithAction, TooltipIconButton } from "../../shared";

function ItemNameInput({ item }) {
  const { t: tDrawings } = useTranslation("drawings");
  const [isEditing, setIsEditing] = useState(false);

  const [editChecklistItem, { isLoading }] = useEditChecklistItemMutation();

  const handleEditItem = (item) => {
    editChecklistItem(item);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Skeleton variant="text" width="100%" />
      </Box>
    );
  }

  if (item.name === "" || isEditing) {
    return (
      <TextField
        placeholder={tDrawings("drawingName")}
        sx={{ height: "1.4rem", minHeight: "unset" }}
        InputProps={{
          sx: { height: "1.4rem", minHeight: "unset", fontSize: "0.875rem" },
        }}
        size="small"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleEditItem({ ...item, name: e.target.value });
            setIsEditing(false);
          }
        }}
        onBlur={(e) => {
          handleEditItem({ ...item, name: e.target.value });
          setIsEditing(false);
        }}
        defaultValue={item.name}
      />
    );
  }

  return (
    <Typography onDoubleClick={() => setIsEditing(true)}>
      {item.name}
    </Typography>
  );
}

function ChecklistItemRow({ item, drawings, onDelete }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawingsChecklist } = useTranslation("drawingsChecklist");

  const [isHover, setIsHover] = useState(false);

  const statuses = ["done", "revision", "pending"];

  const [linkChecklistItem, { isLoading: isLinkLoading }] =
    useLinkChecklistItemMutation();
  const [editChecklistItem, { isLoading: isEditLoading }] =
    useEditChecklistItemMutation();

  const handleLinkItem = (id, idLinkedDrawing) => {
    linkChecklistItem({ id, idLinkedDrawing });
  };

  const handleEditItem = (item) => {
    editChecklistItem(item);
  };

  const statusColor = useMemo(() => {
    if (item.status === "done") return "#E5F5EC";
    if (item.status === "revision") return "#FCF18C";
    return "#E1E1E1";
  }, [item]);

  return (
    <Box
      key={item.id}
      sx={{ ":hover": { backgroundColor: "#FEFDE8" } }}
      component="div"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Box
        display="grid"
        gridTemplateColumns="2fr 1fr 1fr 1fr 30px"
        p="0.25rem 1.5rem"
        alignItems="center"
        columnGap="1rem"
      >
        <ItemNameInput item={item} />
        <Box textAlign="center">
          {isLinkLoading ? (
            <Skeleton variant="text" width="100%" />
          ) : (
            <Select
              variant="standard"
              startAdornment={
                !!item.idLinkedDrawing && (
                  <Tooltip
                    title={moment(item.linkedDate).format("DD/MM/YYYY")}
                    arrow
                    placement="top"
                  >
                    <InfoOutlined fontSize="0.5rem" />
                  </Tooltip>
                )
              }
              IconComponent={KeyboardArrowDown}
              sx={{
                width: "100%",
                backgroundColor: item.idLinkedDrawing ? "transparent" : "#EEE",
                borderRadius: "0.5rem",
              }}
              value={item.idLinkedDrawing}
              displayEmpty
              renderValue={(val) => (
                <Typography>
                  {drawings.find((d) => d.id === val)?.idAux ?? "-"}
                </Typography>
              )}
              size="small"
              disableUnderline
              onChange={(e) => handleLinkItem(item.id, e.target.value)}
            >
              {drawings.map((drawing) => (
                <MenuItem value={drawing.id} disabled={drawing.disabled}>
                  <Box display="flex" columnGap="1rem">
                    <Typography width="100px">{drawing.idAux}</Typography>
                    <Typography flex={1}>{drawing.name}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          )}
        </Box>
        <Box
          display="flex"
          borderRadius="0.5rem"
          width="100%"
          bgcolor="#EEE"
          alignItems="center"
          px="0.375rem"
        >
          {isLinkLoading ? (
            <Skeleton width="100%" />
          ) : (
            <>
              <Tooltip
                sx={{ visibility: item.idLinkedDrawing ? "visible" : "hidden" }}
                title={
                  item.maxRevision
                    ? moment(item.maxRevision.drawingDate).format("DD/MM/YYYY")
                    : ""
                }
                placement="top"
                arrow
              >
                <InfoOutlined fontSize="0.5rem" />
              </Tooltip>
              <Typography flex={1} textAlign="center">
                {item.maxRevision ? `#${item.maxRevision.number}` : "-"}
              </Typography>
            </>
          )}
        </Box>
        <Box>
          {isEditLoading ? (
            <Skeleton width="100%" />
          ) : (
            <Select
              disabled={item.status === "pending"}
              fullWidth
              startAdornment={
                !!item.updatedBy && (
                  <Tooltip
                    title={moment(item.updatedDate).format("DD/MM/YYYY")}
                    arrow
                    placement="top"
                    sx={{ ml: "0.5rem" }}
                  >
                    <InfoOutlined fontSize="0.5rem" />
                  </Tooltip>
                )
              }
              SelectDisplayProps={{ height: "1rem" }}
              IconComponent={KeyboardArrowDown}
              size="small"
              variant="standard"
              value={item.status}
              disableUnderline
              sx={{ backgroundColor: statusColor, borderRadius: "0.5rem" }}
              renderValue={(val) => (
                <Typography textAlign="center">
                  {tDrawingsChecklist(val)}
                </Typography>
              )}
              onChange={(e) =>
                handleEditItem({ ...item, status: e.target.value })
              }
            >
              {statuses.map((status) => (
                <MenuItem value={status}>{tDrawingsChecklist(status)}</MenuItem>
              ))}
            </Select>
          )}
        </Box>
        <Box>
          {isHover && (
            <TooltipIconButton
              label={tGeneral("delete")}
              icon={<CloseTwoTone sx={{ fontSize: "1rem" }} />}
              color="gray"
              onClick={onDelete}
              sx={{ height: "1.4rem", width: "1.4rem" }}
            />
          )}
        </Box>
      </Box>
      <Divider />
    </Box>
  );
}

function ChecklistDisciplineTable({ items, idDiscipline }) {
  const { t: tDrawings } = useTranslation("drawings");
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawingsChecklist } = useTranslation("drawingsChecklist");

  const [deletingItem, setDeletingItem] = useState(null);

  const [createCheckListItem] = useCreateChecklistItemMutation();
  const [deleteChecklistItem, { isLoading: isDeleteLoading }] =
    useDeleteItemMutation();

  const { data } = useGetDrawingsByDisciplineQuery(idDiscipline, {
    skip: !idDiscipline,
  });

  const drawingsOptions = useMemo(() => {
    if (data && items) {
      return data.map((d) => ({
        ...d,
        disabled: items.some((i) => i.idLinkedDrawing === d.id),
      }));
    }
    return [];
  }, [data, items]);

  const handleDeleteItem = async () => {
    await deleteChecklistItem(deletingItem);
    setDeletingItem(null);
  };

  return (
    <Box>
      <Box
        display="grid"
        gridTemplateColumns="2fr 1fr 1fr 1fr 30px"
        p="0.25rem 1.5rem"
        columnGap="1rem"
      >
        <Typography fontWeight="medium">{tDrawings("drawingName")}</Typography>
        <Typography fontWeight="medium" textAlign="center">
          Link
        </Typography>
        <Typography fontWeight="medium" textAlign="center">
          {tDrawings("revision")}
        </Typography>
        <Typography fontWeight="medium" textAlign="center">
          {tGeneral("status")}
        </Typography>
        <Box />
      </Box>
      <Divider />
      {items.map((item) => (
        <ChecklistItemRow
          item={item}
          drawings={drawingsOptions}
          onDelete={() => setDeletingItem(item.id)}
        />
      ))}
      <Button
        fullWidth
        variant="text"
        sx={{
          color: "black",
          textTransform: "none",
          alignItems: "center",
          columnGap: "0.5rem",
        }}
        onClick={() => createCheckListItem({ idDiscipline })}
      >
        <Add /> {tDrawings("addDrawing")}
      </Button>
      <ModalWithAction
        isOpen={!!deletingItem}
        action={handleDeleteItem}
        onClose={() => setDeletingItem(null)}
        actionLabel={tGeneral("delete")}
        title={tDrawingsChecklist("deleteRowTitle")}
        content={tGeneral("cantUndone")}
        showCancel={false}
        isLoading={isDeleteLoading}
      />
    </Box>
  );
}
export { ChecklistDisciplineTable };
