// eslint-disable-next-line import/prefer-default-export
export const projectSchedule = {
  "% Complete": "% 완료",
  "$ Project Cost": "프로젝트 비용",
  "Add New": "새로 추가",
  "All Colors": "모든 색상",
  "All Dates": "모든 날짜",
  "Are you sure you want to delete the activity": "활동을 삭제하시겠습니까?",
  "Are you sure you want to delete the group": "그룹을 삭제하시겠습니까?",
  "Are you sure you want to delete the milestone": "마일스톤을 삭제하시겠습니까?",
  "Are you sure?": "확실합니까?",
  "Basic Blue": "베이직 블루",
  "Beneficial Brown": "유익한 브라운",
  "Blissful Blue": "블리스풀 블루",
  "change(s)": "변화(s)",
  "Choose color": "색상 선택",
  "Click here": "여기를 클릭하세요",
  "Copy Link": "링크 복사",
  "Create Activity": "활동 생성",
  "Create Group": "그룹 생성",
  "Create Milestone": "마일스톤 생성",
  "Create Roadblock": "장애물 생성",
  "Daily Reports": "일일 보고서",
  "Date Format:": "날짜 형식:",
  "Day-Month-Year": "일-월-년",
  "Default Color": "기본 색상",
  "Delete Activity": "활동 삭제",
  "Delete Group": "그룹 삭제",
  "Delete Milestone": "마일스톤 삭제",
  "Dependent On": "의존적인",
  "Depends On": "에 따라 달라집니다",
  "Due Date": "마감일",
  "Due Today": "오늘 마감",
  "End Date": "종료일",
  "Expand All Groups": "모든 그룹 확장",
  "Export to Excel": "엑셀로 내보내기",
  "Gainful Green": "유익한 녹색",
  "Get It Done Grey": "겟잇던 그레이",
  "Golfer's Green": "골퍼스 그린",
  "Great Green": "그레이트 그린",
  "Hide": "숨다",
  "Hide Completed": "완료 숨기기",
  "Import from Excel": "엑셀에서 가져오기",
  "Import or export": "가져오기 또는 내보내기",
  "In Progress": "진행 중",
  "Invite people": "사람 초대",
  "made": "만들어진",
  "Magnifying Magenta": "마젠타 확대",
  "Month-Day-Year": "월-일-년",
  "No other activity depends on this activity.": "다른 활동이 이 활동에 의존하지 않습니다.",
  "No records to display": "표시할 기록이 없습니다.",
  "Operational Orange": "운영 오렌지",
  "Outstanding Orange": "뛰어난 오렌지",
  "PDF Settings": "PDF 설정",
  "Pretty Pink": "예쁜 핑크",
  "Productive Purple": "생산적인 보라색",
  "Project Cost": "프로젝트 비용",
  "Project Schedule": "프로젝트 일정",
  "Prolific Purple": "다작의 보라색",
  "Real Duration": "실제 기간",
  "Real Finish Date": "실제 완료 날짜",
  "Real Finish": "진짜 마무리",
  "Real Start Date": "실제 시작 날짜",
  "Real Start": "실제 시작",
  "Real Times": "실제 시간",
  "Recommended: All Dates": "권장: 모든 날짜",
  "Recommended: Due Today": "추천: 오늘 마감",
  "Recommended: In Progress": "권장 사항: 진행 중",
  "Recommended: Overdue": "추천: 기한이 지났다",
  "Rosy Red": "장미빛 레드",
  "Scheduled Duration": "예정된 기간",
  "Scheduled End Date": "예정 종료 날짜",
  "Scheduled End": "예정된 종료",
  "Scheduled Start Date": "예정 시작 날짜",
  "Scheduled Start": "예정된 시작",
  "Scheduled Times": "예정된 시간",
  "Select a type": "유형 선택",
  "Select Assignees": "담당자 선택",
  "Select Colors": "색상 선택",
  "Show Completed": "완료 표시",
  "Size": "크기",
  "Start Date": "시작일",
  "Starting: Within 1 Week": "시작: 1주일 이내",
  "Starting: Within 2 Weeks": "시작: 2주 이내",
  "Starting: Within 4 Weeks": "시작: 4주 이내",
  "Submitting...": "제출 중...",
  "to download the file and review the errors in the last column." : "파일을 다운로드하고 마지막 열의 오류를 검토하세요.",
  "to download the sample file. Your uploaded file must be based on this template and adhere to the outlined requirements.": "샘플 파일을 다운로드하려면 업로드한 파일은 이 템플릿을 기반으로 해야 하며 설명된 요구 사항을 준수해야 합니다.",
  "Task Due": "작업 기한",
  "Task Due: Within 1 Week": "작업 기한: 1주 이내",
  "Task Due: Within 2 Weeks": "작업 기한: 2주 이내",
  "Task Due: Within 4 Weeks": "작업 기한: 4주 이내",
  "There are no comments in this activity.": "이 활동에 댓글이 없습니다.",
  "There are no comments in this group.": "이 그룹에 댓글이 없습니다.",
  "There are no dependents/depedencies of this activity.": "이 활동의 의존성이 없습니다.",
  "There are no histories in this group.": "이 그룹에는 이력이 없습니다.",
  "There are no items in this module.": "이 모듈에 항목이 없습니다.",
  "There are no more daily reports available.": "더 이상 사용할 수 있는 일일 보고서가 없습니다.",
  "There are no more documents available.": "더 이상 사용할 수 있는 문서가 없습니다.",
  "There are no more drawings available.": "더 이상 사용할 수 있는 도면이 없습니다.",
  "There are no more inspections available.": "더 이상 사용할 수 있는 검사가 없습니다.",
  "There are no more meetings available.": "더 이상 사용할 수 있는 회의가 없습니다.",
  "There are no more RFIs available.": "더 이상 사용할 수 있는 RFI가 없습니다.",
  "There are no more tasks available.": "더 이상 사용할 수 있는 작업이 없습니다.",
  "There is history in this activity.": "이 활동에는 이력이 있습니다.",
  "There is history in this group.": "이 그룹에는 이력이 있습니다.",
  "This activity does noes depend on any other activity.": "이 활동은 다른 활동에 의존하지 않습니다.",
  "This feature is under construction.": "이 기능은 현재 구축 중입니다.",
  "Today": "오늘",
  "Update Roadblock": "로드블록 업데이트",
  "Within 1 Week": "1주일 이내",
  "Within 2 Weeks": "2주 이내",
  "Within 4 Weeks": "4주 이내",
  "Year-Month-Day": "연월일",
  "You do not have the appropriate permission to perform this action.": "이 작업을 수행할 수 있는 적절한 권한이 없습니다.",
  "Your file was not imported due to errors." : "오류로 인해 파일을 가져오지 못했습니다.",
  "You have marked this milestone as completed.": "이 마일스톤을 완료로 표시했습니다.",
  "You have marked this milestone as not completed.": "이 마일스톤을 미완료로 표시했습니다.",
  "You have not invited anyone yet.": "아직 아무도 초대하지 않았습니다.",
  "View": "보다",
  Activities: "활동",
  Activity: "활동",
  Assigned: "할당됨",
  Assignees: "할당된 사람들",
  Blueberry: "블루베리",
  Budget: "예산",
  Cancel: "취소",
  Chart: "차트",
  Close: "닫기",
  CLOSE: "닫기",
  Colors: "색상",
  Comments: "댓글",
  Cost: "비용",
  Create: "생성",
  Day: "일",
  Dependencies: "의존성",
  Description: "설명",
  Difference: "차이",
  Documents: "문서",
  Drawings: "도면",
  Duration: "기간",
  Email: "이메일",
  Error: "오류",
  Everyone: "모두",
  Expenses: "경비",
  File: "파일",
  Files: "파일",
  Grid: "그리드",
  History: "이력",
  Inspections: "검사",
  Invite: "초대",
  Meetings: "회의",
  Milestone: "마일스톤",
  Mustard: "겨자",
  Name: "이름",
  No: "아니오",
  OPEN: "열기",
  Overdue: "연체",
  Permissions: "권한",
  Please: "제발",
  Predecessor: "선행 작업",
  Progress: "진행",
  Recommended: "추천",
  RESCHEDULE: "재스케줄",
  RFIs: "RFI",
  Roadblocks: "장애물",
  Select: "선택",
  Send: "제출",
  Share: "공유",
  Starting: "시작",
  Status: "상태",
  Submit: "제출하다",
  Success: "성공",
  Successor: "후속 작업",
  Tasks: "작업",
  Title: "제목",
  to: "에",
  Total: "총계",
  Update: "업데이트",
  Week: "주",
  Yes: "예",
  "DOWNLOAD PDF": "PDF 다운로드",
  "POPULATE SAMPLE DATA": "샘플 데이터 채우기",
  "Additional Columns": "추가 열",
  ACTIVITY_CREATED: "활동이 생성되었습니다",
  ACTIVITY_UPDATED_ASSIGNEES_CREATED: '담당자 "{{ name }}" 이(가) 추가되었습니다',
  ACTIVITY_UPDATED_ASSIGNEES_REMOVED: '담당자 "{{ name }}" 이(가) 제거되었습니다',
  ACTIVITY_UPDATED_COLOR: '색상이 "{{ old }}" 에서 "{{ new }}" 로 변경되었습니다',
  ACTIVITY_UPDATED_DESCRIPTION: '설명이 "{{ old }}" 에서 "{{ new }}" 로 변경되었습니다',
  ACTIVITY_UPDATED_ID_ACTIVITY_UPDATED: '선행 작업이 "{{ old }}" 에서 "{{ new }}" 로 변경되었습니다',
  ACTIVITY_UPDATED_PROGRESS: '진행률이 "{{ old }}" 에서 "{{ new }}" 로 변경되었습니다',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_CREATED: '비용 항목 "{{ title }}" 이(가) 추가되었습니다',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_REMOVED: '비용 항목 "{{ title }}" 이(가) 제거되었습니다',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_AMOUNT:
    '비용 항목 "{{ number }}" 에서 금액이 "{{ old }}" 에서 "{{ new }}" 로 변경되었습니다',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_FILE:
    '비용 항목 "{{ number }}" 에서 파일이 "{{ old }}" 에서 "{{ new }}" 로 변경되었습니다',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_TITLE:
    '비용 항목 "{{ number }}" 에서 제목이 "{{ old }}" 에서 "{{ new }}" 로 변경되었습니다',
  ACTIVITY_UPDATED_PROJECT_COST_BUDGET: '예산이 "{{ old }}" 에서 "{{ new }}" 로 변경되었습니다',
  ACTIVITY_UPDATED_REAL_TIMES_END: '실제 종료 시간이 "{{ old }}" 에서 "{{ new }}" 로 변경되었습니다',
  ACTIVITY_UPDATED_REAL_TIMES_START: '실제 시작 시간이 "{{ old }}" 에서 "{{ new }}" 로 변경되었습니다',
  ACTIVITY_UPDATED_SCHEDULED_TIMES_END: '예정 종료 시간이 "{{ old }}" 에서 "{{ new }}" 로 변경되었습니다',
  ACTIVITY_UPDATED_SCHEDULED_TIMES_START: '예정 시작 시간이 "{{ old }}" 에서 "{{ new }}" 로 변경되었습니다',
  ACTIVITY_UPDATED_TITLE: '제목이 "{{ old }}" 에서 "{{ new }}" 로 변경되었습니다',
  DAILY_REPORT_CREATED: '일일 보고서 "{{ title }}" 이(가) 추가되었습니다',
  DAILY_REPORT_REMOVED: '일일 보고서 "{{ title }}" 이(가) 제거되었습니다',
  DOCUMENT_CREATED: '문서 "{{ name }}" 이(가) 추가되었습니다',
  DOCUMENT_REMOVED: '문서 "{{ name }}" 이(가) 제거되었습니다',
  DRAWING_CREATED: '도면 "{{ name }}" 이(가) 추가되었습니다',
  DRAWING_REMOVED: '도면 "{{ name }}" 이(가) 제거되었습니다',
  INSPECTION_CREATED: '검사 "{{ title }}" 이(가) 추가되었습니다',
  INSPECTION_REMOVED: '검사 "{{ title }}" 이(가) 제거되었습니다',
  MEETING_CREATED: '회의 "{{ title }}" 이(가) 추가되었습니다',
  MEETING_REMOVED: '회의 "{{ title }}" 이(가) 제거되었습니다',
  RFI_CREATED: 'RFI "{{ title }}" 이(가) 추가되었습니다',
  RFI_REMOVED: 'RFI "{{ title }}" 이(가) 제거되었습니다',
  ROADBLOCK_CREATED: '장애물 "{{ description }}" 이(가) 생성되었습니다',
  ROADBLOCK_UPDATED_ASSIGNEES_CREATED: '장애물 "{{ description }}" 에 담당자 "{{ name }}" 이(가) 추가되었습니다',
  ROADBLOCK_UPDATED_ASSIGNEES_REMOVED: '장애물 "{{ description }}" 에서 담당자 "{{ name }}" 이(가) 제거되었습니다',
  ROADBLOCK_UPDATED_DATE: '장애물 "{{ description }}" 에서 날짜가 "{{ old }}" 에서 "{{ new }}" 로 변경되었습니다',
  ROADBLOCK_UPDATED_DESCRIPTION:
    '장애물 "{{ description }}" 에서 설명이 "{{ old }}" 에서 "{{ new }}" 로 변경되었습니다',
  ROADBLOCK_UPDATED_STATUS: '장애물 "{{ description }}" 에서 상태가 "{{ old }}" 에서 "{{ new }}" 로 변경되었습니다',
  ROADBLOCK_UPDATED_TYPE: '장애물 "{{ description }}" 에서 유형이 "{{ old }}" 에서 "{{ new }}" 로 변경되었습니다',
  TASK_CREATED: '작업 "{{ title }}" 이(가) 추가되었습니다',
  TASK_REMOVED: '작업 "{{ title }}" 이(가) 제거되었습니다',
};
