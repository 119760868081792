/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useMemo, useState } from "react";
// React router
import { useParams } from "react-router-dom";
// MUI Components
import { Box } from "@mui/material";
// Translations
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useGetProjectDirectoryUsersQuery } from "../../../features/project/modules/directory/projectDirectoryApiSlice";
import { TitleWidgets } from "../../../components/shared";
import { ProjectDirectoryTable } from "../../../components/projectDashboard/projectDirectory/ProjectDirectoryTable";
import { LatestActivityDrawer } from "../../../components/projectDashboard/projectDirectory/LatestActivityDrawer";
import { ModalDirectoryAddUser } from "./ModalDirectoryAddUser";
import { EditProjectUserModal } from "../../../components/projectDashboard/projectDirectory/EditProjectUserModal";
import { useAppTourContext } from "../../../components/supportButton/context/context";
import { selectCurrentGuideMe } from "../../../features/project/projectSlice";

function ProjectDirectory() {
  const { idProject } = useParams();
  // Translations
  const { t: tProjectDirectory } = useTranslation("projectDirectory");

  const [filters, setFilters] = useState({ search: "" });
  const [isAddOpen, setIsAddOpen] = useState(false);

  const {
    data: projectUsers,
    isLoading,
    isSuccess,
  } = useGetProjectDirectoryUsersQuery(idProject);

  const [openEdit, setOpenEdit] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [openActivity, setOpenActivity] = useState(false);
  const [viewingActivityUser, setViewingActivityUser] = useState(null);
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const filteredUsers = useMemo(() => {
    if (projectUsers) {
      return projectUsers.filter(
        (user) =>
          `${user.firstName} ${user.lastName}`
            .toLowerCase()
            .match(new RegExp(filters.search.toLowerCase(), "g")) ||
          filters.search === "",
      );
    }
    return [];
  }, [projectUsers, filters]);

  const {
    state: { tourActive, stepIndex, steps },
    setState,
  } = useAppTourContext();

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 0 &&
      currentGuideMe === "directory-users-add"
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }

    if (
      tourActive &&
      (stepIndex === 1 || stepIndex === steps.length - 2) &&
      currentGuideMe === "directory-users-add"
    ) {
      setIsAddOpen(true);
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }

    if (
      tourActive &&
      (stepIndex === steps.length - 1 || stepIndex === 0) &&
      currentGuideMe === "directory-users-add"
    ) {
      setIsAddOpen(false);
    }
  }, [tourActive, stepIndex, currentGuideMe, steps]);

  useEffect(() => {
    if (
      tourActive &&
      (stepIndex === 1 || stepIndex === steps.length - 2) &&
      currentGuideMe === "directory-users-edit"
    ) {
      if (projectUsers && isSuccess && projectUsers.length > 0) {
        setEditingUser(projectUsers[0]);
        setOpenEdit(true);
        setTimeout(() => {
          setState({ run: true });
        }, 500);
      }
    }

    if (
      tourActive &&
      (stepIndex === steps.length - 1 || stepIndex === 0) &&
      currentGuideMe === "directory-users-edit"
    ) {
      setOpenEdit(false);
      setEditingUser(null);
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe, projectUsers, steps]);

  useEffect(() => {
    if (
      tourActive &&
      (stepIndex === 1 || stepIndex === steps.length - 2) &&
      currentGuideMe === "directory-users-view"
    ) {
      if (projectUsers && isSuccess && projectUsers.length > 0) {
        setViewingActivityUser(projectUsers[0]);
        setOpenActivity(true);
        setTimeout(() => {
          setState({ run: true });
        }, 500);
      }
    }

    if (
      tourActive &&
      (steps.length - 1 === stepIndex || stepIndex === 0) &&
      currentGuideMe === "directory-users-view"
    ) {
      setViewingActivityUser(null);
      setOpenActivity(false);
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe, projectUsers, steps]);

  return (
    <Box sx={{ height: 100, width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <TitleWidgets
          title={tProjectDirectory("directory")}
          backEnabled={false}
          moduleName="directory"
          addFunction={() => setIsAddOpen(true)}
          enableSupport
          showMoreOptions={false}
          addNameTour="add-user-1"
        />
      </Box>

      <ProjectDirectoryTable
        isLoading={isLoading}
        data={filteredUsers}
        onInfoClick={(user) => {
          setViewingActivityUser(user);
          setOpenActivity(true);
        }}
        onEditClick={(user) => {
          setEditingUser(user);
          setOpenEdit(true);
        }}
        setFilters={(val) => setFilters(val)}
      />

      {openEdit && (
        <EditProjectUserModal
          open={openEdit}
          setOpen={setOpenEdit}
          user={editingUser}
        />
      )}

      <div>
        <LatestActivityDrawer
          open={openActivity}
          setOpen={setOpenActivity}
          user={viewingActivityUser}
        />
      </div>

      <ModalDirectoryAddUser
        isOpen={isAddOpen}
        onClose={() => setIsAddOpen(false)}
      />
    </Box>
  );
}

export { ProjectDirectory };
