import {
  Box,
  Button,
  Divider,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectTheme } from "../../features/preferences/preferencesSlice";
import { useReportAnIssueMutation } from "../../features/userSettings/userSettingsApiSlice";

// Components
import { PopUpAlert } from "../../components/PopUpAlert";
import UploadFiles from "../../components/UploadFiles";

const modules = [
  {
    value: "drawings",
    label: "Drawings",
  },
  // {
  //   value: "punchList",
  //   label: "Punch List",
  // },
  {
    value: "directory",
    label: "Directory",
  },
  // {
  //   value: "submittals",
  //   label: "Submittals",
  // },
  {
    value: "rfis",
    label: "RFIs",
  },
  // {
  //   value: "deliveries",
  //   label: "Deliveries",
  // },
  // {
  //   value: "hours",
  //   label: "Hours",
  // },
  {
    value: "meetings",
    label: "Meetings",
  },
  {
    value: "photos",
    label: "Photos",
  },
  {
    value: "tasks",
    label: "Tasks",
  },
  // {
  //   value: "inspections",
  //   label: "Inspections",
  // },
  // {
  //   value: "issues",
  //   label: "Issues",
  // },
  {
    value: "dailyReports",
    label: "Daily Reports",
  },
  {
    value: "documents",
    label: "Documents",
  },
  // {
  //   value: "delay",
  //   label: "Delay",
  // },
  {
    value: "announcements",
    label: "Announcements",
  },
  {
    value: "adminPanel",
    label: "Admin Panel",
  },
  {
    value: "notifications",
    label: "Notifications",
  },
];

const fileTypes = {
  "image/*": [".jpeg", ".png", ".jpg"],
};

const styles = {
  cancelBtn: {
    width: "18%",
    m: 1,
    borderRadius: 1,
    color: "black",
    backgroundColor: "white",
    borderColor: "black",
    border: 1,
    ":hover": {
      color: "white",
      backgroundColor: "#b4b4b4",
    },
  },
  saveBtn: {
    width: "18%",
    borderRadius: 1,
    m: 1,
  },
};

const Tickets = () => {
  const { t: tGeneral } = useTranslation("general");
  const [reportAnIssue] = useReportAnIssueMutation();
  const darkMode = useSelector(selectTheme);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:800px)");

  const [version, setVersion] = useState("");
  const [versionError, setVersionError] = useState(false);
  const [versionHelperText, setVersionHelperText] = useState(false);
  const [browser, setBrowser] = useState("");
  const [browserError, setBrowserError] = useState(false);
  const [browserHelperText, setBrowserHelperText] = useState(false);
  const [device, setDevice] = useState("");
  const [deviceError, setDeviceError] = useState(false);
  const [deviceHelperText, setDeviceHelperText] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [titleHelperText, setTitleHelperText] = useState(false);
  const [module, setModule] = useState("");
  const [moduleError, setModuleError] = useState(false);
  const [moduleHelperText, setModuleHelperText] = useState(false);
  const [details, setDetails] = useState("");
  const [detailsError, setDetailsError] = useState(false);
  const [detailsHelperText, setDetailsHelperText] = useState(false);

  const sendReport = async (e) => {
    e.preventDefault();

    let canContinueVersion = validateFields(version, "version");
    let canContinueTitle = validateFields(title, "title");
    let canContinueModule = validateFields(module, "module");
    let canContinueDetails = validateFields(details, "details");

    if (
      canContinueVersion &&
      canContinueTitle &&
      canContinueModule &&
      canContinueDetails
    ) {
      let body = {
        version: version,
        title: title,
        module: module,
        details: details,
      };
      canContinueVersion = false;
      if (version === "Web" && validateFields(browser, "browser")) {
        canContinueVersion = true;
        body.browser = browser;
      } else if (version === "Mobile" && validateFields(device, "device")) {
        canContinueVersion = true;
        body.device = device;
      }

      if (canContinueVersion) {
        try {
          const response = await reportAnIssue(body).unwrap();

          if (response) {
            PopUpAlert(
              "success",
              "Reported issue",
              "The report has been sent succesfully",
            );
          }
        } catch (error) {
          PopUpAlert("error", "Can not report", "Invalid data");
        }
      }
    }
  };

  const validateFields = (value, fieldName) => {
    let validatedField = value.length > 0 ? true : false;
    switch (fieldName) {
      case "version":
        validatedField ? setVersionError(false) : setVersionError(true);
        validatedField
          ? setVersionHelperText("")
          : setVersionHelperText("Required field");
        break;
      case "browser":
        validatedField ? setBrowserError(false) : setBrowserError(true);
        validatedField
          ? setBrowserHelperText("")
          : setBrowserHelperText("Required field");
        break;
      case "device":
        validatedField ? setDeviceError(false) : setDeviceError(true);
        validatedField
          ? setDeviceHelperText("")
          : setDeviceHelperText("Required field");
        break;
      case "title":
        validatedField ? setTitleError(false) : setTitleError(true);
        validatedField
          ? setTitleHelperText("")
          : setTitleHelperText("Required field");
        break;
      case "module":
        validatedField ? setModuleError(false) : setModuleError(true);
        validatedField
          ? setModuleHelperText("")
          : setModuleHelperText("Required field");
        break;
      case "details":
        validatedField ? setDetailsError(false) : setDetailsError(true);
        validatedField
          ? setDetailsHelperText("")
          : setDetailsHelperText("Required field");
        break;
    }
    return validatedField;
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Typography fontSize="36px" align="left" fontWeight="bold" mb={2}>
        {tGeneral("reportAnIssue")}
      </Typography>
      <Typography
        fontSize="16px"
        align="left"
        mb={2}
        sx={{ color: darkMode ? "" : "#5C5C5C" }}
      >
        {tGeneral("welcomeTickets")}
      </Typography>
      <Box display="flex" width="100%" flexWrap="wrap" mt={3}>
        <Box
          display="flex"
          flexWrap="wrap"
          flex={1}
          gap="40px"
          justifyContent="center"
        >
          {/* Version */}
          <TextField
            margin="dense"
            variant="outlined"
            type="text"
            label="Version"
            id="version"
            select
            required
            value={version}
            error={versionError}
            helperText={versionHelperText}
            onChange={(e) => {
              setVersion(e.target.value);
              validateFields(e.target.value, "version");
            }}
            sx={{ width: isMobile ? "100%" : "22.5%" }}
          >
            <MenuItem key="Web" value="Web">
              Web
            </MenuItem>
            <MenuItem key="Mobile" value="Mobile">
              {tGeneral("mobile")}
            </MenuItem>
          </TextField>
          {/* Browser - Web Version*/}
          {version === "Web" && (
            <TextField
              margin="dense"
              variant="outlined"
              type="text"
              label={tGeneral("browser")}
              id="browser"
              select
              required
              value={browser}
              error={browserError}
              helperText={browserHelperText}
              onChange={(e) => {
                setBrowser(e.target.value);
                validateFields(e.target.value, "browser");
              }}
              sx={{ width: isMobile ? "100%" : "22.5%" }}
            >
              <MenuItem value="Safari">Safari</MenuItem>
              <MenuItem value="Chrome">Chrome</MenuItem>
              <MenuItem value="Mozilla">Mozilla</MenuItem>
            </TextField>
          )}
          {/* SO - Mobile Version*/}
          {version === "Mobile" && (
            <TextField
              margin="dense"
              variant="outlined"
              type="text"
              label={tGeneral("device")}
              id="device"
              select
              required
              value={device}
              error={deviceError}
              helperText={deviceHelperText}
              onChange={(e) => {
                setDevice(e.target.value);
                validateFields(e.target.value, "device");
              }}
              sx={{ width: isMobile ? "100%" : "22.5%" }}
            >
              <MenuItem value="iOS">iOS</MenuItem>
              <MenuItem value="Android">Android</MenuItem>
            </TextField>
          )}
          {/* Title */}
          <TextField
            margin="dense"
            variant="outlined"
            type="text"
            label={tGeneral("title")}
            id="title"
            required
            value={title}
            error={titleError}
            helperText={titleHelperText}
            onChange={(e) => {
              setTitle(e.target.value);
              validateFields(e.target.value, "title");
            }}
            sx={{ width: isMobile ? "100%" : "22.5%" }}
          />
          {/* Module */}
          <TextField
            margin="dense"
            variant="outlined"
            type="text"
            label={tGeneral("module")}
            id="module"
            select
            required
            value={module}
            error={moduleError}
            helperText={moduleHelperText}
            onChange={(e) => {
              setModule(e.target.value);
              validateFields(e.target.value, "module");
            }}
            sx={{ width: isMobile ? "100%" : "22.5%" }}
          >
            {modules.map((module) => (
              <MenuItem key={module.value} value={module.label}>
                {tGeneral(module.value)}
              </MenuItem>
            ))}
          </TextField>
          {/* Details */}
          <TextField
            margin="dense"
            variant="outlined"
            type="text"
            label={tGeneral("details")}
            id="details"
            required
            placeholder={tGeneral("writeDetail")}
            multiline
            rows={5}
            value={details}
            error={detailsError}
            helperText={detailsHelperText}
            onChange={(e) => {
              setDetails(e.target.value);
              validateFields(e.target.value, "details");
            }}
            sx={{ width: "100%" }}
          />
          {/* Files */}
          <UploadFiles
            acceptedTypes={fileTypes}
            multipleFiles={true}
            // filesParams={currentRfi?.files !== null ? getFile() : null} // to edit the enterprise settings and if it contains a logo this parameter is sent (ej.: filesParams: {[{name: "yes"}]})
            setFiles={(value) => {
              // handleInputFiles(value); // file is received and assigned to a variable
            }}
            isEditable={true}
          />
        </Box>
      </Box>
      <Divider sx={{ my: 3 }} />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          type="submit"
          variant="contained"
          onClick={(e) => {
            navigate("/user/profile");
          }}
          sx={styles.cancelBtn}
        >
          {tGeneral("cancel")}
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={(e) => {
            sendReport(e);
          }}
          sx={styles.saveBtn}
        >
          {tGeneral("send")}
        </Button>
      </Box>
    </Box>
  );
};

export default Tickets;
