import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useCreateEnterpriseMutation } from "../../../features/enterprise/enterpriseApiSlice";
import { useCreatePresignedUrlMutation } from "../../../features/s3/s3ApiSlice";
import {
  useFinishOnboardingMutation,
  useGetUserQuery,
} from "../../../features/userSettings/userSettingsApiSlice";
import { AddCardModal } from "./AddCardModal";
import { InvoiceDetailsModal } from "./InvoiceDetailsModal";

function Payment({ onBackClick, enterprise, plan }) {
  const navigate = useNavigate();
  const { t: tPayment } = useTranslation("payment");
  const { t: tGeneral } = useTranslation("general");
  const [isInvoiceOpen, setIsInvoiceOpen] = useState(false);
  const [isCardOpen, setIsCardOpen] = useState(false);
  const [acceptedTems, setAcceptedTerms] = useState(false);
  const [cardInfo, setCardInfo] = useState(null);
  const [invoiceInfo, setInvoiceInfo] = useState(null);

  const unitPrice = plan.price;
  const planType = plan?.metadata?.type;
  const period = plan.metadata.period;
  const { data: userData, isLoading: isUserLoading } = useGetUserQuery();

  const [createPresignedUrl] = useCreatePresignedUrlMutation();
  const [createEnterprise, { isLoading, isSuccess, data }] =
    useCreateEnterpriseMutation();
  const [
    finishOnboarding,
    { isLoading: isOnboardingLoading, isSuccess: isOnboardingSuccess },
  ] = useFinishOnboardingMutation();

  useEffect(() => {
    if (isOnboardingSuccess && userData?.onboardingDone) {
      navigate(`/enterprise/${data.id}/dashboard`);
    }
  }, [isOnboardingSuccess, navigate, data, isUserLoading, userData]);

  useEffect(() => {
    if (isSuccess) {
      finishOnboarding();
    }
  }, [isSuccess, finishOnboarding]);

  const handleSbscribe = async () => {
    const planType = plan.metadata.type;
    const body = {
      ...enterprise,
      planConcurrency: plan.metadata.period,
      planType,
      productId: plan.id,
      priceId: plan.default_price,
      cardInfo,
      invoiceInfo,
    };
    if (planType === "project") {
      body.projectsLimit = plan.activeObjects.toString();
    } else {
      body.usersLimit = plan.activeObjects.toString();
    }
    if (body.logo) {
      const { data: url } = await createPresignedUrl();
      await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: body.logo,
      });
      body.logo = url.split("?")[0];
    } else {
      body.logo = "";
    }
    createEnterprise(body);
  };

  return (
    <Box paddingX="30%">
      <Typography
        fontSize="1rem"
        fontWeight="600"
        sx={{
          color: "#646464",
        }}
      >
        Plan
      </Typography>
      <Typography fontSize="1rem" fontWeight="bold" sx={{ color: "#646464" }}>
        {tPayment("paymentMethod")}
      </Typography>

      <Button
        variant="text"
        sx={{
          textTransform: "none",
          p: 0,
          fontSize: "0.75rem",
          color: "#276CF0",
          mt: "10px",
          mb: "30px",
          textDecoration: "underline",
        }}
        onClick={() => setIsCardOpen(true)}
      >
        {cardInfo ? tPayment("editCard") : `+ ${tPayment("addNewCard")}`}
      </Button>

      <Typography fontSize="1rem" fontWeight="bold">
        {tPayment("invoiceDetails")}
      </Typography>

      <Button
        variant="text"
        sx={{
          textTransform: "none",
          p: 0,
          fontSize: "0.75rem",
          color: "#276CF0",
          mt: "10px",
          mb: "30px",
          textDecoration: "underline",
        }}
        onClick={() => setIsInvoiceOpen(true)}
      >
        {invoiceInfo ? tPayment("editData") : `+ ${tPayment("addData")}`}
      </Button>

      <Typography
        fontSize="1.2rem"
        fontWeight="bold"
        sx={{
          color: "#646464",
        }}
        mb="10px"
      >
        {tPayment("orderSummary")}
      </Typography>

      <Box display="flex" flexDirection="column" rowGap="16px">
        <Box display="flex" justifyContent="space-between">
          <Typography fontSize="0.75rem" sx={{ color: "#696969" }}>
            Plan
          </Typography>
          <Typography fontSize="0.75rem" sx={{ color: "#696969" }}>
            {tGeneral(planType)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography fontSize="0.75rem" sx={{ color: "#696969" }}>
            {tPayment(planType === "user" ? "pricePerUser" : "pricePerProject")}
          </Typography>
          <Typography fontSize="0.75rem" sx={{ color: "#696969" }}>
            ${(unitPrice * plan.activeObjects) / (period === "annual" ? 12 : 1)}{" "}
            USD {period === "annual" ? `/ ${tGeneral("month")}` : ""}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography fontSize="0.75rem" sx={{ color: "#696969" }}>
            {tPayment(planType === "user" ? "usersNumber" : "projectsNumber")}
          </Typography>
          <Typography fontSize="0.75rem" sx={{ color: "#696969" }}>
            {plan.activeObjects}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ my: "20px" }} />
      <Box display="flex" justifyContent="space-between">
        <Typography
          fontSize="0.75rem"
          fontWeight="600"
          sx={{ color: "#696969" }}
        >
          {tPayment(period === "annual" ? "totalPerYear" : "totalPerMonth")}
        </Typography>
        <Typography
          fontSize="0.75rem"
          fontWeight="600"
          sx={{ color: "#696969" }}
        >
          ${unitPrice * plan.activeObjects} USD
        </Typography>
      </Box>

      <Box display="flex" columnGap="14px" alignItems="flex-start" my="35px">
        <Checkbox
          size="small"
          sx={{ padding: 0 }}
          value={acceptedTems}
          onChange={(e) => setAcceptedTerms(e.target.checked)}
        />
        <Typography
          fontSize="0.75rem"
          sx={{
            color: "#696969",
          }}
        >
          {tPayment("agreement")}
        </Typography>
      </Box>

      <Box display="flex" justifyContent="flex-end" columnGap="16px">
        <Button
          variant="outlined"
          color="secondary"
          sx={{
            py: "16px",
            borderRadius: "5px",
          }}
          fullWidth
          onClick={onBackClick}
          disabled={isOnboardingLoading || isLoading}
        >
          <Typography fontWeight="medium" fontSize="1rem">
            {tGeneral("cancel")}
          </Typography>
        </Button>

        {/* Save Button */}
        <Button
          variant="contained"
          sx={{ py: "16px", borderRadius: "5px" }}
          onClick={handleSbscribe}
          fullWidth
          disabled={
            isOnboardingLoading ||
            isLoading ||
            !acceptedTems ||
            !cardInfo ||
            !invoiceInfo
          }
        >
          {isOnboardingLoading || isLoading ? (
            <CircularProgress color="primary" />
          ) : (
            <Typography fontWeight="medium" fontSize="1rem">
              {tGeneral("subscribe")}
            </Typography>
          )}
        </Button>
      </Box>
      <InvoiceDetailsModal
        isOpen={isInvoiceOpen}
        onClose={() => setIsInvoiceOpen(false)}
        onAction={(data) => setInvoiceInfo(data)}
        invoiceInfo={invoiceInfo}
      />
      <AddCardModal
        isOpen={isCardOpen}
        onClose={() => setIsCardOpen(false)}
        onAction={(data) => setCardInfo(data)}
        cardInfo={cardInfo}
      />
    </Box>
  );
}

export { Payment };
