// @ts-check
/* eslint react/prop-types: 0 */

import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
// eslint-disable-next-line no-unused-vars
import React from "react";
import { useTranslation } from "react-i18next";

import TransitionComponent from "../TransitionComponent";

export default function Tasks(props) {
  const { open, options, setOpen, tasksList, setTasksList } = props;

  const { t: tProjectSchedule } = useTranslation("projectSchedule");
  const { t: tstatus } = useTranslation("status");

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={handleClose}
      open={open}
      PaperProps={{
        className: "project-schedule",
        sx: {
          borderRadius: "20px",
        },
      }}
      scroll="body"
      // @ts-ignore
      TransitionComponent={TransitionComponent}
    >
      <DialogTitle component="div">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">{tProjectSchedule("Tasks")}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          padding: "30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            gap: "20px",
          }}
        >
          {options?.filter((option) => !tasksList.includes(option.id)).length === 0 && (
            <Alert severity="info">{tProjectSchedule("There are no more tasks available.")}</Alert>
          )}
          {options
            ?.filter((option) => !tasksList.includes(option.id))
            .map((option) => {
              return (
                <Card
                  key={option.id}
                  sx={{
                    alignItems: "center",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "20px",
                    gap: "10px",
                  }}
                  variant="outlined"
                >
                  {option.title}
                  <Box
                    sx={{
                      borderRadius: "10px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "20px",
                    }}
                  >
                    <Chip color="info" label={`#${option.taskNumber}`} />
                    <Chip
                      color="success"
                      label={tstatus(`${option.status.replace("_", " ")}`)}
                    />
                    <Button
                      color="primary"
                      onClick={() => {
                        setTasksList((tasksList) => [...tasksList, option.id]);
                      }}
                      size="small"
                      variant="contained"
                    >
                      {tProjectSchedule("Select")}
                    </Button>
                  </Box>
                </Card>
              );
            })}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
