import React, { useState, useEffect } from "react";
// Redux reducers & mutations
// Hooks
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// MUI Components
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
// Custom components
import Swal from "sweetalert2";
import { selectTheme } from "../features/preferences/preferencesSlice";
import {
  useSendEmailVerifyMutation,
  useVerifyAccountMutation,
} from "../features/auth/authApiSlice";

function VerifyAccount() {
  // We check if theme == dark to render proper logo dynamically
  const darkMode = useSelector(selectTheme);
  // Navigate and location hooks
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/login";
  const path = useLocation().pathname.toString();
  const token = path.substring(path.lastIndexOf("/") + 1);

  // Redux dispatch action
  const [verifyAccount] = useVerifyAccountMutation();

  useEffect(() => {
    const verifyAccountSuccess = verifyAccount({ token }).unwrap();
  });

  // Handle submit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      navigate(from, { replace: true });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {/* Buildpeer logo */}
      <Grid item justifyContent="center" alignItems="center" xs={12}>
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            mt: 15,
            mb: 10,
          }}
        >
          <Box
            component="img"
            sx={{
              width: "40%",
              height: "40%",
            }}
            alt="Buildpeer Logo"
            src={
              darkMode
                ? require("../assets/logo-dark.png")
                : require("../assets/logo.png")
            }
          />
        </Box>
      </Grid>
      {/* Instructions Text */}
      <Grid item xs={8} justifyContent="center" alignItems="center">
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            mt: 5,
          }}
        >
          <Typography variant="h5" align="center" fontWeight="bold">
            Your account is now verified!
          </Typography>
        </Box>
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            mt: 5,
          }}
        >
          <Typography variant="subtitle 1" align="center">
            You can now access your account
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={4}
        sx={{ mt: 5 }}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            mt: 5,
          }}
        >
          {/* Button resend email */}
          <Button
            onClick={(e) => {
              handleSubmit(e);
            }}
            variant="contained"
            sx={{
              width: "50%",
              borderRadius: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Go to login
          </Button>
        </Box>
      </Grid>
    </>
  );
}

export default VerifyAccount;
