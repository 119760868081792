import { Close, Download, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectTheme } from "../../../features/preferences/preferencesSlice";
import ViewFiles from "../../ViewFiles";
import TopicItemDetail from "./TopicItemDetail";
import { ExpandedImage } from "../../shared";
import { useAppTourContext } from "../../supportButton/context/context";
import { selectCurrentGuideMe } from "../../../features/project/projectSlice";

function AgendaItemDetail({ index, item, canBeComment, canModifyTask }) {
  const darkMode = useSelector(selectTheme);
  const [open, setOpen] = useState(false);
  const [viewFiles, setViewFiles] = useState(false);
  // const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [expandImage, setExpandImage] = useState(null);

  const currentGuideMe = useSelector(selectCurrentGuideMe);
  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();

  const handleClose = () => {
    setOpenImage(false);
    setExpandImage(null);
  };

  const { t: tMeetings } = useTranslation("meetings");
  const { t: tGeneral } = useTranslation("general");

  useEffect(() => {
    if (tourActive && currentGuideMe === "meetings-view") {
      if (stepIndex === 5) {
        setOpen(true);
        setTimeout(() => {
          setState({ run: true });
        }, 500);
      }
    }
  }, [tourActive, stepIndex, currentGuideMe]);

  return (
    <>
      <Accordion expanded={open}>
        <AccordionSummary
          expandIcon={
            <IconButton
              onClick={() => setOpen(!open)}
              data-tour="view-meetings-5"
            >
              <ExpandMore sx={{ color: darkMode ? "white" : "black" }} />
            </IconButton>
          }
          sx={{ backgroundColor: darkMode ? "" : open ? "#F0F0F0" : "#F8F8F8" }}
        >
          <Box
            display="flex"
            flexDirection="column"
            width="93%"
            rowGap={1}
            flexWrap="wrap"
            overflow
          >
            <Typography fontWeight="bold" flexWrap="wrap">
              {index}. {item?.title}
            </Typography>
            {item?.files?.length > 0 && (
              <Button
                sx={{ backgroundColor: "white", width: "110px" }}
                variant="outlined"
                onClick={() => setViewFiles(true)}
              >
                {tMeetings("viewFiles")}
              </Button>
            )}
          </Box>
          <Typography
            sx={{
              backgroundColor: "#FFEBBC",
              borderRadius: 5,
              width: "80px",
              height: "24px",
              textAlign: "center",
              color: "black",
            }}
          >
            {item?.time} Min
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: darkMode ? "" : "#F0F0F0" }}>
          {item?.topics.map((topic) => (
            <TopicItemDetail
              key={topic.id}
              topic={topic}
              canBeComment={canBeComment}
              canModifyTask={canModifyTask}
            />
          ))}
          {item?.topics?.length === 0 && (
            <Typography>{tMeetings("noTopics")}</Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Modal
        open={viewFiles}
        onClose={() => setViewFiles(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <div>
          <ViewFiles
            files={item?.files}
            setImageOpen={(value) => {
              setOpenImage(true);
              setExpandImage(value);
            }}
          />
        </div>
      </Modal>
      {expandImage && (
        <ExpandedImage
          expandImage={expandImage}
          openImage={openImage}
          handleClose={handleClose}
        />
      )}
    </>
  );
}

export default AgendaItemDetail;
