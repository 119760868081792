export const login = {
  errorLoginTitle: "로그인 실패",
  errorLoginAuthMessage: "이메일과/또는 비밀번호가 일치하지 않습니다",
  errorLoginMessage: "문제가 발생했습니다. 나중에 다시 시도해주세요",
  errorNoUser: "사용자 이름이 존재하지 않습니다",
  welcomeBack: "다시 오신 것을 환영합니다!",
  password: "비밀번호",
  logIn: "로그인",
  forgotPassword: "비밀번호를 잊으셨나요?",
  errorRecoverTitle: "비밀번호 복구 실패",
  followInstructions: "지시 사항을 따르세요",
  recoverPasswordTitle:
    "Buildpeer 계정의 이메일 주소를 입력하여 비밀번호 재설정 지침을 받으세요",
  exampleEmail: "example@mail.com",
  recoverPassword: "비밀번호 복구",
  emailInvalid: "유효하지 않은 이메일",
};
