export const projectNetwork = {
  addProjectNetwork: "プロジェクトネットワークに追加",
  acceptInvite: "招待を受け入れる",
  yourNewCodeIs: "新しいコードは",
  generateNewCode: "新しいコードを生成する",
  deleteNetworkMessage:
    "このプロジェクトをネットワークから削除してもよろしいですか？共有されたアイテムはもうアクセスできません。",
  emptyNetworkTitle: "空のネットワーク",
  emptyNetworkContent: "まだネットワークにプロジェクトを追加していません。",
  accepted: "承認済み",
  acceptedSuccessfully: "招待を正常に受け入れました。",
  codeCopied: "コードがコピーされました",
  availableCodes: "利用可能なコード",
};
