import { Close, CloudDone } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

function ModalReviseDrawings({ isOpen, onClose }) {
  const { t: tDrawings } = useTranslation("drawings");
  const { t: tGeneral } = useTranslation("general");
  const navigate = useNavigate();
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          position: "relative",
          width: "40%",
          p: "60px 40px",
          borderRadius: "12px",
        }}
      >
        <IconButton
          sx={{ position: "absolute", right: 10, top: 10 }}
          onClick={onClose}
        >
          <Close />
        </IconButton>
        <Typography fontSize="1.563rem" fontWeight="bold" textAlign="center">
          {tDrawings("drawingsAddedTitle").toUpperCase()}
        </Typography>
        <Typography fontSize="1.125rem" textAlign="center">
          {tDrawings("drawingsAddedContent")}
        </Typography>
        <Box display="flex" columnGap="16px">
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            {tGeneral("cancel")}
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{ alignItems: "center", columnGap: "5px" }}
            onClick={() => navigate("./review")}
          >
            <CloudDone /> {tDrawings("reviseDrawings")}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}

export { ModalReviseDrawings };
