import { datadogRum } from "@datadog/browser-rum";
import React, { useEffect, useContext, useMemo, useState } from "react";
import { useParams } from "react-router";

import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";
import { useSelector } from "react-redux";

import "jspdf-autotable";

// Alert
import Swal from "sweetalert2";
import {
  useDeleteRfiMutation,
  useGetRfisQuery,
} from "../../features/project/modules/rfiApiSlice";
import {
  selectCurrentGuideMe,
  selectCurrentProject,
} from "../../features/project/projectSlice";

import Can from "../../utils/can";

import { DISTRIBUTION_LIST_NAMES } from "../../constants/projectDashboard";

import { RFITable } from "../../components/projectDashboard/Rfis";
import { ModalFileViewer } from "../../components/shared/ModalFileViewer";
import { TitleWidgets } from "../../components/shared";
import RfiDeleteModal from "../../components/projectDashboard/Rfis/RfiDeleteModal";
import CaslContext from "../../utils/caslContext";
import { useAppTourContext } from "../../components/supportButton/context/context";
import { customFormatDate } from "../../utils/dateFormats";
import { useToast } from "../../contexts/ToastContext";

function RFIs() {
  const { idProject, idEnterprise } = useParams();
  const currentProject = useSelector(selectCurrentProject);
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const {
    state: { tourActive, stepIndex, run },
    setState,
  } = useAppTourContext();

  const ability = useContext(CaslContext);
  const toast = useToast();

  // Translations
  const { t: tRfis } = useTranslation("rfis");
  const { t: tGeneral } = useTranslation("general");

  const [params, setParams] = useState({});
  const [selectedRfis, setSelectedRfis] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [removeRfi] = useDeleteRfiMutation();
  const {
    data,
    isLoading: isRfisLoading,
    isFetching: isRfisFetching,
  } = useGetRfisQuery(
    {
      idProject,
      params: {
        ...params,
        ...(params?.createdOn !== null &&
          params?.createdOn?.length > 0 && {
            startDate: params?.createdOn[0],
            endDate: params?.createdOn[1],
          }),
        createdOn: null,
      },
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const rfis = useMemo(() => data ?? [], [data]);

  const [pdfUrl, setPdfUrl] = React.useState(null);

  function showAlert(message, type) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: type,
      title: message,
    });
  }

  // Delete rfi
  const deleteRfi = async () => {
    try {
      const body = {
        ids: Object.keys(selectedRfis),
      };
      const response = await removeRfi(body).unwrap();
      if (response) {
        setOpenDelete(false);
        setSelectedRfis({});
        showAlert(tGeneral("deletedSuccessfully"), "success");
      }
    } catch (err) {
      showAlert(tGeneral("cantDelete"), "error");
      datadogRum.addError({
        message: "RFI's - Delete - Error",
        stack: err.stack ? err.stack : "invalid stack",
        custom: {
          app: "buildpeer-web",
          enterprise: idEnterprise,
          module: "RFI's",
          project: idProject,
        },
        timestamp: Date.now(),
      });
    }
  };

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 0 &&
      (currentGuideMe === "rfis-add" ||
        currentGuideMe === "rfi-edit" ||
        currentGuideMe === "rfi-view") &&
      !run
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, run, currentGuideMe, setState]);

  const buildExcelData = () => {
    try {
      const selectedRfisKeys = Object.keys(selectedRfis);
      if (!selectedRfisKeys.length) {
        throw new Error("");
      }
      const selectedRfisData = rfis.filter((rfi) =>
        selectedRfisKeys.includes(rfi.id),
      );
      const rfisData = selectedRfisData.map((rfi) => {
        const assignees = rfi.assignedTo
          .map((assignee) => assignee.name)
          .join(", ");
        const distributionList = rfi.distributionListData
          .map((user) => user.name)
          .join(", ");

        const drawing = rfi.drawing.map((drawing) => drawing.name).join(", ");
        return [
          rfi.number,
          rfi.title,
          rfi.status,
          rfi.createdByData?.name ?? "",
          assignees,
          rfi.receivedFromData?.name ?? "",
          customFormatDate(rfi.createDate, "DD/MM/YYYY"),
          customFormatDate(rfi.dueDate, "DD/MM/YYYY"),
          customFormatDate(rfi.closedDate, "DD/MM/YYYY"),
          `${rfi.delayDays ?? "0"} ${tGeneral("days")}`,
          rfi.cost ?? "",
          drawing,
          rfi.specificationName ?? "",
          distributionList,
          rfi.bPrivate ? tGeneral("private") : tGeneral("public"),
        ];
      });

      const excelData = [
        [tGeneral("rfis")],
        [tGeneral("project"), currentProject.projectName],
        [],
        [],
        [
          tGeneral("number"),
          tGeneral("title"),
          tGeneral("status"),
          tGeneral("creator"),
          tGeneral("assigned"),
          tGeneral("receivedFrom"),
          tGeneral("createDate"),
          tGeneral("limitDate"),
          tGeneral("closedDate"),
          tGeneral("delay"),
          tGeneral("cost"),
          tGeneral("drawing"),
          tGeneral("specification"),
          tGeneral("distributionList"),
          tGeneral("privacy"),
        ],
        ...rfisData,
      ];

      return { fileName: tGeneral("rfis"), data: excelData };
    } catch (error) {
      toast.error(tGeneral("noRfiSelected"), tGeneral("selectRfiToContinue"));
      return { fileName: tGeneral("rfis"), data: [] };
    }
  };

  return (
    <>
      <TitleWidgets
        title={tRfis("rfi_title_module")}
        supportDisabled
        moduleName="rfis"
        backEnabled={false}
        distributionListName={DISTRIBUTION_LIST_NAMES.rfis}
        showDelete={
          Object.keys(selectedRfis).length > 0 && ability.can("delete", "rfis")
        }
        onDelete={() => setOpenDelete(true)}
        enableSupport
        addNameTour="add-rfi-1"
        showExports={false}
        buildExcelData={buildExcelData}
      />
      <Can I="view" a="rfis">
        <Box sx={{ width: "100%" }}>
          <RFITable
            rfis={rfis}
            selectedRfis={selectedRfis}
            setSelectedRfis={setSelectedRfis}
            setFilters={(val) => setParams(val)}
            isLoading={isRfisLoading || isRfisFetching}
          />
          <RfiDeleteModal
            isOpen={openDelete}
            onClose={() => setOpenDelete(false)}
            handleDelete={deleteRfi}
          />
        </Box>
      </Can>

      {/* If there no access to see this module */}
      <Can I="none" a="rfis">
        {tGeneral("noPermission")}
      </Can>
      {Boolean(pdfUrl) && (
        <ModalFileViewer
          file={{ url: pdfUrl }}
          isOpen={Boolean(pdfUrl)}
          onClose={() => setPdfUrl(null)}
        />
      )}
    </>
  );
}

export default RFIs;
