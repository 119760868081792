export const weather = {
  "thunderstorm-200": "軽い雨のある雷雨",
  "thunderstorm-201": "雨のある雷雨",
  "thunderstorm-202": "激しい雨のある雷雨",
  "thunderstorm-210": "軽い雷雨",
  "thunderstorm-211": "雷雨",
  "thunderstorm-212": "激しい雷雨",
  "thunderstorm-221": "乱れた雷雨",
  "thunderstorm-230": "軽い霧雨のある雷雨",
  "thunderstorm-231": "霧雨のある雷雨",
  "thunderstorm-232": "激しい霧雨のある雷雨",
  "drizzle-300": "軽い霧雨",
  "drizzle-301": "霧雨",
  "drizzle-302": "激しい霧雨",
  "drizzle-310": "軽い霧雨の雨",
  "drizzle-311": "霧雨の雨",
  "drizzle-312": "激しい霧雨の雨",
  "drizzle-313": "にわか雨と霧雨",
  "drizzle-314": "激しいにわか雨と霧雨",
  "drizzle-321": "にわか霧雨",
  "rain-500": "小雨",
  "rain-501": "中程度の雨",
  "rain-502": "激しい雨",
  "rain-503": "非常に激しい雨",
  "rain-504": "激しい豪雨",
  "rain-511": "凍雨",
  "rain-520": "小雨のにわか",
  "rain-521": "にわか雨",
  "rain-522": "激しいにわか雨",
  "rain-531": "乱れたにわか雨",
  "snow-600": "小雪",
  "snow-601": "雪",
  "snow-602": "激しい雪",
  "snow-611": "みぞれ",
  "snow-612": "軽いにわかみぞれ",
  "snow-613": "にわかみぞれ",
  "snow-615": "小雨と雪",
  "snow-616": "雨と雪",
  "snow-620": "小雪のにわか",
  "snow-621": "にわか雪",
  "snow-622": "激しいにわか雪",
  "mist-701": "もや",
  "smoke-711": "煙",
  "haze-721": "もや",
  "dust-731": "砂/ほこりの旋風",
  "fog-741": "霧",
  "sand-751": "砂",
  "dust-761": "ほこり",
  "ash-762": "火山灰",
  "squall-771": "スコール",
  "tornado-781": "竜巻",
  "clear-800": "晴れ",
  "clouds-801": "少しの雲：11-25%",
  "clouds-802": "散在する雲：25-50%",
  "clouds-803": "切れ目のある雲：51-84%",
  "clouds-804": "曇り",
  sensation: "体感気温",
};
