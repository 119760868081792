import { MoreHoriz } from "@mui/icons-material";
import {
  Card,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  checkValue,
  ModalWithAction,
  StyledMaterialTable,
  TooltipIconButton,
} from "../../../shared";
import {
  DEFAULT_PAGINATION_LIMIT,
  selectCurrentFiltersDesignTemplates,
  setCurrentFiltersDesignTemplates,
  useDeleteDesignTemplateMutation,
  useDuplicateDesignTemplateMutation,
} from "../../../../features/designTemplates";

import CopyIcon from "../../../../assets/templates/CopyIcon.svg";
import EditIcon from "../../../../assets/templates/EditIcon.svg";
import ExportIcon from "../../../../assets/templates/ExportIcon.svg";
import DeleteIcon from "../../../../assets/templates/DeleteIcon.svg";
import EditedIcon from "../../../../assets/templates/EditedIcon.svg";
import { ModalLastEditDesignTemplate } from "./ModalLastEditDesignTemplate";
import { PopUpAlert } from "../../../PopUpAlert";

function RowActions({ row, onReset }) {
  const { t: tTemplates } = useTranslation("templates");
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const [openDelete, setOpenDelete] = useState(null);
  const [isLastEditOpen, setIsLastEditOpen] = useState(false);

  const [deleteTemplate] = useDeleteDesignTemplateMutation();
  const [duplicateTemplate] = useDuplicateDesignTemplateMutation();

  const handleDuplicate = async () => {
    try {
      const response = await duplicateTemplate(row.id).unwrap();
      if (response) {
        PopUpAlert(
          "success",
          tTemplates("success"),
          tTemplates("templateDuplicated.success"),
        );
      }
      onReset();
    } catch (error) {
      PopUpAlert(
        "error",
        tTemplates("error"),
        tTemplates("templateDuplicated.error"),
      );
    }
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteTemplate(row.id).unwrap();
      if (response) {
        PopUpAlert(
          "success",
          tTemplates("success"),
          tTemplates("templateDeleted.success"),
        );
      }
      onReset();
    } catch (error) {
      PopUpAlert(
        "error",
        tTemplates("error"),
        tTemplates("templateDeleted.error"),
      );
    }
    setAnchorEl(null);
  };

  const handleExport = async () => {};

  const handleLastEdits = () => {
    setIsLastEditOpen(true);
    setAnchorEl(null);
  };

  return (
    <>
      <TooltipIconButton
        icon={<MoreHoriz />}
        color="#8E8E8E"
        label={tTemplates("table.actions")}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleExport} disabled>
          <ListItemIcon>
            <img
              src={ExportIcon}
              alt="icon"
              style={{ height: "24px", color: "#8E8E8E" }}
            />
          </ListItemIcon>
          <ListItemText>{tTemplates("actions.exportTemplate")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigate(`form/${row.id}`)}>
          <ListItemIcon>
            <img
              src={EditIcon}
              alt="icon"
              style={{ height: "24px", color: "#8E8E8E" }}
            />
          </ListItemIcon>
          <ListItemText>{tTemplates("actions.editTemplate")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDuplicate}>
          <ListItemIcon>
            <img
              src={CopyIcon}
              alt="icon"
              style={{ height: "24px", color: "#8E8E8E" }}
            />
          </ListItemIcon>
          <ListItemText>{tTemplates("actions.duplicateTemplate")}</ListItemText>{" "}
        </MenuItem>
        <MenuItem onClick={handleLastEdits}>
          <ListItemIcon>
            <img
              src={EditedIcon}
              alt="icon"
              style={{ height: "24px", color: "#8E8E8E" }}
            />
          </ListItemIcon>
          <ListItemText>{tTemplates("actions.lastEdits")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setOpenDelete(true)}>
          <ListItemIcon>
            <img
              src={DeleteIcon}
              alt="icon"
              style={{ height: "24px", color: "#8E8E8E" }}
            />
          </ListItemIcon>
          <ListItemText>{tTemplates("actions.deleteTemplate")}</ListItemText>
        </MenuItem>
      </Menu>

      <ModalLastEditDesignTemplate
        idObject={row.id}
        isOpen={isLastEditOpen}
        onClose={() => setIsLastEditOpen(false)}
      />

      <ModalWithAction
        title={tTemplates("delete.title")}
        content={tTemplates("delete.description")}
        actionLabel={tTemplates("delete.confirm")}
        onClose={() => setOpenDelete(null)}
        isOpen={openDelete}
        action={handleDelete}
      />
    </>
  );
}

function DesignTemplatesTable({ total, data, isLoading, refetch, reset }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tTemplates } = useTranslation("templates");
  const filters = useSelector(selectCurrentFiltersDesignTemplates);

  const columns = [
    {
      sortable: true,
      field: "title",
      header: tTemplates("table.name"),
      muiTableBodyCellProps: {
        align: "left",
      },
      muiTableHeadCellProps: {
        align: "left",
      },
      size: 150,
    },
    {
      sortable: true,
      field: "updatedDate",
      header: tTemplates("table.editedDate"),
      size: 100,
      render: (row) => (
        <Typography>
          {moment(
            checkValue(row.updatedDate) ? row.updatedDate : row.createdDate,
          ).format("DD/MM/YYYY")}
        </Typography>
      ),
    },
    {
      sortable: true,
      field: "updatedBy",
      header: tTemplates("table.editedBy"),
      render: (row) =>
        checkValue(row.updatedBy) ? (
          <Typography>{`${row.createdBy?.firstName} ${row.createdBy?.lastName}`}</Typography>
        ) : (
          <Typography>{`${row.createdBy?.firstName} ${row.createdBy?.lastName}`}</Typography>
        ),
      size: 100,
    },
    {
      sortable: true,
      field: "items",
      header: tTemplates("table.items.plural"),
      size: 100,
      render: (row) => (
        <Typography
          sx={{
            textAlign: "start",
          }}
        >{`${row.items} ${
          row.items > 1 || row.items === 0
            ? tTemplates("table.items.plural")
            : tTemplates("table.items.singular")
        }`}</Typography>
      ),
    },
    {
      sortable: true,
      field: "disciplines",
      header: tTemplates("table.disciplines.plural"),
      size: 100,
      render: (row) => (
        <Typography
          sx={{
            textAlign: "start",
          }}
        >{`${row.disciplines} ${
          row.disciplines > 1 || row.disciplines === 0
            ? tTemplates("table.disciplines.plural")
            : tTemplates("table.disciplines.singular")
        }`}</Typography>
      ),
    },
    {
      sortable: true,
      field: "createdDate",
      header: tTemplates("table.createdDate"),
      size: 100,
      render: (row) => (
        <Typography>
          <Typography>
            {checkValue(row.createdDate)
              ? moment(row.createdDate).format("DD/MM/YYYY")
              : ""}
          </Typography>
        </Typography>
      ),
    },
    {
      sortable: false,
      field: "actions",
      header: tTemplates("table.actions"),
      size: 100,
      render: (row) => <RowActions row={row} onReset={reset} />,
    },
  ];

  const handlePaginationChange = ({ pageIndex, pageSize }) => {
    setCurrentFiltersDesignTemplates({
      ...filters,
      page: pageIndex,
      limit: pageSize,
    });
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        gap: 1,
        p: "1.25rem",
        borderRadius: "1rem",
        boxShadow: "none",
        bgcolor: "rgba(250, 250, 250)",
      }}
    >
      <Typography variant="h5" sx={{ my: 1, ml: 1, fontWeight: "500" }}>
        {tTemplates("templates")}
      </Typography>
      <StyledMaterialTable
        isLoading={isLoading}
        columns={columns}
        data={data}
        pageIndex={filters?.page || 0}
        pageSize={filters?.limit || DEFAULT_PAGINATION_LIMIT}
        customHeight="fit-content"
        customMaxHeight="calc(90vh - 200px)"
        rowCount={total}
        customTableHeadSx={{
          backgroundColor: "rgba(245, 245, 245, 1)",
        }}
        defaultOrderBy="createdDate"
        onPaginationChange={handlePaginationChange}
      />
    </Card>
  );
}

export { DesignTemplatesTable };
