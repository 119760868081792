export const drawingsChecklist = {
  checkList: "Check list",
  totalCompleted: "Total completed",
  done: "Done",
  revision: "Revision",
  pending: "Pending",
  viewDrawings: "View drawings",
  hideDrawings: "Hide drawings",
  link: "Link",
  deleteRowTitle: "Do you want to delete this row?",
  percentageCompleted: "Percentage completed",
};
