import MaterialTable from "material-table";
import React, { forwardRef, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import clone from "just-clone";

import {
  Add,
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Close,
  Delete,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";
import {
  useDeleteRoleMutation,
  useGetRolesFromEnterprisesQuery,
  useGetUserProjectsByRoleQuery,
} from "../../features/enterprise/enterpriseApiSlice";
import {
  selectCurrentEnterprise,
  setDefaultRoleEnterprise,
} from "../../features/enterprise/enterpriseSlice";
import { selectTheme } from "../../features/preferences/preferencesSlice";
import { useSetUserRoleMutation } from "../../features/project/projectApiSlice";

import { SupportButtonRoles } from "../../components/supportButton/SupportButtonRoles";
import { useAppTourContext } from "../../components/supportButton/context/context";
import { selectCurrentGuideMe } from "../../features/project/projectSlice";
import { CONST_INVALID_ROLE_NAMES } from "../../constants";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function Roles(props) {
  const { t: tGeneral } = useTranslation("general");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const darkMode = useSelector(selectTheme);

  const [columns, setColumns] = useState([
    {
      title: tGeneral("role").toUpperCase(),
      field: "name",
      // render: (rowdata) => (
      //   <Box sx={{ display: "flex", flexDirection: "row" }}>
      //     <Typography
      //       style={{ fontWeight: "600", marginRight: 7 }}
      //     >{`${rowdata.name}`}</Typography>
      //     <Typography>{`(${rowdata.users.length} user${rowdata.users.length > 1 ? "s" : ""
      //       })`}</Typography>
      //   </Box>
      // ),
    },
  ]);

  const [pageSize, setPageSize] = useState(10);
  const [roleSelected, setRoleSelected] = useState("");
  const [projectSelected, setProjectSelected] = useState("");
  const [userIdSelected, setUserIdSelected] = useState("");

  const [bodyDelete, setBodyDelete] = useState({});

  const [deleteRoleEnterprise, { isLoading: isDeleteLoading }] =
    useDeleteRoleMutation();

  const [data, setData] = useState([]);
  const currentEnterprise = useSelector(selectCurrentEnterprise);

  // roles
  const { data: enterpriseRoles, isRolesLoading } =
    useGetRolesFromEnterprisesQuery(currentEnterprise?.id);

  // user's projects by role
  const { data: userProjectsByRole, isUsersProjectsByRoleLoading } =
    useGetUserProjectsByRoleQuery(currentEnterprise?.id);

  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const styles = {
    columnNumHeader: {
      textAlign: "left",
      width: "20%",
      maxWidth: "20%",
      fontWeight: "bold",
      fontSize: "1rem",
    },
    columnNumCell: {
      fontSize: "1rem ",
      textAlign: "left",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "20%",
      maxWidth: "20%",
    },
    columnNameHeader: {
      textAlign: "left",
      width: "100%",
      maxWidth: "100%",
      fontWeight: "bold",
      fontSize: "1rem",
    },
    columnNameCell: {
      fontSize: "1rem",
      textAlign: "left",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
      maxWidth: "100%",
    },
    columnActionHeader: {
      textAlign: "center",
      width: "10%",
      maxWidth: "10%",
      fontWeight: "bold",
      fontSize: "1rem",
    },
    columnActionCell: {
      fontSize: 12,
      textAlign: "center",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "10%",
      maxWidth: "10%",
    },
    iconButton: {
      color: darkMode ? "white" : "black",
      ":hover": {
        color: "#FBCB04",
      },
      m: [0, 0, 0, 0],
    },
    boxStyle: {
      height: "100%",
      width: "100%",
      maxHeight: "90vh",
    },
    boxButton: {
      backgroundColor: "#ffc900",
      padding: 0.5,
      paddingX: 0.7,
      borderRadius: 1,
    },
    addButton: {
      padding: [0.4, 0, 0.4, 0],
      marginBottom: -0.5,
      color: "black",
    },
    dialogTitle: {
      textAlign: "left",
      height: "60px",
      fontWeight: "bold",
    },
    iconButtonClose: {
      position: "absolute",
      right: 8,
      top: 8,
    },
    textField: {
      mx: "auto",
      width: "100%",
      mb: 1,
    },
    cancelButton: {
      width: "50%",
      color: "black",
      backgroundColor: "white",
      borderColor: "black",
      border: 1,
      ":hover": {
        color: "white",
        backgroundColor: "#b4b4b4",
      },
    },
    saveButton: {
      width: "50%",
      color: "black",
      backgroundColor: "#ffc900",
      ":hover": {
        color: "black",
        backgroundColor: "#d9ab00",
      },
    },
    removeButton: {
      width: "50%",
      color: "black",
      backgroundColor: "#ffc900",
      ":hover": {
        color: "black",
        backgroundColor: "#d9ab00",
      },
    },
  };

  const theme = useTheme();

  const md_measurement = useMediaQuery(theme.breakpoints.up("md"));

  const show_searchbar = !!md_measurement;

  const [setRole] = useSetUserRoleMutation();

  // Update user's project Role
  const updateUserProjectRole = async (e) => {
    const body = {
      idUser: userIdSelected,
      idProject: projectSelected,
      idRole: roleSelected.id,
      isNew: false,
    };

    try {
      const response = await setRole(body).unwrap();

      if (response) {
        showAlert(tGeneral("enterpriseRoleUpdated"), "success");
      }
    } catch (err) {
      console.error(err);
      showAlert(tGeneral("cantUpdateEnterpriseRole"), "error");
    }

    setRoleSelected("");
    setUserIdSelected("");
    setProjectSelected("");
    setOpenUpdateDialog(false);
  };

  useEffect(() => {
    if (enterpriseRoles) {
      // let tempData = enterpriseRoles.map((role) => ({
      //   id: role.id,
      //   rol: role.name,
      //   bActive: role.bActive,
      //   drawings: role.drawings,
      //   punchlist: role.punchlist,
      //   directory: role.directory,
      //   rfis: role.rfis,
      //   submittals: role.submittals,
      //   deliveries: role.deliveries,
      //   schedules: role.schedules,
      //   meetings: role.meetings,
      //   photos: role.photos,
      //   tasks: role.tasks,
      //   approvals: role.approvals,
      //   issues: role.issues,
      //   daily_reports: role.dailyReports,
      //   documents: role.documents,
      //   security: role.security,
      //   functional_report: role.functionalReport,
      //   delays: role.delays,
      //   guarantees: role.guarantees,
      //   order_changes: role.orderChanges,
      //   contracts: role.contracts,
      //   estimations: role.estimations,
      //   announcements: role.announcements,
      //   dashboard: role.dashboard,
      //   admin_panel: role.adminPanel,
      //   specifications: role.specifications,
      // }));
      let activeRoles = 0;
      let inactiveRoles = 0;
      for (let i = 0; i < enterpriseRoles.length; i++) {
        if (enterpriseRoles[i].bActive == true) {
          activeRoles += 1;
        } else {
          inactiveRoles += 1;
        }
      }
    }

    if (userProjectsByRole) {
      const newData = clone(userProjectsByRole);
      setData(newData);
    }
  }, [enterpriseRoles, userProjectsByRole]);

  const dataBody = null;

  // Delete Role
  const deleteRole = async (e) => {
    try {
      const response = await deleteRoleEnterprise(bodyDelete).unwrap();

      if (response) {
        showAlert(tGeneral("enterpriseRoleDeleted"), "success");
      }
    } catch (err) {
      console.error(err);
      showAlert(tGeneral("cantDeleteEnterpriseRole"), "error");
    }
    setBodyDelete({});
  };

  function showAlert(message, type) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: type,
      title: message,
    });
  }

  const tableRef = React.createRef();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);

  const tableLocalization = {
    pagination: {
      labelRowsPerPage: `${tGeneral("labelRowsPerPage")}`,
      labelDisplayedRows: `{from}-{to} ${tGeneral("of")} {count}`,
      labelRowsSelect: `${tGeneral("labelRowsSelect")}`,
      firstAriaLabel: `${tGeneral("firstAriaLabel")}`,
      firstTooltip: `${tGeneral("firstTooltip")}`,
      previousAriaLabel: `${tGeneral("previousAriaLabel")}`,
      previousTooltip: `${tGeneral("previousTooltip")}`,
      nextAriaLabel: `${tGeneral("nextAriaLabel")}`,
      nextTooltip: `${tGeneral("nextTooltip")}`,
      lastAriaLabel: `${tGeneral("lastAriaLabel")}`,
      lastTooltip: `${tGeneral("lastTooltip")}`,
    },
    toolbar: {
      searchPlaceholder: tGeneral("search"),
    },
    header: {
      actions: tGeneral("actions"),
    },
    body: {
      emptyDataSourceMessage: tGeneral("emptyDataSourceMessage"),
    },
  };

  const customDetailPanel = (rowData) => {
    return (
      <div
        style={{
          fontSize: 18,
          color: "white",
          backgroundColor: "#F3F3F3",
          padding: 8,
        }}
      >
        {rowData.users.length > 0 ? (
          <>
            {/* users */}
            {rowData.users.map((user, index) => {
              return (
                <Accordion
                  key={`user-accordion-${index}`}
                  expanded={expanded(index)}
                  sx={{ width: "100%", height: "100%" }}
                >
                  {/* user */}
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography style={{ marginRight: 7, fontWeight: "500" }}>
                        {user.name}
                      </Typography>
                      <Typography sx={{ color: "grey" }}>
                        {`(${user.userInfo[0].projects.length} project${
                          user.userInfo[0].projects.length > 1 ? "s" : ""
                        })`}
                      </Typography>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails>
                    <List>
                      {/* projects */}
                      {user.userInfo[0].projects.map((projs) => {
                        return (
                          <React.Fragment key={`project-${projs.id}`}>
                            <Divider />
                            <ListItem
                              secondaryAction={
                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() => {
                                    setProjectSelected(projs.id);
                                    setUserIdSelected(user.idUser);
                                    setOpenUpdateDialog(true);
                                  }}
                                  data-tour="change-role-1"
                                >
                                  <Edit />
                                </IconButton>
                              }
                            >
                              <ListItemText primary={projs.projectName} />
                            </ListItem>
                          </React.Fragment>
                        );
                      })}
                    </List>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </>
        ) : (
          <Typography style={{ color: "black", padding: 5 }}>
            {tGeneral("noUsersRole")}
          </Typography>
        )}
      </div>
    );
  };

  const [tourRun, setTourRun] = useState(false);
  const {
    state: { tourActive, stepIndex, steps },
    setState,
  } = useAppTourContext();
  const expanded = useCallback(
    (index) => {
      if (tourRun && index === 0 && currentGuideMe === "roles-settings")
        return true;
    },
    [tourRun, currentGuideMe],
  );
  const dataFeed = useCallback(() => {
    if (!tourRun) return data;

    return (
      data?.map((d, i) => {
        if (i === 0) {
          return {
            ...d,
            tableData: {
              showDetailPanel: customDetailPanel,
            },
          };
        }
        return {
          ...d,
        };
      }) || []
    );
  }, [data, tourRun]);

  useEffect(() => {
    if (tourActive && stepIndex === 0 && currentGuideMe === "roles-settings") {
      setTourRun(true);
      setProjectSelected(null);
      setUserIdSelected(null);
      setOpenUpdateDialog(false);
      setTimeout(() => {
        setState({ run: true });
      });
    }

    if (
      tourActive &&
      stepIndex === steps.length - 1 &&
      currentGuideMe === "roles-settings"
    ) {
      setProjectSelected(null);
      setUserIdSelected(null);
      setOpenUpdateDialog(false);
      setTourRun(false);
    }
  }, [tourActive, stepIndex, currentGuideMe, steps]);

  useEffect(() => {
    if (
      tourActive &&
      (stepIndex === 1 || stepIndex === steps.length - 2) &&
      data &&
      data.length > 0 &&
      currentGuideMe === "roles-settings"
    ) {
      const projectId = data["0"].users["0"].userInfo["0"].projects["0"].id;
      const userId = data["0"].users["0"].idUser;
      setProjectSelected(projectId);
      setUserIdSelected(userId);
      setOpenUpdateDialog(true);
      setTimeout(() => {
        setState({ run: true });
      });
    }
  }, [tourActive, stepIndex, steps, currentGuideMe, data]);

  useEffect(() => {
    if (
      tourActive &&
      (currentGuideMe === "roles-add" || currentGuideMe === "roles-edit") &&
      stepIndex === 0
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, currentGuideMe, stepIndex]);

  useEffect(() => {
    if (tourActive && stepIndex === 1 && currentGuideMe === "roles-delete") {
      setOpenDeleteDialog(true);
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }

    if (tourActive && (stepIndex === 0 || steps.length - 1 === stepIndex)) {
      setOpenDeleteDialog(false);
    }
  }, [tourActive, stepIndex, steps, currentGuideMe]);

  return (
    <>
      <Box sx={{ height: 500, width: "100%" }}>
        <MaterialTable
          icons={tableIcons}
          columns={columns}
          tableRef={tableRef}
          data={[...dataFeed()]}
          localization={tableLocalization}
          title={
            <Box sx={{ width: "100%" }}>
              <ButtonGroup sx={{ alignItems: "center" }}>
                <Typography
                  sx={{ fontSize: 24, fontWeight: "bold", color: darkMode }}
                >
                  Roles
                </Typography>
                <SupportButtonRoles />
              </ButtonGroup>
            </Box>
          }
          actions={[
            // add
            {
              icon: forwardRef((props, ref) => (
                <Box
                  sx={{
                    backgroundColor: "#ffc900",
                    height: "35px",
                    width: "35px",
                    borderRadius: 1,
                  }}
                  data-tour="add-role-1"
                >
                  <Add
                    ref={ref}
                    style={{
                      padding: 0,
                      margin: 0,
                      marginTop: "5",
                      color: "black",
                    }}
                  />
                </Box>
              )),
              tooltip: tGeneral("newRole"),
              isFreeAction: true,
              // hidden: userRole.panel === 3 ? false : true, // only for super admins
              onClick: (event) => {
                // otras funciones cuando click on add
                dispatch(
                  setDefaultRoleEnterprise({
                    drawings: 0,
                    punchlist: 0,
                    directory: 0,
                    rfis: 0,
                    submittals: 0,
                    deliveries: 0,
                    schedules: 0,
                    meetings: 0,
                    photos: 0,
                    tasks: 0,
                    approvals: 0,
                    issues: 0,
                    dailyReports: 0,
                    documents: 0,
                    security: 0,
                    functionalReport: 0,
                    delays: 0,
                    guarantees: 0,
                    orderChanges: 0,
                    contracts: 0,
                    estimations: 0,
                    name: "",
                    announcements: 0,
                    dashboard: 0,
                    adminPanel: 0,
                    specifications: 0,
                    inspections: 0,
                  }),
                );
                navigate(`/enterprise/${currentEnterprise?.id}/AddRoles`);
              },
            },
            // edit
            (rowData) => ({
              icon: () => <Edit data-tour="edit-role-1" />,
              disabled:
                rowData.name === "Unclassified" ||
                CONST_INVALID_ROLE_NAMES.includes(rowData.name.toLowerCase()),
              tooltip:
                // eslint-disable-next-line no-unused-expressions, no-nested-ternary
                rowData.name === "Unclassified"
                  ? tGeneral("cantEditUnclassifiedRole")
                  : CONST_INVALID_ROLE_NAMES.includes(
                        rowData.name.toLowerCase(),
                      )
                    ? tGeneral("editInvalidRoleNameWarning")
                    : tGeneral("edit"),
              onClick: (event, rowData) => {
                navigate(
                  `/enterprise/${currentEnterprise?.id}/editRole/${rowData.idRole}`,
                );
              },
            }),
            // delete
            (rowData) => ({
              icon: () => (
                <Delete
                  sx={{
                    color:
                      rowData.name === "Unclassified" ||
                      CONST_INVALID_ROLE_NAMES.includes(
                        rowData.name.toLowerCase(),
                      )
                        ? "#fc948c"
                        : "red",
                  }}
                  data-tour="delete-role-1"
                />
              ),
              disabled:
                rowData.name === "Unclassified" ||
                CONST_INVALID_ROLE_NAMES.includes(rowData.name.toLowerCase()),
              tooltip:
                // eslint-disable-next-line no-unused-expressions, no-nested-ternary
                rowData.name === "Unclassified"
                  ? tGeneral("cantDeleteUnclassifiedRole")
                  : CONST_INVALID_ROLE_NAMES.includes(
                        rowData.name.toLowerCase(),
                      )
                    ? tGeneral("deleteInvalidRoleNameWarning")
                    : tGeneral("delete"),
              onClick: (event, rowData) => {
                setBodyDelete({
                  idRole: rowData.idRole,
                  idEnterprise: currentEnterprise?.id,
                });

                setOpenDeleteDialog(true);
              },
            }),
          ]}
          options={{
            actionsColumnIndex: -1,
            search: show_searchbar,
            searchFieldVariant: "outlined",
            searchFieldStyle: { width: "450px", height: "35px" },
            rowStyle: { padding: "0 5%" },
            headerStyle: { fontWeight: "600" },
            rowStyle: { fontWeight: "600" },
            paging: true,
            pageSize,
            pageSizeOptions: [5, 10, 20],
            onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
            emptyRowsWhenPaging: false,
          }}
          detailPanel={customDetailPanel}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
          checkboxSelection
          disableSelectionOnClick
        />
      </Box>

      {/* Modal - delete role */}
      <Dialog
        open={openDeleteDialog}
        onClose={(e) => {
          setBodyDelete({});
          setOpenDeleteDialog(false);
        }}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ mx: "auto" }}
        PaperProps={{ sx: { borderRadius: "10px", width: "50%" } }}
      >
        <DialogTitle id="scroll-dialog-title">
          <IconButton
            aria-label="close"
            onClick={(e) => setOpenDeleteDialog(false)}
            sx={[styles.iconButtonClose, { marginRight: "20px" }]}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <Box style={styles.closeAlert}>
          <Box
            style={{
              width: "100%",
              textAlign: "center",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={require("../../assets/icons/delete.png")}
              style={{ width: 100, height: 100, margin: "auto" }}
              alt=""
            />

            <Typography
              style={{ fontSize: 24, textAlign: "center", fontWeight: "600" }}
            >
              {tGeneral("deleteRole").toUpperCase()}
            </Typography>
          </Box>
        </Box>

        <DialogContent>
          <Typography style={{ fontSize: 16, textAlign: "center" }}>
            {tGeneral("deleteRoleMessage")}
          </Typography>
        </DialogContent>

        <DialogActions
          sx={[
            { marginBottom: "10px" },
            { marginRight: "30px" },
            { marginLeft: "30px" },
          ]}
        >
          <Button
            sx={styles.cancelButton}
            type="submit"
            variant="contained"
            size="large"
            onClick={(e) => {
              setOpenDeleteDialog(false);
              setBodyDelete({});
            }}
          >
            {tGeneral("cancel")}
          </Button>

          <Button
            sx={styles.saveButton}
            type="submit"
            variant="contained"
            size="large"
            onClick={() => {
              setOpenDeleteDialog(false);
              deleteRole();
            }}
            data-tour="delete-role-2"
          >
            {tGeneral("confirm")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal - update user's project role */}
      <Modal
        open={openUpdateDialog}
        onClose={() => {
          setOpenUpdateDialog(false);
        }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          sx={{
            width: "30%",
            height: "auto",
            borderRadius: "20px",
            p: "25px 40px 40px 40px",
          }}
        >
          {/* Title */}
          <Box
            display="flex"
            flex={1}
            alignItems="center"
            justifyContent="space-between"
            mb="10px"
          >
            <Typography fontSize="1.562rem" fontWeight="bold">
              {tGeneral("editRole")}
            </Typography>
            <IconButton
              onClick={() => {
                setOpenUpdateDialog(false);
              }}
            >
              <Close />
            </IconButton>
          </Box>
          <Divider />

          {/* Content */}
          <FormControl sx={{ marginY: 3, width: "100%" }}>
            <InputLabel id="status-label">{tGeneral("roleName")}</InputLabel>
            <Select
              labelId="state-label"
              required
              margin="dense"
              variant="outlined"
              type="text"
              label="Role name"
              select="true"
              value={roleSelected}
              onChange={(e) => {
                setRoleSelected(e.target.value);
              }}
              sx={{ width: "100%", maxHeight: "56px" }}
              data-tour="change-role-2"
            >
              {enterpriseRoles?.map((item) => {
                if (
                  item.name.toLowerCase() !== "unclassified" &&
                  !CONST_INVALID_ROLE_NAMES.includes(item.name.toLowerCase())
                ) {
                  return (
                    <MenuItem key={item.name} value={item}>
                      {item.name}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </FormControl>
          <Divider />

          {/* Buttons */}
          <Box display="flex" justifyContent="flex-end" mr="20px" mt="20px">
            <Button
              fullWidth
              color="secondary"
              sx={{
                marginRight: "20px",
                py: "1rem",
                backgroundColor: "white",
                color: "black",
                border: "1px solid black",
                fontSize: "1rem",
              }}
              onClick={() => {
                setOpenUpdateDialog(false);
              }}
              variant="contained"
            >
              {tGeneral("cancel")}
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                updateUserProjectRole();
              }}
              sx={{ py: "1rem", fontSize: "1rem" }}
              data-tour="change-role-3"
            >
              {tGeneral("save")}
            </Button>
          </Box>
        </Card>
      </Modal>
    </>
  );
}

export default Roles;
