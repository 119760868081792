import { Box, Checkbox, Typography, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  addSelectedTask,
  removeSelectedTask,
  selectSelectedTasks,
} from "../../../features/project/modules/tasks/tasksSlice";
import TaskCard from "./TaskCard";
import locationsUtil from "../../../utils/locations";
import { selectCurrentUser } from "../../../features/userSettings/userSettingsSlice";
import CaslContext from "../../../utils/caslContext";

function TaskBoardColumn({
  title,
  status,
  users = [],
  locations = [],
  borders = false,
  droppableId,
  total = 0,
  tasks = [],
  isLoading = false,
  noResults = false,
  noMoreResults = false,
  onEndReach = () => {},
  refetch = () => {},
}) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tTasks } = useTranslation("tasks");

  const dispatch = useDispatch();
  const selectedTasks = useSelector(selectSelectedTasks);
  const user = useSelector(selectCurrentUser);
  const ability = useContext(CaslContext);

  const [loadingMore, setLoadingMore] = useState(false);
  const containerRef = useRef(null);
  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading || noMoreResults) return; // Stop observing if loading or no more posts
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setLoadingMore(true); // Trigger loading of new posts by changing page number
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading, noMoreResults],
  );

  useEffect(() => {
    if (loadingMore && !noMoreResults && !noResults) {
      onEndReach();
      setLoadingMore(false);
    }
  }, [loadingMore, noMoreResults, noResults, onEndReach]);

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      tasks.forEach((task) => {
        dispatch(addSelectedTask(task?.id));
      });
    } else {
      tasks.forEach((task) => {
        dispatch(removeSelectedTask(task?.id));
      });
    }
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#FFF5DB" : "transparent",
    height: "100%",
    overflowY: "auto",
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: 0,
    margin: "0 0 0 0",
    background: "transparent",
    ...draggableStyle,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        overflow: "hidden",
      }}
      ref={containerRef}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 1,
          mb: 1,
        }}
      >
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={
              tasks?.length > 0 &&
              selectedTasks.length > 0 &&
              tasks?.every((task) => selectedTasks?.includes(task?.id))
            }
            onChange={handleCheckboxChange}
            size="small"
            color="secondary"
            disableRipple
            sx={{
              borderRadius: 0,
              width: "25px",
              height: "25px",
              mr: 1,
            }}
          />
          <Typography fontSize="24px" fontWeight="medium">
            {title}
          </Typography>
        </Box>
        <Typography fontSize="24px" color="#B0B7C1">
          {`#${total || 0}`}
        </Typography>
      </Box>

      <Box
        sx={{
          flex: 1,
          pt: 1,
          ...(borders && {
            borderLeft: { xs: 0, md: "0.5px solid #B0B7C1" },
            borderRight: { xs: 0, md: "0.5px solid #B0B7C1" },
          }),
          overflow: "hidden",
        }}
      >
        <Droppable droppableId={droppableId}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                ...getListStyle(snapshot.isDraggingOver),
                padding: "0.5rem",
              }}
            >
              {tasks.map((task, index) => (
                <Draggable
                  key={task.id}
                  draggableId={task.id}
                  index={index}
                  isDragDisabled={
                    !task?.bActive ||
                    (task?.status === "backlog" &&
                      task?.assignedTo.indexOf(user.id) < 0 &&
                      !ability.can("superAdmin", "tasks")) ||
                    (task?.status === "doing" &&
                      task?.createdBy !== user.id &&
                      !ability.can("superAdmin", "tasks")) ||
                    task?.status === "done"
                  }
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <TaskCard
                        key={task?.id}
                        task={task}
                        title={task?.title}
                        status={task?.status}
                        number={task?.taskNumber}
                        priority={task?.priority}
                        assignedTo={task?.assignedTo?.map((userId) => {
                          const userObject = users?.find(
                            (userInstance) => userInstance?.user?.id === userId,
                          );
                          return userObject?.user;
                        })}
                        creator={
                          users?.find(
                            (userInstance) =>
                              userInstance?.user?.id === task?.createdBy,
                          )?.user
                        }
                        location={locationsUtil.getLocationNameFromId(
                          locations,
                          task?.locationId,
                        )}
                        createDate={task?.createDate}
                        dueDate={task?.dueDate}
                        closedDate={task?.closedDate}
                        isPrivate={task?.bPrivate}
                        isDraft={task?.bDraft}
                        subtasks={task?.subtasks}
                        PaperSxProps={{ mb: 2 }}
                        data-tour={
                          selectedTasks?.includes(task?.id)
                            ? "view-tasks-1"
                            : ""
                        }
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              <div
                ref={lastElementRef}
                style={{ height: "100px", textAlign: "center" }}
              >
                <Box
                  display="flex"
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  {!noMoreResults && !noResults && isLoading && (
                    <CircularProgress color="primary" />
                  )}
                  {noMoreResults && (
                    <p style={{ textAlign: "center", margin: "30px 0" }}>
                      <b>{tGeneral("noMoreResults")}</b>
                    </p>
                  )}
                  {noResults && (
                    <p style={{ textAlign: "center", margin: "30px 0" }}>
                      <b>{tGeneral("noResults")}</b>
                    </p>
                  )}
                </Box>
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </Box>
    </Box>
  );
}

export default TaskBoardColumn;
