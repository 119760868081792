import { Box, CircularProgress } from "@mui/material";
import * as React from "react";
import { useVirtual } from "react-virtual";

function InfiniteList(props) {
  const {
    rows,
    isLoading,
    hasMore,
    fetchMore,
    renderRow,
    isHorizontal = false,
    scrollToBottomOnRowsChange = false,
    spacing = "16px",
    header,
    gridTemplateColumns = "repeat(auto-fill,minmax(390px, 1fr))",
    ...boxProps
  } = props;
  const parentRef = React.useRef(null);
  const { virtualItems, scrollToIndex } = useVirtual({
    size: rows.length,
    parentRef,
    horizontal: isHorizontal,
    overscan: rows.length,
  });

  const observerRef = React.useRef();

  const loadingRowRef = (ref) => {
    if (ref && observerRef.current) {
      observerRef.current.observe(ref);
    }
  };

  React.useEffect(() => {
    if (scrollToBottomOnRowsChange && rows.length > 0) {
      scrollToIndex(rows.length - 1);
    }
  }, [scrollToIndex, rows, scrollToBottomOnRowsChange]);

  React.useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        if (!isLoading && hasMore && entry.isIntersecting) {
          if (typeof fetchMore === "function") {
            fetchMore();
          }
        }
      },
      { root: parentRef.current, rootMargin: "0px", threshold: 0.9 },
    );

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [isLoading, fetchMore, hasMore]);

  return (
    <Box
      ref={parentRef}
      height="100%"
      width="100%"
      overflow="overlay"
      {...boxProps}
    >
      {!!header && React.cloneElement(header)}
      <Box
        sx={{
          position: "relative",
          display: "grid",
          gridTemplateColumns,
          gap: "0px 20px",
        }}
      >
        {virtualItems.map(({ index, measureRef, key }) => {
          return (
            <Box ref={measureRef} key={key}>
              {React.cloneElement(renderRow(rows[index], index), {
                marginBottom: !isHorizontal ? spacing : undefined,
                marginRight: isHorizontal ? spacing : undefined,
              })}
            </Box>
          );
        })}
        {(hasMore || isLoading) && (
          <Box
            ref={loadingRowRef}
            display="flex"
            justifyContent="center"
            padding="1rem"
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export { InfiniteList };
