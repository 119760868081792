import { apiSlice } from "../../api/apiSlice";

export const messageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // get msgs
    getMessages: builder.query({
      query: (params) =>
        `/message/getMessages?idModule=${params.idModule}&module=${params.module}`,
      keepUnusedDataFor: 60,
      providesTags: ["Message"],
      // query: body => ({
      //   url: `/message/getMessages`,
      //   method: 'POST',
      //   body,
      // }),
    }),
    // add msg
    addMessage: builder.mutation({
      query: (body) => ({
        url: "/message/addMessage",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Message"],
    }),
    // delete Msg
    deleteMessage: builder.mutation({
      query: (body) => ({
        url: () => "/message/deleteMessage",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Message"],
    }),
    // edit msg
    editMessage: builder.mutation({
      query: (body) => ({
        url: "/message/editMessage",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Message", "RFIs"],
    }),
    // check msg
    checkMessage: builder.mutation({
      query: (body) => ({
        url: "/message/checkMessage",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Message"],
    }),
    answerQuestion: builder.mutation({
      query: (body) => ({
        url: "/message/answerQuestion",
        method: "POST",
        body,
      }),
      invalidatesTags: ["RFIs", "Submittals", "Tasks"],
    }),
    commentObject: builder.mutation({
      query: (body) => ({
        url: "/message/commentObject",
        method: "POST",
        body,
      }),
      invalidatesTags: [
        "RFIs",
        "Submittals",
        "Tasks",
        "Meetings",
        "DailyReportsChat",
        "Annotations",
        "Manpower",
      ],
    }),
    addTopic: builder.mutation({
      query: (body) => ({
        url: "/message/addTopic",
        method: "POST",
        body,
      }),
      invalidatesTags: ["RFIs", "Submittals", "Tasks"],
    }),
    setOfficialResponse: builder.mutation({
      query: (body) => ({
        url: "/message/setOfficialResponse",
        method: "POST",
        body,
      }),
      invalidatesTags: ["RFIs", "Submittals"],
    }),
  }),
});

export const {
  useGetMessagesQuery,
  useAddMessageMutation,
  useDeleteMessageMutation,
  useEditMessageMutation,
  useCheckMessageMutation,
  useAnswerQuestionMutation,
  useCommentObjectMutation,
  useAddTopicMutation,
  useSetOfficialResponseMutation,
} = messageApiSlice;
