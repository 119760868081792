import { Close } from "@mui/icons-material";
import {
  Box,
  Chip,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectTheme } from "../../../features/preferences/preferencesSlice";

function TopicItem({ topic, index, setSelectedTopic, setModifiedTopic }) {
  const darkMode = useSelector(selectTheme);
  const [topicName, setTopicName] = useState();
  const [isNameFocused, setIsNameFocused] = useState(false);

  useEffect(() => {
    setTopicName(topic?.title);
  }, [topic]);

  return (
    <Box sx={{ width: "100%" }}>
      {!isNameFocused ? (
        <Chip
          label={
            <Box
              onClick={() => {
                setIsNameFocused(true);
              }}
              display="flex"
              alignItems="flex-start"
              columnGap="5px"
            >
              <Typography>{index + 1}.</Typography>{" "}
              <Typography whiteSpace="pre-wrap">{topicName}</Typography>
            </Box>
          }
          sx={{
            bgcolor: darkMode ? "" : "#FFEBBC",
            color: "#B48100",
            ":hover": {
              bgcolor: darkMode ? "" : "#FFEBBC",
            },
            fontSize: "16px",
            maxHeight: "unset",
            height: "auto",
          }}
          deleteIcon={<Close style={{ color: "red" }} />}
          onDelete={() => {
            setSelectedTopic(index);
          }}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            autoFocus={isNameFocused}
            value={topicName}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ mr: 1 }}>{index + 1}.</InputAdornment>
              ),
            }}
            onChange={(e) => {
              setTopicName(e.target.value);
              setModifiedTopic(e.target.value);
            }}
            onBlur={() => {
              setIsNameFocused(false);
            }}
            sx={{
              backgroundColor: darkMode ? "" : "white",
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default TopicItem;
