const TooltipContent = ({ children }) => {
  return (
    <div
      style={{ padding: "20px", backgroundColor: "white", borderRadius: "5px" }}
    >
      {children}
    </div>
  );
};

export { TooltipContent };
