import { createContext, useContext, useMemo, useState } from "react";
import { Toast } from "../components/Toast";

export const ToastContext = createContext(null);

export function ToastProvider({ children, ...props }) {
  const [messages, setMessages] = useState([]);

  const removeMessage = (key) =>
    setMessages((arr) => arr.filter((m) => m.key !== key));

  const addMessage = useMemo(
    () => ({
      addMessage(message) {
        setMessages((arr) => [...arr, message]);
      },
      removeMessage,
    }),
    [],
  );

  return (
    <ToastContext.Provider value={addMessage}>
      {children}
      {messages.map((m) => (
        <Toast
          key={m.key}
          message={m}
          onExited={() => removeMessage(m.key)}
          {...props}
        />
      ))}
    </ToastContext.Provider>
  );
}

export const useToast = () => {
  const { addMessage, removeMessage } = useContext(ToastContext);

  const show = (title, message, options) => {
    addMessage({ title, message, key: new Date().getTime(), ...options });
  };

  const hide = (key) => {
    removeMessage(key);
  };

  return {
    show,
    info(title, message) {
      show(title, message, { severity: "info" });
    },
    success(title, message) {
      show(title, message, { severity: "success" });
    },
    warning(title, message) {
      show(title, message, { severity: "warning" });
    },
    error(title, message) {
      show(title, message, { severity: "error" });
    },
    showLoading(key, title, message) {
      show(title, message, { severity: "loading", key });
    },
    hideLoading(key) {
      hide(key);
    },
  };
};
