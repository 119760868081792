import { apiSlice } from "../../api/apiSlice";

const setsQuery = "project/set";

export const setApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSets: builder.query({
      query: (idProject) => `${setsQuery}/getSets/${idProject}`,
      providesTags: ["Sets"],
    }),
    addSet: builder.mutation({
      query: (body) => ({
        url: `${setsQuery}/createSet`,
        body,
        method: "POST",
      }),
      invalidatesTags: ["Sets"],
    }),
    updateSet: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${setsQuery}/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Sets"],
    }),
    deleteSet: builder.mutation({
      query: (body) => ({
        url: `${setsQuery}/delete`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Sets"],
    }),
  }),
});

export const {
  useGetSetsQuery,
  useAddSetMutation,
  useUpdateSetMutation,
  useDeleteSetMutation,
} = setApiSlice;
