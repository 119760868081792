import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  meetings: [],
  current: null,
};

export const meetingSlice = createSlice({
  name: "meeting",
  initialState,
  reducers: {
    setMeetings: (state, action) => {
      state.meetings = action.payload;
    },
    setCurrentMeeting: (state, action) => {
      state.current = action.payload;
    },
    resetMeetings: () => initialState,
  },
});

export const selectAllMeetings = (state) => state.meeting.meetings;

export const selectCurrentMeeting = (state) => state.meeting.current;

export const { setMeetings, setCurrentMeeting, resetMeetings } =
  meetingSlice.actions;

export default meetingSlice.reducer;
