export const tour = {
  // topbar
  guideMe: "가이드",
  support: "지원",
  meeting: "미팅",
  workflow: "워크플로우",
  // guide me buttons
  addButtonTour: "추가",
  viewButtonTour: "보기",
  editButtonTour: "편집",
  recycleButtonTour: "재활용",
  exportButtonTour: "내보내기",
  settingsButtonTour: "설정",
  deleteButtonTour: "삭제",
  changeRoleButtonTour: "역할 변경",
  // docs
  docsAddFileButtonTour: "파일 추가",
  docsAddFolderButtonTour: "폴더 추가",
  docsFileInfoButtonTour: "파일 정보",
  docsMoveToButtonTour: "이동",
  docsActionsButtonTour: "작업",
  // more
  obligatory: "의무적인",
  congratulations: "축하합니다!",
  moreTitle: "더 많은 옵션",
  moreDescription: "여기에서 추가 옵션을 찾을 수 있습니다.",
};
