import { Box, useMediaQuery } from "@mui/material";
import { DocumentSharingFeature } from "./DocumentSharingFeature";
import { Title } from "./Title";

function DocumentSharing() {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Box
      sx={{
        backgroundColor: "white",
        paddingBottom: isMobile ? "125px" : "250px",
        width: "100%",
        paddingX: isMobile ? "5%" : "0",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-around"
        marginRight={isMobile ? "0" : "140px"}
      >
        {!isMobile && (
          <img
            src={require("./../../../assets/document-sharing-tablet.png")}
            alt="Document sharing"
            style={{
              maxWidth: "50%",
            }}
          />
        )}
        <Box>
          <Title content="Forget the paperwork, with Buildpeer it’s easy to store and share all your construction plans, documents and photos in the cloud." />
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            alignItems="baseline"
          >
            <DocumentSharingFeature
              image={
                <img
                  src={require("./../../../assets/markups.png")}
                  alt="Markups"
                  style={{ maxHeight: "102px", maxWidth: "102px" }}
                />
              }
              title="MARKUPS & AS-BUILTS"
              content="Create your markups and as-builts with easy-to-use mobile editing. Access them at any time, even after construction."
            />
            <DocumentSharingFeature
              image={
                <img
                  src={require("./../../../assets/plan-viewing.png")}
                  alt="Plan viewing"
                  style={{ maxHeight: "102px", maxWidth: "102px" }}
                />
              }
              title="MOBILE PLAN VIEWING"
              content="View Drawings, documents or photos on any device (Web, iOS, Android) and always have access to the latest information."
              reverse={isMobile}
            />
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        marginLeft={isMobile ? "0" : "140px"}
        overflow="hidden"
      >
        <Box marginTop={isMobile ? "120px" : "230px"}>
          <Title content="Connect the office and the field with real time communications tools. Assign tasks to team members and request missing information in a fast and intuitive way." />
          <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
            <DocumentSharingFeature
              image={
                <img
                  src={require("./../../../assets/check.png")}
                  alt="Check"
                  style={{ maxHeight: "102px", maxWidth: "102px" }}
                />
              }
              title="MAKE DECISIONS FAST"
              content="Speed up the interpretation and decision processes with real-time communication tools."
            />
            <DocumentSharingFeature
              image={
                <img
                  src={require("./../../../assets/manage.png")}
                  alt="Manage change"
                  style={{ maxHeight: "102px", maxWidth: "102px" }}
                />
              }
              title="MANAGE CHANGE"
              content="Manage change in your project and deal with issues that may arise, in a safe and timely manner."
              reverse={isMobile}
            />
          </Box>
        </Box>
        {!isMobile && (
          <img
            src={require("./../../../assets/document-sharing-laptop.png")}
            alt="Document sharing"
            style={{
              maxWidth: "60%",
              objectFit: "cover",
            }}
          />
        )}
      </Box>
    </Box>
  );
}

export { DocumentSharing };
