import {
  Delete,
  DriveFileMove,
  FileCopy,
  Info,
  MoreVert,
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { useRef } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import { PopUpAlert } from "../../../../../components/PopUpAlert";
import { useAppTourContext } from "../../../../../components/supportButton/context/context";
import { useDeleteDocumentMutation } from "../../../../../features/project/modules/documents/documentsApiSlice";
import { useDeleteProjectFolderMutation } from "../../../../../features/project/modules/documents/projectFolderApiSlice";
import { setNeedReload } from "../../../../../features/project/modules/documents/projectFolderSlice";
import {
  selectCurrentGuideMe,
  selectCurrentRole,
} from "../../../../../features/project/projectSlice";
import Can from "../../../../../utils/can";
import DeleteModal from "../Modals/DeleteModal";
import MoveModal from "../Modals/MoveModal";

export default function ActionMenu(props) {
  const { t: tGeneral } = useTranslation("general");
  const { rowData, path, project } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Tour
  const moreActions = useRef();
  const {
    state: { stepIndex, tourActive, steps, run },
    setState,
  } = useAppTourContext();
  const currentGuideMe = useSelector(selectCurrentGuideMe);
  // Handle Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  const currentRole = useSelector(selectCurrentRole);

  // Handle info
  const [actionType, setActionType] = useState("");

  // Handle single row delete
  const [
    deleteProjectFolder,
    {
      isSuccess: isDeleteProjectFolderSuccess,
      isError: isDeleteProjectFolderError,
      isLoading: isDeleteProjectFolderLoading,
    },
  ] = useDeleteProjectFolderMutation();
  useEffect(() => {
    if (isDeleteProjectFolderSuccess) {
      PopUpAlert("success", "Done!", "Folder deleted successfully");
    }
  }, [isDeleteProjectFolderSuccess]);
  useEffect(() => {
    if (isDeleteProjectFolderError) {
      PopUpAlert("error", "Error!", "An error has occurred, try again later.");
    }
  }, [isDeleteProjectFolderError]);
  const [
    deleteDocument,
    {
      isSuccess: isDeleteDocumentSuccess,
      isError: isDeleteDocumentError,
      isLoading: isDeleteDocumentLoading,
    },
  ] = useDeleteDocumentMutation();
  useEffect(() => {
    if (isDeleteDocumentSuccess) {
      PopUpAlert("success", "Done!", "File deleted successfully");
    }
  }, [isDeleteDocumentSuccess]);
  useEffect(() => {
    if (isDeleteDocumentError) {
      PopUpAlert("error", "Error!", "An error has occurred, try again later.");
    }
  }, [isDeleteDocumentError]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleCloseDelete = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    const body = {
      ids: [rowData.id],
    };
    if (rowData.isFolder) {
      await deleteProjectFolder(body).unwrap();
    } else {
      await deleteDocument(body).unwrap();
    }
    setDeleteModalOpen(false);
    dispatch(setNeedReload(true));
  };

  const [moveModalOpen, setMoveModalOpen] = useState(false);
  const handleCloseMove = () => {
    setMoveModalOpen(false);
  };

  useEffect(() => {
    if (rowData.tableData.id === 0) {
      if (
        (tourActive &&
          (stepIndex === 0 || stepIndex === 1) &&
          (currentGuideMe === "docs-move-to" ||
            currentGuideMe === "docs-file-info")) ||
        (stepIndex >= 5 && stepIndex <= 9 && currentGuideMe === "docs-actions")
      ) {
        setAnchorEl(moreActions.current);
        setOpen(true);
      } else {
        setAnchorEl(null);
        setOpen(false);
      }

      if (
        tourActive &&
        stepIndex === 9 &&
        !run &&
        currentGuideMe === "docs-actions"
      ) {
        setTimeout(() => {
          setState({ run: true });
        }, 500);
      }
    }
  }, [
    tourActive,
    stepIndex,
    steps,
    moreActions.current,
    currentGuideMe,
    rowData,
  ]);

  useEffect(() => {
    if (rowData.tableData.id === 0) {
      if (
        tourActive &&
        stepIndex === 1 &&
        currentGuideMe === "docs-file-info" &&
        moreActions.current
      ) {
        if (!run) {
          setTimeout(() => {
            setState({ run: true });
          }, 500);
        }
      }

      if (
        tourActive &&
        stepIndex >= 2 &&
        stepIndex < steps.length - 1 &&
        currentGuideMe === "docs-move-to"
      ) {
        setMoveModalOpen(true);
        if (rowData.tableData.isFolder) {
          setActionType("folder");
        } else setActionType("file");
      }

      if (
        tourActive &&
        (steps.length - 1 === stepIndex || stepIndex < 2) &&
        currentGuideMe === "docs-move-to"
      ) {
        setMoveModalOpen(false);
      }

      if (
        tourActive &&
        stepIndex === 1 &&
        currentGuideMe === "docs-move-to" &&
        !run
      ) {
        setTimeout(() => {
          setState({ run: true });
        }, 500);
      }
    }
  }, [
    tourActive,
    stepIndex,
    moreActions.current,
    currentGuideMe,
    run,
    ,
    rowData,
  ]);

  return (
    <>
      <Tooltip
        title={tGeneral("actions")}
        data-tour="docs-actions-6"
        ref={moreActions}
      >
        <IconButton
          onClick={handleClick}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <MoreVert />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* info */}
        <MenuItem
          onClick={() => navigate(`./${rowData.id}`)}
          data-tour="docs-actions-7"
        >
          <ListItemIcon>
            <Info fontSize="small" />
          </ListItemIcon>
          {tGeneral("info")}
        </MenuItem>

        <Can I="move" a="documents">
          {/* move */}
          <MenuItem
            onClick={() => {
              setMoveModalOpen(true);
              if (rowData.isFolder) {
                setActionType("folder");
              } else setActionType("file");
            }}
            data-tour="docs-actions-8"
          >
            <ListItemIcon>
              <DriveFileMove fontSize="small" />
            </ListItemIcon>
            {tGeneral("move")}
          </MenuItem>
        </Can>

        <Can I="copy" a="documents">
          {/* copy */}
          <MenuItem onClick={handleClose} disabled data-tour="docs-actions-9">
            <ListItemIcon>
              <FileCopy fontSize="small" />
            </ListItemIcon>
            {tGeneral("copy")}
          </MenuItem>
        </Can>

        <Can I="delete" a="documents">
          {/* delete */}
          <MenuItem
            onClick={() => {
              setDeleteModalOpen(true);
              if (rowData.isFolder) {
                setActionType("folder");
              } else setActionType("file");
            }}
            data-tour="docs-actions-10"
          >
            <ListItemIcon>
              <Delete fontSize="small" />
            </ListItemIcon>
            {tGeneral("delete")}
          </MenuItem>
        </Can>
      </Menu>
      <LoadingOverlay
        open={isDeleteDocumentLoading || isDeleteProjectFolderLoading}
      />
      {deleteModalOpen && (
        <DeleteModal
          isOpen={deleteModalOpen}
          onClose={handleCloseDelete}
          handleDelete={handleDelete}
          action={actionType}
        />
      )}
      {moveModalOpen && (
        <MoveModal
          isOpen={moveModalOpen}
          projectName={project?.projectName}
          onClose={handleCloseMove}
          object={rowData}
          action={actionType}
          path={path}
        />
      )}
    </>
  );
}
