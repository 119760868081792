const extractFileName = (input) => {
  // Regular expression to capture the file name, with or without timestamp
  const pattern = /^(\d+_)?(?:\d{8}T\d{9}Z___)?(.*)$/;

  const result = input.replace(pattern, (match, p1, p2) => {
    return (p1 || "") + p2.trim();
  });
  return result;
};

export default extractFileName;
