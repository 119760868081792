export const tour = {
  // topbar
  guideMe: "ガイド",
  support: "サポート",
  meeting: "ミーティング",
  workflow: "ワークフロー",
  // guide me buttons
  addButtonTour: "追加",
  viewButtonTour: "表示",
  editButtonTour: "編集",
  recycleButtonTour: "リサイクル",
  exportButtonTour: "エクスポート",
  settingsButtonTour: "設定",
  deleteButtonTour: "削除",
  changeRoleButtonTour: "役割変更",
  // docs
  docsAddFileButtonTour: "ファイル追加",
  docsAddFolderButtonTour: "フォルダ追加",
  docsFileInfoButtonTour: "ファイル情報",
  docsMoveToButtonTour: "移動",
  docsActionsButtonTour: "アクション",
  // more
  obligatory: "必須",
  congratulations: "おめでとうございます！",
  moreTitle: "その他のオプション",
  moreDescription: "ここでは追加のオプションを見つけることができます。",
};
