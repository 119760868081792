import {
  Add,
  Check,
  ChevronRight,
  Close,
  Delete,
  ExpandMore,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import { TooltipIconButton } from "../../../shared";

function LocationColumn({
  locations,
  selectedIds,
  tier,
  onAdd,
  onDelete,
  onSelect,
  showAdd = true,
}) {
  const { t: tGeneral } = useTranslation("general");
  const [name, setName] = useState(null);

  const handleAdd = () => {
    onAdd(tier, name);
    setName(null);
  };
  return (
    <Box flex="1" height="400px" maxHeight="400px" sx={{ overflowY: "auto" }}>
      {showAdd &&
        (name !== null ? (
          <TextField
            fullWidth
            value={name}
            size="small"
            onChange={(e) => setName(e.target.value)}
            InputProps={{
              endAdornment: (
                <Box display="flex">
                  <TooltipIconButton
                    icon={<Close />}
                    label={tGeneral("cancel")}
                    onClick={() => setName(null)}
                    color="gray"
                  />
                  <TooltipIconButton
                    icon={<Check />}
                    label={tGeneral("cancel")}
                    onClick={handleAdd}
                  />
                </Box>
              ),
            }}
          />
        ) : (
          <Button variant="text" onClick={() => setName("")}>
            {tGeneral("add")} <Add />{" "}
          </Button>
        ))}
      {locations.map((location) => (
        <Box
          display="flex"
          sx={{
            cursor: "pointer",
            bgcolor:
              selectedIds.indexOf(location.id) > -1 ? "#f7e4b2" : "white",
            borderRadius: "1rem",
            px: "1rem",
          }}
          alignItems="center"
          component="div"
          onClick={() => onSelect(location.id, location.locationType)}
        >
          <Typography flex={1}>{location.locationName}</Typography>
          <TooltipIconButton
            label={tGeneral("delete")}
            icon={<Delete />}
            color="red"
            onClick={(e) => onDelete(location.id, e)}
          />
          {tier !== "tier 3" && <ChevronRight />}
        </Box>
      ))}
    </Box>
  );
}

function CreateProjectLocations() {
  const { t: tGeneral } = useTranslation("general");
  const [idTierParent, setIdTierParent] = useState(null);
  const [idSubTier, setIdSubTier] = useState(null);
  const { control, watch, setValue } = useFormContext();
  const { append } = useFieldArray({ control, name: "locations" });
  const locations = watch("locations");

  const [name, setName] = useState(null);
  const handleSelectLocation = (idLocation, locationType) => {
    if (locationType === "tier 1") {
      setIdTierParent((prev) => (prev === idLocation ? null : idLocation));
      return;
    }
    if (locationType === "tier 2") {
      setIdSubTier((prev) => (prev === idLocation ? null : idLocation));
    }
  };

  const handleAddLocation = (tier, name) => {
    let superLocation = null;
    if (tier === "tier 2") superLocation = idTierParent;
    if (tier === "tier 3") superLocation = idSubTier;
    append({
      id: v4(),
      locationName: name,
      locationType: tier,
      superLocation,
    });

    setName(null);
  };

  const handleDeleteLocation = (idLocation, e) => {
    e.stopPropagation();
    const parentIds = [
      idLocation,
      ...locations.map((loc) => loc.superLocation === idLocation),
    ];
    if (idSubTier === idLocation) setIdSubTier(null);
    if (idTierParent === idLocation) setIdTierParent(null);
    setValue(
      "locations",
      locations.filter(
        (loc) =>
          loc.id !== idLocation && parentIds.indexOf(loc.superLocation) === -1,
      ),
    );
  };

  return (
    <Accordion sx={{ border: "1px solid #bfbfbf" }}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        {tGeneral("locations")}
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" columnGap="0.5rem">
          <LocationColumn
            locations={locations.filter(
              (location) => location.superLocation === null,
            )}
            selectedIds={[idSubTier, idTierParent]}
            onAdd={handleAddLocation}
            onDelete={handleDeleteLocation}
            onSelect={handleSelectLocation}
            tier="tier 1"
          />
          <Divider orientation="vertical" flexItem />
          <LocationColumn
            locations={
              idTierParent !== null
                ? locations.filter(
                    (location) => location.superLocation == idTierParent,
                  )
                : []
            }
            showAdd={idTierParent != null}
            selectedIds={[idSubTier, idTierParent]}
            onAdd={handleAddLocation}
            onDelete={handleDeleteLocation}
            onSelect={handleSelectLocation}
            tier="tier 2"
          />
          <Divider orientation="vertical" flexItem />
          <LocationColumn
            showAdd={idSubTier !== null}
            locations={
              idSubTier !== null
                ? locations.filter(
                    (location) => location.superLocation == idSubTier,
                  )
                : []
            }
            selectedIds={[idSubTier, idTierParent]}
            onAdd={handleAddLocation}
            onDelete={handleDeleteLocation}
            onSelect={handleSelectLocation}
            tier="tier 3"
          />
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export { CreateProjectLocations };
