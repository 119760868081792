export const enterprise = {
  construction: "Construcción",
  realState: "Bienes raíces",
  architect: "Arquitectura",
  contractor: "Contratista",
  subcontractor: "Subcontratista",
  maintenance: "Mantenimiento",
  developer: "Desarrolladora",
  generalContractor: "Contratista general",
  other: "Otro",
};
