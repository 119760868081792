import {
  Add,
  ContactSupport,
  Delete,
  Edit,
  Settings,
} from "@mui/icons-material";
import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded"; // workflow
import GroupsIcon from "@mui/icons-material/Groups"; // meeting
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded"; // guide me
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
// Calendly
// Youtube player
// Tutorial del modulo
// React router
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

// Icons
import {
  useGetRolesFromEnterprisesQuery,
  useGetUserProjectsByRoleQuery,
} from "../../features/enterprise/enterpriseApiSlice";
import { selectCurrentEnterprise } from "../../features/enterprise/enterpriseSlice";
import { selectLocale } from "../../features/preferences/preferencesSlice";
import { setCurrentGuideMe } from "../../features/project/projectSlice";
import RolesEN from "../../assets/workflow/Roles_en.png";
import RolesES from "../../assets/workflow/Roles_es.png";
import { useGuideMeModal } from "./hooks/useGuideMeModal";
// Support Menu
import { SupportContent } from "./components/SupportContent";
import { SupportListItem } from "./components/SupportListItem";
import { SupportMenu } from "./components/SupportMenu";
import { SupportMenuItemHeader } from "./components/SupportMenuItemHeader";
import { SupportMenuModalGuideMe } from "./components/modals/SupportMenuModalGuideMe";
import { SupportMenuModalMetting } from "./components/modals/SupportMenuModalMetting";
import { SupportMenuModalWorkflow } from "./components/modals/SupportMenuModalWorkflow";
import { useRoleSteps } from "./components/steps/roles";

function SupportButtonRoles() {
  const dispatch = useDispatch();
  const { t: translation } = useTranslation("roles");
  const { t: tTour } = useTranslation("tour");
  const [recordsLength, setRecordsLength] = useState(0);
  const locale = useSelector(selectLocale);
  const [imgWorkflow] = useState(locale === "en" ? RolesEN : RolesES);
  const currentEnterprise = useSelector(selectCurrentEnterprise);

  const { data: records, isSuccess } = useGetRolesFromEnterprisesQuery(
    currentEnterprise?.id,
  );
  const { data: users, isSuccess: getUsersIsSucess } =
    useGetUserProjectsByRoleQuery(currentEnterprise?.id);

  // Support button (make component)
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [anchorSupp, setAnchorSupp] = useState(null);
  // For open
  const handleClick = (event) => {
    setAnchorSupp(event.currentTarget);
    setMenuIsOpen(true);
  };
  // For close
  const handleClose = () => {
    setAnchorSupp(null);
    setMenuIsOpen(false);
  };
  // End support button

  // Meeting modal
  const [meetingModalIsOpened, setMeetingModalIsOpened] = useState(false);
  const openMeetingModal = () => setMeetingModalIsOpened(true);
  const closeMeetingModal = () => setMeetingModalIsOpened(false);
  // Workflow modal
  const [workflowModalIsOpened, setWorkflowModalIsOpened] = useState(false);
  const openWorkflowModal = () => setWorkflowModalIsOpened(true);
  const closeWorkflowModal = () => setWorkflowModalIsOpened(false);
  // guide me modal
  const [guideModalIsOpened, setGuideModalIsOpened] = useState(false);
  const openGuideModal = () => setGuideModalIsOpened(true);
  const closeGuideModal = () => setGuideModalIsOpened(false);

  const [{ addSteps, editSteps, deleteSteps, settingsSteps }] = useRoleSteps();

  const [{ handleGuideMe }] = useGuideMeModal({
    addSteps,
    editSteps,
    deleteSteps,
    settingsSteps,
  });

  useEffect(() => {
    if (records && isSuccess) {
      setRecordsLength(records.length);
    }
  }, [records, isSuccess]);

  return (
    <div>
      {/* Button */}
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={translation("support")}
      >
        <IconButton
          id="support-button"
          aria-controls={menuIsOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={menuIsOpen ? "true" : undefined}
          sx={{ color: "#007FFF" }}
          onClick={handleClick}
        >
          <ContactSupport />
        </IconButton>
      </Tooltip>

      <SupportMenu anchor={anchorSupp} open={menuIsOpen} onClose={handleClose}>
        {/* Meeting */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openMeetingModal();
          }}
        >
          <SupportMenuItemHeader title={tTour("meeting")}>
            <GroupsIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalMetting
          open={meetingModalIsOpened}
          onClose={closeMeetingModal}
        />
        {/* Workflow */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openWorkflowModal();
          }}
        >
          <SupportMenuItemHeader title={translation("workflow")}>
            <AppRegistrationRoundedIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalWorkflow
          open={workflowModalIsOpened}
          diagramImageUrl={imgWorkflow}
          onClose={closeWorkflowModal}
        />

        {/* Guide Me */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openGuideModal();
          }}
        >
          <SupportMenuItemHeader title={translation("guideMe")}>
            <TouchAppRoundedIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalGuideMe
          title={`${translation("guideMe")} ${translation("moduleElement")}`}
          open={guideModalIsOpened}
          onClose={closeGuideModal}
        >
          <SupportContent>
            {/* ADD */}
            <SupportListItem
              can="add"
              moduleName="roles"
              onClick={() => {
                handleGuideMe("add");
                dispatch(setCurrentGuideMe("roles-add"));
                closeGuideModal();
              }}
            >
              <Add sx={{ marginRight: 1 }} />
              {`${translation("addButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* EDIT */}
            <SupportListItem
              can="edit"
              moduleName="roles"
              onClick={() => {
                handleGuideMe("edit");
                dispatch(setCurrentGuideMe("roles-edit"));
                closeGuideModal();
              }}
              disabled={recordsLength === 0}
            >
              <Edit sx={{ marginRight: 1 }} />
              {`${translation("editButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* DELETE */}
            <SupportListItem
              can="delete"
              moduleName="roles"
              onClick={() => {
                handleGuideMe("delete");
                dispatch(setCurrentGuideMe("roles-delete"));
                closeGuideModal();
              }}
              disabled={recordsLength === 0}
            >
              <Delete sx={{ marginRight: 1 }} />
              {`${translation("deleteButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* SETTINGS */}
            <SupportListItem
              can="settings"
              moduleName="roles"
              onClick={() => {
                handleGuideMe("settings");
                dispatch(setCurrentGuideMe("roles-settings"));
                closeGuideModal();
              }}
            >
              <Settings sx={{ marginRight: 1 }} />
              {`${translation("changeRoleButtonTour")}`}
            </SupportListItem>
          </SupportContent>
        </SupportMenuModalGuideMe>
      </SupportMenu>
    </div>
  );
}

export { SupportButtonRoles };
