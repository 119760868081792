import { Button } from "@mui/material";
import Can from "../../../utils/can";

const SupportListItem = ({
  children,
  moduleName,
  onClick,
  disabled,
  can = "add",
}) => {
  return (
    <>
      <Can I={can} a={`${moduleName}`}>
        <Button
          variant="contained"
          color="primary"
          onClick={onClick}
          fullWidth
          disabled={disabled}
          sx={{ py: "1rem" }}
        >
          {children}
        </Button>
      </Can>
    </>
  );
};

export { SupportListItem };
