import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
// MUI Icons
// Custom Components
// import HelpIcon from '@mui/icons-material/Help';
// import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
// Icons
import { ContactSupport } from "@mui/icons-material";
import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded"; // workflow
import CloseIcon from "@mui/icons-material/Close";
import GroupsIcon from "@mui/icons-material/Groups"; // meeting
import PlayCircleIcon from "@mui/icons-material/PlayCircle"; // video
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded"; // guide me
import { ListItemIcon } from "@mui/material";
import Modal from "@mui/material/Modal";
// Calendly
import { InlineWidget } from "react-calendly";
// Youtube player
import YouTube from "react-youtube";

// Tutorial del modulo
import { useTour } from "@reactour/tour";

import Documents from "../assets/workflow/Documents.png";
import Rfis from "../assets/workflow/Rfis.png";

function SupportButton({ sx = {}, moduleName }) {
  // Support button (make component)
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorSupp, setAnchorSupp] = useState(null);

  const getWorkflow = () => {
    if (moduleName === "documents") {
      return Documents;
    }
    if (moduleName === "rfis") {
      return Rfis;
    }
    return Rfis;
  };
  // For open
  const handleClick = (event) => {
    setAnchorSupp(event.currentTarget);
    setOpenMenu(true);
  };
  // For close
  const handleClose = () => {
    setAnchorSupp(null);
    setOpenMenu(false);
  };
  // End support button

  // Modal css
  const closeImg = {
    cursor: "pointer",
    float: "left",
    marginTop: "5px",
    width: "20px",
  };

  // Calendly modal
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // Video modal
  const [openTextModal, setOpenTextModal] = React.useState(false);
  const handleOpenTextModal = () => setOpenTextModal(true);
  const handleCloseTextModal = () => setOpenTextModal(false);

  // Workflow modal
  const [openImgModal, setOpenImgModal] = React.useState(false);
  const handleOpenImgModal = () => {
    setOpenImgModal(true);
  };
  const handleCloseImgModal = () => {
    setOpenImgModal(false);
  };

  // Modal style
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "800px",
    transform: "translate(-50%, -50%)",
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    p: 0,
  };
  // End modal

  // Modal style img
  const modalStyleImage = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "90%",
    transform: "translate(-50%, -50%)",
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    p: 0,
  };
  // End modal

  // Youtube video style
  const YoutubeOpts = {
    width: "100%",
    height: "425",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };
  // End Youtube video

  // Tutorial Guide Me
  const {
    setIsOpen,
    // setCurrentStep,
    // currentStep,
    // isOpen,
    setSteps,
    // setIsOpen,
  } = useTour();

  return (
    <div>
      {/* Button */}
      <Tooltip disableFocusListener disableTouchListener title="Support">
        <IconButton
          id="support-button"
          aria-controls={openMenu ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? "true" : undefined}
          sx={[{ color: "#007FFF" }, sx]}
          onClick={handleClick}
        >
          <ContactSupport />
        </IconButton>
      </Tooltip>

      <Menu
        id="basic-menu"
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorEl={anchorSupp}
        open={openMenu}
        onClose={handleClose}
        keepMounted
        sx={{ borderRadius: 105 }}
      >
        {/* Meeting */}
        <MenuItem onClick={handleOpenTextModal}>
          <ListItemIcon>
            <GroupsIcon sx={{ color: "#000000" }} />
          </ListItemIcon>
          <Typography textAlign="center">Meeting</Typography>
        </MenuItem>
        <Modal open={openTextModal} onClose={handleCloseTextModal}>
          <Box>
            <IconButton
              aria-label="close"
              onClick={handleCloseTextModal}
              sx={{
                position: "absolute",
                right: 15,
                top: 15,
              }}
            >
              <CloseIcon
                style={{
                  backgroundColor: "red",
                  borderRadius: "50%",
                  padding: 1,
                  color: "white",
                }}
              />
            </IconButton>
            <InlineWidget
              url="https://calendly.com/buildpeersupport"
              styles={{
                height: "100vh",
              }}
            />
          </Box>
        </Modal>

        {/* Video */}
        <MenuItem onClick={handleOpenModal}>
          <ListItemIcon>
            <PlayCircleIcon sx={{ color: "#000000" }} />
          </ListItemIcon>
          <Typography textAlign="center">Video</Typography>
        </MenuItem>
        <Modal open={openModal} onClose={handleCloseModal} closeAfterTransition>
          <Box sx={modalStyle}>
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                position: "absolute",
                right: 4,
                top: -4,
                width: 25,
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
            <YouTube videoId="w3_5x-8PVqw" opts={YoutubeOpts} />
          </Box>
        </Modal>

        {/* Workflow */}
        <MenuItem onClick={handleOpenImgModal}>
          <ListItemIcon>
            <AppRegistrationRoundedIcon sx={{ color: "#000000" }} />
          </ListItemIcon>
          <Typography textAlign="center">Workflow</Typography>
        </MenuItem>
        <Modal
          open={openImgModal}
          onClose={handleCloseImgModal}
          closeAfterTransition
        >
          <Box sx={modalStyleImage}>
            <IconButton
              aria-label="close"
              onClick={handleCloseImgModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
            <img
              src={getWorkflow()}
              alt="workflow"
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </Box>
        </Modal>

        {/* Guide Me */}
        <MenuItem
          // onClick={() => setIsOpen(true)}
          onClick={() => {
            handleClose();
            // setIsOpen(true);
          }}
        >
          <ListItemIcon>
            <TouchAppRoundedIcon sx={{ color: "#000000" }} />
          </ListItemIcon>
          <Typography textAlign="center">Guide me</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default SupportButton;
