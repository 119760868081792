import { ArrowBack, Check, Save } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TooltipIconButton } from "./TooltipIconButton";

function FormHeader({
  title,
  onBack,
  onDone,
  isEditing,
  backIcon,
  isSubmit = false,
  isDoneDisabled = false,
  dataTourNameButton = "",
  isLoading = false,
}) {
  const { t: tGeneral } = useTranslation("general");
  return (
    <Box
      display="flex"
      px="10px"
      borderBottom="1px solid #FBCB04"
      alignItems="center"
      minHeight="60px"
      maxHeight="60px"
    >
      <IconButton onClick={() => onBack?.()}>
        {backIcon ?? <ArrowBack />}
      </IconButton>
      <Typography variant="h4">{title}</Typography>
      <Box display="flex" flex={1} justifyContent="flex-end">
        <TooltipIconButton
          disabled={isDoneDisabled}
          label={isEditing ? tGeneral("save") : tGeneral("create")}
          icon={isEditing ? <Save /> : <Check />}
          onClick={() => onDone?.()}
          color="#FBCB04"
          type={isSubmit ? "submit" : "button"}
          data-tour={dataTourNameButton}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
}

export { FormHeader };
