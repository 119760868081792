import { Search } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useGetProjectUsersQuery } from "../../features/project/projectApiSlice";
import BubbleAvatarDeletable from "../userAvatars/BubbleAvatarDeletable";
import UserAvatar from "../userAvatars/UserAvatar";
import { verifyEmail } from "../../utils";

function SendToEmail({ isOpen, title, onClose, onSend, isLoading }) {
  const { idProject } = useParams();

  const isMobile = useMediaQuery("(max-width:820px)");
  const { t: tGeneral } = useTranslation("general");

  const [distributionList, setDistributionList] = useState([]);
  const [message, setMessage] = useState("");
  const [cc, setCc] = useState("");
  const [ccMails, setCcMails] = useState([]);
  const [ccError, setCcError] = useState(null);

  const { data: projectUsers } = useGetProjectUsersQuery(idProject);

  const mapUserItems = (user) => ({
    ...user,
    label: `${user.user?.firstName} ${user.user?.lastName}`,
    id: user.user?.id,
    name: `${user.user?.firstName} ${user.user?.lastName}`,
    urlAvatar: user.user?.urlAvatar,
    email: user?.user?.email,
  });

  const optionUsers = useMemo(() => {
    if (projectUsers) {
      return projectUsers.map(mapUserItems);
    }
    return [];
  }, [projectUsers]);

  const handleDeleteUser = (user) => {
    const newDistributionList = [...distributionList].filter(
      (selectedUser) => selectedUser.id !== user.id,
    );
    setDistributionList(newDistributionList);
  };

  const isSendDisabled =
    (distributionList.length === 0 && ccMails.length === 0) || message === "";
  const handleAddCc = (cc) => {
    if (ccMails.indexOf(cc) > -1) {
      setCc("");
      return;
    }
    if (verifyEmail(cc)) {
      setCcMails((prev) => [...prev, cc]);
    } else {
      setCcError(tGeneral("invalidEmail"));
    }
    setCc("");
  };

  const handleSetCc = ({ target: { value } }) => {
    setCcError(null);
    if (!value.match(/;/)) {
      setCc(value);
      return;
    }
    const newCc = value.replace(";", "");
    handleAddCc(newCc);
  };

  const handleDeleteCc = (mail) => {
    const newCcMails = ccMails.filter((m) => m !== mail);
    setCcMails(newCcMails);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Card
        sx={{
          p: "26px 40px",
          borderRadius: "20px",
          width: isMobile ? "95%" : "30%",
          height: "85%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography fontWeight="bold">{tGeneral("sendToEmail")}</Typography>
        <Typography>{title}</Typography>
        <Divider sx={{ mt: "16px" }} />
        <Box
          flex={1}
          sx={{ maxHeight: "70%", overflowY: "scroll", py: "16px" }}
        >
          <Autocomplete
            multiple
            options={optionUsers}
            disableCloseOnSelect
            value={distributionList}
            isOptionEqualToValue={(opt, val) => val.id === opt.id}
            onChange={(e, val) => setDistributionList(val)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} />
                <UserAvatar name={option.label} url={option.urlAvatar} />
                <Typography>{option.label}</Typography>
              </li>
            )}
            renderInput={(params) => (
              <Box ref={params.InputProps.ref}>
                <TextField
                  fullWidth
                  label={tGeneral("distributionList")}
                  sx={{ padding: 0, height: "55px" }}
                  inputProps={{...params.inputProps}}
                  InputProps={{
                    endAdornment: <Search />,
                    sx: { height: "55px", padding: "14px !important" },
                  }}
                />
              </Box>
            )}
          />
          <Box
            sx={{
              overflow: "auto",
              my: "8px",
              height: "100px",
              maxHeight: "100px",
            }}
          >
            {distributionList.length ? (
              distributionList?.map((user) => (
                <BubbleAvatarDeletable
                  key={user.id}
                  user={user}
                  editable // To show delete button
                  setValues={() => {
                    handleDeleteUser(user);
                  }}
                />
              ))
            ) : (
              <Typography
                fontSize="1rem"
                fontWeight="medium"
                textAlign="center"
              >
                {tGeneral("noUsersSelected")}
              </Typography>
            )}
          </Box>
          <TextField
            sx={{ mt: "8px" }}
            fullWidth
            placeholder="CC"
            value={cc}
            onChange={handleSetCc}
            error={Boolean(ccError)}
            helperText={ccError ?? ""}
            onKeyDownCapture={({ key, target: { value } }) => {
              if (key === "Enter") {
                handleAddCc(value);
              }
            }}
          />
          <Box
            height="50px"
            display="flex"
            alignItems="center"
            sx={{ overflowX: "auto" }}
            columnGap="5px"
          >
            {ccMails.map((mail) => (
              <Chip
                key={mail}
                label={mail}
                onDelete={() => handleDeleteCc(mail)}
              />
            ))}
          </Box>
          <TextField
            sx={{ mt: "16px" }}
            fullWidth
            placeholder={tGeneral("message")}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows={5}
            multiline
            maxRows={5}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" columnGap="16px">
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={onClose}
            sx={{ py: "16px" }}
            disabled={isLoading}
          >
            {tGeneral("cancel")}
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{ py: "16px" }}
            onClick={() => {
              const selectedUsers = distributionList.map((user) => user.email);
              onSend(message, title, selectedUsers, ccMails);
            }}
            disabled={isLoading || isSendDisabled}
          >
            {isLoading ? (
              <CircularProgress color="primary" />
            ) : (
              tGeneral("send")
            )}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}

export default SendToEmail;
