//caslContext.js
import { defineAbility } from "@casl/ability";
import { createContext } from "react";

const CaslContext = createContext(
  defineAbility((can) => {
    can("read", "all");
  }),
);

export default CaslContext;
