import { ExpandMore, Search } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Checkbox,
  TextField,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { useGetActiveProjectUsersQuery } from "../../../../../features/project/projectApiSlice";

function ProjectUsersFilter({ value, onChange, title, icon }) {
  const { idProject } = useParams();

  const [search, setSearch] = useState("");

  const { data } = useGetActiveProjectUsersQuery(
    { idProject, moduleName: "daily_reports" },
    { skip: !idProject },
  );

  const users = useMemo(() => {
    if (data) {
      return data
        .map(({ id, firstName, lastName, urlAvatar }) => ({
          id,
          name: `${firstName} ${lastName}`,
          urlAvatar,
        }))
        .filter((user) =>
          user.name.toLowerCase().includes(search.toLowerCase()),
        );
    }
    return [];
  }, [data, search]);

  const handleSelectUser = (add, id) => {
    if (add) {
      onChange([...value, id]);
    } else {
      onChange(value.filter((userId) => userId !== id));
    }
  };

  return (
    <Accordion variant="outlined" sx={{ border: "none" }}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box display="flex" alignItems="center" gap={1}>
          {icon}
          <Typography height="20px">{title}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          InputProps={{ startAdornment: <Search sx={{ color: "#E6B60C" }} /> }}
          value={search}
          size="small"
          fullWidth
          onChange={(e) => setSearch(e.target.value)}
        />
        <Box
          display="flex"
          flexDirection="column"
          rowGap="0.5rem"
          maxHeight="400px"
          sx={{ overflowY: "auto", mt: "0.5rem" }}
        >
          {users.map((user) => (
            <Box
              display="flex"
              key={user.id}
              alignItems="center"
              columnGap="0.5rem"
            >
              <Checkbox
                checked={value.indexOf(user.id) > -1}
                onChange={(e) => handleSelectUser(e.target.checked, user.id)}
              />
              <Avatar src={user.urlAvatar} sx={{ backgroundColor: "#E6B60C" }}>
                {user.name
                  .split(" ")
                  .map((name) => name[0])
                  .join("")}
              </Avatar>
              <Typography>{user.name}</Typography>
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export { ProjectUsersFilter };
