export const weather = {
  "thunderstorm-200": "轻度雷暴",
  "thunderstorm-201": "雷暴",
  "thunderstorm-202": "强烈雷暴",
  "thunderstorm-210": "轻度雷雨",
  "thunderstorm-211": "雷雨",
  "thunderstorm-212": "强烈雷雨",
  "thunderstorm-221": "狂暴雷雨",
  "thunderstorm-230": "轻度雷雨淋浴",
  "thunderstorm-231": "雷雨淋浴",
  "thunderstorm-232": "强烈雷雨淋浴",
  "drizzle-300": "轻度毛毛雨",
  "drizzle-301": "毛毛雨",
  "drizzle-302": "强烈毛毛雨",
  "drizzle-310": "轻度毛毛雨伴有雨",
  "drizzle-311": "毛毛雨伴有雨",
  "drizzle-312": "强烈毛毛雨伴有雨",
  "drizzle-313": "间歇性雨和毛毛雨",
  "drizzle-314": "间歇性强烈雨和毛毛雨",
  "drizzle-321": "间歇性毛毛雨",
  "rain-500": "小雨",
  "rain-501": "中雨",
  "rain-502": "大雨",
  "rain-503": "暴雨",
  "rain-504": "极端暴雨",
  "rain-511": "冻雨",
  "rain-520": "间歇性小雨",
  "rain-521": "间歇性雨",
  "rain-522": "间歇性大雨",
  "rain-531": "间歇性狂暴雨",
  "snow-600": "小雪",
  "snow-601": "雪",
  "snow-602": "大雪",
  "snow-611": "雨夹雪",
  "snow-612": "轻度雨夹雪",
  "snow-613": "雨夹雪",
  "snow-615": "小雨和雪",
  "snow-616": "雨和雪",
  "snow-620": "间歇性小雪",
  "snow-621": "间歇性雪",
  "snow-622": "间歇性大雪",
  "mist-701": "薄雾",
  "smoke-711": "烟雾",
  "haze-721": "霾",
  "dust-731": "沙尘漩涡",
  "fog-741": "雾",
  "sand-751": "沙尘",
  "dust-761": "尘土",
  "ash-762": "火山灰",
  "squall-771": "狂风",
  "tornado-781": "龙卷风",
  "clear-800": "晴朗",
  "clouds-801": "少云",
  "clouds-802": "多云",
  "clouds-803": "阴云",
  "clouds-804": "阴天",
  sensation: "体感温度",
};
