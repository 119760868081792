import {
  Autocomplete,
  CircularProgress,
  TextField,
  createFilterOptions,
} from "@mui/material";

const filter = createFilterOptions();

function AutocompleteInput({
  options,
  value,
  onChange,
  onCreate,
  isLoading,
  label = "",
  startAdornment = null,
  ...autocompleteProps
}) {
  if (!options) return null;

  return (
    <Autocomplete
      value={
        value ? options.find((option) => option?.value === value)?.label : null
      }
      size="small"
      loading={isLoading}
      disabled={isLoading}
      onChange={(event, newValue) => {
        if (typeof newValue === "string") {
          onChange(newValue);
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          onCreate(newValue.inputValue);
        } else {
          onChange(newValue?.value);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some(
          (option) => inputValue === option.label,
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            label: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.label;
      }}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ backgroundColor: "white" }}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: isLoading ? (
              <CircularProgress size={15} />
            ) : (
              params.InputProps.endAdornment
            ),
            startAdornment,
          }}
        />
      )}
      {...autocompleteProps}
    />
  );
}

export { AutocompleteInput };
