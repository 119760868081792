import { Search } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDistributionList } from "../../hooks";
import BubbleAvatarDeletable from "../userAvatars/BubbleAvatarDeletable";
import UserAvatar from "../userAvatars/UserAvatar";

function DistributionList({
  idProject,
  distributionListName,
  onCancel,
  moduleName,
}) {
  const { t: tGeneral } = useTranslation("general");

  const {
    projectUsers: users,
    getSelectedUsers,
    handleUpdateConfig,
  } = useDistributionList(idProject, moduleName);
  const selectedUsers = getSelectedUsers(distributionListName);
  const [usersAttached, setUsersAttached] = useState(false);
  const [distributionList, setDistributionList] = useState([]);

  useEffect(() => {
    if (selectedUsers?.length > 0 && !usersAttached) {
      setDistributionList(
        selectedUsers.map((user) => ({
          ...user.user,
          name: `${user.user.firstName} ${user.user.lastName}`,
        })),
      );
      setUsersAttached(true);
    }
  }, [selectedUsers, usersAttached]);

  const handleOnDelete = (val) => {
    setDistributionList((prev) => prev.filter((user) => user.id !== val));
  };

  const handleOnSave = async () => {
    await handleUpdateConfig(
      distributionList.map((user) => user.id),
      "drawingDistributionList",
    );
  };

  const mapUserItems = (user) => ({
    label: `${user.user?.firstName} ${user.user?.lastName}`,
    id: user.user?.id,
    name: `${user.user?.firstName} ${user.user?.lastName}`,
    urlAvatar: user.user?.urlAvatar,
  });

  const projectUsers = useMemo(() => {
    if (users) {
      return users.map(mapUserItems);
    }
    return [];
  }, [users]);

  return (
    <Box data-tour="settings-drawings-3">
      <Autocomplete
        multiple
        options={projectUsers ?? []}
        disableCloseOnSelect
        value={distributionList}
        isOptionEqualToValue={(opt, val) => val.id === opt.id}
        onChange={(e, val) => setDistributionList(val)}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox checked={selected} />
            <UserAvatar name={option.label} url={option.urlAvatar} />
            <Typography>{option.label}</Typography>
          </li>
        )}
        renderInput={(params) => (
          <Box ref={params.InputProps.ref}>
            <TextField
              fullWidth
              label={tGeneral("distributionList")}
              sx={{ padding: 0, height: "55px" }}
              inputProps={{...params.inputProps}}
              InputProps={{
                endAdornment: <Search />,
                sx: { height: "55px", padding: "14px !important" },
              }}
            />
          </Box>
        )}
        noOptionsText={tGeneral("noOptions")}
      />
      <Box sx={{ maxHeight: "250px", overflow: "auto", mt: "16px" }}>
        {distributionList?.length ? (
          distributionList?.map((user) => (
            <BubbleAvatarDeletable
              key={user.id}
              user={user}
              editable // To show delete button
              setValues={() => {
                handleOnDelete(user.id);
              }}
            />
          ))
        ) : (
          <Typography fontSize="1rem" fontWeight="medium" textAlign="center">
            {tGeneral("noUsersSelected")}
          </Typography>
        )}
      </Box>
      <Box display="flex" columnGap="16px" mt="30px">
        <Button
          variant="contained"
          color="secondary"
          sx={{
            backgroundColor: "white",
            color: "black",
            py: "1rem",
            border: "1px solid black",
            ":hover": {
              backgroundColor: "lightgrey",
            },
          }}
          fullWidth
          onClick={onCancel}
        >
          {tGeneral("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ py: "1rem" }}
          onClick={handleOnSave}
        >
          {tGeneral("save")}
        </Button>
      </Box>
    </Box>
  );
}

export { DistributionList };
