import { Box } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  useGetActiveProjectUsersQuery,
  useGetDistributionListQuery,
} from "../../../features/project/projectApiSlice";
import { CreateFormUsersSelect } from "./CreateFormUsersSelect";

function CreateFormUsersSide({
  distributionListName,
  distributionListDataTour = "",
  lists = [],
  height = "100%",
  moduleName = "",
}) {
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { setValue } = useFormContext();
  const { data: projectUsersData } = useGetActiveProjectUsersQuery({
    idProject,
    moduleName,
  });
  const { data: distributionListData } = useGetDistributionListQuery(
    {
      idProject,
      distributionListName,
    },
    { skip: !distributionListName },
  );

  useEffect(() => {
    if (distributionListData) {
      setValue(
        "distributionList",
        distributionListData.map((user) => user.id),
      );
    }
  }, [distributionListData, setValue]);

  useEffect(() => {
    lists.forEach((list) => {
      if (list.defaultValues) {
        setValue(list.name, list.defaultValues);
      }
    });
  }, [lists, setValue]);

  const projectUsers = useMemo(() => {
    if (projectUsersData) {
      return projectUsersData;
    }
    return [];
  }, [projectUsersData]);

  return (
    <Box
      height={height}
      display="flex"
      flexDirection="column"
      overflow="hidden"
      rowGap="20px"
      bgcolor="#EEECEC"
      py="5px"
      flex={1}
    >
      {lists.map(
        ({
          label,
          name,
          dataTourName,
          required = false,
          canRemoveSelf = true,
          currentUserId = "",
        }) => (
          <CreateFormUsersSelect
            label={label}
            name={name}
            users={projectUsers}
            required={required}
            canRemoveSelf={canRemoveSelf}
            currentUserId={currentUserId}
            dataTourName={dataTourName}
            key={currentUserId}
          />
        ),
      )}
      {Boolean(distributionListName) && (
        <CreateFormUsersSelect
          label={tGeneral("distributionList")}
          name="distributionList"
          users={projectUsers}
          dataTourName={distributionListDataTour}
        />
      )}
    </Box>
  );
}

export { CreateFormUsersSide };
