import {
  Box,
  CircularProgress,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useUpdateDrawingMutation } from "../../../../../features/drawings";

function EditNameCell({ idDrawing, value }) {
  const [name, setName] = useState(value);
  const [isEditing, setIsEditing] = useState(false);

  const [updateDrawing, { isLoading }] = useUpdateDrawingMutation();

  const handleStartEditing = () => {
    if (idDrawing) {
      setIsEditing(true);
    }
  };

  const handleEdit = async () => {
    if (value !== name) {
      await updateDrawing({ idDrawing, name });
    }
    setIsEditing(false);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleEdit();
    }
  };

  if (isLoading) return <CircularProgress size={20} />;

  return isEditing ? (
    <TextField
      value={name}
      onChange={(e) => setName(e.target.value)}
      onBlur={handleEdit}
      onKeyDown={handleEnter}
      autoFocus
    />
  ) : (
    <Tooltip title={value}>
      <Typography
        noWrap
        sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        onDoubleClick={handleStartEditing}
      >
        {value}
      </Typography>
    </Tooltip>
  );
}
export { EditNameCell };
