export const projectNetwork = {
  addProjectNetwork: "프로젝트 네트워크에 추가",
  acceptInvite: "초대 수락",
  yourNewCodeIs: "새 코드는",
  generateNewCode: "새 코드 생성",
  deleteNetworkMessage:
    "이 프로젝트를 네트워크에서 삭제하시겠습니까? 모든 공유된 항목에 더 이상 접근할 수 없습니다.",
  emptyNetworkTitle: "빈 네트워크",
  emptyNetworkContent: "아직 네트워크에 프로젝트를 추가하지 않았습니다.",
  accepted: "수락됨",
  acceptedSuccessfully: "초대가 성공적으로 수락되었습니다.",
  codeCopied: "코드 복사됨",
  availableCodes: "사용 가능한 코드",
};
