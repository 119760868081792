export const weather = {
  "thunderstorm-200": "Tormenta con lluvia ligera",
  "thunderstorm-201": "Tormenta con lluvia",
  "thunderstorm-202": "Tormenta con lluvia intensa",
  "thunderstorm-210": "Tormenta ligera",
  "thunderstorm-211": "Tormenta",
  "thunderstorm-212": "Tormenta intensa",
  "thunderstorm-221": "Tormenta desgarrada",
  "thunderstorm-230": "Tormenta con llovizna ligera",
  "thunderstorm-231": "Tormenta con llovizna",
  "thunderstorm-232": "Tormenta con llovizna intensa",
  "drizzle-300": "Llovizna de intensidad ligera",
  "drizzle-301": "Llovizna",
  "drizzle-302": "Llovizna intensa",
  "drizzle-310": "Llovizna ligera con lluvia",
  "drizzle-311": "Llovizna con lluvia",
  "drizzle-312": "Llovizna intensa con lluvia",
  "drizzle-313": "Lluvia intermitente y llovizna",
  "drizzle-314": "Lluvia intensa intermitente y llovizna",
  "drizzle-321": "Llovizna intermitente",
  "rain-500": "Lluvia ligera",
  "rain-501": "Lluvia moderada",
  "rain-502": "Lluvia intensa",
  "rain-503": "Lluvia muy intensa",
  "rain-504": "Lluvia extrema",
  "rain-511": "Lluvia helada",
  "rain-520": "Lluvia ligera intermitente",
  "rain-521": "Lluvia intermitente",
  "rain-522": "Lluvia intensa intermitente",
  "rain-531": "Lluvia intermitente desgarrada",
  "snow-600": "Nieve ligera",
  "snow-601": "Nieve",
  "snow-602": "Nieve intensa",
  "snow-611": "Aguanieve",
  "snow-612": "Aguanieve ligera",
  "snow-613": "Aguanieve",
  "snow-615": "Lluvia y nieve ligera",
  "snow-616": "Lluvia y nieve",
  "snow-620": "Nieve intermitente ligera",
  "snow-621": "Nieve intermitente",
  "snow-622": "Nieve intermitente intensa",
  "mist-701": "Niebla",
  "smoke-711": "Humo",
  "haze-721": "Calina",
  "dust-731": "Remolinos de arena/polvo",
  "fog-741": "Niebla",
  "sand-751": "Arena",
  "dust-761": "Polvo",
  "ash-762": "Ceniza volcánica",
  "squall-771": "Ráfagas",
  "tornado-781": "Tornado",
  "clear-800": "Cielo despejado",
  "clouds-801": "Algunas nubes",
  "clouds-802": "Nubes dispersas",
  "clouds-803": "Nubes rotas",
  "clouds-804": "Nublado",
  "sensation": "Sensación T.",
};
