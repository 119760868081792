import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projects: [],
  current: null,
  currentProjectConfig: null,
  currentRole: null,
  currentProjectUsers: [],
  currentGuideMe: "",
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setCurrentProject: (state, action) => {
      state.current = action.payload;
    },
    resetProjects: () => initialState,
    setCurrentProjectConfig: (state, action) => {
      state.currentProjectConfig = action.payload;
    },
    setCurrentRole: (state, action) => {
      state.currentRole = action.payload;
    },
    setCurrentProjectUsers: (state, action) => {
      state.currentProjectUsers = action.payload;
    },
    setCurrentGuideMe: (state, action) => {
      state.currentGuideMe = action.payload;
    },
    setCurrentTranslation: (state, action) => {
      state.current.translation = action.payload;
    },
  },
});

export const selectAllProjects = (state) => state.project.projects;

export const selectCurrentProject = (state) => state.project.current;

export const selectCurrentProjectConfig = (state) =>
  state.project.currentProjectConfig;

export const selectCurrentRole = (state) => state.project.currentRole;

export const selectCurrentProjectUsers = (state) =>
  state.project.currentProjectUsers;

export const selectCurrentGuideMe = (state) => state.project.currentGuideMe;

export const selectCurrentTranslatable = (state) =>
  state.project.current?.translation ?? false;

export const {
  setProjects,
  setCurrentProject,
  resetProjects,
  setCurrentProjectConfig,
  setCurrentRole,
  setCurrentProjectUsers,
  setCurrentGuideMe,
  setCurrentTranslation,
} = projectSlice.actions;

export default projectSlice.reducer;
