import * as React from "react";
import { useSelector } from "react-redux";
import { selectCurrentFiltersTasks } from "../features/project/modules/tasks/tasksSlice";
import {
  useGetTasksCountersQuery,
  useGetTasksPaginatedQuery,
} from "../features/project/modules/tasks/tasksApiSlice";

const DEFAULT_PAGINATION_LIMIT = 25;

function unique(data) {
  const ids = data.map(({ id }) => id);
  return data.filter(({ id }, index) => !ids.includes(id, index + 1));
}

const usePaginatedTasks = (props) => {
  const { bActive = true } = props;

  const [idProject] = React.useState(props.idProject);
  const [status] = React.useState(props.status);
  const currentFilters = useSelector(selectCurrentFiltersTasks);

  const [total, setTotal] = React.useState(0);
  const [paginated, setPaginated] = React.useState([]);
  const [page, setPage] = React.useState(1);

  const categoryValues = React.useMemo(() => {
    if (currentFilters?.category != null) {
      return currentFilters?.category?.map((category) => category.label);
    }

    return currentFilters?.category;
  }, [currentFilters]);

  const {
    data: dataCount,
    isLoading: isLoadingCount,
    isFetching: isFetchingCount,
    refetch: refetchCount,
    isError: isErrorCount,
  } = useGetTasksCountersQuery(
    {
      bActive,
      status,
      idProject,
      assignees: currentFilters?.assignees ?? null,
      creator: currentFilters?.creator ?? null,
      category: categoryValues,
      location: currentFilters?.location ?? null,
      priority: currentFilters?.priority ?? null,
      startDate: currentFilters?.createdAfter ?? null,
      endDate: currentFilters?.createdBefore ?? null,
    },
    {
      skip: !idProject,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    },
  );

  const { data, isLoading, isFetching, refetch, isError } =
    useGetTasksPaginatedQuery(
      {
        bActive,
        status,
        idProject,
        limit: DEFAULT_PAGINATION_LIMIT,
        page,
        assignees: currentFilters?.assignees ?? null,
        creator: currentFilters?.creator ?? null,
        category: categoryValues,
        location: currentFilters?.location ?? null,
        priority: currentFilters?.priority ?? null,
        startDate: currentFilters?.createdAfter ?? null,
        endDate: currentFilters?.createdBefore ?? null,
      },
      {
        skip: !idProject,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
      },
    );

  const onEndReach = () => {
    if (isLoading || isFetching) return;
    setPage((prev) => prev + 1);
  };

  const reset = React.useCallback(() => {
    setTotal(0);
    setPaginated([]);
    setPage(1);
    refetch();
    refetchCount();
  }, [refetch, refetchCount]);

  React.useEffect(() => {
    if (currentFilters !== null && idProject) {
      const hasAtLeastOneFilter = (obj) => {
        return Object.values(obj).some((value) => {
          if (Array.isArray(value)) {
            return value.length > 0;
          }
          return value !== null && value !== undefined;
        });
      };

      // Example usage
      if (hasAtLeastOneFilter(currentFilters) || idProject) {
        reset();
      }
    }
  }, [currentFilters, idProject, reset]);

  React.useEffect(() => {
    if (data?.length > 0 && !isFetching) {
      setPaginated((prev) => unique([...prev, ...data]));
    }
  }, [data, isFetching, page]);

  React.useEffect(() => {
    if (dataCount && !isFetchingCount) {
      setTotal(dataCount?.total);
    }
  }, [dataCount, isFetchingCount]);

  const noResults =
    !isLoading && !isFetching && paginated?.length < 1 && data?.length < 1;

  const noMoreResults =
    !isLoading && !isFetching && data?.length === 0 && paginated.length > 0;

  return {
    total,
    paginated,
    noResults,
    noMoreResults,
    refetch: reset,
    onEndReach: data?.total < DEFAULT_PAGINATION_LIMIT ? null : onEndReach,
    isLoading: isLoading || isLoadingCount,
    isFetching: isFetching || isFetchingCount,
    isError: isError || isErrorCount,
  };
};

export { usePaginatedTasks };
