import { apiSlice } from "../../../api/apiSlice";

export const roleApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRole: builder.query({
      query: (idRole) => `/role/read/${idRole}`,
      keepUnusedDataFor: 60,
      providesTags: ["Roles"],
    }),
    addRole: builder.mutation({
      query: (body) => ({
        url: "role/add",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Roles", "Enterprises"],
    }),
    updateRole: builder.mutation({
      query: ({ idRole, ...body }) => ({
        url: `role/update/${idRole}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Roles", "Enterprises"],
    }),
  }),
});

export const { useGetRoleQuery, useAddRoleMutation, useUpdateRoleMutation } =
  roleApiSlice;
