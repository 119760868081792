import { Add, Close } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  useGetActiveProjectUsersQuery,
  useGetDistributionListQuery,
  useUpdateDistributionListMutation,
} from "../../features/project/projectApiSlice";
import { selectCurrentGuideMe } from "../../features/project/projectSlice";
import { PopUpAlert } from "../PopUpAlert";
import { useAppTourContext } from "../supportButton/context/context";
import BubbleAvatarDeletable from "../userAvatars/BubbleAvatarDeletable";
import UserAvatar from "../userAvatars/UserAvatar";

function ModalDistributionList({
  isOpen,
  onClose,
  distributionListName,
  moduleName = "rfis",
  isTour = false,
}) {
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");

  const { data: usersData } = useGetActiveProjectUsersQuery({
    idProject,
    moduleName,
  });

  const { data } = useGetDistributionListQuery({
    idProject,
    distributionListName,
  });
  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const [updateDistributionList, { isSuccess, isError, error, reset }] =
    useUpdateDistributionListMutation();

  const mapUserItems = (user) => ({
    label: `${user?.firstName} ${user?.lastName}`,
    id: user?.id,
    name: `${user?.firstName} ${user?.lastName}`,
    urlAvatar: user?.urlAvatar,
    email: user?.email,
  });

  const distributionList = useMemo(() => {
    if (data) {
      return data.map(mapUserItems);
    }
    return [];
  }, [data]);

  const handleOnSave = (list) => {
    updateDistributionList({
      idProject,
      distributionListName,
      users: list.map((user) => user.id),
    });
  };

  const handleOnDelete = (val) => {
    const deleteList = distributionList.filter((user) => user.id !== val.id);
    handleOnSave(deleteList);
  };

  const projectUsers = useMemo(() => {
    if (usersData) {
      return usersData.map((user) => mapUserItems(user.user));
    }
    return [];
  }, [usersData]);

  const isAllSelected = useMemo(() => {
    if (!projectUsers || !distributionList) return false;
    return projectUsers.length === distributionList.length;
  }, [projectUsers, distributionList]);

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert(
        "success",
        tGeneral("success"),
        tGeneral("projectConfigurationUpdateSuccess"),
      );
      reset();
    }
    if (isError) {
      PopUpAlert("error", "Error", error.message);
      reset();
    }
  }, [isSuccess, isError, error, tGeneral, onClose, reset]);

  const handleToggleSelectAll = () => {
    handleOnSave(projectUsers);
  };

  useEffect(() => {
    let isTourTimeout;
    if (
      tourActive &&
      (stepIndex === 2 || stepIndex === 3) &&
      (currentGuideMe === "docs-settings" ||
        currentGuideMe === "daily-reports-settings" ||
        currentGuideMe === "tasks-settings") &&
      isTour
    ) {
      isTourTimeout = setTimeout(() => {
        setState({ run: true });
      }, 1000);
    }

    return () => clearTimeout(isTourTimeout);
  }, [tourActive, stepIndex, currentGuideMe, isTour, setState]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={(theme) => ({
          height: "auto",
          borderRadius: "20px",
          p: "25px 40px",
          width: "40%",
          [theme.breakpoints.down("lg")]: {
            width: "50%",
          },
        })}
      >
        <Box display="flex" alignItems="center">
          <Typography sx={{ flex: 1 }} fontSize="1.5625rem">
            {tGeneral("distributionList")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close fontSize="1.5625rem" />
          </IconButton>
        </Box>
        <Divider sx={{ my: "20px" }} />
        <span data-tour="docs-settings-3">
          <Autocomplete
            multiple
            options={projectUsers}
            disableCloseOnSelect
            value={distributionList}
            isOptionEqualToValue={(opt, val) => val.id === opt.id}
            onChange={(e, val) => {
              handleOnSave(val);
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} />
                <UserAvatar name={option.label} url={option.urlAvatar} />
                <Typography>{option.label}</Typography>
              </li>
            )}
            renderInput={(params) => (
              <Box ref={params.InputProps.ref}>
                <TextField
                  fullWidth
                  sx={{ padding: 0, height: "55px" }}
                  inputProps = {{...params.inputProps}}
                  InputProps={{
                    startAdornment: <Add color="primary" />,
                    sx: { height: "55px", padding: "14px !important" },
                  }}
                  placeholder={tGeneral("addPeopleToDistributionList")}
                />
              </Box>
            )}
            PaperComponent={(paperProps) => {
              const { children, ...restPaperProps } = paperProps;
              return (
                <Paper {...restPaperProps}>
                  <Box
                    onMouseDown={(e) => e.preventDefault()} // prevent blur
                    pl={1.5}
                    py={0.5}
                  >
                    <FormControlLabel
                      onClick={(e) => {
                        e.preventDefault(); // prevent blur
                        handleToggleSelectAll();
                      }}
                      label={tGeneral("selectAll")}
                      control={
                        <Checkbox
                          id="select-all-checkbox"
                          checked={isAllSelected}
                        />
                      }
                    />
                  </Box>
                  <Divider />
                  {children}
                </Paper>
              );
            }}
          />
          <Box
            sx={{
              maxHeight: "250px",
              height: "250px",
              overflow: "auto",
              mt: "16px",
            }}
          >
            {distributionList.length ? (
              distributionList?.map((user) => (
                <BubbleAvatarDeletable
                  key={user.id}
                  user={user}
                  editable // To show delete button
                  setValues={() => {
                    handleOnDelete(user);
                  }}
                />
              ))
            ) : (
              <Typography
                fontSize="1rem"
                fontWeight="medium"
                textAlign="center"
              >
                {tGeneral("noUsersSelected")}
              </Typography>
            )}
          </Box>
        </span>
      </Card>
    </Modal>
  );
}

export { ModalDistributionList };
