import React from "react";

import { Clear, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Chip,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectTheme } from "../../features/preferences/preferencesSlice";
import BubbleAvatarDeletable from "../userAvatars/BubbleAvatarDeletable";
import { useTour } from "@reactour/tour";
import { useTranslation } from "react-i18next";

// NOTES
/*
    STRUCTURE OF LIST ITEMS
    itemList: [{
        id: number or string,
        label: string
    }]
    selectedElementsList = [{
        id: number or string,
        label: string,
    }]
*/

function SearchBasicFilter(props) {
  const {
    title = "",
    itemList = [],
    selectedElementsList = [], // Array of selected objects
    handleUpdateElementsList,
    selectProps = {},
  } = props;

  const { t: tGeneral } = useTranslation("general");

  const darkMode = useSelector(selectTheme);

  const { isOpen } = useTour();

  const idsElementsList = React.useMemo(() => {
    if (selectedElementsList) {
      return selectedElementsList.map((item) => item.id);
    }
    return [];
  }, [selectedElementsList]);

  const handleSelectedElementsChange = (elementValue, elementChecked) => {
    const isChecked = !elementChecked;
    const element = itemList.find((el) => el.id === elementValue);

    if (typeof element !== "undefined") {
      if (isChecked) {
        // If the checkbox is checked, add the barrier to the selected list
        handleUpdateElementsList([...selectedElementsList, { ...element }]);
      } else {
        // If the checkbox is unchecked, remove the barrier from the selected list
        handleUpdateElementsList(
          selectedElementsList.filter((el) => el.id !== elementValue),
        );
      }
    }
  };

  return (
    <Box sx={selectProps}>
      <Autocomplete
        multiple
        options={itemList}
        disableCloseOnSelect
        value={selectedElementsList}
        sx={{ backgroundColor: darkMode ? "" : "#FFF5DB" }}
        disablePortal={isOpen}
        componentsProps={{
          popper: {
            style: {
              minWidth: selectProps?.width ? selectProps?.width : "auto",
              width: "fit-content",
            },
          },
        }}
        noOptionsText={tGeneral("noOptions")}
        renderOption={(props, element, { selected }) => (
          <li key={element.id}>
            <Chip
              sx={{
                borderRadius: "0px",
                width: "100%",
                height: 45,
                ":hover": {
                  backgroundColor: "#fbcb041f",
                },
                bgcolor:
                  idsElementsList.indexOf(element.id) > -1
                    ? "#fbcb0414"
                    : "transparent",
                borderColor:
                  idsElementsList.indexOf(element.id) > -1
                    ? "#fbcb0414"
                    : "transparent",
                justifyContent: "left",
                alignContent: "center",
                fontSize: "0.875rem",
              }}
              size="medium"
              variant="outlined"
              onClick={(e) => {
                e.stopPropagation();
                handleSelectedElementsChange(
                  element.id,
                  idsElementsList.indexOf(element.id) > -1,
                );
              }}
              label={<Typography>{element.label}</Typography>}
            />
          </li>
        )}
        renderInput={(params) => (
          <Box ref={params.InputProps.ref}>
            <TextField
              {...params.inputProps}
              fullWidth
              label={`${title} (${selectedElementsList.length})`}
              sx={{ padding: 0, height: "40px", backgroundColor: "#FFF5DB" }}
              size="small"
              InputProps={{
                endAdornment:
                  selectedElementsList.length > 0 ? (
                    <IconButton
                      onClick={(e) => {
                        handleUpdateElementsList([]);
                      }}
                    >
                      <Clear />
                    </IconButton>
                  ) : (
                    <Search />
                  ),
                sx: { height: "40px", padding: "14px !important" },
              }}
            />
          </Box>
        )}
      />
    </Box>
  );
}

export { SearchBasicFilter };
