import { apiSlice } from "../../api/apiSlice";

export const userPreferencesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserPreferences: builder.query({
      query: () => "/userPreferences",
      keepUnusedDataFor: 10,
      providesTags: ["UserPreferences"],
    }),
    updateUserPreferences: builder.mutation({
      query: (body) => ({
        url: "/userPreferences",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["UserPreferences"],
    }),
  }),
});

export const { useGetUserPreferencesQuery, useUpdateUserPreferencesMutation } =
  userPreferencesApiSlice;
