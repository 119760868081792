const status = {
  open: "Abierto",
  closed: "Cerrado",
  close: "Cerrado",
  in_revision: "En progreso",
  draft: "Borrador",
  pending: "Pendiente",
  doing: "Abierto",
  backlog: "Abierto",
  rejected: "Rechazado",
  done: "Cerrado",
  inactive: "Inactivo",
  "in revision": "En progreso",
};

export { status };
