export const rfis = {
  add_button_tour: "추가",
  view_button_tour: "보기",
  edit_button_tour: "편집",
  recycle_button_tour: "휴지통",
  export_button_tour: "내보내기",
  settings_button_tour: "설정",
  // ADD - guide me steps
  step1_addRfi_title: "1. RFI 추가",
  step1_addRfi_text: "RFI를 추가하려면 여기를 클릭하십시오",
  step2_addRfi_title: "2. 제목",
  step2_addRfi_text: "RFI를 설명할 수 있는 제목을 작성하십시오",
  step3_addRfi_title: "3. 납기일",
  step3_addRfi_text: "담당자에게 응답을 받을 마감일로 납기일을 선택하십시오",
  step4_addRfi_title: "4. 상태",
  step4_addRfi_text1_1: "상태를 선택",
  step4_addRfi_text1_2: "응답을 기다리는 RFI를 공개하려면 OPEN을 선택하십시오.",
  step4_addRfi_text2_1: "선택",
  step4_addRfi_text2_2: "RFI의 초안을 유지하려면 DRAFT 상태를 선택하십시오.",
  step5_addRfi_title: "5. 개인 정보 보호",
  step5_addRfi_text_1: "RFI 개인 정보 보호 유형을 선택하십시오",
  step5_addRfi_text_2:
    "모듈에 접근할 수 있는 모든 사람이 귀하의 RFI를 볼 수 있습니다",
  step5_addRfi_text_3: "참가자만 이 RFI를 볼 수 있습니다",
  step6_addRfi_title: "6. 할당된",
  step6_addRfi_text: "질문에 답할 사람들을 선택하십시오",
  step7_addRfi_title: "7. 질문",
  step7_addRfi_text: "질문이나 요청할 정보를 작성하십시오",
  step8_addRfi_title: "8. 파일",
  step8_addRfi_text:
    "모든 유형의 파일(PDF, Word, Excel, JPG 등)을 업로드하십시오.",
  step9_addRfi_title: "9. 정보를 받은 사람",
  step9_addRfi_text: "프로젝트에서 정보를 받은 사람을 명시하십시오.",
  step10_addRfi_title: "10. 사양",
  step10_addRfi_text: "참조하려는 사양을 선택하십시오.",
  step11_addRfi_title: "11. 도면",
  step11_addRfi_text: "이 RFI에 연결하려는 도면을 선택하십시오.",
  step12_addRfi_title: "12. 비용",
  step12_addRfi_text: "이 RFI에 비용 영향이 있다면 금액을 추가하십시오.",
  step13_addRfi_title: "13. 지연",
  step13_addRfi_text: "이 RFI에 지연 영향이 있다면 일수와 시간을 추가하십시오.",
  step14_addRfi_title: "14. 배포 목록",
  step14_addRfi_text: "이 RFI에 대해 알리고 싶은 사람들을 선택하십시오.",
  step15_addRfi_title: "15. 게시",
  step15_addRfi_text: "RFI를 생성하려면 게시를 클릭하십시오.",
  stepFinal_addRfi: "RFI를 생성하는 방법을 배웠습니다",
  // VIEW - guide me steps
  step1_viewRfi_title: "1. RFI 보기",
  step1_viewRfi_text: "RFI를 보려면 여기를 클릭하세요",
  step2_viewRfi_title: "2. RFI의 일반 정보",
  step2_viewRfi_text: "RFI의 완전한 정보를 보여줍니다",
  step3_viewRfi_title: "3. 공식 질문",
  step3_viewRfi_text: "첨부 파일이 포함된 공식 질문을 보여줍니다",
  step4_viewRfi_title: "4. 공식 질문의 번역",
  step4_viewRfi_text:
    "국경을 넘는 효과적인 소통을 위해 질문이 원하는 언어로 자동 번역됩니다",
  step5_viewRfi_title: "5. 답변 및 코멘트 추가",
  step5_viewRfi_text:
    "담당자는 공식 요청에 대한 답변과 코멘트를 보낼 수 있습니다",
  step6_viewRfi_title: "6. 주제 생성",
  step6_viewRfi_text:
    "배포 목록에 있는 사용자도 주제 섹션에서 답변하고 코멘트할 수 있습니다",
  step7_viewRfi_title: "7. 마지막으로 본 사용자",
  step7_viewRfi_text: "최근에 이 RFI를 본 사용자를 볼 수 있습니다",
  step8_viewRfi_title: "8. 마지막 수정",
  step8_viewRfi_text: "최근에 이 RFI와 상호 작용한 사용자를 볼 수 있습니다",
  step9_viewRfi_title: "9. 닫기",
  step9_viewRfi_text:
    "공식적인 답변을 받았다면, 생성자로서 이 RFI를 닫을 책임이 있습니다",
  step10_viewRfi_title: "10. PDF 내보내기",
  step10_viewRfi_text: "이 RFI의 정보가 담긴 PDF를 내보낼 수 있습니다",
  stepFinal_viewRfi: "RFI를 보는 방법을 배웠습니다",
  // EDIT - guide me steps
  step1_editRfi_title: "1. RFI 편집",
  step1_editRfi_text: "RFI를 편집하려면 여기를 클릭하세요",
  step2_editRfi_title: "2. 제목",
  step2_editRfi_text: "RFI를 설명할 수 있는 제목을 작성하세요",
  step3_editRfi_title: "3. 납기일",
  step3_editRfi_text:
    "담당자로부터 응답을 받을 수 있는 마감일로 납기일을 선택하세요",
  step4_editRfi_title: "4. 상태",
  step4_editRfi_text1_1: "상태를 선택하세요",
  step4_editRfi_text1_2:
    "응답을 기다리는 RFI를 공개하기 위해 OPEN을 선택하세요.",
  step4_editRfi_text2_1: "선택",
  step4_editRfi_text2_2:
    "RFI를 공개하지 않고 초안으로 유지하기 위해 DRAFT 상태를 선택하세요.",
  step5_editRfi_title: "5. 개인 정보 보호",
  step5_editRfi_text_1: "RFI 개인 정보 보호 유형을 선택하세요",
  step5_editRfi_text_2:
    "모듈에 접근할 수 있는 모든 사람이 귀하의 RFI를 볼 수 있습니다",
  step5_editRfi_text_3: "참가자만 이 RFI를 볼 수 있습니다",
  step6_editRfi_title: "6. 할당됨",
  step6_editRfi_text: "담당자(Placeholder)",
  step7_editRfi_title: "7. 배포 목록",
  step7_editRfi_text: "배포 목록(Placeholder)",
  step8_editRfi_title: "8. 추가 정보",
  step8_editRfi_text: "추가 정보(Placeholder)",
  step9_editRfi_title: "9. 게시",
  step9_editRfi_text: "게시(Placeholder)",
  stepFinal_editRfi: "RFI를 편집하는 방법을 배웠습니다",
  // RECYCLE - guide me steps
  step1_recycleRfi_title: "1. RFI 휴지통",
  step1_recycleRfi_text: "휴지통을 열려면 여기를 클릭하세요",
  step2_recycleRfi_title: "2. 휴지통 메뉴",
  step2_recycleRfi_text: "이것은 휴지통의 메뉴입니다",
  step3_recycleRfi_title: "3. RFI 보기",
  step3_recycleRfi_text: "완전한 RFI를 볼 수 있습니다",
  step4_recycleRfi_title: "4. RFI 복원",
  step4_recycleRfi_text: "복원할 하나 이상의 RFI를 선택하세요",
  stepFinal_recycleRfi: "휴지통과 상호작용하는 방법을 배웠습니다",
  // EXPORT - guide me steps
  step1_exportRfi_title: "1. PDF 내보내기",
  step1_exportRfi_text: "여기를 클릭하여 RFI를 PDF 형식으로 내보내세요",
  step2_exportRfi_title: "2. 이메일 보내기",
  step2_exportRfi_text: "여기를 클릭하여 이 RFI를 이메일로 공유하세요",
  step3_exportRfi_title: "2. CSV 내보내기",
  step3_exportRfi_text:
    "일부를 선택하거나 모든 RFI를 CSV 파일로 내보내려면 필터링하세요",
  step4_exportRfi_title: "4. PDF 내보내기",
  step4_exportRfi_text:
    "일부를 선택하거나 모든 RFI를 PDF 파일로 내보내려면 필터링하세요",
  step5_exportRfi_title: "3. RFI 내부 화면",
  step5_exportRfi_text: "상세 보기에서 PDF로 내보낼 수 있습니다",
  stepFinal_exportRfi: "RFI 내보내기 방법을 배웠습니다",
  // SETTINGS - guide me steps
  step1_settingsRfi_title: "1. RFI 설정",
  step1_settingsRfi_text: "설정을 수정하려면 여기를 클릭하세요",
  step2_settingsRfi_title: "2. 일수",
  step2_settingsRfi_text:
    "전체적으로 RFI에 응답할 수 있는 일수의 한계를 여기에서 수정하세요",
  step3_settingsRfi_title: "3. 배포 목록",
  step3_settingsRfi_text:
    "전체적으로 배포 목록에 속한 사용자를 여기에서 수정하세요",
  step4_settingsRfi_title: "4. 설정 저장",
  step4_settingsRfi_text: "설정을 저장하려면 여기를 클릭하세요",
  stepFinal_settingsRfi: "RFI 설정과 상호 작용하는 방법을 배웠습니다",
  obligatory: "필수",
  public: "공개",
  private: "비공개",
  congratulations: "축하합니다!",
  rfi_title_module: "RFI",
  no_rfis_title: "현재 RFI가 생성되지 않았습니다",
  no_rfis_text:
    "하나를 추가하고 싶다면, RFI 추가 버튼을 클릭해서 과정을 시작하세요",
  meeting: "미팅",
  workflow: "워크플로우",
  guideMe: "가이드 미",
  support: "지원",
  days: "일",
  rfi_recycle_bin: "RFI 휴지통",
  addSpec: "사양 추가",
  deleteRfi: "RFI 삭제",
  confirmDelete: "선택한 RFI를 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.",
};
