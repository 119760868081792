export const materialTable = {
  actions: "Acciones",
  and: "y",
  cancel: "Cancelar",
  changeFilterMode: "Cambiar modo de filtro",
  changeSearchMode: "Cambiar modo de búsqueda",
  clearFilter: "Borrar filtro",
  clearSearch: "Borrar búsqueda",
  clearSelection: "Borrar selección",
  clearSort: "Borrar ordenamiento",
  clickToCopy: "Haga click para copiar",
  copy: "Copiar",
  collapse: "Colapsar",
  collapseAll: "Colapsar todo",
  columnActions: "Columna de acciones",
  copiedToClipboard: "Copiado al portapapeles",
  dropToGroupBy: "Soltar para agrupar por {column}",
  edit: "Editar",
  expand: "Expandir",
  expandAll: "Expandir todo",
  filterArrIncludes: "Incluye",
  filterArrIncludesAll: "Incluye todos",
  filterArrIncludesSome: "Incluye algunos",
  filterBetween: "Entre",
  filterBetweenInclusive: "Entre (inclusivo)",
  filterByColumn: "Filtrar por {column}",
  filterContains: "Contiene",
  filterEmpty: "Vacio",
  filterEndsWith: "Termina con",
  filterEquals: "Iguales",
  filterEqualsString: "Iguales",
  filterFuzzy: "Difuso",
  filterGreaterThan: "Mas grande que",
  filterGreaterThanOrEqualTo: "Mas grande que o igual a",
  filterInNumberRange: "Entre",
  filterIncludesString: "Contiene",
  filterIncludesStringSensitive: "Contiene",
  filterLessThan: "Menos que",
  filterLessThanOrEqualTo: "Menos que o igual a",
  filterMode: "Modo de filtro: {filterType}",
  filterNotEmpty: "No vacio",
  filterNotEquals: "No iguales",
  filterStartsWith: "Empieza con",
  filterWeakEquals: "Iguales",
  filteringByColumn: "Filtrando por {column} - {filterType} - {filterValue}",
  goToFirstPage: "Ir a la primera página",
  goToLastPage: "Ir a la última página",
  goToNextPage: "Ir a la página siguiente",
  goToPreviousPage: "Regresar a la pagina anterior",
  grab: "Agarrar",
  groupByColumn: "Agrupar por {column}",
  groupedBy: "Agrupado por",
  hideAll: "Ocultar todo",
  hideColumn: "Ocultar {column}",
  max: "Máximo",
  min: "Mínimo",
  move: "Mover",
  noRecordsToDisplay: "No hay registros para mostrar",
  noResultsFound: "No se encontraron resultados",
  of: "de",
  or: "o",
  pin: "Anclar",
  pinToLeft: "Anclar a la izquierda",
  pinToRight: "Anclar a la derecha",
  resetColumnSize: "Resetear tamaño de columna",
  resetOrder: "Resetar orden",
  rowActions: "Acciones de fila",
  rowNumber: "#",
  rowNumbers: "Números de fila",
  rowsPerPage: "Filas por página",
  save: "Guardar",
  search: "Buscar",
  select: "Seleccionar",
  selectedCountOfRowCountRowsSelected:
    "{selectedCount} de {rowCount} fila(s) seleccionada(s)",
  showAll: "Mostrar todo",
  showAllColumns: "Mostrar todas las columnas",
  showHideColumns: "Mostrar/ocultar columnas",
  showHideFilters: "Mostrar/ocultar filtros",
  showHideSearch: "Mostrar/ocultar búsqueda",
  sortByColumnAsc: "Ordenar por {column} ascendente",
  sortByColumnDesc: "Ordenar por {column} descendente",
  sortedByColumnAsc: "Ordenar por {column} ascendente",
  sortedByColumnDesc: "Ordenar por {column} descendente",
  thenBy: ", despues por ",
  toggleDensity: "Alternar densidad",
  toggleFullScreen: "Alternar pantalla completa",
  toggleSelectAll: "Alternar seleccionar todo",
  toggleSelectRow: "Alternar seleccionar fila",
  toggleVisibility: "Alternar visibilidad",
  ungroupByColumn: "Desagrupar por {column}",
  unpin: "Desanclar",
  unpinAll: "Desanclar todo",
};
