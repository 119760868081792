import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { useTranslation } from "react-i18next";

export default function AddActionMenu(props) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDocuments } = useTranslation("documents");
  const { isOpen, handleClose, handleNewFolder, handleNewFile, anchorEl } =
    props;
  const handleAddFolder = () => {
    handleNewFolder(true);
  };
  const handleAddFile = () => {
    handleNewFile(true);
  };
  return (
    <Menu
      anchorEl={anchorEl}
      id="add-menu"
      open={isOpen}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem onClick={handleAddFolder} data-tour="docs-add-folder-2">
        {tDocuments("addFolder")}
      </MenuItem>
      <MenuItem onClick={handleAddFile} data-tour="docs-add-file-2">
        {tDocuments("addFile")}
      </MenuItem>
    </Menu>
  );
}
