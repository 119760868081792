import { apiSlice } from "../../../../api/apiSlice";
import { objectToParamString } from "../../../../utils";

export const inspectionsApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getInspections: builder.query({
      query: (idProject) => `/project/inspections/${idProject}/get`,
      keepUnusedDataFor: 60,
      providesTags: ["Inspections"],
    }),
    getActiveInspections: builder.query({
      query: ({ idProject, ...params }) =>
        `project/inspections/${idProject}/get${objectToParamString({
          bActive: true,
          ...params,
        })}`,
      keepUnusedDataFor: 10,
      providesTags: ["Inspections"],
    }),
    getInactiveInspections: builder.query({
      query: (params) =>
        `project/inspections/${params.idProject}/get?bActive=false&assignees=${params.assignees}&creator=${params.creator}&status=${params.status}&privacy=${params.privacy}`,
      keepUnusedDataFor: 10,
      providesTags: ["Inspections"],
    }),
    getInspectionById: builder.query({
      query: (params) =>
        `project/inspections/get/${params.idInspection}?idProject=${params.idProject}`,
      providesTags: ["Inspections"],
    }),
    getInspectionByIdGraph: builder.query({
      query: (params) =>
        `project/inspections/get/${params.idInspection}/graph?idProject=${params.idProject}`,
      providesTags: ["Inspections"],
    }),
    getInspectionByIdDetails: builder.query({
      query: (params) =>
        `project/inspections/get/${params.idInspection}/details?idProject=${params.idProject}`,
      providesTags: ["Inspections"],
    }),
    createInspection: builder.mutation({
      query: (body) => ({
        url: "project/inspections/create",
        method: "POST",
        body,
      }),
      invalidateTags: ["Inspections"],
    }),
    deleteInspection: builder.mutation({
      query: (body) => ({
        url: "project/inspections/delete",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Inspections"],
    }),
    deleteAllInspections: builder.mutation({
      query: (body) => ({
        url: "project/inspections/deleteAll",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Inspections"],
    }),
    recoverInspection: builder.mutation({
      query: (body) => ({
        url: "project/inspections/recover",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Inspections"],
    }),
    setPrivateInspection: builder.mutation({
      query: (body) => ({
        url: "project/inspections/setPrivate",
        method: "POST",
        body,
      }),
      // providesTags: ["Projects"],
    }),
    setPublicInspection: builder.mutation({
      query: (body) => ({
        url: "project/inspections/setPublic",
        method: "POST",
        body,
      }),
      // providesTags: ["Projects"],
    }),
    publishInspection: builder.mutation({
      query: (body) => ({
        url: "project/inspections/publish",
        method: "POST",
        body,
      }),
      // providesTags: ["Projects"],
    }),
    setDraftInspection: builder.mutation({
      query: (body) => ({
        url: "project/inspections/setDraft",
        method: "POST",
        body,
      }),
      // providesTags: ["Projects"],
    }),
    editInspection: builder.mutation({
      query: (body) => ({
        url: "project/inspections/edit",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Inspections", "Message"],
    }),
    revisionInspection: builder.mutation({
      query: (body) => ({
        url: "project/inspections/revision",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Inspections", "Message"],
    }),
    approveInspection: builder.mutation({
      query: (body) => ({
        url: "project/inspections/approve",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Inspections", "Dashboard"],
    }),
    itemInspection: builder.mutation({
      query: (body) => ({
        url: "project/inspections/item",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Inspections", "Dashboard"],
    }),
    sendEmailInspection: builder.mutation({
      query: (body) => ({
        url: "project/inspections/sendMailInspections",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects"],
    }),
    getInspectionsStatus: builder.mutation({
      query: (body) => ({
        url: "project/inspections/getInspections",
        method: "POST",
        body,
      }),
      invalidateTags: ["Inspections"],
    }),
    getLastInspection: builder.query({
      query: (idProject) => `/project/inspections/lastInspection/${idProject}`,
      keepUnusedDataFor: 10,
      providesTags: ["Inspections"],
    }),
    observation: builder.mutation({
      query: (body) => ({
        url: "project/inspections/observation",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Inspections"],
    }),
    comment: builder.mutation({
      query: (body) => ({
        url: "project/inspections/comment",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Inspections"],
    }),
    rejectInspection: builder.mutation({
      query: (body) => ({
        url: "project/inspections/reject",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Inspections"],
    }),
  }),
});

export const {
  useGetInspectionsQuery,
  useGetActiveInspectionsQuery,
  useGetInactiveInspectionsQuery,
  useCreateInspectionMutation,
  useEditInspectionMutation,
  useDeleteInspectionMutation,
  useRevisionInspectionMutation,
  useApproveInspectionMutation,
  useItemInspectionMutation,
  useSendEmailInspectionMutation,
  useGetInspectionsStatusMutation,
  useGetLastInspectionQuery,
  useRecoverInspectionMutation,
  useGetInspectionByIdQuery,
  useGetInspectionByIdGraphQuery,
  useGetInspectionByIdDetailsQuery,
  useObservationMutation,
  useCommentMutation,
  useRejectInspectionMutation,
  useDeleteAllInspectionsMutation,
} = inspectionsApiSlice;
