import React, { useState } from "react";
import { useNavigate } from "react-router";
// Redux: selectors
import {
  useChangePasswordMutation,
  useGetUserQuery,
} from "../../features/userSettings/userSettingsApiSlice";
//Validators
import { passwordRepeatValidator } from "../../services/validators/passwordRepeatValidator";
import { passwordValidator } from "../../services/validators/passwordValidator";
// MUI Components
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
// Icons
import {
  CheckCircleOutline,
  HighlightOff,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
//alert
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { PopUpAlert } from "../../components/PopUpAlert";

import useMediaQuery from "@mui/material/useMediaQuery";

import ChangePasswordImage from "./../../assets/ChangePassword.svg";

const ChangePassword = () => {
  const { t: tGeneral } = useTranslation("general");

  // hooks
  const [currentPassword, setCurrentPassword] = useState("");
  const [currentPasswordAlert, setCurrentPasswordAlert] = useState("");
  const [errorCurrentPassword, setErrorCurrentPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAlert, setNewPasswordAlert] = useState("");
  const [errorNewPassword, setErrorNewPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [passwordRepeatAlert, setPasswordRepeatAlert] = useState("");
  const [errorPasswordRepeat, setErrorPasswordRepeat] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

  const { data: user, isLoading } = useGetUserQuery();
  const [changePassword, { isLoading: isSubmitLoading }] =
    useChangePasswordMutation();
  const [open, setOpen] = useState(false);

  const isMobile = useMediaQuery("(max-width:820px)");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let mistakes = false;
    try {
      if (currentPassword === "") {
        setErrorCurrentPassword(true);
        setCurrentPasswordAlert("Password invalid");
        mistakes = true;
      }
      let result = passwordValidator(newPassword);
      if (newPassword === "" || result === -1) {
        setErrorNewPassword(true);
        setNewPasswordAlert("New password invalid");
        mistakes = true;
      }
      if (
        passwordRepeat !== "" &&
        passwordRepeatValidator(newPassword, passwordRepeat) === -1
      ) {
        setErrorPasswordRepeat(true);
        setPasswordRepeatAlert("Passwords do not match");
        mistakes = true;
      }

      if (!mistakes) {
        const response = await changePassword({
          idUser: user.id,
          email: user.email,
          currentPassword: currentPassword,
          newPassword: newPassword,
          repeatPassword: passwordRepeat,
        }).unwrap();

        if (response) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
          });
          resetVars();
          setCurrentPassword("");
          setNewPassword("");
          setPasswordRepeat("");
          PopUpAlert("success", "Password Changed Successfully");
        }
      } else {
        resetVars();
        PopUpAlert("error", "Cannot change password", "Invalid data");
      }
    } catch (err) {
      resetVars();
      PopUpAlert("error", "Cannot change password", err?.data);

      console.error(err);
    }
  };

  const resetVars = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container item xs={12} justifyContent="center">
        {/* text Change Password  */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography fontSize="36px" align="left" fontWeight="bold">
            {tGeneral("changePassword")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
            width: "100%",
          }}
        >
          <img
            src={ChangePasswordImage}
            alt="ChangePasswordImage"
            style={{ width: isMobile ? "100%" : "20%" }}
          />
        </Box>
        {/* Password input */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
            width: "100%",
          }}
        >
          <FormControl
            sx={{ width: isMobile ? "100%" : "40%" }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {tGeneral("currentPassword")}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showCurrentPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => {
                      setShowCurrentPassword(!showCurrentPassword);
                    }}
                    edge="end"
                  >
                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              value={currentPassword}
              required={true}
              label={tGeneral("currentPassword")}
              onChange={(e) => {
                setCurrentPassword(e.target.value);
                if (e.target.value === "") {
                  setErrorCurrentPassword(true);
                  setCurrentPasswordAlert("Password invalid");
                } else {
                  setErrorCurrentPassword(false);
                  setCurrentPasswordAlert("");
                }
              }}
            />
          </FormControl>
          {errorCurrentPassword ? (
            <HighlightOff sx={{ color: "red" }} />
          ) : (
            <CheckCircleOutline
              sx={{ color: currentPassword.length === 0 ? "gray" : "green" }}
            />
          )}
        </Box>
        {/* New Password input */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
            width: "100%",
          }}
        >
          <FormControl
            sx={{ width: isMobile ? "100%" : "40%" }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {tGeneral("newPassword")}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-new-password"
              type={showNewPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => {
                      setShowNewPassword(!showNewPassword);
                    }}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              value={newPassword}
              required={true}
              label={tGeneral("newPassword")}
              onChange={(e) => {
                setNewPassword(e.target.value);
                let result = passwordValidator(e.target.value);
                if (e.target.value === "" || result === -1) {
                  setErrorNewPassword(true);
                  setNewPasswordAlert("New password invalid");
                } else {
                  setErrorNewPassword(false);
                  setNewPasswordAlert("");
                }
              }}
            />
          </FormControl>
          {errorNewPassword ? (
            <HighlightOff sx={{ color: "red" }} />
          ) : (
            <CheckCircleOutline
              sx={{ color: newPassword.length === 0 ? "gray" : "green" }}
            />
          )}
        </Box>
        {/* Password repeat */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
            width: "100%",
          }}
        >
          <FormControl
            sx={{ width: isMobile ? "100%" : "40%" }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {tGeneral("repeatPassword")}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-repeat-password"
              type={showPasswordRepeat ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => {
                      setShowPasswordRepeat(!showPasswordRepeat);
                    }}
                    edge="end"
                  >
                    {showPasswordRepeat ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              value={passwordRepeat}
              required={true}
              label={tGeneral("repeatPassword")}
              onChange={(e) => {
                setPasswordRepeat(e.target.value);
                if (
                  e.target.value !== "" &&
                  passwordRepeatValidator(newPassword, e.target.value) === -1
                ) {
                  setErrorPasswordRepeat(true);
                  setPasswordRepeatAlert("Passwords do not match");
                } else {
                  setErrorPasswordRepeat(false);
                  setPasswordRepeatAlert("");
                }
              }}
            />
          </FormControl>
          {errorPasswordRepeat ? (
            <HighlightOff sx={{ color: "red" }} />
          ) : (
            <CheckCircleOutline
              sx={{ color: passwordRepeat.length === 0 ? "gray" : "green" }}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 1,
            width: "100%",
          }}
        >
          <Typography fontSize={12} sx={{ mt: 0, mb: 1 }}>
            {tGeneral("passwordNotice")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
            width: "100%",
          }}
        >
          <Divider sx={{ width: isMobile ? "100%" : "40%" }} />
        </Box>
        {/* Button Save*/}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
            width: "100%",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            onClick={() => {
              navigate("/user/profile");
            }}
            sx={{
              width: isMobile ? "100%" : "18%",
              m: 1,
              borderRadius: 1,
              color: "black",
              backgroundColor: "white",
              borderColor: "black",
              border: 1,
              ":hover": {
                color: "white",
                backgroundColor: "#b4b4b4",
              },
            }}
          >
            {tGeneral("cancel")}
          </Button>
          <Button
            type="submit"
            variant="contained"
            onClick={() => {
              setOpen(true);
            }}
            sx={{
              width: isMobile ? "100%" : "18%",
              m: 1,
              color: "black",
              backgroundColor: "#ffc900",
              ":hover": {
                color: "black",
                backgroundColor: "#d9ab00",
              },
            }}
          >
            {tGeneral("save")}
          </Button>
        </Box>
      </Grid>
      <Dialog
        open={open}
        onClose={(e) => resetVars()}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#ffc900",
            color: "white",
          }}
          id="scroll-dialog-title"
        >
          {tGeneral("changePassword")}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            {tGeneral("passwordYouSure")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => resetVars()}>{tGeneral("cancel")}</Button>
          <Button onClick={(e) => handleSubmit(e)}>{tGeneral("accept")}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangePassword;
