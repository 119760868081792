import { tour } from "./tour";
export const rfis = {
  // general tour
  ...tour,
  moduleElement: "RFI",
  // guide me buttons
  add_button_tour: "Add",
  view_button_tour: "View",
  edit_button_tour: "Edit",
  recycle_button_tour: "Recycle Bin",
  export_button_tour: "Export",
  settings_button_tour: "Settings",
  // ADD - guide me steps
  step1_addRfi_title: "1. Add RFI",
  step1_addRfi_text: "Click here to add RFI",
  step2_addRfi_title: "2. Title",
  step2_addRfi_text: "Write a title that can describe your RFI",
  step3_addRfi_title: "3. Delivery Date",
  step3_addRfi_text:
    "Choose the delivery date as the deadline to receive a response from the asignee",
  step4_addRfi_title: "4. Status",
  step4_addRfi_text1_1: "Choose the status",
  step4_addRfi_text1_2: "OPEN to Publish the RFI pending a response.",
  step4_addRfi_text2_1: "Choose the",
  step4_addRfi_text2_2:
    "DRAFT status to keep a draft of the RFI without publishing it.",
  step5_addRfi_title: "5. Privacy",
  step5_addRfi_text_1: "Choose the type of RFI privacy",
  step5_addRfi_text_2: "Anyone with access to the module will see your RFI",
  step5_addRfi_text_3: "Only participants will be able to see this RFI",
  step6_addRfi_title: "6. Assigned",
  step6_addRfi_text: "Select the people who will answer your question",
  step7_addRfi_title: "7. Question",
  step7_addRfi_text: "Write the question or the information to request",
  step8_addRfi_title: "8. Files",
  step8_addRfi_text: "Upload any type of file (PDF, Word, Excel, JPG, etc).",
  step9_addRfi_title: "9. Received From",
  step9_addRfi_text:
    "Please specify which person in the project you received the information from.",
  step10_addRfi_title: "10. Specs",
  step10_addRfi_text: "Choose the spec you want to refer to.",
  step11_addRfi_title: "11. Drawing",
  step11_addRfi_text: "Choose the drawing you want to link this RFI.",
  step12_addRfi_title: "12. Cost",
  step12_addRfi_text: "If this RFI have cost impact, you can add the amount.",
  step13_addRfi_title: "13. Delay",
  step13_addRfi_text:
    "If this RFI has a delay impact, you can add the days and hours.",
  step14_addRfi_title: "14. Distribution List",
  step14_addRfi_text: "Select the people you want to notify about this RFI.",
  step15_addRfi_title: "15. Publish",
  step15_addRfi_text: "Click in publish to create the RFI.",
  stepFinal_addRfi: "You have learned how to create an RFI",
  // VIEW - guide me steps
  step1_viewRfi_title: "1. View RFI",
  step1_viewRfi_text: "Click here to visualize an RFI",
  step2_viewRfi_title: "2. General information of RFI",
  step2_viewRfi_text: "Shows the complete information of the RFI",
  step3_viewRfi_title: "3. Official question",
  step3_viewRfi_text: "Show the official question with attachments",
  step4_viewRfi_title: "4. Translation of the official question",
  step4_viewRfi_text:
    "The question is automatically translated into the desired language in order to make communication effective across borders",
  step5_viewRfi_title: "5. Add answers and comments",
  step5_viewRfi_text:
    "The assignees can send answers and comments about the official request",
  step6_viewRfi_title: "6. Create topics",
  step6_viewRfi_text:
    "Users in distribution list can also answer and comment in the topics section",
  step7_viewRfi_title: "7. Last seen",
  step7_viewRfi_text: "You can see which users have recently seen this RFI",
  step8_viewRfi_title: "8. Last edit",
  step8_viewRfi_text:
    "You can see which users have recently interacted with this RFI",
  step9_viewRfi_title: "10. Close",
  step9_viewRfi_text:
    "If you are a creator you have the responsibility to close this RFI once you have obtained an official response",
  step10_viewRfi_title: "9. Export PDF",
  step10_viewRfi_text: "You can export a PDF with information of this RFI",
  stepFinal_viewRfi: "You have learned how to view an RFI",
  // EDIT - guide me steps
  step1_editRfi_title: "1. Edit RFI",
  step1_editRfi_text: "Click here to edit an RFI",
  step2_editRfi_title: "2. Title",
  step2_editRfi_text: "Write a title that can describe your RFI",
  step3_editRfi_title: "3. Delivery Date",
  step3_editRfi_text:
    "Choose the delivery date as the deadline to receive a response from the asignee",
  step4_editRfi_title: "4. Status",
  step4_editRfi_text1_1: "Choose the status",
  step4_editRfi_text1_2: "OPEN to Publish the RFI pending a response.",
  step4_editRfi_text2_1: "Choose the",
  step4_editRfi_text2_2:
    "DRAFT status to keep a draft of the RFI without publishing it.",
  step5_editRfi_title: "5. Privacy",
  step5_editRfi_text_1: "Choose the type of RFI privacy",
  step5_editRfi_text_2: "Anyone with access to the module will see your RFI",
  step5_editRfi_text_3: "Only participants will be able to see this RFI",
  step6_editRfi_title: "6. Assigned",
  step6_editRfi_text: "Placeholder",
  step7_editRfi_title: "7. Distribution List",
  step7_editRfi_text: "Placeholder",
  step8_editRfi_title: "8. Additional Information",
  step8_editRfi_text: "Placeholder",
  step9_editRfi_title: "9. Publish",
  step9_editRfi_text: "Placeholder",
  stepFinal_editRfi: "You have learned how to edit an RFI",
  // RECYCLE - guide me steps
  step1_recycleRfi_title: "2. Recycle Bin RFI",
  step1_recycleRfi_text: "Click here to open the recycle bin",
  step2_recycleRfi_title: "3. Recycle Bin Menu",
  step2_recycleRfi_text: "This is the menu fot the recycle bin",
  step3_recycleRfi_title: "4. View an RFI",
  step3_recycleRfi_text: "You can view the complete RFI",
  step4_recycleRfi_title: "5. Restore RFI",
  step4_recycleRfi_text: "Select one or several RFIs to restore",
  stepFinal_recycleRfi: "You have learned how to interact with the recycle bin",
  // EXPORT - guide me steps
  step1_exportRfi_title: "2. Export PDF",
  step1_exportRfi_text: "Click here to export the RFI in PDF format",
  /*step2_exportRfi_title: "3. Send Email",
  step2_exportRfi_text: "Click here to share this RFI by email",*/
  step3_exportRfi_title: "3. Exports CSV",
  step3_exportRfi_text:
    "Select some or filter all the RFIs to export in a CSV file",
  /*step4_exportRfi_title: "4. Exports PDF",
  step4_exportRfi_text:
    "Select some or filter all the RFIs to export in a PDF file",*/
  step5_exportRfi_title: "4. Screen inside an RFI",
  step5_exportRfi_text: "You can export in PDF from the detail view",
  stepFinal_exportRfi: "You have learned how to export an RFI",
  // SETTINGS - guide me steps
  step1_settingsRfi_title: "2. RFI settings",
  step1_settingsRfi_text: "Click here to modify the settings",
  step2_settingsRfi_title: "3. Days",
  step2_settingsRfi_text:
    "Modify here the limit of days to be able to respond to the RFI globally",
  step3_settingsRfi_title: "4. Distribution List",
  step3_settingsRfi_text:
    "Modify here the users who belong to the distribution list globally",
  step4_settingsRfi_title: "5. Save settings",
  step4_settingsRfi_text: "Click here to save the settings",
  stepFinal_settingsRfi:
    "You have learned how to interact with the RFI settings",
  // other
  obligatory: "Obligatory",
  public: "Public",
  private: "Private",
  congratulations: "Congratulations!",
  rfi_title_module: "RFIs",
  no_rfis_title: "No RFIs have been created at the moment",
  no_rfis_text:
    "If you want to add one, we invite you to click the add RFI button to start the process",
  meeting: "Meeting",
  workflow: "Workflow",
  guideMe: "Guide me",
  support: "Support",
  days: "Days",
  rfi_recycle_bin: "RFI Recycle Bin",
  addSpec: "Add specification",
  deleteRfi: "Delete RFI(s)",
  confirmDelete:
    "¿Are you sure you want to delete the selected rfi(s)? By performing this action, it cannot be reversed.",
};
