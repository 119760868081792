import { tour } from "./tour";

export const meetings = {
  // general tour
  ...tour,
  moduleElement: "Reuniões",
  // ADD - guide me steps
  step1_addMeeting_title: "1. Adicionar reunião.",
  step1_addMeeting_text: "Clique no ícone + para adicionar uma nova reunião.",
  step2_addMeeting_title: "2. Título",
  step2_addMeeting_text: "Escreva um título para as reuniões.",
  step3_addMeeting_title: "3. Privacidade",
  step3_addMeeting_text:
    "Selecione se as reuniões serão visíveis para todos ou não.",
  step3_addMeeting_text_2:
    "Qualquer pessoa com acesso ao módulo verá sua reunião.",
  step3_addMeeting_text_3: "Apenas os participantes poderão ver esta reunião.",
  step4_addMeeting_title: "4. Data da Reunião",
  step4_addMeeting_text: "Defina a data e hora da reunião.",
  step5_addMeeting_title: "5. Local",
  step5_addMeeting_text: "Determine o local das reuniões.",
  step6_addMeeting_title: "6. URL da Reunião",
  step6_addMeeting_text: "Insira a URL das reuniões aqui.",
  step7_addMeeting_title: "7. Status",
  step7_addMeeting_text_1_1: "Escolha o status",
  step7_addMeeting_text_1_2: "ABERTO público para as pessoas envolvidas.",
  step7_addMeeting_text_2_1: "Escolha o status",
  step7_addMeeting_text_2_2: "RASCUNHO salvar como rascunho.",
  step8_addMeeting_title: "8. Participantes",
  step8_addMeeting_text: "Selecione as pessoas necessárias para a reunião.",
  step9_addMeeting_title: "9. Lista de Distribuição",
  step9_addMeeting_text:
    "Selecione as pessoas que receberão notificações da reunião.",
  step10_addMeeting_title: "10. Novo item",
  step10_addMeeting_text: "Adicione um novo item à reunião.",
  step11_addMeeting_title: "11. Nome do Item",
  step11_addMeeting_text: "Escreva o nome do item.",
  step12_addMeeting_title: "12. Tópicos a Discutir",
  step12_addMeeting_text:
    "Escreva os tópicos a serem discutidos no item da reunião.",
  step13_addMeeting_title: "13. Tempo",
  step13_addMeeting_text: "Determine o tempo estimado para o item.",
  step14_addMeeting_title: "14. Arquivos",
  step14_addMeeting_text:
    "Faça upload de arquivos relevantes para as reuniões.",
  step15_addMeeting_title: "15. Adicionar",
  step15_addMeeting_text: "Clique para confirmar o novo item",
  step16_addMeeting_title: "16. Criar",
  step16_addMeeting_text: "Clique aqui para criar a reunião.",
  step_final_addMeeting: "Você aprendeu como criar uma reunião.",
  // VIEW - guide me steps
  step1_viewMeeting_title: "1. Visualizar reunião.",
  step1_viewMeeting_text: "Clique para abrir uma reunião.",
  step2_viewMeeting_title: "2. Informações",
  step2_viewMeeting_text:
    "Nesta seção, você pode visualizar as principais informações do resumo da reunião.",
  step3_viewMeeting_title: "3. URL",
  step3_viewMeeting_text:
    "Clique aqui para abrir a URL ou clique no ícone à direita para copiá-la para a área de transferência.",
  step4_viewMeeting_title: "4. Agenda",
  step4_viewMeeting_text: "Esta seção contém as informações da agenda.",
  step5_viewMeeting_title: "5. Item",
  step5_viewMeeting_text: "Clique em um item para ver seu conteúdo.",
  step6_viewMeeting_title: "6. Tópico",
  step6_viewMeeting_text: "Clique em um tópico para ver seu conteúdo.",
  step7_viewMeeting_title: "7. Comentários",
  step7_viewMeeting_text:
    "Nesta seção, você pode adicionar comentários sobre os tópicos.",
  step8_viewMeeting_title: "8. Tarefas",
  step8_viewMeeting_text:
    "As tarefas criadas sob o tópico serão exibidas aqui.",
  step_final_viewMeeting:
    "Você aprendeu como ver as informações de uma reunião.",
  // EDIT - guide me steps
  step1_editMeeting_title: "1. Editar uma reunião.",
  step1_editMeeting_text: "Clique aqui para editar a reunião.",
  step2_editMeeting_title: "2. Título",
  step2_editMeeting_text: "Edite o título das reuniões",
  step3_editMeeting_title: "3. Privacidade",
  step3_editMeeting_text: "Edite a privacidade da reunião.",
  step3_editMeeting_text_2:
    "Qualquer pessoa com acesso ao módulo verá sua reunião.",
  step3_editMeeting_text_3: "Apenas os participantes poderão ver esta reunião.",
  step4_editMeeting_title: "4. Data da Reunião",
  step4_editMeeting_text: "Edite a data e hora da reunião.",
  step5_editMeeting_title: "5. Local",
  step5_editMeeting_text: "Edite o local da reunião.",
  step6_editMeeting_title: "6. URL da Reunião",
  step6_editMeeting_text: "Edite a URL da reunião.",
  step7_editMeeting_title: "7. Status da Reunião",
  step7_editMeeting_text_1_1: "Escolha o status",
  step7_editMeeting_text_1_2: "ABERTO público para as pessoas envolvidas.",
  step7_editMeeting_text_2_1: "Escolha o status",
  step7_editMeeting_text_2_2: "RASCUNHO salvar como rascunho.",
  step8_editMeeting_title: "8. Participantes",
  step8_editMeeting_text:
    "Adicione ou remova as pessoas necessárias para a reunião.",
  step9_editMeeting_title: "9. Lista de Distribuição",
  step9_editMeeting_text:
    "Edite as pessoas que receberão notificações da reunião.",
  step10_editMeeting_title: "10. Nome do Item",
  step10_editMeeting_text: "Edite o nome do item.",
  step11_editMeeting_title: "11. Tópicos de Discussão",
  step11_editMeeting_text: "Edite os tópicos de discussão do item.",
  step12_editMeeting_title: "12. Tempo Estimado",
  step12_editMeeting_text: "Edite o tempo estimado para o item.",
  step13_editMeeting_title: "13. Arquivos",
  step13_editMeeting_text: "Edite ou adicione arquivos relacionados à reunião.",
  step14_editMeeting_title: "14. Novo item",
  step14_editMeeting_text: "Adicione um novo item à reunião.",
  step15_editMeeting_title: "15. Salvar",
  step15_editMeeting_text: "Clique aqui para salvar as alterações da reunião",
  step_final_editMeeting: "Você aprendeu como editar uma reunião.",
  // RECYCLE - guide me steps
  step1_recycleMeeting_title: "2. Lixeira de Reuniões",
  step1_recycleMeeting_text: "Clique aqui para abrir a lixeira de reuniões.",
  step2_recycleMeeting_title: "3. Menu da Lixeira",
  step2_recycleMeeting_text: "Este é o menu da lixeira de reuniões.",
  step3_recycleMeeting_title: "4. Visualizar uma reunião.",
  step3_recycleMeeting_text:
    "Clique aqui para ver as informações da reunião que você deseja restaurar.",
  step4_recycleMeeting_title: "5. Selecionar reuniões.",
  step4_recycleMeeting_text: "Selecione as reuniões que você deseja restaurar.",
  step5_recycleMeeting_title: "6. Restaurar reuniões selecionadas.",
  step5_recycleMeeting_text:
    "Clique aqui para restaurar as reuniões selecionadas.",
  step_final_recycleMeeting:
    "Você aprendeu como interagir com a lixeira de reuniões.",
  // SETTINGS - guide me steps
  step1_settingsMeeting_title: "2. Abrir menu de configurações",
  step1_settingsMeeting_text: "Clique aqui para abrir o menu de configurações.",
  step2_settingsMeeting_title: "3. Lista de Distribuição",
  step2_settingsMeeting_text:
    "Adicione ou remova pessoas que terão acesso padrão ao módulo de reuniões.",
  step3_settingsMeeting_title: "4. Salvar",
  step3_settingsMeeting_text: "Clique aqui para salvar as configurações.",
  step_final_settingsMeeting: "Você aprendeu como ajustar as configurações.",
  // EXPORT - guide me steps
  step1_exportMeeting_title: "1. Exportar reunião",
  step1_exportMeeting_text:
    "Clique aqui para exportar as informações das reuniões por e-mail.",
  step_final_exportMeeting: "Você aprendeu como exportar uma reunião.",

  meetings: "Reuniões",
  addMeeting: "Agendar uma Reunião",
  deleteMeeting: "Excluir Reunião",
  messageDelete:
    "Tem certeza de que deseja excluir as reuniões selecionadas? Ao realizar esta ação, ela não poderá ser revertida.",
  meetingDeleted: "Reunião excluída com sucesso",
  errorMeetingDeleted: "Não é possível excluir a reunião",
  meetingRecovered: "Reunião recuperada com sucesso",
  errorMeetingRecovered: "Não é possível restaurar a reunião",
  meetingsRecycleBin: "Lixeira de Reuniões",
  meetingCreated: "Reunião criada com sucesso",
  errorMeetingCreated: "Não é possível criar a reunião",
  meetingUpdated: "Reunião atualizada com sucesso",
  errorMeetingUpdated: "Não é possível atualizar a reunião",
  editMeeting: "Editar Reunião",
  urlMeeting: "URL da Reunião",
  agenda: "Agenda",
  meetingStarted: "Reunião iniciada com sucesso",
  errorMeetingStarted: "Não é possível iniciar a reunião atual",
  meetingClosed: "Reunião encerrada com sucesso",
  errorMeetingClosed: "A reunião não pode ser encerrada",
  startMeeting: "Iniciar reunião",
  noAgendaItems: "Sem itens de agenda",
  closeMeeting: "Encerrar reunião",
  noElementsTitle: "Hora de começar!",
  noElementsSubtitle:
    "Por que você não cria sua primeira reunião para planejar suas próximas tarefas? Clique no botão abaixo para começar.",
  newAgendaItem: "Novo Item de Agenda",
  topicsToDiscuss: "Tópicos a Discutir",
  attachOrDrop: "Anexar arquivo(s) ou arrastar e soltar aqui",
  viewFiles: "Ver arquivos",
  noTopics: "Sem tópicos",
  taskSuccessfullyDeleted: "Tarefa excluída com sucesso",
  taskCantDelete: "A tarefa não pode ser excluída",
  taskName: "Nome da Tarefa",
  newTask: "Nova Tarefa",
  deleteTask: "Excluir Tarefa",
  deleteTaskQuestion: "Tem certeza de que deseja excluir esta tarefa?",
  noMeetings: "Nenhuma reunião na lixeira",
  confirmedAssistance: "Confirmação de presença",
};
