import { Box, Card, Typography } from "@mui/material";
import { cloneElement, useState } from "react";
import { useTranslation } from "react-i18next";

function FeatureCard({ title, cover, features, content }) {
  const { t: tGeneral } = useTranslation("general");
  const [expanded, setExpanded] = useState(false);

  const renderContent = () => {
    if (expanded) {
      return (
        <Box display="flex" sx={{ minWidth: "650px", height: "100%" }}>
          <Box>
            <Typography>
              Prepare your project to I success from the beggining. Integrate
              all projects activities within a given timeframe budget easily.
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-end">
            <img width="280px" src={cover} alt={title} />
          </Box>
        </Box>
      );
    }
    return (
      <>
        <Box
          sx={{ backgroundColor: "#FFEBBC", borderRadius: "10px" }}
          height="148px"
          width="100%"
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <img src={cover} alt={title} />
        </Box>
        <Typography fontSize="1.125rem" fontWeight="bold" my="22px">
          {title}
        </Typography>
        <Box display="flex" flexDirection="column" rowGap="12px">
          {features &&
            features.map((feature) => (
              <Box display="flex">
                {cloneElement(feature.icon, { sx: { color: "#6A6A6A" } })}
                <Typography
                  color="#6A6A6A"
                  fontWeight="600"
                  fontSize="1rem"
                  ml="10px"
                >
                  {tGeneral(feature.label)}
                </Typography>
              </Box>
            ))}
          {content && (
            <Typography fontSize="0.875rem" color="#6A6A6A1">
              {content}
            </Typography>
          )}
        </Box>
      </>
    );
  };
  return (
    <Card
      sx={{
        padding: "10px 12px",
        borderRadius: "10px",
        height: "430px",
        width: expanded ? "auto" : "290px",
        minWidth: expanded ? "680px" : "290px",
        cursor: "pointer",
        transition: "all 1s ease",
      }}
      component="div"
      onClick={() => setExpanded((prev) => !prev)}
    >
      {renderContent()}
    </Card>
  );
}

export { FeatureCard };
