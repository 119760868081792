import { tour } from "./tour";

export const projectDirectory = {
  ...tour,
  moduleElement: "사용자",
  moduleName: "디렉토리",
  // ADD - guide me steps
  step1_addUser_title: "1. 프로젝트에 새로운 사용자 추가",
  step1_addUser_text: "여기를 클릭하여 프로젝트에 새로운 사용자를 추가하세요.",
  step2_addUser_title: "2. 사용자 선택",
  step2_addUser_text:
    "사용자의 전체 이름을 입력한 후 목록에서 사용자를 선택하세요.",
  step3_addUser_title: "3. 직책",
  step3_addUser_text: "사용자가 프로젝트에서 가지는 직책을 입력하세요.",
  step4_addUser_title: "4. 기본 역할",
  step4_addUser_text:
    "연락처에 대한 기본 역할을 선택하세요. 보기만 가능한지, 아니면 어떠한 권한도 없는지 선택할 수 있습니다.",
  step5_addUser_title: "5. 개별 권한",
  step5_addUser_text: "각 모듈에 대해 특정 역할을 선택하세요.",
  step6_addUser_title: "6. 확인",
  step6_addUser_text: "여기를 클릭하여 사용자를 확인하고 추가하세요.",
  step_final_addUser: "새로운 사용자를 프로젝트에 추가하는 방법을 배웠습니다.",
  // VIEW - guide me steps
  step1_viewUser_title: "1. 사용자 정보 보기",
  step1_viewUser_text: "여기를 클릭하여 사용자의 정보를 시각화하세요.",
  step2_viewUser_title: "2. 최근 로그인",
  step2_viewUser_text:
    "여기에서 사용자의 최근 로그인 정보를 확인할 수 있습니다.",
  step3_viewUser_title: "3. 최근 활동",
  step3_viewUser_text:
    "여기에서 사용자가 최근에 수행한 작업에 대한 정보를 확인할 수 있습니다.",
  step_final_viewUser: "프로젝트 사용자의 정보를 시각화하는 방법을 배웠습니다.",
  // EDIT - guide me steps
  step1_editUser_title: "1. 사용자 편집",
  step1_editUser_text: "여기를 클릭하여 사용자를 편집하세요.",
  step2_editUser_title: "2. 직책",
  step2_editUser_text: "연락처가 프로젝트에서 가지는 직책을 편집하세요.",
  step3_editUser_title: "3. 기본 역할",
  step3_editUser_text:
    "연락처에 대한 기본 역할을 편집하세요. 보기만 가능한지, 아니면 어떠한 권한도 없는지 편집할 수 있습니다.",
  step4_editUser_title: "4. 개별 권한",
  step4_editUser_text: "각 모듈에 대한 역할을 편집하세요.",
  step5_editUser_title: "5. 저장",
  step5_editUser_text: "변경 사항을 저장하려면 여기를 클릭하세요.",
  step_final_editUser: "사용자를 편집하는 방법을 배웠습니다.",

  directory: "디렉토리",
  addCompany: "회사 추가",
  lastLogin: "최근 로그인",
  infoText: "은(는) 연결되지 않았습니다. ",
  days: "일",
  wasConnectedToday: "은(는) 최근 24시간 내에 연결되었습니다.",
  hasNeverLoggedIn: "은(는) 한 번도 로그인하지 않았습니다.",
  latestActivity: "최신 활동",
  createRfi: "RFI 생성 24",
  responseSubmittal: "응답 제출",
  createDailyReport: "일일 보고서 생성",
  createRfi123: "RFI 123 생성",
  uploadPhotos: "사진 업로드",
  newContact: "새로운 연락처",
  editContact: "연락처 편집",
  generalInformation: "일반 정보",
  permissions: "권한",
  drawings: "도면",
  punchList: "펀치 리스트",
  rfis: "RFI",
  submittals: "서브미션",
  deliveries: "배송",
  hours: "시간",
  meetings: "회의",
  photos: "사진",
  tasks: "작업",
  inspections: "검사",
  issues: "이슈",
  dailyReports: "일일 보고서",
  documents: "문서",
  delays: "지연",
  announcements: "공지사항",
  dashbooard: "대시보드",
  adminPanel: "관리자 패널",
  generalDirectory: "일반 디렉토리",
  deleteContact: "연락처 삭제",
  confirmDelete: "이 연락처를 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.",
  currentPlan: "현재 플랜",
  activeUsers: " 활성 사용자.",
  type: "유형",
  user: "사용자",
  contact: "연락처",
  usersLimit: "사용자 제한",
  confirmUsersLimit:
    "계속해서 새로운 사용자를 추가하시겠습니까? 이 작업은 추가 비용이 발생합니다.",
  deletedRoleMessage:
    "이 사용자의 현재 역할이 삭제되었습니다. 새로운 역할을 선택하세요.",
};
