import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TooltipIconButton } from "./TooltipIconButton";

function UpdateTextFieldModal({
  isOpen,
  onClose,
  title,
  label,
  value,
  onUpdate,
  isLoading,
}) {
  const { t: tGeneral } = useTranslation("general");

  const [newValue, setNewValue] = useState();

  useEffect(() => {
    setNewValue(value);
  }, [value]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Card sx={{ width: "40%", p: "1rem", borderRadius: "1rem" }}>
        <Box display="flex" alignItems="center" mb="0.5rem">
          <Typography flex={1}>
            {tGeneral("edit")} {title}
          </Typography>
          <TooltipIconButton
            icon={<Close />}
            onClick={onClose}
            label={tGeneral("close")}
            color="gray"
          />
        </Box>
        <TextField
          fullWidth
          label={label}
          value={newValue}
          onChange={(e) => setNewValue(e.target.value)}
        />
        <Box display="flex" justifyContent="flex-end" mt="0.5rem">
          <Button
            variant="contained"
            onClick={() => onUpdate(newValue)}
            sx={{ textTransform: "none", borderRadius: "1rem" }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={20} /> : tGeneral("update")}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}

export { UpdateTextFieldModal };
