import { Close, Delete, Edit, MoreVert } from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCommentObjectMutation } from "../../features/message/messageApiSlice";
import { MessageBox } from "../question/MessageBox";
import { MessageReply } from "../question/MessageReply";
import { useState } from "react";
import { TooltipUsers } from "../shared";

function DrawingsAnnotationComments({ annotation, onEdit, onDelete }) {
  const { t: tGeneral } = useTranslation("general");
  const [anchorEl, setAnchorEl] = useState(null);
  const [commentAnnotation, { isLoading }] = useCommentObjectMutation();
  const handleReply = (message) => {
    commentAnnotation({
      idObject: annotation.id,
      comment: message,
      module: "drawings",
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        height: "100%",
        pt: "5%",
      }}
    >
      <Box display="flex" mb="10px">
        <Box flex={1} display="flex">
          {annotation.assignedTo.length > 1 ? (
            <TooltipUsers
              users={annotation.assignedTo.map((user) => ({
                ...user,
                name: `${user.firstName} ${user.lastName}`,
              }))}
            >
              <Box display="flex" alignItems="center" columnGap="10px">
                <AvatarGroup max={3}>
                  {annotation.assignedTo.map((user) => (
                    <Avatar
                      src={user?.urlAvatar}
                      sx={{
                        backgroundColor: "#FFC42D",
                        border: "1px solid white",
                      }}
                    >
                      {user?.firstName[0]}
                      {user?.lastName[0]}
                    </Avatar>
                  ))}
                </AvatarGroup>
                <Typography>
                  {annotation.assignedTo[0].firstName}{" "}
                  {annotation.assignedTo[0].lastName} +
                  {annotation.assignedTo.length - 1}
                </Typography>
              </Box>
            </TooltipUsers>
          ) : (
            <Box display="flex" alignItems="center" columnGap="10px">
              <Avatar
                src={annotation.assignedTo[0]?.urlAvatar}
                sx={{ backgroundColor: "#FFC42D", border: "1px solid white" }}
              >
                {annotation.assignedTo[0]?.firstName[0]}
                {annotation.assignedTo[0]?.lastName[0]}
              </Avatar>
              <Typography>
                {annotation.assignedTo[0].firstName}{" "}
                {annotation.assignedTo[0].lastName}
              </Typography>
            </Box>
          )}
        </Box>
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <MoreVert />
        </IconButton>
        <Menu
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
        >
          <MenuItem onClick={onEdit}>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText>{tGeneral("edit")}</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <Delete color="error" />
            </ListItemIcon>
            <ListItemText>
              <Typography color="error">{tGeneral("delete")}</Typography>
            </ListItemText>
          </MenuItem>
        </Menu>
      </Box>
      {annotation.comments.map((comment) => (
        <Box key={comment.id} display="flex" position="relative">
          <Avatar
            src={comment.urlAvatar}
            sx={{ backgroundColor: "#FFC42D", border: "1px solid white" }}
          >
            {comment.firstName[0]}
            {comment.lastName[0]}
          </Avatar>
          <MessageBox
            createdBy={`${comment.firstName} ${comment.lastName}`}
            createdDate={comment.createdDate}
            files={comment.files}
            text={comment.comment}
            question
            width="80%"
          />
        </Box>
      ))}
      <MessageReply
        showConnection={false}
        enableFiles={false}
        placeholder={tGeneral("newComment")}
        reply={handleReply}
        isLoading={isLoading}
      />
    </Box>
  );
}

export { DrawingsAnnotationComments };
