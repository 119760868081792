import { Box, Typography, useMediaQuery } from "@mui/material";

function Feature({ icon, label, reverse = false }) {
  const match = useMediaQuery("(max-width: 600px)");

  return (
    <Box
      display="flex"
      flexDirection={reverse ? "row-reverse" : "reverse"}
      justifyContent="flex-end"
      alignItems="center"
    >
      <Typography
        fontWeight="medium"
        sx={[
          { fontSize: match ? "16px" : "26px", maxWidth: "320px" },
          reverse ? { marginLeft: "40px" } : { marginRight: "40px" },
        ]}
      >
        {label}
      </Typography>
      <img src={icon} alt={label} />
    </Box>
  );
}

export { Feature };
