import { Box, Card, IconButton, Popper, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import DWGIcon from "../../assets/files/dwg.png";
import PDFIcon from "../../assets/files/pdf.png";
import { selectCurrentGuideMe } from "../../features/project/projectSlice";
import { useAppTourContext } from "../supportButton/context/context";

function HoverRevisions({ revisions, lastRev, isTour }) {
  const { t: tGeneral } = useTranslation("general");
  const htmlRef = useRef();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isBlocked, setIsBlocked] = useState(false);

  const handleAnchorEl = (event) => {
    if (!isBlocked) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  };

  const handleDownloadFile = (url) => {
    const link = document.createElement("a");
    const fileName = url.split("/");

    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = xhr.response;
        const objectUrl = URL.createObjectURL(blob);

        link.href = objectUrl;
        link.setAttribute("download", fileName[fileName.length - 1]);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        URL.revokeObjectURL(objectUrl);
      }
    };
    xhr.send();
  };

  const {
    state: { tourActive, stepIndex, steps },
    setState,
  } = useAppTourContext();
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const isOpen = Boolean(anchorEl);

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 0 &&
      currentGuideMe === "drawings-export" &&
      isTour
    ) {
      if (isTour) {
        setIsBlocked(true);
      }
    }

    if (
      tourActive &&
      stepIndex === 1 &&
      currentGuideMe === "drawings-export" &&
      isTour
    ) {
      if (isBlocked) {
        setAnchorEl(null);
      }
    }

    if (
      tourActive &&
      stepIndex === 2 &&
      currentGuideMe === "drawings-export" &&
      htmlRef.current &&
      isTour
    ) {
      setAnchorEl(htmlRef.current);
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }

    if (
      tourActive &&
      stepIndex === 3 &&
      currentGuideMe === "drawings-export" &&
      htmlRef.current &&
      isTour
    ) {
      setIsBlocked(true);
      setAnchorEl(htmlRef.current);
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }

    if (tourActive && stepIndex === steps.length - 1) {
      setAnchorEl(null);
      setIsBlocked(false);
    }
  }, [tourActive, stepIndex, steps, currentGuideMe]);

  return (
    <Box
      sx={{
        backgroundColor: "#FFEBBC",
        color: "#D6A424",
        width: "40px",
        height: "30px",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      component="div"
      onMouseEnter={handleAnchorEl}
      onMouseLeave={handleAnchorEl}
      ref={htmlRef}
      data-tour="export-drawings-3"
    >
      <Typography>{lastRev.number}</Typography>
      <Popper
        open={isOpen}
        anchorEl={anchorEl}
        sx={{
          zIndex: 3,
          maxHeight: "300px",
          overflow: "scroll",
          py: 0.5,
          px: 0.3,
        }}
        data-tour="export-drawings-4"
      >
        <Card sx={{ borderRadius: "10px", p: "10px" }}>
          <Box display="flex" columnGap="12px">
            <Typography fontSize="0.75rem" minWidth="40px" fontWeight="bold">
              #Rev
            </Typography>
            <Typography fontSize="0.75rem" fontWeight="bold">
              {tGeneral("publishedDate")}
            </Typography>
          </Box>
          {revisions.map((rev) => (
            <Box
              display="flex"
              key={rev.id}
              columnGap="12px"
              alignItems="center"
            >
              <Typography textAlign="center" fontSize="0.75rem" minWidth="40px">
                {rev.number}
              </Typography>
              <Typography fontSize="0.75rem">
                {moment(rev.publishedDate).format("D MMMM yyyy")}
              </Typography>
              <Box display="flex" alignItems="center">
                {rev.drawingFile && (
                  <IconButton
                    onClick={() => handleDownloadFile(rev.drawingFile)}
                  >
                    <img src={DWGIcon} alt="DWG File" width="30px" />
                  </IconButton>
                )}
                <IconButton onClick={() => handleDownloadFile(rev.pdfFile)}>
                  <img src={PDFIcon} alt="PDF File" width="30px" />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Card>
      </Popper>
    </Box>
  );
}

export { HoverRevisions };
