import { apiSlice } from "../../api/apiSlice";
import { objectToQueryString } from "../../utils";

const designTemplateApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createDesignTemplate: builder.mutation({
      query: (body) => ({
        url: "designTemplates/create",
        body,
        method: "POST",
      }),
      invalidatesTags: ["DesignTemplates"],
    }),
    getDesignTemplates: builder.query({
      query: ({ idEnterprise, ...params }) =>
        `designTemplates/${idEnterprise}${objectToQueryString({
          ...params,
        })}`,
      providesTags: ["DesignTemplates"],
    }),
    getDesignTemplatedEditedByUserList: builder.query({
      query: (idEnterprise) => `designTemplates/${idEnterprise}/editedByList`,
    }),
    getDesignTemplateDetails: builder.query({
      query: (idDesignTemplate) => `designTemplates/detail/${idDesignTemplate}`,
      providesTags: ["DesignTemplates"],
    }),
    getModifiedByDesignTemplateId: builder.query({
      query: (idDesignTemplate) =>
        `designTemplates/modifiedBy/${idDesignTemplate}`,
    }),
    updateDesignTemplate: builder.mutation({
      query: ({ idDesignTemplate, body }) => ({
        url: `designTemplates/${idDesignTemplate}`,
        body,
        method: "PUT",
      }),
      invalidatesTags: ["DesignTemplates"],
    }),
    duplicateDesignTemplate: builder.mutation({
      query: (idDesignTemplate) => ({
        url: `designTemplates/duplicate/${idDesignTemplate}`,
        method: "POST",
      }),
      invalidatesTags: ["DesignTemplates"],
    }),
    deleteDesignTemplate: builder.mutation({
      query: (idDesignTemplate) => ({
        url: `designTemplates/delete/${idDesignTemplate}`,
        method: "POST",
      }),
      invalidatesTags: ["DesignTemplates"],
    }),
  }),
});

export const {
  useCreateDesignTemplateMutation,
  useGetDesignTemplatesQuery,
  useGetDesignTemplatedEditedByUserListQuery,
  useGetDesignTemplateDetailsQuery,
  useGetModifiedByDesignTemplateIdQuery,
  useUpdateDesignTemplateMutation,
  useDuplicateDesignTemplateMutation,
  useDeleteDesignTemplateMutation,
} = designTemplateApiSlice;
