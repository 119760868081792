import { Close, Edit } from "@mui/icons-material";
import { Box, Button, Chip, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function SubtaskItemForm({ subtask, index, setSelectedItem, setModifiedDescription }) {
    const { t: tGeneral } = useTranslation("general");

    const [isSelected, setIsSelected] = useState(false);
    const [description, setDescription] = useState("");

    useEffect(() => {
        setDescription(subtask?.description);
    }, [subtask]);

    return (
        <Box sx={{width: "100%"}}>
            {!isSelected ? (
                <Chip
                    label={
                        <Box display="flex" alignItems="center" sx={{width: "100%"}}>
                            <Typography
                                sx={{
                                    width: "94%",
                                    overflow: "auto",
                                }}
                            >
                                {description}
                            </Typography>
                            <IconButton
                                onClick={() => {
                                    setIsSelected(true);
                                }}
                                sx={{
                                    ml: 0.5,
                                    width: "5%",
                                    ":hover": {
                                        bgcolor: "transparent",
                                    }
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </Box>
                    }
                    sx={{
                        bgcolor: "#FFEBBC",
                        color: "#B48100",
                        ":hover": {
                            bgcolor: "#FFEBBC",
                        },
                        fontSize: "16px",
                    }}
                    deleteIcon={<Close style={{ color: "red" }} />}
                    onDelete={(e) => {
                        setSelectedItem(index);
                    }}
                />
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <TextField
                        autoFocus={isSelected}
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value);
                        }}
                        // onBlur={(e) => {
                        //     setIsSelected(false);
                        // }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment sx={{ mb: 0.5 }}>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            if (description.length > 0) {
                                                setModifiedDescription(description);
                                                setIsSelected(false);
                                            }
                                        }}
                                    >
                                        {tGeneral("update").toUpperCase()}
                                    </Button>
                                </InputAdornment>
                            ),
                            multiline: true,
                            maxRows: 10,
                        }}
                        sx={{
                            backgroundColor: "white",
                        }}
                    />
                </Box>
            )}
        </Box>
    );
}

export default SubtaskItemForm;