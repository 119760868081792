// @ts-check
/* eslint react/prop-types: 0 */

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, ClickAwayListener, Grow, Paper, Popper } from "@mui/material";
// eslint-disable-next-line no-unused-vars
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Group(props) {
  const { handleCreateGroup, handleCreateActivity, handleCreateMilestone, handleDeleteGroup } = props;

  const ref = useRef(null);

  const [open, setOpen] = useState(false);

  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  return (
    <>
      <MoreVertIcon
        fontSize="small"
        onClick={() => {
          setOpen((open) => !open);
        }}
        ref={ref}
        sx={{
          cursor: "pointer",
        }}
      />
      <Popper
        anchorEl={ref.current}
        open={open}
        placement="bottom-start"
        role={undefined}
        sx={{
          margin: "5px 0 0 10px",
          zIndex: 1201,
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "left top",
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={(event) => {
                  // @ts-ignore
                  if (ref.current && ref.current.contains(event.target)) {
                    return;
                  }
                  setOpen(false);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 0",
                    width: "250px",
                  }}
                >
                  <Box
                    onClick={() => {
                      handleCreateGroup();
                      setOpen(false);
                    }}
                    sx={{
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "7.5px 20px",
                      "&:hover": {
                        backgroundColor: "#eeeeee",
                      },
                    }}
                  >
                    <small>{tProjectSchedule("Create Group")}</small>
                  </Box>
                  <Box
                    onClick={() => {
                      handleCreateActivity({});
                      setOpen(false);
                    }}
                    sx={{
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "7.5px 20px",
                      "&:hover": {
                        backgroundColor: "#eeeeee",
                      },
                    }}
                  >
                    <small>{tProjectSchedule("Create Activity")}</small>
                  </Box>
                  <Box
                    onClick={() => {
                      handleCreateMilestone();
                      setOpen(false);
                    }}
                    sx={{
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "7.5px 20px",
                      "&:hover": {
                        backgroundColor: "#eeeeee",
                      },
                    }}
                  >
                    <small>{tProjectSchedule("Create Milestone")}</small>
                  </Box>
                  <Box
                    onClick={() => {
                      handleDeleteGroup();
                      setOpen(false);
                    }}
                    sx={{
                      backgroundColor: "transparent",
                      color: "#990000",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "7.5px 20px",
                      "&:hover": {
                        backgroundColor: "#eeeeee",
                      },
                    }}
                  >
                    <small>{tProjectSchedule("Delete Group")}</small>
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
