import {
  Add,
  ContactSupport,
  Edit,
  Restore,
  Settings,
  Visibility,
} from "@mui/icons-material";
import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded";
import GroupsIcon from "@mui/icons-material/Groups";
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router";
import DailyReports from "../../assets/workflow/DailyReports.png";
import ReportesDiarios from "../../assets/workflow/ReportesDiarios.png";
import { setCurrentGuideMe } from "../../features/project/projectSlice";
import { selectLocale } from "../../features/preferences/preferencesSlice";
import { useGetDailyReportsQuery } from "../../features/project/modules/dailyReports/dailyReportsApiSlice";
import { setSampleDailyReportId } from "../../features/project/modules/rfiSlice";
import { useDailyReportSteps } from "./components/steps/dailyReport";
import { useGuideMeModal } from "./hooks/useGuideMeModal";
// Support Menu
import { SupportMenu } from "./components/SupportMenu";
import { SupportMenuModalMetting } from "./components/modals/SupportMenuModalMetting";
import { SupportMenuItemHeader } from "./components/SupportMenuItemHeader";
import { SupportContent } from "./components/SupportContent";
import { SupportListItem } from "./components/SupportListItem";
import { SupportMenuModalWorkflow } from "./components/modals/SupportMenuModalWorkflow";
import { SupportMenuModalGuideMe } from "./components/modals/SupportMenuModalGuideMe";

function SupportButtonProjectDirectory() {
  const dispatch = useDispatch();

  const { t: translation } = useTranslation("dailyReports");
  const { idProject } = useParams();

  const [recordsLength, setRecordsLength] = useState(0);
  const [sampleReport, setSampleReport] = useState();
  const locale = useSelector(selectLocale);
  const [imgWorkflow] = useState(
    locale === "en" ? DailyReports : ReportesDiarios,
  );

  const { data: dailyReports } = useGetDailyReportsQuery(
    { idProject },
    { skip: !idProject },
  );

  const [
    {
      addSteps,
      viewSteps,
      editSteps,
      recycleSteps,
      exportSteps,
      settingsSteps,
      viewWorkProgressSteps,
      editWorkProgressSteps,
      viewProblemSteps,
      editProblemSteps,
      viewDelaySteps,
      editDelaySteps,
    },
  ] = useDailyReportSteps();

  // Support button (make component)
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [anchorSupp, setAnchorSupp] = useState(null);
  // For open
  const handleClick = (event) => {
    setAnchorSupp(event.currentTarget);
    setMenuIsOpen(true);
  };
  // For close
  const handleClose = () => {
    setAnchorSupp(null);
    setMenuIsOpen(false);
  };
  // End support button

  // Meeting modal
  const [meetingModalIsOpened, setMeetingModalIsOpened] = React.useState(false);
  const openMeetingModal = () => setMeetingModalIsOpened(true);
  const closeMeetingModal = () => setMeetingModalIsOpened(false);
  // Workflow modal
  const [workflowModalIsOpened, setWorkflowModalIsOpened] =
    React.useState(false);
  const openWorkflowModal = () => setWorkflowModalIsOpened(true);
  const closeWorkflowModal = () => setWorkflowModalIsOpened(false);
  // guide me modal
  const [guideModalIsOpened, setGuideModalIsOpened] = React.useState(false);
  const openGuideModal = () => setGuideModalIsOpened(true);
  const closeGuideModal = () => setGuideModalIsOpened(false);

  // Tutorial Guide Me
  const [{ handleGuideMe }] = useGuideMeModal({
    recycleSteps,
    exportSteps,
    settingsSteps,
    addSteps,
    viewSteps, // Basic
    editSteps, // Basic
    reports: {
      viewWorkProgressSteps, // Work progress
      editWorkProgressSteps, // Work progress
      viewProblemSteps, // Problem
      editProblemSteps, // Problem
      viewDelaySteps, // Delay
      editDelaySteps, // Delay
    },
  });

  useEffect(() => {
    if (dailyReports && dailyReports?.length > 0) {
      const dailyReportsTemp = JSON.parse(
        JSON.stringify(
          dailyReports?.reduce((filtered, report) => {
            if (report?.bActive === true) {
              const reportData = {
                ...report,
                dueDate: report?.dueDate?.split("T")?.[0],
                closeDate: report?.closeDate?.split("T")?.[0],
              };
              filtered.push(reportData);
            }
            return filtered;
          }, []),
        ),
      );
      dispatch(setSampleDailyReportId(dailyReportsTemp[0].id));
      setSampleReport(dailyReportsTemp[0]);
      setRecordsLength(dailyReportsTemp.length);
    } else {
      setRecordsLength(0);
    }
  }, [dailyReports, dispatch]);

  return (
    <div>
      {/* Button */}
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={translation("support")}
      >
        <IconButton
          id="support-button"
          aria-controls={menuIsOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={menuIsOpen ? "true" : undefined}
          sx={{ color: "#007FFF" }}
          onClick={handleClick}
        >
          <ContactSupport />
        </IconButton>
      </Tooltip>

      <SupportMenu anchor={anchorSupp} open={menuIsOpen} onClose={handleClose}>
        {/* Meeting */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openMeetingModal();
          }}
        >
          <SupportMenuItemHeader title={translation("meeting")}>
            <GroupsIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalMetting
          open={meetingModalIsOpened}
          onClose={closeMeetingModal}
        />
        {/* Workflow */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openWorkflowModal();
          }}
        >
          <SupportMenuItemHeader title={translation("workflow")}>
            <AppRegistrationRoundedIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalWorkflow
          open={workflowModalIsOpened}
          diagramImageUrl={imgWorkflow}
          onClose={closeWorkflowModal}
        />

        {/* Guide Me */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openGuideModal();
          }}
        >
          <SupportMenuItemHeader title={translation("guideMe")}>
            <TouchAppRoundedIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalGuideMe
          title={`${translation("guideMe")} ${translation("moduleElement")}`}
          open={guideModalIsOpened}
          onClose={closeGuideModal}
        >
          <SupportContent>
            {/* ADD */}
            <SupportListItem
              can="add"
              moduleName="dailyReports"
              onClick={() => {
                handleGuideMe("reports.add");
                dispatch(setCurrentGuideMe("daily-reports-add"));
                closeGuideModal();
              }}
            >
              <Add sx={{ marginRight: 1 }} />
              {`${translation("addButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* VIEW */}
            <SupportListItem
              can="view"
              moduleName="dailyReports"
              onClick={() => {
                if (sampleReport) {
                  switch (sampleReport.category) {
                    case 1:
                      handleGuideMe("view");
                      dispatch(setCurrentGuideMe("daily-reports-view"));
                      break;
                    case 2:
                      handleGuideMe("reports.viewWorkProgress");
                      dispatch(setCurrentGuideMe("reports-view-work-progress"));
                      break;
                    case 3:
                      handleGuideMe("reports.viewProblem");
                      dispatch(setCurrentGuideMe("reports-view-problem"));
                      break;
                    case 4:
                      handleGuideMe("reports.viewDelay");
                      dispatch(setCurrentGuideMe("reports-view-delay"));
                      break;
                    default:
                      break;
                  }
                }
                closeGuideModal();
              }}
              disabled={recordsLength === 0}
            >
              <Visibility sx={{ marginRight: 1 }} />
              {`${translation("viewButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* EDIT */}
            <SupportListItem
              can="edit"
              moduleName="dailyReports"
              onClick={() => {
                if (sampleReport) {
                  switch (sampleReport.category) {
                    case 1:
                      handleGuideMe("reports.editBasic");
                      dispatch(setCurrentGuideMe("daily-reports-edit"));
                      break;
                    case 2:
                      handleGuideMe("reports.editWorkProgress");
                      dispatch(setCurrentGuideMe("reports-edit-work-progress"));
                      break;
                    case 3:
                      handleGuideMe("reports.editProblem");
                      dispatch(setCurrentGuideMe("reports-edit-problem"));
                      break;
                    case 4:
                      handleGuideMe("reports.editDelay");
                      dispatch(setCurrentGuideMe("reports-edit-delay"));
                      break;
                    default:
                      break;
                  }
                }
                closeGuideModal();
              }}
              disabled={recordsLength === 0}
            >
              <Edit sx={{ marginRight: 1 }} />
              {`${translation("editButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* RECYCLE BIN */}
            <SupportListItem
              can="restore"
              moduleName="dailyReports"
              onClick={() => {
                handleGuideMe("recycle");
                dispatch(setCurrentGuideMe("daily-reports-recycle"));
                closeGuideModal();
              }}
              disabled={recordsLength === 0}
            >
              <Restore sx={{ marginRight: 1 }} />
              {`${translation("recycleButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* EXPORT */}
            {/* <SupportListItem
              can="export"
              moduleName={"dailyReports"}
              onClick={() => {
                handleGuideMe("export");
                dispatch(setCurrentGuideMe("daily-reports-export"));
                closeGuideModal();
              }}
              disabled={recordsLength === 0}
            >
              <PictureAsPdf sx={{ marginRight: 1 }} />
              {`${translation("exportButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem> */}
            {/* SETTINGS */}
            <SupportListItem
              can="settings"
              moduleName="dailyReports"
              disabled={recordsLength === 0}
              onClick={() => {
                handleGuideMe("settings");
                dispatch(setCurrentGuideMe("daily-reports-settings"));
                closeGuideModal();
              }}
            >
              <Settings sx={{ marginRight: 1 }} />
              {`${translation("settingsButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
          </SupportContent>
        </SupportMenuModalGuideMe>
      </SupportMenu>
    </div>
  );
}

export default SupportButtonProjectDirectory;
