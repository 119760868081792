import { tour } from "./tour";

export const projectDirectory = {
  ...tour,
  moduleElement: "User",
  moduleName: "Directory",
  // ADD - guide me steps
  step1_addUser_title: "1. Add new user to project",
  step1_addUser_text: "Click here to add a new user to the project.",
  step2_addUser_title: "2. Select user",
  step2_addUser_text:
    "Enter the full name of the user, next you will need to choose one user from the list.",
  step3_addUser_title: "3. Position",
  step3_addUser_text: "Enter the position that the user has in the project.",
  step4_addUser_title: "4. Default role",
  step4_addUser_text:
    "Select a default role for the contact, whether they can only view and not edit, or if they will have no permissions at all.",
  step5_addUser_title: "5. Individual Permissions",
  step5_addUser_text: "Select a specific role for each module individually.",
  step6_addUser_title: "6. Confirm",
  step6_addUser_text: "Click here to confirm and add the user.",
  step_final_addUser: "You have learned how to add a new user to the project.",
  // VIEW - guide me steps
  step1_viewUser_title: "1. View user's information.",
  step1_viewUser_text: "Click here to visualize user's information.",
  step2_viewUser_title: "2. Last login.",
  step2_viewUser_text:
    "Here, you can see information about the last login from the user.",
  step3_viewUser_title: "3. Recent actions.",
  step3_viewUser_text:
    "Here, you can see information about the recent actions done by the user.",
  step_final_viewUser:
    "You have learned how to visualize project user's information.",
  // EDIT - guide me steps
  step1_editUser_title: "1. Edit user.",
  step1_editUser_text: "Click here to edit a user.",
  step2_editUser_title: "2. Position.",
  step2_editUser_text: "Edit the position that the contact has in the project.",
  step3_editUser_title: "3. Default role.",
  step3_editUser_text:
    "Edit the default role for the contact, whether they can only view and not edit, or if they will have no permissions at all.",
  step4_editUser_title: "4. Individual Permissions.",
  step4_editUser_text: "Edit the roles for each module individually.",
  step5_editUser_title: "5. Save",
  step5_editUser_text: "Click here to save the changes.",
  step_final_editUser: "You have learned how to edit a user.",

  directory: "Directory",
  addCompany: "ADD COMPANY",
  lastLogin: "Last Login",
  infoText: " hasn't connected in ",
  days: " days",
  wasConnectedToday: " was connected in the last 24h",
  hasNeverLoggedIn: " has never logged in ",
  latestActivity: "Latest Activity",
  createRfi: "Create RFI 24",
  responseSubmittal: "Response Submittal",
  createDailyReport: "Create Daily Report",
  createRfi123: "Create RFI 123",
  uploadPhotos: "Upload Photos",
  newContact: "New Contact",
  editContact: "Edit Contact",
  generalInformation: "General Information",
  permissions: "Permissions",
  drawings: "Drawings",
  punchList: "Punch List",
  rfis: "RFIs",
  submittals: "Submittals",
  deliveries: "Deliveries",
  hours: "Hours",
  meetings: "Meetings",
  photos: "Photos",
  tasks: "Tasks",
  inspections: "Inspections",
  issues: "Issues",
  dailyReports: "Daily Reports",
  documents: "Documents",
  delays: "Delays",
  announcements: "Announcements",
  dashbooard: "Dashboard",
  adminPanel: "Admin Panel",
  generalDirectory: "General Directory",
  deleteContact: "Delete Contact",
  confirmDelete:
    "Are you sure you want to delete this contact? This action cant be reverted",
  currentPlan: "Current Plan ",
  activeUsers: " active users.",
  type: "Type",
  user: "User",
  contact: "Contact",
  usersLimit: "Users Limit",
  confirmUsersLimit:
    "Are you sure you want to continue and add a new user? This action will add an extra user to your paycheck. ",
  deletedRoleMessage:
    "This user's current role has been deleted, please select a new one",
};
