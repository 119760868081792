import { tour } from "./tour";

const roles = {
  // general tour
  ...tour,
  moduleElement: "ロール",
  // ADD - guide me steps
  step1_addRole_title: "1. ロールを追加する",
  step1_addRole_text: "新しいロールを追加するにはここをクリックしてください。",
  step2_addRole_title: "2. ロール名",
  step2_addRole_text: "作成するロールに名前を付けてください。",
  step3_addRole_title: "3. 権限",
  step3_addRole_text:
    "ロールが持つ各モジュールの権限タイプを選択してください。",
  step4_addRole_title: "4. 追加",
  step4_addRole_text:
    "ロールをロールのリストに追加するにはここをクリックしてください。",
  step_final_addRole:
    "新しいロールをロールのリストに追加する方法を学びました。",
  // EDIT - guide me steps
  step1_editRole_title: "1. ロールを編集する",
  step1_editRole_text: "ロールを編集するにはここをクリックしてください。",
  step2_editRole_title: "2. ロール名",
  step2_editRole_text: "ロールの名前を編集してください。",
  step3_editRole_title: "3. 権限",
  step3_editRole_text:
    "ロールが持つ各モジュールの権限タイプを編集してください。",
  step4_editRole_title: "4. 保存",
  step4_editRole_text:
    "ロールに対して行われた変更を保存するにはここをクリックしてください。",
  step_final_editRole: "ロールを更新する方法を学びました。",
  // DELETE - guide me steps
  step1_deleteRole_title: "1. ロールを削除する",
  step1_deleteRole_text: "ロールを削除するにはここをクリックしてください。",
  step2_deleteRole_title: "2. 受け入れる",
  step2_deleteRole_text: "削除を確認するにはここをクリックしてください。",
  step_final_deleteRole: "ロールを削除する方法を学びました。",
  // CHANGE ROLE - guide me steps
  step1_changeRole_title: "1. ユーザーロール",
  step1_changeRole_text: "ロールを変更するユーザーを選択してください。",
  step2_changeRole_title: "2. ロールタイプ",
  step2_changeRole_text: "ユーザーが持つロールを選択してください。",
  step3_changeRole_title: "3. 保存",
  step3_changeRole_text: "変更を保存するにはここをクリックしてください。",
  step_final_changeRole: "ユーザーのロールを変更する方法を学びました。",
};

export { roles };
