import { apiSlice } from "../../api/apiSlice";

const annotationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAnnotations: builder.query({
      query: (idObject) => `annotation/${idObject}`,
      providesTags: ["Annotations"],
    }),
    createAnnotation: builder.mutation({
      query: (body) => ({
        url: "annotation",
        body,
        method: "POST",
      }),
      invalidatesTags: ["Annotations"],
    }),
    assignAnnotation: builder.mutation({
      query: (body) => ({
        url: "annotation/assign",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Annotations"],
    }),
    publishAnnotation: builder.mutation({
      query: (body) => ({
        url: "annotation/publish",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Annotations"],
    }),
    linkAnnotation: builder.mutation({
      query: (body) => ({
        url: "annotation/link",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Annotations"],
    }),
    deleteAnnotation: builder.mutation({
      query: (body) => ({
        url: "annotation/delete",
        body,
        method: "PUT",
      }),
      invalidatesTags: ["Annotations"],
    }),
    updateAnnotation: builder.mutation({
      query: (body) => ({
        url: "annotation/",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Annotations"],
    }),
  }),
});

export const {
  useGetAnnotationsQuery,
  useAssignAnnotationMutation,
  useCreateAnnotationMutation,
  useDeleteAnnotationMutation,
  usePublishAnnotationMutation,
  useLinkAnnotationMutation,
  useUpdateAnnotationMutation,
} = annotationApiSlice;
