import { CloseOutlined, Facebook, Instagram } from "@mui/icons-material";
import { Drawer, Box, Button, Typography } from "@mui/material";

function DrawerMenu({ opened, onClose }) {
  return (
    <Drawer
      anchor="top"
      open={opened}
      sx={{ backgroundColor: "rgba(0, 0,0,0.9)" }}
      onClose={onClose}
      PaperProps={{ sx: { backgroundColor: "transparent", height: "100%" } }}
    >
      <Box sx={{ display: "flex", paddingX: "20%" }} mb="120px" mt="60px">
        <img
          src={require("./../../../assets/logo-dark.png")}
          style={{ width: "20%" }}
        />
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={onClose}
          variant="text"
          sx={{ color: "#fff", fontSize: "22px" }}
          endIcon={<CloseOutlined fontSize="lg" />}
        >
          Close
        </Button>
      </Box>
      <Box
        display="flex"
        sx={{
          paddingX: "20%",
          justifyContent: "space-around",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Button variant="text" sx={{ color: "white" }}>
            <Typography variant="h1">HOME</Typography>
          </Button>
          <Button variant="text" sx={{ color: "#404040" }}>
            <Typography variant="h1">PRICING</Typography>
          </Button>
          <Button variant="text" sx={{ color: "#404040" }}>
            <Typography variant="h1">SIGN UP</Typography>
          </Button>
          <Button variant="text" sx={{ color: "#404040" }}>
            <Typography variant="h1">LOG IN</Typography>
          </Button>
        </Box>
        <Box sx={{ alignContent: "center" }}>
          <Typography color="primary" fontSize="29px" mb="25px">
            Contact us
          </Typography>
          <Typography color="white" fontSize="18px" mb="26px">
            Contact@buildpeer.com
          </Typography>
          <Typography color="white" fontSize="18px" mb="70px">
            81 0000 0000 & 81 0000 0000
          </Typography>
          <Box display="flex">
            <Button
              variant="text"
              startIcon={<Instagram sx={{ width: 40, height: 40 }} />}
              sx={{ fontSize: "18px" }}
            >
              Instagram
            </Button>
            <Button
              variant="text"
              startIcon={<Facebook sx={{ width: 40, height: 40 }} />}
              sx={{ fontSize: "18px", marginLeft: "60px" }}
            >
              Facebook
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          borderTop: "1px solid #404040",
          width: "100%",
          height: "100px",
        }}
      >
        <Box display="flex" justifyItems="space-evenly">
          <Button variant="text" sx={{ color: "white" }}>
            Términos y condiciones
          </Button>
          <Button variant="text" sx={{ color: "white" }}>
            Aviso de privacidad
          </Button>
          <Button variant="text" sx={{ color: "white" }}>
            Derechos ARCO
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

export { DrawerMenu };
