export const announcements = {
  published: "Publicados",
  scheduled: "Agendados",
  newAnnouncement: "Novo Anúncio",
  announcements: "Anúncios",
  announcement: "Anúncio",
  generals: "Gerais",
  programmed: "Programados",
  expiration: "Expiração",
  creator: "Criador",
  repeat: "Repetir",
  nextPublication: "Próxima Publicação",
  programAnnouncement: "Programar anúncio",
  programAnnouncementNow:
    "O anúncio será publicado imediatamente ao adicioná-lo",
  programAnnouncementLater: "O anúncio será programado para depois",
  repeatList: "Lista de repetição",
  description: "Descrição",
  startDate: "Data de Início",
  expirationDate: "Data de Expiração",
  time: "Tempo",
  distributionList: "Lista de Distribuição",
  never: "Nunca",
  everyDay: "Todos os Dias",
  everyWeek: "Todas as Semanas",
  everyTwoWeeks: "A cada Duas Semanas",
  everyMonth: "Todos os Meses",
  everyYear: "Todos os Anos",
  noAnnouncements: "Não há anúncios criados no momento",
  addAnnouncement: "Adicionar Anúncio",
  noAnnouncementsPar:
    "Se desejar adicionar um, clique no botão de adicionar anúncios para iniciar o processo",
  deleteAnnouncement: "EXCLUIR ANÚNCIO",
  confirmDelete:
    "Tem certeza de que deseja excluir o anúncio? Essa ação não poderá ser revertida",
  recycleBin: "Lixeira de Anúncios",
  editAnnouncement: "Editar Anúncio",
  programForLater: "Programar para depois",
  addAnnouncementTitle: "Não há anúncios criados no momento",
  addAnnouncementValidation:
    "Se desejar adicionar um, clique no botão de adicionar para iniciar o processo",
  noAnnouncementsRestore: "Não há anúncios selecionados para restaurar",
  expire: "Expira",
  step1_addAnnouncement_title: "1. Criar um novo anúncio",
  step1_addAnnouncement_text:
    "Clique no ícone + para adicionar um novo anúncio.",
  step2_addAnnouncement_title: "2. Agendar anúncio",
  step2_addAnnouncement_text:
    "Escolha se o anúncio será publicado imediatamente ou se será agendado para mais tarde. Se escolher a segunda opção, opções adicionais serão exibidas.",
  step2_1_addAnnouncement_title: "2.1 Repetir",
  step2_1_addAnnouncement_text:
    "Escolha a frequência com que o anúncio se repetirá.",
  step2_2_addAnnouncement_title: "2.2 Data de início",
  step2_2_addAnnouncement_text:
    "Escolha a data em que o anúncio será publicado.",
  step2_3_addAnnouncement_title: "2.3 Hora de início",
  step2_3_addAnnouncement_text:
    "Escolha a hora em que o anúncio será publicado.",
  step3_addAnnouncement_title: "3. Descrição",
  step3_addAnnouncement_text: "Escreva o assunto do anúncio.",
  step4_addAnnouncement_title: "4. Lista de distribuição",
  step4_addAnnouncement_text:
    "Selecione as pessoas que receberão a notificação do anúncio.",
  step5_addAnnouncement_title: "5. Data de expiração",
  step5_addAnnouncement_text:
    "Escolha o dia em que o anúncio expirará / ficará invisível.",
  step6_addAnnouncement_title: "6. Hora de expiração",
  step6_addAnnouncement_text:
    "Escolha a hora em que o anúncio expirará e não será mais visível.",
  step7_addAnnouncement_title: "7. Adicionar",
  step7_addAnnouncement_text: "Clique aqui para publicar o anúncio.",
  step_final_addAnnouncement: "Você aprendeu como criar um anúncio",
  step1_viewAnnouncement_title: "1. Visualizar anúncio",
  step1_viewAnnouncement_text:
    "Clique aqui para ver as informações sobre o anúncio.",
  step2_viewAnnouncement_title: "2. Visualizar anúncio",
  step2_viewAnnouncement_text:
    "Na janela pop-up, você pode ver as informações relevantes sobre o anúncio, como a descrição e a data.",
  step_final_viewAnnouncement: "Você aprendeu como visualizar um anúncio",
  step1_editAnnouncement_title: "1. Editar anúncio",
  step1_editAnnouncement_text: "Clique aqui para editar um anúncio.",
  step2_editAnnouncement_title: "2. Editar programação do anúncio",
  step2_editAnnouncement_text:
    "Edite se o anúncio será publicado imediatamente ou se será publicado mais tarde; selecionar a segunda opção exibirá opções adicionais.",
  step2_1_editAnnouncement_title: "2.1 Editar repetição",
  step2_1_editAnnouncement_text:
    "Edite a frequência com que o anúncio se repetirá.",
  step2_2_editAnnouncement_title: "2.2 Data de início",
  step2_2_editAnnouncement_text: "Edite a data em que o anúncio começará.",
  step2_3_editAnnouncement_title: "2.3 Hora de início",
  step2_3_editAnnouncement_text: "Edite a hora em que o anúncio começará.",
  step3_editAnnouncement_title: "3. Descrição",
  step3_editAnnouncement_text: "Edite o assunto do anúncio.",
  step4_editAnnouncement_title: "4. Editar lista de distribuição",
  step4_editAnnouncement_text: "Edite quais pessoas poderão ver o anúncio.",
  step5_editAnnouncement_title: "5. Data de expiração",
  step5_editAnnouncement_text:
    "Edite o dia em que o anúncio não será mais válido / visível.",
  step6_editAnnouncement_title: "6. Hora de expiração",
  step6_editAnnouncement_text:
    "Edite a hora em que o anúncio expirará e não será mais visível.",
  step7_editAnnouncement_title: "7. Editar",
  step7_editAnnouncement_text: "Clique aqui para salvar as alterações.",
  step_final_editAnnouncement: "Você aprendeu como editar um anúncio",
  step1_recycleAnnouncement_title: "1. Mais opções",
  step1_recycleAnnouncement_text: "Clique aqui para ver mais opções.",
  step2_recycleAnnouncement_title: "2. Lixeira de anúncios",
  step2_recycleAnnouncement_text:
    "Clique aqui para visualizar a lixeira de anúncios.",
  step3_recycleAnnouncement_title: "3. Menu da lixeira",
  step3_recycleAnnouncement_text: "Este é o menu da lixeira de anúncios.",
  step4_recycleAnnouncement_title: "4. Visualizar anúncio",
  step4_recycleAnnouncement_text:
    "Clique aqui para visualizar as informações do anúncio que deseja restaurar.",
  step5_recycleAnnouncement_title: "5. Selecionar anúncios",
  step5_recycleAnnouncement_text:
    "Selecione um ou vários anúncios para restaurar.",
  step6_recycleAnnouncement_title: "6. Restaurar anúncios selecionados",
  step6_recycleAnnouncement_text:
    "Clique aqui para restaurar os anúncios selecionados.",
  step_final_recycleAnnouncement:
    "Você aprendeu como interagir com a lixeira de anúncios",
  guideMe: "Guie-me",
  support: "Suporte",
};
