import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import { Outlet, Route, Routes, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useGetEnterpriseByIdQuery } from "../../features/enterprise/enterpriseApiSlice";
import ProjectsList from "./ProjectsList";

const Warning = require("../../assets/icons/modals/warning-red.png");

function NonPaymentModal({ open }) {
  const { t: tGeneral } = useTranslation("general");

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      fullWidth
      maxWidth="lg"
      hideBackdrop
      disableEnforceFocus
      disableAutoFocus
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        marginRight: " -50%",
        transform: "translate(-50%, -50%)",
        width: "fit-content",
        height: "fit-content",
      }}
    >
      <DialogContent sx={{ width: "450px", height: "390px" }}>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          alignItems="center"
          p={3}
          justifyContent="center"
          height="100%"
        >
          <img
            src={Warning}
            style={{ width: "222px", height: "152px" }}
            alt=""
          />
          <Typography fontSize="24px" fontWeight="700" color="#1F1F1F">
            {tGeneral("unavailableEnterprise")}
          </Typography>
          <Typography
            fontSize="16px"
            fontWeight="400"
            color="#4B4B4B"
            textAlign="center"
          >
            <Trans
              t={tGeneral}
              i18nKey="contactSalesForPayment"
              // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid
              components={{ a: <a /> }}
            />
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

function NonPayment() {
  const { idEnterprise } = useParams();

  const { data: currentEnterprise, isLoading } = useGetEnterpriseByIdQuery(
    idEnterprise,
    {
      skip: !idEnterprise,
    },
  );

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (currentEnterprise && !currentEnterprise.billingActive) {
    return (
      <>
        <Routes>
          <Route index path="dashboard" element={<ProjectsList />} />
        </Routes>

        <NonPaymentModal open={!currentEnterprise.billingActive} />
      </>
    );
  }

  return <Outlet />;
}
export default NonPayment;
