import { ArrowBack } from "@mui/icons-material";
import { Box, Card, Modal, Typography } from "@mui/material";
import { useState } from "react";
import ForgeViewer from "react-forge-viewer";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  useGetBimQuery,
  useLazyGetAPSPublicTokenQuery,
} from "../../../features/project/modules/bim";
import { TooltipIconButton } from "../../shared";

function BimViewer({ isOpen, onClose }) {
  const { idBim } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const [getPublicToken] = useLazyGetAPSPublicTokenQuery();
  const { data: bim } = useGetBimQuery(idBim, { skip: !idBim });

  const [view, setView] = useState(null);
  const handleViewerError = (error) => {};

  /* after the viewer loads a document, we need to select which viewable to
  display in our component */
  const handleDocumentLoaded = (doc, viewables) => {
    if (viewables.length === 0) {
      console.error("Document contains no viewables.");
    } else {
      // Select the first viewable in the list to use in our viewer component
      setView(viewables[0]);
    }
  };

  const handleDocumentError = (viewer, error) => {};

  const handleModelLoaded = (viewer, model) => {};

  const handleModelError = (viewer, error) => {};

  const getForgeToken = async () => {
    const { data } = await getPublicToken();
    return { ...data };
  };

  /* Once the viewer has initialized, it will ask us for a forge token so it can
  access the specified document. */
  const handleTokenRequested = async (onAccessToken) => {
    if (onAccessToken) {
      const token = await getForgeToken();
      if (token) onAccessToken(token.access_token, token.expires_in);
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Card>
        <Box>
          <Box display="flex" alignItems="center">
            <TooltipIconButton
              onClick={onClose}
              icon={<ArrowBack />}
              label={tGeneral("back")}
            />
            <Typography>{bim?.name}</Typography>
          </Box>
          {bim?.urn && (
            <ForgeViewer
              version="6.0"
              urn={bim?.urn}
              view={view}
              headless={false}
              onViewerError={handleViewerError}
              onTokenRequest={handleTokenRequested}
              onDocumentLoad={handleDocumentLoaded}
              onDocumentError={handleDocumentError}
              onModelLoad={handleModelLoaded}
              onModelError={handleModelError}
            />
          )}
        </Box>
      </Card>
    </Modal>
  );
}

export { BimViewer };
