import { Business, ChevronRight } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import IconFolder from "../../../../assets/documents/folderTree.svg";
import IconFolderLock from "../../../../assets/documents/lockFolderTree.svg";
import { selectTheme } from "../../../../features/preferences/preferencesSlice";
import { useGetProjectFoldersQuery } from "../../../../features/project/modules/documents/projectFolderApiSlice";
import { useGetProjectByIdQuery } from "../../../../features/project/projectApiSlice";

function DirTree(props) {
  const { idProject } = useParams();
  const { selectedFolders, addPath, disabledFolder = null, search = null } = props;
  const darkMode = useSelector(selectTheme);
  const [folders, setFolders] = useState([]);
  const selectedFolderId = useMemo(() => {
    return selectedFolders?.length > 0
      ? selectedFolders[selectedFolders.length - 1].id
      : null;
  }, [selectedFolders]);

  const { data: currentProject } = useGetProjectByIdQuery(idProject);

  const { data } = useGetProjectFoldersQuery({
    idProject,
    idFolder: selectedFolderId,
    search: search ?? null,
  });

  useEffect(() => {
    if (data?.length > 0) {
      let orderedData = [...data];
      setFolders(orderedData.sort(function (a, b) {
        return a.name.localeCompare(b.name, 'en', { numeric: true, ignorePunctuation: true });
      }));
    }
  }, [data]);

  //  Set root

  const handleAddPath = (folder) => {
    addPath(folder, Boolean(folders.find((sf) => sf.id === selectedFolderId)));
  };

  return (
    <Box display="flex" flexDirection="column">
      <Button
        onClick={() => addPath(null)}
        disableRipple
        fullWidth
        sx={{
          color: !darkMode ? "black" : "white",
          textTransform: "none",
          backgroundColor: selectedFolderId ? "#fff" : "#FFF9ED",
          borderLeft: selectedFolderId ? "none" : "6px solid #FFC42D",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Business />
        <Typography sx={{ flex: 1 }}>{currentProject?.projectName}</Typography>
      </Button>
      {folders?.map((folder) => {
        const isSelected = folder.id === selectedFolderId;
        return (
          <Button
            key={folder.id}
            disabled={
              disabledFolder === null ? false : folder.id === disabledFolder
            }
            onClick={() => handleAddPath(folder)}
            variant="text"
            sx={{
              textTransform: "none",
              height: "50px",
              display: "flex",
              justifyContent: "flex-start",
              columnGap: "5px",
              backgroundColor: isSelected ? "#FFF9ED" : "#fff",
              borderLeft: isSelected ? "6px solid #FFC42D" : "none",
            }}
            color="secondary"
          >
            <img
              src={folder.bPrivate ? IconFolderLock : IconFolder}
              alt="Folder icon"
              height="40px"
            />
            <Typography noWrap textOverflow="ellipsis">
              {folder.name}
            </Typography>
            <Box flex={1} />
            {folder.haschildren && <ChevronRight />}
          </Button>
        );
      })}
    </Box>
  );
}

export default DirTree;
