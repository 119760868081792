import { useMemo, useState } from "react";

function usePagination(defaultPerPage = 100) {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(defaultPerPage);
  const [total, setTotal] = useState(0);

  const pages = useMemo(() => {
    return Math.ceil(total / perPage);
  }, [total, perPage]);

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handlePerPage = (newPerPage) => {
    setCurrentPage(0);
    setPerPage(newPerPage);
  };

  const handleLastPage = () => {
    setCurrentPage(pages - 1);
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
  };

  const handleTotalItems = (total) => {
    setTotal(total);
  };
  return {
    currentPage,
    pages,
    perPage,
    handlePerPage,
    handleNextPage,
    handlePrevPage,
    handleFirstPage,
    handleLastPage,
    handleTotalItems,
  };
}

export { usePagination };
