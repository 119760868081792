import React, { useEffect, useState } from "react";
// Hooks
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// Redux reducers & mutations
import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  FormControl,
} from "@mui/material";
import Swal from "sweetalert2";
import { useResetpasswordMutation } from "../features/auth/authApiSlice";
import { selectTheme } from "../features/preferences/preferencesSlice";
// Validators
import { passwordValidator } from "../services/validators/passwordValidator";
import { passwordRepeatValidator } from "../services/validators/passwordRepeatValidator";
// MUI Components
// Custom components
import LoadingOverlay from "../components/LoadingOverlay";
import { useTranslation } from "react-i18next";
import {
  CheckCircleOutline,
  HighlightOff,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";

function ResetPassword() {
  const { t: tGeneral } = useTranslation("general");
  const { t: tLogin } = useTranslation("login");
  const [password, setPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [errorPasswordRepeat, setErrorPasswordRepeat] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

  // Navigate and location hooks
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/login";
  const path = useLocation().pathname.toString();
  const token = path.substring(path.lastIndexOf("/") + 1);

  // We check if theme == dark to render proper logo dynamically
  const darkMode = useSelector(selectTheme);

  // Redux dispatch action
  const [resetPassword] = useResetpasswordMutation();

  // Handle submit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const resetPasswordSuccess = await resetPassword({
        token,
        password,
        passwordRepeat,
      }).unwrap();
      if (resetPasswordSuccess) {
        Swal.fire({
          icon: "success",
          title: tGeneral("changedPassword"),
          showConfirmButton: false,
          timer: 2000,
        });
        navigate(from, { replace: true });
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: tGeneral("error"),
        text:
          err?.status === 400 ? err?.data.validation.body.message : tLogin("errorLoginMessage"),
        showConfirmButton: false,
        timer: 2000,
      });
      console.error(err);
    }
  };
  useEffect(() => {
    if (password !== "" && passwordValidator(password) === -1) {
      setErrorPassword(true);
    } else {
      setErrorPassword(false);
    }
    if (
      passwordRepeat !== "" &&
      passwordRepeatValidator(password, passwordRepeat) === -1
    ) {
      setErrorPasswordRepeat(true);
    } else {
      setErrorPasswordRepeat(false);
    }
  }, [password, passwordRepeat]);

  return (
    <Grid container item xs={12} md={6} justifyContent="center">
      {/* Buildpeer logo */}
      <Grid item justifyContent="center" alignItems="center" xs={12}>
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            mt: 15,
            mb: 5,
          }}
        >
          <Box
            component="img"
            sx={{
              width: "40%",
              height: "40%",
            }}
            alt="Buildpeer Logo"
            src={
              darkMode
                ? require("../assets/logo-dark.png")
                : require("../assets/logo.png")
            }
          />
        </Box>
      </Grid>
      {/* Instructions */}
      <Grid item xs={12} justifyContent="center" alignItems="center">
        <Typography align="center" fontWeight="bold">
          {tGeneral("newPasswordTitle")}
        </Typography>
      </Grid>
      {/* Password input */}
      <Grid item xs={8} alignItems="center" justifyContent="center">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 1,
            mb: 1,
          }}
        >
          <FormControl sx={{ width: "100%" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password" required>
              {tGeneral("newPassword")}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              value={password}
              required
              label={tGeneral("newPassword")}
              placeholder={tGeneral("enterPassword")}
              type={showNewPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => {
                      setShowNewPassword(!showNewPassword);
                    }}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          {errorPassword ? (
            <HighlightOff sx={{ color: "red" }} />
          ) : (
            <CheckCircleOutline
              sx={{ color: password.length === 0 ? "gray" : "green" }}
            />
          )}
        </Box>
      </Grid>
      {/* Password repeat */}
      <Grid item xs={8} alignItems="center" justifyContent="center">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 1,
            mb: 1,
          }}
        >
          <FormControl sx={{ width: "100%" }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password" required>
              {tGeneral("repeatPassword")}
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-repeat-password"
              required
              label={tGeneral("repeatPassword")}
              placeholder={tGeneral("enterPassword")}
              type={showPasswordRepeat ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={(e) => {
                      setShowPasswordRepeat(!showPasswordRepeat);
                    }}
                    edge="end"
                  >
                    {showPasswordRepeat ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              value={passwordRepeat}
              onChange={(e) => setPasswordRepeat(e.target.value)}
              sx={{ width: "100%" }}
            />
          </FormControl>
          {errorPasswordRepeat ? (
            <HighlightOff sx={{ color: "red" }} />
          ) : (
            <CheckCircleOutline
              sx={{ color: passwordRepeat.length === 0 ? "gray" : "green" }}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography fontSize={12} sx={{ mt: 0, mb: 1 }}>
            {tGeneral("passwordNotice")}
          </Typography>
        </Box>
      </Grid>
      {/* Button Recover Password */}
      <Grid item xs={8} sx={{ my: 1 }}>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
          variant="contained"
          sx={{
            width: "100%",
            borderRadius: 3,
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
          }}
          disabled={
            errorPassword ||
            errorPasswordRepeat ||
            password === "" ||
            passwordRepeat === ""
          }
        >
          {tGeneral("changePassword")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default ResetPassword;
