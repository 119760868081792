import { apiSlice } from "../../api/apiSlice";

const companyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getActiveCompanies: builder.query({
      query: (idEnterprise) => `company/active/${idEnterprise}`,
      providesTags: ["Companies"],
    }),
    createCompany: builder.mutation({
      query: ({ idEnterprise, ...body }) => ({
        url: `company/${idEnterprise}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Companies"],
    }),
  }),
});

export const { useCreateCompanyMutation, useGetActiveCompaniesQuery } =
  companyApiSlice;
