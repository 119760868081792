import * as React from "react";

// Material UI Components
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useLocation, useNavigate } from "react-router";

import UserAvatar from "./UserAvatar";

const DrawerAvatar = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const user = props.user;

  return (
    <Box>
      <List>
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname === "/user/profile"}
            onClick={() => {
              navigate("/user/profile", { replace: true });
              props && props.toggle && props.toggle();
            }}
          >
            <ListItemIcon>
              <UserAvatar
                name={user?.firstName + " " + user?.lastName}
                size={"Normal"}
                url={user?.urlAvatar}
              />
            </ListItemIcon>
            <ListItemText primary={user?.firstName + " " + user?.lastName} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );
};

export default DrawerAvatar;
