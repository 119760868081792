const reportCategories = [
  {
    label: "basic",
    color: "#BFBFBF",
  },
  {
    label: "workProgress",
    color: "#5FC5F4",
  },
  {
    label: "problem",
    color: "#ED8B23",
  },
  {
    label: "delay",
    color: "#E9569F",
  },
];

export { reportCategories };
