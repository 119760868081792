export const login = {
  errorLoginTitle: "Login Failed",
  errorLoginAuthMessage: "Email and/or password don't match",
  errorLoginMessage: "Something went wrong. Try again later.",
  errorNoUser: "Username doesn't exist",
  welcomeBack: "Welcome Back!",
  password: "Password",
  logIn: "Log In",
  forgotPassword: "Forgot password?",
  errorRecoverTitle: "Recover password failed",
  followInstructions: "Please follow instructions",
  recoverPasswordTitle: "Please enter the email address of your Buildpeer account to receive instructions for your password reset",
  exampleEmail: "example@mail.com",
  recoverPassword: "Recover password",
  emailInvalid: "Email invalid",
};
