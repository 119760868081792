import Lottie from "lottie-react";
import { CircleSharp, Lock, LockOpen } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import animationData from "../../../projectDashboard/16627-firework.json";

const useDailyReportSteps = () => {
  const { t: translation } = useTranslation("dailyReports");

  const addSteps = [
    {
      target: "[data-tour='add-dailyReport-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_addDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_addDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='add-dailyReport-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_addDR_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_addDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='add-dailyReport-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_addDR_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_addDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='add-dailyReport-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_addDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            {translation("step4_addDR_text1_1")}{" "}
            <CircleSharp
              sx={{ color: "#5ce381", marginBottom: -0.3, fontSize: 14 }}
            />
            {translation("step4_addDR_text1_2")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            {translation("step4_addDR_text2_1")}{" "}
            <CircleSharp
              sx={{ color: "#ffc900", marginBottom: -0.3, fontSize: 14 }}
            />
            {translation("step4_addDR_text2_2")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='add-dailyReport-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_addDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_addDR_text_1")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            <span style={{ fontWeight: "bold" }}>
              <LockOpen sx={{ marginBottom: -0.4, fontSize: 16 }} />{" "}
              {translation("public")}
            </span>{" "}
            - {translation("step5_addDR_text_2")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            <span style={{ fontWeight: "bold" }}>
              <Lock sx={{ marginBottom: -0.4, fontSize: 16 }} />{" "}
              {translation("private")}
            </span>{" "}
            - {translation("step5_addDR_text_3")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='add-dailyReport-6']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_addDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_addDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='add-dailyReport-7']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_addDR_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_addDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='add-dailyReport-8']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step8_addDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step8_addDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='add-dailyReport-9']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step9_addDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step9_addDR_text1_1")}
          </Typography>
          <Typography>
            <CircleSharp
              sx={{ color: "#BFBFBF", marginBottom: -0.3, fontSize: 14 }}
            />
            {translation("step9_addDR_text1_2")}
          </Typography>
          <Typography>
            <CircleSharp
              sx={{ color: "#5FC5F4", marginBottom: -0.3, fontSize: 14 }}
            />
            {translation("step9_addDR_text2")}
          </Typography>
          <Typography>
            <CircleSharp
              sx={{ color: "#ED8B23", marginBottom: -0.3, fontSize: 14 }}
            />
            {translation("step9_addDR_text3")}
          </Typography>
          <Typography>
            <CircleSharp
              sx={{ color: "#E9569F", marginBottom: -0.3, fontSize: 14 }}
            />
            {translation("step9_addDR_text4")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='add-dailyReport-10']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step10_addDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step10_addDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='add-dailyReport-11']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step11_addDR_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step11_addDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='add-dailyReport-12']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step12_addDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step12_addDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='add-dailyReport-13']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step13_addDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step13_addDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='add-dailyReport-14']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step14_addDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step14_addDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_addDR")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const generalViewSteps = [
    {
      target: "[data-tour='view-dailyReport-0']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_viewDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_viewDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    // View button
    {
      target: "[data-tour='view-dailyReport-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_viewDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_viewDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    // Category
    {
      target: "[data-tour='category']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_viewDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_viewDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
      disableBeacon: true,
    },
    // General Information
    {
      target: "[data-tour='view-dailyReport-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_viewDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_viewDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
      // disableBeacon: true,
    },
  ];

  const generalSuccessViewStep = [
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_viewDR")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const viewSteps = [
    // Basic
    ...generalViewSteps,
    {
      target: "[data-tour='view-dailyReport-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_viewDR_title", { stepNumber: 4 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_viewDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='view-report-description']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step_view_reports_description_title", {
              stepNumber: 5,
            })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step_view_reports_description_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='view-dailyReport-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_viewDR_title", { stepNumber: 6 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_viewDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    // More options
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("moreTitle")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("moreDescription")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='last-seen']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step_view_last_seen_title", { stepNumber: 7 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step_view_last_seen_text")}
          </Typography>
        </div>
      ),
      placement: "top",
    },
    {
      target: "[data-tour='last-edit']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step_view_last_edit_title", { stepNumber: 8 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step_view_last_edit_text")}
          </Typography>
        </div>
      ),
      placement: "top",
    },
    {
      target: "[data-tour='export-pdf']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step_view_export_title", { stepNumber: 9 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step_view_export_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    // Approve
    {
      target: "[data-tour='view-dailyReport-7']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_viewDR_title", { stepNumber: 10 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_viewDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    ...generalSuccessViewStep,
  ];

  const viewWorkProgressSteps = [
    ...generalViewSteps,
    {
      target: "[data-tour='view-dailyReport-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_viewDR_title", { stepNumber: 4 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_viewDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    // Advance and cost
    {
      target: "[data-tour='view-report-progress']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step_view_report_progress_title", { stepNumber: 5 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step_view_report_progress_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='view-report-description']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step_view_reports_description_title", {
              stepNumber: 6,
            })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step_view_reports_description_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='view-dailyReport-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_viewDR_title", { stepNumber: 7 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_viewDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    // More options
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("moreTitle")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("moreDescription")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='last-seen']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step_view_last_seen_title", { stepNumber: 8 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step_view_last_seen_text")}
          </Typography>
        </div>
      ),
      placement: "top",
    },
    {
      target: "[data-tour='last-edit']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step_view_last_edit_title", { stepNumber: 9 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step_view_last_edit_text")}
          </Typography>
        </div>
      ),
      placement: "top",
    },
    {
      target: "[data-tour='export-pdf']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step_view_export_title", { stepNumber: 10 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step_view_export_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    // Approve
    {
      target: "[data-tour='view-dailyReport-7']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_viewDR_title", { stepNumber: 11 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_viewDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    ...generalSuccessViewStep,
  ];

  const viewProblemSteps = [
    ...generalViewSteps,
    {
      target: "[data-tour='view-dailyReport-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_viewDR_title", { stepNumber: 4 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_viewDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='view-report-description']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step_view_reports_description_title", {
              stepNumber: 5,
            })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step_view_reports_description_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='view-dailyReport-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_viewDR_title", { stepNumber: 6 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_viewDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    // More options
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("moreTitle")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("moreDescription")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='last-seen']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step_view_last_seen_title", { stepNumber: 7 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step_view_last_seen_text")}
          </Typography>
        </div>
      ),
      placement: "top",
    },
    {
      target: "[data-tour='last-edit']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step_view_last_edit_title", { stepNumber: 8 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step_view_last_edit_text")}
          </Typography>
        </div>
      ),
      placement: "top",
    },
    {
      target: "[data-tour='export-pdf']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step_view_export_title", { stepNumber: 9 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step_view_export_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    // Approve
    {
      target: "[data-tour='view-dailyReport-7']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_viewDR_title", { stepNumber: 10 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_viewDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    ...generalSuccessViewStep,
  ];

  const viewDelaySteps = [
    ...generalViewSteps,
    {
      target: "[data-tour='view-report-delay-description']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step_view_reports_description_title", {
              stepNumber: 4,
            })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step_view_reports_description_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='view-dailyReport-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_viewDR_title", { stepNumber: 5 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_viewDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    // More options
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("moreTitle")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("moreDescription")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='last-seen']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step_view_last_seen_title", { stepNumber: 6 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step_view_last_seen_text")}
          </Typography>
        </div>
      ),
      placement: "top",
    },
    {
      target: "[data-tour='last-edit']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step_view_last_edit_title", { stepNumber: 7 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step_view_last_edit_text")}
          </Typography>
        </div>
      ),
      placement: "top",
    },
    {
      target: "[data-tour='export-pdf']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step_view_export_title", { stepNumber: 8 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step_view_export_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    // Approve
    {
      target: "[data-tour='view-dailyReport-7']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_viewDR_title", { stepNumber: 9 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_viewDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    ...generalSuccessViewStep,
  ];

  const generalEditSteps = [
    // 1 to 8
    {
      target: "[data-tour='view-dailyReport-0']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_editDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_editDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "[data-tour='edit-dailyReport-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_editDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_editDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "[data-tour='add-dailyReport-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_addDR_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_addDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='add-dailyReport-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_addDR_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_addDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='add-dailyReport-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_addDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            {translation("step4_addDR_text1_1")}{" "}
            <CircleSharp
              sx={{ color: "#5ce381", marginBottom: -0.3, fontSize: 14 }}
            />
            {translation("step4_addDR_text1_2")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            {translation("step4_addDR_text2_1")}{" "}
            <CircleSharp
              sx={{ color: "#ffc900", marginBottom: -0.3, fontSize: 14 }}
            />
            {translation("step4_addDR_text2_2")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='add-dailyReport-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_addDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_addDR_text_1")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            <span style={{ fontWeight: "bold" }}>
              <LockOpen sx={{ marginBottom: -0.4, fontSize: 16 }} />{" "}
              {translation("public")}
            </span>{" "}
            - {translation("step5_addDR_text_2")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            <span style={{ fontWeight: "bold" }}>
              <Lock sx={{ marginBottom: -0.4, fontSize: 16 }} />{" "}
              {translation("private")}
            </span>{" "}
            - {translation("step5_addDR_text_3")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='add-dailyReport-6']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_addDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_addDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='add-dailyReport-7']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_addDR_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_addDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='add-dailyReport-8']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step8_addDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step8_addDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
  ];

  const generalFinalEditSteps = [
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_editDR")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const editSteps = [
    ...generalEditSteps,
    // A partir de aqui es lo que cambia en los otros
    {
      target: "[data-tour='add-dailyReport-10']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step9_editDR_title", { stepNumber: 9 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step9_editDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='add-dailyReport-11']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step10_editDR_title", { stepNumber: 10 })}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step10_editDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='add-dailyReport-12']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step11_editDR_title", { stepNumber: 11 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step11_editDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='edit-dailyReport-14']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step13_editDR_title", { stepNumber: 12 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step13_editDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    ...generalFinalEditSteps
  ];

  const editWorkProgressSteps = [
    ...generalEditSteps,
    // Add report
    {
      target: "[data-tour='add-dailyReport-10']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step9_editDR_title", { stepNumber: 9 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step9_editDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    // Editar info
    {
      target: "[data-tour='add-dailyReport-11']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step10_editDR_title", { stepNumber: 10 })}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step10_editDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    // Editar avance y costo 11
    {
      target: "[data-tour='edit-report-progress-11']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step11_edit_report_progress_title", { stepNumber: 11 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step11_edit_report_progress_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    // Archivos
    {
      target: "[data-tour='add-dailyReport-12']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step11_editDR_title", { stepNumber: 12 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step11_editDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='edit-dailyReport-14']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step13_editDR_title", { stepNumber: 13 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step13_editDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    ...generalFinalEditSteps
  ];

  const editProblemSteps = [
    ...generalEditSteps,
    // Editar info
    {
      target: "[data-tour='add-dailyReport-11']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step10_editDR_title", { stepNumber: 9 })}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step10_editDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    // Archivos
    {
      target: "[data-tour='edit-dailyReport-11']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step11_editDR_title", { stepNumber: 10 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step10_edit_report_problem")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='edit-dailyReport-14']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step13_editDR_title", { stepNumber: 11 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step13_editDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    ...generalFinalEditSteps,
  ];

  const editDelaySteps = [
    ...generalEditSteps,
    // Delay days and hours
    {
      target: "[data-tour='edit-report-delay-9']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step9_edit_report_delay_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step9_edit_report_delay_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    // Files and description
    {
      target: "[data-tour='edit-report-delay-10']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step11_editDR_title", { stepNumber: 10 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step10_edit_report_delay")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='edit-dailyReport-14']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step13_editDR_title", { stepNumber: 11 })}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step13_editDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    ...generalFinalEditSteps
  ];

  const recycleSteps = [
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_recycleDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_recycleDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='recycle']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_recycleDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_recycleDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='recycle-dailyReport-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_recycleDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_recycleDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
      disableScrolling: true,
    },
    {
      target: ".checkbox-recycle",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_recycleDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_recycleDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
      disableScrolling: true,
    },
    {
      target: "[data-tour='confirm-recycle']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_recycleDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_recycleDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
      disableScrolling: true,
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_recycleDR")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const exportSteps = [
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("moreTitle")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("moreDescription")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='export-pdf']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_exportDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_exportDR_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='export-csv']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_exportDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_exportDR_text")}
          </Typography>
        </div>
      ),
      placement: "top",
    },
    {
      target: "[data-tour='view-dailyReport-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_exportDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_exportDR_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableScrolling: true,
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_exportDR")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const settingsSteps = [
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {`1. ${translation("moreTitle")}`}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("moreDescription")}
          </Typography>
        </div>
      ),
      position: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='settings']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_settingsDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_settingsDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='docs-settings-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_settingsDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_settingsDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "[data-tour='save-settings']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_settingsDR_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_settingsDR_text")}
          </Typography>
        </div>
      ),
      position: "left",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_settingsDR")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  return [
    {
      addSteps,
      viewSteps,
      editSteps,
      recycleSteps,
      exportSteps,
      settingsSteps,
      editWorkProgressSteps,
      editProblemSteps,
      editDelaySteps,
      viewWorkProgressSteps,
      viewProblemSteps,
      viewDelaySteps,
    },
  ];
};

export { useDailyReportSteps };
