const DISTRIBUTION_LIST_NAMES = {
  rfis: "rfi_distribution_list",
  submittals: "submittals_distribution_list",
  image: "image_distribution_list",
  album: "album_distribution_list",
  tasks: "task_distribution_list",
  meeting: "meeting_distribution_list",
  dailyReports: "daily_report_distribution_list",
  inspections: "inspection_distribution_list",
  delay: "delay_distribution_list",
  documents: "document_distribution_list",
  drawings: "drawing_distribution_list",
};

export { DISTRIBUTION_LIST_NAMES };
