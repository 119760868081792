import React, { useEffect, useState } from "react";
import {
  Add,
  Delete,
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
  Restore,
  Settings,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Card,
  Tooltip,
  Typography,
} from "@mui/material";
import SupportButton from "../../components/SupportButton";

const KPI = () => {
  return (
    <>
      <Box sx={{ width: "100%", height: "100%" }}>
        {/* Title */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Typography fontSize="2rem" fontWeight="bold">
              KPI's
            </Typography>
            <SupportButton />
          </Box>
        </Box>

        <Box sx={{ width: "100%", textAlign: "center", paddingY: 10 }}>
          <Box
            component="img"
            sx={{
              width: "35%",
              height: "35%",
              margin: "auto",
            }}
            alt="Buildpeer Logo"
            src={require("../../assets/coming-soon-2.png")}
          />
        </Box>
      </Box>
    </>
  );
};

export default KPI;
