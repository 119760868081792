/* eslint-disable no-nested-ternary */
import {
  ArrowBack,
  ArrowBackIos,
  ArrowForwardIos,
  LocationOn,
  SystemUpdateAlt,
} from "@mui/icons-material";
import {
  Box,
  Card,
  Dialog,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import WebViewer from "@pdftron/webviewer";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import { isVideo } from "../../utils/video";
import { useGetDailyReportChatQuery } from "../../features/project/modules/dailyReports/dailyReportsApiSlice";
import UserAvatar from "../userAvatars/UserAvatar";

function FileViewer({ initialIndex, files = [], isOpen, onClose }) {
  const viewerRef = useRef(null);
  const [instance, setInstance] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setCurrentIndex(initialIndex);
  }, [initialIndex]);

  const currentFile = useMemo(() => {
    if (files.length > 0) {
      return files[currentIndex];
    }
    return null;
  }, [files, currentIndex]);

  const isImg = useMemo(() => {
    if (currentFile) {
      return Boolean(currentFile.extension.match(/png|jpg|jpeg|svg|avif/));
    }
    return false;
  }, [currentFile]);

  const isVideoFile = useMemo(() => {
    if (currentFile) {
      const bVideo = isVideo({
        type: currentFile.extension,
      });
      return bVideo;
    }
    return false;
  }, [currentFile]);

  useEffect(() => {
    if (
      viewerRef.current &&
      isModalOpen &&
      currentFile &&
      !isImg &&
      !isVideoFile
    ) {
      WebViewer(
        {
          licenseKey: process.env.REACT_APP_APRYSE_LICENSE_KEY,
          path: "/pdftron",
          showToolbarControl: true,
          showSideWindowControl: false,
          disabledElements: [
            "textSelection",
            "panToolButton",
            "textAnnotationButton",
            "freeTextAnnotationButton",
            "stickyAnnotationButton",
            "calloutAnnotationButton",
            "stampAnnotationButton",
            "measurementToolGroupButton",
            "toolsHeader",
            "leftPanelButton",
            "viewControlsButton",
            "zoomOverlayButton",
            "selectToolButton",
            "ribbons",
            "searchButton",
            "toggleNotesButton",
          ],
        },
        viewerRef.current,
      )
        .then((newInstance) => {
          setInstance(newInstance);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }
  }, [viewerRef, isModalOpen, currentFile, isImg, isVideoFile]);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const handleNextFile = () => {
    setCurrentIndex((prev) => prev + 1);
  };

  const handlePrevFile = () => {
    setCurrentIndex((prev) => prev - 1);
  };

  useEffect(() => {
    if (currentFile && instance && !isImg && !isVideoFile) {
      instance.loadDocument(currentFile.url, {
        extension: currentFile.extension,
      });
    }
  }, [currentFile, instance, isImg, isVideoFile]);

  const download = async () => {
    const link = document.createElement("a");

    const urlWithCacheBuster = `${
      currentFile.url
    }?cache_buster=${new Date().getTime()}`;
    const res = await fetch(urlWithCacheBuster, { cache: "no-store" });
    const blob = await res.blob();
    const objectUrl = URL.createObjectURL(blob);

    link.href = objectUrl;
    link.setAttribute("download", `${currentFile.name}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    URL.revokeObjectURL(objectUrl);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      height="100%"
      backgroundColor="black"
      width="70%"
      position="relative"
    >
      <Box
        display="flex"
        alignItems="center"
        flex={1}
        justifyContent="space-between"
        mr="10px"
        flexDirection="column"
        py={4}
      >
        <IconButton sx={{ color: "white" }} onClick={onClose}>
          <ArrowBack />
        </IconButton>
        <IconButton onClick={handlePrevFile} disabled={currentIndex === 0}>
          <ArrowBackIos
            sx={{
              backgroundColor: "white",
              borderRadius: "40px",
              pl: "8px",
              width: "48px",
              height: "48px",
            }}
          />
        </IconButton>
        <Box />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" flex={5}>
        <Card
          sx={{
            p: "1%",
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            backgroundColor: "black",
          }}
        >
          {isImg ? (
            <img
              src={currentFile.url}
              alt={currentFile.name}
              style={{
                maxWidth: "100%",
                objectFit: "contain",
                margin: "auto",
                maxHeight: "100%",
              }}
            />
          ) : isVideoFile ? (
            <ReactPlayer
              url={currentFile?.url}
              controls
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
              }}
            />
          ) : (
            <Box
              ref={viewerRef}
              className="webviewer"
              sx={{ height: "100%", width: "100%" }}
            />
          )}
        </Card>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        flex={1}
        justifyContent="center"
        ml="10px"
      >
        <IconButton
          onClick={handleNextFile}
          disabled={currentIndex === files.length - 1}
        >
          <ArrowForwardIos
            sx={{
              backgroundColor: "white",
              borderRadius: "40px",
              pl: "4px",
              width: "48px",
              height: "48px",
            }}
          />
        </IconButton>
      </Box>
      {isImg && (
        <IconButton
          onClick={download}
          sx={{
            position: "absolute",
            right: "50px",
            top: "24px",
            backgroundColor: "white",
          }}
        >
          <SystemUpdateAlt color="primary" />
        </IconButton>
      )}
    </Box>
  );
}

function CommentItem({ comment }) {
  return (
    <Box py={1}>
      <Divider />
      <Box display="flex" gap={1} alignItems="center" mt={2}>
        <UserAvatar url={comment.urlAvatar} bActive={comment.bActive} />
        <Typography color="#4B4B4B">{`${comment.firstName} ${comment.lastName}`}</Typography>
      </Box>
      <Typography fontSize="14px" color="#4B4B4B" pt="4px">
        {comment.comment}
      </Typography>
    </Box>
  );
}

function Description({ location, topic }) {
  const { t: tGeneral } = useTranslation("general");

  const { data: comments } = useGetDailyReportChatQuery(topic.id);

  return (
    <Box
      backgroundColor="white"
      height="100%"
      p={2}
      pr="80px"
      pt={4}
      width="30%"
    >
      <Typography display="flex" alignItems="center" color="#1F1F1F">
        <LocationOn sx={{ color: "#E6B60C" }} />
        {location.path}
      </Typography>
      <Divider sx={{ marginY: 2 }} />
      <Typography fontSize="12px" fontWeight="400" color="#8E8E8E">
        {tGeneral("description")}
      </Typography>
      <Typography color="#4B4B4B">{topic.title}</Typography>
      <Box pt={2}>
        {comments?.map((comment) => (
          <CommentItem key={comment.id} comment={comment} />
        ))}
      </Box>
    </Box>
  );
}

/**
  @params
  - isOpen: Boolean
  - onClose: () => void;
  - files: Array[string] "Array of files urls"
  - initialIndex: Number "Initial index for the carousel. Default is 0"
*/
function ModalCarouselFileViewer(props) {
  const { isOpen, onClose } = props;

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen>
      <Box
        display="flex"
        justifyContent="end"
        alignItems="center"
        width="100%"
        height="100%"
        backgroundColor="black"
      >
        <FileViewer {...props} />
        <Description {...props} />
      </Box>
    </Dialog>
  );
}

export { ModalCarouselFileViewer };
