import { Avatar, Box, Card, Typography } from "@mui/material";
import {
  useAddTopicMutation,
  useCommentObjectMutation,
} from "../../features/message/messageApiSlice";
import { MessageBox } from "../question/MessageBox";
import { MessageReply } from "../question/MessageReply";
import { useTranslation } from "react-i18next";

function Topic({ topics, idObject, moduleName, disabled = false }) {
  const [addTopic, { isLoading: isAddTopicLoading }] = useAddTopicMutation();
  const [commentTopic, { isLoading: isCommentTopicLoading }] =
    useCommentObjectMutation();

  //Translations
  const { t: tRfis } = useTranslation("rfis");
  const { t: tGeneral } = useTranslation("general");

  const handleComment = (idTopic, message, files) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("idObject", idTopic);
    formData.append("comment", message);
    formData.append("module", moduleName);
    formData.append("objectName", "topic");
    commentTopic(formData);
  };

  const handleAddTopic = async (message, files) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("idObject", idObject);
    formData.append("title", message);
    formData.append("module", moduleName);
    addTopic(formData);
  };

  return (
    <Box>
      <Typography fontSize="1.125rem" fontWeight="bold" mb="35px">
        {tGeneral("topics")}
      </Typography>
      {topics?.map((topic) => (
        <Box key={topic.id} display="flex">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Card sx={{ borderRadius: "100%", p: "2px" }}>
              <Avatar src={topic?.urlAvatar} />
            </Card>
            <Box
              sx={{
                height: "calc(100% - 102px)",
                width: "1px",
                border: "1px solid #F2F2F2",
              }}
            />
          </Box>
          <Box flex={1}>
            <MessageBox
              createdDate={topic?.createDate}
              createdBy={`${topic?.firstName} ${topic?.lastName}`}
              files={topic?.files}
              text={topic?.title}
              question
              isTopic
            />
            {topic?.comments?.map((comment) => (
              <Box key={comment.id}>
                <Box display="flex" position="relative">
                  <Box
                    sx={{
                      width: "50px",
                      border: "1px solid #F2F2F2",
                      position: "absolute",
                      top: 20,
                      left: -21,
                    }}
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Card sx={{ borderRadius: "100%", p: "2px" }}>
                      <Avatar src={comment?.urlAvatar} />
                    </Card>
                  </Box>
                  <Box flex={1}>
                    <MessageBox
                      createdDate={comment?.createdDate}
                      createdBy={`${comment?.firstName} ${comment?.lastName}`}
                      files={comment?.files}
                      text={comment?.comment}
                      isTopic
                    />
                  </Box>
                </Box>
              </Box>
            ))}
            {!disabled && (
              <MessageReply
                reply={(message, files) =>
                  handleComment(topic.id, message, files)
                }
                placeholder={tGeneral("newComment")}
                isLoading={isCommentTopicLoading}
                disabled={disabled}
              />
            )}
          </Box>
        </Box>
      ))}
      {!disabled && (
        <MessageReply
          placeholder={tGeneral("newTopic")}
          isLoading={isAddTopicLoading}
          reply={handleAddTopic}
          showConnection={false}
          disabled={disabled}
        />
      )}
    </Box>
  );
}

export { Topic };
