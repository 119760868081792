import { Close, Done } from "@mui/icons-material";
import { Box, Chip, IconButton, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ModalWithAction } from "../../../../components/shared";
import { selectCurrentEnterprise } from "../../../../features/enterprise/enterpriseSlice";
import { useUpdateProjectTodoMutation } from "../../../../features/project/projectApiSlice";

function TodoCard({ name, limitDate, id, last }) {
  const { t: tGeneral } = useTranslation("general");
  const currentEnterprise = useSelector(selectCurrentEnterprise);
  const [editingName, setEditingName] = useState(null);
  const [editingDate, setEditingDate] = useState(null);
  const [isFinishing, setIsFinishing] = useState(false);
  const isOnTime = moment(limitDate).diff(Date.now()) > 0;
  const color = isOnTime ? "#FFC42D" : "#E05353";
  const [updateTodo, { isLoading }] = useUpdateProjectTodoMutation();
  const localeDate = new Date(limitDate).toLocaleString(
    currentEnterprise?.locale ?? "es",
    {
      day: "numeric",
      month: "long",
      year: "numeric",
    },
  );

  const handleCancelEditing = () => {
    setEditingDate(null);
    setEditingName(null);
  };

  const handleDoneTask = async () => {
    await updateTodo({ id, bActive: false });
  };

  const handleUpdateTask = async () => {
    if (!editingDate && !editingName) {
      setIsFinishing(true);
      return;
    }

    const body = {};
    if (editingDate) {
      body.limitDate = editingDate.toISOString();
    }
    if (editingName) {
      body.name = editingName;
    }

    await updateTodo({ id, ...body });

    handleCancelEditing();
  };

  return (
    <Box
      display="flex"
      width="100%"
      height="70px"
      alignItems="center"
      px="20px"
      py="12px"
      columnGap="12px"
      sx={{ borderBottom: last ? "none" : "1px solid #F8F8F8" }}
    >
      <Box flex={1}>
        {editingName ? (
          <TextField
            fullWidth
            value={editingName}
            onChange={(e) => setEditingName(e.target.value)}
            size="small"
            InputProps={{
              sx: { maxHeight: "27px", mb: "1%" },
            }}
          />
        ) : (
          <Typography component="div" onClick={() => setEditingName(name)}>
            {name}
          </Typography>
        )}
        {editingDate ? (
          <DatePicker
            value={editingDate}
            sx={{ maxHeight: "27px" }}
            formatDensity="dense"
            onChange={(val) => setEditingDate(val)}
          />
        ) : (
          <Box
            display="flex"
            alignItems="center"
            columnGap="5px"
            component="div"
            onClick={() => setEditingDate(moment(limitDate))}
          >
            <Typography fontSize="0.75rem" color={color} fontWeight="bold">
              {localeDate}
            </Typography>
            <Chip
              sx={{
                fontSize: "0.75rem",
                height: "18px",
                color: "white",
                backgroundColor: color,
              }}
              label={isOnTime ? tGeneral("onTime") : tGeneral("overdue")}
            />
          </Box>
        )}
      </Box>
      {(editingDate || editingName) && (
        <IconButton onClick={handleCancelEditing}>
          <Close />
        </IconButton>
      )}
      <IconButton onClick={handleUpdateTask}>
        <Done />
      </IconButton>
      <ModalWithAction
        isOpen={isFinishing}
        onClose={() => setIsFinishing(false)}
        title={tGeneral("done")}
        content={tGeneral("areYouTask")}
        actionLabel={tGeneral("done")}
        action={handleDoneTask}
        isLoading={isLoading}
      />
    </Box>
  );
}

export { TodoCard };
