import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import "../fonts/poppins.css";
// To explore the default theme object we're customizing from Material UI:
// https://mui.com/material-ui/customization/default-theme/

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#FBCB04",
    },
    secondary: {
      main: "#353535",
    },
    error: {
      main: "#e05353",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          borderBottom: `2px solid ${theme.palette.primary.main}`,
          boxShadow: "none",
        }),
      },
    },
    // FIXME: Change color when selected, too light right now
    MuiListItemButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          "&:selected": {
            backgroundColor: "red",
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette.primary.main,
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
  },
});

export default responsiveFontSizes(defaultTheme);
