import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Menu,
} from "@mui/material";
import { Add, Delete, Edit, MoreVert } from "@mui/icons-material";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useDeleteTypeMutation,
  useGetTypesQuery,
} from "../../../features/project/typeApiSlice";
import { TypeModal } from "./TypeModal";
import { PopUpAlert } from "../../PopUpAlert";
import { ModalWithAction } from "../../shared";

const TypeSelect = ({ selectedType, onChange = () => {} }) => {
  const { idProject } = useParams();

  const { t: tGeneral } = useTranslation("general");

  const { data: projectTypes } = useGetTypesQuery(idProject);
  const [deleteType, { isLoading: isDeleteLoading }] = useDeleteTypeMutation();

  const [openModalType, setOpenModalType] = useState(false);
  const [focusedType, setFocusedType] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const [openDelete, setOpenDelete] = useState(false);

  const types = useMemo(() => projectTypes ?? [], [projectTypes]);

  const handleMenuOpen = (event, type) => {
    setMenuAnchorEl(event.currentTarget);
    setFocusedType(type);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setFocusedType(null);
  };

  const handleDeleteType = async () => {
    let body = {
      idType: focusedType.id,
      idProject,
    };
    try {
      const response = await deleteType(body).unwrap();
      if (response) {
        PopUpAlert("success", tGeneral("success"), tGeneral("typeDeleted"));
      }
      setFocusedType(null);
      setOpenDelete(false);
    } catch (error) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
    }
  };

  const menuOptions = [
    {
      title: tGeneral("edit"),
      icon: <Edit fontSize="small" />,
      onClick: () => {
        setOpenModalType(true);
      },
    },
    {
      title: tGeneral("delete"),
      icon: <Delete fontSize="small" />,
      onClick: () => {
        setOpenDelete(true);
      },
    },
  ];

  return (
    <>
      <FormControl fullWidth>
        <InputLabel>{tGeneral("type")}</InputLabel>
        <Select
          id="type-select"
          labelId="type-select-label"
          value={selectedType || ""}
          onClose={() => {
            setFocusedType(null);
          }}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          label={tGeneral("type")}
          MenuProps={{ sx: { maxHeight: "300px" } }}
          renderValue={(value) => (
            <Typography>
              {types.find((type) => type.id === value)?.name}
            </Typography>
          )}
        >
          {types.map((type) => (
            <MenuItem value={type.id} key={type.id}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                {type.name}
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMenuOpen(e, type);
                  }}
                >
                  <MoreVert />
                </IconButton>
              </Box>
            </MenuItem>
          ))}
          <Box display="flex">
            <Button
              variant="text"
              startIcon={<Add />}
              fullWidth
              style={{
                justifyContent: "flex-start",
                paddingLeft: "8%",
                color: "black",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModalType(true);
              }}
            >
              {tGeneral("addType")}
            </Button>
          </Box>
          <Menu
            anchorEl={menuAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
          >
            {menuOptions.map((option) => (
              <MenuItem
                key={option.title}
                onClick={() => {
                  option.onClick();
                  setMenuAnchorEl(null);
                }}
              >
                {option.icon}
                <Typography fontSize={12} fontWeight="bold" sx={{ mx: 1 }}>
                  {option.title}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Select>
      </FormControl>
      <TypeModal
        isOpen={openModalType}
        selectedtType={focusedType}
        onClose={(e) => {
          setOpenModalType(false);
          setFocusedType(null);
        }}
      />
      <ModalWithAction
        isOpen={openDelete}
        onClose={() => {
          setOpenDelete(false);
          setFocusedType(null);
        }}
        title={tGeneral("deleteType")}
        content={tGeneral("deleteGeneric")}
        icon="delete"
        actionLabel={tGeneral("delete")}
        action={handleDeleteType}
        isLoading={isDeleteLoading}
      />
    </>
  );
};

export default TypeSelect;
