// eslint-disable-next-line import/prefer-default-export
export const projectSchedule = {
  "% Complete": "完成%",
  "$ Project Cost": "项目成本",
  "Add New": "新增",
  "All Colors": "所有颜色",
  "All Dates": "所有日期",
  "Are you sure you want to delete the activity": "确定要删除此活动吗？",
  "Are you sure you want to delete the group": "确定要删除此群组吗？",
  "Are you sure you want to delete the milestone": "确定要删除此里程碑吗？",
  "Are you sure?": "确定吗？",
  "Basic Blue": "基本蓝",
  "Beneficial Brown": "有益的棕色",
  "Blissful Blue": "幸福蓝",
  "change(s)": "改变(s)",
  "Choose color": "选择颜色",
  "Click here": "点击这里",
  "Copy Link": "复制链接",
  "Create Activity": "创建活动",
  "Create Group": "创建群组",
  "Create Milestone": "创建里程碑",
  "Create Roadblock": "创建障碍",
  "Daily Reports": "日报告",
  "Date Format:": "日期格式：",
  "Day-Month-Year": "日-月-年",
  "Default Color": "默认颜色",
  "Delete Activity": "删除活动",
  "Delete Group": "删除群组",
  "Delete Milestone": "删除里程碑",
  "Dependent On": "依赖于",
  "Depends On": "取决于",
  "Due Date": "到期日",
  "Due Today": "今天到期",
  "End Date": "结束日期",
  "Expand All Groups": "展开所有群组",
  "Export to Excel": "导出到Excel",
  "Gainful Green": "有收益的绿色",
  "Get It Done Grey": "完成灰色",
  "Golfer's Green": "高尔夫球手果岭",
  "Great Green": "大绿",
  "Hide": "隐藏",
  "Hide Completed": "隐藏已完成",
  "Import from Excel": "从Excel导入",
  "Import or export": "导入或导出",
  "In Progress": "进行中",
  "Invite people": "邀请人",
  "made": "制成",
  "Magnifying Magenta": "放大洋红色",
  "Month-Day-Year": "月-日-年",
  "No other activity depends on this activity.": "没有其他活动依赖于此活动。",
  "No records to display": "没有记录可显示",
  "Operational Orange": "橙色行动",
  "Outstanding Orange": "优秀橙子",
  "PDF Settings": "PDF 设置",
  "Pretty Pink": "漂亮的粉红色",
  "Productive Purple": "高效紫色",
  "Project Cost": "项目成本",
  "Project Schedule": "项目计划",
  "Prolific Purple": "多产的紫色",
  "Real Duration": "实际持续时间",
  "Real Finish Date": "实际完成日期",
  "Real Finish": "真正的完成",
  "Real Start Date": "实际开始日期",
  "Real Start": "实际开始",
  "Real Times": "实际时间",
  "Recommended: All Dates": "推荐：所有日期",
  "Recommended: Due Today": "推荐：今天到期",
  "Recommended: In Progress": "推荐：进行中",
  "Recommended: Overdue": "推荐：逾期",
  "Rosy Red": "玫瑰红",
  "Scheduled Duration": "计划持续时间",
  "Scheduled End Date": "计划结束日期",
  "Scheduled End": "计划结束",
  "Scheduled Start Date": "计划开始日期",
  "Scheduled Start": "计划开始",
  "Scheduled Times": "计划时间",
  "Select a type": "选择类型",
  "Select Assignees": "选择指派人",
  "Select Colors": "选择颜色",
  "Show Completed": "显示已完成",
  "Size": "尺寸",
  "Start Date": "开始日期",
  "Starting: Within 1 Week": "开始时间：1周内",
  "Starting: Within 2 Weeks": "开始：2周内",
  "Starting: Within 4 Weeks": "开始：4周内",
  "Submitting...": "提交中...",
  "to download the file and review the errors in the last column." : "下载文件并检查最后一列中的错误。",
  "to download the sample file. Your uploaded file must be based on this template and adhere to the outlined requirements.": "下载示例文件。您上传的文件必须基于此模板并遵守概述的要求。",
  "Task Due": "任务到期",
  "Task Due: Within 1 Week": "任务到期时间：1 周内",
  "Task Due: Within 2 Weeks": "任务到期：两周内",
  "Task Due: Within 4 Weeks": "任务到期时间：4 周内",
  "There are no comments in this activity.": "此活动中没有评论。",
  "There are no comments in this group.": "此群组中没有评论。",
  "There are no dependents/depedencies of this activity.": "此活动没有依赖项。",
  "There are no histories in this group.": "此群组中没有历史记录。",
  "There are no items in this module.": "此模块中没有项目。",
  "There are no more daily reports available.": "没有更多的日报告。",
  "There are no more documents available.": "没有更多的文件。",
  "There are no more drawings available.": "没有更多的图纸。",
  "There are no more inspections available.": "没有更多的检查。",
  "There are no more meetings available.": "没有更多的会议。",
  "There are no more RFIs available.": "没有更多的请求信息。",
  "There are no more tasks available.": "没有更多的任务。",
  "There is history in this activity.": "此活动中有历史记录。",
  "There is history in this group.": "此群组中有历史记录。",
  "This activity does noes depend on any other activity.": "此活动不依赖于任何其他活动。",
  "This feature is under construction.": "此功能正在建设中。",
  "Today": "今天",
  "Update Roadblock": "更新路障",
  "Within 1 Week": "1周内",
  "Within 2 Weeks": "2周内",
  "Within 4 Weeks": "4周内",
  "Year-Month-Day": "年月日",
  "You do not have the appropriate permission to perform this action.": "您没有执行此操作的适当权限。",
  "Your file was not imported due to errors." : "由于错误，您的文件未被导入。",
  "You have marked this milestone as completed.": "您已将此里程碑标记为已完成。",
  "You have marked this milestone as not completed.": "您已将此里程碑标记为未完成。",
  "You have not invited anyone yet.": "您尚未邀请任何人。",
  "View": "看法",
  Activities: "活动",
  Activity: "活动",
  Assigned: "已分配",
  Assignees: "指派人",
  Blueberry: "蓝莓",
  Budget: "预算",
  Cancel: "取消",
  Chart: "图表",
  Close: "关闭",
  CLOSE: "关闭",
  Colors: "颜色",
  Comments: "评论",
  Cost: "成本",
  Create: "创建",
  Day: "日",
  Dependencies: "依赖项",
  Description: "描述",
  Difference: "差异",
  Documents: "文件",
  Drawings: "图纸",
  Duration: "持续时间",
  Email: "电子邮件",
  Error: "错误",
  Everyone: "每个人",
  Expenses: "费用",
  File: "文件",
  Files: "文件",
  Grid: "网格",
  History: "历史",
  Inspections: "检查",
  Invite: "邀请",
  Meetings: "会议",
  Milestone: "里程碑",
  Mustard: "芥末",
  Name: "姓名",
  No: "否",
  OPEN: "开放",
  Overdue: "逾期",
  Permissions: "权限",
  Please: "请",
  Predecessor: "前置任务",
  Progress: "进度",
  Recommended: "受到推崇的",
  RESCHEDULE: "重新安排",
  RFIs: "请求信息",
  Roadblocks: "障碍",
  Select: "选择",
  Send: "提交",
  Share: "分享",
  Starting: "开始",
  Status: "状态",
  Submit: "提交",
  Success: "成功",
  Successor: "后续任务",
  Tasks: "任务",
  Title: "标题",
  to: "到",
  Total: "总计",
  Update: "更新",
  Week: "周",
  Yes: "是",
  "DOWNLOAD PDF": "下载PDF",
  "POPULATE SAMPLE DATA": "填充样本数据",
  "Additional Columns": "附加列",
  ACTIVITY_CREATED: "活动已创建",
  ACTIVITY_UPDATED_ASSIGNEES_CREATED: '已添加被指派者 "{{ name }}"',
  ACTIVITY_UPDATED_ASSIGNEES_REMOVED: '已移除被指派者 "{{ name }}"',
  ACTIVITY_UPDATED_COLOR: '颜色已从 "{{ old }}" 变为 "{{ new }}"',
  ACTIVITY_UPDATED_DESCRIPTION: '描述已从 "{{ old }}" 变为 "{{ new }}"',
  ACTIVITY_UPDATED_ID_ACTIVITY_UPDATED: '前置任务已从 "{{ old }}" 变为 "{{ new }}"',
  ACTIVITY_UPDATED_PROGRESS: '进度已从 "{{ old }}" 变为 "{{ new }}"',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_CREATED: '费用行 "{{ title }}" 已添加',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_REMOVED: '费用行 "{{ title }}" 已移除',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_AMOUNT:
    '费用行 "{{ number }}" 的金额已从 "{{ old }}" 变为 "{{ new }}"',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_FILE: '费用行 "{{ number }}" 的文件已从 "{{ old }}" 变为 "{{ new }}"',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_TITLE: '费用行 "{{ number }}" 的标题已从 "{{ old }}" 变为 "{{ new }}"',
  ACTIVITY_UPDATED_PROJECT_COST_BUDGET: '预算已从 "{{ old }}" 变为 "{{ new }}"',
  ACTIVITY_UPDATED_REAL_TIMES_END: '实际结束时间已从 "{{ old }}" 变为 "{{ new }}"',
  ACTIVITY_UPDATED_REAL_TIMES_START: '实际开始时间已从 "{{ old }}" 变为 "{{ new }}"',
  ACTIVITY_UPDATED_SCHEDULED_TIMES_END: '计划结束时间已从 "{{ old }}" 变为 "{{ new }}"',
  ACTIVITY_UPDATED_SCHEDULED_TIMES_START: '计划开始时间已从 "{{ old }}" 变为 "{{ new }}"',
  ACTIVITY_UPDATED_TITLE: '标题已从 "{{ old }}" 变为 "{{ new }}"',
  DAILY_REPORT_CREATED: '日报 "{{ title }}" 已添加',
  DAILY_REPORT_REMOVED: '日报 "{{ title }}" 已移除',
  DOCUMENT_CREATED: '文档 "{{ name }}" 已添加',
  DOCUMENT_REMOVED: '文档 "{{ name }}" 已移除',
  DRAWING_CREATED: '图纸 "{{ name }}" 已添加',
  DRAWING_REMOVED: '图纸 "{{ name }}" 已移除',
  INSPECTION_CREATED: '检查 "{{ title }}" 已添加',
  INSPECTION_REMOVED: '检查 "{{ title }}" 已移除',
  MEETING_CREATED: '会议 "{{ title }}" 已添加',
  MEETING_REMOVED: '会议 "{{ title }}" 已移除',
  RFI_CREATED: 'RFI "{{ title }}" 已添加',
  RFI_REMOVED: 'RFI "{{ title }}" 已移除',
  ROADBLOCK_CREATED: '障碍 "{{ description }}" 已创建',
  ROADBLOCK_UPDATED_ASSIGNEES_CREATED: '障碍 "{{ description }}" 已添加被指派者 "{{ name }}"',
  ROADBLOCK_UPDATED_ASSIGNEES_REMOVED: '障碍 "{{ description }}" 已移除被指派者 "{{ name }}"',
  ROADBLOCK_UPDATED_DATE: '障碍 "{{ description }}" 的日期已从 "{{ old }}" 变为 "{{ new }}"',
  ROADBLOCK_UPDATED_DESCRIPTION: '障碍 "{{ description }}" 的描述已从 "{{ old }}" 变为 "{{ new }}"',
  ROADBLOCK_UPDATED_STATUS: '障碍 "{{ description }}" 的状态已从 "{{ old }}" 变为 "{{ new }}"',
  ROADBLOCK_UPDATED_TYPE: '障碍 "{{ description }}" 的类型已从 "{{ old }}" 变为 "{{ new }}"',
  TASK_CREATED: '任务 "{{ title }}" 已添加',
  TASK_REMOVED: '任务 "{{ title }}" 已移除',
};
