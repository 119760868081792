import { Autorenew, Close } from "@mui/icons-material";
import {
  Box,
  Card,
  Divider,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";

const SupportMenuModalGuideMe = ({ open, onClose, children, title }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          width: "40%",
          height: "auto",
          borderRadius: "20px",
          p: "25px 40px",
        }}
      >
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="space-between"
          mb="20px"
        >
          <Box display="flex" alignItems="center">
            <Autorenew />
            <Typography
              sx={{
                fontSize: "1.5625rem",
                fontWeight: "bold",
                marginLeft: 1,
              }}
            >
              {title}
              {/* {`${translation("guideMe")} - ${translation('moduleElement')}`} */}
            </Typography>
          </Box>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider />
        <Box mt="35px">{children}</Box>
      </Card>
    </Modal>
  );
};

export { SupportMenuModalGuideMe };
