import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import animationData from "../../../projectDashboard/16627-firework.json";

const useGeneralDirectorySteps = () => {
  const { t: translation } = useTranslation("contacts");

  const addSteps = [
    {
      target: `[data-tour='${"add-contact-1"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_addContact_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_addContact_text")}
          </Typography>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: `[data-tour='${"contact-first-name"}']`,
      position: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_addContact_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_addContact_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"contact-lastname"}']`,
      position: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_addContact_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_addContact_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"contact-email"}']`,
      position: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_addContact_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_addContact_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"contact-phone"}']`,
      position: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_addContact_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_addContact_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"contact-enterprise"}']`,
      position: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_addContact_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_addContact_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"contact-add-button"}']`,
      position: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_addContact_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_addContact_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_addContact")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];
  const editSteps = [
    {
      target: `[data-tour='${"edit-contact-1"}']`,
      placement: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_editContact_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_editContact_text")}
          </Typography>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: `[data-tour='${"contact-first-name"}']`,
      placement: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_editContact_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_editContact_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"contact-lastname"}']`,
      placement: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_editContact_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_editContact_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"contact-email"}']`,
      placement: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_editContact_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_editContact_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"contact-phone"}']`,
      placement: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_editContact_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_editContact_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"contact-enterprise"}']`,
      placement: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_editContact_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_editContact_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: `[data-tour='${"contact-add-button"}']`,
      placement: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_editContact_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_editContact_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_editContact")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];
  const deleteSteps = [
    {
      target: "[data-tour='delete-contact-1']",
      placement: "right",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_deleteContact_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_deleteContact_text")}
          </Typography>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: ".swal2-confirm",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_deleteContact_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_deleteContact_text")}
          </Typography>
        </div>
      ),
      placement: "right",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_deleteContact")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  return [
    {
      addSteps,
      editSteps,
      deleteSteps,
    },
  ];
};

export { useGeneralDirectorySteps };
