export const enterprise = {
  construction: "Construção",
  realState: "Imobiliária",
  architect: "Arquitetura",
  contractor: "Empreiteiro",
  subcontractor: "Subempreiteiro",
  maintenance: "Manutenção",
  developer: "Desenvolvedora",
  generalContractor: "Empreiteiro geral",
  other: "Outro",
};
