import { tour } from "./tour";

export const documents = {
  // general tour
  ...tour,
  moduleElement: "Documents",
  // ADD FILE - guide me steps
  step1_docsAddFile_title: "1. Add",
  step1_docsAddFile_text: "Click on the + icon",
  step2_docsAddFile_title: "2. Add files",
  step2_docsAddFile_text: "Select the option to add files.",
  step3_docsAddFile_title: "3. Upload files",
  step3_docsAddFile_text:
    "Drag and drop files, or click on the dotted square to upload them.",
  step4_docsAddFile_title: "4. Privacy",
  step4_docsAddFile_text:
    "If activated, access to certain companies and individuals will be restricted.",
  step5_docsAddFile_title: "5. Add",
  step5_docsAddFile_text: "Click here to add the files.",
  step_final_docsAddFile: "You have learned how to add a file.",
  // ADD FOLDER - guide me steps
  step1_docsAddFolder_title: "1. Add",
  step1_docsAddFolder_text: "Click on the + icon",
  step2_docsAddFolder_title: "2. Add folder",
  step2_docsAddFolder_text: "Select the option to create a folder",
  step3_docsAddFolder_title: "3. Folder name",
  step3_docsAddFolder_text: "Type the name of the folder",
  step4_docsAddFolder_title: "4. Privacy",
  step4_docsAddFolder_text:
    "If activated, access to certain companies and individuals will be restricted.",
  step5_docsAddFolder_title: "5. Add",
  step5_docsAddFolder_text: "Click here to add the folder.",
  step_final_docsAddFolder: "You have learned how to add a folder.",
  // ACTIONS - guide me steps
  step1_docsActions_title: "1. Actions",
  step1_docsActions_text:
    "Here you have different actions you can execute regarding files/folders.",
  step2_docsActions_title: "2. Select as favorite",
  step2_docsActions_text:
    "Click here to mark the file as favorite for easy access.",
  step3_docsActions_title: "3. Download",
  step3_docsActions_text: "Click here to download the selected file.",
  step5_docsActions_title: "4. Edit name",
  step5_docsActions_text: "Click here to edit the name of the file.",
  step6_docsActions_title: "5. More actions",
  step6_docsActions_text:
    "Click on the 3 dots to open a menu with more actions.",
  step7_docsActions_title: "6. Information",
  step7_docsActions_text:
    "Here you can view the file or folder size, creation date, permissions, change history, and list of email recipients. (Refer to the guide for this feature)",
  step8_docsActions_title: "7. Move",
  step8_docsActions_text:
    "Click here to open the menu to move files. (See Guide Me for this function)",
  step9_docsActions_title: "8. Copy",
  step9_docsActions_text: "Click here to copy the file to the clipboard.",
  step10_docsActions_title: "9. Delete",
  step10_docsActions_text:
    "Click here if you want to permanently delete the file.",
  step_final_docsActions:
    "You have learned how to execute a vary of actions on files/folders.",
  // MOVE TO - guide me steps
  step1_docsMoveTo_title: "1. More actions",
  step1_docsMoveTo_text:
    "Click on the 3 dots and a menu with more actions will appear.",
  step2_docsMoveTo_title: "2. Move",
  step2_docsMoveTo_text: "Click here to open the menu to move files.",
  step3_docsMoveTo_title: "3. Move file",
  step3_docsMoveTo_text:
    "Select the new desired path and click accept to move the file.",
  step4_docsMoveTo_title: "4. Accept",
  step4_docsMoveTo_text:
    "Click here to confirm the new location and move the file.",
  step_final_docsMoveTo: "You have learned how to move files/folders.",
  // FILE INFO - guide me steps
  step1_docsFileInfo_title: "1. More actions",
  step1_docsFileInfo_text:
    "Click on the 3 dots and a menu with more actions will appear.",
  step2_docsFileInfo_title: "2. Information",
  step2_docsFileInfo_text:
    "Here you can see the size of the file and/or folder, the creation date, permissions, the history of changes, and the list of emails that have been sent.",
  step3_docsFileInfo_title: "3. Manage permissions",
  step3_docsFileInfo_text:
    "Click here to manage which companies and people have access to the document.",
  step4_docsFileInfo_title: "4. Change history",
  step4_docsFileInfo_text:
    "Click here to view information about the changes made to the documents, and who made the changes.",
  step5_docsFileInfo_title: "5. Email history",
  step5_docsFileInfo_text:
    "Click here to see the email history related to the document.",
  step5_1_docsFileInfo_title: "5.1 Download",
  step5_1_docsFileInfo_text:
    "Click here to download the information from the email.",
  step_final_docsFileInfo:
    "You have learned how to see information about files/folders.",
  // SETTINGS - guide me steps
  step1_docsSettings_title: "2. Open settings menu",
  step1_docsSettings_text: "Click here to open the settings menu.",
  step2_docsSettings_title: "3. Distribution list",
  step2_docsSettings_text:
    "Add or remove people who will have default access to the document module.",
  step3_docsSettings_title: "4. Save",
  step3_docsSettings_text: "Click here to save the settings.",
  step_final_docsSettings: "You have learned how to ajust the settings.",

  lastEdit: "Last Edit",
  uploadedCreatedBy: "Uploaded / Created By",
  uploadedCreatedOn: "Uploaded / Created On",
  recentDocuments: "Recent Documents",
  addFolder: "Add Folder",
  addFile: "Add File",
  folderIsPublic: "This folder is public to everybody in",
  folderIsPrivate: "This folder is private",
  documentMovedMessage: "Document moved successfully.",
  folderMovedMessage: "Folder moved successfully.",
  recentDocument: "Recent document",
  documentsRecycleBin: "Documents Recycle Bin",
  noDocumentsCreated: "No documents created",
  addDocumentsPrompt: "If you want to add documents, click on the add button",
  fileUpdatedSuccessfully: "File updated successfully.",
  folderMarkedSuccessfully: "Folder marked successfully.",
  fileMarkedSuccessfully: "File marked successfully.",
  folderDeletedSuccessfully: "Folder(s) deleted successfully",
  fileDeletedSuccessfully: "File(s) deleted successfully",
  storage: "Storage",
  sendToEmail: "Send to Email",
  sendTo: "Send to",
  subject: "Subject",
  body: "Body",
  selectAll: "Select All",
  deleteItems: "Delete Items",
  deleteItemsQuestion: "Are you sure you want to delete the selected item(s)?",
  deleteItemsWarning: "This action cannot be undone.",
  deleteFolder: "Delete Folder",
  deleteFile: "Delete File",
  deleteQuestion: "Are you sure you want to delete this ",
  deleteFolderQuestion: "Are you sure you want to delete the selected folder?",
  deleteFileQuestion: "Are you sure you want to delete the selected file?",
  file: "file",
  folder: "folder",
  renameFolder: "Rename Folder",
  renameFile: "Rename File",
  folderName: "Folder Name",
  fileName: "File Name",
  folderCreatedSuccessfully: "Folder created successfully.",
  fileCreatedSuccessfully: "File created successfully.",
  noFilesOnFavorites: "No files on favorites",
  noFilesOnRecycleBin: "No files on recycle bin",
  fileRestoreSuccessfully: "File restored successfully.",
  folderRestoreSuccessfully: "Folder restored successfully.",
};
