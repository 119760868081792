import React, { useState } from "react";
// MUI Components
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
// MUI Icons
import AddIcon from "@mui/icons-material/Add";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
// Custom Components
import CreateEnterpriseModal from "../../components/enterpriseDashboard/CreateEnterpriseModal";
import { useTranslation } from "react-i18next";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingY: 1,
    marginY: 2,
  },
};

const NoEnterprises = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const handleOpenCreate = () => setCreateModalOpen(true);
  const handleCloseCreate = () => setCreateModalOpen(false);
  const { t: tGeneral } = useTranslation("general");

  return (
    <>
      <Box sx={styles.container}>
        <Typography variant="h6" component={"h1"} textAlign="center">
          {tGeneral("noEnterprises")}
        </Typography>
      </Box>
      {/* <Box sx={styles.container}>
        <Button
          fullWidth
          sx={{ minWidth: 200, maxWidth: 400 }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenCreate}
        >
          Create Enterprise
        </Button>
      </Box> */}
      <CreateEnterpriseModal
        open={createModalOpen}
        handleClose={handleCloseCreate}
      />
    </>
  );
};

export default NoEnterprises;
