import { Close, MoreHoriz } from "@mui/icons-material";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { v4 } from "uuid";
import { COLORS } from "../../../../constants";
import { EditDesignDisciplineMenu } from "./EditDesignDisciplineMenu";

function DesignDisciplinesInput({ value }) {
  const { idEnterprise } = useParams();
  const { t: tTemplates } = useTranslation("templates");
  const { t: tGeneral } = useTranslation("general");

  const [focused, setFocused] = useState(false);
  const [search, setSearch] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [focusedDiscipline, setFocusedDiscipline] = useState(null);

  const menuRef = useRef(null);

  const { control } = useFormContext();
  const { append } = useFieldArray({ control, name: "disciplines" });

  const filteredDisciplines = value.filter(
    (d) => d.name.toLowerCase().includes(search.toLowerCase()) || search === "",
  );

  const handleCreate = () => {
    append({
      name: search,
      color: COLORS[0].color,
      id: v4(),
      elements: [
        {
          id: v4(),
          idAux: "",
          value: 0,
          startsAt: 0,
          name: "",
          files: [],
          checklist: {
            id: v4(),
            category: "required",
            createdDate: new Date(),
            items: [],
          },
          parent: null,
        },
      ],
      idEnterprise,
    });
    setSearch("");
    setFocused(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !anchorEl
      ) {
        setFocused(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef, anchorEl]);

  return (
    <Box width="100%">
      <Box display="flex" alignItems="center" columnGap="0.25rem">
        {value.map((discipline) => (
          <Box
            display="flex"
            sx={{
              backgroundColor: `${discipline.color}20`,
              border: `1px solid ${discipline.color}`,
            }}
            alignItems="center"
            px="0.25rem"
            borderRadius="0.25rem"
            height="24px"
          >
            <Typography fontSize="0.875rem" color="#4B4B4B">
              {discipline.name}
            </Typography>
          </Box>
        ))}
        <Box position="relative" flex={1}>
          <TextField
            placeholder={tTemplates("selectDisciplines")}
            onFocus={() => setFocused(true)}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
            size="small"
            sx={{
              "& .MuiOutlinedInput-notchedOutline": { border: "none" },
            }}
          />

          <Box
            position="absolute"
            top="40px"
            display={focused ? "block" : "none"}
            sx={{ backgroundColor: "white" }}
            width="300px"
            ref={menuRef}
          >
            {filteredDisciplines.map((d) => (
              <Box display="flex">
                <Button
                  fullWidth
                  sx={{
                    textTransform: "none",
                    flex: 1,
                    color: "black",
                    justifyContent: "flex-start",
                  }}
                  variant="text"
                >
                  <Typography>{d.name}</Typography>
                </Button>
                <IconButton
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    setFocusedDiscipline(d);
                  }}
                >
                  <MoreHoriz />
                </IconButton>
              </Box>
            ))}
            {filteredDisciplines.length === 0 && search !== "" && (
              <Button
                fullWidth
                sx={{
                  textTransform: "none",
                  color: "black",
                  justifyContent: "flex-start",
                }}
                variant="text"
                onClick={() => handleCreate()}
              >
                {tGeneral("add")} &quot;{search}&quot;
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <EditDesignDisciplineMenu
        anchorEl={anchorEl}
        discipline={focusedDiscipline}
        onClose={() => setAnchorEl(null)}
      />
    </Box>
  );
}

export { DesignDisciplinesInput };
