import React, { useEffect, useState } from "react";
// Redux reducers & selectors
import { useDispatch, useSelector } from "react-redux";
// Mui components
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

import { Check, Save } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import {
  selectTheme,
  setDarkMode,
  setLocale,
} from "../../features/preferences/preferencesSlice";
// Assests
import DarkMode from "../../assets/preferences/DarkMode.png";
import LightMode from "../../assets/preferences/LightMode.png";
import TextTranslation from "../../assets/preferences/TextTranslation.png";
import {
  useGetUserPreferencesQuery,
  useUpdateUserPreferencesMutation,
} from "../../features/userPreferences/userPreferencesApiSlice";
import { PopUpAlert } from "../../components/PopUpAlert";
import { TooltipIconButton } from "../../components/shared";

function Preferences() {
  const dispatch = useDispatch();
  const { t: tGeneral } = useTranslation("general");
  const { t: tPreferences } = useTranslation("preferences");
  const { reset, control, handleSubmit } = useForm({
    defaultValues: {
      theme: null,
      translationFeature: "off",
      dailyDigest: false,
      weeklyDigest: false,
      monthlyDigest: false,
    },
  });

  // We check if theme == dark to render proper logo dynamically
  const darkMode = useSelector(selectTheme);

  const [isDarkMode, setIsDarkMode] = useState();

  const { data } = useGetUserPreferencesQuery();
  const [updateUserPreferences, { isLoading, isSuccess, isError, error }] =
    useUpdateUserPreferencesMutation();

  useEffect(() => {
    if (data) {
      const { id, ...rest } = data;
      reset(rest);
    }
  }, [reset, data]);

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert(
        "success",
        tGeneral("updated"),
        tGeneral("updatedSuccessfully"),
      );
    }
    if (isError) {
      PopUpAlert("error", "Error", error.message);
    }
  }, [isSuccess, isError, error, tGeneral]);

  useEffect(() => {
    setIsDarkMode(darkMode);
  }, [darkMode]);

  const styles = {
    title: {
      bgcolor: "#FFC42D",
      display: "inline-block",
      p: "1px 15px",
      borderBottomRightRadius: "10px",
      mb: "20px",
    },
    cardMode: {
      borderRadius: 5,
      width: "195px",
      height: "145px",
      justifyContent: "center",
      backgroundColor: isDarkMode ? "#000000" : "#DCDCDC",
    },
    cancelBtn: {
      width: "18%",
      m: 1,
      borderRadius: 1,
      color: "black",
      backgroundColor: "white",
      borderColor: "black",
      border: 1,
      ":hover": {
        color: "white",
        backgroundColor: "#b4b4b4",
      },
    },
    saveBtn: {
      width: "18%",
      borderRadius: 1,
      m: 1,
    },
  };

  function SectionTitle({ title, ...props }) {
    return [
      <Divider key={`${title}divider`} />,
      <Typography
        key={title}
        fontSize="1.125rem"
        sx={styles.title}
        width="auto"
      >
        {title}
      </Typography>,
    ];
  }

  const onSubmit = (formData) => {
    updateUserPreferences({ ...formData, ...(data && { id: data.id }) });
    dispatch(setLocale(formData.translationFeature));
  };

  return (
    <Box
      sx={{ width: "100%" }}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box display="flex">
        <Typography
          fontSize="36px"
          align="left"
          fontWeight="bold"
          mb={3}
          flex={1}
        >
          {tGeneral("preferences")}
        </Typography>
        <TooltipIconButton
          icon={<Save />}
          label={tGeneral("save")}
          type="submit"
        />
      </Box>
      {/* Mode */}
      <Box sx={{ height: "85vh", overflowY: "auto", pb: "10px" }}>
        {/* <SectionTitle title={tPreferences("mode")} />
        <Box display="flex" columnGap="20px" flexWrap="wrap">
          <Box>
            <Card
              sx={{
                ...styles.cardMode,
                border: isDarkMode ? "2px" : "3px solid #0088F5",
              }}
              onClick={(e) => {
                setIsDarkMode(false);
              }}
            >
              <CardActionArea>
                <CardMedia
                  name="light"
                  component="img"
                  height="145px"
                  width="195px"
                  image={LightMode}
                  alt="Light Mode"
                />
              </CardActionArea>
            </Card>
            <Stack direction="row" alignItems="center" gap={1} marginY={2}>
              {!isDarkMode && <Check sx={{ color: "#0088F5" }} />}
              <Typography sx={{ color: isDarkMode ? "" : "#0088F5" }}>
                {tGeneral("light")}
              </Typography>
            </Stack>
          </Box>
          <Box>
            <Card
              sx={{
                ...styles.cardMode,
                border: isDarkMode ? "3px solid #0088F5" : "2px",
                cursor: "not-allowed",
              }}
            >
              <CardActionArea disabled>
                <CardMedia
                  name="dark"
                  component="img"
                  height="145px"
                  width="195px"
                  image={DarkMode}
                  alt="Dark Mode"
                  sx={{ opacity: "0.2" }}
                />
              </CardActionArea>
            </Card>
            <Stack direction="row" alignItems="center" gap={1} marginY={2}>
              {isDarkMode && <Check sx={{ color: "#0088F5" }} />}
              <Typography color="gray">{tGeneral("dark")}</Typography>
            </Stack>
          </Box>
        </Box> */}
        <SectionTitle title={tPreferences("translationFeature")} />
        <Box display="flex" mb={4}>
          <Card sx={{ flex: 1, p: "20px", borderRadius: "20px" }}>
            <Box display="flex" alignItems="center" pb="10px" columnGap="10px">
              <img height="80px" src={TextTranslation} alt="Translation text" />
              <Typography fontSize="2.2rem">{tPreferences("text")}</Typography>
            </Box>
            <Divider />
            <FormControl>
              <Controller
                control={control}
                name="translationFeature"
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <FormControlLabel
                      value="es"
                      label="Español (ES)"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="pt"
                      label="Português (PT)"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="en"
                      label="English (EN)"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="zh"
                      label="中文 (CN)"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="ja"
                      label="日本語 (JP)"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="ko"
                      label="한국어 (KR)"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value="off"
                      label={tGeneral("none")}
                      control={<Radio />}
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Card>
          <Box flex={1} />
        </Box>
        <SectionTitle title={tPreferences("emailUpdates")} />
        <Box display={"flex"} flexDirection={"column"} ml={3} gap={1}>
          <Controller
            name="dailyDigest"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<Switch checked={value} onChange={onChange} />}
                label={tPreferences("daily")}
              />
            )}
          />
          <Controller
            name="weeklyDigest"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<Switch checked={value} onChange={onChange} />}
                label={tPreferences("weekly")}
              />
            )}
          />
          <Controller
            name="monthlyDigest"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<Switch checked={value} onChange={onChange} />}
                label={tPreferences("monthly")}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Preferences;
