import { Delete } from "@mui/icons-material";
import { Button, Menu, MenuItem, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useDeleteAllRevisionsMutation,
  useDeleteLastRevisionsMutation,
} from "../../features/drawings";
import { PopUpAlert } from "../PopUpAlert";
import { ModalWithAction } from "../shared";

const DELETE_TYPES = {
  deleteAll: "deleteAll",
  deleteLast: "deleteLast",
};

function DeleteDrawingsButton({
  selectedDrawings,
  onDelete,
  anchorEl,
  setAnchorEl,
}) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");
  const [deleteType, setDeleteType] = useState(null);

  const [
    deleteLastRevisions,
    {
      isLoading: isDeleteLastLoading,
      isSuccess: isDeleteLastSuccess,
      isError: isDeleteLastError,
      error: deleteLastError,
    },
  ] = useDeleteLastRevisionsMutation();
  const [
    deleteAllRevisions,
    {
      isLoading: isDeleteAllLoading,
      isSuccess: isDeleteAllSuccess,
      isError: isDeleteAllError,
      error: deleteAllError,
    },
  ] = useDeleteAllRevisionsMutation();

  const handleDelete = () => {
    const formattedSelectedDrawings = (selectedDrawings || []).filter(
      (id) => !id.includes("disciplineName:"),
    );

    if (deleteType === DELETE_TYPES.deleteAll) {
      deleteAllRevisions({ ids: formattedSelectedDrawings });
    } else {
      deleteLastRevisions({ ids: formattedSelectedDrawings });
    }
  };

  useEffect(() => {
    if (isDeleteAllSuccess || isDeleteLastSuccess) {
      PopUpAlert(
        "success",
        tGeneral("deleted"),
        "Drawings deleted successfully",
      );
      setDeleteType(null);
      onDelete();
    }
    if (isDeleteAllError || isDeleteLastError) {
      PopUpAlert(
        "error",
        tGeneral("error"),
        deleteType === DELETE_TYPES.deleteAll
          ? deleteAllError.message
          : deleteLastError.message,
      );
      setDeleteType(null);
      onDelete();
    }
  }, [
    isDeleteLastSuccess,
    isDeleteAllSuccess,
    isDeleteLastError,
    isDeleteAllError,
    deleteAllError,
    deleteLastError,
    deleteType,
    tGeneral,
    onDelete,
  ]);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => setDeleteType(DELETE_TYPES.deleteAll)}>
          {tGeneral("deleteAll")}
        </MenuItem>
        <MenuItem onClick={() => setDeleteType(DELETE_TYPES.deleteLast)}>
          {tDrawings("deleteLastRevision")}
        </MenuItem>
      </Menu>
      <ModalWithAction
        title={tGeneral("delete")}
        isOpen={Boolean(deleteType)}
        onClose={() => setDeleteType(null)}
        icon="delete"
        content={tDrawings("deleteDrawingsMessage")}
        actionLabel={tGeneral("delete")}
        action={handleDelete}
        isLoading={isDeleteAllLoading || isDeleteLastLoading}
      />
    </>
  );
}

export { DeleteDrawingsButton };
