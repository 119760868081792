export const notificationActions = {
  CREATE: "생성했습니다 {{object}}",
  EDIT: "편집했습니다 {{object}}",
  CLOSE: "닫았습니다 {{object}}",
  RESTORE: "복원했습니다 {{object}}",
  ANSWER: "{{object}}에서 질문에 답변했습니다",
  NEW_TOPIC: "{{object}}에서 새로운 주제를 생성했습니다",
  COMMENT: "{{object}}에 댓글을 남겼습니다",
  REOPEN: "{{object}}을(를) 다시 열었습니다",
  OFFICIAL_RESPONSE: "{{object}}에서 공식 응답으로 표시했습니다",
  DRAFT: "{{object}}을(를) 초안으로 생성했습니다",
  APPROVE: "{{object}}을(를) 승인했습니다",
  DELETE: "{{object}}을(를) 삭제했습니다",
  START: "{{object}}을(를) 시작했습니다",
  CONFIRM: "{{object}}에 참석을 확인했습니다",
  CREATE_PHOTO: "사진을 업로드했습니다",
  CREATE_ALBUM: "앨범을 생성했습니다",
  CREATE_VIDEO: "비디오를 생성했습니다",
  CREATE_FOLDER: "폴더를 생성했습니다",
  CREATE_FILE: "파일을 업로드했습니다",
  MOVE_FILE: "파일을 이동했습니다",
  MOVE_FOLDER: "폴더를 이동했습니다",
  CREATE_SCHEDULED: "예정된 공지를 생성했습니다",
  REVISION: "개정을 생성했습니다",
  CREATE_ANNOTATION: "주석을 생성했습니다",
  ASSIGN: "주석을 할당했습니다",
  LINK: "주석을 연결했습니다",
  SIGN: "{{object}}을(를) 서명했습니다",
  REJECT: "{{object}}을(를) 거절했습니다",
  NEW_REVISION: "이 검사의 개정을 만들었습니다",
  rfi: "RFI",
  drawing: "도면",
  task: "작업",
  daily_report: "일일 보고서",
  dailyReport: "일일 보고서",
  image: "이미지",
  document: "문서",
  inspection: "검사",
  meeting: "회의",
};
