import { Box, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const SupportMenuModalWorkflow = ({ open, diagramImageUrl, onClose }) => {
  // Modal style img
  const modalStyleImage = {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "90%",
    transform: "translate(-50%, -50%)",
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    p: 0,
  };

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Box sx={modalStyleImage}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <img
          src={diagramImageUrl}
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      </Box>
    </Modal>
  );
};

export { SupportMenuModalWorkflow };
