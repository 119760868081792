import { InsertDriveFile } from "@mui/icons-material";
import aviIcon from "../../assets/documents/avi.png";
import docIcon from "../../assets/documents/doc.png";
import dwgIcon from "../../assets/documents/dwg.svg";
import folderIcon from "../../assets/documents/folder.svg";
import gifIcon from "../../assets/documents/gif.png";
import heicIcon from "../../assets/documents/heic.png";
import jpegIcon from "../../assets/documents/jpg.svg";
import pdfIcon from "../../assets/documents/pdf.svg";
import pngIcon from "../../assets/documents/png.png";
import pptIcon from "../../assets/documents/ppt.svg";
import svgIcon from "../../assets/documents/svg.svg";
import xlsIcon from "../../assets/documents/xls.svg";
import zipIcon from "../../assets/documents/zip.png";
import videoIcon from "../../assets/icons/sharedModules/video.png";

function FileIcon({ extension }) {
  let src = null;
  if (extension.match(/folder/)) {
    src = folderIcon;
  }
  if (extension.match(/pdf/)) {
    src = pdfIcon;
  }
  if (extension.match(/svg/)) {
    src = svgIcon;
  }
  if (extension.match(/ppt/)) {
    src = pptIcon;
  }
  if (extension.match(/xls/)) {
    src = xlsIcon;
  }
  if (extension.match(/jpeg|jpg/)) {
    src = jpegIcon;
  }
  if (extension.match(/dwg/)) {
    src = dwgIcon;
  }
  if (extension.match(/png/)) {
    src = pngIcon;
  }
  if (extension.match(/avi/)) {
    src = aviIcon;
  }
  if (extension.match(/doc/)) {
    src = docIcon;
  }
  if (extension.match(/gif/)) {
    src = gifIcon;
  }
  if (extension.match(/heic/)) {
    src = heicIcon;
  }
  if (extension.match(/zip|rar/)) {
    src = zipIcon;
  }
  if (extension.match(/mp4|mov/)) {
    src = videoIcon;
  }

  if (src === null) {
    return <InsertDriveFile />;
  }
  return <img style={{ maxHeight: "25px" }} src={src} alt="Icon" />;
}

export { FileIcon };
