import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  useDeleteCostCodeMutation,
  useGetCostCodesQuery,
} from "../../../../features/project/costCodeApiSlice";
import { useMemo, useState } from "react";
import { PopUpAlert } from "../../../PopUpAlert";
import { Add, Delete, Edit, MoreVert } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Menu,
} from "@mui/material";
import { CostCodeModal } from "./CostCodeModal";
import { ModalWithAction } from "../../../shared";

const CostCodeSelect = ({ selectedCostCode, onChange = () => {} }) => {
  const { idProject } = useParams();

  const { t: tGeneral } = useTranslation("general");
  const { t: tSubmittals } = useTranslation("submittals");

  const { data: projectCostCodes } = useGetCostCodesQuery(idProject);
  const [deleteCostCode, { isLoading: isDeleteLoading }] =
    useDeleteCostCodeMutation();

  const [openModalCostCode, setOpenModalCostCode] = useState(false);
  const [focusedCostCode, setFocusedCostCode] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const [openDelete, setOpenDelete] = useState(false);

  const costCodes = useMemo(() => projectCostCodes ?? [], [projectCostCodes]);

  const handleMenuOpen = (event, costCode) => {
    setMenuAnchorEl(event.currentTarget);
    setFocusedCostCode(costCode);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setFocusedCostCode(null);
  };

  const handleDeleteCostCode = async () => {
    let body = {
      idCostCode: focusedCostCode.id,
      idProject,
    };
    try {
      const response = await deleteCostCode(body).unwrap();
      if (response) {
        PopUpAlert("success", tGeneral("success"), tGeneral("costCodeDeleted"));
      }
      setFocusedCostCode(null);
      setOpenDelete(false);
    } catch (error) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
    }
  };

  const menuOptions = [
    {
      title: tGeneral("edit"),
      icon: <Edit fontSize="small" />,
      onClick: () => {
        setOpenModalCostCode(true);
      },
    },
    {
      title: tGeneral("delete"),
      icon: <Delete fontSize="small" />,
      onClick: () => {
        setOpenDelete(true);
      },
    },
  ];

  return (
    <>
      <FormControl fullWidth>
        <InputLabel>{tSubmittals("costCode")}</InputLabel>
        <Select
          id="type-select"
          labelId="type-select-label"
          value={selectedCostCode || ""}
          onClose={() => {
            setFocusedCostCode(null);
          }}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          label={tSubmittals("costCode")}
          MenuProps={{ sx: { maxHeight: "300px" } }}
          renderValue={(value) => (
            <Typography>
              {costCodes.find((costCode) => costCode.id === value)?.name}
            </Typography>
          )}
        >
          {costCodes.map((costCode) => (
            <MenuItem value={costCode.id} key={costCode.id}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                {costCode.code} - {costCode.name}
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMenuOpen(e, costCode);
                  }}
                >
                  <MoreVert />
                </IconButton>
              </Box>
            </MenuItem>
          ))}
          <Box display="flex">
            <Button
              variant="text"
              startIcon={<Add />}
              fullWidth
              style={{
                justifyContent: "flex-start",
                paddingLeft: "8%",
                color: "black",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModalCostCode(true);
              }}
            >
              {tGeneral("addCostCode")}
            </Button>
          </Box>
          <Menu
            anchorEl={menuAnchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
          >
            {menuOptions.map((option) => (
              <MenuItem
                key={option.title}
                onClick={() => {
                  option.onClick();
                  setMenuAnchorEl(null);
                }}
              >
                {option.icon}
                <Typography fontSize={12} fontWeight="bold" sx={{ mx: 1 }}>
                  {option.title}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Select>
      </FormControl>
      <CostCodeModal
        isOpen={openModalCostCode}
        selectedCostCode={focusedCostCode}
        onClose={(e) => {
          setOpenModalCostCode(false);
          setFocusedCostCode(null);
        }}
      />
      <ModalWithAction
        isOpen={openDelete}
        onClose={() => {
          setOpenDelete(false);
          setFocusedCostCode(null);
        }}
        title={tGeneral("deleteCostCode")}
        content={tGeneral("deleteGeneric")}
        icon="delete"
        actionLabel={tGeneral("delete")}
        action={handleDeleteCostCode}
        isLoading={isDeleteLoading}
      />
    </>
  );
};

export default CostCodeSelect;
