import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  inspections: [],
  current: null,
  settingsInspectionPopupOpen: false,
  sampleInspection: null,
  sampleDailyReportId: null,
  settingsDailyReportsPopupOpen: false,
  sampleTask: null,
  settingsTasksPopupOpen: false,
  addPhotoModalOpen: false,
  addAlbumModalOpen: false,
  sampleImage: null,
  imageViewerOpen: false,
  settingsPhotosPopupOpen: false,
};

export const inspectionSlice = createSlice({
  name: "inspections",
  initialState,
  reducers: {
    setInspections: (state, action) => {
      state.inspections = action.payload;
    },
    setCurrentInspection: (state, action) => {
      state.current = action.payload;
    },
    setSettingsInspectionPopupOpen: (state, action) => {
      state.settingsInspectionPopupOpen = action.payload;
    },
    setSampleInspection: (state, action) => {
      state.sampleInspection = action.payload;
    },
    setSampleDailyReportId: (state, action) => {
      state.sampleDailyReportId = action.payload;
    },
    setSettingsDailyReportsPopupOpen: (state, action) => {
      state.settingsDailyReportsPopupOpen = action.payload;
    },
    setSampleTask: (state, action) => {
      state.sampleTask = action.payload;
    },
    setSettingsTasksPopupOpen: (state, action) => {
      state.settingsTasksPopupOpen = action.payload;
    },
    setAddPhotoModalOpen: (state, action) => {
      state.addPhotoModalOpen = action.payload;
    },
    setAddAlbumModalOpen: (state, action) => {
      state.addAlbumModalOpen = action.payload;
    },
    setSampleImage: (state, action) => {
      state.sampleImage = action.payload;
    },
    setImageViewerOpen: (state, action) => {
      state.imageViewerOpen = action.payload;
    },
    setSettingsPhotosPopupOpen: (state, action) => {
      state.settingsPhotosPopupOpen = action.payload;
    },
    resetInspections: () => initialState,
  },
});

export const selectAllInspections = (state) => state.inspections.inspections;

export const selectCurrentInspection = (state) => state.inspections.current;

export const selectSettingsInspectionPopupOpen = (state) => {
  return state.inspections.settingsInspectionPopupOpen;
};

export const selectSettingsDailyReportsPopupOpen = (state) =>
  state.inspections.settingsDailyReportsPopupOpen;

export const selectSampleInspection = (state) =>
  state.inspections.sampleInspection;

export const selectSampleDailyReportId = (state) =>
  state.inspections.sampleDailyReportId;

export const selectSampleTask = (state) => state.inspections.sampleTask;

export const selectSettingsTasksPopupOpen = (state) =>
  state.inspections.settingsTasksPopupOpen;

export const selectAddPhotoModalOpen = (state) =>
  state.inspections.addPhotoModalOpen;

export const selectAddAlbumModalOpen = (state) =>
  state.inspections.addAlbumModalOpen;

export const selectSampleImage = (state) => state.inspections.sampleImage;

export const selectImageViewerOpen = (state) =>
  state.inspections.imageViewerOpen;

export const selectSettingsPhotosPopupOpen = (state) =>
  state.inspections.settingsPhotosPopupOpen;

export const {
  setInspections,
  setCurrentInspection,
  setSettingsInspectionPopupOpen,
  setSampleInspection,
  resetInspections,
  setSampleDailyReportId,
  setSettingsDailyReportsPopupOpen,
  setSampleTask,
  setSettingsTasksPopupOpen,
  setAddPhotoModalOpen,
  setAddAlbumModalOpen,
  setSampleImage,
  setImageViewerOpen,
  setSettingsPhotosPopupOpen,
} = inspectionSlice.actions;

export default inspectionSlice.reducer;
