// @ts-check
/* eslint jsx-a11y/control-has-associated-label: 0 */
/* eslint react/prop-types: 0 */

import AddBoxIcon from "@mui/icons-material/AddBox";
import CachedRounded from "@mui/icons-material/CachedRounded";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import DoNotDisturbOffIcon from "@mui/icons-material/DoNotDisturbOff";
import EditIcon from "@mui/icons-material/Edit";
import FlagRounded from "@mui/icons-material/FlagRounded";
import FolderRounded from "@mui/icons-material/FolderRounded";
import GroupsRounded from "@mui/icons-material/GroupsRounded";
import LibraryAddCheckRounded from "@mui/icons-material/LibraryAddCheckRounded";
import NoteRounded from "@mui/icons-material/NoteRounded";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ScienceIcon from "@mui/icons-material/Science";
import StickyNote2Rounded from "@mui/icons-material/StickyNote2Rounded";
import SubjectIcon from "@mui/icons-material/Subject";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import {
  Alert,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  tabsClasses,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// eslint-disable-next-line no-unused-vars
import dayjs from "dayjs";
import React, { forwardRef, useEffect, useState } from "react";
import { Controller, FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import MaskedInput from "react-text-mask";
import Swal from "sweetalert2";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

import { useGetDrawingsQuery } from "../../../../features/drawings";
import { useGetDailyReportsQuery } from "../../../../features/project/modules/dailyReports/dailyReportsApiSlice";
import { useGetDocumentsQuery } from "../../../../features/project/modules/documents/documentsApiSlice";
import { useGetActiveInspectionsQuery } from "../../../../features/project/modules/inspections/apiSlice";
import { useGetMeetingsQuery } from "../../../../features/project/modules/meetings/meetingApiSlice";
import { useGetRfisQuery } from "../../../../features/project/modules/rfiApiSlice";
import { useGetTasksQuery } from "../../../../features/project/modules/tasks/tasksApiSlice";
import { colors, confirm, renderCurrency, renderDate } from "../../../../utils/projectSchedule";
import { AssignedUsersTooltip } from "../../../shared/AssignedUsersTooltip";
import Assignees from "../Assignees";
import Colors from "../Colors";
import Comments from "../Comments";
import Histories from "../Histories";
import TransitionComponent from "../TransitionComponent";
import DailyReports from "./DailyReports";
import Documents from "./Documents";
import Drawings from "./Drawings";
import Inspections from "./Inspections";
import Meetings from "./Meetings";
import RFIs from "./RFIs";
import Tasks from "./Tasks";

const recursiveFlatMap = (a, f) => {
  return a.reduce((acc, item) => {
    const mapped = f(item);
    if (Array.isArray(mapped)) {
      acc.push(...recursiveFlatMap(mapped, f));
    } else {
      acc.push(mapped);
    }
    return acc;
  }, []);
};

const toBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

// @ts-ignore
const CurrencyInput = forwardRef(({ maskOptions, onChange, value, ...inputProps }, ref) => {
  const currencyMask = createNumberMask({
    allowDecimal: true,
    allowLeadingZeroes: false,
    allowNegative: false,
    decimalLimit: 2,
    decimalSymbol: ".",
    includeThousandsSeparator: true,
    integerLimit: 999,
    prefix: "$",
    suffix: "",
    thousandsSeparatorSymbol: ",",
    ...maskOptions,
  });

  const handleChange = (e) => {
    onChange(e.target.value || "");
  };

  return (
    <MaskedInput
      {...inputProps}
      mask={currencyMask}
      onChange={handleChange}
      ref={ref}
      value={value != null ? value : ""}
    />
  );
});

export default function Activity(props) {
  const {
    client,
    color: c,
    dataSource,
    dateFormat,
    idEnterprise,
    idProject,
    setDataSource,
    object,
    open,
    setOpen,
    setRoadblockObject,
    setRoadblockOpen,
    users,
  } = props;

  const [loading, setLoading] = useState(false);

  const [color, setColor] = useState(object?.color ? colors.find((color) => color.name === object?.color) ?? c : c);
  const [assignees, setAssignees] = useState(object?.assignees ?? []);

  const [dailyReportsOpen, setDailyReportsOpen] = useState(false);
  const [dailyReportsList, setDailyReportsList] = useState(object?.relations?.dailyReports ?? []);
  const [documentsOpen, setDocumentsOpen] = useState(false);
  const [documentsList, setDocumentsList] = useState(object?.relations?.documents ?? []);
  const [drawingsOpen, setDrawingsOpen] = useState(false);
  const [drawingsList, setDrawingsList] = useState(object?.relations?.drawings ?? []);
  const [inspectionsOpen, setInspectionsOpen] = useState(false);
  const [inspectionsList, setInspectionsList] = useState(object?.relations?.inspections ?? []);
  const [meetingsOpen, setMeetingsOpen] = useState(false);
  const [meetingsList, setMeetingsList] = useState(object?.relations?.meetings ?? []);
  const [rfisOpen, setRFIsOpen] = useState(false);
  const [rfisList, setRFIsList] = useState(object?.relations?.rfis ?? []);
  const [tasksOpen, setTasksOpen] = useState(false);
  const [tasksList, setTasksList] = useState(object?.relations?.tasks ?? []);
  const [showHistory, setShowHistory] = useState(true);

  const [tab, setTab] = useState(0);
  const [descriptionCharCount, setDescriptionCharCount] = useState(0);
  const [titleCharCount, setTitleCharCount] = useState(0);

  const { t: tGeneral } = useTranslation("general");
  const { t: tProjectSchedule } = useTranslation("projectSchedule");
  const { t: tStatus } = useTranslation("status");

  const dailyReports = useGetDailyReportsQuery(
    { idEnterprise, idProject },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const documents = useGetDocumentsQuery({
    idEnterprise,
    idProject,
    params: {
      createdBy: null,
      endDate: null,
      favorite: false,
      idFolder: null,
      recent: null,
      startDate: null,
      allFilesAndFolders: true,
    },
  });
  const drawings = useGetDrawingsQuery(
    { idEnterprise, idProject },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const inspections = useGetActiveInspectionsQuery(
    { idEnterprise, idProject },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const meetings = useGetMeetingsQuery(
    { idEnterprise, idProject },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const rfis = useGetRfisQuery(
    { idEnterprise, idProject, params: {} },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const tasks = useGetTasksQuery(
    { idEnterprise, idProject },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const formContext = useForm({
    defaultValues: {
      title: object?.title || "",
      description: object?.description || "",
      progress: object?.progress || 0,
      projectCostBudget: object?.projectCostBudget || 0,
      // eslint-disable-next-line no-underscore-dangle, react/prop-types
      projectCostExpenses: object?.projectCostExpenses_ || [{ title: "", amount: 0, file: {} }],
      scheduledTimesStart: object?.scheduledTimesStart?.slice(0, 10) || new Date().toISOString().slice(0, 10),
      scheduledTimesEnd: object?.scheduledTimesEnd?.slice(0, 10) || new Date().toISOString().slice(0, 10),
      realTimesStart: object?.realTimesStart?.slice(0, 10) || new Date().toISOString().slice(0, 10),
      realTimesEnd: object?.realTimesEnd?.slice(0, 10) || new Date().toISOString().slice(0, 10),
    },
  });

  const { control, formState, getValues, handleSubmit, reset, watch } = formContext;

  const { append, fields, remove } = useFieldArray({
    control,
    name: "projectCostExpenses",
  });

  useEffect(() => {
    reset({
      title: object?.title || "",
      description: object?.description || "",
      progress: object?.progress || 0,
      projectCostBudget: object?.projectCostBudget || 0,
      // eslint-disable-next-line no-underscore-dangle, react/prop-types
      projectCostExpenses: object?.projectCostExpenses_ || [{ title: "", amount: 0, file: {} }],
      scheduledTimesStart: object?.scheduledTimesStart?.slice(0, 10) || new Date().toISOString().slice(0, 10),
      scheduledTimesEnd: object?.scheduledTimesEnd?.slice(0, 10) || new Date().toISOString().slice(0, 10),
      realTimesStart: object?.realTimesStart?.slice(0, 10) || new Date().toISOString().slice(0, 10),
      realTimesEnd: object?.realTimesEnd?.slice(0, 10) || new Date().toISOString().slice(0, 10),
    });
  }, [object, reset]);

  const { comments = [], histories = [] } = object;

  const toggleHistory = () => {
    setShowHistory((prevShowHistory) => !prevShowHistory);
  };

  comments.sort((a, b) => {
    if (a.createdDate < b.createdDate) {
      return -1;
    }
    if (a.createdDate > b.createdDate) {
      return 1;
    }
    return 0;
  });

  histories.sort((a, b) => {
    if (a.createdDate < b.createdDate) {
      return -1;
    }
    if (a.createdDate > b.createdDate) {
      return 1;
    }
    return 0;
  });

  const modules = [
    { icon: <FlagRounded />, name: "Daily Reports", title: tProjectSchedule("Daily Reports") },
    { icon: <FolderRounded />, name: "Documents", title: tProjectSchedule("Documents") },
    { icon: <NoteRounded />, name: "Drawings", title: tProjectSchedule("Drawings") },
    { icon: <LibraryAddCheckRounded />, name: "Inspections", title: tProjectSchedule("Inspections") },
    { icon: <GroupsRounded />, name: "Meetings", title: tProjectSchedule("Meetings") },
    { icon: <CachedRounded />, name: "RFIs", title: tProjectSchedule("RFIs") },
    { icon: <StickyNote2Rounded />, name: "Tasks", title: tProjectSchedule("Tasks") },
  ];

  const projectCostExpenses = watch("projectCostExpenses")
    .map((item) => parseFloat((item.amount || "0").toString().replace(/[$,]/g, "")))
    .reduce((a, b) => a + b, 0);
  const projectCostDifference =
    parseFloat(watch("projectCostBudget").toString().replace(/[$,]/g, "")) - projectCostExpenses;

  const fetch = (groups) => {
    const activities = [];

    const traverse = (group) => {
      if (group.type === "activity") {
        activities.push(group);
      }
      if (group.child && group.child.length) {
        group.child.forEach(traverse);
      }
    };

    groups.forEach(traverse);

    return activities;
  };

  const activities = fetch(dataSource);

  const dependsOn = activities.filter((activity) => activity.id === object?.idActivity);

  const dependentOn = activities.filter((activity) => activity.idActivity === object?.id);

  const handleDelete = async () => {
    const isConfirm = await confirm(
      tProjectSchedule("Are you sure?"),
      `${tProjectSchedule("Are you sure you want to delete the activity")} ${object.title}?`,
      tProjectSchedule("Yes"),
      tProjectSchedule("No"),
    );
    if (isConfirm) {
      try {
        const response = await client.request({
          method: "DELETE",
          url: `/activities/${object.id}`,
        });
        setDataSource(response.data);
        const Toast = Swal.mixin({
          position: "center",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          toast: true,
        });
        Toast.fire({
          icon: "success",
          title: tProjectSchedule("Success"),
        });
      } catch (error) {
        const Toast = Swal.mixin({
          position: "center",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          toast: true,
        });
        Toast.fire({
          icon: "error",
          title: tProjectSchedule(error.response.data.error ? error.response.data.error : error),
        });
        // eslint-disable-next-line no-console
        console.error(error);
      }
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderOptions = (items, level) => {
    return items.map((item) => {
      if (item.type === "group") {
        return [
          { disabled: true, key: item.id, value: item.id, label: `${"-".repeat(level * 4)} ${item.title}` },
          renderOptions(item.child, level + 1),
        ];
      }
      if (item.type === "activity") {
        return [
          {
            disabled: item.id === object?.id,
            key: item.id,
            value: item.id,
            label: `${"-".repeat(level * 4)} ${item.title}`,
          },
        ];
      }
      return [];
    });
  };

  const process = async (data) => {
    setLoading(true);
    const projectCostExpenses = [];
    // eslint-disable-next-line no-restricted-syntax
    for await (const item of data.projectCostExpenses) {
      projectCostExpenses.push({
        ...item,
        amount: parseFloat(item.amount.toString().replace(/[$,]/g, "")),
        file: {
          contents: item.file.length > 0 ? await toBase64(item.file[0]) : "",
          name: item.file.length > 0 ? item.file[0].name : "",
        },
      });
    }
    try {
      if (!object.id) {
        const response = await client.request({
          data: {
            idGroup: object.idGroup,
            idActivity: undefined,
            title: data.title,
            description: data.description,
            assignees,
            progress: data.progress,
            projectCostBudget: parseFloat(data.projectCostBudget.toString().replace(/[$,]/g, "")),
            projectCostExpenses,
            scheduledTimesStart: data.scheduledTimesStart,
            scheduledTimesEnd: data.scheduledTimesEnd,
            realTimesStart: data.realTimesStart,
            realTimesEnd: data.realTimesEnd,
            color: color.name,
            metadata: {},
            relations: {
              dailyReports: dailyReportsList,
              documents: documentsList,
              drawings: drawingsList,
              inspections: inspectionsList,
              meetings: meetingsList,
              rfis: rfisList,
              tasks: tasksList,
            },
          },
          method: "POST",
          url: "/activities",
        });
        setDataSource(response.data);
      } else {
        const response = await client.request({
          data: {
            idGroup: object.idGroup,
            idActivity: undefined,
            title: data.title,
            description: data.description,
            assignees,
            progress: data.progress,
            projectCostBudget: parseFloat(data.projectCostBudget.toString().replace(/[$,]/g, "")),
            projectCostExpenses,
            scheduledTimesStart: data.scheduledTimesStart,
            scheduledTimesEnd: data.scheduledTimesEnd,
            realTimesStart: data.realTimesStart,
            realTimesEnd: data.realTimesEnd,
            color: color.name,
            metadata: object.metadata,
            relations: {
              dailyReports: dailyReportsList,
              documents: documentsList,
              drawings: drawingsList,
              inspections: inspectionsList,
              meetings: meetingsList,
              rfis: rfisList,
              tasks: tasksList,
            },
          },
          method: "PUT",
          url: `/activities/${object.id}`,
        });
        setDataSource(response.data);
      }
      const Toast = Swal.mixin({
        position: "center",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        toast: true,
      });
      Toast.fire({
        icon: "success",
        title: tProjectSchedule("Success"),
      });
    } catch (error) {
      const Toast = Swal.mixin({
        position: "center",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        toast: true,
      });
      Toast.fire({
        icon: "error",
        title: tProjectSchedule(error.response.data.error ? error.response.data.error : error),
      });
      // eslint-disable-next-line no-console
      console.error(error);
    }
    setLoading(false);
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={handleClose}
      open={open}
      PaperProps={{
        className: "project-schedule",
        sx: {
          borderRadius: "20px",
        },
      }}
      scroll="body"
      // @ts-ignore
      TransitionComponent={TransitionComponent}
    >
      <FormProvider {...formContext}>
        <form onSubmit={handleSubmit(process)}>
          <DialogTitle
            component="div"
            sx={{
              padding: 0,
            }}
          >
            <Box
              sx={{
                backgroundColor: color.light,
                position: "relative",
              }}
            >
              <Box
                sx={{
                  backgroundColor: color.dark,
                  bottom: 0,
                  left: 0,
                  position: "absolute",
                  top: 0,
                  width: `${object?.progress ?? 0}%`,
                  zIndex: 1,
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  padding: "30px",
                  position: "relative",
                  zIndex: 2,
                }}
              >
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      flexGrow: 1,
                      gap: "20px",
                    }}
                  >
                    <Controller
                      control={control}
                      name="title"
                      render={({ field }) => {
                        field.value ? setTitleCharCount(field.value.length) : setTitleCharCount(0);
                        return (
                          <>
                            <TextField
                              {...field}
                              error={formState.errors.title !== undefined}
                              fullWidth
                              helperText={formState.errors.title && tGeneral("fieldRequired")}
                              label={tProjectSchedule("Title")}
                              type="text"
                              onChange={(e) => {
                                setTitleCharCount(e.target.value.length);
                                field.onChange(e);
                              }}
                              inputProps={{
                                maxLength: 255,
                              }}
                            />
                            <FormHelperText
                              style={{
                                color: titleCharCount > 200 ? "red" : "inherit",
                              }}
                            >
                              {`${titleCharCount} / ${255}`}
                            </FormHelperText>
                          </>
                        );
                      }}
                      rules={{ required: true }}
                    />
                    <Assignees assignees={assignees} options={users} setAssignees={setAssignees} />
                  </Box>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <Colors
                      caption={tProjectSchedule("Choose color")}
                      color={color}
                      options={colors}
                      setColor={setColor}
                      zIndex={1301}
                    />
                    {object.id && (
                      <Box
                        onClick={handleDelete}
                        sx={{
                          alignItems: "center",
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <DeleteIcon />
                      </Box>
                    )}
                    <Box
                      onClick={handleClose}
                      sx={{
                        alignItems: "center",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <CloseIcon />
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      fontSize="md"
                      fontWeight="bold"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {tProjectSchedule("% Complete")}
                    </Typography>
                    <Controller
                      control={control}
                      name="progress"
                      render={({ field }) => {
                        return (
                          <TextField
                            {...field}
                            error={formState.errors.progress !== undefined}
                            fullWidth
                            helperText={formState.errors.progress && tGeneral("fieldRequired")}
                            inputProps={{ max: 100, min: 0, step: 1 }}
                            type="number"
                          />
                        );
                      }}
                      rules={{ required: true }}
                    />
                  </Box>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      fontSize="md"
                      fontWeight="bold"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {tProjectSchedule("Scheduled Times")}
                    </Typography>
                    <Controller
                      control={control}
                      name="scheduledTimesStart"
                      render={({ field }) => {
                        return (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              {...field}
                              format={dateFormat}
                              sx={{ width: "225px" }}
                              onChange={(newValue) => field.onChange(dayjs(newValue).format("YYYY-MM-DD"))}
                              value={field.value ? dayjs(field.value) : null}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!!formState.errors.scheduledTimesStart}
                                  fullWidth
                                  helperText={formState.errors.scheduledTimesStart && tGeneral("fieldRequired")}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        );
                      }}
                      rules={{ required: true }}
                    />
                    <Controller
                      control={control}
                      name="scheduledTimesEnd"
                      render={({ field }) => {
                        return (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              {...field}
                              format={dateFormat}
                              sx={{ width: "225px" }}
                              onChange={(newValue) => field.onChange(dayjs(newValue).format("YYYY-MM-DD"))}
                              value={field.value ? dayjs(field.value) : null}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!!formState.errors.scheduledTimesEnd}
                                  fullWidth
                                  helperText={formState.errors.scheduledTimesEnd && tGeneral("fieldRequired")}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        );
                      }}
                      rules={{ required: true }}
                    />
                  </Box>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <Typography
                      fontSize="md"
                      fontWeight="bold"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {tProjectSchedule("Real Times")}
                    </Typography>
                    <Controller
                      control={control}
                      name="realTimesStart"
                      render={({ field }) => {
                        return (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              {...field}
                              format={dateFormat}
                              sx={{ width: "225px" }}
                              onChange={(newValue) => field.onChange(dayjs(newValue).format("YYYY-MM-DD"))}
                              value={field.value ? dayjs(field.value) : null}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!!formState.errors.realTimesStart}
                                  fullWidth
                                  helperText={formState.errors.realTimesStart && tGeneral("fieldRequired")}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        );
                      }}
                      rules={{ required: true }}
                    />
                    <Controller
                      control={control}
                      name="realTimesEnd"
                      render={({ field }) => {
                        return (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              {...field}
                              format={dateFormat}
                              sx={{ width: "225px" }}
                              onChange={(newValue) => field.onChange(dayjs(newValue).format("YYYY-MM-DD"))}
                              value={field.value ? dayjs(field.value) : null}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={!!formState.errors.realTimesEnd}
                                  fullWidth
                                  helperText={formState.errors.realTimesEnd && tGeneral("fieldRequired")}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        );
                      }}
                      rules={{ required: true }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent
            dividers
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "0",
            }}
          >
            <Box
              flex={1}
              sx={{
                borderRight: "1px solid #e1e1e1",
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                padding: "30px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                  }}
                >
                  <SubjectIcon />
                  <strong>{tProjectSchedule("Description")}</strong>
                </Box>
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => {
                    field.value ? setDescriptionCharCount(field.value.length) : setDescriptionCharCount(0);
                    return (
                      <>
                        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column' }}>
                          <TextField
                            {...field}
                            error={formState.errors.description !== undefined}
                            fullWidth
                            helperText={formState.errors.description && tGeneral("fieldRequired")}
                            label={tProjectSchedule("Description")}
                            multiline
                            rows={2}
                            type="text"
                            onChange={(e) => {
                              setDescriptionCharCount(e.target.value.length);
                              field.onChange(e);
                            }}
                            inputProps={{
                              maxLength: 255,
                            }}
                          />
                          <FormHelperText
                            style={{
                              position: 'absolute',
                              right: 0,
                              bottom: '-20px',
                              color: descriptionCharCount > 200 ? 'red' : 'inherit',
                            }}
                          >
                            {`${descriptionCharCount} / ${255}`}
                          </FormHelperText>
                        </div>
                      </>
                    );
                  }}
                />
              </Box>
              <table className="project-schedule project-schedule-project-cost">
                <tbody>
                  <tr>
                    <td className="center" colSpan={3}>
                      <strong>{tProjectSchedule("$ Project Cost")}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td className="center">{tProjectSchedule("Budget")}</td>
                    <td className="center">{tProjectSchedule("Expenses")}</td>
                    <td className="center">{tProjectSchedule("Difference")}</td>
                  </tr>
                  <tr>
                    <td className="center">
                      <Controller
                        control={control}
                        defaultValue={object?.projectCostBudget || 0}
                        name="projectCostBudget"
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={formState.errors.projectCostBudget !== undefined}
                            fullWidth
                            helperText={formState.errors.projectCostBudget && tGeneral("fieldRequired")}
                            InputProps={{
                              inputComponent: CurrencyInput,
                            }}
                            placeholder="$0.00"
                            size="small"
                            type="text"
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </td>
                    <td className="center">
                      <TextField
                        disabled
                        fullWidth
                        placeholder="$0.00"
                        size="small"
                        type="text"
                        value={renderCurrency(projectCostExpenses)}
                      />
                    </td>
                    <td className="center">
                      <TextField
                        disabled
                        fullWidth
                        size="small"
                        type="text"
                        value={renderCurrency(projectCostDifference)}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="project-schedule project-schedule-project-cost">
                <tbody>
                  <tr>
                    <td className="center">
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                        }}
                      >
                        <AddBoxIcon
                          color="primary"
                          onClick={() => {
                            append({
                              title: "",
                              amount: 0,
                              file: {},
                            });
                          }}
                          sx={{
                            cursor: "pointer",
                          }}
                        />
                        <strong>{tProjectSchedule("Expenses")}</strong>
                      </Box>
                    </td>
                    <td className="center">
                      <strong>{tProjectSchedule("Cost")}</strong>
                    </td>
                    <td className="center">
                      <strong>{tProjectSchedule("File")}</strong>
                    </td>
                  </tr>
                  {fields.map((_, index) => {
                    const file = getValues(`projectCostExpenses.${index}.file`);
                    return (
                      <tr
                        key={
                          // eslint-disable-next-line react/no-array-index-key
                          index
                        }
                      >
                        <td className="center">
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              gap: "10px",
                            }}
                          >
                            <DisabledByDefaultIcon
                              color="error"
                              onClick={() => {
                                remove(index);
                              }}
                              sx={{
                                cursor: "pointer",
                              }}
                            />
                            <Controller
                              control={control}
                              name={`projectCostExpenses.${index}.title`}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  error={formState.errors.projectCostExpenses?.[index]?.title}
                                  fullWidth
                                  helperText={
                                    formState.errors.projectCostExpenses?.[index]?.title && tGeneral("fieldRequired")
                                  }
                                  placeholder={tProjectSchedule("Description")}
                                  size="small"
                                  type="text"
                                />
                              )}
                            />
                          </Box>
                        </td>
                        <td className="center">
                          <Controller
                            control={control}
                            defaultValue={
                              (watch(`projectCostExpenses.${index}.amount`) ?? "0").toString().replace(/[$,]/g, "") || 0
                            }
                            name={`projectCostExpenses.${index}.amount`}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                error={formState.errors.projectCostExpenses?.[index]?.amount}
                                fullWidth
                                helperText={
                                  formState.errors.projectCostExpenses?.[index]?.amount && tGeneral("fieldRequired")
                                }
                                InputProps={{
                                  inputComponent: CurrencyInput,
                                }}
                                placeholder="$0.00"
                                size="small"
                                type="text"
                              />
                            )}
                          />
                        </td>
                        <td className="center">
                          <Box
                            sx={{
                              alignItems: "center",
                              display: "flex",
                              flexDirection: "row",
                              gap: "5px",
                              justifyContent: "space-between",
                            }}
                          >
                            <Controller
                              control={control}
                              defaultValue={[]}
                              name={`projectCostExpenses.${index}.file`}
                              render={({ field }) => (
                                <TextField
                                  error={formState.errors.projectCostExpenses?.[index]?.file}
                                  fullWidth
                                  helperText={
                                    formState.errors.projectCostExpenses?.[index]?.file && tGeneral("fieldRequired")
                                  }
                                  onChange={(e) => {
                                    // @ts-ignore
                                    field.onChange(e.target.files);
                                  }}
                                  ref={field.ref}
                                  size="small"
                                  type="file"
                                />
                              )}
                            />
                            <Box
                              sx={{
                                whiteSpace: "nowrap",
                              }}
                            >
                              {file && file.name && file.url ? (
                                <a href={file.url} rel="noreferrer" target="_blank">
                                  {file.name.length > 10 ? `${file.name.slice(0, 7)}...` : file.name}
                                </a>
                              ) : null}
                            </Box>
                          </Box>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                  }}
                >
                  <ScienceIcon />
                  <strong>{tProjectSchedule("Roadblocks")}</strong>{" "}
                  {object.id && (
                    <AddBoxIcon
                      color="primary"
                      onClick={() => {
                        setRoadblockObject({
                          activity: object,
                          idActivity: object.id,
                          dateFormat,
                        });
                        setRoadblockOpen(true);
                      }}
                      sx={{
                        cursor: "pointer",
                      }}
                    />
                  )}
                </Box>
                <table className="project-schedule project-schedule-roadblocks">
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        <strong>{tProjectSchedule("Description")}</strong>
                      </td>
                      <td className="center">
                        <strong>{tProjectSchedule("Status")}</strong>
                      </td>
                      <td className="center">
                        <strong>{tProjectSchedule("Assigned")}</strong>
                      </td>
                      <td className="center">
                        <strong>{tProjectSchedule("Due Date")}</strong>
                      </td>
                    </tr>
                    {object.roadblocks?.map((roadblock) => {
                      return (
                        <tr key={roadblock.id}>
                          <td className="center">
                            <Chip color="success" label={roadblock.type.replace("_", " ").toUpperCase()} />
                          </td>
                          <td>
                            {roadblock.description.length > 53
                              ? `${roadblock.description.slice(0, 50)}...`
                              : roadblock.description}
                          </td>
                          <td className="center">
                            <Select
                              disableUnderline
                              onChange={async (event) => {
                                try {
                                  const response = await client.request({
                                    data: {
                                      idActivity: roadblock.idActivity,
                                      type: roadblock.type,
                                      description: roadblock.description,
                                      assignees: roadblock.assignees,
                                      date: roadblock.date,
                                      status: event.target.value,
                                    },
                                    method: "PUT",
                                    url: `/roadblocks/${roadblock.id}`,
                                  });
                                  setDataSource(response.data);
                                  const Toast = Swal.mixin({
                                    position: "center",
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                    toast: true,
                                  });
                                  Toast.fire({
                                    icon: "success",
                                    title: tProjectSchedule("Success"),
                                  });
                                } catch (error) {
                                  const Toast = Swal.mixin({
                                    position: "center",
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                    toast: true,
                                  });
                                  Toast.fire({
                                    icon: "error",
                                    title: tProjectSchedule(error.response.data.error ? error.response.data.error : error),
                                  });
                                  // eslint-disable-next-line no-console
                                  console.error(error);
                                }
                              }}
                              fullWidth
                              value={roadblock.status}
                              variant="standard"
                            >
                              <MenuItem key="open" value="open">
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Chip
                                    label={tProjectSchedule("OPEN")}
                                    sx={{
                                      backgroundColor: "#1A6100",
                                      color: "#ffffff",
                                    }}
                                  />
                                </Box>
                              </MenuItem>
                              <MenuItem key="reschedule" value="reschedule">
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Chip
                                    label={tProjectSchedule("RESCHEDULE")}
                                    sx={{
                                      backgroundColor: "#0073E6",
                                      color: "#ffffff",
                                    }}
                                  />
                                </Box>
                              </MenuItem>
                              <MenuItem key="close" value="close">
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Chip
                                    label={tProjectSchedule("CLOSE")}
                                    sx={{
                                      backgroundColor: "#cccccc",
                                      color: "#000000",
                                    }}
                                  />
                                </Box>
                              </MenuItem>
                            </Select>
                          </td>
                          <td className="center">
                            {roadblock.assignees.length > 0 && (
                              <Box
                                sx={{
                                  transform: "scale(0.75)",
                                }}
                              >
                                <AssignedUsersTooltip
                                  users={roadblock.assignees.map((assignee) => {
                                    return {
                                      bActive: true,
                                      id: assignee.id,
                                      name: assignee.name,
                                      urlAvatar: assignee.urlAvatar,
                                    };
                                  })}
                                />
                              </Box>
                            )}
                          </td>
                          <td className="center">
                            <Box
                              sx={{
                                alignItems: "center",
                                display: "flex",
                                gap: "5px",
                                justifyContent: "flex-center",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {renderDate(roadblock.date, dateFormat)}
                              <EditIcon
                                color="secondary"
                                onClick={() => {
                                  setRoadblockObject({
                                    ...roadblock,
                                    activity: object,
                                    dateFormat,
                                  });
                                  setRoadblockOpen(true);
                                }}
                                sx={{
                                  cursor: "pointer",
                                }}
                              />
                            </Box>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                  }}
                >
                  <DoNotDisturbOffIcon />
                  <strong>{tProjectSchedule("Dependencies")}</strong>
                </Box>
                <Select
                  defaultValue={object.idActivity === null ? "N/A" : object.idActivity}
                  onChange={async (event) => {
                    try {
                      const response = await client.request({
                        data: {
                          idGroup: object.idGroup,
                          idActivity: event.target.value === "N/A" ? null : event.target.value,
                          title: object.title,
                          description: object.description,
                          assignees,
                          progress: object.progress,
                          projectCostBudget: object.projectCostBudget,
                          // eslint-disable-next-line no-underscore-dangle
                          projectCostExpenses: object.projectCostExpenses_.map((item) => {
                            return {
                              ...item,
                              amount: parseFloat(item.amount.toString().replace(/[$,]/g, "")),
                            };
                          }),
                          scheduledTimesStart: object.scheduledTimesStart,
                          scheduledTimesEnd: object.scheduledTimesEnd,
                          realTimesStart: object.realTimesStart,
                          realTimesEnd: object.realTimesEnd,
                          color: color.name,
                          metadata: object.metadata,
                          relations: object.relations,
                        },
                        method: "PUT",
                        url: `/activities/${object.id}`,
                      });
                      setDataSource(response.data);
                      const Toast = Swal.mixin({
                        position: "center",
                        showConfirmButton: false,
                        timer: 1500,
                        timerProgressBar: true,
                        toast: true,
                      });
                      Toast.fire({
                        icon: "success",
                        title: tProjectSchedule("Success"),
                      });
                    } catch (error) {
                      const Toast = Swal.mixin({
                        position: "center",
                        showConfirmButton: false,
                        timer: 1500,
                        timerProgressBar: true,
                        toast: true,
                      });
                      Toast.fire({
                        icon: "error",
                        title: tProjectSchedule(error.response.data.error ? error.response.data.error : error),
                      });
                      // eslint-disable-next-line no-console
                      console.error(error);
                    }
                  }}
                  fullWidth
                  inputProps={{
                    sx: {
                      padding: "10px!important",
                    },
                  }}
                  variant="outlined"
                  disabled={object.idActivity === undefined}
                >
                  <MenuItem key="N/A" value="N/A">
                    N/A
                  </MenuItem>
                  {recursiveFlatMap(renderOptions(dataSource, 0), (item) => item).map((item) => {
                    return (
                      <MenuItem disabled={item.disabled} key={item.key} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <div>{tProjectSchedule("Predecessor")}</div>
                  {dependsOn.length === 0 && (
                    <Alert severity="info">
                      {tProjectSchedule("This activity does noes depend on any other activity.")}
                    </Alert>
                  )}
                  {dependsOn.map((activity) => {
                    const color = colors.find((color) => color.name === activity.color);
                    return (
                      <Box
                        key={activity.id}
                        sx={{
                          // @ts-ignore
                          backgroundColor: color.light,
                          // @ts-ignore
                          borderColor: color.dark,
                          borderRadius: "20px",
                          borderStyle: "solid",
                          borderWidth: "1px",
                          padding: "5px 20px",
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            // @ts-ignore
                            backgroundColor: color.dark,
                            borderBottomLeftRadius: "20px",
                            borderTopLeftRadius: "20px",
                            bottom: 0,
                            left: 0,
                            position: "absolute",
                            top: 0,
                            width: `${activity.progress}%`,
                            zIndex: 1,
                          }}
                        />
                        <Box
                          sx={{
                            position: "relative",
                            zIndex: 2,
                          }}
                        >
                          {activity.title}
                        </Box>
                      </Box>
                    );
                  })}
                  <div>{tProjectSchedule("Successor")}</div>
                  {dependentOn.length === 0 && (
                    <Alert severity="info">{tProjectSchedule("No other activity depends on this activity.")}</Alert>
                  )}
                  {dependentOn.map((activity) => {
                    const color = colors.find((color) => color.name === activity.color);
                    return (
                      <Box
                        key={activity.id}
                        sx={{
                          // @ts-ignore
                          backgroundColor: color.light,
                          // @ts-ignore
                          borderColor: color.dark,
                          borderRadius: "20px",
                          borderStyle: "solid",
                          borderWidth: "1px",
                          padding: "5px 20px",
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            // @ts-ignore
                            backgroundColor: color.dark,
                            borderBottomLeftRadius: "20px",
                            borderTopLeftRadius: "20px",
                            bottom: 0,
                            left: 0,
                            position: "absolute",
                            top: 0,
                            width: `${activity.progress}%`,
                            zIndex: 1,
                          }}
                        />
                        <Box
                          sx={{
                            position: "relative",
                            zIndex: 2,
                          }}
                        >
                          {activity.title}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Box
                sx={{
                  backgroundColor: "#cccccc",
                  height: "1px",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                  }}
                >
                  <QuestionAnswerIcon />
                  <strong>{tProjectSchedule("Comments")}</strong>
                </Box>
                <Box
                  sx={{
                    marginBottom: "-15px",
                  }}
                >
                  <Comments
                    activity={object}
                    client={client}
                    comment={undefined}
                    comments={comments}
                    dateFormat={dateFormat}
                    level={0}
                    readOnly={!object?.id}
                    setDataSource={setDataSource}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  backgroundColor: "#cccccc",
                  height: "1px",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <WorkHistoryIcon />
                    <strong>{tProjectSchedule("History")}</strong>
                  </Box>
                  {histories.length ? (
                    <Button onClick={toggleHistory}>
                        {showHistory ? tProjectSchedule("Hide") : tProjectSchedule("View")}
                    </Button>
                  ) : null}
                </Box>
                {showHistory && <Histories dateFormat={dateFormat} histories={histories} users={users} />}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "500px",
              }}
            >
              <Tabs
                onChange={(_, tab) => {
                  setTab(tab);
                }}
                scrollButtons="auto"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                  borderBottom: "1px solid #e1e1e1",
                }}
                value={tab}
                variant="scrollable"
              >
                {modules.map(({ icon, name, title }) => {
                  return (
                    <Tab
                      key={name}
                      label={
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            justifyContent: "space-between",
                          }}
                        >
                          {icon}
                          {title}
                        </Box>
                      }
                    />
                  );
                })}
              </Tabs>
              <Box
                sx={{
                  padding: "30px",
                }}
              >
                {modules.map(({ name, title }, key) => {
                  return (
                    <Box hidden={tab !== key} key={name}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          gap: "30px",
                        }}
                      >
                        <Button
                          color="primary"
                          onClick={() => {
                            if (name === "Daily Reports") {
                              setDailyReportsOpen(true);
                            }
                            if (name === "Documents") {
                              setDocumentsOpen(true);
                            }
                            if (name === "Drawings") {
                              setDrawingsOpen(true);
                            }
                            if (name === "Inspections") {
                              setInspectionsOpen(true);
                            }
                            if (name === "Meetings") {
                              setMeetingsOpen(true);
                            }
                            if (name === "RFIs") {
                              setRFIsOpen(true);
                            }
                            if (name === "Tasks") {
                              setTasksOpen(true);
                            }
                          }}
                          sx={{
                            padding: "10px 50px",
                          }}
                          variant="outlined"
                        >
                          {tProjectSchedule("Add New")} {title}
                        </Button>
                        {name === "Daily Reports" &&
                          dailyReports.data
                            // @ts-ignore
                            ?.filter((dailyReport) => dailyReportsList.includes(dailyReport.id))
                            .map((dailyReport) => {
                              return (
                                <Card
                                  key={dailyReport.id}
                                  sx={{
                                    borderRadius: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "20px",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {dailyReport.title}
                                    <Box
                                      sx={{
                                        borderRadius: "10px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        gap: "20px",
                                      }}
                                    >
                                      <Chip color="primary" label={tStatus(dailyReport.status).toUpperCase()} />
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <AssignedUsersTooltip users={dailyReport.assignedToUsers} />
                                    <Box
                                      sx={{
                                        alignItems: "center",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "10px",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      {renderDate(dailyReport.createdDate, dateFormat)}
                                      <RemoveRedEyeIcon
                                        onClick={() => {
                                          window.open(
                                            `${window.location.origin}/enterprise/${idEnterprise}/project/${idProject}/dailyReports/${dailyReport.id}`,
                                            "_blank",
                                          );
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      />
                                      <RemoveCircleOutlineIcon
                                        onClick={() => {
                                          setDailyReportsList(
                                            dailyReportsList.filter((item) => item !== dailyReport.id),
                                          );
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      />
                                    </Box>
                                  </Box>
                                </Card>
                              );
                            })}
                        {name === "Documents" &&
                          documents.data
                            // @ts-ignore
                            ?.filter((document) => documentsList.includes(document.id))
                            .map((document) => {
                              return (
                                <Card
                                  key={document.id}
                                  sx={{
                                    borderRadius: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "20px",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {document.name}
                                    <Box
                                      sx={{
                                        borderRadius: "10px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        gap: "20px",
                                      }}
                                    >
                                      <Chip color="success" label={`Size: ${document.size}MB`} />
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box flex={1} />
                                    <Box
                                      sx={{
                                        alignItems: "center",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "10px",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      {renderDate(document.createdDate, dateFormat)}
                                      <RemoveRedEyeIcon
                                        onClick={() => {
                                          window.open(
                                            `${window.location.origin}/enterprise/${idEnterprise}/project/${idProject}/documents`,
                                            "_blank",
                                          );
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      />
                                      <RemoveCircleOutlineIcon
                                        onClick={() => {
                                          setDocumentsList(documentsList.filter((item) => item !== document.id));
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      />
                                    </Box>
                                  </Box>
                                </Card>
                              );
                            })}
                        {name === "Drawings" &&
                          drawings.data
                            // @ts-ignore
                            ?.filter((drawing) => drawingsList.includes(drawing.id))
                            .map((drawing) => {
                              return (
                                <Card
                                  key={drawing.id}
                                  sx={{
                                    borderRadius: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "20px",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {drawing.name}
                                    <Box
                                      sx={{
                                        borderRadius: "10px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        gap: "20px",
                                      }}
                                    >
                                      <Chip color="success" label={drawing.idAux} />
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box flex={1} />
                                    <Box
                                      sx={{
                                        alignItems: "center",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "10px",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      {renderDate(drawing.createdDate, dateFormat)}
                                      <RemoveRedEyeIcon
                                        onClick={() => {
                                          window.open(
                                            `${window.location.origin}/enterprise/${idEnterprise}/project/${idProject}/drawings/${drawing.id}`,
                                            "_blank",
                                          );
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      />
                                      <RemoveCircleOutlineIcon
                                        onClick={() => {
                                          setDrawingsList(drawingsList.filter((item) => item !== drawing.id));
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      />
                                    </Box>
                                  </Box>
                                </Card>
                              );
                            })}
                        {name === "Inspections" &&
                          inspections.data
                            // @ts-ignore
                            ?.filter((inspection) => inspectionsList.includes(inspection.id))
                            .map((inspection) => {
                              return (
                                <Card
                                  key={inspection.id}
                                  sx={{
                                    borderRadius: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "20px",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {inspection.title}
                                    <Box
                                      sx={{
                                        borderRadius: "10px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        gap: "20px",
                                      }}
                                    >
                                      <Chip color="secondary" label={`#${inspection.number}.${inspection.revision}`} />
                                      <Chip color="primary" label={tStatus(inspection.status).toUpperCase()} />
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <AssignedUsersTooltip users={inspection.assignedToUsers} />
                                    <Box
                                      sx={{
                                        alignItems: "center",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "10px",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      {renderDate(inspection.createdDate, dateFormat)}
                                      <RemoveRedEyeIcon
                                        onClick={() => {
                                          window.open(
                                            `${window.location.origin}/enterprise/${idEnterprise}/project/${idProject}/inspections/${inspection.id}`,
                                            "_blank",
                                          );
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      />
                                      <RemoveCircleOutlineIcon
                                        onClick={() => {
                                          setInspectionsList(inspectionsList.filter((item) => item !== inspection.id));
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      />
                                    </Box>
                                  </Box>
                                </Card>
                              );
                            })}
                        {name === "Meetings" &&
                          meetings.data
                            // @ts-ignore
                            ?.filter((meeting) => meetingsList.includes(meeting.id))
                            .map((meeting) => {
                              return (
                                <Card
                                  key={meeting.id}
                                  sx={{
                                    borderRadius: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "20px",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {meeting.title}
                                    <Box
                                      sx={{
                                        borderRadius: "10px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        gap: "20px",
                                      }}
                                    >
                                      <Chip color="primary" label={tStatus(meeting.status).toUpperCase()} />
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <AssignedUsersTooltip users={meeting.attendenceList} />
                                    <Box
                                      sx={{
                                        alignItems: "center",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "10px",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      {renderDate(meeting.createdDate, dateFormat)}
                                      <RemoveRedEyeIcon
                                        onClick={() => {
                                          window.open(
                                            `${window.location.origin}/enterprise/${idEnterprise}/project/${idProject}/meetings/${meeting.id}`,
                                            "_blank",
                                          );
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      />
                                      <RemoveCircleOutlineIcon
                                        onClick={() => {
                                          setMeetingsList(meetingsList.filter((item) => item !== meeting.id));
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      />
                                    </Box>
                                  </Box>
                                </Card>
                              );
                            })}
                        {name === "RFIs" &&
                          rfis.data
                            // @ts-ignore
                            ?.filter((rfi) => rfisList.includes(rfi.id))
                            .map((rfi) => {
                              return (
                                <Card
                                  key={rfi.id}
                                  sx={{
                                    borderRadius: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "20px",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {rfi.title}
                                    <Box
                                      sx={{
                                        borderRadius: "10px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        gap: "20px",
                                      }}
                                    >
                                      <Chip color="info" label={`#${rfi.number}`} />
                                      <Chip color="success" label={tStatus(rfi.status).toUpperCase()} />
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <AssignedUsersTooltip users={rfi.assignedTo} />
                                    <Box
                                      sx={{
                                        alignItems: "center",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "10px",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      {renderDate(rfi.createdDate, dateFormat)}
                                      <RemoveRedEyeIcon
                                        onClick={() => {
                                          window.open(
                                            `${window.location.origin}/enterprise/${idEnterprise}/project/${idProject}/rfis/${rfi.id}`,
                                            "_blank",
                                          );
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      />
                                      <RemoveCircleOutlineIcon
                                        onClick={() => {
                                          setRFIsList(rfisList.filter((item) => item !== rfi.id));
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      />
                                    </Box>
                                  </Box>
                                </Card>
                              );
                            })}
                        {name === "Tasks" &&
                          tasks.data
                            // @ts-ignore
                            ?.filter((task) => tasksList.includes(task.id))
                            .map((task) => {
                              return (
                                <Card
                                  key={task.id}
                                  sx={{
                                    borderRadius: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "20px",
                                    gap: "10px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {task.title}
                                    <Box
                                      sx={{
                                        borderRadius: "10px",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        gap: "20px",
                                      }}
                                    >
                                      <Chip color="secondary" label={`#${task.taskNumber}`} />
                                      <Chip color="primary" label={tStatus(task.status).toUpperCase()} />
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <AssignedUsersTooltip users={task.assignedToUsers} />
                                    <Box
                                      sx={{
                                        alignItems: "center",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "10px",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      {renderDate(task.createdDate, dateFormat)}
                                      <RemoveRedEyeIcon
                                        onClick={() => {
                                          window.open(
                                            `${window.location.origin}/enterprise/${idEnterprise}/project/${idProject}/tasks/${task.id}`,
                                            "_blank",
                                          );
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      />
                                      <RemoveCircleOutlineIcon
                                        onClick={() => {
                                          setTasksList(tasksList.filter((item) => item !== task.id));
                                        }}
                                        sx={{ cursor: "pointer" }}
                                      />
                                    </Box>
                                  </Box>
                                </Card>
                              );
                            })}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              <DailyReports
                options={dailyReports.data}
                open={dailyReportsOpen}
                setOpen={setDailyReportsOpen}
                dailyReportsList={dailyReportsList}
                setDailyReportsList={setDailyReportsList}
              />
              <Documents
                options={documents.data}
                open={documentsOpen}
                setOpen={setDocumentsOpen}
                documentsList={documentsList}
                setDocumentsList={setDocumentsList}
              />
              <Drawings
                options={drawings.data}
                open={drawingsOpen}
                setOpen={setDrawingsOpen}
                drawingsList={drawingsList}
                setDrawingsList={setDrawingsList}
              />
              <Inspections
                options={inspections.data}
                open={inspectionsOpen}
                setOpen={setInspectionsOpen}
                inspectionsList={inspectionsList}
                setInspectionsList={setInspectionsList}
              />
              <Meetings
                options={meetings.data}
                open={meetingsOpen}
                setOpen={setMeetingsOpen}
                meetingsList={meetingsList}
                setMeetingsList={setMeetingsList}
              />
              <RFIs
                options={rfis.data}
                open={rfisOpen}
                setOpen={setRFIsOpen}
                rfisList={rfisList}
                setRFIsList={setRFIsList}
              />
              <Tasks
                options={tasks.data}
                open={tasksOpen}
                setOpen={setTasksOpen}
                tasksList={tasksList}
                setTasksList={setTasksList}
              />
            </Box>
          </DialogContent>
          <DialogActions
            disableSpacing
            sx={{
              gap: "30px",
              padding: "30px",
            }}
          >
            {loading && <CircularProgress size={20} />}
            <Button color="secondary" onClick={handleClose} variant="outlined">
              {tProjectSchedule("Cancel")}
            </Button>
            <Button color="primary" type="submit" variant="contained">
              {tGeneral("save")}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}
