import React, { useState } from "react";
import { useParams } from "react-router";
// Redux: selectors
// Redux reducers & mutations
// MUI Components
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tab,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

// Redux: queries
import { FacturaSettings } from "../../components/enterpriseDashboard/Settings/FacturaSettings";
import { InformationSettings } from "../../components/enterpriseDashboard/Settings/InformationSettings";
import { PaymentTable } from "../../components/enterpriseDashboard/Settings/PaymentTable";
import { PlanSettings } from "../../components/enterpriseDashboard/Settings/PlanSettings";
import { useGetEnterpriseByIdQuery } from "../../features/enterprise/enterpriseApiSlice";

function EnterpriseSettings() {
  const { t: tGeneral } = useTranslation("general");

  // TABLIST ONCHANGE
  const [value, setValue] = React.useState("informacionGeneral");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = useState(false);

  const resetVars = () => {
    setOpen(false);
  };

  const { idEnterprise } = useParams();

  const { data: enterpriseData } = idEnterprise
    ? useGetEnterpriseByIdQuery(idEnterprise)
    : {};
  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <Typography variant="h5" align="left" fontWeight="bold">
          {tGeneral("settings")}
        </Typography>
        <TabContext value={value}>
          {/* TABS */}
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange}>
              <Tab
                label={tGeneral("generalInfo")}
                value="informacionGeneral"
                sx={{
                  fontWeight: value === "informacionGeneral" ? "bold" : "none",
                  textTransform: "none",
                  color: "black",
                  minWidth: "266px",
                  "&.Mui-selected": {
                    color: "black",
                  },
                }}
              />
              {/* <Tab
                label={tGeneral("changePlan")}
                value="changePlan"
                sx={{
                  fontWeight: value === "changePlan" ? "bold" : "none",
                  textTransform: "none",
                  color: "black",
                  minWidth: "266px",
                  "&.Mui-selected": {
                    color: "black",
                  },
                }}
              /> */}
              {/* <Tab
                label={tGeneral("billing")}
                value="facturacion"
                sx={{
                  fontWeight: value === "facturacion" ? "bold" : "none",
                  textTransform: "none",
                  color: "black",
                  minWidth: "266px",
                  "&.Mui-selected": {
                    color: "black",
                  },
                }}
              /> */}
              {/* <Tab
                label={tGeneral("paymentMethod")}
                value="metodoPago"
                sx={{
                  fontWeight: value === "metodoPago" ? "bold" : "none",
                  textTransform: "none",
                  color: "black",
                  minWidth: "266px",
                  "&.Mui-selected": {
                    color: "black",
                  },
                }}
              /> */}
            </TabList>
          </Box>

          {/* tab 1 */}
          <TabPanel value="informacionGeneral">
            <InformationSettings enterprise={enterpriseData} />
          </TabPanel>

          {/* tab 2  */}
          <TabPanel value="changePlan">
            <PlanSettings enterprise={enterpriseData} />
          </TabPanel>

          {/* Specifications */}
          <TabPanel value="facturacion">
            <FacturaSettings />
          </TabPanel>

          {/* Tab 4 */}
          <TabPanel value="metodoPago">
            <PaymentTable />
          </TabPanel>
        </TabContext>
      </Box>
      <Dialog
        open={open}
        onClose={(e) => resetVars()}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          sx={{
            backgroundColor: "#ffc900",
            color: "white",
          }}
          id="scroll-dialog-title"
        >
          {tGeneral("updateProfile")}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            {tGeneral("youSureEnterprise")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => resetVars()}>Cancel</Button>
          <Button onClick={(e) => handleSubmit(e)}>Update</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EnterpriseSettings;
