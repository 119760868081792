const inspectionsTemplates = {
  add: "Adicionar Modelo",
  edit: "Editar Modelo",
  guideMe: "Guie-me",
  inspection_template_recycle_bin: "Lixeira (Modelos de Inspeção)",
  list: "Lista de Inspeções",
  meeting: "Reunião",
  no_inspections_templates_text:
    "Se você quiser adicionar um, clique no botão Adicionar Modelo de Inspeção para iniciar o processo",
  no_inspections_templates_title:
    "Não há modelos de inspeção criados no momento",
  title: "Inspeções - Modelos",
  workflow: "Fluxo de trabalho",
  add_button_tour: "Criar um novo modelo de inspeção",
};

export { inspectionsTemplates };
