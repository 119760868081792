import { apiSlice } from "../../api/apiSlice";

export const projectSettingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrder: builder.mutation({
      query: (body) => ({
        url: "/project/settings/getOrder",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProjectSettings"],
    }),
    getVisibles: builder.mutation({
      query: (body) => ({
        url: "/project/settings/getVisibles",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProjectSettings"],
    }),
    getVisibleModules: builder.query({
      query: ({ idProject, idUser }) => ({
        url: `/project/settings/getVisibles?idProject=${idProject}&idUser=${idUser}`,
        method: "GET",
      }),
      providesTags: ["VisibleModules"],
    }),
    updateOrder: builder.mutation({
      query: (body) => ({
        url: "/project/settings/postOrder",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProjectSettings"],
    }),
    updateVisibles: builder.mutation({
      query: (body) => ({
        url: "/project/settings/postVisibles",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProjectSettings", "VisibleModules"],
    }),
    getNotificationConfig: builder.query({
      query: (idProject) => `/project/notifications/getConfig/${idProject}`,
      keepUnusedDataFor: 60,
      providesTags: ["NotificationsProject"],
    }),
    updateNotificationConfig: builder.mutation({
      query: (body) => ({
        url: "/project/notifications/updateConfig",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["NotificationsProject"],
    }),
  }),
});

export const {
  useGetOrderMutation,
  useUpdateOrderMutation,
  useGetVisiblesMutation,
  useUpdateVisiblesMutation,
  useGetVisibleModulesQuery,
  useUpdateNotificationConfigMutation,
  useGetNotificationConfigQuery,
} = projectSettingsApiSlice;
