import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PopUpAlert } from "../../PopUpAlert";
import {
  useAddSpecificationMutation,
  useUpdateSpecificationMutation,
} from "../../../features/project/specificationApiSlice";
import { useParams } from "react-router";

function SpecificationModal({ isOpen, onClose, selectedSpecification = null }) {
  const { idProject } = useParams();

  const { t: tGeneral } = useTranslation("general");

  const [number, setNumber] = useState("");
  const [numberError, setNumberError] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);

  const [newSpecification] = useAddSpecificationMutation();
  const [updateSpecification] = useUpdateSpecificationMutation();

  const styles = {
    dialogTitle: {
      textAlign: "left",
      height: "60px",
      fontWeight: "bold",
    },
    iconButtonClose: {
      position: "absolute",
      right: 8,
      top: 8,
    },
    textField: {
      mx: "auto",
      width: "100%",
      mb: 1,
    },
    cancelButton: {
      width: "50%",
      color: "black",
      backgroundColor: "white",
      borderColor: "black",
      border: 1,
      ":hover": {
        color: "white",
        backgroundColor: "#b4b4b4",
      },
    },
    saveButton: {
      width: "50%",
      color: "black",
      backgroundColor: "#ffc900",
      ":hover": {
        color: "black",
        backgroundColor: "#d9ab00",
      },
    },
  };

  useEffect(() => {
    if (selectedSpecification) {
      setName(selectedSpecification.name);
      setNumber(selectedSpecification.code);
    } else {
      setName("");
      setNumber("");
    }
  }, [selectedSpecification, isOpen]);

  function validateFields(val, field) {
    switch (field) {
      case "number":
        setNumber(val);
        const numberValidation = val.length > 0 ? 1 : -1;
        numberValidation === 1 ? setNumberError(false) : setNumberError(true);
        break;
      case "name":
        setName(val);
        const nameValidation = val.length > 0 ? 1 : -1;
        nameValidation === 1 ? setNameError(false) : setNameError(true);
        break;
      default:
    }
  }

  function resetVars() {
    onClose();
    setNumber("");
    setNumberError(false);
    setName("");
    setNameError(false);
  }

  const onSubmit = async (e) => {
    let body = {
      idProject: idProject,
      code: number,
      name: name,
    };
    try {
      let response;
      if (selectedSpecification) {
        body.idSpecification = selectedSpecification.id;
        response = await updateSpecification(body).unwrap();
      } else {
        response = await newSpecification(body).unwrap();
      }

      if (response) {
        resetVars();
        PopUpAlert(
          "success",
          tGeneral("success"),
          selectedSpecification
            ? tGeneral("specificationUpdated")
            : tGeneral("specificationAdded"),
        );
      }
    } catch (err) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={(e) => resetVars()}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{ mx: "auto" }}
      PaperProps={{ sx: { borderRadius: "10px" } }}
    >
      <DialogTitle
        id="scroll-dialog-title"
        sx={[
          styles.dialogTitle,
          { padding: "16px 40px" },
          { marginBottom: "10px" },
        ]}
      >
        {selectedSpecification
          ? tGeneral("editSpecification")
          : tGeneral("addSpecification")}
        <IconButton
          aria-label="close"
          onClick={(e) => resetVars()}
          sx={[styles.iconButtonClose, { marginRight: "20px" }]}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={[{ mx: "auto" }, { padding: "0px 40px 10px 40px" }]}>
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={-1}
          sx={{ mx: "auto" }}
        >
          <TextField
            id="defaultSpecNumTextField"
            variant="outlined"
            error={numberError}
            type="number"
            label="Num"
            required
            value={number}
            onChange={(e) => {
              validateFields(e.target.value, "number");
            }}
            sx={[styles.textField, { marginY: "20px" }]}
          />
          <TextField
            id="defaultNameTextField"
            variant="outlined"
            error={nameError}
            type="text"
            label={tGeneral("name")}
            required
            value={name}
            onChange={(e) => {
              validateFields(e.target.value, "name");
            }}
            sx={styles.textField}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={[
          { marginBottom: "10px" },
          { marginRight: "30px" },
          { marginLeft: "30px" },
        ]}
      >
        <Button
          sx={styles.cancelButton}
          type="submit"
          variant="contained"
          size="large"
          onClick={(e) => resetVars()}
        >
          {tGeneral("cancel")}
        </Button>
        <Button
          sx={styles.saveButton}
          type="submit"
          variant="contained"
          size="large"
          disabled={
            numberError || nameError || number.length === 0 || name.length === 0
              ? true
              : false
          }
          onClick={onSubmit}
        >
          {selectedSpecification ? tGeneral("save") : tGeneral("add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { SpecificationModal };
