import {
  BookRounded,
  CachedRounded,
  Close,
  FlagRounded,
  FolderRounded,
  GroupsRounded,
  InsertPhotoRounded,
  Search,
  StickyNote2Rounded,
} from "@mui/icons-material";

import NoteRoundedIcon from "@mui/icons-material/NoteRounded"; // Drawings

import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  InputAdornment,
  Modal,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectLocale } from "../features/preferences/preferencesSlice";
import { useGetModuleSearchByTextMutation } from "../features/project/projectApiSlice";
import { PopUpAlert } from "./PopUpAlert";

function ModalSearchProjectOverview(props) {
  const navigate = useNavigate();

  const { isOpen, onClose, idProject } = props;
  const currentLocale = useSelector(selectLocale);
  const { t: tGeneral } = useTranslation("general");

  const [filteredElements, setFilteredElements] = useState([]);

  const [searchQuery, setSearchQuery] = useState(null);
  const [isFilterElementsButtonActive, setIsFilterElementsButtonActive] =
    useState(false);

  const [moduleSearch, { isLoading }] = useGetModuleSearchByTextMutation();

  const handleSearchModule = async () => {
    try {
      const searchElements = await moduleSearch({
        idProject,
        text: searchQuery,
      }).unwrap();

      setFilteredElements(searchElements?.data ?? []);
    } catch (err) {
      setFilteredElements([]);
      PopUpAlert("error", tGeneral("error"), tGeneral("generalErrorMessage"));
    }
  };

  const handleClose = () => {
    setSearchQuery("");
    setFilteredElements([]);
    setIsFilterElementsButtonActive(false);
    onClose();
  };

  useEffect(() => {
    setIsFilterElementsButtonActive(
      !isLoading && searchQuery && searchQuery !== "",
    );
  }, [searchQuery, isLoading]);

  const onChangeSearchQuery = (event) => {
    setSearchQuery(event.target.value);
  };

  const renderFilterableElements = () => {
    const moduleIcons = {
      rfi: <CachedRounded />,
      photos: <InsertPhotoRounded />,
      tasks: <StickyNote2Rounded />,
      meetings: <GroupsRounded />,
      drawings: <NoteRoundedIcon />,
      documents: <FolderRounded />,
      directories: <BookRounded />,
      dailyReports: <FlagRounded />,
    };

    const moduleLabels = {
      rfi: tGeneral("rfi"),
      photos: "",
      tasks: tGeneral("task"),
      meetings: tGeneral("meeting"),
      drawings: tGeneral("drawing"),
      documents: "",
      directories: "",
      dailyReports: tGeneral("dailyReport"),
    };

    if (isLoading) {
      return [...Array(10)].map((_val, index) => (
        <Box
          display="flex"
          width="100%"
          height="67px"
          columnGap="10px"
          px="12px"
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          <Box display="flex" alignItems="center">
            <Skeleton height="25px" width="25px" variant="rectangular" />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
          >
            <Skeleton width="100%" />
            <Skeleton width="70%" />
          </Box>
          <Box display="flex" alignItems="flex-end">
            <Skeleton width="100px" />
          </Box>
        </Box>
      ));
    }

    if (filteredElements.length > 0) {
      const baseQuery = `./project/${idProject}`;

      return filteredElements.map(
        ({ id, title, createDate, moduleName, number }, index) => (
          <Box
            sx={{
              cursor: "pointer",
              ":hover": {
                backgroundColor: "#FEF9EE",
              },
              borderBottom:
                filteredElements.length > index ? "1px solid #F8F8F8" : "none",
            }}
            display="flex"
            height="60px"
            columnGap="12px"
            p="12px 16px"
            key={id}
            component="div"
            alignItems="center"
            onClick={() => {
              handleClose();
              setSearchQuery("");
              navigate(`./${baseQuery}/${moduleName}/${id}`);
            }}
          >
            {moduleIcons[moduleName]}
            <Box width="100%">
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "calc(100vw/5)",
                }}
              >
                {title}
              </Typography>
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{ color: "#CFCFCF" }}
              >
                <Typography fontSize="12px">
                  {moment(new Date(createDate))
                    .locale(currentLocale)
                    .format("DD MMMM YYYY")}
                </Typography>
                <Typography fontSize="12px">
                  {moduleLabels[moduleName]} {number && `#${number}`}
                </Typography>
              </Box>
            </Box>
          </Box>
        ),
      );
    }

    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="100%"
      >
        <Typography color="#9F9F9F" textAlign="center">
          {tGeneral("noFilterableElements")}
        </Typography>
      </Box>
    );
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ justifyContent: "center", display: "flex", alignItems: "center" }}
    >
      <Card
        sx={{
          width: "35vw",
          height: "45vh",
          maxHeight: "70vh",
          p: "30px 40px",
          display: "flex",
          flexDirection: "column",
          borderRadius: "20px",
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography fontSize="1.5625rem" fontWeight="bold">
            {tGeneral("projectSearch")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Box display="flex" columnGap="16px" mt="10px">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flex: 2.5,
            }}
          >
            <TextField
              margin="dense"
              variant="outlined"
              type="text"
              label={tGeneral("search")}
              value={searchQuery ?? ""}
              disabled={isLoading}
              sx={{ width: "100%" }}
              onChange={onChangeSearchQuery}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearchModule();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flex: 1.25,
            }}
          >
            <Button
              sx={{ height: "55px", mt: "3px" }}
              fullWidth
              variant="contained"
              disabled={isLoading || !isFilterElementsButtonActive}
              onClick={handleSearchModule}
            >
              {isLoading ? (
                <CircularProgress color="secondary" size="24px" />
              ) : (
                tGeneral("search")
              )}
            </Button>
          </Box>
        </Box>

        <Card
          sx={{
            height: "32vh",
            marginTop: "10px",
            overflowY: "auto",
          }}
        >
          {renderFilterableElements()}
        </Card>
      </Card>
    </Modal>
  );
}

export default ModalSearchProjectOverview;
