export const projectNetwork = {
  addProjectNetwork: "Add to project network",
  acceptInvite: "Accept Invite",
  yourNewCodeIs: "Your new code is",
  generateNewCode: "Generate new code",
  deleteNetworkMessage:
    "Are you sure you want to delete this project of your network? All shared items won't be accessible anymore.",
  emptyNetworkTitle: "Empty Network",
  emptyNetworkContent: "You haven't added projects to your network yet.",
  accepted: "Accepted",
  acceptedSuccessfully: "Invite accepted successfully.",
  codeCopied: "Code copied",
  availableCodes: "Available codes",
};
