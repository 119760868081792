import { Box, Divider, Skeleton } from "@mui/material";

function TasksSkeletonLoading() {
  return (
    <Box p="0.5rem">
      <Box display="flex" alignItems="center" columnGap="0.5rem">
        <Skeleton variant="text" width="150px" height="60px" />
        <Box flex={1} />
        <Skeleton variant="circular" height="40px" width="40px" />
        <Skeleton variant="circular" height="40px" width="40px" />
      </Box>
      <Divider sx={{ borderColor: "FBCB04", mb: "0.5rem" }} />
      <Skeleton variant="rounded" width="200px" height="40px" />
      <Box display="flex" columnGap="0.5rem">
        {[...Array(3)].map((v, i) => (
          <Box key={i} flex={1}>
            <Box display="flex" alignItems="center" columnGap="0.5rem">
              <Skeleton variant="rectangular" width="25px" height="25px" />
              <Skeleton variant="text" width="120px" height="60px" />
              <Box flex={1} />
              <Skeleton variant="text" width="50px" height="60px" />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              rowGap="0.5rem"
            >
              {[...Array(4)].map((_v, _i) => (
                <Skeleton
                  variant="rounded"
                  width="500px"
                  height="170px"
                  key={_i}
                />
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export { TasksSkeletonLoading };
