import { apiSlice } from "../../api/apiSlice";

export const stripeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: () => "stripe/products",
    }),
  }),
});

export const { useGetProductsQuery } = stripeApiSlice;
