import { tour } from "./tour";

export const meetings = {
  // general tour
  ...tour,
  moduleElement: "会议",
  // ADD - guide me steps
  step1_addMeeting_title: "1. 添加会议。",
  step1_addMeeting_text: "点击加号图标添加新会议。",
  step2_addMeeting_title: "2. 标题",
  step2_addMeeting_text: "为会议编写标题。",
  step3_addMeeting_title: "3. 隐私",
  step3_addMeeting_text: "选择会议对所有人是否可见。",
  step3_addMeeting_text_2: "任何有权限访问模块的人都能看到您的会议。",
  step3_addMeeting_text_3: "只有参与者才能看到此会议。",
  step4_addMeeting_title: "4. 会议日期",
  step4_addMeeting_text: "设置会议的日期和时间。",
  step5_addMeeting_title: "5. 地点",
  step5_addMeeting_text: "确定会议的地点。",
  step6_addMeeting_title: "6. 会议 URL",
  step6_addMeeting_text: "在此处输入会议的 URL。",
  step7_addMeeting_title: "7. 状态",
  step7_addMeeting_text_1_1: "选择状态",
  step7_addMeeting_text_1_2: "公开对参与人员开放。",
  step7_addMeeting_text_2_1: "选择状态",
  step7_addMeeting_text_2_2: "保存为草稿。",
  step8_addMeeting_title: "8. 参与者",
  step8_addMeeting_text: "选择参加会议的人员。",
  step9_addMeeting_title: "9. 分发列表",
  step9_addMeeting_text: "选择会议通知的人员。",
  step10_addMeeting_title: "10. 新项目",
  step10_addMeeting_text: "向会议添加新项目。",
  step11_addMeeting_title: "11. 项目名称",
  step11_addMeeting_text: "填写项目的名称。",
  step12_addMeeting_title: "12. 讨论主题",
  step12_addMeeting_text: "在会议项目中写下要讨论的主题。",
  step13_addMeeting_title: "13. 时间",
  step13_addMeeting_text: "确定项目的预计时间。",
  step14_addMeeting_title: "14. 文件",
  step14_addMeeting_text: "上传与会议相关的文件。",
  step15_addMeeting_title: "15. 添加",
  step15_addMeeting_text: "点击确认新项目",
  step16_addMeeting_title: "16. 创建",
  step16_addMeeting_text: "点击此处创建会议。",
  step_final_addMeeting: "您已学会如何创建会议。",
  // VIEW - guide me steps
  step1_viewMeeting_title: "1. 查看会议。",
  step1_viewMeeting_text: "点击打开会议。",
  step2_viewMeeting_title: "2. 信息",
  step2_viewMeeting_text: "在此部分，您可以查看会议摘要的主要信息。",
  step3_viewMeeting_title: "3. URL",
  step3_viewMeeting_text:
    "点击此处打开 URL，或点击右侧的图标将其复制到剪贴板。",
  step4_viewMeeting_title: "4. 议程",
  step4_viewMeeting_text: "此部分包含议程的信息。",
  step5_viewMeeting_title: "5. 项目",
  step5_viewMeeting_text: "点击项目以查看其内容。",
  step6_viewMeeting_title: "6. 主题",
  step6_viewMeeting_text: "点击主题以查看其内容。",
  step7_viewMeeting_title: "7. 评论",
  step7_viewMeeting_text: "在此部分，您可以添加有关主题的评论。",
  step8_viewMeeting_title: "8. 任务",
  step8_viewMeeting_text: "在此处显示在主题下创建的任务。",
  step_final_viewMeeting: "您已学会如何查看会议信息。",
  // EDIT - guide me steps
  step1_editMeeting_title: "1. 编辑会议。",
  step1_editMeeting_text: "点击此处编辑会议。",
  step2_editMeeting_title: "2. 标题",
  step2_editMeeting_text: "编辑会议的标题",
  step3_editMeeting_title: "3. 隐私",
  step3_editMeeting_text: "编辑会议的隐私。",
  step3_editMeeting_text_2: "任何有权限访问模块的人都能看到您的会议。",
  step3_editMeeting_text_3: "只有参与者才能看到此会议。",
  step4_editMeeting_title: "4. 会议日期",
  step4_editMeeting_text: "编辑会议的日期和时间。",
  step5_editMeeting_title: "5. 地点",
  step5_editMeeting_text: "编辑会议的地点。",
  step6_editMeeting_title: "6. 会议 URL",
  step6_editMeeting_text: "编辑会议的 URL。",
  step7_editMeeting_title: "7. 会议状态",
  step7_editMeeting_text_1_1: "选择状态",
  step7_editMeeting_text_1_2: "公开对参与人员开放。",
  step7_editMeeting_text_2_1: "选择状态",
  step7_editMeeting_text_2_2: "保存为草稿。",
  step8_editMeeting_title: "8. 参与者",
  step8_editMeeting_text: "添加或删除参加会议的人员。",
  step9_editMeeting_title: "9. 分发列表",
  step9_editMeeting_text: "编辑会议通知的人员。",
  step10_editMeeting_title: "10. 项目名称",
  step10_editMeeting_text: "编辑项目的名称。",
  step11_editMeeting_title: "11. 讨论主题",
  step11_editMeeting_text: "编辑项目的讨论主题。",
  step12_editMeeting_title: "12. 预计时间",
  step12_editMeeting_text: "编辑项目的预计时间。",
  step13_editMeeting_title: "13. 文件",
  step13_editMeeting_text: "编辑或添加与会议相关的文件。",
  step14_editMeeting_title: "14. 新项目",
  step14_editMeeting_text: "向会议添加新项目。",
  step15_editMeeting_title: "15. 保存",
  step15_editMeeting_text: "点击此处保存会议的更改",
  step_final_editMeeting: "您已学会如何编辑会议。",
  // RECYCLE - guide me steps
  step1_recycleMeeting_title: "2. 会议回收站",
  step1_recycleMeeting_text: "点击此处打开会议回收站。",
  step2_recycleMeeting_title: "3. 回收站菜单",
  step2_recycleMeeting_text: "这是会议回收站菜单。",
  step3_recycleMeeting_title: "4. 查看会议。",
  step3_recycleMeeting_text: "点击此处查看要恢复的会议的信息。",
  step4_recycleMeeting_title: "5. 选择会议。",
  step4_recycleMeeting_text: "选择要恢复的会议。",
  step5_recycleMeeting_title: "6. 恢复选定的会议。",
  step5_recycleMeeting_text: "点击此处恢复选定的会议。",
  step_final_recycleMeeting: "您已学会如何与会议回收站交互。",
  // SETTINGS - guide me steps
  step1_settingsMeeting_title: "2. 打开设置菜单",
  step1_settingsMeeting_text: "点击此处打开设置菜单。",
  step2_settingsMeeting_title: "3. 分发列表",
  step2_settingsMeeting_text: "添加或删除默认访问会议模块的人员。",
  step3_settingsMeeting_title: "4. 保存",
  step3_settingsMeeting_text: "点击此处保存设置。",
  step_final_settingsMeeting: "您已学会如何调整设置。",
  // EXPORT - guide me steps
  step1_exportMeeting_title: "1. 导出会议",
  step1_exportMeeting_text: "点击此处通过电子邮件导出会议信息。",
  step_final_exportMeeting: "您已学会如何导出会议。",

  meetings: "会议",
  addMeeting: "安排会议",
  deleteMeeting: "删除会议",
  messageDelete: "您确定要删除所选的会议吗？执行此操作后无法撤消。",
  meetingDeleted: "会议删除成功",
  errorMeetingDeleted: "无法删除会议",
  meetingRecovered: "会议恢复成功",
  errorMeetingRecovered: "无法恢复会议",
  meetingsRecycleBin: "会议回收站",
  meetingCreated: "会议创建成功",
  errorMeetingCreated: "无法创建会议",
  meetingUpdated: "会议更新成功",
  errorMeetingUpdated: "无法更新会议",
  editMeeting: "编辑会议",
  urlMeeting: "会议 URL",
  agenda: "议程",
  meetingStarted: "会议成功开始",
  errorMeetingStarted: "无法开始当前会议",
  meetingClosed: "会议成功关闭",
  errorMeetingClosed: "无法关闭会议",
  startMeeting: "开始会议",
  noAgendaItems: "没有议程项目",
  closeMeeting: "关闭会议",
  noElementsTitle: "是时候开始了！",
  noElementsSubtitle:
    "为什么不创建您的第一个会议来计划下一项任务？点击下面的按钮开始。",
  newAgendaItem: "新议程项目",
  topicsToDiscuss: "讨论主题",
  attachOrDrop: "附加文件或拖放到此处",
  viewFiles: "查看文件",
  noTopics: "没有主题",
  taskSuccessfullyDeleted: "任务删除成功",
  taskCantDelete: "任务无法删除",
  taskName: "任务名称",
  newTask: "新任务",
  deleteTask: "删除任务",
  deleteTaskQuestion: "您确定要删除此任务吗？",
  noMeetings: "回收站中没有会议",
  confirmedAssistance: "确认出席",
};
