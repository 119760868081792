import { Menu } from "@mui/material";

const SupportMenu = ({ children, anchor, open, onClose }) => {
  return (
    <Menu
      id="basic-menu"
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      anchorEl={anchor}
      open={open}
      onClose={onClose}
      keepMounted
      sx={{ borderRadius: 105 }}
    >
      {children}
    </Menu>
  );
};

export { SupportMenu };
