import { Refresh } from "@mui/icons-material";
import { Box, Button, Chip, CircularProgress, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import DirectoryTable from "../../../components/DirectoryTable";
import { useGetEnterpriseByIdQuery, useGetUsersFromEnterpriseQuery } from "../../../features/enterprise/enterpriseApiSlice";

import { TitleWidgets } from "../../../components/shared";
import { ModalCreateEnterpriseUser } from "./ModalCreateEnterpriseUser";

function Directory() {
  const { idEnterprise } = useParams();
  // Translations
  const { t: tGeneral } = useTranslation("general");
  const [search, setSearch] = useState("");
  const [isAddOpen, setIsAddOpen] = useState(false);

  const {
    data: enterpriseUsersData,
    isLoading,
    isError,
    refetch,
  } = useGetUsersFromEnterpriseQuery(
    { idEnterprise, search },
    { skip: !idEnterprise },
  );

  const currentEnterprise = useGetEnterpriseByIdQuery(idEnterprise, {
    skip: !idEnterprise,
  });

  const enterpriseUsers = useMemo(
    () => enterpriseUsersData ?? [],
    [enterpriseUsersData],
  );

  return (
    <Box sx={{ height: 500, width: "100%" }}>
      <TitleWidgets
        title={tGeneral("users")}
        titleAdornment={
          currentEnterprise?.data?.planType === "user" ? (
            <Box
              sx={{
                display: "flex",
                marginLeft: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Chip
                size="small"
                label={`${currentEnterprise?.data?.activeUsers} / ${currentEnterprise?.data?.usersLimit}`}
                sx={{
                  fontWeight: "500",
                  backgroundColor: "#EBEBEB",
                  fontSize: 13,
                  color: "#3f3f3f",
                }}
              />
            </Box>
          ) : ([])
        }
        showMoreOptions={false}
        enableSupport
        addFunction={() => setIsAddOpen(true)}
        moduleName="user"
      />

      {isLoading && (
        <Box
          width={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          py={8}
        >
          <CircularProgress color="primary" />
        </Box>
      )}
      {isError && (
        <Box
          width={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          py={8}
        >
          <Typography fontSize={20} fontWeight="bold" textAlign="center" mb={4}>
            {tGeneral("generalErrorMessage")}
          </Typography>
          <Button
            variant="contained"
            startIcon={<Refresh />}
            onClick={() => refetch()}
          >
            {tGeneral("retry")}
          </Button>
        </Box>
      )}

      <DirectoryTable
        data={enterpriseUsers}
        setSearch={(val) => setSearch(val)}
      />
      <ModalCreateEnterpriseUser
        isOpen={isAddOpen}
        onClose={() => setIsAddOpen(false)}
      />
    </Box>
  );
}

export { Directory };
