import { apiSlice } from "../../../../api/apiSlice";
import { objectToParamString } from "../../../../utils";

export const meetingApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getMeetings: builder.query({
      query: ({ idProject, ...params }) =>
        `project/meeting/${idProject}${objectToParamString(params)}`,
      keepUnusedDataFor: 10,
      providesTags: ["Meetings"],
    }),
    getMeetingById: builder.query({
      query: (idMeeting) => `project/meeting/getMeeting/${idMeeting}`,
      providesTags: ["Meetings"],
    }),
    createMeeting: builder.mutation({
      query: (body) => ({
        url: "project/meeting/createMeeting",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Meetings"],
    }),
    editMeeting: builder.mutation({
      query: (body) => ({
        url: "project/meeting/editMeeting",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Meetings"], // Check
    }),
    updateMeeting: builder.mutation({
      query: (body) => ({
        url: "project/meeting/v2",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Meetings"],
    }),
    deleteMeeting: builder.mutation({
      query: (body) => ({
        url: "project/meeting/deleteMeeting",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Meetings"],
    }),
    recoverMeeting: builder.mutation({
      query: (body) => ({
        url: "project/meeting/restoreMeeting",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Meetings", "Dashboard"],
    }),
    startMeeting: builder.mutation({
      query: (body) => ({
        url: "project/meeting/startMeeting",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Meetings", "Dashboard"],
    }),
    confirmAttendenceMeeting: builder.mutation({
      query: (body) => ({
        url: "project/meeting/confirmAttendenceMeeting",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Meetings", "Dashboard"],
    }),
    setStatusMeeting: builder.mutation({
      query: (body) => ({
        url: "project/meeting/setStatusMeeting",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Meetings", "Dashboard"],
    }),
  }),
});

export const {
  useGetMeetingsQuery,
  useGetMeetingByIdQuery,
  useCreateMeetingMutation,
  useEditMeetingMutation,
  useDeleteMeetingMutation,
  useRecoverMeetingMutation,
  useStartMeetingMutation,
  useConfirmAttendenceMeetingMutation,
  useSetStatusMeetingMutation,
  useUpdateMeetingMutation,
} = meetingApiSlice;
