export const enterprise = {
  construction: "建筑",
  realState: "房地产",
  architect: "建筑师",
  contractor: "承包商",
  subcontractor: "分包商",
  maintenance: "维护",
  developer: "开发商",
  generalContractor: "总承包商",
  other: "其他",
};
