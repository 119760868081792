export const payment = {
  paymentMethod: "Método de pagamento",
  invoiceDetails: "Detalhes da fatura",
  addNewCard: "Adicionar novo cartão",
  addData: "Adicionar dados",
  orderSummary: "Resumo do pedido",
  pricePerProject: "Preço por projeto",
  pricePerUser: "Preço por usuário",
  projectsNumber: "Número de projetos",
  usersNumber: "Número de usuários",
  totalPerYear: "Total por ano",
  totalPerMonth: "Total por mês",
  agreement:
    "Entendo que minhas faturas serão baseadas no número de usuários. Licenças podem ser adicionadas convidando usuários para minha conta ou projetos após o pagamento. Os usuários podem ser removidos a qualquer momento.",
  editCard: "Editar cartão",
  emailRecipient: "E-mail do destinatário",
  companyName: "Nome da empresa",
  addCard: "Adicionar cartão",
  cardNumber: "Número do cartão",
};
