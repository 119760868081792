import { apiSlice } from "../../../../api/apiSlice";

export const manpowerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getJobTypes: builder.query({
      query: (idEnterprise) => `/manpower/jobTypes/${idEnterprise}`,
      keepUnusedDataFor: 60,
      providesTags: ["JobTypes"],
    }),
    getSubcontractors: builder.query({
      query: (idEnterprise) => `/manpower/subcontractors/${idEnterprise}`,
      keepUnusedDataFor: 60,
      providesTags: ["Subcontractors"],
    }),
    addSubcontractor: builder.mutation({
      query: (data) => ({
        url: "/manpower/subcontractor",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Subcontractors"],
    }),
    addJobType: builder.mutation({
      query: (data) => ({
        url: "/manpower/jobType",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["JobTypes"],
    }),
    updateJobType: builder.mutation({
      query: (body) => ({
        url: "/manpower/jobType",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["JobTypes"],
    }),
    updateSubcontractor: builder.mutation({
      query: (body) => ({
        url: "/manpower/subcontractor",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Subcontractors"],
    }),
  }),
});

export const {
  useGetJobTypesQuery,
  useGetSubcontractorsQuery,
  useAddSubcontractorMutation,
  useAddJobTypeMutation,
  useUpdateJobTypeMutation,
  useUpdateSubcontractorMutation,
} = manpowerApiSlice;
