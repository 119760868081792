export const plan = {
  perUser: "ユーザーごと",
  perProject: "プロジェクトごと",
  perMonth: "月ごと",
  perYear: "年ごと",
  monthly: "毎月",
  annual: "年間",
  bestValue: "最適な価値",
  youWillPay: "支払い金額",
  activeProjects: "アクティブなプロジェクト",
  activeUsers: "アクティブなユーザー",
};
