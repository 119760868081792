import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import animationData from "../../../projectDashboard/16627-firework.json";

const usePhotosSteps = () => {
  const { t: translation } = useTranslation("photos");

  const addPhotoSteps = [
    {
      target: "[data-tour='add-photos-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_addPhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_addPhotos_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='add-photos-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_addPhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_addPhotos_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-photos-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_addPhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_addPhotos_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-photos-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_addPhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_addPhotos_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-photos-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_addPhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_addPhotos_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_addPhotos")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const addAlbumSteps = [
    {
      target: "[data-tour='add-photos-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_addAlbums_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_addAlbums_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='add-album-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_addAlbums_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_addAlbums_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "[data-tour='add-album-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_addAlbums_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_addAlbums_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableScrolling: true,
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_addAlbums")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const viewSteps = [
    {
      target: "[data-tour='view-photos-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_viewPhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_viewPhotos_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "[data-tour='view-photos-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_viewPhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_viewPhotos_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableScrolling: true,
    },
    {
      target: "[data-tour='view-photos-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_viewPhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_viewPhotos_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableScrolling: true,
    },
    {
      target: "[data-tour='view-photos-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_viewPhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_viewPhotos_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableScrolling: true,
    },
    {
      target: "[data-tour='view-photos-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_viewPhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_viewPhotos_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='view-photos-6']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_viewPhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_viewPhotos_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='view-photos-7']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_viewPhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_viewPhotos_text")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            <strong>{translation("step7_viewPhotos_text1_1")}</strong> -{" "}
            {translation("step7_viewPhotos_text1_2")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            <strong>{translation("step7_viewPhotos_text2_1")}</strong> -{" "}
            {translation("step7_viewPhotos_text2_2")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            <strong>{translation("step7_viewPhotos_text3_1")}</strong> -{" "}
            {translation("step7_viewPhotos_text3_2")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_viewPhotos")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const recycleSteps = [
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {`1. ${translation("moreTitle")}`}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("moreDescription")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='recycle']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_recyclePhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_recyclePhotos_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      //meta: { isLastMore: true },
      //actionAfter: () => navigate("./restore"),
    },
    {
      target: "[data-tour='recycle-photos-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_recyclePhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_recyclePhotos_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='recycle-photos-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_recyclePhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_recyclePhotos_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='recycle-photos-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_recyclePhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_recyclePhotos_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_recyclePhotos")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const exportSteps = [
    {
      target: "[data-tour='recycle-photos-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_exportPhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_exportPhotos_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='export-photos-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_exportPhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_exportPhotos_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_exportPhotos")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const settingsSteps = [
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {`1. ${translation("moreTitle")}`}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("moreDescription")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='settings']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_settingsPhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_settingsPhotos_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='docs-settings-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_settingsPhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_settingsPhotos_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='save-settings']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_settingsPhotos_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_settingsPhotos_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_settingsPhotos")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  return [
    {
      addPhotoSteps,
      addAlbumSteps,
      viewSteps,
      recycleSteps,
      exportSteps,
      settingsSteps,
    },
  ];
};

export { usePhotosSteps };
