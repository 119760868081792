export const login = {
  errorLoginTitle: "ログインに失敗しました",
  errorLoginAuthMessage: "メールアドレスと/またはパスワードが一致しません",
  errorLoginMessage: "何か問題が発生しました。後でもう一度お試しください。",
  errorNoUser: "ユーザー名が存在しません",
  welcomeBack: "お帰りなさい！",
  password: "パスワード",
  logIn: "ログイン",
  forgotPassword: "パスワードをお忘れですか？",
  errorRecoverTitle: "パスワードの回復に失敗しました",
  followInstructions: "指示に従ってください",
  recoverPasswordTitle:
    "Buildpeerアカウントのメールアドレスを入力して、パスワードリセットの手順を受け取ってください",
  exampleEmail: "example@mail.com",
  recoverPassword: "パスワードの回復",
  emailInvalid: "無効なメールアドレス",
};
