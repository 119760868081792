const templates = {
  createTemplates: "Create templates",
  createTemplate: "Create template",
  templateInstructions: "Complete the following steps to get started",
  templateTitle: "Template title",
  selectDisciplines: "Add disciplines",
  saveTemplate: "Save template",
  weighting: "Weighting",
  checklist: "Checklist",
  designTemplates: "Design templates",
  addSubitem: "Add sublist",
  designDescription: "Create templates to track the design department",
  selectModule: "Select a module to create your template",
  templates: "Templates",
  libraries: "Libraries",
  noTemplates: "You don't have any templates created yet",
  createTemplatesDesc: "Click on create template to get started",
  importTemplate: "Import from Excel",
  createNewTemplate: "Create new template",
  filters: {
    title: "Filters",
    filterBy: "Filter by",
    searchUser: "Search users",
    filterByEditedBy: "Edited by",
    filterByDateRange: "Date",
    startDate: "Start:",
    endDate: "End:",
    setDatePlaceholder: "Set date",
    showResults: "Show results",
    clearFilters: "Clear",
  },
  noTemplatesFilters: "Your filters don't match any template",
  editTemplatesFiltersDesc: "Adjust your filters to continue.",
  table: {
    name: "Template name",
    createdDate: "Creation date",
    editedDate: "Last edited",
    disciplines: {
      singular: "Discipline",
      plural: "Disciplines",
    },
    date: "Date",
    actions: "Actions",
    editedBy: "Edited by",
    items: {
      singular: "Item",
      plural: "Items",
    },
  },
  actions: {
    lastEdits: "Last edits",
    exportTemplate: "Export to Excel",
    editTemplate: "Edit template",
    deleteTemplate: "Delete template",
    duplicateTemplate: "Duplicate template",
  },
  delete: {
    title: "Delete template",
    description: "Are you sure you want to delete this template?",
    confirm: "Delete",
    cancel: "Cancel",
  },
  success: "Success",
  error: "Error",
  templateDuplicated: {
    success: "Template duplicated successfully",
    error: "Error duplicating the template",
  },
  templateDeleted: {
    success: "Template deleted successfully",
    error: "Error deleting the template",
  },
};

export { templates };
