export const tour = {
  // topbar
  guideMe: "引导我",
  support: "支持",
  meeting: "会议",
  workflow: "工作流程",
  // guide me buttons
  addButtonTour: "添加",
  viewButtonTour: "查看",
  editButtonTour: "编辑",
  recycleButtonTour: "回收",
  exportButtonTour: "导出",
  settingsButtonTour: "设置",
  deleteButtonTour: "删除",
  changeRoleButtonTour: "更改角色",
  // docs
  docsAddFileButtonTour: "添加文件",
  docsAddFolderButtonTour: "添加文件夹",
  docsFileInfoButtonTour: "文件信息",
  docsMoveToButtonTour: "移动到",
  docsActionsButtonTour: "操作",
  // more
  obligatory: "必填",
  congratulations: "恭喜！",
  moreTitle: "更多选项",
  moreDescription: "在这里您可以找到其他选项。",
};
