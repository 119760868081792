const gray = "#C2C2C2";
const green = "#82DF8A";
const orange = "#FF891E";
const yellow = "#FFC42D";
const blue = "#03cafc";
const red = "#ff3b3b";

const statusStyles = {
  close: {
    backgroundColor: gray,
  },
  closed: {
    backgroundColor: gray,
  },
  open: {
    backgroundColor: green,
  },
  doing: {
    backgroundColor: green,
  },
  draft: {
    backgroundColor: orange,
  },
  pending: {
    backgroundColor: yellow,
  },
  in_revision: {
    backgroundColor: blue,
  },
  backlog: {
    backgroundColor: green,
  },
  rejected: {
    backgroundColor: red,
  },
  done: {
    backgroundColor: gray,
  },
};

export { statusStyles };
