import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import EmptyTemplatesFilters from "../../../assets/icons/templates/emptyTemplatesFilters.svg";

function TemplatesEmptyFiltersState() {
  const { t: tTemplates } = useTranslation("templates");
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height="100%"
      alignSelf="baseline"
    >
      <img
        src={EmptyTemplatesFilters}
        alt="empty state"
        style={{ width: "50rem", marginBottom: "2rem" }}
      />
      <Typography
        textAlign="center"
        fontSize="2.325rem"
        color="#4B4B4B"
        fontWeight="700"
      >
        {tTemplates("noTemplatesFilters")}
      </Typography>
      <Typography fontSize="1.425rem" textAlign="center" color="#4B4B4B">
        {tTemplates("editTemplatesFiltersDesc")}
      </Typography>
    </Box>
  );
}

export { TemplatesEmptyFiltersState };
