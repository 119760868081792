import { Box, Divider, Skeleton } from "@mui/material";

function TaskDetailsSkeletonLoading() {
  return (
    <Box pt="0.5rem">
      <Box display="flex" columnGap="0.5rem" alignItems="center" px="0.5rem">
        <Skeleton variant="circular" width="25px" height="25px" />
        <Skeleton variant="circular" width="60px" height="32px" />
        <Box flex={1} />
        <Skeleton variant="text" width="60px" height="32px" />
        <Skeleton variant="circular" width="25px" height="25px" />
        <Skeleton variant="circular" width="25px" height="25px" />
      </Box>
      <Box display="flex" justifyContent="center" mb="0.5rem" px="0.5rem">
        <Skeleton variant="text" width="220px" height="50px" />
      </Box>
      <Divider sx={{ borderColor: "#FBCB04" }} />
      <Box px="0.5rem">
        <Skeleton variant="text" width="150px" height="32px" />
        <Skeleton
          variant="rounded"
          width="100%"
          height="88px"
          sx={{ mb: "0.5rem" }}
        />
        <Box
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          rowGap="1rem"
          alignItems="center"
        >
          {[...Array(6)].map((v, i) => (
            <Skeleton key={i} variant="rounded" width="230px" height="130px" />
          ))}
        </Box>
        <Box display="flex" alignItems="center" columnGap="0.5rem">
          <Skeleton variant="circular" height="25px" width="25px" />
          <Skeleton variant="text" height="45px" width="100px" />
        </Box>
        <Box display="flex" alignItems="center" columnGap="0.5rem">
          <Skeleton variant="text" height="45px" width="50px" />
          <Skeleton variant="rounded" sx={{ flex: 1 }} height="7px" />
        </Box>
        {[...Array(3)].map((v, i) => (
          <Box key={i} display="flex" alignItems="center" columnGap="0.5rem">
            <Skeleton variant="square" width="25px" height="25px" />
            <Skeleton variant="text" height="45px" width="150px" />
          </Box>
        ))}
        <Box display="flex" alignItems="center" columnGap="0.5rem">
          <Skeleton variant="circular" height="25px" width="25px" />
          <Skeleton variant="text" height="45px" width="100px" />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="left"
          columnGap={3}
          width="100%"
        >
          <Skeleton variant="circular" width={50} height={50} />
          <Skeleton variant="rounded" width="100%" height={125} />
        </Box>
      </Box>
    </Box>
  );
}

export { TaskDetailsSkeletonLoading };
