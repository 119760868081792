import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import {
  AssignedUsersTooltip,
  EmptyState,
  StyledMaterialReactTable,
  TooltipIconButton,
} from "../../../components/shared";
import { setCurrentAnnouncement } from "../../../features/project/modules/announcements/announcementSlice";
import {
  DateFormat,
  getNextDateAnnouncement,
} from "../../../services/helpers/dateFormat";
import Can from "../../../utils/can";
import { useAbility } from "@casl/react";
import CaslContext from "../../../utils/caslContext";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../features/userSettings/userSettingsSlice";

function ScheduledAnnouncements(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ability = useAbility(CaslContext);
  const currentUser = useSelector(selectCurrentUser);
  
  // Translations
  const { t: tAnnouncements } = useTranslation("announcements");
  const { t: tGeneral } = useTranslation("general");
  const [selectedAnnouncements, setSelectedAnnouncements] = useState({});

  const {
    data,
    pageSize,
    setPageSize,
    openDialogFunc,
    handleDelete,
    darkMode,
    isLoading,
    userRole,
  } = props;

  const columns = [
    // Message
    {
      accessorKey: "description",
      header: tAnnouncements("announcement"),
      enableSorting: false,
      muiTableBodyCellProps: {
        align: "left",
      },
      muiTableHeadCellProps: {
        align: "left",
      },
      Cell: ({ cell, row }) => (
        <Typography
          sx={{ fontSize: 12 }}
          onClick={() => {
            openDialogFunc(row.original.id);
          }}
        >
          {cell.getValue().length <= 60
            ? cell.getValue()
            : `${cell.getValue().substr(0, 60)}...`}
        </Typography>
      ),
      size: 180,
    },
    // Repeat
    {
      accessorKey: "repeatRate",
      header: tAnnouncements("repeat"),
      enableSorting: false,
      Cell: ({ cell }) => tAnnouncements(cell.getValue()),
      size: 100,
    },
    // Next Publication
    {
      accessorKey: "publishDate",
      header: tAnnouncements("nextPublication"),
      enableSorting: false,
      Cell: ({ cell, row }) => (
        <DateFormat
          date={getNextDateAnnouncement(
            cell.getValue(),
            row.original.repeatRate,
          )}
          withTime
        />
      ),
      size: 130,
    },
    // Expiration Date
    {
      accessorKey: "expirationDate",
      header: tAnnouncements("expiration"),
      sorting: true,
      Cell: ({ cell }) => <DateFormat date={cell.getValue()} withTime />,
      size: 140,
    },
    // Creator
    {
      accessorKey: "createdByUser",
      header: tAnnouncements("creator"),
      enableSorting: false,
      Cell: ({ cell }) => <AssignedUsersTooltip users={[cell.getValue()]} />,
      size: 90,
    },
    // Distribution List
    {
      accessorKey: "distributionListUsers",
      header: tAnnouncements("distributionList"),
      enableSorting: false,
      Cell: ({ cell }) =>
        cell.getValue().length > 0 ? (
          <AssignedUsersTooltip users={cell.getValue()} />
        ) : (
          <Typography sx={{ fontSize: 12, color: darkMode }}>
            {tGeneral("no_assinged_users")}
          </Typography>
        ),
      size: 150,
    },
  ];

  const handleEdit = (value) => {
    dispatch(setCurrentAnnouncement(value));
    navigate("../announcementForm/");
  };

  if (!isLoading && (!data || !data.length)) {
    return (
      <EmptyState
        title={tAnnouncements("addAnnouncementTitle")}
        content={
          userRole?.drawings === 3 || userRole?.drawings === 2
            ? tAnnouncements("addAnnouncementValidation")
            : ""
        }
        module="announcements"
      />
    );
  }

  return (
    <StyledMaterialReactTable
      columns={columns}
      data={data}
      isLoading={isLoading}
      enableRowSelection
      filtersComponent={null}
      selectedRows={selectedAnnouncements}
      setSelectedRows={setSelectedAnnouncements}
      renderRowActions={({ row }) => (
        <Box display="flex">
          {(ability.can("superAdmin", "announcements") || (ability.can("edit", "announcements") && row.original.createdBy === currentUser.id)) &&
            <TooltipIconButton
              icon={<Edit />}
              onClick={() => navigate(`form/${row.id}`)}
              label={tGeneral("edit")}
              color="black"
              data-tour="edit-announcement-1"
            />
          }
          <Can I="view" a="announcements">
            <TooltipIconButton
              icon={<RemoveRedEye />}
              onClick={() => openDialogFunc(row.original.id)}
              label={tGeneral("seeDetails")}
              color="black"
              data-tour="view-announcement-1"
            />
          </Can>
          <Can I="delete" a="announcements">
            <TooltipIconButton
              icon={<Delete />}
              label={tGeneral("delete")}
              onClick={() => handleDelete([row.id])}
              color="red"
            />
          </Can>
        </Box>
      )}
    />
  );
}

export default ScheduledAnnouncements;
