import {
  Check,
  CropSquare,
  Delete,
  Edit,
  MoreVert,
  Square,
} from "@mui/icons-material";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Checkbox,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LazyLoad from "react-lazyload";
import { useDeleteAlbumsMutation } from "../../../../features/project/imageApiSlice";
import { PopUpAlert } from "../../../PopUpAlert";
import { ModalWithAction } from "../../../shared";
import { CreateAlbum } from "../CreateAlbum";

import DefaultCover from "../../../../assets/plan-viewing.png";
import Can from "../../../../utils/can";

function AlbumCard({ album, goAlbum, selectAlbum, isSelected }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tPhotos } = useTranslation("photos");
  const [isHover, setIsHover] = useState(false);
  const [showEditing, setShowEditing] = React.useState(false);
  const [menuElement, setMenuElement] = React.useState(null);
  const [isDeleting, setIsDeleting] = React.useState(false);

  const open = Boolean(menuElement);

  const handleOpenMenu = (event) => setMenuElement(event.currentTarget);

  const [
    deleteAlbums,
    {
      isLoading: isDeleteLoading,
      isError: isDeleteError,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteAlbumsMutation();

  const handleDeleteAlbum = async () => {
    await deleteAlbums({ ids: [album.id] }).unwrap();
  };

  React.useEffect(() => {
    if (isDeleteSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tPhotos("albumDeletedSuccessfully"),
      );
      setIsDeleting(false);
    }
    if (isDeleteError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      setIsDeleting(false);
    }
  }, [isDeleteError, isDeleteSuccess]);

  return (
    <Card
      component="div"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      sx={{ width: "300px", borderRadius: "20px", position: "relative" }}
    >
      {(isHover || isSelected) && (
        <Checkbox
          sx={{ position: "absolute", left: "10px", top: "10px", zIndex: 2 }}
          onChange={(e) => {
            selectAlbum(album.id);
            e.stopPropagation();
          }}
          value={isSelected}
          icon={
            <Box
              sx={{
                width: "18px",
                height: "18px",
                borderRadius: "2px",
                border: "2px solid black",
                backgroundColor: "white",
              }}
            />
          }
          checkedIcon={
            <Box
              sx={{
                width: "18px",
                height: "18px",
                borderRadius: "2px",
                backgroundColor: "#FFC42D",
              }}
            >
              <Check sx={{ color: "white", fontSize: "1rem" }} />
            </Box>
          }
        />
      )}
      <CardActionArea onClick={() => goAlbum(album.id)}>
        <LazyLoad>
          <CardMedia
            component="img"
            height="140"
            image={album.frontpageImageUrl ?? DefaultCover}
            loading="lazy"
            sx={{
              borderTopRightRadius: "20px",
              borderTopLeftRadius: "20px",
              objectFit: album.frontpageImageUrl ? "cover" : "scale-down",
            }}
          />
        </LazyLoad>
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>{album.name}</Typography>
            <Can I="renameAlbum" a="photos">
              <IconButton onMouseEnter={handleOpenMenu} onClick={handleOpenMenu}>
                <MoreVert />
              </IconButton>
            </Can>
            <Menu
              id="image-menu"
              anchorEl={menuElement}
              open={open}
              onClose={(e) => {
                e.stopPropagation();
                setMenuElement(null);
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={(e) => {
                  e.stopPropagation();
                  setShowEditing(true);
                }}
              >
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                <ListItemText>{tGeneral("rename")}</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
          <Typography fontSize="12px">
            {album?.totalActive} {tPhotos("photos")}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CreateAlbum
        currentAlbum={album}
        onClose={() => {
          setShowEditing(false);
        }}
        isOpen={showEditing}
      />
      <ModalWithAction
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        title="Delete"
        content="Are you sure you want to delete this album?"
        actionLabel="Delete"
        action={handleDeleteAlbum}
        isLoading={isDeleteLoading}
      />
    </Card>
  );
}

export { AlbumCard };
