import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
};
export const filterOptions = [
  {
    value: "createdBy",
    label: "uploadedCreatedBy",
  },
  {
    value: "createdOn",
    label: "uploadedCreatedOn",
  },
  {
    value: "recent",
    label: "recentDocuments",
  },
];
export const filterRecentOptions = [
  {
    value: "thisWeek",
    label: "thisWeek",
  },
  {
    value: "lastWeek",
    label: "lastWeek",
  },
  {
    value: "thisMonth",
    label: "thisMonth",
  },
];
