import { Close, DoneAll } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useGetModifiedByLogIdObjectQuery } from "../../features/log/logApiSlice";
import UserAvatar from "../userAvatars/UserAvatar";
import { TooltipIconButton } from "./TooltipIconButton";

function ModalLastEdit({ isOpen, onClose, idObject }) {
  const { t: tGeneral } = useTranslation("general");
  const isMobile = useMediaQuery("(max-width:820px)");
  const { data: editBy } = useGetModifiedByLogIdObjectQuery(idObject, {
    skip: !isOpen,
  });

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          width: "30%",
          height: "50%",
          display: "flex",
          flexDirection: "column",
          p: "40px",
        }}
      >
        <Box display="flex">
          <Typography fontSize="25px" fontWeight="bold" flex={1}>
            {tGeneral("lastEdit")}
          </Typography>
          <TooltipIconButton
            icon={<Close />}
            label={tGeneral("close")}
            onClick={onClose}
            color="gray"
          />
        </Box>
        <Divider sx={{ marginTop: 3, marginBottom: 3 }} />

        <List
          sx={{
            width: "100%",
            maxWidth: "100%",
            flex: 1,
            overflowY: "auto",
          }}
        >
          {editBy?.map((log) => (
            <ListItem alignItems="center" key={log.createdAt}>
              <UserAvatar
                name={`${log.firstName} ${log.lastName}`}
                size="Small"
                url={log.urlAvatar}
              />
              <ListItemText primary={`${log.firstName} ${log.lastName}`} sx={{ mr: 2 }}/>
              <ListItemText
                primary={moment(log.createdAt).format("D MMMM YYYY h:mm A")}
              />
              <ListItemIcon>
                <DoneAll />
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </Card>
    </Modal>
  );
}

export { ModalLastEdit };
