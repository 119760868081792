import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  restoreSelection: [],
  updated: false,
  current: null,
};

export const announcementSlice = createSlice({
  name: "announcement",
  initialState,
  reducers: {
    resetAnnouncements: () => initialState,
    setRestoreSelection: (state, action) => {
      state.restoreSelection = action.payload;
    },
    setUpdated: (state, action) => {
      state.updated = action.payload;
    },
    setCurrentAnnouncement: (state, action) => {
      state.current = action.payload;
    },
  },
});

export const selectRestoreSelection = (state) =>
  state.announcement.restoreSelection;
export const selectUpdated = (state) => state.announcement.updated;
export const selectCurrentAnnouncement = (state) => state.announcement.current;

export const { setRestoreSelection, setUpdated, setCurrentAnnouncement } =
  announcementSlice.actions;

export default announcementSlice.reducer;
