import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import { Feature } from "./Feature";
import Calendar from "./../../../assets/icons/calendar.svg";
import Cloud from "./../../../assets/icons/cloud.svg";
import CustomerService from "./../../../assets/icons/customer-service.svg";
import Easy from "./../../../assets/icons/easy.svg";
import Responsive from "./../../../assets/icons/responsive.svg";
import Speed from "./../../../assets/icons/speed.svg";

const leftFeatures = [
  { icon: Speed, label: "Fast speed" },
  {
    icon: Cloud,
    label: "Backup of information",
  },
  {
    icon: Responsive,
    label: "Responsive design",
  },
];

const rightFeatures = [
  { icon: Easy, label: "Easy to use" },
  {
    icon: Calendar,
    label: "Info always available and updated",
  },
  {
    icon: CustomerService,
    label: "High quality client service",
  },
];

function WhyBuildpeer() {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        paddingTop: isMobile ? "70px" : "140px",
      }}
    >
      <Typography
        fontSize={isMobile ? "29px" : "47px"}
        align="center"
        fontWeight="bold"
        mb="40px"
      >
        Why Buildpeer
      </Typography>
      <Typography
        align="center"
        fontSize={isMobile ? "16px" : "22px"}
        sx={{ paddingX: isMobile ? "10%" : "33%" }}
      >
        Access all your information on any browser, tablet or phone to get
        started quickly. Easy to use. Designed for the field and extremely fast.
      </Typography>
      <Box
        sx={[
          {
            display: "flex",
            backgroundImage:
              !isMobile &&
              `url(${require("./../../../assets/phone-modules.png")})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            height: "700px",
          },
          isMobile
            ? {
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
                paddingX: "5%",
              }
            : { justifyContent: "space-evenly" },
        ]}
      >
        <Box
          sx={{
            justifyContent: "space-evenly",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {leftFeatures.map((feature) => (
            <Feature
              key={feature.label}
              icon={feature.icon}
              label={feature.label}
              reverse={isMobile}
            />
          ))}
        </Box>
        <Box
          sx={{
            justifyContent: "space-evenly",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {rightFeatures.map((feature) => (
            <Feature
              key={feature.label}
              icon={feature.icon}
              label={feature.label}
              reverse
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export { WhyBuildpeer };
