import { tour } from "./tour";

export const dailyReports = {
  ...tour,
  moduleElement: "レポート",
  dailyReports: "日報",
  reports: "レポート",
  emptyReportsTitle: "この時点で作成されたレポートはありません",
  emptyReportsContent:
    "追加する場合は、レポートの追加ボタンをクリックしてプロセスを開始してください",
  emptyInactiveReportsTitle: "現在、ごみ箱にレポートはありません",
  emptyInactiveReportsContent: "レポートを削除すると、ここに表示されます",
  addReport: "レポートを追加",
  addDailyReport: "日報を追加",
  editReport: "レポートを編集",
  dueDate: "期限",
  newReport: "新しいレポート",
  creators: "作成者",
  assigned: "割り当てられた",
  distributionList: "配布リスト",
  private: "非公開",
  num: "番号",
  title: "タイトル",
  status: "ステータス",
  closedDate: "クローズ日",
  actions: "アクション",
  detail: "詳細",
  exportPdf: "PDFエクスポート",
  exportCvs: "CSVエクスポート",
  fetchReportIssue: "このレポートの読み込みに問題が発生しました",
  retry: "リトライ",
  createdDate: "作成日",
  atHour: "時",
  lastSeen: "最終閲覧",
  lastEdit: "最終編集",
  approveReport: "レポートを承認",
  dailyReportsRecycleBin: "ごみ箱（レポート）",
  confirmDelete:
    "選択した日報を削除してもよろしいですか？この操作は元に戻すことはできません",
  successRecovered: "日報が正常に回復しました",
  errorRecovered: "日報を復元できません",
  successDeleted: "日報が正常に削除されました",
  errorDeleted: "日報を削除できません",
  approvedReport: "レポートが正常に承認されました",
  errorApprovedReport: "レポートを承認できません",
  deleteReport: "レポートを削除",
  report: "レポート",
  addDescription: "説明を追加",
  // other
  obligatory: "必須",
  public: "公開",
  congratulations: "おめでとうございます！",
  rfi_title_module: "RFI",
  no_rfis_title: "現在、RFIは作成されていません",
  no_rfis_text:
    "追加する場合は、RFIの追加ボタンをクリックしてプロセスを開始してください",
  meeting: "ミーティング",
  workflow: "ワークフロー",
  guideMe: "ガイド",
  support: "サポート",
  days: "日",
  rfi_recycle_bin: "RFIごみ箱",
  // add steps
  step1_addDR_title: "1. レポートを追加",
  step1_addDR_text: "ここをクリックして新しいレポートを追加します",
  step2_addDR_title: "2. タイトル",
  step2_addDR_text: "新しいレポートを説明できるタイトルを書いてください",
  step3_addDR_title: "3. 期限",
  step3_addDR_text:
    "割り当てられた人からの承認を受ける期限として期限を選択してください",
  step4_addDR_title: "4. ステータス",
  step4_addDR_text1_1: "ステータスを選択してください",
  step4_addDR_text1_2: "「OPEN」は、レポートをアクティブとして公開します。",
  step4_addDR_text2_1: "「DRAFT」ステータスを選択してください",
  step4_addDR_text2_2: "公開せずにレポートの下書きを保持します。",
  step5_addDR_title: "5. プライバシー",
  step5_addDR_text_1: "レポートのプライバシータイプを選択してください",
  step5_addDR_text_2:
    "モジュールにアクセス権があるすべてのユーザーがレポートを表示できます",
  step5_addDR_text_3: "参加者のみがこのレポートを表示できます",
  step6_addDR_title: "6. 作成者",
  step6_addDR_text: "あなたと同じ権限を持つユーザーを選択してください",
  step7_addDR_title: "7. 承認者",
  step7_addDR_text: "レポートを承認する人を選択してください",
  step8_addDR_title: "8. 配布リスト",
  step8_addDR_text: "このレポートに関して通知する人を選択してください",
  step9_addDR_title: "9. カテゴリ",
  step9_addDR_text1_1: "カテゴリを選択してください",
  step9_addDR_text1_2: "複数のレポートを作成するための「基本」カテゴリ。",
  step9_addDR_text2:
    "進捗とコストの測定値を持つレポートを作成するための「作業進捗」カテゴリ。",
  step9_addDR_text3: "1つのレポートのみを作成するための「問題」カテゴリ。",
  step9_addDR_text4:
    "日数と時間の遅延を持つレポートを作成するための「遅延」カテゴリ。",
  step10_addDR_title: "10. 新しいレポートを追加",
  step10_addDR_text: "ここをクリックして新しいレポートを開始します",
  step11_addDR_title: "11. 場所を選択",
  step11_addDR_text:
    "報告される場所を選択または追加するには、ここをクリックします",
  step12_addDR_title: "12. 説明",
  step12_addDR_text: "作業の進捗に関する説明を書いてください",
  step13_addDR_title: "13. 写真またはファイルを添付",
  step13_addDR_text: "説明に写真またはファイルを追加します",
  step14_addDR_title: "14. 追加",
  step14_addDR_text: "レポートを公開するには、ここをクリックします",
  stepFinal_addDR: "レポートの作成方法を学びました",
  // VIEW - guide me steps
  step1_viewDR_title: "1. レポートを表示",
  step1_viewDR_text: "ここをクリックしてレポートを表示します",
  step2_viewDR_title: "2. カテゴリ",
  step2_viewDR_text: "レポートが属するカテゴリを表示します",
  step3_viewDR_title: "3. レポートの全般情報",
  step3_viewDR_text: "このレポートの完全な情報を表示します",
  step4_viewDR_title: "{{stepNumber}}. 場所ごとのレポートの説明",
  step4_viewDR_text: "このレポートと写真の完全な情報を表示します",
  step5_viewDR_title: "{{stepNumber}}. コメント",
  step5_viewDR_text: "報告された場所にコメントできます",
  step7_viewDR_title: "{{stepNumber}}. 承認",
  step7_viewDR_text: "レポートを承認します",
  stepFinal_viewDR: "レポートの表示方法を学びました",
  step_view_reports_description_title: "{{stepNumber}}. 説明",
  step_view_reports_description_text: "レポートの完全な説明を表示します",
  step_view_report_progress_title: "{{stepNumber}}. 遅延とコスト",
  step_view_report_progress_text: "レポートの進捗とコストの測定値を表示します",
  // EDIT - guide me steps
  step1_editDR_title: "1. レポートを編集",
  step1_editDR_text: "ここをクリックしてレポートを編集します",
  step9_editDR_title: "{{stepNumber}}. 新しいレポートを追加",
  step9_editDR_text: "ここをクリックして新しいレポートを開始します",
  step10_editDR_title: "{{stepNumber}}. 作成済みレポートの編集",
  step10_editDR_text: "レポートに関する情報を表示および編集できます",
  step11_editDR_title: "{{stepNumber}}. ファイルと情報",
  step11_editDR_text: "作成済みレポートのファイルを編集または追加します",
  step13_editDR_title: "{{stepNumber}}. 保存",
  step13_editDR_text: "変更したレポートを保存するには、ここをクリックします",
  stepFinal_editDR: "レポートの編集方法を学びました",
  step11_edit_report_progress_title: "{{stepNumber}}. 進捗とコスト",
  step11_edit_report_progress_text:
    "レポートの進捗とコストの測定値を編集または追加します",
  step10_edit_report_problem: "レポートの説明とファイルを変更します",
  step9_edit_report_delay_title: "9. 遅延",
  step9_edit_report_delay_text: "レポートの遅延日数または時間を編集します",
  step10_edit_report_delay: "レポートの説明とファイルを変更します",
  // RECYCLE - guide me steps
  step1_recycleDR_title: "1. レポートごみ箱",
  step1_recycleDR_text: "ここをクリックしてごみ箱を開きます",
  step2_recycleDR_title: "2. レポートごみ箱メニュー",
  step2_recycleDR_text: "これはごみ箱のメニューです",
  step3_recycleDR_title: "3. レポートを表示",
  step3_recycleDR_text: "完全なレポートを表示できます",
  step4_recycleDR_title: "4. レポートを復元",
  step4_recycleDR_text: "復元するレポートを選択します",
  step5_recycleDR_title: "5. 選択したレポートを復元",
  step5_recycleDR_text: "選択したレポートを復元するには、ここをクリックします",
  stepFinal_recycleDR: "ごみ箱との対話方法を学びました",
  // EXPORT - guide me steps
  step1_exportDR_title: "1. PDFエクスポート",
  step1_exportDR_text:
    "レポートをPDF形式でエクスポートするには、ここをクリックします",
  step2_exportDR_title: "2. メール送信",
  step2_exportDR_text:
    "このレポートをメールで共有するには、ここをクリックします",
  step3_exportDR_title: "3. CSVエクスポート",
  step3_exportDR_text:
    "いくつかのレポートを選択するか、すべてのレポートをフィルタリングしてCSVファイルにエクスポートします",
  step4_exportDR_title: "4. PDFエクスポート",
  step4_exportDR_text:
    "いくつかのレポートを選択するか、すべてのレポートをフィルタリングしてPDFファイルにエクスポートします",
  step5_exportDR_title: "5. レポート内の画面",
  step5_exportDR_text: "詳細ビューからPDFでエクスポートできます",
  stepFinal_exportDR: "レポートのエクスポート方法を学びました",
  // SETTINGS - guide me steps
  step1_settingsDR_title: "2. レポート設定",
  step1_settingsDR_text: "設定を変更するには、ここをクリックします",
  step2_settingsDR_title: "3. 配布リスト",
  step2_settingsDR_text: "グローバルに所属する配布リストのユーザーを変更します",
  step3_settingsDR_title: "4. 設定を保存",
  step3_settingsDR_text: "設定を保存するには、ここをクリックします",
  stepFinal_settingsDR: "レポート設定との対話方法を学びました",
  due: "期限",
  closed: "クローズ",
  basic: "基本",
  workProgress: "作業進捗",
  problem: "問題",
  delay: "遅延",
  advance: "進捗",
  unit: "単位",
  addUnit: "単位を追加",
  unitAdded: "単位が正常に追加されました",
  risk: "リスク",
  highRisk: "高リスク",
  mediumRisk: "中リスク",
  lowRisk: "低リスク",
  workerName: "労働者名",
  jobType: "仕事の種類",
  workedHours: "労働時間",
  assistants: "アシスタント",
  manpower: "人員",
  subcontractorMessage:
    "今日{{date}}に合計{{total}}人が参加しました。ご確認ください。",
  job: "仕事",
  createReport: "レポートの作成",
  generalChart: "一般的なチャート",
  approvedCorrectly: "レポートは\n正常に承認されました",
  continueNavigate: "閲覧を続けることができます",
  approvedAtAndBy: "{{date}}に承認されました",
};
