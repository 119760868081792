import {
  Box,
  CircularProgress,
  Divider,
  Skeleton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";

function SkeletonLoading({ isTable, isInspections }) {
  const isMobile = useMediaQuery("(max-width:820px)");

  return isTable ? ( // Table
    <Box
      display="flex"
      width="100%"
      height="100%"
      flexDirection="column"
      rowGap={2}
    >
      {/* Title, searchbar and actions (settings, recycle and add) */}
      <Box display="flex" flexDirection="row" columnGap={2} width="100%">
        <Skeleton variant="rounded" width={800} height={40} />
        <Skeleton variant="rounded" width={250} height={40} />
        <Skeleton variant="rounded" width={40} height={40} />
        <Skeleton variant="rounded" width={40} height={40} />
        <Skeleton variant="rounded" width={40} height={40} />
      </Box>
      {/* Filters */}
      <Box display="flex" flexDirection="row" columnGap={2} width="100%">
        <Skeleton variant="rounded" width={200} height={40} />
        <Skeleton variant="rounded" width={200} height={40} />
        <Skeleton variant="rounded" width={200} height={40} />
        <Skeleton variant="rounded" width={200} height={40} />
      </Box>
      {/* Header Table */}
      <Box display="flex" flexDirection="row" columnGap={2} width="100%">
        <Skeleton variant="rounded" width={30} height={40} /> {/* Checkbox */}
        <Skeleton variant="rounded" width={90} height={40} /> {/* Privacy */}
        <Skeleton variant="rounded" width={50} height={40} /> {/* Num */}
        <Skeleton variant="rounded" width={200} height={40} /> {/* Title */}
        <Skeleton variant="rounded" width={100} height={40} /> {/* Status */}
        <Skeleton variant="rounded" width={130} height={40} /> {/* Avatars */}
        <Skeleton variant="rounded" width={180} height={40} /> {/* Date one */}
        <Skeleton variant="rounded" width={160} height={40} /> {/* Date two */}
        <Skeleton variant="rounded" width={160} height={40} /> {/* Actions */}
      </Box>
      {/* Rows table */}
      {[...Array(4)].map((v, i) => (
        <Box
          key={i}
          display="flex"
          flexDirection="column"
          rowGap={2}
          width="100%"
        >
          {/* Row one */}
          <Box display="flex" flexDirection="row" columnGap={2} width="100%">
            <Skeleton variant="rounded" width={30} height={40} />
            <Skeleton variant="text" width={400} height={40} />
            <Skeleton
              sx={{ borderRadius: 15 }}
              variant="text"
              width={150}
              height={40}
            />
            {[...Array(3)].map((v, i) => (
              <Skeleton key={i} variant="circular" width={40} height={40} />
            ))}
            <Skeleton variant="text" width={120} height={40} />
            <Skeleton
              sx={{ borderRadius: 15 }}
              variant="text"
              width={100}
              height={40}
            />
            {[...Array(4)].map((v, i) => (
              <Skeleton key={i} variant="rounded" width={40} height={40} />
            ))}
          </Box>
          {/* Row two */}
          <Box display="flex" flexDirection="row" columnGap={2} width="100%">
            <Skeleton variant="rounded" width={30} height={40} />
            <Skeleton variant="text" width={400} height={40} />
            <Skeleton
              sx={{ borderRadius: 15 }}
              variant="text"
              width={150}
              height={40}
            />
            {[...Array(2)].map((v, i) => (
              <Skeleton key={i} variant="circular" width={40} height={40} />
            ))}
            <Skeleton variant="text" width={140} height={40} />
            <Skeleton variant="text" width={140} height={40} />
            <Skeleton variant="rounded" width={200} height={40} />
          </Box>
        </Box>
      ))}
    </Box>
  ) : (
    // Details
    <Box
      display="flex"
      width="100%"
      height="100%"
      flexDirection="column"
      rowGap={2}
    >
      {/* Header (num, title, status, privacy) */}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        columnGap={2}
        width="100%"
      >
        {!isMobile && <Skeleton variant="circular" width="10%" height={40} />}
        <Skeleton
          sx={{ borderRadius: 15 }}
          variant="rounded"
          width={isMobile ? "20" : "10%"}
          height={40}
        />
        <Skeleton variant="text" width="50%" height={40} />
        {!isMobile && <Skeleton variant="rounded" width="10%" height={40} />}
        <Skeleton
          sx={{ borderRadius: 15 }}
          variant="rounded"
          width="10%"
          height={40}
        />
        <Skeleton variant="rounded" width="10%" height={40} />
      </Box>
      {/* General info */}
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        justifyContent="center"
        columnGap={3}
        rowGap={2}
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          columnGap={3}
          width={isMobile ? "100%" : "50%"}
        >
          <Skeleton variant="rounded" width="60%" height={125} />
          <Skeleton variant="rounded" width="40%" height={125} />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          columnGap={3}
          width={isMobile ? "100%" : "50%"}
        >
          <Skeleton variant="rounded" width="50%" height={125} />
          <Skeleton variant="rounded" width="50%" height={125} />
        </Box>
      </Box>
      <Skeleton variant="rounded" width="100%" height={100} />
      <Skeleton variant="text" width={isMobile ? "50%" : "25%"} height={40} />
      {/* Chat */}
      {isInspections ? (
        <Box display="flex" flexDirection="column" rowGap="1rem">
          {[...Array(3)].map((v, i) => (
            <React.Fragment key={i}>
              <Box display="flex" alignItems="center">
                <Box flex={1}>
                  <Skeleton variant="text" width="250px" height="40px" />
                  <Skeleton variant="text" width="200px" height="40px" />
                  <Box display="flex" columnGap="0.5rem">
                    <Skeleton variant="circular" width="40px" height="40px" />
                    <Skeleton variant="circular" width="40px" height="40px" />
                    <Skeleton variant="circular" width="40px" height="40px" />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <Box display="flex" columnGap="0.5rem">
                    <Skeleton variant="circular" width="40px" height="40px" />
                    <Skeleton variant="circular" width="40px" height="40px" />
                    <Skeleton variant="circular" width="40px" height="40px" />
                  </Box>
                  <Skeleton variant="text" width="200px" height="40px" />
                </Box>
              </Box>
              {i !== 2 && <Divider />}
            </React.Fragment>
          ))}
        </Box>
      ) : (
        [...Array(2)].map((v, i) => (
          <Box
            key={i}
            display="flex"
            flexDirection="row"
            justifyContent="left"
            columnGap={3}
            width="100%"
          >
            <Skeleton variant="circular" width={50} height={50} />
            <Skeleton variant="rounded" width="70%" height={125} />
          </Box>
        ))
      )}
    </Box>
  );
}

export default SkeletonLoading;
