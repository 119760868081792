import { Box, Card, Skeleton } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useGetProjectPendingsQuery } from "../../../../features/project/projectApiSlice";
import { ModalModulePendings } from "./ModalModulePendings";
import { ModuleCard } from "./ModuleCard";

function ModulePendings() {
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { data, isLoading } = useGetProjectPendingsQuery(idProject, {
    refetchOnMountOrArgChange: true,
  });
  const [selectedModule, setSelectedModule] = useState(null);
  const handleOpenPendings = (mod) => setSelectedModule(mod);
  const handleClosePendings = () => setSelectedModule(null);

  const modules = useMemo(
    () => (data ? data.filter((x) => x.moduleName !== "reports") : []),
    [data],
  );

  return (
    <>
      {isLoading
        ? [...Array(10)].map((_val, index) => (
            <Card
              key={index}
              sx={{
                display: "flex",
                p: "22px 24px",
                columnGap: "30px",
                alignItems: "center",
                borderRadius: "22px",
                mb: "5px",
                height: "84px",
              }}
            >
              <Skeleton variant="circular" width="60px" height="60px" />
              <Box flex={1}>
                <Skeleton height="40px" width="200px" />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                rowGap="10px"
              >
                <Skeleton variant="rounded" width="40px" height="25px" />
                <Skeleton variant="rounded" width="40px" height="25px" />
              </Box>
            </Card>
          ))
        : modules
            ?.sort((a, b) =>
              tGeneral(a.moduleName).localeCompare(tGeneral(b.moduleName)),
            )
            .map((mod) => (
              <ModuleCard
                key={mod.moduleName}
                openPendings={handleOpenPendings}
                {...mod}
              />
            ))}

      <ModalModulePendings
        data={selectedModule}
        onClose={handleClosePendings}
        isOpen={selectedModule !== null}
      />
    </>
  );
}

export { ModulePendings };
