import { Box, Card, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

function TemplateCard({ icon, image, title, description }) {
  const { t: tGeneral } = useTranslation("general");
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        border: "0.8px solid rgba(251, 203, 4, 0.80)",
        height: "260px",
        p: "1rem",
        borderRadius: "1rem",
        cursor: "pointer",
      }}
      onClick={() => navigate("design")}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography color="#4B4B4B" fontSize="0.875rem">
          {tGeneral("module")}
        </Typography>
        {icon}
      </Box>
      <Typography color="#4B4B4B" fontSize="2.125rem" fontWeight="700">
        {title}
      </Typography>
      <Typography color="#4B4B4B" fontSize="0.875rem">
        {description}
      </Typography>
      <Box display="flex" justifyContent="flex-end">
        <img src={image} alt="module" height="80px" width="auto" />
      </Box>
    </Card>
  );
}

export { TemplateCard };
