export const submittals = {
  submittal: "Submittal",
  submittalTitleModule: "Submittals",
  deleteSubmittalsTitle: "Delete submittals",
  deleteSubmittalsContent: "Are you sure you want to delete these submittals?",
  addSubmittal: "Add submittal",
  noSubmittalsTitle: "No Submittals have been created at the moment",
  addSubmittalsValidation:
    "If you want to add one, we invite you to click the Add Submittal button to start the process",
  submittalRecycleBin: "Submittals Recycle Bin",
  costCode: "Cost code",
};
