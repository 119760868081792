export const projectNetwork = {
  addProjectNetwork: "Adicionar à rede do projeto",
  acceptInvite: "Aceitar convite",
  yourNewCodeIs: "Seu novo código é",
  generateNewCode: "Gerar novo código",
  deleteNetworkMessage:
    "Tem certeza de que deseja excluir este projeto da sua rede? Todos os itens compartilhados não estarão mais acessíveis.",
  emptyNetworkTitle: "Rede vazia",
  emptyNetworkContent: "Você ainda não adicionou projetos à sua rede.",
  accepted: "Aceito",
  acceptedSuccessfully: "Convite aceito com sucesso.",
  codeCopied: "Código copiado",
  availableCodes: "Códigos disponíveis",
};
