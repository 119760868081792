import React from "react";
import { Navigate } from "react-router";
import { Route, Routes } from "react-router-dom";
// RFI tour
// Theme
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector } from "react-redux";
import darkTheme from "./assets/theme/darkTheme";
import defaultTheme from "./assets/theme/defaultTheme";
import { selectTheme } from "./features/preferences/preferencesSlice";
// Views
import Login from "./views/Login";
import RecoverPassword from "./views/RecoverPassword";
import ResetPassword from "./views/ResetPassword";
import SendEmailVerify from "./views/SendEmailVerify";
import SignUp from "./views/SignUp";
import VerifyAccount from "./views/VerifyAccount";
import { Landing } from "./views/landing";

// Redux selectors

function EnterpriseRoutes() {
  const darkMode = useSelector(selectTheme);
  const theme = darkMode ? darkTheme : defaultTheme;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Routes>
          {/* public routes */}
          {/* navigate to enterprise by default */}
          <Route path="/" element={<Navigate to="login" />} />

          <Route path="login" element={<Login />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="landingPage" element={<Landing />} />
          {/* <Route path="recoverpass" element={<RecoverPass />} /> */}
          <Route path="recover-password" element={<RecoverPassword />} />
          <Route path="reset-password/*" element={<ResetPassword />} />
          <Route path="send-email-verify" element={<SendEmailVerify />} />
          <Route path="verify-account/*" element={<VerifyAccount />} />
        </Routes>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default EnterpriseRoutes;
