import {
  Box,
  Card,
  CardContent,
  Chip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { RelatedUsersDetailsCard } from "../shared";
import Calendar from "../../assets/rfi/calendar.png";
import Delivery from "../../assets/rfi/delivery.svg";
import Star from "../../assets/rfi/star.svg";

function DateCard({ dateString, label, img, status, type }) {
  // Translations
  const { t: tRfis } = useTranslation("rfis");
  const { t: tGeneral } = useTranslation("general");

  const statusProps = {
    pending: {
      label: tGeneral("open_action").toUpperCase(),
      styles: {
        backgroundColor: "#ffc900",
        fontSize: 10,
        color: "white",
      },
    },
    closed: {
      label: tGeneral("closed").toUpperCase(),
      styles: {
        color: "#707070",
        fontSize: 10,
        backgroundColor: "#EAEAEA",
      },
    },
    draft: {
      label: tGeneral("draft").toUpperCase(),
      styles: {
        color: "white",
        fontSize: 10,
        backgroundColor: "#fe9300",
      },
    },
    stillOpen: {
      label: tGeneral("still_open").toUpperCase(),
      styles: {
        color: "white",
        fontSize: 10,
        backgroundColor: "#ffc900",
      },
    },
    still_open_past_due: {
      label: tGeneral("still_open").toUpperCase(),
      styles: {
        backgroundColor: "#e15452",
        color: "white",
        fontSize: 10,
        width: 100,
      },
    },
    notDefined: {
      label: tGeneral("not_defined").toUpperCase(),
      styles: {
        color: "white",
        fontSize: 10,
        backgroundColor: "#2196f3",
      },
    },
  };

  const isMobile = useMediaQuery("(max-width:820px)");

  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
      }}
    >
      <CardContent
        sx={{
          margin: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          margin={0.5}
          gap="20px"
          flexDirection={isMobile ? "column" : "row"}
        >
          {img}
          <Box margin={0.5}>
            <Typography fontSize="12px" color="GrayText" textAlign="center">
              {label}
            </Typography>
            {type === "delivery" ||
            (type === "closed" && status === "closed") ||
            type === "create" ? (
              <Typography
                fontSize="14px"
                fontWeight="medium"
                textAlign={isMobile ? "center" : "initial"}
              >
                {dateString}
              </Typography>
            ) : new Date(dateString) > new Date() ? (
              <Chip
                sx={statusProps["stillOpen"].styles}
                label={statusProps["stillOpen"].label}
              />
            ) : (
              <Chip
                sx={statusProps["still_open_past_due"].styles}
                label={statusProps["still_open_past_due"].label}
              />
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

function RfiUserDetail(props) {
  const isMobile = useMediaQuery("(max-width:820px)");

  const { userList, currentRfi } = props;

  // Translations
  const { t: tRfis } = useTranslation("rfis");
  const { t: tGeneral } = useTranslation("general");

  return (
    <Box
      display="flex"
      my="20px"
      gap="10px"
      flexWrap="wrap"
      width="100%"
      flexDirection={isMobile ? "column" : "row"}
    >
      <RelatedUsersDetailsCard
        lists={[
          { users: [currentRfi?.createdBy], label: tGeneral("creator") },
          {
            users: currentRfi?.receivedFrom ? [currentRfi?.receivedFrom] : [],
            label: tGeneral("receivedFrom"),
          },
          { users: currentRfi?.assignedTo, label: tGeneral("assignees") },
        ]}
      />
      <Box
        display="flex"
        flex={1}
        gap="1%"
        maxWidth={isMobile ? "100%" : "60%"}
      >
        {/* Created Date */}
        <DateCard
          dateString={moment(currentRfi.createDate).format("D MMMM YYYY")}
          label={tGeneral("createDate")}
          img={<img src={Calendar} alt="Creado" width="60px" />}
          status={currentRfi.status}
          type={"create"}
        />

        {/* Due Date */}
        <DateCard
          dateString={
            currentRfi.dueDate
              ? moment(currentRfi.dueDate).format("D MMMM YYYY")
              : "N/A"
          }
          label={tGeneral("due_date")}
          img={<img src={Delivery} alt="Entrega" width="60px" />}
          status={currentRfi.status}
          type={"delivery"}
        />

        {/* Closed Date */}
        <DateCard
          dateString={moment(currentRfi.closedDate).format("D MMMM YYYY")}
          label={tGeneral("closedDate")}
          img={<img src={Star} alt="Cierre" width="60px" />}
          status={currentRfi.status}
          type={"closed"}
        />
      </Box>
    </Box>
  );
}

export default RfiUserDetail;
