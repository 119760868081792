export const submittals = {
  submittal: "提出物",
  submittalTitleModule: "提出物",
  deleteSubmittalsTitle: "提出物を削除しますか？",
  deleteSubmittalsContent: "これらの提出物を削除してもよろしいですか？",
  addSubmittal: "提出物を追加",
  noSubmittalsTitle: "現在、提出物は作成されていません",
  addSubmittalsValidation:
    "追加する場合は、提出物の追加ボタンをクリックしてプロセスを開始してください",
  submittalRecycleBin: "提出物のごみ箱",
  costCode: "コストコード",
};
