import { ArrowBack } from "@mui/icons-material";
import { Box, Typography, CircularProgress } from "@mui/material";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TooltipIconButton } from "../../../components/shared";
import {
  CONST_DEFAULT_DESIGN_TEMPLATES_FILTERS,
  CONST_DEFAULT_DESIGN_TEMPLATES_W_PAGINATION,
  selectCurrentFiltersDesignTemplates,
  setCurrentFiltersDesignTemplates,
  useGetDesignTemplatedEditedByUserListQuery,
} from "../../../features/designTemplates";

import {
  DesignTemplatesTable,
  TemplatesEmptyFiltersState,
  TemplatesEmptyState,
} from "../../../components/enterpriseDashboard/Templates";
import { useGetUsersFromEnterpriseQuery } from "../../../features/enterprise/enterpriseApiSlice";
import {
  hasAtLeastOneFilter,
  usePaginatedDesignTemplates,
} from "../../../utils/usePaginatedDesignTemplates";
import { CreatePopover, FiltersPopover } from "./DesignTemplatesPopovers";

function DesignTemplates() {
  const { idEnterprise } = useParams();
  const { t: tTemplates } = useTranslation("templates");
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const filters = useSelector(selectCurrentFiltersDesignTemplates);

  const {
    paginated,
    refetch,
    isLoading: isLoadingDesignTemplates,
    isFetching: isFetchingDesignTemplates,
    noResults,
    noMoreResults,
  } = usePaginatedDesignTemplates({
    idEnterprise,
    search: null,
  });

  const { data: enterpriseUsers, isLoading: isLoadingUsers } =
    useGetDesignTemplatedEditedByUserListQuery(idEnterprise);

  const onChangeFilters = (newFilters) => {
    dispatch(
      setCurrentFiltersDesignTemplates({
        ...CONST_DEFAULT_DESIGN_TEMPLATES_FILTERS,
        ...newFilters,
      }),
    );
  };

  const isLoading = useMemo(
    () =>
      isLoadingDesignTemplates || isFetchingDesignTemplates || isLoadingUsers,
    [isLoadingDesignTemplates, isFetchingDesignTemplates, isLoadingUsers],
  );

  const users = useMemo(() => {
    if (isLoading) return [];
    return enterpriseUsers.map((user) => ({
      ...user,
      name: `${user.firstName} ${user.lastName}`,
    }));
  }, [enterpriseUsers, isLoading]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        p: "2rem",
        background: `
          linear-gradient(to top right, #F9F6C8, #FAFAFA 50%, #F9F6C8)
        `,
        backgroundBlendMode: "multiply",
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <TooltipIconButton
            icon={<ArrowBack />}
            label="Back"
            onClick={() => navigate(-1)}
            color="#8E8E8E"
          />
          <Typography color="#1F1F1F" variant="h4" fontWeight="700">
            {tTemplates("designTemplates")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "30px",
            alignItems: "center",
          }}
        >
          <CreatePopover />
          <FiltersPopover
            userList={users}
            filters={filters}
            onChangeFilters={onChangeFilters}
          />
        </Box>
      </Box>

      {!noResults && !isLoading && (
        <Box flex={1} sx={{ marginTop: "2rem", height: "90%" }}>
          <DesignTemplatesTable
            data={paginated ?? []}
            total={paginated?.totalResults}
            isLoading={isLoading}
            noMoreResults={noMoreResults}
            refetch={refetch}
            reset={() => {
              onChangeFilters({
                ...CONST_DEFAULT_DESIGN_TEMPLATES_W_PAGINATION,
              });
            }}
          />
        </Box>
      )}

      <Box
        display="flex"
        width="100%"
        justifyContent="center"
        alignItems="center"
        height="50%"
      >
        {!noMoreResults && !noResults && isLoading && (
          <CircularProgress size={100} color="primary" />
        )}
        {noResults && !hasAtLeastOneFilter(filters) && <TemplatesEmptyState />}
        {noResults && hasAtLeastOneFilter(filters) && (
          <TemplatesEmptyFiltersState />
        )}
      </Box>
    </Box>
  );
}

export { DesignTemplates };
