/* eslint-disable jsx-a11y/media-has-caption */
import { Pause, PlayArrow } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";

export default function AudioPlayer({ url }) {
  const audioRef = useRef(new Audio(url));

  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [time, setTime] = useState(0);

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleSeek = (event) => {
    const seekTime = event.target.value;
    audioRef.current.currentTime = seekTime;
    setTime(seekTime);
  };

  const handleTimeUpdate = () => {
    setTime(audioRef.current.currentTime);
    setDuration(audioRef.current.duration);
  };

  const handleEnded = () => {
    setIsPlaying(false);
    setTime(0);
  };

  useEffect(() => {
    const audioElement = audioRef.current;

    const cleanup = () => {
      audioElement.removeEventListener("timeupdate", handleTimeUpdate);
      audioElement.removeEventListener("ended", handleEnded);
      audioElement.removeEventListener("error", () => {});
    };

    audioElement.addEventListener("timeupdate", handleTimeUpdate);
    audioElement.addEventListener("ended", handleEnded);
    audioElement.addEventListener("error", () => {});

    return () => {
      cleanup();
    };
  }, [url]);

  useEffect(() => {
    audioRef.current.src = url;
    setTime(0);
    setDuration(0);
  }, [url]);

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {isPlaying ? (
        <Pause style={{ cursor: "pointer" }} onClick={handlePlayPause} />
      ) : (
        <PlayArrow style={{ cursor: "pointer" }} onClick={handlePlayPause} />
      )}
      <input
        disabled={!audioRef.current.src}
        max={duration || 1}
        onChange={handleSeek}
        step="1"
        style={{ width: "100%" }}
        type="range"
        value={time}
      />
      <audio ref={audioRef} />
    </div>
  );
}
