import React, { forwardRef, useEffect, useState } from "react";

// React router
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";

// Components
import { AvatarGroup } from "@mui/material";

// MUI Components
import MaterialTable from "material-table";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  MenuItem,
  Grid,
  CardActions,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

// Icons
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Settings,
} from "@mui/icons-material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";

// Alert
import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";

const Hours = () => {
  const { t: tGeneral } = useTranslation("general");

  //Hardcode MACHINES
  const machineList = [
    "Machine 1",
    "Machine 2",
    "Machine 3",
    "Machine 4",
    "Machine 5",
  ];
  const locationList = [
    "Location 1",
    "Location 2",
    "Location 3",
    "Location 4",
    "Location 5",
  ];
  const employeeList = [
    "Employee 1",
    "Employee 2",
    "Employee 3",
    "Employee 4",
    "Employee 5",
    "Employee 6",
    "Employee 7",
  ];
  const codeList = ["Code 1", "Code 2", "Code 3", "Code 4", "Code 5"];

  const { idProject } = useParams();
  const navigate = useNavigate();

  const [type, setType] = React.useState("employees");
  const handleType = (event, newType) => {
    setType(newType);
  };

  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const [pageSize, setPageSize] = useState(5);
  const [dataAnnouncement, setDataAnnouncement] = useState([]);
  const columnsEmployees = [
    {
      field: "message",
      title: "CODE",
      width: 50,
    },
    {
      field: "expirationDate",
      title: "Employees",
      width: 250,
    },
  ];
  const columnsMachines = [
    {
      field: "message",
      title: "LOCATION",
      width: 50,
    },
    {
      field: "expirationDate",
      title: "Machines",
      width: 250,
    },
  ];
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  // useEffect(() => {
  //
  //   if (!checked) {
  //
  //   }
  // }, [checked]);

  const tableLocalization = {
    pagination: {
      labelRowsPerPage: `${tGeneral("labelRowsPerPage")}`,
      labelDisplayedRows: `{from}-{to} ${tGeneral("of")} {count}`,
      labelRowsSelect: `${tGeneral("labelRowsSelect")}`,
      firstAriaLabel: `${tGeneral("firstAriaLabel")}`,
      firstTooltip: `${tGeneral("firstTooltip")}`,
      previousAriaLabel: `${tGeneral("previousAriaLabel")}`,
      previousTooltip: `${tGeneral("previousTooltip")}`,
      nextAriaLabel: `${tGeneral("nextAriaLabel")}`,
      nextTooltip: `${tGeneral("nextTooltip")}`,
      lastAriaLabel: `${tGeneral("lastAriaLabel")}`,
      lastTooltip: `${tGeneral("lastTooltip")}`,
    },
    toolbar: {
      searchPlaceholder: tGeneral("search"),
    },
    body: {
      emptyDataSourceMessage: tGeneral("emptyDataSourceMessage"),
    },
  };

  return (
    <Box sx={{ height: 500, width: "100%" }}>
      <Grid container item xs={12} justifyContent="center" alignItems="center">
        <Grid item xs={6} md={9}>
          <CardActions>
            <Typography fontSize="24px">Hours</Typography>
          </CardActions>
        </Grid>

        <Grid item xs={6} md={3}>
          <ToggleButtonGroup
            value={type}
            exclusive
            onChange={handleType}
            aria-label="choose type"
          >
            <ToggleButton value="employees" aria-label="employees">
              EMPLOYEES
            </ToggleButton>
            <ToggleButton value="machines" aria-label="machines">
              MACHINES
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>

      {type == "machines" ? (
        // MACHINES HOURS
        <Box>
          <Grid
            sx={{
              maxWidth: 950,
              maxHeight: 700,
              margin: "0 auto 16px",
              overflowX: "scroll",
              overflowY: "scroll",
            }}
          >
            <table className="">
              <thead>
                <tr>
                  <th>
                    <Typography fontSize="18px">LOCATION</Typography>
                  </th>
                  {machineList.map((machines) => (
                    <th>
                      <Typography fontSize="18px">{machines}</Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {locationList.map((locations) => (
                  <tr>
                    <td>
                      <Typography fontSize="18px">{locations}</Typography>
                    </td>
                    {machineList.map((machines) => (
                      <td>
                        <Grid
                          //sx={{ overflowY: "scroll" }}
                          container
                          item
                          xs={12}
                          md={12}
                          justifyContent="center"
                        >
                          <Grid item xs={12} md={12} marginTop={2}>
                            <Box
                              sx={{
                                display: "flex",
                                m: 1,
                              }}
                            >
                              <TextField
                                margin="dense"
                                variant="outlined"
                                type="text"
                                label="REG"
                                // error={titleError ? true : false}
                                // helpertext={titleHelperText}
                                // defaultValue={currentRfi ? currentRfi.title : ""}
                                onChange={(e) => {
                                  //validateFields(e.target.value, "title");
                                }}
                                //value={title}
                                sx={{ width: "90%" }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </Grid>

          <MaterialTable
            icons={tableIcons}
            columns={columnsMachines}
            data={dataAnnouncement}
            localization={tableLocalization}
            title={date}
            options={{
              actionsColumnIndex: -1,
              exportButton: false,
            }}
            actions={[
              {
                icon: Settings,
                tooltip: "Settings Machines",
                isFreeAction: true,
                onClick: (event) => {
                  navigate(`/project/${idProject}/hoursSettings`);
                },
              },
            ]}
            rowsPerPageOptions={[5, 10, 20]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          />
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                m: 1,
                justifyContent: "space-between",
              }}
            >
              <Button
                sx={{
                  width: "100%",
                  m: 5,
                  color: "white",
                  backgroundColor: "#ffc900",
                  ":hover": {
                    color: "white",
                    backgroundColor: "#d9ab00",
                  },
                }}
                type="submit"
                variant="contained"
                id="createButton"
                onClick=""
              >
                ADD MACHINE HOURS
              </Button>
            </Box>
          </Grid>
        </Box>
      ) : (
        // EMPLOYEES HOURS
        <Box>
          <Grid
            sx={{
              maxWidth: 950,
              maxHeight: 700,
              margin: "0 auto 16px",
              overflowX: "scroll",
              overflowY: "scroll",
            }}
          >
            <table
              className=""
              style={{ borderWidth: "1px", borderStyle: "solid" }}
            >
              <thead>
                <tr>
                  <th>
                    <Typography fontSize="18px">CODE</Typography>
                  </th>
                  {employeeList.map((employee) => (
                    <th>
                      <Typography fontSize="18px">{employee}</Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {codeList.map((code) => (
                  <tr style={{ borderWidth: "1px", borderStyle: "solid" }}>
                    <td>
                      <Typography fontSize="18px">{code}</Typography>
                    </td>
                    {employeeList.map((employee) => (
                      <td>
                        <Grid
                          //sx={{ overflowY: "scroll" }}
                          container
                          item
                          xs={12}
                          md={12}
                          justifyContent="center"
                        >
                          <Grid item xs={12} md={4} marginTop={2}>
                            <TextField
                              margin="dense"
                              variant="outlined"
                              type="text"
                              label="REG"
                              // error={titleError ? true : false}
                              // helpertext={titleHelperText}
                              // defaultValue={currentRfi ? currentRfi.title : ""}
                              onChange={(e) => {
                                //validateFields(e.target.value, "title");
                              }}
                              //value={title}
                              sx={{ width: "90%" }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4} marginTop={2}>
                            <TextField
                              margin="dense"
                              variant="outlined"
                              type="text"
                              label="EXT"
                              // error={titleError ? true : false}
                              // helpertext={titleHelperText}
                              // defaultValue={currentRfi ? currentRfi.title : ""}
                              onChange={(e) => {
                                //validateFields(e.target.value, "title");
                              }}
                              //value={title}
                              sx={{ width: "90%" }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4} marginTop={2}>
                            <TextField
                              margin="dense"
                              variant="outlined"
                              type="text"
                              label="DOU"
                              // error={titleError ? true : false}
                              // helpertext={titleHelperText}
                              // defaultValue={currentRfi ? currentRfi.title : ""}
                              onChange={(e) => {
                                //validateFields(e.target.value, "title");
                              }}
                              //value={title}
                              sx={{ width: "90%" }}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          //sx={{ overflowY: "scroll" }}
                          container
                          item
                          xs={12}
                          md={12}
                          justifyContent="center"
                        >
                          <Grid item xs={12} md={6} marginTop={2}>
                            <TextField
                              margin="dense"
                              variant="outlined"
                              type="text"
                              label="VAC"
                              // error={titleError ? true : false}
                              // helpertext={titleHelperText}
                              // defaultValue={currentRfi ? currentRfi.title : ""}
                              onChange={(e) => {
                                //validateFields(e.target.value, "title");
                              }}
                              //value={title}
                              sx={{ width: "90%" }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} marginTop={2}>
                            <TextField
                              margin="dense"
                              variant="outlined"
                              type="text"
                              label="SIC"
                              // error={titleError ? true : false}
                              // helpertext={titleHelperText}
                              // defaultValue={currentRfi ? currentRfi.title : ""}
                              onChange={(e) => {
                                //validateFields(e.target.value, "title");
                              }}
                              //value={title}
                              sx={{ width: "90%" }}
                            />
                          </Grid>
                        </Grid>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </Grid>
          <MaterialTable
            icons={tableIcons}
            columns={columnsEmployees}
            data={dataAnnouncement}
            localization={tableLocalization}
            title={date}
            options={{
              actionsColumnIndex: -1,
              exportButton: false,
            }}
            actions={[
              {
                icon: Settings,
                tooltip: "Settings Employees",
                isFreeAction: true,
                onClick: (event) => {
                  navigate(`/project/${idProject}/hoursSettings`);
                },
              },
            ]}
            rowsPerPageOptions={[5, 10, 20]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          />
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                display: "flex",
                m: 1,
                justifyContent: "space-between",
              }}
            >
              <Button
                sx={{
                  width: "100%",
                  m: 5,
                  color: "white",
                  backgroundColor: "#ffc900",
                  ":hover": {
                    color: "white",
                    backgroundColor: "#d9ab00",
                  },
                }}
                type="submit"
                variant="contained"
                id="createButton"
                onClick=""
              >
                ADD HOURS
              </Button>
            </Box>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default Hours;
