export const drawingsChecklist = {
  checkList: "检查清单",
  totalCompleted: "已完成总数",
  done: "完成",
  revision: "修订",
  pending: "待处理",
  viewDrawings: "查看图纸",
  hideDrawings: "隐藏图纸",
  link: "链接",
  deleteRowTitle: "您确定要删除此行吗？",
  percentageCompleted: "完成百分比",
};
