/* eslint-disable no-restricted-syntax */
// @ts-check

import moment from "moment";
import Swal from "sweetalert2";

export const colors = [
  { dark: "#c0e5f0", light: "#ddf7fe", name: "Basic Blue" },
  { dark: "#c3d888", light: "#d7e9a1", name: "Golfer's Green" },
  { dark: "#cea0e9", light: "#e3bff8", name: "Productive Purple" },
  { dark: "#dc9e94", light: "#f6cac1", name: "Rosy Red" },
  { dark: "#e9b075", light: "#f5c781", name: "Operational Orange" },
  { dark: "#9caceb", light: "#bdcafb", name: "Blueberry" },
  { dark: "#c2c2c2", light: "#dbdbdb", name: "Get It Done Grey" },
  { dark: "#f0e896", light: "#fef8b6", name: "Mustard" },
  { dark: "#78abd8", light: "#98c8e9", name: "Blissful Blue" },
  { dark: "#d283bb", light: "#eeadde", name: "Magnifying Magenta" },
  { dark: "#f0a1d6", light: "#f3c4e2", name: "Pretty Pink" },
  { dark: "#a2ddbd", light: "#beeed6", name: "Great Green" },
  { dark: "#b3a1f9", light: "#d3c6fb", name: "Prolific Purple" },
  { dark: "#f4b6a8", light: "#f8d4ca", name: "Outstanding Orange" },
  { dark: "#d7b093", light: "#e9c9ae", name: "Beneficial Brown" },
  { dark: "#95bda9", light: "#b1d8c4", name: "Gainful Green" },
];

export const dateFormats = [
  {
    category: "Month-Day-Year",
    options: ["MM/DD/YY", "MMM DD, YY", "MMMM DD, YYYY"],
  },
  {
    category: "Day-Month-Year",
    options: ["DD/MM/YY", "DD MMM YY", "DD MMMM YYYY"],
  },
  {
    category: "Year-Month-Day",
    options: ["YYYY/MM/DD"],
  },
];

export const timestamps = [
  {
    category: "Recommended",
    options: ["Recommended: All Dates", "Recommended: Due Today", "Recommended: Overdue", "Recommended: In Progress"],
  },
  {
    category: "Task Due",
    options: ["Task Due: Within 1 Week", "Task Due: Within 2 Weeks", "Task Due: Within 4 Weeks"],
  },
  {
    category: "Starting",
    options: ["Starting: Within 1 Week", "Starting: Within 2 Weeks", "Starting: Within 4 Weeks"],
  },
];

export const buildAssignees = (dataSource) => {
  const findAssignees = (items) =>
    items.reduce((acc, item) => {
      const a = item.assignees ? acc.concat(item.assignees) : acc;
      const b = item.child ? findAssignees(item.child) : [];
      return a.concat(b);
    }, []);

  const assignees = Array.from(new Map(findAssignees(dataSource).map((assignee) => [assignee.id, assignee])).values());

  assignees.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return assignees;
};

export const buildColor = (value) => {
  if (value >= 0) {
    return "#367420";
  }
  return "#990000";
};

export const computeSplitterSettings = (fullscreenMode, columns, view) => {
  const size = ((fullscreenMode) => {
    if (fullscreenMode) {
      return "large";
    }
    return window.innerWidth >= 1536 ? "large" : "small";
  })(fullscreenMode);

  let position = null;

  if (size === "large") {
    if ((columns.length === 2 && window.innerWidth <= 1920) || (columns.length === 3 && window.innerWidth <= 2400)) {
      position = "100%";
    }

    return {
      position,
      columnIndex: 3 + columns.length * 3,
      separatorSize: 0,
      minimum: null,
      view: "Default",
    };
  }

  return {
    position: "100%",
    columnIndex: -1,
    separatorSize: 0,
    minimum: null,
    view,
  };
};

export const computeTimelineSettings = (timelineViewMode) => {
  if (timelineViewMode === "Day") {
    return {
      bottomTier: {
        count: 1,
        formatter: (date) => {
          return `${date.getDate()}<br/>${new Intl.DateTimeFormat("en-US", { weekday: "short" })
            .format(date)
            .slice(0, 1)}`;
        },
        unit: "Day",
      },
      timelineUnitSize: 35,
      timelineViewMode,
      topTier: {
        format: "MMMM yyyy",
        unit: "Month",
      },
    };
  }
  if (timelineViewMode === "Week") {
    return {
      bottomTier: {
        count: 1,
        unit: "Week",
      },
      timelineUnitSize: 35,
      timelineViewMode,
      topTier: {
        format: "MMMM yyyy",
        unit: "Month",
      },
    };
  }
  return {};
};

export const confirm = async (title, contents, yes, no) => {
  const result = await Swal.fire({
    title,
    text: contents,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: yes,
    cancelButtonText: no,
  });

  return result.isConfirmed;
};

export const isFirst = (dataSource, id) => {
  const findParent = (items) => {
    for (const item of items) {
      if (item.id === id) {
        return items;
      }
      if (item.child) {
        const found = findParent(item.child);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const parent = findParent(dataSource) || dataSource;
  if (parent) {
    return parent[0].id === id;
  }

  return false;
};

export const isLast = (dataSource, id) => {
  const findParent = (items) => {
    for (const item of items) {
      if (item.id === id) {
        return items;
      }
      if (item.child) {
        const found = findParent(item.child);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  const parent = findParent(dataSource) || dataSource;
  if (parent) {
    return parent[parent.length - 1].id === id;
  }

  return false;
};

export const prepareDataSource = (dataSource, filterAssignees, filterColors, filterTimestamp, filterCompleted) => {
  const isDueToday = (date, today) => {
    return (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    );
  };

  const isOverdue = (date, today) => {
    today.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);
    return date < today;
  };

  const isInProgress = (date, today) => {
    today.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);
    return date > today;
  };

  const isWithinWeek = (date, today, weeks) => {
    return Math.abs(date.getTime() - today.getTime()) <= weeks * 7 * 24 * 60 * 60 * 1000;
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return dataSource
    .filter((item) => {
      if (item.type === "group") {
        return true;
      }
      if (item.type === "activity") {
        // filterAssignees
        if (filterAssignees.length > 0) {
          if (!filterAssignees.some((assignee) => item.assignees.map((item) => item.name).includes(assignee))) {
            return false;
          }
        }
        // filterColors
        if (filterColors.length > 0) {
          if (!filterColors.some((color) => color === item.color)) {
            return false;
          }
        }
        // filterTimestamp
        if (filterTimestamp) {
          const scheduledTimesStart = new Date(item.scheduledTimesStart);
          const scheduledTimesEnd = new Date(item.scheduledTimesEnd);
          if (filterTimestamp === "Recommended: All Dates") {
            // pass
          }
          if (filterTimestamp === "Recommended: Due Today") {
            if (!isDueToday(scheduledTimesEnd, today)) {
              return false;
            }
          }
          if (filterTimestamp === "Recommended: Overdue") {
            if (!isOverdue(scheduledTimesEnd, today)) {
              return false;
            }
          }
          if (filterTimestamp === "Recommended: In Progress") {
            if (!isInProgress(scheduledTimesEnd, today)) {
              return false;
            }
          }
          if (filterTimestamp === "Task Due: Within 1 Week") {
            if (!isWithinWeek(scheduledTimesEnd, today, 1)) {
              return false;
            }
          }
          if (filterTimestamp === "Task Due: Within 2 Weeks") {
            if (!isWithinWeek(scheduledTimesEnd, today, 2)) {
              return false;
            }
          }
          if (filterTimestamp === "Task Due: Within 4 Weeks") {
            if (!isWithinWeek(scheduledTimesEnd, today, 4)) {
              return false;
            }
          }
          if (filterTimestamp === "Starting: Within 1 Week") {
            if (!isWithinWeek(scheduledTimesStart, today, 1)) {
              return false;
            }
          }
          if (filterTimestamp === "Starting: Within 2 Weeks") {
            if (!isWithinWeek(scheduledTimesStart, today, 2)) {
              return false;
            }
          }
          if (filterTimestamp === "Starting: Within 4 Weeks") {
            if (!isWithinWeek(scheduledTimesStart, today, 4)) {
              return false;
            }
          }
        }
        // filterCompleted
        if (filterCompleted) {
          if (item.globalProgressProgress === 100) {
            return false;
          }
        }
        return true;
      }
      if (item.type === "milestone") {
        // filterAssignees
        // pass
        // filterColors
        // pass
        // filterTimestamp
        if (filterTimestamp) {
          const date = new Date(item.date);
          if (filterTimestamp === "Recommended: All Dates") {
            // pass
          }
          if (filterTimestamp === "Recommended: Due Today") {
            if (!isDueToday(date, today)) {
              return false;
            }
          }
          if (filterTimestamp === "Recommended: Overdue") {
            if (!isOverdue(date, today)) {
              return false;
            }
          }
          if (filterTimestamp === "Recommended: In Progress") {
            if (!isInProgress(date, today)) {
              return false;
            }
          }
          if (filterTimestamp === "Task Due: Within 1 Week") {
            if (!isWithinWeek(date, today, 1)) {
              return false;
            }
          }
          if (filterTimestamp === "Task Due: Within 2 Weeks") {
            if (!isWithinWeek(date, today, 2)) {
              return false;
            }
          }
          if (filterTimestamp === "Task Due: Within 4 Weeks") {
            if (!isWithinWeek(date, today, 4)) {
              return false;
            }
          }
          if (filterTimestamp === "Starting: Within 1 Week") {
            if (!isWithinWeek(date, today, 1)) {
              return false;
            }
          }
          if (filterTimestamp === "Starting: Within 2 Weeks") {
            if (!isWithinWeek(date, today, 2)) {
              return false;
            }
          }
          if (filterTimestamp === "Starting: Within 4 Weeks") {
            if (!isWithinWeek(date, today, 4)) {
              return false;
            }
          }
        }
        // filterCompleted
        if (filterCompleted) {
          if (item.bComplete) {
            return false;
          }
        }
        return true;
      }
      return true;
    })
    .map((item) => {
      if (item.child && item.child.length > 0) {
        return {
          ...item,
          child: prepareDataSource(item.child, filterAssignees, filterColors, filterTimestamp, filterCompleted),
          progressTaskLabel: item.progress.toFixed(0),
        };
      }
      return {
        ...item,
        progressTaskLabel: item.progress.toFixed(0),
      };
    });
};

export const queryTaskbarInfo = (args) => {
  if (args.data.taskData.type === "milestone") {
    // eslint-disable-next-line no-param-reassign
    args.progressBarBgColor = "#c2c2c2";
    // eslint-disable-next-line no-param-reassign
    args.taskbarBgColor = "#d7d7de";
    // eslint-disable-next-line no-param-reassign
    args.taskbarBorderColor = "#c2c2c2";
    return;
  }
  const color = colors.find((color) => color.name === args.data.taskData.color);
  // eslint-disable-next-line no-param-reassign
  args.progressBarBgColor = color ? color.dark : "#c2c2c2";
  // eslint-disable-next-line no-param-reassign
  args.taskbarBgColor = color ? color.light : "#d7d7de";
  // eslint-disable-next-line no-param-reassign
  args.taskbarBorderColor = color ? color.dark : "#c2c2c2";
};

export const renderCurrency = (value) => {
  return new Intl.NumberFormat("en-US", {
    currency: "USD",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: "currency",
  }).format(value);
};

export const renderDate = (date, format) => {
  return moment(date).utc().format(format);
};

export const renderPercent = (value) => {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
    style: "percent",
  }).format(value / 100);
};

export const rowDataBound = (args) => {
  if (args.data.taskData.type === "group") {
    // eslint-disable-next-line no-param-reassign
    args.row.style.backgroundColor = "#fff5db";
  }
};

export const underConstruction = (title) => {
  const Toast = Swal.mixin({
    position: "center",
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
    toast: true,
  });
  Toast.fire({
    icon: "success",
    title,
  });
};
