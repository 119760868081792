import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  darkMode: false,
  locale: "off",
};

export const preferencesSlice = createSlice({
  name: "preferences",
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      state.darkMode = !state.darkMode;
    },
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
    },
    setLocale: (state, action) => {
      state.locale = action.payload;
    },
    resetPreferences: (state) => {
      state.locale = "off";
    },
  },
});

export const selectTheme = (state) => state.preferences.darkMode;
export const selectLocale = (state) => state.preferences.locale;

export const { toggleDarkMode, setDarkMode, setLocale, resetPreferences } =
  preferencesSlice.actions;

export default preferencesSlice.reducer;
