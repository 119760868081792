import { tour } from "./tour";

export const meetings = {
  // general tour
  ...tour,
  moduleElement: "ミーティング",
  // ADD - guide me steps
  step1_addMeeting_title: "1. ミーティングを追加する。",
  step1_addMeeting_text:
    "新しいミーティングを追加するには、+ アイコンをクリックします。",
  step2_addMeeting_title: "2. タイトル",
  step2_addMeeting_text: "ミーティングのタイトルを入力します。",
  step3_addMeeting_title: "3. プライバシー",
  step3_addMeeting_text: "ミーティングを誰に公開するかを選択します。",
  step3_addMeeting_text_2:
    "モジュールにアクセス権があるすべての人がミーティングを見ることができます。",
  step3_addMeeting_text_3: "参加者のみがこのミーティングを見ることができます。",
  step4_addMeeting_title: "4. ミーティング日時",
  step4_addMeeting_text: "ミーティングの日時を設定します。",
  step5_addMeeting_title: "5. 場所",
  step5_addMeeting_text: "ミーティングの場所を決定します。",
  step6_addMeeting_title: "6. ミーティングURL",
  step6_addMeeting_text: "ミーティングのURLをここに入力します。",
  step7_addMeeting_title: "7. ステータス",
  step7_addMeeting_text_1_1: "ステータスを選択する",
  step7_addMeeting_text_1_2: "参加者に公開された状態を選択する",
  step7_addMeeting_text_2_1: "ステータスを選択する",
  step7_addMeeting_text_2_2: "下書きとして保存する",
  step8_addMeeting_title: "8. 参加者",
  step8_addMeeting_text: "ミーティングに必要な人々を選択します。",
  step9_addMeeting_title: "9. 配布リスト",
  step9_addMeeting_text: "ミーティングの通知を受け取る人々を選択します。",
  step10_addMeeting_title: "10. 新しいアイテム",
  step10_addMeeting_text: "ミーティングに新しいアイテムを追加します。",
  step11_addMeeting_title: "11. アイテム名",
  step11_addMeeting_text: "アイテムの名前を入力します。",
  step12_addMeeting_title: "12. 議題",
  step12_addMeeting_text:
    "ミーティングのアイテムで議論するトピックを入力します。",
  step13_addMeeting_title: "13. 時間",
  step13_addMeeting_text: "アイテムの予想時間を決定します。",
  step14_addMeeting_title: "14. ファイル",
  step14_addMeeting_text:
    "ミーティングに関連するファイルをアップロードします。",
  step15_addMeeting_title: "15. 追加",
  step15_addMeeting_text: "新しいアイテムを確認するためにクリックします",
  step16_addMeeting_title: "16. 作成",
  step16_addMeeting_text: "ミーティングを作成するにはここをクリックします。",
  step_final_addMeeting: "ミーティングの作成方法を学びました。",
  // VIEW - guide me steps
  step1_viewMeeting_title: "1. ミーティングを表示する。",
  step1_viewMeeting_text: "ミーティングを開くにはクリックします。",
  step2_viewMeeting_title: "2. 情報",
  step2_viewMeeting_text:
    "このセクションでは、ミーティングの概要情報を表示できます。",
  step3_viewMeeting_title: "3. URL",
  step3_viewMeeting_text:
    "URLを開くにはここをクリックするか、右側のアイコンをクリックしてクリップボードにコピーします。",
  step4_viewMeeting_title: "4. アジェンダ",
  step4_viewMeeting_text:
    "このセクションにはアジェンダの情報が含まれています。",
  step5_viewMeeting_title: "5. アイテム",
  step5_viewMeeting_text: "アイテムをクリックしてその内容を表示します。",
  step6_viewMeeting_title: "6. トピック",
  step6_viewMeeting_text: "トピックをクリックしてその内容を表示します。",
  step7_viewMeeting_title: "7. コメント",
  step7_viewMeeting_text:
    "このセクションでは、トピックに関するコメントを追加できます。",
  step8_viewMeeting_title: "8. タスク",
  step8_viewMeeting_text:
    "トピックの下に作成されたタスクはここに表示されます。",
  step_final_viewMeeting: "ミーティングの情報を表示する方法を学びました。",
  // EDIT - guide me steps
  step1_editMeeting_title: "1. ミーティングを編集する。",
  step1_editMeeting_text: "ミーティングを編集するにはここをクリックします。",
  step2_editMeeting_title: "2. タイトル",
  step2_editMeeting_text: "ミーティングのタイトルを編集します。",
  step3_editMeeting_title: "3. プライバシー",
  step3_editMeeting_text: "ミーティングのプライバシーを編集します。",
  step3_editMeeting_text_2:
    "モジュールにアクセス権があるすべての人がミーティングを見ることができます。",
  step3_editMeeting_text_3:
    "参加者のみがこのミーティングを見ることができます。",
  step4_editMeeting_title: "4. ミーティング日時",
  step4_editMeeting_text: "ミーティングの日時を編集します。",
  step5_editMeeting_title: "5. 場所",
  step5_editMeeting_text: "ミーティングの場所を編集します。",
  step6_editMeeting_title: "6. ミーティングURL",
  step6_editMeeting_text: "ミーティングのURLを編集します。",
  step7_editMeeting_title: "7. ミーティングステータス",
  step7_editMeeting_text_1_1: "ステータスを選択する",
  step7_editMeeting_text_1_2: "参加者に公開された状態を選択する",
  step7_editMeeting_text_2_1: "ステータスを選択する",
  step7_editMeeting_text_2_2: "下書きとして保存する",
  step8_editMeeting_title: "8. 参加者",
  step8_editMeeting_text: "ミーティングに必要な人々を追加または削除します。",
  step9_editMeeting_title: "9. 配布リスト",
  step9_editMeeting_text: "ミーティングの通知を受け取る人々を編集します。",
  step10_editMeeting_title: "10. アイテム名",
  step10_editMeeting_text: "アイテムの名前を編集します。",
  step11_editMeeting_title: "11. 議論トピック",
  step11_editMeeting_text: "アイテムの議論トピックを編集します。",
  step12_editMeeting_title: "12. 予想時間",
  step12_editMeeting_text: "アイテムの予想時間を編集します。",
  step13_editMeeting_title: "13. ファイル",
  step13_editMeeting_text:
    "ミーティングに関連するファイルを編集または追加します。",
  step14_editMeeting_title: "14. 新しいアイテム",
  step14_editMeeting_text: "ミーティングに新しいアイテムを追加します。",
  step15_editMeeting_title: "15. 保存",
  step15_editMeeting_text:
    "ミーティングの変更を保存するにはここをクリックします。",
  step_final_editMeeting: "ミーティングの編集方法を学びました。",
  // RECYCLE - guide me steps
  step1_recycleMeeting_title: "2. ミーティングのごみ箱",
  step1_recycleMeeting_text:
    "ミーティングのごみ箱を開くにはここをクリックします。",
  step2_recycleMeeting_title: "3. ごみ箱メニュー",
  step2_recycleMeeting_text: "これはミーティングのごみ箱メニューです。",
  step3_recycleMeeting_title: "4. ミーティングを表示する",
  step3_recycleMeeting_text:
    "復元したいミーティングの情報を表示するにはここをクリックします。",
  step4_recycleMeeting_title: "5. ミーティングを選択する",
  step4_recycleMeeting_text: "復元したいミーティングを選択します。",
  step5_recycleMeeting_title: "6. 選択したミーティングを復元する",
  step5_recycleMeeting_text:
    "選択したミーティングを復元するにはここをクリックします。",
  step_final_recycleMeeting: "ミーティングのごみ箱とのやり取り方を学びました。",
  // SETTINGS - guide me steps
  step1_settingsMeeting_title: "2. 設定メニューを開く",
  step1_settingsMeeting_text: "設定メニューを開くにはここをクリックします。",
  step2_settingsMeeting_title: "3. 配布リスト",
  step2_settingsMeeting_text:
    "ミーティングモジュールへのデフォルトアクセス権を持つ人々を追加または削除します。",
  step3_settingsMeeting_title: "4. 保存",
  step3_settingsMeeting_text: "設定を保存するにはここをクリックします。",
  step_final_settingsMeeting: "設定の調整方法を学びました。",
  // EXPORT - guide me steps
  step1_exportMeeting_title: "1. ミーティングをエクスポートする",
  step1_exportMeeting_text:
    "メールでミーティングの情報をエクスポートするにはここをクリックします。",
  step_final_exportMeeting: "ミーティングのエクスポート方法を学びました。",

  meetings: "ミーティング",
  addMeeting: "ミーティングをスケジュールする",
  deleteMeeting: "ミーティングを削除する",
  messageDelete:
    "選択したミーティングを削除してもよろしいですか？この操作は元に戻すことはできません。",
  meetingDeleted: "ミーティングが正常に削除されました",
  errorMeetingDeleted: "ミーティングを削除できません",
  meetingRecovered: "ミーティングが正常に復元されました",
  errorMeetingRecovered: "ミーティングを復元できません",
  meetingsRecycleBin: "ミーティングのごみ箱",
  meetingCreated: "ミーティングが正常に作成されました",
  errorMeetingCreated: "ミーティングを作成できません",
  meetingUpdated: "ミーティングが正常に更新されました",
  errorMeetingUpdated: "ミーティングを更新できません",
  editMeeting: "ミーティングを編集する",
  urlMeeting: "ミーティングのURL",
  agenda: "アジェンダ",
  meetingStarted: "ミーティングが正常に開始されました",
  errorMeetingStarted: "現在のミーティングを開始できません",
  meetingClosed: "ミーティングが正常に終了しました",
  errorMeetingClosed: "ミーティングを終了できません",
  startMeeting: "ミーティングを開始する",
  noAgendaItems: "アジェンダアイテムがありません",
  closeMeeting: "ミーティングを終了する",
  noElementsTitle: "始める時間です！",
  noElementsSubtitle:
    "次のタスクを計画するために最初のミーティングを作成しませんか？開始するには下のボタンをクリックしてください。",
  newAgendaItem: "新しいアジェンダアイテム",
  topicsToDiscuss: "議論するトピック",
  attachOrDrop: "ファイルを添付またはドラッグアンドドロップ",
  viewFiles: "ファイルを表示",
  noTopics: "トピックがありません",
  taskSuccessfullyDeleted: "タスクが正常に削除されました",
  taskCantDelete: "タスクを削除できません",
  taskName: "タスク名",
  newTask: "新しいタスク",
  deleteTask: "タスクを削除する",
  deleteTaskQuestion: "このタスクを削除してもよろしいですか？",
  noMeetings: "ごみ箱にミーティングはありません",
  confirmedAssistance: "出席が確認されました",
};
