export const payment = {
  paymentMethod: "Payment method",
  invoiceDetails: "Invoice details",
  addNewCard: "Add new card",
  addData: "Add info",
  orderSummary: "Order summary",
  pricePerProject: "Price per project",
  pricePerUser: "Price per user",
  projectsNumber: "Projects number",
  usersNumber: "Users number",
  totalPerYear: "Total per year",
  totalPerMonth: "Total per month",
  agreement:
    "I understand that my invoices will be based on my number of users. Licenses can be added by inviting users to my account or projects after payment. Users can be removed at any time.",
  editCard: "Edit card",
  emailRecipient: "Email recipient",
  companyName: "Company name",
  addCard: "Add Card",
  cardNumber: "Card number",
};
