import { apiSlice } from "../../../../api/apiSlice";

const objectToQueryString = (object) => {
  const queryString = Object.keys(object)
    .map((key) => {
      let value = object[key];
      if (!value) {
        return "";
      }
      if (typeof value === "object") {
        value = value.join(",");
      }

      return `${key}=${value}`;
    })
    .filter((param) => param.length)
    .join("&");
  return queryString.length ? `?${queryString}` : "";
};

export const projectFolderApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    // Get ProjectFolders by idProject
    getProjectFolders: builder.query({
      query: ({ idProject, idFolder, search }) =>
        `/project/projectFolder/getProjectFolders/${idProject}${objectToQueryString(
          {
            idFolder,
            search,
          },
        )}`,
      keepUnusedDataFor: 0,
      providesTags: ["ProjectFolder"],
    }),
    getProjectFolderDownload: builder.mutation({
      query: (body) => ({
        url: "project/projectFolder/download",
        method: "POST",
        body,
      }),
    }),
    // Get TreeView
    getTreeView: builder.query({
      query: (idProject) => `/project/projectFolder/getTreeView/${idProject}`,
      keepUnusedDataFor: 0,
      providesTags: ["ProjectFolder"],
    }),
    // Get Project Folder by folder id
    getProjectFolder: builder.query({
      query: (id) => `/project/projectFolder/getProjectFolder/${id}`,
      keepUnusedDataFor: 0,
      providesTags: ["ProjectFolder"],
    }),
    // Get Deleted Project folders and documents by idProject
    getDeletedProjectFoldersAndDocuments: builder.query({
      query: (idProject) =>
        `/project/projectFolder/getDeletedProjectFoldersAndDocuments/${idProject}`,
      keepUnusedDataFor: 0,
      providesTags: ["ProjectFolder"],
    }),
    // Get Favorited Project folders and documents by idProject
    getFavoriteProjectFoldersAndDocuments: builder.query({
      query: (idProject) =>
        `/project/projectFolder/getFavoriteProjectFoldersAndDocuments/${idProject}`,
      keepUnusedDataFor: 0,
      providesTags: ["ProjectFolder"],
    }),
    // Create Project Folder
    createProjectFolder: builder.mutation({
      query: (body) => ({
        url: "project/projectFolder/createProjectFolder",
        method: "POST",
        body,
      }),
      invalidatesTags: ["ProjectFolder", "Documents"],
    }),
    // Delete Project Folder
    deleteProjectFolder: builder.mutation({
      query: (body) => ({
        url: "project/projectFolder/deleteProjectFolder",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ProjectFolder", "Documents"],
    }),
    // Restore Project Folder
    restoreProjectFolder: builder.mutation({
      query: (body) => ({
        url: "project/projectFolder/restoreProjectFolder",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ProjectFolder"],
    }),
    // Edit Project Folder
    editProjectFolder: builder.mutation({
      query: (body) => ({
        url: "project/projectFolder/editProjectFolder",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ProjectFolder", "Documents"],
    }),
    // Move Project Folder
    moveFolder: builder.mutation({
      query: (body) => ({
        url: "project/projectFolder/moveFolder",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ProjectFolder", "Documents"],
    }),
    getFolderPath: builder.query({
      query: (idFolder) => `/project/projectFolder/folderPath/${idFolder}`,
    }),
  }),
});

export const {
  useGetProjectFoldersQuery,
  useGetTreeViewQuery,
  useGetProjectFolderQuery,
  useGetDeletedProjectFoldersAndDocumentsQuery,
  useGetFavoriteProjectFoldersAndDocumentsQuery,
  useCreateProjectFolderMutation,
  useDeleteProjectFolderMutation,
  useRestoreProjectFolderMutation,
  useEditProjectFolderMutation,
  useMoveFolderMutation,
  useGetProjectFolderDownloadMutation,
  useLazyGetFolderPathQuery,
} = projectFolderApiSlice;
