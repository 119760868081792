import { Add, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Drawer,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";

function ChecklistItem({ item, onEdit }) {
  const { t: tTemplates } = useTranslation("templates");
  const [value, setValue] = useState();

  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setValue(item.title);
  }, [item]);

  const handleChange = (newVal) => {
    onEdit(newVal);
    setEditing(false);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleChange(e.target.value);
    }
  };

  return (
    <Box display="flex" height="2rem" alignItems="center">
      {editing || item.title === "" ? (
        <TextField
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleEnter}
          onBlur={(e) => handleChange(e.target.value)}
          size="small"
          placeholder={tTemplates("addSubitem")}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        />
      ) : (
        <Typography component="div" onDoubleClick={() => setEditing(true)}>
          {item.title}
        </Typography>
      )}
    </Box>
  );
}

function Checklist({ isOpen, onClose, elementIndex, disciplineIndex }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tTemplates } = useTranslation("templates");
  const { watch, control, setValue } = useFormContext();

  const { prepend, update, remove } = useFieldArray({
    control,
    name: `disciplines[${disciplineIndex}].elements[${elementIndex}].checklist.items`,
  });

  const disciplineName = watch(`disciplines[${disciplineIndex}].name`);

  const handleAddItem = () => {
    prepend({ id: v4(), title: "" });
  };

  const fields = watch(
    `disciplines[${disciplineIndex}].elements[${elementIndex}].checklist.items`,
  );

  const checklist = watch(
    `disciplines[${disciplineIndex}].elements[${elementIndex}].checklist`,
  );

  const handleClose = () => {
    fields.forEach((f, i) => {
      if (f.title === "") {
        remove(i);
      }
    });
    onClose();
  };

  return (
    <Drawer
      open={isOpen}
      onClose={handleClose}
      anchor="right"
      sx={{
        "& .MuiPaper-root": {
          margin: "0.875rem 0.875rem 0 0",
          borderRadius: "1.5rem",
          height: "95%",
          width: "35%",
          p: "1rem 1.5rem",
        },
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" columnGap="0.5rem">
          <Typography fontWeight={500} color="#8E8E8E">
            {tGeneral("at")}
          </Typography>
          <Typography
            fontWeight={500}
            color="#C68D08"
            sx={{ textDecoration: "underline" }}
          >
            {disciplineName}
          </Typography>
        </Box>
        <Button sx={{ color: "#8E8E8E" }} onClick={onClose}>
          <Close />
        </Button>
      </Box>
      <Box display="flex" columnGap="1rem" alignItems="center">
        <Typography color="#8E8E8E" width="100px">
          {tGeneral("category")}:
        </Typography>
        <Select
          size="small"
          value={checklist.category}
          onChange={(e) =>
            setValue(
              `disciplines[${disciplineIndex}].elements[${elementIndex}].checklist.category`,
              e.target.value,
            )
          }
          sx={{ width: "120px" }}
        >
          <MenuItem value="required">{tGeneral("required")}</MenuItem>
          <MenuItem value="notRequired">{tGeneral("notRequired")}</MenuItem>
        </Select>
      </Box>
      <Box display="flex" columnGap="1rem">
        <Typography color="#8E8E8E" width="100px">
          {tGeneral("createdAt")}:
        </Typography>
        <Typography>
          {moment(checklist.createdDate).format("DD, MMM, YY")}
        </Typography>
      </Box>
      <Divider />
      <Typography color="#C68D08" fontWeight={500} p="0.25rem 0.5rem">
        {tGeneral("list")}
      </Typography>
      <Divider />
      <Button
        sx={{
          textTransform: "none",
          color: "#4B4B4B",
          justifyContent: "flex-start",
        }}
        onClick={handleAddItem}
      >
        <Add />
        <Typography>{tTemplates("addSubitem")}</Typography>
      </Button>
      {fields?.map((f, i) => (
        <React.Fragment key={f.id}>
          <ChecklistItem
            item={f}
            onEdit={(newTitle) => update(i, { id: f.id, title: newTitle })}
          />
          {i < (fields?.length ?? 1) - 1 && <Divider />}
        </React.Fragment>
      ))}
    </Drawer>
  );
}

export { Checklist };
