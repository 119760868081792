import { Delete } from "@mui/icons-material";
import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../../constants";

function EditDesignDisciplineMenu({ anchorEl, discipline, onClose }) {
  const { t: tGeneral } = useTranslation();
  const [name, setName] = useState(discipline?.label);
  const [color, setColor] = useState(discipline?.color);

  const { getValues, control } = useFormContext();
  const { update } = useFieldArray({ control, name: "disciplines" });

  const handleClose = () => {
    const disciplines = getValues("disciplines");
    const index = disciplines.findIndex((d) => d.id === discipline.id);
    update(index, { ...discipline, label: name, color });
    onClose();
  };

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
      <TextField
        value={name}
        onChange={(e) => setName(e.target.value)}
        size="small"
      />
      <MenuItem>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        <ListItemText>{tGeneral("delete")}</ListItemText>
      </MenuItem>
      <Divider />
      {tGeneral("colors")}
      {COLORS.map((c) => (
        <MenuItem
          selected={c.color === color}
          onClick={() => setColor(c.color)}
        >
          <ListItemIcon>
            <Box
              sx={{
                backgroundColor: c.color,
                width: "20px",
                height: "20px",
                borderRadius: "0.125rem",
              }}
            />
          </ListItemIcon>
          <ListItemText>{tGeneral(c.name)}</ListItemText>
        </MenuItem>
      ))}
    </Menu>
  );
}

export { EditDesignDisciplineMenu };
