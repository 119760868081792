export const submittals = {
    submittal: "Submittal",
    submittalTitleModule: "Submittals",
    deleteSubmittalsTitle: "Eliminar submittals",
    deleteSubmittalsContent: "¿Estás seguro que deseas borrar estos submittals?",
    addSubmittal: "Agregar submittal",
    noSubmittalsTitle: "No se han creado Submittals hasta el momento",
    addSubmittalsValidation: "Si deseas agregar uno, te invitamos a dar click en el botón Agregar Submittal para comenzar con el proceso",
    submittalRecycleBin: "Papelera (Submittals)",
    costCode: "Código costo",
};