import { Close, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import BubbleAvatarDeletable from "../../userAvatars/BubbleAvatarDeletable";

export default function TaskSettingsModal({
  isOpen,
  onClose,
  users,
  days,
  selectedUsers,
  updateConfig,
}) {
  // Translation
  const { t: tGeneral } = useTranslation("general");
  const { t: tTasks } = useTranslation("tasks");

  const [taskDays, setTaskDays] = useState(days);
  const [distributionList, setDistributionList] = useState([]);

  useEffect(() => {
    if (selectedUsers) {
      setDistributionList(selectedUsers.map(mapUserItems));
    }
  }, [selectedUsers]);

  const mapUserItems = (user) => ({
    label: `${user.user?.firstName} ${user.user?.lastName}`,
    id: user.user?.id,
    name: `${user.user?.firstName} ${user.user?.lastName}`,
    urlAvatar: user.user?.urlAvatar,
  });

  const projectUsers = useMemo(() => {
    if (users) {
      return users.map(mapUserItems);
    }
    return [];
  }, [users]);

  const handleOnDelete = (val) => {
    setDistributionList((prev) => prev.filter((user) => user.id !== val.id));
  };

  const handleOnSave = () => {
    updateConfig(
      distributionList.map((user) => user.id),
      taskDays,
    );
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      disableScrollLock
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={(theme) => ({
          height: "auto",
          borderRadius: "20px",
          p: "25px 40px",
          width: "30%",
          [theme.breakpoints.down("lg")]: {
            width: "50%",
          },
        })}
      >
        <Box display="flex" alignItems="center">
          <Typography sx={{ flex: 1 }} fontSize="1.5625rem" fontWeight="bold">
            {tGeneral("settings")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close fontSize="1.5625rem" />
          </IconButton>
        </Box>
        <Divider sx={{ my: "20px" }} />
        <TextField
          label={tTasks("days")}
          value={taskDays}
          inputMode="numeric"
          fullWidth
          onChange={(event) => {
            const numericRegex = /^[0-9]+$/;
            if (
              numericRegex.test(event.target.value) ||
              event.target.value === ""
            ) {
              setTaskDays(event.target.value);
            }
          }}
          sx={{ mb: "20px" }}
          data-tour="settings-tasks-2"
        />
        <Autocomplete
          multiple
          options={projectUsers}
          disableCloseOnSelect
          value={distributionList}
          onChange={(e, val) => setDistributionList(val)}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox checked={selected} />
              <Typography>{option.label}</Typography>
            </li>
          )}
          renderInput={(params) => (
            <Box ref={params.InputProps.ref}>
              <TextField
                {...params.inputProps}
                fullWidth
                label={tGeneral("distributionList")}
                sx={{ padding: 0, height: "55px" }}
                InputProps={{
                  endAdornment: <Search />,
                  sx: { height: "55px", padding: "14px !important" },
                }}
              />
            </Box>
          )}
          data-tour="settings-tasks-3"
        />
        <Box sx={{ maxHeight: "250px", overflow: "auto", mt: "16px" }}>
          {distributionList.length ? (
            distributionList?.map((user) => (
              <BubbleAvatarDeletable
                key={user.id}
                user={user}
                editable={true} //To show delete button
                setValues={() => {
                  handleOnDelete(user);
                }}
              />
            ))
          ) : (
            <Typography fontSize="1rem" fontWeight="medium" textAlign="center">
              {tGeneral("noUsersSelected")}
            </Typography>
          )}
        </Box>
        <Box display="flex" columnGap="16px" mt="30px">
          <Button
            variant="contained"
            color="secondary"
            sx={{
              backgroundColor: "white",
              color: "black",
              py: "1rem",
              border: "1px solid black",
            }}
            fullWidth
            onClick={onClose}
          >
            {tGeneral("cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ py: "1rem" }}
            onClick={handleOnSave}
            data-tour="settings-tasks-4"
          >
            {tGeneral("save")}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}
