import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PopUpAlert } from "../components/PopUpAlert";
import {
  useGetActiveProjectUsersQuery,
  useGetProjectConfigurationQuery,
  useUpdateProjectConfigMutation,
} from "../features/project/projectApiSlice";

function useDistributionList(idProject, moduleName) {
  const { data: projectConfig } = useGetProjectConfigurationQuery(idProject);
  const { data: projectUsers } = useGetActiveProjectUsersQuery({
    idProject,
    moduleName,
  });

  const { t: tPhotos } = useTranslation("photos");
  const { t: tGeneral } = useTranslation("general");

  const [isDistributionListOpen, setIsDistributionListOpen] = useState(false);

  const [
    updateConfig,
    { isSuccess: isUpdateConfigSuccess, isError: isUpdateConfigError },
  ] = useUpdateProjectConfigMutation();

  useEffect(() => {
    if (isUpdateConfigSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tPhotos("projectConfigurationUpdate"),
      );
      handleCloseDistributionList();
    }
    if (isUpdateConfigError) {
      PopUpAlert("error", "Error!", tGeneral("errorOccurred"));
      handleCloseDistributionList();
    }
  }, [isUpdateConfigSuccess, isUpdateConfigError]);

  const handleOpenDistributionList = () => {
    setIsDistributionListOpen(true);
  };
  const handleCloseDistributionList = () => {
    setIsDistributionListOpen(false);
  };
  const getSelectedUsers = (distributionList) => {
    if (projectConfig) {
      return projectUsers?.filter(
        (user) => projectConfig[distributionList]?.indexOf(user?.user?.id) > -1,
      );
    }
    return [];
  };

  const handleUpdateConfig = async (value, updating) => {
    const body = { idProject };
    body[updating] = value;

    await updateConfig(body).unwrap();
  };

  return {
    projectConfig,
    projectUsers,
    handleOpenDistributionList,
    handleCloseDistributionList,
    isDistributionListOpen,
    getSelectedUsers,
    handleUpdateConfig,
  };
}

export { useDistributionList };
