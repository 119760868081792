// eslint-disable-next-line import/prefer-default-export
export const projectSchedule = {
  "% Complete": "Completado",
  "$ Project Cost": "$ Costo del Proyecto",
  "Add New": "Añadir Nuevo",
  "All Colors": "Todos los Colores",
  "All Dates": "Todas las Fechas",
  "Are you sure you want to delete the activity": "¿Está seguro de querer eliminar la actividad?",
  "Are you sure you want to delete the group": "¿Está seguro de querer eliminar el grupo?",
  "Are you sure you want to delete the milestone": "¿Está seguro de querer eliminar el hito?",
  "Are you sure?": "¿Está seguro?",
  "Basic Blue": "Azul Básico",
  "Beneficial Brown": "Marrón beneficioso",
  "Blissful Blue": "Azul dichoso",
  "Choose color": "Elige color",
  "Click here": "haga clic aquí",
  "Copy Link": "Copiar enlace",
  "Create Activity": "Crear Actividad",
  "Create Group": "Crear Grupo",
  "Create Milestone": "Crear Hito",
  "Create Roadblock": "Crear Bloqueo",
  "Daily Reports": "Reportes",
  "change(s)": "cambio(s)",
  "Date Format:": "Formato de Fecha:",
  "Day-Month-Year": "Día-Mes-Año",
  "Default Color": "Color Predeterminado",
  "Delete Activity": "Eliminar Actividad",
  "Delete Group": "Eliminar Grupo",
  "Delete Milestone": "Eliminar Hito",
  "Dependent On": "Dependiente De",
  "Depends On": "Depende De",
  "Due Date": "Fecha de Vencimiento",
  "Due Today": "Vencimiento hoy",
  "End Date": "Fecha de Fin",
  "Expand All Groups": "Expandir Todos los Grupos",
  "Export to Excel": "Exportar a Excel",
  "Hide": "Esconder",
  "Gainful Green": "Verde lucrativo",
  "Get It Done Grey": "Hazlo gris",
  "Golfer's Green": "Verde del golfista",
  "Great Green": "Gran verde",
  "Hide Completed": "Ocultar Completados",
  "Import from Excel": "Importar de Excel",
  "Import or export": "Importar o exportar",
  "In Progress": "En progreso",
  "Invite people": "Invitar personas",
  "made": "realizó",
  "Magnifying Magenta": "Magenta de aumento",
  "Month-Day-Year": "Mes-Día-Año",
  "No other activity depends on this activity.": "Ninguna otra actividad depende de esta.",
  "No records to display": "No hay registros para mostrar",
  "Operational Orange": "Naranja Operacional",
  "Outstanding Orange": "Naranja excepcional",
  "PDF Settings": "Configuración de PDF",
  "Pretty Pink": "bastante rosa",
  "Productive Purple": "Púrpura productivo",
  "Project Cost": "Costo del Proyecto",
  "Project Schedule": "Programa de Obra",
  "Prolific Purple": "Púrpura prolífico",
  "Real Duration": "Duración Real",
  "Real Finish Date": "Fecha de finalización real",
  "Real Finish": "Acabado real",
  "Real Start Date": "Fecha de Inicio Real",
  "Real Start": "Inicio Real",
  "Real Times": "Tiempos Reales",
  "Recommended: All Dates": "Recomendado: Todas las fechas",
  "Recommended: Due Today": "Recomendado: Vencimiento hoy",
  "Recommended: In Progress": "Recomendado: En progreso",
  "Recommended: Overdue": "Recomendado: Vencido",
  "Rosy Red": "rojo rosado",
  "Scheduled Duration": "Duración Programada",
  "Scheduled End Date": "Fecha de Fin Programada",
  "Scheduled End": "Final Programado",
  "Scheduled Start Date": "Fecha de Inicio Programada",
  "Scheduled Start": "Inicio Programado",
  "Scheduled Times": "Tiempos Programados",
  "Select a type": "Seleccione un tipo",
  "Select Assignees": "Seleccionar Asignados",
  "Select Colors": "Seleccionar Colores",
  "Show Completed": "Mostrar Completados",
  "Size": "Tamaño",
  "Start Date": "Fecha de Inicio",
  "Starting: Within 1 Week": "Inicio: Dentro de 1 semana",
  "Starting: Within 2 Weeks": "Inicio: Dentro de 2 semanas",
  "Starting: Within 4 Weeks": "Inicio: Dentro de 4 semanas",
  "Submitting...": "Enviando...",
  "to download the file and review the errors in the last column." : "para descargar el archivo y revisar los errores en la última columna.",
  "to download the sample file. Your uploaded file must be based on this template and adhere to the outlined requirements.": "para descargar el archivo de muestra. El archivo subido debe basarse en esta plantilla y cumplir con los requisitos descritos.",
  "Task Due": "Tarea vencida",
  "Task Due: Within 1 Week": "Tarea vencida: Dentro de 1 semana",
  "Task Due: Within 2 Weeks": "Tarea vencida: Dentro de 2 semanas",
  "Task Due: Within 4 Weeks": "Tarea vencida: Dentro de 4 semanas",
  "There are no comments in this activity.": "No hay comentarios en esta actividad.",
  "There are no comments in this group.": "No hay comentarios en este grupo.",
  "There are no dependents/depedencies of this activity.": "No hay dependientes/dependencias de esta actividad.",
  "There are no histories in this group.": "No hay historiales en este grupo.",
  "There are no items in this module.": "No hay elementos en este módulo.",
  "There are no more daily reports available.": "No hay más reportes disponibles.",
  "There are no more documents available.": "No hay más documentos disponibles.",
  "There are no more drawings available.": "No hay más planos disponibles.",
  "There are no more inspections available.": "No hay más inspecciones disponibles.",
  "There are no more meetings available.": "No hay más reuniones disponibles.",
  "There are no more RFIs available.": "No hay más RFIs disponibles.",
  "There are no more tasks available.": "No hay más tareas disponibles.",
  "There is history in this activity.": "Hay historial en esta actividad.",
  "There is history in this group.": "Hay historial en este grupo.",
  "This activity does noes depend on any other activity.": "Esta actividad no depende de ninguna otra actividad.",
  "This feature is under construction.": "Esta función está en construcción.",
  "Today": "Hoy",
  "Update Roadblock": "Actualizar Obstáculo",
  "Within 1 Week": "Dentro de 1 semana",
  "Within 2 Weeks": "Dentro de 2 semanas",
  "Within 4 Weeks": "Dentro de 4 semanas",
  "Year-Month-Day": "Año-Mes-Día",
  "You do not have the appropriate permission to perform this action.": "No tiene el permiso adecuado para realizar esta acción.",
  "Your file was not imported due to errors." : "Su archivo no fue importado debido a errores.",
  "You have marked this milestone as completed.": "Ha marcado este hito como completado.",
  "You have marked this milestone as not completed.": "Ha marcado este hito como no completado.",
  "You have not invited anyone yet.": "Aún no has invitado a nadie.",
  "View": "Vista",
  Activities: "Actividades",
  Activity: "Actividad",
  Assigned: "Asignado",
  Assignees: "Asignados",
  Blueberry: "Arándano",
  Budget: "Presupuesto",
  Cancel: "Cancelar",
  Chart: "Gráfico",
  CLOSE: "CERRADO",
  Close: "Cerrar",
  Colors: "Colores",
  Comments: "Comentarios",
  Cost: "Costo",
  Create: "Crear",
  Day: "Día",
  Dependencies: "Dependencias",
  Description: "Descripción",
  Difference: "Diferencia",
  Documents: "Documentos",
  Drawings: "Planos",
  Duration: "Duración",
  Email: "Correo electrónico",
  Error: "Error",
  Everyone: "Todos",
  Expenses: "Gastos",
  File: "Archivo",
  Files: "Archivos",
  Grid: "Cuadrícula",
  History: "Historia",
  Inspections: "Inspecciones",
  Invite: "Invitar",
  Meetings: "Reuniones",
  Milestone: "Hito",
  Mustard: "Mostaza",
  Name: "Nombre",
  No: "No",
  OPEN: "ABIERTO",
  Overdue: "Vencido",
  Permissions: "Permisos",
  Please: "Por favor",
  Predecessor: "Predecesor",
  Progress: "Progreso",
  Recommended: "Recomendado",
  RESCHEDULE: "REPROGRAMADO",
  RFIs: "RFIs",
  Roadblocks: "Obstáculos",
  Select: "Seleccionar",
  Send: "Guardar",
  Share: "Compartir",
  Starting: "A partir de",
  Status: "Estado",
  Submit: "Entregar",
  Success: "Éxito",
  Successor: "Sucesor",
  Tasks: "Tareas",
  Title: "Título",
  to: "a",
  Total: "Total",
  Update: "Actualizar",
  Week: "Semana",
  Yes: "Sí",
  "DOWNLOAD PDF": "DESCARGAR PDF",
  "POPULATE SAMPLE DATA": "COMPLETAR DATOS DE MUESTRA",
  "Additional Columns": "Columnas Adicionales",
  ACTIVITY_CREATED: "La actividad fue creada",
  ACTIVITY_UPDATED_ASSIGNEES_CREATED: 'Se añadió el asignado "{{ name }}"',
  ACTIVITY_UPDATED_ASSIGNEES_REMOVED: 'Se eliminó el asignado "{{ name }}"',
  ACTIVITY_UPDATED_COLOR: 'El color cambió de "{{ old }}" a "{{ new }}"',
  ACTIVITY_UPDATED_DESCRIPTION: 'La descripción cambió de "{{ old }}" a "{{ new }}"',
  ACTIVITY_UPDATED_ID_ACTIVITY_UPDATED: 'El predecesor cambió de "{{ old }}" a "{{ new }}"',
  ACTIVITY_UPDATED_PROGRESS: 'El progreso cambió de "{{ old }}" a "{{ new }}"',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_CREATED: 'Se añadió la línea de gasto "{{ title }}"',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_REMOVED: 'Se eliminó la línea de gasto "{{ title }}"',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_AMOUNT:
    'El monto cambió de "{{ old }}" a "{{ new }}" en la línea de gasto "{{ number }}"',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_FILE:
    'El archivo cambió de "{{ old }}" a "{{ new }}" en la línea de gasto "{{ number }}"',
  ACTIVITY_UPDATED_PROJECT_COST_EXPENSES_UPDATED_TITLE:
    'El título cambió de "{{ old }}" a "{{ new }}" en la línea de gasto "{{ number }}"',
  ACTIVITY_UPDATED_PROJECT_COST_BUDGET: 'El presupuesto cambió de "{{ old }}" a "{{ new }}"',
  ACTIVITY_UPDATED_REAL_TIMES_END: 'El fin real cambió de "{{ old }}" a "{{ new }}"',
  ACTIVITY_UPDATED_REAL_TIMES_START: 'El inicio real cambió de "{{ old }}" a "{{ new }}"',
  ACTIVITY_UPDATED_SCHEDULED_TIMES_END: 'El fin programado cambió de "{{ old }}" a "{{ new }}"',
  ACTIVITY_UPDATED_SCHEDULED_TIMES_START: 'El inicio programado cambió de "{{ old }}" a "{{ new }}"',
  ACTIVITY_UPDATED_TITLE: 'El título cambió de "{{ old }}" a "{{ new }}"',
  DAILY_REPORT_CREATED: 'Se añadió el informe diario "{{ title }}"',
  DAILY_REPORT_REMOVED: 'Se eliminó el informe diario "{{ title }}"',
  DOCUMENT_CREATED: 'Se añadió el documento "{{ name }}"',
  DOCUMENT_REMOVED: 'Se eliminó el documento "{{ name }}"',
  DRAWING_CREATED: 'Se añadió el dibujo "{{ name }}"',
  DRAWING_REMOVED: 'Se eliminó el dibujo "{{ name }}"',
  INSPECTION_CREATED: 'Se añadió la inspección "{{ title }}"',
  INSPECTION_REMOVED: 'Se eliminó la inspección "{{ title }}"',
  MEETING_CREATED: 'Se añadió la reunión "{{ title }}"',
  MEETING_REMOVED: 'Se eliminó la reunión "{{ title }}"',
  RFI_CREATED: 'Se añadió el RFI "{{ title }}"',
  RFI_REMOVED: 'Se eliminó el RFI "{{ title }}"',
  ROADBLOCK_CREATED: 'Se creó el obstáculo "{{ description }}"',
  ROADBLOCK_UPDATED_ASSIGNEES_CREATED: 'Se añadió el asignado "{{ name }}" al obstáculo "{{ description }}"',
  ROADBLOCK_UPDATED_ASSIGNEES_REMOVED: 'Se eliminó el asignado "{{ name }}" del obstáculo "{{ description }}"',
  ROADBLOCK_UPDATED_DATE: 'La fecha cambió de "{{ old }}" a "{{ new }}" en el obstáculo "{{ description }}"',
  ROADBLOCK_UPDATED_DESCRIPTION:
    'La descripción cambió de "{{ old }}" a "{{ new }}" en el obstáculo "{{ description }}"',
  ROADBLOCK_UPDATED_STATUS: 'El estado cambió de "{{ old }}" a "{{ new }}" en el obstáculo "{{ description }}"',
  ROADBLOCK_UPDATED_TYPE: 'El tipo cambió de "{{ old }}" a "{{ new }}" en el obstáculo "{{ description }}"',
  TASK_CREATED: 'Se añadió la tarea "{{ title }}"',
  TASK_REMOVED: 'Se eliminó la tarea "{{ title }}"',
};
