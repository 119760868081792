import { Tune } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiltersDrawer } from "./FiltersDrawer";

function FiltersButton({ filters, setFilters, countResults }) {
  const { t: tGeneral } = useTranslation("general");
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Button
        variant="contained"
        startIcon={<Tune sx={{ color: "#E6B60C" }} />}
        onClick={() => setIsOpen(true)}
        sx={{
          borderRadius: "1rem",
          backgroundColor: "white",
          textTransform: "none",
        }}
      >
        {tGeneral("filters")}
      </Button>
      <FiltersDrawer
        filters={filters}
        setFilters={setFilters}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        countResults={countResults}
      />
    </>
  );
}

export { FiltersButton };
