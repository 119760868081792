import { Add, Delete } from "@mui/icons-material";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

function FileMenu({ anchorEl, files, onClose, onChange }) {
  const { t: tGeneral } = useTranslation();
  const { t: tTemplates } = useTranslation("templates");
  const inputRef = useRef(null);

  const handleDelete = (file) => {
    onClose();
  };

  const handleFiles = () => {
    inputRef.current.click();
  };

  const onFilesChange = (e) => {
    const newFiles = [];
    for (let i = 0; i < e.target.files.length; i += 1) {
      newFiles.push(e.target.files[i]);
    }

    onChange(newFiles);
  };

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      {files?.map((f) => (
        <MenuItem onClick={() => handleDelete(f)}>
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText>{f.name}</ListItemText>
        </MenuItem>
      ))}
      <MenuItem onClick={handleFiles}>
        <ListItemIcon>
          <Add />
        </ListItemIcon>
        <ListItemText>{tGeneral("addNewFile")}</ListItemText>
        <input
          type="file"
          id="file"
          ref={inputRef}
          style={{ display: "none" }}
          onChange={onFilesChange}
          multiple
        />
      </MenuItem>
    </Menu>
  );
}

export { FileMenu };
