import { Info, MoreVert } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useUpdateEnterpriseMutation } from "../../../features/enterprise/enterpriseApiSlice";
import {
  useGetCitiesQuery,
  useGetCountriesQuery,
  useGetStatesQuery,
} from "../../../features/geolocation";
import { setLocale } from "../../../features/preferences/preferencesSlice";
import { useCreatePresignedUrlMutation } from "../../../features/s3/s3ApiSlice";
import { PopUpAlert } from "../../PopUpAlert";
import { IOSSwitch } from "../../shared";

function SectionTitle({ title, ...props }) {
  return [
    <Divider key={`${title}divider`} />,
    <Typography
      key={title}
      fontSize="1.125rem"
      sx={{
        bgcolor: "#FFC42D",
        display: "inline-block",
        p: "1px 15px",
        borderBottomRightRadius: "10PX",
        mb: "40px",
      }}
      width="auto"
    >
      {title}
    </Typography>,
  ];
}

function SectionDataCell({
  label,
  content,
  isEditing,
  value,
  values,
  onChange,
  icons,
  type = "text",
}) {
  const renderInput = () => {
    if (type === "switch") {
      return (
        <Card
          sx={{
            m: 0,
            p: "15px 16px",
            position: "relative",
            overflow: "inherit",
          }}
        >
          <Typography
            fontSize="0.875rem"
            fontWeight="bold"
            top={-10}
            sx={{ position: "absolute" }}
          >
            {label}
          </Typography>
          <Box display="flex" alignItems="center">
            {value ? icons?.trueIcon : icons?.falseIcon}
            <Typography sx={{ ml: "12px" }}>{content}</Typography>
            <Box flex="1" />
            <IOSSwitch
              checked={value}
              onChange={(e) => onChange(e.target.checked)}
            />
          </Box>
        </Card>
      );
    }

    if (type === "select") {
      return (
        <FormControl fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select
            value={value}
            onChange={(e) => onChange(e.target.value)}
            fullWidth
            label={label}
          >
            {values.map(({ label, value }) => (
              <MenuItem value={value} key={value}>{label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }

    return (
      <TextField
        value={value}
        onChange={(e) => onChange(e.target.value)}
        fullWidth
        label={label}
      />
    );
  };

  return (
    <Box flex={1}>
      {isEditing ? (
        renderInput()
      ) : (
        <>
          <Typography fontSize="0.875rem" fontWeight="bold">
            {label}
          </Typography>
          <Typography fontSize="1rem">
            {typeof content === "string"
              ? content
              : content?.join(", ") ?? "N/A"}
          </Typography>
        </>
      )}
    </Box>
  );
}

function ImageEdit({ src, onChange, alt, isEditing, help = null, ...style }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const inputRef = React.useRef(null);
  const open = Boolean(anchorEl);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const newUrl = URL.createObjectURL(file);
    onChange(newUrl, file);
    setAnchorEl(null);
  };

  return (
    <>
      {isEditing && (
        <>
          <IconButton
            id="more-button"
            aria-controls={open ? "image-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: "rgba(200, 200, 200, 0.8)",
            }}
          >
            <MoreVert sx={{ color: "white" }} />
          </IconButton>
          {help ? (
            <Tooltip
              title={help}
              sx={{ position: "absolute", bottom: 10, right: 10 }}
            >
              <Info color="info" />
            </Tooltip>
          ) : null}
          <Menu
            id="image-menu"
            MenuListProps={{
              "aria-labelledby": "more-button",
            }}
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
          >
            {src && <MenuItem>Ver imagen completa</MenuItem>}
            <MenuItem
              onClick={() => {
                inputRef.current.click();
              }}
            >
              Reemplazar imagen
            </MenuItem>
            <MenuItem>Eliminar imagen completa</MenuItem>
          </Menu>
          <input
            type="file"
            style={{ display: "none" }}
            ref={inputRef}
            multiple={false}
            accept="image/*"
            onChange={handleImageChange}
          />
        </>
      )}
      <img
        src={src}
        alt={alt}
        style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
      />
    </>
  );
}

const mapItem = (item) => ({
  label: item.name,
  value: item.iso2,
});

function InformationSettings({ enterprise }) {
  const { t: tEnterprise } = useTranslation("enterprise");
  const { t: tGeneral } = useTranslation("general");
  const [isEditing, setIsEditing] = React.useState(false);
  const defaultProjectInfo = React.useMemo(
    () => ({
      id: enterprise?.id,
      name: enterprise?.name,
      type: enterprise?.type,
      locale: enterprise?.locale,
      city: enterprise?.city || "",
      country: enterprise?.country || "",
      logo: enterprise?.logo || "",
      state: enterprise?.state || "",
      zipCode: enterprise?.zipCode || "",
      street: enterprise?.street || "",
      number: enterprise?.number || "",
    }),
    [enterprise],
  );

  const [projectAdminForm, setProjectAdminForm] = React.useState({});
  React.useEffect(() => {
    setProjectAdminForm(defaultProjectInfo);
  }, [defaultProjectInfo]);
  const [
    updateInformationSettings,
    {
      isLoading: isUpdateProjectLoading,
      isSuccess: isUpdateProjectSuccess,
      isError: isUpdateProjectError,
    },
  ] = useUpdateEnterpriseMutation();

  const { data: countries } = useGetCountriesQuery({}, { skip: !isEditing });
  const { data: states } = useGetStatesQuery(projectAdminForm.country, {
    skip: projectAdminForm.country === undefined || !isEditing,
  });
  const { data: cities } = useGetCitiesQuery(
    { country: projectAdminForm.country, state: projectAdminForm.state },
    {
      skip:
        projectAdminForm.state === undefined ||
        projectAdminForm.state === "" ||
        !isEditing,
    },
  );
  const countryItems = useMemo(() => {
    if (countries?.length) {
      return countries.map(mapItem);
    }
    return [];
  }, [countries]);
  const stateItems = useMemo(() => {
    if (states?.length) {
      return states.map(mapItem);
    }
    return [];
  }, [states]);

  const cityItems = useMemo(() => {
    if (cities?.length) {
      return cities.map((city) => ({ label: city.name, value: city.name }));
    }
    return [];
  }, [cities]);

  const [createPresignedUrl] = useCreatePresignedUrlMutation();

  const [newProjectImage, setNewProjectImage] = useState(null);

  React.useEffect(() => {
    if (isUpdateProjectSuccess) {
      PopUpAlert(
        tGeneral("success"),
        tGeneral("done"),
        tGeneral("enterpriseUpdated"),
      );
      setIsEditing(false);
    }
    if (isUpdateProjectError) {
      PopUpAlert(
        tGeneral("error"),
        tGeneral("errorBig"),
        tGeneral("errorOccurred"),
      );
      setIsEditing(false);
    }
  }, [isUpdateProjectError, isUpdateProjectSuccess]);

  const handleCancelEdit = () => {
    setProjectAdminForm(defaultProjectInfo);
    setIsEditing(false);
  };

  const dispatch = useDispatch();

  const handleSave = async () => {
    const country = countryItems.find(
      (ci) => ci.value === projectAdminForm.country,
    )?.label;
    const state = stateItems.find(
      (si) => si.value === projectAdminForm.state,
    )?.label;
    const body = {
      ...projectAdminForm,
      country: country ?? projectAdminForm.country,
      state: state ?? projectAdminForm.state,
    };
    if (newProjectImage) {
      const { data } = await createPresignedUrl();
      await fetch(data, { method: "PUT", body: newProjectImage });
      body.logo = data.split("?")[0];
    }
    await updateInformationSettings(body).unwrap();
    setIsEditing(false);
  };

  const handleStartEdit = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    if (!isEditing) {
      return;
    }
    if (Boolean(projectAdminForm.country) && countryItems.length > 0) {
      const country = countryItems.find(
        (ci) => ci.label === projectAdminForm.country,
      )?.value;
      if (country) {
        setProjectAdminForm((prev) => ({
          ...prev,
          country,
        }));
      }
    }
    if (Boolean(projectAdminForm.state) && stateItems.length > 0) {
      const state = stateItems.find(
        (si) => si.label === projectAdminForm.state,
      )?.value;
      if (state) {
        setProjectAdminForm((prev) => ({
          ...prev,
          state,
        }));
      }
    }
  }, [countryItems, cityItems, stateItems, projectAdminForm, isEditing]);

  const srcNewImage = useMemo(() => {
    if (newProjectImage !== null) {
      return URL.createObjectURL(newProjectImage);
    }
    return "";
  }, [newProjectImage]);

  const languages = { es: "Spanish", en: "English" };

  return (
    <Box>
      <Grid
        container
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Grid item xs={12} md={8} sx={{ paddingRight: 2 }}>
          {/* GENERAL */}
          <SectionTitle title="General" />
          <Box display="flex" mb="60px" columnGap="20px">
            {/* Nombre Empresa */}
            <Box flex={1}>
              <SectionDataCell
                label={tGeneral("enterpriseName")}
                content={projectAdminForm?.name}
                value={projectAdminForm.name}
                onChange={(value) =>
                  setProjectAdminForm((prev) => ({
                    ...prev,
                    name: value,
                  }))
                }
                isEditing={isEditing}
              />
            </Box>

            {/* Tipo Empresa */}
            <Box flex={1}>
              <SectionDataCell
                label={tGeneral("enterpriseType")}
                isEditing={isEditing}
                value={projectAdminForm.type}
                content={tEnterprise(projectAdminForm.type)}
                type="select"
                values={[
                  {
                    value: "construction",
                    label: tEnterprise("construction"),
                  },
                  { value: "realState", label: tEnterprise("realState") },
                  { value: "architect", label: tEnterprise("architect") },
                  { value: "contractor", label: tEnterprise("contractor") },
                  {
                    value: "subcontractor",
                    label: tEnterprise("subcontractor"),
                  },
                  { value: "maintenance", label: tEnterprise("maintenance") },
                  { value: "developer", label: tEnterprise("developer") },
                  {
                    value: "generalContractor",
                    label: tEnterprise("generalContractor"),
                  },
                  { value: "other", label: tEnterprise("other") },
                ]}
                onChange={(value) =>
                  setProjectAdminForm((prev) => ({
                    ...prev,
                    type: value,
                  }))
                }
              />
            </Box>
            <Box flex={1}>
              <SectionDataCell
                label={tGeneral("systemLanguage")}
                isEditing={isEditing}
                content={languages[projectAdminForm.locale]}
                value={projectAdminForm.locale}
                type="select"
                values={[
                  { label: "English", value: "en" },
                  { label: "Spanish", value: "es" },
                ]}
                onChange={(value) =>
                  setProjectAdminForm((prev) => ({
                    ...prev,
                    locale: value,
                  }))
                }
              />
            </Box>
          </Box>

          {/* ADDRESS */}
          <SectionTitle title={tGeneral("address")} />
          <Box display="flex" columnGap="20px">
            {/* street */}
            <SectionDataCell
              label={tGeneral("street")}
              content={projectAdminForm?.street}
              value={projectAdminForm.street}
              isEditing={isEditing}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({ ...prev, street: value }))
              }
            />

            {/* number */}
            <SectionDataCell
              label={tGeneral("number")}
              content={projectAdminForm?.number}
              value={projectAdminForm.number}
              isEditing={isEditing}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({
                  ...prev,
                  number: value,
                }))
              }
            />

            {/* country */}
            <SectionDataCell
              label={tGeneral("country")}
              isEditing={isEditing}
              value={projectAdminForm.country}
              values={countryItems}
              content={projectAdminForm.country}
              type="select"
              onChange={(value) =>
                setProjectAdminForm((prev) => ({
                  ...prev,
                  country: value,
                  state: "",
                  city: "",
                }))
              }
            />
          </Box>
          <Box display="flex" mt="60px" columnGap="20px">
            {/* state */}
            <SectionDataCell
              label={tGeneral("state")}
              value={projectAdminForm.state}
              content={projectAdminForm?.state}
              values={stateItems}
              type="select"
              isEditing={isEditing}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({ ...prev, state: value }))
              }
            />

            {/* city */}
            <SectionDataCell
              label={tGeneral("city")}
              content={projectAdminForm?.city}
              value={projectAdminForm.city}
              values={cityItems}
              type="select"
              isEditing={isEditing}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({ ...prev, city: value }))
              }
            />

            {/* zip code */}
            <SectionDataCell
              label={tGeneral("zipCode")}
              content={projectAdminForm?.zipCode}
              value={projectAdminForm.zipCode}
              isEditing={isEditing}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({ ...prev, zipCode: value }))
              }
            />
          </Box>
          <Divider sx={{ my: "30px" }} />
          <Box display="flex" justifyContent="flex-end" columnGap="16px">
            {isEditing ? (
              <>
                {/* cancel button */}
                <Button
                  variant="contained"
                  sx={{
                    p: "16px 100px",
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid black",
                    "&:hover": {
                      backgroundColor: "gray",
                    },
                  }}
                  disabled={isUpdateProjectLoading}
                  onClick={handleCancelEdit}
                >
                  <Typography fontWeight="medium" fontSize="1rem">
                    Cancel
                  </Typography>
                </Button>

                {/* Save Button */}
                <Button
                  variant="contained"
                  sx={{ p: "16px 100px" }}
                  onClick={handleSave}
                  disabled={isUpdateProjectLoading}
                >
                  {isUpdateProjectLoading ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <Typography fontWeight="medium" fontSize="1rem">
                      Save
                    </Typography>
                  )}
                </Button>
              </>
            ) : (
              // Edit button
              <Button
                variant="contained"
                sx={{ p: "16px 62px" }}
                onClick={handleStartEdit}
              >
                <Typography fontWeight="medium" fontSize="1rem">
                  {tGeneral("edit")}
                </Typography>
              </Button>
            )}
          </Box>
        </Grid>

        {/* IMAGES */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: 400,
            width: "100%",
          }}
        >
          {/* Project Image */}
          <Box
            flex={1}
            position="relative"
            justifyContent="center"
            display="flex"
            marginBottom={2}
            maxHeight="280px"
          >
            {/* Project IMAGE */}
            <ImageEdit
              src={
                newProjectImage === null ? projectAdminForm.logo : srcNewImage
              }
              alt={tGeneral("enterpriseImage")}
              isEditing={isEditing}
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
              onChange={(value, file) => {
                setNewProjectImage(file);
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export { InformationSettings };
