import React from "react";
// MUI Components
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingOverlay = ({ open, zIndex }) => {
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: zIndex ? zIndex : (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoadingOverlay;
