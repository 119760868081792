import { Close, ContentCopy } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  Modal,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { TooltipIconButton } from "../../../components/shared";
import {
  useAcceptInvitationMutation,
  useLazyGetNewCodeQuery,
} from "../../../features/project/modules/projectNetwork/projectNetworkApiSlice";
import { PopUpAlert } from "../../../components/PopUpAlert";

function ModalCreateProjectNetwork({ isOpen, onClose }) {
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { t: tProjectNetwork } = useTranslation("projectNetwork");
  const [code, setCode] = useState("");
  const [copied, setCopied] = useState(false);

  const [getNewCode, { data: newCodeData }] = useLazyGetNewCodeQuery();
  const [acceptInvitation, { isLoading, isSuccess, isError, error }] =
    useAcceptInvitationMutation();

  const handleGenerateNewCode = () => {
    getNewCode(idProject);
  };

  const handleAcceptInvitation = () => {
    acceptInvitation({ idProject, code });
  };

  const newCode = useMemo(() => {
    if (newCodeData) {
      return newCodeData.code;
    }
    return null;
  }, [newCodeData]);

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert(
        "success",
        tProjectNetwork("accepted"),
        tProjectNetwork("acceptedSuccessfully"),
      );
      onClose();
    }
    if (isError) {
      PopUpAlert("error", "Error", error.message);
    }
  }, [isSuccess, isError, error, tProjectNetwork, onClose]);

  const handleCopy = () => {
    navigator.clipboard.writeText(newCode);
    setCopied(true);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          width: "50%",
          overflow: "auto",
        }}
      >
        <Box display="flex" alignItems="center" p="1rem">
          <Typography flex={1} fontSize="1.5625rem">
            {tProjectNetwork("addProjectNetwork")}
          </Typography>
          <TooltipIconButton
            label={tGeneral("close")}
            onClick={onClose}
            icon={<Close />}
          />
        </Box>
        <Divider />
        <Box display="flex" height="300px">
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Typography>{tProjectNetwork("acceptInvite")}</Typography>
            <Box textAlign>
              <TextField
                label={tGeneral("code")}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Box>
            <Button
              size="small"
              variant="contained"
              onClick={handleAcceptInvitation}
            >
              {tGeneral("accept")}
            </Button>
          </Box>
          <Divider orientation="vertical" flexItem sx={{ marginY: "1rem" }} />
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Typography>{tProjectNetwork("yourNewCodeIs")}</Typography>
            <Box position="relative">
              <Typography fontSize="1.5rem">
                {newCode ?? "-----------"}
              </Typography>
              {newCode && (
                <TooltipIconButton
                  label={tGeneral("copy")}
                  icon={<ContentCopy />}
                  onClick={handleCopy}
                  sx={{
                    color: "gray",
                    position: "absolute",
                    right: -50,
                    top: 0,
                  }}
                />
              )}
            </Box>
            <Button
              variant="contained"
              size="small"
              onClick={handleGenerateNewCode}
              disabled={newCode !== null}
            >
              {tProjectNetwork("generateNewCode")}
            </Button>
          </Box>
        </Box>
        <Snackbar
          open={copied}
          autoHideDuration={2000}
          message={tProjectNetwork("codeCopied")}
          onClose={() => setCopied(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        />
      </Card>
    </Modal>
  );
}

export { ModalCreateProjectNetwork };
