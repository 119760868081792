import { Add, ArrowBack, ChevronRight } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  useAddLocationsMutation,
  useGetLocationsQuery,
} from "../../features/locations/locationApiSlice";

const locationTypes = ["tier 1", "tier 2", "tier 3"];

function NestedLocationSelect({
  selectedLocation,
  onChange,
  error,
  helperText,
}) {
  const { idProject } = useParams();

  // Translations
  const { t: tGeneral } = useTranslation("general");

  const { data: locations } = useGetLocationsQuery(idProject, {
    skip: !idProject,
    refetchOnMountOrArgChange: true,
  });
  const [addLocation, { isLoading }] = useAddLocationsMutation();
  const [currentTier, setCurrentTier] = React.useState(0);
  const [superLocation, setSuperLocation] = React.useState([]);
  const [newLocation, setNewLocation] = React.useState(null);

  const tiers = React.useMemo(() => {
    if (locations) {
      return Object.keys(locations);
    }
    return [];
  }, [locations]);

  const handleAddLocation = async () => {
    await addLocation({
      idProject,
      locationName: newLocation,
      locationType: locationTypes[currentTier],
      superLocationId:
        currentTier > 0 ? superLocation[currentTier - 1].id : null,
    });
    setNewLocation(null);
  };

  const handleNextTier = (location) => {
    if (currentTier < tiers.length) {
      setSuperLocation((prev) => [...prev, location]);
      setCurrentTier((prev) => prev + 1);
      setNewLocation(null);
    }
  };

  const handlePrevTier = () => {
    const lastId = superLocation[currentTier - 1].id;
    setSuperLocation((prev) => prev.filter((loc) => loc.id !== lastId));
    setCurrentTier((prev) => prev - 1);
    setNewLocation(null);
  };

  const filterBySuperLocation = (location) => {
    if (superLocation.length === 0) {
      return true;
    }
    return superLocation[currentTier - 1].id === location.superLocation;
  };

  const isLocationAdding = newLocation !== null;

  React.useEffect(() => {
    if (!locations) {
      return;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const [t, items] of Object.entries(locations)) {
      // eslint-disable-next-line no-restricted-syntax, no-unused-vars
      for (const [_, item] of Object.entries(items)) {
        if (item.id === selectedLocation) {
          const items = Object.values(locations).flatMap((location) =>
            Object.values(location),
          );
          const superLocation = [];
          if (item.superLocation) {
            superLocation.unshift(
              items.find((i) => i.id === item.superLocation),
            );
          }
          if (superLocation.length) {
            while (superLocation[0].superLocation) {
              superLocation.unshift(
                items.find((i) => i.id === superLocation[0].superLocation),
              );
            }
          }
          setCurrentTier(parseInt(t.replace("tier", ""), 10) - 1);
          setSuperLocation(superLocation);
        }
      }
    }
  }, [locations, selectedLocation]);

  if (!locations) {
    return null;
  }

  return (
    <FormControl fullWidth error={error} data-tour="add-inspection-6">
      <InputLabel id="location-select-label">{tGeneral("location")}</InputLabel>
      <Select
        labelId="location-select-label"
        MenuProps={{ disableScrollLock: true, sx: { maxHeight: "30%" } }}
        id="location-select"
        value={selectedLocation || ""}
        label="Location"
        onChange={(event) => {
          onChange(
            event.target.value.toString(),
            locations[tiers[currentTier]].find(
              (location) => location.id === event.target.value,
            )?.locationName,
          );
        }}
        renderValue={(value) => (
          <Typography>
            {locations &&
              locations[tiers[currentTier]].find(
                (location) => location.id === value,
              )?.locationName}
          </Typography>
        )}
      >
        {currentTier > 0 && (
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handlePrevTier();
              }}
            >
              <ArrowBack />
            </IconButton>
            <Typography fontWeight="bold">
              {superLocation[currentTier - 1].locationName}
            </Typography>
          </Box>
        )}

        {tiers?.length &&
          locations[tiers[currentTier]]
            .filter(filterBySuperLocation)
            .map((location) => (
              <MenuItem key={location.id} value={location.id}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  {location.locationName}
                  {currentTier < tiers.length - 1 && (
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleNextTier(location);
                      }}
                    >
                      <ChevronRight />
                    </IconButton>
                  )}
                </Box>
              </MenuItem>
            ))}
        <Box display="flex" columnGap="10px" px="10px">
          {isLocationAdding ? (
            <OutlinedInput
              fullWidth
              value={newLocation}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => setNewLocation(e.target.value)}
              size="small"
              endAdornment={
                <Button
                  onClick={handleAddLocation}
                  variant="contained"
                  size="small"
                >
                  {isLoading ? (
                    <CircularProgress color="secondary" size="1.2rem" />
                  ) : (
                    tGeneral("add")
                  )}
                </Button>
              }
            />
          ) : (
            <Button
              variant="text"
              startIcon={<Add />}
              fullWidth
              style={{
                justifyContent: "flex-start",
                paddingLeft: "3%",
                color: "black",
              }}
              onClick={() => setNewLocation("")}
            >
              {tGeneral("addLocation")}
            </Button>
          )}
        </Box>
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export { NestedLocationSelect };
