import { Box } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useParams } from "react-router";
import {
  useGetJobTypesQuery,
  useGetSubcontractorsQuery,
} from "../../../../../features/project/modules/dailyReports/manpowerApiSlice";
import { ManpowerCard } from "./ManpowerCard";
import { ManpowerForm } from "./ManpowerForm";

function Manpower() {
  const { idEnterprise } = useParams();
  const { control } = useFormContext();
  const { fields, remove } = useFieldArray({ control, name: "manpower" });
  const { append } = useFieldArray({ control, name: "deletedManpowers" });

  const { data: jobTypesData } = useGetJobTypesQuery(idEnterprise);
  const { data: subcontractorsData } = useGetSubcontractorsQuery(idEnterprise);

  const jobTypes = jobTypesData ?? [];
  const subcontractors = subcontractorsData ?? [];
  return (
    <Box width="100%">
      <ManpowerForm jobTypes={jobTypes} subcontractors={subcontractors} />
      <Box display="flex" flexDirection="column" rowGap="1rem">
        {fields.map((field, index) => {
          return (
            <ManpowerCard
              index={index}
              jobTypes={jobTypes}
              subcontractors={subcontractors}
              onDelete={() => {
                remove(index);

                const deletedManpower = fields[index];
                if (deletedManpower.bNew) return;

                append({ ...deletedManpower });
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export { Manpower };
