import { tour } from "./tour";

export const documents = {
  // general tour
  ...tour,
  moduleElement: "ドキュメント",
  // ADD FILE - guide me steps
  step1_docsAddFile_title: "1. 追加",
  step1_docsAddFile_text: "+ アイコンをクリックします",
  step2_docsAddFile_title: "2. ファイルを追加",
  step2_docsAddFile_text: "ファイルを追加するオプションを選択します",
  step3_docsAddFile_title: "3. ファイルをアップロード",
  step3_docsAddFile_text:
    "ファイルをドラッグアンドドロップするか、点線の四角形をクリックしてアップロードします",
  step4_docsAddFile_title: "4. プライバシー",
  step4_docsAddFile_text:
    "有効にすると、特定の企業や個人へのアクセスが制限されます",
  step5_docsAddFile_title: "5. 追加",
  step5_docsAddFile_text: "ファイルを追加するにはここをクリックします",
  step_final_docsAddFile: "ファイルの追加方法を学びました",
  // ADD FOLDER - guide me steps
  step1_docsAddFolder_title: "1. 追加",
  step1_docsAddFolder_text: "+ アイコンをクリックします",
  step2_docsAddFolder_title: "2. フォルダを追加",
  step2_docsAddFolder_text: "フォルダを作成するオプションを選択します",
  step3_docsAddFolder_title: "3. フォルダ名",
  step3_docsAddFolder_text: "フォルダの名前を入力します",
  step4_docsAddFolder_title: "4. プライバシー",
  step4_docsAddFolder_text:
    "有効にすると、特定の企業や個人へのアクセスが制限されます",
  step5_docsAddFolder_title: "5. 追加",
  step5_docsAddFolder_text: "フォルダを追加するにはここをクリックします",
  step_final_docsAddFolder: "フォルダの追加方法を学びました",
  // ACTIONS - guide me steps
  step1_docsActions_title: "1. アクション",
  step1_docsActions_text:
    "ファイル/フォルダに対して実行できるさまざまなアクションがあります",
  step2_docsActions_title: "2. お気に入りに選択",
  step2_docsActions_text:
    "ファイルをお気に入りにマークするにはここをクリックします",
  step3_docsActions_title: "3. ダウンロード",
  step3_docsActions_text:
    "選択したファイルをダウンロードするにはここをクリックします",
  step5_docsActions_title: "4. 名前を編集",
  step5_docsActions_text: "ファイルの名前を編集するにはここをクリックします",
  step6_docsActions_title: "5. その他のアクション",
  step6_docsActions_text:
    "3つの点をクリックして、その他のアクションが表示されるメニューを開きます",
  step7_docsActions_title: "6. 情報",
  step7_docsActions_text:
    "ファイルまたはフォルダのサイズ、作成日、アクセス権、変更履歴、メールの受信者リストを表示できます（この機能のガイドを参照してください）",
  step8_docsActions_title: "7. 移動",
  step8_docsActions_text:
    "ファイルを移動するためのメニューを開くにはここをクリックします（この機能のガイドを参照してください）",
  step9_docsActions_title: "8. コピー",
  step9_docsActions_text:
    "ファイルをクリップボードにコピーするにはここをクリックします",
  step10_docsActions_title: "9. 削除",
  step10_docsActions_text: "ファイルを完全に削除する場合はここをクリックします",
  step_final_docsActions:
    "ファイル/フォルダに対してさまざまなアクションを実行する方法を学びました",
  // MOVE TO - guide me steps
  step1_docsMoveTo_title: "1. その他のアクション",
  step1_docsMoveTo_text:
    "3つの点をクリックすると、その他のアクションが表示されるメニューが表示されます",
  step2_docsMoveTo_title: "2. 移動",
  step2_docsMoveTo_text:
    "ファイルを移動するためのメニューを開くにはここをクリックします",
  step3_docsMoveTo_title: "3. ファイルを移動",
  step3_docsMoveTo_text:
    "新しい場所を選択し、ファイルを移動するために受け入れをクリックします",
  step4_docsMoveTo_title: "4. 受け入れ",
  step4_docsMoveTo_text:
    "新しい場所を確認し、ファイルを移動するためにここをクリックします",
  step_final_docsMoveTo: "ファイル/フォルダを移動する方法を学びました",
  // FILE INFO - guide me steps
  step1_docsFileInfo_title: "1. その他のアクション",
  step1_docsFileInfo_text:
    "3つの点をクリックすると、その他のアクションが表示されるメニューが表示されます",
  step2_docsFileInfo_title: "2. 情報",
  step2_docsFileInfo_text:
    "ファイルやフォルダのサイズ、作成日、アクセス権、変更履歴、送信されたメールのリストを表示できます",
  step3_docsFileInfo_title: "3. アクセス権の管理",
  step3_docsFileInfo_text:
    "ドキュメントにアクセスできる企業や人物を管理するにはここをクリックします",
  step4_docsFileInfo_title: "4. 変更履歴",
  step4_docsFileInfo_text:
    "ドキュメントへの変更内容や変更を行った人物に関する情報を表示するにはここをクリックします",
  step5_docsFileInfo_title: "5. メール履歴",
  step5_docsFileInfo_text:
    "ドキュメントに関連するメールの履歴を表示するにはここをクリックします",
  step5_1_docsFileInfo_title: "5.1 ダウンロード",
  step5_1_docsFileInfo_text:
    "メールから情報をダウンロードするにはここをクリックします",
  step_final_docsFileInfo:
    "ファイル/フォルダに関する情報を表示する方法を学びました",
  // SETTINGS - guide me steps
  step1_docsSettings_title: "2. 設定メニューを開く",
  step1_docsSettings_text: "設定メニューを開くにはここをクリックします",
  step2_docsSettings_title: "3. 配布リスト",
  step2_docsSettings_text:
    "ドキュメントモジュールへのデフォルトアクセス権を持つ人物を追加または削除します",
  step3_docsSettings_title: "4. 保存",
  step3_docsSettings_text: "設定を保存するにはここをクリックします",
  step_final_docsSettings: "設定を調整する方法を学びました",

  lastEdit: "最終編集",
  uploadedCreatedBy: "アップロード/作成者",
  uploadedCreatedOn: "アップロード/作成日",
  recentDocuments: "最近のドキュメント",
  addFolder: "フォルダを追加",
  addFile: "ファイルを追加",
  folderIsPublic:
    "このフォルダは$SELECTION_PLACEHOLDER$の全員に公開されています",
  folderIsPrivate: "このフォルダはプライベートです",
  documentMovedMessage: "ドキュメントが正常に移動しました",
  folderMovedMessage: "フォルダが正常に移動しました",
  recentDocument: "最近のドキュメント",
  documentsRecycleBin: "ドキュメントのごみ箱",
  noDocumentsCreated: "ドキュメントが作成されていません",
  addDocumentsPrompt:
    "ドキュメントを追加する場合は、追加ボタンをクリックしてください",
  fileUpdatedSuccessfully: "ファイルが正常に更新されました",
  folderMarkedSuccessfully: "フォルダが正常にマークされました",
  fileMarkedSuccessfully: "ファイルが正常にマークされました",
  folderDeletedSuccessfully: "フォルダが正常に削除されました",
  fileDeletedSuccessfully: "ファイルが正常に削除されました",
  storage: "ストレージ",
  sendToEmail: "メールに送信",
  sendTo: "送信先",
  subject: "件名",
  body: "本文",
  selectAll: "すべて選択",
  deleteItems: "アイテムを削除",
  deleteItemsQuestion: "選択したアイテムを削除してもよろしいですか？",
  deleteItemsWarning: "この操作は元に戻すことができません",
  deleteFolder: "フォルダを削除",
  deleteFile: "ファイルを削除",
  deleteQuestion: "この$SELECTION_PLACEHOLDER$を削除してもよろしいですか？",
  deleteFolderQuestion: "選択したフォルダを削除してもよろしいですか？",
  deleteFileQuestion: "選択したファイルを削除してもよろしいですか？",
  file: "ファイル",
  folder: "フォルダ",
  renameFolder: "フォルダ名を変更",
  renameFile: "ファイル名を変更",
  folderName: "フォルダ名",
  fileName: "ファイル名",
  folderCreatedSuccessfully: "フォルダが正常に作成されました",
  fileCreatedSuccessfully: "ファイルが正常に作成されました",
  noFilesOnFavorites: "お気に入りにファイルがありません",
  noFilesOnRecycleBin: "ごみ箱にファイルがありません",
  fileRestoreSuccessfully: "ファイルが正常に復元されました",
  folderRestoreSuccessfully: "フォルダが正常に復元されました",
};
