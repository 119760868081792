export const enterprise = {
  construction: "建設",
  realState: "不動産",
  architect: "建築",
  contractor: "請負業者",
  subcontractor: "下請け業者",
  maintenance: "メンテナンス",
  developer: "開発者",
  generalContractor: "ゼネコン",
  other: "その他",
};
