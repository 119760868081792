import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  inspectionsTemplates: [],
  current: null,
  settingsInspectionTemplatePopupOpen: false,
  sampleInspectionTemplate: null,
  sampleDailyReportId: null,
  settingsDailyReportsPopupOpen: false,
  sampleTask: null,
  settingsTasksPopupOpen: false,
  addPhotoModalOpen: false,
  addAlbumModalOpen: false,
  sampleImage: null,
  imageViewerOpen: false,
  settingsPhotosPopupOpen: false,
};

export const inspectionsTemplatesSlice = createSlice({
  name: "inspectionsTemplates",
  initialState,
  reducers: {
    setInspectionsTemplates: (state, action) => {
      state.inspectionsTemplates = action.payload;
    },
    setCurrentInspectionTemplate: (state, action) => {
      state.current = action.payload;
    },
    setSettingsInspectionTemplatePopupOpen: (state, action) => {
      state.settingsInspectionTemplatePopupOpen = action.payload;
    },
    setSampleInspectionTemplate: (state, action) => {
      state.sampleInspectionTemplate = action.payload;
    },
    setSampleDailyReportId: (state, action) => {
      state.sampleDailyReportId = action.payload;
    },
    setSettingsDailyReportsPopupOpen: (state, action) => {
      state.settingsDailyReportsPopupOpen = action.payload;
    },
    setSampleTask: (state, action) => {
      state.sampleTask = action.payload;
    },
    setSettingsTasksPopupOpen: (state, action) => {
      state.settingsTasksPopupOpen = action.payload;
    },
    setAddPhotoModalOpen: (state, action) => {
      state.addPhotoModalOpen = action.payload;
    },
    setAddAlbumModalOpen: (state, action) => {
      state.addAlbumModalOpen = action.payload;
    },
    setSampleImage: (state, action) => {
      state.sampleImage = action.payload;
    },
    setImageViewerOpen: (state, action) => {
      state.imageViewerOpen = action.payload;
    },
    setSettingsPhotosPopupOpen: (state, action) => {
      state.settingsPhotosPopupOpen = action.payload;
    },
    resetInspectionsTemplates: () => initialState,
  },
});

export const selectAllInspectionsTemplates = (state) =>
  state.inspectionsTemplates.inspectionsTemplates;

export const selectCurrentInspectionTemplate = (state) =>
  state.inspectionsTemplates.current;

export const selectSettingsInspectionTemplatePopupOpen = (state) => {
  return state.inspectionsTemplates.settingsInspectionTemplatePopupOpen;
};

export const selectSettingsDailyReportsPopupOpen = (state) =>
  state.inspectionsTemplates.settingsDailyReportsPopupOpen;

export const selectSampleInspectionTemplate = (state) =>
  state.inspectionsTemplates.sampleInspectionTemplate;

export const selectSampleDailyReportId = (state) =>
  state.inspectionsTemplates.sampleDailyReportId;

export const selectSampleTask = (state) =>
  state.inspectionsTemplates.sampleTask;

export const selectSettingsTasksPopupOpen = (state) =>
  state.inspectionsTemplates.settingsTasksPopupOpen;

export const selectAddPhotoModalOpen = (state) =>
  state.inspectionsTemplates.addPhotoModalOpen;

export const selectAddAlbumModalOpen = (state) =>
  state.inspectionsTemplates.addAlbumModalOpen;

export const selectSampleImage = (state) =>
  state.inspectionsTemplates.sampleImage;

export const selectImageViewerOpen = (state) =>
  state.inspectionsTemplates.imageViewerOpen;

export const selectSettingsPhotosPopupOpen = (state) =>
  state.inspectionsTemplates.settingsPhotosPopupOpen;

export const {
  setInspectionsTemplates,
  setCurrentInspectionTemplate,
  setSettingsInspectionTemplatePopupOpen,
  setSampleInspectionTemplate,
  resetInspectionsTemplates,
  setSampleDailyReportId,
  setSettingsDailyReportsPopupOpen,
  setSampleTask,
  setSettingsTasksPopupOpen,
  setAddPhotoModalOpen,
  setAddAlbumModalOpen,
  setSampleImage,
  setImageViewerOpen,
  setSettingsPhotosPopupOpen,
} = inspectionsTemplatesSlice.actions;

export default inspectionsTemplatesSlice.reducer;
