const switchPrivacyLabel = {
  inputProps: { "aria-label": "Color switch demo" },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const statusProps = {
  pending: {
    label: "OPEN",
    styles: {
      backgroundColor: "#5ce381",
      color: "white",
    },
  },
  closed: {
    label: "CLOSED",
    styles: {
      color: "white",
      backgroundColor: "#c2c3c3",
    },
  },
  draft: {
    label: "DRAFT",
    styles: {
      color: "white",
      backgroundColor: "#fe9300",
    },
  },
  stillOpen: {
    label: "STILL OPEN",
    styles: {
      color: "white",
      backgroundColor: "#ffc900",
    },
  },
  notDefined: {
    label: "NOT DEFINED",
    styles: {
      color: "white",
      backgroundColor: "#2196f3",
    },
  },
};

// For table
const statusPropsTable = {
  open: {
    label: "OPEN",
    styles: {
      backgroundColor: "#5ce381",
      color: "white",
      fontSize: "1rem",
      width: "100px",
      fontWeight: "medium",
    },
  },
  close: {
    label: "CLOSED",
    styles: {
      color: "white",
      backgroundColor: "#c2c3c3",
      fontSize: "1rem",
      width: "100px",
      fontWeight: "medium",
    },
  },
  draft: {
    label: "DRAFT",
    styles: {
      color: "white",
      backgroundColor: "#fe9300",
      fontSize: "1rem",
      width: "100px",
      fontWeight: "medium",
    },
  },
  stillOpen: {
    label: "STILL OPEN",
    styles: {
      color: "white",
      backgroundColor: "#ffc900",
      fontSize: "1rem",
      width: "100px",
      fontWeight: "medium",
    },
  },
};

// Meeting details
const specificStatusProps = {
  open: {
    label: "OPEN",
    styles: {
      backgroundColor: "#5ce381",
      color: "white",
    },
  },
  close: {
    label: "CLOSED",
    styles: {
      color: "white",
      backgroundColor: "#c2c3c3",
    },
  },
  draft: {
    label: "DRAFT",
    styles: {
      color: "white",
      backgroundColor: "#fe9300",
    },
  },
  stillOpen: {
    label: "STILL OPEN",
    styles: {
      color: "white",
      backgroundColor: "#ffc900",
    },
  },
};

export {
  switchPrivacyLabel,
  MenuProps,
  statusProps,
  specificStatusProps,
  statusPropsTable,
};
