import { useTranslation } from "react-i18next";
import { CircleSharp } from "@mui/icons-material";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

const RiskSelect = ({ selectedRisk, onChange = () => {} }) => {
  const { t: tDailyReports } = useTranslation("dailyReports");

  const risks = [
    {
      label: tDailyReports("highRisk"),
      value: "0",
      color: "#EC2D30",
    },
    {
      label: tDailyReports("mediumRisk"),
      value: "1",
      color: "#FE9B0E",
    },
    {
      label: tDailyReports("lowRisk"),
      value: "2",
      color: "#0C9D61",
    },
  ];

  return (
    <>
      <FormControl fullWidth>
        <InputLabel size="small">{tDailyReports("risk")}</InputLabel>
        <Select
          id="risk-select"
          labelId="risk-select-label"
          value={selectedRisk || ""}
          onChange={(event) => {
            event.stopPropagation();
            onChange(event.target.value);
          }}
          onClose={(e) => {
            e.stopPropagation();
          }}
          label={tDailyReports("risk")}
          MenuProps={{ sx: { maxHeight: "300px" } }}
          size="small"
          sx={{
            backgroundColor: "white",
          }}
        >
          {risks.map((risk) => (
            <MenuItem value={risk.value} key={risk.value}>
              <Box display="flex" alignItems="center">
                <CircleSharp sx={{ color: risk.color, mr: 2 }} />
                {risk.label}
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default RiskSelect;
