import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import Swal from "sweetalert2";

import { useGetProjectByIdQuery } from "../../features/project/projectApiSlice";

function Connections() {
  const { idProject } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const { data: project } = useGetProjectByIdQuery(idProject);

  const [connections, setConnections] = useState({
    dropbox: { isConnected: false },
    google: { isConnected: false },
    microsoft: { isConnected: false },
  });

  const sendTokensToServer = async (data) => {
    try {
      await fetch(`${process.env.REACT_APP_BACKEND_URL}connections/insert`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...data, projectId: idProject }),
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const showAlert = (message, type) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: type,
      title: message,
    });
  };

  const handleConnect = (service) => {
    const connectionUrl = `${process.env.REACT_APP_BACKEND_URL}connections/auth/${service}`;
    const authWindow = window.open(connectionUrl, "_blank");
    window.addEventListener("message", (event) => {
      if (event.data.message === "Success") {
        sendTokensToServer({
          ...event.data,
          path: connections[service].path,
        });
        authWindow.close();
      }
    });
  };

  const handleDisconnect = async (service) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}connections/connectionsDelete`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ service, projectId: idProject }),
        },
      );
      if (response.ok) {
        const success = await response.json();
        setConnections((prevState) => ({
          ...prevState,
          [service]: false,
        }));
        showAlert(success.message, "success");
      } else {
        const errorData = await response.json();
        showAlert(errorData.message, "error");
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      showAlert("Error disconnecting", "error");
    }
  };

  const handleMessage = (event) => {
    if (event.data.message === "Success") {
      const { service, path, accessToken, refreshToken } = event.data;
      setConnections((prevState) => ({
        ...prevState,
        [service.toLowerCase()]: {
          isConnected: true,
          path,
          accessToken,
          refreshToken,
        },
      }));
      showAlert("Connected successfully.", "success");
    }
  };

  const handleSave = async (service) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}connections/paths`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            service,
            path: connections[service].path,
            projectId: idProject,
          }),
        },
      );

      if (response.ok) {
        const success = await response.json();
        setIsLoading(false);
        showAlert(success.message, "success");
      } else {
        const errorData = await response.json();
        setIsLoading(false);
        showAlert(errorData.message, "error");
      }
    } catch (error) {
      setIsLoading(false);
      showAlert("Error saving path", "error");
    }
  };

  const handleSync = async (service) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}connections/sync`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            service,
            path: connections[service].path,
            projectId: idProject,
          }),
        },
      );

      if (response.ok) {
        const success = await response.json();
        setIsLoading(false);
        showAlert(success.message, "success");
      } else {
        const errorData = await response.json();
        setIsLoading(false);
        showAlert(errorData.message, "error");
      }
    } catch (error) {
      setIsLoading(false);
      showAlert("Error saving path", "error");
    }
  };

  const getContents = (service) => {
    const { isConnected, path } = connections[service];
    if (isConnected) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            <Button
              onClick={() => handleDisconnect(service)}
              variant="contained"
            >
              Disconnect
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
            }}
          >
            <TextField
              label="Path"
              onChange={(e) =>
                setConnections((prevState) => ({
                  ...prevState,
                  [service]: {
                    ...prevState[service],
                    path: e.target.value,
                  },
                }))
              }
              size="small"
              value={path}
            />
            <Button onClick={() => handleSave(service)} variant="contained">
              Save
            </Button>
            <Button onClick={() => handleSync(service)} variant="contained">
              Sync
            </Button>
          </Box>
        </Box>
      );
    }
    return (
      <Box>
        <Button onClick={() => handleConnect(service)} variant="contained">
          Connect
        </Button>
      </Box>
    );
  };

  useEffect(() => {
    const { dropbox, google, microsoft } = project.connections;
    setConnections({
      dropbox: { isConnected: dropbox.is_connected, path: dropbox.path || "" },
      google: { isConnected: google.is_connected, path: google.path || "" },
      microsoft: {
        isConnected: microsoft.is_connected,
        path: microsoft.path || "",
      },
    });

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Dropbox
      </Typography>
      <Box display="flex" flexDirection="column">
        {getContents("dropbox")}
      </Box>

      <Typography variant="h6" gutterBottom mt={2}>
        Google
      </Typography>
      <Box display="flex" flexDirection="column">
        {getContents("google")}
      </Box>

      <Typography variant="h6" gutterBottom mt={2}>
        Microsoft
      </Typography>
      <Box display="flex" flexDirection="column">
        {getContents("microsoft")}
      </Box>
    </Box>
  );
}

export default Connections;
