export const rfis = {
  // guide me buttons
  add_button_tour: "追加",
  view_button_tour: "表示",
  edit_button_tour: "編集",
  recycle_button_tour: "ごみ箱",
  export_button_tour: "エクスポート",
  settings_button_tour: "設定",
  // ADD - guide me steps
  step1_addRfi_title: "1. RFIを追加",
  step1_addRfi_text: "RFIを追加するにはここをクリック",
  step2_addRfi_title: "2. タイトル",
  step2_addRfi_text: "RFIを説明するタイトルを入力してください",
  step3_addRfi_title: "3. 納期",
  step3_addRfi_text:
    "回答者からの回答を受けるための締め切りとして納期を選択してください",
  step4_addRfi_title: "4. ステータス",
  step4_addRfi_text1_1: "ステータスを選択してください",
  step4_addRfi_text1_2: "回答待ちのRFIを公開するためにOPENを選択してください",
  step4_addRfi_text2_1: "次の",
  step4_addRfi_text2_2:
    "DRAFTステータスを選択して、公開せずにRFIの下書きを保持してください",
  step5_addRfi_title: "5. プライバシー",
  step5_addRfi_text_1: "RFIのプライバシータイプを選択してください",
  step5_addRfi_text_2:
    "モジュールにアクセス権限を持つすべてのユーザーがRFIを見ることができます",
  step5_addRfi_text_3: "参加者のみがこのRFIを見ることができます",
  step6_addRfi_title: "6. 担当者",
  step6_addRfi_text: "質問に回答する人を選択してください",
  step7_addRfi_title: "7. 質問",
  step7_addRfi_text:
    "質問または情報をリクエストするための内容を入力してください",
  step8_addRfi_title: "8. ファイル",
  step8_addRfi_text:
    "任意の種類のファイル（PDF、Word、Excel、JPGなど）をアップロードしてください",
  step9_addRfi_title: "9. 受信元",
  step9_addRfi_text:
    "プロジェクト内のどの人から情報を受け取ったかを指定してください",
  step10_addRfi_title: "10. 仕様",
  step10_addRfi_text: "参照したい仕様を選択してください",
  step11_addRfi_title: "11. 図面",
  step11_addRfi_text: "このRFIにリンクする図面を選択してください",
  step12_addRfi_title: "12. コスト",
  step12_addRfi_text: "このRFIにコストの影響がある場合は、金額を追加できます",
  step13_addRfi_title: "13. 遅延",
  step13_addRfi_text:
    "このRFIに遅延の影響がある場合は、日数と時間を追加できます",
  step14_addRfi_title: "14. 配布リスト",
  step14_addRfi_text: "このRFIについて通知する人を選択してください",
  step15_addRfi_title: "15. 公開",
  step15_addRfi_text: "RFIを作成するには公開をクリックしてください",
  stepFinal_addRfi: "RFIの作成方法を学びました",
  // VIEW - guide me steps
  step1_viewRfi_title: "1. RFIを表示",
  step1_viewRfi_text: "RFIを表示するにはここをクリック",
  step2_viewRfi_title: "2. RFIの一般情報",
  step2_viewRfi_text: "RFIの完全な情報を表示します",
  step3_viewRfi_title: "3. 公式の質問",
  step3_viewRfi_text: "添付ファイルを含む公式の質問を表示します",
  step4_viewRfi_title: "4. 公式質問の翻訳",
  step4_viewRfi_text:
    "コミュニケーションを効果的にするために、質問は希望の言語に自動的に翻訳されます",
  step5_viewRfi_title: "5. 回答とコメントの追加",
  step5_viewRfi_text:
    "担当者は公式のリクエストに対して回答やコメントを送信することができます",
  step6_viewRfi_title: "6. トピックの作成",
  step6_viewRfi_text:
    "配布リストのユーザーもトピックセクションで回答やコメントをすることができます",
  step7_viewRfi_title: "7. 最終閲覧",
  step7_viewRfi_text: "最近このRFIを閲覧したユーザーを確認することができます",
  step8_viewRfi_title: "8. 最終編集",
  step8_viewRfi_text:
    "最近このRFIとやり取りしたユーザーを確認することができます",
  step9_viewRfi_title: "9. 閉じる",
  step9_viewRfi_text:
    "作成者である場合は、公式な回答を得た後にこのRFIを閉じる責任があります",
  step10_viewRfi_title: "10. PDFでエクスポート",
  step10_viewRfi_text: "このRFIの情報を含むPDFをエクスポートできます",
  stepFinal_viewRfi: "RFIの表示方法を学びました",
  // EDIT - guide me steps
  step1_editRfi_title: "1. RFIを編集",
  step1_editRfi_text: "RFIを編集するにはここをクリック",
  step2_editRfi_title: "2. タイトル",
  step2_editRfi_text: "RFIを説明するタイトルを入力してください",
  step3_editRfi_title: "3. 納期",
  step3_editRfi_text:
    "回答者からの回答を受けるための締め切りとして納期を選択してください",
  step4_editRfi_title: "4. ステータス",
  step4_editRfi_text1_1: "ステータスを選択してください",
  step4_editRfi_text1_2: "回答待ちのRFIを公開するためにOPENを選択してください",
  step4_editRfi_text2_1: "次の",
  step4_editRfi_text2_2:
    "DRAFTステータスを選択して、公開せずにRFIの下書きを保持してください",
  step5_editRfi_title: "5. プライバシー",
  step5_editRfi_text_1: "RFIのプライバシータイプを選択してください",
  step5_editRfi_text_2:
    "モジュールにアクセス権限を持つすべてのユーザーがRFIを見ることができます",
  step5_editRfi_text_3: "参加者のみがこのRFIを見ることができます",
  step6_editRfi_title: "6. 担当者",
  step6_editRfi_text: "プレースホルダー",
  step7_editRfi_title: "7. 配布リスト",
  step7_editRfi_text: "プレースホルダー",
  step8_editRfi_title: "8. 追加情報",
  step8_editRfi_text: "プレースホルダー",
  step9_editRfi_title: "9. 公開",
  step9_editRfi_text: "プレースホルダー",
  stepFinal_editRfi: "RFIの編集方法を学びました",
  // RECYCLE - guide me steps
  step1_recycleRfi_title: "1. RFIごみ箱",
  step1_recycleRfi_text: "ごみ箱を開くにはここをクリック",
  step2_recycleRfi_title: "2. ごみ箱メニュー",
  step2_recycleRfi_text: "これはごみ箱のメニューです",
  step3_recycleRfi_title: "3. RFIを表示",
  step3_recycleRfi_text: "完全なRFIを表示することができます",
  step4_recycleRfi_title: "4. RFIを復元",
  step4_recycleRfi_text: "復元するRFIを1つまたは複数選択してください",
  stepFinal_recycleRfi: "ごみ箱とのやり取り方法を学びました",
  // EXPORT - guide me steps
  step1_exportRfi_title: "1. PDFでエクスポート",
  step1_exportRfi_text: "RFIをPDF形式でエクスポートするにはここをクリック",
  step2_exportRfi_title: "2. メールで送信",
  step2_exportRfi_text: "このRFIをメールで共有するにはここをクリック",
  step3_exportRfi_title: "2. CSVでエクスポート",
  step3_exportRfi_text:
    "いくつかのRFIを選択するか、すべてのRFIをフィルタリングしてCSVファイルにエクスポートします",
  step4_exportRfi_title: "4. PDFでエクスポート",
  step4_exportRfi_text:
    "いくつかのRFIを選択するか、すべてのRFIをフィルタリングしてPDFファイルにエクスポートします",
  step5_exportRfi_title: "3. RFI内の画面",
  step5_exportRfi_text: "詳細ビューからPDFでエクスポートすることができます",
  stepFinal_exportRfi: "RFIのエクスポート方法を学びました",
  // SETTINGS - guide me steps
  step1_settingsRfi_title: "1. RFIの設定",
  step1_settingsRfi_text: "設定を変更するにはここをクリック",
  step2_settingsRfi_title: "2. 日数",
  step2_settingsRfi_text:
    "グローバルにRFIに回答する期限を変更するにはここを変更してください",
  step3_settingsRfi_title: "3. 配布リスト",
  step3_settingsRfi_text:
    "グローバルに配布リストに属するユーザーを変更するにはここを変更してください",
  step4_settingsRfi_title: "4. 設定を保存",
  step4_settingsRfi_text: "設定を保存するにはここをクリック",
  stepFinal_settingsRfi: "RFIの設定とのやり取り方法を学びました",
  // other
  obligatory: "必須",
  public: "公開",
  private: "非公開",
  congratulations: "おめでとうございます！",
  rfi_title_module: "RFIs",
  no_rfis_title: "現在、RFIは作成されていません",
  no_rfis_text:
    "追加する場合は、RFIを追加するボタンをクリックしてプロセスを開始してください",
  meeting: "ミーティング",
  workflow: "ワークフロー",
  guideMe: "ガイド",
  support: "サポート",
  days: "日",
  rfi_recycle_bin: "RFIごみ箱",
  addSpec: "仕様を追加",
  deleteRfi: "RFIを削除",
  confirmDelete:
    "選択したRFIを削除してもよろしいですか？この操作は元に戻すことはできません。",
};
