import { Box, Typography, useMediaQuery } from "@mui/material";

function Download() {
  const match = useMediaQuery("(min-width: 600px)");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row-reverse",
        backgroundImage: `url(${require("./../../assets/download-background.png")})`,
        backgroundSize: match ? "contain" : "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        width: "100%",
        paddingX: "10%",
        paddingY: match ? "0" : "5%",
        minHeight: match ? "741px" : "0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          maxWidth="536px"
          fontSize={match ? "47px" : "25px"}
          fontWeight={match ? "regular" : "bold"}
          textAlign={match ? "inherit" : "center"}
          mb={match ? "100px" : "30px"}
        >
          Download our app to have the best experience.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: match ? "row" : "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <img
            src={require("./../../assets/download-android.png")}
            alt="Download Google Play"
            style={{
              marginRight: match ? "8px" : "0",
              marginBottom: match ? "0" : "15px",
            }}
            width="200px"
          />
          <img
            src={require("./../../assets/download-apple.png")}
            alt="Download App Store"
            width="200px"
          />
        </Box>
      </Box>
    </Box>
  );
}

export { Download };
