export const plan = {
  perUser: "사용자당",
  perProject: "프로젝트당",
  perMonth: "월별",
  perYear: "연간",
  monthly: "매월",
  annual: "매년",
  bestValue: "최고의 가치",
  youWillPay: "지불할 금액",
  activeProjects: "활성 프로젝트",
  activeUsers: "활성 사용자",
};
