import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  Modal,
  Snackbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { TooltipIconButton } from "../../../components/shared";
import { useGetAvailableCodesQuery } from "../../../features/project/modules/projectNetwork/projectNetworkApiSlice";

function ModalAvailableCodes({ isOpen, onClose }) {
  const { idProject } = useParams();
  const { t: tProjectNetwork } = useTranslation("projectNetwork");
  const { t: tGeneral } = useTranslation("general");

  const [copied, setCopied] = useState(false);

  const { data } = useGetAvailableCodesQuery(idProject, {
    skip: !idProject || !isOpen,
  });

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code);
    setCopied(true);
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card sx={{ width: "40%" }}>
        <Box display="flex" alignItems="center" p="1rem">
          <Typography flex={1} fontSize="1.5625rem">
            {tProjectNetwork("availableCodes")}
          </Typography>
          <TooltipIconButton
            icon={<Close />}
            onClick={onClose}
            label={tGeneral("close")}
            color="gray"
          />
        </Box>
        <Divider />
        <Box
          height="300px"
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
          gridTemplateRows="50px"
          p="1rem"
          columnGap="0.5rem"
          rowGap="0.5rem"
        >
          {data?.codes?.map((code) => (
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleCopy(code)}
            >
              {code}
            </Button>
          ))}
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={copied}
          autoHideDuration={2000}
          message={tProjectNetwork("codeCopied")}
          onClose={() => setCopied(false)}
        />
      </Card>
    </Modal>
  );
}

export { ModalAvailableCodes };
