export const drawingsChecklist = {
  checkList: "체크리스트",
  totalCompleted: "총 완료",
  done: "완료",
  revision: "개정",
  pending: "보류",
  viewDrawings: "도면 보기",
  hideDrawings: "도면 숨기기",
  link: "링크",
  deleteRowTitle: "이 행을 삭제하시겠습니까?",
  percentageCompleted: "완료율",
};
