import { ArrowBack } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { PopUpAlert } from "../../../components/PopUpAlert";
import {
  DesignDisciplinesInput,
  DisciplineForm,
} from "../../../components/enterpriseDashboard/Templates";
import {
  useCreateDesignTemplateMutation,
  useGetDesignTemplateDetailsQuery,
  useUpdateDesignTemplateMutation,
} from "../../../features/designTemplates";
import { useUploadFilesMutation } from "../../../features/files";

function DesignTemplatesForm() {
  const { idEnterprise, idTemplate } = useParams();
  const navigate = useNavigate();
  const { t: tTemplates } = useTranslation("templates");
  const { t: tGeneral } = useTranslation("general");
  const formValue = useForm({
    defaultValues: {
      title: "",
      date: null,
      duration: 0,
      disciplines: [],
    },
  });

  const [uploadFiles] = useUploadFilesMutation();
  const [createDesignTemplate, { isLoading, isSuccess, isError, error }] =
    useCreateDesignTemplateMutation();

  const [
    updateDesignTemplate,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateDesignTemplateMutation();

  const { register, control, handleSubmit, watch, reset } = formValue;

  const { data: template } = useGetDesignTemplateDetailsQuery(idTemplate, {
    skip: !idTemplate,
  });

  useEffect(() => {
    if (template) {
      reset({
        title: template.title,
        date: template.date,
        duration: template.duration,
        disciplines: template.disciplines,
      });
    }
  }, [template, reset]);

  const onSubmit = async (data) => {
    // const files = data.disciplines.flatMap((d) =>
    //   d.elements.flatMap((e) => ({ id: e.id, files: e.files })),
    // );

    // await Promise.all(
    //   files.map(async (f) => {
    //     const filesFormData = new FormData();
    //     f.files.forEach((file) => {
    //       filesFormData.append("file", file);
    //     });
    //     await uploadFiles({
    //       idObject: f.id,
    //       moduleName: "templates",
    //       body: filesFormData,
    //     });
    //   }),
    // );

    if (idTemplate) {
      updateDesignTemplate({ idDesignTemplate: idTemplate, body: data });
    } else {
      createDesignTemplate({ ...data, idEnterprise });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert(
        "success",
        tTemplates("templateCreated"),
        tTemplates("templateCreatedDescription"),
      );
      navigate(-1);
    }
    if (isError) {
      PopUpAlert("error", tGeneral("error"), error.message);
    }
  }, [isSuccess, isError, error, navigate, tTemplates, tGeneral]);

  useEffect(() => {
    if (isUpdateSuccess) {
      PopUpAlert(
        "success",
        tTemplates("templateUpdated"),
        tTemplates("templateUpdatedDescription"),
      );
      navigate(-1);
    }
    if (isUpdateError) {
      PopUpAlert("error", tGeneral("error"), updateError.message);
    }
  }, [
    isUpdateSuccess,
    isUpdateError,
    updateError,
    navigate,
    tTemplates,
    tGeneral,
  ]);

  const disciplines = watch("disciplines");

  return (
    <FormProvider {...formValue}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        style={{ padding: "1rem 0.5rem" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{ backgroundColor: "rgba(250,250,250,0.8)" }}
          p="1rem 0.5rem"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              sx={{ columnGap: "0.5rem", textTransform: "none" }}
              onClick={() => navigate(-1)}
            >
              <ArrowBack sx={{ color: "#8E8E8E" }} />
              <Typography fontSize="1.25rem" fontWeight="700" color="#1F1F1F">
                {tTemplates("createTemplates")}
              </Typography>
            </Button>
            <Button
              sx={{ textTransform: "none", borderRadius: "1rem" }}
              variant="contained"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress /> : tTemplates("saveTemplate")}
            </Button>
          </Box>
          <Typography>{tTemplates("templateInstructions")}</Typography>
          <Divider />
          <Box display="flex">
            <TextField
              {...register("title", { rules: { required: true } })}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                flex: 1,
              }}
              InputProps={{
                sx: {
                  fontSize: "2.175rem",
                  fontWeight: "700",
                  color: "#4B4B4B",
                },
              }}
              placeholder={tTemplates("templateTitle")}
            />
            <Box display="flex" alignItems="center" columnGap="0.5rem">
              <Typography>{tGeneral("date")}</Typography>
              <Typography>
                {moment(new Date()).format("DD, MMM, YY")}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography>{tGeneral("duration")}:</Typography>
            <TextField
              type="number"
              size="small"
              {...register("duration", { rules: { required: true } })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {tGeneral("weeks")}
                  </InputAdornment>
                ),
              }}
              sx={{ width: "150px" }}
            />
          </Box>
          <Box display="flex" width="100%" alignItems="center">
            <Typography>{tGeneral("disciplines")}:</Typography>
            <DesignDisciplinesInput
              value={disciplines}
            />
          </Box>
          <Box>
            {disciplines.map((discipline, index) => (
              <DisciplineForm
                discipline={discipline}
                index={index}
                key={discipline.id}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
}

export { DesignTemplatesForm };
