export const analytics = {
  projectProgress: "Progreso del proyecto",
  hi: "Hola",
  realProgress: "Progreso real",
  welcomeBack: "Bienvenido",
  moduleStatistics: "Estadísticas de módulos",
  onTime: "A tiempo",
  overdue: "Atrasado",
  constructionPercentage: "Porcentaje de construcción",
  completed: "Completado",
  thisWeek: "Esta semana",
  workForce: "Fuerza laboral",
  hours: "Horas",
  completedTasks: "Tareas completadas",
  analytics: "Analíticas",
  addWidgets: "Agrega tus widgets para mostrar",
  projectStatistics: "Estadísticas de proyecto",
  rfis: "RFIs",
  tasks: "Tareas",
  submittals: "Submittals",
  drawingsProgress: "Avance general de Planos",
  drawingsCount_one: "Plano",
  drawingsCount_other: "Planos",
  inspections: "Inspecciones",
  inspectionCount_one: "Inspección",
  inspectionCount_other: "Inspecciones",
  reports: "Reportes",
  reportCount_one: "Reporte",
  reportCount_other: "Reportes",
  delayed: "Atrasado",
  closed: "Cerrado",
  open: "Abierto",
  pending: "Pendiente",
  inProgress: "En progreso",
  done: "Hecho",
  revision: "Revisión",
  total: "Total",
  selectWidget: "Seleccione el widget de su elección",
  widgetDisplayed: "El widget seleccionado se mostrará en su tablero",
  somethingWentWrong: "Algo salió mal",
  basic: "Básico",
  manpower: "Mano de obra",
  workProgress: "Avance",
  problem: "Problema",
  delay: "Retraso",
  categoryReports: "Categoría de reportes",
  projectAdvancement: "Avance del proyecto",
  remainingDays: "Días faltantes para finalización del proyecto",
  day_one: "día",
  day_other: "días",
  progress: "Progreso",
  remaining: "Restante",
  error: "Error",
  notEnoughSpace: "No hay suficiente espacio para este widget.",
  w: "S",
  progressOverTime: "Progreso a traves del tiempo",
  week: "Semana",
  noDataAvailable: "No hay datos disponibles.",
};
