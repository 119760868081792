import { Box, Divider } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  EnterpriseInfo,
  Features,
  Payment,
  Plan,
  Welcome,
} from "../../components/Onboarding";
import { Timeline } from "../../components/shared";

function Onboarding() {
  const [onboardingStep, setOnboardingStep] = useState(0);
  const { t: tOnboarding } = useTranslation("onboarding");
  const handleNextStep = () => {
    setOnboardingStep((prev) => prev + 1);
  };

  const handlePrevStep = () => {
    setOnboardingStep((prev) => prev - 1);
  };
  const [enterpriseBody, setEnterpriseBody] = useState({});
  const [plan, setPlan] = useState({});
  const handleEnterprise = (data) => {
    setEnterpriseBody(data);
    handleNextStep();
  };

  const handlePlan = (selectedPlan) => {
    setPlan(selectedPlan);
    handleNextStep();
  };

  const steps = [
    <Welcome onButtonClick={handleNextStep} />,
    <EnterpriseInfo
      onButtonClick={handleEnterprise}
      onBackClick={handlePrevStep}
    />,
    <Features onButtonClick={handleNextStep} onBackClick={handlePrevStep} />,
    <Plan onButtonClick={handlePlan} onBackClick={handlePrevStep} />,
    <Payment
      onBackClick={handlePrevStep}
      plan={plan}
      enterprise={enterpriseBody}
    />,
  ];
  return (
    <Box flex={1} p="40px">
      <Box paddingX="30%">
        <Timeline
          steps={[
            tOnboarding("welcome"),
            tOnboarding("enterpriseInfo"),
            tOnboarding("features"),
            tOnboarding("choosePlan"),
            tOnboarding("payment"),
          ]}
          currentStep={onboardingStep}
        />
      </Box>
      <Divider sx={{ marginY: "25px" }} />
      <Box px="10%">{steps[onboardingStep]}</Box>
    </Box>
  );
}

export { Onboarding };
