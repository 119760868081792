import { apiSlice } from "../../api/apiSlice";

export const translationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    translateText: builder.mutation({
      query: (body) => ({
        url: "/translation/translateText",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useTranslateTextMutation } = translationApiSlice;
