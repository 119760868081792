export const payment = {
  paymentMethod: "Método de pago",
  invoiceDetails: "Detalles de la factura",
  addNewCard: "Añadir nueva tarjeta",
  addData: "Añadir datos",
  orderSummary: "Resumen de pedidos",
  pricePerProject: "Precio por proyecto",
  pricePerUser: "Precio por usuario",
  projectsNumber: "Número de proyectos",
  usersNumber: "Número de usuarios",
  totalPerYear: "Total por año",
  totalPerMonth: "Total por mes",
  agreement:
    "Entiendo que mis facturas se basarán en mi número de usuarios. Se pueden añadir licencias invitando a usuarios a mi cuenta o proyectos después del pago. Los usuarios pueden eliminarse en cualquier momento.",
  editCard: "Editar tarjeta",
  emailRecipient: "Correo electrónico del destinatario",
  companyName: "Nombre de la empresa",
  addCard: "Agregar tarjeta",
  cardNumber: "Número de tarjeta",
};
