import { Close, DragHandle, Edit, ExpandMore } from "@mui/icons-material";
import SubjectIcon from "@mui/icons-material/Subject";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UploadPhotoLocationSelect } from "../../../../../components/projectDashboard/Photos/UploadPhoto/UploadPhotoLocationSelect";
import { ReportTopics } from "./ReportTopics";
import UnitSelect from "../UnitSelect/UnitSelect";
import RiskSelect from "../RiskSelect";

function Agenda({ agenda, onEditAgenda, deleteAgenda, deleteTopic, category }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDailyReports } = useTranslation("dailyReports");

  const [isEditing, setIsEditing] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const handleEditing = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleEditAgendaLocation = (id, name) => {
    onEditAgenda({
      ...agenda,
      idLocation: id,
      locationName: name,
      title: name,
    });
    setIsEditing(false);
  };

  const handleEditTopic = (newTopic) => {
    const newAgenda = { ...agenda };
    newAgenda.topics = newAgenda.topics.map((topic) => {
      if (topic.id === newTopic.id && topic.idAux === newTopic.idAux) {
        return newTopic;
      }
      return topic;
    });
    onEditAgenda(newAgenda);
  };

  const handleAddTopic = (newTopic) => {
    const newAgenda = { ...agenda };
    newAgenda.topics = [...newAgenda.topics, newTopic];
    onEditAgenda(newAgenda);
  };

  const handleDeleteTopic = (oldTopic) => {
    if (oldTopic.id) {
      deleteTopic(oldTopic.id);
    }
    const newAgenda = { ...agenda };
    newAgenda.topics = newAgenda.topics
      .filter(
        (topic) => topic.id !== oldTopic.id || oldTopic.idAux !== topic.idAux,
      )
      .map((topic, i) => ({ ...topic, order: i + 1 }));
    onEditAgenda(newAgenda);
  };

  const handleDeleteAgenda = (e) => {
    e.stopPropagation();
    deleteAgenda(agenda);
  };

  return (
    <Accordion expanded={expanded} onClick={() => setExpanded((prev) => !prev)}>
      <AccordionSummary
        sx={{ backgroundColor: expanded ? "#E8E8E8" : "white" }}
        expandIcon={<ExpandMore />}
      >
        <Box display="flex" flexDirection="column" width="100%" rowGap={1}>
          <Box display="flex" flex={1} alignItems="center">
            <DragHandle />
            <Box display="flex" flex={1} alignItems="center" width={category === 3 ? "80%" : "90%"}>
              {isEditing || !agenda.idLocation ? (
                <UploadPhotoLocationSelect
                  selectedLocation={agenda.idLocation ?? ""}
                  onChange={handleEditAgendaLocation}
                  mb={0}
                  size="small"
                />
              ) : (
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography component="div" onClick={handleEditing}>
                    {agenda.locationName}
                  </Typography>
                  <IconButton
                    onClick={handleEditing}
                  >
                    <Edit />
                  </IconButton>
                </Box>
              )}
            </Box>
            {category === 3 && (
              <Box display="flex" alignItems="center" sx={{ width: "200px", ml: 1 }}>
                <RiskSelect
                  selectedRisk={agenda.risk}
                  onChange={(risk) => {
                    onEditAgenda({
                      ...agenda,
                      risk: risk,
                    });
                  }}
                />
              </Box>
            )}
          </Box>
          {/* Category 2 - Work progress */}
          {category === 2 && (
            <Box display="flex" flex={1} alignItems="center" columnGap={1} data-tour="edit-report-progress-11">
              {/* Advance */}
              <Typography fontWeight="bold">
                {tDailyReports("advance")}
              </Typography>
              <TextField
                placeholder={tGeneral("number")}
                onClick={(e) => e.stopPropagation()}
                value={agenda.advance}
                type="number"
                onFocus={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  e.stopPropagation();
                  onEditAgenda({
                    ...agenda,
                    advance: e.target.value,
                  });
                }}
                sx={{
                  width: "70px",
                  backgroundColor: "white",
                }}
                size="small"
                inputProps={{
                  style: {
                    paddingRight: 5,
                  },
                }}
              />
              <Box sx={{ width: "110px" }}>
                <UnitSelect
                  selectedUnit={agenda.idUnit}
                  onChange={(unit) => {
                    onEditAgenda({
                      ...agenda,
                      idUnit: unit,
                    });
                  }}
                />
              </Box>
              {/* Cost */}
              <Typography fontWeight="bold">{tGeneral("cost")}</Typography>
              <TextField
                placeholder={tGeneral("price")}
                onClick={(e) => e.stopPropagation()}
                value={agenda.cost}
                type="number"
                onFocus={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  e.stopPropagation();
                  onEditAgenda({
                    ...agenda,
                    cost: e.target.value,
                  });
                }}
                sx={{
                  width: "70px",
                  backgroundColor: "white",
                }}
                size="small"
                inputProps={{
                  style: {
                    paddingRight: 5,
                  },
                }}
              />
              <FormControl>
                <InputLabel size="small">{tGeneral("currency")}</InputLabel>
                <Select
                  label={tGeneral("currency")}
                  value={agenda.currency}
                  onChange={(e) => {
                    e.stopPropagation();
                    onEditAgenda({
                      ...agenda,
                      currency: e.target.value,
                    });
                  }}
                  onClose={(e) => {
                    e.stopPropagation();
                  }}
                  size="small"
                  sx={{
                    width: "110px",
                    backgroundColor: "white",
                  }}
                >
                  <MenuItem value="mxn">{tGeneral("mxn")}</MenuItem>
                  <MenuItem value="usd">{tGeneral("usd")}</MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>
        {category !== 3 && (
          <IconButton
            onClick={handleDeleteAgenda}
            sx={{ maxHeight: "50px", alignSelf: "center" }}
          >
            <Close />
          </IconButton>
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{ backgroundColor: "#F2F2F2", pl: "74px", pr: "27px" }}
      >
        <Box display="flex" alignItems="center" columnGap={1}>
          <SubjectIcon />
          {tDailyReports("report").toUpperCase()}
        </Box>
        <ReportTopics
          topics={agenda.topics}
          onEditTopic={handleEditTopic}
          onAddTopic={handleAddTopic}
          onDeleteTopic={handleDeleteTopic}
          category={category}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export { Agenda };
