import { apiSlice } from "../../../../api/apiSlice";

export const announcementApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getAnnouncements: builder.query({
      query: (idProject) =>
        `/project/announcements/getAnnouncements/${idProject}`,
      keepUnusedDataFor: 0,
      providesTags: ["Announcements"],
    }),
    getAnnouncement: builder.query({
      query: (idAnnouncement) =>
        `/project/announcements/byId/${idAnnouncement}`,
      keepUnusedDataFor: 10,
    }),
    // announcements endpoints
    getActiveAnnouncements: builder.query({
      query: (idProject) =>
        `project/announcements/getAnnouncements/${idProject}/get?bActive=true`,
      keepUnusedDataFor: 0,
      // rovidesTags: ["Projects"],
    }),
    getInactiveAnnouncements: builder.query({
      query: (idProject) =>
        `project/announcements/getAnnouncements/${idProject}/get?bActive=false`,
      keepUnusedDataFor: 0,
      // providesTags: ["Projects"],
    }),
    createAnnouncement: builder.mutation({
      query: (body) => ({
        url: "project/announcements/createAnnouncement",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Announcements"],
    }),
    deleteAnnouncement: builder.mutation({
      query: (body) => ({
        url: "project/announcements/deleteAnnouncements",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["Announcements"],
    }),
    restoreAnnouncement: builder.mutation({
      query: (body) => ({
        url: "project/announcements/restoreAnnouncements",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Announcements"],
    }),
    publishAnnouncement: builder.mutation({
      query: (body) => ({
        url: "project/announcements/publishAnnouncement",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Announcements"],
    }),
    setAnnouncementPrivate: builder.mutation({
      query: (body) => ({
        url: "project/announcements/setAnnouncementPrivate",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Announcements"],
    }),
    editAnnouncement: builder.mutation({
      query: (body) => ({
        url: "project/announcements/editAnnouncement",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Announcements"],
    }),
  }),
});

export const {
  useGetAnnouncementsQuery,
  useGetActiveAnnouncementsQuery,
  useGetInactiveAnnouncementsQuery,
  useCreateAnnouncementMutation,
  useEditAnnouncementMutation,
  useDeleteAnnouncementMutation,
  useRestoreAnnouncementMutation,
  useSetAnnouncementPrivateMutation,
  usePublishAnnouncementMutation,
  useGetAnnouncementQuery,
} = announcementApiSlice;
