import { Delete, Search } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserAvatar from "../../../../../components/userAvatars/UserAvatar";
import { selectTheme } from "../../../../../features/preferences/preferencesSlice";
import { useTranslation } from "react-i18next";

const label = { inputProps: { "aria-label": "Switch demo" } };
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function EmailModal({
  isOpen,
  onClose,
  users,
  data,
  onSend,
  isLoading = false,
}) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDocuments } = useTranslation("documents");

  const darkMode = useSelector(selectTheme);
  const theme = useTheme();

  const [modalState, setModalState] = useState({
    name: "",
    isFolder: null,
    subject: "",
    body: "",
  });
  const [distributionList, setDistributionList] = useState([]);
  const [selectedDistList, setSelectedDistList] = useState([]);
  const changeDistributionList = (event) => {
    const { value } = event.target;
    if (value[value.length - 1] === "all") {
      setSelectedDistList(
        selectedDistList.length === distributionList.length
          ? []
          : distributionList,
      );
    } else {
      setSelectedDistList(value);
    }
  };
  const handleDelete = (toDelete) => {
    setSelectedDistList(
      selectedDistList.filter(function (item) {
        return item.id !== toDelete.id;
      }),
    );
  };
  function getStyles(name, selectedDistList, theme) {
    return {
      fontWeight:
        selectedDistList.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleChange = (event, name) => {
    setModalState({
      ...modalState,
      [name]: event.target.value,
    });
  };

  useEffect(() => {
    setDistributionList(users);
    setModalState({
      isFolder: data.isFolder,
      name: data.name,
      url: data.url ?? null,
    });
    return () => {};
  }, [users, data]);

  const resetVars = () => {
    setSelectedDistList([]);
    setModalState({
      name: "",
      isFolder: null,
      subject: "",
      body: "",
    });
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        resetVars();
        onClose();
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          width: "40%",
          height: "80%",
          borderRadius: "20px",
          p: "25px 40px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="space-between"
          mb="15px"
        >
          <Typography variant="h6" fontWeight="bold">
            {tDocuments("sendToEmail")}
          </Typography>
          <IconButton
            onClick={() => {
              resetVars();
              onClose();
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Typography variant="body1">{modalState.name}</Typography>
        <Divider />
        <Box sx={{ mt: 2, mb: 2, height: "60%", overflowY: "scroll" }}>
          {/* Distribution List */}
          <FormControl sx={{ width: "100%", mb: 2 }} required>
            <InputLabel
              id="dist-list-select-label"
              required={selectedDistList.length <= 0}
              shrink={false}
            >
              {selectedDistList.length > 0 ? "" : `${tDocuments("sendTo")}: `}
            </InputLabel>
            <Select
              id="dist-list-select"
              multiple
              displayEmpty
              value={selectedDistList}
              onChange={changeDistributionList}
              IconComponent={({ ...rest }) => (
                <Search
                  {...rest}
                  style={{
                    // pointerEvents: "none",
                    position: "absolute",
                    right: "10px",
                  }}
                />
              )}
              input={<OutlinedInput />}
              sx={{
                color: darkMode ? "white" : "black",
                backgroundColor: darkMode ? "" : "#FFF",
                "& .MuiSelect-iconOpen": {
                  transform: "rotate(0deg)",
                  color: "#FBCB04",
                },
              }}
              renderValue={(selected) =>
                selectedDistList.length > 0 ? `${tDocuments("sendTo")}: ` : null
              }
              MenuProps={MenuProps}
            >
              <MenuItem
                value="all"
                sx={{
                  width: "95%",
                  height: 45,
                  justifyContent: "left",
                  alignContent: "center",
                  fontSize: "0.875rem",
                  mt: 1,
                  ml: "auto",
                  mr: "auto",
                  padding: 2,
                  borderRadius: "23px",
                }}
              >
                <Checkbox
                  checked={
                    distributionList.length > 0 &&
                    selectedDistList.length === distributionList.length
                  }
                  indeterminate={
                    selectedDistList.length > 0 &&
                    selectedDistList.length < distributionList.length
                  }
                />
                <ListItemText
                  primary={
                    <Typography sx={{ fontWeight: 600 }} variant="inherit">
                      {tDocuments("selectAll")}
                    </Typography>
                  }
                />
              </MenuItem>
              {distributionList.map((user) => (
                <MenuItem
                  key={user.id}
                  value={user}
                  style={getStyles(user, selectedDistList, theme)}
                  sx={{
                    width: "95%",
                    height: 45,
                    justifyContent: "left",
                    alignContent: "center",
                    fontSize: "0.875rem",
                    mt: 1,
                    ml: "auto",
                    mr: "auto",
                    padding: 2,
                    borderRadius: "23px",
                  }}
                >
                  <Checkbox checked={selectedDistList.indexOf(user) > -1} />
                  <Avatar
                    alt={user.name}
                    src={user.urlAvatar}
                    sx={{ width: 24, height: 24, mr: 1 }}
                  />
                  <ListItemText primary={user.name} />
                </MenuItem>
              ))}
            </Select>
            <Box
              sx={{
                overflow: "auto",
                maxHeight: "100px",
              }}
            >
              {distributionList.map((person) => {
                if (selectedDistList.indexOf(person) > -1) {
                  return (
                    <Chip
                      key={person.id}
                      sx={{
                        width: "100%",
                        height: 40,
                        bgcolor: darkMode ? "" : "#f7e4b2",
                        fontSize: "0.875rem",
                        justifyContent: "left",
                        mt: 1,
                        padding: 2,
                        borderRadius: "23px",
                      }}
                      avatar={
                        <UserAvatar
                          sx={{ m: 10 }}
                          key={person.id}
                          name={person.name}
                          size="Small"
                          url={person?.urlAvatar}
                        />
                      }
                      clickable={false}
                      label={person.name}
                      deleteIcon={
                        <Delete style={{ color: "red", marginLeft: "auto" }} />
                      }
                      onDelete={() => handleDelete(person)}
                    />
                  );
                }
                return null;
              })}
            </Box>
          </FormControl>
          {/* Subject */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              id="subject"
              label={tDocuments("subject")}
              variant="outlined"
              required
              type="text"
              value={modalState.subject}
              onChange={(e) => handleChange(e, "subject")}
            />
          </FormControl>

          {/* Mail body */}
          <FormControl fullWidth>
            <TextField
              id="body"
              label={tDocuments("body")}
              variant="outlined"
              required
              type="text"
              value={modalState.body}
              multiline
              rows={8}
              onChange={(e) => handleChange(e, "body")}
            />
          </FormControl>
        </Box>
        {/* Action Buttons */}
        <Box
          display="flex"
          justifyContent="space-around"
          mb="20px"
          columnGap="1rem"
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              resetVars();
              onClose();
            }}
            sx={{
              backgroundColor: "white",
              color: "black",
              py: ".8rem",
              border: "1px solid black",
              ":hover": {
                backgroundColor: "lightgrey",
              },
            }}
            fullWidth
          >
            {tGeneral("cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              let selectedUsers = selectedDistList.map((user) => user.email);
              onSend(selectedUsers, modalState);
            }}
            disabled={
              !modalState.subject ||
              !modalState.body ||
              selectedDistList.length <= 0
            }
            fullWidth
            sx={{ py: ".8rem" }}
          >
            {isLoading ? (
              <CircularProgress color="primary" />
            ) : (
              tGeneral("accept")
            )}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}

export default EmailModal;
