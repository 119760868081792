import { Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
  AssignedUsersTooltip,
  EmptyState,
  StyledMaterialReactTable,
  TooltipIconButton,
} from "../../../components/shared";
import { DateFormat } from "../../../services/helpers/dateFormat";
import Can from "../../../utils/can";
import { useAbility } from "@casl/react";
import CaslContext from "../../../utils/caslContext";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../features/userSettings/userSettingsSlice";

function GeneralAnnouncements({
  data,
  openDialogFunc,
  enableSelection,
  handleDelete,
  darkMode,
  isLoading,
  userRole,
}) {
  const navigate = useNavigate();
  // Translations
  const { t: tAnnouncements } = useTranslation("announcements");
  const { t: tGeneral } = useTranslation("general");

  const ability = useAbility(CaslContext);
  const currentUser = useSelector(selectCurrentUser);

  const [selectedAnnouncements, setSelectedAnnouncements] = useState({});

  const columns = [
    // Message
    {
      accessorKey: "description",
      header: tAnnouncements("announcement"),
      enableSorting: false,
      muiTableBodyCellProps: {
        align: "left",
      },
      muiTableHeadCellProps: {
        align: "left",
      },
      Cell: ({ cell, row }) => (
        <Typography
          sx={{ fontSize: 12 }}
          onClick={() => {
            openDialogFunc(row.original.id);
          }}
        >
          {cell.getValue().length <= 60
            ? cell.getValue()
            : `${cell.getValue().substr(0, 60)}...`}
        </Typography>
      ),
      size: 100,
    },
    // Expiration Date
    {
      accessorKey: "expirationDate",
      header: tAnnouncements("expiration"),
      sorting: true,
      Cell: ({ cell }) => <DateFormat date={cell.getValue()} withTime />,
      size: 100,
    },
    // Creator
    {
      accessorKey: "createdByUser",
      header: tAnnouncements("creator"),
      enableSorting: false,
      Cell: ({ cell }) => <AssignedUsersTooltip users={[cell.getValue()]} />,
      size: 100,
    },
    // Distribution List
    {
      accessorKey: "distributionListUsers",
      header: tAnnouncements("distributionList"),
      enableSorting: false,
      Cell: ({ cell }) =>
        cell.getValue().length > 0 ? (
          <AssignedUsersTooltip users={cell.getValue()} />
        ) : (
          <Typography sx={{ fontSize: 12, color: darkMode }}>
            {tGeneral("no_assinged_users")}
          </Typography>
        ),
      size: 100,
    },
  ];

  if (!isLoading && (!data || !data.length)) {
    return (
      <EmptyState
        title={tAnnouncements("addAnnouncementTitle")}
        content={
          userRole?.drawings === 3 || userRole?.drawings === 2
            ? tAnnouncements("addAnnouncementValidation")
            : ""
        }
        module="announcements"
      />
    );
  }
  return (
    <StyledMaterialReactTable
      columns={columns}
      isLoading={isLoading}
      data={data || []}
      filtersComponent={null}
      selectedRows={selectedAnnouncements}
      enableRowSelection={enableSelection}
      setSelectedRows={setSelectedAnnouncements}
      muiSelectAllCheckboxProps={{ class: "checkbox-recycle" }}
      renderRowActions={({ row }) => (
        <Box display="flex">
          {(row.original.bActive && (ability.can("superAdmin", "announcements") || (ability.can("edit", "announcements") && row.original.createdBy === currentUser.id))) && (
            <TooltipIconButton
              icon={<Edit />}
              onClick={() => navigate(`form/${row.id}`)}
              label={tGeneral("edit")}
              color="black"
              data-tour="edit-announcement-1"
            />
          )}
          <Can I="view" a="announcements">
            <TooltipIconButton
              icon={<RemoveRedEye />}
              onClick={() => openDialogFunc(row.original.id)}
              label={tGeneral("seeDetails")}
              color="black"
              data-tour="view-announcement-1"
            />
          </Can>
          {row.original.bActive && (
            <Can I="delete" a="announcements">
              <TooltipIconButton
                icon={<Delete />}
                onClick={() => handleDelete([row.id])}
                label={tGeneral("delete")}
                color="red"
              />
            </Can>
          )}
        </Box>
      )}
    />
  );
}

export default GeneralAnnouncements;
