import { Add, Check, Close } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  useCreateEnterpriseUserMutation,
  useGetRolesFromEnterprisesQuery,
  useGetUserProjectsByEnterpriseQuery,
} from "../../../features/enterprise/enterpriseApiSlice";
import { AssignedProjects } from "./AssignedProjects";
import { PopUpAlert } from "../../../components/PopUpAlert";
import {
  useCreateCompanyMutation,
  useGetActiveCompaniesQuery,
} from "../../../features/company";

const filter = createFilterOptions();

function ModalCreateEnterpriseUser({ isOpen, onClose }) {
  const { idEnterprise } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { t: tError } = useTranslation("error");
  const [idProject, setIdProject] = useState(null);
  const [idRole, setIdRole] = useState(null);
  const [position, setPosition] = useState(null);
  const formData = useForm({
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
      bAdmin: false,
      company: null,
      projects: [],
    },
  });

  const { data: projectsData } =
    useGetUserProjectsByEnterpriseQuery(idEnterprise);
  const { data: rolesData } = useGetRolesFromEnterprisesQuery(idEnterprise);
  const { data: companiesData } = useGetActiveCompaniesQuery(idEnterprise);
  const [
    createEnterpriseUser,
    { isSuccess, isError, error, reset: resetMutation },
  ] = useCreateEnterpriseUserMutation();
  const [createCompany] = useCreateCompanyMutation();
  const { handleSubmit, control, watch, setValue, reset } = formData;

  const handleClose = useCallback(() => {
    setIdProject(null);
    setIdRole(null);
    setPosition(null);
    reset();
    onClose();
  }, [onClose, reset]);

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert("success", tGeneral("done"), tGeneral("sessionDataSent"));
      resetMutation();
      handleClose();
    }

    if (isError) {
      PopUpAlert("error", "Error", tError(error.data.message));
      resetMutation();
      handleClose();
    }
  }, [isSuccess, isError, error, tGeneral, handleClose, tError, resetMutation]);

  const onSubmit = (data) => {
    const body = { ...data, idEnterprise };
    createEnterpriseUser(body);
  };

  const projects = watch("projects");
  const idCompany = watch("company");
  const enterpriseProjects = projectsData ? projectsData.projects : [];
  const enterpriseRoles = rolesData ?? [];
  const companies = useMemo(() => companiesData ?? [], [companiesData]);

  const handleAddProject = () => {
    const project = enterpriseProjects.find((proj) => proj.id === idProject);
    const role = enterpriseRoles.find((role) => role.id === idRole);
    setValue("projects", [
      ...projects,
      {
        idProject: project.id,
        projectName: project.projectName,
        idRole: role.id,
        roleName: role.name,
        image: project.image,
        position,
      },
    ]);
    setIdProject(null);
    setIdRole(null);
    setPosition("");
  };

  const handleDeleteAssignedProject = (idProject) => {
    setValue(
      "projects",
      projects.filter((project) => project.idProject !== idProject),
    );
  };

  const selectedCompany = useMemo(() => {
    if (companies && idCompany) {
      return companies.find((company) => company.id === idCompany);
    }
    return null;
  }, [companies, idCompany]);

  const handleAddCompany = async (newCompany) => {
    const createdCompany = await createCompany({
      name: newCompany,
      idEnterprise,
    }).unwrap();
    setValue("company", createdCompany.id);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card sx={{ width: "800px", padding: "10px" }}>
        <FormProvider {...formData}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex" alignItems="center" my="10px">
              <Typography sx={{ flex: 1 }} variant="h4">
                {tGeneral("createuser")}
              </Typography>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
              <IconButton color="primary" type="submit">
                <Check />
              </IconButton>
            </Box>
            <Divider />
            <Box display="flex" flexDirection="column" rowGap="10px" mt="10px">
              <Box display="flex" columnGap="10px">
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      required
                      label={tGeneral("email")}
                      type="email"
                      sx={{ flex: 1 }}
                      size="small"
                    />
                  )}
                />
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="bAdmin"
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label={tGeneral("superAdmin")}
                />
              </Box>
              <Box display="flex" columnGap="10px">
                <Controller
                  control={control}
                  name="firstName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={tGeneral("firstName")}
                      sx={{ flex: 1 }}
                      size="small"
                      required
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="lastName"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={tGeneral("lastName")}
                      sx={{ flex: 1 }}
                      size="small"
                      required
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="phone"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={tGeneral("phone")}
                      sx={{ flex: 1 }}
                      size="small"
                      required
                      type="number"
                    />
                  )}
                />
                <Autocomplete
                  size="small"
                  value={selectedCompany?.name}
                  onChange={(event, newValue) => {
                    if (typeof newValue === "string") {
                      setValue("company", newValue);
                    } else if (newValue && newValue.inputValue) {
                      handleAddCompany(newValue.inputValue);
                    } else {
                      setValue("company", newValue ? newValue.id : newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    const isExisting = options.some(
                      (option) => inputValue === option.title,
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        name: `Add "${inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={companies}
                  getOptionLabel={(option) => {
                    if (typeof option === "string") {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.name;
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>{option.name}</li>
                  )}
                  sx={{ flex: 1 }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} label={tGeneral("company")} />
                  )}
                />
              </Box>
              <Divider />
              <Typography>{tGeneral("assignedProjects")}</Typography>
              <Box display="flex" columnGap="10px">
                <FormControl sx={{ flex: 1 }} size="small">
                  <InputLabel>{tGeneral("project")}</InputLabel>
                  <Select
                    label={tGeneral("project")}
                    value={idProject}
                    onChange={(e) => setIdProject(e.target.value)}
                    MenuProps={{ sx: { maxHeight: "400px" } }}
                  >
                    {enterpriseProjects
                      .filter(
                        (project) =>
                          projects.findIndex(
                            (proj) => proj.idProject === project.id,
                          ) < 0,
                      )
                      .map((project) => (
                        <MenuItem value={project.id} key= {project.id}>
                          {project.projectName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ flex: 1 }} size="small">
                  <InputLabel>{tGeneral("role")}</InputLabel>
                  <Select
                    label={tGeneral("role")}
                    value={idRole}
                    onChange={(e) => setIdRole(e.target.value)}
                    MenuProps={{
                      sx: { maxHeight: "400px" },
                    }}
                  >
                    {enterpriseRoles.map((role) => (
                      <MenuItem value={role.id} key={role.id}>{role.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label={tGeneral("position")}
                  onChange={(e) => setPosition(e.target.value)}
                  value={position}
                  size="small"
                  variant="outlined"
                />
                <Button
                  variant="text"
                  disabled={idProject === null || idRole === null}
                  onClick={handleAddProject}
                >
                  <Add />
                </Button>
              </Box>
              <AssignedProjects
                projects={projects}
                onDelete={handleDeleteAssignedProject}
              />
            </Box>
          </form>
        </FormProvider>
      </Card>
    </Modal>
  );
}

export { ModalCreateEnterpriseUser };
