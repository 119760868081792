import { Box, Typography, useMediaQuery } from "@mui/material";

function DocumentSharingFeature({ image, title, content, reverse }) {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Box
      maxWidth={isMobile ? "100%" : "360px"}
      display="flex"
      flexDirection="column"
      alignItems={reverse ? "flex-end" : "flex-start"}
    >
      {image}
      <Typography fontSize={isMobile ? "20px" : "26px"} fontWeight="bold">
        {title}
      </Typography>
      <Typography
        fontSize={isMobile ? "16px" : "22px"}
        lineHeight={isMobile ? "35px" : "initial"}
        textAlign={reverse ? "right" : "left"}
      >
        {content}
      </Typography>
    </Box>
  );
}

export { DocumentSharingFeature };
