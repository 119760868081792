import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { datadogRum } from "@datadog/browser-rum";
import {
  useDeleteSubmittalMutation,
  useGetSubmittalsQuery,
} from "../../../features/project/modules/submittals/submittalApiSlice";
import { selectCurrentProject } from "../../../features/project/projectSlice";
import "jspdf-autotable";

import { ModalWithAction, TitleWidgets } from "../../../components/shared";
import { DISTRIBUTION_LIST_NAMES } from "../../../constants";
import Can from "../../../utils/can";
import { SubmittalsTable } from "../../../components/projectDashboard/Submittals/SubmittalsTable";
import { PopUpAlert } from "../../../components/PopUpAlert";
import CaslContext from "../../../utils/caslContext";
import { ModalFileViewer } from "../../../components/shared/ModalFileViewer";
import { customFormatDate } from "../../../utils/dateFormats";
import { useToast } from "../../../contexts/ToastContext";

function Submittals() {
  const { idProject, idEnterprise } = useParams();

  const { t: tGeneral } = useTranslation("general");
  const { t: tSubmittals } = useTranslation("submittals");
  const toast = useToast();

  const currentProject = useSelector(selectCurrentProject);

  const ability = React.useContext(CaslContext);

  const [params, setParams] = useState({});
  const [selectedSubmittals, setSelectedSubmittals] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [removeSubmittal, { isLoading: isDeleteLoading }] =
    useDeleteSubmittalMutation();
  const {
    data,
    isLoading: isSubmittalsLoading,
    isFetching: isSubmittalsFetching,
  } = useGetSubmittalsQuery({
    idProject,
    params: {
      ...params,
      ...(params?.createdOn !== null &&
        params?.createdOn?.length > 0 && {
          startDate: params?.createdOn[0],
          endDate: params?.createdOn[1],
        }),
      createdOn: null,
    },
  });

  const submittals = useMemo(() => data ?? [], [data]);

  const [pdfUrl, setPdfUrl] = React.useState(null);

  const deleteSubmittals = async () => {
    try {
      const body = {
        ids: Object.keys(selectedSubmittals),
      };
      const response = await removeSubmittal(body).unwrap();
      if (response) {
        setOpenDelete(false);
        setSelectedSubmittals({});
        PopUpAlert(
          "success",
          tGeneral("done"),
          tGeneral("deletedSuccessfully"),
        );
      }
    } catch (err) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      datadogRum.addError({
        message: "Submittals - Delete - Error",
        stack: err.stack ? err.stack : "invalid stack",
        custom: {
          app: "buildpeer-web",
          enterprise: idEnterprise,
          module: "Submittals",
          project: idProject,
        },
        timestamp: Date.now(),
      });
    }
  };

  const buildExcelData = () => {
    try {
      const selectedSubmittalsKeys = Object.keys(selectedSubmittals);
      if (!selectedSubmittalsKeys.length) {
        throw new Error("");
      }
      const selectedSubmittalsData = submittals.filter((rfi) =>
        selectedSubmittalsKeys.includes(rfi.id),
      );

      const submittalsData = selectedSubmittalsData.map((submittal) => {
        const assignees = submittal.assignedTo
          .map((assignee) => assignee.name)
          .join(", ");

        const distributionList = submittal.distributionListData
          .map((user) => user.name)
          .join(", ");
        return [
          submittal.number,
          submittal.title,
          submittal.status,
          submittal.createdByData?.name ?? "",
          assignees,
          submittal.receivedFromData?.name ?? "",
          customFormatDate(submittal.createDate, "DD/MM/YYYY"),
          customFormatDate(submittal.dueDate, "DD/MM/YYYY"),
          customFormatDate(submittal.closedDate, "DD/MM/YYYY"),
          submittal.typeName ?? "",
          submittal.costCode ?? "",
          submittal.locationName ?? "",
          submittal.specificationName ?? "",
          distributionList,
          submittal.bPrivate ? tGeneral("private") : tGeneral("public"),
        ];
      });

      const excelData = [
        [tGeneral("submittals")],
        [tGeneral("project"), currentProject.projectName],
        [],
        [],
        [
          tGeneral("number"),
          tGeneral("title"),
          tGeneral("status"),
          tGeneral("creator"),
          tGeneral("assigned"),
          tGeneral("receivedFrom"),
          tGeneral("createDate"),
          tGeneral("limitDate"),
          tGeneral("closedDate"),
          tGeneral("type"),
          tSubmittals("costCode"),
          tGeneral("location"),
          tGeneral("specification"),
          tGeneral("distributionList"),
          tGeneral("privacy"),
        ],
        ...submittalsData,
      ];

      return { fileName: tGeneral("submittals"), data: excelData };
    } catch (error) {
      toast.error(
        tGeneral("noSubmittalSelected"),
        tGeneral("selectSubmittalToContinue"),
      );
      return { fileName: tGeneral("submittals"), data: [] };
    }
  };

  return (
    <>
      <TitleWidgets
        title={tSubmittals("submittalTitleModule")}
        supportDisabled
        moduleName="submittals"
        backEnabled={false}
        distributionListName={DISTRIBUTION_LIST_NAMES.submittals}
        showDelete={
          Object.keys(selectedSubmittals).length > 0 &&
          ability.can("delete", "submittals")
        }
        onDelete={() => setOpenDelete(true)}
        enableSupport
        addNameTour="add-submittal-1"
        buildExcelData={buildExcelData}
      />
      <Can I="view" a="submittals">
        <Box sx={{ width: "100%" }}>
          <SubmittalsTable
            submittals={submittals}
            selectedSubmittals={selectedSubmittals}
            setSelectedSubmittals={setSelectedSubmittals}
            setFilters={(val) => setParams(val)}
            isLoading={isSubmittalsLoading || isSubmittalsFetching}
          />
          <ModalWithAction
            isOpen={openDelete}
            onClose={() => setOpenDelete(false)}
            title={tSubmittals("deleteSubmittalsTitle")}
            content={tSubmittals("deleteSubmittalsContent")}
            icon="delete"
            actionLabel={tGeneral("delete")}
            action={deleteSubmittals}
            isLoading={isDeleteLoading}
          />
        </Box>
      </Can>

      {/* If there no access to see this module */}
      <Can I="none" a="submittals">
        {tGeneral("noPermission")}
      </Can>
      {/* For export pdf */}
      {Boolean(pdfUrl) && (
        <ModalFileViewer
          file={{ url: pdfUrl }}
          isOpen={Boolean(pdfUrl)}
          onClose={() => setPdfUrl(null)}
        />
      )}
    </>
  );
}

export default Submittals;
