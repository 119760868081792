import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  downloadingFiles: null,
};

const downloadsSlice = createSlice({
  name: "downloads",
  initialState,
  reducers: {
    setDownloadingFiles: (state, action) => {
      state.downloadingFiles = action.payload;
    },
  },
});

export const selectDownloadingFiles = (state) =>
  state.downloads.downloadingFiles;

export const { setDownloadingFiles } = downloadsSlice.actions;

export default downloadsSlice.reducer;
