import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TooltipIconButton } from "../../../../components/shared";
import { useCommentDrawingMutation } from "../../../../features/drawings";
import { PopUpAlert } from "../../../../components/PopUpAlert";

function CommentDrawingModal({ isOpen, onClose, idDrawing, comment = "" }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");
  const [newComment, setNewComment] = useState(comment);
  const [commentError, setCommentError] = useState(null);

  const [commentDrawing, { isLoading, isSuccess, isError, error, reset }] =
    useCommentDrawingMutation();

  const handleCommentDrawing = () => {
    commentDrawing({ id: idDrawing, comment: newComment });
  };

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert(
        "success",
        tGeneral("success"),
        tGeneral("updatedSuccessfully"),
      );
      onClose();
      reset();
    }
    if (isError) {
      PopUpAlert("error", tGeneral("error"), error.message);
    }
  }, [isSuccess, isError, error, onClose, reset, tGeneral]);

  const handleChangeComment = (e) => {
    const {
      target: { value },
    } = e;

    if (value.length <= 255) {
      setNewComment(e.target.value);
      setCommentError(null);
    } else {
      setCommentError(tGeneral("charactersLimitError"));
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          width: "40%",
          p: "1.5rem 1rem",
          display: "flex",
          flexDirection: "column",
          rowGap: "1rem",
          borderRadius: "1rem",
        }}
      >
        <Box display="flex">
          <Typography flex={1} color="#4B4B4B">
            {tDrawings("commentDrawing")}
          </Typography>
          <TooltipIconButton
            icon={<Close />}
            onClick={onClose}
            label={tGeneral("close")}
            color="#8E8E8E"
          />
        </Box>
        <Box>
          <Typography>{tGeneral("maxCharacters", { max: "255" })}</Typography>
          <TextField
            placeholder={tDrawings("commentPlaceholder")}
            value={newComment}
            onChange={handleChangeComment}
            multiline
            rows={4}
            error={commentError}
            helperText={commentError}
            fullWidth
          />
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            sx={{ borderRadius: "1rem", textTransform: "none" }}
            variant="contained"
            disabled={isLoading}
            onClick={handleCommentDrawing}
          >
            {isLoading ? <CircularProgress size={12} /> : tDrawings("comment")}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}

export { CommentDrawingModal };
