export const plan = {
  perUser: "Per user",
  perProject: "Per project",
  perMonth: "Per month",
  perYear: "Per year",
  monthly: "Monthly",
  annual: "Annual",
  bestValue: "Best value",
  youWillPay: "You will pay",
  activeProjects: "Active Projects",
  activeUsers: "Active Users",
};
