import { tour } from "./tour";

export const projectDirectory = {
  ...tour,
  moduleElement: "ユーザー",
  moduleName: "ディレクトリ",
  // ADD - guide me steps
  step1_addUser_title: "1. プロジェクトに新しいユーザーを追加する",
  step1_addUser_text:
    "ここをクリックしてプロジェクトに新しいユーザーを追加します。",
  step2_addUser_title: "2. ユーザーを選択する",
  step2_addUser_text:
    "ユーザーのフルネームを入力し、次にリストからユーザーを選択する必要があります。",
  step3_addUser_title: "3. ポジション",
  step3_addUser_text: "ユーザーがプロジェクトで持つポジションを入力します。",
  step4_addUser_title: "4. デフォルトの権限",
  step4_addUser_text:
    "連絡先のデフォルトの権限を選択します。閲覧のみ可能か、編集権限がないかを選択します。",
  step5_addUser_title: "5. 個別の権限",
  step5_addUser_text: "各モジュールごとに特定の権限を選択します。",
  step6_addUser_title: "6. 確認",
  step6_addUser_text: "ここをクリックしてユーザーを確認して追加します。",
  step_final_addUser:
    "新しいユーザーをプロジェクトに追加する方法を学びました。",
  // VIEW - guide me steps
  step1_viewUser_title: "1. ユーザーの情報を表示する",
  step1_viewUser_text: "ここをクリックしてユーザーの情報を表示します。",
  step2_viewUser_title: "2. 最終ログイン",
  step2_viewUser_text:
    "ここでは、ユーザーの最終ログインに関する情報を確認できます。",
  step3_viewUser_title: "3. 最近のアクション",
  step3_viewUser_text:
    "ここでは、ユーザーが行った最近のアクションに関する情報を確認できます。",
  step_final_viewUser: "プロジェクトユーザーの情報を表示する方法を学びました。",
  // EDIT - guide me steps
  step1_editUser_title: "1. ユーザーを編集する",
  step1_editUser_text: "ここをクリックしてユーザーを編集します。",
  step2_editUser_title: "2. ポジション",
  step2_editUser_text: "プロジェクトでの連絡先のポジションを編集します。",
  step3_editUser_title: "3. デフォルトの権限",
  step3_editUser_text:
    "連絡先のデフォルトの権限を編集します。閲覧のみ可能か、編集権限がないかを編集します。",
  step4_editUser_title: "4. 個別の権限",
  step4_editUser_text: "各モジュールごとの権限を編集します。",
  step5_editUser_title: "5. 保存",
  step5_editUser_text: "変更内容を保存するにはここをクリックします。",
  step_final_editUser: "ユーザーを編集する方法を学びました。",

  directory: "ディレクトリ",
  addCompany: "会社を追加",
  lastLogin: "最終ログイン",
  infoText: "は接続していません ",
  days: "日",
  wasConnectedToday: "は過去24時間以内に接続されました",
  hasNeverLoggedIn: "はログインしたことがありません ",
  latestActivity: "最新のアクティビティ",
  createRfi: "RFI 24を作成",
  responseSubmittal: "応答提出",
  createDailyReport: "日報を作成",
  createRfi123: "RFI 123を作成",
  uploadPhotos: "写真をアップロード",
  newContact: "新しい連絡先",
  editContact: "連絡先を編集",
  generalInformation: "一般情報",
  permissions: "権限",
  drawings: "図面",
  punchList: "パンチリスト",
  rfis: "RFIs",
  submittals: "提出物",
  deliveries: "納品",
  hours: "時間",
  meetings: "ミーティング",
  photos: "写真",
  tasks: "タスク",
  inspections: "検査",
  issues: "問題",
  dailyReports: "日報",
  documents: "ドキュメント",
  delays: "遅延",
  announcements: "お知らせ",
  dashbooard: "ダッシュボード",
  adminPanel: "管理パネル",
  generalDirectory: "一般ディレクトリ",
  deleteContact: "連絡先を削除",
  confirmDelete:
    "この連絡先を削除してもよろしいですか？この操作は元に戻すことはできません",
  currentPlan: "現在のプラン ",
  activeUsers: " アクティブなユーザー。",
  type: "タイプ",
  user: "ユーザー",
  contact: "連絡先",
  usersLimit: "ユーザー制限",
  confirmUsersLimit:
    "続行して新しいユーザーを追加しますか？この操作により、給与に追加のユーザーが含まれます。",
  deletedRoleMessage:
    "このユーザーの現在の役割は削除されました。新しい役割を選択してください",
};
