import { Box, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";

function ModalVideoViewer({ isOpen, onClose, file }) {
  const [src, setSrc] = useState(null);

  useEffect(() => {
    setSrc(file.url);
  }, [file]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Box>
        <ReactPlayer
          url={src}
          controls
          style={{
            width: "100%",
            height: "calc(100vh - 100px)",
            backgroundColor: "black",
          }}
        />
      </Box>
    </Modal>
  );
}

export { ModalVideoViewer };
