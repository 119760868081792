import { Delete } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TooltipIconButton } from "../../../components/shared";

function AssignedProjects({ projects, onDelete }) {
  const { t: tGeneral } = useTranslation("general");

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent={projects.length > 0 ? "flex-start" : "center"}
      height="300px"
      maxHeight="300px"
      sx={{ overflowY: "auto" }}
    >
      {projects.length === 0 ? (
        tGeneral("noAssignedProjects")
      ) : (
        <>
          <Box display="flex" width="100%" height="40px" alignItems="center">
            <Box width="100px" />
            <Typography sx={{ flex: 1 }} fontWeight="bold">
              {tGeneral("projectName")}
            </Typography>
            <Typography sx={{ flex: 1 }} fontWeight="bold">
              {tGeneral("roleName")}
            </Typography>
            <Typography sx={{ flex: 1 }} fontWeight="bold">
              {tGeneral("position")}
            </Typography>
            <Box width="50px" />
          </Box>
          <Box overflow="auto" width="100%">
            {projects.map((project) => (
              <Box
                display="flex"
                key={project.idProject}
                height="50px"
                width="100%"
                alignItems="center"
              >
                <Box width="100px" display="flex" justifyContent="center">
                  {project.image ? (
                    <img
                      src={project.image}
                      alt={project.projectName}
                      height="40px"
                      width="auto"
                    />
                  ) : (
                    "N/A"
                  )}
                </Box>
                <Typography sx={{ flex: 1 }}>{project.projectName}</Typography>
                <Typography sx={{ flex: 1 }}>{project.roleName}</Typography>
                <Typography sx={{ flex: 1 }}>
                  {project.position?.length > 0 ? project.position : "N/A"}
                </Typography>
                <Box width="50px">
                  <TooltipIconButton
                    icon={<Delete />}
                    onClick={() => onDelete(project.idProject)}
                    label={tGeneral("delete")}
                    color="red"
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
}

export { AssignedProjects };
