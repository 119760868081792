import {
  Cancel,
  CheckCircle,
  HelpOutline,
  HourglassBottom,
  RemoveRedEye,
  WarningAmber,
} from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { StyledMaterialReactTable, TooltipIconButton } from "../../shared";
import { BimViewer } from "./BimViewer";

function BimTable({
  selectedBims,
  setSelectedBims,
  bimData,
  isLoading,
  isFetching,
}) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tBim } = useTranslation("bim");
  const { idBim } = useParams();

  const navigate = useNavigate();

  const columns = [
    {
      header: tGeneral("name"),
      accessorKey: "name",
      Cell: ({ cell }) => <Typography>{cell.getValue()}</Typography>,
      enableSorting: false,
      size: 100,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      header: tGeneral("progress"),
      Header: () => (
        <Box display="flex" columnGap="5px">
          {tGeneral("progress")}{" "}
          <Tooltip title={tBim("progressHelp")}>
            <HelpOutline />
          </Tooltip>
        </Box>
      ),
      accessorKey: "progress",
      Cell: ({ cell }) => (
        <Box width="200px" height="20px" position="relative">
          <Box
            width={`${cell.getValue()}%`}
            height="20px"
            sx={{
              backgroundColor:
                Number(cell.getValue()) < 100 ? "#ffc900" : "#5ce381",
              borderRadius: "1rem",
            }}
            position="absolute"
            left={0}
            zIndex={-1}
          />
          <Typography textAlign="center">{cell.getValue()}%</Typography>
        </Box>
      ),
      enableSorting: false,
      size: 50,
    },
    {
      header: tGeneral("status"),
      Header: () => (
        <Box display="flex" columnGap="5px">
          {tGeneral("status")}{" "}
        </Box>
      ),
      accessorKey: "status",
      Cell: ({ cell }) => (
        <Box width="200px" height="20px" position="relative">
          {["failed", "timeout"].includes(cell.getValue()) ? (
            <Tooltip title={tBim("errorUpload")}>
              <Cancel sx={{ color: "#FF5B2B" }} />
            </Tooltip>
          ) : ["pending", "inprogress"].includes(cell.getValue()) ? (
            <Tooltip title={tBim("pendingUpload")}>
              <HourglassBottom sx={{ color: "#FFC62B" }} />
            </Tooltip>
          ) : (
            <Tooltip title={tBim("successUpload")}>
              <CheckCircle sx={{ color: "green" }} />
            </Tooltip>
          )}
        </Box>
      ),
      enableSorting: false,
      size: 50,
    },
  ];
  return (
    <>
      <StyledMaterialReactTable
        columns={columns}
        isLoading={isLoading || isFetching}
        data={bimData ?? []}
        renderRowActions={({ row }) => (
          <Box display="flex" justifyContent="center">
            <TooltipIconButton
              icon={<RemoveRedEye sx={{ color: "black" }} />}
              onClick={() => navigate(`./${row.original?.id}`)}
              label={tGeneral("view")}
              disabled={Number(row.original?.progress) < 100}
            />
          </Box>
        )}
        enableRowSelection
        selectedRows={selectedBims}
        setSelectedRows={setSelectedBims}
      />
      {idBim && (
        <BimViewer isOpen={Boolean(idBim)} onClose={() => navigate(-1)} />
      )}
    </>
  );
}

export { BimTable };