import { apiSlice } from "../../api/apiSlice";

export const exportPdfApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    exportPhotos1PerPage: builder.mutation({
      query: (body) => ({
        url: "exportPdf/photos/1perPage",
        method: "POST",
        body,
      }),
    }),
    exportPhotos2PerPage: builder.mutation({
      query: (body) => ({
        url: "exportPdf/photos/2perPage",
        method: "POST",
        body,
      }),
    }),
    exportPhotos4PerPage: builder.mutation({
      query: (body) => ({
        url: "exportPdf/photos/4perPage",
        method: "POST",
        body,
      }),
    }),
    exportDailyReport: builder.mutation({
      query: (body) => ({
        url: "exportPdf/dailyReports/individual",
        method: "POST",
        body,
      }),
    }),
    exportDailyReports: builder.mutation({
      query: (body) => ({
        url: "exportPdf/dailyReports/all",
        method: "POST",
        body,
      }),
    }),
    exportRfi: builder.mutation({
      query: (body) => ({
        url: "exportPdf/rfis/individual",
        method: "POST",
        body,
      }),
    }),
    exportRfis: builder.mutation({
      query: (body) => ({
        url: "exportPdf/rfis/all",
        method: "POST",
        body,
      }),
    }),
    exportSubmittal: builder.mutation({
      query: (body) => ({
        url: "exportPdf/submittals/individual",
        method: "POST",
        body,
      }),
    }),
    exportSubmittals: builder.mutation({
      query: (body) => ({
        url: "exportPdf/submittals/all",
        method: "POST",
        body,
      }),
    }),
    exportTask: builder.mutation({
      query: (body) => ({
        url: "exportPdf/tasks/individual",
        method: "POST",
        body,
      }),
    }),
    exportTasks: builder.mutation({
      query: (body) => ({
        url: "exportPdf/tasks/all",
        method: "POST",
        body,
      }),
    }),
    exportMeeting: builder.mutation({
      query: (body) => ({
        url: "exportPdf/meetings/individual",
        method: "POST",
        body,
      }),
    }),
    exportMeetings: builder.mutation({
      query: (body) => ({
        url: "exportPdf/meetings/all",
        method: "POST",
        body,
      }),
    }),
    exportDrawings: builder.mutation({
      query: (body) => ({
        url: "exportPdf/drawings/all",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useExportPhotos1PerPageMutation,
  useExportPhotos2PerPageMutation,
  useExportPhotos4PerPageMutation,
  useExportDailyReportMutation,
  useExportDailyReportsMutation,
  useExportRfiMutation,
  useExportRfisMutation,
  useExportSubmittalMutation,
  useExportSubmittalsMutation,
  useExportTaskMutation,
  useExportTasksMutation,
  useExportMeetingMutation,
  useExportMeetingsMutation,
  useExportDrawingsMutation,
} = exportPdfApiSlice;
