import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uploadingFiles: null,
};

const uploadsSlice = createSlice({
  name: "uploads",
  initialState,
  reducers: {
    setUploadingFiles: (state, action) => {
      state.uploadingFiles = action.payload;
    },
  },
});

export const selectUploadingFiles = (state) => state.uploads.uploadingFiles;

export const { setUploadingFiles } = uploadsSlice.actions;

export default uploadsSlice.reducer;
