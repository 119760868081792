import { Close, Delete, Save, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useGetProjectNetworkQuery } from "../../features/project/modules/projectNetwork/projectNetworkApiSlice";
import { TooltipIconButton } from "./TooltipIconButton";

function ModalShare({
  isOpen,
  onClose,
  onShare,
  sharedWith = [],
  moduleName,
  objectName,
  isLoading,
}) {
  const { idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { data } = useGetProjectNetworkQuery(idProject, { skip: !idProject });

  const projects = useMemo(() => {
    if (data) {
      return data.map((project) => project.relatedProject);
    }
    return [];
  }, [data]);
  const [sharedProjects, setSharedProjects] = useState([]);

  useEffect(() => {
    if (projects.length > 0 && sharedWith.length > 0) {
      setSharedProjects(
        projects.filter((project) => sharedWith.indexOf(project.id) > -1),
      );
    }
  }, [sharedWith, projects]);

  const handleDelete = (idProject) => {
    setSharedProjects((prev) =>
      prev.filter((project) => project.id !== idProject),
    );
  };

  const isAllSelected = useMemo(
    () => projects.length === sharedProjects.length && projects.length > 0,
    [projects, sharedProjects],
  );

  const handleToggleSelectAll = () => {
    if (isAllSelected) {
      setSharedProjects([]);
    } else {
      setSharedProjects(projects);
    }
  };

  const handleShare = () => {
    onShare(sharedProjects.map((project) => project.id));
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card sx={{ width: "30%" }}>
        <Box display="flex" alignItems="center" px="1rem" pt="1rem">
          <Typography fontSize="1.5625rem" flex={1}>
            {tGeneral("share")}
          </Typography>
          <TooltipIconButton
            label={tGeneral("close")}
            color="black"
            onClick={onClose}
            icon={<Close />}
            disabled={isLoading}
          />
          <TooltipIconButton
            label={tGeneral("save")}
            onClick={handleShare}
            icon={<Save />}
            disabled={isLoading}
            isLoading={isLoading}
          />
        </Box>
        <Divider />
        <Box p="1rem">
          <Box display="flex" columnGap="1rem">
            <Typography>{tGeneral(moduleName)}:</Typography>
            <Typography>{objectName}</Typography>
          </Box>
          <Autocomplete
            multiple
            options={projects}
            disableCloseOnSelect
            value={sharedProjects}
            isOptionEqualToValue={(opt, val) => val.id === opt.id}
            onChange={(e, val) => setSharedProjects(val)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox checked={selected} />
                <Typography>{option.projectName}</Typography>
              </li>
            )}
            renderInput={(params) => (
              <Box ref={params.InputProps.ref}>
                <TextField
                  {...params.inputProps}
                  fullWidth
                  label={tGeneral("projects")}
                  sx={{ padding: 0, height: "55px" }}
                  InputProps={{
                    endAdornment: <Search />,
                    sx: { height: "55px", padding: "14px !important" },
                  }}
                />
              </Box>
            )}
            PaperComponent={(paperProps) => {
              const { children, ...restPaperProps } = paperProps;
              return (
                <Paper {...restPaperProps}>
                  <Box
                    onMouseDown={(e) => e.preventDefault()} // prevent blur
                    pl={1.5}
                    py={0.5}
                  >
                    <FormControlLabel
                      onClick={(e) => {
                        e.preventDefault(); // prevent blur
                        handleToggleSelectAll();
                      }}
                      label={tGeneral("selectAll")}
                      control={
                        <Checkbox
                          id="select-all-checkbox"
                          checked={isAllSelected}
                        />
                      }
                    />
                  </Box>
                  <Divider />
                  {children}
                </Paper>
              );
            }}
          />
          <Box
            height="200px"
            maxHeight="200px"
            sx={{ overflowY: "auto" }}
            mt="1rem"
            display="flex"
            flexDirection="column"
            rowGap="0.5rem"
          >
            {sharedProjects.map((project) => (
              <Chip
                key={project.id}
                label={project.projectName}
                deleteIcon={<Delete sx={{ fill: "red" }} />}
                onDelete={() => handleDelete(project.id)}
                sx={{
                  width: "100%",
                  px: "5px",
                  height: "35px",
                  "& .MuiChip-label": { flex: 1 },
                }}
              />
            ))}
          </Box>
        </Box>
      </Card>
    </Modal>
  );
}

export { ModalShare };
