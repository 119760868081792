import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allMessages: [],
  checkedMessage: null,
};

export const messsageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setAllMessages: (state, action) => {
      state.allMessages = action.payload;
    },
    setCheckedMessage: (state, action) => {
      state.checkedMessage = action.payload;
    },
    resetMessages: () => initialState,
  },
});

export const selectAllMessages = (state) => state.message.allMessages;

export const selectCheckedMessage = (state) => state.message.checkedMessage;

export const { setAllMessages, setCheckedMessage, resetMessages } =
  messsageSlice.actions;

export default messsageSlice.reducer;
