export const weather = {
  "thunderstorm-200": "Tempestade com chuva fraca",
  "thunderstorm-201": "Tempestade com chuva",
  "thunderstorm-202": "Tempestade com chuva intensa",
  "thunderstorm-210": "Tempestade fraca",
  "thunderstorm-211": "Tempestade",
  "thunderstorm-212": "Tempestade intensa",
  "thunderstorm-221": "Tempestade isolada",
  "thunderstorm-230": "Tempestade com garoa fraca",
  "thunderstorm-231": "Tempestade com garoa",
  "thunderstorm-232": "Tempestade com garoa intensa",
  "drizzle-300": "Garoa fraca",
  "drizzle-301": "Garoa",
  "drizzle-302": "Garoa intensa",
  "drizzle-310": "Garoa fraca com chuva",
  "drizzle-311": "Garoa com chuva",
  "drizzle-312": "Garoa intensa com chuva",
  "drizzle-313": "Chuva intermitente com garoa",
  "drizzle-314": "Chuva intensa intermitente com garoa",
  "drizzle-321": "Garoa intermitente",
  "rain-500": "Chuva fraca",
  "rain-501": "Chuva moderada",
  "rain-502": "Chuva intensa",
  "rain-503": "Chuva muito intensa",
  "rain-504": "Chuva extrema",
  "rain-511": "Chuva congelante",
  "rain-520": "Chuva fraca intermitente",
  "rain-521": "Chuva intermitente",
  "rain-522": "Chuva intensa intermitente",
  "rain-531": "Chuva intermitente isolada",
  "snow-600": "Neve fraca",
  "snow-601": "Neve",
  "snow-602": "Neve intensa",
  "snow-611": "Chuva com neve",
  "snow-612": "Chuva com neve fraca",
  "snow-613": "Chuva com neve",
  "snow-615": "Chuva e neve fraca",
  "snow-616": "Chuva e neve",
  "snow-620": "Neve intermitente fraca",
  "snow-621": "Neve intermitente",
  "snow-622": "Neve intermitente intensa",
  "mist-701": "Névoa",
  "smoke-711": "Fumaça",
  "haze-721": "Neblina",
  "dust-731": "Redemoinhos de areia/poeira",
  "fog-741": "Nevoeiro",
  "sand-751": "Areia",
  "dust-761": "Poeira",
  "ash-762": "Cinzas vulcânicas",
  "squall-771": "Rajadas de vento",
  "tornado-781": "Tornado",
  "clear-800": "Céu limpo",
  "clouds-801": "Algumas nuvens",
  "clouds-802": "Nuvens dispersas",
  "clouds-803": "Nuvens quebradas",
  "clouds-804": "Nublado",
  sensation: "Sensação T.",
};
