import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import i18n from "i18next";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
// Redux & Redux-Persist
import { initReactI18next } from "react-i18next";
import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import moment from "moment";
import App from "./App";
import defaultTheme from "./assets/theme/defaultTheme";
import { selectLocale } from "./features/preferences/preferencesSlice";
import { en, es, pt, zh, ja, ko } from "./i18n/locales";
import storeConfig from "./store";

const { store, persistor } = storeConfig();

i18n.use(initReactI18next).init({
  fallbackLng: "es",
  resources: {
    en,
    es,
    pt,
    zh,
    ja,
    ko,
  },
});
moment.locale("es", {
  months: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  monthsShort: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ],
  weekdays: [
    "domingo",
    "lunes",
    "martes",
    "miércoles",
    "jueves",
    "viernes",
    "sábado",
  ],
  weekdaysShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
  weekdaysMin: ["D", "L", "M", "X", "J", "V", "S"],
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "DD/MM/YYYY",
    LL: "D [de] MMMM [de] YYYY",
    LLL: "D [de] MMMM [de] YYYY HH:mm",
    LLLL: "dddd, D [de] MMMM [de] YYYY HH:mm",
  },
  calendar: {
    sameDay: "[Hoy a las] LT",
    nextDay: "[Mañana a las] LT",
    nextWeek: "dddd [a las] LT",
    lastDay: "[Ayer a las] LT",
    lastWeek: "[el] dddd [pasado a las] LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "en %s",
    past: "hace %s",
    s: "unos segundos",
    ss: "%d segundos",
    m: "un minuto",
    mm: "%d minutos",
    h: "una hora",
    hh: "%d horas",
    d: "un día",
    dd: "%d días",
    M: "un mes",
    MM: "%d meses",
    y: "un año",
    yy: "%d años",
  },
  dayOfMonthOrdinalParse: /\d{1,2}º/,
  ordinal: "%dº",
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains January 4th is the first week of the year.
  },
});

function Main() {
  const locale = useSelector(selectLocale);

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  return (
    <PersistGate loading={null} persistor={persistor}>
      {/* <ThemeProvider theme={theme.matches ? darkTheme : defaultTheme}> */}
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <BrowserRouter>
          {/* All routes */}
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </PersistGate>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Main />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// This is a comment to trigger Amplify "dev" branch build. It can be deleted anytime.
