import { Business, Check, Close, Email, Phone } from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { PopUpAlert } from "../../../components/PopUpAlert";
import DisplayPermissionsGrid from "../../../components/projectDashboard/projectDirectory/DisplayPermissionsGrid";
import { TooltipIconButton } from "../../../components/shared";
import { useGetRolesFromEnterprisesQuery } from "../../../features/enterprise/enterpriseApiSlice";
import {
  useAddUserToProjectMutation,
  useGetNoAddedUsersQuery,
} from "../../../features/project/projectApiSlice";

function ModalDirectoryAddUser({ isOpen, onClose }) {
  const { idEnterprise, idProject } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { register, watch, reset, handleSubmit, formState, setValue } = useForm(
    {
      defaultValues: {
        idUser: null,
        position: null,
        idRole: null,
      },
    },
  );
  const { data: noAddedUsersData } = useGetNoAddedUsersQuery({
    idEnterprise,
    idProject,
  });

  const { data: enterpriseRolesData } =
    useGetRolesFromEnterprisesQuery(idEnterprise);

  const [
    addToProject,
    { isLoading, isSuccess, isError, error, reset: resetMutation },
  ] = useAddUserToProjectMutation();

  const noAddedUsers = useMemo(
    () => noAddedUsersData ?? [],
    [noAddedUsersData],
  );
  const enterpriseRoles = enterpriseRolesData ?? [];
  const idUser = watch("idUser");
  const selectedUser = useMemo(() => {
    if (idUser && noAddedUsers) {
      return noAddedUsers.find((user) => user.id === idUser);
    }
    return null;
  }, [idUser, noAddedUsers]);
  const idRole = watch("idRole");

  const selectedRole = useMemo(() => {
    if (enterpriseRoles && idRole) {
      return enterpriseRoles.find((role) => role.id === idRole);
    }
    return null;
  }, [idRole, enterpriseRoles]);

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  const onSubmit = (data) => {
    addToProject({ idProject, ...data });
  };

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tGeneral("userAddedSuccessfully"),
      );
      resetMutation();
      handleClose();
    }
    if (isError) {
      PopUpAlert("success", "Error", error.message);
      resetMutation();
      handleClose();
    }
  }, [tGeneral, isSuccess, isError, error, handleClose, resetMutation]);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          sx={{
            width: "60vw",
            p: "25px 40px",
            height: "90vh",
            borderRadius: "20px",
          }}
        >
          <Box display="flex" alignItems="center" pb="1rem">
            <Typography variant="h4" fontWeight="bold" sx={{ flex: 1 }}>
              {tGeneral("add")} {tGeneral("user")}
            </Typography>
            <TooltipIconButton
              label={tGeneral("close")}
              icon={<Close />}
              color="black"
              onClick={handleClose}
              disabled={isLoading}
            />
            <TooltipIconButton
              label={tGeneral("add")}
              icon={isLoading ? <CircularProgress size="1.5rem" /> : <Check />}
              type="submit"
              disabled={!formState.isValid || isLoading}
              data-tour="add-user-6"
            />
          </Box>
          <Divider sx={{ mb: "10px" }} />
          <Box display="flex" data-tour="add-user-2">
            <Autocomplete
              disablePortal
              options={noAddedUsers}
              renderOption={(props, { id, urlAvatar, firstName, lastName }) => (
                <Box {...props} key={id} value={id} display="flex">
                  <Avatar src={urlAvatar}>
                    {firstName?.[0]}
                    {lastName?.[0]}
                  </Avatar>
                  {firstName} {lastName}
                </Box>
              )}
              sx={{ flex: 1 }}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName}`
              }
              onChange={(_, value) =>
                setValue("idUser", value ? value.id : value)
              }
              renderInput={(params) => (
                <TextField {...params} label={tGeneral("user")} />
              )}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            columnGap="20px"
            my="10px"
            height="100px"
          >
            {selectedUser ? (
              <>
                <Avatar
                  src={selectedUser.urlAvatar}
                  sx={{ height: "80px", width: "80px" }}
                >
                  {selectedUser.firstName[0]}
                  {selectedUser.lastName[0]}
                </Avatar>
                <Box>
                  <Box display="flex" columnGap="10px">
                    <Typography fontWeight="700" fontSize="1.5rem">
                      {selectedUser.firstName} {selectedUser.lastName}
                    </Typography>
                  </Box>
                  <Box display="flex" columnGap="1rem" mt="1rem">
                    <Box display="flex" columnGap="10px">
                      <Email sx={{ color: "rgba(32, 33, 36, 0.54)" }} />
                      <Typography
                        component="a"
                        color="rgba(32, 33, 36, 0.54)"
                        href={`mailto:${selectedUser.email}`}
                      >
                        {selectedUser.email}
                      </Typography>
                    </Box>
                    <Box display="flex" columnGap="10px">
                      <Phone sx={{ color: "rgba(32, 33, 36, 0.54)" }} />
                      <Typography color="rgba(32, 33, 36, 0.54)">
                        {selectedUser.phone}
                      </Typography>
                    </Box>
                    <Box display="flex" columnGap="10px">
                      <Business sx={{ color: "rgba(32, 33, 36, 0.54)" }} />
                      <Typography color="rgba(32, 33, 36, 0.54)">
                        {selectedUser.company}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              "No user selected"
            )}
          </Box>
          <Box display="flex" columnGap="10px" mt="10px">
            <Box flex={1}>
              <Typography
                textAlign="center"
                fontSize="1.5rem"
                fontWeight="bold"
                mb={1}
              >
                {tGeneral("position")}
              </Typography>
              <TextField
                label={tGeneral("position")}
                fullWidth
                {...register("position", { required: false })}
                disabled={!selectedUser}
                data-tour="add-user-3"
              />
            </Box>
            <Box flex={1}>
              <Typography
                textAlign="center"
                fontSize="1.5rem"
                fontWeight="bold"
                mb={1}
              >
                {tGeneral("role")}
              </Typography>
              <FormControl fullWidth data-tour="add-user-4">
                <InputLabel>{tGeneral("role")}</InputLabel>
                <Select
                  MenuProps={{ sx: { maxHeight: "300px" } }}
                  label={tGeneral("role")}
                  {...register("idRole", { required: true })}
                  disabled={!selectedUser}
                >
                  {enterpriseRoles.map((role) => (
                    <MenuItem value={role.id} key={role.id}>{role.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Typography
            textAlign="center"
            fontSize="1.5rem"
            fontWeight="bold"
            mb="0.5rem"
            mt="0.5rem"
          >
            {tGeneral("permissions")}
          </Typography>
          <Box height="350px" data-tour="add-user-5">
            <DisplayPermissionsGrid role={selectedRole} maxHeight="280px" />
          </Box>
        </Card>
      </form>
    </Modal>
  );
}

export { ModalDirectoryAddUser };
