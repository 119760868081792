import {
  ArrowDropDown,
  Block,
  BorderColor,
  BorderStyle,
  FormatColorFill,
  LineWeight,
  Opacity,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Popover,
  Select,
  Slider,
} from "@mui/material";
import { useState } from "react";
import { ReactComponent as SolidIcon } from "../../../assets/drawings/solid-line.svg";
import { ReactComponent as CloudIcon } from "../../../assets/drawings/cloud-line.svg";

const COLORS = [
  { R: 248, G: 36, B: 0, A: 1 },
  { R: 243, G: 149, B: 0, A: 1 },
  { R: 255, G: 255, B: 0, A: 1 },
  { R: 110, G: 176, B: 0, A: 1 },
  { R: 34, G: 147, B: 251, A: 1 },
  { R: 255, G: 0, B: 255, A: 1 },
  { R: 255, G: 114, B: 147, A: 1 },
  { R: 255, G: 139, B: 94, A: 1 },
  { R: 252, G: 238, B: 124, A: 1 },
  { R: 162, G: 250, B: 123, A: 1 },
  { R: 141, G: 184, B: 255, A: 1 },
  { R: 245, G: 135, B: 255, A: 1 },
  { R: 255, G: 255, B: 255, A: 1 },
  { R: 192, G: 192, B: 192, A: 1 },
  { R: 128, G: 128, B: 128, A: 1 },
  { R: 64, G: 64, B: 64, A: 1 },
  { R: 0, G: 0, B: 0, A: 1 },
];

function StyleToolbar({ setStyles, showCloud }) {
  const [colorAnchorEl, setColorAnchorEl] = useState(null);
  const [fillAnchorEl, setFillAnchorEl] = useState(null);
  const [opacityAnchorEl, setOpacityAnchorEl] = useState(null);
  const [strokeAnchorEl, setStrokeAnchorEl] = useState(null);
  const [toolColor, setToolColor] = useState({ R: 248, G: 36, B: 0, A: 1 });
  const [toolFill, setToolFill] = useState({ R: 0, G: 0, B: 0, A: 0 });
  const [opacity, setOpacity] = useState(100);
  const [stroke, setStroke] = useState(5);
  const [borderStyle, setBorderStyle] = useState("solid");

  const openColor = Boolean(colorAnchorEl);
  const openFill = Boolean(fillAnchorEl);
  const openOpacity = Boolean(opacityAnchorEl);
  const openStroke = Boolean(strokeAnchorEl);

  const handleChangeColor = (color) => {
    setToolColor(color);
    setStyles({ StrokeColor: color });
    setColorAnchorEl(null);
  };

  const handleChangeFill = (color) => {
    setToolFill(color);
    setStyles({ FillColor: color });
    setFillAnchorEl(null);
  };

  const handleCloseOpacity = () => {
    setStyles({ Opacity: opacity / 100 });
    setOpacityAnchorEl(null);
  };

  const handleCloseStroke = () => {
    setStyles({ StrokeThickness: stroke });
    setStrokeAnchorEl(null);
  };

  const handleChangeBorderStyle = (style) => {
    setBorderStyle(style);
    setStyles({ Style: style });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ backgroundColor: "rgba(0,0,0,0.1)", py: "5px" }}
    >
      <Button
        onClick={(e) => setColorAnchorEl(e.currentTarget)}
        sx={{ columnGap: "5px", alignItems: "center" }}
      >
        <BorderColor sx={{ color: "#000" }} />
        <Box
          sx={{
            backgroundColor: `rgb(${toolColor.R}, ${toolColor.G}, ${toolColor.B})`,
            border: "1px solid black",
            width: "20px",
            height: "20px",
            borderRadius: "20px",
          }}
        />
        <ArrowDropDown sx={{ color: "#000" }} />
      </Button>
      <Divider orientation="vertical" sx={{ height: "30px" }} />
      <Popover
        open={openColor}
        anchorEl={colorAnchorEl}
        onClose={() => setColorAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr 1fr"
          columnGap="5px"
          rowGap="5px"
          p="5px"
        >
          {COLORS.map((color) => (
            <Box
              key={color}
              sx={{
                backgroundColor: `rgb(${color.R}, ${color.G}, ${color.B})`,
                border: "1px solid black",
                width: "20px",
                height: "20px",
                borderRadius: "20px",
                cursor: "pointer",
              }}
              component="div"
              onClick={() => handleChangeColor(color)}
            />
          ))}
        </Box>
      </Popover>
      <Button
        onClick={(e) => setFillAnchorEl(e.currentTarget)}
        sx={{ alignItems: "center", columnGap: "5px" }}
      >
        <FormatColorFill color="secondary" />
        {toolFill === "transparent" ? (
          <Block color="error" sx={{ width: "20px", height: "20px" }} />
        ) : (
          <Box
            sx={{
              backgroundColor: toolFill,
              border: "1px solid black",
              width: "20px",
              height: "20px",
              borderRadius: "20px",
            }}
          />
        )}
        <ArrowDropDown sx={{ color: "#000" }} />
      </Button>
      <Divider orientation="vertical" sx={{ height: "30px" }} />
      <Popover
        open={openFill}
        anchorEl={fillAnchorEl}
        onClose={() => setFillAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr 1fr"
          columnGap="5px"
          rowGap="5px"
          p="5px"
        >
          <Box
            sx={{
              cursor: "pointer",
              p: 0,
              m: 0,
              width: "20px",
              height: "20px",
            }}
            component="div"
            onClick={() => handleChangeFill({ R: 0, G: 0, B: 0, A: 0 })}
          >
            <Block color="error" sx={{ width: "20px", height: "20px" }} />
          </Box>
          {COLORS.map((color) => (
            <Box
              key={`fill-${color}`}
              sx={{
                backgroundColor: `rgb(${color.R}, ${color.G}, ${color.B})`,
                border: "1px solid black",
                width: "20px",
                height: "20px",
                borderRadius: "20px",
                cursor: "pointer",
              }}
              component="div"
              onClick={() => handleChangeFill(color)}
            />
          ))}
        </Box>
      </Popover>
      <Button
        onClick={(e) => setOpacityAnchorEl(e.currentTarget)}
        color="secondary"
      >
        <Opacity color="secondary" />
        {opacity}%
        <ArrowDropDown color="secondary" />
      </Button>
      <Divider orientation="vertical" sx={{ height: "30px" }} />
      <Popover
        open={openOpacity}
        anchorEl={opacityAnchorEl}
        onClose={handleCloseOpacity}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box p="5px 15px 5px 10px">
          <Slider
            min={0}
            max={100}
            value={opacity}
            onChange={(e, val) => setOpacity(val)}
            sx={{ width: "150px" }}
          />
        </Box>
      </Popover>
      <Button
        onClick={(e) => setStrokeAnchorEl(e.currentTarget)}
        color="secondary"
      >
        <LineWeight color="secondary" />
        {stroke} pt
        <ArrowDropDown />
      </Button>
      <Popover
        open={openStroke}
        anchorEl={strokeAnchorEl}
        onClose={handleCloseStroke}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box p="5px 15px 5px 10px">
          <Slider
            min={0}
            max={40}
            value={stroke}
            onChange={(e, val) => setStroke(val)}
            sx={{ width: "150px" }}
          />
        </Box>
      </Popover>
      <Divider orientation="vertical" sx={{ height: "30px" }} />
      {showCloud && (
        <Box display="flex" alignItems="center" columnGap="5px">
          <BorderStyle />
          <Select
            size="small"
            onChange={(e) => handleChangeBorderStyle(e.target.value)}
            value={borderStyle}
            renderValue={() =>
              borderStyle === "solid" ? (
                <SolidIcon
                  style={{ color: "#000", height: "24px", width: "50px" }}
                />
              ) : (
                <CloudIcon
                  style={{ color: "#000", height: "24px", width: "50px" }}
                />
              )
            }
          >
            <MenuItem value="solid">
              <SolidIcon
                style={{ color: "#000", height: "24px", width: "50px" }}
              />
            </MenuItem>
            <MenuItem value="cloudy">
              <CloudIcon
                style={{ color: "#000", height: "24px", width: "50px" }}
              />
            </MenuItem>
          </Select>
        </Box>
      )}
    </Box>
  );
}

export { StyleToolbar };
