import { ArrowBack, Folder } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PopUpAlert } from "../../../../../components/PopUpAlert";
import { useAppTourContext } from "../../../../../components/supportButton/context/context";
import { useMoveDocumentMutation } from "../../../../../features/project/modules/documents/documentsApiSlice";
import { useMoveFolderMutation } from "../../../../../features/project/modules/documents/projectFolderApiSlice";
import { selectCurrentGuideMe } from "../../../../../features/project/projectSlice";
import DirTree from "../DirTree";

function MoveModal({ isOpen, onClose, action, object, path, projectName }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDocuments } = useTranslation("documents");

  const [movingPath, setMovingPath] = useState([]);

  const [
    moveDocument,
    {
      isLoading: isMoveDocumentLoading,
      isSuccess: isMoveDocumentSuccess,
      isError: isMoveDocumentError,
      error: moveDocumentError,
    },
  ] = useMoveDocumentMutation();

  const [
    moveFolder,
    {
      isLoading: isMoveFolderLoading,
      isSuccess: isMoveFolderSuccess,
      isError: isMoveFolderError,
      error: moveFolderError,
    },
  ] = useMoveFolderMutation();

  useEffect(() => {
    if (isMoveDocumentSuccess || isMoveFolderSuccess) {
      PopUpAlert(
        "success",
        tGeneral("moved"),
        isMoveDocumentSuccess
          ? tDocuments("documentMovedMessage")
          : tDocuments("folderMovedMessage"),
      );
      onClose();
    }

    if (isMoveDocumentError || isMoveFolderError) {
      PopUpAlert(
        "error",
        "Error",
        isMoveDocumentError
          ? moveDocumentError.message
          : moveFolderError.message,
      );
      onClose();
    }
  }, [
    isMoveDocumentSuccess,
    isMoveFolderSuccess,
    isMoveDocumentError,
    isMoveFolderError,
    moveDocumentError,
    moveFolderError,
    onClose,
    tDocuments,
    tGeneral,
  ]);

  useEffect(() => {
    if (path) {
      setMovingPath(path);
    }
  }, [path]);

  const {
    state: { tourActive },
    setState,
  } = useAppTourContext();
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  useLayoutEffect(() => {
    if (tourActive && currentGuideMe === "docs-move-to") {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, currentGuideMe]);

  const handleAddPath = (newFolder, replace = false) => {
    if (!newFolder) {
      setMovingPath([]);
      return;
    }
    const newPath = [...movingPath];
    const index = newPath.findIndex((p) => p.id === newFolder.id);
    if (replace) {
      newPath.splice(newPath.length - 1, 1, newFolder);
    } else if (index === -1) {
      newPath.push(newFolder);
    }
    setMovingPath(newPath);
  };

  const isAnotherParent = useMemo(() => {
    if (path && movingPath) {
      const oldParent =
        path.length > 0 ? path[Number(path?.length) - 1]?.id : null;
      const newParent =
        movingPath.length > 0 ? movingPath[movingPath.length - 1]?.id : null;
      return oldParent === newParent;
    }
    return true;
  }, [path, movingPath]);

  const handleMove = () => {
    const newParent =
      movingPath.length > 0 ? movingPath[movingPath.length - 1]?.id : null;
    if (object.isFolder) {
      moveFolder({ id: object.id, idParentFolder: newParent });
    } else {
      moveDocument({ id: object.id, idProjectFolder: newParent });
    }
  };

  const handleBackPath = () => {
    const currPath = [...movingPath];
    currPath.pop();
    setMovingPath(currPath);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          width: "30%",
          height: "80%",
          borderRadius: "20px",
          p: "25px 40px",
        }}
      >
        <Box
          display="flex"
          flex={1}
          alignItems="center"
          justifyContent="space-between"
          mb="15px"
        >
          <Typography variant="h6" fontWeight="bold">
            {action === "folder"
              ? tGeneral("moveFolderTo")
              : tGeneral("moveFileTo")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          sx={{ maxWidth: "100%" }}
          columnGap="5px"
        >
          <IconButton
            onClick={handleBackPath}
            disabled={!movingPath.length}
            sx={{ p: 0, m: 0 }}
          >
            <ArrowBack />
          </IconButton>
          <Folder />/
          <Button
            variant="text"
            color="secondary"
            sx={{ textTransform: "none", minWidth: "max-content", p: 0, m: 0 }}
          >
            {projectName}
          </Button>
          <Box
            display="flex"
            sx={{
              maxWidth: "100%",
              overflowX: "auto",
              "&::-webkit-scrollbar": { width: "2px" },
            }}
            alignItems="flex-start"
          >
            {movingPath.map((dir) => (
              <Box
                key={dir.id}
                display="flex"
                alignItems="flex-start"
                minWidth="max-content"
              >
                <Typography>/</Typography>
                <Button
                  variant="text"
                  color="secondary"
                  sx={{ textTransform: "none", p: 0, m: 0 }}
                >
                  {dir.name}
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{ maxHeight: "450px", overflowY: "auto" }}
          data-tour="docs-move-to-3"
        >
          <DirTree
            addPath={handleAddPath}
            selectedFolders={movingPath}
            disabledFolder={object.id}
          />
        </Box>
        {/* Action Buttons */}
        <Box
          display="flex"
          justifyContent="space-around"
          mb="20px"
          columnGap="1rem"
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            sx={{
              backgroundColor: "white",
              color: "black",
              py: ".8rem",
              border: "1px solid black",
              ":hover": {
                backgroundColor: "lightgrey",
              },
            }}
            fullWidth
          >
            {tGeneral("cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleMove}
            fullWidth
            disabled={
              isAnotherParent || isMoveFolderLoading || isMoveDocumentLoading
            }
            sx={{ py: ".8rem" }}
            data-tour="docs-move-to-4"
          >
            {isMoveDocumentLoading || isMoveFolderLoading ? (
              <CircularProgress color="primary" />
            ) : (
              <Typography>{tGeneral("accept")}</Typography>
            )}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}

export default MoveModal;
