import { Close } from "@mui/icons-material";
import { Box, Card, IconButton, Modal, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { useTranslation } from "react-i18next";

const HISTORY = [
  {
    id: "13k1lkasdla",
    date: new Date(),
    modifiedBy: "Raul Esparza",
    isFolder: true,
    action: "move",
    from: "00 Draw",
    to: "Recycle Bin",
  },
];

function ChangeHistoryModal({ isOpen, onClose, file }) {
  const { t: tGeneral } = useTranslation("general");
  const columns = [
    {
      field: "modified",
      headerName: tGeneral("modified"),
      flex: 1,
      valueGetter: (params) =>
        `${moment(params.row.date).format("DD MMMM YYYY hh:mmA")} By ${
          params.row.modifiedBy
        }`,
    },
    {
      headerName: tGeneral("modification"),
      field: tGeneral("modification"),
      flex: 1,
      valueGetter: (params) =>
        `${params.row.isFolder ? "folder" : "file"} ${params.row.action}`,
    },
    {
      headerName: tGeneral("from"),
      field: "from",
      flex: 1,
    },
    {
      headerName: tGeneral("to"),
      field: "to",
      flex: 1,
    },
  ];
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          height: "50%",
          width: "80%",
          p: "40px 30px",
          borderRadius: "20px",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb="30px"
        >
          <Typography fontSize="1.563rem" fontWeight="bold">
            {tGeneral("changeHistory")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <DataGrid
          sx={{ height: "calc(80%)" }}
          columns={columns}
          rows={HISTORY}
        />
      </Card>
    </Modal>
  );
}

export { ChangeHistoryModal };
