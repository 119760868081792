import {
  CloudUpload,
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
// Download PDF
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";
import JSZip from "jszip";
import {
  Albums,
  PhotoGallery,
  UploadPhoto,
} from "../../../components/projectDashboard/Photos";
import { CreateAlbum } from "../../../components/projectDashboard/Photos/CreateAlbum";
import {
  ModalDistributionList,
  ModalWithAction,
  TitleWidgets,
} from "../../../components/shared";
import { ModalFileViewer } from "../../../components/shared/ModalFileViewer";
import {
  DISTRIBUTION_LIST_NAMES,
  PHOTO_EXPORT_DOWNLOAD_LIMIT,
  VIDEO_EXTENSIONS,
} from "../../../constants";
import { useGetAllProjectImagesQuery } from "../../../features/project/imageApiSlice";
import {
  selectAddAlbumModalOpen,
  selectAddPhotoModalOpen,
  selectSettingsPhotosPopupOpen,
} from "../../../features/project/modules/rfiSlice";
import {
  selectCurrentGuideMe,
  selectCurrentRole,
} from "../../../features/project/projectSlice";
import {
  useAlbumGallery,
  useDistributionList,
  useImageGallery,
  usePagination,
} from "../../../hooks";
import CaslContext from "../../../utils/caslContext";
import { useAppTourContext } from "../../../components/supportButton/context/context";
import { SearchUserFilter } from "../../../components/shared/SearchUserFilter";
import { SearchLocationFilter } from "../../../components/shared/SearchLocationFilter";
import { DateRangeFilter } from "../../../components/shared/DateRangeFilter";
import { selectCurrentToken } from "../../../features/auth/authSlice";
import { PopUpAlert } from "../../../components/PopUpAlert";
import { useToast } from "../../../contexts/ToastContext";
import { setDownloadingFiles } from "../../../features/drawings/downloadsSlice";

function Photos() {
  const { t: tPhotos } = useTranslation("photos");
  const { t: tGeneral } = useTranslation("general");
  const { state } = useLocation();
  const { idEnterprise, idProject } = useParams();
  const toast = useToast();

  const dispatch = useDispatch();
  const ability = React.useContext(CaslContext);

  const [showTab, setShowTab] = React.useState(0);
  const [isUploadOpen, setIsUploadOpen] = React.useState(false);
  const [isCreateOpen, setIsCreateOpen] = React.useState(false);
  const [selectedLocations, setSelectedLocations] = React.useState(null);
  const [selectedUsers, setSelectedUsers] = React.useState(null);
  const [selectedDateRange, setSelectedDateRange] = React.useState(null);
  const [selectedDateRangeCreated, setSelectedDateRangeCreated] =
    React.useState(null);
  const [openAddFilter, setOpenAddFilter] = React.useState(false);
  const [pdfUrl, setPdfUrl] = React.useState(null);
  const [groupBy, setGroupBy] = React.useState("month");
  const openModalAddPhoto = useSelector(selectAddPhotoModalOpen);
  const openModalAddAlbum = useSelector(selectAddAlbumModalOpen);
  const token = useSelector(selectCurrentToken);
  const {
    state: { tourActive, stepIndex, steps },
    setState,
  } = useAppTourContext();
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const {
    currentPage,
    pages,
    perPage,
    handleLastPage,
    handleNextPage,
    handlePerPage,
    handlePrevPage,
    handleFirstPage,
    handleTotalItems,
  } = usePagination();

  const { data: retreivedImages } = useGetAllProjectImagesQuery(
    {
      projectId: idProject,
      uploadedBy:
        selectedUsers !== null
          ? selectedUsers?.map((user) => user.id).join(",")
          : "",
      locations:
        selectedLocations?.map((location) => location.id).join(",") ?? "",
      ...(selectedDateRange !== null &&
        selectedDateRange !== "" && { startDate: selectedDateRange[0] }),
      ...(selectedDateRange !== null &&
        selectedDateRange !== "" && { endDate: selectedDateRange[1] }),
      ...(selectedDateRangeCreated !== null &&
        selectedDateRangeCreated !== "" && {
          startDateCreated: selectedDateRangeCreated[0],
        }),
      ...(selectedDateRangeCreated !== null &&
        selectedDateRangeCreated !== "" && {
          endDateCreated: selectedDateRangeCreated[1],
        }),
      perPage,
      currentPage,
      groupBy,
    },
    { skip: showTab === 1 },
  );

  const settingsPopupOpen = useSelector(selectSettingsPhotosPopupOpen);

  React.useEffect(() => {
    handleTotalItems(retreivedImages?.total);
  }, [retreivedImages, handleTotalItems]);

  const {
    selectedDates,
    selectedImages,
    handleSelectImage,
    isDeleteLoading,
    handleDeleteImages,
    onOpenDelete,
    onCloseDelete,
    isDeleteOpen,
    handleClearImageGallery,
  } = useImageGallery();

  const {
    isDeleteLoading: isDeleteAlbumsLoading,
    isRestoreLoading,
    isAlbumsOpen,
    handleOpen,
    handleClose,
    handleAction,
    handleSelectAlbum,
    selectedAlbums,
    handleClearAlbumGallery,
  } = useAlbumGallery(false);
  const {
    getSelectedUsers,
    projectUsers,
    handleCloseDistributionList,
    handleUpdateConfig,
    handleOpenDistributionList,
    isDistributionListOpen,
  } = useDistributionList(idProject, "photos");

  const users = React.useMemo(() => {
    if (projectUsers) {
      return projectUsers.map((user) => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        urlAvatar: user.urlAvatar,
        ...user,
      }));
    }
    return [];
  }, [projectUsers]);

  const userRole = useSelector(selectCurrentRole);

  React.useEffect(() => {
    if (state?.stateShowTab) {
      setShowTab(state.stateShowTab);
    }
  }, [state]);

  React.useEffect(() => {
    if (openModalAddPhoto === true) {
      setIsUploadOpen(true);
    } else {
      setIsUploadOpen(false);
    }
  }, [openModalAddPhoto]);

  React.useEffect(() => {
    if (openModalAddAlbum === true) {
      setIsCreateOpen(true);
    } else {
      setIsCreateOpen(false);
    }
  }, [openModalAddAlbum]);

  const selectedImagesFiltered = React.useMemo(() => {
    let filteredImages = [];

    if (retreivedImages?.results) {
      const allImages = retreivedImages.results.reduce(
        (prev, curr) => [...prev, ...curr.photos],
        [],
      );

      if (selectedImages.length > 0) {
        filteredImages = [
          ...filteredImages,
          ...allImages.filter((image) => selectedImages.indexOf(image.id) > -1),
        ];
      }

      if (selectedAlbums.length > 0) {
        filteredImages = [
          ...filteredImages,
          ...allImages.filter(
            (image) => selectedAlbums.indexOf(image.albumId) > -1,
          ),
        ];
      }
    }

    return filteredImages;
  }, [retreivedImages, selectedImages, selectedAlbums]);

  const handleAddFilter = (filter) => {
    if (filter === "location") {
      setSelectedLocations((prev) => (prev === null ? [] : prev));
    } else if (filter === "uploadedOn") {
      setSelectedDateRange((prev) => (prev === null ? "" : prev));
    } else if (filter === "createdOn") {
      setSelectedDateRangeCreated((prev) => (prev === null ? "" : prev));
    } else {
      setSelectedUsers((prev) => (prev === null ? [] : prev));
    }
    setOpenAddFilter(false);
  };

  const handleExportImages = async (type) => {
    if (selectedImagesFiltered.length === 0) return;

    if (selectedImagesFiltered.length > PHOTO_EXPORT_DOWNLOAD_LIMIT) {
      PopUpAlert(
        "warning",
        tGeneral("exportPdf"),
        tGeneral("exportPdfLimitWarning"),
        4000,
        "30rem",
      );
      return;
    }

    const allImages = retreivedImages.results.reduce(
      (prev, curr) => [...prev, ...curr.photos],
      [],
    );

    const downloadImages = allImages?.filter(
      (image) => selectedImages.indexOf(image.id) > -1,
    );

    let timeout = 500;
    if (
      downloadImages.some((i) =>
        VIDEO_EXTENSIONS.includes(i.name.split(".").pop().toLowerCase()),
      )
    ) {
      PopUpAlert(
        "info",
        tGeneral("exportPdf"),
        tGeneral("exportPdfVideoWarning"),
        2000,
        "30rem",
      );

      // Increase timeout to allow user to read the warning message and the loading spinner to show
      timeout = 2250;
    }

    setTimeout(async () => {
      dispatch(
        setDownloadingFiles([
          {
            type: "export",
            module: "photos",
            export: {
              data: {
                album: selectedAlbums,
                photos: selectedImages,
                type,
              },
              token,
              url: `${process.env.REACT_APP_BACKEND_URL}pdf/${idEnterprise}/${idProject}/photos`,
              download: "photos",
              module: "photos",
              tGeneral,
            },
          },
        ]),
      );
    }, timeout);
  };

  const handleDownload = async () => {
    if (selectedImagesFiltered.length === 0) return;

    const allImages = retreivedImages.results.reduce(
      (prev, curr) => [...prev, ...curr.photos],
      [],
    );
    const downloadImages = allImages?.filter(
      (image) => selectedImages.indexOf(image.id) > -1,
    );

    dispatch(
      setDownloadingFiles([
        {
          module: "photos",
          files: downloadImages,
          type: downloadImages.length > 1 ? "multiple" : "single",
        },
      ]),
    );
  };

  React.useEffect(() => {
    // Photo
    if (
      tourActive &&
      stepIndex === 0 &&
      (currentGuideMe === "photos-add" ||
        currentGuideMe === "photos-view" ||
        currentGuideMe === "photos-export")
    ) {
      setShowTab(0);
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }

    if (
      tourActive &&
      (stepIndex === 1 || stepIndex === steps.length - 2) &&
      currentGuideMe === "photos-add"
    ) {
      setIsUploadOpen(true);
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
    if (
      tourActive &&
      (stepIndex === steps.length - 1 || stepIndex === 0) &&
      currentGuideMe === "photos-add"
    ) {
      setIsUploadOpen(false);
    }

    // Album
    if (tourActive && stepIndex === 0 && currentGuideMe === "photos-edit") {
      setShowTab(1);
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }

    if (
      tourActive &&
      (stepIndex === 1 || stepIndex === steps.length - 2) &&
      currentGuideMe === "photos-edit"
    ) {
      setIsCreateOpen(true);
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }

    if (
      tourActive &&
      (stepIndex === steps.length - 1 || stepIndex === 0) &&
      currentGuideMe === "photos-edit"
    ) {
      setIsCreateOpen(false);
    }
  }, [tourActive, stepIndex, steps, currentGuideMe, setState]);

  React.useEffect(() => {
    if (settingsPopupOpen === true) {
      handleOpenDistributionList();
    } else {
      handleCloseDistributionList();
    }
  }, [
    settingsPopupOpen,
    handleCloseDistributionList,
    handleOpenDistributionList,
  ]);

  return (
    <Box sx={{ width: "100%", height: "80vh" }}>
      <TitleWidgets
        title={tPhotos("photos")}
        distributionListName={DISTRIBUTION_LIST_NAMES.image}
        moduleName="photos"
        addFunction={() => {
          if (showTab) {
            setIsCreateOpen(true);
          } else {
            setIsUploadOpen(true);
          }
        }}
        showDelete={
          (selectedImages.length > 0 || selectedAlbums.length > 0) &&
          ability.can("delete", "photos")
        }
        onDelete={() => {
          if (showTab === 1) {
            handleOpen();
          } else {
            onOpenDelete();
          }
        }}
        showDownload={selectedImages.length > 0}
        onDownload={() => handleDownload()}
        customElement={
          <>
            {selectedImagesFiltered.length > 0 && (
              <InputLabel id="actions-label">{`${selectedImagesFiltered.length}/${PHOTO_EXPORT_DOWNLOAD_LIMIT}`}</InputLabel>
            )}
            <FormControl
              disabled={!selectedImages.length || !selectedImages.length}
              size="small"
              sx={{ width: "200px" }}
            >
              <InputLabel id="actions-label">
                {tGeneral("exportPdf")}
              </InputLabel>
              <Select
                labelId="actions-label"
                id="actions-select"
                label={tGeneral("exportPdf")}
                autoWidth
                data-tour="export-photos-2"
                onChange={() => null}
                value=""
              >
                <ListSubheader>{`PDF - ${tPhotos(
                  "selectedPhotos",
                )}`}</ListSubheader>
                <MenuItem
                  value="1perpage"
                  onClick={() => handleExportImages("1perPage")}
                >
                  {`1 ${tPhotos("perPage")}`}
                </MenuItem>
                <MenuItem
                  value="2perpage"
                  onClick={() => handleExportImages("2perPage")}
                >
                  {`2 ${tPhotos("perPage")}`}
                </MenuItem>
                <MenuItem
                  value="4perpage"
                  onClick={() => handleExportImages("4perPage")}
                >
                  {`4 ${tPhotos("perPage")}`}
                </MenuItem>
              </Select>
            </FormControl>
          </>
        }
        enableSupport
        showMoreOptions={
          ability.can("settings", "photos") || ability.can("restore", "photos")
        }
        addNameTour="add-photos-1"
      />

      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" columnGap="10px" p="10px" flex={1}>
          <FormControl sx={{ minWidth: "150px" }} size="small">
            <InputLabel shrink={false}>{tGeneral("addFilter")}</InputLabel>
            <Select
              open={openAddFilter}
              onClose={() => {
                setOpenAddFilter(false);
              }}
              onOpen={() => {
                setOpenAddFilter(true);
              }}
              onChange={() => null}
              value=""
            >
              {showTab === 0 && (
                <div>
                  <MenuItem
                    value="location"
                    onClick={() => handleAddFilter("location")}
                  >
                    {tGeneral("location")}
                  </MenuItem>
                  <MenuItem
                    value="uploadedOn"
                    onClick={() => handleAddFilter("uploadedOn")}
                  >
                    {tPhotos("dateUploaded")}
                  </MenuItem>
                  <MenuItem
                    value="createdOn"
                    onClick={() => handleAddFilter("createdOn")}
                  >
                    {tGeneral("takenOn")}
                  </MenuItem>
                </div>
              )}
              <MenuItem
                value="createdBy"
                onClick={() => handleAddFilter("uploadedBy")}
              >
                {tGeneral(showTab ? "createdBy" : "uploadedBy")}
              </MenuItem>
            </Select>
          </FormControl>
          {selectedLocations !== null && (
            <SearchLocationFilter
              title={tGeneral("location")}
              selectedLocations={selectedLocations}
              onChange={(list) => {
                setSelectedLocations(list.length > 0 ? list : null);
              }}
              selectProps={{
                ml: 1,
                width: "200px",
              }}
            />
          )}
          {selectedUsers !== null && (
            <SearchUserFilter
              title={tGeneral(showTab ? "createdBy" : "uploadedBy")}
              userList={users}
              selectedUsersList={users.filter(
                (user) =>
                  selectedUsers
                    .map((user) => user.id)
                    .findIndex((creatorUser) => creatorUser === user.id) > -1,
              )}
              selectedUsersFormatted={selectedUsers.map((user) => user.id)}
              handleUpdateUsersList={(list) => {
                setSelectedUsers(list.length > 0 ? list : null);
              }}
              selectProps={{
                ml: 1,
                width: "200px",
              }}
            />
          )}
          {/* Uploaded on */}
          {selectedDateRange !== null && showTab === 0 && (
            <DateRangeFilter
              onChange={(stringDates) => {
                setSelectedDateRange(stringDates);
              }}
              customIcon={<CloudUpload fontSize="small" />}
            />
          )}
          {/* Created on */}
          {selectedDateRangeCreated !== null && showTab === 0 && (
            <DateRangeFilter
              onChange={(stringDates) => {
                setSelectedDateRangeCreated(stringDates);
              }}
            />
          )}
        </Box>
        {showTab === 0 ? (
          <>
            <Tabs
              value={groupBy}
              onChange={(_, newValue) => {
                setGroupBy(newValue);
                handleClearAlbumGallery();
                handleClearImageGallery();
              }}
            >
              <Tab value="day" label={tGeneral("day")} />
              <Tab value="week" label={tGeneral("week")} />
              <Tab value="month" label={tGeneral("month")} />
            </Tabs>
            <Divider orientation="vertical" flexItem sx={{ mx: "10px" }} />
          </>
        ) : (
          <Box />
        )}
        <Tabs
          value={showTab}
          onChange={(_, newValue) => {
            handleClearAlbumGallery();
            handleClearImageGallery();
            setSelectedDateRange(null);
            setSelectedDateRangeCreated(null);
            setShowTab(newValue);
          }}
        >
          <Tab label={tPhotos("timeline")} />
          <Tab label={tPhotos("albums")} />
        </Tabs>
      </Box>
      <Divider />
      {showTab === 0 ? (
        <PhotoGallery
          images={retreivedImages}
          selectedImages={selectedImages}
          selectedDates={selectedDates}
          setSelectedImages={handleSelectImage}
          add={() => setIsUploadOpen(true)}
          userRole={userRole}
          hasFilters={selectedLocations !== null || selectedUsers !== null}
        />
      ) : (
        <Albums
          selectedAlbums={selectedAlbums}
          handleSelectAlbum={handleSelectAlbum}
          selectedUsers={selectedUsers}
          selectedLocations={selectedLocations}
          skip={showTab === 0}
          add={() => setIsCreateOpen(true)}
          userRole={userRole}
        />
      )}
      {showTab === 0 && retreivedImages?.results.length > 0 && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          mt="20px"
        >
          <Typography>{tPhotos("photosPerPage")}</Typography>
          <Select
            size="small"
            value={perPage}
            onChange={(e) => {
              handlePerPage(e.target.value);
            }}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent !important",
              },
            }}
          >
            <MenuItem value={10}>10 {tPhotos("photos").toLowerCase()}</MenuItem>
            <MenuItem value={20}>20 {tPhotos("photos").toLowerCase()}</MenuItem>
            <MenuItem value={30}>30 {tPhotos("photos").toLowerCase()}</MenuItem>
            <MenuItem value={100}>
              100 {tPhotos("photos").toLowerCase()}
            </MenuItem>
          </Select>
          <Box display="flex" alignItems="center">
            <IconButton onClick={handleFirstPage} disabled={currentPage === 0}>
              <FirstPage />
            </IconButton>
            <IconButton onClick={handlePrevPage} disabled={currentPage === 0}>
              <NavigateBefore />
            </IconButton>

            <Typography>
              {currentPage + 1} of {pages}
            </Typography>
            <IconButton
              onClick={handleNextPage}
              disabled={currentPage === pages - 1}
            >
              <NavigateNext />
            </IconButton>
            <IconButton
              onClick={handleLastPage}
              disabled={currentPage === pages - 1}
            >
              <LastPage />
            </IconButton>
          </Box>
        </Box>
      )}
      {isUploadOpen && (
        <UploadPhoto
          isOpen={isUploadOpen}
          onClose={() => setIsUploadOpen(false)}
        />
      )}
      {isCreateOpen && (
        <CreateAlbum
          isOpen={isCreateOpen}
          onClose={() => setIsCreateOpen(false)}
        />
      )}
      <ModalWithAction
        isOpen={isDeleteOpen}
        onClose={onCloseDelete}
        title={tGeneral("delete")}
        content={tPhotos("deleteImagesContent")}
        actionLabel={tGeneral("delete")}
        isLoading={isDeleteLoading}
        action={handleDeleteImages}
      />
      <ModalWithAction
        isOpen={isAlbumsOpen}
        onClose={handleClose}
        title={tGeneral("delete")}
        content={tPhotos("deleteAlbumsContent")}
        actionLabel={tGeneral("delete")}
        action={handleAction}
        isLoading={isDeleteAlbumsLoading || isRestoreLoading}
      />
      {isDistributionListOpen && (
        <ModalDistributionList
          isOpen={isDistributionListOpen}
          onClose={handleCloseDistributionList}
          users={projectUsers}
          selectedUsers={getSelectedUsers(
            showTab ? "albumDistributionList" : "imageDistributionList",
          )}
          updateConfig={(distributionList) =>
            handleUpdateConfig(
              distributionList,
              showTab ? "albumDistributionList" : "imageDistributionList",
            )
          }
        />
      )}
      {Boolean(pdfUrl) && (
        <ModalFileViewer
          file={{ url: pdfUrl }}
          isOpen={Boolean(pdfUrl)}
          onClose={() => setPdfUrl(null)}
        />
      )}
    </Box>
  );
}

export { Photos };
