import { Box, Tab, Tabs, Typography } from "@mui/material";
import moment from "moment";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowIcon } from "../../../assets/drawings/arrow.svg";
import { ReactComponent as EllipseIcon } from "../../../assets/drawings/ellipse.svg";
import { ReactComponent as LineIcon } from "../../../assets/drawings/line.svg";
import { ReactComponent as PolylineIcon } from "../../../assets/drawings/polyline.svg";
import { ReactComponent as RectangleIcon } from "../../../assets/drawings/rectangle.svg";

import { FormatShapes, Link, TextSnippet } from "@mui/icons-material";
import { useParams } from "react-router";
import { useGetProjectByIdQuery } from "../../../features/project/projectApiSlice";

const TABS = {
  personal: "personal",
  public: "public",
};

function DrawingsAnnotationList({ annotations }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");

  const { idProject } = useParams();

  const [tab, setTab] = useState(TABS.personal);

  const { data: project } = useGetProjectByIdQuery(idProject, {
    skip: !idProject,
  });

  const tabAnnotations = useMemo(() => {
    if (annotations) {
      return annotations.filter((annot) =>
        tab === TABS.public ? annot.bPublished : !annot.bPublished,
      );
    }
    return [];
  }, [annotations, tab]);

  const getAnnotationTitle = (annotation) => {
    const type = annotation.type.replace(" ", "").toLowerCase();
    if (type === "freetext" && annotation.text) {
      return annotation.text;
    }
    return tDrawings(type);
  };

  const getAnnotationIcon = (annotation) => {
    const type = annotation.type.replace(" ", "").toLowerCase();
    if (type === "rectangle") {
      return <RectangleIcon height="24px" width="24px" />;
    }
    if (type === "ellipse") {
      return <EllipseIcon height="24px" width="24px" />;
    }
    if (type === "arrow") {
      return <ArrowIcon height="24px" width="24px" />;
    }
    if (type === "line") {
      return <LineIcon height="24px" width="24px" />;
    }
    if (type === "polyline") {
      return <PolylineIcon height="24px" width="24px" />;
    }
    if (type === "freetext") {
      return <FormatShapes />;
    }
    return <TextSnippet />;
  };

  return (
    <Box sx={{ backgroundColor: "#fff", height: "100%" }}>
      <Tabs value={tab} onChange={(_e, val) => setTab(val)} variant="fullWidth">
        <Tab value={TABS.personal} label={tGeneral("personal")} />
        <Tab value={TABS.public} label={tGeneral("public")} />
      </Tabs>
      {tabAnnotations.map((annot) => (
        <Box
          display="flex"
          key={annot.id}
          alignItems="center"
          columnGap="10px"
          p="5px"
        >
          <Box height="20px">{getAnnotationIcon(annot)}</Box>
          <Box>
            <Box display="flex" columnGap="12px" alignItems="center">
              <Typography>{getAnnotationTitle(annot)}</Typography>
              {annot.linkedId && (
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    borderRadius: "15px",
                    backgroundColor: "#FFC42DE6",
                    p: "6px 10px",
                    columnGap: "5px",
                  }}
                >
                  <Link sx={{ fontSize: "1rem" }} />
                  <Typography fontSize="0.75rem">
                    {tGeneral(annot.linkedModule)}
                  </Typography>
                  <Typography fontSize="0.75rem">
                    #{tGeneral(annot.linkedObject.number)}
                  </Typography>
                </Box>
              )}
            </Box>
            <Typography fontSize="0.75rem" color="gray">
              {annot.createdBy?.firstName} {annot.createdBy?.lastName},{" "}
              {moment(annot.createdDate)
                .tz(project?.projectTimezone)
                .format("M/D/YYYY | hh:mm a")}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export { DrawingsAnnotationList };
