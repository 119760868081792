import { datadogRum } from "@datadog/browser-rum";
import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { PopUpAlert } from "../../../components/PopUpAlert";
import { TitleWidgets } from "../../../components/shared";
import { selectTheme } from "../../../features/preferences/preferencesSlice";
import {
  useDeleteAnnouncementMutation,
  useGetActiveAnnouncementsQuery,
} from "../../../features/project/modules/announcements/announcementApiSlice";
import {
  selectUpdated,
  setUpdated,
} from "../../../features/project/modules/announcements/announcementSlice";
import {
  selectCurrentGuideMe,
  selectCurrentRole,
} from "../../../features/project/projectSlice";
import { selectCurrentUser } from "../../../features/userSettings/userSettingsSlice";
import {
  getLastPublication,
  getNextDateAnnouncement,
} from "../../../services/helpers/dateFormat";
import GeneralAnnouncements from "./GeneralAnnouncements";
import ScheduledAnnouncements from "./ScheduledAnnouncements";
import AnnouncementDialog from "./components/AnnouncementDialog";
import { useAppTourContext } from "../../../components/supportButton/context/context";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function Announcements() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(30);
  // Translations
  const { t: tAnnouncements } = useTranslation("announcements");
  const { t: tGeneral } = useTranslation("general");

  // Table tabs
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  // Theme
  const darkMode = useSelector(selectTheme);

  // Dialog
  const openDialogFunc = (idAnnouncement) => {
    navigate(`./${idAnnouncement}`);
  };

  // Data
  const { idProject, idEnterprise, idAnnouncement } = useParams();
  const currentUser = useSelector(selectCurrentUser);
  const userRole = useSelector(selectCurrentRole);
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  // Announcements Data
  const {
    data: announcements,
    isSuccess,
    isLoading,
    isFetching,
    refetch,
  } = useGetActiveAnnouncementsQuery(idProject);
  const [publishedData, setPublishedData] = useState([]);
  const [programmedData, setProgrammedData] = useState([]);
  const [deleteAnnouncement] = useDeleteAnnouncementMutation();
  const reloadAfterRecycle = useSelector(selectUpdated);
  const [announcement, setAnnouncement] = useState({ id: null });

  const handleDelete = async (value) => {
    const body = {
      ids: value,
    };
    try {
      const response = await deleteAnnouncement(body).unwrap();
      if (response) {
        // TODO: Create a confirmation delete pop up
        refetch();
        // setAnnData([]);
        PopUpAlert(
          "success",
          tGeneral("done"),
          `${tAnnouncements("announcement")} ${tGeneral(
            "deletedSuccessfully",
          )}`,
        );
      }
    } catch (err) {
      PopUpAlert(
        "error",
        "Error!",
        `${tGeneral("cantDelete")} ${tAnnouncements("announcement")}`,
      );
      // JSON.stringify(err.data),
      datadogRum.addError({
        message: "Announcements - Delete - Error",
        stack: err.stack ? err.stack : "invalid stack",
        custom: {
          app: "buildpeer-web",
          enterprise: idEnterprise,
          module: "Announcements",
          project: idProject,
        },
        timestamp: Date.now(),
      });
    }
  };

  function isDatePast(first, secondDate) {
    const firstDate = new Date(first);
    if (firstDate <= secondDate) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (!reloadAfterRecycle && isSuccess && announcements.length > 0) {
      setPublishedData(
        announcements
          .filter(function (obj) {
            return isDatePast(obj.publishDate, new Date());
          })
          .map((announcement) => ({
            ...announcement,
            id: announcement.id,
            bPrivate: announcement.bPrivate,
            userData: announcement.createdByUser,
            expirationDate: announcement.expirationDate,
            publishDate: announcement.publishDate,
            distributionList: announcement.distributionListUsers,
            description: announcement.description,
            repeatRate: announcement.repeatRate,
          }))
          .sort((a, b) => {
            if (
              getLastPublication(a.publishDate, a.repeatRate) <
              getLastPublication(b.publishDate, b.repeatRate)
            ) {
              return -1;
            }
            return 1;
          }),
      );
      setProgrammedData(
        announcements
          .filter(function (obj) {
            return (
              obj.repeatRate !== null &&
              obj.createdBy === currentUser.id &&
              obj.repeatRate.toLowerCase() !== "never"
            );
          })
          .map((announcement) => ({
            ...announcement,
            id: announcement.id,
            bPrivate: announcement.bPrivate,
            userData: announcement.createdByUser,
            expirationDate: announcement.expirationDate,
            publishDate: announcement.publishDate,
            distributionList: announcement.distributionListUsers,
            description: announcement.description,
            repeatRate: announcement.repeatRate,
          }))
          .sort((a, b) => {
            if (
              getNextDateAnnouncement(a.publishDate, a.repeatRate) <
              getNextDateAnnouncement(b.publishDate, b.repeatRate)
            ) {
              return -1;
            }
            return 1;
          }),
      );
    } else {
      refetch();
      dispatch(setUpdated(false));
    }
  }, [
    isSuccess,
    announcements,
    reloadAfterRecycle,
    currentUser,
    dispatch,
    refetch,
  ]);

  const handleCloseDialog = () => {
    navigate("./..");
  };

  // ==== React-JoyRide - START ====
  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();

  useLayoutEffect(() => {
    if (announcements && isSuccess) {
      if (announcements.length > 0) {
        setAnnouncement(announcements[0]);
      }
    }
  }, [announcements, isSuccess]);

  useLayoutEffect(() => {
    if (tourActive) {
      setState({ run: true });
    }

    if (
      tourActive &&
      currentGuideMe === "announcement-view" &&
      stepIndex === 1 &&
      Boolean(announcement)
    ) {
      openDialogFunc(announcement.id);
    }
  }, [tourActive, announcement, stepIndex, currentGuideMe]);
  // ==== React-JoyRide - END ====

  return (
    <>
      <TitleWidgets
        title={tAnnouncements("announcements")}
        supportDisabled
        moduleName="announcements"
        enableSupport
        addNameTour="add-announcement-1"
      />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="primary"
            aria-label="announcements tabs"
          >
            <Tab
              sx={{ textTransform: "none" }}
              {...a11yProps(0)}
              label={tAnnouncements("published")}
            />
            {userRole?.announcements == 2 || userRole?.announcements == 3 ? (
              <Tab
                sx={{ textTransform: "none" }}
                {...a11yProps(0)}
                label={tAnnouncements("scheduled")}
              />
            ) : null}
          </Tabs>
        </Box>
        <TabPanel value={selectedTab} index={0}>
          <GeneralAnnouncements
            data={publishedData}
            darkMode={darkMode}
            openDialogFunc={openDialogFunc}
            pageSize={pageSize}
            setPageSize={setPageSize}
            handleDelete={handleDelete}
            isLoading={isLoading || isFetching}
            userRole={userRole}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <ScheduledAnnouncements
            data={programmedData}
            openDialogFunc={openDialogFunc}
            pageSize={pageSize}
            setPageSize={setPageSize}
            handleDelete={handleDelete}
            isLoading={isLoading || isFetching}
            userRole={userRole}
          />
        </TabPanel>
      </Box>

      <AnnouncementDialog
        isOpen={Boolean(idAnnouncement)}
        onClose={handleCloseDialog}
      />
    </>
  );
}

export default Announcements;
