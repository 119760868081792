import {
  Add,
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Close,
  Delete,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MaterialTable from "material-table";
import React, { forwardRef, useState } from "react";
import { IMask, IMaskInput } from "react-imask";
import { useSelector } from "react-redux";
import { selectTheme } from "../../../features/preferences/preferencesSlice";
import { useTranslation } from "react-i18next";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  const masks = {
    cardNumber: "####-####-####-####",
    expireDate: "MM/AA",
    cvv: "####",
  };
  return (
    <IMaskInput
      {...other}
      mask={masks[props.name]}
      definitions={{
        "#": /[0-9]/,
      }}
      blocks={{
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
        },
        AA: {
          mask: IMask.MaskedRange,
          from: new Date().getFullYear() % 100,
          to: 99,
        },
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
    />
  );
});

function PaymentTable() {
  const { t: tGeneral } = useTranslation("general");
  const [data, setData] = useState([]);
  const [openCardDialog, setOpenCardDialog] = useState(false);
  const theme = useTheme();
  const md_measurement = useMediaQuery(theme.breakpoints.up("md"));
  var show_searchbar = md_measurement ? true : false;
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => (
      <Delete {...props} color="error" ref={ref} />
    )),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    // Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const [pageSize, setPageSize] = useState(5);
  const darkMode = useSelector(selectTheme);
  const [columns, setColumns] = useState([
    {
      field: "email",
      title: tGeneral("cardNumber"),
      initialEditValue: "",
      validate: (rowData) =>
        rowData.email !== "" && emailValidator(rowData.email) === 1
          ? true
          : { isValid: false, helperText: "Invalid email" },
      width: "90%",
      headerStyle: { width: "90%" },
    },
    {
      field: "email",
      title: tGeneral("name"),
      initialEditValue: "",
      validate: (rowData) =>
        rowData.email !== "" && emailValidator(rowData.email) === 1
          ? true
          : { isValid: false, helperText: "Invalid email" },
      width: "90%",
      headerStyle: { width: "90%" },
    },
    {
      field: "email",
      title: tGeneral("mmaa"),
      initialEditValue: "",
      validate: (rowData) =>
        rowData.email !== "" && emailValidator(rowData.email) === 1
          ? true
          : { isValid: false, helperText: "Invalid email" },
      width: "90%",
      headerStyle: { width: "90%" },
    },
    {
      field: "email",
      title: tGeneral("address"),
      initialEditValue: "",
      validate: (rowData) =>
        rowData.email !== "" && emailValidator(rowData.email) === 1
          ? true
          : { isValid: false, helperText: "Invalid email" },
      width: "90%",
      headerStyle: { width: "90%" },
    },
    {
      // field: "action",
      title: tGeneral("actions"),
      sorting: false,
      render: (rowData) => (
        <ButtonGroup>
          <IconButton
            title="Delete"
            onClick={() => {
              setDeleteEmail(rowData.email);
              setOpenDeleteDialog(true);
            }}
            disabled={rowData.bMain}
            sx={{ color: "red" }}
          >
            <Delete />
          </IconButton>
        </ButtonGroup>
      ),
      width: "10%",
      headerStyle: { width: "10%", textAlign: "end" },
      cellStyle: { textAlign: "end" },
    },
  ]);

  const styles = {
    columnNumHeader: {
      textAlign: "left",
      width: "20%",
      maxWidth: "20%",
      fontWeight: "bold",
      fontSize: "1rem",
    },
    columnNumCell: {
      fontSize: "1rem ",
      textAlign: "left",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "20%",
      maxWidth: "20%",
    },
    columnNameHeader: {
      textAlign: "left",
      width: "100%",
      maxWidth: "100%",
      fontWeight: "bold",
      fontSize: "1rem",
    },
    columnNameCell: {
      fontSize: "1rem",
      textAlign: "left",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
      maxWidth: "100%",
    },
    columnActionHeader: {
      textAlign: "center",
      width: "10%",
      maxWidth: "10%",
      fontWeight: "bold",
      fontSize: "1rem",
    },
    columnActionCell: {
      fontSize: 12,
      textAlign: "center",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "10%",
      maxWidth: "10%",
    },
    iconButton: {
      color: darkMode ? "white" : "black",
      ":hover": {
        color: "#FBCB04",
      },
      m: [0, 0, 0, 0],
    },
    boxStyle: {
      height: "100%",
      width: "100%",
      maxHeight: "90vh",
    },
    boxButton: {
      backgroundColor: "#ffc900",
      padding: 0.5,
      paddingX: 0.7,
      borderRadius: 1,
    },
    addButton: {
      padding: [0.4, 0, 0.4, 0],
      marginBottom: -0.5,
      color: "black",
    },
    dialogTitle: {
      textAlign: "left",
      height: "60px",
      fontWeight: "bold",
    },
    iconButtonClose: {
      position: "absolute",
      right: 8,
      top: 8,
    },
    textField: {
      mx: "auto",
      width: "100%",
      marginTop: 1,
      marginBottom: 1,
    },
    cancelButton: {
      width: "50%",
      color: "black",
      backgroundColor: "white",
      borderColor: "black",
      border: 1,
      ":hover": {
        color: "white",
        backgroundColor: "#b4b4b4",
      },
    },
    saveButton: {
      width: "50%",
      color: "black",
      backgroundColor: "#ffc900",
      ":hover": {
        color: "black",
        backgroundColor: "#d9ab00",
      },
    },
    removeButton: {
      width: "50%",
      color: "black",
      backgroundColor: "#ffc900",
      ":hover": {
        color: "black",
        backgroundColor: "#d9ab00",
      },
    },
  };
  const tableRef = React.createRef();
  const [cardNumber, setCardNumber] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [cvv, setCvv] = useState("");

  const tableLocalization = {
    pagination: {
      labelRowsPerPage: `${tGeneral("labelRowsPerPage")}`,
      labelDisplayedRows: `{from}-{to} ${tGeneral("of")} {count}`,
      labelRowsSelect: `${tGeneral("labelRowsSelect")}`,
      firstAriaLabel: `${tGeneral("firstAriaLabel")}`,
      firstTooltip: `${tGeneral("firstTooltip")}`,
      previousAriaLabel: `${tGeneral("previousAriaLabel")}`,
      previousTooltip: `${tGeneral("previousTooltip")}`,
      nextAriaLabel: `${tGeneral("nextAriaLabel")}`,
      nextTooltip: `${tGeneral("nextTooltip")}`,
      lastAriaLabel: `${tGeneral("lastAriaLabel")}`,
      lastTooltip: `${tGeneral("lastTooltip")}`,
    },
    toolbar: {
      searchPlaceholder: tGeneral("search"),
    },
    body: {
      emptyDataSourceMessage: tGeneral("emptyDataSourceMessage"),
    },
  };

  return (
    <>
      <Box sx={{ height: 500, width: "100%" }}>
        <MaterialTable
          icons={tableIcons}
          columns={columns}
          tableRef={tableRef}
          data={data}
          localization={tableLocalization}
          title={
            <Box sx={{ width: "100%" }}>
              <ButtonGroup sx={{ alignItems: "center" }}></ButtonGroup>
            </Box>
          }
          actions={[
            {
              icon: forwardRef((props, ref) => (
                <Box
                  sx={{
                    backgroundColor: "#ffc900",
                    height: "35px",
                    width: "35px",
                    borderRadius: 1,
                  }}
                >
                  <Add
                    ref={ref}
                    style={{
                      padding: 0,
                      margin: 0,
                      marginTop: "5",
                      color: "black",
                    }}
                  />
                </Box>
              )),
              tooltip: tGeneral("addCard"),
              isFreeAction: true,
              // hidden: userRole.panel === 3 ? false : true, // only for super admins
              onClick: (event) => {
                // otras funciones cuando click on add
                setOpenCardDialog(true);
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            search: show_searchbar,
            searchFieldVariant: "outlined",
            searchFieldStyle: { width: "450px", height: "35px" },
            rowStyle: { padding: "0 5%" },
            // tableLayout: "fixed",
          }}
          rowsPerPageOptions={[5, 10, 30]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          checkboxSelection
          disableSelectionOnClick
        />
      </Box>

      {/* Modal - Add Card */}
      <Dialog
        open={openCardDialog}
        onClose={(e) => setOpenCardDialog(false)}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ mx: "auto" }}
        PaperProps={{ sx: { borderRadius: "10px", width: "30%" } }}
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={[
            styles.dialogTitle,
            { padding: "16px 40px" },
            { marginBottom: "10px" },
          ]}
        >
          {tGeneral("addCard")}
          <IconButton
            aria-label="close"
            onClick={(e) => setOpenCardDialog(false)}
            sx={[styles.iconButtonClose, { marginRight: "20px" }]}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={[{ padding: "0px 40px 10px 40px" }]}>
          <TextField
            fullWidth
            id="defaultNameTextField"
            variant="outlined"
            type="text"
            label={tGeneral("name")}
            required
            sx={styles.textField}
          />
          <TextField
            fullWidth
            InputProps={{
              inputComponent: TextMaskCustom,
            }}
            id="defaultNameTextField"
            variant="outlined"
            type="text"
            label={tGeneral("cardNumber")}
            required
            name="cardNumber"
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            sx={styles.textField}
          />
          <TextField
            fullWidth
            id="defaultNameTextField"
            variant="outlined"
            type="text"
            label={tGeneral("mmaa")}
            required
            sx={styles.textField}
            InputProps={{
              inputComponent: TextMaskCustom,
            }}
            name="expireDate"
            value={expireDate}
            onChange={(e) => setExpireDate(e.target.value)}
          />
          <TextField
            fullWidth
            id="defaultNameTextField"
            variant="outlined"
            type="text"
            label="CVV"
            required
            name="cvv"
            InputProps={{
              inputComponent: TextMaskCustom,
            }}
            value={cvv}
            sx={styles.textField}
            onChange={(e) => setCvv(e.target.value)}
          />
          <TextField
            fullWidth
            id="defaultNameTextField"
            variant="outlined"
            type="text"
            label={tGeneral("address")}
            required
            sx={styles.textField}
          />
        </DialogContent>
        <DialogActions
          sx={[
            { marginBottom: "10px" },
            { marginRight: "30px" },
            { marginLeft: "30px" },
          ]}
        >
          <Button
            sx={styles.cancelButton}
            type="submit"
            variant="contained"
            size="large"
            onClick={(e) => setOpenCardDialog(false)}
          >
            {tGeneral("cancel")}
          </Button>
          <Button
            sx={styles.saveButton}
            type="submit"
            variant="contained"
            size="large"
            onClick={(e) => setOpenCardDialog(false)}
          >
            {tGeneral("save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export { PaymentTable };
