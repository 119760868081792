export const notificationActions = {
  CREATE: "が{{object}}を作成しました",
  EDIT: "が{{object}}を編集しました",
  CLOSE: "が{{object}}を閉じました",
  RESTORE: "が{{object}}を復元しました",
  ANSWER: "が{{object}}の質問に回答しました",
  NEW_TOPIC: "が{{object}}に新しいトピックを作成しました",
  COMMENT: "が{{object}}にコメントしました",
  REOPEN: "が{{object}}を再開しました",
  OFFICIAL_RESPONSE: "が{{object}}で公式の回答としてマークしました",
  DRAFT: "が{{object}}を下書きとして作成しました",
  APPROVE: "が{{object}}を承認しました",
  DELETE: "が{{object}}を削除しました",
  START: "が{{object}}を開始しました",
  CONFIRM: "が{{object}}への出席を確認しました",
  CREATE_PHOTO: "が写真をアップロードしました",
  CREATE_ALBUM: "がアルバムを作成しました",
  CREATE_VIDEO: "がビデオを作成しました",
  CREATE_FOLDER: "がフォルダを作成しました",
  CREATE_FILE: "がファイルをアップロードしました",
  MOVE_FILE: "がファイルを移動しました",
  MOVE_FOLDER: "がフォルダを移動しました",
  CREATE_SCHEDULED: "が予定されたお知らせを作成しました",
  REVISION: "がリビジョンを作成しました",
  CREATE_ANNOTATION: "が注釈を作成しました",
  ASSIGN: "が注釈を割り当てました",
  LINK: "が注釈をリンクしました",
  SIGN: "が{{object}}に署名しました",
  REJECT: "が{{object}}を拒否しました",
  NEW_REVISION: "がこの検査のリビジョンを作成しました",
  rfi: "RFI",
  drawing: "図面",
  task: "タスク",
  daily_report: "日報",
  dailyReport: "日報",
  image: "画像",
  document: "ドキュメント",
  inspection: "検査",
  meeting: "ミーティング",
};
