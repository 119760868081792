import { createContext, useMemo, useContext } from "react";
import { useSetState } from "react-use";

const appState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
};

export const AppTourContext = createContext({
  state: appState,
  setState: () => undefined,
});
AppTourContext.displayName = "AppTourContext";

export const AppTourProvider = (props) => {
  const [state, setState] = useSetState(appState);

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [state, setState],
  );

  return <AppTourContext.Provider value={value} {...props} />;
};

export const useAppTourContext = () => {
  const context = useContext(AppTourContext);

  if (!context) {
    throw new Error("useAppContext must be used within a AppTourProvider");
  }

  return context;
};
