import { tour } from "./tour";

const roles = {
  // general tour
  ...tour,
  moduleElement: "역할",
  step1_addRole_title: "1. 역할 추가",
  step1_addRole_text: "새로운 역할을 추가하려면 여기를 클릭하세요.",
  step2_addRole_title: "2. 역할 이름",
  step2_addRole_text: "생성 중인 역할에 이름을 지정하세요.",
  step3_addRole_title: "3. 권한",
  step3_addRole_text: "역할이 가질 각 모듈의 권한 유형을 선택하세요.",
  step4_addRole_title: "4. 추가",
  step4_addRole_text: "역할을 역할 목록에 추가하려면 여기를 클릭하세요.",
  step_final_addRole: "역할 목록에 새로운 역할을 추가하는 방법을 배웠습니다.",
  // EDIT - guide me steps
  step1_editRole_title: "1. 역할 편집",
  step1_editRole_text: "역할을 편집하려면 여기를 클릭하세요.",
  step2_editRole_title: "2. 역할 이름",
  step2_editRole_text: "역할의 이름을 편집하세요.",
  step3_editRole_title: "3. 권한",
  step3_editRole_text: "역할이 가지고 있는 각 모듈의 권한 유형을 편집하세요.",
  step4_editRole_title: "4. 저장",
  step4_editRole_text: "역할에 대한 변경 사항을 저장하려면 여기를 클릭하세요.",
  step_final_editRole: "역할을 업데이트하는 방법을 배웠습니다.",
  // DELETE - guide me steps
  step1_deleteRole_title: "1. 역할 삭제",
  step1_deleteRole_text: "역할을 삭제하려면 여기를 클릭하세요.",
  step2_deleteRole_title: "2. 확인",
  step2_deleteRole_text: "삭제를 확인하려면 여기를 클릭하세요.",
  step_final_deleteRole: "역할을 삭제하는 방법을 배웠습니다.",
  // CHANGE ROLE - guide me steps
  step1_changeRole_title: "1. 사용자 역할",
  step1_changeRole_text: "역할을 변경할 사용자를 선택하세요.",
  step2_changeRole_title: "2. 역할 유형",
  step2_changeRole_text: "사용자가 가질 역할을 선택하세요.",
  step3_changeRole_title: "3. 저장",
  step3_changeRole_text: "변경 사항을 저장하려면 여기를 클릭하세요.",
  step_final_changeRole: "사용자의 역할을 변경하는 방법을 배웠습니다.",
};

export { roles };
