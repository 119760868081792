import { Download } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  ImageList,
  ImageListItem,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import JSZip from "jszip";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
import VideoIcon from "../assets/icons/sharedModules/video.png";
import ExcelIcon from "../assets/rfi/excel.png";
import PdfIcon from "../assets/rfi/pdf.png";
import WordIcon from "../assets/rfi/word.png";
import DwgIcon from "../assets/documents/dwg.svg";
import PptIcon from "../assets/documents/ppt.svg";
import ZipIcon from "../assets/documents/zip.png";
import { isVideo } from "../utils/video";
import AudioPlayer from "./AudioPlayer";
import { PopUpAlert } from "./PopUpAlert";
import { ModalFileViewer } from "./shared/ModalFileViewer";
import { ModalVideoViewer } from "./shared/ModalVideoViewer";
import { setDownloadingFiles } from "../features/drawings/downloadsSlice";

const style = {
  marginRight: 0,
  border: "2px solid #FFF",
  borderRadius: "20%",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  // Translations
  const { t: tRfis } = useTranslation("rfis");
  const { t: tGeneral } = useTranslation("general");

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box display=" flex" sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CONST_IMAGE_LIST_VALUE = 0;
const CONST_FILE_LIST_VALUE = 1;
const CONST_VIDEO_LIST_VALUE = 2;

function ViewFiles({ files, setImageOpen }) {
  const dispatch = useDispatch();

  const [currentFile, setCurrentFile] = useState(null);
  const [value, setValue] = useState(CONST_IMAGE_LIST_VALUE);

  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [isDownloadBatchFilesError, setIsDownloadBatchFilesError] =
    useState(false);

  // Translations
  const { t: tRfis } = useTranslation("rfis");
  const { t: tGeneral } = useTranslation("general");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getIcon = (type, file) => {
    let src = PdfIcon;
    if (type === "ppt" || type === "pptx") {
      src = PptIcon;
    }
    if (type === "xlsx" || type === "csv" || type === "xls") {
      src = ExcelIcon;
    }
    if (type === "doc" || type === "docx") {
      src = WordIcon;
    }
    if (type === "mp4" || type === "mov" || type === "avi") {
      src = VideoIcon;
    }
    if (type === "dwg") {
      src = DwgIcon;
    }
    if (type === "zip" || type === "rar") {
      src = ZipIcon;
    }
    return <img src={src} alt="" width="80%" height="80%" style={style} />;
  };

  const filesList = React.useMemo(() => {
    return files.filter(
      (file) =>
        file &&
        file.extension !== null &&
        (file.extension === "pdf" ||
          file.extension === "xlsx" ||
          file.extension === "xls" ||
          file.extension === "csv" ||
          file.extension === "doc" ||
          file.extension === "docx" ||
          file.extension === "dwg" ||
          file.extension === "ppt" ||
          file.extension === "pptx" ||
          file.extension === "zip" ||
          file.extension === "rar") &&
        file,
    );
  }, [files]);

  const imagesList = React.useMemo(() => {
    return files
      .map((file) => {
        if (
          file.extension !== null &&
          (file.extension === "png" ||
            file.extension === "jpg" ||
            file.extension === "jpeg" ||
            file.extension === "svg" ||
            file.extension === "jfif" ||
            file.extension === "avif")
        ) {
          return file;
        }
      })
      .filter((file) => file !== undefined && file);
  }, [files]);

  const videoList = React.useMemo(() => {
    return files
      .map((file) => {
        if (
          file.extension !== null &&
          (file.extension === "mp4" ||
            file.extension === "mov" ||
            file.extension === "avi")
        ) {
          return file;
        }
      })
      .filter((file) => file !== undefined && file);
  }, [files]);

  const openFile = (file, extension) => {
    setCurrentFile({ url: file, type: extension });
  };
  const handleCloseFile = () => {
    setCurrentFile(null);
  };

  const isViewFileOpen = Boolean(currentFile);

  const getOriginalName = (name) => {
    let originalName = "";
    originalName = name.replace(/%20/g, " ");

    return originalName;
  };

  useEffect(() => {
    if (isDownloadBatchFilesError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("generalErrorMessage"));
    }
  }, [isDownloadBatchFilesError]);

  const handleDownload = async () => {
    const elementList =
      value === CONST_IMAGE_LIST_VALUE
        ? imagesList
        : value === CONST_FILE_LIST_VALUE
          ? filesList
          : value === CONST_VIDEO_LIST_VALUE
            ? videoList
            : null;

    dispatch(
      setDownloadingFiles([
        { type: "multiple", module: "drawings", files: elementList },
      ]),
    );
  };

  return (
    <Box sx={{ width: "580px", height: "350px" }}>
      <Card sx={{ overflowY: "true", display: "flex", minHeight: "350px" }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box>
            <Box
              sx={{
                width: "97%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography
                fontSize="18px"
                fontWeight="bold"
                alignItems="flex-start"
                textAlign="left"
              >
                {tGeneral("files")}
              </Typography>
              <Box
                sx={{ width: "97%", display: "flex", justifyContent: "end" }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#ffc900",
                    borderRadius: 1,
                    marginLeft: 1,
                    padding: 1,
                    color: "#000",
                    ":hover": {
                      backgroundColor: "#ffc900",
                    },
                  }}
                  style={{ minWidth: "24px" }}
                  onClick={() => handleDownload()}
                  disabled={
                    (value === CONST_IMAGE_LIST_VALUE &&
                      imagesList?.length === 0) ||
                    (value === CONST_FILE_LIST_VALUE &&
                      filesList?.length === 0) ||
                    (value === CONST_VIDEO_LIST_VALUE &&
                      videoList?.length === 0)
                  }
                >
                  {isLoadingDownload ? (
                    <CircularProgress
                      style={{ width: "18px", height: "18px" }}
                      color="secondary"
                    />
                  ) : (
                    <Download style={{ fontSize: "18px" }} />
                  )}
                </Button>
              </Box>
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                sx={{ width: "570px" }}
              >
                <Tab
                  label={tGeneral("images")}
                  {...a11yProps(0)}
                  sx={{
                    width: "25%",
                    fontWeight:
                      value === CONST_IMAGE_LIST_VALUE ? "bold" : "none",
                    textTransform: "none",
                    color: "black",
                  }}
                />
                <Tab
                  label={tGeneral("documents")}
                  {...a11yProps(1)}
                  sx={{
                    width: "25%",
                    fontWeight:
                      value === CONST_FILE_LIST_VALUE ? "bold" : "none",
                    textTransform: "none",
                    color: "black",
                  }}
                />
                <Tab
                  label={tGeneral("videos")}
                  {...a11yProps(2)}
                  sx={{
                    width: "25%",
                    fontWeight:
                      value === CONST_VIDEO_LIST_VALUE ? "bold" : "none",
                    textTransform: "none",
                    color: "black",
                  }}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {imagesList.length ? (
                <ImageList
                  sx={{ width: "500px", height: "100%" }}
                  variant="standard"
                  cols={4}
                >
                  {imagesList.map((image) => (
                    <ImageListItem
                      cols={image?.cols || 1}
                      key={image?.url}
                      rows={image?.rows || 1}
                    >
                      <Box
                        component={Button}
                        onClick={(e) => setImageOpen(image)}
                        sx={{ width: "100%", height: "90px" }}
                      >
                        <img
                          src={image?.url}
                          alt=""
                          width="100%"
                          height="90px"
                          style={style}
                          loading="lazy"
                        />
                      </Box>
                    </ImageListItem>
                  ))}
                </ImageList>
              ) : (
                <Typography
                  fontSize="14px"
                  fontWeight="normal"
                  sx={{ width: "500px", height: "100%" }}
                >
                  {tGeneral("noImages")}
                </Typography>
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {filesList.length > 0 ? (
                <ImageList
                  sx={{ width: "500px", height: "300px" }}
                  variant="standard"
                  cols={4}
                >
                  {filesList.map((file) => (
                    <Box
                      key={file?.url}
                      onClick={(e) => openFile(file.url, file.extension)}
                      sx={{ width: "110px", height: "120px" }}
                    >
                      {getIcon(file.extension)}
                      <Typography
                        fontSize="10px"
                        fontWeight="light"
                        sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          lineClamp: 4,
                        }}
                      >
                        {getOriginalName(file.name)}
                      </Typography>
                    </Box>
                  ))}
                </ImageList>
              ) : (
                <Typography
                  fontSize="14px"
                  fontWeight="normal"
                  sx={{ width: "500px", height: "100%" }}
                >
                  {tGeneral("noDocuments")}
                </Typography>
              )}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {videoList.length > 0 ? (
                <ImageList
                  sx={{ width: "500px", height: "300px" }}
                  variant="standard"
                  cols={4}
                >
                  {videoList.map((video) => (
                    <Box
                      key={video?.url}
                      onClick={(e) => openFile(video.url, video.extension)}
                      sx={{ width: "110px", height: "120px" }}
                    >
                      {getIcon(video.extension)}
                      <Typography
                        fontSize="10px"
                        fontWeight="light"
                        sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          lineClamp: 4,
                        }}
                      >
                        {getOriginalName(video.name)}
                      </Typography>
                    </Box>
                  ))}
                </ImageList>
              ) : (
                <Typography
                  fontSize="14px"
                  fontWeight="normal"
                  sx={{ width: "500px", height: "100%" }}
                >
                  {tGeneral("noVideos")}
                </Typography>
              )}
            </TabPanel>
          </Box>
        </CardContent>
      </Card>
      {isViewFileOpen &&
        (isVideo(currentFile) ? (
          <ModalVideoViewer
            isOpen={isViewFileOpen}
            onClose={handleCloseFile}
            file={currentFile}
          />
        ) : (
          <ModalFileViewer
            isOpen={isViewFileOpen}
            onClose={handleCloseFile}
            file={currentFile}
          />
        ))}
    </Box>
  );
}

export default ViewFiles;
