import { useAbility } from "@casl/react";
import { Edit, Email, RemoveRedEye } from "@mui/icons-material";
import { Box, Button, Chip, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectCurrentUser } from "../../../features/userSettings/userSettingsSlice";
import Can from "../../../utils/can";
import CaslContext from "../../../utils/caslContext";
import {
  AssignedUsersTooltip,
  StyledMaterialReactTable,
  TooltipIconButton,
} from "../../shared";
import { MeetingsFilters } from "./MeetingsFilters";

const statusPropsTable = {
  open: {
    label: "open_action",
    styles: {
      backgroundColor: "#5ce381",
      color: "white",
      fontSize: "0.75rem",
      width: "100px",
      fontWeight: "medium",
    },
  },
  close: {
    label: "closed",
    styles: {
      color: "white",
      backgroundColor: "#c2c3c3",
      fontSize: "0.75rem",
      width: "100px",
      fontWeight: "medium",
    },
  },
  draft: {
    label: "draft",
    styles: {
      color: "white",
      backgroundColor: "#fe9300",
      fontSize: "0.75rem",
      width: "100px",
      fontWeight: "medium",
    },
  },
  stillOpen: {
    label: "still_open",
    styles: {
      color: "white",
      backgroundColor: "#ffc900",
      fontSize: "0.75rem",
      width: "100px",
      fontWeight: "medium",
    },
  },
};

function DateCloseChip({ closedDate, status }) {
  const { t: tGeneral } = useTranslation("general");
  if (status === "close") {
    return (
      <Typography>{moment.utc(closedDate).format("DD/MM/YYYY")}</Typography>
    );
  }
  return (
    <Chip
      sx={[
        statusPropsTable[status === "draft" ? status : "stillOpen"].styles,
        { width: "130px" },
      ]}
      label={tGeneral(
        statusPropsTable[status === "draft" ? "draft" : "stillOpen"].label,
      )}
    />
  );
}

function MeetingsTable({
  meetings,
  selectedMeetings,
  setSelectedMeetings,
  setFilters,
  isLoading,
  sendToEmail = () => {},
}) {
  const { t: tDailyReports } = useTranslation("dailyReports");
  const { t: tGeneral } = useTranslation("general");
  const { t: tMeetings } = useTranslation("meetings");

  const currentUser = useSelector(selectCurrentUser);

  const ability = useAbility(CaslContext);

  const navigate = useNavigate();

  const columns = [
    // {
    //   accessorKey: "bPrivate",
    //   header: "Priv.",
    //   Cell: ({ cell }) => (cell.getValue() ? <Lock /> : <LockOpen />),
    //   size: 30,
    // },
    {
      accessorKey: "number",
      header: "#",
      size: 30,
      muiTableHeadCellProps: {
        sx: { padding: 0 },
        align: "center",
      },
    },
    {
      accessorKey: "title",
      header: tGeneral("title"),
      size: 100,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()}>
          <Typography noWrap textOverflow="ellipsis">
            {cell.getValue()}
          </Typography>
        </Tooltip>
      ),
    },
    {
      accessorKey: "status",
      header: tGeneral("status"),
      Cell: ({ cell }) => (
        <Chip
          sx={statusPropsTable[cell.getValue()].styles}
          label={tGeneral(statusPropsTable[cell.getValue()].label)}
        />
      ),
      size: 50,
    },
    {
      accessorKey: "attendenceList",
      header: tGeneral("attendance"),
      Cell: ({ cell }) => <AssignedUsersTooltip users={cell.getValue()} />,
      size: 100,
    },
    {
      accessorKey: "date",
      header: tGeneral("date"),
      Cell: ({ cell, row }) => (
        <>
          {moment.utc(cell.getValue()).format("DD/MM/YYYY")}{" "}
          {row.original?.hour !== "N/A" && row.original?.hour
            ? row.original?.hour
            : ""}
        </>
      ),
      size: 100,
    },
    {
      accessorKey: "closeDate",
      header: tDailyReports("closed"),
      Cell: ({ cell, row }) => (
        <DateCloseChip
          closedDate={cell.getValue()}
          status={row.original?.status}
        />
      ),
      size: 100,
    },
  ];

  const renderRowActions = ({ row }) => {
    if (
      row.original.status === "draft" &&
      (ability.can("edit", "meetings") ||
        row.original.createdBy.id === currentUser.id)
    ) {
      return (
        <Button
          onClick={() => navigate(`form/${row.id}`)}
          variant="contained"
          sx={{
            width: "90%",
            color: "black",
            backgroundColor: "white",
            borderColor: "black",
            border: 1,
            fontSize: "1.125rem",
            ":hover": {
              color: "white",
              backgroundColor: "#b4b4b4",
            },
            padding: "12px 0",
          }}
        >
          {tGeneral("edit")}
        </Button>
      );
    }
    return (
      <Box display="flex">
        {(ability.can("superAdmin", "meetings") || (ability.can("edit", "meetings") && row.original.createdBy.id === currentUser.id)) &&
          <TooltipIconButton
            icon={<Edit fontSize="small" />}
            label={tGeneral("edit")}
            onClick={() => navigate(`form/${row.id}`)}
            color="black"
            data-tour="edit-meetings-1"
          />
        }
        <Can I="view" a="meetings">
          <TooltipIconButton
            icon={<RemoveRedEye fontSize="small" />}
            label={tGeneral("seeDetails")}
            onClick={() => navigate(row.id)}
            color="black"
            data-tour="view-meetings-1"
          />
        </Can>
        <TooltipIconButton
          icon={<Email fontSize="small" />}
          label={tGeneral("email")}
          onClick={() => sendToEmail(row.original)}
          color="black"
          data-tour="export-meetings-1"
        />
      </Box>
    );
  };

  return (
    <StyledMaterialReactTable
      columns={columns}
      data={meetings}
      filtersComponent={
        <MeetingsFilters setFilters={(val) => setFilters(val)} />
      }
      selectedRows={selectedMeetings}
      setSelectedRows={setSelectedMeetings}
      enableRowSelection
      renderRowActions={renderRowActions}
      isLoading={isLoading}
      emptyStateMessage={tGeneral("noElements", {
        moduleName: tMeetings("meetings").toLowerCase(),
      })}
    />
  );
}

export { MeetingsTable };
