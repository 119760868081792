import { apiSlice } from "../../../../api/apiSlice";
import { objectToParamString } from "../../../../utils";

export const projectDirectoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Project directory - get project users
    getProjectDirectoryUsers: builder.query({
      query: (idProject) => `/project/${idProject}/getProjectDirectoryUsers`,
      keepUnusedDataFor: 10,
      providesTags: ["ProjectDirectoryUsers"],
    }),
    editProjectDirectoryUser: builder.mutation({
      query: (body) => ({
        url: "/project/editProjectDirectoryUser",
        method: "PUT",
        body,
      }),
      invalidatesTags: [
        "Enterprises",
        "Projects",
        "Logs",
        "ProjectDirectoryUsers",
      ],
    }),
    getUserLatestActivity: builder.query({
      query: ({ idProject, idUser }) =>
        `/log/getUserLogByIdProject/${idProject}/${idUser}`,
      keepUnusedDataFor: 10,
      providesTags: ["Projects"],
    }),
    getProjectCompanies: builder.query({
      query: ({ idEnterprise, idProject }) =>
        `/project/${idEnterprise}/${idProject}/getProjectCompanies`,
      keepUnusedDataFor: 10,
      providesTags: ["Projects"],
    }),
    getCompanyUsers: builder.query({
      query: ({ idCompany, idProject, ...params }) =>
        `/project/companyUsers/${idCompany}/${idProject}${objectToParamString(
          params,
        )}`,
    }),
    changeProjectUserStatus: builder.mutation({
      query: ({ idProject, idUser }) => ({
        url: `/project/userStatus/${idProject}/${idUser}`,
        method: "PUT",
      }),
      invalidatesTags: ["ProjectDirectoryUsers"],
    }),
  }),
});

export const {
  useGetProjectDirectoryUsersQuery,
  useEditProjectDirectoryUserMutation,
  useGetUserLatestActivityQuery,
  useGetProjectCompaniesQuery,
  useGetCompanyUsersQuery,
  useChangeProjectUserStatusMutation,
} = projectDirectoryApiSlice;
