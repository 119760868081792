export const weather = {
  "thunderstorm-200": "Thunderstorm with light rain",
  "thunderstorm-201": "Thunderstorm with rain",
  "thunderstorm-202": "Thunderstorm with heavy rain",
  "thunderstorm-210": "Light thunderstorm",
  "thunderstorm-211": "Thunderstorm",
  "thunderstorm-212": "Heavy thunderstorm",
  "thunderstorm-221": "Ragged thunderstorm",
  "thunderstorm-230": "Thunderstorm with light drizzle",
  "thunderstorm-231": "Thunderstorm with drizzle",
  "thunderstorm-232": "Thunderstorm with heavy drizzle",
  "drizzle-300": "Light intensity drizzle",
  "drizzle-301": "Drizzle",
  "drizzle-302": "Heavy intensity drizzle",
  "drizzle-310": "Light intensity drizzle rain",
  "drizzle-311": "Drizzle rain",
  "drizzle-312": "Heavy intensity drizzle rain",
  "drizzle-313": "Shower rain and drizzle",
  "drizzle-314": "Heavy shower rain and drizzle",
  "drizzle-321": "Shower drizzle",
  "rain-500": "Light rain",
  "rain-501": "Moderate rain",
  "rain-502": "Heavy intensity rain",
  "rain-503": "Very heavy rain",
  "rain-504": "Extreme rain",
  "rain-511": "Freezing rain",
  "rain-520": "Light intensity shower rain",
  "rain-521": "Shower rain",
  "rain-522": "Heavy intensity shower rain",
  "rain-531": "Ragged shower rain",
  "snow-600": "Light snow",
  "snow-601": "Snow",
  "snow-602": "Heavy snow",
  "snow-611": "Sleet",
  "snow-612": "Light shower sleet",
  "snow-613": "Shower sleet",
  "snow-615": "Light rain and snow",
  "snow-616": "Rain and snow",
  "snow-620": "Light shower snow",
  "snow-621": "Shower snow",
  "snow-622": "Heavy shower snow",
  "mist-701": "Mist",
  "smoke-711": "Smoke",
  "haze-721": "Haze",
  "dust-731": "Sand/dust whirls",
  "fog-741": "Fog",
  "sand-751": "Sand",
  "dust-761": "Dust",
  "ash-762": "Volcanic ash",
  "squall-771": "Squalls",
  "tornado-781": "Tornado",
  "clear-800": "Clear sky",
  "clouds-801": "Few clouds: 11-25%",
  "clouds-802": "Scattered clouds: 25-50%",
  "clouds-803": "Broken clouds: 51-84%",
  "clouds-804": "Overcast clouds",
  "sensation": "Feels like",
};
