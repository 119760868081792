// @ts-check
/* eslint react/prop-types: 0 */

import { Button, Switch } from "@mui/material";
// eslint-disable-next-line no-unused-vars
import React from "react";
import { useTranslation } from "react-i18next";

export default function ExpandAllGroups(props) {
  const { expandAllGroups, setExpandAllGroups } = props;

  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  return (
    <Button
      color="secondary"
      disableElevation
      onClick={() => {
        setExpandAllGroups((expandAllGroups) => !expandAllGroups);
      }}
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-end",
        textTransform: "none",
      }}
      variant="text"
    >
      {tProjectSchedule("Expand All Groups")}
      <Switch
        checked={expandAllGroups}
        onChange={(event) => {
          setExpandAllGroups(event.target.checked);
        }}
      />
    </Button>
  );
}
