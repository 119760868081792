import {
  CheckCircle,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { Box, Card, Divider, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function PlanCard({ plan, period, onSelect, isSelected, objectLimit }) {
  const { price, bestValue, id, metadata } = plan;
  const isUser = metadata?.type === "user";

  const { t: tPlan } = useTranslation("plan");
  const { t: tGeneral } = useTranslation("general");
  const [activeObjects, setActiveObjects] = useState(
    Number(metadata?.default_active),
  );
  const periodMultiplier = period === "annual" ? 12 : 1;
  const handleAddObject = (e) => {
    e.stopPropagation();
    const newActibeObjects = activeObjects + 1;
    onSelect(null, newActibeObjects);
    setActiveObjects(newActibeObjects);
  };

  const handleLessObject = (e) => {
    e.stopPropagation();
    const newActibeObjects = activeObjects - 1;
    onSelect(null, newActibeObjects);
    setActiveObjects(newActibeObjects);
  };

  useEffect(() => {
    if (objectLimit !== null) {
      setActiveObjects(objectLimit);
      onSelect(null, objectLimit);
    }
  }, [objectLimit, onSelect]);

  const features = metadata?.en_features?.split(",");
  const showingPrice =
    (price / (metadata.period === "annual" ? 12 : 1)) * metadata.default_active;

  return (
    <Card
      sx={{
        flex: 1,
        p: "12px 30px",
        borderRadius: "10px",
        boxShadow: 3,
        ":hover": {
          transform: "scale3d(1.01, 1.01, 1)",
          boxShadow: 5,
          transition: "transform 0.25s ease-in-out",
        },
        cursor: "pointer",
        border: isSelected ? "2px solid #FFC42D" : "",
        boxSizing: "border-box",
      }}
      component="div"
      onClick={() => onSelect(id, activeObjects)}
    >
      {/* Card Title */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* title */}
        <Typography
          fontSize="3.5625rem"
          fontWeight="bold"
          lineHeight="3.5625rem"
        >
          {/* COST  */}${showingPrice} USD
        </Typography>
        {bestValue && (
          <Box
            sx={{
              backgroundColor: "#FF891E",
              p: "3px 8px",
              borderRadius: "8px",
            }}
          >
            <Typography sx={{ color: "white" }}>
              {tPlan("bestValue")}
            </Typography>
          </Box>
        )}
      </Box>
      <Typography fontSize="1.375rem" lineHeight="1.375rem">
        {/* COST  */}
        {tPlan(isUser ? "perUser" : "perProject")},{" "}
        {tPlan("perMonth").toLocaleLowerCase()}
      </Typography>
      <Divider sx={{ my: "10px" }} />
      <Typography fontSize="1rem" fontWeight="bold" marginBottom={1}>
        {tGeneral("features")}
      </Typography>

      {features?.map((feature) => (
        <Box display="flex" alignItems="center" mb="18px" ml="20px">
          <CheckCircle color="primary" />
          <Typography fontSize="1rem" ml="6px">
            {feature}
          </Typography>
        </Box>
      ))}

      <Divider sx={{ my: "10px" }} />
      <Box display="flex" justifyContent="space-between">
        <Box
          display="flex"
          sx={{ borderRadius: "20px", backgroundColor: "#FFEBBC" }}
          columnGap="22px"
          p="5px 16px"
        >
          <Box>
            {tPlan(isUser ? "activeUsers" : "activeProjects")
              .split(" ")
              .map((val) => (
                <Typography fontSize="0.813rem">{val}</Typography>
              ))}
          </Box>
          <Typography fontSize="1.375rem" alignSelf="center">
            {activeObjects}
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
          >
            <IconButton
              onClick={handleAddObject}
              sx={{ width: "10px", height: "10px" }}
            >
              <KeyboardArrowUp />
            </IconButton>
            <IconButton
              onClick={handleLessObject}
              disabled={isUser ? activeObjects === 1 : activeObjects === 10}
              sx={{ width: "10px", height: "10px" }}
            >
              <KeyboardArrowDown />
            </IconButton>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography fontSize="2rem" fontWeight="bold">
            ${price * activeObjects} USD
          </Typography>
          <Typography ml="8px">
            {tPlan("youWillPay")} <br /> /
            {tPlan(
              period === "annual" ? "perYear" : "perMonth",
            ).toLocaleLowerCase()}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}

export { PlanCard };
