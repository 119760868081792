import { Face, Mail } from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

function SignUpForm() {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <Box
      sx={{
        backgroundImage: `url(${require("./../../assets/form-background.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "white",
      }}
      display="flex"
      flexDirecstion="column"
      height="500px"
      justifyContent="center"
      alignItems="center"
      py="130px"
    >
      <Box
        display="flex"
        justifyContent="center"
        flexDirection={isMobile ? "column" : "row"}
      >
        <Box sx={{ flex: 1 }}>
          <Typography
            fontSize={isMobile ? "30px" : "63px"}
            fontWeight="bold"
            textAlign={isMobile ? "center" : "initial"}
            mb={isMobile ? "40px" : "0"}
          >
            Sign up for a Free Trial!
          </Typography>
        </Box>
        <Box sx={{ flex: 1 }} display="flex" flexDirection="column">
          <TextField
            variant="outlined"
            placeholder="Name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Face />
                </InputAdornment>
              ),
              sx: { backgroundColor: "white", borderRadius: "30px" },
            }}
            sx={{ marginBottom: "60px" }}
          />
          <TextField
            variant="outlined"
            placeholder="Email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Mail />
                </InputAdornment>
              ),
              sx: { backgroundColor: "white", borderRadius: "30px" },
            }}
            sx={{ marginBottom: "60px" }}
          />
          <Box display="flex" justifyContent={isMobile ? "center" : "flex-end"}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "30px",
                fontSize: "20px",
                fontWeight: "bold",
                width: isMobile ? "90%" : "50%",
                height: "60px",
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export { SignUpForm };
