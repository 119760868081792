import { useCallback } from "react";
import { useAppTourContext } from "../context/context";

const useGuideMeModal = ({
  addSteps,
  viewSteps,
  editSteps,
  recycleSteps,
  exportSteps,
  settingsSteps,
  deleteSteps,
  docs,
  templates,
  drawings,
  reports,
}) => {
  const { setState } = useAppTourContext();

  const handleGuideMe = useCallback(
    (option) => {
      let newState = {};
      if (option == "add") {
        newState = {
          steps: addSteps,
        };
      } else if (option == "view") {
        newState = {
          steps: viewSteps,
        };
      } else if (option == "edit") {
        newState = {
          steps: editSteps,
        };
      } else if (option == "delete") {
        newState = {
          steps: deleteSteps,
        };
      } else if (option == "recycle") {
        newState = {
          steps: recycleSteps,
        };
      } else if (option == "export") {
        newState = {
          steps: exportSteps,
        };
      } else if (option == "settings") {
        newState = {
          steps: settingsSteps,
        };
      } else if (option == "docs.addFile") {
        newState = {
          steps: docs.addFile,
        };
      } else if (option === "docs.addFolder") {
        newState = {
          steps: docs.addFolder,
        };
      } else if (option === "docs.fileInfo") {
        newState = {
          steps: docs.fileInfo,
        };
      } else if (option === "docs.moveTo") {
        newState = {
          steps: docs.moveTo,
        };
      } else if (option === "docs.actions") {
        newState = {
          steps: docs.actions,
        };
      } else if (option === "docs.settings") {
        newState = {
          steps: docs.settings,
        };
      } else if (option === "templates.add") {
        newState = {
          steps: templates.add,
        };
      } else if (option === "drawings.reviewAndPublish") {
        newState = {
          steps: drawings.reviewAndPublish,
        };
      } else if (option === "reports.add") {
        newState = {
          steps: addSteps,
        };
      } else if (option === "reports.editBasic") {
        newState = {
          steps: editSteps,
        };
      } else if (option === "reports.editWorkProgress") {
        newState = {
          steps: reports.editWorkProgressSteps,
        };
      } else if (option === "reports.editProblem") {
        newState = {
          steps: reports.editProblemSteps,
        };
      } else if (option === "reports.editDelay") {
        newState = {
          steps: reports.editDelaySteps,
        };
      } else if (option === "reports.viewWorkProgress") {
        newState = {
          steps: reports.viewWorkProgressSteps,
        };
      } else if (option === "reports.viewProblem") {
        newState = {
          steps: reports.viewProblemSteps,
        };
      } else if (option === "reports.viewDelay") {
        newState = {
          steps: reports.viewDelaySteps,
        };
      }

      setState({
        ...newState,
        stepIndex: 0,
        run: true,
        tourActive: true,
      });
    },
    [
      addSteps,
      viewSteps,
      editSteps,
      deleteSteps,
      recycleSteps,
      exportSteps,
      settingsSteps,
      docs,
      templates,
      drawings,
    ],
  );

  return [{ handleGuideMe }];
};

export { useGuideMeModal };
