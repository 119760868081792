import {
  Add,
  Apartment,
  Delete,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Checkbox,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useFieldArray, useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { v4 } from "uuid";
import {
  TooltipIconButton,
  UpdateTextFieldModal,
} from "../../../../../components/shared";
import {
  useAddJobTypeMutation,
  useAddSubcontractorMutation,
  useUpdateJobTypeMutation,
  useUpdateSubcontractorMutation,
} from "../../../../../features/project/modules/dailyReports/manpowerApiSlice";
import { AutocompleteCell } from "../../../Drawings/UploadDrawings/DrawingRow/AutocompleteCell";

function ManpowerForm({ subcontractors, jobTypes }) {
  const { idEnterprise } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const { t: tDailyReports } = useTranslation("dailyReports");

  const [selectedRecords, setSelectedRecords] = useState([]);
  const [editing, setEditing] = useState(null);

  const [addSubcontractor, { isLoading: isSubcontractorLoading }] =
    useAddSubcontractorMutation();
  const [addJobType, { isLoading: isJobTypeLoading }] = useAddJobTypeMutation();
  const [updateSubcontractor, { isLoading: isUpdateSubcontractorLoading }] =
    useUpdateSubcontractorMutation();
  const [updateJobType, { isLoading: isUpdateJobTypeLoading }] =
    useUpdateJobTypeMutation();

  const { setValue: setReportValue, getValues } = useFormContext();
  const { control, handleSubmit, reset, watch, setValue, register } = useForm({
    defaultValues: {
      idSubcontractor: null,
      deletedRecords: [],
      records: [
        {
          id: "first-id-record",
          name: "",
          idJobType: "",
          assistants: 0,
          hours: 0,
        },
      ],
    },
  });
  const watchedFieldArray = watch("records");
  const { fields, update, append, remove } = useFieldArray({
    control,
    name: "records",
  });

  const controlledFields = fields.map((field, index) => ({
    ...field,
    ...watchedFieldArray[index],
  }));

  const onSubmit = (data) => {
    if (
      data.records.some(
        (record) =>
          (record.name === "" && record.assistants === 0) ||
          record.idJobType === "" ||
          record.hours === 0,
      )
    ) {
      return;
    }
    const currentManpower = getValues("manpower");

    setReportValue("manpower", [...currentManpower, { ...data, bNew: true }]);
    reset({
      idSubcontractor: null,
      records: [{ id: v4(), name: "", idJobType: "", assistants: 0, hours: 0 }],
    });
  };

  const onAddSubcontractor = async (name) => {
    const newSubcontractor = await addSubcontractor({ idEnterprise, name });
    setValue("idSubcontractor", newSubcontractor.data.id);
  };

  const onAddJobType = async (name, index, record) => {
    const newJobType = await addJobType({ idEnterprise, name });
    update(index, { ...record, idJobType: newJobType.data.id });
  };

  const handleSelectRecord = (id, checked) => {
    if (checked) {
      setSelectedRecords([...selectedRecords, id]);
    } else {
      setSelectedRecords(selectedRecords.filter((recordId) => recordId !== id));
    }
  };

  const handleDeleteRecords = () => {
    remove(selectedRecords);
    setSelectedRecords([]);
  };

  const handleUpdate = async (newValue) => {
    if (editing.type === "subContractor") {
      await updateSubcontractor({ id: editing.idObject, name: newValue });
    } else if (editing.type === "jobType") {
      await updateJobType({ id: editing.idObject, name: newValue });
    }
    setEditing(null);
  };

  return (
    <form>
      <Card
        variant="outlined"
        sx={{ p: "0.625rem 0.5rem", mb: "1rem", backgroundColor: "#EEE" }}
      >
        <Box display="flex" mb="0.625rem">
          <Typography>{tGeneral("report")}</Typography>
          <Box flex={1} />
          {selectedRecords.length > 0 && (
            <TooltipIconButton
              icon={<Delete />}
              onClick={handleDeleteRecords}
              label={tGeneral("delete")}
              color="red"
            />
          )}
          <Button
            sx={{
              textTransform: "none",
              borderRadius: "1rem",
              p: "0.25rem 2rem",
            }}
            onClick={() => handleSubmit(onSubmit)()}
            variant="contained"
          >
            {tGeneral("save")}
          </Button>
        </Box>
        <AutocompleteCell
          options={subcontractors.map((s) => ({ value: s.id, label: s.name }))}
          value={watch("idSubcontractor")}
          onCreate={onAddSubcontractor}
          onChange={(val) => setValue("idSubcontractor", val)}
          onEdit={(id, name) =>
            setEditing({ type: "subContractor", idObject: id, value: name })
          }
          startAdornment={<Apartment sx={{ color: "#E6B60C" }} />}
          label={tGeneral("enterprise")}
          isLoading={isSubcontractorLoading}
        />
        <Box
          display="grid"
          gridTemplateColumns="20px 2fr 2fr 1fr 1fr"
          gap="0.5rem"
          mb="0.5rem"
          alignItems="center"
        >
          <Box />
          <Typography>{tGeneral("name")}</Typography>
          <Typography textAlign="center">{tDailyReports("jobType")}</Typography>
          <Typography textAlign="center">
            {tDailyReports("assistants")}
          </Typography>
          <Typography textAlign="center">
            {tDailyReports("workedHours")}
          </Typography>
        </Box>
        {controlledFields.map((record, index) => (
          <Box
            display="grid"
            gridTemplateColumns="20px 2fr 2fr 1fr 1fr"
            columnGap="0.5rem"
            mb="0.5rem"
            key={record.id}
          >
            <Checkbox
              icon={<RadioButtonUnchecked />}
              checkedIcon={<RadioButtonChecked />}
              onChange={(e) => handleSelectRecord(index, e.target.checked)}
            />
            <TextField
              key={record.id}
              size="small"
              value={record.name}
              {...register(`records.${index}.name`)}
              disabled={record.assistants > 0}
              sx={{
                "& .Mui-disabled": {
                  backgroundColor: "#F5F5F5",
                },
                backgroundColor: "white",
              }}
              placeholder={tDailyReports("workerName")}
            />
            <AutocompleteCell
              options={jobTypes.map((jt) => ({ value: jt.id, label: jt.name }))}
              value={record.idJobType}
              onChange={(val) => update(index, { ...record, idJobType: val })}
              onCreate={(name) => onAddJobType(name, index, record)}
              onEdit={(id, name) =>
                setEditing({ type: "jobType", idObject: id, value: name })
              }
              isLoading={isJobTypeLoading}
              label={tDailyReports("jobType")}
            />
            <TextField
              key={record.id}
              size="small"
              type="number"
              {...register(`records.${index}.assistants`)}
              disabled={record.name !== ""}
              sx={{
                "& .Mui-disabled": {
                  backgroundColor: "#F5F5F5",
                },
                backgroundColor: "white",
              }}
            />
            <TextField
              key={record.id}
              size="small"
              type="number"
              {...register(`records.${index}.hours`)}
              sx={{ backgroundColor: "white" }}
            />
          </Box>
        ))}
        <TooltipIconButton
          label={tGeneral("add")}
          icon={<Add />}
          onClick={() =>
            append({
              id: v4(),
              name: "",
              bNew: true,
              idJobType: null,
              assistants: 0,
              hours: 0,
            })
          }
        />
      </Card>
      <UpdateTextFieldModal
        title={
          editing?.type === "jobType"
            ? tDailyReports("jobType")
            : tGeneral("enterprise")
        }
        onUpdate={handleUpdate}
        label={
          editing?.type === "jobType"
            ? tDailyReports("jobType")
            : tGeneral("enterprise")
        }
        onClose={() => setEditing(null)}
        isOpen={Boolean(editing)}
        value={editing?.value}
        isLoading={isUpdateSubcontractorLoading || isUpdateJobTypeLoading}
      />
    </form>
  );
}

export { ManpowerForm };
