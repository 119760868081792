import React from "react";
import { useLocation, useNavigate } from "react-router";
// Redux reducers & selectors
import { useSelector } from "react-redux";
import { selectCurrentEnterprise } from "../../features/enterprise/enterpriseSlice";
import { selectTheme } from "../../features/preferences/preferencesSlice";
import { selectCurrentUser } from "../../features/userSettings/userSettingsSlice";
// Material UI Components
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// Material UI Icons
import AutoStoriesIcon from "@mui/icons-material/AutoStories"; // User Manual
import HomeRoundedIcon from "@mui/icons-material/HomeRounded"; // Home
import PersonRoundedIcon from "@mui/icons-material/PersonRounded"; // Profile
import SettingsAccessibilityRoundedIcon from "@mui/icons-material/SettingsAccessibilityRounded"; // Preferences
import ShuffleRoundedIcon from "@mui/icons-material/ShuffleRounded"; // Menu Order
import VpnKeyIcon from "@mui/icons-material/VpnKey"; // Change Password
import { useTranslation } from "react-i18next";
import DrawerAvatar from "./../userAvatars/DrawerAvatar";

function DrawerSettings(props) {
  const { t: tGeneral } = useTranslation("general");
  // Hooks
  const location = useLocation();
  const navigate = useNavigate();
  //Get current user
  const user = useSelector(selectCurrentUser);

  // We check if theme == dark to render proper logo dynamically
  const darkMode = useSelector(selectTheme);
  //Get current enterprise
  const enterprise = useSelector(selectCurrentEnterprise);

  let logo;
  if (enterprise != null && enterprise.logo != null) {
    logo = (
      <Box
        component="img"
        sx={{
          width: "80%",
          height: "80%",
        }}
        alt="Company Logo"
        src={enterprise.logo}
      />
    );
  } else {
    logo = (
      <Box
        component="img"
        sx={{
          width: "80%",
          height: "80%",
        }}
        alt="Buildpeer Logo"
        src={
          darkMode
            ? require("../../assets/logo-dark.png")
            : require("../../assets/logo.png")
        }
      />
    );
  }

  const drawerContent = (
    <div>
      <List>
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname === "/enterprise/dashboard"}
            onClick={() => {
              navigate(`/enterprise/${enterprise?.id}/dashboard`);
              props?.toggle();
            }}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "rgba(251, 203, 4, 0.5)",
              },
            }}
          >
            <ListItemIcon>
              <HomeRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={tGeneral("home")} />
          </ListItemButton>
        </ListItem>
        {/* Profile */}
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname === "/user/profile"}
            onClick={() => {
              navigate("profile", { replace: true });
              props?.toggle();
            }}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "rgba(251, 203, 4, 0.5)",
              },
            }}
          >
            <ListItemIcon>
              <PersonRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={tGeneral("profile")} />
          </ListItemButton>
        </ListItem>
        {/* Change Password */}
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname === "/user/changePassword"}
            onClick={() => {
              navigate("changePassword", { replace: true });
              props?.toggle();
            }}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "rgba(251, 203, 4, 0.5)",
              },
            }}
          >
            <ListItemIcon>
              <VpnKeyIcon />
            </ListItemIcon>
            <ListItemText primary={tGeneral("changePassword")} />
          </ListItemButton>
        </ListItem>

        {/* Tickets */}
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname === "/user/tickets"}
            onClick={() => {
              navigate("tickets", { replace: true });
              props?.toggle();
            }}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "rgba(251, 203, 4, 0.5)",
              },
            }}
          >
            <ListItemIcon>
              <ShuffleRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={tGeneral("reportAnIssue")} />
          </ListItemButton>
        </ListItem>
        {/* Preferences */}
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname === "/user/preferences"}
            onClick={() => {
              navigate("preferences", { replace: true });
              props?.toggle();
            }}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "rgba(251, 203, 4, 0.5)",
              },
            }}
          >
            <ListItemIcon>
              <SettingsAccessibilityRoundedIcon />
            </ListItemIcon>
            <ListItemText primary={tGeneral("preferences")} />
          </ListItemButton>
        </ListItem>
        {/* User Manual */}
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname === "/user/userManual"}
            onClick={() => {
              navigate("userManual", { replace: true });
              props?.toggle();
            }}
            sx={{
              "&.Mui-selected": {
                backgroundColor: "rgba(251, 203, 4, 0.5)",
              },
            }}
          >
            <ListItemIcon>
              <AutoStoriesIcon />
            </ListItemIcon>
            <ListItemText primary={tGeneral("userManual")} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      {/* User Avatar */}
      <DrawerAvatar user={user} />
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{
        width: {
          xs: props.drawerWidth,
        },
        flexShrink: { xs: 1 },
      }}
      aria-label="drawer enterprise"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        variant="temporary"
        open={props.open}
        onClose={props?.toggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: props.drawerWidth || 240,
          },
        }}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: props.drawerWidth || 240,
          },
        }}
        open
      >
        {drawerContent}
      </Drawer>
    </Box>
  );
}

export default DrawerSettings;
