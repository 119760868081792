import { Save } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { PopUpAlert } from "../../components/PopUpAlert";
import {
  IOSSwitch,
  StyledMaterialReactTable,
  TitleWidgets,
  TooltipIconButton,
} from "../../components/shared";
import {
  useGetNotificationConfigQuery,
  useUpdateNotificationConfigMutation,
} from "../../features/project/projectSettingsApiSlice";

const DUMMYDATA = [{ moduleName: "Rfis", mail: false, app: true }];

const NotificationType = {
  NONE: 0,
  EMAIL: 1,
  PUSH: 2,
  BOTH: 3,
};

const NotificationModule = {
  DIRECTORY: "directory",
  DOCUMENTS: "documents",
  DRAWINGS: "drawings",
  PHOTOS: "photos",
  RFIS: "rfis",
  ANNOUNCEMENTS: "announcements",
  DAILYREPORTS: "dailyReports",
  TASKS: "tasks",
  MEETINGS: "meetings",
  INSPECTIONS: "inspections",
  ANNOTATIONS: "annotations",
  SUBMITTALS: "submittals",
};

const DEFAULT_NOTIFICATIONS_BY_PROJECT = [
  { moduleName: NotificationModule.DIRECTORY, value: NotificationType.BOTH },
  { moduleName: NotificationModule.DOCUMENTS, value: NotificationType.BOTH },
  { moduleName: NotificationModule.DRAWINGS, value: NotificationType.BOTH },
  { moduleName: NotificationModule.PHOTOS, value: NotificationType.BOTH },
  { moduleName: NotificationModule.RFIS, value: NotificationType.BOTH },
  {
    moduleName: NotificationModule.ANNOUNCEMENTS,
    value: NotificationType.BOTH,
  },
  { moduleName: NotificationModule.DAILYREPORTS, value: NotificationType.BOTH },
  { moduleName: NotificationModule.TASKS, value: NotificationType.BOTH },
  { moduleName: NotificationModule.MEETINGS, value: NotificationType.BOTH },
  { moduleName: NotificationModule.INSPECTIONS, value: NotificationType.BOTH },
  { moduleName: NotificationModule.ANNOTATIONS, value: NotificationType.BOTH },
  { moduleName: NotificationModule.SUBMITTALS, value: NotificationType.BOTH },
];

function ProjectNotifications() {
  const { t: tGeneral } = useTranslation("general");
  const { idProject } = useParams();

  const [notificationConfig, setNotificationConfig] = useState(
    DEFAULT_NOTIFICATIONS_BY_PROJECT,
  );

  const { data, isLoading } = useGetNotificationConfigQuery(idProject);
  const [
    updateConfig,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateNotificationConfigMutation();

  useEffect(() => {
    if (isUpdateSuccess) {
      PopUpAlert(
        "success",
        tGeneral("updatedSuccessfully"),
        tGeneral("configUpdated"),
      );
    }
    if (isUpdateError) {
      PopUpAlert("error", "Error", updateError.message);
    }
  }, [isUpdateError, isUpdateSuccess, updateError, tGeneral]);

  useEffect(() => {
    if (data) {
      setNotificationConfig(
        Object.entries(data)
          .filter(([moduleName]) =>
            Object.values(NotificationModule).includes(moduleName),
          )
          .map(([moduleName, value]) => ({
            moduleName,
            value,
          })),
      );
    }
  }, [data]);

  const onChange = (moduleName, email, push) => {
    setNotificationConfig((prev) =>
      prev.map((currentModule) => {
        if (currentModule.moduleName !== moduleName)
          return { ...currentModule };

        let newValue = NotificationType.NONE;
        if (email) newValue += 1;
        if (push) newValue += 2;
        return { moduleName: currentModule.moduleName, value: newValue };
      }),
    );
  };

  const handleSaveConfig = () => {
    const obj = {};
    notificationConfig.forEach(({ moduleName, value }) => {
      obj[moduleName] = value;
    });
    updateConfig({
      id: data?.id,
      ...(!data?.id && { idProject }),
      ...obj,
    });
  };

  const columns = [
    {
      header: tGeneral("module"),
      accessorKey: "moduleName",
      enableSorting: false,
      Cell: ({ cell }) => <Typography>{tGeneral(cell.getValue())}</Typography>,
      muiTableHeadCellProps: {
        textalign: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
    },
    {
      header: tGeneral("email"),
      accessorKey: "value",
      Cell: ({ cell, row }) => (
        <IOSSwitch
          checked={
            cell.getValue() === NotificationType.EMAIL ||
            cell.getValue() === NotificationType.BOTH
          }
          onChange={(e) =>
            onChange(
              row.original?.moduleName,
              e.target.checked,
              cell.getValue() === NotificationType.PUSH ||
                cell.getValue() === NotificationType.BOTH,
            )
          }
        />
      ),
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      enableSorting: false,
    },
    {
      header: "App",
      accessorKey: "value",
      Cell: ({ cell, row }) => (
        <IOSSwitch
          checked={
            cell.getValue() === NotificationType.PUSH ||
            cell.getValue() === NotificationType.BOTH
          }
          onChange={(e) =>
            onChange(
              row.original?.moduleName,
              cell.getValue() === NotificationType.EMAIL ||
                cell.getValue() === NotificationType.BOTH,
              e.target.checked,
            )
          }
        />
      ),
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      enableSorting: false,
    },
  ];

  const rows = useMemo(() => {
    const modules = notificationConfig.map((row) => ({
      moduleName: row.moduleName,
      value: row.value,
    }));

    const filteredModules = [];
    const unavailableModules = [NotificationModule.ANNOTATIONS];
    modules.forEach((module) => {
      if (!unavailableModules.includes(module.moduleName)) {
        filteredModules.push(module);
      }
    });

    return filteredModules;
  }, [notificationConfig]);

  return (
    <Box>
      <TitleWidgets
        title={tGeneral("notifications")}
        showMoreOptions={false}
        customElement={
          <TooltipIconButton
            icon={<Save />}
            onClick={handleSaveConfig}
            label={tGeneral("save")}
            isLoading={isUpdateLoading}
          />
        }
      />
      <StyledMaterialReactTable
        isLoading={isLoading}
        columns={columns}
        data={rows}
        enableRowActions={false}
      />
    </Box>
  );
}

export default ProjectNotifications;
