import { Check, Close } from "@mui/icons-material";
import {
  Box,
  Card,
  Divider,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TooltipIconButton } from "../shared";

function ModalCalibrate({ isOpen, onClose, width, onCalibrate }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");

  const { register, watch, getValues } = useForm({
    defaultValues: { unit: "m", size: 1, inches: 1 },
  });

  const handleCalibrate = () => {
    const { inches, size, unit } = getValues();
    let value = size;
    if (unit === "ft-in") {
      value = `${size}ft-${inches}in`;
    }
    onCalibrate(Number(width) * 0.957, value, unit);
    onClose();
  };

  const unit = watch("unit");
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Card sx={{ width: "30%", p: "20px 45px", borderRadius: "20px" }}>
        <Box display="flex">
          <Typography fontSize="1.563rem" fontWeight="600">
            {tDrawings("setCalibration")}
          </Typography>
          <Box flex={1} />
          <TooltipIconButton
            label={tGeneral("close")}
            icon={<Close />}
            onClick={onClose}
          />
          <TooltipIconButton
            label={tDrawings("calibrate")}
            icon={<Check />}
            onClick={handleCalibrate}
          />
        </Box>
        <Divider sx={{ my: "20px" }} />
        <Typography>{tDrawings("calibrateDescription")}</Typography>
        <br />
        <Typography fontStyle="italic" fontSize="0.875rem">
          {tDrawings("calibrationNote")}
        </Typography>
        <Box display="flex" flexDirection="column" mt="40px">
          <FormControl>
            <RadioGroup defaultValue="m">
              <FormControlLabel
                {...register("unit")}
                value="m"
                label={tGeneral("metric")}
                control={<Radio />}
              />
              <FormControlLabel
                {...register("unit")}
                value="ft-in"
                label={tGeneral("imperial")}
                control={<Radio />}
              />
            </RadioGroup>
          </FormControl>
          <Box display="flex" mt="30px">
            <TextField
              {...register("size")}
              label={unit === "m" ? tGeneral("meters") : tGeneral("feet")}
              type="number"
              min={unit === "m" ? 1 : 0}
            />
            {unit === "ft" && (
              <TextField
                {...register("inches")}
                type="number"
                label={tGeneral("inches")}
                min={1}
              />
            )}
          </Box>
        </Box>
      </Card>
    </Modal>
  );
}

export { ModalCalibrate };
