import moment from "moment";

function calculateDateDifference(date1, date2) {
  if (!date1 || !date2 || !moment(date1).isValid || !moment(date2).isValid) {
    return null;
  }
  const start = moment(date1);
  const end = moment(date2);
  const duration = moment.duration(end.diff(start));
  const days = Math.abs(duration.asDays());

  return days;
}

export default { calculateDateDifference };
