import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import { useCreateEnterpriseMutation } from "../../features/enterprise/enterpriseApiSlice";
import { emptyValidator } from "../../services/validators/emptyValidator";
import { positiveIntegerValidator } from "../../services/validators/positiveIntegerValidator";
import LoadingOverlay from "../LoadingOverlay";
import Swal from "sweetalert2";

const styles = {
  modalContent: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxWidth: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 5,
  },
  form: {
    "& > :not(style)": {
      m: 1,
      width: "90%",
    },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
};

const CreateEnterpriseModal = ({ open, handleClose }) => {
  const [name, setName] = useState({ value: "", error: "" });
  const [description, setDescription] = useState({ value: "", error: "" });
  const [subscription, setSubscription] = useState("users");
  const [userLimit, setUserLimit] = useState({ value: "", error: "" });
  const [projectLimit, setProjectLimit] = useState({ value: "", error: "" });

  const handleSubscriptionChange = (event, newSubscription) => {
    if (newSubscription === null) return;
    if (newSubscription === "users") {
      setProjectLimit({ value: 1, error: "" });
      setUserLimit({ value: 9999, error: "" });
    }
    if (newSubscription === "projects") {
      setProjectLimit({ value: 9999, error: "" });
      setUserLimit({ value: 1, error: "" });
    }
    setSubscription(newSubscription);
  };

  const [createEnterprise, { isLoading }] = useCreateEnterpriseMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate inputs to not be empty
    const nameError = emptyValidator(name.value);
    const descriptionError = emptyValidator(description.value);
    const userLimitError = positiveIntegerValidator(userLimit.value);
    const projectLimitError = positiveIntegerValidator(projectLimit.value);
    if (nameError || descriptionError || userLimitError || projectLimitError) {
      setName({ ...name, ...(nameError && { error: nameError }) });
      setDescription({
        ...description,
        ...(descriptionError && { error: descriptionError }),
      });
      setUserLimit({
        ...userLimit,
        ...(userLimitError && { error: userLimitError }),
      });
      setProjectLimit({
        ...projectLimit,
        ...(projectLimitError && { error: projectLimitError }),
      });
      return;
    }
    try {
      // Close the modal
      handleClose();
      // Create new enterprise endpoint
      const newEnterprise = await createEnterprise({
        name: name.value,
        description: description.value,
        userLimit: userLimit.value,
        projectLimit: projectLimit.value,
      }).unwrap();
      // If request was succesful, display feedback with SweetAlert
      if (newEnterprise) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: "success",
          title: "Enterprise created succesfully",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
      >
        <Box sx={styles.modalContent}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            Create New Enterprise
          </Typography>
          <Box component="form" sx={styles.form} noValidate autoComplete="off">
            <TextField
              id="name"
              label="Name"
              variant="standard"
              error={name.error ? true : false}
              helperText={name.error}
              value={name.value}
              onChange={(e) => setName({ value: e.target.value, error: "" })}
            />
            <TextField
              id="description"
              label="Description"
              variant="standard"
              error={description.error ? true : false}
              helperText={description.error}
              value={description.value}
              onChange={(e) =>
                setDescription({ value: e.target.value, error: "" })
              }
            />
            <Typography
              id="subscription-type"
              variant="h6"
              component="h3"
              sx={{ textAlign: "center", fontSize: 18 }}
            >
              Subscription Type
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={4} alignSelf={"center"}>
                <Typography
                  variant="h6"
                  component="h4"
                  sx={{
                    textAlign: "center",
                    fontSize: 14,
                  }}
                >
                  Unlimited
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                justifyContent={"center"}
                alignItems="center"
              >
                <ToggleButtonGroup
                  color="secondary"
                  value={subscription}
                  exclusive
                  onChange={handleSubscriptionChange}
                  aria-label="subscription type"
                  sx={{ width: "100%" }}
                >
                  <ToggleButton
                    value="users"
                    aria-label="users"
                    sx={{ width: "50%" }}
                  >
                    Users
                  </ToggleButton>
                  <ToggleButton
                    value="projects"
                    aria-label="projects"
                    sx={{ width: "50%" }}
                  >
                    Projects
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
            <TextField
              id="userLimit"
              label="Number of Users"
              variant="standard"
              type={"number"}
              inputProps={{
                min: 0,
                max: 9999,
              }}
              disabled={subscription === "users"}
              error={userLimit.error ? true : false}
              helperText={userLimit.error}
              value={userLimit.value}
              onChange={(e) => {
                setUserLimit({ value: e.target.value, error: "" });
              }}
            />
            <TextField
              id="projectLimit"
              label="Number of Projects"
              variant="standard"
              type={"number"}
              inputProps={{
                min: 0,
                max: 9999,
              }}
              disabled={subscription === "projects"}
              error={projectLimit.error ? true : false}
              helperText={projectLimit.error}
              value={projectLimit.value}
              onChange={(e) =>
                setProjectLimit({ value: e.target.value, error: "" })
              }
            />
            <Button
              type="submit"
              variant="contained"
              size="small"
              onClick={handleSubmit}
            >
              Create
            </Button>
          </Box>
        </Box>
      </Modal>
      <LoadingOverlay open={isLoading} />
    </>
  );
};

export default CreateEnterpriseModal;
