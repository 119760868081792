import * as React from "react";
import { useSelector } from "react-redux";
import {
  DEFAULT_PAGINATION_LIMIT,
  selectCurrentFiltersDesignTemplates,
  useGetDesignTemplatesQuery,
} from "../features/designTemplates";

export function hasAtLeastOneFilter(obj) {
  return Object.entries(obj).some(([key, value]) => {
    if (key === "page" || key === "limit") {
      return false;
    }

    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return value !== null && value !== undefined;
  });
}

function unique(data) {
  const ids = data.map(({ id }) => id);
  return data.filter(({ id }, index) => !ids.includes(id, index + 1));
}

const usePaginatedDesignTemplates = (props) => {
  const { idEnterprise, search } = props;
  const currentFilters = useSelector(selectCurrentFiltersDesignTemplates);

  const [total, setTotal] = React.useState(0);
  const [paginated, setPaginated] = React.useState([]);
  const [limit, setLimit] = React.useState(
    currentFilters.limit ?? DEFAULT_PAGINATION_LIMIT,
  );
  const [page, setPage] = React.useState(currentFilters.page ?? 0);

  const { data, isLoading, isFetching, isError, refetch } =
    useGetDesignTemplatesQuery(
      {
        limit,
        idEnterprise,
        page: page + 1,
        search: search ?? null,
        editedBy: currentFilters?.editedBy ?? null,
        startDate: currentFilters?.createdAfter ?? null,
        endDate: currentFilters?.createdBefore ?? null,
      },
      {
        skip: !idEnterprise,
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
      },
    );

  const reset = React.useCallback(() => {
    setTotal(0);
    setPaginated([]);
    refetch();
  }, [refetch]);

  React.useEffect(() => {
    if (currentFilters !== null && idEnterprise) {
      if (hasAtLeastOneFilter(currentFilters) || idEnterprise) {
        reset();
      }
    }
  }, [currentFilters, idEnterprise, reset]);

  React.useEffect(() => {
    if (data?.items?.length > 0 && !isFetching) {
      setTotal(data.total);
      setPaginated((prev) => unique([...prev, ...data.items]));
    }
  }, [data, isFetching, page]);

  const noResults =
    !isLoading &&
    !isFetching &&
    paginated?.length < 1 &&
    data?.items?.length < 1;

  const noMoreResults =
    !isLoading &&
    !isFetching &&
    data?.items?.length === 0 &&
    paginated.length > 0;

  return {
    total,
    paginated,
    noResults,
    noMoreResults,
    refetch: reset,
    isLoading,
    isFetching,
    isError,
  };
};

export { usePaginatedDesignTemplates };
