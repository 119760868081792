// @ts-check

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
// eslint-disable-next-line no-unused-vars
import React from "react";
import { useTranslation } from "react-i18next";

export default function Title() {
  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      <Typography variant="h4">{tProjectSchedule("Project Schedule")}</Typography>
    </Box>
  );
}
