import { Add, KeyboardArrowDown, RemoveRedEye } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  IconButton,
  Modal,
  Skeleton,
  Typography,
} from "@mui/material";
import Lottie from "lottie-react";
import moment from "moment";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import AnnouncementAnimation from "../../../assets/animations/dashboard/announcements.json";
import { useGetProjectAnnouncementsQuery } from "../../../features/project/projectApiSlice";
import AnnouncementForm from "../Announcements/AnnouncementForm";

import { selectCurrentRole } from "../../../features/project/projectSlice";
import { getLastPublication } from "../../../services/helpers/dateFormat";

function AnnouncementCard({
  id,
  urlAvatar,
  firstName,
  lastName,
  description,
  publishDate,
  expirationDate,
  isLast,
}) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tAnnouncements } = useTranslation("announcements");
  const [opened, setOpened] = useState(false);

  function formatDate(simpleDate) {
    const date = new Date(simpleDate);

    const months = [
      tGeneral("january"),
      tGeneral("february"),
      tGeneral("march"),
      tGeneral("april"),
      tGeneral("may"),
      tGeneral("june"),
      tGeneral("july"),
      tGeneral("august"),
      tGeneral("september"),
      tGeneral("october"),
      tGeneral("november"),
      tGeneral("december"),
    ];

    const dateBirthFormat = `${date.getDate()} ${
      months[date.getMonth()]
    } ${date.getFullYear()}`;

    return dateBirthFormat;
  }

  return (
    <Box
      key={id}
      p="10px 14px"
      position="relative"
      component="div"
      maxHeight={opened ? "500px" : "100px"}
      sx={{
        cursor: "pointer",
        borderBottom: isLast ? "" : "1px solid #F8F8F8",
        overflow: "hidden",
        transition: "0.5s max-height",
      }}
      onClick={() => setOpened((prev) => !prev)}
    >
      <Box sx={{ position: "absolute", right: 10, top: 0 }}>
        <KeyboardArrowDown
          sx={{
            transition: "0.5s transform ease",
            transform: `rotate(${opened ? "0deg" : "180deg"})`,
          }}
        />
      </Box>
      <Box display="flex" alignItems="center" columnGap="10px">
        <Card sx={{ borderRadius: "100%", p: "2px" }}>
          <Avatar src={urlAvatar} />
        </Card>
        <Typography fontWeight="bold">
          {firstName} {lastName}
        </Typography>
      </Box>
      <Typography fontSize="0.75rem" color="#CFCFCF">
        {`${tAnnouncements("expire")}: ${formatDate(expirationDate)}`}
      </Typography>
      <Typography>{description}</Typography>
    </Box>
  );
}

function Announcements() {
  const { t: tGeneral } = useTranslation("general");
  const navigate = useNavigate();
  const { idProject } = useParams();
  const { data, isLoading } = useGetProjectAnnouncementsQuery({
    idProject,
    view: 1,
  });
  const currentRole = useSelector(selectCurrentRole);
  const options = {
    animationData: AnnouncementAnimation,
    loop: true,
    style: { height: "200px" },
  };

  const [isAddAnnounceOpen, setIsAddAnnounceOpen] = useState(false);

  const handleOpenAdd = () => {
    setIsAddAnnounceOpen(true);
  };

  const handleCloseAdd = () => {
    setIsAddAnnounceOpen(false);
  };

  const publishedAnnouncements = useMemo(() => {
    if (data) {
      const announces = data.map((ann) => ({
        ...ann,
        lastPublish:
          ann.repeatRate !== null && ann.repeatRate.toLowerCase() !== "never"
            ? getLastPublication(ann.publishDate, ann.repeatRate)
            : ann.publishDate,
        lastExpiration:
          ann.repeatRate !== null && ann.repeatRate.toLowerCase() !== "never"
            ? getLastPublication(ann.expirationDate, ann.repeatRate)
            : ann.expirationDate,
      }));

      return announces.filter(
        (ann) =>
          new Date(ann.lastPublish) <= new Date() &&
          new Date(ann.expirationDate) >= new Date(),
      );
    }
    return [];
  }, [data]);

  const renderContent = () => {
    if (isLoading) {
      return [...Array(10)].map((_val, index) => (
        <Box key={index} width="100%" p="22px">
          <Box display="flex" columnGap="10px">
            <Skeleton width="50px" height="50px" variant="circular" />
            <Skeleton width="120px" variant="text" />
          </Box>
          <Skeleton width="110px" />
          <Skeleton />
        </Box>
      ));
    }
    if (publishedAnnouncements?.length > 0) {
      return publishedAnnouncements?.map((announce, index) => (
        <AnnouncementCard
          key={announce.id}
          {...announce}
          isLast={index === data.length - 1}
        />
      ));
    }
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="100%"
      >
        <Lottie
          animationData={AnnouncementAnimation}
          loop
          style={{ height: "200px" }}
        />
        <Typography color="#9F9F9F" fontWeight="600">
          {tGeneral("noAnno")}
        </Typography>
      </Box>
    );
  };

  return (
    <Box display="flex" flexDirection="column" overflow="hidden" flex={1}>
      <Box display="flex">
        <Typography fontSize="1.375rem">{tGeneral("announcements")}</Typography>
        <Box flex={1} />
        {currentRole?.announcements !== 0 && (
          <IconButton onClick={() => navigate("../announcements")}>
            <RemoveRedEye />
          </IconButton>
        )}
        {currentRole?.announcements > 1 && (
          <IconButton onClick={handleOpenAdd}>
            <Add />
          </IconButton>
        )}
      </Box>
      <Card sx={{ overflowY: "auto", flex: 1, mx: "5px", mb: "5px" }}>
        {renderContent()}
      </Card>
      <Modal
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        open={isAddAnnounceOpen}
      >
        <Card sx={{ width: "85%", p: "32px 20px" }}>
          <AnnouncementForm isModal onClose={handleCloseAdd} />
        </Card>
      </Modal>
    </Box>
  );
}

export { Announcements };
