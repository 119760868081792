import moment from "moment";

function getReportStatus(status) {
  let label = "OPEN";
  let value = "open_action";
  let color = "#5de280";
  if (status === "draft") {
    label = "DRAFT";
    value = "draft";
    color = "#FF891E";
  }
  if (status === "close") {
    label = "CLOSED";
    value = "closed";
    color = "#C2C2C2";
  }

  return { label, value, color };
}

function getCategory(category) {
  const categoryOptions = {
    1: {
      value: 1,
      label: "basic",
      color: "#BFBFBF",
    },
    2: {
      value: 2,
      label: "workProgress",
      color: "#5FC5F4",
    },
    3: {
      value: 3,
      label: "problem",
      color: "#ED8B23",
    },
    4: {
      value: 4,
      label: "delay",
      color: "#E9569F",
    },
    5: {
      value: 5,
      label: "manpower",
      color: "#CB6CE6",
    },
  };

  return categoryOptions[category];
}

function getCloseDatePillStyle(status, date) {
  if (!status || !date) return null;
  const today = moment();
  const dueDate = moment(date).endOf("day");
  let value = "draft";
  let label = "DRAFT";
  let color = "#FF891E";
  if (status === "open" && today <= dueDate) {
    value = "onTime";
    label = "STILL OPEN";
    color = "#FBCB04";
  } else if (status === "open" && today > dueDate) {
    value = "overdue";
    label = "STILL OPEN";
    color = "#e05353";
  }
  return { label, color, value };
}

function hasEmptyReportLocation(reports) {
  if (!reports) return false;
  if (reports.length === 0) return false;

  let found = false;
  for (let i = 0; i < reports.length; i++) {
    if (!reports[i]?.title) found = true;
  }
  return found;
}

function isLocationAlreadyAdded(reports, targetId) {
  if (reports.findIndex((report) => report?.idLocation === targetId) === -1)
    return false;
  return true;
}

function deleteReportItem(
  reports,
  locationTargetId,
  reportTargetTitle,
  reportTargetCreateDate,
) {
  // Clone reportItems
  // Can't manipulate directly
  const newItems = [...reports];
  // Find location item index where the report is in
  const locationTargetIndex = reports?.findIndex(
    (report) => report?.idLocation === locationTargetId,
  );
  if (locationTargetIndex === -1) return;
  // Filter location topics to remove selected report
  const newTopics = newItems[locationTargetIndex]?.topics?.filter(
    (report) =>
      report?.title !== reportTargetTitle &&
      report?.createDate !== reportTargetCreateDate,
  );
  // Assign new topics array
  newItems[locationTargetIndex].topics = newTopics;

  return newItems;
}

function avatarGroup(assignedTo, usersList) {
  const group = [];
  if (usersList?.length > 0) {
    for (let i = 0; i < assignedTo?.length; i++) {
      const index = usersList?.findIndex(function (item, j) {
        return item?.idUser === assignedTo?.[i];
      });
      group.push({
        id: usersList?.[index]?.idUser,
        name: `${usersList?.[index]?.user?.firstName} ${usersList?.[index]?.user?.lastName}`,
        urlAvatar: usersList?.[index]?.user?.urlAvatar,
      });
    }
  }
  return group;
}

export default {
  getReportStatus,
  getCloseDatePillStyle,
  hasEmptyReportLocation,
  isLocationAlreadyAdded,
  deleteReportItem,
  avatarGroup,
  getCategory,
};
