import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Divider,
  Modal,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useCreateProjectMutation } from "../../../features/project/projectApiSlice";
import { useCreatePresignedUrlMutation } from "../../../features/s3/s3ApiSlice";
import { PopUpAlert } from "../../PopUpAlert";
import { TooltipIconButton } from "../../shared";
import { BasicInfo } from "./BasicInfo";
import { CreateProjectConstants } from "./CreateProjectConstants";
import { ImportInfo } from "./ImportInfo";
import { Users } from "./Users";

function ModalCreateProject({ isOpen, onClose }) {
  const { t: tGeneral } = useTranslation("general");
  const { idEnterprise } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const formRef = useRef(null);
  const formContext = useForm({
    defaultValues: {
      title: "",
      type: null,
      timeZone: null,
      cover: null,
      logo: null,
      street: "",
      number: "",
      country: "",
      state: "",
      city: "",
      zipCode: "",
      constructionDirector: "",
      projectArchitect: "",
      projectManager: "",
      projectEngineer: "",
      users: [],
      costCodes: [],
      disciplines: [],
      locations: [],
      specifications: [],
      documents: [],
      exportedProject: null,
    },
  });

  const { handleSubmit, watch } = formContext;

  const [createPresignedUrl, { isLoading: isPresignedUrlLoading }] =
    useCreatePresignedUrlMutation();
  const [
    createProject,
    { isLoading: isCreateLoading, isSuccess, isError, error },
  ] = useCreateProjectMutation();

  const steps = [
    tGeneral("importInfo"),
    tGeneral("generalInfo"),
    tGeneral("users"),
    tGeneral("constants"),
  ];

  const componentsSteps = [
    <ImportInfo />,
    <BasicInfo />,
    <Users />,
    <CreateProjectConstants />,
  ];

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const onSubmit = async ({ title, timeZone, exportedProject, ...data }) => {
    const body = { ...data };
    if (data.logo) {
      const urlFile = await createPresignedUrl({
        idEnterprise,
        idProject: title,
        moduleName: "project-logo",
        name: data.logo.name,
      });
      fetch(urlFile.data, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: data.logo,
      });
      body.logo = urlFile.data.split("?")[0];
    }

    if (data.cover) {
      const urlFile = await createPresignedUrl({
        idEnterprise,
        idProject: title,
        moduleName: "project-cover",
        name: data.cover.name,
      });
      fetch(urlFile.data, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: data.cover,
      });
      body.cover = urlFile.data.split("?")[0];
    }
    createProject({
      ...body,
      idEnterprise,
      name: title,
      projectTimezone: timeZone,
    });
  };

  const handleNext = () => {
    if (currentStep < componentsSteps.length - 1) {
      setCurrentStep((prev) => prev + 1);
    } else if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true }),
      );
    }
  };

  useEffect(() => {
    if (isSuccess) {
      PopUpAlert(
        "sucess",
        tGeneral("createdSuccessfully"),
        tGeneral("projectCreated"),
      );
      onClose();
    }
    if (isError) {
      PopUpAlert("error", "Error", tGeneral(error?.data?.message));
      onClose();
    }
  }, [isSuccess, isError, error, tGeneral, onClose]);

  const [title, type, timeZone] = watch(["title", "type", "timeZone"]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <FormProvider {...formContext}>
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <Card
            sx={{
              height: "700px",
              width: "80vw",
              maxHeight: "700px",
              bgcolor: "white",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p="1rem"
            >
              <Typography fontSize="1.5625rem" fontWeight="bold">
                {tGeneral("createProject")}
              </Typography>
              <TooltipIconButton
                icon={<Close />}
                label={tGeneral("close")}
                onClick={onClose}
                disabled={isCreateLoading || isPresignedUrlLoading}
              />
            </Box>
            <Divider />
            <Stepper
              alternativeLabel
              activeStep={currentStep}
              sx={{ mt: "1rem" }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box
              width="100%"
              height="500px"
              maxHeight="500px"
              p="1rem"
              sx={{ overflowY: "auto" }}
            >
              {componentsSteps[currentStep]}
            </Box>
            <Box display="flex" justifyContent="space-between" px="1rem">
              <Button
                disabled={
                  currentStep === 0 || isCreateLoading || isPresignedUrlLoading
                }
                onClick={handleBack}
              >
                {tGeneral("back")}
              </Button>
              <ButtonGroup sx={{ columnGap: "0.5rem" }}>
                <Button
                  variant="text"
                  disabled={
                    isCreateLoading ||
                    isPresignedUrlLoading ||
                    (currentStep === 1 &&
                      (title === "" || timeZone === null || type === null)) ||
                    (currentStep === 2 &&
                      watch("users").some((u) => !u.idRole || !u.idUser))
                  }
                  onClick={handleNext}
                >
                  {currentStep === componentsSteps.length - 1
                    ? tGeneral("create")
                    : tGeneral("next")}
                </Button>
              </ButtonGroup>
            </Box>
          </Card>
        </form>
      </FormProvider>
    </Modal>
  );
}

export { ModalCreateProject };
