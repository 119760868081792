export const drawingsChecklist = {
  checkList: "Check list",
  totalCompleted: "Total completado",
  done: "Listo",
  revision: "Revisión",
  pending: "Por cargar",
  viewDrawings: "Ver planos",
  hideDrawings: "Ocultar planos",
  link: "Link",
  deleteRowTitle: "¿Quieres eliminar esta fila?",
  percentageCompleted: "Porcentaje completado",
};
