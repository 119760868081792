import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function ImageZoom({ children }) {
  return (
    <TransformWrapper>
      {({ zoomIn, zoomOut }) => (
        <Box
          sx={{
            cursor: "grab",
            width: "100%",
            height: "100%",
          }}
        >
          <Box sx={{ position: "absolute", zIndex: "1" }}>
            <IconButton aria-label="zoomIn" onClick={() => zoomIn()}>
              <AddCircle
                sx={{
                  color: "#FFE009",
                  backgroundColor: "white",
                  borderRadius: "25px",
                  p: 0,
                  m: 0,
                }}
              />
            </IconButton>
            <IconButton aria-label="zoomOut" onClick={() => zoomOut()}>
              <RemoveCircle
                sx={{
                  color: "#FFE009",
                  backgroundColor: "white",
                  borderRadius: "25px",
                  p: 0,
                  m: 0,
                }}
              />
            </IconButton>
          </Box>
          <TransformComponent>{children}</TransformComponent>
        </Box>
      )}
    </TransformWrapper>
  );
}

export { ImageZoom };
