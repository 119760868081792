import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  AttachFile,
  AttachMoney,
  LocationOn,
  ShowChart,
} from "@mui/icons-material";
import { Button, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useGetDailyReportChatQuery } from "../../../../features/project/modules/dailyReports/dailyReportsApiSlice";
import { useGetProjectByIdQuery } from "../../../../features/project/projectApiSlice";
import { selectCurrentUser } from "../../../../features/userSettings/userSettingsSlice";
import CaslContext from "../../../../utils/caslContext";
import { ReportLocationDescription } from "./ReportLocationDescription";

function TopicItem({ topic, selected, onSelect }) {
  const { idProject } = useParams();
  const { data: currentProject } = useGetProjectByIdQuery(idProject);
  const { t: tGeneral } = useTranslation("general");

  const { data: comments } = useGetDailyReportChatQuery(topic.id);

  return (
    <Box
      mb={2}
      p={2}
      borderRadius="8px"
      bgcolor={selected ? "#FDF9C4" : undefined}
      onClick={onSelect}
      sx={{ cursor: "pointer" }}
    >
      <Typography
        fontSize="1rem"
        fontWeight="400"
        color="#4B4B4B"
        noWrap
        textOverflow="ellipsis"
        width="50vh"
      >
        {topic.title}
      </Typography>
      <Box display="flex" justifyContent="space-between" pt={2}>
        <Box display="flex" gap={2} pl={2}>
          <Typography
            fontSize="12px"
            fontWeight="400"
            color="#8E8E8E"
            sx={{
              textDecoration: "underline",
              display: "flex",
              alignItems: "center",
              gap: "2px",
            }}
          >
            <AttachFile
              sx={{
                color: "#E6B60C",
                transform: "rotate(45deg)",
                fontSize: "16px",
              }}
            />{" "}
            {topic.files.length} {tGeneral("files")}
          </Typography>
          <Typography
            fontSize="12px"
            fontWeight="400"
            color="#8E8E8E"
            sx={{
              textDecoration: "underline",
              display: "flex",
              alignItems: "center",
              gap: "2px",
            }}
          >
            <AttachFile
              sx={{
                color: "#E6B60C",
                transform: "rotate(45deg)",
                fontSize: "16px",
              }}
            />{" "}
            {comments?.length} {tGeneral("comments")}
          </Typography>
        </Box>
        <Box pr={1}>
          <Typography fontSize="12px" fontWeight="400" color="#8E8E8E">
            {moment(topic.createDate)
              .tz(currentProject?.projectTimezone)
              .format("H:mm, DD, MMM, YY")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function LocationItem({
  location,
  selectedReportItem,
  onSelectTopic,
  category,
}) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDailyReports } = useTranslation("dailyReports");

  return (
    <Box p={2}>
      <Typography display="flex" alignItems="center" p="8px 16px">
        <LocationOn sx={{ color: "#E6B60C" }} />
        {location.path}
      </Typography>

      <Divider variant="middle" />

      {category === 2 && (
        <Box py={1} pl={2}>
          <Typography
            fontSize="16px"
            fontWeight="500"
            color="#4B4B4B"
            display="flex"
            justifyContent="start"
            gap={1}
            alignItems="center"
            component={'div'}
          >
            <ShowChart sx={{ fontSize: "16px", color: "#5571FF" }} />
            {tDailyReports("advance")}:
            <Typography>{`${location.advance} m3`}</Typography>
          </Typography>
          <Typography
            fontSize="16px"
            fontWeight="500"
            color="#4B4B4B"
            display="flex"
            justifyContent="start"
            gap={1}
            alignItems="center"
            component={'div'}
          >
            <AttachMoney sx={{ fontSize: "16px", color: "#5571FF" }} />
            {tGeneral("cost")}:
            <Typography>{`${location.cost} ${location.currency}`}</Typography>
          </Typography>
        </Box>
      )}

      {location?.topics.map((topic) => (
        <TopicItem
          key={topic.id}
          topic={topic}
          selected={selectedReportItem.id === topic.id}
          onSelect={() => onSelectTopic(topic)}
        />
      ))}
    </Box>
  );
}

function ReportLocationDetailsTable({ report }) {
  const { idProject } = useParams();
  const currentUser = useSelector(selectCurrentUser);
  const ability = useContext(CaslContext);

  const { t: tGeneral } = useTranslation("general");

  const isShared = useMemo(() => {
    if (report && idProject) {
      return report.sharedWith.indexOf(idProject) > -1;
    }
    return false;
  }, [report, idProject]);

  const locationItems = useMemo(() => {
    if (report) {
      return report.agendas;
    }
    return [];
  }, [report]);

  const disableChat = useMemo(() => {
    if (isShared) return true;
    if (report && currentUser) {
      const isDisabled = report.status === "close";
      if (!isDisabled && !ability.can("superAdmin", "dailyReports")) {
        const canComment =
          report.creators.indexOf(currentUser.id) > -1 ||
          report.assignedTo.indexOf(currentUser.id) > -1;
        if (canComment) {
          return false;
        }
        return true;
      }
      return isDisabled;
    }
    return false;
  }, [report, currentUser, isShared, ability]);

  const [selectedReportItem, setSelectedReportItem] = useState(
    locationItems?.[0]?.topics?.[0],
  );
  const [filter, setFilter] = useState("all");
  const [selectedLocation, setSelectedLocation] = useState(
    locationItems?.[0] ?? {},
  );

  const onSelectTopic = (topic) => {
    setSelectedReportItem(topic);
    const location = locationItems.find((location) =>
      location?.topics?.some((x) => x.id === topic.id),
    );
    setSelectedLocation(location ?? {});
  };

  return (
    <Box>
      <Box display="flex" gap={2} py={2}>
        <Button
          key="tab-location-all"
          variant="outlined"
          sx={{
            backgroundColor: filter === "all" ? "#FDF9C4" : "#FEFDE8",
            color: "black",
            borderColor: filter === "all" ? "#E6B60C" : "#E1E1E1",
            borderRadius: "16px",
            textTransform: "capitalize",
          }}
          onClick={() => {
            setFilter("all");
          }}
        >
          {tGeneral("all")}
        </Button>
        {locationItems.map((location) => {
          let locationLabel = location?.locationName;
          if (location?.locationType === "tier 2")
            locationLabel = `> ${locationLabel}`;
          if (location?.locationType === "tier 3")
            locationLabel = `>> ${locationLabel}`;

          return (
            <Button
              key={`tab-location-${location.id}`}
              variant="outlined"
              sx={{
                backgroundColor: filter === location.id ? "#FDF9C4" : "#FEFDE8",
                color: "black",
                borderColor: filter === location.id ? "#E6B60C" : "#E1E1E1",
                borderRadius: "16px",
                textTransform: "capitalize",
              }}
              onClick={() => {
                setFilter(location.id);
              }}
            >
              {locationLabel}
            </Button>
          );
        })}
      </Box>

      <Paper elevation={3} sx={{ display: "flex", height: "100%" }}>
        {report.category !== 3 && (
          <>
            <Box flex={1}>
              <Typography
                fontSize="20px"
                fontWeight="500"
                color="#4B4B4B"
                p="16px 8px"
              >
                {tGeneral("descriptions")}
              </Typography>
              <Divider variant="middle" />

              {locationItems
                .filter(
                  (location) => filter === "all" || filter === location.id,
                )
                .map((location) => (
                  <LocationItem
                    key={location.id}
                    location={location}
                    selectedReportItem={selectedReportItem}
                    onSelectTopic={onSelectTopic}
                    category={report.category}
                  />
                ))}
            </Box>

            <Divider orientation="vertical" flexItem />
          </>
        )}

        <Box flex={1} data-tour="view-dailyReport-5" overflow="auto" pb="20px">
          <ReportLocationDescription
            disabled={disableChat}
            location={selectedLocation}
            topic={selectedReportItem}
            category={report.category}
          />
        </Box>
      </Paper>
    </Box>
  );
}

export default ReportLocationDetailsTable;
