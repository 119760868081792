import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetRfiByIdQuery } from "../../features/project/modules/rfiApiSlice";
import { useGetTaskByIdQuery } from "../../features/project/modules/tasks/tasksApiSlice";
import { Question } from "../question";
import moment from "moment";
import { Delete, Edit, MoreVert } from "@mui/icons-material";

function DrawingsAnnotationLinkedModule({ annotation, onEdit }) {
  const { idProject, idEnterprise } = useParams();
  const navigate = useNavigate();
  const { t: tGeneral } = useTranslation("general");
  const [anchorEl, setAnchorEl] = useState(null);

  const { data: rfiData, isLoading: isRfiLoading } = useGetRfiByIdQuery(
    annotation.linkedId,
    {
      skip: annotation.linkedModule !== "rfi",
    },
  );
  const { data: taskData, isLoading: isTaskLoading } = useGetTaskByIdQuery(
    annotation.linkedId,
    {
      skip: annotation.linkedModule !== "task",
    },
  );

  const objectData = useMemo(() => {
    if (rfiData) {
      return { ...rfiData, deliveryDate: rfiData.dueDate };
    }
    if (taskData) {
      return {
        ...taskData,
        createdBy: taskData.createdByUser,
        assignedTo: taskData.assignedToUsers,
      };
    }
    return {};
  }, [rfiData, taskData]);

  const handleNav = () => {
    let mod = "rfi";
    if (annotation.linkedModule === "task") {
      mod = "tasks";
    }
    navigate(
      `/enterprise/${idEnterprise}/project/${idProject}/${mod}/${annotation.linkedId}`,
    );
  };

  if (isRfiLoading || isTaskLoading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <Box>
      <Box display="flex" columnGap="12px" alignItems="center">
        <Button
          variant="text"
          onClick={handleNav}
          style={{
            fontSize: "1.875rem",
            color: "#4991F3",
            textDecoration: "underline",
          }}
        >
          {tGeneral(annotation.linkedModule)} #{annotation.linkedObject.number}
        </Button>
        <Chip label={tGeneral(objectData.status === "pending" ? "open_action" : objectData.status).toUpperCase()} />
        <Box flex={1} />
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
          <MoreVert />
        </IconButton>
        <Menu
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
        >
          <MenuItem onClick={onEdit}>
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText>{tGeneral("edit")}</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              <Delete color="error" />
            </ListItemIcon>
            <ListItemText>
              <Typography color="error">{tGeneral("delete")}</Typography>
            </ListItemText>
          </MenuItem>
        </Menu>
      </Box>
      <Typography sx={{ color: "#B0B7C1", fontSize: "0.75rem" }}>
        {tGeneral("deliveryDate")}{" "}
        {moment(objectData.deliveryDate).format("D MMMM YYYY")}
      </Typography>
      <Divider sx={{ my: "12px" }} />
      <Box display="flex" alignItems="center">
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-around"
        >
          <Avatar
            src={objectData.createdBy.urlAvatar}
            sx={{ backgroundColor: "#FFC42D", border: "1px solid white" }}
          />
          <Typography>
            {objectData.createdBy.name ??
              `${objectData.createdBy.firstName} ${objectData.createdBy.lastName}`}
          </Typography>
          <Typography fontSize="0.75rem">{tGeneral("responsible")}</Typography>
        </Box>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-around"
        >
          {objectData.assignedTo.length > 1 ? (
            <AvatarGroup max={3}>
              {objectData.assignedTo.map((user) => (
                <Avatar
                  key={user.id}
                  src={user.urlAvatar}
                  sx={{ backgroundColor: "#FFC42D", border: "1px solid white" }}
                />
              ))}
            </AvatarGroup>
          ) : (
            <Avatar
              src={objectData.assignedTo[0].urlAvatar}
              sx={{ backgroundColor: "#FFC42D", border: "1px solid white" }}
            />
          )}
          <Typography textAlign="center">
            {objectData.assignedTo[0].name}{" "}
            {objectData.assignedTo.length > 1 &&
              `and +${objectData.assignedTo.length - 1}`}
          </Typography>
          <Typography fontSize="0.75rem">{tGeneral("assigned")}</Typography>
        </Box>
      </Box>
      <Question
        moduleName={annotation.linkedModule}
        object={objectData}
        title=""
      />
    </Box>
  );
}

export { DrawingsAnnotationLinkedModule };
