export const drawingsChecklist = {
  checkList: "Lista de verificação",
  totalCompleted: "Total concluído",
  done: "Concluído",
  revision: "Revisão",
  pending: "Pendente",
  viewDrawings: "Ver desenhos",
  hideDrawings: "Ocultar desenhos",
  link: "Link",
  deleteRowTitle: "Deseja excluir esta linha?",
  percentageCompleted: "Porcentagem concluída",
};
