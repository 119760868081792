export const materialTable = {
  actions: 'Ações',
  and: 'e',
  cancel: 'Cancelar',
  changeFilterMode: 'Alterar o modo de filtro',
  changeSearchMode: 'Alterar o modo de pesquisa',
  clearFilter: 'Limpar filtros',
  clearSearch: 'Limpar pesquisa',
  clearSelection: 'Limpar seleção',
  clearSort: 'Limpar classificações',
  clickToCopy: 'Clique para copiar',
  copy: 'Copiar',
  collapse: 'Recolher',
  collapseAll: 'Recolher tudo',
  columnActions: 'Ações das colunas',
  copiedToClipboard: 'Copiado para área de transferência',
  dropToGroupBy: 'Solte para agrupar por {column}',
  edit: 'Editar',
  expand: 'Expandir',
  expandAll: 'Expandir tudo',
  filterArrIncludes: 'Inclui',
  filterArrIncludesAll: 'Incluir tudo',
  filterArrIncludesSome: 'Inclui alguns',
  filterBetween: 'Entre',
  filterBetweenInclusive: 'Entre valores incluídos',
  filterByColumn: 'Filtrar por {column}',
  filterContains: 'Contém',
  filterEmpty: 'vazio',
  filterEndsWith: 'Termina com',
  filterEquals: 'Igual',
  filterEqualsString: 'Igual',
  filterFuzzy: 'Impreciso',
  filterGreaterThan: 'Maior que',
  filterGreaterThanOrEqualTo: 'Maior ou igual que',
  filterInNumberRange: 'Entre',
  filterIncludesString: 'Contém',
  filterIncludesStringSensitive: 'Contém',
  filterLessThan: 'Menor que',
  filterLessThanOrEqualTo: 'Menor ou igual que',
  filterMode: 'Modo de filtro: {filterType}',
  filterNotEmpty: 'Não é vazio',
  filterNotEquals: 'Não é igual',
  filterStartsWith: 'Começa com',
  filterWeakEquals: 'Igual',
  filteringByColumn: 'Filtrando por {column} - {filterType} {filterValue}',
  goToFirstPage: 'Ir para a primeira página',
  goToLastPage: 'Ir para a última página',
  goToNextPage: 'Ir para a próxima página',
  goToPreviousPage: 'Ir para a página anterior',
  grab: 'Agarrar',
  groupByColumn: 'Agrupar por {column}',
  groupedBy: 'Agrupado por ',
  hideAll: 'Ocultar tudo',
  hideColumn: 'Ocultar coluna {column}',
  max: 'Max',
  min: 'Min',
  move: 'Mover',
  noRecordsToDisplay: 'Não há registros a serem exibidos',
  noResultsFound: 'Nenhum resultado encontrado',
  of: 'de',
  or: 'ou',
  pin: 'Fixar',
  pinToLeft: 'Fixar à esquerda',
  pinToRight: 'Fixar à direita',
  resetColumnSize: 'Restaurar tamanho da coluna',
  resetOrder: 'Restaurar ordem',
  rowActions: 'Ações da linha',
  rowNumber: '#',
  rowNumbers: 'Número da linha',
  rowsPerPage: 'Linhas por página',
  save: 'Salvar',
  search: 'Pesquisar',
  selectedCountOfRowCountRowsSelected:
    '{selectedCount} de {rowCount} linha(s) selecionada(s)',
  select: 'Selecionar',
  showAll: 'Mostrar tudo',
  showAllColumns: 'Mostrar todas as colunas',
  showHideColumns: 'Mostrar/Ocultar colunas',
  showHideFilters: 'Mostrar/Ocultar filtros',
  showHideSearch: 'Mostrar/Ocultar barra de pesquisa',
  sortByColumnAsc: 'Ordenar por {column} em ascendente',
  sortByColumnDesc: 'Ordenar por {column} em descendente',
  sortedByColumnAsc: 'Ordenado por {column} em ascendente',
  sortedByColumnDesc: 'Ordenado por {column} em descendente',
  thenBy: ', depois por ',
  toggleDensity: 'Alternar densidade',
  toggleFullScreen: 'Alternar tela cheia',
  toggleSelectAll: 'Alternar selecionar tudo',
  toggleSelectRow: 'Alternar seleção da linha',
  toggleVisibility: 'Alternar visibilidade',
  ungroupByColumn: 'Desagrupar por {column}',
  unpin: 'Desfixar',
  unpinAll: 'Desfixar tudo',
};
