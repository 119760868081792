import { Add } from "@mui/icons-material";
import { Box, Button, Card } from "@mui/material";
import { useTour } from "@reactour/tour";
import { useMemo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { Agenda } from "./Agenda";

function Reports({
  agendas,
  updateAgendas,
  deleteAgenda,
  deleteTopic,
  category,
}) {
  const { t: tDailyReports } = useTranslation("dailyReports");
  const { setCurrentStep, isOpen, currentStep } = useTour();
  const handleAddAgenda = () => {
    const newAgendas = [...agendas];
    updateAgendas([
      ...newAgendas,
      {
        idLocation: null,
        idAux: Date.now().toString(),
        topics: [],
        order: newAgendas.length + 1,
        advance: 0,
        idUnit: null,
        cost: 0,
        currency: "",
        risk: "",
      },
    ]);
    if (isOpen) setCurrentStep?.(currentStep + 1);
  };

  const missingLocation = useMemo(() => {
    if (agendas) {
      const agendasWithNoLocation = agendas?.filter(
        (agenda) => agenda.idLocation === null,
      );
      return agendasWithNoLocation.length > 0;
    }
    return true;
  }, [agendas]);

  const handleEditAgenda = (newAgenda) => {
    const newAgendas = [...agendas].map((agenda) => {
      if (agenda.id === newAgenda.id && agenda.idAux === newAgenda.idAux) {
        return newAgenda;
      }
      return agenda;
    });
    updateAgendas(newAgendas);
  };

  const handleDeleteAgenda = (oldAgenda) => {
    if (oldAgenda.id) {
      deleteAgenda(oldAgenda.id);
    }
    const newAgendas = [...agendas].filter(
      (agenda) =>
        oldAgenda.id !== agenda.id || oldAgenda.idAux !== agenda.idAux,
    );
    updateAgendas(newAgendas);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;

    // Reorder the agendas array based on the drag and drop result
    const reorderedAgendas = Array.from(agendas);
    const [removed] = reorderedAgendas.splice(source.index, 1);
    reorderedAgendas.splice(destination.index, 0, removed);
    updateAgendas(
      reorderedAgendas.map((agenda, i) => ({ ...agenda, order: i + 1 })),
    );
  };

  return (
    <Box>
      <Box data-tour="add-dailyReport-11">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="agendaDroppable">
            {(provided, snapshot) => (
              <Card
                component="div"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {agendas.map((agenda, i) => (
                  <Draggable
                    draggableId={agenda.id ?? agenda.idAux}
                    index={i}
                    key={agenda.id}
                  >
                    {(provided, snapshot) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Agenda
                          key={agenda.id ?? agenda.idAux}
                          agenda={agenda}
                          onEditAgenda={handleEditAgenda}
                          deleteAgenda={handleDeleteAgenda}
                          deleteTopic={deleteTopic}
                          category={category}
                        />
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Card>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt="10px" mb="55px">
        {category !== 3 && (
          <Button
            variant="contained"
            sx={{ p: "16px 40px", textTransform: "none" }}
            onClick={handleAddAgenda}
            disabled={missingLocation}
            data-tour="add-dailyReport-10"
          >
            <Add />
            {tDailyReports("newReport")}
          </Button>
        )}
      </Box>
    </Box>
  );
}

export { Reports };
