import {
  AssessmentRounded,
  AssignmentLateRounded,
  AssignmentTurnedInRounded,
  BookRounded,
  CachedRounded,
  CampaignRounded,
  EventNoteRounded,
  FlagRounded,
  FolderRounded,
  GroupsRounded,
  InsertPhotoRounded,
  LibraryAddCheckRounded,
  NoteRounded,
  StickyNote2Rounded,
  ThumbUpRounded,
  WarningRounded,
  WatchLaterRounded,
} from "@mui/icons-material";
import { Box, Card, Skeleton, Typography } from "@mui/material";
import Lottie from "lottie-react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import PendingsAnimation from "../../../assets/animations/dashboard/pendings.json";
import { useGetUserPendingsQuery } from "../../../features/project/projectApiSlice";
import { useSelector } from "react-redux";
import { selectLocale } from "../../../features/preferences/preferencesSlice";
import { reportCategories } from "../../../constants";

const moduleIcons = {
  drawings: <NoteRounded />,
  punchList: <ThumbUpRounded />,
  directory: <BookRounded />,
  rfi: <CachedRounded />,
  submittal: <AssignmentTurnedInRounded />,
  deliveries: <EventNoteRounded />,
  hours: <WatchLaterRounded />,
  meetings: <GroupsRounded />,
  photso: <InsertPhotoRounded />,
  tasks: <StickyNote2Rounded />,
  inspections: <LibraryAddCheckRounded />,
  issues: <WarningRounded />,
  reports: <FlagRounded />,
  documents: <FolderRounded />,
  delays: <AssignmentLateRounded />,
  announcements: <CampaignRounded />,
  kpis: <AssessmentRounded />,
};

function Pendings() {
  const { t: tGeneral } = useTranslation("general");
  const { t: tSubmittals } = useTranslation("submittals");
  const { t: tDailyReports } = useTranslation("dailyReports");

  const moduleLabels = {
    drawings: "",
    punchList: "",
    directory: "",
    rfi: "RFI",
    submittal: tSubmittals("submittal"),
    deliveries: "",
    hours: "",
    meetings: "",
    photos: "",
    tasks: tGeneral("task"),
    inspections: tGeneral("inspection"),
    issues: "",
    reports: tGeneral("report"),
    documents: "",
    delays: "",
    announcements: "",
    kpis: "",
  };
  const navigate = useNavigate();
  const { idProject } = useParams();
  const { data, isLoading } = useGetUserPendingsQuery(idProject);
  const currentLocale = useSelector(selectLocale);

  const renderContent = () => {
    if (isLoading) {
      return [...Array(10)].map((_val, index) => (
        <Box
          display="flex"
          width="100%"
          height="67px"
          columnGap="10px"
          px="12px"
          key={index}
        >
          <Box display="flex" alignItems="center">
            <Skeleton height="25px" width="25px" variant="rectangular" />
          </Box>
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
          >
            <Skeleton width="100%" />
            <Skeleton width="70%" />
          </Box>
          <Box display="flex" alignItems="flex-end">
            <Skeleton width="100px" />
          </Box>
        </Box>
      ));
    }
    if (data?.length > 0) {
      return data?.map(
        ({ id, title, dueDate, moduleName, number, category }, index) => (
          <Box
            sx={{
              cursor: "pointer",
              ":hover": {
                backgroundColor: "#FEF9EE",
              },
              borderBottom: data.length > index ? "1px solid #F8F8F8" : "none",
            }}
            display="flex"
            height="60px"
            columnGap="12px"
            p="12px 16px"
            key={id}
            component="div"
            alignItems="center"
            onClick={() =>
              navigate(
                `./../${
                  moduleName === "reports" ? "dailyReports" : moduleName
                }/${id}`,
              )
            }
          >
            {moduleIcons[moduleName]}
            <Box width="100%">
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "calc(100vw/5)",
                }}
              >
                {title}
              </Typography>
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{ color: "#CFCFCF" }}
              >
                <Typography fontSize="12px">
                  {moment(new Date(dueDate))
                    .locale(currentLocale)
                    .format("DD MMMM YYYY")}
                </Typography>
                <Typography fontSize="12px">
                  {moduleLabels[moduleName]}
                  {category &&
                    ` | ${tDailyReports(
                      reportCategories[category - 1]?.label,
                    )}`}{" "}
                  #{number}
                </Typography>
              </Box>
            </Box>
          </Box>
        ),
      );
    }
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="100%"
      >
        <Lottie
          animationData={PendingsAnimation}
          loop
          style={{ height: "200px" }}
        />
        <Typography color="#9F9F9F" fontWeight="600">
          ¡{tGeneral("congratulations")}!
        </Typography>
        <Typography color="#9F9F9F">{tGeneral("noPending")}</Typography>
      </Box>
    );
  };

  return (
    <Box
      flex={1}
      overflow="hidden"
      display="flex"
      flexDirection="column"
      p="10px"
    >
      <Typography fontSize="1.375rem">
        {tGeneral("pendingItems")} ({data?.length || 0})
      </Typography>
      <Card sx={{ overflowY: "auto", flex: 1 }}>{renderContent()}</Card>
    </Box>
  );
}

export { Pendings };
