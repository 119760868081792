import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TotalCompleted } from "./TotalCompleted";
import { ChecklistDisciplineTable } from "./ChecklistDisciplineTable";

function ChecklistDisciplineAccordion({ discipline, index }) {
  const { t: tDrawingsChecklist } = useTranslation("drawingsChecklist");

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  return (
    <Box mb="0.5rem">
      <Divider sx={{ mb: "0.5rem" }} />
      <Box display="flex" p="0.688rem 1rem" alignItems="center">
        <Typography fontSize="1rem" fontWeight="medium">
          {index + 1}. {discipline.name}
        </Typography>
        <Box flex={1} />
        <Button
          variant="text"
          onClick={() => setIsAccordionOpen((prev) => !prev)}
          sx={{ textTransform: "none", minWidth: "unset", minHeight: "unset" }}
        >
          {isAccordionOpen
            ? tDrawingsChecklist("hideDrawings")
            : tDrawingsChecklist("viewDrawings")}
          <KeyboardArrowDown
            sx={{
              transition: "0.5s ease rotate",
              rotate: isAccordionOpen ? "180deg" : "none",
            }}
          />
        </Button>
      </Box>
      <TotalCompleted drawings={discipline.drawings} />
      <Box
        maxHeight={isAccordionOpen ? "500px" : "0px"}
        sx={{
          transition: "0.5s ease all",
          overflowY: isAccordionOpen ? "auto" : "hidden",
        }}
      >
        <ChecklistDisciplineTable
          items={discipline.drawings}
          idDiscipline={discipline.idDiscipline}
        />
      </Box>
    </Box>
  );
}
export { ChecklistDisciplineAccordion };
