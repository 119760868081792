import { Close, FormatShapes, TextSnippet } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { ReactComponent as ArrowIcon } from "../../assets/drawings/arrow.svg";
import { ReactComponent as EllipseIcon } from "../../assets/drawings/ellipse.svg";
import { ReactComponent as LineIcon } from "../../assets/drawings/line.svg";
import { ReactComponent as PolylineIcon } from "../../assets/drawings/polyline.svg";
import { ReactComponent as RectangleIcon } from "../../assets/drawings/rectangle.svg";
import { useTranslation } from "react-i18next";
import { usePublishAnnotationMutation } from "../../features/annotations";
import { PopUpAlert } from "../PopUpAlert";
import { useEffect } from "react";
import moment from "moment";

function ModalPublishAnnotations({ isOpen, onClose, annotations }) {
  const { t: tDrawings } = useTranslation("drawings");
  const { t: tGeneral } = useTranslation("general");

  const [
    publishAnnotation,
    {
      isError: isPublishError,
      isSuccess: isPublishSuccess,
      error: publishError,
      isLoading: isPublishLoading,
    },
  ] = usePublishAnnotationMutation();

  useEffect(() => {
    if (isPublishError) {
      PopUpAlert("error", "Error", publishError.message);
    }
    if (isPublishSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tDrawings("annotationPublishedMessage"),
      );
    }
  }, [isPublishError, isPublishSuccess, publishError, tDrawings, tGeneral]);

  const handlePublishAnnotations = async () => {
    await publishAnnotation({ ids: annotations.map((annot) => annot.id) });
    onClose();
  };

  const getAnnotationTitle = (annotation) => {
    const type = annotation.type.replace(" ", "").toLowerCase();
    if (type === "freetext" && annotation.text) {
      return annotation.text;
    }
    return tDrawings(type);
  };

  const getAnnotationIcon = (annotation) => {
    const type = annotation.type.replace(" ", "").toLowerCase();
    if (type === "rectangle") {
      return <RectangleIcon height="24px" width="24px" />;
    }
    if (type === "ellipse") {
      return <EllipseIcon height="24px" width="24px" />;
    }
    if (type === "arrow") {
      return <ArrowIcon height="24px" width="24px" />;
    }
    if (type === "line") {
      return <LineIcon height="24px" width="24px" />;
    }
    if (type === "polyline") {
      return <PolylineIcon height="24px" width="24px" />;
    }
    if (type === "freetext") {
      return <FormatShapes />;
    }
    return <TextSnippet />;
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          borderRadius: "12px",
          width: "30%",
          maxHeight: "50%",
          p: "32px 40px",
        }}
      >
        <Box display="flex" alignItems="center">
          <Typography sx={{ flex: 1 }} fontWeight="bold" fontSize="1.563rem">
            {tDrawings("publishAnnotations")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        {annotations.map((annot) => (
          <Box
            display="flex"
            key={annot.id}
            alignItems="center"
            columnGap="10px"
            p="5px"
          >
            <Box height="20px">{getAnnotationIcon(annot)}</Box>
            <Box>
              <Typography>{getAnnotationTitle(annot)}</Typography>
              <Typography fontSize="0.75rem" color="gray">
                {annot.createdBy?.firstName} {annot.createdBy?.lastName},{" "}
                {moment(annot.createdDate).format("M/D/YYYY")}
              </Typography>
            </Box>
          </Box>
        ))}
        <Box display="flex" columnGap="5px">
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            disabled={isPublishLoading}
            sx={{ py: "16px" }}
            onClick={onClose}
          >
            {tGeneral("cancel")}
          </Button>
          <Button
            variant="contained"
            fullWidth
            disabled={isPublishLoading}
            sx={{ py: "16px" }}
            onClick={handlePublishAnnotations}
          >
            {isPublishLoading ? (
              <CircularProgress color="primary" />
            ) : (
              tGeneral("publish")
            )}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}

export { ModalPublishAnnotations };
