import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function ModalProjectJoin({ isOpen, onClose }) {
  const [projectCode, setProjectCode] = useState("");
  const { t: tGeneral } = useTranslation("general");
  const { t: tOnboarding } = useTranslation("onboarding");
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{ width: "40%", height: "280px", p: "30px", borderRadius: "20px" }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontSize="1.563rem" fontWeight="bold">
            {tOnboarding("joinProject")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ my: "20px" }} />
        <FormControl fullWidth>
          <InputLabel>{tOnboarding("projectCode")}</InputLabel>
          <TextField
            label={tOnboarding("projectCode")}
            value={projectCode}
            onChange={(e) => setProjectCode(e.target.value)}
          />
        </FormControl>
        <Box
          display="flex"
          mt="18px"
          justifyContent="flex-end"
          columnGap="16px"
        >
          <Button
            sx={{ maxWidth: "240px", py: "16px" }}
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            {tGeneral("cancel")}
          </Button>
          <Button
            sx={{ maxWidth: "240px", py: "16px" }}
            fullWidth
            variant="contained"
          >
            {tGeneral("confirm")}
          </Button>
        </Box>
      </Card>
    </Modal>
  );
}

export { ModalProjectJoin };
