function objectToParamString(object) {
  let paramString = "";
  Object.keys(object).forEach((key) => {
    if (
      object[key] === undefined ||
      object[key] === null ||
      object[key] === "" ||
      object[key].length === 0
    )
      return;
    if (paramString.length > 0) {
      paramString += "&";
    }
    paramString += `${key}=${object[key]}`;
  });
  return paramString.length > 0 ? `?${paramString}` : "";
}

export { objectToParamString };
