// @ts-check
/* eslint react/prop-types: 0 */

import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CachedRounded from "@mui/icons-material/CachedRounded";
import CloseIcon from "@mui/icons-material/Close";
import FlagRounded from "@mui/icons-material/FlagRounded";
import FolderRounded from "@mui/icons-material/FolderRounded";
import GroupsRounded from "@mui/icons-material/GroupsRounded";
import LibraryAddCheckRounded from "@mui/icons-material/LibraryAddCheckRounded";
import NoteRounded from "@mui/icons-material/NoteRounded";
import StickyNote2Rounded from "@mui/icons-material/StickyNote2Rounded";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// eslint-disable-next-line no-unused-vars
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";
import { colors } from "../../../../utils/projectSchedule";
import TransitionComponent from "../TransitionComponent";

export default function Roadblock(props) {
  const { client, object, open, setDataSource, setOpen, users } = props;

  const [loading, setLoading] = useState(false);

  const { t: tGeneral } = useTranslation("general");
  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  const formContext = useForm({
    defaultValues: {
      idActivity: object.idActivity,
      type: object.type || "",
      description: object.description || "",
      assignees: object.assignees ? object.assignees.map((assignee) => assignee.id) : [],
      date: object.date || new Date().toISOString(),
      status: object.status || "open",
    },
  });
  const { control, formState, handleSubmit, reset, watch } = formContext;

  useEffect(() => {
    reset({
      idActivity: object.idActivity,
      type: object.type || "",
      description: object.description || "",
      assignees: object.assignees ? object.assignees.map((assignee) => assignee.id) : [],
      date: object.date || new Date().toISOString(),
      status: object.status || "open",
    });
  }, [object, reset]);

  const color = colors.find((color) => color.name === object.activity.color);

  const modules = [
    { icon: <NoteRounded />, name: tProjectSchedule("Drawings") },
    { icon: <CachedRounded />, name: tProjectSchedule("RFIs") },
    { icon: <GroupsRounded />, name: tProjectSchedule("Meetings") },
    { icon: <StickyNote2Rounded />, name: tProjectSchedule("Tasks") },
    { icon: <LibraryAddCheckRounded />, name: tProjectSchedule("Inspections") },
    { icon: <FlagRounded />, name: tProjectSchedule("Daily Reports") },
    { icon: <FolderRounded />, name: tProjectSchedule("Documents") },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const process = async (data) => {
    setLoading(true);
    try {
      if (!object.id) {
        const response = await client.request({
          data: {
            idActivity: data.idActivity,
            type: data.type,
            description: data.description,
            assignees: data.assignees,
            date: data.date,
            status: data.status,
          },
          method: "POST",
          url: "/roadblocks",
        });
        setDataSource(response.data);
      } else {
        const response = await client.request({
          data: {
            idActivity: data.idActivity,
            type: data.type,
            description: data.description,
            assignees: data.assignees,
            date: data.date,
            status: data.status,
          },
          method: "PUT",
          url: `/roadblocks/${object.id}`,
        });
        setDataSource(response.data);
      }
      const Toast = Swal.mixin({
        position: "center",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        toast: true,
      });
      Toast.fire({
        icon: "success",
        title: tProjectSchedule("Success"),
      });
    } catch (error) {
      const Toast = Swal.mixin({
        position: "center",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        toast: true,
      });
      Toast.fire({
        icon: "error",
        title: tProjectSchedule(error.response.data.error ? error.response.data.error : error),
      });
      // eslint-disable-next-line no-console
      console.error(error);
    }
    setLoading(false);
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={handleClose}
      open={open}
      PaperProps={{
        className: "project-schedule",
        sx: {
          borderRadius: "20px",
        },
      }}
      scroll="paper"
      // @ts-ignore
      TransitionComponent={TransitionComponent}
    >
      <FormProvider {...formContext}>
        <form onSubmit={handleSubmit(process)}>
          <DialogTitle component="div">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4">
                {!object.id ? tProjectSchedule("Create Roadblock") : tProjectSchedule("Edit Roadblock")}
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent
            dividers
            sx={{
              padding: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "30px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div>{tProjectSchedule("Select a type")}</div>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {modules.map(({ icon, name }) => {
                    return (
                      <Box
                        key={name}
                        onClick={() => {
                          formContext.setValue("type", name);
                        }}
                        sx={{
                          alignItems: "center",
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                        }}
                      >
                        <Radio
                          checked={watch("type") === name}
                          sx={{
                            padding: "0",
                          }}
                        />
                        {icon}
                        {name}
                      </Box>
                    );
                  })}
                </Box>
                {formState.isSubmitted && watch("type") === "" && (
                  <FormHelperText error>{tGeneral("fieldRequired")}</FormHelperText>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  gap: "30px",
                }}
              >
                <Box>
                  <InputLabel htmlFor="description">{tProjectSchedule("Description")}</InputLabel>
                  <Controller
                    control={control}
                    name="description"
                    render={({ field }) => {
                      return (
                        <TextField
                          {...field}
                          error={formState.errors.description !== undefined}
                          fullWidth
                          multiline
                          helperText={formState.errors.description && tGeneral("fieldRequired")}
                          inputProps={{ id: "description" }}
                          rows={5}
                          type="text"
                        />
                      );
                    }}
                    rules={{ required: true }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      width: "75%",
                    }}
                  >
                    <InputLabel htmlFor="assignees">{tProjectSchedule("Assignees")}</InputLabel>
                    <FormControl
                      error={!!formState.errors.assignees}
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Controller
                        control={control}
                        name="assignees"
                        render={({ field }) => {
                          return (
                            <Select
                              {...field}
                              fullWidth
                              id="assignees"
                              input={<OutlinedInput label="Chip" />}
                              multiple
                              renderValue={(selected) => (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                  {selected.map((value) => {
                                    const user = users.find((user) => user.user.id === value);
                                    return <Chip key={value} label={`${user.user.firstName} ${user.user.lastName}`} />;
                                  })}
                                </Box>
                              )}
                              variant="outlined"
                            >
                              {users.map((user) => {
                                return (
                                  <MenuItem key={user.user.id} value={user.user.id}>
                                    {user.user.firstName} {user.user.lastName}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          );
                        }}
                        rules={{ required: true }}
                      />
                      {formState.errors.assignees && <FormHelperText>{tGeneral("fieldRequired")}</FormHelperText>}
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      width: "25%",
                    }}
                  >
                    <InputLabel htmlFor="date">{tGeneral("date")}</InputLabel>
                    <Controller
                      control={control}
                      name="date"
                      render={({ field }) => {
                        return (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              {...field}
                              format={object.dateFormat}
                              onChange={(newValue) => field.onChange(dayjs(newValue).format("YYYY-MM-DD"))}
                              value={field.value ? dayjs(field.value.split("T")[0]) : null}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={formState.errors.date !== undefined}
                                  fullWidth
                                  inputProps={{ id: "date" }}
                                  helperText={formState.errors.date && tGeneral("fieldRequired")}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        );
                      }}
                      rules={{ required: true }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "5px",
                    }}
                  >
                    <AssignmentTurnedInIcon />
                    <strong>{tProjectSchedule("Activity")}</strong>
                  </Box>
                  <Box
                    sx={{
                      // @ts-ignore
                      backgroundColor: color.light,
                      // @ts-ignore
                      borderColor: color.dark,
                      borderRadius: "20px",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      padding: "5px 20px",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        // @ts-ignore
                        backgroundColor: color.dark,
                        borderBottomLeftRadius: "20px",
                        borderTopLeftRadius: "20px",
                        bottom: 0,
                        left: 0,
                        position: "absolute",
                        top: 0,
                        width: `${object.activity.progress}%`,
                        zIndex: 1,
                      }}
                    />
                    <Box
                      sx={{
                        position: "relative",
                        zIndex: 2,
                      }}
                    >
                      {object.activity.title}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            disableSpacing
            sx={{
              gap: "30px",
              padding: "30px",
            }}
          >
            {loading && <CircularProgress size={20} />}
            <Button color="secondary" onClick={handleClose} variant="outlined">
              {tProjectSchedule("Cancel")}
            </Button>
            <Button color="primary" type="submit" variant="contained">
              {!object.id ? tProjectSchedule("Create") : tProjectSchedule("Update")}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}
