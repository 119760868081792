import { ChevronRight } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

function Timeline({ steps, currentStep }) {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-around">
      {steps.map((step, i) => {
        const isActive = currentStep >= i;
        const isLast = i === steps.length - 1;

        if (isLast) {
          return (
            <Box key={step} display="flex" alignItems="center">
              <Box
                sx={{
                  borderRadius: "100%",
                  backgroundColor: isActive ? "#FFC42D" : "#fff",
                  width: "22px",
                  height: "22px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mr: "6px",
                  border: isActive ? "none" : "1px solid #DADDDF",
                }}
              >
                <Typography
                  fontSize="0.625rem"
                  fontWeight={isActive ? "bold" : "600"}
                >
                  {i + 1}
                </Typography>
              </Box>
              <Typography
                fontSize="0.75rem"
                sx={{ color: isActive ? "#000" : "#99989B" }}
                fontWeight={isActive ? "bold" : "regular"}
              >
                {step}
              </Typography>
            </Box>
          );
        }
        return [
          <Box key={step} display="flex" alignItems="center">
            <Box
              sx={{
                borderRadius: "100%",
                backgroundColor: isActive ? "#FFC42D" : "#fff",
                width: "22px",
                height: "22px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mr: "6px",
                border: isActive ? "none" : "1px solid #DADDDF",
              }}
            >
              <Typography
                fontSize="0.625rem"
                fontWeight={isActive ? "bold" : "600"}
              >
                {i + 1}
              </Typography>
            </Box>
            <Typography
              fontSize="0.75rem"
              sx={{ color: isActive ? "#000" : "#99989B" }}
              fontWeight={isActive ? "bold" : "regular"}
            >
              {step}
            </Typography>
          </Box>,
          <ChevronRight sx={{ fontSize: "1.125rem", mx: "16px" }} />,
        ];
      })}
    </Box>
  );
}

export { Timeline };
