export const payment = {
  paymentMethod: "支付方式",
  invoiceDetails: "发票详情",
  addNewCard: "添加新卡",
  addData: "添加数据",
  orderSummary: "订单摘要",
  pricePerProject: "每个项目的价格",
  pricePerUser: "每个用户的价格",
  projectsNumber: "项目数量",
  usersNumber: "用户数量",
  totalPerYear: "年总计",
  totalPerMonth: "月总计",
  agreement:
    "我理解我的发票将基于我的用户数量。付款后，可以通过邀请用户加入我的账户或项目来添加许可证。用户可以随时删除。",
  editCard: "编辑卡片",
  emailRecipient: "收件人电子邮件",
  companyName: "公司名称",
  addCard: "添加卡片",
  cardNumber: "卡号",
};
