// @ts-check
/* eslint react/prop-types: 0 */

// eslint-disable-next-line no-unused-vars
import React from "react";

import { AssignedUsersTooltip } from "../../shared/AssignedUsersTooltip";

export default function People(props) {
  const { options } = props;

  const users = options.map((option) => {
    return {
      bActive: true,
      id: option.id,
      name: option.name,
      urlAvatar: option.urlAvatar,
    };
  });

  return <AssignedUsersTooltip users={users} />;
}
