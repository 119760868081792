import { useMemo } from "react";
// Third-party library imports
import { Box, CircularProgress, Divider, IconButton, Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveLine } from "@nivo/line";
import { useTranslation } from "react-i18next";

// Icon imports
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import RefreshIcon from "@mui/icons-material/Refresh";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

// Project-specific absolute imports
import { useParams } from "react-router";
import { useGetProjectSchedulesQuery } from "../../../../features/project/analyticsApiSlice";
import dateUtil from "../../../../utils/date";

function ProjectSchedule({ size, isMock }) {
  const { t: tAnalytics } = useTranslation("analytics");
  const { idProject } = useParams();

  const {
    data: projectScheduleData,
    isError,
    isLoading,
    refetch,
  } = useGetProjectSchedulesQuery(idProject, {
    skip: isMock,
  });

  const handleRefetch = () => {
    refetch(); // Call refetch to reload data
  };

  // Function to transform projectScheduleData into the desired format
  const chartData = [
    {
      id: tAnalytics("progress"), // Unique identifier for each slice
      label: tAnalytics("progress"),
      value: isMock ? 78 : projectScheduleData?.totalProgress,
      color: "url(#progress-gradient)",
    },
    {
      id: tAnalytics("remaining"), // Unique identifier for each slice
      label: tAnalytics("remaining"),
      value: isMock ? 22 : 100 - (projectScheduleData?.totalProgress ?? 0),
      color: "#EEEEEE",
    },
  ];

  // Calculate the difference between the start and endDate to get the number of weeks
  const weeksNumber = Math.ceil(
    dateUtil.calculateDateDifference(projectScheduleData?.startDate, projectScheduleData?.endDate) / 7,
  );

  // Transform progressHistory data for ResponsiveLine chart
  const progressHistory = useMemo(() => {
    return [
      {
        id: tAnalytics("progressHistory"), // Identifier for the line
        color: "#E6B60C", // Color for the line
        data: isMock
          ? [
              { x: "W0", y: 0 },
              { x: `${tAnalytics("w")}1`, y: 20 },
              { x: `${tAnalytics("w")}2`, y: 30 },
              { x: `${tAnalytics("w")}3`, y: 60 },
              { x: `${tAnalytics("w")}4`, y: 70 },
              { x: `${tAnalytics("w")}5`, y: 72 },
              { x: `${tAnalytics("w")}6`, y: null },
              { x: `${tAnalytics("w")}7`, y: null },
            ]
          : [
              { x: "W0", y: 0 }, // Adds the initial week "W0" with a y-value of 0
              ...Array.from({ length: weeksNumber }, (_, index) => ({
                x: `${tAnalytics("w")} ${index + 1}`,
                y:
                  projectScheduleData?.progressHistory && projectScheduleData.progressHistory.length > index
                    ? parseFloat(projectScheduleData.progressHistory[index].toFixed(0))
                    : null, // Set to null if no data for this we
              })),
            ],
      },
    ];
  }, [isMock, projectScheduleData, tAnalytics, weeksNumber]);

  // Process remaininDays data
  const remainingDays = isMock ? 148 : projectScheduleData?.remainingDays;

  let content;
  switch (size) {
    case "sm":
    case "md":
    case "lg":
      content = (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "215px", // Adjust height as needed
            justifyContent: "space-between",
          }}
        >
          {/* Left Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "50%", // Each section takes 50% of the width
              padding: "16px",
            }}
          >
            {/* Remaining Days Box */}
            <Box sx={{ py: "8px" }}>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  color: "var(--Neutral-Neutral-500, #8E8E8E)",
                }}
              >
                {tAnalytics("remainingDays")}
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  color: "var(--Neutral-Neutral-600, #4B4B4B)",
                  mt: "6px",
                }}
              >
                {remainingDays} {tAnalytics("day", { count: remainingDays })}
              </Typography>
            </Box>

            {/* Divider */}
            <Divider sx={{ borderBottomWidth: 2 }} />

            {/* Progress Box */}
            <Box sx={{ py: "8px" }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  color: "var(--Neutral-Neutral-500, #8E8E8E)",
                }}
              >
                {tAnalytics("projectAdvancement")}
              </Typography>

              {/* Progress Details Box */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* Percentage */}
                <Typography
                  sx={{
                    fontSize: "48px",
                    fontStyle: "normal",
                    fontWeight: 700,
                    color: "var(--Neutral-Neutral-700, #1F1F1F)",
                  }}
                >
                  {chartData[0].value}%
                </Typography>

                {/* Nivo Graph Placeholder */}
                <Box
                  sx={{
                    width: 45,
                    height: 45,
                  }}
                >
                  <ResponsivePie
                    data={chartData}
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    innerRadius={0.7}
                    cornerRadius={2}
                    borderWidth={0}
                    startAngle={0}
                    padAngle={0}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 0.2]],
                    }}
                    colors={{ datum: "data.color" }}
                    enableArcLabels={false}
                    enableArcLinkLabels={false}
                    arcLabelsSkipAngle={10}
                    defs={[
                      {
                        id: "progress-gradient",
                        type: "linearGradient",
                        colors: [
                          { offset: 0, color: "#E5E900" },
                          { offset: 100, color: "#FBCB04" },
                        ],
                      },
                    ]}
                    fill={[
                      {
                        match: {
                          id: "Progress",
                        },
                        id: "progress-gradient",
                      },
                    ]}
                  />
                </Box>
              </Box>

              {/* Horizontal Graph Placeholder */}
              <Box>
                <Box
                  sx={{
                    height: 8,
                    backgroundColor: "#eee",
                    borderRadius: 2,
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      width: `${chartData[0].value}%`,
                      height: "100%",
                      background: "linear-gradient(90deg, #FBCB04 0%, #E5E900 100%)",
                      borderRadius: 2,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      color: "var(--Neutral-Neutral-500, #8E8E8E)",
                    }}
                  >
                    0%
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      color: "var(--Neutral-Neutral-500, #8E8E8E)",
                    }}
                  >
                    100%
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Vertical Divider */}
          <Box
            sx={{
              width: "2px",
              backgroundColor: "#EEE",
            }}
          />

          {/* Right Section (Placeholder for future Nivo chart) */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "50%", // Maintains width across conditions
            }}
          >
            {/* Typography Container */}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                ml: "12px",
              }}
            >
              <Typography
                sx={{
                  color: "#8E8E8E",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              >
                {tAnalytics("progressOverTime")}
              </Typography>
            </Box>

            {/* Conditional Rendering of Chart or No Data Message */}
            {(projectScheduleData?.progressHistory && projectScheduleData.progressHistory.length > 0) || isMock ? (
              <ResponsiveLine
                data={progressHistory}
                margin={{ top: 15, right: 15, bottom: 35, left: 45 }}
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: 0,
                  max: 100,
                  stacked: false,
                  reverse: false,
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 0,
                  tickRotation: 0,
                  tickValues: [...progressHistory[0].data.map((d) => d.x)],
                  format: (value) => (value === "W0" ? "" : value),
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  tickValues: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                  format: (value) => `${value}%`,
                }}
                theme={{
                  axis: {
                    ticks: {
                      text: {
                        fill: "#8E8E8E",
                      },
                    },
                  },
                }}
                enableGridX={false}
                enableGridY={false}
                legendOffsetX={0}
                legendOffsetY={0}
                legendOrientation="horizontal"
                enablePoints
                curve="basis"
                pointSize={6}
                pointColor={{ theme: "background" }}
                pointBorderColor={{ from: "serieColor" }}
                pointLabel="progresshistory.yFormatted"
                pointLabelYOffset={-15}
                enableArea
                areaBaselineValue={1}
                areaOpacity={0.5}
                enableCrosshair
                crosshairType="cross"
                useMesh
                defs={[
                  {
                    id: "gradientA",
                    type: "linearGradient",
                    colors: [
                      { offset: 0, color: "#F9DF3A", opacity: 1 },
                      { offset: 100, color: "#FFFFFF", opacity: 1 },
                    ],
                  },
                ]}
                fill={[{ match: "*", id: "gradientA" }]}
                markers={[
                  {
                    axis: "y",
                    value: 0,
                    lineStyle: { stroke: "#CACACA", strokeWidth: 1 },
                  },
                  {
                    axis: "x",
                    value: "S0",
                    lineStyle: { stroke: "#CACACA", strokeWidth: 1 },
                  },
                ]}
                tooltip={({ point }) => (
                  <Box
                    sx={{
                      background: "#FFFFFF",
                      padding: "10px",
                      borderRadius: "16px",
                      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
                      color: "#8E8E8E",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "18px",
                    }}
                  >
                    <Typography>{`${tAnalytics("week")} ${point.data.x.substring(1)}`}</Typography>
                    <Typography>{`${tAnalytics("progress")} ${point.data.yFormatted}%`}</Typography>
                  </Box>
                )}
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%", // Ensures it takes full width
                  height: "100%",
                }}
              >
                <SentimentVeryDissatisfiedIcon color="action" />
                <Typography
                  sx={{
                    color: "#8E8E8E",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    ml: "3px",
                  }}
                >
                  {tAnalytics("noDataAvailable")}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      );
      break;
    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      content = <></>;
  }

  if (isLoading && !isError && !isMock)
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (isError && !isLoading && !isMock)
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography
          sx={{
            color: "#8E8E8E",
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "22px",
          }}
        >
          {tAnalytics("somethingWentWrong")}
        </Typography>
        <IconButton onClick={handleRefetch}>
          <RefreshIcon />
        </IconButton>
      </Box>
    );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <Box sx={{ display: "flex", gap: "8px" }}>
        <TrendingUpIcon sx={{ color: "#E5E900" }} />
        <Typography sx={{ fontSize: "16px", fontStyle: "normal", fontWeight: "500" }}>
          {tAnalytics("projectProgress")}
        </Typography>
      </Box>
      {content}
    </Box>
  );
}

export { ProjectSchedule };
