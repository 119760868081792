// @ts-check
/* eslint react/prop-types: 0 */

import AddIcon from "@mui/icons-material/Add";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import RemoveIcon from "@mui/icons-material/Remove";
import { Button, ButtonGroup } from "@mui/material";
import Box from "@mui/material/Box";
// eslint-disable-next-line no-unused-vars
import React from "react";

export default function Zoom(props) {
  const { fitToProject, zoomIn, zoomOut } = props;

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        gap: "10px",
      }}
    >
      <ButtonGroup variant="outlined">
        <Button
          color="secondary"
          onClick={zoomOut}
          size="small"
          sx={{
            paddingBottom: "5.25px",
            paddingTop: "5.25px",
          }}
          variant="outlined"
        >
          <RemoveIcon />
        </Button>
        <Button
          color="secondary"
          onClick={fitToProject}
          size="small"
          sx={{
            paddingBottom: "5.25px",
            paddingTop: "5.25px",
          }}
          variant="outlined"
        >
          <FitScreenIcon />
        </Button>
        <Button
          color="secondary"
          onClick={zoomIn}
          size="small"
          sx={{
            paddingBottom: "5.25px",
            paddingTop: "5.25px",
          }}
          variant="outlined"
        >
          <AddIcon />
        </Button>
      </ButtonGroup>
    </Box>
  );
}
