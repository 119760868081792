import { Box, CircularProgress } from "@mui/material";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
// Redux
import { useSelector } from "react-redux";
import {
  useGetMeetingsQuery,
  useDeleteMeetingMutation,
} from "../../../features/project/modules/meetings/meetingApiSlice";

// Components
import { PopUpAlert } from "../../../components/PopUpAlert";

import { MeetingsTable } from "../../../components/projectDashboard/Meetings/MeetingsTable";
import { ModalFileViewer } from "../../../components/shared/ModalFileViewer";
import SendToEmail from "../../../components/shared/SendToEmail";
import { DISTRIBUTION_LIST_NAMES } from "../../../constants";

import { useSendToEmailExportMutation } from "../../../features/ses/sesApiSlice";
import { TitleWidgets } from "../../../components/shared";
import DeleteModal from "../../../components/projectDashboard/Meetings/DeleteModal";
import { useAppTourContext } from "../../../components/supportButton/context/context";
import CaslContext from "../../../utils/caslContext";
import { selectCurrentEnterprise } from "../../../features/enterprise/enterpriseSlice";

function Meetings() {
  const { idProject } = useParams();

  const ability = useContext(CaslContext);

  // Filters
  const [filters, setFilters] = useState({});
  const [selectedMeetings, setSelectedMeetings] = useState({});

  const [openDelete, setOpenDelete] = useState(false);

  const { data: meetingsData, isLoading } = useGetMeetingsQuery(
    {
      idProject,
      ...filters,
      ...(filters?.createdOn !== null &&
        filters?.createdOn !== undefined &&
        filters?.createdOn.length > 0 && {
          startDate: filters?.createdOn[0],
          endDate: filters?.createdOn[1],
        }),
      createdOn: null,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const [removeMeeting] = useDeleteMeetingMutation();
  const meetings = useMemo(() => meetingsData ?? [], [meetingsData]);

  const [
    sendMail,
    {
      isLoading: isSendMailLoading,
      isSuccess: isSendEmailSuccess,
      isError: isSendEmailError,
      error: sendEmailError,
    },
  ] = useSendToEmailExportMutation();

  const currentEnterprise = useSelector(selectCurrentEnterprise);

  const [pdfUrl, setPdfUrl] = React.useState(null);
  const [loadingData] = useState(false);
  const [sendingMeetings, setSendingMeetings] = useState(null);

  const { t: tMeetings } = useTranslation("meetings");
  const { t: tGeneral } = useTranslation("general");

  useEffect(() => {
    if (isSendEmailSuccess) {
      PopUpAlert("success", tGeneral("sent"), tGeneral("sentMessage"));
      setSendingMeetings(null);
    }
    if (isSendEmailError) {
      PopUpAlert("error", tGeneral("error"), sendEmailError.message);
      setSendingMeetings(null);
    }
  }, [isSendEmailError, isSendEmailSuccess, sendEmailError, tGeneral]);

  // Send email
  const handleSendEmailClick = (rowData) => {
    setSendingMeetings([rowData]);
  };

  const handleSendEmail = async (
    selectedMeeting,
    message,
    subject,
    recipients,
  ) => {
    const body = {
      message,
      subject,
      module: "meeting",
    };
    if (recipients?.length > 0) {
      body.recipients = recipients;
    }
    sendMail({
      idEnterprise: currentEnterprise?.id,
      idProject,
      idObject: selectedMeeting[0].id,
      body,
    });
  };

  const deleteMeeting = async () => {
    try {
      const body = {
        ids: Object.keys(selectedMeetings),
      };
      const response = await removeMeeting(body).unwrap();
      if (response) {
        setOpenDelete(false);
        setSelectedMeetings({});
        PopUpAlert(
          "success",
          tGeneral("done"),
          tGeneral("deletedSuccessfully"),
        );
      }
    } catch (err) {
      PopUpAlert("error", tGeneral("error"), tGeneral("cantDelete"));
    }
  };

  const {
    setState,
    state: { tourActive, run },
  } = useAppTourContext();

  useLayoutEffect(() => {
    if (tourActive && !run) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, run, setState]);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <TitleWidgets
        title={tMeetings("meetings")}
        moduleName="meetings"
        distributionListName={DISTRIBUTION_LIST_NAMES.meeting}
        showDelete={
          Object.keys(selectedMeetings).length > 0 &&
          ability.can("delete", "meetings")
        }
        onDelete={() => setOpenDelete(true)}
        enableSupport
        addNameTour="add-meetings-1"
        showExports={false}
      />
      <Box sx={{ height: "100%", width: "100%" }}>
        <MeetingsTable
          meetings={meetings}
          selectedMeetings={selectedMeetings}
          setSelectedMeetings={setSelectedMeetings}
          setFilters={(val) => setFilters(val)}
          isLoading={isLoading}
          sendToEmail={(meeting) => handleSendEmailClick(meeting)}
        />
        <DeleteModal
          isOpen={openDelete}
          onClose={() => setOpenDelete(false)}
          handleDelete={deleteMeeting}
        />

        {loadingData && (
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            marginTop={10}
          >
            <CircularProgress size={60} />
          </Box>
        )}
      </Box>
      {Boolean(pdfUrl) && (
        <ModalFileViewer
          file={{ url: pdfUrl }}
          isOpen={Boolean(pdfUrl)}
          onClose={() => setPdfUrl(null)}
        />
      )}
      {Boolean(sendingMeetings) && (
        <SendToEmail
          onClose={() => setSendingMeetings(null)}
          isOpen={Boolean(sendingMeetings)}
          title={
            sendingMeetings?.length > 1
              ? `${tGeneral("meetings")} (${sendingMeetings?.length})`
              : sendingMeetings[0]?.title
          }
          onSend={(message, subject, recipients) => {
            handleSendEmail(sendingMeetings, message, subject, recipients);
          }}
          isLoading={isSendMailLoading}
        />
      )}
    </Box>
  );
}

export default Meetings;
