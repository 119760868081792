import { apiSlice } from "../../api/apiSlice";

// TODO: Move rfi endpoints to rfiApiSlice
// TODO: Make apiSlice per module

export const locationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLocations: builder.query({
      query: (idProject) => `/project/location/get/${idProject}`,
      keepUnusedDataFor: 10,
      providesTags: ["Locations"],
    }),
    addLocations: builder.mutation({
      query: (body) => ({
        url: "project/location/add",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Locations"],
    }),
    addLocationTier1: builder.mutation({
      query: (body) => ({
        url: "project/location/addTier1",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects", "Locations"],
    }),
    deleteLocationTier: builder.mutation({
      query: (body) => ({
        url: "project/location/delete",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects", "Locations"],
    }),
    editLocations: builder.mutation({
      query: (body) => ({
        url: "project/location/update",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects", "Locations"],
    }),
    editLocationTier1: builder.mutation({
      query: (body) => ({
        url: "project/location/updateTier1",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Projects", "Locations"],
    }),
  }),
});

export const {
  useGetLocationsQuery,
  useAddLocationsMutation,
  useAddLocationTier1Mutation,
  useDeleteLocationTierMutation,
  useEditLocationsMutation,
  useEditLocationTier1Mutation,
} = locationApiSlice;
