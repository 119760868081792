import { Close } from "@mui/icons-material";
import {
  Avatar,
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useState } from "react";

function FilterSelect({
  onChange,
  selectedObjects,
  label,
  renderText,
  options = [],
  size = "medium",
}) {
  const [tempObjects, setTempObjects] = useState(selectedObjects);

  const handleOnChange = (event) => {
    const {
      target: { value },
    } = event;
    setTempObjects(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl size={size} fullWidth sx={{ maxWidth: "270px" }}>
      <InputLabel>{label}</InputLabel>
      <Select
        size={size}
        multiple
        value={tempObjects}
        onChange={handleOnChange}
        onClose={() => {
          onChange(tempObjects.length > 0 ? tempObjects : null);
        }}
        sx={{ backgroundColor: tempObjects.length ? "#FFF5DB" : null }}
        renderValue={(selected) => `(${selected.length}) ${renderText}`}
        input={
          <OutlinedInput
            label={label}
            startAdornment={
              tempObjects.length ? (
                <IconButton onClick={() => onChange(null)}>
                  <Close />
                </IconButton>
              ) : null
            }
          />
        }
        MenuProps={{ sx: { maxHeight: "30%" } }}
      >
        {options?.map(({ value, label, urlAvatar }) => (
          <MenuItem value={value}>
            <Box display="flex" alignItems="center" columnGap="10px">
              {urlAvatar !== undefined && (
                <Avatar src={urlAvatar}>
                  {label.split(" ")[0][0]}
                  {label.split(" ")[1][0]}
                </Avatar>
              )}
              {label}
            </Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export { FilterSelect };
