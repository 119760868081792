import { tour } from "./tour";

export const projectDirectory = {
  // general tour
  ...tour,
  moduleElement: "Usuario",
  moduleName: "Directorio",
  // ADD - guide me steps
  step1_addUser_title: "1. Agregar nuevo usuario al proyecto",
  step1_addUser_text:
    "Haz click aquí para agregar un nuevo usuario al proyecto",
  step2_addUser_title: "2. Seleccionar usuario",
  step2_addUser_text:
    "Escribe el nombre completo del usuario, a continuación seleccionalo de la lista.",
  step3_addUser_title: "3. Posición",
  step3_addUser_text:
    "Escribe cual es la posición que el usuario tiene en el proyecto.",
  step4_addUser_title: "4. Rol predeterminado",
  step4_addUser_text:
    "Selecciona un rol predeterminado para el usuario, si solo podrá ver y no editar, o si no tendrá ningún tipo de permiso.",
  step5_addUser_title: "5. Permisos individuales",
  step5_addUser_text:
    "Selecciona un tipo de rol para cada módulo de manera individual.",
  step6_addUser_title: "6. Confirmar",
  step6_addUser_text: "Haz click aquí para confirmar y agregará al usuario.",
  step_final_addUser:
    "Haz aprendido cómo agregar un nuevo usuario a un proyecto.",
  // VIEW - guide me steps
  step1_viewUser_title: "1. Ver información de usuario.",
  step1_viewUser_text:
    "Haz click aqui para visualizar la información de un usuario.",
  step2_viewUser_title: "2. Último inicio de sesión.",
  step2_viewUser_text:
    "En esta sección podrás ver la información sobre el último inicio de sesión del usuario.",
  step3_viewUser_title: "3. Actividad reciente.",
  step3_viewUser_text:
    "En esta sección podrás ver la información sobre la actividad reciente del usuario en formato de lista.",
  step_final_viewUser:
    "Haz aprendido cómo visualizar información sobre un usuario del proyecto.",
  // EDIT - guide me steps
  step1_editUser_title: "1. Editar usuario.",
  step1_editUser_text: "Haz click aquí para editar usuario.",
  step2_editUser_title: "2. Posición.",
  step2_editUser_text: "Edita la posición que el usuario tiene en el proyecto.",
  step3_editUser_title: "3. Rol predeterminado.",
  step3_editUser_text:
    "Edita el tipo de rol predeterminado para el contacto, si solo podrá ver y no editar, o si no tendrá ningún tipo de permiso.",
  step4_editUser_title: "4. Permisos individuales.",
  step4_editUser_text: "Edita los roles para cada modulo de manera individual.",
  step5_editUser_title: "5. Guardar",
  step5_editUser_text: "Haz click aquí para guardar los cambios.",
  step_final_editUser: "Haz aprendido cómo editar un usuario.",

  directory: "Directorio",
  addCompany: "AÑADIR COMPAÑIA",
  lastLogin: "Ultimo Inicio de Sesión",
  infoText: " no se ha conectado en ",
  days: " dias",
  wasConnectedToday: " estuvo conectado en las últimas 24h",
  hasNeverLoggedIn: " nunca ha iniciado sesión",
  latestActivity: "Ultima Actividad",
  createRfi: "Crear RFI 24",
  responseSubmittal: "Respuesta Submittal",
  createDailyReport: "Crear Reporte Diario",
  createRfi123: "Crear RFI 123",
  uploadPhotos: "Subir Fotos",
  newContact: "Nuevo Contacto",
  editContact: "Editar Contacto",
  generalInformation: "Información General",
  permissions: "Permisos",
  drawings: "Planos",
  punchList: "Punch List",
  rfis: "RFIs",
  submittals: "Submittals",
  deliveries: "Entregas",
  hours: "Horas",
  meetings: "Reuniones",
  photos: "Fotos",
  tasks: "Tasks",
  inspections: "Inspecciones",
  issues: "Issues",
  dailyReports: "Reportes Diarios",
  documents: "Documentos",
  delays: "Retraso",
  announcements: "Anuncios",
  dashbooard: "Dashboard",
  adminPanel: "Panel Admin",
  generalDirectory: "Directorio General",
  deleteContact: "Eliminar Contacto",
  confirmDelete:
    "¿Estás seguro de eliminar el contacto? Al realizar esta acción no podrá revertirse",
  currentPlan: "Plan actual ",
  activeUsers: " usuarios activos.",
  type: "Tipo",
  user: "Usuario",
  contact: "Contacto",
  usersLimit: "Límite de Usuarios",
  confirmUsersLimit:
    "Seguro que deseas proseguir con la adición de un usuario más? Al realizar esta acción se cargará un usuario extra en tu próxima factura.",
  deletedRoleMessage:
    "El rol actual de este usuario ha sido eliminado, por favor seleccione uno nuevo",
};
