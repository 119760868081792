import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { PopUpAlert } from "../../../PopUpAlert";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import {
  useAddCostCodeMutation,
  useUpdateCostCodeMutation,
} from "../../../../features/project/costCodeApiSlice";

function CostCodeModal({ isOpen, onClose, selectedCostCode = null }) {
  const { idProject } = useParams();

  const { t: tGeneral } = useTranslation("general");

  const [name, setName] = useState("");
  const [number, setNumber] = useState("");

  const [newCostCode] = useAddCostCodeMutation();
  const [updateCostCode] = useUpdateCostCodeMutation();

  const styles = {
    dialogTitle: {
      textAlign: "left",
      height: "60px",
      fontWeight: "bold",
    },
    iconButtonClose: {
      position: "absolute",
      right: 8,
      top: 8,
    },
    textField: {
      mx: "auto",
      width: "100%",
      mb: 1,
    },
    cancelButton: {
      width: "50%",
      color: "black",
      backgroundColor: "white",
      borderColor: "black",
      border: 1,
      ":hover": {
        color: "white",
        backgroundColor: "#b4b4b4",
      },
    },
    saveButton: {
      width: "50%",
      color: "black",
      backgroundColor: "#ffc900",
      ":hover": {
        color: "black",
        backgroundColor: "#d9ab00",
      },
    },
  };

  useEffect(() => {
    if (selectedCostCode) {
      setName(selectedCostCode.name);
      setNumber(selectedCostCode.code);
    } else {
      setName("");
      setNumber("");
    }
  }, [selectedCostCode, isOpen]);

  function resetVars() {
    onClose();
    setName("");
    setNumber("");
  }

  const onSubmit = async (e) => {
    let body = {
      idProject,
      name,
      code: number,
    };
    try {
      let response;
      if (selectedCostCode) {
        body.idCostCode = selectedCostCode.id;
        response = await updateCostCode(body).unwrap();
      } else {
        response = await newCostCode(body).unwrap();
      }

      if (response) {
        resetVars();
        PopUpAlert(
          "success",
          tGeneral("success"),
          selectedCostCode
            ? tGeneral("costCodeUpdated")
            : tGeneral("costCodeAdded"),
        );
      }
    } catch (err) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={(e) => resetVars()}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{ mx: "auto" }}
      PaperProps={{ sx: { borderRadius: "10px" } }}
    >
      <DialogTitle
        id="scroll-dialog-title"
        sx={[
          styles.dialogTitle,
          { padding: "16px 40px" },
          { marginBottom: "10px" },
        ]}
      >
        {selectedCostCode ? tGeneral("editCostCode") : tGeneral("addCostCode")}
        <IconButton
          aria-label="close"
          onClick={(e) => resetVars()}
          sx={[styles.iconButtonClose, { marginRight: "20px" }]}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={[{ mx: "auto" }, { padding: "0px 40px 10px 40px" }]}>
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={-1}
          sx={{ mx: "auto" }}
        >
          <TextField
            id="defaultCostCodeNumTextField"
            variant="outlined"
            type="number"
            label={tGeneral("code")}
            required
            value={number}
            onChange={(e) => {
              setNumber(e.target.value);
            }}
            sx={[styles.textField, { marginY: "20px" }]}
          />
          <TextField
            id="defaultNameTextField"
            variant="outlined"
            type="text"
            label={tGeneral("name")}
            required
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            sx={styles.textField}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={[
          { marginBottom: "10px" },
          { marginRight: "30px" },
          { marginLeft: "30px" },
        ]}
      >
        <Button
          sx={styles.cancelButton}
          type="submit"
          variant="contained"
          size="large"
          onClick={(e) => resetVars()}
        >
          {tGeneral("cancel")}
        </Button>
        <Button
          sx={styles.saveButton}
          type="submit"
          variant="contained"
          size="large"
          disabled={name.length === 0 || number.length === 0}
          onClick={onSubmit}
        >
          {selectedCostCode ? tGeneral("save") : tGeneral("add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { CostCodeModal };
