import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import React, { useEffect, useMemo, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import YourLogoHere from "../../assets/onboarding/your-logo.png";
import {
  useGetCitiesQuery,
  useGetCountriesQuery,
  useGetStatesQuery,
} from "../../features/geolocation";
import { IOSSwitch } from "../shared";

const mapItem = (item) => ({
  label: item.name,
  value: item.iso2,
});

function SectionTitle({ title }) {
  return [
    <Divider key={`${title}divider`} />,
    <Typography
      key={title}
      fontSize="1.125rem"
      sx={{
        bgcolor: "#FFC42D",
        display: "inline-block",
        p: "1px 15px",
        borderBottomRightRadius: "10PX",
        mb: "40px",
      }}
      width="auto"
    >
      {title}
    </Typography>,
  ];
}

function SectionDataCell({
  label,
  content,
  isEditing,
  value,
  values,
  onChange,
  icons,
  register,
  required,
  disabled = false,
  type = "text",
}) {
  const renderInput = () => {
    if (type === "switch") {
      return (
        <Card
          sx={{
            m: 0,
            p: "15px 16px",
            position: "relative",
            overflow: "inherit",
          }}
        >
          <Typography
            fontSize="0.875rem"
            fontWeight="bold"
            top={-10}
            sx={{ position: "absolute" }}
          >
            {label}
          </Typography>
          <Box display="flex" alignItems="center">
            {value ? icons?.trueIcon : icons?.falseIcon}
            <Typography sx={{ ml: "12px" }}>{content}</Typography>
            <Box flex="1" />
            <IOSSwitch
              checked={value}
              onChange={(e) => onChange(e.target.checked)}
            />
          </Box>
        </Card>
      );
    }

    if (type === "select") {
      return (
        <FormControl fullWidth>
          <InputLabel required={required} id={`${label}-labe-select`}>
            {label}
          </InputLabel>
          <Select
            disabled={disabled}
            labelId={`${label}-labe-select`}
            fullWidth
            sx={{ maxWidth: "100%" }}
            label={label}
            {...register}
            required={required}
            MenuProps={{ sx: { maxHeight: "400px" } }}
          >
            {values?.map(({ label, value }) => (
              <MenuItem value={value}>{label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    return (
      <TextField
        value={value}
        onChange={(e) => onChange(e.target.value)}
        fullWidth
        label={label}
        required={required}
        {...register}
      />
    );
  };

  return (
    <Box flex={1}>
      {isEditing ? (
        renderInput()
      ) : (
        <>
          <Typography fontSize="0.875rem" fontWeight="bold">
            {label}
          </Typography>
          <Typography fontSize="1rem">
            {typeof content === "string"
              ? content
              : content?.join(", ") ?? "N/A"}
          </Typography>
        </>
      )}
    </Box>
  );
}

function EnterpriseInfo({ onButtonClick, onBackClick }) {
  const formRef = useRef(null);
  const { t: tGeneral } = useTranslation("general");
  const { t: tOnboarding } = useTranslation("onboarding");
  const { t: tEnterprise } = useTranslation("enterprise");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const selectedCountry = watch("country");
  const selectedState = watch("state");
  const [isEditing, setIsEditing] = React.useState(true);
  const [selectedLogo, setSelectedLogo] = React.useState(null);
  const { data: countries, isLoading: isCountryLoading } =
    useGetCountriesQuery();
  const { data: states, isLoading: isStateLoading } = useGetStatesQuery(
    selectedCountry,
    {
      skip: selectedCountry === undefined,
    },
  );
  const { data: cities } = useGetCitiesQuery(
    { country: selectedCountry, state: selectedState },
    {
      skip: selectedState === undefined || selectedState === "",
    },
  );
  const countryItems = useMemo(() => {
    if (countries?.length) {
      return countries.map(mapItem);
    }
    return [];
  }, [countries]);
  const stateItems = useMemo(() => {
    if (states?.length) {
      return states.map(mapItem);
    }
    return [];
  }, [states]);

  const cityItems = useMemo(() => {
    if (cities?.length) {
      return cities.map((city) => ({ label: city.name, value: city.name }));
    }
    return [];
  }, [cities]);

  const handleDrop = (file) => {
    setSelectedLogo(file[0]);
  };
  const { getInputProps, getRootProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    multiple: false,
    onDrop: handleDrop,
  });

  const logoPreview = React.useMemo(() => {
    if (selectedLogo) {
      return URL.createObjectURL(selectedLogo);
    }
    return "";
  }, [selectedLogo]);

  const onSubmit = (data) => {
    const country = countryItems.find((c) => c.value === data.country)?.label;
    const state = stateItems.find((s) => s.value === data.state)?.label;

    onButtonClick({ ...data, country, state, logo: selectedLogo });
  };

  const locale = watch("locale");

  useEffect(() => {
    i18next.changeLanguage(locale);
  }, [locale]);

  return (
    <Box>
      <Typography
        fontSize="1.5rem"
        sx={{
          fontWeight: "bold",
          display: "inline-block",
          p: "1px 15px",
          borderBottomRightRadius: "10PX",
          mb: "20px",
        }}
        width="auto"
      >
        {tOnboarding("createEnterprise")}
      </Typography>
      <Box display="flex" columnGap="30px">
        <form ref={formRef} style={{ flex: 2 }}>
          <Box flex={2}>
            <SectionTitle title="General" />
            <Box display="flex" mb="60px" columnGap="20px">
              {/* Nombre Empresa */}
              <Box flex={1}>
                <SectionDataCell
                  required
                  register={register("name", { required: true })}
                  label={tOnboarding("officialName")}
                  isEditing={isEditing}
                />
              </Box>

              {/* Tipo Empresa */}
              <Box flex={1}>
                <SectionDataCell
                  required
                  register={register("type", { required: true })}
                  label={tOnboarding("enterpriseType")}
                  isEditing={isEditing}
                  type="select"
                  values={[
                    {
                      value: "construction",
                      label: tEnterprise("construction"),
                    },
                    { value: "realState", label: tEnterprise("realState") },
                    { value: "architect", label: tEnterprise("architect") },
                    { value: "contractor", label: tEnterprise("contractor") },
                    {
                      value: "subcontractor",
                      label: tEnterprise("subcontractor"),
                    },
                    { value: "maintenance", label: tEnterprise("maintenance") },
                    { value: "developer", label: tEnterprise("developer") },
                    {
                      value: "generalContractor",
                      label: tEnterprise("generalContractor"),
                    },
                    { value: "other", label: tEnterprise("other") },
                  ]}
                />
              </Box>
              <Box flex={1}>
                <SectionDataCell
                  required
                  register={register("locale", { required: true })}
                  label={tGeneral("systemLanguage")}
                  isEditing={isEditing}
                  type="select"
                  values={[
                    {
                      value: "en",
                      label: tGeneral("en"),
                    },
                    {
                      value: "es",
                      label: tGeneral("es"),
                    },
                  ]}
                />
              </Box>
            </Box>
            <SectionTitle title={tGeneral("address")} />
            <Box display="flex" columnGap="20px">
              {/* street */}
              <Box flex={1}>
                <SectionDataCell
                  register={register("street")}
                  label="Street"
                  isEditing={isEditing}
                />
              </Box>

              {/* number */}
              <Box flex={1}>
                <SectionDataCell
                  register={register("number")}
                  label={tGeneral("number")}
                  isEditing={isEditing}
                />
              </Box>

              {/* country */}
              <Box flex={1}>
                <SectionDataCell
                  register={register("country")}
                  label={tGeneral("country")}
                  isEditing={isEditing}
                  type="select"
                  values={countryItems}
                />
              </Box>
            </Box>
            <Box display="flex" mt="60px" columnGap="20px">
              {/* state */}
              <Box flex={1}>
                <SectionDataCell
                  register={register("state")}
                  label={tGeneral("state")}
                  isEditing={isEditing}
                  type="select"
                  disabled={!stateItems.length || isCountryLoading}
                  values={stateItems}
                />
              </Box>

              {/* city */}
              <Box flex={1}>
                <SectionDataCell
                  register={register("city")}
                  label={tGeneral("city")}
                  isEditing={isEditing}
                  type="select"
                  disabled={!cityItems.length || isStateLoading}
                  values={cityItems}
                />
              </Box>

              {/* zip code */}
              <Box flex={1}>
                <SectionDataCell
                  register={register("zipCode")}
                  label={tGeneral("zipCode")}
                  isEditing={isEditing}
                />
              </Box>
            </Box>
          </Box>
        </form>
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {selectedLogo ? (
            <img
              src={logoPreview}
              alt="Enterprise Logo"
              width="100%"
              style={{ objectFit: "contain" }}
            />
          ) : (
            <Box
              {...getRootProps({ className: "dropzone" })}
              sx={{
                textAlign: "center",
                padding: "25px",
                border: "3px dashed #B0B7C1",
                color: "#bdbdbd",
                cursor: "pointer",
                height: "100%",
                display: "flex",
              }}
            >
              <input {...getInputProps()} />
              <img
                src={YourLogoHere}
                alt="Your logo here"
                style={{ objectFit: "contain" }}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Divider sx={{ my: "30px" }} />
      <Box display="flex" justifyContent="flex-end" columnGap="16px">
        {isEditing ? (
          <>
            {/* cancel button */}
            <Button
              variant="contained"
              sx={{
                p: "16px 100px",
                backgroundColor: "white",
                color: "black",
                border: "1px solid black",
                "&:hover": {
                  backgroundColor: "gray",
                },
              }}
              // disabled={isUpdateProjectLoading}
              onClick={onBackClick}
            >
              <Typography fontWeight="medium" fontSize="1rem">
                {tGeneral("back")}
              </Typography>
            </Button>

            {/* Save Button */}
            <Button
              variant="contained"
              sx={{ p: "16px 100px" }}
              onClick={handleSubmit(onSubmit)}
              disabled={Object.keys(errors).length > 0}
            >
              <Typography fontWeight="medium" fontSize="1rem">
                {tGeneral("next")}
              </Typography>
            </Button>
          </>
        ) : (
          // Edit button
          <Button
            variant="contained"
            sx={{ p: "16px 62px" }}
            onClick={() => setIsEditing(true)}
          >
            <Typography fontWeight="medium" fontSize="1rem">
              Edit
            </Typography>
          </Button>
        )}
      </Box>
    </Box>
  );
}

export { EnterpriseInfo };
