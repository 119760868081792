import { Clear } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useGetLocationsQuery } from "../../../features/locations/locationApiSlice";
import { useGetActiveProjectUsersQuery } from "../../../features/project/projectApiSlice";
import { DateRangeFilter } from "../../shared/DateRangeFilter";
import { SearchLocationFilter } from "../../shared/SearchLocationFilter";
import { SearchUserFilter } from "../../shared/SearchUserFilter";

const styles = {
  secondaryFilter: {
    ml: 1,
    width: "23ch",
    verticalAlign: "center",
  },
  inputTitle: {
    backgroundColor: "transparent",
    borderRadius: 2,
    mt: 0,
  },
  selectInput: {
    backgroundColor: "#FFF5DB",
  },
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

function InspectionsFilters({ filters, setFilters }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tStatus } = useTranslation("status");

  const [currentTier, setCurrentTier] = useState(0);
  const [superLocation, setSuperLocation] = useState([]);

  const filterOptions = [
    {
      value: "creator",
      label: tGeneral("createdBy"),
    },
    {
      value: "status",
      label: tGeneral("status"),
    },
    {
      value: "location",
      label: tGeneral("location"),
    },
    {
      value: "createdOn",
      label: tGeneral("createdOn"),
    },
  ];

  const statusOptions = [
    {
      value: "draft",
      label: tGeneral("draft").toUpperCase(),
      color: "#ffc900",
    },
    {
      value: "in_revision",
      label: tStatus("in_revision").toUpperCase(),
      color: "#03cafc",
    },
    {
      value: "open",
      label: tStatus("open").toUpperCase(),
      color: "#5ce381",
    },
    {
      value: "closed",
      label: tGeneral("closed").toUpperCase(),
      color: "#707070",
    },
    {
      value: "rejected",
      label: tStatus("rejected").toUpperCase(),
      color: "red",
    },
  ];

  const { idProject } = useParams();

  const { data: projectUsersData } = useGetActiveProjectUsersQuery({
    idProject,
    moduleName: "inspections",
  });
  const { data: locations } = useGetLocationsQuery(idProject, {
    skip: !idProject,
    refetchOnMountOrArgChange: true,
  });

  const projectUsers = useMemo(() => {
    if (projectUsersData) {
      return projectUsersData.map((user) => ({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        urlAvatar: user.urlAvatar,
        ...user,
      }));
    }
    return [];
  }, [projectUsersData]);

  const handleChangeFilters = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const tiers = useMemo(() => {
    if (locations) {
      return Object.keys(locations);
    }
    return [];
  }, [locations]);

  const handleNextTier = (location) => {
    if (currentTier < tiers.length) {
      setSuperLocation((prev) => [...prev, location]);
      setCurrentTier((prev) => prev + 1);
    }
  };

  const handlePrevTier = () => {
    const lastId = superLocation[currentTier - 1].id;
    setSuperLocation((prev) => prev.filter((loc) => loc.id !== lastId));
    setCurrentTier((prev) => prev - 1);
  };

  const filterBySuperLocation = (location) => {
    if (superLocation.length === 0) {
      return true;
    }
    return superLocation[currentTier - 1].id === location.superLocation;
  };

  return (
    <Box
      display="flex"
      flex={1}
      justifyContent="flex-start"
      alignItems="center"
      p="10px"
      columnGap="10px"
    >
      <FormControl size="small" sx={{ maxWidth: "15%" }} fullWidth>
        <InputLabel>{tGeneral("addFilter")}</InputLabel>
        <Select
          label={tGeneral("addFilter")}
          value=""
          onChange={(e) => {
            handleChangeFilters(e.target.value, []);
          }}
        >
          {filterOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Created by Select */}
      {filters.creator && (
        <SearchUserFilter
          title={tGeneral("createdBy")}
          userList={projectUsers}
          selectedUsersList={projectUsers.filter(
            (user) =>
              filters.creator.findIndex(
                (creatorUser) => creatorUser === user.id,
              ) > -1,
          )}
          selectedUsersFormatted={filters.creator}
          handleUpdateUsersList={(list) => {
            handleChangeFilters(
              "creator",
              list.length > 0 ? list.map((user) => user.id) : null,
            );
          }}
          selectProps={{
            ml: 1,
            width: "200px",
          }}
        />
      )}

      {/* Status Select */}
      {filters.status && (
        <FormControl size="small" sx={styles.secondaryFilter} fullWidth>
          <InputLabel id="userTag1" sx={styles.inputTitle}>
            {tGeneral("status")}
          </InputLabel>
          <Select
            labelId="statusField"
            id="demo-multiple-checkbox1"
            multiple
            onChange={(e) => {
              handleChangeFilters("status", e.target.value);
            }}
            value={filters.status}
            startAdornment={
              filters.status.length > 0 ? (
                <IconButton
                  onClick={(e) => {
                    handleChangeFilters("status", null);
                  }}
                >
                  <Clear />
                </IconButton>
              ) : (
                ""
              )
            }
            input={
              <OutlinedInput
                label={filters.status.length > 0 ? "" : tGeneral("status")}
              />
            }
            renderValue={(selected) =>
              filters.status.length > 0
                ? `${tGeneral("status")} (${filters.status.length})`
                : `${tGeneral("status")}`
            }
            MenuProps={MenuProps}
            sx={styles.selectInput}
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox
                  checked={filters.status.indexOf(option.value) > -1}
                  sx={{
                    color: "black",
                    ":checked": {
                      color: "#FBCB04",
                    },
                    borderColor:
                      filters.status.indexOf(option.value) > -1
                        ? "#f7e4b2"
                        : "black",
                  }}
                />
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {/* Location Select */}
      {filters.location && (
        <SearchLocationFilter
          title={tGeneral("location")}
          selectedLocations={filters.location}
          onChange={(list) => {
            handleChangeFilters("location", list.length > 0 ? list : null);
          }}
          selectProps={{
            ml: 1,
            width: "200px",
          }}
        />
      )}

      {/* Date Range */}
      {filters.createdOn && (
        <DateRangeFilter
          onChange={(stringDates) => {
            handleChangeFilters("createdOn", stringDates);
          }}
        />
      )}
    </Box>
  );
}
export { InspectionsFilters };
