import moment from "moment";

export function timeZoneOffset(utcTime) {
  const tzoffset = utcTime.getTimezoneOffset() * 60000;
  const localTime = new Date(utcTime.getTime() + tzoffset);
  return localTime;
}

export function formatWithTimeZoneOffset(utcTime) {
  // const temp = timeZoneOffset(new Date(utcTime));
  const temp = new Date(utcTime);
  return moment(temp).format("DD MMMM YYYY");
}

export function formatTimeWithTimeZoneOffset(utcTime) {
  // const temp = timeZoneOffset(new Date(utcTime));
  const temp = new Date(utcTime);
  return moment(temp).format("DD MMMM YYYY, h:mm a");
}
