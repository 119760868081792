import { Typography, useMediaQuery } from "@mui/material";

function Title({ content }) {
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <>
      <Typography fontSize={isMobile ? "29px" : "47px"} fontWeight="bold">
        Document Sharing
      </Typography>
      <Typography
        fontSize={isMobile ? "16px" : "22px"}
        lineHeight={isMobile ? "30px" : "55px"}
      >
        {content}
      </Typography>
    </>
  );
}

export { Title };
