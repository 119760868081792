export const payment = {
  paymentMethod: "支払い方法",
  invoiceDetails: "請求書の詳細",
  addNewCard: "新しいカードを追加",
  addData: "情報を追加",
  orderSummary: "注文の概要",
  pricePerProject: "プロジェクトごとの価格",
  pricePerUser: "ユーザーごとの価格",
  projectsNumber: "プロジェクト数",
  usersNumber: "ユーザー数",
  totalPerYear: "年間合計",
  totalPerMonth: "月間合計",
  agreement:
    "私は、請求書がユーザー数に基づいて作成されることを理解しています。支払い後、アカウントやプロジェクトにユーザーを招待することでライセンスを追加できます。ユーザーはいつでも削除できます。",
  editCard: "カードを編集",
  emailRecipient: "メールの受信者",
  companyName: "会社名",
  addCard: "カードを追加",
  cardNumber: "カード番号",
};
