import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import {
  Add,
  Visibility,
  Edit,
  Restore,
  PictureAsPdf,
  Settings,
  ContactSupport,
} from "@mui/icons-material";
import ChecklistIcon from "@mui/icons-material/Checklist";
// Icons
import GroupsIcon from "@mui/icons-material/Groups"; // meeting
import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded"; // workflow
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded"; // guide me
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import Drawings from "../../assets/workflow/Drawings.png";
import Planos from "../../assets/workflow/Planos.png";
import { setCurrentGuideMe } from "../../features/project/projectSlice";
import { useGetActiveRfisQuery } from "../../features/project/modules/rfiApiSlice";
import { selectLocale } from "../../features/preferences/preferencesSlice";
import { useGuideMeModal } from "./hooks/useGuideMeModal";
import { useDrawingsSteps } from "./components/steps/drawings";
// Support Menu
import { SupportMenu } from "./components/SupportMenu";
import { SupportMenuModalMetting } from "./components/modals/SupportMenuModalMetting";
import { SupportMenuItemHeader } from "./components/SupportMenuItemHeader";
import { SupportContent } from "./components/SupportContent";
import { SupportListItem } from "./components/SupportListItem";
import { SupportMenuModalWorkflow } from "./components/modals/SupportMenuModalWorkflow";
import { SupportMenuModalGuideMe } from "./components/modals/SupportMenuModalGuideMe";

function SupportButtonDrawings() {
  const dispatch = useDispatch();

  const { t: translation } = useTranslation("drawings");
  const { t: tTour } = useTranslation("tour");
  const { idProject } = useParams();

  const [recordsLength, setRecordsLength] = useState(0);
  const locale = useSelector(selectLocale);
  const [imgWorkflow] = useState(locale === "en" ? Drawings : Planos);

  const { data: records, isSuccess } = useGetActiveRfisQuery({
    idProject,
  });

  const [
    {
      addSteps,
      viewSteps,
      editSteps,
      recycleSteps,
      exportSteps,
      settingsSteps,
      reviewAndPublishSteps,
    },
  ] = useDrawingsSteps();

  // Support button (make component)
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [anchorSupp, setAnchorSupp] = useState(null);
  // For open
  const handleClick = (event) => {
    setAnchorSupp(event.currentTarget);
    setMenuIsOpen(true);
  };
  // For close
  const handleClose = () => {
    setAnchorSupp(null);
    setMenuIsOpen(false);
  };
  // End support button

  // Meeting modal
  const [meetingModalIsOpened, setMeetingModalIsOpened] = useState(false);
  const openMeetingModal = () => setMeetingModalIsOpened(true);
  const closeMeetingModal = () => setMeetingModalIsOpened(false);
  // Workflow modal
  const [workflowModalIsOpened, setWorkflowModalIsOpened] = useState(false);
  const openWorkflowModal = () => setWorkflowModalIsOpened(true);
  const closeWorkflowModal = () => setWorkflowModalIsOpened(false);
  // guide me modal
  const [guideModalIsOpened, setGuideModalIsOpened] = useState(false);
  const openGuideModal = () => setGuideModalIsOpened(true);
  const closeGuideModal = () => setGuideModalIsOpened(false);

  // Tutorial Guide Me
  const [{ handleGuideMe }] = useGuideMeModal({
    addSteps,
    viewSteps,
    editSteps,
    recycleSteps,
    exportSteps,
    settingsSteps,
    drawings: { reviewAndPublish: reviewAndPublishSteps },
  });

  useEffect(() => {
    if (records && isSuccess) {
      setRecordsLength(records.length);
    }
  }, [records, isSuccess]);

  return (
    <div>
      {/* Button */}
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={translation("support")}
      >
        <IconButton
          id="support-button"
          aria-controls={menuIsOpen ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={menuIsOpen ? "true" : undefined}
          sx={{ color: "#007FFF" }}
          onClick={handleClick}
        >
          <ContactSupport />
        </IconButton>
      </Tooltip>

      <SupportMenu anchor={anchorSupp} open={menuIsOpen} onClose={handleClose}>
        {/* Meeting */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openMeetingModal();
          }}
        >
          <SupportMenuItemHeader title={tTour("meeting")}>
            <GroupsIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalMetting
          open={meetingModalIsOpened}
          onClose={closeMeetingModal}
        />
        {/* Workflow */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openWorkflowModal();
          }}
        >
          <SupportMenuItemHeader title={translation("workflow")}>
            <AppRegistrationRoundedIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalWorkflow
          open={workflowModalIsOpened}
          diagramImageUrl={imgWorkflow}
          onClose={closeWorkflowModal}
        />

        {/* Guide Me */}
        <MenuItem
          onClick={() => {
            setMenuIsOpen(false);
            openGuideModal();
          }}
        >
          <SupportMenuItemHeader title={translation("guideMe")}>
            <TouchAppRoundedIcon sx={{ color: "#000000" }} />
          </SupportMenuItemHeader>
        </MenuItem>
        <SupportMenuModalGuideMe
          title={`${translation("guideMe")} ${translation("moduleElement")}`}
          open={guideModalIsOpened}
          onClose={closeGuideModal}
        >
          <SupportContent>
            {/* ADD */}
            <SupportListItem
              can="add"
              moduleName="drawings"
              onClick={() => {
                handleGuideMe("add");
                dispatch(setCurrentGuideMe("drawings-add"));
                closeGuideModal();
              }}
            >
              <Add sx={{ marginRight: 1 }} />
              {`${translation("addButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* REVIEW AND PUBLISH */}
            <SupportListItem
              can="reviewAndPublish"
              moduleName="drawings"
              onClick={() => {
                handleGuideMe("drawings.reviewAndPublish");
                dispatch(setCurrentGuideMe("drawings-review-and-publish"));
                closeGuideModal();
              }}
            >
              <ChecklistIcon sx={{ marginRight: 1 }} />
              {`${translation("reviewAndPublishButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* VIEW */}
            <SupportListItem
              can="view"
              moduleName="drawings"
              onClick={() => {
                handleGuideMe("view");
                dispatch(setCurrentGuideMe("drawings-view"));
                closeGuideModal();
              }}
              disabled={recordsLength === 0}
            >
              <Visibility sx={{ marginRight: 1 }} />
              {`${translation("viewButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* EDIT */}
            <SupportListItem
              can="edit"
              moduleName="drawings"
              onClick={() => {
                handleGuideMe("edit");
                dispatch(setCurrentGuideMe("drawings-edit"));
                closeGuideModal();
              }}
              disabled={recordsLength === 0}
            >
              <Edit sx={{ marginRight: 1 }} />
              {`${translation("editButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* RECYCLE BIN */}
            <SupportListItem
              can="restore"
              moduleName="drawings"
              onClick={() => {
                handleGuideMe("recycle");
                dispatch(setCurrentGuideMe("drawings-recycle"));
                closeGuideModal();
              }}
              disabled={recordsLength === 0}
            >
              <Restore sx={{ marginRight: 1 }} />
              {`${translation("recycleButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* EXPORT */}
            <SupportListItem
              can="export"
              moduleName="drawings"
              onClick={() => {
                handleGuideMe("export");
                dispatch(setCurrentGuideMe("drawings-export"));
                closeGuideModal();
              }}
              disabled={recordsLength === 0}
            >
              <PictureAsPdf sx={{ marginRight: 1 }} />
              {`${translation("exportButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
            {/* SETTINGS */}
            <SupportListItem
              can="settings"
              moduleName="drawings"
              disabled={recordsLength === 0}
              onClick={() => {
                handleGuideMe("settings");
                dispatch(setCurrentGuideMe("drawings-settings"));
                closeGuideModal();
              }}
            >
              <Settings sx={{ marginRight: 1 }} />
              {`${translation("settingsButtonTour")} ${translation(
                "moduleElement",
              )}`}
            </SupportListItem>
          </SupportContent>
        </SupportMenuModalGuideMe>
      </SupportMenu>
    </div>
  );
}

export { SupportButtonDrawings };
