import { Close, DoneAll } from "@mui/icons-material";
import {
  Box,
  Card,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { TooltipIconButton } from "../../../shared";
import UserAvatar from "../../../userAvatars/UserAvatar";
import { useGetModifiedByLogIdObjectQuery } from "../../../../features/log/logApiSlice";

function ModalLastEditDesignTemplate({ isOpen, onClose, idObject }) {
  const { t: tGeneral } = useTranslation("general");
  const { data: editBy } = useGetModifiedByLogIdObjectQuery(idObject, {
    skip: !isOpen,
  });

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          width: "750px",
          minHeight: "400px",
          maxheight: "700px",
          display: "flex",
          flexDirection: "column",
          p: "40px",
        }}
      >
        <Box display="flex">
          <Typography fontSize="25px" fontWeight="bold" flex={1}>
            {tGeneral("lastEdit")}
          </Typography>
          <TooltipIconButton
            icon={<Close />}
            label={tGeneral("close")}
            onClick={onClose}
            color="gray"
          />
        </Box>
        <Divider sx={{ marginTop: 3, marginBottom: 3 }} />

        <List
          sx={{
            width: "100%",
            maxWidth: "100%",
            flex: 1,
            overflowY: "auto",
          }}
        >
          {(editBy ?? [])?.map((log) => (
            <ListItem alignItems="center" key={log.createdAt}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  flexDirection: "row",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                    width: "60%",
                    flexDirection: "row",
                  }}
                >
                  <UserAvatar
                    name={`${log.firstName} ${log.lastName}`}
                    size="Medium"
                    url={log.urlAvatar}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    <ListItemText
                      primary={`${log.firstName} ${log.lastName}`}
                      sx={{ mr: 2 }}
                    />
                    <ListItemText
                      primary={`${log.email}`}
                      sx={{ mr: 2, color: "#8E8E8E" }}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "40%",
                    flexDirection: "row",
                    position: "relative",
                    bottom: "15px",
                  }}
                >
                  <ListItemText
                    primary={moment(log.createdAt).format(
                      "DD MMMM YYYY h:mm A",
                    )}
                  />
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      </Card>
    </Modal>
  );
}

export { ModalLastEditDesignTemplate };
