/* eslint-disable no-inner-declarations */
/* eslint-disable no-case-declarations */
import { Close, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { v4 } from "uuid";
import JSZip from "jszip";
import {
  useGetPresignUrlsMutation,
  useAddDrawingV2Mutation,
} from "../../../features/drawings";

import {
  selectUploadingFiles,
  setUploadingFiles as setUploadingFilesAction,
} from "../../../features/drawings/uploadsSlice";
import {
  selectDownloadingFiles,
  setDownloadingFiles as setDownloadingFilesAction,
} from "../../../features/drawings/downloadsSlice";

import { TooltipIconButton } from "../../shared";
import {
  useAbortMultipartUploadMutation,
  useCompleteMultipartUploadMutation,
  useContinueMultipartUploadMutation,
  useCreatePresignedUrlMutation,
  useInitiateMultipartUploadMutation,
} from "../../../features/s3/s3ApiSlice";
import { useCreateDocumentMutation } from "../../../features/project/modules/documents/documentsApiSlice";
import { PopUpAlert } from "../../PopUpAlert";
import {
  useCreateImageFromUrlMutation,
  useUploadThumbnailMutation,
} from "../../../features/project/imageApiSlice";
import { getVideoThumbnail } from "../../../utils/video";

import XlsIcon from "../../../assets/documents/XlsIcon.svg";
import DocIcon from "../../../assets/documents/DocIcon.svg";
import PptIcon from "../../../assets/documents/PptIcon.svg";
import PdfIcon from "../../../assets/documents/PdfIcon.svg";
import DwgIcon from "../../../assets/documents/DwgIcon.svg";
import ZipIcon from "../../../assets/documents/ZipIcon.svg";
import FileIcon from "../../../assets/documents/FileIcon.svg";
import AudioIcon from "../../../assets/documents/AudioIcon.svg";
import VideoIcon from "../../../assets/documents/VideoIcon.svg";
import ImageIcon from "../../../assets/documents/ImageIcon.svg";

import RetryIcon from "../../../assets/documents/RetryIcon.svg";
import CheckIcon from "../../../assets/documents/CheckIcon.svg";
import LoadingIcon from "../../../assets/documents/LoadingIcon.svg";

import UploadIcon from "../../../assets/documents/UploadIcon.svg";
import DownloadIcon from "../../../assets/documents/DownloadIcon.svg";
import pdf from "../../../utils/pdf";

function UploadDrawingsModal() {
  const { idProject, idEnterprise } = useParams();
  const { t: tGeneral } = useTranslation("general");
  const [tab, setTab] = useState("uploads");

  const [errorFiles, setErrorFiles] = useState([]);

  // Upload
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const uploadingModuleFiles = useSelector(selectUploadingFiles);

  // Download
  const [downloadingFiles, setDownloadingFiles] = useState([]);
  const [downloadedFiles, setDownloadedFiles] = useState([]);
  const downloadingModuleFiles = useSelector(selectDownloadingFiles);

  // Upload endpoints
  const [createPresignedUrl] = useCreatePresignedUrlMutation();
  const [createDocument] = useCreateDocumentMutation();
  const [getPresignUrls] = useGetPresignUrlsMutation();
  const [addDrawingV2] = useAddDrawingV2Mutation();
  const [createImage] = useCreateImageFromUrlMutation();
  const [uploadThumbnail] = useUploadThumbnailMutation();

  // Multipart endpoints
  const [initiateMultipartUpload] = useInitiateMultipartUploadMutation();
  const [continueMultipartUpload] = useContinueMultipartUploadMutation();
  const [completeMultipartUpload] = useCompleteMultipartUploadMutation();
  const [abortMultipartUpload] = useAbortMultipartUploadMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (uploadingModuleFiles) {
      const newFiles = [...uploadingModuleFiles];
      setUploadingFiles((prev) => [
        ...prev.map((f) => ({ ...f, processed: true })),
        ...newFiles.map((file) => ({
          ...file,
          id: v4(),
        })),
      ]);
      dispatch(setUploadingFilesAction(null));
    }
  }, [uploadingModuleFiles, dispatch, setUploadingFiles]);

  useEffect(() => {
    if (downloadingModuleFiles) {
      const newFiles = [...downloadingModuleFiles];
      setDownloadingFiles((prev) => [
        ...prev.map((f) => ({ ...f, processed: true })),
        ...newFiles.map((file) => ({
          ...file,
          id: v4(),
        })),
      ]);
      dispatch(setDownloadingFilesAction(null));
    }
  }, [downloadingModuleFiles, dispatch, setDownloadingFiles]);

  const uploadDrawing = useCallback(
    async (files) => {
      const batchId = v4();
      const newFiles = [...files].filter((f) => !f.processed);

      const timestampStr = new Date().toISOString().replace(/[-:.]/g, "");

      const fileMeta = newFiles.map((file) => ({
        idProject,
        idEnterprise,
        filename: `${timestampStr}___${file.file.name}`,
        id: file.id,
        moduleName: "drawings",
        batchId,
      }));
      const nonDwgFile = [];
      const { uploadUrls } = await getPresignUrls(fileMeta).unwrap();

      // Upload files asynchronously and wait for all to settle
      const uploadPromises = newFiles.map(async (file, idx) => {
        try {
          const uploadUrl = uploadUrls[idx];
          const uploadStatus = await fetch(uploadUrl, {
            method: "PUT",
            signal: AbortSignal.timeout(30000),
            headers: {
              "Content-Type": "multipart/form-data",
            },
            body: file.file,
          });

          if (uploadStatus.status !== 200) {
            setErrorFiles((prev) => [...prev, file.id]);
          } else {
            if (!file.file.name.endsWith(".dwg")) {
              nonDwgFile.push({
                pdfFile: uploadUrl.split("?")[0],
                drawingFile: null,
                idProject,
              });
            }
            setUploadedFiles((prev) => [...prev, file.id]);
          }
        } catch (e) {
          setErrorFiles((prev) => [...prev, file.id]);
        }
      });

      // Wait for all uploads to complete (either success or failure)
      await Promise.allSettled(uploadPromises);

      if (nonDwgFile.length > 0) await addDrawingV2(nonDwgFile);
    },
    [getPresignUrls, addDrawingV2, idProject, idEnterprise],
  );

  const uploadDocument = useCallback(
    async (file) => {
      try {
        const fnCallBack = async (presignedUrl) => {
          const requestBody = {
            idProject,
            url: presignedUrl.split("?")[0],
            name: file.file.name,
            size: (file.file.size / 1000000).toFixed(2),
            versionNo: 1,
            allowedUsers: file.allowedUsers ?? [],
            allowedCompanies: file.allowedCompanies ?? [],
            bActive: true,
            bPrivate: file.bPrivate,
            idProjectFolder: file.idProjectFolder,
          };

          const res = await createDocument(requestBody);
          if (res?.error) {
            setErrorFiles((prev) => [...prev, file.id]);
          } else {
            setUploadedFiles((prev) => [...prev, file.id]);
          }
        };

        if (file.file.size / 1000000 > 100) {
          const partSize = 100 * 1024 * 1024;
          const numParts = Math.ceil(file.file.size / partSize);

          let uploadId;
          let key;
          try {
            const responseS3 = await initiateMultipartUpload({
              idEnterprise,
              idProject,
              moduleName: "Documents",
              name: file.file.name,
            }).unwrap();

            key = responseS3.key;
            uploadId = responseS3.uploadId;

            const parts = [];

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < numParts; i++) {
              const start = i * partSize;
              const end = Math.min(file.file.size, start + partSize);
              const partData = file.file.slice(start, end);
              const partNumber = i + 1;

              // eslint-disable-next-line no-await-in-loop
              const formData = new FormData();
              formData.append("key", key);
              formData.append("uploadId", uploadId);
              formData.append("partNumber", partNumber);
              formData.append("partData", partData);

              // eslint-disable-next-line no-await-in-loop
              const part = await continueMultipartUpload(formData).unwrap();
              parts.push(part);
            }

            const presignedUrl = await completeMultipartUpload({
              key,
              uploadId,
              parts,
            }).unwrap();

            fnCallBack(presignedUrl);
          } catch (error) {
            if (uploadId) {
              await abortMultipartUpload({ key, uploadId });
              throw new Error(error);
            }
          }
        } else {
          const presignedUrl = await createPresignedUrl({
            idEnterprise,
            idProject,
            moduleName: "Documents",
            name: file.file.name,
          }).unwrap();

          await fetch(presignedUrl, {
            method: "PUT",
            signal: AbortSignal.timeout(30000),
            headers: {
              "Content-Type": "multipart/form-data",
            },
            body: file.file,
          });

          fnCallBack(presignedUrl);
        }
      } catch (e) {
        setErrorFiles((prev) => [...prev, file.id]);
      }
    },
    [
      createDocument,
      createPresignedUrl,
      idEnterprise,
      idProject,
      abortMultipartUpload,
      completeMultipartUpload,
      initiateMultipartUpload,
      continueMultipartUpload,
    ],
  );

  const uploadImage = useCallback(
    async (file) => {
      try {
        const fnCallBack = async (presignedUrl) => {
          const isVideo = file.file.type.split("/")[0] === "video";
          let thumbnailUrl;
          if (isVideo) {
            const thumbnail = await getVideoThumbnail(file.file);
            const formData = new FormData();
            formData.append("files", thumbnail);
            const thumbnailRes = await uploadThumbnail(formData);
            if (thumbnailRes.error) {
              throw new Error(thumbnailRes.error);
            } else {
              thumbnailUrl = thumbnailRes.data.url;
            }
          }
          const requestBody = {
            idProject: file.idProject,
            location: file.selectedLocation,
            album: file.selectedAlbum,
            name: file.file.name,
            url: presignedUrl.split("?")[0],
            thumbnailUrl,
            size: file.file.size.toString(),
            type: isVideo ? "video" : "image",
          };

          const res = await createImage(requestBody);
          if (res?.error) {
            setErrorFiles((prev) => [...prev, file.id]);
          } else {
            setUploadedFiles((prev) => [...prev, file.id]);
          }
        };

        if (file.file.size / 1000000 > 100) {
          const partSize = 100 * 1024 * 1024;
          const numParts = Math.ceil(file.file.size / partSize);

          let uploadId;
          let key;
          try {
            const responseS3 = await initiateMultipartUpload({
              idEnterprise,
              idProject,
              moduleName: "Image",
              name: file.file.name,
            }).unwrap();

            key = responseS3.key;
            uploadId = responseS3.uploadId;

            const parts = [];

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < numParts; i++) {
              const start = i * partSize;
              const end = Math.min(file.file.size, start + partSize);
              const partData = file.file.slice(start, end);
              const partNumber = i + 1;

              // eslint-disable-next-line no-await-in-loop
              const formData = new FormData();
              formData.append("key", key);
              formData.append("uploadId", uploadId);
              formData.append("partNumber", partNumber);
              formData.append("partData", partData);

              // eslint-disable-next-line no-await-in-loop
              const part = await continueMultipartUpload(formData).unwrap();
              parts.push(part);
            }

            const presignedUrl = await completeMultipartUpload({
              key,
              uploadId,
              parts,
            }).unwrap();

            fnCallBack(presignedUrl);
          } catch (error) {
            if (uploadId) {
              await abortMultipartUpload({ key, uploadId });
              throw new Error(error);
            }
          }
        } else {
          const presignedUrl = await createPresignedUrl({
            idEnterprise,
            idProject,
            moduleName: "Image",
            name: file.file.name,
          }).unwrap();

          await fetch(presignedUrl, {
            method: "PUT",
            signal: AbortSignal.timeout(30000),
            headers: {
              "Content-Type": "multipart/form-data",
            },
            body: file.file,
          });

          fnCallBack(presignedUrl);
        }
      } catch (e) {
        setErrorFiles((prev) => [...prev, file.id]);
      }
    },
    [
      createImage,
      uploadThumbnail,
      createPresignedUrl,
      idEnterprise,
      idProject,
      abortMultipartUpload,
      completeMultipartUpload,
      initiateMultipartUpload,
      continueMultipartUpload,
    ],
  );

  const startUpload = useCallback(async () => {
    const newFiles = [...uploadingFiles].filter((f) => !f.processed);
    const drawingFiles = newFiles.filter((f) => f.type === "drawing");
    const documentFiles = newFiles.filter((f) => f.type === "document");
    const imageFiles = newFiles.filter((f) => f.type === "image");
    if (drawingFiles.length > 0) {
      await uploadDrawing(drawingFiles);
    }
    if (documentFiles.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const file of documentFiles) {
        if (!errorFiles.includes(file.id)) {
          // eslint-disable-next-line no-await-in-loop
          await uploadDocument(file);
        }
      }
    }
    if (imageFiles.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const file of imageFiles) {
        if (!errorFiles.includes(file.id)) {
          // eslint-disable-next-line no-await-in-loop
          await uploadImage(file);
        }
      }
    }

    if (errorFiles.length > 0) {
      PopUpAlert("error", tGeneral("Error"), tGeneral("errorUploadMessage"));
    }
  }, [
    errorFiles,
    uploadingFiles,
    tGeneral,
    uploadDocument,
    uploadDrawing,
    uploadImage,
  ]);

  const startDownload = useCallback(async () => {
    const verifyFileName = (name, initialName, iterations, list = []) => {
      if (list.includes(name)) {
        const nameSplit = initialName.split(".");
        const extension = nameSplit.pop();
        const newName = `${nameSplit.join("")} (${iterations}).${extension}`;
        return verifyFileName(newName, initialName, iterations + 1, list);
      }
      return name;
    };

    const newFiles = [...downloadingFiles].filter((f) => !f.processed);
    newFiles.forEach(async (file) => {
      try {
        const zip = new JSZip();

        switch (file.type) {
          case "export":
            await pdf({
              ...file.export,
              onFail: () => setErrorFiles((prev) => [...prev, file.id]),
            });
            break;
          case "album":
            await Promise.all(
              file.files.map(async (item) => {
                const urlWithCacheBuster = `${
                  item.url
                }?cache_buster=${new Date().getTime()}`;
                const res = await fetch(urlWithCacheBuster, {
                  cache: "no-store",
                });
                const dataBlob = await res.blob();
                const fileName = verifyFileName(
                  item.name,
                  item.name,
                  1,
                  Object.keys(zip.files),
                );
                zip.file(fileName, dataBlob);
              }),
            );

            zip.generateAsync({ type: "blob" }).then((content) => {
              const downloadLink = document.createElement("a");
              downloadLink.download = `${file.albumName}.zip`;
              downloadLink.href = URL.createObjectURL(content);
              downloadLink.click();
            });
            break;
          case "drawings":
            if (
              file.keys.length === 1 &&
              file.files[file.keys[0]].length === 1
            ) {
              const xhrLink = document.createElement("a");

              const xhr = new XMLHttpRequest();
              xhr.open("GET", file.files[file.keys][0], true);
              xhr.responseType = "blob";
              xhr.onload = () => {
                if (xhr.status === 200) {
                  const blob = xhr.response;
                  const objectUrl = URL.createObjectURL(blob);

                  xhrLink.href = objectUrl;
                  xhrLink.setAttribute(
                    "download",
                    `${file.drawingName} - ${file.keys[0]}`,
                  );
                  document.body.appendChild(xhrLink);
                  xhrLink.click();
                  xhrLink.parentNode.removeChild(xhrLink);

                  URL.revokeObjectURL(objectUrl);
                }
              };
              xhr.send();
            } else {
              await Promise.all(
                file.keys.map(async (folder) => {
                  await Promise.all(
                    file.files[folder].map(async (url) => {
                      const urlWithCacheBuster = `${url}?cache_buster=${new Date().getTime()}`;
                      const res = await fetch(urlWithCacheBuster, {
                        cache: "no-store",
                      });
                      const data = await res.blob();
                      const extensionFile = url.split(".").pop();
                      zip.file(
                        `${folder}/${file.drawingName} - ${folder}.${extensionFile}`,
                        data,
                      );
                    }),
                  );
                }),
              );

              zip.generateAsync({ type: "blob" }).then((content) => {
                const downloadLink = document.createElement("a");
                downloadLink.download = `${file.drawingName}.zip`;
                downloadLink.href = URL.createObjectURL(content);
                downloadLink.click();
              });
            }

            break;
          case "multiple":
            await Promise.all(
              file.files.map(async (item) => {
                const urlWithCacheBuster = `${
                  item.url
                }?cache_buster=${new Date().getTime()}`;
                const res = await fetch(urlWithCacheBuster, {
                  cache: "no-store",
                });
                const dataBlob = await res.blob();
                const fileName = verifyFileName(
                  item.name,
                  item.name,
                  1,
                  Object.keys(zip.files),
                );
                zip.file(`${fileName}.${item.extension}`, dataBlob);
              }),
            );

            zip.generateAsync({ type: "blob" }).then((content) => {
              const downloadLink = document.createElement("a");
              downloadLink.download = `${file.module}.zip`;
              downloadLink.href = URL.createObjectURL(content);
              downloadLink.click();
            });
            break;
          case "documents":
            async function createZip(documents, folders, zipObject) {
              await Promise.all(
                documents.map(async (doc) => {
                  const urlWithCacheBuster = `${
                    doc.url
                  }?cache_buster=${new Date().getTime()}`;
                  const res = await fetch(urlWithCacheBuster, {
                    cache: "no-store",
                  });
                  const data = await res.blob();
                  const fileName =
                    doc.name ?? doc.url.substring(doc.url.lastIndexOf("/") + 1);
                  zipObject.file(fileName, data);
                }),
              );
              await Promise.all(
                folders.map(async (folder) => {
                  const zipFolder = zip.folder(folder.name);
                  await createZip(folder.documents, folder.folders, zipFolder);
                }),
              );
            }
            await createZip(file?.files, file?.folders, zip);

            zip.generateAsync({ type: "blob" }).then((content) => {
              const downloadLink = document.createElement("a");
              downloadLink.download = `${file.folderName}.zip`;
              downloadLink.href = URL.createObjectURL(content);
              downloadLink.click();
            });
            break;
          default:
            const link = document.createElement("a");

            const urlWithCacheBuster = `${
              file.files[0].url
            }?cache_buster=${new Date().getTime()}`;
            const res = await fetch(urlWithCacheBuster, {
              cache: "no-store",
            });
            const blob = await res.blob();
            const objectUrl = URL.createObjectURL(blob);

            link.href = objectUrl;
            link.setAttribute("download", `${file.files[0].name}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            URL.revokeObjectURL(objectUrl);
            break;
        }

        setDownloadedFiles((prev) => [...prev, file.id]);
      } catch (e) {
        setErrorFiles((prev) => [...prev, file.id]);
      }
    });
  }, [downloadingFiles]);

  const allUploaded = useMemo(
    () =>
      uploadingFiles.every(
        (file) =>
          uploadedFiles.indexOf(file.id) > -1 ||
          errorFiles.indexOf(file.id) > -1,
      ),
    [uploadingFiles, uploadedFiles, errorFiles],
  );

  const allDownloaded = useMemo(
    () =>
      downloadingFiles.every(
        (file) =>
          downloadedFiles.indexOf(file.id) > -1 ||
          errorFiles.indexOf(file.id) > -1,
      ),
    [downloadingFiles, downloadedFiles, errorFiles],
  );

  useEffect(() => {
    if (uploadingFiles.length && !allUploaded) {
      startUpload();
      setTab("uploads");
    }
  }, [allUploaded, uploadingFiles, startUpload]);

  useEffect(() => {
    if (downloadingFiles.length && !allDownloaded) {
      startDownload();
      setTab("downloads");
    }
  }, [allDownloaded, downloadingFiles, startDownload]);

  const handleClose = (e) => {
    e.stopPropagation();
    setUploadingFiles([]);
    setDownloadingFiles([]);
    setErrorFiles([]);
  };

  const getStatusText = (id) => {
    if (downloadedFiles.indexOf(id) > -1) {
      return tGeneral("downloaded");
    }
    if (uploadedFiles.indexOf(id) > -1) {
      return tGeneral("uploaded");
    }
    if (errorFiles.indexOf(id) > -1) {
      return tGeneral("tryAgain");
    }
    return tGeneral("loading");
  };

  const getStatusIcon = (id) => {
    if (uploadedFiles.indexOf(id) > -1 || downloadedFiles.indexOf(id) > -1) {
      return <img src={CheckIcon} alt="icon" style={{ height: "18px" }} />;
    }
    if (errorFiles.indexOf(id) > -1) {
      return (
        <Tooltip title={tGeneral("tryAgain")}>
          <IconButton
            aria-label="retry"
            size="small"
            sx={{ height: "20px", width: "20px" }}
            onClick={() => {
              setErrorFiles((prev) => prev.filter((x) => x !== id));
            }}
          >
            <img src={RetryIcon} alt="icon" style={{ height: "18px" }} />;
          </IconButton>
        </Tooltip>
      );
    }

    const spinKeyframes = `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `;
    return (
      <>
        <style>{spinKeyframes}</style>{" "}
        {/* Inject keyframes into the document */}
        <img
          src={LoadingIcon}
          alt="icon"
          style={{ height: "18px", animation: "spin 1s linear infinite" }}
        />
      </>
    );
  };

  const renderFileType = (file) => {
    const fileType = file.name
      .substring(file.name.lastIndexOf(".") + 1)
      .toLowerCase();

    let sourceImage = null;
    switch (fileType) {
      case "doc":
      case "docx":
        sourceImage = DocIcon;
        break;
      case "xls":
      case "csv":
        sourceImage = XlsIcon;
        break;
      case "dwg":
      case "cad":
        sourceImage = DwgIcon;
        break;
      case "ppt":
      case "pptx":
        sourceImage = PptIcon;
        break;
      case "zip":
      case "rar":
        sourceImage = ZipIcon;
        break;
      case "mp4":
      case "mov":
      case "avi":
        sourceImage = VideoIcon;
        break;
      case "aac":
      case "wav":
        sourceImage = AudioIcon;
        break;
      case "jpeg":
      case "jpg":
      case "png":
      case "svg":
        sourceImage = ImageIcon;
        break;
      case "pdf":
        sourceImage = PdfIcon;
        break;
      default:
        sourceImage = FileIcon;
    }

    return <img src={sourceImage} alt="icon" style={{ height: "18px" }} />;
  };

  const renderFiles = (type, files) => {
    let formattedFiles = [];
    if (type === "download") {
      const getFileName = (file) => {
        // eslint-disable-next-line default-case
        switch (file.type) {
          case "single":
            return file.files[0].name;
          case "album":
            return `${file.albumName}.zip`;
          case "export":
          case "multiple":
            return `${file.module}.zip`;
          case "drawings":
            if (
              file.keys.length === 1 &&
              file.files[file.keys[0]].length === 1
            ) {
              return `${file.drawingName} - ${file.keys[0]}`;
            }

            return `${file.drawingName}.zip`;
          case "documents":
            return `${file.folderName}.zip`;
        }
      };

      formattedFiles = files.map((file) => ({
        id: file.id,
        file: {
          name: getFileName(file),
        },
      }));
    } else {
      formattedFiles = files;
    }

    return (
      <Box
        display="flex"
        flexDirection="column"
        minHeight="50px"
        maxHeight="400px"
        sx={{ overflowY: "auto", overflowX: "hidden" }}
        rowGap="0.5rem"
      >
        {formattedFiles.map((file) => (
          <Box
            display="flex"
            alignItems="center"
            height="1.5rem"
            columnGap="0.5rem"
            p="0.25rem 1rem"
          >
            {renderFileType(file.file)}
            <Tooltip title={file.file.name}>
              <Typography
                noWrap
                sx={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {file.file.name}
              </Typography>
            </Tooltip>

            <Typography
              noWrap
              sx={{
                flex: 1,
                textAlign: "end",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "#8E8E8E",
                textDecoration: "underline",
              }}
            >
              {getStatusText(file.id)}
            </Typography>

            {getStatusIcon(file.id)}
          </Box>
        ))}
      </Box>
    );
  };

  if (uploadingFiles.length === 0 && downloadingFiles.length === 0) return null;

  return (
    <Accordion
      sx={{
        zIndex: 500,
        position: "absolute",
        bottom: 0,
        right: 0,
        width: "600px",
        borderTopLeftRadius: "1rem",
        borderTopRightRadius: "1rem",
      }}
      defaultExpanded
    >
      <AccordionSummary
        sx={{
          backgroundColor: "#F5F5F5",
          borderTopLeftRadius: "1rem",
          borderTopRightRadius: "1rem",
        }}
        expandIcon={<ExpandMore />}
      >
        <Box
          sx={{
            width: "100%",
            margin: "-10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Tabs
            value={tab}
            onChange={(_, newValue) => setTab(newValue)}
            onClick={(event) => {
              event.stopPropagation(); // Prevent accordion toggle on click
            }}
            onFocus={(event) => {
              event.stopPropagation(); // Prevent accordion toggle on focus
            }}
            sx={{
              display: "flex",
              mb: "10px",
            }}
          >
            <Tab
              sx={{
                flex: 1,
                fontSize: "1.1428rem",
                color: "#8E8E8E",
                "&.Mui-selected": {
                  color: "black",
                  fontWeight: "bold",
                },
                textTransform: "none",
                height: "50px",
              }}
              label={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "0.5rem",
                    flexDirection: "row",
                  }}
                >
                  <img src={UploadIcon} alt="icon" style={{ height: "18px" }} />
                  {tGeneral("uploads")}
                </Box>
              }
              value="uploads"
            />
            <Tab
              sx={{
                flex: 1,
                fontSize: "1.1428rem",
                color: "#8E8E8E",
                "&.Mui-selected": {
                  color: "black",
                  fontWeight: "bold",
                },
                textTransform: "none",
                height: "50px",
              }}
              label={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "0.5rem",
                    flexDirection: "row",
                  }}
                >
                  <img
                    src={DownloadIcon}
                    alt="icon"
                    style={{ height: "18px" }}
                  />
                  {tGeneral("downloads")}
                </Box>
              }
              value="downloads"
            />
          </Tabs>
          <Box flex={1} />
          <TooltipIconButton
            icon={<Close sx={{ color: "#8E8E8E", fontSize: "1.25rem" }} />}
            label={tGeneral("close")}
            onClick={handleClose}
            disabled={!allUploaded}
          />
        </Box>
      </AccordionSummary>
      <Box
        sx={{
          padding: ".5rem 1rem",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#E1E1E1",
        }}
      >
        {tab === "uploads" && (
          <Typography>{`${tGeneral("uploading")}...`}</Typography>
        )}
        {tab === "downloads" && (
          <Typography>{`${tGeneral("downloading")}...`}</Typography>
        )}
        <Box flex={1} />
        {tab === "uploads" && (
          <Typography>{`${uploadedFiles.length}/${uploadingFiles.length}`}</Typography>
        )}
        {tab === "downloads" && (
          <Typography>{`${downloadedFiles.length}/${downloadingFiles.length}`}</Typography>
        )}
      </Box>
      <AccordionDetails
        sx={{
          padding: "8px 12px 12px",
        }}
      >
        {tab === "uploads" && renderFiles("upload", uploadingFiles)}
        {tab === "downloads" && renderFiles("download", downloadingFiles)}
      </AccordionDetails>
    </Accordion>
  );
}

export { UploadDrawingsModal };
