import { TooltipBody } from "./TooltipBody";
import { TooltipTitle } from "./TooltipTitle";
import { TooltipContent } from "./TooltipContent";
import { TooltipFooter } from "./TooltipFooter";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const Tooltip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  isLastStep,
}) => {
  const { t: translation } = useTranslation("general");

  const styles = {
    cancelButton: {
      color: "black",
      backgroundColor: "white",
      borderColor: "black",
      border: 1,
      //float: "right",
      ":hover": {
        color: "white",
        backgroundColor: "#b4b4b4",
      },
    },
    saveButton: {
      color: "black",
      backgroundColor: "#ffc900",
      //float: "left",
      ":hover": {
        color: "black",
        backgroundColor: "#d9ab00",
      },
    },
  };

  return (
    <TooltipBody {...tooltipProps}>
      {step.title && <TooltipTitle>{step.title}</TooltipTitle>}
      <TooltipContent>{step.content}</TooltipContent>
      <TooltipFooter>
        <>
          {index > 0 && (
            <Box sx={{ paddingRight: "5px" }}>
              <Button sx={styles.cancelButton} id="back" {...backProps}>
                {index === 0 ? translation("close") : translation("back")}
              </Button>
            </Box>
          )}
          {continuous && (
            <Box>
              <Button sx={styles.saveButton} id="next" {...primaryProps}>
                {isLastStep ? translation("close") : translation("next")}
              </Button>
            </Box>
          )}
          {!continuous && (
            <Box>
              <Button sx={styles.saveButton} id="close" {...closeProps}>
                {translation("close")}
              </Button>
            </Box>
          )}
        </>
      </TooltipFooter>
    </TooltipBody>
  );
};

export { Tooltip };
