export const submittals = {
  submittal: "서면 제출",
  submittalTitleModule: "서면 제출",
  deleteSubmittalsTitle: "서면 제출 삭제",
  deleteSubmittalsContent: "이 서면 제출을 삭제하시겠습니까?",
  addSubmittal: "서면 제출 추가",
  noSubmittalsTitle: "현재까지 서면 제출이 생성되지 않았습니다",
  addSubmittalsValidation:
    "하나를 추가하려면 서면 제출 추가 버튼을 클릭하여 프로세스를 시작하십시오",
  submittalRecycleBin: "서면 제출 재활용통",
  costCode: "비용 코드",
};
