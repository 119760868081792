import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";

/*
 * This component is used to render inline images supports both SVG and non SVG images
 * The component can flex between both image types and renders them using either a <div> or an <img> tag
 */
function ImgInline(props) {
  const [isSvg, setIsSvg] = React.useState(false);

  const [url, setUrl] = useState("");
  const [svg, setSvg] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErrored, setIsErrored] = useState(false);

  useEffect(() => {
    const src = props.src ?? "";

    const fileExtension = src.substring(src.lastIndexOf(".") + 1);
    if (fileExtension.toLowerCase().includes("svg")) {
      setIsSvg(true);
      setUrl(src);
    }
  }, [props.src]);

  useEffect(() => {
    setIsLoaded(false);
    setIsErrored(false);
    fetch(url)
      .then((res) => res.text())
      .then(setSvg)
      .catch(setIsErrored)
      .then(() => setIsLoaded(true));
  }, [url]);

  return (
    <div>
      {isSvg && !isLoaded && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            ...props.svgStyle,
          }}
        >
          <CircularProgress
            style={{ width: "40px", height: "40px" }}
            color="secondary"
          />
        </div>
      )}

      {(isErrored || !isSvg) && (
        <img
          src={props.src}
          alt={props.alt}
          style={props.imgStyle}
        />
      )}
      {isSvg && svg && (
        <div
          style={props.svgStyle}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: svg }}
        />
      )}
    </div>
  );
}

export { ImgInline };
