import { Box } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonTabs } from "../../../../../components/shared";
import { ManpowerSummary } from "./ManpowerSummary";
import { SubcontractorDetails } from "./SubcontractorDetails";

function ManpowerDetails({ report }) {
  const { t: tGeneral } = useTranslation("general");

  const [activeTab, setActiveTab] = useState("all");
  return (
    <Box overflow="hidden" pt={2}>
      <ButtonTabs
        activeTab={activeTab}
        tabs={[
          {
            label: tGeneral("all"),
            value: "all",
          },
          ...report.manpower.map((m) => ({
            value: m.subcontractor.id,
            label: m.subcontractor.name,
          })),
        ]}
        setActiveTab={(newTab) => setActiveTab(newTab)}
      />
      <Box
        display="flex"
        overflow="hidden"
        sx={{ bgcolor: "white", mt: 2, p: 1, borderRadius: "16px" }}
      >
        {activeTab === "all" && <ManpowerSummary report={report} />}
        {activeTab === "all" ? (
          <Box maxHeight="40vh" sx={{ overflowY: "auto" }} flex={3}>
            {report.manpower.map((m) => (
              <SubcontractorDetails manpower={m} showComments={false} />
            ))}
          </Box>
        ) : (
          <SubcontractorDetails
            manpower={
              report.manpower[
                report.manpower.findIndex(
                  (m) => m.subcontractor.id === activeTab,
                )
              ]
            }
          />
        )}
      </Box>
    </Box>
  );
}

export { ManpowerDetails };
