// @ts-check
/* eslint react/prop-types: 0 */

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button } from "@mui/material";
// eslint-disable-next-line no-unused-vars
import React from "react";

export default function Close(props) {
  const { handleClick } = props;

  return (
    <Button
      color="secondary"
      onClick={() => {
        handleClick();
      }}
      sx={{
        minWidth: "0",
      }}
      variant="text"
    >
      <HighlightOffIcon />
    </Button>
  );
}
