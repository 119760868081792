import { Check, Close, Delete, Edit } from "@mui/icons-material";
import { Box, Button, Divider, TextField } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowModes,
  GridRowEditStopReasons,
  useGridApiRef,
} from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  useAddDisciplineMutation,
  useDeleteDisciplineMutation,
  useEditDisciplineMutation,
  useGetDisciplinesQuery,
} from "../../../features/drawings";
import { PopUpAlert } from "../../PopUpAlert";

function Disciplines() {
  const apiRef = useGridApiRef();
  const { idProject } = useParams();
  const { t: tDrawings } = useTranslation("drawings");
  const { t: tGeneral } = useTranslation("general");
  const [newDiscipline, setNewDiscipline] = useState("");
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [rowModesModel, setRowModesModel] = useState({});

  const { data } = useGetDisciplinesQuery(idProject);
  const [addDiscipline, { isLoading: isAddLoading }] =
    useAddDisciplineMutation();
  const [
    editDiscipline,
    {
      isLoading: isEditLoading,
      isSuccess: isEditSuccess,
      isError: isEditError,
      error: editError,
    },
  ] = useEditDisciplineMutation();
  const [
    deleteDiscipline,
    {
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      error: deleteError,
    },
  ] = useDeleteDisciplineMutation();

  const handleAddDiscipline = async () => {
    await addDiscipline({ idProject, name: newDiscipline });
    setNewDiscipline("");
  };

  const handleDelete = (id) => {
    deleteDiscipline({ ids: [id] });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = async (newRow, oldRow) => {
    const updatedRow = { ...newRow };
    if (newRow.name.length > 0) {
      await editDiscipline({
        id: newRow.id,
        name: newRow.name,
      });
      return updatedRow;
    }

    return oldRow;
  };

  useEffect(() => {
    if (isEditSuccess) {
      PopUpAlert("success", tGeneral("updatedSuccessfully"));
    }
    if (isEditError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("generalErrorMessage"));
    }

    return () => {};
  }, [isEditSuccess, isEditError, editError]);

  const columns = [
    {
      headerName: tDrawings("discipline"),
      field: "name",
      flex: 1,
      editable: true,
    },
    {
      field: "actions",
      headerName: tGeneral("actions"),
      type: "actions",
      cellClassName: "actions",
      getActions: (params) => {
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Check color="secondary" />}
              onClick={handleSaveClick(params.id)}
              label="Save"
              disabled={isEditLoading || isDeleteLoading}
            />,
            <GridActionsCellItem
              icon={<Close color="red" />}
              onClick={handleCancelClick(params.id)}
              label="Cancel"
              disabled={isEditLoading || isDeleteLoading}
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<Edit color="secondary" />}
            onClick={handleEditClick(params.id)}
            label="Edit"
            disabled={isEditLoading || isDeleteLoading}
          />,
          <GridActionsCellItem
            icon={<Delete color="error" />}
            label="Delete"
            onClick={() => handleDelete(params.id)}
            disabled={isEditLoading || isDeleteLoading}
          />,
        ];
      },
    },
  ];

  const disciplines = useMemo(() => {
    if (data) {
      return data;
    }
    return [];
  }, [data]);

  return (
    <Box flex={1} display="flex" flexDirection="column">
      <span data-tour="settings-drawings-5">
        <TextField
          value={newDiscipline}
          onChange={(e) => setNewDiscipline(e.target.value)}
          placeholder={tDrawings("discipline")}
          fullWidth
        />
        <Button
          onClick={handleAddDiscipline}
          variant="contained"
          fullWidth
          sx={{ height: "55px", mt: "15px" }}
          disabled={isAddLoading}
        >
          {tGeneral("add")}
        </Button>
      </span>
      <Divider sx={{ my: "10px" }} />
      <DataGrid
        apiRef={apiRef}
        columns={columns}
        rows={disciplines}
        paginationModel={paginationModel}
        disableColumnMenu
        onCellDoubleClick={(params, event) => {
          event.defaultMuiPrevented = true;
        }}
        disableRowSelectionOnClick
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        sx={{
          "&.MuiDataGrid-root": {
            height: "70%",
            maxHeight: "70%",
            minHeight: "unset",
          },
        }}
        localeText={{
          noRowsLabel: tGeneral("noData"),
          noResultsOverlayLabel: tGeneral("noResults"),
          columnHeaderSortIconLabel: tGeneral("order"),
        }}
        slotProps={{
          pagination: {
            labelDisplayedRows: ({ from, to, count }) => {
              return from + "-" + to + ` ${tGeneral("of")} ` + count;
            },
          },
        }}
      />
    </Box>
  );
}

export { Disciplines };
