import { tour } from "./tour";

export const photos = {
  // general tour
  ...tour,
  moduleElement: "Fotos",
  editButtonTour: "Agregar Álbum",
  // ADD - guide me steps
  addPhoto: "Agregar foto",
  timeline: "Galería",
  albums: "Álbumes",
  album: "Álbum",
  photos: "Fotos",
  addAlbum: "Agregar álbum",
  photosPerPage: "Fotos por página",
  deleteImagesTitle: "Eliminar imágenes",
  deleteImagesContent: "¿Estás seguro que deseas borrar estas imágenes?",
  deleteAlbumsContent: "¿Estás seguro que deseas borrar estos álbumes?",
  deleteImagetTitle: "Eliminar imagen",
  deleteImageContent: "¿Estás seguro que deseas borrar esta imagen?",
  setLocation: "Establecer ubicación",
  setAlbumCover: "Establecer como portada de álbum",
  setAlbumCoverConfirm:
    "¿Está seguro que desea establecer esta imagen como portada del álbum?",
  movePhotoLocationTitle: "Cambiar ubicación",
  movePhotoAlbumTitle: "Cambiar álbum",
  movePhotoLocationSubtitle: "Mover foto a ubicación",
  movePhotoAlbumSubtitle: "Mover foto al álbum",
  movePhotoLocationSuccess: "Ubicación de imagen editado con éxito",
  movePhotoAlbumSuccess: "Álbum de imagen editado con éxito",
  selectedPhotos: "Fotos seleccionadas",
  perPage: "Por página",
  noImagesCreated: "No hay imágenes creadas en este momento",
  noAlbumsCreated: "No hay álbumes creados en este momento",
  addImagesValidation:
    "Si deseas agregar una, te invitamos a dar click en el botón Agregar Foto para iniciar el proceso",
  addAlbumsValidation:
    "Si deseas agregar uno, te invitamos a dar click en el botón Agregar Álbum para iniciar el proceso",
  projectConfigurationUpdate:
    "Configuración del proyecto actualizada con éxito",
  newPhotos: "Nuevas fotos",
  images: "imágenes",
  youHaveUploaded: "Haz subido",
  restoreAlbumsQuestion: "¿Estás seguro que deseas restaurar este álbum?",
  restoreImagesQuestion: "¿Estás seguro que deseas restaurar estas imágenes?",
  deletePermanentlyQuestion:
    "¿Estás seguro que deseas eliminar esto permanentemente?",
  albumName: "Nombre del álbum",
  imagesDeletedSuccessfully: "Imágenes eliminadas con éxito",
  imageCoverSetSuccessfully: "Portada de álbum establecida con éxito",
  albumDeletedSuccessfully: "Álbum eliminado con éxito",
  imagesRestoredSuccessfully: "Imágenes restauradas con éxito",
  // ADD Photo - guide me steps
  step1_addPhotos_title: "1. Añadir foto",
  step1_addPhotos_text:
    "En la vista de línea de tiempo, haz clic en el botón de añadir para agregar uno o varios archivos multimedia.",
  step2_addPhotos_title: "2. Ubicación",
  step2_addPhotos_text: "Selecciona una ubicación que ha sido preestablecida",
  step3_addPhotos_title: "3. Álbum",
  step3_addPhotos_text: "Selecciona un álbum para los archivos.",
  step4_addPhotos_title: "4. Agregar archivos",
  step4_addPhotos_text:
    "Adjunta cualquier tipo de foto o video, o arrástralos y suéltalos dentro del cuadro punteado.",
  step5_addPhotos_title: "5. Confirmar",
  step5_addPhotos_text:
    "Haz clic aquí para confirmar la información de los archivos multimedia subidos y publicarlos.",
  stepFinal_addPhotos: "Haz aprendido cómo añadir una foto",
  // ADD Album - guide me steps
  step1_addAlbums_title: "1. Añadir álbum",
  step1_addAlbums_text:
    "En la vista de álbumes, haz clic en el botón de añadir para agregar un álbum.",
  step2_addAlbums_title: "2. Nombre del álbum",
  step2_addAlbums_text: "Escribe un nombre para el álbum.",
  step3_addAlbums_title: "3. Añadir",
  step3_addAlbums_text:
    "Haz clic aquí para agregar el álbum al panel de álbumes.",
  stepFinal_addAlbums: "Haz aprendido cómo añadir un álbum",
  // VIEW - guide me steps
  step1_viewPhotos_title: "1. Ver archivo multimedia",
  step1_viewPhotos_text: "Haz clic en una foto o video para visualizarlo.",
  step2_viewPhotos_title: "2. Visualizar archivo multimedia",
  step2_viewPhotos_text:
    "Una vez que has hecho clic en un archivo, este aparecerá en pantalla completa.",
  step3_viewPhotos_title: "3. Información",
  step3_viewPhotos_text:
    "En la parte superior derecha de la vista previa del archivo multimedia, se mostrará la fecha en que se tomó, la fecha y quién lo subió, y la ubicación.",
  step4_viewPhotos_title: "4. Comentarios",
  step4_viewPhotos_text:
    "Comenta sobre el archivo multimedia para que otras personas puedan ver tus observaciones.",
  step5_viewPhotos_title: "5. Descargar archivo multimedia",
  step5_viewPhotos_text:
    "Click aquí para descargar el archivo multimedia que has seleccionado en tu dispositivo.",
  step6_viewPhotos_title: "6. Eliminar imagen/video",
  step6_viewPhotos_text:
    "Si eres un superadministrador o administrador, puedes eliminar los archivos de medios subidos.",
  step7_viewPhotos_title: "7. Más opciones",
  step7_viewPhotos_text:
    "Pasa el cursor sobre los tres puntos y se desplegará un menú emergente con las siguientes opciones:",
  step7_viewPhotos_text1_1: "Cambiar de álbum",
  step7_viewPhotos_text1_2:
    "Mueve el archivo multimedia seleccionado a un álbum diferente.",
  step7_viewPhotos_text2_1: "Cambiar ubicación",
  step7_viewPhotos_text2_2:
    "Cambia la ubicación a la que se hace referencia el archivo de medios.",
  step7_viewPhotos_text3_1: "Establecer como portada del álbum",
  step7_viewPhotos_text3_2:
    "Establece la foto seleccionada como portada del álbum al que pertenece (esto solo funciona con fotos).",
  stepFinal_viewPhotos: "Haz aprendido a visualizar un archivo multimedia",
  // RECYCLE - guide me steps
  step1_recyclePhotos_title: "2. Papelera",
  step1_recyclePhotos_text:
    "Haz clic aquí para abrir el menú de la papelera de reciclaje. Esto te llevará al menu de fotos / álbumes dependiendo de tu vista actual.",
  step2_recyclePhotos_title: "3. Seleccionar archivos / álbumes a restaurar",
  step2_recyclePhotos_text:
    "Selecciona las casillas de verificación de los archivos / álbumes que deseas restaurar o eliminar de forma permanente. También puedes seleccionar los archivos de todo un día, semana o mes si es necesario.",
  step3_recyclePhotos_title: "4. Restaurar",
  step3_recyclePhotos_text:
    "Haz clic aquí para restaurar los archivos seleccionados.",
  step4_recyclePhotos_title: "5. Borrar",
  step4_recyclePhotos_text:
    "Haz clic aquí para eliminar permanentemente los archivos seleccionados.",
  stepFinal_recyclePhotos:
    "Haz aprendido cómo interactuar con la papelera de fotos",
  // EXPORT - guide me steps
  step1_exportPhotos_title: "1. Seleccionar archivos multimedia",
  step1_exportPhotos_text:
    "Selecciona la casilla de verificación de todos los archivos multimedia que deseas exportar. También puedes seleccionar los archivos de un día completo, una semana o un mes si es necesario.",
  step2_exportPhotos_title: "2. Exportar PDF",
  step2_exportPhotos_text:
    "Haz clic aquí y aparecerá un menú emergente con las opciones de exportar archivos multimedia en fomato PDF (1, 2 o 4 por página).",
  stepFinal_exportPhotos: "Haz aprendido a exportar un archivo multimedia",
  // SETTINGS - guide me steps
  step1_settingsPhotos_title: "2. Configuración",
  step1_settingsPhotos_text:
    "Haz clic aquí para abrir el menú emergente de configuración.",
  step2_settingsPhotos_title: "3. Lista de distribución",
  step2_settingsPhotos_text:
    "Selecciona las personas que tendrán permiso para ver todos los archivos multimedia y álbumes.",
  step3_settingsPhotos_title: "4. Guardar",
  step3_settingsPhotos_text: "Guarda la configuración.",
  stepFinal_settingsPhotos:
    "Haz aprendido cómo interactuar con la configuración de módulo",
  // other
  obligatory: "Obligatorio",
  public: "Público",
  private: "Privado",
  congratulations: "¡Felicidades!",
  rfi_title_module: "RFIs (Dudas)",
  no_rfis_title: "No se han creado RFIs (Dudas) hasta el momento",
  no_rfis_text:
    "Si deseas agregar uno, te invitamos a dar click en el botón de añadir para comenzar con el proceso",
  meeting: "Reunión",
  workflow: "Flujo",
  guideMe: "Guíame",
  support: "Soporte",
  days: "Días",
  rfi_recycle_bin: "Papelera (RFIs)",
  addSpec: "Añadir especificación",
  imageUploadedMessage: "Imagenes añadidas con éxito.",
  captureSite: "Lugar de captura",
  uploadedOn: "Subido En",
  dateUploaded: "Fecha de carga",
  dateTaken: "Fecha de toma",
  coordinates: "Coordenadas",
};
