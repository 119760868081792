import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  enterprises: [],
  main: null,
  current: null,
  defaultRole: null,
  adminUsers: null,
};

export const enterpriseSlice = createSlice({
  name: "enterprise",
  initialState,
  reducers: {
    setMainEnterprise: (state, action) => {
      state.main = action.payload;
    },
    setCurrentEnterprise: (state, action) => {
      state.current = action.payload;
    },
    setDefaultRoleEnterprise: (state, action) => {
      state.defaultRole = action.payload;
    },
    setAllEnterprises: (state, action) => {
      state.defaultRole = action.enterprises;
    },
    setAdminUsers: (state, action) => {
      state.adminUsers = action.payload;
    },
    resetEnterprises: () => initialState,
  },
});

export const selectAllEnterprises = (state) => state.enterprise.enterprises;

export const selectMainEnterprise = (state) => state.enterprise.main;

export const selectCurrentEnterprise = (state) => state.enterprise.current;

export const selectRolesEnterprise = (state) => state.enterprise.defaultRole;

export const selectAdminUsers = (state) => state.enterprise.adminUsers;

export const {
  setMainEnterprise,
  setCurrentEnterprise,
  setDefaultRoleEnterprise,
  setAllEnterprises,
  resetEnterprises,
  setAdminUsers,
} = enterpriseSlice.actions;

export default enterpriseSlice.reducer;
