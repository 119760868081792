import * as React from "react";
import { PlayCircle } from "@mui/icons-material";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";

function VideoSection() {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const imgRef = React.useRef(null);
  const [height, setHeight] = React.useState(isMobile ? 490 : 727.5);
  React.useEffect(() => {
    if (imgRef.current) {
      setHeight(imgRef.current.height > 0 ? imgRef.current.height : height);
    }
  }, [imgRef]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundImage: `url(${require("./../../assets/construction.png")})`,
        backgroundSize: isMobile ? "cover" : "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        minHeight: height,
        justifyContent: "center",
        paddingY: isMobile ? "100px" : "0",
      }}
    >
      {!isMobile && (
        <img
          src={require("./../../assets/construction.png")}
          style={{ visibility: "hidden", position: "absolute", width: "100%" }}
          ref={imgRef}
        />
      )}
      <Typography
        fontSize={isMobile ? "30px" : "63px"}
        fontWeight="bold"
        color="white"
      >
        Know your project...
      </Typography>
      <Typography
        fontSize={isMobile ? "30px" : "63px"}
        fontWeight="bold"
        color="white"
      >
        Enjoy the process.
      </Typography>
      <Typography fontSize={isMobile ? "16px" : "22px"} color="white" my="40px">
        The app that glues construction together
      </Typography>
      <Button
        variant="outlined"
        endIcon={<PlayCircle />}
        sx={{
          borderRadius: "30px",
          color: "white",
          border: "1px solid white",
          fontSize: "13px",
          height: isMobile ? "50px" : "60px",
          paddingX: "60px",
        }}
      >
        Watch video
      </Button>
    </Box>
  );
}

export { VideoSection };
