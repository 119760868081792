export const onboarding = {
  welcome: "환영합니다",
  enterpriseInfo: "기업 정보",
  features: "기능",
  choosePlan: "플랜 선택",
  payment: "결제",
  welcomeTitle: "빌드피어에 오신 것을 환영합니다",
  welcomeSubtitle: "프로젝트를 알고, 프로세스를 즐기세요",
  startCreateEnterprise: "기업 생성 시작",
  joinProject: "프로젝트 참여",
  projectCode: "프로젝트 코드",
  createEnterprise: "기업 생성",
  officialName: "기업명",
  enterpriseType: "기업 유형",
  welcomeJourney: "여정에 오신 것을 환영합니다",
  planning: "계획",
  processTracking: "진행 상황 및 추적",
  documentation: "문서",
  resources: "자원",
  community: "커뮤니티",
  management: "관리",
  communication: "소통",
};
