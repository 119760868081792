import { tour } from "./tour";

const inspectionsTemplates = {
  // general tour
  ...tour,
  moduleElement: "Inspecciones - Plantillas",
  subModuleElement: "Plantillas",
  // EDIT AND VIEW - guide me steps
  step1_inspectionTemplatesEdit_title: "1. Ver/Editar una plantilla.",
  step1_inspectionTemplatesEdit_text:
    "Haz click aquí para poder editar la plantilla.",
  step2_inspectionTemplatesEdit_title: "2. Datos de la plantilla.",
  step2_inspectionTemplatesEdit_text:
    "En esta sección puedes ver los datos de la plantilla.",
  step3_inspectionTemplatesEdit_title: "3. Lista de inspecciones.",
  step3_inspectionTemplatesEdit_text:
    "En esta sección puedes la lista de elementos a inspeccionar.",
  step4_inspectionTemplatesEdit_title: "4. Guardar.",
  step4_inspectionTemplatesEdit_text:
    "Haz click aqui para guardar los cambios a la plantilla.",
  step_final_inspectionTemplatesEdit:
    "Haz aprendido cómo visualizar/editar una plantilla.",

  add: "Agregar Plantilla",
  edit: "Editar Plantilla",
  guideMe: "Guíame",
  inspection_template_recycle_bin: "Papelera (Plantillas de Inspección)",
  list: "Lista de Inspecciones",
  meeting: "Reunión",
  no_inspections_templates_text:
    "Si quieres agregar una, te invitamos a hacer clic en el botón Agregar Plantilla de Inspección para comenzar el proceso",
  no_inspections_templates_title:
    "No hay plantillas de inspección creadas en este momento",
  title: "Inspecciones - Plantillas",
  workflow: "Flujo de trabajo",
  add_button_tour: "Crear una nueva plantilla de inspección",
};

export { inspectionsTemplates };
