import { apiSlice } from "../../../../api/apiSlice";

const projectNetworkApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNewCode: builder.query({
      query: (idProject) => `/project/network/newCode/${idProject}`,
      keepUnusedDataFor: 10,
      invalidatesTags: ["ProjectNetworkCodes"],
    }),
    acceptInvitation: builder.mutation({
      query: ({ idProject, ...body }) => ({
        url: `/project/network/accept/${idProject}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ProjectNetwork", "ProjectNetworkCodes"],
    }),
    getProjectNetwork: builder.query({
      query: (idProject) => `/project/network/projectNetwork/${idProject}`,
      providesTags: ["ProjectNetwork"],
    }),
    deleteNetwork: builder.mutation({
      query: ({ idProject, ...body }) => ({
        url: `/project/network/delete/${idProject}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ProjectNetwork"],
    }),
    getAvailableCodes: builder.query({
      query: (idProject) => `/project/network/availableCodes/${idProject}`,
      providesTags: ["ProjectNetworkCodes"],
    }),
  }),
});

export const {
  useLazyGetNewCodeQuery,
  useAcceptInvitationMutation,
  useGetProjectNetworkQuery,
  useDeleteNetworkMutation,
  useGetAvailableCodesQuery,
} = projectNetworkApiSlice;
