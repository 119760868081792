import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

function OptionsFilter({ options, value, onChange, title, icon }) {
  const { t: tGeneral } = useTranslation("general");
  const handleChange = (add, optionValue) => {
    if (add) {
      onChange([...value, optionValue]);
    } else {
      onChange(value.filter((val) => val !== optionValue));
    }
  };

  return (
    <Accordion variant="outlined" sx={{ border: "none" }}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box display="flex" alignItems="center" gap={1}>
          {icon}
          <Typography height="20px">{title}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" rowGap="0.5rem">
          {options.length === 0 ? (
            <Typography>{tGeneral("noOptions")}</Typography>
          ) : (
            options.map((option) => (
              <Box display="flex" key={option.value} alignItems="center">
                <Checkbox
                  checked={value.indexOf(option.value) > -1}
                  onChange={(e) => handleChange(e.target.checked, option.value)}
                />
                <Typography>{option.label}</Typography>
              </Box>
            ))
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export { OptionsFilter };
