export const rfis = {
  // guide me buttons
  add_button_tour: "Adicionar",
  view_button_tour: "Visualizar",
  edit_button_tour: "Editar",
  recycle_button_tour: "Reciclar",
  export_button_tour: "Exportar",
  settings_button_tour: "Configurações",
  // ADD - guide me steps
  step1_addRfi_title: "1. Adicionar RFI",
  step1_addRfi_text: "Clique aqui para adicionar RFI",
  step2_addRfi_title: "2. Título",
  step2_addRfi_text: "Digite um título que descreva o seu RFI",
  step3_addRfi_title: "3. Data de entrega",
  step3_addRfi_text:
    "Escolha a data de entrega como prazo final para receber uma resposta do responsável",
  step4_addRfi_title: "4. Status",
  step4_addRfi_text1_1: "Escolha o status",
  step4_addRfi_text1_2: "ABERTO para publicar o RFI pendente de resposta.",
  step4_addRfi_text2_1: "Escolha o status",
  step4_addRfi_text2_2:
    "RASCUNHO para salvar um rascunho do RFI sem publicá-lo.",
  step5_addRfi_title: "5. Privacidade",
  step5_addRfi_text_1: "Escolha o tipo de privacidade do RFI",
  step5_addRfi_text_2:
    "Qualquer pessoa com acesso ao módulo poderá ver o seu RFI",
  step5_addRfi_text_3: "Apenas os participantes poderão ver este RFI",
  step6_addRfi_title: "6. Responsável",
  step6_addRfi_text: "Selecione as pessoas que irão responder à sua pergunta",
  step7_addRfi_title: "7. Pergunta",
  step7_addRfi_text:
    "Digite a pergunta ou a informação que você deseja solicitar",
  step8_addRfi_title: "8. Arquivos",
  step8_addRfi_text:
    "Faça o upload de qualquer tipo de arquivo (PDF, Word, Excel, JPG, etc).",
  step9_addRfi_title: "9. Recebido De",
  step9_addRfi_text:
    "Por favor, especifique a pessoa do projeto de quem você recebeu a informação.",
  step10_addRfi_title: "10. Especificações",
  step10_addRfi_text:
    "Escolha a especificação à qual você deseja fazer referência.",
  step11_addRfi_title: "11. Desenho",
  step11_addRfi_text:
    "Escolha o desenho ao qual você deseja vincular este RFI.",
  step12_addRfi_title: "12. Custo",
  step12_addRfi_text:
    "Se este RFI tiver impacto no custo, você pode adicionar o valor.",
  step13_addRfi_title: "13. Atraso",
  step13_addRfi_text:
    "Se este RFI tiver impacto no atraso, você pode adicionar os dias e horas.",
  step14_addRfi_title: "14. Lista de Distribuição",
  step14_addRfi_text:
    "Selecione as pessoas que você deseja notificar sobre este RFI.",
  step15_addRfi_title: "15. Publicar",
  step15_addRfi_text: "Clique em publicar para criar o RFI.",
  stepFinal_addRfi: "Você aprendeu como criar um RFI",
  // VIEW - guide me steps
  step1_viewRfi_title: "1. Ver RFI",
  step1_viewRfi_text: "Clique aqui para visualizar um RFI",
  step2_viewRfi_title: "2. Informações gerais do RFI",
  step2_viewRfi_text: "Mostra as informações completas do RFI",
  step3_viewRfi_title: "3. Pergunta oficial",
  step3_viewRfi_text: "Mostra a pergunta oficial com arquivos anexados",
  step4_viewRfi_title: "4. Tradução da pergunta oficial",
  step4_viewRfi_text:
    "A pergunta é traduzida automaticamente para o idioma desejado para uma comunicação eficaz através das fronteiras",
  step5_viewRfi_title: "5. Adicionar respostas e comentários",
  step5_viewRfi_text:
    "Os responsáveis podem enviar respostas e comentários sobre a solicitação oficial",
  step6_viewRfi_title: "6. Criar tópicos",
  step6_viewRfi_text:
    "Os usuários na lista de distribuição também podem responder e comentar na seção de tópicos",
  step7_viewRfi_title: "7. Última visualização",
  step7_viewRfi_text:
    "Você pode ver quais usuários visualizaram recentemente este RFI",
  step8_viewRfi_title: "8. Última edição",
  step8_viewRfi_text:
    "Você pode ver quais usuários interagiram recentemente com este RFI",
  step9_viewRfi_title: "9. Fechar",
  step9_viewRfi_text:
    "Se você é o criador, é sua responsabilidade fechar este RFI assim que receber uma resposta oficial",
  step10_viewRfi_title: "10. Exportar PDF",
  step10_viewRfi_text: "Você pode exportar um PDF com informações deste RFI",
  stepFinal_viewRfi: "Você aprendeu como visualizar um RFI",
  // EDIT - guide me steps
  step1_editRfi_title: "1. Editar RFI",
  step1_editRfi_text: "Clique aqui para editar um RFI",
  step2_editRfi_title: "2. Título",
  step2_editRfi_text: "Digite um título que possa descrever o seu RFI",
  step3_editRfi_title: "3. Data de entrega",
  step3_editRfi_text:
    "Escolha a data de entrega como prazo final para receber uma resposta do responsável",
  step4_editRfi_title: "4. Status",
  step4_editRfi_text1_1: "Escolha o status",
  step4_editRfi_text1_2: "ABERTO para publicar o RFI pendente de resposta.",
  step4_editRfi_text2_1: "Escolha o status",
  step4_editRfi_text2_2:
    "RASCUNHO para manter um rascunho do RFI sem publicá-lo.",
  step5_editRfi_title: "5. Privacidade",
  step5_editRfi_text_1: "Escolha o tipo de privacidade do RFI",
  step5_editRfi_text_2:
    "Qualquer pessoa com acesso ao módulo poderá ver o seu RFI",
  step5_editRfi_text_3: "Apenas os participantes poderão ver este RFI",
  step6_editRfi_title: "6. Responsável",
  step6_editRfi_text: "Marcador de posição",
  step7_editRfi_title: "7. Lista de distribuição",
  step7_editRfi_text: "Marcador de posição",
  step8_editRfi_title: "8. Informações adicionais",
  step8_editRfi_text: "Marcador de posição",
  step9_editRfi_title: "9. Publicar",
  step9_editRfi_text: "Marcador de posição",
  stepFinal_editRfi: "Você aprendeu como editar um RFI",
  // RECYCLE - guide me steps
  step1_recycleRfi_title: "1. Lixeira de RFI",
  step1_recycleRfi_text: "Clique aqui para abrir a lixeira de RFIs",
  step2_recycleRfi_title: "2. Menu da lixeira",
  step2_recycleRfi_text: "Este é o menu da lixeira de RFIs",
  step3_recycleRfi_title: "3. Ver um RFI",
  step3_recycleRfi_text: "Você pode ver o RFI completo",
  step4_recycleRfi_title: "4. Restaurar RFI",
  step4_recycleRfi_text: "Selecione um ou vários RFIs para restaurar",
  stepFinal_recycleRfi: "Você aprendeu como interagir com a lixeira de RFIs",
  // EXPORT - guide me steps
  step1_exportRfi_title: "1. Exportar PDF",
  step1_exportRfi_text: "Clique aqui para exportar o RFI em formato PDF",
  step2_exportRfi_title: "2. Enviar por e-mail",
  step2_exportRfi_text: "Clique aqui para compartilhar este RFI por e-mail",
  step3_exportRfi_title: "2. Exportar CSV",
  step3_exportRfi_text:
    "Selecione alguns ou filtre todos os RFIs para exportá-los em um arquivo CSV",
  step4_exportRfi_title: "4. Exportar PDF",
  step4_exportRfi_text:
    "Selecione alguns ou filtre todos os RFIs para exportá-los em um arquivo PDF",
  step5_exportRfi_title: "3. Tela dentro de um RFI",
  step5_exportRfi_text:
    "Você pode exportar em PDF a partir da visualização detalhada",
  stepFinal_exportRfi: "Você aprendeu como exportar um RFI",
  // SETTINGS - guide me steps
  step1_settingsRfi_title: "1. Configurações de RFI",
  step1_settingsRfi_text: "Clique aqui para modificar as configurações",
  step2_settingsRfi_title: "2. Dias",
  step2_settingsRfi_text:
    "Modifique aqui o limite de dias para responder ao RFI globalmente",
  step3_settingsRfi_title: "3. Lista de distribuição",
  step3_settingsRfi_text:
    "Modifique aqui os usuários que pertencem à lista de distribuição globalmente",
  step4_settingsRfi_title: "4. Salvar configurações",
  step4_settingsRfi_text: "Clique aqui para salvar as configurações",
  stepFinal_settingsRfi:
    "Você aprendeu como interagir com as configurações de RFI",
  // other
  obligatory: "Obrigatório",
  public: "Público",
  private: "Privado",
  congratulations: "Parabéns!",
  rfi_title_module: "RFIs",
  no_rfis_title: "Nenhum RFI (Dúvida) foi criado até o momento",
  no_rfis_text:
    "Se você deseja adicionar um, convidamos você a clicar no botão de adicionar para iniciar o processo",
  meeting: "Suporte",
  workflow: "Fluxo",
  guideMe: "Guie-me",
  support: "Suporte",
  days: "Dias",
  rfi_recycle_bin: "Lixeira (RFIs)",
  addSpec: "Adicionar especificação",
  deleteRfi: "Excluir RFI(s)",
  confirmDelete:
    "Tem certeza de que deseja excluir o(s) RFI(s) selecionado(s)? Essa ação não poderá ser desfeita.",
};
