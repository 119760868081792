import { apiSlice } from "../../api/apiSlice";

export const userSettingsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // get user
    getUser: builder.query({
      query: () => "/user/get",
      keepUnusedDataFor: 60,
      providesTags: ["Projects", "User"],
    }),
    finishOnboarding: builder.mutation({
      query: () => ({
        url: "/user/finishOnboarding",
        method: "PUT",
      }),
      invalidatesTags: ["User"],
    }),
    changePassword: builder.mutation({
      query: (body) => ({
        url: "/user/change-password",
        method: "POST",
        body,
      }),
      // providesTags: ["Projects"],
    }),
    createAvatar: builder.mutation({
      query: (body) => ({
        url: "/user/create-avatar",
        method: "POST",
        body,
      }),
      // invalidatesTags: ["Images"],
    }),
    // Report an issue / Tickets
    reportAnIssue: builder.mutation({
      query: (body) => ({
        url: "/user/reportAnIssue",
        method: "POST",
        body,
      }),
      // invalidatesTags: ["User"],
      // Buildpeer notifications
    }),
    getBuildpeerNotification: builder.query({
      query: (idUser) => `/user/${idUser}/configBpNotifications`,
      keepUnusedDataFor: 60,
      providesTags: ["User"],
    }),
    updateBuildpeerNotification: builder.mutation({
      query: (body) => ({
        url: `/user/updateConfigBpNotifications`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    updateSignature: builder.mutation({
      query: (body) => ({
        url: `/user/signature`,
        method: "PUT",
        body,
      }),
    }),
    // AVATARS
    /* getPresignedUrl: builder.query({
      query: () => "/s3/getPresignedUrl",
      method: "GET",
    }),
    getAvatar: builder.query({
      query: () => "/user/get-avatar",
      method: "GET",
      keepUnusedDataFor: 10,
      providesTags: ["Images"],
    }),
    deleteAvatar: builder.mutation({
      query: (body) => ({
        url: "/user/delete-avatar",
        method: "DELETE",
        body,
      }),
      //invalidatesTags: ["Images"],
    }),
    createBucketAvatar: builder.mutation({
      query: ({ url, body }) => ({
        url,
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        body,
      }),
    }),
    updateSeenBy: builder.mutation({
      query: (body) => ({
        url: "/user/update-seen-by",
        method: "PUT",
        body,
      }),
    }), */
  }),
});

export const {
  useGetUserQuery,
  useChangePasswordMutation,
  // useUploadAvatarMutation,
  // useGetPresignedUrlQuery,
  // useGetAvatarQuery,
  // useDeleteAvatarMutation,
  useCreateAvatarMutation,
  // useUpdateSeenByMutation,
  // useCreateBucketAvatarMutation,
  useReportAnIssueMutation,
  useGetBuildpeerNotificationQuery,
  useUpdateBuildpeerNotificationMutation,
  useFinishOnboardingMutation,
  useUpdateSignatureMutation,
} = userSettingsApiSlice;
