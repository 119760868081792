import { apiSlice } from "../../../../api/apiSlice";
import { objectToParamString } from "../../../../utils";

export const submittalApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getSubmittals: builder.query({
      query: ({ idProject, params }) =>
        `/project/submittals/getProjectSubmittals/${idProject}${objectToParamString(params)}`,
      keepUnusedDataFor: 60,
      providesTags: ["Submittals"],
    }),
    createSubmittal: builder.mutation({
      query: (body) => ({
        url: "project/submittals/createSubmittal",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Submittals"],
    }),
    deleteSubmittal: builder.mutation({
      query: (body) => ({
        url: "project/submittals/deleteSubmittal",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Submittals"],
    }),
    recoverSubmittal: builder.mutation({
      query: (body) => ({
        url: "project/submittals/restoreSubmittal",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Submittals"],
    }),
    editSubmittal: builder.mutation({
      query: (body) => ({
        url: "project/submittals/editSubmittal",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Submittals", "Message"],
    }),
    closeSubmittal: builder.mutation({
      query: (body) => ({
        url: "project/submittals/closeSubmittal",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Submittals", "Dashboard"],
    }),
    getSubmittalById: builder.query({
      query: (idSubmittal) => `project/submittals/getSubmittal/${idSubmittal}`,
      providesTags: ["Submittals"],
    }),
  }),
});

export const {
  useGetSubmittalsQuery,
  useCreateSubmittalMutation,
  useEditSubmittalMutation,
  useDeleteSubmittalMutation,
  useCloseSubmittalMutation,
  useRecoverSubmittalMutation,
  useGetSubmittalByIdQuery,
} = submittalApiSlice;
