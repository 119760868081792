import { Add } from "@mui/icons-material";
import { Box, Button, Card, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SubtaskItemForm from "./SubtaskItemForm";

function SubtaskForm({ subtasks = [], onAdd, onChange}) {
    const { t: tTasks } = useTranslation("tasks");
    const { t: tGeneral } = useTranslation("general");

    const [addItem, setAddItem] = useState(false);
    const [description, setDescription] = useState("");

    return (
        <Box mt={1}>
            {/* Items */}
            <Box
                display="flex"
                sx={{
                    width: "100%",
                    flexDirection: "column",
                    rowGap: 0.5,
                }}
            >
                {subtasks?.map((subtask, index) => (
                    subtask?.bActive && (
                        <SubtaskItemForm
                            key={subtask.id}
                            subtask={subtask}
                            index={index}
                            setSelectedItem={(index) => {
                                let currentSubtasks = [...subtasks];
                                if (currentSubtasks[index].bNew) {
                                    currentSubtasks.splice(index, 1);
                                } else {
                                    currentSubtasks[index].bActive = false;
                                }
                                onChange(currentSubtasks);
                            }}
                            setModifiedDescription={(modifiedDescription) => {
                                let tempSubtasks = [...subtasks];
                                tempSubtasks[index].description = modifiedDescription;
                                onChange(tempSubtasks);
                            }}
                        />
                    )
                ))}
            </Box>
            {/* Add item */}
            {!addItem ? (
                <Button
                    startIcon={
                        <Box
                            sx={{
                                backgroundColor: "#FBCB04",
                                padding: 0,
                                margin: 0,
                                height: 25,
                                width: 25,
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: 1,
                            }}
                        >
                            <Add />
                        </Box>
                    }
                    sx={{
                        color: "black",
                        textTransform: "none",
                        mt: 1,
                    }}
                    onClick={() => setAddItem(true)}
                >
                    {tTasks("addSubtask")}
                </Button>
            ) : (
                <Card elevation={2} sx={{ margin: 1, display: "flex" }}>
                    <TextField
                        fullWidth
                        autoFocus={addItem}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <>
                                    <InputAdornment sx={{ mb: 1, ml: 1 }}>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                bgcolor: "#bdbdbd",
                                                borderRadius: 5,
                                                ":hover": {
                                                    bgcolor: "#bdbdbd",
                                                }
                                            }}
                                            onClick={() => {
                                                setAddItem(false);
                                                setDescription("");
                                            }}
                                        >
                                            {tGeneral("cancel")}
                                        </Button>
                                    </InputAdornment>
                                    <InputAdornment sx={{ mb: 1, ml: 1 }}>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                            if (description.length > 0) {
                                                setAddItem(false);
                                                onAdd(description)
                                                setDescription("");
                                            }
                                            }}
                                        >
                                            {tGeneral("add").toUpperCase()}
                                        </Button>
                                    </InputAdornment>
                                </>
                            ),
                            multiline: true,
                            maxRows: 10,
                        }}
                        sx={{
                            backgroundColor: "white",
                            borderRadius: 5,
                            minHeight: "50px",
                            "& fieldset": { border: "none" },
                        }}
                    />
                </Card>
            )}
        </Box>
    );
}

export default SubtaskForm;