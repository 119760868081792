import { Box, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RelatedUsersDetailsCard } from "../../../components/shared";
import DateCardDetail from "../../../components/shared/DateCardDetail";
import Calendar from "../../../assets/rfi/calendar.png";
import Delivery from "../../../assets/rfi/delivery.svg";
import Star from "../../../assets/rfi/star.svg";
import moment from "moment";

function SubmittalSectionOneDetail(props) {
    const { currentSubmittal } = props;

    const { t: tGeneral } = useTranslation("general");

    const isMobile = useMediaQuery("(max-width:820px)");

    return (
        <Box
            display="flex"
            my="20px"
            gap="10px"
            flexWrap="wrap"
            width="100%"
            flexDirection={isMobile ? "column" : "row"}
        >
            <RelatedUsersDetailsCard
                lists={[
                { users: [currentSubmittal?.createdBy], label: tGeneral("creator") },
                {
                    users: currentSubmittal?.receivedFrom ? [currentSubmittal?.receivedFrom] : [],
                    label: tGeneral("receivedFrom"),
                },
                { users: currentSubmittal?.assignedTo, label: tGeneral("assignees") },
                ]}
            />
            <Box
                display="flex"
                flex={1}
                gap="1%"
                maxWidth={isMobile ? "100%" : "60%"}
            >
                {/* Created Date */}
                <DateCardDetail
                    dateString={moment(currentSubmittal.createDate).format("D MMMM YYYY")}
                    label={tGeneral("createDate")}
                    img={<img src={Calendar} alt="Creado" width="60px" />}
                    status={currentSubmittal.status}
                    type={"create"}
                />
                {/* Due Date */}
                <DateCardDetail
                    dateString={
                        currentSubmittal.dueDate
                        ? moment.utc(currentSubmittal.dueDate).format("D MMMM YYYY")
                        : "N/A"
                    }
                    label={tGeneral("due_date")}
                    img={<img src={Delivery} alt="Entrega" width="60px" />}
                    status={currentSubmittal.status}
                    type={"delivery"}
                />
                {/* Closed Date */}
                <DateCardDetail
                    dateString={currentSubmittal.status.toLowerCase() === "closed" ? moment(currentSubmittal.closedDate).format("D MMMM YYYY") : currentSubmittal.dueDate}
                    label={tGeneral("closedDate")}
                    img={<img src={Star} alt="Cierre" width="60px" />}
                    status={currentSubmittal.status}
                    type={"closed"}
                />
            </Box>
        </Box>
    );
}

export default SubmittalSectionOneDetail;