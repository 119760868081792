import { Brightness1, Check, Close, Delete } from "@mui/icons-material";
import {
  Box,
  ButtonGroup,
  Card,
  IconButton,
  Modal,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SignaturePad from "signature_pad";
import {
  useGetUserQuery,
  useUpdateSignatureMutation,
} from "../../features/userSettings/userSettingsApiSlice";
import { TooltipIconButton } from "./TooltipIconButton";

function SignatureModal({ isOpen, onClose, onSign }) {
  const { t: tGeneral } = useTranslation("general");
  const signaturePad = useRef();

  const [usingExisting, setUsingExisting] = useState(0);

  const [updateSignature] = useUpdateSignatureMutation();

  const { data: currentUser } = useGetUserQuery();

  const resizeCanvas = (signatureCanvas) => {
    if (signatureCanvas) {
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      signatureCanvas.setAttribute(
        "width",
        signatureCanvas.offsetWidth * ratio,
      );
      signatureCanvas.setAttribute(
        "height",
        signatureCanvas.offsetHeight * ratio,
      );
      signatureCanvas.getContext("2d")?.scale(ratio, ratio);
      signaturePad.current?.clear();
    }
  };

  const canvasReference = (signatureCanvas) => {
    if (signatureCanvas) {
      signaturePad.current = new SignaturePad(signatureCanvas);
      resizeCanvas(signatureCanvas);
    }
  };

  const handleSign = () => {
    if (signaturePad.current?.isEmpty() && !usingExisting) return;
    if (!usingExisting) {
      const signImage = signaturePad.current?.toDataURL();
      const formData = new FormData();
      formData.append("signature", signImage);
      updateSignature(formData);
    }
    onSign();
  };

  const changeColor = (newColor) => {
    if (signaturePad.current) {
      signaturePad.current.penColor = newColor;
    }
  };

  const clearPad = () => {
    if (signaturePad.current) {
      signaturePad.current.clear();
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          width: "500px",
          padding: "1rem",
          alignItems: "center",
          borderRadius: "1rem",
        }}
      >
        <Box display="flex" mb="0.5rem">
          <Typography flex={1} fontSize="1.5rem" p="0.5rem">
            {tGeneral("sign")}
          </Typography>
          <TooltipIconButton
            icon={<Close />}
            label={tGeneral("close")}
            onClick={onClose}
            color="black"
          />
          <TooltipIconButton
            icon={<Check />}
            label={tGeneral("sign")}
            onClick={handleSign}
          />
        </Box>
        <Tabs
          value={usingExisting}
          onChange={(e, newValue) => setUsingExisting(newValue)}
          sx={{ mb: "0.5rem" }}
          textColor="primary"
        >
          <Tab
            label={tGeneral("draw")}
            sx={{
              fontSize: "1rem",
              textTransform: "none",
            }}
          />
          <Tab
            label={tGeneral("useExisting")}
            sx={{
              fontSize: "1rem",
              textTransform: "none",
            }}
          />
        </Tabs>
        <Box
          sx={{
            border: "1px solid #E1E1E1",
            backgroundColor: "#EEE",
            borderRadius: "1rem",
          }}
          width="100%"
        >
          <Box
            display="flex"
            sx={{ visibility: usingExisting ? "hidden" : "visible" }}
          >
            <ButtonGroup sx={{ flex: 1 }}>
              <IconButton onClick={() => changeColor("blue")}>
                <Brightness1 sx={{ fill: "blue" }} />
              </IconButton>
              <IconButton onClick={() => changeColor("red")}>
                <Brightness1 sx={{ fill: "red" }} />
              </IconButton>
              <IconButton onClick={() => changeColor("black")}>
                <Brightness1 sx={{ fill: "black" }} />
              </IconButton>
            </ButtonGroup>
            <IconButton onClick={() => clearPad()}>
              <Delete />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="center" height="150px">
            {usingExisting ? (
              <img
                src={currentUser?.signature}
                alt="User signature"
                width="80%"
                height="150px"
              />
            ) : (
              <canvas
                style={{
                  width: "470px",
                  height: "150px",
                  touchAction: "none",
                  userSelect: "none",
                }}
                id="signature-canvas"
                ref={canvasReference}
              />
            )}
          </Box>
        </Box>
      </Card>
    </Modal>
  );
}

export { SignatureModal };
