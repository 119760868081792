export const onboarding = {
  welcome: "ようこそ",
  enterpriseInfo: "企業情報",
  features: "特徴",
  choosePlan: "プランを選択",
  payment: "支払い",
  welcomeTitle: "buildpeerへようこそ",
  welcomeSubtitle: "プロジェクトを知り、プロセスを楽しむ",
  startCreateEnterprise: "企業の作成を開始",
  joinProject: "プロジェクトに参加",
  projectCode: "プロジェクトコード",
  createEnterprise: "企業を作成",
  officialName: "企業名",
  enterpriseType: "企業タイプ",
  welcomeJourney: "旅へようこそ",
  planning: "計画",
  processTracking: "進捗とフォローアップ",
  documentation: "ドキュメンテーション",
  resources: "リソース",
  community: "コミュニティ",
  management: "管理",
  communication: "コミュニケーション",
};
