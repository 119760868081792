export function positiveAndNeutralIntegerValidator(number) {
  if (
    !number ||
    number < 0 ||
    number.includes(".") ||
    number.includes("-") ||
    number.includes("+")
  )
    return "Number can't be decimal or negative";
  return "";
}
