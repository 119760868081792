import { apiSlice } from "../../../../api/apiSlice";

const objectToQueryString = (object) => {
  const queryString = Object.keys(object)
    .map((key) => {
      let value = object[key];
      if (!value) {
        return "";
      }
      if (typeof value === "object") {
        value = value.join(",");
      }

      return `${key}=${value}`;
    })
    .filter((param) => param.length)
    .join("&");
  return queryString.length ? `?${queryString}` : "";
};

export const documentsApiSlice = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    // Get Documents by idProject
    getDocuments: builder.query({
      query: ({ idProject, params, search }) =>
        `/project/document/getDocuments/${idProject}${objectToQueryString({
          ...params,
          search,
        })}`,
      keepUnusedDataFor: 0,
      providesTags: ["Documents"],
    }),
    // Get Document by id
    getDocument: builder.query({
      query: (id) => `/project/document/getDocument/${id}`,
      keepUnusedDataFor: 0,
      providesTags: ["Documents"],
    }),
    // Create document
    createDocument: builder.mutation({
      query: (body) => ({
        url: "/project/document/createDocument",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Documents"],
    }),
    // Delete document
    deleteDocument: builder.mutation({
      query: (body) => ({
        url: "/project/document/deleteDocument",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Documents"],
    }),
    // Restore document
    restoreDocument: builder.mutation({
      query: (body) => ({
        url: "/project/document/restoreDocument",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Documents"],
    }),
    // Edit document
    editDocument: builder.mutation({
      query: (body) => ({
        url: "/project/document/editDocument",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Documents"],
    }),
    // Move document
    moveDocument: builder.mutation({
      query: (body) => ({
        url: "/project/document/moveDocument",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Documents"],
    }),
    toggleFavorite: builder.mutation({
      query: (body) => ({
        url: "project/document/toggleFavorite",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Documents", "ProjectFolder"],
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useGetDocumentQuery,
  useCreateDocumentMutation,
  useDeleteDocumentMutation,
  useRestoreDocumentMutation,
  useEditDocumentMutation,
  useMoveDocumentMutation,
  useToggleFavoriteMutation,
} = documentsApiSlice;
