import { Lock } from "@mui/icons-material";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import { PopUpAlert } from "../../../components/PopUpAlert";
import {
  useGetDocumentsQuery,
  useRestoreDocumentMutation,
} from "../../../features/project/modules/documents/documentsApiSlice";
import { useRestoreProjectFolderMutation } from "../../../features/project/modules/documents/projectFolderApiSlice";
import {
  setNeedReload,
  setSelectedFolder,
  setSelectedFolderId,
} from "../../../features/project/modules/documents/projectFolderSlice";
import { useDistributionList } from "../../../hooks";
import {
  EmptyState,
  FileIcon,
  StyledMaterialReactTable,
  TitleWidgets,
} from "../../../components/shared";
import { ModalFileViewer } from "../../../components/shared/ModalFileViewer";
import { ModalVideoViewer } from "../../../components/shared/ModalVideoViewer";
import { isVideo } from "../../../utils/video";
import DocumentFilters from "./components/DocumentFilters";

function RecycleDocuments() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Translations
  const { t: tGeneral } = useTranslation("general");
  const { t: tDocuments } = useTranslation("documents");

  // Project and user settings
  const { idProject } = useParams();
  const [userList, setUserList] = useState([]);
  const [selectedDocs, setSelectedDocs] = useState({});
  const { projectUsers } = useDistributionList(idProject, "documents");
  // FileViewer
  const [pdfUrl, setPdfUrl] = useState(null);
  // VideoViewer
  const [video, setVideo] = useState(null);
  // Filters
  const [paramsDocs, setParamsDocs] = useState(null);

  // Set project config and project users
  useEffect(() => {
    if (paramsDocs === null) {
      setParamsDocs({ idProject });
    }
    // if (projectConfiguration) {
    //   dispatch(setCurrentProjectConfig(projectConfiguration));
    // }
    if (projectUsers) {
      const usersList = projectUsers
        .filter((obj) => {
          return !obj.bExternal; // only intern users
        })
        .map((user) => ({
          id: user.id,
          name: `${user.user.firstName} ${user.user.lastName}`,
          urlAvatar: user.user.urlAvatar,
          idUser: user.user.id,
        }));
      setUserList(usersList);
    }
    return () => {};
  }, [projectUsers, idProject, paramsDocs]);
  const columns = [
    {
      header: tGeneral("name"),
      accessorKey: "name",
      size: 100,
      muiTableHeadCellProps: {
        align: "left",
      },
      muiTableBodyCellProps: {
        align: "left",
      },
      Cell: ({ cell, row }) => {
        const filetype = cell
          .getValue()
          ?.substring(cell.getValue().indexOf(".") + 1)
          .toLowerCase();
        return (
          <Box
            display="flex"
            component="div"
            columnGap="5px"
            alignItems="center"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              if (!row.original?.isFolder) {
                const tempFile = {
                  ...row.original,
                  type: filetype,
                };
                if (isVideo(tempFile)) {
                  setVideo(tempFile);
                } else {
                  setPdfUrl(row.original?.url);
                }
              }
            }}
          >
            <FileIcon
              extension={row.original?.isFolder ? "folder" : filetype}
            />
            {row.original?.lock && <Lock />}
            <Tooltip title={cell.getValue()}>
              <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                {cell.getValue()}
              </Typography>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      header: tGeneral("createdOn"),
      accessorKey: "createDate",
      Cell: ({ cell }) => (
        <Typography>{moment(cell.getValue()).format("D MMMM YYYY")}</Typography>
      ),
      size: 50,
    },
    {
      header: tGeneral("lastEdit"),
      accessorKey: "lastEdit",
      Cell: ({ cell }) => (
        <Typography>{moment(cell.getValue()).format("D MMMM YYYY")}</Typography>
      ),
      size: 50,
    },
  ];

  const { data: documents, refetch: refetchDocuments } = useGetDocumentsQuery({
    idProject,
    params: { bActive: false, ...paramsDocs },
  });

  // Handle Restore
  const [
    restoreProjectFolder,
    {
      isSuccess: isRestoreProjectFolderSuccess,
      isError: isRestoreProjectFolderError,
    },
  ] = useRestoreProjectFolderMutation();
  useEffect(() => {
    if (isRestoreProjectFolderSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tDocuments("folderRestoreSuccessfully"),
      );
    }
  }, [isRestoreProjectFolderSuccess, tDocuments, tGeneral]);
  useEffect(() => {
    if (isRestoreProjectFolderError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
    }
  }, [isRestoreProjectFolderError, tGeneral]);
  const [
    restoreDocument,
    { isSuccess: isRestoreDocumentSuccess, isError: isRestoreDocumentError },
  ] = useRestoreDocumentMutation();
  useEffect(() => {
    if (isRestoreDocumentSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tDocuments("fileRestoreSuccessfully"),
      );
    }
  }, [isRestoreDocumentSuccess, tDocuments, tGeneral]);
  useEffect(() => {
    if (isRestoreDocumentError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
    }
  }, [isRestoreDocumentError, tGeneral]);
  const [rowsToRestore] = useState([]);
  const handleRestore = async () => {
    const folders = rowsToRestore.reduce((folders, item) => {
      if (item.isFolder) folders.push(item.id);
      return folders;
    }, []);
    const docs = rowsToRestore.reduce((docs, item) => {
      if (!item.isFolder) docs.push(item.id);
      return docs;
    }, []);
    if (folders.length > 0) {
      await restoreProjectFolder({ ids: folders }).unwrap();
    }
    if (docs.length > 0) {
      await restoreDocument({ ids: docs }).unwrap();
    }
    dispatch(setNeedReload(true));
    refetchDocuments();
  };

  return (
    <>
      <TitleWidgets
        title={tDocuments("documentsRecycleBin")}
        searchEnabled
        backEnabled
        supportDisabled
        customBackFunctionEnabled
        customBackFunction={() => {
          dispatch(setSelectedFolderId(""));
          dispatch(setSelectedFolder(null));
          dispatch(setNeedReload(true));
          navigate(-1);
        }}
        onRestore={handleRestore}
        restoreDisabled={!Object.keys(selectedDocs).length}
        isRecycle
      />

      {/* File Manager */}
      <Grid container spacing={1} sx={{ mt: 1 }}>
        {/* File Table */}
        {documents?.length > 0 ? (
          <>
            {/* Filters */}

            <Grid item xs={12}>
              <StyledMaterialReactTable
                data={documents}
                columns={columns}
                setSelectedRows={setSelectedDocs}
                enableRowSelection
                selectedRows={selectedDocs}
                enableRowActions={false}
                filtersComponent={
                  <DocumentFilters
                    paramsDocs={paramsDocs}
                    setParamsDocs={setParamsDocs}
                    userList={userList}
                  />
                }
              />
            </Grid>
            {Boolean(pdfUrl) && (
              <ModalFileViewer
                isOpen={Boolean(pdfUrl)}
                onClose={() => setPdfUrl(null)}
                file={{ url: pdfUrl }}
              />
            )}
            {Boolean(video) && (
              <ModalVideoViewer
                isOpen={Boolean(video)}
                onClose={() => setVideo(null)}
                file={video}
              />
            )}
          </>
        ) : (
          <EmptyState
            title={tDocuments("noFilesOnRecycleBin")}
            // content={""}
            module="documents"
          />
        )}
      </Grid>
    </>
  );
}

export default RecycleDocuments;
