import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import Lottie from "lottie-react";

import animationData from "../../../projectDashboard/16627-firework.json";

const useRoleSteps = () => {
  const { t: translation } = useTranslation("roles");
  const addSteps = [
    {
      target: `[data-tour='${"add-role-1"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_addRole_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_addRole_text")}
          </Typography>
        </div>
      ),
      disableBeacon: true,
    },
    {
      target: `[data-tour='role-name']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_addRole_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_addRole_text")}
          </Typography>
        </div>
      ),
      disableScrolling: true,
    },
    {
      target: `[data-tour='role-permissions']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_addRole_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_addRole_text")}
          </Typography>
        </div>
      ),
      disableScrolling: true,
    },
    {
      target: `[data-tour='role-save-button']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_addRole_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_addRole_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_addRole")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];
  const editSteps = [
    {
      target: `[data-tour='${"edit-role-1"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_editRole_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_editRole_text")}
          </Typography>
        </div>
      ),
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: `[data-tour='role-name']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_editRole_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_editRole_text")}
          </Typography>
        </div>
      ),
      disableScrolling: true,
    },
    {
      target: `[data-tour='role-permissions']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_editRole_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_editRole_text")}
          </Typography>
        </div>
      ),
      disableScrolling: true,
    },
    {
      target: `[data-tour='role-save-button']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_editRole_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_editRole_text")}
          </Typography>
        </div>
      ),
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_editRole")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const deleteSteps = [
    {
      target: `[data-tour='${"delete-role-1"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_deleteRole_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_deleteRole_text")}
          </Typography>
        </div>
      ),
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: `[data-tour='${"delete-role-2"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_deleteRole_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_deleteRole_text")}
          </Typography>
        </div>
      ),
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_deleteRole")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];
  const settingsSteps = [
    {
      target: `[data-tour='${"change-role-1"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_changeRole_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_changeRole_text")}
          </Typography>
        </div>
      ),
      disableBeacon: true,
      disableScrolling: true,
    },
    {
      target: `[data-tour='${"change-role-2"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_changeRole_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_changeRole_text")}
          </Typography>
        </div>
      ),
      disableScrolling: true,
    },
    {
      target: `[data-tour='${"change-role-3"}']`,
      position: "left",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_changeRole_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_changeRole_text")}
          </Typography>
        </div>
      ),
      disableScrolling: true,
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_changeRole")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  return [
    {
      addSteps,
      editSteps,
      deleteSteps,
      settingsSteps,
    },
  ];
};

export { useRoleSteps };
