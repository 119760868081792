import React, { useState } from "react";
// Redux reducers & mutations
// Validate los input
// Hooks
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// MUI Components
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
// Custom components
import Swal from "sweetalert2";
import { emailValidator } from "../services/validators/emailValidator";
import { selectTheme } from "../features/preferences/preferencesSlice";
import { useRecoverpasswordMutation } from "../features/auth/authApiSlice";
import { useTranslation } from "react-i18next";

function RecoverPassword() {
  const { t: tGeneral } = useTranslation("general");
  const { t: tLogin } = useTranslation("login");

  // local input state definition
  const [email, setEmail] = useState("");
  const [emailAlert, setEmailAlert] = useState("");
  const [errorEmail, setErrorEmail] = useState(false);

  const navigate = useNavigate();
  // We check if theme == dark to render proper logo dynamically
  const darkMode = useSelector(selectTheme);

  // Redux dispatch action
  const [recoverPassword] = useRecoverpasswordMutation();

  // Handle submit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = await recoverPassword({ email }).unwrap();
      Swal.fire({
        icon: "success",
        title: tGeneral("sentMessage"),
        text: tLogin("followInstructions"),
        showConfirmButton: false,
        timer: 2000,
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: tLogin("errorRecoverTitle"),
        text:
          err?.status === 404 ? tLogin("errorNoUser") : tLogin("errorLoginMessage"),
        showConfirmButton: false,
        timer: 2000,
      });
      console.error(err);
    }
  };

  return (
    <Grid container item xs={12} md={6} justifyContent="center">
      {/* Buildpeer logo */}
      <Grid item justifyContent="center" alignItems="center" xs={12}>
        <Box
          component="div"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
            mt: 15,
            mb: 10,
          }}
        >
          <Box
            component="img"
            sx={{
              width: "40%",
              height: "40%",
            }}
            alt="Buildpeer Logo"
            src={
              darkMode
                ? require("../assets/logo-dark.png")
                : require("../assets/logo.png")
            }
          />
        </Box>
      </Grid>
      {/* Instructions Text */}
      <Grid item xs={10} justifyContent="center" alignItems="center">
        <Typography align="center" fontWeight="bold">
          {tLogin("recoverPasswordTitle")}
        </Typography>
      </Grid>
      {/* Email input */}
      <Grid item xs={8} alignItems="center" justifyContent="center">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 1,
            mb: 1,
          }}
        >
          <TextField
            error={errorEmail}
            helperText={emailAlert}
            name="email"
            value={email}
            margin="dense"
            variant="outlined"
            required
            label={tGeneral("email")}
            placeholder={tLogin("exampleEmail")}
            type="email"
            sx={{ width: "100%" }}
            onChange={(e) => {
              setEmail(e.target.value);
              if (e.target.value !== "" && !emailValidator(e.target.value)) {
                setErrorEmail(true);
                setEmailAlert(tLogin("emailInvalid"));
              } else {
                setErrorEmail(false);
                setEmailAlert("");
              }
            }}
          />
        </Box>
      </Grid>
      {/* Button Recover Password */}
      <Grid item xs={8} sx={{ my: 1 }}>
        <Button
          onClick={(e) => {
            handleSubmit(e);
          }}
          variant="contained"
          sx={{
            width: "100%",
            borderRadius: 3,
            justifyContent: "center",
            alignItems: "center",
          }}
          disabled={errorEmail || email === ""}
        >
          {tLogin("recoverPassword")}
        </Button>
      </Grid>

      {/* Button Back */}
      <Grid item xs={8} sx={{ my: 1 }}>
        <Button
          onClick={(e) => {
            navigate("/");
          }}
          variant="outlined"
          color="secondary"
          sx={{
            width: "100%",
            borderRadius: 3,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {tGeneral("back")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default RecoverPassword;
