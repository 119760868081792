export const tour = {
  // topbar
  guideMe: "Guide Me",
  support: "Support",
  meeting: "Support",
  workflow: "Workflow",
  // guide me buttons
  addButtonTour: "Add",
  viewButtonTour: "View",
  editButtonTour: "Edit",
  recycleButtonTour: "Recycle",
  exportButtonTour: "Export",
  settingsButtonTour: "Settings",
  deleteButtonTour: "Delete",
  changeRoleButtonTour: "Change Role",
  // docs
  docsAddFileButtonTour: "Add File",
  docsAddFolderButtonTour: "Add Folder",
  docsFileInfoButtonTour: "File Info",
  docsMoveToButtonTour: "Move to",
  docsActionsButtonTour: "Actions",
  // more
  obligatory: "Obligatory",
  congratulations: "Congratulations!",
  moreTitle: "More options",
  moreDescription: "Here you can find additional options.",
};
