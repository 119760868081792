import {
  Circle,
  Email,
  RemoveRedEye,
  MoreHoriz,
  InfoOutlined,
  EditOutlined,
  DeleteOutlineOutlined,
  Share,
  EditNote,
} from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Tooltip,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  AssignedUsersTooltip,
  ModalLastEdit,
  ModalSeenBy,
  ModalShare,
  StyledMaterialReactTable,
  TooltipIconButton,
} from "../../../../components/shared";
import { useAppTourContext } from "../../../../components/supportButton/context/context";
import { selectCurrentGuideMe } from "../../../../features/project/projectSlice";
import { selectCurrentUser } from "../../../../features/userSettings/userSettingsSlice";
import Can from "../../../../utils/can";
import CaslContext from "../../../../utils/caslContext";
import { SendEmailModal } from "./Modals/SendEmailModal";
import pdf from "../../../../utils/pdf";
import { selectCurrentToken } from "../../../../features/auth/authSlice";
import DeleteModal from "./Modals/DeleteModal";
import {
  useDeleteReportsMutation,
  useShareDailyReportMutation,
} from "../../../../features/project/modules/dailyReports/dailyReportsApiSlice";
import { PopUpAlert } from "../../../../components/PopUpAlert";
import { ReactComponent as ShareIcon } from "../../../../assets/icons/share.svg";

const risks = [
  {
    label: "highRisk",
    color: "#FFC62B",
  },
  {
    label: "mediumRisk",
    color: "#FCF18C",
  },
  {
    label: "lowRisk",
    color: "#C0E5D1",
  },
];

const statusColor = {
  open: "#0C9D61",
  closed: "#F64C4C",
  close: "#F64C4C",
  inactive: "#CACACA",
  draft: "#FE9B0E",
};

function Actions({ row, inactive }) {
  const { idEnterprise, idProject } = useParams();
  const navigate = useNavigate();
  const ability = useContext(CaslContext);
  const currentUser = useSelector(selectCurrentUser);
  const token = useSelector(selectCurrentToken);

  const { t: tGeneral } = useTranslation("general");
  const { t: tDailyReports } = useTranslation("dailyReports");

  const [sendingReports, setSendingReports] = useState(false);
  const [isLastSeenOpen, setIsLastSeenOpen] = useState(false);
  const [isLastEditOpen, setIsLastEditOpen] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [menuRef, setMenuRef] = useState(null);
  const [shareMenuRef, setShareMenuRef] = useState(null);
  const [infoMenuRef, setInfoMenuRef] = useState(null);
  const [editMenuRef, setEditMenuRef] = useState(null);
  const [deleteMenuRef, setDeleteMenuRef] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [
    shareDailyReport,
    {
      isSuccess: isShareSuccess,
      error: shareError,
      isError: isShareError,
      isLoading: isShareLoading,
    },
  ] = useShareDailyReportMutation();

  const [
    deleteReports,
    {
      isLoading: isDeleteReportsLoading,
      isSuccess: isDeleteReportsSuccess,
      isError: isDeleteReportsError,
    },
  ] = useDeleteReportsMutation();

  const exportPDF = async () => {
    await pdf({
      data: {},
      token,
      url: `${process.env.REACT_APP_BACKEND_URL}pdf/${idEnterprise}/${idProject}/daily-reports/${row.original.id}`,
      download: row.original.title,
      module: "dailyReport",
      object: row.original,
      tGeneral,
    });
  };

  const handleShareDailyReport = (idProjects) => {
    shareDailyReport({ idDailyReport: row.original.id, shareWith: idProjects });
  };

  const handleDelete = async () => {
    deleteReports({ ids: [row.original.id] });
  };

  useEffect(() => {
    if (isShareSuccess) {
      PopUpAlert("success", tGeneral("shared"), tGeneral("sharedSuccessfully"));
      setIsShareOpen(false);
    }
    if (isShareError) {
      PopUpAlert("error", "Error", shareError.message);
      setIsShareOpen(false);
    }
  }, [isShareSuccess, isShareError, shareError, tGeneral]);

  useEffect(() => {
    if (isDeleteReportsSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        `${tDailyReports("reports")} ${tGeneral("deletedSuccessfully")}`,
      );
      setDeleteModalOpen(false);
    } else if (isDeleteReportsError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      setDeleteModalOpen(false);
    }
  }, [isDeleteReportsSuccess, isDeleteReportsError, tDailyReports, tGeneral]);

  if (inactive) {
    return (
      <TooltipIconButton
        icon={<RemoveRedEye fontSize="small" />}
        label={tGeneral("view")}
        onClick={() => navigate(row.id)}
        data-tour={row.index === 0 ? "recycle-dailyReport-3" : ""}
        color="black"
      />
    );
  }
  if (row.original.status === "draft") {
    return (
      <Button
        fullWidth
        variant="outlined"
        color="secondary"
        onClick={() => {
          navigate(`form/${row.original.id}`);
        }}
        data-tour="edit-dailyReport-1"
      >
        {tGeneral("edit")}
      </Button>
    );
  }
  return (
    <ButtonGroup>
      <IconButton
        aria-label="options"
        onClick={(e) => {
          setMenuRef(e.currentTarget);
          e.stopPropagation();
        }}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id="options"
        anchorEl={menuRef}
        open={Boolean(menuRef)}
        onClose={(e) => {
          setMenuRef(null);
          e.stopPropagation();
        }}
      >
        <IconButton
          aria-label="share"
          onClick={(e) => {
            setShareMenuRef(e.currentTarget);
            e.stopPropagation();
          }}
        >
          <SvgIcon sx={{ width: 20, height: "auto" }}>
            <ShareIcon />
          </SvgIcon>
        </IconButton>
        <IconButton
          aria-label="info"
          onClick={(e) => {
            setInfoMenuRef(e.currentTarget);
            e.stopPropagation();
          }}
        >
          <InfoOutlined />
        </IconButton>
        <Can I="edit" a="dailyReports">
          {(ability.can("superAdmin", "dailyReports") ||
            row.original.creators.indexOf(currentUser.id) > -1) && (
            <IconButton
              aria-label="edit"
              onClick={(e) => {
                setEditMenuRef(e.currentTarget);
                e.stopPropagation();
              }}
            >
              <EditOutlined />
            </IconButton>
          )}
        </Can>
        <IconButton
          aria-label="delete"
          onClick={(e) => {
            setDeleteMenuRef(e.currentTarget);
            e.stopPropagation();
          }}
        >
          <DeleteOutlineOutlined />
        </IconButton>
      </Menu>

      {/* Share Menu */}
      <Menu
        id="share"
        anchorEl={shareMenuRef}
        open={Boolean(shareMenuRef)}
        onClose={(e) => {
          setShareMenuRef(null);
          e.stopPropagation();
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={(e) => {
            exportPDF();
            e.stopPropagation();
          }}
        >
          <ListItemIcon>
            <PictureAsPdfIcon />
          </ListItemIcon>
          <ListItemText>{tDailyReports("exportPdf")}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            setIsShareOpen(true);
            e.stopPropagation();
          }}
        >
          <ListItemIcon>
            <Share />
          </ListItemIcon>
          <ListItemText>{tGeneral("share")}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            setSendingReports(true);
            e.stopPropagation();
          }}
        >
          <ListItemIcon>
            <Email />
          </ListItemIcon>
          <ListItemText>{tGeneral("email")}</ListItemText>
        </MenuItem>
      </Menu>

      {/* Info Menu */}
      <Menu
        id="info"
        anchorEl={infoMenuRef}
        open={Boolean(infoMenuRef)}
        onClose={(e) => {
          setInfoMenuRef(null);
          e.stopPropagation();
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={(e) => {
            setIsLastSeenOpen(true);
            e.stopPropagation();
          }}
        >
          <ListItemIcon>
            <RemoveRedEye />
          </ListItemIcon>
          <ListItemText>{tDailyReports("lastSeen")}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            setIsLastEditOpen(true);
            e.stopPropagation();
          }}
        >
          <ListItemIcon>
            <EditNote />
          </ListItemIcon>
          <ListItemText>{tDailyReports("lastEdit")}</ListItemText>
        </MenuItem>
      </Menu>

      {/* Edit Menu */}
      <Menu
        id="info"
        anchorEl={editMenuRef}
        open={Boolean(editMenuRef)}
        onClose={(e) => {
          setEditMenuRef(null);
          e.stopPropagation();
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={(e) => {
            navigate(`form/${row.original.id}`);
            e.stopPropagation();
          }}
        >
          <ListItemIcon>
            <EditNote />
          </ListItemIcon>
          <ListItemText>{tGeneral("edit")}</ListItemText>
        </MenuItem>
      </Menu>

      {/* Delete Menu */}
      <Menu
        id="delete"
        anchorEl={deleteMenuRef}
        open={Boolean(deleteMenuRef)}
        onClose={(e) => {
          setDeleteMenuRef(null);
          e.stopPropagation();
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={(e) => {
            setDeleteModalOpen(true);
            e.stopPropagation();
          }}
        >
          <ListItemIcon>
            <DeleteOutlineOutlined />
          </ListItemIcon>
          <ListItemText>{tGeneral("delete")}</ListItemText>
        </MenuItem>
      </Menu>

      {sendingReports && (
        <SendEmailModal
          onClose={(e) => {
            setSendingReports(false);
            e.stopPropagation();
          }}
          isOpen={sendingReports}
          reports={[row.original]}
        />
      )}
      <ModalSeenBy
        isOpen={isLastSeenOpen}
        onClose={(e) => {
          setIsLastSeenOpen(false);
          e.stopPropagation();
        }}
        idObject={row.original.id}
      />
      <ModalLastEdit
        isOpen={isLastEditOpen}
        onClose={(e) => {
          setIsLastEditOpen(false);
          e.stopPropagation();
        }}
        idObject={row.original.id}
      />
      <ModalShare
        isOpen={isShareOpen}
        onClose={(e) => {
          setIsShareOpen(false);
          e.stopPropagation();
        }}
        moduleName="dailyReport"
        onShare={handleShareDailyReport}
        objectName={row.original.title}
        sharedWith={row.original.sharedWith ?? []}
        isLoading={isShareLoading}
      />

      <DeleteModal
        isOpen={deleteModalOpen}
        onClose={(e) => {
          setDeleteModalOpen(false);
          e.stopPropagation();
        }}
        handleDelete={handleDelete}
        action="multi"
        isLoading={isDeleteReportsLoading}
      />
    </ButtonGroup>
  );
}

function DailyReportsTable({ reports, inactive = false, category, isLoading }) {
  const { t: tDailyReports } = useTranslation("dailyReports");
  const { t: tGeneral } = useTranslation("general");
  const { t: tStatus } = useTranslation("status");

  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const [sendingReports, setSendingReports] = useState(null);

  const navigate = useNavigate();
  const columns = useMemo(() => {
    const cols = [
      {
        header: (
          <Typography color="#8E8E8E" fontWeight="400">
            {tGeneral("status")}
          </Typography>
        ),
        accessorKey: "status",
        Cell: ({ cell, row }) => (
          <Tooltip
            title={tStatus(
              row.original?.bActive ? cell.getValue() : "inactive",
            )}
          >
            <Circle
              sx={{
                color:
                  statusColor[
                    row.original?.bActive ? cell.getValue() : "inactive"
                  ],
              }}
            />
          </Tooltip>
        ),
        size: 40,
        enableSorting: false,
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
          sx: { padding: 0 },
        },
      },
      {
        header: (
          <Typography color="#8E8E8E" fontWeight="400">
            #
          </Typography>
        ),
        accessorKey: "number",
        size: 50,
        muiTableHeadCellProps: {
          sx: { padding: 0 },
          align: "center",
        },
        muiTableBodyCellProps: {
          align: "center",
        },
      },
      {
        header: (
          <Typography color="#8E8E8E" fontWeight="400">
            {tDailyReports("title")}
          </Typography>
        ),
        accessorKey: "title",
        muiTableBodyCellProps: {
          align: "left",
        },
        muiTableHeadCellProps: {
          align: "left",
        },
        size: 120,
        Cell: ({ cell }) => (
          <Tooltip title={cell.getValue()}>
            <Typography
              textOverflow="ellipsis"
              noWrap
              sx={{
                ":hover": {
                  textOverflow: "unset",
                  textWrap: "wrap",
                  maxHeight: "50px",
                },
              }}
            >
              {cell.getValue()}
            </Typography>
          </Tooltip>
        ),
      },
    ];
    if (category === 5) {
      cols.push({
        header: (
          <Typography color="#8E8E8E" fontWeight="400">
            {tGeneral("attendance")}
          </Typography>
        ),
        accessorKey: "manpower",
        size: 100,
        Cell: ({ cell }) => (
          <Box display="flex" flexDirection="column">
            <Typography>
              {cell.getValue()?.assistants} {tDailyReports("assistants")}
            </Typography>
            <Typography>
              {cell.getValue()?.hours} {tGeneral("hours")}
            </Typography>
          </Box>
        ),
      });
    }
    if (category === 4) {
      cols.push({
        header: (
          <Typography color="#8E8E8E" fontWeight="400">
            {tDailyReports("delay")}
          </Typography>
        ),
        accessorKey: "delayReport",
        size: 100,
        Cell: ({ cell }) => (
          <Box display="flex" flexDirection="column">
            <Typography>
              {cell.getValue()?.delayDays}{" "}
              {cell.getValue()?.delayDays === 1
                ? tGeneral("day")
                : tGeneral("days")}
            </Typography>
            <Typography>
              {cell.getValue()?.delayHours}{" "}
              {cell.getValue()?.delayHours === 1
                ? tGeneral("hour")
                : tGeneral("hours")}
            </Typography>
          </Box>
        ),
      });
    }
    if (category === 3) {
      cols.push({
        header: (
          <Typography color="#8E8E8E" fontWeight="400">
            {tDailyReports("risk")}
          </Typography>
        ),
        accessorKey: "risk",
        size: 100,
        Cell: ({ cell }) => (
          <Chip
            sx={{
              backgroundColor: tDailyReports(risks[cell.getValue()]?.color),
            }}
            label={tDailyReports(risks[cell.getValue()]?.label)}
          />
        ),
      });
    }
    if (category === 1 || category === 2) {
      cols.push({
        header: (
          <Typography color="#8E8E8E" fontWeight="400">
            {tGeneral("locations")}
          </Typography>
        ),
        accessorKey: "agendas",
        size: 100,
        Cell: ({ cell }) => (
          <Typography>
            {cell.getValue()?.length}{" "}
            {cell.getValue()?.length > 1
              ? tGeneral("locations")
              : tGeneral("location")}
          </Typography>
        ),
      });
    }
    return [
      ...cols,
      {
        header: (
          <Typography color="#8E8E8E" fontWeight="400">
            {tDailyReports("assigned")}
          </Typography>
        ),
        accessorKey: "assignedToUsers",
        enableSorting: false,
        Cell: ({ cell }) => <AssignedUsersTooltip users={cell.getValue()} />,
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
        size: 120,
      },
      {
        accessorKey: "createdDate",
        header: (
          <Typography color="#8E8E8E" fontWeight="400">
            {tGeneral("date")}
          </Typography>
        ),
        Cell: ({ cell }) => moment.utc(cell.getValue()).format("DD/MM/YYYY"),
        size: 120,
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
      },
    ];
  }, [category, tDailyReports, tGeneral, tStatus]);

  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 2 &&
      currentGuideMe === "daily-reports-recycle"
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe, setState]);

  return (
    <>
      <StyledMaterialReactTable
        data={reports}
        pageSize={5}
        isLoading={isLoading}
        columns={columns}
        enableRowSelection={false}
        customHeight="unset"
        renderRowActions={({ row }) => (
          <Actions row={row} inactive={inactive} />
        )}
        emptyStateMessage={tGeneral("noElements", {
          moduleName: tDailyReports("reports").toLowerCase(),
        })}
        onClickRow={(row) => {
          navigate(row.id);
        }}
      />
      {Boolean(sendingReports) && (
        <SendEmailModal
          onClose={() => setSendingReports(null)}
          isOpen={Boolean(sendingReports)}
          reports={sendingReports}
        />
      )}
    </>
  );
}

export { DailyReportsTable };
