// @ts-check
/* eslint react/prop-types: 0 */

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button } from "@mui/material";
// eslint-disable-next-line no-unused-vars
import React from "react";
import { useTranslation } from "react-i18next";

export default function ShowHideCompleted(props) {
  const { completed, setCompleted } = props;

  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  return (
    <Button
      color="secondary"
      disableElevation
      startIcon={completed ? <VisibilityIcon /> : <VisibilityOffIcon />}
      onClick={() => {
        setCompleted(!completed);
      }}
      sx={{
        textTransform: "none",
      }}
      variant="text"
    >
      {completed ? tProjectSchedule("Show Completed") : tProjectSchedule("Hide Completed")}
    </Button>
  );
}
