import { Add, Delete, FormatListBulleted } from "@mui/icons-material";
import { Box, Button, Card, CircularProgress, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  EmptyState,
  ModalWithAction,
  TitleWidgets,
  TooltipIconButton,
} from "../../../components/shared";
import {
  useDeleteNetworkMutation,
  useGetProjectNetworkQuery,
} from "../../../features/project/modules/projectNetwork/projectNetworkApiSlice";
import { ModalCreateProjectNetwork } from "./ModalCreateProjectNetwork";
import Can from "../../../utils/can";
import { ModalAvailableCodes } from "./ModalAvailableCodes";

function ProjectCard({ projectNetwork }) {
  const { relatedProject: project } = projectNetwork;
  const { idProject } = useParams();

  const { t: tGeneral } = useTranslation("general");
  const { t: tProjectNetwork } = useTranslation("projectNetwork");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const [deleteNetwork] = useDeleteNetworkMutation();

  const handleDeleteNetwork = () => {
    deleteNetwork({ idProject, idProjectGuest: project.id });
  };

  return (
    <Card
      sx={{
        display: "flex",
        p: "1rem",
        columnGap: "1rem",
        height: "180px",
        borderRadius: "1rem",
        position: "relative",
      }}
    >
      <Box flex={1}>
        <Typography variant="h6" fontWeight="bold">
          {project?.projectName}
        </Typography>

        <Box flex={1} display="flex" columnGap="1rem">
          <Box display="flex" flexDirection="column" rowGap="0.3rem">
            <Typography fontWeight="bold">{tGeneral("createdBy")}:</Typography>
            <Typography fontWeight="bold">
              {tGeneral("createdDate")}:
            </Typography>
            <Typography fontWeight="bold">{tGeneral("acceptedBy")}:</Typography>
            <Typography fontWeight="bold">
              {tGeneral("acceptedDate")}:
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" rowGap="0.3rem">
            <Typography>
              {projectNetwork.createdBy.firstName}{" "}
              {projectNetwork.createdBy.lastName}
            </Typography>
            <Typography>
              {moment(projectNetwork.createdDate).format("D/MM/YYYY LT")}
            </Typography>
            <Typography>
              {projectNetwork.acceptedBy.firstName}{" "}
              {projectNetwork.acceptedBy.lastName}
            </Typography>
            <Typography>
              {moment(projectNetwork.acceptedDate).format("D/MM/YYYY LT")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <img
          src={project.logo}
          alt={project.projectName}
          style={{
            objectFit: "cover",
            height: "100%",
            width: "100%",
            borderRadius: "1rem",
          }}
        />
      </Box>
      <TooltipIconButton
        sx={{
          position: "absolute",
          color: "red",
          right: 0,
          top: 0,
        }}
        icon={<Delete />}
        label={tGeneral("delete")}
        onClick={() => setIsDeleteOpen(true)}
      />
      <ModalWithAction
        actionLabel={tGeneral("delete")}
        title={tGeneral("delete")}
        content={tProjectNetwork("deleteNetworkMessage")}
        isOpen={isDeleteOpen}
        onClose={() => setIsDeleteOpen(false)}
        action={handleDeleteNetwork}
      />
    </Card>
  );
}

function ProjectNetworkContent({ openAdd }) {
  const { idProject } = useParams();

  const { t: tProjectNetwork } = useTranslation("projectNetwork");

  const { data, isLoading } = useGetProjectNetworkQuery(idProject, {
    skip: !idProject,
  });

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <CircularProgress size="3rem" />
      </Box>
    );
  }

  if (data && data.length === 0) {
    return (
      <EmptyState
        title={tProjectNetwork("emptyNetworkTitle")}
        content={tProjectNetwork("emptyNetworkContent")}
        module="adminPanel"
        button={
          <Can I="add" a="adminPanel">
            <Button variant="contained" onClick={openAdd}>
              <Add />
              {tProjectNetwork("addProjectNetwork")}
            </Button>
          </Can>
        }
      />
    );
  }

  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr" p="1rem">
      {data?.map((project) => (
        <ProjectCard projectNetwork={project} key={project.id}/>
      ))}
    </Box>
  );
}

function ProjectNetwork() {
  const { t: tGeneral } = useTranslation("general");
  const { t: tProjectNetwork } = useTranslation("projectNetwork");

  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isCodesOpen, setIsCodesOpen] = useState(false);

  return (
    <Box>
      <TitleWidgets
        title={tGeneral("projectNetwork")}
        addFunction={() => setIsAddOpen(true)}
        moduleName="adminPanel"
        showAdd
        showRestore={false}
        showExports={false}
        customActions={[
          {
            icon: <FormatListBulleted />,
            label: tProjectNetwork("availableCodes"),
            onClick: () => setIsCodesOpen(true),
          },
        ]}
      />
      <ProjectNetworkContent openAdd={() => setIsAddOpen(true)} />
      <ModalCreateProjectNetwork
        isOpen={isAddOpen}
        onClose={() => setIsAddOpen(false)}
      />
      <ModalAvailableCodes
        isOpen={isCodesOpen}
        onClose={() => setIsCodesOpen(false)}
      />
    </Box>
  );
}

export { ProjectNetwork };
