export const login = {
  errorLoginTitle: "登录失败",
  errorLoginAuthMessage: "电子邮件和/或密码不匹配",
  errorLoginMessage: "出现了一些问题。请稍后重试。",
  errorNoUser: "用户名不存在",
  welcomeBack: "欢迎回来！",
  password: "密码",
  logIn: "登录",
  forgotPassword: "忘记密码？",
  errorRecoverTitle: "密码恢复失败",
  followInstructions: "请按照说明操作",
  recoverPasswordTitle:
    "请输入您的 Buildpeer 帐户的电子邮件地址，以接收有关密码重置的说明",
  exampleEmail: "example@mail.com",
  recoverPassword: "恢复密码",
  emailInvalid: "电子邮件无效",
};
