import {
  Popover,
  Box,
  Typography,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import {
  CONST_DEFAULT_DESIGN_TEMPLATES_FILTERS,
  CONST_DEFAULT_DESIGN_TEMPLATES_W_PAGINATION,
} from "../../../features/designTemplates";

import AddIcon from "../../../assets/templates/AddIcon.svg";
import CloseIcon from "../../../assets/templates/CloseIcon.svg";
import EditedIcon from "../../../assets/templates/EditedIcon.svg";
import ImportIcon from "../../../assets/templates/ImportIcon.svg";
import FiltersIcon from "../../../assets/templates/FiltersIcon.svg";
import CalendarIcon from "../../../assets/templates/CalendarIcon.svg";

function FiltersPopover({
  userList = [],
  onChangeFilters = () => {},
  filters = CONST_DEFAULT_DESIGN_TEMPLATES_FILTERS,
}) {
  const { t: tTemplates } = useTranslation("templates");

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [innerFilters, setInnerFilters] = useState({ ...filters });

  const [editedByAccordionExpanded, setEditedByAccordionExpanded] =
    useState(false);
  const [dateRangeAccordionExpanded, setDateRangeAccordionExpanded] =
    useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const getFilteredGroupedUsers = () => {
    return userList
      .filter((user) =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()),
      )
      .reduce((acc, user) => {
        const firstLetter = user.name[0].toUpperCase();
        if (!acc[firstLetter]) acc[firstLetter] = [];
        acc[firstLetter].push(user);
        return acc;
      }, {});
  };

  const handleUserCheckboxChange = (userId) => {
    setInnerFilters((prevFilters) => ({
      ...prevFilters,
      editedBy: prevFilters.editedBy.includes(userId)
        ? prevFilters.editedBy.filter((id) => id !== userId)
        : [...prevFilters.editedBy, userId],
    }));
  };

  const handleDateChange = (key, value) => {
    setInnerFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  const onHandleSetFilters = () => {
    onChangeFilters({
      ...filters,
      createdAfter: innerFilters.createdAfter,
      createdBefore: innerFilters.createdBefore,
      editedBy: innerFilters.editedBy,
    });

    handleClose();
  };

  const onHandleClearFilters = () => {
    onChangeFilters({
      ...CONST_DEFAULT_DESIGN_TEMPLATES_W_PAGINATION,
    });

    handleClose();
  };

  return (
    <>
      <Button onClick={handleClick}>
        <img
          src={FiltersIcon}
          alt="icon"
          style={{ height: "24px", color: "#8E8E8E" }}
        />
      </Button>
      <Popover
        id={open ? "filters-popover" : undefined}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "start",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            width: "600px",
            display: "flex",
            flexDirection: "column",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "18px 12px",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontWeight: 500, fontSize: "1.25rem" }}>
              {tTemplates("filters.title")}
            </Typography>
            <Button onClick={handleClose}>
              <img
                src={CloseIcon}
                alt="icon"
                style={{ height: "24px", color: "#8E8E8E" }}
              />
            </Button>
          </Box>

          {/* editedBy Filter Collapsible Section */}
          <Divider sx={{ my: "0.5rem" }} />
          <Accordion
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
            expanded={editedByAccordionExpanded || filters.editedBy.length > 0}
            onChange={() => setEditedByAccordionExpanded((prev) => !prev)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <img
                  src={EditedIcon}
                  alt="icon"
                  style={{ height: "24px", color: "#8E8E8E" }}
                />
                <Typography
                  sx={{
                    fontSize: "1.25rem",
                  }}
                >
                  {tTemplates("filters.filterByEditedBy")}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  overflowY: "auto",
                  maxHeight: "30vh",
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                  width: "100%",
                  margin: "0 0 1rem",
                  padding: "8px 4px 16px",
                }}
              >
                <TextField
                  sx={{
                    "> .MuiOutlinedInput-root": {
                      borderRadius: "14px",
                    },
                  }}
                  placeholder={tTemplates("filters.searchUser")}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <Box
                  sx={{
                    padding: "8px 8px 16px",
                  }}
                >
                  {Object.entries(getFilteredGroupedUsers()).map(
                    ([letter, users]) => (
                      <Box key={letter} sx={{ mt: 1 }}>
                        <Typography variant="subtitle2">{letter}</Typography>
                        <List dense>
                          {users.map((user) => (
                            <ListItem
                              key={user.id}
                              sx={{
                                px: "4px",
                              }}
                            >
                              <Checkbox
                                checked={innerFilters.editedBy.includes(
                                  user.id,
                                )}
                                onChange={() =>
                                  handleUserCheckboxChange(user.id)
                                }
                              />
                              <ListItemText primary={user.name} />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    ),
                  )}
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          {/* Date Range Filter Collapsible Section */}
          <Divider sx={{ my: "0.5rem" }} />
          <Accordion
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
            expanded={
              dateRangeAccordionExpanded ||
              filters.createdAfter !== null ||
              filters.createdBefore !== null
            }
            onChange={() => setDateRangeAccordionExpanded((prev) => !prev)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <img
                  src={CalendarIcon}
                  alt="icon"
                  style={{ height: "24px", color: "#8E8E8E" }}
                />
                <Typography
                  sx={{
                    fontSize: "1.25rem",
                  }}
                >
                  {tTemplates("filters.filterByDateRange")}
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  overflowY: "auto",
                  maxHeight: "30vh",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  width: "100%",
                  margin: "0 0 1rem",
                  padding: "8px 8px 16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "3rem",
                    width: "100%",
                    margin: "0 0 1rem",
                    alignItems: "baseline",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "50px",
                    }}
                  >
                    <Typography>{tTemplates("filters.startDate")}</Typography>
                  </Box>
                  <DatePicker
                    sx={{
                      width: "18rem",
                      "> .MuiOutlinedInput-root": {
                        borderRadius: "14px",
                      },
                    }}
                    label={tTemplates("filters.setDatePlaceholder")}
                    value={innerFilters.createdAfter}
                    onChange={(date) => handleDateChange("startDate", date)}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "3rem",
                    width: "100%",
                    margin: "0 0 1rem",
                    alignItems: "baseline",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "50px",
                    }}
                  >
                    <Typography>{tTemplates("filters.endDate")} </Typography>
                  </Box>
                  <DatePicker
                    sx={{
                      width: "18rem",
                      "> .MuiOutlinedInput-root": {
                        borderRadius: "14px",
                      },
                    }}
                    label={tTemplates("filters.setDatePlaceholder")}
                    value={innerFilters.createdBefore}
                    onChange={(date) =>
                      handleDateChange("filters.endDate", date)
                    }
                  />
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Divider sx={{ my: "0.5rem" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              padding: "18px 12px",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={onHandleClearFilters}
              sx={{
                width: "15rem",
                my: 2,
                borderRadius: "20px",
                alignSelf: "center",
                px: 3,
                py: 1,
              }}
            >
              {tTemplates("filters.clearFilters")}
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={onHandleSetFilters}
              disabled={
                (innerFilters.createdAfter === null &&
                  innerFilters.createdBefore !== null) ||
                (innerFilters.createdAfter !== null &&
                  innerFilters.createdBefore === null)
              }
              sx={{
                width: "15rem",
                my: 2,
                borderRadius: "20px",
                alignSelf: "center",
                px: 3,
                py: 1,
              }}
            >
              {tTemplates("filters.showResults")}
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

function CreatePopover() {
  const navigate = useNavigate();
  const { t: tTemplates } = useTranslation("templates");

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        sx={{ textTransform: "none", borderRadius: "1rem", width: "14rem" }}
        onClick={handleClick}
        variant="contained"
      >
        <span
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <img
            src={AddIcon}
            alt="icon"
            style={{ height: "24px", color: "#8E8E8E" }}
          />
          <Typography sx={{ color: "black", fontWeight: 500 }}>
            {tTemplates("createTemplate")}
          </Typography>
        </span>
      </Button>
      {/* Popover */}
      <Popover
        id={open ? "create-popover" : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "start",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          marginTop: "5px",
        }}
      >
        <Box
          sx={{
            px: "16px",
            py: "8px",
            display: "flex",
            flexDirection: "column",
            borderRadius: "10px",
          }}
        >
          {/* Buttons inside Popover */}
          <Button
            variant="outlined"
            onClick={() => navigate("form")}
            sx={{
              px: "16px",
              py: "8px",
              textTransform: "none",
              border: "none",
              alignSelf: "flex-start",
              "&:hover": {
                border: "none",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            <span
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <img
                src={AddIcon}
                alt="icon"
                style={{ height: "24px", color: "#8E8E8E" }}
              />
              <Typography sx={{ color: "black", fontWeight: 500 }}>
                {tTemplates("createNewTemplate")}
              </Typography>
            </span>
          </Button>
          <Divider sx={{ my: "0.5rem" }} />
          <Button
            disabled
            variant="outlined"
            onClick={() => navigate("import")}
            sx={{
              px: "16px",
              py: "8px",
              textTransform: "none",
              border: "none",
              alignSelf: "flex-start",
              "&.Mui-disabled": {
                border: "none",
              },
              "&:hover": {
                border: "none",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            <span
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <img
                src={ImportIcon}
                alt="icon"
                style={{ height: "24px", color: "#8E8E8E" }}
              />
              <Typography sx={{ fontWeight: 500 }}>
                {tTemplates("importTemplate")}
              </Typography>
            </span>
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export { FiltersPopover, CreatePopover };
