import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import {
  useGetSubmittalsQuery,
  useRecoverSubmittalMutation,
} from "../../../features/project/modules/submittals/submittalApiSlice";
import { useTranslation } from "react-i18next";
import { PopUpAlert } from "../../../components/PopUpAlert";
import { TitleWidgets } from "../../../components/shared";
import { SubmittalsTable } from "../../../components/projectDashboard/Submittals/SubmittalsTable";

const SubmittalRecycle = () => {
  const { idProject } = useParams();

  const { t: tGeneral } = useTranslation("general");
  const { t: tSubmittals } = useTranslation("submittals");

  const [paramsFilters, setParamsFilters] = useState({});
  const [selectedSubmittals, setSelectedSubmittals] = useState({});

  const {
    data: submittalsData,
    isLoading: isSubmittalsLoading,
    isFetching: isSubmittalsFetching,
    refetch,
  } = useGetSubmittalsQuery({
    idProject,
    params: { bActive: false, ...paramsFilters },
  });

  const submittals = useMemo(() => submittalsData ?? [], [submittalsData]);

  const [
    recoverSubmittals,
    {
      isSuccess: isRecoverSuccess,
      isError: isRecoverError,
      isLoading: isRestoreLoading,
    },
  ] = useRecoverSubmittalMutation();

  const restoreSubmittal = async (ids) => {
    const body = { ids: Object.keys(selectedSubmittals) };
    recoverSubmittals(body);
    setSelectedSubmittals({});
    refetch();
  };

  useEffect(() => {
    if (isRecoverSuccess) {
      PopUpAlert(
        "success",
        tGeneral("success"),
        `${tSubmittals("submittal")} ${tGeneral("recoveredSuccessfully")}`,
      );
    }
    if (isRecoverError) {
      PopUpAlert("error", "Error", tGeneral("cantRestore"));
    }
  }, [tGeneral, isRecoverSuccess, isRecoverError]);

  return (
    <Box sx={{ height: 500, width: "100%" }}>
      <TitleWidgets
        title={tSubmittals("submittalRecycleBin")}
        backEnabled
        isRecycle
        restoreDisabled={!Object.keys(selectedSubmittals).length}
        onRestore={restoreSubmittal}
        isRestoreLoading={isRestoreLoading}
      />
      <SubmittalsTable
        submittals={submittals}
        setFilters={(val) => setParamsFilters(val)}
        selectedSubmittals={selectedSubmittals}
        setSelectedSubmittals={setSelectedSubmittals}
        isRecycle
        isLoading={isSubmittalsLoading || isSubmittalsFetching}
      />
    </Box>
  );
};

export default SubmittalRecycle;
