import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

function InvoiceDetailsModal({ isOpen, onClose, onAction, invoiceInfo }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tPayment } = useTranslation("payment");
  const { control, handleSubmit } = useForm({ defaultValues: invoiceInfo });
  const onSubmit = (data) => {
    onAction(data);
    onClose();
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{ width: "30%", height: "780px", borderRadius: "20px", p: "30px" }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography fontSize="1.563rem" fontWeight="600">
            {tPayment("invoiceDetails")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close sx={{ color: "#000" }} />
          </IconButton>
        </Box>
        <Divider sx={{ my: "30px" }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column" rowGap="20px">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label={tPayment("emailRecipient")}
                  placeholder={tPayment("emailRecipient")}
                  {...field}
                />
              )}
            />
            <Controller
              name="companyName"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label={tPayment("companyName")}
                  placeholder={tPayment("companyName")}
                  {...field}
                />
              )}
            />
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label={tGeneral("country")}
                  placeholder={tGeneral("country")}
                  {...field}
                />
              )}
            />
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label={tGeneral("address")}
                  placeholder={tGeneral("address")}
                  {...field}
                />
              )}
            />
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label={tGeneral("city")}
                  placeholder={tGeneral("city")}
                  {...field}
                />
              )}
            />
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label={tGeneral("state")}
                  placeholder={tGeneral("state")}
                  {...field}
                />
              )}
            />
            <Controller
              name="zipCode"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label={tGeneral("zipCode")}
                  placeholder={tGeneral("zipCode")}
                  {...field}
                />
              )}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            mt="35px"
            columnGap="16px"
          >
            <Button
              sx={{ py: "16px" }}
              variant="outlined"
              color="secondary"
              onClick={onClose}
              fullWidth
            >
              {tGeneral("cancel")}
            </Button>
            <Button
              sx={{ py: "16px" }}
              variant="contained"
              type="submit"
              fullWidth
            >
              {tGeneral("save")}
            </Button>
          </Box>
        </form>
      </Card>
    </Modal>
  );
}

export { InvoiceDetailsModal };
