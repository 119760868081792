import { datadogRum } from "@datadog/browser-rum";
import { Box } from "@mui/material";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { PopUpAlert } from "../../../components/PopUpAlert";
import { selectTheme } from "../../../features/preferences/preferencesSlice";
import {
  useGetInactiveAnnouncementsQuery,
  useRestoreAnnouncementMutation,
} from "../../../features/project/modules/announcements/announcementApiSlice";
import {
  selectRestoreSelection,
  setRestoreSelection,
  setUpdated,
} from "../../../features/project/modules/announcements/announcementSlice";
import { selectCurrentProjectUsers } from "../../../features/project/projectSlice";
import AnnouncementDialog from "./components/AnnouncementDialog";
import GeneralAnnouncements from "./GeneralAnnouncements";
import { TitleWidgets } from "../../../components/shared";
import { useAppTourContext } from "../../../components/supportButton/context/context";

function AnnouncementRecycle() {
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(5);

  // Theme
  const darkMode = useSelector(selectTheme);
  // Translations
  const { t: tAnnouncements } = useTranslation("announcements");
  const { t: tGeneral } = useTranslation("general");
  // Dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState({});
  const openDialogFunc = (value) => {
    setMessage(value);
    setOpenDialog(true);
  };

  // Data
  const { idProject, idEnterprise } = useParams();
  const currentProjectUsers = useSelector(selectCurrentProjectUsers);
  const toRestorArray = useSelector(selectRestoreSelection);

  const {
    state: { tourActive },
    setState,
  } = useAppTourContext();

  // Announcements Data
  const {
    data: announcements,
    isSuccess,
    isLoading,
    isFetching,
    refetch,
  } = useGetInactiveAnnouncementsQuery(idProject);
  const [annData, setAnnData] = useState([]);

  const [restoreAnnouncement] = useRestoreAnnouncementMutation();

  const handleRestore = async () => {
    const body = {
      ids: toRestorArray,
    };
    if (toRestorArray.length === 0) {
      PopUpAlert("warning", "Oh!", tAnnouncements("noAnnouncementsRestore"));
      return;
    }
    try {
      const response = await restoreAnnouncement(body).unwrap();
      if (response) {
        // TODO: Create a confirmation delete pop up
        if (toRestorArray.length === annData.length) {
          setAnnData([]);
        }
        dispatch(setUpdated(true));
        dispatch(setRestoreSelection([]));
        refetch();
        PopUpAlert(
          "success",
          "Done!",
          `${tAnnouncements("announcement")} ${tGeneral(
            "recoveredSuccessfully",
          )}`,
        );
      }
    } catch (err) {
      PopUpAlert("error", "Error!", tGeneral("errorMessage"));
      // JSON.stringify(err.data),
      datadogRum.addError({
        message: "Announcements - Restore - Error",
        stack: err.stack ? err.stack : "invalid stack",
        custom: {
          app: "buildpeer-web",
          enterprise: idEnterprise,
          module: "Announcements",
          project: idProject,
        },
        timestamp: Date.now(),
      });
    }
  };

  const avatarGroup = useCallback(
    (rowData) => {
      const group = [];
      if (currentProjectUsers.length > 0) {
        for (let i = 0; i < rowData.length; i += 1) {
          const index = currentProjectUsers.findIndex((item) => {
            return (
              item.id === rowData[i] || item.idProjectDirectory === rowData[i]
            );
          });
          if (currentProjectUsers[index]) {
            group.push({
              id: currentProjectUsers[index].id,
              name: currentProjectUsers[index].name,
              urlAvatar: currentProjectUsers[index].urlAvatar,
            });
          }
        }
      }
      return group;
    },
    [currentProjectUsers],
  );

  const buildUserData = useCallback(
    (id) => {
      return currentProjectUsers.find((user) => user.id === id);
    },
    [currentProjectUsers],
  );

  const [searchValue, setsearchValue] = useState("");
  const customSearchFunction = (value) => {
    setsearchValue(value);
    // if (value === "" || value === null) {
    //   onRefresh();
    // }
  };

  useEffect(() => {
    if (isSuccess && announcements.length > 0) {
      setAnnData(
        announcements.map((announcement) => ({
          ...announcement,
          userData: buildUserData(announcement.createdBy),
          distributionList: avatarGroup(announcement.distributionList),
        })),
      );
    } else {
      refetch();
    }
  }, [isSuccess, announcements, avatarGroup, buildUserData, refetch]);

  useLayoutEffect(() => {
    if (tourActive) {
      setTimeout(() => {
        setState({ run: true, stepIndex: 2 });
      }, 500);
    }
  }, [setState, tourActive]);

  useEffect(() => {
    return () => {
      setState({ run: false, tourActive: false, stepIndex: 0 });
    };
  }, [setState]);

  return (
    <>
      <TitleWidgets
        title={tAnnouncements("recycleBin")}
        actions={[
          {
            name: "recycle",
            icon: tGeneral("restore"),
            onClick: () => handleRestore(),
          },
        ]}
        searchEnabled
        customSearchFunction={customSearchFunction}
        backEnabled
      />
      <Box sx={{ width: "100%", mt: 4 }}>
        <Box>
          <GeneralAnnouncements
            data={annData.filter((obj) => {
              return obj.description
                .toLowerCase()
                .includes(searchValue.toLowerCase());
            })}
            darkMode={darkMode}
            openDialogFunc={openDialogFunc}
            pageSize={pageSize}
            setPageSize={setPageSize}
            enableSelection
            isLoading={isLoading || isFetching}
          />
        </Box>
      </Box>

      <AnnouncementDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        message={message}
      />
    </>
  );
}

export default AnnouncementRecycle;
