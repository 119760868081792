import { Avatar, Box, Card, Typography } from "@mui/material";
import SubjectIcon from "@mui/icons-material/Subject";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  useAnswerQuestionMutation,
  useCommentObjectMutation,
} from "../../features/message/messageApiSlice";
import { MessageBox } from "./MessageBox";
import { MessageReply } from "./MessageReply";

function Question({
  object,
  moduleName,
  title,
  disabled = false,
  canMakeOfficial = false,
  requiredText = false,
  showTitleIcon = false,
}) {
  const [answer, { isLoading: isAnswerLoading }] = useAnswerQuestionMutation();
  const [comment, { isLoading: isCommentLoading }] = useCommentObjectMutation();
  // Translations
  const { t: tRfis } = useTranslation("rfis");
  const { t: tGeneral } = useTranslation("general");

  const titleQuestion = title ?? tGeneral("question");
  const question = useMemo(() => {
    if (object) {
      return object.question;
    }
    return {};
  }, [object]);

  const module = useMemo(() => {
    if (moduleName) {
      return moduleName;
    }
    return {};
  }, [moduleName]);

  const handleAnswer = (message, files) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("idQuestion", question.id);
    formData.append("answer", message);
    formData.append("module", module);
    answer(formData);
  };

  const handleComment = (idObject, message, files) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("idObject", idObject);
    formData.append("comment", message);
    formData.append("module", module);
    comment(formData);
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" columnGap={1} mb="35px">
        {showTitleIcon && <SubjectIcon />}
        <Typography fontSize="1.125rem" fontWeight="bold">
          {titleQuestion}
        </Typography>
      </Box>
      <Box display="flex">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Card sx={{ borderRadius: "100%", p: "2px" }}>
            <Avatar
              src={question?.urlAvatar}
              sx={{ opacity: question?.bActive ? 1 : 0.5 }}
            />
          </Card>
          {(question?.answers?.length > 0 || !disabled) && (
            <Box
              sx={{
                height: disabled ? "calc(87% - 100px)" : "calc(100% - 102px)",
                width: "1px",
                border: "1px solid #F2F2F2",
              }}
            />
          )}
        </Box>
        <Box flex={1}>
          <MessageBox
            createdDate={question?.createdDate}
            createdBy={`${question?.firstName} ${question?.lastName}`}
            files={question?.files}
            text={question?.question}
            bActive={question?.bActive}
            question
          />
          {question?.answers?.map((answer) => (
            <Box key={answer.id}>
              <Box display="flex" position="relative">
                <Box
                  sx={{
                    width: "50px",
                    border: "1px solid #F2F2F2",
                    position: "absolute",
                    top: 20,
                    left: -21,
                  }}
                />
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Card sx={{ borderRadius: "100%", p: "2px" }}>
                    <Avatar
                      src={answer?.urlAvatar}
                      sx={{ opacity: answer?.bActive ? 1 : 0.5 }}
                    />
                  </Card>
                  {(answer.comments.length > 0 || !disabled) && (
                    <Box
                      sx={{
                        height: disabled
                          ? "calc(69% - 103px)"
                          : "calc(100% - 102px)",
                        width: "1px",
                        border: "1px solid #F2F2F2",
                      }}
                    />
                  )}
                </Box>
                <Box flex={1}>
                  <MessageBox
                    createdDate={answer?.createdDate}
                    createdBy={`${answer?.firstName} ${answer?.lastName}`}
                    files={answer?.files}
                    text={answer?.answer}
                    isOfficial={answer.bChecked}
                    canMakeOfficial={canMakeOfficial}
                    id={answer.id}
                    module={module}
                    bActive={answer?.bActive}
                  />
                  {answer.comments.map((comment) => (
                    <Box key={comment.id} display="flex">
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        position="relative"
                      >
                        <Box
                          sx={{
                            width: "30px",
                            border: "1px solid #F2F2F2",
                            position: "absolute",
                            top: "10%",
                            left: "-50%",
                          }}
                        />
                        <Card sx={{ borderRadius: "100%", p: "2px" }}>
                          <Avatar
                            src={comment?.urlAvatar}
                            sx={{ opacity: comment?.bActive ? 1 : 0.5 }}
                          />
                        </Card>
                      </Box>
                      <Box flex={1}>
                        <MessageBox
                          createdDate={comment?.createdDate}
                          createdBy={`${comment?.firstName} ${comment?.lastName}`}
                          files={comment?.files}
                          text={comment?.comment}
                          bActive={comment?.bActive}
                        />
                      </Box>
                    </Box>
                  ))}
                  <Box data-tour={!disabled ? "view-tasks-6" : ""}>
                    {!disabled && (
                      <MessageReply
                        reply={(message, files) =>
                          handleComment(answer.id, message, files)
                        }
                        placeholder={tGeneral("newComment")}
                        isLoading={isCommentLoading}
                        disabled={disabled}
                        requiredText={requiredText}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
          <Box data-tour={!disabled ? "view-rfi-5" : ""} mt={5}>
            {!disabled && (
              <MessageReply
                reply={handleAnswer}
                placeholder={tGeneral("newResponse")}
                isLoading={isAnswerLoading}
                disabled={disabled}
                requiredText={requiredText}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export { Question };
