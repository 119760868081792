import { Close } from "@mui/icons-material";
import {
  Box,
  Card,
  IconButton,
  Modal,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { selectCurrentGuideMe } from "../../../features/project/projectSlice";
import { DistributionList } from "../../shared/DistributionList";
import { useAppTourContext } from "../../supportButton/context/context";
import { Disciplines } from "./Disciplines";

function ModalSettingsDrawings({ isOpen, onClose }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");
  const [tab, setTab] = useState("distributionList");
  const { idProject } = useParams();
  const currentGuideMe = useSelector(selectCurrentGuideMe);
  const {
    state: { tourActive, stepIndex },
    setState,
  } = useAppTourContext();

  useEffect(() => {
    if (
      tourActive &&
      stepIndex === 3 &&
      currentGuideMe === "drawings-settings"
    ) {
      setTab("distributionList");
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }

    if (
      tourActive &&
      stepIndex === 4 &&
      currentGuideMe === "drawings-settings"
    ) {
      setTab("disciplines");
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          height: "80%",
          width: "35%",
          borderRadius: "20px",
          p: "10px 20px",
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
        }}
      >
        <Box display="flex" justifyContent="space-between" mb="10px">
          <Typography fontSize="1.5625rem" fontWeight="600">
            {tGeneral("settings")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Tabs
          value={tab}
          onChange={(_, newValue) => setTab(newValue)}
          sx={{
            display: "flex",
            borderBottom: "1px solid #D7D7D7",
            mb: "10px",
          }}
        >
          <Tab
            sx={{
              flex: 1,
              fontSize: "1.1428rem",
              "&.Mui-selected": {
                color: "black",
                fontWeight: "bold",
              },
              textTransform: "none",
              height: "50px",
            }}
            label={tGeneral("distributionList")}
            value="distributionList"
          />
          <Tab
            sx={{
              flex: 1,
              fontSize: "1.1428rem",
              "&.Mui-selected": {
                color: "black",
                fontWeight: "bold",
              },
              textTransform: "none",
              height: "50px",
            }}
            label={tDrawings("disciplines")}
            value="disciplines"
            data-tour="settings-drawings-4"
          />
        </Tabs>
        {tab === "distributionList" && (
          <DistributionList
            idProject={idProject}
            distributionListName="drawingDistributionList"
            onCancel={onClose}
            moduleName="drawings"
          />
        )}
        {tab === "disciplines" && <Disciplines />}
      </Card>
    </Modal>
  );
}

export { ModalSettingsDrawings };
