export const tour = {
  // topbar
  guideMe: "Guíame",
  support: "Soporte",
  meeting: "Reunión",
  workflow: "Flujo",
  // guide me buttons
  addButtonTour: "Añadir",
  viewButtonTour: "Visualizar",
  editButtonTour: "Editar",
  recycleButtonTour: "Papelera",
  exportButtonTour: "Exportar",
  settingsButtonTour: "Configuración",
  deleteButtonTour: "Eliminar",
  changeRoleButtonTour: "Cambiar role",
  // docs
  docsAddFileButtonTour: "Añadir Archivo",
  docsAddFolderButtonTour: "Añadir Carpeta",
  docsFileInfoButtonTour: "Información de archivo",
  docsMoveToButtonTour: "Mover a",
  docsActionsButtonTour: "Acciones",
  // more
  obligatory: "Obligatorio",
  congratulations: "¡Felicidades!",
  moreTitle: "Más opciones",
  moreDescription: "Aquí podrás encontrar opciones adicionales.",
};
