export const general = {
  addFilter: "Agregar filtro",
  settings: "Configuración",
  restore: "Restaurar",
  location: "Ubicación",
  locations: "Ubicaciones",
  uploadedBy: "Subido por",
  attendance: "Asistencia",
  assignees: "Asignados",
  assigned: "Asignados",
  category: "Categoría",
  priority: "Prioridad",
  createdBy: "Creado por",
  distributionList: "Lista de distribución",
  noUsersSelected: "No hay usuarios seleccionados",
  noAttendanceUsers: "No hay asistentes",
  actions: "Acciones",
  day: "Día",
  month: "Mes",
  year: "Año",
  week: "Semana",
  rename: "Renombrar",
  delete: "Eliminar",
  deleteAll: "Eliminar todo",
  cancel: "Cancelar",
  confirm: "Confirmar",
  edit: "Editar",
  information: "Información",
  comments: "Comentarios",
  takenOn: "Fecha de toma",
  uploadedOn: "Fecha de subida",
  errorMessage: "Ha ocurrido un error, intenta de nuevo más tarde.",
  errorUploadMessage:
    "Los archivos no se pudieron subir, por favor verifica tu conexión a internet e inténtalo de nuevo más tarde.",
  errorDownloadMessage:
    "Los archivos no se pudieron descargar. Por favor, verifica tu conexión a internet e inténtalo de nuevo más tarde.",
  close: "Cerrar",
  save: "Guardar",
  logOut: "Cerrar sesión",
  view: "Ver",
  seeDetails: "Ver Detalles",
  download: "Descargar",
  add: "Añadir",
  update: "Actualizar",
  accept: "Aceptar",
  loading: "Cargando",
  loadingDots: "Cargando...",
  email: "Correo",
  phone: "Teléfono",
  name: "Nombre",
  lastName: "Apellido",
  position: "Puesto",
  enterprise: "Empresa",
  role: "Rol",
  module: "Módulo",
  superAdmin: "Super Admin",
  admin: "Admin",
  viewOnly: "Solo Ver",
  none: "Ninguno",
  invalid: "Inválido",
  search: "Buscar",
  projectSearch: "Buscar en el proyecto",
  open: "Abrir",
  open_action: "Abierto",
  still_open: "Sigue abierto",
  public: "Público",
  private: "Privado",
  privacy: "Privacidad",
  date: "Fecha",
  closedDate: "Fecha de cierre",
  status: "Estado",
  title: "Título",
  draft: "Borrador",
  favorite: "Favorito",
  back: "Atrás",
  configUpdated: "Configuración actualizada exitosamente",
  errorConfigUpdated: "No se pudo actualizar la configuración",
  hour: "Hora",
  selectStatus: "Seleccionar estado",
  publish: "Publicar",
  attendanceConfirmed: "Asistencia confirmada exitosamente",
  errorAttendanceConfirmed: "No se puede confirmar la asistencia",
  copy: "Copiar",
  confirmAttendance: "Confirmar asistencia",
  exportPdf: "Exportar Pdf",
  pdf: "Pdf",
  creator: "Creador",
  creators: "Creadores",
  lastSeen: "Historial de vista",
  lastEdit: "Historial ediciones",
  systemLanguage: "Lenguaje del sistema",
  address: "Dirección",
  street: "Calle",
  number: "Número",
  state: "Estado",
  city: "Ciudad",
  zipCode: "Código postal",
  next: "Siguiente",
  en: "Inglés",
  es: "Español",
  files: "Archivos",
  dateReceived: "Fecha de recibido",
  features: "Características",
  drawings: "Planos",
  drawing: "Plano",
  submittals: "Submittals",
  rfis: "RFIs",
  dailyReports: "Reportes",
  issues: "Problemas",
  photos: "Fotos",
  tasks: "Tareas",
  inspections: "Inspecciones",
  documents: "Documentos",
  meetings: "Reuniones",
  delay: "Retrasos",
  directory: "Directorio",
  hours: "Horas",
  deliveries: "Entregas",
  announcements: "Anuncios",
  user: "Usuario",
  project: "Proyecto",
  projects: "Proyectos",
  subscribe: "Suscribir",
  // ProjectsList
  notFound: "No se encontraron proyectos",
  noProjects: "No se han creado proyectos",
  createButton:
    "Si deseas agregar uno, te invitamos a hacer clic en el botón de crear proyecto para comenzar el proceso",
  createProject: "Crear proyecto",
  // Enterprises Dashboard Layout
  loadingEnterprises: "Cargando empresas...",
  // Drawer Enterprise
  generalDirectory: "Directorio General",
  superAdmins: "Super Admins",
  roles: "Roles",
  manager: "Gerente de proyecto",
  constructionDirector: "Director de Construcción",
  architect: "Arquitecto",
  engineer: "Ingeniero",
  active: "Activo",
  inactive: "Inactivo",
  // Settings
  generalInfo: "Información general",
  changePlan: "Cambiar plan",
  billing: "Facturación",
  paymentMethod: "Método de pago",
  updateProfile: "Actualizar perfil empresa",
  youSureEnterprise:
    "¿Estás seguro de que deseas actualizar el perfil de la empresa con estos datos?",
  // General Info
  enterpriseName: "Nombre de la empresa",
  enterpriseType: "Tipo de empresa",
  enterpriseImage: "Logo de empresa",
  // Information Settings
  success: "Éxito",
  done: "¡Listo!",
  enterpriseUpdated: "La información de la empresa se actualizó con éxito.",
  error: "Error",
  errorBig: "¡Error!",
  errorOccurred: "Se ha producido un error, inténtalo de nuevo más tarde.",
  // Logout Section
  logout: "Cerrar sesión",
  profile: "Perfil",
  // Plan Settings
  currentPlan: "Plan actual",
  per: "Por",
  activeProjects: "Proyectos activos",
  activeUsers: "Usuarios activos",
  annual: "Anual",
  monthly: "Mensual",
  allTheFeatures: "Todas las características",
  unlimitedProjects: "Proyectos ilimitados",
  unlimitedUsers: "Usuarios ilimitados",
  unlimitedStorage: "Almacenamiento ilimitado",
  translationSpanglish: "Traducciones Ingles-Español",
  companyReports: "Informes de la marca de la empresa",
  customizableReports: "Plantillas de reportes personalizadas",
  // DrawerSettings
  home: "Inicio",
  changePassword: "Cambiar contraseña",
  tickets: "Tickets",
  preferences: "Preferencias",
  userManual: "Manual de usuario",
  notifications: "Notificaciones",
  // Profile
  male: "Masculino",
  female: "Femenino",
  editProfile: "Editar perfil",
  // Profile Edit
  firstName: "Nombre",
  birthDate: "Fecha de nacimiento",
  gender: "Género",
  inProcess: "En proceso",
  // Change password
  currentPassword: "Contraseña actual",
  newPassword: "Nueva contraseña",
  repeatPassword: "Repetir contraseña",
  passwordNotice:
    "La contraseña debe tener al menos 1 mayúscula, 1 minúscula, 1 número, 1 carácter especial (_, #, ?, !, @, $, %, ^, &, * o -) y 8 caracteres de largo.",
  passwordYouSure: "¿Estás seguro de que quieres cambiar la contraseña?",
  enterPassword: "Introducir la contraseña",
  changedPassword: "Contraseña actualizada exitosamente",
  // Tickets
  welcomeTickets:
    "¡Bienvenido! Seleccione la categoría del incidente que se encuentra a continuación.",
  mobile: "Móvil",
  browser: "Navegador",
  device: "Dispositivo",
  details: "Detalles",
  send: "Enviar",
  // Preferences
  mode: "Modo",
  dark: "Oscuro",
  light: "Claro",
  exportCvs: "Exportar CVS",
  newSuperAdmin: "Nuevo super admin",
  dashboard: "Escritorio",
  adminPanel: "Panel Admin",
  visibleModules: "Módulos Visibles",
  orderModules: "Orden Módulos",
  // Admin Panel
  projectAdmin: "Administración de proyecto",
  specification: "Especificación",
  specifications: "Especificaciones",
  costCodes: "Códigos de costo",
  connections: "Conexiones",
  projectName: "Nombre de proyecto",
  translation: "Traducción",
  managers: "Gerentes",
  constructionManager: "Gerente de proyecto",
  projectEngineer: "Ingeniero de proyecto",
  editInfo: "Editar información",
  on: "Encendido",
  off: "Apagado",
  addSuperAdmin: "Agregar Super Admin",
  deleteSuperAdmin: "Eliminar Super Admin",
  areYouAdmin:
    "¿Estás seguro de que quieres eliminar este super admin? Al realizar esta acción, no se puede revertir.",
  writeDetail: "Escribe con detalle el error encontrado.",
  dragFiles:
    "Arrastra y suelta archivos aquí, o haz clic para seleccionar archivos",
  dragFile:
    "Arrastra y suelta el archivo aquí, o haz clic para seleccionar el archivo",
  emails: "Emails",
  subject: "Asunto",
  to: "Para",
  createdOn: "Creado En",
  info: "Info",
  move: "Mover",
  favorites: "Favoritos",
  generalInformation: "Información General",
  permissions: "Permisos",
  managePermissions: "Administrar Permisos",
  changeHistory: "Historial de cambios",
  published: "Publicado",
  deleted: "Eliminado",
  noPermission: "No tienes permiso para ver esta página",
  recycleBin: "Papelera",
  not_defined: "No definido",
  closed: "Cerrado",
  no_assinged_users: "No hay usuarios asignados",
  due_date: "Fecha límite",
  closed_date: "Fecha de cierre",
  emptyDataSourceMessage: "No hay registros que mostrar",
  of: "de",
  deletedPermanently: "Eliminado permanentemente",
  deletedSuccessfully: "Eliminado Exitosamente",
  updatedSuccessfully: "Editado Exitosamente",
  createdSuccessfully: "Creado Exitosamente",
  recoveredSuccessfully: "Restaurado Exitosamente",
  closedSuccessfully: "Cerrado Exitosamente",
  cantRestore: "Error al Restaurar",
  cantClose: "Error al Cerrar",
  cantCreate: "Error al Crear",
  cantDelete: "Error al Eliminar",
  cantEdit: "Error al Editar",
  deleteRFI: "¿Estás seguro de que quieres eliminar este RFI?",
  question: "Pregunta",
  startWriting: "Comienza a escribir...",
  additionalInformation: "Información adicional",
  receivedFrom: "Recibido de",
  company: "Compañía",
  cost: "Costo",
  price: "Precio",
  currency: "Moneda",
  mxn: "MXN - PESO MEXICANO",
  usd: "USD - DÓLAR AMERICANO",
  emptyQuestion: "La pregunta no puede estar vacía",
  createDate: "Fecha de creación",
  newResponse: "Nueva respuesta...",
  newComment: "Nuevo comentario...",
  newTopic: "Nuevo tema...",
  topics: "Temas",
  showTranslation: "Mostrar traducción",
  closeTranslation: "Cerrar traducción",
  viewFiles: "Ver archivos",
  images: "Imágenes",
  noImages: "No hay imágenes",
  noDocuments: "No hay documentos",
  file: "Archivo",
  attachFiles: "Adjunta archivos o arrastra y suelta",
  january: "Enero",
  february: "Febrero",
  march: "Marzo",
  april: "Abril",
  may: "Mayo",
  june: "Junio",
  july: "Julio",
  august: "Agosto",
  september: "Septiembre",
  october: "Octubre",
  november: "Noviembre",
  december: "Diciembre",
  noEnteprises: "No hay empresas",
  pendingItems: "Pendientes",
  weather: "Clima",
  todoList: "Mi lista",
  ensureThat:
    "Asegúrese de que su proyecto tenga una dirección para consultar el clima o inténtalo de nuevo más tarde.",
  addItemList: "Agrega actividades a tu lista",
  noAnno: "No tienes anuncios por ver",
  daysLeft: "Días Restantes",
  congratulations: "Felicidades",
  noPending: "No tienes pendientes.",
  noFilterableElements:
    "No se encontraron elementos que cumplan con los criterios de búsqueda.",
  addList: "Agregar actividad",
  activityDesc: "Descripción de la actividad",
  expirationDate: "Fecha de expiración",
  onTime: "A tiempo",
  overdue: "Atrasado",
  areYouTask: "¿Seguro que quieres marcar esta tarea como completada?",
  trashCan: "Papelera",
  newAnnouncement: "Nuevo Anuncio",
  startDate: "Fecha inicio",
  time: "Hora",
  limitDate: "Fecha límite",
  cancelled: "Cancelados",
  operations: "Las operaciones fueron canceladas.",
  task: "Tarea",
  dailyReport: "R. Diario",
  deletedBy: "Eliminado por",
  deletedAt: "Fecha de eliminación",
  publishedDate: "Publicación",
  moved: "Reubicado",
  selectAll: "Seleccionar todo",
  pleaseMakeSureAllDataIsCorrect:
    "Por favor asegúrate que los datos estén correctos.",
  addLocation: "Agregar ubicación",
  new: "Nuevo",
  sendSomeoneComment: "Enviar a @alguien un comentario",
  endDate: "Fecha final",
  recent: "Reciente",
  thisWeek: "Esta semana",
  thisMonth: "Este mes",
  lastWeek: "Última semana",
  projectConfigurationUpdateSuccess: "Configuración de proyecto actualizada",
  description: "Descripción",
  enterValidTitle: "Ingrese un título válido",
  enterValidDescription: "Ingrese una descripción válida",
  enterValidDate: "Ingrese una fecha válida",
  enterValidLocation: "Ingrese una ubicación válida",
  enterValidStatus: "Ingrese un estado válido",
  enterValidPriority: "Ingrese una prioridad válida",
  enterValidCategory: "Ingrese una categoría válida",
  fieldRequired: "Este campo es requerido",
  cantUndone: "Esta accion no se puede deshacer. ",
  users: "Usuarios",
  minutes: "Minutos",
  noOne: "Nadie",
  noComments: "No hay comentarios",
  noTasks: "No hay tareas",
  updated: "Actualizado",
  restored: "Restaurado",
  created: "Creado",
  successfully: "Exitosamente",
  // material table
  labelRowsPerPage: "Filas por página:",
  labelRowsSelect: "filas",
  firstAriaLabel: "Primera página",
  firstTooltip: "Primera página",
  previousAriaLabel: "Página anterior",
  previousTooltip: "Página anterior",
  nextAriaLabel: "Siguiente página",
  nextTooltip: "Siguiente página",
  lastAriaLabel: "Última página",
  lastTooltip: "Última página",
  adminEnabledSuccessfully: "Administrador habilitado exitosamente",
  cantEnableAdmin: "No se pudo habilitar el administrador",
  adminDisabledSuccessfully: "Administrador deshabilitado exitosamente",
  cantDisableAdmin: "No se pudo deshabilitar el administrador",
  emptyFields: "Campos vacíos",
  cantCreateUser: "No se pudo crear el usuario",
  userCreatedSuccessfully: "Usuario creado exitosamente",
  userEditedSuccessfully: "Usuario editado exitosamente",
  cantEditUser: "No se pudo editar el usuario",
  userDeletedSuccessfully: "Usuario eliminado exitosamente",
  cantDeleteUser: "No se pudo eliminar el usuario",
  companyCreatedSuccessfully: "Compañía creada exitosamente",
  cantCreateCompany: "No se pudo crear la compañía",
  totalEnterprises: "Total de empresas",
  totalContacts: "Total de contactos",
  enterpriseRoleUpdated: "Rol de empresa actualizado",
  cantUpdateEnterpriseRole: "No se pudo actualizar el rol de la empresa",
  enterpriseRoleDeleted: "Rol de empresa eliminado",
  cantDeleteEnterpriseRole: "No se pudo eliminar el rol de la empresa",
  newRole: "Nuevo rol",
  cantEditUnclassifiedRole: "No se puede editar el rol sin clasificar",
  cantDeleteUnclassifiedRole: "No se puede eliminar el rol sin clasificar",
  noUsersRole: "No hay usuarios con este rol",
  deleteRole: "Eliminar rol",
  deleteRoleMessage:
    "¿Estás seguro que quieres eliminar este rol? Al realizar esta acción, no se puede revertir.",
  editRole: "Editar rol",
  roleName: "Nombre del rol",
  invalidName: "Nombre inválido",
  cantCreateRole: "No se pudo crear el rol",
  roleCreatedSuccessfully: "Rol creado exitosamente",
  roleEditedSuccessfully: "Rol editado exitosamente",
  cantEditRole: "No se pudo editar el rol",
  addRole: "Agregar rol",
  roleNameEmpty: "El nombre del rol no puede estar vacío",
  projectCreated: "Proyecto creado",
  cantCreateProject: "No se pudo crear el proyecto",
  userName: "Nombre de usuario",
  selectUser: "Seleccionar usuario",
  userAlreadyAdded: "El usuario ya fue agregado",
  newProject: "Nuevo proyecto",
  skip: "Saltar",
  fillRequiredFields: "Por favor llene los campos requeridos",
  exportInfo: "Exportar info",
  deleteImage: "Eliminar imagen",
  clickAddProject: "Haga clic para agregar",
  other: "Otro",
  projectTypeCreated: "Tipo de proyecto creado",
  cantCreateProjectType: "No se pudo crear el tipo de proyecto",
  projectType: "Tipo de proyecto",
  addProjectType: "Agregar tipo de proyecto",
  cover: "portada",
  logo: "logo",
  recommendedSize: "Medidas recomendadas",
  mexico: "México",
  unitedStates: "Estados Unidos",
  guatemala: "Guatemala",
  colombia: "Colombia",
  chile: "Chile",
  argentina: "Argentina",
  projectLimitReached: "Límite de proyectos alcanzado",
  projectsLimitReached: "Límite de proyectos alcanzado",
  projectAddWarning:
    "¿Está seguro de que desea continuar con la creación de un proyecto más? Al realizar esta acción, se cargará un proyecto extra en tu próxima factura.",
  billingInformation: "Datos de facturación",
  enterpriseInfoUpdated: "Información de la empresa actualizada",
  cantUpdateEnterpriseInfo:
    "No se pudo actualizar la información de la empresa",
  reference: "Referencia",
  amount: "Monto",
  paymentDate: "Fecha de pago",
  emailRecipient: "Correo electrónico del destinatario",
  cardNumber: "Número de tarjeta",
  mmaa: "MM/AA",
  addCard: "Agregar tarjeta",
  selectAvatar: "Seleccionar avatar",
  userUpdate: "Actualización de usuario",
  cantUpdateUser: "No se pudo actualizar el usuario",
  avatarUpdateError: "No se pudo actualizar el avatar",
  feelsLike: "Sensación térmica",
  weatherValidation:
    "Asegúrese de que su proyecto tenga un código postal y un país para recuperar el clima o vuelva a intentarlo más tarde",
  clear: "Despejado",
  cloudy: "Nublado",
  mostlyClear: "Mayormente despejado",
  partlyCloudy: "Parcialmente nublado",
  mostlyCloudy: "Mayormente nublado",
  fog: "Niebla",
  lightFog: "Niebla ligera",
  drizzle: "Llovizna",
  rain: "Lluvia",
  lightRain: "Lluvia ligera",
  heavyRain: "Lluvia intensa",
  snow: "Nieve",
  flurries: "Ventisca",
  lightSnow: "Nieve ligera",
  heavySnow: "Nieve intensa",
  freezingDrizzle: "Llovizna helada",
  freezingRain: "Lluvia helada",
  freezingLightRain: "Lluvia helada ligera",
  freezingHeavyRain: "Lluvia helada intensa",
  icePellets: "Aguanieve",
  heavyIcePellets: "Aguanieve intensa",
  lightIcePellets: "Aguanieve ligera",
  thunderstorm: "Tormenta eléctrica",
  Sun: "Dom",
  Mon: "Lun",
  Tue: "Mar",
  Wed: "Mié",
  Thu: "Jue",
  Fri: "Vie",
  Sat: "Sáb",
  cantUpdateConfig: "No se pudo actualizar la configuración",
  drawingsAdded: "Planos agregados",
  modified: "Modificado",
  modification: "Modificación",
  from: "De",
  moveFolderTo: "Mover carpeta a",
  moveFileTo: "Mover archivo a",
  locationCreated: "Ubicación creada",
  cantCreateLocation: "No se pudo crear la ubicación",
  locationUpdated: "Ubicación actualizada",
  cantUpdateLocation: "No se pudo actualizar la ubicación",
  locationDeleted: "Ubicación eliminada",
  cantDeleteLocation: "No se pudo eliminar la ubicación",
  type: "Tipo",
  addType: "Agregar tipo",
  typeAdded: "Tipo añadido exitosamente",
  editType: "Editar tipo",
  typeUpdated: "Tipo actualizado exitosamente",
  deleteType: "Eliminar tipo",
  typeDeleted: "Tipo eliminado exitosamente",
  superLocation: "Super ubicación",
  sureDelete: "¿Estás seguro que quieres eliminar",
  specificationAdded: "Especificación agregada",
  costCodeAdded: "Código de costo agregado",
  specificationUpdated: "Especificación actualizada",
  costCodeUpdated: "Código de costo actualizado",
  specificationDeleted: "Especificación eliminada",
  costCodeDeleted: "Código de costo eliminado",
  addSpecification: "Agregar especificación",
  addCostCode: "Agregar código de costo",
  editSpecification: "Editar especificación",
  editCostCode: "Editar código de costo",
  code: "Código",
  deleteSpecification: "Eliminar especificación",
  deleteCostCode: "Eliminar código de costo",
  deleteGeneric:
    "¿Estás seguro que quieres eliminar este elemento? Al realizar esta acción, no se puede revertir.",
  seen: "Visto",
  create: "Crear",
  rfi: "RFIs",
  meeting: "Reuniones",
  project_directory: "Directorio de proyectos",
  createuser: "Crear usuario",
  deleteAgendaItem: "Eliminar elemento de la agenda",
  deleteAgendaItemMessage:
    "¿Estás seguro que quieres eliminar este elemento de la agenda? Al realizar esta acción, no se puede revertir.",
  viewCurrentFiles: "Ver archivos actuales",
  reportAnIssue: "Reportar un problema",
  message: "Mensaje",
  sendToEmail: "Enviar a correo electrónico",
  sent: "Enviado",
  sentMessage: "El correo electrónico fue enviado satisfactoriamente.",
  timezone: "Zona horaria",
  agenda: "Agenda",
  newPasswordTitle: "Por favor introduzca su nueva contraseña",
  assignTo: "Asignar usuario",
  comment: "Comentario",
  linkTo: "Vincular",
  deliveryDate: "Fecha de entrega",
  responsible: "Responsable",
  invalidEmail: "Email inválido",
  publishedBy: "Publicado por",
  privacyOptions: "Opciones de privacidad",
  person: "Usuario",
  companies: "Compañías",
  onlyFavorites: "Solo Favoritos",
  approvedBy: "Aprobado por",
  approvalDate: "Fecha de aprobación",
  externalContacts: "Contactos Externos",
  retry: "Reintentar",
  generalErrorMessage: "Algo salió mal. Inténtelo de nuevo",
  yesDeleteIt: "Sí, eliminar",
  generate: "Generar",
  videos: "Videos",
  noVideos: "No hay videos",
  in_revision: "En revisión",
  logoutMessage: "¿Estás seguro de cerrar sesión?",
  exportCsv: "Exportar CSV",
  compare: "Comparar",
  approved: "Aprobado",
  comparing: "Comparando",
  more: "Más",
  noCCSelected: "Ningún destinatario en Copia de Carbón (CC) seleccionado",
  and: "y",
  removeSuperAdminTitle: "Quitar rol super admin",
  removeSuperAdminDescription:
    "Esta acción le quita el permiso de super admin de empresa al usuario, no lo elimina.",
  noResults: "Sin resultados",
  noResultsMessage: "Intente usar otros filtros.",
  approvedSuccessfully: "Aprobado satisfactoriamente.",
  assignedProjects: "Proyectos asignados",
  noAssignedProjects: "Sin proyectos asignados",
  userAddedSuccessfully: "Usuario añadido satisfactoriamente",
  noOptions: "Sin opciones",
  noData: "No se han encontrado datos",
  order: "Ordenar",
  changeStatus: "Cambiar status",
  changeStatusContent: "¿Está seguro que desea cambiar el status del usuario?",
  projectSchedule: "Programa",
  cancelPrompt: "¿Está seguro que desea cancelar?",
  select: "Seleccionar",
  sign: "Firmar",
  useExisting: "Usar existente",
  reject: "Rechazar",
  rejectedSuccessfully: "Rechazado satisfactoriamente",
  reviewedBy: "Revisado por:",
  timeAgo: "Hace {{time}} {{unit}}",
  seconds: "Segundos",
  onlyUnread: "Mostrar solo no leídos",
  days: "Días",
  image: "Imagen",
  reviewDate: "Fecha de revisión",
  publicationDate: "Fecha de publicación",
  inspection: "Inspección",
  announcement: "Anuncio",
  updatedProject: "Información del proyecto actualizada exitosamente.",
  draw: "Dibujar",
  history: "Historial",
  templates: "Plantillas",
  useFileName: "Usar nombre del archivo",
  progress: "Progreso",
  bim: "BIM",
  reports: "Reportes",
  report: "Reporte",
  constants: "Constantes",
  importInfoMessage:
    "Aquí puedes importar información de un proyecto para ahorrar tiempo en la creación.",
  importInfo: "Importar info",
  country: "País",
  projectManager: "Administrador de proyecto",
  imported: "Importado",
  importedSuccessfully: "Importado satisfactoriamente",
  createdDate: "Fecha de creación",
  acceptedBy: "Aceptado por",
  acceptedDate: "Fecha de aceptado",
  projectNetwork: "Red de proyectos",
  share: "Compartir",
  shared: "Compartido",
  sharedSuccessfully: "Compartido satisfactoriamente",
  sharedOnly: "Solo compartidos",
  altitude: "Altitud",
  fullscreen: "Pantalla completa",
  metric: "Métrico",
  imperial: "Imperial",
  meters: "Metros",
  feet: "Pies",
  inches: "Pulgadas",
  daily_reports: "Reportes",
  noManager: "Sin encargado",
  noConstructionDirector: "Sin director",
  noArchitect: "Sin arquitecto",
  noProjectEngineer: "Sin ingeniero",
  upload: "Cargar",
  enterprises: "Empresas",
  all: "Todos",
  total: "Total",
  modules: "Módulos",
  noElements:
    "No se han cargado {{moduleName}}. Haz click en el botón de la barra superior para crear uno.",
  sessionDataSent: "Los datos de sesión se han enviado al nuevo usuario",
  editUser: "Editar usuario",
  filters: "Filtros",
  filterBy: "Filtrar por",
  clearFilter: "Limpiar",
  typeToAdd: "Escribe para añadir",
  saveChanges: "Guardar cambios",
  sendEmail: "Enviar correo",
  format: "Formato",
  showResults: "Mostrar resultados",
  activity: "Actividad",
  allProjects: "Todos los proyectos",
  allModules: "Todos los módulos",
  analytics: "Analíticas",
  uploadingQtyFiles: "Subiendo {{qty}} archivos",
  technicalIssues: "Actualmente estamos experimentando problemas técnicos",
  comeBackLater: "Por favor, vuelva en unos minutos.",
  noInternetConnection: "Sin conexión a internet",
  checkConnection: "Por favor, revise su conexión o conéctese a Wi-Fi.",
  charactersLimitError: "Se alcanzó el límite de caracteres",
  maxCharacters: "Máximo {{max}} caracteres",
  sharedBy: "Compartido por",
  ccInstruction:
    "Presiona Enter o ';' después de un correo electrónico válido para agregarlo",
  exportExcel: "Exportar a Excel",
  excelSuccessfullyDownloaded:
    "Tu documento Excel se ha descargado correctamente",
  noRfiSelected: "No haz seleccionado ningún RFI para Exportar.",
  selectRfiToContinue: "Selecciona un RFI para continuar.",
  noSubmittalSelected: "No haz seleccionado ningún Submittal para Exportar.",
  selectSubmittalToContinue: "Selecciona un Submbittal para continuar.",
  noMoreResults: "No hay más resultados",
  projectDescription: "Descripción del Proyecto",
  clickToWrite: "Haz clic aquí para escribir",
  fileUploadMaxSizeWarning:
    "El límite de tamaño para la carga de archivos es de 5GB",
  errorFileUploadSizeTitle: "Uno de los archivos excede el límite de 5GB",
  errorFileUploadSizeDescription:
    "Por favor, revise sus archivos y vuelva a intentarlo con un archivo más pequeño",
  errorBimUploadLimitTitle: "Límite de carga de BIM para el proyecto alcanzado",
  errorBimUploadLimitDescription:
    "Has alcanzado el límite de archivos para este proyecto, por favor contacta al soporte si deseas agregar más.",
  duration: "Duración",
  startsAt: "Comienza en",
  weighting: "Ponderación",
  document: "Documento",
  dependsOn: "Depende de",
  calculate: "Calcular",
  checklist: "Checklist",
  activities: "Actividades",
  weeks: "Semanas",
  disciplines: "Disciplinas",
  required: "Requerido",
  notRequired: "No requerido",
  duplicate: "Duplicar",
  design: "Diseño",
  exportPdfLimitWarning:
    "El número máximo de fotos por reporte es de 50, favor de reducir la selección y volver a intentarlo.",
  exportPdfVideoWarning:
    "Advertencia, los archivos de video no son compatibles con las exportaciones en PDF",
  errorExportPDFMessage:
    "Hubo un error al generar el PDF de exportación, por favor contacte al soporte",
  downloading: "Descargando",
  chatSupport: "Chat de soporte",
  fromDate: "Desde",
  toDate: "Hasta",
  approve: "Aprobar",
  descriptions: "Descripciones",
  continue: "Continuar",
  unavailableEnterprise: "Empresa inhabilitada",
  contactSalesForPayment:
    "Favor de dirigirse a <a href='mailto:ventas@buildpeer.com'>ventas@buildpeer.com</a> para realizar su pago correspondiente",
  showCountResults: "Mostrar {{count}} resultados",
  people: "personas",
  addPeopleToDistributionList: "Agregar personas a tu lista de distribución",
  invalidRoleNameTitle: "Acción inválida",
  addInvalidRoleNameWarning:
    "El nombre del rol Super Admin está reservado para el sistema.",
  editInvalidRoleNameWarning: "El rol Super Admin no puede ser actualizado.",
  deleteInvalidRoleNameWarning: "El rol Super Admin no puede ser eliminado.",
  uploads: "Subidas",
  downloads: "Descargas",
  uploaded: "Subido",
  downloaded: "Descargado",
  uploading: "Subiendo",
  downloading: "Descargando",
};
