import { Add, Check, Close, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  useAddDrawingMutation,
  useAddSetMutation,
  useDeleteSetMutation,
  useGetSetsQuery,
  useUpdateSetMutation,
} from "../../features/drawings";
import { PopUpAlert } from "../PopUpAlert";
import { FilePreviewHorizontalScroll, TooltipIconButton } from "../shared";

function ModalAddDrawing({ isOpen, onClose, onSuccess }) {
  const { idProject } = useParams();

  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");

  const [files, setFiles] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [newSet, setNewSet] = useState("");
  const [editingSet, setEditingSet] = useState(null);

  const { data: sets } = useGetSetsQuery(idProject);
  const [addSet, { isLoading: isAddSetLoading }] = useAddSetMutation();
  const [updateSet, { isLoading: isUpdateSetLoading }] = useUpdateSetMutation();
  const [deleteSet, { isLoading: isDeleteSetLoading }] = useDeleteSetMutation();
  const [
    addDrawing,
    {
      isLoading: isAddDrawingLoading,
      isSuccess: isAddDrawingSuccess,
      isError: isAddDrawingError,
      error: addDrawingError,
    },
  ] = useAddDrawingMutation();
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      drawingDate: null,
      receivedDate: null,
    },
  });

  const handleDeleteDrawing = (file) => {
    setFiles((prev) => prev.filter((f) => f.name !== file.name));
  };

  const handleAddSet = async () => {
    await addSet({ idProject, name: newSet });
    setNewSet("");
    setIsAdding(false);
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file, encodeURIComponent(file.name));
    });
    formData.append("idProject", idProject);
    Object.keys(data).forEach((key) => {
      let value = data[key];
      if (Date.parse(value)) {
        value = moment(value).format("YYYY/MM/DD");
      }
      formData.append(key, value);
    });
    await addDrawing(formData);
  };

  useEffect(() => {
    if (isAddDrawingSuccess) {
      PopUpAlert("success", tGeneral("success"), tGeneral("drawingsAdded"));
      onSuccess();
    }
    if (isAddDrawingError) {
      PopUpAlert("error", tGeneral("error"), addDrawingError?.message);
      onClose();
    }
  }, [isAddDrawingSuccess, isAddDrawingError, addDrawingError]);

  const handleEditSet = (event, idSet) => {
    event.stopPropagation();
    setEditingSet(idSet);
  };

  const handleCancelEdit = (event) => {
    event.stopPropagation();
    setEditingSet(null);
    setNewSet("");
  };

  const handleUpdateSet = async (e) => {
    e.stopPropagation();
    await updateSet({ id: editingSet, name: newSet });
    setEditingSet(null);
    setNewSet("");
  };
  const handleAddingNewSet = (e) => {
    e.stopPropagation();
    setIsAdding(true);
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          width: "30%",
          padding: "32px 40px",
          borderRadius: "17px",
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{ fontSize: "1.5625rem", fontWeight: "600", flex: 1 }}
          >
            {tDrawings("addDrawing")}
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
          <TooltipIconButton
            icon={<Check />}
            type="submit"
            disabled={isAddDrawingLoading}
            label={tGeneral("add")}
            data-tour="add-drawings-6"
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          height="100%"
          rowGap="20px"
        >
          <FilePreviewHorizontalScroll
            files={files}
            onAddPhotos={(value) => setFiles(value)}
            onDelete={handleDeleteDrawing}
            height="200px"
          />
          <Typography fontStyle="italic">
            {tDrawings("fileSizeLimit")}
          </Typography>
          <FormControl>
            <InputLabel>{tDrawings("setName")}</InputLabel>
            <Select
              placeholder={tDrawings("setName")}
              label={tDrawings("setName")}
              required
              onClose={() => {
                setIsAdding(false);
                setEditingSet(null);
                setNewSet("");
              }}
              MenuProps={{ sx: { maxHeight: "300px" } }}
              renderValue={(value) =>
                sets?.find((set) => set.id === value)?.name
              }
              {...register("idSet")}
              data-tour="add-drawings-3"
            >
              {sets?.map((set) =>
                editingSet === set.id ? (
                  <Box display="flex" justifyContent="center">
                    <OutlinedInput
                      fullWidth
                      size="small"
                      sx={{ maxWidth: "99%" }}
                      defaultValue={set.name}
                      onChange={(e) => {
                        e.stopPropagation();
                        setNewSet(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        e.stopPropagation();
                      }}
                      autoFocus={editingSet === set.id}
                      endAdornment={
                        <Box display="flex" columnGap="5px">
                          <IconButton
                            disabled={isUpdateSetLoading}
                            onClick={handleCancelEdit}
                          >
                            <Close />
                          </IconButton>
                          <IconButton
                            disabled={isUpdateSetLoading}
                            color="primary"
                            onClick={handleUpdateSet}
                          >
                            {isUpdateSetLoading ? (
                              <CircularProgress size="24px" color="primary" />
                            ) : (
                              <Check />
                            )}
                          </IconButton>
                        </Box>
                      }
                    />
                  </Box>
                ) : (
                  <MenuItem value={set.id}>
                    <ListItemText primary={set.name} />
                    <IconButton
                      onClick={(event) => handleEditSet(event, set.id)}
                      disabled={isAdding}
                    >
                      <Edit />
                    </IconButton>
                  </MenuItem>
                ),
              )}
              {isAdding ? (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  component="div"
                  sx={{ cursor: "pointer" }}
                  px="3%"
                >
                  <OutlinedInput
                    size="small"
                    fullWidth
                    value={newSet}
                    onClick={(e) => e.stopPropagation()}
                    autoFocus
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(event) => {
                      event.stopPropagation();
                      setNewSet(event.target.value);
                    }}
                    disabled={isAddSetLoading}
                    endAdornment={
                      <Button
                        disabled={isAddSetLoading}
                        onClick={handleAddSet}
                        size="small"
                        variant="contained"
                      >
                        {tGeneral("add")}
                      </Button>
                    }
                  />
                </Box>
              ) : (
                <MenuItem>
                  <Button
                    variant="text"
                    onClick={handleAddingNewSet}
                    fullWidth
                    disabled={editingSet}
                  >
                    <Add />
                    <Typography>{tDrawings("addNewSet")}</Typography>
                  </Button>
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <Controller
            control={control}
            name="drawingDate"
            render={({ field }) => (
              <span
                style={{ flexDirection: "column", display: "inline-flex" }}
                data-tour="add-drawings-4"
              >
                <DatePicker
                  label={tDrawings("drawingDate")}
                  renderInput={(params) => {
                    return (
                      <TextField label={tDrawings("drawingDate")} {...params} />
                    );
                  }}
                  {...field}
                />
              </span>
            )}
          />
          <Controller
            name="receivedDate"
            control={control}
            render={({ field }) => (
              <span
                style={{ flexDirection: "column", display: "inline-flex" }}
                data-tour="add-drawings-5"
              >
                <DatePicker
                  label={tGeneral("dateReceived")}
                  renderInput={(params) => (
                    <TextField label={tGeneral("dateReceived")} {...params} />
                  )}
                  {...field}
                />
              </span>
            )}
          />
        </Box>
      </Card>
    </Modal>
  );
}

export { ModalAddDrawing };
