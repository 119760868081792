import { datadogRum } from "@datadog/browser-rum";
import {
  ArrowBack,
  Comment,
  Delete,
  Edit,
  Link as LinkIcon,
  Person,
  SquareFoot,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import WebViewer from "@pdftron/webviewer";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  DrawingsAnnotationDetails,
  DrawingsToolbar,
  ModalAnnotationLinkModule,
  ModalAnnotationLinkUser,
  ModalCalibrate,
  ModalDeleteAnnotations,
  ModalPublishAnnotations,
} from "../../../components/drawings";
import { useAppTourContext } from "../../../components/supportButton/context/context";
import {
  useCreateAnnotationMutation,
  useGetAnnotationsQuery,
  useUpdateAnnotationMutation,
} from "../../../features/annotations";
import { useGetDrawingsListQuery } from "../../../features/drawings";
import { selectCurrentGuideMe } from "../../../features/project/projectSlice";
import { useGetUserPreferencesQuery } from "../../../features/userPreferences/userPreferencesApiSlice";
import { DrawingsAnnotationList } from "./DrawingsAnnotationList";

function DrawingView({ drawing, onClose }) {
  const { idProject, idDrawing, idEnterprise } = useParams();
  const navigate = useNavigate();

  const viewerRef = useRef(null);

  const { t: tGeneral } = useTranslation("general");
  const { t: tDrawings } = useTranslation("drawings");

  const [expandTools, setExpandTools] = useState(false);
  const [showMeasure, setShowMeasure] = useState(false);
  const [showAnnotations, setShowAnnotations] = useState(true);
  const [selectedRev, setSelectedRev] = useState(null);
  const [instance, setInstance] = useState(null);
  const [selectedAnnotations, setSelectedAnnotations] = useState([]);
  const [showAnnotationList, setShowAnnotationList] = useState(false);
  const [assignUserOpened, setAssignUserOpened] = useState(false);
  const [assignModuleOpened, setAssignModuleOpened] = useState(false);
  const [selectedDrawing, setSelectedDrawing] = useState(null);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [documentLoaded, setDocumentLoaded] = useState(false);
  const [calibrationWidth, setCalibrationWidth] = useState(null);
  const [scale, setScale] = useState(null);
  const [commentAnchorEl, setCommentAnchorEl] = useState(null);

  const currentGuideMe = useSelector(selectCurrentGuideMe);
  const {
    state: { tourActive, stepIndex, steps },
    setState,
  } = useAppTourContext();

  const { data: drawings } = useGetDrawingsListQuery(idProject);
  const { data: annotations } = useGetAnnotationsQuery(selectedRev, {
    skip: !selectedRev,
  });
  const { data: userPreferences } = useGetUserPreferencesQuery();

  const [createAnnotation] = useCreateAnnotationMutation();
  const [updateAnnotation] = useUpdateAnnotationMutation();

  const revisions = useMemo(() => {
    if (selectedDrawing && drawings) {
      return drawings.find((drawing) => drawing.id === selectedDrawing)
        ?.revisions;
    }
    return [];
  }, [selectedDrawing, drawings]);

  useEffect(() => {
    if (drawing) {
      setSelectedDrawing(drawing.id);
    } else {
      setSelectedDrawing(idDrawing);
    }
  }, [drawing, idDrawing]);

  useEffect(() => {
    if (userPreferences && instance) {
      instance.UI.setLanguage(
        userPreferences.translationFeature === "off"
          ? "en"
          : userPreferences.translationFeature,
      );
    }
  }, [userPreferences, instance]);

  useEffect(() => {
    if (
      selectedRev &&
      instance &&
      revisions.length &&
      annotations &&
      createAnnotation &&
      updateAnnotation &&
      setCalibrationWidth
    ) {
      const { annotationManager, documentViewer } = instance.Core;
      const handleAnnotationChanged = async (
        annotations,
        action,
        { imported },
      ) => {
        if (imported) return;
        const xfdfString = await annotationManager.exportAnnotationCommand();
        const annotation = annotations[0];
        if (
          action === "add" &&
          annotation.ToolName !== "AnnotationCreateCalibrationMeasurement"
        ) {
          const body = {
            id: annotation.Id,
            dashes: annotation.Dashes ? Number(annotation.Dashes) : 0,
            annotationObject: xfdfString,
            idObject: selectedRev,
            module: "drawings",
            type: annotation.Subject,
          };
          createAnnotation(body);
        }
        if (action === "modify") {
          const body = {
            id: annotation.Id,
            annotationObject: xfdfString,
          };
          updateAnnotation(body);
        }
        if (
          annotation.ToolName === "AnnotationCreateCalibrationMeasurement" &&
          action === "add"
        ) {
          setCalibrationWidth(annotation.Width);
          setScale(null);
        }
      };
      const handleDocumentLoaded = () => {
        setDocumentLoaded(true);
      };
      documentViewer.addEventListener("documentLoaded", handleDocumentLoaded);
      annotationManager.off("annotationChanged");
      annotationManager.addEventListener(
        "annotationChanged",
        handleAnnotationChanged,
      );
    }
  }, [
    selectedRev,
    revisions,
    instance,
    annotations,
    createAnnotation,
    updateAnnotation,
    setCalibrationWidth,
    documentLoaded,
  ]);

  useEffect(() => {
    if (instance && selectedRev && revisions && selectedDrawing) {
      const currRev = revisions.find((rev) => rev.id === selectedRev);
      if (currRev) {
        setDocumentLoaded(false);
        instance.loadDocument(currRev.pdfFile);
      }
    }
  }, [instance, selectedRev, revisions, selectedDrawing]);

  useEffect(() => {
    if (instance && annotations && documentLoaded) {
      const { annotationManager } = instance.Core;
      annotations.forEach(async (annotation) => {
        const annot = await annotationManager.importAnnotationCommand(
          annotation.annotationObject,
        );
        annotationManager.redrawAnnotation(annot);
      });
    }
  }, [instance, annotations, documentLoaded]);

  useEffect(() => {
    if (revisions?.length) {
      setSelectedRev(revisions[0].id);
    }
  }, [revisions]);

  const currentAnnotations = useMemo(() => {
    if (annotations && selectedAnnotations.length > 0) {
      return annotations.filter(
        (annot) => selectedAnnotations.indexOf(annot.id) > -1,
      );
    }
    return null;
  }, [annotations, selectedAnnotations]);

  const annotationsToPublish = useMemo(() => {
    if (currentAnnotations) {
      return currentAnnotations.filter((annot) => !annot.bPublished);
    }
    return [];
  }, [currentAnnotations]);

  const { showAssign, showLink } = useMemo(() => {
    if (instance && currentAnnotations) {
      const lastAnnotation = currentAnnotations[0];
      const showButtons = { showAssign: false, showLink: false };
      if (!lastAnnotation?.comments.length) {
        showButtons.showAssign = true;
      }
      if (!lastAnnotation?.linkedId) {
        showButtons.showLink = true;
      }
      return showButtons;
    }
    return { showAssign: false, showLink: false };
  }, [instance, currentAnnotations]);

  const handleCalibrate = (width, value, unit) => {
    setScale([
      [width, "pt"],
      [value, unit],
    ]);
  };

  const handleCloseCalibrate = () => {
    const { annotationManager, documentViewer, Tools } = instance.Core;
    const selectedAnnots = annotationManager.getSelectedAnnotations();
    annotationManager.deselectAnnotations(selectedAnnots);
    annotationManager.deleteAnnotations(selectedAnnots);
    documentViewer.setToolMode(documentViewer.getTool(Tools.ToolNames.PAN));
    setCalibrationWidth(null);
  };

  useEffect(() => {
    if (viewerRef.current) {
      WebViewer(
        {
          licenseKey: process.env.REACT_APP_APRYSE_LICENSE_KEY,
          path: "/pdftron",
          enableMeasurement: true,
          disabledElements: ["header", "toolsHeader", "scaleOverlayContainer"],
          css: "/pdfTron.css",
        },
        viewerRef.current,
      )
        .then((newInstance) => {
          const { annotationManager } = newInstance.Core;
          const originalKeyDown =
            newInstance.Core.Tools.AnnotationSelectTool.prototype.keyDown;
          newInstance.Core.Tools.AnnotationSelectTool.prototype.keyDown =
            function (e) {
              originalKeyDown.apply(e, arguments);
            };
          annotationManager.off("annotationSelected");
          annotationManager.addEventListener("annotationSelected", () => {
            const selAnnots = annotationManager.getSelectedAnnotations();
            setSelectedAnnotations(selAnnots.map((annot) => annot.Id));
          });
          setInstance(newInstance);
        })
        .catch((err) => {
          datadogRum.addError({
            message: "Drawings - Error",
            stack: err.stack ? err.stack : "invalid stack",
            custom: {
              app: "buildpeer-web",
              enterprise: idEnterprise,
              module: "Drawings",
              project: idProject,
            },
            timestamp: Date.now(),
          });
        });
    }
  }, [viewerRef, createAnnotation, updateAnnotation, idEnterprise, idProject]);

  const handleSelectDrawing = (e) => {
    setSelectedRev(null);
    setSelectedDrawing(e.target.value);
  };

  const handleShowAnnotations = () => {
    const { annotationManager } = instance.Core;
    const annots = annotationManager.getAnnotationsList();
    if (showAnnotations) {
      annotationManager.hideAnnotations(annots);
    } else {
      annotationManager.showAnnotations(annots);
    }
    setShowAnnotations((prev) => !prev);
  };

  const handleCloseView = () => {
    if (typeof onClose !== "undefined") {
      onClose();
    } else {
      navigate("./../");
    }
  };

  const handleCloseAssign = () => {
    setAssignModuleOpened(false);
    setAssignUserOpened(false);
  };

  const deselectAll = () => {
    setSelectedAnnotations([]);
    const { annotationManager } = instance.Core;
    annotationManager.deselectAllAnnotations();
  };

  const handleClosePublish = () => {
    setIsPublishModalOpen(false);
    deselectAll();
  };

  const handleCloseDelete = () => {
    setIsDeleteModalOpen(false);
    deselectAll();
  };

  const handleDeleteAnnotations = () => {
    const { annotationManager } = instance.Core;
    const annots = annotationManager.getSelectedAnnotations();
    annotationManager.deleteAnnotations(annots);
  };

  const handleMeasure = () => {
    if (!expandTools) {
      setExpandTools(true);
    }
    setShowMeasure(true);
  };

  const handleTools = () => {
    if (!expandTools) {
      setExpandTools(true);
      return;
    }
    if (!showMeasure) {
      setExpandTools(false);
      return;
    }
    setShowMeasure(false);
  };

  const handleSelectRev = (e) => {
    setSelectedRev(e.target.value);
    setDocumentLoaded(false);
  };

  useEffect(() => {
    if (
      drawing &&
      tourActive &&
      (stepIndex === 1 || stepIndex === steps.length - 2) &&
      currentGuideMe === "drawings-view"
    ) {
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, steps, currentGuideMe, drawing, setState]);
  return (
    <Box sx={{ width: "100%" }}>
      <Box display="flex" mb="20px" columnGap="10px">
        <Button variant="text" onClick={handleCloseView}>
          <ArrowBack />
          <Typography> {tGeneral("back")}</Typography>
        </Button>
        <FormControl
          fullWidth
          sx={{ maxWidth: "300px" }}
          data-tour="view-drawings-3"
        >
          <InputLabel shrink>{tGeneral("drawing")}</InputLabel>
          <Select
            label={tGeneral("drawing")}
            value={selectedDrawing || ""}
            onChange={handleSelectDrawing}
            notched
          >
            {drawings?.map((drawing) => (
              <MenuItem key={drawing.id} value={drawing.id}>
                {drawing.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          sx={{ maxWidth: "300px" }}
          data-tour="view-drawings-2"
        >
          <InputLabel shrink>{tDrawings("revision")}</InputLabel>
          <Select
            value={selectedRev || ""}
            label={tDrawings("revision")}
            onChange={handleSelectRev}
            input={<OutlinedInput notched label={tDrawings("revision")} />}
            notched
          >
            {revisions?.map((rev) => (
              <MenuItem key={rev.id} value={rev.id}>
                {tDrawings("revision")} {rev.number}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box flex="1" />
        <Box display="flex" columnGap="10px">
          {selectedAnnotations.length > 0 && (
            <Button
              variant="contained"
              onClick={() => setIsDeleteModalOpen(true)}
            >
              <Delete /> ({selectedAnnotations.length})
            </Button>
          )}
          {annotationsToPublish.length > 0 && (
            <Button
              variant="contained"
              onClick={() => setIsPublishModalOpen(true)}
            >
              {tGeneral("publish")} ({annotationsToPublish.length})
            </Button>
          )}
          {showAssign && (
            <Tooltip title={tGeneral("assignTo")}>
              <Button
                variant="contained"
                onClick={() => setAssignUserOpened(true)}
              >
                <Person />
              </Button>
            </Tooltip>
          )}
          {showLink && (
            <Tooltip title={tGeneral("linkTo")}>
              <Button
                variant="contained"
                onClick={() => setAssignModuleOpened(true)}
              >
                <LinkIcon />
              </Button>
            </Tooltip>
          )}
          {drawings?.find((drawing) => drawing.id === selectedDrawing)
            ?.comment && (
            <Tooltip title={tGeneral("comment")}>
              <Button
                variant="contained"
                onClick={(e) => setCommentAnchorEl(e.currentTarget)}
              >
                <Comment />
              </Button>
            </Tooltip>
          )}
          <Popover
            id="comment-popover"
            open={Boolean(commentAnchorEl)}
            anchorEl={commentAnchorEl}
            onClose={() => setCommentAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box
              maxWidth="300px"
              maxHeight="300px"
              sx={{ p: "0.5rem", overflowY: "auto" }}
            >
              {drawings
                ?.find((drawing) => drawing.id === selectedDrawing)
                ?.comment.split("\n")
                .map((line) => (
                  <Typography key={line}>{line}</Typography>
                ))}
            </Box>
          </Popover>
          <Tooltip title={tDrawings("measure")}>
            <Button variant="contained" onClick={handleMeasure}>
              <SquareFoot />
            </Button>
          </Tooltip>
          <Tooltip
            title={
              showAnnotations
                ? tDrawings("hideAnnotations")
                : tDrawings("showAnnotations")
            }
          >
            <Button
              variant="contained"
              onClick={handleShowAnnotations}
              data-tour="view-drawings-5"
            >
              {showAnnotations ? <Visibility /> : <VisibilityOff />}
            </Button>
          </Tooltip>
          <Tooltip title={tGeneral("edit")}>
            <Button
              variant="contained"
              onClick={handleTools}
              data-tour="view-drawings-4"
            >
              <Edit />
            </Button>
          </Tooltip>
        </Box>
      </Box>
      {instance && (
        <DrawingsToolbar
          webViewerInstance={instance}
          expanded={expandTools}
          showMeasure={showMeasure}
          toggleAnnotationList={() => setShowAnnotationList((prev) => !prev)}
          annotations={annotations}
          isFreeText={
            currentAnnotations?.length === 1 &&
            currentAnnotations?.[0]?.type === "Free Text"
          }
          currentAnnotations={currentAnnotations}
          scale={scale}
        />
      )}

      <Box position="relative">
        {showAnnotationList && (
          <Box position="absolute" width="20vw" height="100%">
            <DrawingsAnnotationList annotations={annotations} />
          </Box>
        )}
        <Box
          sx={{ height: "75vh", width: "100%" }}
          ref={viewerRef}
          className="webviewer"
        />
        {currentAnnotations &&
          (currentAnnotations[0]?.comments.length ||
            currentAnnotations[0]?.linkedId) && (
            <Box
              position="absolute"
              width="35vw"
              height="100%"
              right={0}
              top={0}
            >
              <DrawingsAnnotationDetails
                annotation={currentAnnotations[0]}
                onClose={() => deselectAll()}
                onEditModule={() => setAssignModuleOpened(true)}
                onEditUsers={() => setAssignUserOpened(true)}
              />
            </Box>
          )}
      </Box>
      {assignUserOpened && (
        <ModalAnnotationLinkUser
          isOpen={assignUserOpened}
          onClose={handleCloseAssign}
          annotation={currentAnnotations[0]}
        />
      )}
      {assignModuleOpened && (
        <ModalAnnotationLinkModule
          isOpen={assignModuleOpened}
          onClose={handleCloseAssign}
          annotation={currentAnnotations[0]}
          idDrawing={selectedDrawing}
        />
      )}
      {isPublishModalOpen && (
        <ModalPublishAnnotations
          isOpen={isPublishModalOpen}
          onClose={handleClosePublish}
          annotations={annotationsToPublish}
        />
      )}
      {isDeleteModalOpen && (
        <ModalDeleteAnnotations
          isOpen={isDeleteModalOpen}
          annotations={currentAnnotations}
          eraseAnnotations={handleDeleteAnnotations}
          onClose={handleCloseDelete}
        />
      )}
      {calibrationWidth && (
        <ModalCalibrate
          isOpen={Boolean(calibrationWidth)}
          onClose={handleCloseCalibrate}
          onCalibrate={handleCalibrate}
          width={calibrationWidth}
        />
      )}
    </Box>
  );
}

export { DrawingView };
