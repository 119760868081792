import { tour } from "./tour";

export const drawings = {
  // general tour
  ...tour,
  moduleElement: "Planos",
  exportButtonTour: "Descargar",
  reviewAndPublishButtonTour: "Revisar y publicar",
  // ADD - guide me steps
  step1_addDrawing_title: "1. Añadir",
  step1_addDrawing_text: "Haz click en el ícono de + para agregar un plano.",
  step2_addDrawing_title: "2. Subir archivo",
  step2_addDrawing_text:
    "Elige los archivos que deseas cargar.",
  step3_addDrawing_title: "3. Subiendo archivos",
  step3_addDrawing_text: "Un panel debajo de la pantalla indicará el progreso de carga del archivo, al finalizar se actualizará la tabla.",
  step4_addDrawing_title: "4. Fecha del plano",
  step4_addDrawing_text: "Selecciona la fecha del plano.",
  step5_addDrawing_title: "5. Fecha de recepción",
  step5_addDrawing_text: "Selecciona la fecha de recepción del plano.",
  step6_addDrawing_title: "6. Añadir",
  step6_addDrawing_text: "Haz click para añadir el plano.",
  step_final_addDrawing: "Haz aprendido cómo añadir un plano.",
  // REVIEW & PUBLISH - guide me steps
  step1_reviewAndPublishDrawing_title: "1. Planos cargados",
  step1_reviewAndPublishDrawing_text: "Haz click aquí para ver los planos que ya han sido cargados.",
  step2_reviewAndPublishDrawing_title: "2. ID",
  step2_reviewAndPublishDrawing_text:
    "Selecciona o ingresa el nombre del ID.",
  step3_reviewAndPublishDrawing_title: "3. Nombre del Set",
  step3_reviewAndPublishDrawing_text:
    "Selecciona o ingresa el nombre del set.",
  step4_reviewAndPublishDrawing_title: "4. Disciplina",
  step4_reviewAndPublishDrawing_text:
    "Selecciona o ingresa el nombre de la disciplina.",
  step5_reviewAndPublishDrawing_title: "5. Más opciones",
  step5_reviewAndPublishDrawing_text: "Haz click aquí para ver las opciones.",
  step6_reviewAndPublishDrawing_title: "6. Seleccionar planos",
  step6_reviewAndPublishDrawing_text:
    "Selecciona los planos que deseas publicar.",
  step7_reviewAndPublishDrawing_title: "7. Publicar",
  step7_reviewAndPublishDrawing_text:
    "Haz click para publicar los planos seleccionados.",
  step_final_reviewAndPublishDrawing:
    "Haz aprendido cómo revisar y publicar un plano.",
  // VIEW - guide me steps
  step1_viewDrawing_title: "1. Ver",
  step1_viewDrawing_text: "Haz click para ver el plano.",
  step2_viewDrawing_title: "2. Revisión",
  step2_viewDrawing_text: "Selecciona el número de revisión.",
  step3_viewDrawing_title: "3. Plano",
  step3_viewDrawing_text: "Selecciona si necesitas cambiar de plano.",
  step4_viewDrawing_title: "4. Anotaciones",
  step4_viewDrawing_text: "Haz click para agregar anotaciones.",
  step5_viewDrawing_title: "5. Ver/Ocultar anotaciones",
  step5_viewDrawing_text:
    "Haz click para visualizar u ocultar las anotaciones.",
  step_final_viewDrawing: "Haz aprendido cómo visualizar un plano.",
  // EDIT - guide me steps
  step1_editDrawing_title: "1. Editar",
  step1_editDrawing_text: "Haz click para editar un plano.",
  step2_editDrawing_title: "2. Nombre del plano",
  step2_editDrawing_text: "Modificar nombre del plano.",
  step3_editDrawing_title: "3. Nombre del Set",
  step3_editDrawing_text: "Selecciona o ingresa el nombre del set.",
  step4_editDrawing_title: "4. Disciplina",
  step4_editDrawing_text: "Selecciona la disciplina.",
  step5_editDrawing_title: "5. Privacidad",
  step5_editDrawing_text:
    "Selecciona si el plano sera visible para todos o no.",
  step6_editDrawing_title: "6. Nivel de Privacidad",
  step6_editDrawing_text:
    "Si se activa, se restringirá el acceso a ciertas empresas y personas.",
  step7_editDrawing_title: "7. Guardar",
  step7_editDrawing_text: "Haz click para guardar los cambios.",
  step_final_editDrawing: "Haz aprendido cómo editar un plano.",
  // RECYCLE - guide me steps
  step1_recycleDrawing_title: "1. Más opciones",
  step1_recycleDrawing_text: "Haz click aquí para ver las opciones.",
  step2_recycleDrawing_title: "2. Papelera de planos",
  step2_recycleDrawing_text: "Haz click para visualizar la papelera de planos.",
  step3_recycleDrawing_title: "3. Seleccionar planos",
  step3_recycleDrawing_text: "Selecciona los planos que desees restaurar.",
  step4_recycleDrawing_title: "4. Restaurar",
  step4_recycleDrawing_text:
    "Haz click para restaurar los planos seleccionados.",
  step_final_recycleDrawing: "Haz aprendido cómo restaurar un plano.",
  // DOWNLOAD - guide me steps
  step1_exportDrawing_title: "1. Más acciones",
  step1_exportDrawing_text:
    "Haz click en los 3 puntos y se desplegará un menú con más acciones.",
  step2_exportDrawing_title: "2. Descargar",
  step2_exportDrawing_text: "Haz click para descargar el plano.",
  step3_exportDrawing_title: "3. Descarga alternativa",
  step3_exportDrawing_text:
    "Puedes colocar el cursor sobre la columna #Rev para desplegar la lista de revisiones.",
  step4_exportDrawing_title: "4. Descargar revisión",
  step4_exportDrawing_text: "Haz click sobre el archivo que desees descargar.",
  step_final_exportDrawing: "Haz aprendido cómo descargar un plano.",
  // SETTINGS - guide me steps
  step1_settingsDrawing_title: "1. Más opciones",
  step1_settingsDrawing_text: "Haz click aquí para ver las opciones.",
  step2_settingsDrawing_title: "2. Configuración",
  step2_settingsDrawing_text:
    "Haz click aquí para abrir la ventana de configuración.",
  step3_settingsDrawing_title: "3. Lista de distribución",
  step3_settingsDrawing_text:
    "Agrega o elimina las personas que tendrán acceso de manera predeterminada al modulo de planos.",
  step4_settingsDrawing_title: "4. Disciplinas",
  step4_settingsDrawing_text: "Haz click para acceder al tab de Disciplinas.",
  step5_settingsDrawing_title: "5. Añadir disciplina",
  step5_settingsDrawing_text: "Ingresar el nombre de la disciplina a añadir.",
  step_final_settingsDrawing:
    "Haz aprendido cómo configurar el modulo de planos.",

  addDrawing: "Agregar plano",
  reviewDrawings: "Revisar planos",
  drawingsToCheck: "Por revisar",
  drawingsToPublish: "Por publicar",
  discipline: "Disciplina",
  disciplines: "Disciplinas",
  addNewSet: "Agregar nuevo set",
  setName: "Nombre del set",
  drawingDate: "Fecha del plano",
  deletingDrawingMessage:
    "¿Estás seguro de eliminar el plano? Al realizar esta acción no podrá revertirse",
  deleteDrawing: "Eliminar Plano",
  drawingsDeletedMessage: "Planos eliminados correctamente.",
  drawingsPublishedMessage: "Planos publicados correctamente.",
  drawingsRestoredMessage: "Planos restaurados correctamente.",
  recycleBin: "Basurero de planos",
  drawingName: "Nombre del plano",
  deleteDrawingsMessage: "¿Estás seguro de eliminar estos planos?",
  deleteLastRevision: "Eliminar última revisión",
  revision: "Revisión",
  noDrawingsCreated: "No se han creado planos",
  addDrawingsValidation:
    "Si deseas agregar uno, te invitamos a dar click en el botón Agregar Plano para iniciar el proceso",
  line: "Línea",
  rectangle: "Rectángulo",
  ellipse: "Elipse",
  arrow: "Flecha",
  polyline: "Polilínea",
  annotationPublishedMessage: "Anotación publicada correctamente.",
  freetext: "Texto Libre",
  annotationAssignedMessage: "Anotación asignada correctamente.",
  annotationLinkedMessage: "Anotación enlazada correctamente.",
  approveDrawing: "Aprobar plano",
  drawingId: "ID del plano",
  approve: "Aprobar",
  publishAnnotations: "Publicar anotaciones",
  deleteAnnotations: "Eliminar anotaciones",
  deleteAnnotationsContent: "¿Estás seguro de eliminar ${1} anotaciones?",
  annotationDeletedMessage: "Anotaciones elminadas con éxito",
  drawingsAddedTitle: "Set de planos añadidos",
  drawingsAddedContent:
    "Los planos se han añadido con éxito. Te notificaremos cuando estén cargados en el sistema.",
  reviseDrawings: "Revisar planos",
  area: "Área",
  calibrate: "Calibrar",
  distance: "Distancia",
  includeAnnotations: "Incluir anotaciones",
  excludeAnnotations: "Excluir anotaciones",
  editDrawing: "Editar plano",
  addDiscipline: "Agregar disciplina",
  editDrawingId: "Editar ID del plano",
  editedDrawingMessage: "Plano editado satisfactoriamente.",
  compareWith: "Comparar con",
  selectDrawgingsToCompare: "Selecciona los planos a comparar",
  revisionsDeletedSuccessfully: "Revisiones eliminadas satisfactoriamente",
  links: "Enlaces",
  showAnnotations: "Mostrar anotaciones",
  hideAnnotations: "Ocultar anotaciones",
  measure: "Medir",
  generated: "Generado",
  comparisionGeneratedSuccessfully: "Comparación generada satisfactoriamente",
  setCalibration: "Ajustar calibración",
  calibrateDescription:
    "Todas las medidas en este plano se referirán a la calibración indicada a continuación.",
  calibrationNote: "Nota: Estas medidas no son exactas.",
  calibrateInstructions: "Selecciona 2 puntos en el plano para calibrar",
  fileSizeLimit: "Tamaño máximo de archivos: 10GB (total)",
  uploadingQtyDrawings: "Subiendo {{qty}} planos",
  uploadDrawings: "Subir planos",
  comment: "Comentar",
  commentDrawing: "Agregar un comentario al plano",
  commentPlaceholder: "Escribe un comentario sobre el plano",
  drawingViewer: "Visualizador del plano",
  dwgUploadedTitle: "Subida en proceso",
  dwgUploadedMessage:
    "Tus archivos DWG están siendo convertidos a PDF en este momento, se publicarán en breve para su edición.",
  dwgUploadedAction: "Continuar Navegando",
};
