import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Delete,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MaterialTable from "material-table";
import React, { forwardRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { selectTheme } from "../../../features/preferences/preferencesSlice";
import {
  useGetProjectAdminPanelInfoQuery,
  useUpdateProjectMutation,
} from "../../../features/project/projectApiSlice";
import { useCreatePresignedUrlMutation } from "../../../features/s3/s3ApiSlice";
import { PopUpAlert } from "../../PopUpAlert";
import { IOSSwitch } from "../../shared";
import { useTranslation } from "react-i18next";

function SectionTitle({ title, ...props }) {
  return [
    <Divider key={title + "divider"} />,
    <Typography
      key={title}
      fontSize="1.125rem"
      sx={{
        bgcolor: "#FFC42D",
        display: "inline-block",
        p: "1px 15px",
        borderBottomRightRadius: "10PX",
        mb: "40px",
      }}
      width="auto"
    >
      {title}
    </Typography>,
  ];
}

function UserSelect({ label, values, value, onChange }) {
  return (
    <FormControl fullWidth>
      <InputLabel id="multi-select-label">{label}</InputLabel>
      <Select
        labelId="multi-select-label"
        id="multi-select"
        multiple
        value={value}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) =>
          selected?.map((sel) => `${sel.firstName} ${sel.lastName}`).join(", ")
        }
        onChange={(e) => {
          const {
            target: { value },
          } = e;
          onChange(typeof value === "string" ? value.split(",") : value);
        }}
      >
        {values?.map((val) => (
          <MenuItem key={val.id} value={val}>
            <Checkbox checked={value?.findIndex((v) => v.id === val.id) > -1} />
            <ListItemText primary={`${val.firstName} ${val.lastName}`} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function SectionDataCell({
  label,
  content,
  isEditing,
  value,
  values,
  onChange,
  icons,
  type = "text",
}) {
  const renderInput = () => {
    if (type === "text") {
      return (
        <TextField
          value={value}
          onChange={(e) => onChange(e.target.value)}
          fullWidth
          label={label}
        />
      );
    }

    if (type === "switch") {
      return (
        <Card
          sx={{
            m: 0,
            p: "15px 16px",
            position: "relative",
            overflow: "inherit",
          }}
        >
          <Typography
            fontSize="0.875rem"
            fontWeight="bold"
            top={-10}
            sx={{ position: "absolute" }}
          >
            {label}
          </Typography>
          <Box display="flex" alignItems="center">
            {value ? icons?.trueIcon : icons?.falseIcon}
            <Typography sx={{ ml: "12px" }}>{content}</Typography>
            <Box flex="1" />
            <IOSSwitch
              checked={value}
              onChange={(e) => onChange(e.target.checked)}
            />
          </Box>
        </Card>
      );
    }

    if (type === "select") {
      return (
        <UserSelect
          onChange={onChange}
          label={label}
          value={value}
          values={values}
        />
      );
    }
  };

  return (
    <Box flex={1} sx={{ maxWidth: type === "select" ? "25%" : "100%" }}>
      {isEditing ? (
        renderInput()
      ) : (
        <>
          <Typography fontSize="0.875rem" fontWeight="bold">
            {label}
          </Typography>
          <Typography fontSize="1rem">
            {typeof content === "string"
              ? content
              : content?.join(", ") ?? "N/A"}
          </Typography>
        </>
      )}
    </Box>
  );
}

function FacturaSettings() {
  const { idProject } = useParams();

  const { t: tGeneral } = useTranslation("general");

  const [isEditing, setIsEditing] = React.useState(false);
  const { data: project } = useGetProjectAdminPanelInfoQuery(idProject);
  const [
    updateInformationSettings,
    {
      isLoading: isUpdateProjectLoading,
      isSuccess: isUpdateProjectSuccess,
      isError: isUpdateProjectError,
    },
  ] = useUpdateProjectMutation();

  const [createPresignedUrl] = useCreatePresignedUrlMutation();

  const [newProjectLogo, setNewProjectLogo] = useState(null);
  const [newProjectImage, setNewProjectImage] = useState(null);

  React.useEffect(() => {
    if (isUpdateProjectSuccess) {
      PopUpAlert(
        "success",
        tGeneral("done"),
        tGeneral("enterpriseInfoUpdated"),
      );
      setIsEditing(false);
    }
    if (isUpdateProjectError) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
      setIsEditing(false);
    }
  }, [isUpdateProjectError, isUpdateProjectSuccess]);

  const defaultProjectInfo = React.useMemo(
    () => ({
      projectId: project?.id,
      usersAvailable: project?.usersAvailable,
      projectName: project?.projectName || "",
      idEnterprise: project?.idEnterprise,
      bActive: project?.bActive,
      city: project?.city || "",
      country: project?.country || "",
      projectImage: project?.image || null,
      projectLogo: project?.logo || null,
      state: project?.state || "",
      postalCode: project?.postalCode || "",
      street: project?.street || "",
      streetNumber: project?.streetNumber || "",
      meta: project?.meta,
      architect: project?.architect,
      projectEngineer: project?.projectEngineer,
      constructionDirector: project?.constructionDirector,
      constructionManager: project?.constructionManager,
    }),
    [project],
  );

  React.useEffect(() => {
    setProjectAdminForm(defaultProjectInfo);
  }, [defaultProjectInfo]);

  const [projectAdminForm, setProjectAdminForm] = React.useState({});

  const handleCancelEdit = () => {
    setProjectAdminForm(defaultProjectInfo);
    setIsEditing(false);
  };

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleSave = async () => {
    let filesLogoUrl = null;

    if (newProjectLogo) {
      try {
        let presignedLogoUrl;

        //for await (let file of messageFiles) {
        let bodyLogoFiles = {
          idEnterprise: projectAdminForm.idEnterprise,
          idProject: projectAdminForm.projectId,
          moduleName: "admin panel",
          name: projectAdminForm.projectLogoFile.name,
        };
        presignedLogoUrl = await createPresignedUrl(bodyLogoFiles).unwrap();

        //presignedList.push({presignedUrl, file});

        //presignedList.map(async function (value) {
        fetch(presignedLogoUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: projectAdminForm.projectLogoFile,
        });
        //});

        let filesUrl = presignedLogoUrl.split("?")[0];
        filesLogoUrl = filesUrl;
      } catch (err) {
        console.error(err);
      }
    }

    let filesCoverUrl = null;

    if (newProjectImage) {
      try {
        let presignedCoverUrl;

        //for await (let file of messageFiles) {
        let bodyCoverFiles = {
          idEnterprise: projectAdminForm.idEnterprise,
          idProject: projectAdminForm.projectId,
          moduleName: "admin panel",
          name: projectAdminForm.projectImageFile.name,
        };
        presignedCoverUrl = await createPresignedUrl(bodyCoverFiles).unwrap();

        //presignedList.push({presignedUrl, file});

        //presignedList.map(async function (value) {
        fetch(presignedCoverUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: projectAdminForm.projectImageFile,
        });
        //});

        let filesUrl = presignedCoverUrl.split("?")[0];
        filesCoverUrl = filesUrl;
      } catch (err) {
        console.error(err);
      }
    }

    let body = {
      // PROJECT TABLE
      idProject: projectAdminForm.projectId,
      // usersAvailable: ,
      projectName: projectAdminForm.projectName,
      country: projectAdminForm.country,
      state: projectAdminForm.state,
      city: projectAdminForm.city,
      street: projectAdminForm.street,
      streetNumber: projectAdminForm.streetNumber,
      postalCode: projectAdminForm.postalCode,
      // image: filePathCoverDeleted == true ? null : filesCoverUrl ? filesCoverUrl : projectAdminForm.projectImage,
      idEnterprise: projectAdminForm.idEnterprise,
      bActive: projectAdminForm.bActive,
      // meta: ,

      // PROJECT DIRECTORY TABLE
      architect: projectAdminForm.architect,
      projectEngineer: projectAdminForm.projectEngineer,
      constructionDirector: projectAdminForm.constructionDirector,
      constructionManager: projectAdminForm.projectManager,

      // OTHER
      // idPlan: ,
      // translation: ,
    };

    // const {
    //   projectLogoFile,
    //   projectLogo,
    //   projectImage,
    //   projectImageFile,
    //   ...body
    // } = projectAdminForm;

    if (newProjectImage) {
      // body.image = filePathCoverDeleted == true ? null : filesCoverUrl ? filesCoverUrl : projectInfo.projectImage
      body.image = filesCoverUrl;
    }

    if (newProjectLogo) {
      // body.logo = filePathLogoDeleted == true ? null : filesLogoUrl ? filesLogoUrl : projectInfo.projectLogo
      body.logo = filesLogoUrl;
    }

    await updateInformationSettings(body).unwrap();
  };

  const [data, setData] = useState([]);
  const [openCardDialog, setOpenCardDialog] = useState(false);
  const theme = useTheme();
  const md_measurement = useMediaQuery(theme.breakpoints.up("md"));
  var show_searchbar = md_measurement ? true : false;
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => (
      <Delete {...props} color="error" ref={ref} />
    )),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    // Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const [pageSize, setPageSize] = useState(5);
  const darkMode = useSelector(selectTheme);
  const [columns, setColumns] = useState([
    {
      field: "email",
      title: tGeneral("reference"),
      initialEditValue: "",
      validate: (rowData) =>
        rowData.email !== "" && emailValidator(rowData.email) === 1
          ? true
          : { isValid: false, helperText: "Invalid email" },
      width: "90%",
      headerStyle: { width: "90%" },
    },
    {
      field: "email",
      title: tGeneral("status"),
      initialEditValue: "",
      validate: (rowData) =>
        rowData.email !== "" && emailValidator(rowData.email) === 1
          ? true
          : { isValid: false, helperText: "Invalid email" },
      width: "90%",
      headerStyle: { width: "90%" },
    },
    {
      field: "email",
      title: tGeneral("amount"),
      initialEditValue: "",
      validate: (rowData) =>
        rowData.email !== "" && emailValidator(rowData.email) === 1
          ? true
          : { isValid: false, helperText: "Invalid email" },
      width: "90%",
      headerStyle: { width: "90%" },
    },
    {
      field: "email",
      title: tGeneral("paymentDate"),
      initialEditValue: "",
      validate: (rowData) =>
        rowData.email !== "" && emailValidator(rowData.email) === 1
          ? true
          : { isValid: false, helperText: "Invalid email" },
      width: "90%",
      headerStyle: { width: "90%" },
    },
    {
      // field: "action",
      title: tGeneral("actions"),
      sorting: false,
      render: (rowData) => (
        <ButtonGroup>
          <IconButton
            title="Delete"
            onClick={() => {
              setDeleteEmail(rowData.email);
              setOpenDeleteDialog(true);
            }}
            disabled={rowData.bMain}
            sx={{ color: "red" }}
          >
            <Delete />
          </IconButton>
        </ButtonGroup>
      ),
      width: "10%",
      headerStyle: { width: "10%", textAlign: "end" },
      cellStyle: { textAlign: "end" },
    },
  ]);

  const styles = {
    columnNumHeader: {
      textAlign: "left",
      width: "20%",
      maxWidth: "20%",
      fontWeight: "bold",
      fontSize: "1rem",
    },
    columnNumCell: {
      fontSize: "1rem ",
      textAlign: "left",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "20%",
      maxWidth: "20%",
    },
    columnNameHeader: {
      textAlign: "left",
      width: "100%",
      maxWidth: "100%",
      fontWeight: "bold",
      fontSize: "1rem",
    },
    columnNameCell: {
      fontSize: "1rem",
      textAlign: "left",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
      maxWidth: "100%",
    },
    columnActionHeader: {
      textAlign: "center",
      width: "10%",
      maxWidth: "10%",
      fontWeight: "bold",
      fontSize: "1rem",
    },
    columnActionCell: {
      fontSize: 12,
      textAlign: "center",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "10%",
      maxWidth: "10%",
    },
    iconButton: {
      color: darkMode ? "white" : "black",
      ":hover": {
        color: "#FBCB04",
      },
      m: [0, 0, 0, 0],
    },
    boxStyle: {
      height: "100%",
      width: "100%",
      maxHeight: "90vh",
    },
    boxButton: {
      backgroundColor: "#ffc900",
      padding: 0.5,
      paddingX: 0.7,
      borderRadius: 1,
    },
    addButton: {
      padding: [0.4, 0, 0.4, 0],
      marginBottom: -0.5,
      color: "black",
    },
    dialogTitle: {
      textAlign: "left",
      height: "60px",
      fontWeight: "bold",
    },
    iconButtonClose: {
      position: "absolute",
      right: 8,
      top: 8,
    },
    textField: {
      mx: "auto",
      width: "100%",
      marginTop: 1,
      marginBottom: 1,
    },
    cancelButton: {
      width: "50%",
      color: "black",
      backgroundColor: "white",
      borderColor: "black",
      border: 1,
      ":hover": {
        color: "white",
        backgroundColor: "#b4b4b4",
      },
    },
    saveButton: {
      width: "50%",
      color: "black",
      backgroundColor: "#ffc900",
      ":hover": {
        color: "black",
        backgroundColor: "#d9ab00",
      },
    },
    removeButton: {
      width: "50%",
      color: "black",
      backgroundColor: "#ffc900",
      ":hover": {
        color: "black",
        backgroundColor: "#d9ab00",
      },
    },
  };
  const tableRef = React.createRef();

  const tableLocalization = {
    pagination: {
      labelRowsPerPage: `${tGeneral("labelRowsPerPage")}`,
      labelDisplayedRows: `{from}-{to} ${tGeneral("of")} {count}`,
      labelRowsSelect: `${tGeneral("labelRowsSelect")}`,
      firstAriaLabel: `${tGeneral("firstAriaLabel")}`,
      firstTooltip: `${tGeneral("firstTooltip")}`,
      previousAriaLabel: `${tGeneral("previousAriaLabel")}`,
      previousTooltip: `${tGeneral("previousTooltip")}`,
      nextAriaLabel: `${tGeneral("nextAriaLabel")}`,
      nextTooltip: `${tGeneral("nextTooltip")}`,
      lastAriaLabel: `${tGeneral("lastAriaLabel")}`,
      lastTooltip: `${tGeneral("lastTooltip")}`,
    },
    toolbar: {
      searchPlaceholder: tGeneral("search"),
    },
    body: {
      emptyDataSourceMessage: tGeneral("emptyDataSourceMessage"),
    },
  };

  return (
    <Box>
      <Grid
        container
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Grid item xs={12} md={12} sx={{ paddingRight: 2 }}>
          {/* GENERAL */}
          <SectionTitle title={tGeneral("billingInformation")} />
          <Box display="flex" mb="60px" columnGap="20px">
            {/* Nombre Empresa */}
            <SectionDataCell
              label={tGeneral("emailRecipient")}
              content={projectAdminForm?.projectName}
              value={projectAdminForm.projectName}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({ ...prev, projectName: value }))
              }
              isEditing={isEditing}
            />

            <SectionDataCell
              label={tGeneral("enterpriseName")}
              content={projectAdminForm?.projectName}
              value={projectAdminForm.projectName}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({ ...prev, projectName: value }))
              }
              isEditing={isEditing}
            />

            <SectionDataCell
              label={tGeneral("country")}
              content={projectAdminForm?.projectName}
              value={projectAdminForm.projectName}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({ ...prev, projectName: value }))
              }
              isEditing={isEditing}
            />

            <SectionDataCell
              label={tGeneral("address")}
              content={projectAdminForm?.projectName}
              value={projectAdminForm.projectName}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({ ...prev, projectName: value }))
              }
              isEditing={isEditing}
            />
          </Box>

          <Box display="flex" columnGap="20px">
            {/* street */}
            <SectionDataCell
              label={tGeneral("city")}
              content={projectAdminForm?.street}
              value={projectAdminForm.street}
              isEditing={isEditing}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({ ...prev, street: value }))
              }
            />

            {/* number */}
            <SectionDataCell
              label={tGeneral("state")}
              content={projectAdminForm?.streetNumber}
              value={projectAdminForm.streetNumber}
              isEditing={isEditing}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({
                  ...prev,
                  streetNumber: value,
                }))
              }
            />

            {/* country */}
            <SectionDataCell
              label={tGeneral("zipCode")}
              content={projectAdminForm?.country}
              value={projectAdminForm.country}
              isEditing={isEditing}
              onChange={(value) =>
                setProjectAdminForm((prev) => ({ ...prev, country: value }))
              }
            />
          </Box>
          <Divider sx={{ my: "30px" }} />
          <Box display="flex" justifyContent="flex-end" columnGap="16px">
            {isEditing ? (
              <>
                {/* cancel button */}
                <Button
                  variant="contained"
                  sx={{
                    p: "16px 100px",
                    backgroundColor: "white",
                    color: "black",
                    border: "1px solid black",
                    "&:hover": {
                      backgroundColor: "gray",
                    },
                  }}
                  disabled={isUpdateProjectLoading}
                  onClick={handleCancelEdit}
                >
                  <Typography fontWeight="medium" fontSize="1rem">
                    {tGeneral("cancel")}
                  </Typography>
                </Button>

                {/* Save Button */}
                <Button
                  variant="contained"
                  sx={{ p: "16px 100px" }}
                  onClick={handleSave}
                  disabled={isUpdateProjectLoading}
                >
                  {isUpdateProjectLoading ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <Typography fontWeight="medium" fontSize="1rem">
                      {tGeneral("save")}
                    </Typography>
                  )}
                </Button>
              </>
            ) : (
              // Edit button
              <Button
                variant="contained"
                sx={{ p: "16px 62px" }}
                onClick={() => setIsEditing(true)}
              >
                <Typography fontWeight="medium" fontSize="1rem">
                  {tGeneral("edit")}
                </Typography>
              </Button>
            )}
          </Box>
        </Grid>

        <SectionTitle title="Historial de facturación" />
        <Box sx={{ height: 500, width: "100%" }}>
          <MaterialTable
            icons={tableIcons}
            columns={columns}
            tableRef={tableRef}
            data={data}
            localization={tableLocalization}
            title={
              <Box sx={{ width: "100%" }}>
                <ButtonGroup sx={{ alignItems: "center" }}></ButtonGroup>
              </Box>
            }
            actions={[]}
            options={{
              actionsColumnIndex: -1,
              search: show_searchbar,
              searchFieldVariant: "outlined",
              searchFieldStyle: { width: "450px", height: "35px" },
              rowStyle: { padding: "0 5%" },
              // tableLayout: "fixed",
            }}
            rowsPerPageOptions={[5, 10, 30]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            checkboxSelection
            disableSelectionOnClick
          />
        </Box>
      </Grid>
    </Box>
  );
}

export { FacturaSettings };
