export const announcements = {
  published: "게시됨",
  scheduled: "예약됨",
  newAnnouncement: "새로운 공지",
  announcements: "공지사항",
  announcement: "공지",
  generals: "일반",
  programmed: "프로그램",
  expiration: "만료",
  creator: "작성자",
  repeat: "반복",
  nextPublication: "다음 게시",
  programAnnouncement: "공지 프로그램",
  programAnnouncementNow: "공지를 지금 게시합니다",
  programAnnouncementLater: "공지를 나중에 예약합니다",
  repeatList: "반복 목록",
  description: "설명",
  startDate: "시작 날짜",
  expirationDate: "만료 날짜",
  time: "시간",
  distributionList: "배포 목록",
  never: "안 함",
  everyDay: "매일",
  everyWeek: "매주",
  everyTwoWeeks: "2주마다",
  everyMonth: "매월",
  everyYear: "매년",
  noAnnouncements: "현재 공지가 없습니다",
  addAnnouncement: "공지 추가",
  noAnnouncementsPar: "추가하려면 다음 버튼을 클릭하여 공지를 시작하세요",
  deleteAnnouncement: "공지 삭제",
  confirmDelete: "이 공지를 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다",
  recycleBin: "공지 복구함",
  editAnnouncement: "공지 수정",
  programForLater: "나중에 예약",
  addAnnouncementTitle: "현재 공지가 없습니다",
  addAnnouncementValidation: "추가하려면 추가 버튼을 클릭하세요",
  noAnnouncementsRestore: "복구할 공지가 선택되지 않았습니다",
  expire: "만료",
  step1_addAnnouncement_title: "1. 새 공지 만들기",
  step1_addAnnouncement_text: "새 공지를 추가하려면 + 아이콘을 클릭하세요.",
  step2_addAnnouncement_title: "2. 공지 일정",
  step2_addAnnouncement_text:
    "공지를 즉시 게시할지 나중에 예약할지 선택하세요. 후자를 선택하면 추가 옵션이 표시됩니다.",
  step2_1_addAnnouncement_title: "2.1 반복",
  step2_1_addAnnouncement_text: "공지가 반복될 빈도를 선택하세요.",
  step2_2_addAnnouncement_title: "2.2 시작 날짜",
  step2_2_addAnnouncement_text: "공지가 게시될 날짜를 선택하세요.",
  step2_3_addAnnouncement_title: "2.3 시작 시간",
  step2_3_addAnnouncement_text: "공지가 게시될 시간을 선택하세요.",
  step3_addAnnouncement_title: "3. 설명",
  step3_addAnnouncement_text: "공지 제목을 작성하세요.",
  step4_addAnnouncement_title: "4. 배포 목록",
  step4_addAnnouncement_text: "공지의 알림을 받을 사람을 선택하세요.",
  step5_addAnnouncement_title: "5. 만료 날짜",
  step5_addAnnouncement_text: "공지의 만료 날짜를 선택하세요.",
  step6_addAnnouncement_title: "6. 만료 시간",
  step6_addAnnouncement_text:
    "공지가 만료되어 더 이상 표시되지 않을 시간을 선택하세요.",
  step7_addAnnouncement_title: "7. 추가",
  step7_addAnnouncement_text: "공지를 게시하려면 여기를 클릭하세요.",
  step_final_addAnnouncement: "공지를 만드는 방법을 배웠습니다",
  step1_viewAnnouncement_title: "1. 공지 보기",
  step1_viewAnnouncement_text: "공지 정보를 보려면 여기를 클릭하세요.",
  step2_viewAnnouncement_title: "2. 공지 보기",
  step2_viewAnnouncement_text:
    "팝업 창에서 공지의 설명 및 날짜와 같은 관련 정보를 볼 수 있습니다.",
  step_final_viewAnnouncement: "공지 보는 방법을 배웠습니다",
  step1_editAnnouncement_title: "1. 공지 수정",
  step1_editAnnouncement_text: "공지를 수정하려면 여기를 클릭하세요.",
  step2_editAnnouncement_title: "2. 공지 일정 수정",
  step2_editAnnouncement_text:
    "공지를 즉시 게시할지 나중에 게시할지 수정하세요. 후자를 선택하면 추가 옵션이 표시됩니다.",
  step2_1_editAnnouncement_title: "2.1 반복 수정",
  step2_1_editAnnouncement_text: "공지가 반복될 빈도를 수정하세요.",
  step2_2_editAnnouncement_title: "2.2 시작 날짜 수정",
  step2_2_editAnnouncement_text: "공지가 시작될 날짜를 수정하세요.",
  step2_3_editAnnouncement_title: "2.3 시작 시간 수정",
  step2_3_editAnnouncement_text: "공지가 시작될 시간을 수정하세요.",
  step3_editAnnouncement_title: "3. 설명 수정",
  step3_editAnnouncement_text: "공지 제목을 수정하세요.",
  step4_editAnnouncement_title: "4. 배포 목록 수정",
  step4_editAnnouncement_text: "공지를 볼 수 있는 사람을 수정하세요.",
  step5_editAnnouncement_title: "5. 만료 날짜 수정",
  step5_editAnnouncement_text: "공지의 만료 날짜를 수정하세요.",
  step6_editAnnouncement_title: "6. 만료 시간 수정",
  step6_editAnnouncement_text:
    "공지가 만료되어 더 이상 표시되지 않을 시간을 수정하세요.",
  step7_editAnnouncement_title: "7. 수정",
  step7_editAnnouncement_text: "변경 사항을 저장하려면 여기를 클릭하세요.",
  step_final_editAnnouncement: "공지 수정 방법을 배웠습니다",
  step1_recycleAnnouncement_title: "1. 추가 옵션",
  step1_recycleAnnouncement_text: "더 많은 옵션을 보려면 여기를 클릭하세요.",
  step2_recycleAnnouncement_title: "2. 공지 복구함",
  step2_recycleAnnouncement_text: "공지의 복구함을 보려면 여기를 클릭하세요.",
  step3_recycleAnnouncement_title: "3. 복구함 메뉴",
  step3_recycleAnnouncement_text: "이것은 공지의 복구함 메뉴입니다.",
  step4_recycleAnnouncement_title: "4. 공지 보기",
  step4_recycleAnnouncement_text:
    "복구하려는 공지의 정보를 보려면 여기를 클릭하세요.",
  step5_recycleAnnouncement_title: "5. 공지 선택",
  step5_recycleAnnouncement_text: "복구할 공지를 선택하세요.",
  step6_recycleAnnouncement_title: "6. 선택한 공지 복구",
  step6_recycleAnnouncement_text: "선택한 공지를 복구하려면 여기를 클릭하세요.",
  step_final_recycleAnnouncement:
    "공지 복구함과 상호 작용하는 방법을 배웠습니다",
  guideMe: "안내",
  support: "지원",
};
