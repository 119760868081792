import { Box, Card, CircularProgress, Modal } from "@mui/material";
import WebViewer from "@pdftron/webviewer";
import heic2any from "heic2any";
import { useCallback, useEffect, useRef, useState } from "react";

function ModalFileViewer({ isOpen, onClose, file }) {
  const viewerRef = useRef(null);
  const [instance, setInstance] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [src, setSrc] = useState(null);
  const isImg = Boolean(file?.type?.toLowerCase().match(/image|heic/g));
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (viewerRef.current && !isImg && isModalOpen) {
      WebViewer(
        {
          licenseKey: process.env.REACT_APP_APRYSE_LICENSE_KEY,
          path: "/pdftron",
          showToolbarControl: true,
          showSideWindowControl: false,
          disabledElements: [
            "textSelection",
            "panToolButton",
            "textAnnotationButton",
            "freeTextAnnotationButton",
            "stickyAnnotationButton",
            "calloutAnnotationButton",
            "stampAnnotationButton",
            "measurementToolGroupButton",
            "toolsHeader",
            "leftPanelButton",
            "viewControlsButton",
            "zoomOverlayButton",
            "selectToolButton",
            "ribbons",
            "searchButton",
            "toggleNotesButton",
          ],
        },
        viewerRef.current,
      )
        .then((newInstance) => {
          setInstance(newInstance);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [viewerRef, isImg, isModalOpen]);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const setImgSrc = useCallback(async () => {
    if (file.type.toLowerCase() === "heic") {
      setIsLoading(true);
      const blobImage = await (await fetch(file.url)).blob();
      const heicBlob = await heic2any({
        blob: blobImage,
        toType: "image/jpeg",
        quality: 1,
      });
      const heicSrc = URL.createObjectURL(heicBlob);
      setIsLoading(false);
      setSrc(heicSrc);
    } else {
      setSrc(file.url);
    }
  }, [file]);

  useEffect(() => {
    if (file && instance && !isImg) {
      let extension = "pdf";
      if (file.type) {
        extension = file.type;
      } else if (file.url) {
        const fileUrlArray = file.url.split(".");
        const fileType = fileUrlArray[fileUrlArray.length - 1];
        if (!fileType.match(/\//)) {
          extension = fileType;
        }
      }
      instance.loadDocument(file.url, { extension });
    }
  }, [instance, file, isImg]);

  useEffect(() => {
    if (file && isImg) {
      setImgSrc();
    }
  }, [file, isImg, setImgSrc]);

  const renderContent = useCallback(() => {
    if (isLoading) {
      return <CircularProgress />;
    }

    if (isImg) {
      return <img width="100%" height="100%" src={src} alt={file.name} />;
    }
    return (
      <Box
        ref={viewerRef}
        className="webviewer"
        sx={{ height: "100%", width: "100%" }}
      />
    );
  }, [isLoading, isImg, viewerRef, src, file]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card
        sx={{
          height: "80%",
          width: "41%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {renderContent()}
      </Card>
    </Modal>
  );
}

export { ModalFileViewer };
