// React PDF
import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  rowHeader: {
    flexDirection: "row",
    paddingVertical: 10,
    maxHeight: "15%",
  },
  logo: {
    height: "auto",
    maxWidth: "30%",
    flex: 1,
  },
  headerText: {
    fontSize: 10,
    color: "black",
    flex: 1,
    textAlign: "right",
  },
  row: {
    flexDirection: "row",
    paddingTop: 10,
    height: "75%",
  },
  sideInfo: {
    fontSize: 12,
    flex: 1,
    maxHeight: "100%",
  },
  textTitle: {
    color: "grey",
  },
  textDescription: {
    color: "black",
  },

  text: {
    fontSize: "14px",
  },
  textLabel: {
    fontWeight: "bold",
    fontSize: "12px",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

function ColoredLine({ color }) {
  return (
    <hr
      style={{
        color,
        backgroundColor: color,
        height: 1,
      }}
    />
  );
}

const placeholderLogo =
  "https://placeholderlogo.com/img/placeholder-logo-1.png";

function range(size, startAt) {
  return [...Array(size).keys()].map((i) => i + startAt);
}

function formattedDate(date) {
  const dateObject = new Date(date);
  const newDate = dateObject.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });
  return newDate.replace(",", " at");
}

function ImageToPdf({ images = [], perPage = 0 }) {
  const listItems = [];

  for (
    let currentImage = 0;
    currentImage < images.length;
    currentImage += perPage
  ) {
    listItems.push(
      <Page size="A4" orientation="portrait" style={{ padding: "10px 45px" }}>
        <View
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            marginBottom: "15px",
          }}
        >
          <Image style={styles.logo} src={placeholderLogo} />
          <View>
            <Text style={{ fontWeight: "bold", fontSize: "18px" }}>
              Photos - Buildpeer
            </Text>
            <Text style={styles.text}>Buildpeer - Buildpeer</Text>
            <Text style={styles.text}>Monterrey, Nuevo León, México</Text>
            <Text style={styles.text}>
              {new Date().toLocaleDateString("es-MX", {
                weekday: "long",
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </Text>
          </View>
        </View>
        <ColoredLine />
        <View
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          {range(perPage, currentImage).map((index) => (
            <View
              key={images[index]?.id}
              style={{
                width: perPage === 1 ? "100%" : "calc(50% - 40px)",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Image
                style={{ width: "100%", height: "auto", margin: 10 }}
                src={images[index]?.url}
              />
              <ColoredLine />
              <Text style={styles.text}>Information</Text>
              <Text style={[styles.textLabel, { marginTop: "5px" }]}>
                Take on
              </Text>
              <Text style={styles.text}>
                {formattedDate(images[index]?.dateTaken)}
              </Text>
              <Text style={[styles.textLabel, { marginTop: "10px" }]}>
                Uploaded on
              </Text>
              <Text style={styles.text}>
                {formattedDate(images[index]?.dateUploaded)}
              </Text>
              <Text style={[styles.textLabel, { marginTop: "10px" }]}>
                Uploaded by
              </Text>
              <Text style={styles.text}>{images[index]?.createdBy}</Text>
              <Text style={[styles.textLabel, { marginTop: "10px" }]}>
                Album
              </Text>
              <Text style={styles.text}>
                {images[index]?.albumName ?? "- - -"}
              </Text>
              <Text style={[styles.textLabel, { marginTop: "10px" }]}>
                Location
              </Text>
              <Text style={styles.text}>
                {images[index]?.locationName ?? "- - -"}
              </Text>
            </View>
          ))}
        </View>
      </Page>,
    );
  }
  return <Document>{listItems}</Document>;
}

export { ImageToPdf };
