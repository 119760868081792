export const onboarding = {
  welcome: "欢迎",
  enterpriseInfo: "企业信息",
  features: "特点",
  choosePlan: "选择计划",
  payment: "支付",
  welcomeTitle: "欢迎来到buildpeer",
  welcomeSubtitle: "了解您的项目和享受过程",
  startCreateEnterprise: "创建企业",
  joinProject: "加入项目",
  projectCode: "项目代码",
  createEnterprise: "创建企业",
  officialName: "官方名称",
  enterpriseType: "企业类型",
  welcomeJourney: "欢迎旅程",
  planning: "规划",
  processTracking: "进度追踪",
  documentation: "文档",
  resources: "资源",
  community: "社区",
  management: "管理",
  communication: "沟通",
};
