import { tour } from "./tour";

export const contacts = {
  // general tour
  ...tour,
  moduleElement: "External Contacts",
  // ADD - guide me steps
  step1_addContact_title: "1. New Contact",
  step1_addContact_text: "Click here to create a new contact.",
  step2_addContact_title: "2. Name",
  step2_addContact_text: "Enter the name of the contact.",
  step3_addContact_title: "3. Lastname",
  step3_addContact_text: "Enter the contact's last name(s).",
  step4_addContact_title: "4. Email",
  step4_addContact_text: "Enter the email address of the contact.",
  step5_addContact_title: "5. Phone number",
  step5_addContact_text: "Enter the phone number of the contact.",
  step6_addContact_title: "6. Enterprise",
  step6_addContact_text: "Enter the company to which the contact belongs.",
  step7_addContact_title: "7. Add",
  step7_addContact_text: "Confirm the contact information to add it.",
  step_final_addContact: "You have learned how to add a new contact.",
  // EDIT - guide me steps
  step1_editContact_title: "1. Edit Contact",
  step1_editContact_text: "Click to edit the contact.",
  step2_editContact_title: "2. Name",
  step2_editContact_text: "Edit the name of the contact.",
  step3_editContact_title: "3. Lastname",
  step3_editContact_text: " Edit the last name(s) of the contact.",
  step4_editContact_title: "4. Email",
  step4_editContact_text: "Edit the email of the contact.",
  step5_editContact_title: "5. Phone number",
  step5_editContact_text: "Edit the phone number of the contact.",
  step6_editContact_title: "6. Company",
  step6_editContact_text: "Edit the company to which the contact belongs.",
  step7_editContact_title: "7. Confirm",
  step7_editContact_text: "Confirm the changes made to the contact.",
  step_final_editContact: "You have learned how to edit a contact.",
  // DELETE - guide me steps
  step1_deleteContact_title: "1. Delete Contact",
  step1_deleteContact_text: "Click here to delete a contact.",
  step2_deleteContact_title: "2. Confirm Deletion",
  step2_deleteContact_text:
    "Click here to confirm the deletion of the contact.",
  step_final_deleteContact: "You have learned how to delete a contact.",

  externalContacts: "External Contacts",
  addContact: "Add Contact",
  editContact: "Edit Contact",
  loadingError: "There was an issue loading external contacts",
  createContactSuccess: "Contact created",
  updateContactSuccess: "Contact updated",
  deleteContactSuccess: "Contact deleted",
  deleteContactConfirmation: "Are you sure you want to delete this contact?",
  noContactsCreated: "There are no contacts at this moment",
  noContactsContent:
    "If you want to create one, you can click the button below",
};
