import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";

import animationData from "../../../projectDashboard/16627-firework.json";

const useInspectionTemplatesSteps = () => {
  const { t: translation } = useTranslation("inspectionsTemplates");

  const editSteps = [
    {
      target: "[data-tour='inspection-templates-edit-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_inspectionTemplatesEdit_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_inspectionTemplatesEdit_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='inspection-templates-edit-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_inspectionTemplatesEdit_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_inspectionTemplatesEdit_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='add-inspection-templates-8']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_inspectionTemplatesEdit_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_inspectionTemplatesEdit_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("step_final_inspectionTemplatesEdit")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  return [
    {
      editSteps,
    },
  ];
};

export { useInspectionTemplatesSteps };
