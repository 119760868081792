import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  useAddTypeMutation,
  useUpdateTypeMutation,
} from "../../../features/project/typeApiSlice";
import { PopUpAlert } from "../../PopUpAlert";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { Close } from "@mui/icons-material";

function TypeModal({ isOpen, onClose, selectedtType = null }) {
  const { idProject } = useParams();

  const { t: tGeneral } = useTranslation("general");

  const [name, setName] = useState("");

  const [newType] = useAddTypeMutation();
  const [updateType] = useUpdateTypeMutation();

  const styles = {
    dialogTitle: {
      textAlign: "left",
      height: "60px",
      fontWeight: "bold",
    },
    iconButtonClose: {
      position: "absolute",
      right: 8,
      top: 8,
    },
    textField: {
      mx: "auto",
      width: "100%",
      mb: 1,
    },
    cancelButton: {
      width: "50%",
      color: "black",
      backgroundColor: "white",
      borderColor: "black",
      border: 1,
      ":hover": {
        color: "white",
        backgroundColor: "#b4b4b4",
      },
    },
    saveButton: {
      width: "50%",
      color: "black",
      backgroundColor: "#ffc900",
      ":hover": {
        color: "black",
        backgroundColor: "#d9ab00",
      },
    },
  };

  useEffect(() => {
    if (selectedtType) {
      setName(selectedtType.name);
    } else {
      setName("");
    }
  }, [selectedtType, isOpen]);

  function resetVars() {
    onClose();
    setName("");
  }

  const onSubmit = async (e) => {
    let body = {
      idProject,
      name,
    };
    try {
      let response;
      if (selectedtType) {
        body.idType = selectedtType.id;
        response = await updateType(body).unwrap();
      } else {
        response = await newType(body).unwrap();
      }

      if (response) {
        PopUpAlert(
          "success",
          tGeneral("success"),
          selectedtType ? tGeneral("typeUpdated") : tGeneral("typeAdded"),
        );
        resetVars();
      }
    } catch (err) {
      PopUpAlert("error", tGeneral("error"), tGeneral("errorMessage"));
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={(e) => resetVars()}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{ mx: "auto" }}
      PaperProps={{ sx: { borderRadius: "10px" } }}
    >
      <DialogTitle
        id="scroll-dialog-title"
        sx={[
          styles.dialogTitle,
          { padding: "16px 40px" },
          { marginBottom: "10px" },
        ]}
      >
        {selectedtType ? tGeneral("editType") : tGeneral("addType")}
        <IconButton
          aria-label="close"
          onClick={(e) => resetVars()}
          sx={[styles.iconButtonClose, { marginRight: "20px" }]}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={[{ mx: "auto" }, { padding: "0px 40px 10px 40px" }]}>
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={-1}
          sx={{ mx: "auto" }}
        >
          <TextField
            id="defaultNameTextField"
            variant="outlined"
            type="text"
            label={tGeneral("name")}
            required
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            sx={[styles.textField, { marginY: "20px" }]}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={[
          { marginBottom: "10px" },
          { marginRight: "30px" },
          { marginLeft: "30px" },
        ]}
      >
        <Button
          sx={styles.cancelButton}
          type="submit"
          variant="contained"
          size="large"
          onClick={(e) => resetVars()}
        >
          {tGeneral("cancel")}
        </Button>
        <Button
          sx={styles.saveButton}
          type="submit"
          variant="contained"
          size="large"
          disabled={name.length === 0}
          onClick={onSubmit}
        >
          {selectedtType ? tGeneral("save") : tGeneral("add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { TypeModal };
