import { apiSlice } from "../../api/apiSlice";
const baseQuery = "/project/types";

export const typeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTypes: builder.query({
      query: (idProject) => `${baseQuery}/getTypes/${idProject}`,
      keepUnusedDataFor: 60,
      providesTags: ["Types"],
    }),
    addType: builder.mutation({
      query: (body) => ({
        url: `${baseQuery}/addType`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Types"],
    }),
    updateType: builder.mutation({
      query: (body) => ({
        url: `${baseQuery}/updateType`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Types"],
    }),
    deleteType: builder.mutation({
      query: (body) => ({
        url: `${baseQuery}/deleteType`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Types"],
    }),
  }),
});

export const {
  useGetTypesQuery,
  useAddTypeMutation,
  useUpdateTypeMutation,
  useDeleteTypeMutation,
} = typeApiSlice;
