import { useTranslation } from "react-i18next";
import {
  Box,
  CircularProgress,
  Divider,
  Drawer,
  Typography,
  useMediaQuery,
} from "@mui/material";
import JoyRide from "../../../assets/joyride.svg";
import NoActivity from "../../../assets/noActivity.svg";
import { useGetUserLatestActivityQuery } from "../../../features/project/modules/directory/projectDirectoryApiSlice";
import moment from "moment";

function LatestActivityDrawerContent({ setOpen, user }) {
  const isMobile = useMediaQuery("(max-width:820px)");
  const { t: tGeneral } = useTranslation("general");
  const { t: tProjectDirectory } = useTranslation("projectDirectory");

  const { data: userActivity, isLoading: isActivityLoading } =
    useGetUserLatestActivityQuery(
      { idProject: user?.idProject, idUser: user?.id },
      {
        skip: !user?.id || !user?.idProject,
      },
    );

  const handleClose = () => {
    setOpen(false);
  };

  function calculateDateDiff(first, second) {
    const diffInMs = new Date(second) - new Date(first);
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    return Math.floor(diffInDays);
  }

  if (isActivityLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={500}
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{ width: 500, padding: 5 }}
      role="presentation"
      onClick={handleClose}
      onKeyDown={handleClose}
    >
      <span data-tour="view-user-2">
        <Typography style={styles.title}>
          {tProjectDirectory("lastLogin")}
        </Typography>
        <Box
          display="flex"
          columnGap="1rem"
          justifyContent="flex-start"
          flex={1}
        >
          <Typography style={styles.text}>
            {moment(userActivity?.lastLogin[0]?.createdAt).format(
              "DD MMMM YYYY",
            )}
          </Typography>
          <Typography style={styles.text}>
            {moment(userActivity?.lastLogin[0]?.createdAt).format("hh:mm A")}
          </Typography>
        </Box>
        {userActivity?.lastLogin?.length < 1 ? (
          <Typography style={styles.bigText}>
            {user?.firstName + tProjectDirectory("hasNeverLoggedIn")}
          </Typography>
        ) : (
          <Typography style={styles.bigText}>
            {calculateDateDiff(
              userActivity?.lastLogin[0]?.createdAt,
              new Date(),
            ) < 1
              ? user?.firstName + tProjectDirectory("wasConnectedToday")
              : user?.firstName +
                tProjectDirectory("infoText") +
                calculateDateDiff(
                  userActivity?.lastLogin[0]?.createdAt,
                  new Date(),
                ) +
                tProjectDirectory("days")}
          </Typography>
        )}
        {calculateDateDiff(userActivity?.lastLogin[0]?.createdAt, new Date()) <
        1 ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={JoyRide} style={{ width: isMobile ? "90%" : "100%" }} />
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={NoActivity}
                style={{ width: isMobile ? "90%" : "100%" }}
              />
            </Box>
          </>
        )}
      </span>
      <span data-tour="view-user-3">
        <Typography style={styles.title}>
          {tProjectDirectory("latestActivity")}
        </Typography>
        <Divider />
        {userActivity?.latestActivity?.map((action, index) => (
          <Box
            key={index}
            sx={{ ...styles.textBox, marginTop: 1 }}
            role="presentation"
          >
            <Typography style={styles.text} sx={{ flex: 1 }}>
              {tGeneral(action.action.replace(/\s+/g, "")) +
                " " +
                tGeneral(action.module.replace(/\s+/g, ""))}
            </Typography>
            <Box
              display="flex"
              columnGap="1rem"
              justifyContent="space-evenly"
              flex={1}
            >
              <Typography style={styles.text}>
                {moment(action?.createdAt).format("DD MMMM YYYY")}
              </Typography>
              <Typography style={styles.text}>
                {moment(action?.createdAt).format("hh:mm A")}
              </Typography>
            </Box>
          </Box>
        ))}
        <Divider />
      </span>
    </Box>
  );
}

function LatestActivityDrawer({ open, setOpen, user }) {
  return (
    <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
      <LatestActivityDrawerContent setOpen={setOpen} user={user} />
    </Drawer>
  );
}

const styles = {
  logoContainer: {
    backgroundColor: "blue",
  },
  logo: {
    margin: 2,
    width: "100%",
    height: "auto",
    resizeMode: "contain",
    justifyContent: "center",
  },
  drawerSection: {
    marginTop: 30,
  },
  preference: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 12,
    paddingHorizontal: 16,
  },
  title: {
    fontWeight: "bold",
    fontSize: 25,
    margin: 10,
  },
  textBox: {
    display: "flex",
    width: "100%",
    alignContent: "center",
  },
  text: {
    fontSize: 14,
    marginLeft: 10,
    marginBottom: 10,
  },
  dateTitle: {
    color: "gray",
    fontSize: 15,
    marginLeft: 10,
    marginBottom: 10,
  },
  bigText: {
    color: "gray",
    fontSize: 29,
    textAlign: "center",
    margin: 10,
    marginLeft: 20,
    marginRight: 20,
  },
};

export { LatestActivityDrawer };
