import { tour } from "./tour";

export const meetings = {
  ...tour,
  moduleElement: "회의",
  step1_addMeeting_title: "1. 회의 추가",
  step1_addMeeting_text: "새로운 회의를 추가하려면 + 아이콘을 클릭하세요.",
  step2_addMeeting_title: "2. 제목",
  step2_addMeeting_text: "회의의 제목을 작성하세요.",
  step3_addMeeting_title: "3. 공개 여부",
  step3_addMeeting_text: "회의가 모두에게 표시되는지 여부를 선택하세요.",
  step3_addMeeting_text_2:
    "모듈에 액세스 권한이 있는 모든 사람이 회의를 볼 수 있습니다.",
  step3_addMeeting_text_3: "참가자만이 이 회의를 볼 수 있습니다.",
  step4_addMeeting_title: "4. 회의 날짜",
  step4_addMeeting_text: "회의의 날짜와 시간을 설정하세요.",
  step5_addMeeting_title: "5. 장소",
  step5_addMeeting_text: "회의의 장소를 결정하세요.",
  step6_addMeeting_title: "6. 회의 URL",
  step6_addMeeting_text: "회의의 URL을 여기에 입력하세요.",
  step7_addMeeting_title: "7. 상태",
  step7_addMeeting_text_1_1: "상태 선택",
  step7_addMeeting_text_1_2: "참여자에게 공개된 상태",
  step7_addMeeting_text_2_1: "상태 선택",
  step7_addMeeting_text_2_2: "임시 저장된 상태",
  step8_addMeeting_title: "8. 참석자",
  step8_addMeeting_text: "회의에 필요한 사람들을 선택하세요.",
  step9_addMeeting_title: "9. 배포 목록",
  step9_addMeeting_text: "회의 알림을 받을 사람들을 선택하세요.",
  step10_addMeeting_title: "10. 새 항목",
  step10_addMeeting_text: "회의에 새 항목을 추가하세요.",
  step11_addMeeting_title: "11. 항목 이름",
  step11_addMeeting_text: "항목의 이름을 작성하세요.",
  step12_addMeeting_title: "12. 논의할 주제",
  step12_addMeeting_text: "회의 항목에서 논의할 주제를 작성하세요.",
  step13_addMeeting_title: "13. 시간",
  step13_addMeeting_text: "항목의 예상 시간을 결정하세요.",
  step14_addMeeting_title: "14. 파일",
  step14_addMeeting_text: "회의와 관련된 파일을 업로드하세요.",
  step15_addMeeting_title: "15. 추가",
  step15_addMeeting_text: "새 항목을 확인하려면 클릭하세요.",
  step16_addMeeting_title: "16. 생성",
  step16_addMeeting_text: "회의를 생성하려면 여기를 클릭하세요.",
  step_final_addMeeting: "회의를 생성하는 방법을 배웠습니다.",
  // VIEW - guide me steps
  step1_viewMeeting_title: "1. 회의 보기",
  step1_viewMeeting_text: "회의를 열려면 클릭하세요.",
  step2_viewMeeting_title: "2. 정보",
  step2_viewMeeting_text: "이 섹션에서 회의 요약의 주요 정보를 볼 수 있습니다.",
  step3_viewMeeting_title: "3. URL",
  step3_viewMeeting_text:
    "URL을 열거나 오른쪽 아이콘을 클릭하여 클립 보드에 복사하세요.",
  step4_viewMeeting_title: "4. 안건",
  step4_viewMeeting_text: "이 섹션에는 안건의 정보가 포함되어 있습니다.",
  step5_viewMeeting_title: "5. 항목",
  step5_viewMeeting_text: "항목을 클릭하여 내용을 볼 수 있습니다.",
  step6_viewMeeting_title: "6. 주제",
  step6_viewMeeting_text: "주제를 클릭하여 내용을 볼 수 있습니다.",
  step7_viewMeeting_title: "7. 댓글",
  step7_viewMeeting_text: "이 섹션에서 주제에 대한 댓글을 추가할 수 있습니다.",
  step8_viewMeeting_title: "8. 작업",
  step8_viewMeeting_text: "주제 아래에 생성된 작업이 여기에 위치합니다.",
  step_final_viewMeeting: "회의 정보를 보는 방법을 배웠습니다.",
  // EDIT - guide me steps
  step1_editMeeting_title: "1. 회의 편집",
  step1_editMeeting_text: "회의를 편집하려면 여기를 클릭하세요.",
  step2_editMeeting_title: "2. 제목",
  step2_editMeeting_text: "회의의 제목을 편집하세요.",
  step3_editMeeting_title: "3. 공개 여부",
  step3_editMeeting_text: "회의의 공개 여부를 편집하세요.",
  step3_editMeeting_text_2:
    "모듈에 액세스 권한이 있는 모든 사람이 회의를 볼 수 있습니다.",
  step3_editMeeting_text_3: "참가자만이 이 회의를 볼 수 있습니다.",
  step4_editMeeting_title: "4. 회의 날짜",
  step4_editMeeting_text: "회의의 날짜와 시간을 편집하세요.",
  step5_editMeeting_title: "5. 장소",
  step5_editMeeting_text: "회의의 장소를 편집하세요.",
  step6_editMeeting_title: "6. 회의 URL",
  step6_editMeeting_text: "회의의 URL을 편집하세요.",
  step7_editMeeting_title: "7. 회의 상태",
  step7_editMeeting_text_1_1: "상태 선택",
  step7_editMeeting_text_1_2: "참여자에게 공개된 상태",
  step7_editMeeting_text_2_1: "상태 선택",
  step7_editMeeting_text_2_2: "임시 저장된 상태",
  step8_editMeeting_title: "8. 참석자",
  step8_editMeeting_text: "회의에 필요한 사람들을 추가 또는 제거하세요.",
  step9_editMeeting_title: "9. 배포 목록",
  step9_editMeeting_text: "회의 알림을 받을 사람들을 편집하세요.",
  step10_editMeeting_title: "10. 항목 이름",
  step10_editMeeting_text: "항목의 이름을 편집하세요.",
  step11_editMeeting_title: "11. 논의할 주제",
  step11_editMeeting_text: "항목의 논의할 주제를 편집하세요.",
  step12_editMeeting_title: "12. 예상 시간",
  step12_editMeeting_text: "항목의 예상 시간을 편집하세요.",
  step13_editMeeting_title: "13. 파일",
  step13_editMeeting_text: "회의와 관련된 파일을 편집 또는 추가하세요.",
  step14_editMeeting_title: "14. 새 항목",
  step14_editMeeting_text: "회의에 새 항목을 추가하세요.",
  step15_editMeeting_title: "15. 저장",
  step15_editMeeting_text: "회의 변경 사항을 저장하려면 여기를 클릭하세요.",
  step_final_editMeeting: "회의를 편집하는 방법을 배웠습니다.",
  // RECYCLE - guide me steps
  step1_recycleMeeting_title: "2. 회의 휴지통",
  step1_recycleMeeting_text: "회의 휴지통을 열려면 여기를 클릭하세요.",
  step2_recycleMeeting_title: "3. 휴지통 메뉴",
  step2_recycleMeeting_text: "이것은 회의 휴지통 메뉴입니다.",
  step3_recycleMeeting_title: "4. 회의 보기",
  step3_recycleMeeting_text:
    "복원하려는 회의의 정보를 보려면 여기를 클릭하세요.",
  step4_recycleMeeting_title: "5. 회의 선택",
  step4_recycleMeeting_text: "복원하려는 회의를 선택하세요.",
  step5_recycleMeeting_title: "6. 선택한 회의 복원",
  step5_recycleMeeting_text: "선택한 회의를 복원하려면 여기를 클릭하세요.",
  step_final_recycleMeeting: "회의 휴지통과 상호 작용하는 방법을 배웠습니다.",
  // SETTINGS - guide me steps
  step1_settingsMeeting_title: "2. 설정 메뉴 열기",
  step1_settingsMeeting_text: "설정 메뉴를 열려면 여기를 클릭하세요.",
  step2_settingsMeeting_title: "3. 배포 목록",
  step2_settingsMeeting_text:
    "회의 모듈에 기본 액세스 권한이 있는 사람들을 추가하거나 제거하세요.",
  step3_settingsMeeting_title: "4. 저장",
  step3_settingsMeeting_text: "설정을 저장하려면 여기를 클릭하세요.",
  step_final_settingsMeeting: "설정을 조정하는 방법을 배웠습니다.",
  // EXPORT - guide me steps
  step1_exportMeeting_title: "1. 회의 내보내기",
  step1_exportMeeting_text:
    "이메일을 통해 회의 정보를 내보내려면 여기를 클릭하세요.",
  step_final_exportMeeting: "회의를 내보내는 방법을 배웠습니다.",

  meetings: "회의",
  addMeeting: "회의 일정 추가",
  deleteMeeting: "회의 삭제",
  messageDelete:
    "선택한 회의를 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.",
  meetingDeleted: "회의가 성공적으로 삭제되었습니다.",
  errorMeetingDeleted: "회의를 삭제할 수 없습니다.",
  meetingRecovered: "회의가 성공적으로 복원되었습니다.",
  errorMeetingRecovered: "회의를 복원할 수 없습니다.",
  meetingsRecycleBin: "회의 휴지통",
  meetingCreated: "회의가 성공적으로 생성되었습니다.",
  errorMeetingCreated: "회의를 생성할 수 없습니다.",
  meetingUpdated: "회의가 성공적으로 업데이트되었습니다.",
  errorMeetingUpdated: "회의를 업데이트할 수 없습니다.",
  editMeeting: "회의 편집",
  urlMeeting: "회의 URL",
  agenda: "안건",
  meetingStarted: "회의가 성공적으로 시작되었습니다.",
  errorMeetingStarted: "현재 회의를 시작할 수 없습니다.",
  meetingClosed: "회의가 성공적으로 종료되었습니다.",
  errorMeetingClosed: "회의를 종료할 수 없습니다.",
  startMeeting: "회의 시작",
  noAgendaItems: "안건 없음",
  closeMeeting: "회의 종료",
  noElementsTitle: "시작할 시간입니다!",
  noElementsSubtitle:
    "다음 작업을 계획하기 위해 첫 번째 회의를 생성해 보는 것은 어떨까요? 시작하려면 아래 버튼을 클릭하세요.",
  newAgendaItem: "새로운 안건 항목",
  topicsToDiscuss: "논의할 주제",
  attachOrDrop: "파일 첨부 또는 여기로 드래그 앤 드롭",
  viewFiles: "파일 보기",
  noTopics: "주제 없음",
  taskSuccessfullyDeleted: "작업이 성공적으로 삭제되었습니다.",
  taskCantDelete: "작업을 삭제할 수 없습니다.",
  taskName: "작업 이름",
  newTask: "새 작업",
  deleteTask: "작업 삭제",
  deleteTaskQuestion: "이 작업을 삭제하시겠습니까?",
  noMeetings: "휴지통에 회의가 없습니다.",
  confirmedAssistance: "참석 확인",
};
