export const login = {
  errorLoginTitle: "Falha no Login",
  errorLoginAuthMessage: "Email e/ou senha não correspondem",
  errorLoginMessage: "Algo deu errado. Tente novamente mais tarde.",
  errorNoUser: "Usuário não existe",
  welcomeBack: "Bem-vindo de volta!",
  password: "Senha",
  logIn: "Entrar",
  forgotPassword: "Esqueceu a senha?",
  errorRecoverTitle: "Falha na recuperação de senha",
  followInstructions: "Por favor, siga as instruções",
  recoverPasswordTitle:
    "Por favor, informe o endereço de e-mail da sua conta Buildpeer para receber instruções de redefinição de senha",
  exampleEmail: "exemplo@mail.com",
  recoverPassword: "Recuperar senha",
  emailInvalid: "Email inválido",
};
