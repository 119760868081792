// @ts-check
/* eslint react/prop-types: 0 */

import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";

// eslint-disable-next-line no-unused-vars
import React from "react";
import { useTranslation } from "react-i18next";
import { FileIcon } from "../../../shared";

import TransitionComponent from "../TransitionComponent";

export default function Documents(props) {
  const { open, options, setOpen, documentsList, setDocumentsList } = props;

  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  const handleClose = () => {
    setOpen(false);
  };

  const renderTreeItems = (options, parentId = null) => {
    return options
      ?.filter(
        (option) =>
          (option.idParentFolder === parentId || option.idProjectFolder === parentId) &&
          !documentsList.includes(option.id),
      )
      .map((option) => {
        const childItems = renderTreeItems(options, option.id);

        if (option.isFolder && childItems.length === 0) {
          return null;
        }

        return (
          <TreeItem
            key={option.id}
            itemId={option.id}
            label={
              <Card
                sx={{
                  alignItems: "center",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: "10px",
                  gap: "10px",
                }}
                variant="outlined"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <FileIcon
                    extension={
                      option.isFolder ? "folder" : option.name.substring(option.name.indexOf(".") + 1).toLowerCase()
                    }
                  />
                  {option.name}
                </Box>
                {!option.isFolder ? (
                  <Box
                    sx={{
                      borderRadius: "10px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "20px",
                    }}
                  >
                    <Chip color="success" label={`${tProjectSchedule("Select")}: ${option.size}MB`} />
                    <Button
                      color="primary"
                      onClick={() => {
                        setDocumentsList((documentsList) => [...documentsList, option.id]);
                      }}
                      size="small"
                      variant="contained"
                    >
                      {tProjectSchedule("Select")}
                    </Button>
                  </Box>
                ) : null}
              </Card>
            }
          >
            {childItems}
          </TreeItem>
        );
      })
      .filter(Boolean);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={handleClose}
      open={open}
      PaperProps={{
        className: "project-schedule",
        sx: {
          borderRadius: "20px",
        },
      }}
      scroll="body"
      // @ts-ignore
      TransitionComponent={TransitionComponent}
    >
      <DialogTitle component="div">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4">{tProjectSchedule("Documents")}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          padding: "30px",
        }}
      >
        <Box sx={{ minHeight: 352, minWidth: 250 }}>
          <SimpleTreeView>{renderTreeItems(options)}</SimpleTreeView>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
