// @ts-check
/* eslint react/prop-types: 0 */

import { Box, Button, ClickAwayListener, Grow, Paper, Popper } from "@mui/material";
// eslint-disable-next-line no-unused-vars
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Share(props) {
  const { setInvitePeople, setExportPDF } = props;

  const ref = useRef(null);

  const [open, setOpen] = useState(false);

  const { t: tGeneral } = useTranslation("general");
  const { t: tProjectSchedule } = useTranslation("projectSchedule");

  return (
    <>
      <Button
        color="secondary"
        onClick={() => {
          setOpen(true);
        }}
        ref={ref}
        sx={{
          paddingBottom: "5.25px",
          paddingTop: "5.25px",
        }}
        variant="outlined"
      >
        {tProjectSchedule("Share")}
      </Button>
      <Popper
        anchorEl={ref.current}
        open={open}
        placement="bottom-start"
        role={undefined}
        sx={{
          margin: "5px 0 0 10px",
          zIndex: 1301,
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "left top",
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={(event) => {
                  // @ts-ignore
                  if (ref.current && ref.current.contains(event.target)) {
                    return;
                  }
                  setOpen(false);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    padding: "20px",
                    width: "250px",
                  }}
                >
                  <Box
                    onClick={() => {
                      setInvitePeople(true);
                    }}
                    sx={{
                      alignItems: "center",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <small>{tProjectSchedule("Invite people")}</small>
                  </Box>
                  <Box
                    onClick={() => {
                      setExportPDF(true);
                    }}
                    sx={{
                      alignItems: "center",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <small>{tGeneral("exportPdf")}</small>
                  </Box>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
