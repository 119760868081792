import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import "../fonts/poppins.css";
// To explore the default theme object we're customizing from Material UI:
// https://mui.com/material-ui/customization/default-theme/

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#FBCB04",
    },
    secondary: {
      main: "#fbfbfb",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.primary,
          borderBottom: `1px solid ${theme.palette.primary.main}`,
          boxShadow: "none",
        }),
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
  },
});

export default responsiveFontSizes(darkTheme);
