import { CircleSharp } from "@mui/icons-material";
import { Typography, Box } from "@mui/material";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";

import animationData from "../../../projectDashboard/16627-firework.json";

const useTasksSteps = () => {
  const { t: translation } = useTranslation("tasks");

  const addSteps = [
    {
      target: "[data-tour='add-tasks-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_addtask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_addtask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='add-tasks-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_addtask_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_addtask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-tasks-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_addtask_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_addtask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-tasks-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_addtask_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            {translation("step4_addtask_text1_1")}{" "}
            <CircleSharp
              sx={{ color: "#5ce381", marginBottom: -0.3, fontSize: 14 }}
            />
            {translation("step4_addtask_text1_2")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            {translation("step4_addtask_text2_1")}{" "}
            <CircleSharp
              sx={{ color: "#ffc900", marginBottom: -0.3, fontSize: 14 }}
            />
            {translation("step4_addtask_text2_2")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-tasks-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_addtask_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_addtask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-tasks-6']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_addtask_title")}
          </Typography>
          <Typography sx={{ color: "grey", fontSize: 14 }}>
            {translation("obligatory")}(<span style={{ color: "red" }}>*</span>)
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_addtask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-tasks-7']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_addtask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_addtask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-tasks-8']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step8_addtask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step8_addtask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-tasks-9']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step9_addtask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step9_addtask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableScrolling: true,
    },
    {
      target: "[data-tour='add-tasks-10']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step10_addtask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step10_addtask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-tasks-11']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step11_addtask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step11_addtask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-tasks-12']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step12_addtask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step12_addtask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-tasks-13']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step13_addtask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step13_addtask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_addtask")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const viewSteps = [
    {
      target: "[data-tour='view-tasks-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_viewtask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_viewtask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='view-tasks-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_viewtask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_viewtask_text")}
          </Typography>
        </div>
      ),
      placement: "top",
    },
    {
      target: "[data-tour='view-tasks-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_viewtask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_viewtask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='view-tasks-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_viewtask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_viewtask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='view-rfi-5']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_viewtask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_viewtask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='view-tasks-6']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_viewtask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_viewtask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='view-tasks-7']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step8_viewtask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step8_viewtask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_viewtask")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const editSteps = [
    {
      target: "[data-tour='edit-tasks-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_edittask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_edittask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='edit-tasks-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_edittask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_edittask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-tasks-8']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_edittask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_edittask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='edit-tasks-4']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_edittask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_edittask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-tasks-6']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step5_edittask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step5_edittask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-tasks-7']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step6_edittask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step6_edittask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='add-tasks-13']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step7_edittask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step7_edittask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_edittask")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const recycleSteps = [
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {`1. ${translation("moreTitle")}`}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("moreDescription")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='recycle']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_recycletask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_recycletask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='recycle-tasks-2']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step2_recycletask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step2_recycletask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='confirm-recycle']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_recycletask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_recycletask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_recycletask")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const exportSteps = [
    {
      target: "[data-tour='export-tasks-1']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_exporttask_title")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            <CircleSharp
              sx={{ color: "black", marginBottom: -0.1, fontSize: 10 }}
            />{" "}
            <span style={{ fontWeight: "bold" }}>
              {translation("step1_exporttask_text1_1")}
            </span>
            {" - "}
            {translation("step1_exporttask_text1_2")}
          </Typography>
          <Typography sx={{ marginTop: 2 }}>
            <CircleSharp
              sx={{ color: "black", marginBottom: -0.1, fontSize: 10 }}
            />{" "}
            <span style={{ fontWeight: "bold" }}>
              {translation("step1_exporttask_text2_1")}
            </span>
            {" - "}
            {translation("step1_exporttask_text2_2")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_exporttask")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  const settingsSteps = [
    {
      target: "[data-tour='more']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {`1. ${translation("moreTitle")}`}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("moreDescription")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='settings']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step1_settingstask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step1_settingstask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
      disableBeacon: true,
    },
    {
      target: "[data-tour='docs-settings-3']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step3_settingstask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step3_settingstask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "[data-tour='save-settings']",
      content: (
        <div>
          <Typography sx={{ fontWeight: "bold" }}>
            {translation("step4_settingstask_title")}
          </Typography>
          <Typography sx={{ marginTop: 1 }}>
            {translation("step4_settingstask_text")}
          </Typography>
        </div>
      ),
      placement: "left",
    },
    {
      target: "body",
      content: (
        <Box sx={{ justifyContent: "center", textAlign: "center" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
            {translation("congratulations")}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {translation("stepFinal_settingstask")}
          </Typography>
          <div style={{ position: "absolute", top: 190, right: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", top: 0, left: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 80, right: 350 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <div style={{ position: "absolute", bottom: 220, left: 100 }}>
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
            />
          </div>
        </Box>
      ),
      placement: "center",
    },
  ];

  return [
    {
      addSteps,
      viewSteps,
      editSteps,
      recycleSteps,
      exportSteps,
      settingsSteps,
    },
  ];
};

export { useTasksSteps };
