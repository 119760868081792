// Material UI Components
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

import { Close } from "@mui/icons-material";
import { Typography } from "@mui/material";

// Custom Components
import UserAvatar from "./UserAvatar";

function BubbleAvatarDeletable(props) {
  const { user, color, editable, isList, byObject, setValues } = props;
  const chipColor = color ? "#f7e4b2" : "transparent";
  const avatar = (
    <UserAvatar
      sx={{ m: 10 }}
      key={user.id}
      name={user.name}
      size="Small"
      url={user?.urlAvatar}
    />
  );

  return (
    <Box padding={isList ? 0 : 0.5}>
      {editable ? (
        <Chip
          sx={{
            width: "100%",
            height: 40,
            fontSize: "0.875rem",
            justifyContent: "left",
            mt: 1,
            padding: 2,
            bgcolor: chipColor,
            ":hover": {
              backgroundColor: "#FEFDE8",
            },
          }}
          avatar={avatar}
          label={
            <Box>
              <Typography fontSize="16px" fontWeight="500" color="#1F1F1F">
                {user.name}
              </Typography>
              <Typography fontSize="14px" fontWeight="400" color="#8E8E8E">
                {user.email}
              </Typography>
            </Box>
          }
          deleteIcon={<Close style={{ marginLeft: "auto" }} />}
          onDelete={() => {
            if (byObject) {
              // For return id user
              setValues(user.id);
            } else {
              setValues(user.name);
            }
          }}
        />
      ) : (
        <Chip
          sx={{
            width: "100%",
            height: 40,
            bgcolor: chipColor,
            fontSize: "0.875rem",
            justifyContent: "space-between",
            padding: isList ? 0 : 2,
            borderRadius: "23px",
          }}
          avatar={avatar}
          label={user.name}
        />
      )}
    </Box>
  );
}

export default BubbleAvatarDeletable;
