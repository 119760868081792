import { apiSlice } from "../../../../api/apiSlice";

const drawingsQuery = "project/drawingsChecklist";

export const drawingsChecklistApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChecklistDrawings: builder.query({
      query: (idProject) =>
        `${drawingsQuery}/getDrawingsChecklist/${idProject}`,
      providesTags: ["DrawingsChecklist"],
    }),
    createChecklistItem: builder.mutation({
      query: (body) => ({
        url: `${drawingsQuery}/createItem`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["DrawingsChecklist"],
    }),
    editChecklistItem: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${drawingsQuery}/editItem/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["DrawingsChecklist"],
    }),
    linkChecklistItem: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `${drawingsQuery}/linkItem/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["DrawingsChecklist"],
    }),
    deleteItem: builder.mutation({
      query: (id) => ({
        url: `${drawingsQuery}/deleteItem/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DrawingsChecklist"],
    }),
  }),
});

export const {
  useGetChecklistDrawingsQuery,
  useCreateChecklistItemMutation,
  useEditChecklistItemMutation,
  useLinkChecklistItemMutation,
  useDeleteItemMutation,
} = drawingsChecklistApiSlice;
