import { apiSlice } from "../../api/apiSlice";

export const costCodeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCostCodes: builder.query({
      query: (idProject) => `/project/costCode/getCostCodes/${idProject}`,
      keepUnusedDataFor: 60,
      providesTags: ["CostCodes"],
    }),
    addCostCode: builder.mutation({
      query: (body) => ({
        url: "/project/costCode/addCostCode",
        method: "POST",
        body,
      }),
      invalidatesTags: ["CostCodes"],
    }),
    updateCostCode: builder.mutation({
      query: (body) => ({
        url: "/project/costCode/updateCostCode",
        method: "POST",
        body,
      }),
      invalidatesTags: ["CostCodes"],
    }),
    deleteCostCode: builder.mutation({
      query: (body) => ({
        url: "/project/costCode/deleteCostCode",
        method: "POST",
        body,
      }),
      invalidatesTags: ["CostCodes"],
    }),
  }),
});

export const {
  useGetCostCodesQuery,
  useAddCostCodeMutation,
  useUpdateCostCodeMutation,
  useDeleteCostCodeMutation,
} = costCodeApiSlice;
