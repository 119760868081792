// @ts-check
/* eslint react/prop-types: 0 */

import MenuIcon from "@mui/icons-material/Menu";
import { Button } from "@mui/material";
// eslint-disable-next-line no-unused-vars
import React from "react";

export default function Menu(props) {
  const { setMenu } = props;

  return (
    <Button
      color="secondary"
      onClick={() => {
        setMenu((menu) => {
          return !menu;
        });
      }}
      sx={{
        minWidth: "0",
      }}
      variant="text"
    >
      <MenuIcon />
    </Button>
  );
}
