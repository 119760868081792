import {
    Box,
    TextField,
  } from "@mui/material";
  import { useTranslation } from "react-i18next";
  import { useEffect, useRef, useState } from "react";
  import { Search } from "@mui/icons-material";
  
  function DrawingsReviewFilters({ filters, onChange }) {
    const { t: tGeneral } = useTranslation("general");
  
    const [search, setSearch] = useState("");
    const timerIdRef = useRef(null);

    useEffect(() => {
        if (filters?.search?.length > 0) {
            setSearch(filters?.search);
        }
    }, [filters]);
  
    const handleChange = (key, value) => {
      onChange({ ...filters, [key]: value });
    };
  
    const handleChangeSearch = (e) => {
      const {
        target: { value },
      } = e;
      setSearch(value);
      clearTimeout(timerIdRef.current);
      timerIdRef.current = setTimeout(() => {
        handleChange("search", value);
      }, 500);
    };
  
    return (
      <Box display="flex" p="10px" columnGap="10px" justifyContent="end">
        <TextField
          size="small"
          value={search}
          onChange={handleChangeSearch}
          placeholder={tGeneral("search")}
          InputProps={{ startAdornment: <Search /> }}
        />
      </Box>
    );
  }
  
  export { DrawingsReviewFilters };
  