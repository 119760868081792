import { Add } from "@mui/icons-material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { selectTheme } from "../../../features/preferences/preferencesSlice";

// Components
import { FilePreviewHorizontalScroll } from "../../shared";
import TopicForm from "./TopicForm";

// Constants
import { selectCurrentGuideMe } from "../../../features/project/projectSlice";
import { useAppTourContext } from "../../supportButton/context/context";
import TopicItem from "./TopicItem";

function AgendaForm({ setAgendaItem }) {
  const darkMode = useSelector(selectTheme);

  const { t: tMeetings } = useTranslation("meetings");
  const { t: tGeneral } = useTranslation("general");
  const currentGuideMe = useSelector(selectCurrentGuideMe);

  const [itemName, setItemName] = useState("");
  const [itemNameError, setItemNameError] = useState("");
  const [topics, setTopics] = useState([]);
  const [time, setTime] = useState(0);
  const [timeError, setTimeError] = useState(false);
  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState([]);

  function resetVars() {
    setItemName("");
    setItemNameError(false);
    setTopics([]);
    setTime(0);
    setTimeError(false);
    setFiles([]);
  }

  const handleOnDeleteFile = (file) => {
    const tempFiles = [...files];
    setFiles(tempFiles.filter((f) => f.name !== file.name));
  };

  function validateFields(val, field) {
    switch (field) {
      case "itemName":
        setItemName(val);
        setItemNameError(val.length > 0);
        break;
      case "time":
        if (String(val).length <= 3) {
          setTime(val);
          setTimeError(val > 0);
        }
        break;
      default:
        break;
    }
  }

  const {
    state: { tourActive, stepIndex, steps },
    setState,
  } = useAppTourContext();

  useEffect(() => {
    if (tourActive && currentGuideMe === "meetings-add" && stepIndex === 10) {
      setOpen(true);
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }

    if (
      tourActive &&
      currentGuideMe === "meetings-add" &&
      (stepIndex === 9 || stepIndex === steps.length - 1) &&
      open
    ) {
      setOpen(false);
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [tourActive, stepIndex, currentGuideMe, steps]);

  return !open ? (
    <Box width="100%" display="flex" justifyContent="right">
      <Button
        startIcon={<Add />}
        sx={{
          // width: "190px",
          mt: 3,
          color: "black",
          backgroundColor: "#ffc900",
          ":hover": {
            color: "black",
            backgroundColor: "#d9ab00",
          },
          textTransform: "none",
          py: "0.5rem",
        }}
        type="submit"
        variant="contained"
        id=""
        onClick={(e) => {
          setOpen(true);
        }}
        data-tour="add-meetings-10"
      >
        {tMeetings("newAgendaItem")}
      </Button>
    </Box>
  ) : (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        border: darkMode ? 1 : 0,
        borderRadius: darkMode ? 5 : 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: darkMode ? "" : "#E8E8E8",
          rowGap: 2,
          paddingY: 2,
          paddingX: 5,
          borderTopLeftRadius: 25,
          borderTopRightRadius: 25,
        }}
        data-tour="add-meetings-11"
      >
        <Typography fontWeight="bold">{tMeetings("newAgendaItem")}</Typography>
        <TextField
          autoFocus={open}
          value={itemName}
          error={itemNameError}
          onChange={(e) => {
            validateFields(e.target.value, "itemName");
          }}
          type="text"
          sx={{
            backgroundColor: darkMode ? "" : "white",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: darkMode ? "" : "#F2F2F2",
          rowGap: 2,
          paddingY: 2,
          paddingX: 5,
          borderBottomLeftRadius: 25,
          borderBottomRightRadius: 25,
        }}
      >
        {/* Topics */}
        <span data-tour="add-meetings-12">
          <Typography>{tMeetings("topicsToDiscuss").toUpperCase()}</Typography>
          {topics?.map((topic, index) => (
            // <Box key={index}>
            <TopicItem
              key={index}
              topic={topic}
              index={index}
              setSelectedTopic={(index) => {
                const currentTopics = [...topics];
                currentTopics.splice(index, 1);
                setTopics(currentTopics);
              }}
            />
            // </Box>
          ))}
          <TopicForm
            setTopic={(value) => {
              const newTopic = {
                id: topics.length + 1,
                title: value,
                bNew: true,
              };
              const currentTopics = [...topics];
              currentTopics.push(newTopic);
              setTopics(currentTopics);
            }}
          />
        </span>
        <Divider sx={{ border: 1, color: "#E1E1E1" }} />
        {/* Time */}
        <span data-tour="add-meetings-13">
          <Typography>{tGeneral("time").toUpperCase()}</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              columnGap: 1,
            }}
          >
            <TextField
              type="number"
              value={time}
              error={timeError}
              onKeyDown={(e) => {
                if (
                  !e.key.match(/\d/) &&
                  e.key !== "Backspace" &&
                  e.key !== "Delete" &&
                  e.key !== "ArrowLeft" &&
                  e.key !== "ArrowRight"
                ) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                validateFields(e.target.value, "time");
              }}
              sx={{
                backgroundColor: darkMode ? "" : "white",
                width: "100px",
              }}
            />
            <Typography>{tGeneral("minutes")}</Typography>
          </Box>
        </span>
        {/* Files */}
        <span data-tour="add-meetings-14">
          <Box display="flex" alignItems="center" columnGap={1}>
            <DescriptionOutlinedIcon />
            <Typography>{tGeneral("files").toUpperCase()}</Typography>
          </Box>
          <FilePreviewHorizontalScroll
            files={files}
            onDelete={handleOnDeleteFile}
            onAddPhotos={(files) => setFiles(files)}
          />
        </span>
        <Divider sx={{ border: 1, color: "#E1E1E1" }} />
        <Box width="100%" display="flex" justifyContent="right">
          <Button
            variant="contained"
            sx={{
              py: "0.5rem",
              width: "120px",
            }}
            onClick={() => {
              if (itemName.length > 0 && time > 0) {
                const body = {
                  title: itemName,
                  topics,
                  time,
                  files,
                };
                setAgendaItem(body);
                setOpen(false);
                resetVars();
              } else {
                validateFields(itemName, "itemName");
                validateFields(time, "time");
              }
            }}
            data-tour="add-meetings-15"
          >
            {tGeneral("add")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default AgendaForm;
