import { apiSlice } from "../../api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/login",
        method: "POST",
        body: { ...credentials },
      }),
      invalidatesTags: ["Enterprises", "Projects"],
    }),
    logout: builder.mutation({
      query: () => ({
        url: "auth/logout",
        method: "POST",
      }),
    }),
    signup: builder.mutation({
      query: (body) => ({
        url: "/auth/register",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Enterprises", "Projects"],
    }),
    recoverpassword: builder.mutation({
      query: (body) => ({
        url: "/auth/recover-password",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Enterprises", "Projects"],
    }),
    resetpassword: builder.mutation({
      query: (body) => ({
        url: "/auth/reset-password",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Enterprises", "Projects"],
    }),
    sendEmailVerify: builder.mutation({
      query: (body) => ({
        url: "/auth/send-email-verify",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Enterprises", "Projects"],
    }),
    verifyAccount: builder.mutation({
      query: (body) => ({
        url: "/auth/verify-account",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Enterprises", "Projects"],
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useSignupMutation,
  useRecoverpasswordMutation,
  useResetpasswordMutation,
  useVerifyAccountMutation,
  useSendEmailVerifyMutation,
} = authApiSlice;
