import * as React from "react";
import { useSelector } from "react-redux";
import {
  useGetNotificationsByEnterpriseQuery,
  useGetUnreadNotificacionsCounterQuery,
} from "../features/notificationCenter/notificationCenterApiSlice";
import { selectCurrentEnterprise } from "../features/enterprise/enterpriseSlice";

const POLLING_INTERVAL = 18000;

function useNotificationsCounterPolling() {
  const currentEnterprise = useSelector(selectCurrentEnterprise);

  const { data: count } = useGetUnreadNotificacionsCounterQuery(
    { idEnterprise: currentEnterprise?.id, status: "unread" },
    {
      skip: !currentEnterprise,
      pollingInterval: POLLING_INTERVAL,
    },
  );

  return {
    count,
  };
}

function useNotificationsCounterEnterprisePolling() {
  const { data: allEnterprisesCount } = useGetNotificationsByEnterpriseQuery(
    null,
    {
      pollingInterval: POLLING_INTERVAL,
    },
  );

  return {
    allEnterprisesCount,
  };
}

export {
  useNotificationsCounterPolling,
  useNotificationsCounterEnterprisePolling,
};
