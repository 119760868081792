import { apiSlice } from "../../api/apiSlice";

const disciplinesQuery = "project/discipline";

export const disciplineApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDisciplines: builder.query({
      query: (idProject) => `${disciplinesQuery}/getDisciplines/${idProject}`,
      providesTags: ["Disciplines"],
    }),
    addDiscipline: builder.mutation({
      query: (body) => ({
        url: `${disciplinesQuery}/createDiscipline`,
        body,
        method: "POST",
      }),
      invalidatesTags: ["Disciplines"],
    }),
    editDiscipline: builder.mutation({
      query: (body) => ({
        url: `${disciplinesQuery}/editDiscipline`,
        body,
        method: "PUT",
      }),
      invalidatesTags: ["Drawings", "Disciplines"],
    }),
    deleteDiscipline: builder.mutation({
      query: (body) => ({
        url: `${disciplinesQuery}/deleteDiscipline`,
        body,
        method: "PUT",
      }),
      invalidatesTags: ["Disciplines"],
    }),
  }),
});

export const {
  useGetDisciplinesQuery,
  useAddDisciplineMutation,
  useEditDisciplineMutation,
  useDeleteDisciplineMutation,
} = disciplineApiSlice;
