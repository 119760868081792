import ClearDay from "../../../../assets/icons/weather/clear_day.svg";
import Cloudy from "../../../../assets/icons/weather/cloudy.svg";
import MostlyClearDay from "../../../../assets/icons/weather/mostly_clear_day.svg";
import PartlyCloudyDay from "../../../../assets/icons/weather/partly_cloudy_day.svg";
import MostlyCloudy from "../../../../assets/icons/weather/mostly_cloudy.svg";
import Fog from "../../../../assets/icons/weather/fog.svg";
import FogLight from "../../../../assets/icons/weather/fog_light.svg";
import Drizzle from "../../../../assets/icons/weather/drizzle.svg";
import Rain from "../../../../assets/icons/weather/rain.svg";
import RainLight from "../../../../assets/icons/weather/rain_light.svg";
import RainHeavy from "../../../../assets/icons/weather/rain_heavy.svg";
import Snow from "../../../../assets/icons/weather/snow.svg";
import Flurries from "../../../../assets/icons/weather/flurries.svg";
import SnowLight from "../../../../assets/icons/weather/snow_light.svg";
import SnowHeavy from "../../../../assets/icons/weather/snow_heavy.svg";
import FreezingDrizzle from "../../../../assets/icons/weather/freezing_drizzle.svg";
import FreezingRain from "../../../../assets/icons/weather/freezing_rain.svg";
import FreezingRainLight from "../../../../assets/icons/weather/freezing_rain_light.svg";
import FreezingRainHeavy from "../../../../assets/icons/weather/freezing_rain_heavy.svg";
import IcePellets from "../../../../assets/icons/weather/ice_pellets.svg";
import IcePelletsHeavy from "../../../../assets/icons/weather/ice_pellets_heavy.svg";
import IcePelletsLight from "../../../../assets/icons/weather/ice_pellets_light.svg";
import TStorm from "../../../../assets/icons/weather/tstorm.svg";

const weatherIcons = {
  1000: { icon: ClearDay, label: "Clear" },
  1001: { icon: Cloudy, label: "Cloudy" },
  1100: { icon: MostlyClearDay, label: "Mostly Clear" },
  1101: { icon: PartlyCloudyDay, label: "Partly Cloudy" },
  1102: { icon: MostlyCloudy, label: "Mostly Cloudy" },
  2000: { icon: Fog, label: "Fog" },
  2100: { icon: FogLight, label: "Light Fog" },
  4000: { icon: Drizzle, label: "Drizzle" },
  4001: { icon: Rain, label: "Rain" },
  4200: { icon: RainLight, label: "Light Rain" },
  4201: { icon: RainHeavy, label: "Heavy Rain" },
  5000: { icon: Snow, label: "Snow" },
  5001: { icon: Flurries, label: "Flurries" },
  5100: { icon: SnowLight, label: "Light Snow" },
  5101: { icon: SnowHeavy, label: "Heavy Snow" },
  6000: { icon: FreezingDrizzle, label: "Drizzle Freezing" },
  6001: { icon: FreezingRain, label: "Freezing Rain" },
  6200: { icon: FreezingRainLight, label: "Freezing Light Rain" },
  6201: { icon: FreezingRainHeavy, label: "Freezing Heavy Rain" },
  7000: { icon: IcePellets, label: "Ice Pellets" },
  7101: { icon: IcePelletsHeavy, label: "Heavy Ice Pellets" },
  7102: { icon: IcePelletsLight, label: "Light Ice Pellets" },
  8000: { icon: TStorm, label: "Thunderstorm" },
};

export function getIcon(weatherCode) {
  return weatherIcons[weatherCode];
}
