import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import animationData from "../../../projectDashboard/16627-firework.json";

const useDocumentsSteps = () => {
  const { t: translation } = useTranslation("documents");

  const docs = {
    addFile: [
      {
        target: `[data-tour='${"docs-add-1"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step1_docsAddFile_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step1_docsAddFile_text")}
            </Typography>
          </div>
        ),
        disableBeacon: true,
      },
      {
        target: `[data-tour='${"docs-add-file-2"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step2_docsAddFile_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step2_docsAddFile_text")}
            </Typography>
          </div>
        ),
      },
      {
        target: `[data-tour='${"docs-add-file-3"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step3_docsAddFile_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step3_docsAddFile_text")}
            </Typography>
          </div>
        ),
      },
      {
        target: `[data-tour='${"docs-add-4"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step4_docsAddFile_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step4_docsAddFile_text")}
            </Typography>
          </div>
        ),
      },
      {
        target: `[data-tour='${"docs-add-5"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step5_docsAddFile_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step5_docsAddFile_text")}
            </Typography>
          </div>
        ),
      },
      {
        target: "body",
        content: (
          <Box sx={{ justifyContent: "center", textAlign: "center" }}>
            <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
              {translation("congratulations")}
            </Typography>
            <Typography sx={{ fontSize: 18 }}>
              {translation("step_final_docsAddFile")}
            </Typography>
            <div style={{ position: "absolute", top: 190, right: 100 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
            <div style={{ position: "absolute", top: 0, left: 350 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
            <div style={{ position: "absolute", bottom: 80, right: 350 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
            <div style={{ position: "absolute", bottom: 220, left: 100 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
          </Box>
        ),
        placement: "center",
      },
    ],
    addFolder: [
      {
        target: `[data-tour='${"docs-add-1"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step1_docsAddFolder_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step1_docsAddFolder_text")}
            </Typography>
          </div>
        ),
        disableBeacon: true,
      },
      {
        target: `[data-tour='${"docs-add-folder-2"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step2_docsAddFolder_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step2_docsAddFolder_text")}
            </Typography>
          </div>
        ),
      },
      {
        target: `[data-tour='${"docs-add-folder-3"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step3_docsAddFolder_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step3_docsAddFolder_text")}
            </Typography>
          </div>
        ),
      },
      {
        target: `[data-tour='${"docs-add-4"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step4_docsAddFolder_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step4_docsAddFolder_text")}
            </Typography>
          </div>
        ),
      },
      {
        target: `[data-tour='${"docs-add-5"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step5_docsAddFolder_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step5_docsAddFolder_text")}
            </Typography>
          </div>
        ),
      },
      {
        target: "body",
        content: (
          <Box sx={{ justifyContent: "center", textAlign: "center" }}>
            <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
              {translation("congratulations")}
            </Typography>
            <Typography sx={{ fontSize: 18 }}>
              {translation("step_final_docsAddFolder")}
            </Typography>
            <div style={{ position: "absolute", top: 190, right: 100 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
            <div style={{ position: "absolute", top: 0, left: 350 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
            <div style={{ position: "absolute", bottom: 80, right: 350 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
            <div style={{ position: "absolute", bottom: 220, left: 100 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
          </Box>
        ),
        placement: "center",
      },
    ],
    fileInfo: [
      {
        target: `[data-tour='${"docs-actions-6"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step1_docsFileInfo_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step1_docsFileInfo_text")}
            </Typography>
          </div>
        ),
        disableBeacon: true,
        disableScrolling: true,
      },
      {
        target: `[data-tour='${"docs-actions-7"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step2_docsFileInfo_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step2_docsFileInfo_text")}
            </Typography>
          </div>
        ),
        disableBeacon: true,
        disableScrolling: true,
      },
      {
        target: `[data-tour='${"docs-file-info-3"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step3_docsFileInfo_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step3_docsFileInfo_text")}
            </Typography>
          </div>
        ),
        disableBeacon: true,
        disableScrolling: true,
      },
      {
        target: `[data-tour='${"docs-file-info-4"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step4_docsFileInfo_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step4_docsFileInfo_text")}
            </Typography>
          </div>
        ),
        disableBeacon: true,
        disableScrolling: true,
      },
      {
        target: `[data-tour='${"docs-file-info-5"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step5_docsFileInfo_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step5_docsFileInfo_text")}
            </Typography>
          </div>
        ),
        disableBeacon: true,
        disableScrolling: true,
      },
      {
        target: `[data-tour='${"docs-file-info-5_1"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step5_1_docsFileInfo_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step5_1_docsFileInfo_text")}
            </Typography>
          </div>
        ),
        disableBeacon: true,
        disableScrolling: true,
      },
      {
        target: "body",
        content: (
          <Box sx={{ justifyContent: "center", textAlign: "center" }}>
            <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
              {translation("congratulations")}
            </Typography>
            <Typography sx={{ fontSize: 18 }}>
              {translation("step_final_docsFileInfo")}
            </Typography>
            <div style={{ position: "absolute", top: 190, right: 100 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
            <div style={{ position: "absolute", top: 0, left: 350 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
            <div style={{ position: "absolute", bottom: 80, right: 350 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
            <div style={{ position: "absolute", bottom: 220, left: 100 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
          </Box>
        ),
        placement: "center",
      },
    ],
    moveTo: [
      {
        target: `[data-tour='${"docs-actions-6"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step1_docsMoveTo_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step1_docsMoveTo_text")}
            </Typography>
          </div>
        ),
        disableBeacon: true,
        disableScrolling: true,
      },
      {
        target: `[data-tour='${"docs-actions-8"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step2_docsMoveTo_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step2_docsMoveTo_text")}
            </Typography>
          </div>
        ),
        disableBeacon: true,
        disableScrolling: true,
      },
      {
        target: `[data-tour='${"docs-move-to-3"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step3_docsMoveTo_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step3_docsMoveTo_text")}
            </Typography>
          </div>
        ),
        disableBeacon: true,
        disableScrolling: true,
      },
      {
        target: `[data-tour='${"docs-move-to-4"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step4_docsMoveTo_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step4_docsMoveTo_text")}
            </Typography>
          </div>
        ),
        disableBeacon: true,
        disableScrolling: true,
      },
      {
        target: "body",
        content: (
          <Box sx={{ justifyContent: "center", textAlign: "center" }}>
            <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
              {translation("congratulations")}
            </Typography>
            <Typography sx={{ fontSize: 18 }}>
              {translation("step_final_docsMoveTo")}
            </Typography>
            <div style={{ position: "absolute", top: 190, right: 100 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
            <div style={{ position: "absolute", top: 0, left: 350 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
            <div style={{ position: "absolute", bottom: 80, right: 350 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
            <div style={{ position: "absolute", bottom: 220, left: 100 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
          </Box>
        ),
        placement: "center",
      },
    ],
    actions: [
      {
        target: `[data-tour='${"docs-actions-1"}']`,
        position: "top-start",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step1_docsActions_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step1_docsActions_text")}
            </Typography>
          </div>
        ),
        disableBeacon: true,
        disableScrolling: true,
      },
      {
        target: `[data-tour='${"docs-actions-2"}']`,
        position: "top-start",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step2_docsActions_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step2_docsActions_text")}
            </Typography>
          </div>
        ),
        disableScrolling: true,
      },
      {
        target: `[data-tour='${"docs-actions-3"}']`,
        position: "top-start",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step3_docsActions_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step3_docsActions_text")}
            </Typography>
          </div>
        ),
        disableScrolling: true,
      },
      {
        target: `[data-tour='${"docs-actions-4"}']`,
        position: "top-start",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step4_docsActions_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step4_docsActions_text")}
            </Typography>
          </div>
        ),
        disableScrolling: true,
      },
      {
        target: `[data-tour='${"docs-actions-5"}']`,
        position: "top-start",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step5_docsActions_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step5_docsActions_text")}
            </Typography>
          </div>
        ),
        disableScrolling: true,
      },
      {
        target: `[data-tour='${"docs-actions-6"}']`,
        position: "top-start",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step6_docsActions_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step6_docsActions_text")}
            </Typography>
          </div>
        ),
        disableScrolling: true,
      },
      {
        target: `[data-tour='${"docs-actions-7"}']`,
        position: "top-start",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step7_docsActions_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step7_docsActions_text")}
            </Typography>
          </div>
        ),
        disableScrolling: true,
      },
      {
        target: `[data-tour='${"docs-actions-8"}']`,
        position: "top-start",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step8_docsActions_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step8_docsActions_text")}
            </Typography>
          </div>
        ),
        disableScrolling: true,
      },
      {
        target: `[data-tour='${"docs-actions-9"}']`,
        position: "top-start",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step9_docsActions_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step9_docsActions_text")}
            </Typography>
          </div>
        ),
        disableScrolling: true,
      },
      {
        target: `[data-tour='${"docs-actions-10"}']`,
        position: "top-start",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step10_docsActions_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step10_docsActions_text")}
            </Typography>
          </div>
        ),
        disableScrolling: true,
      },
      {
        target: "body",
        content: (
          <Box sx={{ justifyContent: "center", textAlign: "center" }}>
            <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
              {translation("congratulations")}
            </Typography>
            <Typography sx={{ fontSize: 18 }}>
              {translation("step_final_docsActions")}
            </Typography>
            <div style={{ position: "absolute", top: 190, right: 100 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
            <div style={{ position: "absolute", top: 0, left: 350 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
            <div style={{ position: "absolute", bottom: 80, right: 350 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
            <div style={{ position: "absolute", bottom: 220, left: 100 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
          </Box>
        ),
        placement: "center",
      },
    ],
    settings: [
      {
        target: `[data-tour='${"more"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {`1. ${translation("moreTitle")}`}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("moreDescription")}
            </Typography>
          </div>
        ),
        disableBeacon: true,
      },
      {
        target: `[data-tour='${"settings"}']`,
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step1_docsSettings_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step1_docsSettings_text")}
            </Typography>
          </div>
        ),
        disableBeacon: true,
      },
      {
        target: "[data-tour='docs-settings-3']",
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step2_docsSettings_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step2_docsSettings_text")}
            </Typography>
          </div>
        ),
        disableBeacon: true,
      },
      {
        target: "[data-tour='save-settings']",
        position: "left",
        content: (
          <div>
            <Typography sx={{ fontWeight: "bold" }}>
              {translation("step3_docsSettings_title")}
            </Typography>
            <Typography sx={{ marginTop: 1 }}>
              {translation("step3_docsSettings_text")}
            </Typography>
          </div>
        ),
        disableBeacon: true,
      },
      {
        target: "body",
        content: (
          <Box sx={{ justifyContent: "center", textAlign: "center" }}>
            <Typography sx={{ fontWeight: "bold", fontSize: 24 }}>
              {translation("congratulations")}
            </Typography>
            <Typography sx={{ fontSize: 18 }}>
              {translation("step_final_docsSettings")}
            </Typography>
            <div style={{ position: "absolute", top: 190, right: 100 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
            <div style={{ position: "absolute", top: 0, left: 350 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
            <div style={{ position: "absolute", bottom: 80, right: 350 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
            <div style={{ position: "absolute", bottom: 220, left: 100 }}>
              <Lottie
                animationData={animationData}
                style={{ height: 200, width: 200 }}
              />
            </div>
          </Box>
        ),
        placement: "center",
      },
    ],
  };

  return [
    {
      docs,
    },
  ];
};

export { useDocumentsSteps };
