export const onboarding = {
  welcome: "Welcome",
  enterpriseInfo: "Enterprise info",
  features: "Features",
  choosePlan: "Choose plan",
  payment: "Payment",
  welcomeTitle: "Welcome to buildpeer",
  welcomeSubtitle: "Know your project & Enjoy the process",
  startCreateEnterprise: "Start create enterprise",
  joinProject: "Join a project",
  projectCode: "Project code",
  createEnterprise: "Create Enterprise",
  officialName: "Enterprise name",
  enterpriseType: "Enterprise type",
  welcomeJourney: "Welcome to the journey",
  planning: "Planning",
  processTracking: "Progress and follow-up",
  documentation: "Documentation",
  resources: "Resources",
  community: "Community",
  management: "Management",
  communication: "Communication",
};
