import { useMemo } from "react";
import { MaterialReactTable } from "material-react-table";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import stringUtil from "../../../utils/string";

const CHECKMARK_COLOR = "green";

function DisplayPermissionsGrid({ role, maxHeight }) {
  const { t: tGeneral } = useTranslation("general");
  const { t: tProjectDirectory } = useTranslation("projectDirectory");

  const modules = [
    {
      id: "drawings",
      role: 0,
      name: tProjectDirectory("drawings"),
    },
    {
      id: "directory",
      role: 0,
      name: tProjectDirectory("directory"),
    },
    {
      id: "rfis",
      role: 0,
      name: tProjectDirectory("rfis"),
    },
    {
      id: "meetings",
      role: 0,
      name: tProjectDirectory("meetings"),
    },
    {
      id: "photos",
      role: 0,
      name: tProjectDirectory("photos"),
    },
    {
      id: "tasks",
      role: 0,
      name: tGeneral("tasks"),
    },
    {
      id: "dailyReports",
      role: 0,
      name: tProjectDirectory("dailyReports"),
    },
    {
      id: "documents",
      role: 0,
      name: tProjectDirectory("documents"),
    },
    {
      id: "announcements",
      role: 0,
      name: tProjectDirectory("announcements"),
    },
    {
      id: "dashboard",
      role: 0,
      name: tGeneral("dashboard"),
    },
    {
      id: "adminPanel",
      role: 0,
      name: tProjectDirectory("adminPanel"),
    },
    {
      id: "inspections",
      role: 0,
      name: tGeneral("inspections"),
    },
  ];

  const columns = useMemo(
    () => [
      {
        header: tGeneral("module"),
        accessorKey: "name",
        enableGrouping: false,
        Cell: ({ cell, row }) => {
          const moduleName = cell.getValue();
          return (
            <Typography>{`${stringUtil.capitalizeFirstLetter(
              moduleName,
            )}`}</Typography>
          );
        },
      },
      {
        id: "superAdmin",
        header: tGeneral("superAdmin"),
        enableGrouping: false,
        size: 80,
        Cell: ({ row }) => {
          const modulePermission = role?.[row?.original?.id];
          return modulePermission === 3 ? (
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <CheckIcon sx={{ flex: 3, color: CHECKMARK_COLOR }} />
              <Box flex={1} />
            </Box>
          ) : null;
        },
      },
      {
        id: "admin",
        header: tGeneral("admin"),
        enableGrouping: false,
        size: 80,
        Cell: ({ row }) => {
          const modulePermission = role?.[row?.original?.id];
          return modulePermission === 2 ? (
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <CheckIcon sx={{ flex: 3, color: CHECKMARK_COLOR }} />
              <Box flex={1} />
            </Box>
          ) : null;
        },
      },
      {
        id: "viewOnly",
        header: tGeneral("viewOnly"),
        enableGrouping: false,
        size: 80,
        Cell: ({ row }) => {
          const modulePermission = role?.[row?.original?.id];
          return modulePermission === 1 ? (
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <CheckIcon sx={{ flex: 3, color: CHECKMARK_COLOR }} />
              <Box flex={1} />
            </Box>
          ) : null;
        },
      },
      {
        id: "none",
        header: tGeneral("none"),
        enableGrouping: false,
        size: 80,
        Cell: ({ row }) => {
          const modulePermission = role?.[row?.original?.id];
          return modulePermission === 0 ? (
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <CheckIcon sx={{ flex: 3, color: CHECKMARK_COLOR }} />
              <Box flex={1} />
            </Box>
          ) : null;
        },
      },
    ],
    [role, tGeneral],
  );
  return (
    <MaterialReactTable
      columns={columns}
      data={modules}
      enableStickyHeader
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableSorting={false}
      enableBottomToolbar={false}
      enableTopToolbar={false}
      muiTableBodyRowProps={{ hover: false }}
      muiTableProps={{
        sx: {
          border: "0px",
        },
        "data-tour": "edit-user-4",
      }}
      muiTableHeadCellProps={{
        sx: {
          border: "0px",
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          border: "0px",
        },
      }}
      muiTableContainerProps={{
        sx: { borderRadius: 5, overflowY: "auto", maxHeight },
      }}
      muiTablePaperProps={{
        sx: {
          borderRadius: 5,
          boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.1)",
          WebkitBoxShadow: "0px 0px 0px rgba(0, 0, 0, 0.1)",
        },
      }}
      muiTopToolbarProps={{
        sx: { borderRadius: 5 },
      }}
      muiBottomToolbarProps={{
        sx: { borderRadius: 5 },
      }}
    />
  );
}

export default DisplayPermissionsGrid;
