import axios from "axios";
import JSZip from "jszip";

import { PopUpAlert } from "../components/PopUpAlert";

export function getObjectFiles(object) {
  const objectFiles = [];

  try {
    (object.topics || []).forEach((topic) => {
      topic.files.forEach((file) => {
        objectFiles.push(file);
      });
    });

    ((object.question || {}).files || []).forEach((file) => {
      objectFiles.push(file);
    });

    (object.sections || []).forEach((section) => {
      section.items.forEach((item) => {
        item.observations.forEach((observation) => {
          observation.files.forEach((file) => {
            objectFiles.push(file);
          });
        });
      });
    });

    (object.agenda || []).forEach((agenda) => {
      agenda.files.forEach((file) => {
        objectFiles.push(file);
      });

      agenda.topics.forEach((topic) => {
        topic.files.forEach((file) => {
          objectFiles.push(file);
        });
      });
    });

    (object.agendas || []).forEach((agenda) => {
      agenda.files.forEach((file) => {
        objectFiles.push(file);
      });

      agenda.topics.forEach((topic) => {
        topic.files.forEach((file) => {
          objectFiles.push(file);
        });
      });
    });
  } catch (error) {
    // eslint-disable-next-line no-console
  }

  return objectFiles;
}

export default async function pdf({
  data,
  token,
  url,
  download,
  module = "",
  object = {},
  tGeneral = () => null,
  onFail = () => null,
}) {
  let errorType = "timeout"; // timeout|request
  try {
    const response = await axios.request({
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
      responseType: "blob",
      url,
      timeout: 300000, // Timeout in milliseconds (300000ms = 5min)
    });

    if (!response.data) {
      errorType = "request";
      throw new Error("No data in axios request for url: ", url);
    }

    const link = document.createElement("a");
    const file = new Blob([response.data], { type: "application/pdf" });

    const loadZipFile = async () => {
      const zip = new JSZip();

      const erroredFiles = [];
      const addFileToZip = async (index, file, folder) => {
        try {
          const urlWithCacheBuster = `${
            file.url
          }?cache_buster=${new Date().getTime()}`;
          const res = await fetch(urlWithCacheBuster, {
            cache: "no-store",
            signal: AbortSignal.timeout(300000),
          });
          const data = await res.blob();
          const fileExtension = file.url.substring(
            file.url.lastIndexOf(".") + 1,
          );
          folder.file(`${file.name}-${index}.${fileExtension}`, data);
        } catch (error) {
          erroredFiles.push(file);
        }
      };

      await Promise.all(
        [{}].map(async () => {
          switch (module) {
            case "rfi":
            case "submittal":
              await Promise.all(
                object.topics.map(async (topic) => {
                  if (topic.files.length === 0) {
                    return;
                  }

                  let topicFolder = zip.folder(topic.title);
                  await Promise.all(
                    topic.files.map(async (file, index) => {
                      topicFolder = await addFileToZip(
                        index,
                        file,
                        topicFolder,
                      );
                    }),
                  );
                }),

                await Promise.all(
                  [object.question].map(async (question) => {
                    if (question.files.length === 0) {
                      return;
                    }

                    let questionFolder = zip.folder(question.question);
                    await Promise.all(
                      question.files.map(async (file, index) => {
                        questionFolder = await addFileToZip(
                          index,
                          file,
                          questionFolder,
                        );
                      }),
                    );
                  }),
                ),
              );
              break;
            case "task":
              await Promise.all(
                [object.question].map(async (question) => {
                  if (question.files.length === 0) {
                    return;
                  }

                  let questionFolder = zip.folder(question.question);
                  await Promise.all(
                    question.files.map(async (file, index) => {
                      questionFolder = await addFileToZip(
                        index,
                        file,
                        questionFolder,
                      );
                    }),
                  );
                }),
              );
              break;
            case "meeting":
              await Promise.all(
                object.agenda.map(async (agenda) => {
                  if (agenda.files.length === 0) {
                    return;
                  }

                  let zipFolder = zip.folder(agenda.title);
                  await Promise.all(
                    agenda.files.map(async (file, index) => {
                      zipFolder = await addFileToZip(index, file, zipFolder);
                    }),
                  );

                  await Promise.all(
                    agenda.topics.map(async (topic) => {
                      if (topic.files.length === 0) {
                        return;
                      }

                      let topicFolder = zipFolder.folder(topic.title);
                      await Promise.all(
                        topic.files.map(async (file, index) => {
                          topicFolder = await addFileToZip(
                            index,
                            file,
                            topicFolder,
                          );
                        }),
                      );
                    }),
                  );
                }),
              );
              break;
            case "inspection":
              await Promise.all(
                object.sections.map(async (section) => {
                  const sectionFolder = zip.folder(section.title);
                  await Promise.all(
                    section.items.map(async (item) => {
                      if (item.observations.length === 0) {
                        return;
                      }

                      const itemFolder = sectionFolder.folder(item.title);
                      await Promise.all(
                        item.observations.map(async (observation) => {
                          await Promise.all(
                            observation.files.map(async (file, index) => {
                              await addFileToZip(index, file, itemFolder);
                            }),
                          );
                        }),
                      );
                    }),
                  );
                }),
              );
              break;
            case "dailyReport":
              await Promise.all(
                object.agendas.map(async (agenda) => {
                  const zipFolder = zip.folder(agenda.title);
                  await Promise.all(
                    agenda.topics.map(async (topic, i) => {
                      if (topic.files.length === 0) {
                        return;
                      }

                      let topicFolder = zipFolder.folder(`${i + 1}`);
                      await Promise.all(
                        topic.files.map(async (file, index) => {
                          topicFolder = await addFileToZip(
                            index,
                            file,
                            topicFolder,
                          );
                        }),
                      );
                    }),
                  );
                }),
              );
              break;
            default:
              break;
          }

          if (erroredFiles.length > 0) {
            throw new Error(`Error downloading files: ${erroredFiles}`);
          } else {
            zip.generateAsync({ type: "blob" }).then((content) => {
              link.href = URL.createObjectURL(content);
              link.download = `${download}.zip`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            });
          }
        }),
      );
    };

    if (getObjectFiles(object).length > 0) {
      link.href = window.URL.createObjectURL(file);
      link.download = `${download}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      await loadZipFile();
    } else {
      link.href = window.URL.createObjectURL(file);
      link.download = `${download}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    const errorMessage =
      errorType === "timeout"
        ? tGeneral("errorDownloadMessage")
        : tGeneral("errorExportPDFMessage");

    PopUpAlert(
      "error",
      tGeneral("Error") ?? "Error",
      errorMessage ?? "Error",
      5000,
    );

    onFail();
  }
}
